import { useMemo } from 'react';
import { useUserInfo } from '../utils/roleHelpers';

export const useAvailableStatesList = () => {
  const { stateCodes = [] } = useUserInfo();

  const options = useMemo(() => {
    return stateCodes.map((code) => ({
      id: code,
      label: code,
      value: code,
    }));
  }, [stateCodes]);

  return options;
};
