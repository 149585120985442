export const AttorneyPerformanceFormBoxes = [
  {
    name: 'states',
    inputBoxes: [
      {
        label: 'State(s)',
        name: 'stateCodes',
        placeholder: '-',
        width: '226px',
        isCheckboxSelect: true,
        isMandatory: true,
      },
    ],
  },
  {
    name: 'periods',
    inputBoxes: {
      fromDate: {
        label: 'From Date',
        name: 'fromDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      toDate: {
        label: 'To Date',
        name: 'toDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
    },
  },
];

export const stateCodesOptions = [
  { value: 'AZ', label: 'AZ' },
  { value: 'NV', label: 'NV' },
];
