/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const editCommunity = createAsyncThunk(
  'editCommunity/editCommunity',
  async (editCommunityObj, { rejectWithValue }) => {
    try {
      const { id, ...fields } = editCommunityObj;
      const { data } = await apiClient.put(`/api/communities/${id}`, fields);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editCommunityErrorReset = createAction('community/editCommunityErrorReset', () => ({
  type: 'editCommunity/editCommunityErrorReset',
}));

export const getCommunity = createAsyncThunk('editCommunity/getCommunity', async (id) => {
  const { data } = await apiClient.get(`/api/communities/${id}`);

  return data;
});

export const deleteCommunity = createAsyncThunk(
  'editCommunity/deleteCommunity',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.delete(`/api/communities/${id}`);

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const editCommunityNotes = createAsyncThunk(
  'editCommunity/editCommunityNotes',
  async (editCommunityNotesObj) => {
    const { id, ...fields } = editCommunityNotesObj;
    const { data } = await apiClient.post(`/api/communities/${id}/notes`, fields);

    return data;
  },
);

export const getCommunityNotes = createAsyncThunk('editCommunity/getCommunityNotes', async (id) => {
  const { data } = await apiClient.get(`/api/communities/${id}/notes`);

  return data;
});

export const editCommunityAddresses = createAsyncThunk(
  'editCommunity/editCommunityAddresses',
  async (editCommunityAddressesObj, { rejectWithValue }) => {
    try {
      const { id, ...fields } = editCommunityAddressesObj;
      const { data } = await apiClient.post(`/api/communities/${id}/addresses`, fields);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCommunityAddresses = createAsyncThunk(
  'editCommunity/getCommunityAddresses',
  async (getCommunityAddressesObj) => {
    const { id, ...fields } = getCommunityAddressesObj;
    const { data } = await apiClient.post(`/api/communities/${id}/addresses/filter`, fields);

    return data;
  },
);

export const deleteCommunityAddresses = createAsyncThunk(
  'editCommunity/deleteCommunityAddresses',
  async (deleteCommunityAddressesObj) => {
    const { id, ...fields } = deleteCommunityAddressesObj;
    const { data } = await apiClient.delete(`/api/communities/${id}/addresses`, {
      data: fields,
    });

    return data;
  },
);

export const editCommunityContacts = createAsyncThunk(
  'editCommunity/editCommunityContacts',
  async (editCommunityContactsObj) => {
    const { id, ...fields } = editCommunityContactsObj;
    const { data } = await apiClient.post(`/api/communities/${id}/contacts`, fields);
    return data;
  },
);

export const getCommunityContacts = createAsyncThunk(
  'editCommunity/getCommunityContacts',
  async (id) => {
    const { data } = await apiClient.get(`/api/communities/${id}/contacts`);

    return data;
  },
);

export const deleteCommunityContacts = createAsyncThunk(
  'editCommunity/deleteCommunityContacts',
  async (args) => {
    const { id, contactId } = args;
    const { data } = await apiClient.delete(`/api/communities/${id}/contacts/${contactId}`);
    return data;
  },
);

export const getContacts = createAsyncThunk('editCommunity/getContacts', async (params = {}) => {
  const { data } = await apiClient.post(`/api/contacts/filter`, { params });
  return data;
});

export const getManagements = createAsyncThunk(
  'community/getManagements',
  async (params = {}, thunkAPI) => {
    const {
      managements: { items: prevItems = [] },
    } = thunkAPI.getState().editCommunity;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/managements/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.managementId,
      value: item.managementName,
      label: item.managementName,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getAddresses = createAsyncThunk('editCommunity/getAddresses', async (params) => {
  const { data } = await apiClient.post(`/api/addresses/filter`, params);
  return data;
});

export const getStates = createAsyncThunk('editCommunity/states', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data;
});

export const resetCommunityError = createAction('editCommunity/resetCommunityError', () => ({
  type: 'community/resetCommunityError',
}));

export const uploadDocument = createAsyncThunk(
  'editCommunity/uploadDocument',
  async ({ id, data: filesToUpload }) => {
    const formData = new FormData();

    filesToUpload.forEach((item, index) => {
      formData.append(`request[${index}].documentName`, item.name);
      formData.append(`request[${index}].type`, item.type);
      formData.append(`request[${index}].file`, item.file);
    });

    const { data } = await apiClient.post(`/api/community/${id}/documents/upload`, formData, {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  },
);

export const deleteDocument = createAsyncThunk('editCommunity/deleteDocument', async (args) => {
  const { id, ...restParams } = args;
  const { data } = await apiClient.delete(`/api/community/${id}/documents`, {
    data: { ...restParams },
    headers: { 'Content-Type': 'application/json' },
  });

  return data;
});

export const getDocument = createAsyncThunk(
  'editCommunity/getDocument',
  async ({ id, documentId }) => {
    const { data } = await apiClient.get(`/api/community/${id}/documents/${documentId}`);

    return data;
  },
);

export const getDocuments = createAsyncThunk('editCommunity/getDocuments', async () =>
  // params
  {
    // const { data } = await apiClient.post(`/api/community/${params.id}/documents/filter`, params);
    // return data;
    // TODO remove mock
    return {
      result: {
        currentPage: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        items: [
          {
            documentId: 1306,
            documentName: 'Writ Order111 - TEST',
            type: 'Order',
            createdDate: '2024-01-23T12:20:07.0681931',
            createdBy: 'Mike Salivan',
            link: null,
            fileName: null,
            deliveredBy: null,
            prefileDate: null,
            source: 'Generated',
          },
          {
            documentId: 1307,
            documentName: 'Justice Court - Summons',
            type: 'Summons',
            createdDate: '2024-02-09T02:08:48.8087165',
            createdBy: 'Mike Salivan',
            link: null,
            fileName: null,
            deliveredBy: null,
            prefileDate: null,
            source: 'Generated',
          },
        ],
        totalPages: 1,
        totalRowsCount: 2,
      },
    };
  },
);

export const updateDocument = createAsyncThunk(
  'editCommunity/updateDocument',
  async ({ id, documentId, ...body }) => {
    const { data } = await apiClient.put(`/api/community/${id}/documents/${documentId}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  },
);

const initialState = {
  editableCommunity: {},
  editableCommunityContacts: [],
  notes: [],
  addresses: {},
  contacts: [],
  managements: {
    items: [],
  },
  addressesAll: [],
  states: [],
  error: null,
  communityAddressError: null,
  editCommunityError: null,
  documents: {},
  document: null,
};

export const editCommunitySlice = createSlice({
  name: 'editCommunity',
  initialState,
  reducers: {},
  extraReducers: {
    [editCommunity.rejected]: (state, action) => {
      state.editCommunityError = action.payload;
    },
    [editCommunityErrorReset]: (state) => {
      state.editCommunityError = null;
    },
    [getCommunity.fulfilled]: (state, action) => {
      state.editableCommunity = action.payload.result;
    },
    [getCommunity.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteCommunity.fulfilled]: (state, action) => {
      state.editableCommunity = action.payload;
    },
    [deleteCommunity.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editCommunityNotes.fulfilled]: (state, action) => {
      state.notes = action.payload;
    },
    [editCommunityNotes.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCommunityNotes.fulfilled]: (state, action) => {
      state.notes = action.payload.result;
    },
    [getCommunityNotes.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editCommunityAddresses.fulfilled]: (state, action) => {
      state.addresses = action.payload;
    },
    [editCommunityAddresses.rejected]: (state, action) => {
      state.communityAddressError = action.payload;
    },
    [resetCommunityError]: (state) => {
      state.communityAddressError = null;
    },
    [getCommunityAddresses.fulfilled]: (state, action) => {
      state.addresses = action.payload.result;
    },
    [getCommunityAddresses.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteCommunityAddresses.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editCommunityContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCommunityContacts.fulfilled]: (state, action) => {
      state.editableCommunityContacts = action.payload.result;
    },
    [getCommunityContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteCommunityContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getContacts.fulfilled]: (state, action) => {
      state.contacts = action.payload.result;
    },
    [getContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getManagements.fulfilled]: (state, action) => {
      state.managements = action.payload;
    },
    [getManagements.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.addressesAll = action.payload.result;
    },
    [getAddresses.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.result.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.code,
      }));
    },
    [getStates.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getDocuments.fulfilled]: (state, action) => {
      state.documents = action.payload.result;
    },
    [getDocuments.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [uploadDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getDocument.fulfilled]: (state, action) => {
      state.document = action.payload;
    },
    [getDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default editCommunitySlice.reducer;
