export const contactHeading = 'Contact Properties';

export const contactBoxes = [
  {
    name: '',
    inputBoxes: [
      {
        label: 'Contact Info',
        isSubHeading: true,
      },
      {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter name',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'Title',
        name: 'title',
        placeholder: 'Enter title',
        width: '208px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        isSpace: true,
      },
      {
        label: 'Work Number',
        name: 'workPhone',
        width: '326px',
        placeholder: '+1(480)xx xx xx xx',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Mobile number',
        name: 'mobilePhone',
        width: '326px',
        placeholder: '+1(480)xx xx xx xx',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Email',
        name: 'email',
        placeholder: 'Enter email',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Email updates',
        name: 'isEmailUpdatesEnabled',
        placeholder: '',
        width: '240px',
        isDropdown: false,
        isRadioButtons: true,
        radioButtonWidth: '116px',
        data: [
          { id: 0, label: 'Yes', value: true, isDefault: true },
          { id: 1, label: 'No', value: false },
        ],
        isMandatory: false,
        isDisabled: false,
        isDate: false,
      },
    ],
  },
];
