import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl/FormControl';
import { useMemo } from 'react';
import { documentStrings } from './Document.constants';
import MButton from '../../../MUI/Button/MButton';
import ShadowBlock from '../../../atoms/ShadowBlock';
import { ReactComponent as AddSquare } from '../../../../assets/icons/addSquare.svg';
import { ReactComponent as Upload } from '../../../../assets/icons/document-upload.svg';
import Dropdown from '../../../atoms/Dropdown';

const CreateUploadDocument = ({ handleOpen, onCreateDocument, value, onChange, isDB }) => {
  const { documentTemplates } = useSelector((state) => state.casesGeneral);

  const documentTemplatesOptions = useMemo(() => {
    return documentTemplates.map((item) => ({
      id: item.templateId,
      label: item.templateName,
      value: item.templateName,
    }));
  }, [documentTemplates]);

  return (
    <Box sx={{ mx: 4, p: 4, borderRadius: 2 }}>
      <Typography variant="h5" sx={{ mb: 15 }}>
        {documentStrings.title}
      </Typography>
      <Stack spacing={2} direction="row" justifyContent="center">
        {isDB && (
          <ShadowBlock>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
                height: '176px',
                width: '336px',
              }}
            >
              <Typography variant="h5" sx={{ mb: 2 }}>
                {documentStrings.boxes[0].title}
              </Typography>
              <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Dropdown
                    width="320px"
                    options={documentTemplatesOptions}
                    placeholder={documentStrings.boxes[0].selectLabel}
                    value={value}
                    isColumn
                    onChange={onChange}
                  />
                </Box>
              </FormControl>
              <MButton variant="borderLess" onClick={onCreateDocument}>
                <AddSquare />
              </MButton>
            </Box>
          </ShadowBlock>
        )}
        <ShadowBlock>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: isDB ? '176px' : '95px',
              width: '336px',
            }}
          >
            <Typography variant="h5">{documentStrings.boxes[1].title}</Typography>
            <MButton variant="borderLess" onClick={handleOpen}>
              <Upload />
            </MButton>
          </Box>
        </ShadowBlock>
      </Stack>
    </Box>
  );
};

CreateUploadDocument.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  onCreateDocument: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isDB: PropTypes.bool,
};

CreateUploadDocument.defaultProps = {
  isDB: true,
};

export default CreateUploadDocument;
