/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Stack, Box, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as Add } from '../../../assets/icons/Add.svg';
import { ReactComponent as AddBlue } from '../../../assets/icons/AddBlue.svg';
import Table from '../../atoms/Table/Table';
import { getNonPaymentNoticeColumns } from './ClientPortalNonPaymentNoticeDialog.constants';
import MButton from '../../MUI/Button/MButton';
import { palette } from '../../../theme/default';
import {
  createRequestNotice,
  getAddressesListNV,
  getNonWorkingDates,
} from '../../../store/slices/requests/nonPaymentRequest';
import DateOrTimePicker from '../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../assets/icons/Calender.svg';
import Form from '../../atoms/Form';
import Field from '../../atoms/Field';
import NotificationDialog from '../../molecules/NotificationDialog/NotificationDialog';
import MTooltip from '../../atoms/MTooltip';
import BulkNoticeRequestDialog from './BulkNoticeRequestDialog/BulkNoticeRequestDialog';
import LoaderCover from '../../atoms/LoaderCover';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/ArrowLeft.svg';
import useSearchDropdown from '../../../hooks/useSearchDropdown';
import { getRandomId } from '../../../utils/randomId';
import { addObjToFormData } from '../../../utils/convertObjToFormData';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const ClientPortalNonPaymentNoticeNV = ({ stateCode = 'NV' }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isBulkNoticeRequestDialogOpen, setIsBulkNoticeRequestDialogOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isDateLoading, setDateLoading] = useState(false);
  const [isErrorNotificationOpen, setIsErrorNotificationOpen] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const id = getRandomId();
  const base = useBaseUrl();
  const cells = {
    id,
    addressName: '',
    defendantName: '',
    apartmentNumber: '',
    addressId: '',
    hasNoAppartmentNumber: false,
    individuals: [],
    entities: [],
    lateFeeAssessed: '',
    rentBecameDelinquent: '',
    lastPaymentDate: '',
    periodicRent: '',
    currentAmountDue: '',
    pastAmountDue: '',
    lateFees: '',
    lastPaymentAmount: '',
    totalOwed: '',
  };
  const initialRow = {
    ...cells,
    errors: {
      addressId: true,
      defendantName: true,
      apartmentNumber: true,
      lateFeeAssessed: true,
      rentBecameDelinquent: true,
      lastPaymentDate: true,
      periodicRent: true,
      currentAmountDue: true,
      pastAmountDue: true,
      lateFees: true,
      lastPaymentAmount: true,
    },
    touched: {
      addressId: false,
      defendantName: false,
      apartmentNumber: false,
      lateFeeAssessed: false,
      rentBecameDelinquent: false,
      lastPaymentDate: false,
      periodicRent: false,
      currentAmountDue: false,
      pastAmountDue: false,
      lateFees: false,
      lastPaymentAmount: false,
    },
  };
  const [tableData, setTableData] = useState({
    [id]: initialRow,
  });

  const [isNotification, setIsNotification] = useState(false);
  const [isCancelNotificationOpened, setIsCancelNotificationOpened] = useState(false);
  const { addressesList } = useSelector((state) => state.nonPaymentRequest);
  const nonWorkingDates = useSelector((store) => store.nonPaymentRequest.nonWorkingDates);
  const [file, saveFile] = useState(null);

  const dateForm = useForm();
  const [failedIds, setFailedIds] = useState([]);

  useEffect(() => {
    getAddressesListNV({
      currentPage: 1,
      pageSize: 20,
      address: '',
    });

    dispatch(
      getNonWorkingDates({
        stateCode,
        year: moment().format('YYYY'),
        month: moment().format('MM'),
      }),
    );
  }, []);

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: addressesList?.hasNextPage,
    getAction: ({ input, page }) =>
      getAddressesListNV({
        currentPage: page,
        address: input,
      }),
  });

  const onOpenAddressesMenu = () => {
    setIsLoading(true);
    dispatch(
      getAddressesListNV({
        currentPage: 1,
        pageSize: 20,
        address: '',
        open: true,
      }),
    ).finally(() => {
      setIsLoading(false);
    });
  };

  const handleBulkNoticeRequestDialogOpen = () => {
    setIsBulkNoticeRequestDialogOpen(true);
  };
  const handleBulkNoticeRequestDialogClose = (data) => {
    setIsBulkNoticeRequestDialogOpen(false);
    if (data) {
      const updatedData = data.reduce((acc, item) => {
        const id = getRandomId();
        acc[id] = {
          ...item,
          id,
          errors: {
            addressId: item.errors?.Address || item.errors?.StreetNumber || item.errors?.AddressId,
            addressName:
              item.errors?.Address || item.errors?.StreetNumber || item.errors?.AddressId,
            pastAmountDue: item.errors?.PastAmountDue,
            defendantName: item.errors?.Individuals,
            apartmentNumber: item.errors?.ApartmentNumber,
            lateFeeAssessed: item.errors?.LateFeeAssessed,
            rentBecameDelinquent: item.errors?.RentBecameDelinquent,
            lastPaymentDate: item.errors?.LastPaymentDate,
            periodicRent: item.errors?.PeriodicRent,
            currentAmountDue: item.errors?.CurrentAmountDue,
            lateFees: item.errors?.LateFees,
            lastPaymentAmount: item.errors?.LastPaymentAmount,
          },
          __error: !!Object.values(item.errors)?.length,
          touched: {
            addressId: true,
            defendantName: true,
            apartmentNumber: true,
            lateFeeAssessed: true,
            rentBecameDelinquent: true,
            lastPaymentDate: true,
            periodicRent: true,
            currentAmountDue: true,
            pastAmountDue: true,
            lateFees: true,
            lastPaymentAmount: true,
          },
        };
        return acc;
      }, {});

      setTableData(updatedData);
    }
  };

  const onMonthChange = async (date) => {
    const instance = moment(date);
    setDateLoading(true);
    await dispatch(
      getNonWorkingDates({
        stateCode,
        year: instance.format('YYYY'),
        month: instance.format('MM'),
      }),
    );
    setDateLoading(false);
  };

  const checkIfDateIsNotAvailable = (date) => {
    return nonWorkingDates.includes(date.format('YYYY-MM-DD'));
  };

  const thereAreRowsWithErrors = Object.values(tableData)?.some((item) => item.__error);

  const cannotSubmit = useMemo(
    () =>
      Object.values(tableData).some((item) => {
        return Object.values(item.errors).some((item) => !!item);
      }),
    [tableData],
  );

  const onUpdateData = (originalId, editableCell) => {
    setTableData((prev) => {
      return {
        ...prev,
        [originalId]: {
          ...prev[originalId],
          [editableCell.id]: editableCell.value,
          errors: { ...prev[originalId]?.errors, [editableCell.id]: editableCell.error },
          touched: { ...prev[originalId]?.touched, [editableCell.id]: editableCell.touched },
        },
      };
    });
  };

  const columns = useMemo(
    () =>
      getNonPaymentNoticeColumns({
        showErrorColumn: thereAreRowsWithErrors,
        updateValue: onUpdateData,
        handleMenuScrollToBottom,
        changeInput,
        onOpenAddressesMenu,
      }),
    [thereAreRowsWithErrors],
  );

  const onDeleteRow = (rowId) => {
    if (Object.values(tableData).length <= 1) return;
    const { [rowId]: omitted, ...rest } = tableData;

    setTableData(rest);
  };

  const handleClickAdd = () => {
    const id = getRandomId();
    setTableData((prev) => {
      return { ...prev, [id]: { ...initialRow, id } };
    });
  };
  const handleCloseNotification = () => {
    setIsNotification(false);
    navigate(`/${base}/requests`, {
      state: {
        navPage: 'New Requests',
        stateCode: 'NV',
      },
    });
  };
  const handleCloseErrorNotification = () => {
    setIsErrorNotificationOpen(false);
  };

  const handleCloseCancelNotification = () => {
    setIsCancelNotificationOpened(false);
  };

  const handleConfirmCancelNotification = () => {
    setIsCancelNotificationOpened(false);
    navigate(`/${base}/requests`, {
      state: {
        navPage: 'New Requests',
        stateCode: 'NV',
      },
    });
  };

  const handleCancel = () => {
    setIsCancelNotificationOpened(true);
  };

  const handleSubmit = ({ dateServed }) => {
    const requestData = Object.values(tableData).map((item) => {
      const { addressName, __error, touched, errors, defendantName, lastPaymentDate, id, ...rest } =
        item;
      return { ...rest, rowId: id, lastPaymentDate: lastPaymentDate || '' };
    });

    setIsLoader(true);
    dispatch(
      createRequestNotice(
        addObjToFormData({
          nonPaymentNotices: requestData,
          dateServed: `${moment(dateServed).format('yyyy-MM-DD')}T00:00:00`,
          saveSpreadsheet: false,
          ...(isBulk && file && { formFile: file, saveSpreadsheet: true }),
        }),
      ),
    )
      .unwrap()
      .then((response) => {
        // some notices returned from backend are failed to request
        const someItemHasError = response?.result?.items?.some(
          (item) => !!Object.values(item.errors).length,
        );
        if (someItemHasError) {
          const failed = response?.result.items
            .filter((item) => !!Object.values(item.errors).length)
            .map((item) => item);
          setFailedIds(failed);
        } else {
          setIsNotification(true);
          saveFile(null);
          setIsBulk(false);
        }
      })
      .catch((err) => {
        setIsErrorNotificationOpen(true);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const rows = useMemo(() => {
    return Object.values(tableData).map((row) => {
      if (Object.values(row.errors).every((errorField) => !errorField)) {
        return { ...row, __error: false };
      }
      return row;
    });
  }, [tableData]);

  const leaveOnlyFailed = () => {
    const leftFailedData = failedIds.reduce((acc, item) => {
      if (tableData[item.rowId]) {
        acc[item.rowId] = tableData[item.rowId];
        acc[item.rowId].errors = {
          addressId: item.errors?.Address || item.errors?.StreetNumber || item.errors?.AddressId,
          addressName: item.errors?.Address || item.errors?.StreetNumber || item.errors?.AddressId,
          pastAmountDue: item.errors?.PastAmountDue,
          defendantName: item.errors?.Individuals,
          apartmentNumber: item.errors?.ApartmentNumber,
          lateFeeAssessed: item.errors?.LateFeeAssessed,
          rentBecameDelinquent: item.errors?.RentBecameDelinquent,
          lastPaymentDate: item.errors?.LastPaymentDate,
          periodicRent: item.errors?.PeriodicRent,
          currentAmountDue: item.errors?.CurrentAmountDue,
          lateFees: item.errors?.LateFees,
          lastPaymentAmount: item.errors?.LastPaymentAmount,
        };
        acc[item.rowId].touched = {
          addressId: true,
          addressName: true,
          pastAmountDue: true,
          defendantName: true,
          apartmentNumber: true,
          lateFeeAssessed: true,
          rentBecameDelinquent: true,
          lastPaymentDate: true,
          periodicRent: true,
          currentAmountDue: true,
          lateFees: true,
          lastPaymentAmount: true,
        };
      }
      return acc;
    }, {});

    setTableData(leftFailedData);
    setFailedIds([]);
  };

  const saveFileForBulk = (file) => {
    setIsBulk(true);
    saveFile(file);
  };

  return (
    <Stack display="flex" height="100%">
      {isLoader && <LoaderCover isFixed />}
      {isLoading && (
        <Box
          position="absolute"
          width="100%"
          height="calc(100% - 80px)"
          top="-80px"
          left="0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={10}
          transform="translate(-50%, -50%)"
        >
          <CircularProgress />
        </Box>
      )}
      <Stack
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={4}
      >
        <Stack spacing={1} direction="row" alignItems="baseline" width={1}>
          <NavLink to={`/${base}/requests`} state={{ stateCode: 'NV', navPage: 'New Requests' }}>
            <ArrowLeft width={24} height={24} />
          </NavLink>
          <Typography variant="h4" color="text.primary" data-testid="subpage_heading_text">
            Non-Payment Notices
          </Typography>
        </Stack>

        <Stack display="flex" direction="row" alignItems="center" gap={2}>
          <MButton
            sx={{ whiteSpace: 'nowrap' }}
            size="large"
            onClick={handleBulkNoticeRequestDialogOpen}
            startIcon={<Add />}
          >
            Bulk notice request
          </MButton>
        </Stack>
      </Stack>

      <Stack pl={4} pr={4} mb={4} direction="row" justifyContent="space-between">
        <Form form={dateForm}>
          <Field
            isMandatory
            name="dateServed"
            render={({ field, onCustomChange, error }) => {
              return (
                <DateOrTimePicker
                  isDate
                  error={error}
                  height="48px"
                  width="247px"
                  name="dateServed"
                  label="Please select the day these notices are to be served"
                  placeholder="mm/dd/yyyy"
                  isMandatory
                  Svg={Calender}
                  minDate={moment().add(1, 'day')}
                  onChange={onCustomChange(field.onChange)}
                  setSelectedTimeOrDate={onCustomChange(field.onChange)}
                  selected={field.value}
                  isLoading={isDateLoading}
                  onMonthChange={onMonthChange}
                  shouldDisableDate={checkIfDateIsNotAvailable}
                  tooltip={
                    <MTooltip
                      customStyles={{ position: 'absolute', right: '-30px' }}
                      text="Notices can not be served on weekends, holidays, or same day."
                    />
                  }
                />
              );
            }}
          />
        </Form>

        {thereAreRowsWithErrors && (
          <Typography variant="bodyM" pt={4} color={palette.additional.alert}>
            Please check and correct the fields. Then press request button.
          </Typography>
        )}
      </Stack>
      <Stack
        display="flex"
        sx={{ maxWidth: '100vw', flexGrow: '0' }}
        alignItems="center"
        flexGrow={1}
        mb={isBulk ? 14 : 0}
      >
        <Stack width="100%" pl="32px" pr="32px" maxHeight={'50vh'}>
          <Table
            columns={columns}
            rows={rows}
            onDeleteRow={onDeleteRow}
            hasFixedColumns
            showFixedColumnsAtWidth={1920}
            onUpdateData={onUpdateData}
            stickyWithRightButton
          />
        </Stack>
      </Stack>
      {!isBulk && (
        <Box mt={2} ml={2} pb={4} mb={14}>
          <MButton startIcon={<AddBlue />} variant="borderLess" onClick={handleClickAdd}>
            Add One More Notice
          </MButton>
        </Box>
      )}
      <Box
        width={1}
        p={4}
        boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        display="flex"
        direction="row"
        justifyContent="flex-end"
        position="absolute"
        bottom="0"
      >
        <Stack spacing={2} display="flex" direction="row">
          <MButton variant="secondary" size="large" onClick={handleCancel} sx={{ mr: 'auto' }}>
            Cancel
          </MButton>
          <MButton
            width={144}
            size="large"
            onClick={dateForm.handleSubmit(handleSubmit)}
            disabled={cannotSubmit || isLoader}
          >
            Request
          </MButton>
        </Stack>
      </Box>
      <BulkNoticeRequestDialog
        isOpen={isBulkNoticeRequestDialogOpen}
        onClose={handleBulkNoticeRequestDialogClose}
        callback={saveFileForBulk}
        stateCode="NV"
      />
      <NotificationDialog
        title="Notification message"
        desc={
          Object.values(tableData).length > 1 ? 'Requests have been sent' : 'Request has been sent'
        }
        buttonSecondaryText=""
        buttonPrimaryText="Ok"
        onClose={handleCloseNotification}
        onConfirm={handleCloseNotification}
        isOpen={isNotification}
        width="500px"
      />
      <NotificationDialog
        type="alert"
        title="Error"
        desc={
          <>
            Case can not be created with this data.
            <br />
            Please contact a Clark & Walker staff member for resolution.
          </>
        }
        buttonSecondaryText=""
        buttonPrimaryText="Ok"
        onClose={handleCloseErrorNotification}
        onConfirm={handleCloseErrorNotification}
        isOpen={isErrorNotificationOpen}
      />
      <NotificationDialog
        title="Notification message"
        desc="Requests will be removed from the table"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Ok"
        onClose={handleCloseCancelNotification}
        onConfirm={handleConfirmCancelNotification}
        isOpen={isCancelNotificationOpened}
        type="alert"
        width="600px"
      />
      <NotificationDialog
        title="Notification message"
        desc={
          <>
            {Object.values(tableData).length - failedIds.length || 0} out of{' '}
            {Object.values(tableData).length} requests have been sent successfully.
            <br />
            <br />
            Please review the notices which failed to be requested. You can contact a Clark & Walker
            staff member for resolution.
          </>
        }
        buttonPrimaryText="Ok"
        onConfirm={leaveOnlyFailed}
        isOpen={!!failedIds.length}
        type="alert"
        width="600px"
      />
    </Stack>
  );
};

export default ClientPortalNonPaymentNoticeNV;
