import React from 'react';
import { Typography } from '@mui/material';
import PropTypes, { number } from 'prop-types';

export const NumberCell = ({ value }) =>
  value ? (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 600,
        width: '100%',
        textAlign: 'end',
      }}
      data-testid="number_cell"
    >
      {value}
    </Typography>
  ) : (
    ''
  );

NumberCell.propTypes = {
  value: PropTypes.instanceOf(number).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};
