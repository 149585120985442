export const palette = {
  type: 'light',

  common: {
    black: '#000000',
    white: '#FFFFFF',
  },

  buttonPrimary: {
    active: '#546FFF',
    hover: '#3D53DB',
    pressed: '#2A3BB7',
    disabled: '#98ABFF',
  },

  buttonSecondary: {
    activeBg: '#FFFFFF',
    activeBorder: '#AEB1D2',
    hoverBorder: '#54577A',
    pressedBg: '#EBEDFA',
    pressedBorder: '#AEB1D2',
    disabledBorder: '#DCE4FF',
  },

  text: {
    primary: '#030410',
    secondary: '#54577A',
    disabled: '#EBEDFA',
    disabledText: '#999999',
    tertiary: '#AEB1D2',
    browseFile: '#546FFF',
  },

  additional: {
    lines: '#DCE4FF',
    lines1: '#AFB1D0',
    alert: '#DB2719',
    success: '#6DC657',
    thumb: '#8E92BC',
    tag: '#DFDCFF',
    tableTotalBackground: '#fbfbfe',
  },

  statuses: {
    grey: '#d8d8d8',
    blue: '#349afd',
    green: '#79ffbd',
    yellow: '#ffe669',
    red: '#ff7691',
    anakiwa: '#7cbfff',
    periwinkle: '#bf83ff',
    transparent: 'rgba(255, 0, 0, 0)',
    orange: '#FEBA6C',
    magenta: '#ff80ff',
    cyan: '#57ceba',
    frostedMint: '#dbfffb',
    black: '#54577A',
  },

  shadow: {
    boxBlur: 'rgba(106, 114, 142, 0.1)',
    boxAccent: 'rgba(105, 129, 216, 0.1)',
    boxContrast: 'rgba(69, 80, 121, 0.13)',
  },

  upload: {
    dragAndDropArea: '#EBEDFA',
  },

  scrollbar: {
    thumb: 'rgba(0,0,0,0.5)',
  },

  connectionStatuses: {
    success: '#6DC657',
    alert: '#DB2719',
  },

  fieldDisabled: {
    backgroundColor: '#f2f2f2',
  },
};
