import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { decodeParams, navigateToNewTab } from '../utils/toNewTab';

export const useNavigateToNewTab = () => {
  return navigateToNewTab;
};

export const useInitializeRouterState = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const { path, ...state } = Object.fromEntries(searchParams);
    if (path) {
      setSearchParams({});
      if (Object.keys(state).length) {
        navigate(path, { state: decodeParams(state), replace: true });
      } else {
        navigate(path, { replace: true });
      }
    }
  }, []);
};
