import React, { useEffect, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

import { TextareaAutosize, styled } from '@mui/material';
import {
  ClientPortalNoticeIntakeFormBoxes,
  documentsColumns,
  documentStrings,
  INDIVIDUALS_COLUMNS,
  PROPERTY_TYPE,
  uploadDocumentsColumns,
} from './ClientPortalNoticeIntakeForm.constants';
import Field from '../../../atoms/Field';
import Form from '../../../atoms/Form';
import Dropdown from '../../../atoms/Dropdown';
import RadioButtons from '../../../atoms/RadioButtons';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import { ReactComponent as Upload } from '../../../../assets/icons/document-upload.svg';
import {
  getAddresses,
  createNoticeIntake,
} from '../../../../store/slices/requests/noticeIntakeSlice';
import Table from '../../../atoms/Table/Table';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import ClientPortalNoticeIntakeUploadModal from './ClientPortalNoticeIntakeUploadModal';
import { addObjToFormData } from '../../../../utils/convertObjToFormData';
import notificationUtils from '../../../../utils/notificationUtils';
import Dropzone from '../../../atoms/Dropzone';
import LoaderCover from '../../../atoms/LoaderCover';
import { useBaseUrl } from '../../../../hooks/useBaseUrl';

const mandatoryError = { type: 'required', message: 'Mandatory field' };

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme, error }) => ({
  width: '675px',
  maxWidth: '675px',
  minWidth: '675px',
  padding: '12px 4px 4px 12px',
  background: theme.palette.common.white,
  borderRadius: '10px',
  border: `1px solid ${error ? palette.additional.alert : palette.additional.lines}`,
  fontWeight: 500,
  fontSize: '16px',
}));

const UploadButton = styled(MButton)(({ theme }) => ({
  height: '36px',
  '& svg': {
    height: '14px',
    width: '14px',
  },
  '& svg path': {
    stroke: theme.palette.buttonPrimary.active,
  },
}));

const ClientPortalNoticeIntakeForm = ({ stateCode = 'AZ' }) => {
  const base = useBaseUrl();
  const addresses = useSelector((state) => state.noticeIntake.getAddresses);

  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { navPage, subNav } = useLocation()?.state || {};

  const [open, setOpen] = useState(false);
  const [isDragAndDrop, setIsDrag] = useState(true);

  const form = useForm({
    defaultValues: {},
    resolver: async (data) => {
      const errors = {};

      if (typeof data.addressInfo?.id !== 'number') {
        errors.addressInfo = mandatoryError;
      }

      if (!data.address.hasNoApartment && !data.address.apartmentNumber) {
        errors.address = {
          apartmentNumber: mandatoryError,
        };
      }

      return { values: data, errors };
    },
  });

  const individualFields = useFieldArray({
    control: form.control,
    name: 'individuals',
  });

  const documentsFields = useFieldArray({
    control: form.control,
    name: 'documents',
  });

  const opposingPartyLength = individualFields.fields.length;

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: addresses.hasNextPage,
    getAction: ({ input, page }) =>
      getAddresses({
        currentPage: page,
        stateCode,
        address: input,
      }),
  });

  const handleSubmit = useCallback(async (values) => {
    const noticeValues = {
      AddressId: values.addressInfo.id,
      propertyType: values.address?.propertyType?.value,
      ApartmentNumber: values.address.apartmentNumber || '',
      HasNoApartmentNumber: !!values.address.hasNoApartment,
      Individuals: values.individuals.map((individual) => ({
        firstName: individual.firstName || '',
        lastName: individual.lastName || '',
        middleName: individual.middleName || '',
        suffix: individual.suffix || '',
      })),
      Entities: [],
      Documents: values.documents,
      SummaryOfEvents: values.summaryOfEvents || '',
    };

    if (stateCode === 'NV') {
      delete noticeValues.propertyType;
    }

    const data = addObjToFormData(noticeValues);

    setIsLoader(true);
    try {
      await dispatch(createNoticeIntake(data)).unwrap();
      notificationUtils.success('Created successfully');
      navigate(`/${base}/requests`, {
        state: {
          navPage,
          subNav,
          stateCode,
        },
      });
    } catch (e) {
      notificationUtils.error('Failed, try again later');
      setIsLoader(false);
    }
  }, []);

  const handleCancel = useCallback(() => {
    form.reset();
    navigate(`/${base}/requests`, {
      state: {
        navPage,
        subNav,
        stateCode,
      },
    });
  }, []);

  const handleAddDefendant = (i) => async () => {
    const values = form.getValues();
    const boxes = i.inputBoxes.filter((b) => !!b?.name?.startsWith('individual.'));
    const errors = boxes.map(({ name, isMandatory }) => ({
      name,
      error: isMandatory && !form.getValues(name) ? mandatoryError : null,
    }));
    errors.forEach(({ name, error }) => form.setError(name, error));
    if (errors.find((e) => e.error)) {
      return;
    }
    individualFields.prepend(values.individual);
    boxes.forEach((box) => form.setValue(box.name, ''));
  };

  const handleUpdateDefendant = async (index, values) => {
    const areFieldsInvalid = INDIVIDUALS_COLUMNS.some((c) => c.isMandatory && !values[c.accessor]);
    if (areFieldsInvalid) return false;
    individualFields.update(index, values);
    return true;
  };

  const hasNoApartmentNumber = form.watch('address.hasNoApartment');
  useEffect(() => {
    if (hasNoApartmentNumber) form.setValue('address.apartmentNumber', '');
  }, [hasNoApartmentNumber]);

  const addressId = useWatch({ name: 'addressInfo.id', control: form.control });

  useEffect(() => {
    if (addressId && addresses?.items?.length && !isLoader) {
      const { data: address } = addresses.items.find((addr) => addr.id === addressId);
      if (address) {
        const values = form.watch();

        let propType = values.address?.propertyType;

        if (!propType) {
          propType = PROPERTY_TYPE.find((item) => item.default);
        }

        form.reset({
          ...values,
          address: {
            apartmentNumber: values.address?.apartmentNumber ?? '',
            hasNoApartment: values.address?.hasNoApartment ?? false,
            propertyType: propType,
            ...address,
          },
        });
      }
    } else if (stateCode === 'AZ') {
      const propertyType = PROPERTY_TYPE.find((item) => item.default);

      form.setValue('address.propertyType', propertyType);
    }
  }, [addressId]);

  const formUploads = useForm({
    defaultValues: {
      uploadDocumentsArray: [],
    },
  });

  const uploadDocumentsArray = useFieldArray({
    control: formUploads.control,
    name: 'uploadDocumentsArray',
  });

  useEffect(() => {
    setIsDrag(!uploadDocumentsArray.fields.length);
  }, [uploadDocumentsArray.fields.length]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    uploadDocumentsArray.remove();
  };

  const handleChangeDocumentTypeInPopup = (event) => {
    if (event.target?.files?.length) {
      const file = event.target.files[0];
      uploadDocumentsArray.prepend({
        documentName: file.name.slice(0, file.name.lastIndexOf('.')),
        type: 'Notice',
        file,
      });
    }
  };

  const handleUploadDocuments = () => {
    const values = formUploads.getValues();
    documentsFields.append(values.uploadDocumentsArray ?? []);
    handleClose();
  };

  const defineDropdownOptions = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case 'address.propertyType':
          return PROPERTY_TYPE;
        case 'addressInfo':
          return addresses.items;
        default:
          return [];
      }
    },
    [addresses?.items],
  );

  const getShowMandatory = useCallback(
    (fieldName, initial = false) => {
      switch (fieldName) {
        default:
          return initial;
      }
    },
    [opposingPartyLength],
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      uploadDocumentsArray.replace(
        acceptedFiles.map((file) => ({
          documentName: file.name,
          type: 'Notice',
          file,
        })),
      );
    },
    [uploadDocumentsArray],
  );

  const dragAndDrop = (
    <Dropzone
      onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
      dropText={documentStrings.upload.text}
      actionText={documentStrings.upload.action}
      multiple
    />
  );

  const uploadTable = (
    <Form form={formUploads}>
      <Box
        sx={{
          overflowY: 'scroll',
          maxHeight: '240px',
          width: '732px',
          margin: '32px 0',
          '&::-webkit-scrollbar': {
            width: '4px',
            marginLeft: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: palette.additional.lines,
          },
          '&::-webkit-scrollbar-thumb': {
            background: palette.additional.thumb,
          },
        }}
      >
        <Table
          loading={false}
          columns={uploadDocumentsColumns}
          rows={uploadDocumentsArray.fields}
          onUpdateData={uploadDocumentsArray.update}
          onDeleteRow={uploadDocumentsArray.remove}
        />
      </Box>
    </Form>
  );

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mb: 9 + 4 + 4 + 6,
          px: 4,
          width: 1,
          maxWidth: 1542,
        }}
      >
        <Typography variant="h3">
          {stateCode === 'AZ' ? 'Non-Compliance Notice Form' : 'Notice Intake Form'}
        </Typography>
        {isLoader && <LoaderCover isFixed />}
        <Form form={form} onSubmit={handleSubmit}>
          {ClientPortalNoticeIntakeFormBoxes &&
            ClientPortalNoticeIntakeFormBoxes.map((i) => (
              <Container
                key={i.boxTitle}
                maxWidth={false}
                disableGutters
                sx={{
                  mx: 'auto',
                  mt: 4,
                  width: 1,
                }}
              >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                  <Typography variant="h5">{i.boxTitle}</Typography>
                  {!!i.info && (
                    <Typography variant="bodyM" color="text.secondary">
                      {i.info}
                    </Typography>
                  )}
                  {i.name === 'documents' && (
                    <UploadButton
                      variant="bordered"
                      startIcon={<Upload />}
                      size="small"
                      onClick={handleOpen}
                    >
                      {documentStrings.boxes[1].title}
                    </UploadButton>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" columnGap={3} rowGap={2} flexWrap="wrap">
                  {i.inputBoxes.map((item) => {
                    if (item.isDropdown) {
                      if (stateCode === 'NV' && item.name === 'address.propertyType') return null;
                      return (
                        <Field
                          key={item.label}
                          isMandatory={item.isMandatory}
                          name={item.name}
                          render={({ field, onCustomChange, error }) => (
                            <Dropdown
                              key={item.label}
                              label={item.label}
                              value={field.value}
                              width={item.width}
                              error={error}
                              isColumn
                              isMandatory={item.isMandatory}
                              isDisabled={item.isDisabled}
                              placeholder={item.placeholder}
                              onChange={onCustomChange(field.onChange)}
                              options={defineDropdownOptions(item.name)}
                              onMenuScrollToBottom={handleMenuScrollToBottom}
                              onInputChange={changeInput}
                              isSearchable
                              filterOption={null}
                            />
                          )}
                        />
                      );
                    }

                    if (item.isCheckbox) {
                      return (
                        <Field
                          key={item.label}
                          name={item.name}
                          render={({ field, onCustomChange }) => (
                            <CheckboxWithLabel
                              isDisabled={item.isDisabled}
                              key={item.label}
                              label={item.label}
                              isChecked={field.value}
                              onChange={onCustomChange(field.onChange)}
                              marginTop="25px"
                            />
                          )}
                        />
                      );
                    }

                    if (item.isRadioButtons) {
                      return (
                        <Field
                          key={item.label}
                          name={item.name}
                          render={({ field, onCustomChange }) => (
                            <RadioButtons
                              isDisabled={item.isDisabled}
                              key={item.label}
                              data={item.data}
                              defaultValue={field.value ? 'yes' : 'no'}
                              value={field.value ? 'yes' : 'no'}
                              onChange={onCustomChange(field.onChange)}
                              checked={field.value}
                              name={item.name}
                              label={item.label}
                            />
                          )}
                        />
                      );
                    }

                    if (item.isSubHeading) {
                      return (
                        <Typography width={1} mb={1.5} variant="bodyL500" key={item.label}>
                          {item.label}
                        </Typography>
                      );
                    }

                    if (item?.isSpace) {
                      return <div style={{ width: '100%' }} />;
                    }

                    if (item.isTextArea) {
                      return (
                        <Field
                          key={item.label}
                          name={item.name}
                          isMandatory={item.isMandatory}
                          render={({ field, error }) => (
                            <StyledTextareaAutosize
                              placeholder={item.placeholder}
                              value={field.value}
                              onChange={field.onChange}
                              error={error}
                              minRows={10}
                              style={{ width: '675px', maxWidth: '675px', minWidth: '675px' }}
                            />
                          )}
                        />
                      );
                    }

                    if (item.isAddButtonWithTable) {
                      if (item.table === 'individualTable') {
                        return (
                          <React.Fragment key={item.table}>
                            <MButton
                              sx={{ marginTop: '32px', marginLeft: 'auto' }}
                              startIcon={<Add />}
                              onClick={handleAddDefendant(i)}
                            >
                              Add
                            </MButton>
                            <Box mt={2} width="100%">
                              <Table
                                columns={INDIVIDUALS_COLUMNS}
                                isLoading={false}
                                onUpdateData={handleUpdateDefendant}
                                onDeleteRow={individualFields.remove}
                                rows={individualFields.fields}
                              />
                            </Box>
                          </React.Fragment>
                        );
                      }
                      return null;
                    }

                    return (
                      <Field
                        key={item.label}
                        name={item.name}
                        isMandatory={item.isMandatory}
                        render={({ field, error }) => (
                          <EditInputWithLabel
                            error={error}
                            isMandatory={getShowMandatory(item.name, item.isMandatory)}
                            isDisabled={
                              typeof item.isDisabled === 'function'
                                ? item.isDisabled(form)
                                : item.isDisabled
                            }
                            key={item.label}
                            type="text"
                            label={item.label}
                            name={item.name}
                            value={field.value}
                            placeholder={item.placeholder}
                            width={item.width}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    );
                  })}
                  {i.name === 'documents' && (
                    <Box mt={2} width="100%">
                      <Table
                        columns={documentsColumns}
                        rows={documentsFields.fields}
                        isLoading={false}
                        onUpdateData={documentsFields.update}
                        onDeleteRow={documentsFields.remove}
                      />
                    </Box>
                  )}
                </Stack>
              </Container>
            ))}
          <Box
            position="absolute"
            left="50%"
            bottom={0}
            sx={{ transform: 'translateX(-50%)' }}
            zIndex={10}
            width={1}
            p={4}
            boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
          >
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <MButton
                variant="secondary"
                size="large"
                onClick={handleCancel}
                sx={{ width: '144px' }}
              >
                Cancel
              </MButton>
              <MButton
                type="submit"
                size="large"
                sx={{ width: '144px' }}
                disabled={!opposingPartyLength || isLoader}
              >
                Request
              </MButton>
            </Stack>
          </Box>
        </Form>
      </Container>
      <ClientPortalNoticeIntakeUploadModal
        open={open}
        handleChange={handleChangeDocumentTypeInPopup}
        handleClose={handleClose}
        isDragAndDrop={isDragAndDrop}
        dragAndDrop={dragAndDrop}
        uploadTable={uploadTable}
        uploadHandler={handleUploadDocuments}
        documentStrings={documentStrings}
      />
    </>
  );
};

ClientPortalNoticeIntakeForm.propTypes = {
  stateCode: PropTypes.string.isRequired,
};

export default ClientPortalNoticeIntakeForm;
