import { Box, CircularProgress, Stack } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Table from '../../../atoms/Table/Table';

import {
  ATTORNEY_REVIEW,
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
  listConfig,
} from './DashboardAttorney.constants';
import { apiClient } from '../../../../lib/apiClient';
import useAuth from '../../../../hooks/useAuth';
import { rolesDB } from '../../../../utils/roleHelpers';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import notificationUtils from '../../../../utils/notificationUtils';
import { BulkAssignToOtherDialog } from './BulkAssignToOtherDialog';

export const AttorneyReview = ({
  stateCode,
  handleFetchDataPage,
  sortField,
  sortOrder,
  handleSortTable,
  selectedIds,
  setSelectedIds,
  isLoader,
}) => {
  const { columns } = listConfig[ATTORNEY_REVIEW];
  const { lists } = useSelector((state) => state.attorneyDashboard);
  const [isShowAdditionalButton, setIsShowAdditionalButton] = useState(false);
  const [otherModalOpen, setOtherModalOpen] = useState(false);
  const { attorneyReview } = lists;
  const { userInfo } = useAuth();
  const isCurrentUserAttorney = userInfo.roles.includes(rolesDB.Attorney);

  const canBulkAssignToMe = isCurrentUserAttorney;
  const canBulkAssignToOther = userInfo.roles.some((item) => item === rolesDB.Admin);

  const handleCheckedRow = useCallback(
    (selectedRows) => {
      setIsShowAdditionalButton(selectedRows.length > 0);
      setSelectedIds(selectedRows.map((item) => item.caseId));
    },
    [isShowAdditionalButton],
  );

  const rowsAdapter = useMemo(
    () =>
      attorneyReview?.items?.map((item) => ({
        ...item,
        isCheckboxDisabled:
          (isCurrentUserAttorney && item.attorney?.id === userInfo.UserGuid) ||
          !item.canAssignReviewingAttorney,
      })),
    [attorneyReview?.items],
  );

  const columnList = useMemo(() => {
    return typeof columns === 'function' ? columns(stateCode) : columns;
  }, [stateCode]);

  const openOtherModal = () => {
    setOtherModalOpen(true);
  };

  const closeOtherModal = () => {
    setOtherModalOpen(false);
  };

  const bulkAssignToMe = async () => {
    const url = `/api/dashboards/attorney/cards/evictionMatters/assignAttorneyReviewer`;
    try {
      await apiClient.put(url, { caseIds: selectedIds, userId: userInfo.UserGuid });
      notificationUtils.success('Assigned successfully');
      handleFetchDataPage(1);
    } catch {
      notificationUtils.error('Failed, try again later');
    }
  };

  const bulkAssignToOther = async (chosenAssigneeId) => {
    const url = `/api/dashboards/attorney/cards/evictionMatters/assignAttorneyReviewer`;
    try {
      await apiClient.put(url, { caseIds: selectedIds, userId: chosenAssigneeId });
      notificationUtils.success('Assigned successfully');
      handleFetchDataPage(1);
    } catch {
      notificationUtils.error('Failed, try again later');
    }
    closeOtherModal();
  };

  return (
    <Stack position="relative">
      {isLoader && (
        <Box position="absolute" top="50%" left="50%" zIndex={10} transform="translate(-50%, -50%)">
          <CircularProgress />
        </Box>
      )}
      <Table
        columns={columnList}
        rows={rowsAdapter}
        total={attorneyReview?.totalRowsCount}
        isPagination={attorneyReview?.totalPages > 1}
        onNextPage={handleFetchDataPage}
        onPreviousPage={handleFetchDataPage}
        onGotoPage={handleFetchDataPage}
        pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
        isWithCheckbox
        onCheckedRow={handleCheckedRow}
        onSortTable={handleSortTable}
        sortField={sortField}
        sortOrder={sortOrder}
        hasFixedColumns
        showFixedColumnsAtWidth={1600}
        onRefreshPage={handleFetchDataPage}
      />
      {!!selectedIds.length && (
        <Box
          position="fixed"
          left="50%"
          bottom={0}
          sx={{ transform: 'translateX(-50%)', background: 'white' }}
          zIndex={10}
          width={1}
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            {canBulkAssignToOther && (
              <MButton
                type="submit"
                size="large"
                name="Submit community"
                sx={{ width: '165px' }}
                onClick={openOtherModal}
                data-testid="assign_to_other_btn"
              >
                Assign To Other
              </MButton>
            )}
            {canBulkAssignToMe && (
              <MButton
                type="submit"
                size="large"
                name="Submit community"
                sx={{ width: '144px' }}
                onClick={bulkAssignToMe}
                data-testid="assign_to_me_btn"
              >
                Assign To Me
              </MButton>
            )}
          </Stack>
        </Box>
      )}
      {otherModalOpen && (
        <BulkAssignToOtherDialog
          title="Assign Cases"
          onClose={closeOtherModal}
          handleSubmit={bulkAssignToOther}
          stateCode={stateCode}
          selectedIds={selectedIds}
        />
      )}
    </Stack>
  );
};

AttorneyReview.propTypes = {
  stateCode: PropTypes.string.isRequired,
  attorney: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  handleFetchDataPage: PropTypes.func.isRequired,
  sortField: PropTypes.func.isRequired,
  sortOrder: PropTypes.func.isRequired,
  handleSortTable: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  isLoader: PropTypes.bool,
};

AttorneyReview.defaultProps = { isLoader: false };
