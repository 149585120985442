import moment from 'moment';

export function isInFuture(input, today = moment()) {
  const prefileDate = moment(input).startOf('day');
  const todayDayOfWeek = moment(today).day();

  if (
    prefileDate.isSameOrBefore(today, 'day') ||
    prefileDate.isSame(today.clone().add(1, 'day'), 'day') ||
    (todayDayOfWeek === 5 && prefileDate.day() <= 1)
  ) {
    return false;
  }

  return prefileDate.isAfter(today, 'day');
}

export function getIsDateTodayOrInFuture(input, today = moment()) {
  const prefileDate = moment(input).startOf('day');
  const todayStartOfTheDay = moment(today).startOf('day');

  return prefileDate.isAfter(today, 'day') || prefileDate.isSame(todayStartOfTheDay, 'day');
}
