import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderCover from '../../atoms/LoaderCover';
import NotificationDialog from '../../molecules/NotificationDialog';
import { apiClient, tokenRepository } from '../../../lib/apiClient';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const SelfEnrollment = () => {
  const navigate = useNavigate();
  const { token = '' } = useParams();
  const [showNotification, setShowNotification] = useState(false);
  const [error, setError] = useState(null);
  const base = useBaseUrl();

  const verifyToken = async () => {
    try {
      const {
        data: { result: userInfo },
      } = await apiClient.get(`/cp/api/selfEnrollments/tokens/${token}/status`);
      tokenRepository.setTemporaryToken(token);
      navigate('/self-enrollment/create', { state: userInfo, replace: true });
    } catch (e) {
      const errorMessage =
        'Please reach out to your admin or to the Clark & Walker team to request a new link to create your account.';
      setError(errorMessage);
      setShowNotification(true);
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const onErrorClose = () => {
    navigate(`/${base}/login`);
  };

  return (
    <>
      <LoaderCover />
      <NotificationDialog
        title="The link you have used is no longer active"
        type="alert"
        desc={error}
        buttonPrimaryText="Ok"
        onClose={onErrorClose}
        onConfirm={onErrorClose}
        isOpen={showNotification}
        width="528px"
      />
    </>
  );
};

export default SelfEnrollment;
