import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import AddButton from '../../../atoms/AddButton/AddButton';
import CheckboxSelect from '../../../atoms/CheckboxSelect';
import Dropdown from '../../../atoms/Dropdown';
import MDialog from '../../../atoms/MDialog';
import Table, { TableLoader } from '../../../atoms/Table/Table';
import EmptyBlock from '../../../molecules/EmptyBlock';
import NotificationDialog from '../../../molecules/NotificationDialog';
import MButton from '../../../MUI/Button/MButton';
import {
  MAX_AMOUNT_OF_ITEMS_IN_MODAL,
  UsersTableColumns,
} from './ClientRequestNotifications.constants';
import useRuleDialog from './RuleDialogHooks';
import UsersDialog from './UsersDialog';

const RuleDialog = ({
  isOpen,
  onClose,
  currentRule,
  isUsersDialogOpen,
  openUsersDialog,
  closeUsersDialog,
}) => {
  const {
    isEdit,
    handleClose,
    canSave,
    handleSubmit,
    types,
    selectedRequestType,
    setSelectedRequestType,
    selectedStates,
    setSelectedStates,
    states,
    isLoading,
    usersPagination,
    tableLoading,
    handleDeleteUserRow,
    handleCloseUsersDialog,
    isDeleteDialogOpen,
    onConfirmDelete,
    closeDeleteUserDialog,
  } = useRuleDialog({ currentRule, isOpen, onClose, closeUsersDialog });

  return (
    <>
      <MDialog
        fullWidth
        maxWidth="md"
        title={`${isEdit ? 'Edit' : 'New'} Rule`}
        isOpen={isOpen}
        onClose={handleClose}
        scrollable
        footerActions={[
          <MButton
            sx={{ width: '150px' }}
            data-testid="close_btn"
            variant="bordered"
            size="large"
            onClick={handleClose}
          >
            Cancel
          </MButton>,
          <MButton
            sx={{ width: '150px' }}
            disabled={!canSave}
            variant="primary"
            size="large"
            onClick={handleSubmit}
            data-testid="save_btn"
          >
            Save
          </MButton>,
        ]}
      >
        <Stack direction="column" gap={3}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Dropdown
              isMandatory
              isColumn
              label="Request Type"
              placeholder="Select request type"
              width="330px"
              options={types}
              value={selectedRequestType}
              onChange={setSelectedRequestType}
              testid="rule_dialog_type_select"
            />
            <CheckboxSelect
              isMandatory
              label="State(s)"
              value={selectedStates}
              width="330px"
              placeholder="Select state(s)"
              onChange={setSelectedStates}
              options={states}
            />
          </Stack>
          <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
            <Typography variant="bodyL500">User(s)</Typography>
            <AddButton onClick={openUsersDialog} label="Add" width="78px" height="36px" />
          </Stack>
          <Box height="max(calc(100vh - 450px), 250px)">
            {isLoading && <TableLoader />}
            {!usersPagination.rows?.length && !isLoading && !tableLoading && (
              <Stack alignItems="center" justifyContent="center" height="100%">
                <EmptyBlock
                  title="No users selected"
                  desc={`Use "+ Add" button to add users to the rule`}
                />
              </Stack>
            )}
            {!!usersPagination.rows?.length && !isLoading && (
              <Table
                columns={UsersTableColumns}
                rows={usersPagination.rows ?? []}
                total={usersPagination.totalRowsCount ?? 0}
                isPagination={usersPagination.isPagination}
                onDeleteRow={handleDeleteUserRow}
                onNextPage={usersPagination.setCurrentPage}
                onPreviousPage={usersPagination.setCurrentPage}
                onGotoPage={usersPagination.setCurrentPage}
                pageSize={MAX_AMOUNT_OF_ITEMS_IN_MODAL}
                loading={tableLoading}
              />
            )}
          </Box>
        </Stack>
      </MDialog>
      <UsersDialog isEdit={isEdit} isOpen={isUsersDialogOpen} onClose={handleCloseUsersDialog} />
      <NotificationDialog
        desc="Removing user will take effect immediately even without saving the rule. Are you sure?"
        type="alert"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Delete"
        onClose={closeDeleteUserDialog}
        onConfirm={onConfirmDelete}
        isOpen={isDeleteDialogOpen}
      />
    </>
  );
};

RuleDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentRule: PropTypes.shape({
    stateCodes: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    notificationRuleId: PropTypes.number,
  }),
  isUsersDialogOpen: PropTypes.bool.isRequired,
  openUsersDialog: PropTypes.func.isRequired,
  closeUsersDialog: PropTypes.func.isRequired,
};

RuleDialog.defaultProps = {
  currentRule: null,
};

export default RuleDialog;
