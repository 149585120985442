import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderCover from '../../atoms/LoaderCover';
import NotificationDialog from '../../molecules/NotificationDialog';
import { apiClient, tokenRepository } from '../../../lib/apiClient';

const ForgotPasswordLanding = () => {
  const navigate = useNavigate();
  const { token = '', portal = 'cp' } = useParams();
  const [showNotification, setShowNotification] = useState(false);
  const [error, setError] = useState(null);

  const verifyToken = async () => {
    try {
      const apiByPortal = {
        db: `/api/passwordRecoveries/tokens/${token}/status`,
        cp: `/cp/api/passwordRecoveries/tokens/${token}/status`,
        client: `/cp/api/passwordRecoveries/tokens/${token}/status`,
      };
      await apiClient.get(apiByPortal[portal]);
      tokenRepository.setTemporaryToken(token);
      navigate(`/${portal}/password-recovery`, { replace: true });
    } catch (e) {
      const errorMessage =
        e.response?.data?.errorMessage ||
        'The link you have used is no longer active. Please request a new link.';
      setError(errorMessage);
      setShowNotification(true);
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const onErrorClose = () => {
    navigate(`/${portal}/login`);
  };

  return (
    <>
      <LoaderCover />
      <NotificationDialog
        title="The link you have used is no longer active"
        type="alert"
        desc={error}
        buttonPrimaryText="Ok"
        onClose={onErrorClose}
        onConfirm={onErrorClose}
        isOpen={showNotification}
        width="528px"
      />
    </>
  );
};

export default ForgotPasswordLanding;
