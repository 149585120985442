import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import DashboardLegalAssistantCard from './DashboardLegalAssistantCard';
import {
  CASES_TO_BE_ENTERED,
  FOLLOW_UPS,
  NOTICES_TO_BE_DRAFTED,
  PREFILES_TO_BE_PRINTED,
  cardsListByState,
  listConfig,
} from './DashboardLegalAssistantLists.constants';
import DashboardLegalAssistantList, {
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
} from './DashboardLegalAssistantList';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import {
  getDashboardStats,
  getDashboardLegalAssistantList,
  getSettings,
} from '../../../../../store/slices/legalAssistantDashboardSlice';
import MButton from '../../../../MUI/Button/MButton';
import { palette } from '../../../../../theme/default';
import { rolesDB, useUserInfo } from '../../../../../utils/roleHelpers';
import { apiClient } from '../../../../../lib/apiClient';
import notificationUtils from '../../../../../utils/notificationUtils';
import { BulkAssignToOtherDialog } from './BulkAssignToOtherDialog';
import useAuth from '../../../../../hooks/useAuth';

const DashboardLegalAssistantLists = ({ state }) => {
  const { userInfo } = useAuth();
  const [activeCard, setActiveCard] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [listLoader, setListLoader] = useState(false);
  const { stats } = useSelector((store) => store.legalAssistantDashboard);
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [otherModalOpen, setOtherModalOpen] = useState(false);
  const cards = useMemo(() => cardsListByState[state.value], [state]);
  const user = useUserInfo();
  const defaultPeriod = listConfig[activeCard]?.defaultPeriod || '';
  const [period, setPeriod] = useState(defaultPeriod);
  const dateNow = moment();
  const [date, setDate] = useState(dateNow);
  const [isTableLoader, setIsTableLoader] = useState(true);

  const canBulkAssign =
    !!selectedRows?.length &&
    (activeCard === CASES_TO_BE_ENTERED || activeCard === NOTICES_TO_BE_DRAFTED);
  const canBulkAssignToMe = user.roles.some((item) => item === rolesDB.LegalAssistant);
  const canBulkAssignToOther = user.roles.some((item) => item === rolesDB.Admin);

  useEffect(() => {
    dispatch(getSettings(userInfo.UserGuid));
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage = 1, nextPeriod = period, nextDate = date) => {
      setIsTableLoader(true);

      const params = {
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        currentPage,
        onlyTotal: false,
        stateCode: state.value,
        api: activeCard,
      };

      if (
        [FOLLOW_UPS, PREFILES_TO_BE_PRINTED, CASES_TO_BE_ENTERED, NOTICES_TO_BE_DRAFTED].includes(
          activeCard,
        )
      ) {
        if (nextPeriod === 'unassigned') {
          params.unassigned = true;
        } else {
          params.onlyMine = nextPeriod;
        }
      }

      if (activeCard === PREFILES_TO_BE_PRINTED) {
        const isValidDate = moment(nextDate).isValid();

        // maybe we shouldn't request api if there is no date
        if ((isValidDate && nextDate) || !nextDate) {
          if (nextDate) {
            params.prefileDate = `${nextDate.format('YYYY-MM-DD')}T00:00:00`;
          }

          return dispatch(getDashboardLegalAssistantList(params)).then(() => {
            setIsTableLoader(false);
          });
        }
        setIsTableLoader(false);
        return null;
      }

      return dispatch(getDashboardLegalAssistantList(params)).then(() => {
        setIsTableLoader(false);
      });
    },
    [activeCard, period, state.value, date],
  );

  const loadStats = () => {
    setIsLoader(true);
    dispatch(
      getDashboardStats({ stateCode: state.value, cards, userId: userInfo.UserGuid }),
    ).finally(() => setIsLoader(false));
  };

  const onCardClick = (id) => () => {
    setListLoader(true);
    setSelectedRows([]);
    setActiveCard(id);
  };

  useEffect(() => {
    loadStats();
    onCardClick(cards[0].id)();
  }, [state, cards]);

  const openOtherModal = () => {
    setOtherModalOpen(true);
  };

  const closeOtherModal = () => {
    setOtherModalOpen(false);
  };

  const handleCheckedRow = useCallback((selected) => {
    setSelectedRows(selected);
  }, []);

  const bulkAssignToMe = async () => {
    const url = `/api/dashboards/legalAssistant/cards/${activeCard}/assignAssistant`;

    const ids = selectedRows?.map((item) => item.caseId);
    try {
      await apiClient.put(url, { caseIds: ids, userId: user.UserGuid, stateCode: state?.value });
      notificationUtils.success('Assigned successfully');
      handleFetchDataPage(1, period);
    } catch {
      notificationUtils.error('Failed, try again later');
    }
  };

  const bulkAssignToOther = async (chosenAssigneeId) => {
    const url = `/api/dashboards/legalAssistant/cards/${activeCard}/assignAssistant`;
    const ids = selectedRows?.map((item) => item.caseId);

    try {
      await apiClient.put(url, { caseIds: ids, userId: chosenAssigneeId, stateCode: state?.value });
      notificationUtils.success('Assigned successfully');
      handleFetchDataPage(1, period);
    } catch {
      notificationUtils.error('Failed, try again later');
    }
    closeOtherModal();
  };

  return (
    <Stack direction="row" columnGap={3} alignItems="stretch" minHeight="736px">
      <Stack rowGap={2} position="relative" direction="column" flexWrap="wrap" width="328px">
        {isLoader && (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            zIndex={10}
            transform="translate(-50%, -50%)"
          >
            <CircularProgress />
          </Box>
        )}
        {cards.map(({ title, icon, id, isDisabled, base }) => (
          <DashboardLegalAssistantCard
            key={id}
            isActive={activeCard === id}
            onClick={onCardClick(id)}
            title={title}
            icon={icon}
            count={stats?.[id] ?? 0}
            base={base}
            isDisabled={isLoader || isDisabled || activeCard === id}
            stateCode={state.value}
          />
        ))}
      </Stack>
      {activeCard ? (
        <DashboardLegalAssistantList
          isLoader={listLoader}
          setIsLoader={setListLoader}
          stateCode={state.value}
          type={activeCard}
          handleCheckedRow={handleCheckedRow}
          isTableLoader={isTableLoader}
          period={period}
          setPeriod={setPeriod}
          handleFetchDataPage={handleFetchDataPage}
          date={date}
          setDate={setDate}
        />
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <EmptyBlock title="Please select a card" />
        </Box>
      )}
      {canBulkAssign && (canBulkAssignToMe || canBulkAssignToOther) && (
        <Box
          position="fixed"
          left="50%"
          bottom={0}
          sx={{ transform: 'translateX(-50%)', background: 'white' }}
          zIndex={10}
          width={1}
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            {canBulkAssignToOther && (
              <MButton
                type="submit"
                size="large"
                name="Submit community"
                sx={{ width: '165px' }}
                onClick={openOtherModal}
              >
                Assign To Other
              </MButton>
            )}
            {canBulkAssignToMe && (
              <MButton
                type="submit"
                size="large"
                name="Submit community"
                sx={{ width: '144px' }}
                onClick={bulkAssignToMe}
              >
                Assign To Me
              </MButton>
            )}
          </Stack>
        </Box>
      )}
      {otherModalOpen && (
        <BulkAssignToOtherDialog
          title={activeCard === CASES_TO_BE_ENTERED ? 'Assign Cases' : 'Assign Notices'}
          onClose={closeOtherModal}
          handleSubmit={bulkAssignToOther}
          stateCode={state?.value}
        />
      )}
    </Stack>
  );
};

DashboardLegalAssistantLists.propTypes = {
  state: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default DashboardLegalAssistantLists;
