import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const CourtsColumns = [
  {
    Header: 'Court Name',
    accessor: 'courtName',
    width: 30,
  },
  {
    Header: 'Court Address',
    accessor: 'address',
    width: 30,
  },
  {
    Header: 'Trial Date',
    accessor: 'trialDate',
    width: 18,
  },
  {
    Header: 'Trial Time',
    accessor: 'trialTime',
    width: 18,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    Cell: DeleteCell,
  },
];
