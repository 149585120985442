import { LinearProgress, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { apiClient } from '../../../../../lib/apiClient';
import MButton from '../../../../MUI/Button/MButton';
import { rolesDB, useUserInfo } from '../../../../../utils/roleHelpers';
import notificationUtils from '../../../../../utils/notificationUtils';

const CompleteCell = ({ row: { original }, refreshPage, state: { pageIndex }, stateCode }) => {
  const { roles, UserGuid } = useUserInfo();
  const { caseId } = original;
  const isRoleAdmin = roles.includes(rolesDB.Admin);

  const disabledButton = isRoleAdmin ? false : original.attorney !== UserGuid;

  const [isLoader, setIsLoader] = useState(false);

  const refresh = () => refreshPage(pageIndex + 1);

  const complete = async () => {
    setIsLoader(true);
    const url = `/api/dashboards/attorney/cards/timelines/complete`;
    const ids = [caseId];

    try {
      await apiClient.post(url, { caseIds: ids, stateCode });
      notificationUtils.success('Completed successfully');
      refresh();
    } catch {
      notificationUtils.error('Failed, try again later');
    } finally {
      setIsLoader(false);
    }
  };

  if (isLoader) {
    return (
      <Box sx={{ width: '130px' }}>
        <LinearProgress />
      </Box>
    );
  }

  const renderButton = () => {
    const component = (
      <MButton
        variant="secondary"
        size="small"
        disabled={disabledButton}
        onClick={complete}
        sx={{ width: '80px' }}
        data-testid="complete_btn"
      >
        Complete
      </MButton>
    );

    return component;
  };
  return <>{renderButton()}</>;
};

CompleteCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      attorney: PropTypes.number,
    }),
  }).isRequired,
  refreshPage: PropTypes.func.isRequired,
  state: PropTypes.shape({
    pageIndex: PropTypes.number.isRequired,
  }).isRequired,
  stateCode: PropTypes.string.isRequired,
};

export default CompleteCell;
