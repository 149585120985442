import axios from 'axios';
import {
  requestRefreshToken,
  clearLocalStorageAndMoveToLoginPage,
  dateTransformer,
} from './libUtils';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function createClient({ tokenRepository }) {
  const client = axios.create({
    baseURL: BASE_URL,
    transformRequest: [dateTransformer].concat(axios.defaults.transformRequest),
  });

  client.interceptors.request.use((config) => {
    const token = tokenRepository.getToken() || tokenRepository.getTemporaryToken();

    if (token == null) {
      return config;
    }

    const newConfig = {
      headers: {},
      ...config,
    };

    newConfig.headers.Authorization = `Bearer ${token}`;
    return newConfig;
  });

  let refreshingToken = null;

  client.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      const { config } = error;
      const { status } = error.response;

      const isLoginRequest = window.location.pathname.includes('/login');
      const isSelfEnrollment = window.location.pathname.includes('/self-enrollment');
      const isForgotPassword = window.location.pathname.includes('recovery');

      if (isSelfEnrollment || isForgotPassword) throw error;

      if (!isLoginRequest && !tokenRepository.getRefreshToken()) {
        clearLocalStorageAndMoveToLoginPage(tokenRepository);
        return null;
      }

      if (status === 401) {
        config.retry = true;

        if (!refreshingToken) {
          refreshingToken = requestRefreshToken(tokenRepository);
        }

        try {
          const response = await refreshingToken;
          const { accessToken, refreshToken } = response.data.result;
          tokenRepository.setToken(accessToken);
          tokenRepository.setRefreshToken(refreshToken);
          refreshingToken = null;
          return client(config);
        } catch (err) {
          refreshingToken = null;
          clearLocalStorageAndMoveToLoginPage(tokenRepository);
          return Promise.reject(err);
        }
      }

      return Promise.reject(error);
    },
  );

  return client;
}
