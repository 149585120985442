import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Page from '../../organisms/Page/Page';

const Dashboard = () => {
  const { state } = useLocation();

  const navPageString = state && state.navPage;
  const subNavString = state && state.subNav;
  const subSubNavigation = state && state.subSubNavigation;

  return (
    <Page
      navPage={navPageString || 'Dashboard'}
      subNavigation={subNavString}
      subSubNavigation={subSubNavigation}
    >
      <Outlet />
    </Page>
  );
};

export default Dashboard;
