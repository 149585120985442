import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';
import { apiClient } from '../../../../lib/apiClient';
import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';
import notificationUtils from '../../../../utils/notificationUtils';

export const InvoiceNumberCell = ({ value, row }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const {
        data: {
          result: { contentType, content, originalFileName },
        },
      } = await apiClient(`/cp/api/invoices/${row?.original?.invoiceId}/download`);

      openOrDownloadBinary({ contentType, content, fileName: originalFileName });
    } catch (error) {
      notificationUtils.error(
        error.response?.data?.message || error.message || 'Error downloading invoice',
      );
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <LinearProgress sx={{ width: '100%' }} />;
  }

  return (
    <Typography role="button" sx={{ cursor: 'pointer' }} variant="link" onClick={handleClick}>
      {value}
    </Typography>
  );
};

InvoiceNumberCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape().isRequired,
};
