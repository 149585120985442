/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import MDialog from '../../../../atoms/MDialog';
import { Stack } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import { ReactComponent as Calender } from '../../../../../assets/icons/Calender.svg';
import DateOrTimePicker from '../../../../atoms/DateOrTimePicker';
import moment from 'moment';
import CheckboxSelect from '../../../../atoms/CheckboxSelect';

const OPTIONS = [
  {
    id: 0,
    value: 'Prior to the granting of the Eviction Order, Defendant vacated the Leased Premises.',
    label: 'Prior to the granting of the Eviction Order, Defendant vacated the Leased Premises.',
  },
  {
    id: 1,
    value: 'Prior to the granting of the Eviction Order, Defendant paid the balance owed.',
    label: 'Prior to the granting of the Eviction Order, Defendant paid the balance owed.',
  },
  {
    id: 2,
    value:
      'Plaintiff has received rental assistance funding that justifies the rescission of the Eviction Order granted.',
    label:
      'Plaintiff has received rental assistance funding that justifies the rescission of the Eviction Order granted.',
  },
];

const MotionToRescind = ({ isOpen, handleConfirm, handleClose }) => {
  const [isLoading, setLoading] = useState(false);
  const [filedOn, setFiledOn] = useState(null);
  const [orderedOn, setOrderedOn] = useState(null);
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setFiledOn(null);
      setOrderedOn(null);
      setSelectedOption([]);
    } else setLoading(false);
  }, [isOpen]);

  const handleSelectChange = useCallback((value) => {
    setSelectedOption(value);
  }, []);

  const onFiledOnDateChange = (date) => {
    setFiledOn(date);
  };

  const onOrderedOnDateChange = (date) => {
    setOrderedOn(date);
  };

  const onConfirm = async () => {
    setLoading(true);

    await handleConfirm({
      EvictionFiledOn: filedOn ? moment(filedOn, moment.ISO_8601) : null,
      EvictionOrderedOn: orderedOn ? moment(orderedOn, moment.ISO_8601) : null,
      RescindReason: selectedOption.map((o) => o.value).join(','),
    });
  };

  return (
    <MDialog
      onClose={handleClose}
      isOpen={isOpen}
      maxWidth="582px"
      minHeight="396px"
      fullWidth
      title="Reason for Rescission"
    >
      <Stack flex direction="row" columnGap={3} mb={4}>
        <DateOrTimePicker
          isDate
          Svg={Calender}
          label="Eviction Filed On"
          selected={filedOn}
          width="226px"
          height="48px"
          placeholder="mm/dd/yyyy"
          setSelectedTimeOrDate={onFiledOnDateChange}
        />
        <DateOrTimePicker
          isDate
          Svg={Calender}
          label="Eviction Ordered On"
          selected={orderedOn}
          width="226px"
          height="48px"
          placeholder="mm/dd/yyyy"
          setSelectedTimeOrDate={onOrderedOnDateChange}
        />
      </Stack>
      <Stack rowGap={4}>
        <CheckboxSelect
          value={selectedOption}
          label="Choose reason"
          placeholder="Select Option"
          options={OPTIONS}
          onChange={handleSelectChange}
        />
        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
          <MButton variant="secondary" size="large" onClick={handleClose} sx={{ width: '104px' }}>
            Cancel
          </MButton>
          <MButton
            size="large"
            onClick={onConfirm}
            sx={{ width: '104px' }}
            disabled={!selectedOption.length || isLoading}
          >
            Create
          </MButton>
        </Stack>
      </Stack>
    </MDialog>
  );
};

export default MotionToRescind;
