import React, { useCallback, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import MButton from '../../../MUI/Button/MButton';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup/CustomRadioGroup';
import { TypeRadio, TABLE_COLUMNS, TABLE_ROWS_COUNT } from './CivilMatterView.constants';
import DatePickerWithLabel from '../../../atoms/DatePickerWithLabel/DatePickerWithLabel';
import Table from '../../../atoms/Table/Table';
import { apiClient } from '../../../../lib/apiClient';
import ShadowBlock from '../../../atoms/ShadowBlock';
import EmptyBlock from '../../../molecules/EmptyBlock';
import LoaderCover from '../../../atoms/LoaderCover';
// NOTE: Hide matter properties for now
// import NonEditableInformationField from '../../../atoms/NonEditableInformationField/NonEditableInformationField';

export default function CivilMatterView() {
  const {
    state: { id },
  } = useLocation();

  const [isLoader, setIsLoader] = useState(false);
  // NOTE: Hide matter properties for now
  // const [matterProperties, setMatterProperties] = useState(null);
  const [activities, setActivities] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [type, setType] = useState(TypeRadio[0].value);

  // NOTE: Hide matter properties for now
  // useEffect(() => {
  // apiClient.get(`/cp/api/cases/${id}/civilMatters/attorneys`).then(({ data: { result } }) => {
  // setMatterProperties(result);
  // });
  // }, [id]);

  const handleFetchTable = useCallback(
    async (currentPage = 1, populateWithParams = true) => {
      setIsLoader(true);
      let requestBody = {
        caseId: id,
        type: TypeRadio[0].value,
      };

      if (populateWithParams) {
        requestBody = {
          ...requestBody,
          type: type?.value || type,
          ...(fromDate && { fromDate: moment(fromDate, moment.ISO_8601) }),
          ...(toDate && { toDate: moment(toDate, moment.ISO_8601) }),
        };
      }

      const civilMatterResponse = await apiClient.post(`/cp/api/cases/${id}/civilMatters/filter`, {
        currentPage,
        pageSize: TABLE_ROWS_COUNT,
        ...requestBody,
      });

      const totalResponse = await apiClient.post(
        `/cp/api/cases/${id}/civilMatters/total`,
        requestBody,
      );

      const {
        data: { result },
      } = civilMatterResponse;
      const {
        quantityTotal,
        nonBillableTotal,
        billableTotal,
        nonBillableQuantityTotal,
        billableQuantityTotal,
      } = totalResponse.data.result;

      if (result?.items?.length) {
        result.items.push({
          isTotalRow: true,
          civilMatterId: null,
          type: null,
          quantity: quantityTotal,
          description: null,
          nonBillableQuantityTotal,
          billableQuantityTotal,
          rate: null,
          nonBillable: nonBillableTotal,
          billable: billableTotal,
          date: null,
          user: null,
          invoiceStatus: null,
        });
      }

      setIsLoader(false);
      setActivities(result);
    },
    [apiClient, id, type, fromDate, toDate],
  );

  useEffect(() => {
    handleFetchTable();
  }, []);

  const handleTypeChange = useCallback((value) => {
    setType(value);
  }, []);

  const handleFromDateChange = useCallback((value) => {
    setFromDate(value);
  }, []);

  const handleToDateChange = useCallback((value) => {
    setToDate(value);
  }, []);

  const handleClearFilter = useCallback(() => {
    setType(TypeRadio[0].value);
    setFromDate(null);
    setToDate(null);
    handleFetchTable(1, false);
  }, [handleFetchTable]);

  return (
    <>
      {isLoader && <LoaderCover isFixed />}
      {/* NOTE: Hide matter properties for now
       *      <Container
       *        maxWidth={false}
       *        disableGutters
       *        sx={{
       *          mx: 'auto',
       *          mt: 4,
       *          mb: 4,
       *          px: 4,
       *          width: 1,
       *          maxWidth: 1542,
       *        }}
       *      >
       *        <ShadowBlock variant="dropdownCard">
       *          <Stack direction="row" alignItems="center" justifyContent="space-between">
       *            <Typography variant="h5">Matter Properties</Typography>
       *          </Stack>
       *          <Stack flex direction="row" columnGap={3}>
       *            <NonEditableInformationField
       *              width="400px"
       *              height="56px"
       *              label="Originator Attorney"
       *              placeholder="-"
       *              value={matterProperties?.originatorAttorneyFullName}
       *            />
       *
       *            <NonEditableInformationField
       *              width="400px"
       *              height="56px"
       *              label="Responsible Attorney"
       *              placeholder="-"
       *              value={matterProperties?.responsibleAttorneyFullName}
       *            />
       *
       *            <NonEditableInformationField
       *              width="400px"
       *              height="56px"
       *              label="Requested By"
       *              placeholder="-"
       *              value={matterProperties?.requestedBy}
       *            />
       *          </Stack>
       *        </ShadowBlock>
       *      </Container>
       */}
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          mb: 9 + 4 + 4,
          px: 4,
          width: 1,
          maxWidth: 1542,
        }}
      >
        <ShadowBlock variant="dropdownCard">
          <Stack flex direction="row" justifyContent="space-between" columnGap={4}>
            <Typography variant="h5">Activities</Typography>
          </Stack>
          <Stack
            flex
            flexWrap="wrap"
            direction="row"
            alignItems="flex-end"
            columnGap={3}
            rowGap={3}
            mt="36px"
            mb={4}
          >
            <CustomRadioGroup value={type} data={TypeRadio} onChange={handleTypeChange} />
            <DatePickerWithLabel
              label="From Date"
              value={fromDate}
              dateFormat="MM/DD/YY"
              onChangeValue={handleFromDateChange}
              width="200px"
            />
            <DatePickerWithLabel
              label="To Date"
              value={toDate}
              dateFormat="MM/DD/YY"
              onChangeValue={handleToDateChange}
              width="200px"
            />
            <Stack
              flex
              mb="6px"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
              columnGap={1}
            >
              <MButton variant="bordered" onClick={() => handleFetchTable()} disabled={isLoader}>
                Apply
              </MButton>
              <MButton variant="borderLess" onClick={handleClearFilter} disabled={isLoader}>
                Clear Filter
              </MButton>
            </Stack>
          </Stack>
          {activities?.items?.length ? (
            <Table
              hasTotalRow
              columns={TABLE_COLUMNS}
              rows={activities?.items}
              total={activities?.totalRowsCount}
              isPagination={activities?.totalPages > 1}
              onNextPage={handleFetchTable}
              onPreviousPage={handleFetchTable}
              onGotoPage={handleFetchTable}
              pageSize={TABLE_ROWS_COUNT}
            />
          ) : (
            <Stack alignItems="center" paddingTop="50px">
              <EmptyBlock title="No records" />
            </Stack>
          )}
        </ShadowBlock>
      </Container>
    </>
  );
}
