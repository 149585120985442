/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const createCase = createAsyncThunk('cases/createCase', async (createCaseObj) => {
  const { data } = await apiClient.post(`/api/Case/createCase`, createCaseObj);

  return data;
});

export const getCommunityByAddressId = createAsyncThunk(
  'cases/getCommunityByAddressId',
  async (propertyAddressId) => {
    const { data } = await apiClient.get(`/api/Case/getCommunityByAddressId`, {
      params: { propertyAddressId },
    });

    return data;
  },
);

export const getCourtByCommunityAddressId = createAsyncThunk(
  'cases/getCourtByCommunityAddressId',
  async (propertyAddressId) => {
    const { data } = await apiClient.get(`/api/Case/getCourtByCommunityAddressId`, {
      params: { propertyAddressId },
    });

    return data;
  },
);

export const getAttorneyDropDownItems = createAsyncThunk(
  'user/getAttorneyDropDownItems',
  async () => {
    const { data } = await apiClient.get(`/api/User/getAttorneyDropDownItems`);

    return data;
  },
);

export const getDeliveredByDropDownItems = createAsyncThunk(
  'deliveredBy/getDeliveredByDropDownItems',
  async () => {
    const { data } = await apiClient.get(`/api/DeliveredBy/getDeliveredByDropDownItems`);

    return data;
  },
);

export const getHearingTypeDropDownItems = createAsyncThunk(
  'hearingType/getHearingTypeDropDownItems',
  async () => {
    const { data } = await apiClient.get(`/api/HearingType/getHearingTypeDropDownItems`);

    return data;
  },
);

export const getViolationDropDownItems = createAsyncThunk(
  'violation/getViolationDropDownItems',
  async (stateId) => {
    const { data } = await apiClient.get(`/api/Violation/getViolationDropDownItems`, {
      params: { stateId },
    });

    return data;
  },
);

const initialState = {
  case: {},
  community: {},
  court: {},
  attorneys: {},
  deliveredBy: {},
  hearingTypes: {},
  violationTypes: {},
  error: null,
};

export const createCaseSlice = createSlice({
  name: 'createCase',
  initialState,
  reducers: {},
  extraReducers: {
    [createCase.fulfilled]: (state, action) => {
      state.case = action.payload;
    },
    [createCase.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCommunityByAddressId.fulfilled]: (state, action) => {
      state.community = action.payload;
    },
    [getCommunityByAddressId.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCourtByCommunityAddressId.fulfilled]: (state, action) => {
      state.court = action.payload;
    },
    [getCourtByCommunityAddressId.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAttorneyDropDownItems.fulfilled]: (state, action) => {
      state.attorneys = action.payload;
    },
    [getAttorneyDropDownItems.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getDeliveredByDropDownItems.fulfilled]: (state, action) => {
      state.deliveredBy = action.payload;
    },
    [getDeliveredByDropDownItems.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getHearingTypeDropDownItems.fulfilled]: (state, action) => {
      state.hearingTypes = action.payload;
    },
    [getHearingTypeDropDownItems.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getViolationDropDownItems.fulfilled]: (state, action) => {
      state.violationTypes = action.payload;
    },
    [getViolationDropDownItems.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default createCaseSlice.reducer;
