import { useCallback, useEffect, useState } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as Asterisk } from '../../../assets/icons/asterisk.svg';
import ShadowBlock from '../../atoms/ShadowBlock';
import MButton from '../../MUI/Button/MButton';
import CustomRadioGroup from '../../atoms/CustomRadioGroup/CustomRadioGroup';
import { palette } from '../../../theme/default';
import { useUserAssignmentData } from './SelfEnrollmentHooks';
import { ReactComponent as Add } from '../../../assets/icons/Add.svg';
import { DeleteCell } from '../../atoms/Table/TableCells/DeleteCell';
import Table from '../../atoms/Table/Table';
import SelfEnrollmentCommunitiesModal from './SelfEnrollmentCommunitiesModal';
import SelfEnrollmentAddressesModal from './SelfEnrollmentAddressesModal';
import { invalidCommunitiesAndAddressesMessage } from '../../../utils/formHelpers';
import MTooltip from '../../atoms/MTooltip';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const entirePortfolioData = [
  { id: 0, label: 'Yes', value: true },
  { id: 1, label: 'No', value: false, isDefault: true },
];

export const newUserCommunitiesColumnsWithDeleteCell = [
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 96,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    Cell: DeleteCell,
  },
];

export const newUserAddressColumnsWithDeleteCell = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 48,
  },
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 48,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    Cell: DeleteCell,
  },
];

const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

const SelfEnrollmentUserAssignment = ({ userInfo, setUserInfo, onNext, onBack }) => {
  const navigate = useNavigate();
  const base = useBaseUrl();
  const [communityAndAddressError, setCommunityAndAddressError] = useState(false);
  const {
    hasEntirePortfolio,
    setHasEntirePortfolio,

    statesToUseInSearch,
    management,

    communities,
    loadCommunities,
    selectedCommunities,
    setSelectedCommunities,
    isCommunitiesModalOpen,
    setCommunitiesModalOpen,

    addresses,
    loadAddresses,
    selectedAddresses,
    setSelectedAddresses,
    isAddressesModalOpen,
    setAddressesModalOpen,
  } = useUserAssignmentData(userInfo);

  useEffect(() => {
    if (hasEntirePortfolio || selectedAddresses.length || selectedCommunities.length) {
      setCommunityAndAddressError(false);
    }
  }, [hasEntirePortfolio, selectedAddresses, selectedCommunities]);

  const handleCancel = () => {
    navigate(`/${base}/login`);
  };

  const handleDeleteCommunityRow = (row) => {
    setSelectedCommunities((cs) =>
      cs.filter((item) => {
        return item.communityId !== row.communityId;
      }),
    );
  };

  const handleDeleteAddressRow = (row) => {
    setSelectedAddresses((as) =>
      as.filter((item) => {
        return item.addressId !== row.addressId;
      }),
    );
  };

  const handleSubmit = () => {
    if (!selectedCommunities.length && !selectedAddresses.length && !hasEntirePortfolio) {
      setCommunityAndAddressError(true);
    } else {
      const data = {
        ...userInfo,
        hasEntirePortfolio,
        selectedAddresses,
        selectedCommunities,
      };

      setUserInfo(data);
      onNext(data);
    }
  };

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  const handleBack = () => {
    setUserInfo((currentUserInfo) => ({
      ...currentUserInfo,
      hasEntirePortfolio,
      selectedAddresses,
      selectedCommunities,
    }));
    onBack();
  };

  return (
    <Stack gap={4} pb={24}>
      <ShadowBlock variant="dropdownCard">
        <Typography variant="h5">
          Assignment
          <MTooltip
            text="Setting Entire Portfolio to yes will grant access for every case of the management for the user. 
                  Setting it to no will limit the user's access to only cases for the selected communities and addresses."
            customStyles={{ position: 'absolute', top: '-10px', right: '-30px' }}
          />
        </Typography>
        <CustomRadioGroup
          data={entirePortfolioData}
          value={hasEntirePortfolio}
          label="Entire Portfolio"
          tooltip={
            <MTooltip
              text="Need access to the entire portfolio? Email your request to credentials@clarkwalker.com. Please note that you will receive updates for all properties associated with the portfolio."
              customStyles={{ position: 'absolute', top: '-10px', right: '-30px' }}
            />
          }
          disabled
          radioButtonWidth="158px"
          onChange={setHasEntirePortfolio}
          defaultValue={getRadioGroupDefaultValue(entirePortfolioData)}
        />
      </ShadowBlock>
      {!hasEntirePortfolio && (
        <ShadowBlock variant="dropdownCard">
          <StyledTypography width={1} mb={1} variant="bodyL500">
            Communities & Addresses
            <StyledSpan style={{ alignSelf: 'flex-start' }}>
              <Asterisk />
            </StyledSpan>
          </StyledTypography>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Typography variant="h5">Communities</Typography>
            <MButton
              onClick={() => setCommunitiesModalOpen(true)}
              startIcon={<Add />}
              disabled={hasEntirePortfolio}
            >
              Add
            </MButton>
          </Stack>
          {selectedCommunities?.length ? (
            <Table
              columns={newUserCommunitiesColumnsWithDeleteCell}
              rows={selectedCommunities}
              total={selectedCommunities?.length}
              pagination={false}
              loading={false}
              onCheckedRow={() => null}
              isWithCheckbox={false}
              onDeleteRow={(index, original) => handleDeleteCommunityRow(original)}
            />
          ) : null}
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Typography variant="h5">Addresses</Typography>
            <MButton
              onClick={() => setAddressesModalOpen(true)}
              startIcon={<Add />}
              disabled={hasEntirePortfolio}
            >
              Add
            </MButton>
          </Stack>
          {selectedAddresses?.length ? (
            <Table
              columns={newUserAddressColumnsWithDeleteCell}
              rows={selectedAddresses}
              total={selectedAddresses?.length}
              pagination={false}
              loading={false}
              onCheckedRow={() => null}
              isWithCheckbox={false}
              onDeleteRow={(index, original) => handleDeleteAddressRow(original)}
            />
          ) : null}
          {communityAndAddressError && (
            <Typography color="error">{invalidCommunitiesAndAddressesMessage}</Typography>
          )}
        </ShadowBlock>
      )}
      {isCommunitiesModalOpen && (
        <SelfEnrollmentCommunitiesModal
          stateCode={statesToUseInSearch}
          loadData={loadCommunities}
          alreadyAdded={selectedCommunities}
          setSelected={setSelectedCommunities}
          communities={communities}
          managementId={management.id}
          title="Communities"
          buttonSecondaryText="Cancel"
          buttonPrimaryText="Add"
          onClose={() => setCommunitiesModalOpen(false)}
          isOpen
          inputPlaceholder="Search by Community Name"
        />
      )}
      {isAddressesModalOpen && (
        <SelfEnrollmentAddressesModal
          data-testid="SelfEnrollmentAddressesModal"
          stateCode={statesToUseInSearch}
          loadData={loadAddresses}
          alreadyAdded={selectedAddresses}
          setSelected={setSelectedAddresses}
          addresses={addresses}
          managementId={management.id}
          title="Addresses"
          buttonSecondaryText="Cancel"
          buttonPrimaryText="Add"
          onClose={() => setAddressesModalOpen(false)}
          isOpen
          inputPlaceholder="Search by Address"
        />
      )}
      <Stack direction="row" gap={3} flexWrap="wrap">
        <Box
          position="fixed"
          bottom={0}
          left={0}
          zIndex={10}
          width={1}
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
          backgroundColor="#fff"
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton
              variant="secondary"
              size="large"
              onClick={handleCancel}
              sx={{ width: '144px' }}
            >
              Cancel
            </MButton>
            <MButton size="large" variant="secondary" onClick={handleBack} sx={{ width: '144px' }}>
              Back
            </MButton>
            <MButton size="large" type="submit" onClick={handleSubmit} sx={{ width: '144px' }}>
              Create
            </MButton>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

SelfEnrollmentUserAssignment.propTypes = {
  userInfo: PropTypes.shape({
    states: PropTypes.arrayOf(PropTypes.shape({})),
    management: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  setUserInfo: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default SelfEnrollmentUserAssignment;
