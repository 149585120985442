import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  Typography,
  Stack,
  Container,
  Box,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { prop, sortBy, sum, reverse } from 'ramda';
import moment from 'moment';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';
import Form from '../../../atoms/Form';
import Dropdown from '../../../atoms/Dropdown';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import Table from '../../../atoms/Table/Table';
import {
  caseInformationBoxes,
  violationColumns,
  proceduralHistoryColumns,
  caseStatusColumns,
  hearingTypes,
  violationTypes,
  tenancyTypes,
  noticeRequestDropdownBlockToggler,
  OTHER_THAN,
  NON_PAYMENT,
  TABLE_ROWS_COUNT,
  TABLE_COLUMNS,
  proceduralHistoryColumnsEvictionMatter,
} from './CaseInformationNevada.constants';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import { ReactComponent as AddCur } from '../../../../assets/icons/AddCurColor.svg';
import { ReactComponent as Clock } from '../../../../assets/icons/Clock.svg';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import Calculator from '../../../molecules/Calculator/Calculator';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import useCases from '../../../../hooks/useCases';
import { isEmptyObjValues } from '../../../../utils/isEmptyObjValues';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup/CustomRadioGroup';
import {
  addCaseProceduralHistoryEntry,
  addCaseViolation,
  addStatusToCase,
  deleteLastNevadeCaseStatus,
  deleteNevadeCaseStatusById,
  getCaseInformation,
  getCaseProcedularHistoryEntries,
  getCaseViolations,
  getListOfAllStatusesForCase,
  getNevadaRelatedCases,
  getNevadaViolationTemplatesForCase,
  getStatusesForCase,
  removeCaseProceduralHistoryEntry,
  updateCaseProceduralHistoryEntry,
  updateEvictionRequest,
  updateNoticeRequest,
} from '../../../../store/slices/cases/casesNevadaSlice';
import {
  getAllAttorneys,
  getNoticeTypes,
  removeViolationFromCase,
  updateViolationInCase,
} from '../../../../store/slices/cases/casesGeneralSlice';
import formatDateAndTimeToISO from '../../../../utils/formatDateAndTimeToISO';
import { palette } from '../../../../theme/default';
import MTooltip from '../../../atoms/MTooltip';
import NotificationDialog from '../../../molecules/NotificationDialog/NotificationDialog';
import { convertObjectValuesToNumber } from '../../../../utils/convertObjectValuesToNumbers';
import notificationUtils from '../../../../utils/notificationUtils';
import CaseInformation from './CaseInformation';
import LoaderCover from '../../../atoms/LoaderCover';
import EmptyBlock from '../../../molecules/EmptyBlock';
import EditExpenseEntry from './EditExpenseEntry';
import EditTimeEntry from './EditTimeEntry';
import { getCourt } from '../../../../store/slices/courts/nevadaCourtsSlice';
import { getAbbriviation } from '../../../../store/slices/requests/nonPaymentRequest';
import AdditionalInformationDialog from './AdditionalInformationDialog';
import { apiClient } from '../../../../lib/apiClient';

const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
    '.MuiPaper-root': {
      boxShadow: '0px 3px 20px rgba(69, 80, 121, 0.13)',
      marginBottom: '16px',
      borderRadius: '24px',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiAccordionSummary-root': {
      padding: '0 32px',
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '32px 0',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '16px',
      height: '16px',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 32px 32px',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

const CaseInformationNevada = () => {
  const {
    state: { id, stateCode, caseType },
  } = useLocation();
  // eslint-disable-next-line
  const {
    getListOfAllStatusesForCase: statusOptions,
    relatedNevadaCases,
    violationTemplates,
  } = useSelector((state) => state.casesNevada);
  const { getAllAttorneys: attorneys, getNoticeTypes: noticeTypes } = useSelector(
    (state) => state.casesGeneral,
  );

  const { state } = useCases();
  const [violationErrorMessage, setViolationErrorMessage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [attorneyBilling, setAttorneyBilling] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [legalCategories, setLegalCategories] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [court, setCourt] = useState(null);
  const [errorNotification, setErrorNotification] = useState(false);
  const [additionalInfoDialog, setAdditionalInfoDialog] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: state,
  });

  const proceduralHistoryFields = useFieldArray({
    control: form.control,
    name: 'proceduralHistory',
  });
  const caseStatusFields = useFieldArray({
    control: form.control,
    name: 'caseStatus',
  });
  const violationsFields = useFieldArray({
    control: form.control,
    name: 'violations',
  });

  const getFloatFromInput = (input) => {
    const number = parseFloat(input);
    if (Number.isNaN(number)) return 0;
    return number;
  };

  const formatCalcValue = (value = 0) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const nonPaymentNoticeCalcFields = form.watch([
    'noticeRequest.nonPaymentNotice.currentAmountDue',
    'noticeRequest.nonPaymentNotice.pastAmountDue',
    'noticeRequest.nonPaymentNotice.lateFees',
  ]);

  useEffect(() => {
    if (id) {
      dispatch(getNevadaRelatedCases({ caseId: id }));
      dispatch(getNevadaViolationTemplatesForCase(id));
    }
  }, [id]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await apiClient.get(`/api/cases/attorneyActivities/time/categories`);
      const nextCategories = response?.data?.result?.map(({ id: categoryId, name }) => {
        return {
          id: categoryId,
          value: categoryId,
          label: name,
        };
      });

      setLegalCategories(nextCategories);
    };

    fetchCategories();
  }, [apiClient, id, setLegalCategories]);

  useEffect(() => {
    const fetchLastDates = async () => {
      const {
        data: { result },
      } = await apiClient.get(`/api/cases/${id}/lastInvoicing`);
      setLastDate(moment.max(Object.values(result).map((date) => moment(date))));
    };

    fetchLastDates();
  }, [id]);

  useEffect(() => {
    form.setValue(
      'noticeRequest.nonPaymentNotice.totalOwed',
      sum(nonPaymentNoticeCalcFields.map(getFloatFromInput)),
    );
  }, nonPaymentNoticeCalcFields);

  const nonPaymentEvictionCalcFields = form.watch([
    'damages.nonPaymentEviction.owedPeriodicRent',
    'damages.nonPaymentEviction.owedLateFee',
  ]);

  useEffect(() => {
    form.setValue(
      'damages.nonPaymentEviction.totalOwed',
      sum(nonPaymentEvictionCalcFields.map(getFloatFromInput)),
    );
  }, nonPaymentEvictionCalcFields);

  const [
    hasUnknownEntranceDate,
    tenancyType,
    exemptionFromStayReason,
    movingToRebutReason,
    isInDefault,
  ] = form.watch([
    'damages.otherThanEviction.hasUnknownEntranceDate',
    'damages.otherThanEviction.tenancyType',
    'damages.otherThanEviction.exemptionFromStayReason',
    'damages.otherThanEviction.movingToRebutReason',
    'damages.otherThanEviction.isInDefault',
  ]);

  useEffect(() => {
    if (!isInDefault) {
      form.setValue('damages.otherThanEviction.exemptionFromStayReason', '');
      form.setValue('damages.otherThanEviction.movingToRebutReason', '');
    }
  }, [isInDefault]);

  useEffect(() => {
    if (hasUnknownEntranceDate) form.setValue('damages.otherThanEviction.entranceDate', null);
  }, [hasUnknownEntranceDate]);

  useEffect(() => {
    if (tenancyType?.value !== 'Fixed term')
      form.setValue('damages.otherThanEviction.leaseEndDate', null);
  }, [tenancyType]);

  useEffect(() => {
    form.setValue(
      'damages.otherThanEviction.isExemptionFromStay',
      Boolean(exemptionFromStayReason),
    );
  }, [Boolean(exemptionFromStayReason)]);

  useEffect(() => {
    form.setValue('damages.otherThanEviction.isMovingToRebut', Boolean(movingToRebutReason));
  }, [Boolean(movingToRebutReason)]);

  const dropDownOptions = useMemo(() => {
    return {
      'noticeRequest.noticeRequestType': noticeRequestDropdownBlockToggler || [],
      'proceduralHistoryFields.assignedAttorney': attorneys || [],
      'proceduralHistoryFields.hearingType': hearingTypes || [],
      'caseStatusFields.caseStatus': statusOptions || [],
      'damages.otherThanEviction.violationType': violationTypes || [],
      'damages.otherThanEviction.tenancyType': tenancyTypes || [],
      'violationsFields.noticeType': noticeTypes || [],
    };
  }, [statusOptions, attorneys]);

  const [originalNoticeRequest, setOriginalNoticeRequest] = useState(null);
  const [originalEvictionRequest, setOriginalEvictionRequest] = useState(null);

  const getCaseInformationData = async () => {
    setIsLoader(true);
    try {
      const caseInformationReq = dispatch(getCaseInformation(id)).unwrap();
      const proceduralHistoryReq = dispatch(getCaseProcedularHistoryEntries(id)).unwrap();
      const violationsReq = dispatch(getCaseViolations(id)).unwrap();
      const statusesReq = dispatch(getStatusesForCase(id)).unwrap();

      const {
        result: { noticeRequest, evictionRequest, courtId, propertyType },
      } = await caseInformationReq;

      // maybe we can receive it from case info later
      const {
        result: { name: courtName },
      } = await dispatch(getCourt({ id: courtId })).unwrap();

      setCourt({ id: courtId, name: courtName, propertyType });
      setOriginalNoticeRequest(noticeRequest);
      setOriginalEvictionRequest(evictionRequest);

      const { result: proceduralHistory } = await proceduralHistoryReq;
      const { result: violations } = await violationsReq;
      const statuses = await statusesReq;

      const { noticeRequestType: noticeRequestTypeFromServer } = noticeRequest;

      const {
        otherThanNotice: { nuisanceDatetime },
      } = noticeRequest;

      const formValuesToSet = {
        ...form.getValues(),
        damages: {
          ...evictionRequest,
          otherThanEviction: {
            ...evictionRequest.otherThanEviction,
            violationType: evictionRequest.otherThanEviction.violationType
              ? violationTypes.find(
                  (v) => v.value === evictionRequest.otherThanEviction.violationType,
                )
              : null,
            tenancyType: evictionRequest.otherThanEviction.tenancyType
              ? tenancyTypes.find((v) => v.value === evictionRequest.otherThanEviction.tenancyType)
              : null,
          },
        },
        noticeRequest: {
          ...noticeRequest,
          noticeRequestType: noticeRequestDropdownBlockToggler.find(
            ({ value }) => value === noticeRequestTypeFromServer,
          ),
          otherThanNotice: {
            ...noticeRequest.otherThanNotice,
            nuisanceDate: nuisanceDatetime,
            nuisanceTime: nuisanceDatetime,
          },
        },
      };

      delete formValuesToSet.noticeRequest.otherThanNotice.nuisanceDatetime;

      form.reset(formValuesToSet);
      proceduralHistoryFields.replace(proceduralHistory);
      violationsFields.replace(violations);
      caseStatusFields.replace(reverse(sortBy(prop('caseStatusId'), statuses)));
    } catch (e) {
      // todo: Add Alert Component
      alert('---------------', e);
    } finally {
      setIsLoader(false);
    }
  };

  const loadOptionsForDropdowns = () => {
    dispatch(getListOfAllStatusesForCase());
    dispatch(getNoticeTypes({ stateCode }));
    dispatch(getAllAttorneys(id));
  };

  useEffect(() => {
    if (id) {
      getCaseInformationData();
      loadOptionsForDropdowns();
    }
  }, [id]);

  useEffect(() => {
    form.trigger();
  }, [
    form.watch('damages.courtFees.attorneyFeeSummaryEviction'),
    form.watch('damages.courtFees.motionToRescindEviction'),
    form.watch('damages.courtFees.courtFilingFee'),
    form.watch('damages.courtFees.constableFee'),
    form.watch('noticeRequest.associatedFees.nonPaymentNoticeFee'),
    form.watch('noticeRequest.associatedFees.otherThanNoticeFee'),
  ]);

  const dismissedByJudge = form.watch('proceduralHistoryFields.dismissedByJudge');

  useEffect(() => {
    if (dismissedByJudge) {
      const [data] = form.watch('proceduralHistory');
      if (data) {
        form.setValue(
          'proceduralHistoryFields.assignedAttorney',
          attorneys.find((item) => item.label === data.assignedAttorney),
        );
        form.setValue(
          'proceduralHistoryFields.hearingType',
          hearingTypes.find((item) => item.label === data.hearingType),
        );
        form.setValue('proceduralHistoryFields.courtDate', data.courtDateTime);
        form.setValue('proceduralHistoryFields.courtTime', data.courtDateTime);
      }
    } else {
      form.setValue('proceduralHistoryFields.assignedAttorney', '');
      form.setValue('proceduralHistoryFields.hearingType', '');
      form.setValue('proceduralHistoryFields.courtDate', '');
      form.setValue('proceduralHistoryFields.courtTime', '');
    }
  }, [dismissedByJudge, attorneys]);

  const handleCloseViolationErrorMessage = () => {
    setViolationErrorMessage(false);
  };

  const addCaseStatus = async () => {
    const values = form.getValues('caseStatusFields');

    setIsLoader(true);
    try {
      await dispatch(
        addStatusToCase({
          id,
          body: {
            caseId: id,
            caseStatus: values.caseStatus?.value,
            comment: values.comment,
          },
        }),
      ).unwrap();
      notificationUtils.success('Added successfully');
      await getCaseInformationData();
      ['caseStatus.caseStatus', 'caseStatus.comment'].forEach((inputName) =>
        form.setValue(inputName, ''),
      );
    } catch (e) {
      // todo: Add Alert Component
      notificationUtils.error(e?.errorMessage || 'Something went wrong');
    }
    setIsLoader(false);
  };

  const checkIsTemplateAvailable = (noticeType) => {
    const courtAbbriviation = getAbbriviation(court?.name);
    if (!violationTemplates.length) {
      setErrorNotification(true);
      return false;
    }

    switch (noticeType) {
      case '7 Day Non-Payment':
      case '30 Day Non-Payment': {
        if (['LVT', 'NLV', 'HEN', 'REN', 'SPARKS'].includes(courtAbbriviation)) {
          return true;
        }
        setErrorNotification(true);
        return false;
      }

      case '14 Day Non-Payment': {
        if (['LVT', 'NLV'].includes(courtAbbriviation)) {
          return true;
        }
        setErrorNotification(true);
        return false;
      }

      case '3 Day Nuisance': {
        if (
          ['LVT', 'NLV', 'HEN', 'REN', 'SPARKS'].includes(courtAbbriviation) &&
          court?.propertyType !== 'Mobile Home Park'
        ) {
          return true;
        }
        setErrorNotification(true);
        return false;
      }
      case '5 Day Notice Of Lease Violation':
      case '5 Day Notice Unlawful Detainer':
      case '30 Day Non-Renewal Notice':
      case '60 Day Non-Renewal Notice': {
        if (['LVT', 'NLV', 'HEN', 'REN', 'SPARKS'].includes(courtAbbriviation)) {
          return true;
        }
        setErrorNotification(true);
        return false;
      }

      case '3 Day Nuisance MHP': {
        // check abbriviations
        if (courtAbbriviation === 'LVT' && court?.propertyType === 'Mobile Home Park') {
          return true;
        }
        setErrorNotification(true);
        return false;
      }

      case '5 Day Notice Of Abandonment':
      case '10 Day Notice to Quit (Demand to Vacate)':
      case '5 Day Notice To Quit': {
        if (courtAbbriviation === 'LVT') {
          return true;
        }
        setErrorNotification(true);
        return false;
      }
      case '5 Day UNCURABLE Notice Lease Violation': {
        if (['LVT', 'NLV', 'HEN'].includes(courtAbbriviation)) {
          return true;
        }
        setErrorNotification(true);
        return false;
      }

      case 'Notice To Surrender': {
        if (['LVT', 'HEN', 'REN', 'SPARKS'].includes(courtAbbriviation)) {
          return true;
        }
        setErrorNotification(true);
        return false;
      }

      case '24 Hour Notice of Intent to Enter':
      case '30 Day After Lockout Abandonment Notice':
      case '30 Day Skip Notice':
      case '30 Day Skip Notice - Garage':
      case '45 Day Notice of Rent Increase': {
        return true;
      }

      default: {
        setErrorNotification(true);
        return false;
      }
    }
  };

  const checkFillingFields = ({ name, inputBoxes }) => {
    const inputBoxesFields = typeof inputBoxes === 'function' ? inputBoxes() : inputBoxes;

    const fieldInputNames = inputBoxesFields.map((item) => item.name);
    const watching = form.watch(name);
    const mandatoryFields = inputBoxesFields.filter((i) => i.isMandatory).map((i) => i.name);
    const checkIfFieldsNotEmpty = (fields) => {
      for (let i = 0; i < fields.length; i += 1) {
        if (!fields[i]) return false;
      }
      return true;
    };
    const areMandatoryFieldsFilled = mandatoryFields.length
      ? checkIfFieldsNotEmpty(form.watch(mandatoryFields))
      : true;

    switch (name) {
      case 'proceduralHistory': {
        const columns =
          caseType === 'Eviction Matter'
            ? [...proceduralHistoryColumnsEvictionMatter]
            : [...proceduralHistoryColumns];
        columns[0] = { ...columns[0], options: attorneys };
        return {
          watching,
          isValid: areMandatoryFieldsFilled,
          add: async () => {
            const values = form.getValues('proceduralHistoryFields');
            setIsLoader(true);
            try {
              await dispatch(
                addCaseProceduralHistoryEntry({
                  id,
                  body: {
                    caseId: id,
                    attorneyUserId: values?.assignedAttorney?.id,
                    hearingType: values.hearingType?.value,
                    courtDateTime: formatDateAndTimeToISO(values.courtDate, values.courtTime),
                    hasDismissedByJudge: Boolean(values.dismissedByJudge),
                    comment: values.comment || '',
                  },
                }),
              ).unwrap();
              notificationUtils.success('Added successfully');
              await getCaseInformationData();
              fieldInputNames.forEach((inputName) => form.setValue(inputName, ''));
            } catch (e) {
              notificationUtils.error(e?.errorMessage || 'Something went wrong');
            }
            setIsLoader(false);
          },
          update: async (row, { assignedAttorney, proceduralHistoryEntryId }) => {
            if (assignedAttorney?.value) {
              setIsLoader(true);
              try {
                await dispatch(
                  updateCaseProceduralHistoryEntry({
                    id,
                    proceduralHistoryEntryId,
                    body: {
                      caseId: id,
                      attorneyUserId: assignedAttorney.value,
                      caseProceduralHistoryEntryId: proceduralHistoryEntryId,
                    },
                  }),
                ).unwrap();
                notificationUtils.success('Updated successfully');
                await getCaseInformationData();
              } catch (e) {
                notificationUtils.error(e?.errorMessage || 'Something went wrong');
              }
              setIsLoader(false);
            }
          },
          deleteRow: async (index) => {
            const entry = proceduralHistoryFields.fields[index];
            setIsLoader(true);
            try {
              await dispatch(
                removeCaseProceduralHistoryEntry({
                  proceduralEntryId: entry.proceduralHistoryEntryId,
                  id,
                }),
              ).unwrap();
              notificationUtils.success('Deleted successfully');
              await getCaseInformationData();
            } catch (e) {
              notificationUtils.error(e?.errorMessage || 'Something went wrong');
            }
            setIsLoader(false);
          },
          rows: proceduralHistoryFields.fields,
          columns,
        };
      }
      case 'violations':
        return {
          watching,
          isValid: areMandatoryFieldsFilled,
          // maybe we need to disable it if !deliveredBy and no templates from backend
          add: async (ev, skipCheck, templateId, fields, onFinish) => {
            const values = form.getValues('violationsFields');
            const noticeType = values.noticeType.value;
            // if template is available then we show either additional dialog or add violation
            // if template isn't available we show error notification

            // check of deliveredBy is a temporary undo of new logic
            const isTemplate = values.deliveredBy
              ? true
              : checkIsTemplateAvailable(noticeType) && violationTemplates.length !== 0;
            const skipAllChecks = skipCheck || values.deliveredBy;

            const isTemplateDialogToShow = skipAllChecks ? false : isTemplate;

            if (isTemplateDialogToShow) {
              setAdditionalInfoDialog(true);
            } else if (isTemplate) {
              setIsLoader(true);
              try {
                await dispatch(
                  addCaseViolation({
                    id,
                    body: {
                      ...values,
                      signedDate: values.signedDate || null,
                      caseId: id,
                      noticeType: values.noticeType.value,
                      templateId,
                      ...(!!fields && { templateFields: fields }),
                    },
                  }),
                ).unwrap();
                notificationUtils.success('Added successfully');
                await getCaseInformationData();
                onFinish?.();
                fieldInputNames.forEach((inputName) => form.setValue(inputName, ''));
              } catch (e) {
                setViolationErrorMessage(e);
              }
              setIsLoader(false);
            }
          },
          update: async (index, values) => {
            setIsLoader(true);
            try {
              await dispatch(
                updateViolationInCase({
                  id,
                  violationId: values.caseViolationId,
                  body: {
                    caseId: id,
                    caseViolationId: values.caseViolationId,
                    description: values.description || '',
                  },
                }),
              ).unwrap();
              notificationUtils.success('Updated successfully');
              await getCaseInformationData();
            } catch (e) {
              notificationUtils.error(e?.errorMessage || 'Something went wrong');
            }
            setIsLoader(false);
          },
          deleteRow: async (index) => {
            const entry = violationsFields.fields[index];
            setIsLoader(true);
            try {
              await dispatch(
                removeViolationFromCase({
                  violationId: entry.caseViolationId,
                  id,
                }),
              ).unwrap();
              notificationUtils.success('Deleted successfully');
              await getCaseInformationData();
            } catch (e) {
              notificationUtils.error(e?.errorMessage || 'Something went wrong');
            }
            setIsLoader(false);
          },
          rows: violationsFields.fields,
          columns: violationColumns,
        };
      case 'caseStatus':
        // eslint-disable-next-line
        return {
          watching,
          isValid: areMandatoryFieldsFilled,
          add: addCaseStatus,
          deleteRow: async (caseStatusId) => {
            dispatch(
              caseStatusId
                ? deleteNevadeCaseStatusById({ id, caseStatusId })
                : deleteLastNevadeCaseStatus({ id }),
            )
              .unwrap()
              .then(() => {
                getCaseInformationData();
                notificationUtils.success(
                  caseStatusId ? 'Deleted successfully' : 'Reverted successfully',
                );
              })
              .catch(() => {});
          },
          rows: caseStatusFields.fields,
          columns: caseStatusColumns,
        };
      case 'noticeRequest&damages':
        return {
          updateForm: async () => {
            await form.trigger();
            const { errors } = form.formState;
            const visibleBlock =
              form.getValues('noticeRequest.noticeRequestType')?.value || OTHER_THAN;

            if (
              errors?.noticeRequest?.associatedFees?.nonPaymentNoticeFee?.message ||
              errors?.noticeRequest?.associatedFees?.otherThanNoticeFee?.message ||
              errors?.damages?.courtFees?.attorneyFeeSummaryEviction?.message ||
              errors?.damages?.courtFees?.courtFilingFee?.message ||
              errors?.damages?.courtFees?.constableFee?.message ||
              errors?.damages?.courtFees?.motionToRescindEviction?.message
            ) {
              return;
            }

            const valuesnoticeRequest = form.getValues('noticeRequest');
            const values = form.getValues('damages');
            const { nuisanceDate, nuisanceTime, ...rest } = valuesnoticeRequest.otherThanNotice;

            let nuisancedateValue = null;

            if (nuisanceDate && nuisanceTime) {
              nuisancedateValue = formatDateAndTimeToISO(nuisanceDate, nuisanceTime);
            }
            if (!nuisanceDate && nuisanceTime) {
              nuisancedateValue = formatDateAndTimeToISO(moment(), nuisanceTime);
            }
            if (nuisanceDate && !nuisanceTime) {
              nuisancedateValue = formatDateAndTimeToISO(nuisanceDate, moment());
            }

            const checkValuesSentTypes = {
              ...valuesnoticeRequest,
              nonPaymentNotice: {
                ...convertObjectValuesToNumber({
                  currentAmountDue: valuesnoticeRequest.nonPaymentNotice.currentAmountDue || null,
                  pastAmountDue: valuesnoticeRequest.nonPaymentNotice.pastAmountDue || null,
                  lateFees: valuesnoticeRequest.nonPaymentNotice.lateFees || null,
                  totalOwed: valuesnoticeRequest.nonPaymentNotice.totalOwed || null,
                  lastPaymentAmount: valuesnoticeRequest.nonPaymentNotice.lastPaymentAmount || null,
                }),
                lastPaymentDate: valuesnoticeRequest.nonPaymentNotice.lastPaymentDate || null,
              },
            };

            const valuesToBeUpdated = {
              ...checkValuesSentTypes,
              noticeRequestType: checkValuesSentTypes.noticeRequestType.value,
              otherThanNotice: {
                ...rest,
                nuisanceDatetime: nuisancedateValue,
              },
            };

            if (visibleBlock === OTHER_THAN) {
              valuesToBeUpdated.nonPaymentNotice = originalNoticeRequest.nonPaymentNotice;
            }

            if (visibleBlock === NON_PAYMENT) {
              valuesToBeUpdated.otherThanNotice = originalNoticeRequest.otherThanNotice;
            }

            setIsLoader(true);
            try {
              await dispatch(
                updateNoticeRequest({
                  id,
                  body: {
                    ...valuesToBeUpdated,
                    associatedFees: convertObjectValuesToNumber(valuesToBeUpdated.associatedFees),
                    caseId: id,
                  },
                }),
              ).unwrap();

              const { udDateServed } = values.otherThanEviction;
              const udDate = udDateServed ? moment(udDateServed, moment.ISO_8601) : null;

              const valueToSend = {
                id,
                body: {
                  ...values,
                  caseId: id,
                  otherThanEviction: {
                    ...values.otherThanEviction,
                    udDateServed: udDate,
                    tenancyType: values.otherThanEviction?.tenancyType?.value,
                    violationType: values.otherThanEviction?.violationType?.value,
                  },
                  nonPaymentEviction: {
                    ...values.nonPaymentEviction,
                    rentBecameDelinquentOn:
                      values?.nonPaymentEviction?.rentBecameDelinquentOn || null,
                    periodicRent: values?.nonPaymentEviction?.periodicRent || null,
                    rentDeposit: values?.nonPaymentEviction?.rentDeposit || null,
                    securityDeposit: values?.nonPaymentEviction?.securityDeposit || null,
                    cleaningDeposit: values?.nonPaymentEviction?.cleaningDeposit || null,
                    owedPeriodicRent: values?.nonPaymentEviction?.owedPeriodicRent || null,
                    owedLateFee: values?.nonPaymentEviction?.owedLateFee || null,
                    lateFeeAssessed: values?.nonPaymentEviction?.lateFeeAssessed || null,
                  },
                },
              };
              if (visibleBlock === OTHER_THAN) {
                valueToSend.body.nonPaymentEviction = originalEvictionRequest.nonPaymentEviction;
              }

              if (visibleBlock === NON_PAYMENT) {
                valueToSend.body.otherThanEviction = originalEvictionRequest.otherThanEviction;
              }

              await dispatch(updateEvictionRequest(valueToSend)).unwrap();
              notificationUtils.success('Updated successfully');
              await getCaseInformationData();
            } catch (e) {
              notificationUtils.error(e?.errorMessage || 'Something went wrong');
            }
            setIsLoader(false);
          },
        };
      default:
        return {
          watching,
        };
    }
  };

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  const onUpdateData = useCallback(() => {}, []);

  const onDeleteRow = useCallback(
    (rowId) => {
      // TODO: there should be activityId, but it should be fixed on the backend side first
      const { civilMatterId } = attorneyBilling.items[rowId];
      setRowToDelete(civilMatterId);
    },
    [attorneyBilling],
  );

  const handleFetchDataPage = useCallback((currentPage = 1) => {
    const fetcher = async () => {
      const reqBody = {
        caseId: id,
      };
      setTableLoading(true);
      const filterResponse = await apiClient.post(`/api/cases/${id}/attorneyActivities/filter`, {
        currentPage,
        pageSize: TABLE_ROWS_COUNT,
        ...reqBody,
      });

      const totalResponse = await apiClient.post(
        `/api/cases/${id}/attorneyActivities/total`,
        reqBody,
      );

      const {
        data: { result },
      } = filterResponse;
      const {
        quantityTotal,
        nonBillableTotal,
        billableTotal,
        nonBillableQuantityTotal,
        billableQuantityTotal,
      } = totalResponse.data.result;

      if (result?.items?.length) {
        result.items.push({
          isTotalRow: true,
          civilMatterId: null, // TODO: there should be activityId, but it should be fixed on the backend side first
          type: null,
          quantity: quantityTotal,
          description: null,
          rate: null,
          nonBillable: nonBillableTotal,
          billable: billableTotal,
          date: null,
          user: null,
          invoiceStatus: null,
          nonBillableQuantityTotal,
          billableQuantityTotal,
        });
      }
      setAttorneyBilling(result);
      setTableLoading(false);
    };
    fetcher();
  }, []);

  useEffect(() => {
    handleFetchDataPage(1);
  }, []);

  const handleConfirmDeleteTableRow = useCallback(async () => {
    try {
      await apiClient.delete(`/api/cases/${id}/attorneyActivities/${rowToDelete}`);
      setRowToDelete(null);
      handleFetchDataPage();
      notificationUtils.success('Deleted successfully');
    } catch {
      notificationUtils.error('Failed, try again later');
    }
  }, [rowToDelete]);

  const handleCancelDeleteTableRow = useCallback(() => {
    setRowToDelete(null);
  }, []);

  const handleOpenModalOnTableEditClick = useCallback(
    (_, original) => {
      setModalData({
        id,
        title: original.type,
        civilMatterId: original.civilMatterId, // TODO: there should be activityId, but it should be fixed on the backend side first
        isEdit: true,
      });
    },
    [attorneyBilling?.items],
  );

  const handleNewTimeEntry = useCallback(() => {
    setModalData({ title: 'Time', isEdit: false });
  }, []);

  const handleNewExpense = useCallback(() => {
    setModalData({ title: 'Expense', isEdit: false });
  }, []);

  const onCloseModal = () => {
    setModalData(null);
  };

  const handleExpenseEntryConfirm = async (values) => {
    const { firmUser, date, description, amount, isNonBillable, isEdit, category } = values;

    if (isEdit) {
      // TODO: there should be activityId, but it should be fixed on the backend side first
      await apiClient
        .put(`/api/cases/${id}/attorneyActivities/expense/${values?.civilMatterId}`, {
          caseId: id,
          userId: firmUser.id,
          date: moment(date, moment.ISO_8601),
          description,
          amount: +amount,
          isNonBillable,
          category: category.value,
        })
        .then(() => {
          handleFetchDataPage();
          notificationUtils.success('Updated successfully');
          setModalData(null);
        });
    } else {
      await apiClient
        .post(`/api/cases/${id}/attorneyActivities/expense`, {
          caseId: id,
          userId: firmUser.id,
          date: moment(date, moment.ISO_8601),
          description,
          amount: +amount,
          isNonBillable,
          category: category.value,
        })
        .then(() => {
          handleFetchDataPage();
          notificationUtils.success('Added successfully');
          setModalData(null);
        });
    }
  };

  const handleTimeEntryConfirm = useCallback(async (values) => {
    const { firmUser, date, description, rate, duration, isNonBillable, isEdit, category } = values;

    if (isEdit) {
      const params = {
        caseId: id,
        civilMatterId: values?.civilMatterId, // TODO: there should be activityId, but it should be fixed on the backend side first
        userId: firmUser.id,
        date,
        description,
        rate: +rate,
        duration: +duration,
        isNonBillable,
        categoryId: category.value,
      };
      // TODO: there should be activityId, but it should be fixed on the backend side first
      await apiClient
        .put(`/api/cases/${id}/attorneyActivities/time/${values?.civilMatterId}`, params)
        .then(() => {
          handleFetchDataPage();
          notificationUtils.success('Updated successfully');
          setModalData(null);
        });
    } else {
      const params = {
        caseId: id,
        userId: firmUser.id,
        date: moment(date, moment.ISO_8601),
        description,
        rate: +rate,
        duration: +duration,
        isNonBillable,
        categoryId: category.value,
      };
      await apiClient.post(`/api/cases/${id}/attorneyActivities/time`, params).then(() => {
        handleFetchDataPage();
        notificationUtils.success('Added successfully');
        setModalData(null);
      });
    }
  }, []);
  const addViolation = (templateId) => (fields, onFinish) => {
    const violationBoxes = caseInformationBoxes.find((item) => item.name === 'violations');
    const methods = checkFillingFields({
      name: 'violations',
      inputBoxes: violationBoxes.inputBoxes,
    });
    methods.add(null, true, templateId, fields, onFinish);
  };

  const noticeType = useMemo(() => {
    return form.getValues('violationsFields')?.noticeType?.value || '';
  }, [form.getValues('violationsFields')]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      {isLoader && <LoaderCover isFixed />}
      <CaseInformation onUpdate={getCaseInformationData} />
      <Form form={form}>
        {caseInformationBoxes &&
          caseInformationBoxes.map((data) => {
            const { watching, isValid, columns, rows, add, update, deleteRow, updateForm } =
              checkFillingFields(data);

            const inputs =
              typeof data.inputBoxes === 'function'
                ? data.inputBoxes(form.watch('noticeRequest.noticeRequestType')?.value, caseType)
                : data.inputBoxes;

            return (
              <StyledContainer
                key={data.boxTitle}
                maxWidth={false}
                disableGutters
                sx={{
                  mx: 'auto',
                  mt: 4,
                  px: 4,
                  maxWidth: 1542,
                  width: 1,
                  position: 'relative',
                }}
              >
                <StyledAccordion defaultExpanded>
                  <AccordionSummary expandIcon={<ArrowDown />}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="h5">{data.boxTitle}</Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack
                      direction="row"
                      alignItems="center"
                      columnGap={3}
                      rowGap={2}
                      flexWrap="wrap"
                    >
                      {inputs.map((item) => {
                        if (item.isDropdown) {
                          return (
                            <Field
                              key={item.name}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              render={({ field, onCustomChange }) => (
                                <Dropdown
                                  value={field.value}
                                  isAddDisabled={isEmptyObjValues(watching)}
                                  isSearchable
                                  label={item.label}
                                  width={item.width}
                                  isColumn
                                  placeholder={item.placeholder}
                                  options={dropDownOptions[item.name]}
                                  onChange={onCustomChange(field.onChange)}
                                  isDisabled={item.isDisabled}
                                  isMandatory={item.isMandatory}
                                />
                              )}
                            />
                          );
                        }

                        if (item.isCheckbox) {
                          return (
                            <Field
                              key={item.name}
                              name={item.name}
                              render={({ field, onCustomChange }) => (
                                <CheckboxWithLabel
                                  label={item.label}
                                  defaultValue={field.value}
                                  onChange={onCustomChange(field.onChange)}
                                  marginTop="25px"
                                  isChecked={field.value}
                                />
                              )}
                            />
                          );
                        }

                        if (item.isSpace) {
                          return <div style={{ width: '100%' }} key={item.label} />;
                        }

                        if (item.isGrouppedCheckboxes) {
                          return (
                            <Stack position="relative">
                              <Typography
                                fontSize="16px"
                                fontWeight={600}
                                color={palette.text.secondary}
                                mb={0.5}
                              >
                                {item.label}
                              </Typography>
                              <Stack
                                flex
                                direction="row"
                                gap={2}
                                height="48px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                {item.fields.map((checkoxItem) => (
                                  <Field
                                    key={checkoxItem.name}
                                    name={checkoxItem.name}
                                    render={({ field, onCustomChange }) => (
                                      <CheckboxWithLabel
                                        label={checkoxItem.label}
                                        isChecked={field.value}
                                        defaultValue={field.value}
                                        onChange={onCustomChange(field.onChange)}
                                      />
                                    )}
                                  />
                                ))}
                              </Stack>
                            </Stack>
                          );
                        }

                        if (item.isSubHeading) {
                          return (
                            <StyledTypography width={1} mb={1} variant="bodyL500" key={item.label}>
                              {item.label}
                            </StyledTypography>
                          );
                        }

                        if (item.isLabelGroupHeading) {
                          return (
                            <StyledTypography
                              width={1}
                              style={{ marginTop: 0 }}
                              mb={1}
                              variant="bodyM"
                              key={item.label}
                            >
                              {item.label}
                            </StyledTypography>
                          );
                        }

                        if (item.isDatePicker) {
                          return (
                            <div style={{ width: item.wrapperWidth || 'auto' }} key={item.label}>
                              <Field
                                name={item.name}
                                render={({ field, onCustomChange }) => (
                                  <DateOrTimePicker
                                    name={item.name}
                                    label={item.label}
                                    isDate
                                    Svg={Calender}
                                    selected={field.value}
                                    width={item.width}
                                    height={item.height}
                                    isMandatory={item.isMandatory}
                                    disabled={
                                      item.getDisabled ? item.getDisabled(form) : item.isDisabled
                                    }
                                    placeholder={item.placeholder}
                                    onChange={onCustomChange(field.onChange)}
                                    setSelectedTimeOrDate={onCustomChange(field.onChange)}
                                  />
                                )}
                              />
                            </div>
                          );
                        }

                        if (item.isTimePicker) {
                          return (
                            <div style={{ width: item.wrapperWidth || 'auto' }} key={item.label}>
                              <Field
                                name={item.name}
                                render={({ field, onCustomChange }) => (
                                  <DateOrTimePicker
                                    name={item.name}
                                    label={item.label}
                                    isMandatory={item.isMandatory}
                                    isTime
                                    Svg={Clock}
                                    selected={field.value}
                                    onChange={onCustomChange(field.onChange)}
                                    setSelectedTimeOrDate={onCustomChange(field.onChange)}
                                    width={item.width}
                                    height={item.height}
                                    placeholder={item.placeholder}
                                  />
                                )}
                              />
                            </div>
                          );
                        }

                        if (item.isCalculator) {
                          return (
                            <Calculator
                              value={formatCalcValue(form.watch(item.name))}
                              key={item.label}
                              label={item.label}
                              placeholder={item.placeholder}
                              subcomponents={item.addedBoxes}
                              tooltip={item?.tooltip && <MTooltip text={item.tooltip} />}
                            />
                          );
                        }

                        if (item.isStub) {
                          return <Stack width={item.width} />;
                        }

                        if (item.isRadioButtons) {
                          return (
                            <Field
                              key={item.name}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              render={({ field, onCustomChange }) => (
                                <CustomRadioGroup
                                  data={item.data}
                                  value={field.value}
                                  label={item.label}
                                  width={item.width}
                                  defaultValue={getRadioGroupDefaultValue(item.data)}
                                  radioButtonWidth={item.radioButtonWidth}
                                  onChange={onCustomChange(field.onChange)}
                                  disabled={
                                    item.getDisabled ? item.getDisabled(form) : item.isDisabled
                                  }
                                />
                              )}
                            />
                          );
                        }

                        if (item.isRelatedCasesTable) {
                          return relatedNevadaCases ? (
                            <Table columns={item.columns} rows={relatedNevadaCases} />
                          ) : (
                            <Typography variant="bodyL500" textAlign="center">
                              Currently there is no Related Cases.
                            </Typography>
                          );
                        }

                        return (
                          <Field
                            name={item.name}
                            key={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, error }) => {
                              return (
                                <EditInputWithLabel
                                  error={error}
                                  isMandatory={item.isMandatory}
                                  type="text"
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  width={item.width}
                                  name={item.name}
                                  value={field.value}
                                  onChange={field.onChange}
                                  tooltip={
                                    item?.tooltipText &&
                                    stateCode === 'NV' && <MTooltip text={item.tooltipText} />
                                  }
                                  isDisabled={
                                    item.getDisabled ? item.getDisabled(form) : item.isDisabled
                                  }
                                />
                              );
                            }}
                          />
                        );
                      })}

                      {data.withAdd && (
                        <>
                          <MButton
                            disabled={!isValid || isLoader}
                            startIcon={<Add />}
                            sx={{
                              ml: 'auto',
                              mt: '28px',
                            }}
                            onClick={add}
                          >
                            Add
                          </MButton>
                          <Table
                            columns={columns}
                            loading={false}
                            overflowHidden={false}
                            rows={rows}
                            onUpdateData={update}
                            onDeleteRow={deleteRow}
                          />
                        </>
                      )}

                      {data.withUpdate &&
                        ((caseType === 'Eviction Matter' &&
                          data.name !== 'noticeRequest&damages') ||
                          caseType !== 'Eviction Matter') && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              width: '100%',
                              paddingTop: '36px',
                            }}
                          >
                            <MButton
                              size="large"
                              sx={{ width: '144px' }}
                              disabled={isLoader}
                              onClick={updateForm}
                            >
                              Update
                            </MButton>
                          </Box>
                        )}
                    </Stack>

                    {data.name === 'noticeRequest&damages' && caseType === 'Eviction Matter' && (
                      <>
                        <Stack
                          flex
                          direction="row"
                          justifyContent="space-between"
                          columnGap={4}
                          mt={3}
                          mb={3}
                        >
                          <Typography variant="h5">Attorney Billing</Typography>
                          <Stack flex direction="row" columnGap={3}>
                            <Box>
                              <MButton
                                onClick={handleNewTimeEntry}
                                startIcon={<AddCur />}
                                variant="secondary"
                                // disabled={!canCurrentUserAddActivities}
                              >
                                New Time Entry
                              </MButton>
                            </Box>
                            <Box>
                              <MButton
                                onClick={handleNewExpense}
                                startIcon={<AddCur />}
                                variant="secondary"
                                // disabled={!canCurrentUserAddActivities}
                              >
                                New Expense
                              </MButton>
                            </Box>
                          </Stack>
                        </Stack>
                        {attorneyBilling?.items?.length ? (
                          <Table
                            hasTotalRow
                            columns={TABLE_COLUMNS}
                            onUpdateData={onUpdateData}
                            rows={attorneyBilling?.items}
                            onDeleteRow={onDeleteRow}
                            onModalOpenWhenClickEdit={handleOpenModalOnTableEditClick}
                            total={attorneyBilling?.totalRowsCount}
                            isPagination={attorneyBilling?.totalPages > 1}
                            onNextPage={handleFetchDataPage}
                            onPreviousPage={handleFetchDataPage}
                            onGotoPage={handleFetchDataPage}
                            pageSize={TABLE_ROWS_COUNT}
                            loading={tableLoading}
                          />
                        ) : (
                          <Stack alignItems="center" paddingTop="50px">
                            <EmptyBlock title="No records" />
                          </Stack>
                        )}
                        {modalData?.title === 'Expense' && (
                          <EditExpenseEntry
                            modalData={modalData}
                            onClose={onCloseModal}
                            firmUsersList={attorneys}
                            onConfirm={handleExpenseEntryConfirm}
                            isOpen={modalData?.title === 'Expense'}
                            minDate={lastDate}
                          />
                        )}
                        {modalData?.title === 'Time' && (
                          <EditTimeEntry
                            modalData={modalData}
                            onClose={onCloseModal}
                            firmUsersList={attorneys}
                            categoriesList={legalCategories}
                            onConfirm={handleTimeEntryConfirm}
                            isOpen={modalData?.title === 'Time'}
                            minDate={lastDate}
                          />
                        )}
                        <NotificationDialog
                          title="Confirm delete"
                          desc="Are you sure you want to delete activity?"
                          type="alert"
                          buttonSecondaryText="Cancel"
                          buttonPrimaryText="Delete"
                          onClose={handleCancelDeleteTableRow}
                          onConfirm={handleConfirmDeleteTableRow}
                          isOpen={rowToDelete}
                        />

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            paddingTop: '36px',
                          }}
                        >
                          <MButton
                            size="large"
                            sx={{ width: '144px' }}
                            disabled={isLoader}
                            onClick={updateForm}
                          >
                            Update
                          </MButton>
                        </Box>
                      </>
                    )}
                  </AccordionDetails>
                </StyledAccordion>
              </StyledContainer>
            );
          })}
      </Form>
      {additionalInfoDialog && (
        <AdditionalInformationDialog
          isOpen={additionalInfoDialog}
          onClose={() => setAdditionalInfoDialog(false)}
          court={court || {}}
          addViolation={addViolation}
          noticeType={noticeType}
          templates={violationTemplates}
          caseId={id}
        />
      )}
      {/* TODO check according to the design */}
      {errorNotification && (
        <NotificationDialog
          title="Error"
          desc={<>Selected notice type is not applicable in this case</>}
          type="warning"
          buttonSecondaryText=""
          buttonPrimaryText="Ok"
          onClose={() => setErrorNotification(false)}
          onConfirm={() => setErrorNotification(false)}
          isOpen={!!errorNotification}
        />
      )}
      <NotificationDialog
        title="Error"
        desc={violationErrorMessage}
        type="warning"
        buttonSecondaryText=""
        buttonPrimaryText="Ok"
        onClose={handleCloseViolationErrorMessage}
        onConfirm={handleCloseViolationErrorMessage}
        isOpen={!!violationErrorMessage}
      />
    </Container>
  );
};

export default CaseInformationNevada;
