/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getQuickBookConnectionStatus = createAsyncThunk(
  'generateInvoice/getQuickBookConnectionStatus',
  async () => {
    const { data } = await apiClient.get(`/api/invoices/connection/status`);

    return data;
  },
);

export const getQuickBookConnectionUrl = createAsyncThunk(
  'generateInvoice/getQuickBookConnectionUrl',
  async (redirectUrl) => {
    const {
      data: { result },
    } = await apiClient.get(`/api/invoices/connection/url?RedirectUrl=${redirectUrl}`);
    window.open(result, '_self');
  },
);

export const updateQuickBookConnection = createAsyncThunk(
  'generateInvoice/updateQuickBookConnection',
  async (params) => {
    const { data } = await apiClient.put(`/api/invoices/connection`, params);

    return data;
  },
);

export const getInvoiceCreationResult = createAsyncThunk(
  'generateInvoice/getInvoiceCreationResult',
  async (id) => {
    const { data } = await apiClient.get(`/api/invoices/results/${id}`);
    return data;
  },
);

export const getManagementInvoicingDate = createAsyncThunk(
  'generateInvoice/getManagementInvoicingDate',
  async (id) => {
    const { data } = await apiClient.get(`/api/invoices/managements/${id}/invoicingDate`);
    return data;
  },
);

export const getCommunityInvoicingDate = createAsyncThunk(
  'generateInvoice/getCommunityInvoicingDate',
  async (id) => {
    const { data } = await apiClient.get(`/api/invoices/communities/${id}/invoicingDate`);
    return data;
  },
);

export const startInvoiceCreation = createAsyncThunk(
  'generateInvoice/startInvoiceCreation',
  async (params) => {
    const { data } = await apiClient.post(`/api/invoices`, params);

    return data;
  },
);

export const getManagementsDropdownItems = createAsyncThunk(
  'generateInvoice/managements',
  // eslint-disable-next-line
  async (params = {}, thunkAPI) => {
    const {
      managements: { items: prevStateItems = [] },
    } = thunkAPI.getState().generateInvoice;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/managements/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.managementId,
      value: item.managementName,
      label: item.managementName,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevStateItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getCommunitiesDropdownItems = createAsyncThunk(
  'generateInvoice/communties',
  // eslint-disable-next-line
  async (params = {}, thunkAPI) => {
    const {
      communities: { items: prevStateItems = [] },
    } = thunkAPI.getState().generateInvoice;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/communities/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.communityId,
      value: item.communityName,
      label: item.communityName,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevStateItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getBillingInstances = createAsyncThunk(
  'generateInvoice/billingInstances',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/invoices/billingInstances/filter`, params);
    return data;
  },
);

export const deleteBillingInstance = createAsyncThunk(
  'generateInvoice/deleteBillingInstance',
  async (id) => {
    const { data } = await apiClient.delete(`/api/invoices/billingInstances/${id}`);
    return data;
  },
);

export const billBillingInstance = createAsyncThunk(
  'generateInvoice/billBillingInstance',
  async (id) => {
    const { data } = await apiClient.post(`/api/invoices/billingInstances/${id}/bill`);
    return data;
  },
);

export const unbillBillingInstance = createAsyncThunk(
  'generateInvoice/unbillBillingInstance',
  async (id) => {
    const { data } = await apiClient.post(`/api/invoices/billingInstances/${id}/unbill`);
    return data;
  },
);

const initialState = {
  error: null,
  status: null,
  url: null,
  managements: {},
  communities: {},
  billingInstances: {},
};

export const generateInvoiceSlice = createSlice({
  name: 'generateInvoice',
  initialState,
  reducers: {},
  extraReducers: {
    [getQuickBookConnectionStatus.fulfilled]: (state, action) => {
      state.status = action.payload.result;
    },
    [getManagementsDropdownItems.fulfilled]: (state, action) => {
      state.managements = action.payload;
    },
    [getManagementsDropdownItems.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCommunitiesDropdownItems.fulfilled]: (state, action) => {
      state.communities = action.payload;
    },
    [getCommunitiesDropdownItems.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getBillingInstances.fulfilled]: (state, action) => {
      state.billingInstances = action.payload.result;
    },
    [getBillingInstances.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default generateInvoiceSlice.reducer;
