import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import Navigation from '../Navigation/Navigation';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/ArrowLeft.svg';

const SubPageHeading = ({ subPageHeading, subPageNav, nav, subPageHeadingRight }) => {
  return (
    <Stack spacing={4} margin="0 32px">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        data-testid="subpage_heading_nav_back"
      >
        <Stack spacing={1} direction="row" alignItems="baseline" width={1}>
          <NavLink to={nav.to} state={nav.state}>
            <ArrowLeft width={24} height={24} />
          </NavLink>
          <Typography variant="h4" color="text.primary" data-testid="subpage_heading_text">
            {subPageHeading}
          </Typography>
        </Stack>
        {subPageHeadingRight}
      </Stack>
      <Navigation navData={subPageNav} isInnerPageNavigation />
    </Stack>
  );
};

SubPageHeading.propTypes = {
  subPageHeading: PropTypes.string,
  subPageHeadingRight: PropTypes.node,
  subPageNav: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      svg: PropTypes.element,
      to: PropTypes.string,
      state: PropTypes.shape({
        navPage: PropTypes.string,
        subNav: PropTypes.string,
      }),
    }),
  ),
  nav: PropTypes.shape({
    to: PropTypes.string,
    state: PropTypes.shape({
      navPage: PropTypes.string,
      subNav: PropTypes.string,
    }),
  }),
};

SubPageHeading.defaultProps = {
  subPageHeading: '',
  subPageHeadingRight: null,
  subPageNav: [],
  nav: {},
};

export default SubPageHeading;
