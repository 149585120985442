import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Refresh } from '../../../../assets/icons/Refresh.svg';
import '../../../organisms/CalendarHeader/Calendar.sass';
import Dropdown from '../../../atoms/Dropdown';
import { ATTORNEYS_EXTRA_OPTION } from '../../Reports/ProductivityByAttorney/ProductivityByAttorney.constants';
import useAuth from '../../../../hooks/useAuth';
import { apiClient } from '../../../../lib/apiClient';
import { rolesDB, useUserInfo } from '../../../../utils/roleHelpers';
import { getSettings, saveRefreshSettings } from '../../../../store/slices/attorneyDashboardSlice';

export const TIMERS = [
  {
    label: 'Do not refresh',
    value: 'no_refresh',
  },
  {
    label: '15 seconds',
    value: 15,
  },
  {
    label: '30 seconds',
    value: 30,
  },
  {
    label: '1 minute',
    value: 60,
  },
  {
    label: '5 minutes',
    value: 300,
  },
];

const CustomRightFilters = ({
  stateCode,
  attorney,
  setAttorney,
  setRefresh,
  refresh,
  isSchedulingAssistant,
}) => {
  const [attorneys, setAttorneys] = useState([]);
  const { userInfo } = useAuth();
  const { settings } = useSelector((state) => state.attorneyDashboard);

  const settingsForCards =
    stateCode === 'AZ' ? settings?.arizonaAttorney : settings?.nevadaAttorney;

  const dispatch = useDispatch();
  const { UserGuid } = useUserInfo();

  const isCurrentUserAttorney = userInfo.roles.includes(rolesDB.Attorney);

  useEffect(() => {
    const fetcher = async () => {
      const { data } = await apiClient.get(`/api/cases/attorneys?stateCode=${stateCode}`);
      const list = data.result.map((item) => ({
        id: item.userId,
        value: item.userId,
        label: `${item.firstName} ${item.lastName}`,
      }));
      const attorneyList = list?.slice();
      attorneyList.unshift(ATTORNEYS_EXTRA_OPTION);
      setAttorneys(attorneyList);
      const currentUserAttorney = attorneyList.find((item) => item.id === userInfo.UserGuid);

      if (!isSchedulingAssistant && settingsForCards?.courtCalendarDto?.attorney) {
        const currentUserAttorneyFromSettings = attorneyList.find(
          (item) => item.id === settingsForCards?.courtCalendarDto?.attorney,
        );

        if (currentUserAttorneyFromSettings) {
          setAttorney(currentUserAttorneyFromSettings);
        }
      } else if (!isSchedulingAssistant && isCurrentUserAttorney && currentUserAttorney) {
        setAttorney(currentUserAttorney);
      } else if (isSchedulingAssistant) {
        setAttorney(ATTORNEYS_EXTRA_OPTION);
      } else {
        setAttorney(null);
      }
    };
    fetcher();
  }, [stateCode, settingsForCards?.courtCalendarDto?.attorney]);

  const handleChangeAttorney = (val) => {
    setAttorney(val);
  };

  const handleChangeRefesh = async (val) => {
    setRefresh(val);
    const queryParameter = val.value === 'no_refresh' ? null : val.value;
    await dispatch(
      saveRefreshSettings({
        settings: {
          ...settings,
          ...(isSchedulingAssistant
            ? { schedulingAssistantDashboardRefreshTime: queryParameter }
            : { courtAttorneyDashboardRefreshTime: queryParameter }),
        },
        userId: UserGuid,
      }),
    );
    await dispatch(getSettings(UserGuid));
  };

  return (
    <>
      <Dropdown
        options={attorneys}
        isColumn
        width="342px"
        onChange={handleChangeAttorney}
        isSearchable
        placeholder="Select attorney"
        value={attorney}
      />
      <Dropdown
        options={TIMERS}
        isColumn
        width="200px"
        onChange={handleChangeRefesh}
        isSearchable
        startIcon={<Refresh />}
        value={refresh}
      />
    </>
  );
};

CustomRightFilters.propTypes = {
  stateCode: PropTypes.string.isRequired,
  attorney: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    PropTypes.instanceOf(null),
  ]).isRequired,

  setAttorney: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isSchedulingAssistant: PropTypes.bool,
};

CustomRightFilters.defaultProps = {
  isSchedulingAssistant: false,
};
export default CustomRightFilters;
