import React from 'react';
import { Box } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { tokenRepository } from '../../../lib/apiClient';
import EmptyBlock from '../../molecules/EmptyBlock';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const NotFound = () => {
  const token = tokenRepository.getToken();
  const base = useBaseUrl();

  if (token) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <EmptyBlock title="Page not found" />
      </Box>
    );
  }

  return <Navigate to={`/${base}/login`} />;
};

export default NotFound;
