import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { documentsLabelAndDropDown } from './DocumentsTemplates.constants';
import Dropdown from '../../../../atoms/Dropdown';
import { getStates, setCurrentState } from '../../../../../store/slices/dbAdmin/documentsSlice';
import MButton from '../../../../MUI/Button/MButton';
import { ReactComponent as DownloadDocumentIcon } from '../../../../../assets/icons/document-download.svg';
import { apiClient } from '../../../../../lib/apiClient';
import { usePrimaryState } from '../../../../../hooks/usePrimaryState';

const DocumentsTemplatesHeader = () => {
  const dispatch = useDispatch();

  const primaryState = usePrimaryState();
  const { state } = useLocation();

  const [chosenState, setChosenState] = useState(primaryState || null);

  const { states } = useSelector((globalState) => globalState.adminDocuments);

  const handleDropdownChange = (value) => {
    setChosenState(value);
    dispatch(setCurrentState(value));
  };

  useEffect(() => {
    dispatch(getStates()).then(({ payload }) => {
      handleDropdownChange(state?.filters?.currentState || primaryState || payload[0]);
    });
  }, [state?.filters?.currentState]);

  const handledownloadVariables = async () => {
    try {
      const { data } = await apiClient.get('/api/templates/fields/txt');
      const file = new Blob([data], {
        type: 'text/plain;charset=utf-8',
      });
      const fileURL = URL.createObjectURL(file);

      const newTabLink = document.createElement('a');
      const downloadFileLink = document.createElement('a');

      newTabLink.href = fileURL;
      newTabLink.target = '_blank';
      document.body.appendChild(newTabLink);
      newTabLink.click();

      downloadFileLink.href = fileURL;
      downloadFileLink.download = 'variables.txt';
      downloadFileLink.click();

      document.body.appendChild(newTabLink);
      document.body.appendChild(downloadFileLink);

      setTimeout(() => {
        document.body.removeChild(newTabLink);
        document.body.removeChild(downloadFileLink);
      }, 100);
    } catch (error) {
      console.log('/db/admin/documents: Download variables Error', error);
    }
  };

  return (
    <Stack flex justifyContent="flex-start" alignItems="center" flexDirection="initial">
      <Typography variant="h3" pb={1} data-testid="documents_templates_header_label">
        {documentsLabelAndDropDown.label}
      </Typography>
      <Dropdown
        label={documentsLabelAndDropDown.dropdownLabel}
        options={states}
        onChange={handleDropdownChange}
        placeholder="-"
        isSearchable={false}
        value={chosenState}
      />
      <Stack flexGrow={1} />
      <MButton
        startIcon={<DownloadDocumentIcon />}
        onClick={handledownloadVariables}
        data-testid="documents_templates_header_button"
      >
        Download Variables
      </MButton>
    </Stack>
  );
};

export default DocumentsTemplatesHeader;
