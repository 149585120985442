import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Container, Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import MButton from '../../../MUI/Button/MButton';
import { ReactComponent as PrinterActive } from '../../../../assets/icons/PrinterWhiteIcon.svg';
import { MAX_AMOUNT_OF_ITEMS_ON_PAGE, documentStrings } from './Document.constants';

import Table from '../../../atoms/Table/Table';
import { ReactComponent as Upload } from '../../../../assets/icons/document-upload-active.svg';
import { ReactComponent as Trash } from '../../../../assets/icons/TrashWhite.svg';
import { palette } from '../../../../theme/default';
import NotificationDialog from '../../../molecules/NotificationDialog';
import DocumentContext from './DocumentContext';

export const DocumentSection = ({
  disabledUpload,
  handleOpenUpload,
  handleChangeDocumentName,
  rows,
  isDocumentsLoading,
  handleChangeDocumentType,
  handleCheckedRow,
  isPagination,
  handleFetchDataPage,
  totalRowsCount,
  handlePrintDocuments,
  selectedDocumentIds,
  canDeleteDocument,
  handleDeleteDocuments,
  columns,
}) => {
  const [isConfirmDeleteDocumentsModalOpen, setIsConfirmDeleteDocumentsModalOpen] = useState(false);
  const contextValue = useMemo(
    () => ({
      handleChangeDocumentName,
    }),
    [handleChangeDocumentName],
  );

  const handleCloseConfirmDeleteDocumentsModal = () => {
    setIsConfirmDeleteDocumentsModalOpen(false);
  };

  const handleOpenConfirmDeleteDocumentsModal = () => {
    setIsConfirmDeleteDocumentsModalOpen(true);
  };

  const handleDelete = async () => {
    handleCloseConfirmDeleteDocumentsModal();
    await handleDeleteDocuments();
  };

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          mt: 4,
          px: 4,
          pb: 13,
          width: 1,
        }}
        data-testid="document_section"
      >
        <Stack spacing={5} direction="row" mb={4}>
          <Typography variant="h5" marginRight="auto" data-testid="document_section_title">
            {documentStrings.title}
          </Typography>
          <MButton
            variant="bordered"
            disabled={disabledUpload}
            startIcon={<Upload width="20px" height="20px" />}
            onClick={handleOpenUpload}
            data-testid="upload_button"
          >
            {documentStrings.button.upload}
          </MButton>
        </Stack>
        <DocumentContext.Provider value={contextValue}>
          <Table
            columns={columns}
            rows={rows}
            loading={isDocumentsLoading}
            onUpdateData={handleChangeDocumentType}
            onCheckedRow={handleCheckedRow}
            isWithCheckbox
            isPagination={isPagination}
            pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            onNextPage={handleFetchDataPage}
            total={totalRowsCount}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            onRefreshPage={handleFetchDataPage}
          />
        </DocumentContext.Provider>
      </Container>
      <Box
        position="absolute"
        left="50%"
        bottom={0}
        sx={{ transform: 'translateX(-50%)' }}
        zIndex={10}
        width={1}
        p={4}
        bgcolor="#FFF"
        boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
      >
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <MButton
            size="large"
            sx={{ height: '48px', width: '144px' }}
            startIcon={<PrinterActive width="20px" height="20px" />}
            onClick={handlePrintDocuments}
            disabled={_.isEmpty(selectedDocumentIds)}
            data-testid="print_button"
          >
            Print
          </MButton>
          {canDeleteDocument && (
            <MButton
              size="large"
              sx={{ height: '48px', width: '144px' }}
              startIcon={<Trash width="20px" height="20px" />}
              onClick={handleOpenConfirmDeleteDocumentsModal}
              disabled={_.isEmpty(selectedDocumentIds)}
              data-testid="open_delete_dialog_button"
            >
              Delete
            </MButton>
          )}
        </Stack>
      </Box>

      <NotificationDialog
        title="Confirm delete"
        desc={`Are you sure you want to delete ${selectedDocumentIds.length} document(s)?`}
        type="alert"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Delete"
        onClose={handleCloseConfirmDeleteDocumentsModal}
        onConfirm={handleDelete}
        isOpen={isConfirmDeleteDocumentsModalOpen}
      />
    </>
  );
};

DocumentSection.propTypes = {
  disabledUpload: PropTypes.bool,
  handleOpenUpload: PropTypes.func.isRequired,
  handleChangeDocumentName: PropTypes.func.isRequired,
  // eslint-disable-next-line
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDocumentsLoading: PropTypes.bool,
  handleChangeDocumentType: PropTypes.func.isRequired,
  handleCheckedRow: PropTypes.func.isRequired,
  isPagination: PropTypes.bool,
  handleFetchDataPage: PropTypes.func.isRequired,
  totalRowsCount: PropTypes.number.isRequired,
  handlePrintDocuments: PropTypes.func.isRequired,
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.number),
  canDeleteDocument: PropTypes.bool,
  handleDeleteDocuments: PropTypes.func.isRequired,
};

DocumentSection.defaultProps = {
  disabledUpload: false,
  isDocumentsLoading: false,
  isPagination: true,
  selectedDocumentIds: [],
  canDeleteDocument: true,
};
