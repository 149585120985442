import { useSelector } from 'react-redux';
import { useAvailableStatesList } from './useAvailableStatesList';

export const usePrimaryState = () => {
  const defaultStates = useAvailableStatesList();
  const { primaryState = '' } = useSelector((state) => state.userProfile.profile);

  return primaryState
    ? {
        id: primaryState,
        label: primaryState,
        value: primaryState,
      }
    : defaultStates[0];
};
