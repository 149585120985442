import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import MButton from '../../../MUI/Button/MButton';
import {
  invoceGenerationTableColumns,
  invoiceDetailOptions,
  invoiceGenerationBoxes,
} from './InvoiceGeneration.constants';
import Dropdown from '../../../atoms/Dropdown';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Field from '../../../atoms/Field';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import Form from '../../../atoms/Form';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import {
  getCommunitiesDropdownItems,
  getInvoiceCreationResult,
  getManagementsDropdownItems,
  startInvoiceCreation,
  getManagementInvoicingDate,
  getCommunityInvoicingDate,
  getBillingInstances,
} from '../../../../store/slices/generateInvoiceSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import MDialog from '../../../atoms/MDialog/MDialog';
import Table from '../../../atoms/Table/Table';
import LoaderCover from '../../../atoms/LoaderCover';

const InvoiceGeneration = () => {
  const dispatch = useDispatch();

  const [isLoader, setIsLoader] = useState(false);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [areBillingInstancesShown, setAreBillingInstancesShown] = useState(false);
  const [resultMessage, setResultMessage] = useState(null);
  const { status, communities, managements, billingInstances } = useSelector(
    (state) => state.generateInvoice,
  );

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      community: null,
      invoicingDetail: invoiceDetailOptions[0],
      management: null,
      startDate: null,
      endDate: null,
    },
  });

  const onCloseResultPopup = () => {
    setResultMessage(null);
    setAreBillingInstancesShown(false);
    form.reset({
      community: null,
      endDate: null,
      invoicingDetail: invoiceDetailOptions[0],
      management: null,
      startDate: null,
    });
  };

  const invoicingDetailValue = form.watch('invoicingDetail')?.value;
  const startDate = form.watch('startDate');
  const management = form.watch('management')?.id;
  const community = form.watch('community')?.id;

  const getDisabled = (name) => {
    if (isLoader) return true;
    switch (name) {
      case 'community':
        return invoicingDetailValue !== 'Community';
      case 'endDate':
        return !startDate;
      default:
        return isLoader;
    }
  };

  const getMandatory = (name) => {
    switch (name) {
      case 'community':
        return invoicingDetailValue === 'Community';
      default:
        return false;
    }
  };

  const getOptions = useCallback(
    (name) => {
      switch (name) {
        case 'management':
          return managements.items;
        case 'community':
          return communities.items;
        case 'invoicingDetail':
          return invoiceDetailOptions;
        default:
          return [];
      }
    },
    [managements, communities],
  );

  const getMinDate = (name) => {
    switch (name) {
      case 'endDate':
        return startDate;
      default:
        return null;
    }
  };

  const getMaxDate = (name) => {
    switch (name) {
      case 'endDate':
        return moment();
      default:
        return null;
    }
  };

  const communityActionArgs = useMemo(() => {
    if (management) {
      return { managementId: management };
    }
    return {};
  }, [management]);

  useEffect(() => {
    form.setValue('community', null, { shouldValidate: true });

    if (invoicingDetailValue === 'Management' && management) {
      dispatch(getManagementInvoicingDate(management)).then(({ payload: { result } }) => {
        form.setValue('startDate', moment(result));
        form.setValue('endDate', moment(), { shouldValidate: true });
      });
    }
  }, [invoicingDetailValue, management]);

  useEffect(() => {
    if (invoicingDetailValue === 'Community' && community) {
      dispatch(getCommunityInvoicingDate(community)).then(({ payload: { result } }) => {
        form.setValue('startDate', moment(result));
        form.setValue('endDate', moment(), { shouldValidate: true });
      });
    }
  }, [invoicingDetailValue, community]);

  const communityDropdownHandlers = useSearchDropdown({
    hasNextPage: communities.hasNextPage,
    getAction: ({ input, page, managementId }) =>
      getCommunitiesDropdownItems({
        currentPage: page,
        communityName: input,
        managementId,
      }),
    actionArguments: communityActionArgs,
  });

  const managementDropdownHandlers = useSearchDropdown({
    hasNextPage: managements.hasNextPage,
    getAction: ({ input, page }) =>
      getManagementsDropdownItems({
        currentPage: page,
        managementName: input,
      }),
  });

  const getDropdownHandlers = (name) => {
    switch (name) {
      case 'management':
        return managementDropdownHandlers;
      case 'community':
        return communityDropdownHandlers;
      default:
        return {};
    }
  };

  const handleFetchDataPage = (currentPage = 1) => {
    setIsTableLoader(true);

    const values = form.watch();

    const params = {
      pageSize: 10,
      currentPage,
      startDate: values.startDate,
      endDate: values.endDate,
      managementId: values.management.id,
      invoicingDetail: values.invoicingDetail.value,
      communityId: values.community?.id ?? null,
    };

    return dispatch(getBillingInstances(params)).then(() => {
      setIsTableLoader(false);
    });
  };

  useEffect(() => {
    if (areBillingInstancesShown) {
      handleFetchDataPage();
    }
  }, [areBillingInstancesShown]);

  const handleSubmit = async () => {
    const values = form.watch();
    const data = {
      startDate: values.startDate,
      endDate: values.endDate,
      managementId: values.management.id,
      invoicingDetail: values.invoicingDetail.value,
      communityId: values.community?.id ?? null,
    };

    setIsLoader(true);

    try {
      const { result: id } = await dispatch(startInvoiceCreation(data)).unwrap();
      // eslint-disable-next-line
      while (true) {
        // eslint-disable-next-line
        const { result } = await dispatch(getInvoiceCreationResult(id)).unwrap();
        if (result?.isCompleted) {
          setResultMessage(result.message);
          break;
        } else {
          // eslint-disable-next-line
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      setResultMessage('Something went wrong, please try again later.');
    }
    setIsLoader(false);
  };

  if (!status?.isActive) return null;

  return (
    <>
      <Stack>
        <Typography variant="h4" margin="32px 32px 64px">
          Invoice Generation
        </Typography>
        <Box width={areBillingInstancesShown ? '1200px' : '740px'} margin="0 auto">
          <ShadowBlock>
            <Stack position="relative">
              {isLoader && <LoaderCover />}
              {areBillingInstancesShown ? (
                <Box
                  height={isTableLoader ? '850px' : 'auto'}
                  display="flex"
                  alignItems="center"
                  pb={4}
                >
                  <Table
                    columns={invoceGenerationTableColumns}
                    rows={billingInstances.items ?? []}
                    total={billingInstances?.totalRowsCount}
                    isPagination={billingInstances?.totalPages > 1}
                    onNextPage={handleFetchDataPage}
                    onPreviousPage={handleFetchDataPage}
                    onGotoPage={handleFetchDataPage}
                    onRefreshPage={handleFetchDataPage}
                    pageSize={10}
                    loading={isTableLoader}
                    overflowHidden={false}
                  />
                </Box>
              ) : (
                <Form form={form}>
                  <Stack
                    direction="row"
                    alignItems="flex-end"
                    columnGap={3}
                    rowGap={2}
                    flexWrap="wrap"
                    py={1}
                    marginBottom={5}
                  >
                    {invoiceGenerationBoxes.map((item) => {
                      if (item.isDatePicker) {
                        return (
                          <Field
                            name={item.name}
                            isMandatory={getMandatory(item.name) || item.isMandatory}
                            render={({ field, onCustomChange }) => (
                              <DateOrTimePicker
                                name={item.name}
                                isDate
                                Svg={Calender}
                                label={item.label}
                                selected={field.value}
                                isMandatory={getMandatory(item.name) || item.isMandatory}
                                width={item.width}
                                height={item.height}
                                placeholder={item.placeholder}
                                disabled={getDisabled(item.name) || item.isDisabled}
                                setSelectedTimeOrDate={onCustomChange(field.onChange)}
                                minDate={getMinDate(item.name)}
                                maxDate={getMaxDate(item.name)}
                              />
                            )}
                          />
                        );
                      }

                      if (item.isDropdown) {
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            isMandatory={getMandatory(item.name) || item.isMandatory}
                            render={({ field, onCustomChange }) => {
                              return (
                                <Dropdown
                                  value={field.value}
                                  isAddDisabled={false}
                                  isSearchable
                                  key={item.label}
                                  label={item.label}
                                  width={item.width}
                                  isColumn
                                  placeholder={item.placeholder}
                                  options={getOptions(item.name)}
                                  onChange={onCustomChange(field.onChange)}
                                  isDisabled={getDisabled(item.name) || item.isDisabled}
                                  isMandatory={getMandatory(item.name) || item.isMandatory}
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item.isDynamicSearchDropdown) {
                        const { changeInput, handleMenuScrollToBottom } = getDropdownHandlers(
                          item.name,
                        );
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            isMandatory={getMandatory(item.name) || item.isMandatory}
                            render={({ field, onCustomChange }) => (
                              <Dropdown
                                value={field.value}
                                isAddDisabled={false}
                                isSearchable
                                key={item.label}
                                label={item.label}
                                width={item.width}
                                isColumn
                                placeholder={item.placeholder}
                                options={getOptions(item.name)}
                                onChange={onCustomChange(field.onChange)}
                                isMandatory={getMandatory(item.name) || item.isMandatory}
                                onMenuScrollToBottom={handleMenuScrollToBottom}
                                onInputChange={changeInput}
                                isDisabled={getDisabled(item.name) || item.isDisabled}
                                filterOption={null}
                              />
                            )}
                          />
                        );
                      }

                      return null;
                    })}
                  </Stack>
                </Form>
              )}
              <Stack direction="row" justifyContent="end" gap={3}>
                {areBillingInstancesShown && (
                  <MButton
                    type="button"
                    variant="secondary"
                    size="large"
                    disabled={isLoader || !form.formState.isValid}
                    onClick={() => {
                      setAreBillingInstancesShown(false);
                    }}
                  >
                    Back
                  </MButton>
                )}
                <MButton
                  data-testid="billingInstancesButton"
                  type="button"
                  size="large"
                  disabled={isLoader || !form.formState.isValid}
                  onClick={() => {
                    if (areBillingInstancesShown) {
                      handleSubmit();
                    } else {
                      setAreBillingInstancesShown(true);
                      setIsTableLoader(true);
                    }
                  }}
                >
                  {areBillingInstancesShown ? 'Submit' : 'Show Billing Instances'}
                </MButton>
              </Stack>
            </Stack>
          </ShadowBlock>
        </Box>
      </Stack>
      {resultMessage && (
        <MDialog
          minWidth="lg"
          title="Invoice Generation"
          onClose={onCloseResultPopup}
          footerActions={
            <MButton size="large" onClick={onCloseResultPopup}>
              Close
            </MButton>
          }
        >
          <Typography variant="bodyL500">{resultMessage}</Typography>
        </MDialog>
      )}
    </>
  );
};

export default InvoiceGeneration;
