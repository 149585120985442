import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { isEqual } from 'lodash';
import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import InputCell from './InputCell';
import { formatUSD } from '../../../../utils/formatCurrency';

const courtArray = ['HEN', 'NLV', 'RENO', 'SPARKS'];
const getIsMandatoryByCourt = (columnId, abbreviation, hasGovernmentBackedLoan) => {
  switch (columnId) {
    case 'periodicRent':
    case 'currentAmountDue':
    case 'pastAmountDue':
    case 'lateFees': {
      if (!hasGovernmentBackedLoan && abbreviation === 'LVT') {
        return false;
      }
      return true;
    }
    case 'lastPaymentAmount': {
      if (
        (!hasGovernmentBackedLoan && abbreviation === 'LVT') ||
        courtArray.includes(abbreviation)
      ) {
        return false;
      }
      return true;
    }
    default:
      return 'unknown';
  }
};

const DollarCell = ({ row, updateValue, cell, isMandatory: isMandatoryProp }) => {
  const { value, column } = cell;

  const [isEdit, setEdit] = useState(false);
  const [val, setVal] = useState(value);
  const error = row.original?.errors?.[column.id];
  const touched = row.original?.touched?.[column.id];

  const { lateFees, pastAmountDue, currentAmountDue } = row.original;

  const {
    noticeFee: serviceFee,
    totalLateFee,
    previousBalance: balanceCredit,
    otherCharges,
    nsfFee: nsfCharges,
    utilities,
    rentPerLeaseAgreement: rent,
  } = row.original;

  const { addressesList } = useSelector((state) => state.nonPaymentRequest);

  const chosenAddressCourtInfo = addressesList?.courts?.find(
    (item) => item.addressId === row.original.addressId,
  ) || {
    abbreviation: row.original.abbreviation,
    addressId: row.original.addressId,
    courtName: row.original.courtName,
    hasGovernmentBackedLoan: row.original.hasGovernmentBackedLoan,
  };

  const readOnly =
    (column.id === 'totalOwed' &&
      ((chosenAddressCourtInfo?.abbreviation === 'LVT' &&
        chosenAddressCourtInfo?.hasGovernmentBackedLoan) ||
        chosenAddressCourtInfo?.abbreviation !== 'LVT')) ||
    column.id === 'totalDue';

  const fieldByCourt = getIsMandatoryByCourt(
    column.id,
    chosenAddressCourtInfo?.abbreviation,
    chosenAddressCourtInfo?.hasGovernmentBackedLoan,
  );
  const isMandatory = isMandatoryProp || (fieldByCourt !== 'unknown' && !!fieldByCourt);

  const isDisabled = !fieldByCourt;

  useEffect(() => {
    if (isDisabled) {
      setVal(0);
      setEdit(false);
      if (!row.original?.id) return;

      updateValue(row.original.id, { id: column.id, value: 0, error: undefined });
    }
  }, [isDisabled, row.original.id, value]);

  useEffect(() => {
    if (val !== value) {
      setVal(value);
    }
    setEdit(false);
  }, [row.original.id, column.id]);

  useEffect(() => {
    if (column.id === 'totalOwed' && readOnly) {
      const resultValue =
        Number(Number(+lateFees + +pastAmountDue + +currentAmountDue).toFixed(2)) || 0;
      setVal(resultValue);
      if (!row.original?.id) return;
      updateValue(row.original.id, {
        id: column.id,
        value: resultValue,
        error: undefined,
      });
    }
  }, [row.original.id, lateFees, pastAmountDue, currentAmountDue, readOnly]);

  useEffect(() => {
    if (column.id === 'totalDue') {
      const part1 =
        (+serviceFee || 0) +
        (+totalLateFee || 0) +
        (+balanceCredit || 0) +
        (+otherCharges || 0) +
        (+nsfCharges || 0) +
        (+utilities || 0) +
        (+rent || 0);

      // const newCalculation = (part1 - credit + monthToMonth).toFixed(2);
      const calculation = Number(part1.toFixed(2)) || 0;
      if (!row.original?.id) return;
      setVal(calculation);
      updateValue(row.original.id, {
        id: column.id,
        value: calculation,
        error: undefined,
      });
    }
  }, [
    row.original.id,
    serviceFee,
    totalLateFee,
    balanceCredit,
    otherCharges,
    nsfCharges,
    utilities,
    rent,
  ]);

  const onChange = (ev) => {
    if (readOnly) return;
    const valueOnlyDigits = ev.target.value?.replace(/[^0-9.,]+/g, '')?.replaceAll(',', '.');
    const isValid = /^[a-zA-Z0-9,]*[.]{0,1}[a-zA-Z0-9,]*$/.test(valueOnlyDigits);

    if (isValid) {
      setVal(valueOnlyDigits);
    }
  };

  const handleFocus = useCallback(() => {
    if (
      (column.id !== 'totalOwed' && column.id !== 'totalDue') ||
      (column.id === 'totalOwed' &&
        chosenAddressCourtInfo?.abbreviation === 'LVT' &&
        !chosenAddressCourtInfo?.hasGovernmentBackedLoan)
    ) {
      setEdit(true);
    }
  }, [column.id, row.original.addressId]);

  const onBlur = () => {
    if (readOnly) return;
    if (!row.original?.id) return;
    if (column.id === 'totalOwed' && !readOnly && !val) {
      updateValue(row.original.id, {
        id: column.id,
        value: 0,
        error: undefined,
        touched: true,
      });
      setVal(0);
      setEdit(false);
      return;
    }
    if (!val && isMandatory) {
      updateValue(row.original.id, {
        id: column.id,
        value: val,
        error: 'Mandatory field',
        touched: true,
      });
    } else {
      setEdit(false);
      updateValue(row.original.id, {
        id: column.id,
        value: val,
        error: undefined,
        touched: true,
      });
    }
  };

  const valueToDollar = useMemo(() => {
    return formatUSD(val || 0);
  }, [val]);

  const message = useMemo(() => {
    if (error && touched) return { message: 'Mandatory field' };
    return null;
  }, [error, touched]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <InputCell
        isNoBorder={(!isEdit && !message) || readOnly}
        onBlur={onBlur}
        onFocus={handleFocus}
        data-testid="cell_value_input"
        value={isEdit ? val || '' : valueToDollar}
        onChange={onChange}
        textAlign={column.id !== 'totalOwed' && column.id !== 'totalDue' ? 'right' : 'left'}
        error={!!message}
        disabled={isDisabled}
        readOnly={readOnly} // TODO check when it isn't disable
      />
      {message?.message && error && touched && (
        <Typography sx={{ position: 'absolute', bottom: '-15px' }} variant="caption" color="error">
          {message.message}
        </Typography>
      )}
    </Box>
  );
};

DollarCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    column: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({
      id: PropTypes.number,
      errors: PropTypes.shape({}),
      lateFees: PropTypes.number,
      pastAmountDue: PropTypes.number,
      currentAmountDue: PropTypes.number,
      touched: PropTypes.shape({}),
      addressId: PropTypes.number,
      noticeFee: PropTypes.number,
      totalLateFee: PropTypes.number,
      previousBalance: PropTypes.number,
      otherCharges: PropTypes.number,
      nsfFee: PropTypes.number,
      utilities: PropTypes.number,
      rentPerLeaseAgreement: PropTypes.number,
      hasGovernmentBackedLoan: PropTypes.bool,
      abbreviation: PropTypes.string,
      courtName: PropTypes.string,
    }),
  }).isRequired,
  updateValue: PropTypes.func.isRequired,
  isMandatory: PropTypes.bool,
};

DollarCell.defaultProps = {
  cell: {
    value: '',
    column: {
      id: '',
    },
  },
  isMandatory: false,
};

const customComparator = (prevProps, nextProps) => {
  return (
    nextProps.cell.value === prevProps.cell.value &&
    isEqual(nextProps.row.original, prevProps.row.original) &&
    nextProps.cell.column.id === prevProps.cell.column.id
  );
};

export default React.memo(DollarCell, customComparator);
