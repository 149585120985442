import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { tokenRepository } from '../lib/apiClient';
import RoutingWrapper from '../components/organisms/RoutingWrapper/RoutingWrapper';
import NotFound from '../components/pages/NotFound';
import AuthProvider from '../services/AuthProvider';
import { useInitializeRouterState } from '../hooks/useNavigateToNewTab';
import { getDefaultPage, rolesDB } from '../utils/roleHelpers';
import getDecodedJwtToken from '../utils/getDecodedJwtToken';
import { getCPUserProfile, getUserProfile } from '../store/slices/userProfileSlice';

import { getLandingPagesByRoles } from '../components/organisms/TopNavBar/TopNavBar.constants';
import LoaderCover from '../components/atoms/LoaderCover';
import { useBaseUrl } from '../hooks/useBaseUrl';

export const RoutesGuard = ({ isDB, isCP }) => {
  const token = tokenRepository.getToken();
  const arePrivate = isCP || isDB;
  const { profile } = useSelector((state) => state.userProfile);
  const base = useBaseUrl();

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      const { isClientPortalUser } = getDecodedJwtToken(token);
      if (!isClientPortalUser) {
        dispatch(getUserProfile());
      } else {
        dispatch(getCPUserProfile());
      }
    }
  }, [token]);

  if (arePrivate && !token) return <NotFound />;

  if (token && profile.userId) {
    const { isClientPortalUser, roles, stateCodes } = getDecodedJwtToken(token);

    const isCPUserFromDB =
      isClientPortalUser &&
      roles.some((role) => {
        return Object.values(rolesDB).includes(role);
      });

    if (!arePrivate || (isDB && isClientPortalUser) || (isCP && !isClientPortalUser)) {
      const availablePagesByRoles = roles
        ? getLandingPagesByRoles(
            roles,
            isClientPortalUser,
            base,
            stateCodes.includes('AZ') ? [] : ['Legal Forms'],
          )
        : [];
      const chosenPage = availablePagesByRoles.find((item) => item.label === profile?.landingPage);
      const chosenDefaultPage = isCPUserFromDB ? `/${base}/cases/casesearch` : chosenPage?.href;

      const defaultPage = getDefaultPage(undefined, chosenDefaultPage);

      if (defaultPage === null) return null;
      return <Navigate to={defaultPage} />;
    }
  } else if (token && !profile.userId) {
    return <LoaderCover />;
  }

  return <Outlet />;
};

RoutesGuard.propTypes = {
  isCP: PropTypes.bool,
  isDB: PropTypes.bool,
};

RoutesGuard.defaultProps = {
  isCP: false,
  isDB: false,
};

export const RoleGuard = ({ whitelist, children }) => {
  const token = tokenRepository.getToken();
  const { profile } = useSelector((state) => state.userProfile);
  const base = useBaseUrl();
  if (token) {
    const { roles, isClientPortalUser, stateCodes } = getDecodedJwtToken(token);
    const isCPUserFromDB =
      isClientPortalUser &&
      roles.some((role) => {
        return Object.values(rolesDB).includes(role);
      });
    if (Array.isArray(whitelist) && !roles.filter((role) => whitelist.includes(role)).length) {
      const availablePagesByRoles = roles
        ? getLandingPagesByRoles(
            roles,
            isClientPortalUser,
            base,
            stateCodes.includes('AZ') ? [] : ['Legal Forms'],
          )
        : [];
      const chosenPage = availablePagesByRoles.find((item) => item.label === profile?.landingPage);
      const chosenDefaultPage = isCPUserFromDB ? `/${base}/cases/casesearch` : chosenPage?.href;
      const defaultPage = getDefaultPage(undefined, chosenDefaultPage);
      if (defaultPage === null) return null;
      return <Navigate to={defaultPage} />;
    }
  }

  return children;
};

RoleGuard.propTypes = {
  whitelist: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

RoleGuard.defaultProps = {
  whitelist: null,
  children: null,
};

export const RoutesWrapper = () => {
  useInitializeRouterState();

  return (
    <RoutingWrapper>
      <AuthProvider>
        <Outlet />
      </AuthProvider>
    </RoutingWrapper>
  );
};
