import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import MButton from '../../../MUI/Button/MButton';
import { printChecksBoxes } from './PrintChecks.constants';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Field from '../../../atoms/Field';
import Form from '../../../atoms/Form';
import { apiClient } from '../../../../lib/apiClient';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import notificationUtils from '../../../../utils/notificationUtils';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import LoaderCover from '../../../atoms/LoaderCover';

const PrintChecks = () => {
  useSafeLocationState('Accounting', 'Print Checks');

  const [isLoader, setIsLoader] = useState(false);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      createForcible: false,
      createWrit: false,
      createWritConstable: false,
    },
  });

  const handleSubmit = async (values) => {
    setIsLoader(true);

    try {
      await apiClient.post(`/api/tallies`, values);

      notificationUtils.success('Generated successfully');

      form.reset({
        createForcible: false,
        createWrit: false,
        createWritConstable: false,
      });
    } catch (e) {
      notificationUtils.error('Something went wrong');
    }
    setIsLoader(false);
  };

  const atLeastOneChecked = Object.values(form.watch()).some((value) => value);

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <Box width="740px" margin="0 auto" pb={10}>
        <ShadowBlock>
          <Stack position="relative">
            {isLoader && <LoaderCover isFixed />}
            <Typography variant="h4" marginBottom={4}>
              Print Checks
            </Typography>
            <Form form={form} onSubmit={handleSubmit}>
              <Stack
                direction="row"
                alignItems="flex-end"
                columnGap={3}
                rowGap={2}
                flexWrap="wrap"
                py={1}
                marginBottom={5}
              >
                {printChecksBoxes.map((item) => {
                  return (
                    <Field
                      key={item.label}
                      name={item.name}
                      render={({ field, onCustomChange }) => (
                        <CheckboxWithLabel
                          label={item.label}
                          isChecked={field.value}
                          onChange={onCustomChange(field.onChange)}
                        />
                      )}
                    />
                  );
                })}
              </Stack>
              <Stack direction="row-reverse">
                <MButton type="submit" size="large" disabled={isLoader || !atLeastOneChecked}>
                  Generate
                </MButton>
              </Stack>
            </Form>
          </Stack>
        </ShadowBlock>
      </Box>
    </Box>
  );
};

export default PrintChecks;
