import { CaseNumberCell } from '../DashboardAttorney/DashboardAttorney.constants';
import { DefendantCell } from '../../Processing/CaseSearch/CaseSearchCells';
import CaseStatus from '../../../molecules/CaseStatus';

export const CourtScheduleFormBoxes = [
  {
    name: 'dataFields',
    inputBoxes: [
      {
        label: 'From Date',
        name: 'fromDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'To Date',
        name: 'toDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'County',
        name: 'county',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'Courts',
        name: 'courts',
        placeholder: '',
        width: '226px',
        isCheckboxSelect: true,
        showSelectAll: true,
        isMandatory: true,
      },
    ],
  },
];

export const CourtScheduleColumns = [
  {
    Header: 'Community Name',
    accessor: 'communityName',
  },
  {
    Header: 'Opposing Party',
    accessor: 'opposingParty',
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const HearingsPopupColumns = [
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    Cell: CaseNumberCell,
  },
  {
    Header: 'Attorney',
    accessor: 'assignedAttorneyFullName',
  },
  {
    Header: 'Community',
    accessor: 'communityName',
  },
  {
    Header: 'Opposing Party',
    accessor: 'oppositeParty',
    Cell: DefendantCell,
  },
  {
    Header: 'Violation Type',
    accessor: 'violationType',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CaseStatus,
  },
];
