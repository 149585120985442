import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SubPageHeading from '../../../../organisms/SubPageHeading';
import { subPageNavEdit, subPageNavNew } from './OwnerEntityContainer.constants';

const OwnerEntityContainer = () => {
  const {
    state: { navPage, subNav, name, id },
  } = useLocation();

  const subPageNavEditExtended = subPageNavEdit.map((item) => ({
    ...item,
    state: {
      navPage,
      subNav,
      name,
      id,
    },
  }));

  return (
    <>
      <SubPageHeading
        subPageNav={name ? subPageNavEditExtended : subPageNavNew}
        subPageHeading={name || 'New Owner Entity'}
        nav={{
          to: '/db/datahandling/ownerentities',
          state: {
            navPage,
            subNav,
          },
        }}
      />
      <Outlet />
    </>
  );
};

export default OwnerEntityContainer;
