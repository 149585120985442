export const stateCodesOptions = [
  { value: 'AZ', label: 'AZ' },
  { value: 'NV', label: 'NV' },
];

export const CaseComparisonByEmployeeFormBoxes = [
  {
    name: 'filters',
    inputBoxes: [
      {
        label: 'Sub-report',
        name: 'subReport',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'From Date',
        name: 'fromDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'To Date',
        name: 'toDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'State(s)',
        name: 'stateCodes',
        placeholder: '-',
        width: '226px',
        isCheckboxSelect: true,
        isMandatory: true,
      },
    ],
  },
];
