import React, { useState, useCallback, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
  Stack,
  styled,
  Box,
} from '@mui/material';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import Dropdown from '../../../atoms/Dropdown';
import {
  addUserForRole,
  getAvailableRoles,
  getUsersForRole,
  getAllUsers,
  deleteUserFromRole,
  resetRoleUsers,
} from '../../../../store/slices/adminRolesSlice';
import MButton from '../../../MUI/Button/MButton';
import { ReactComponent as AddIcon } from '../../../../assets/icons/Add.svg';
import { palette } from '../../../../theme/default';
import { ReactComponent as Cross } from '../../../../assets/icons/Cross.svg';
import { UsersTableColumns } from './RolesFlow.constants';
import Table from '../../../atoms/Table/Table';
import EmptyBlock from '../../../molecules/EmptyBlock';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
    },
    '.MuiPaper-root': {
      border: `2px solid ${palette.buttonSecondary.disabledBorder}`,
      marginBottom: '16px',
      borderRadius: '16px',
      boxShadow: 'none',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiTypography-root': {
      fontSize: '18px',
      fontWeight: 500,
      color: palette.text.primary,
      flexGrow: 1,
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '72px',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '24px',
      height: '24px',
      stroke: 'red',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

function Roles() {
  useSafeLocationState('Admin', 'Roles');
  const dispatch = useDispatch();

  const [expandedId, setExpandedId] = useState(false);
  const [expandedPanelId, setExpandedPanelId] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const { roles, roleUsers, allUsers } = useSelector((state) => state.adminRoles);

  useEffect(() => {
    dispatch(getAvailableRoles());
  }, []);

  const toggleAccordion = (panel) => (event, isExpanded) => {
    setExpandedId(!isExpanded || panel);
    setExpandedPanelId(panel);

    if (!isExpanded || expandedPanelId !== panel) {
      dispatch(resetRoleUsers());
    }

    if (isExpanded) {
      dispatch(
        getAllUsers({
          currentPage: 1,
          pageSize: 10,
        }),
      );
      dispatch(getUsersForRole(panel));
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleClickApply = () => {
    const { id } = selectedUser;
    dispatch(addUserForRole({ id: expandedId, userId: id })).then(() => {
      dispatch(getUsersForRole(expandedId));
      setSelectedUser(null);
    });
  };

  const handleDeleteSelectedUsers = (e) => {
    e.stopPropagation();
    setIsLoaderTable(true);

    const userToDeleteObject = {
      id: expandedId,
      removeAll: false,
      selectedUsers: checkedRows.map((item) => ({
        userId: item.userId,
      })),
    };
    dispatch(deleteUserFromRole(userToDeleteObject)).then(() => {
      dispatch(getUsersForRole(expandedId)).then(() => {
        setCheckedRows([]);
        setIsLoaderTable(false);
      });
    });
  };

  const handleDeleteUser = useCallback(
    (id, original) => {
      setIsLoaderTable(true);

      const userToDeleteObject = {
        id: expandedPanelId,
        removeAll: false,
        selectedUsers: [{ userId: original.userId }],
      };
      dispatch(deleteUserFromRole(userToDeleteObject)).then(() => {
        dispatch(getUsersForRole(expandedId)).then(() => {
          setIsLoaderTable(false);
        });
      });
    },
    [expandedPanelId],
  );

  const handleCheckTableRows = (rows) => {
    setCheckedRows(rows);
  };

  const handleInputChange = debounce((value) => {
    dispatch(
      getAllUsers({
        currentPage: 1,
        pageSize: 10,
        firstName: value,
      }),
    );
  }, 500);

  return (
    <Box data-testid="roles_wrapper">
      <Typography variant="h4" paddingLeft="32px">
        Roles
      </Typography>
      <StyledContainer
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          mb: 9 + 4 + 4 + 6,
          px: 4,
          width: 1,
          maxWidth: 1542,
        }}
      >
        {roles &&
          roles.map((item) => (
            <StyledAccordion
              expanded={expandedId === item.roleId}
              onChange={toggleAccordion(item.roleId)}
              key={item.label}
              data-testid="role_accordion"
            >
              <AccordionSummary
                expandIcon={<ArrowDown />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>{item.name}</Typography>
                {checkedRows.length && expandedId === item.roleId ? (
                  <MButton
                    variant="borderLess"
                    size="large"
                    endIcon={<Cross width="18px" height="18px" />}
                    onClick={handleDeleteSelectedUsers}
                    sx={{ mr: 'auto' }}
                    data-testid="remove_selected_btn"
                  >
                    Remove selected
                  </MButton>
                ) : null}
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  pb={8}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    mr={15}
                  >
                    <Dropdown
                      value={selectedUser}
                      isSearchable
                      key={item.label}
                      width="423px"
                      isColumn
                      placeholder="Select user"
                      options={allUsers?.items}
                      onChange={handleUserSelect}
                      onInputChange={handleInputChange}
                    />
                    <MButton
                      sx={{ width: '78px', height: '48px' }}
                      onClick={handleClickApply}
                      startIcon={<AddIcon />}
                      data-testid="add_role_user"
                    >
                      Add
                    </MButton>
                  </Stack>
                  <Stack width="100%" alignItems="center" height={290}>
                    {roleUsers.length ? (
                      <Table
                        columns={UsersTableColumns}
                        rows={roleUsers}
                        isWithCheckbox
                        isPagination={false}
                        onDeleteRow={handleDeleteUser}
                        loading={isLoaderTable}
                        onCheckedRow={handleCheckTableRows}
                        maxHeight="250px"
                      />
                    ) : (
                      <EmptyBlock
                        title="No Users"
                        desc="Add a user in the dropdown list on the left."
                      />
                    )}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </StyledAccordion>
          ))}
      </StyledContainer>
    </Box>
  );
}

Roles.propTypes = {};

Roles.defaultProps = {};

export default Roles;
