import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { palette } from '../../../../../theme/default';
import { formatUSD } from '../../../../../utils/formatCurrency';

const RateCell = ({ value, row }) => {
  if (row?.original?.isTotalRow) {
    return (
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          textAlign: 'right',
          color: palette.text.secondary,
          width: '100%',
        }}
      >
        Total($)
        <br />
        Total(h)
      </Typography>
    );
  }
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
        textAlign: 'right',
        width: '100%',
      }}
    >
      {value ? formatUSD(value) : null}
    </Typography>
  );
};

RateCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      quantityTotal: PropTypes.string,
      isTotalRow: PropTypes.bool,
    }),
  }).isRequired,
};

RateCell.defaultProps = {
  value: '',
};

export default RateCell;
