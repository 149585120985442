import { useMemo, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import Dropdown from '../../../../atoms/Dropdown';
import { useUserInfo } from '../../../../../utils/roleHelpers';
import {
  ARIZONA_KEY,
  DOCUMENT_TYPE_DROPDOWN,
  BULK_DOCUMENT_PRINTING_STATE_DROPDOWN,
} from '../BulkDocumentPrinting.constants';

export const DropdownFormInputCell = ({
  row,
  setEditableRowIndex,
  changeValueCell,
  setEditableRow,
  updateData,
}) => {
  const { stateCodes } = useUserInfo();
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const findDefaultOption = (options) => {
    return options.find((item) => item.default);
  };

  const bulkDocumentStateOptions = useMemo(
    () => BULK_DOCUMENT_PRINTING_STATE_DROPDOWN.filter(({ value }) => stateCodes.includes(value)),
    [stateCodes],
  );

  const stateKey = bulkDocumentStateOptions[0]?.value || ARIZONA_KEY;

  useEffect(() => {
    setSelectedDocumentType(findDefaultOption(DOCUMENT_TYPE_DROPDOWN[stateKey]));
  }, [stateKey]);

  const handleChangeDocumentType = (value) => {
    setSelectedDocumentType(value);

    setEditableRowIndex(row.index);
    setEditableRow(row.original);
    changeValueCell('type', value.value);
    setNeedsUpdate(true);
  };

  useEffect(() => {
    if (needsUpdate) {
      updateData();
      setNeedsUpdate(false);
    }
  }, [needsUpdate]);

  const handleDropdownIndicatorClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const options = useMemo(() => {
    return DOCUMENT_TYPE_DROPDOWN[stateKey] || [];
  }, [stateKey]);

  return (
    <Dropdown
      value={selectedDocumentType}
      onMenuOpen={handleDropdownIndicatorClick}
      onMenuClose={handleDropdownIndicatorClick}
      onChange={handleChangeDocumentType}
      menuIsOpen={isMenuOpen}
      name="type"
      width="162px"
      placeholder="Select Type"
      options={options}
      height={32}
      isColumn
    />
  );
};

DropdownFormInputCell.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number,
    original: PropTypes.shape({
      documentName: PropTypes.string,
      file: PropTypes.shape({
        path: PropTypes.string.isRequired,
        lastModified: PropTypes.number,
        lastModifiedDate: PropTypes.instanceOf(Date),
        name: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string,
        webkitRelativePath: PropTypes.string,
      }),
      id: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
  setEditableRowIndex: PropTypes.func.isRequired,
  changeValueCell: PropTypes.func.isRequired,
  setEditableRow: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
};
