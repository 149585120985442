import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import MDialog from '../../../atoms/MDialog';
import MButton from '../../../MUI/Button/MButton';
import LoaderCover from '../../../atoms/LoaderCover';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Clock } from '../../../../assets/icons/Clock.svg';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup/CustomRadioGroup';
import MultilineTextField from '../../../atoms/MultilineTextField/MultilineTextField';

const AdditionalInformationFormDialog = ({
  isOpen,
  onClose,
  onClosed,
  readonly,
  addViolation,
  fields,
  isLoading,
}) => {
  const [isLoader, setIsLoader] = useState(false);
  const [formFields, setFormFields] = useState([]);

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    if (isOpen && fields.length) {
      const preparedFields = fields.map((field) => ({
        ...field,
        name: field.key,
        initial: field.value,
      }));

      const radioButtonRegex = /^(.*)(Yes|No)$/;
      const mappedFields = Object.values(
        preparedFields.reduce((acc, field) => {
          const match = field.name.match(radioButtonRegex);

          if (field.type === 'checkbox' && match) {
            const [fullName, generalName, optionName] = match;
            if (!acc[generalName]) acc[generalName] = { ...field, type: 'radio', options: [] };
            const option = { label: optionName, name: fullName, value: optionName };
            if (field.initial === 'True') acc[generalName].initial = option;
            if (optionName === 'Yes') {
              acc[generalName].options.unshift(option);
            } else {
              acc[generalName].options.push(option);
            }
          } else {
            acc[field.name] = field;
          }

          return acc;
        }, {}),
      );

      setFormFields(mappedFields);

      mappedFields.forEach((field) => {
        if (Object.prototype.hasOwnProperty.call(field, 'initial')) {
          const { name, initial } = field;
          if (['date', 'time'].includes(initial)) {
            form.setValue(name, moment(initial).isValid() ? moment(initial) : null);
          } else {
            form.setValue(name, initial);
          }
        }
      });
    }
  }, [isOpen, fields]);

  const handleClose = () => {
    onClose?.();
  };

  const handleClosed = () => {
    form.reset();
    setFormFields([]);
    onClosed?.();
    setIsLoader(false);
  };

  const submitForm = form.handleSubmit((values) => {
    setIsLoader(true);

    const variables = formFields.reduce((acc, field) => {
      const key = field.name;
      const { type, options } = field;
      const input = values[field.name];

      const getValue = () => {
        if (['int', 'float'].includes(type) && input === '') return null;
        if (['int', 'float'].includes(type)) return input.toString();
        if (type === 'checkbox') return input ? 'True' : 'False';

        return input;
      };

      if (type === 'radio') {
        options.forEach((o) => {
          acc[o.name] = o.value === input ? 'True' : 'False';
        });
      } else {
        acc[key] = getValue();
      }

      return acc;
    }, {});

    addViolation(variables, handleClose);
  });

  return (
    <MDialog
      title="Additional Information"
      isOpen={isOpen}
      onClose={handleClose}
      onClosed={handleClosed}
      fullWidth
      scrollable
      maxWidth="xs"
      footerActions={
        readonly ? (
          <MButton type="button" size="large" onClick={handleClose}>
            Ok
          </MButton>
        ) : (
          <>
            <MButton variant="secondary" size="large" onClick={handleClose} disabled={isLoader}>
              Cancel
            </MButton>
            <MButton
              type="submit"
              size="large"
              disabled={isLoader || !form.formState.isValid}
              onClick={submitForm}
            >
              Submit
            </MButton>
          </>
        )
      }
    >
      {(isLoader || isLoading) && <LoaderCover />}
      <Form form={form}>
        <Stack direction="column" flexWrap="wrap" rowGap={3} columnGap={3}>
          {formFields.map((formField) => {
            const { type, name, label, options, initial } = formField;
            const required = (() => {
              if (readonly) return false;
              if (Object.prototype.hasOwnProperty.call(formField, 'required'))
                return formField.required;
              return true;
            })();
            if (type === 'date') {
              return (
                <Field
                  key={name}
                  name={name}
                  isMandatory={required}
                  render={({ field, onCustomChange }) => (
                    <DateOrTimePicker
                      isMandatory={required}
                      onChange={onCustomChange(field.onChange)}
                      name={name}
                      label={label}
                      selected={field.value}
                      Svg={Calender}
                      isDate
                      setSelectedTimeOrDate={onCustomChange(field.onChange)}
                      height="48px"
                      width="100%"
                      placeholder="MM/DD/YYYY"
                      disabled={readonly}
                    />
                  )}
                />
              );
            }

            if (type === 'time') {
              return (
                <Field
                  key={name}
                  name={name}
                  isMandatory={required}
                  render={({ field, onCustomChange }) => (
                    <DateOrTimePicker
                      isMandatory={required}
                      onChange={onCustomChange(field.onChange)}
                      name={name}
                      label={label}
                      selected={field.value}
                      Svg={Clock}
                      isTime
                      setSelectedTimeOrDate={onCustomChange(field.onChange)}
                      height="48px"
                      width="100%"
                      placeholder="hh:mm"
                      disabled={readonly}
                    />
                  )}
                />
              );
            }

            if (type === 'checkbox') {
              return (
                <Box
                  width="100%"
                  maxHeight="72px"
                  minHeight="24px"
                  display="flex"
                  alignItems="flex-end"
                >
                  <Field
                    key={name}
                    name={name}
                    render={({ field, onCustomChange }) => (
                      <CheckboxWithLabel
                        label={label}
                        marginTop={0}
                        isChecked={field.value}
                        onChange={onCustomChange(field.onChange)}
                        isDisabled={readonly}
                      />
                    )}
                  />
                </Box>
              );
            }

            if (type === 'radio') {
              return (
                <Field
                  key={name}
                  name={name}
                  isMandatory={required}
                  render={({ field, onCustomChange }) => (
                    <CustomRadioGroup
                      data={options.map((o, index) => ({
                        ...o,
                        id: index,
                      }))}
                      value={field.value}
                      label={label}
                      width="100%"
                      onChange={onCustomChange(field.onChange)}
                      defaultValue={initial || null}
                      disabled={readonly}
                      isMandatory={required}
                    />
                  )}
                />
              );
            }

            if (['int', 'float', 'text'].includes(type)) {
              return (
                <Field
                  name={name}
                  key={name}
                  isMandatory={required}
                  render={({ field, error }) => (
                    <EditInputWithLabel
                      error={error}
                      type={type}
                      label={label}
                      placeholder={type === 'text' ? 'Enter text' : '0'}
                      width="100%"
                      name={name}
                      value={field.value}
                      onChange={field.onChange}
                      isMandatory={required}
                      isDisabled={readonly}
                    />
                  )}
                />
              );
            }

            if (type === 'multiline') {
              return (
                <Field
                  key={label}
                  name={name}
                  isMandatory={required}
                  render={({ field, error }) => (
                    <MultilineTextField
                      error={error}
                      isMandatory={required}
                      width="100%"
                      label={label}
                      rows={4}
                      placeholder="Enter text"
                      onChange={field.onChange}
                      value={field.value}
                      disabled={readonly}
                    />
                  )}
                />
              );
            }

            return null;
          })}
        </Stack>
      </Form>
    </MDialog>
  );
};

AdditionalInformationFormDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onClosed: PropTypes.func,
  addViolation: PropTypes.func,
  readonly: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

AdditionalInformationFormDialog.defaultProps = {
  onClosed: () => {},
  addViolation: () => {},
  readonly: false,
  isLoading: false,
  fields: [],
};

export default AdditionalInformationFormDialog;
