import React from 'react';
import { Pagination, Stack, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { palette } from '../../../theme/default';

const StyledPagination = styled(Pagination)({
  '& li button': {
    fontSize: '16px',
    fontWeight: 500,
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
  },
  '& li:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${palette.buttonSecondary.pressedBorder}`,
    borderRadius: '10px',
    width: '32px',
  },
  '& li:last-of-type': {
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${palette.buttonSecondary.pressedBorder}`,
    borderRadius: '10px',
    width: '32px',
  },
});

const MPagination = ({ count, handleChange, currentPage }) => {
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 16px',
        marginTop: '32px',
      }}
      data-testid="pagination_wrapper"
    >
      <Stack>
        <Typography
          color={palette.text.secondary}
          fontSize="16px"
          fontWeight={500}
          data-testid="count_text"
        >
          {`Page ${currentPage} of ${count}`}
        </Typography>
      </Stack>
      <StyledPagination
        count={count}
        defaultPage={1}
        siblingCount={0}
        boundaryCount={1}
        onChange={handleChange}
        page={currentPage}
        data-testid="pagination_actions"
        sx={{
          '& li button': {
            width: '32px',
            height: '32px',
            color: palette.text.secondary,
          },
          '& li button:hover': {
            backgroundColor: 'transparent',
          },
          '& li button.Mui-selected': {
            backgroundColor: 'transparent',
            color: palette.text.browseFile,
          },
          '& li button.Mui-selected:hover': {
            backgroundColor: 'transparent',
          },
        }}
      />
    </Stack>
  );
};

MPagination.propTypes = {
  count: PropTypes.number,
  currentPage: PropTypes.number,
  handleChange: PropTypes.func,
};

MPagination.defaultProps = {
  count: 0,
  currentPage: 0,
  handleChange: () => null,
};

export default MPagination;
