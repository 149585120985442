import React, { useCallback, useLayoutEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import TextInput from '../../../MUI/MUITextInput';

export const TextInputCell = ({
  value: initialValue,
  changeValueCell,
  editableRowIndex,
  column,
  row,
}) => {
  const [value, setValue] = useState('');
  const isEditingCell = editableRowIndex === row.index;
  const isObjectValue = initialValue !== null && typeof initialValue === 'object';

  const handleChange = useCallback(({ target: { value: nextValue } }) => {
    setValue(nextValue);
    changeValueCell(
      column.id,
      nextValue !== null && isObjectValue ? { ...initialValue, value: nextValue } : nextValue,
    );
  }, []);

  useLayoutEffect(() => {
    setValue(initialValue !== null && isObjectValue ? initialValue.value : initialValue);
  }, [initialValue, isEditingCell]);

  if (column.edit && isEditingCell) {
    return (
      <TextInput
        value={value}
        onChange={handleChange}
        height={32}
        error={column.isMandatory ? !value : false}
        helperText={
          column.showHelperText && column.isMandatory && !value ? 'Mandatory field' : null
        }
        data-testid="input_wrapper"
        inputProps={{ 'data-testid': 'input' }}
      />
    );
  }

  return isObjectValue ? initialValue.label : value;
};

TextInputCell.propTypes = {
  value: PropTypes.string.isRequired,
  changeValueCell: PropTypes.func.isRequired,
  editableRowIndex: PropTypes.number.isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
    edit: PropTypes.bool,
    isMandatory: PropTypes.bool,
    showHelperText: PropTypes.bool,
  }).isRequired,
  row: PropTypes.shape({ index: PropTypes.number }).isRequired,
};
