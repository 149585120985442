import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import CommentNew from '../../molecules/Comment/CommentNew';

const NotesNew = ({ title, list }) => (
  <Stack spacing={4} data-testid="note_wrapper">
    <Typography variant="h5" data-testid="note_title">
      {title}
    </Typography>
    <Stack spacing={10}>
      {list.map((item) => (
        <CommentNew
          key={item.createdAt}
          createdAt={item.createdAt}
          comment={item.comment}
          ava={item.ava || ''}
          name={item.userFullName || 'Default Name'}
        />
      ))}
    </Stack>
  </Stack>
);

NotesNew.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      comment: PropTypes.string.isRequired,
      name: PropTypes.string,
      ava: PropTypes.string,
    }),
  ).isRequired,
};

export default NotesNew;
