/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getUserProfile = createAsyncThunk('userProfileSlice/getUserProfile', async () => {
  const { data } = await apiClient.get(`/api/users/profile`);

  return data;
});

export const getCPUserProfile = createAsyncThunk('userProfileSlice/getCPUserProfile', async () => {
  const { data } = await apiClient.get(`/cp/api/users/profile`);

  return data;
});

export const updateUserProfile = createAsyncThunk(
  'userProfileSlice/updateUserProfile',
  async (args, { rejectWithValue }) => {
    const { values, isCP } = args;
    try {
      const formData = new FormData();
      formData.append('File', values.file || '');
      formData.append('FileExtension', values.fileExtension || '');
      formData.append('Email', values.email || '');
      formData.append('FirstName', values.firstName || '');
      formData.append('LastName', values.lastName || '');
      formData.append('MiddleName', values.middleName || '');
      formData.append('PrimaryStateCode', values.primaryState || '');
      formData.append('Suffix', values.suffix || '');
      formData.append('UserName', values.userName || '');
      formData.append('LandingPage', values.landingPage || '');

      const url = isCP ? '/cp/api/users/profile' : '/api/users/profile';
      const { data } = await apiClient.post(url, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errorMessage);
    }
  },
);

const initialState = {
  profile: {},
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {},
  extraReducers: {
    [getUserProfile.fulfilled]: (state, action) => {
      state.profile = action.payload.result;
    },
    [getUserProfile.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCPUserProfile.fulfilled]: (state, action) => {
      state.profile = action.payload.result;
    },
    [getCPUserProfile.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default userProfileSlice.reducer;
