import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MDialog from '../../../atoms/MDialog';
import MButton from '../../../MUI/Button/MButton';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import MTooltip from '../../../atoms/MTooltip';

const ApproveDialog = ({ isOpen, onClose, onConfirm }) => {
  const [comment, setComment] = useState('');

  const handleClosed = () => {
    setComment('');
  };

  return (
    <MDialog
      isOpen={isOpen}
      onClose={onClose}
      onClosed={handleClosed}
      title="Notices Approval"
      fullWidth
      footerActions={[
        <MButton variant="secondary" size="large" onClick={onClose}>
          Cancel
        </MButton>,
        <MButton type="submit" size="large" onClick={() => onConfirm(comment)}>
          Approve
        </MButton>,
      ]}
    >
      <EditInputWithLabel
        type="text"
        label="Comment"
        placeholder="Add comment"
        width="518px"
        name="comment"
        value={comment}
        onChange={(ev) => setComment(ev.target.value)}
        tooltip={
          <MTooltip
            text="Comments are visible to clients."
            customStyles={{ position: 'absolute', top: '-10px', right: '-30px' }}
          />
        }
      />
    </MDialog>
  );
};

ApproveDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ApproveDialog.defaultProps = {};

export default ApproveDialog;
