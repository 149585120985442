/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getContacts = createAsyncThunk('contact/getContacts', async (params = {}) => {
  const { data } = await apiClient.post(`/api/contacts/filter`, params);

  return data;
});

export const getCombinedContacts = createAsyncThunk(
  'contact/getCombinedContacts',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/contacts/combined/filter`, params);

    return data;
  },
);

const initialState = {
  contacts: {},
  combinedContacts: {},
  error: null,
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: {
    [getContacts.fulfilled]: (state, action) => {
      state.contacts = action.payload.result;
    },
    [getContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCombinedContacts.fulfilled]: (state, action) => {
      state.combinedContacts = action.payload.result;
    },
  },
});

export default contactsSlice.reducer;
