import { EditCell } from '../../atoms/Table/TableCells/EditCell';
import { TextInputCell } from '../../atoms/Table/TableCells/TextInputCell';
import { DeleteCell } from '../../atoms/Table/TableCells/DeleteCell';
import { ReferenceNumberCell } from '../Processing/CaseSearch/CaseSearchCells';
import { DuplicateOrRelatedCell } from '../Processing/PartiesAndPrecinct/DuplicateOrRelatedCell';

export const columnsList = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    Cell: (props) => <ReferenceNumberCell {...props} isBlank />,
    width: 50,
  },
  {
    Header: 'Related',
    accessor: 'isRelated',
    width: 25,
    Cell: DuplicateOrRelatedCell,
  },
  {
    Header: 'Duplicate',
    accessor: 'isDuplicate',
    Cell: DuplicateOrRelatedCell,
    width: 25,
  },
];

export const evictionIntakeFormConfigForArizona = [
  {
    boxTitle: 'Opposing Party',
    name: 'opposingParty',
    inputBoxes: [
      {
        label: 'Individual(s)',
        isSubHeading: true,
      },
      {
        label: 'First Name',
        name: 'individual.firstName',
        placeholder: 'Enter first name',
        width: '311px',
        isMandatory: true,
      },
      {
        label: 'Middle Name',
        name: 'individual.middleName',
        placeholder: 'Enter middle name',
        width: '311px',
        isMandatory: false,
      },
      {
        label: 'Last Name',
        name: 'individual.lastName',
        placeholder: 'Enter last name',
        width: '311px',
        isMandatory: true,
      },
      {
        label: 'Suffix',
        name: 'individual.suffix',
        placeholder: 'Enter suffix',
        width: '199px',
        isMandatory: false,
      },
      {
        isAddButtonWithTable: true,
        table: 'individualTable',
      },
    ],
  },

  {
    boxTitle: 'Address',
    name: 'community',
    inputBoxes: [
      {
        label: 'Address',
        name: 'address',
        placeholder: 'Select address',
        width: '326px',
        isDropdown: true,
        isRadioButton: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'City',
        name: 'community.city',
        placeholder: '-',
        width: '208px',
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'State',
        name: 'community.stateCode',
        placeholder: '-',
        width: '94px',
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'ZIP Code',
        name: 'community.zipCode',
        placeholder: '-',
        width: '208px',
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Apartment',
        name: 'community.apartmentNumber',
        placeholder: 'xxxxx',
        width: '209px',
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'No apartment number',
        name: 'community.hasNoApartment',
        isCheckbox: true,
        isChecked: false,
        isRadioButton: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Property Type',
        name: 'community.propertyType',
        placeholder: 'Select property type',
        width: '220px',
        isDropdown: true,
        isMandatory: false,
      },
    ],
  },
  {
    boxTitle: 'Documents',
    name: 'documents',
    inputBoxes: [
      {
        label: 'Notice',
        isSubHeading: true,
      },
      {
        label: 'Delivered by',
        name: 'notice.deliveredBy',
        placeholder: 'Select Delivered by',
        width: '311px',
        isDropdown: true,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: false,
      },
      {
        label: '',
        isSubHeading: false,
        type: 'notice',
        isSubHeadingWithButton: true,
        buttonName: 'Upload Notice Document',
      },
      {
        label: '',
        name: 'notice.document',
        placeholder: '',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
      {
        label: 'Lease',
        isSubHeading: true,
      },
      {
        label: 'Lease Unavailable',
        name: 'lease.isDocumentNotRequired',
        isCheckbox: true,
        isMandatory: true,
        tooltip: 'Please select if lease is unavailable.',
      },
      {
        label: '',
        isSubHeading: false,
        type: 'lease',
        isSubHeadingWithButton: true,
        buttonName: 'Upload Lease Document',
      },
      {
        label: '',
        name: 'lease.document',
        placeholder: '',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
      {
        label: 'Ledger',
        isSubHeading: true,
      },
      {
        label: 'Ledger Unavailable',
        name: 'ledger.isDocumentNotRequired',
        isCheckbox: true,
        isMandatory: true,
        tooltip: 'Please select if ledger is unavailable.',
      },
      {
        label: '',
        isSubHeading: false,
        type: 'ledger',
        isSubHeadingWithButton: true,
        buttonName: 'Upload Ledger Document',
      },
      {
        label: '',
        name: 'ledger.document',
        placeholder: '',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
    ],
  },
];

export const PROPERTY_TYPE = [
  { id: 0, value: 'Apartment', label: 'Apartment', default: true },
  { id: 1, value: 'Mobile Home Park', label: 'Mobile Home Park' },
];

export const evictionIntakeFormConfigForNevada = [
  {
    boxTitle: 'Opposing Party',
    name: 'opposingParty',
    inputBoxes: [
      {
        label: 'Individual(s)',
        isSubHeading: true,
      },
      {
        label: 'First Name',
        name: 'individual.firstName',
        placeholder: 'Enter first name',
        width: '311px',
        isMandatory: true,
      },
      {
        label: 'Middle Name',
        name: 'individual.middleName',
        placeholder: 'Enter middle name',
        width: '311px',
        isMandatory: false,
      },
      {
        label: 'Last Name',
        name: 'individual.lastName',
        placeholder: 'Enter last name',
        width: '311px',
        isMandatory: true,
      },
      {
        label: 'Suffix',
        name: 'individual.suffix',
        placeholder: 'Enter suffix',
        width: '199px',
        isMandatory: false,
      },
      {
        isAddButtonWithTable: true,
        table: 'individualTable',
      },
    ],
  },
  {
    boxTitle: 'Address',
    name: 'community',
    inputBoxes: [
      {
        label: 'Address',
        name: 'address',
        placeholder: 'Select address',
        width: '326px',
        isDropdown: true,
        isRadioButton: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'City',
        name: 'community.city',
        placeholder: '-',
        width: '208px',
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'State',
        name: 'community.stateCode',
        placeholder: '-',
        width: '94px',
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'ZIP Code',
        name: 'community.zipCode',
        placeholder: '-',
        width: '208px',
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Apartment',
        name: 'community.apartmentNumber',
        placeholder: 'xxxxx',
        width: '209px',
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'No apartment number',
        name: 'community.hasNoApartment',
        isCheckbox: true,
        isChecked: false,
        isRadioButton: false,
        isMandatory: false,
        isDisabled: false,
      },
    ],
  },
  {
    boxTitle: 'Documents',
    name: 'documents',
    inputBoxes: [
      {
        label: 'Notice',
        isSubHeading: true,
      },
      {
        label: '',
        isSubHeading: false,
        type: 'notice',
        isSubHeadingWithButton: true,
        buttonName: 'Upload Notice Document',
      },
      {
        label: '',
        name: 'notice.document',
        placeholder: '',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
      {
        label: 'Lease',
        isSubHeading: true,
      },
      {
        label: 'Lease Unavailable',
        name: 'lease.isDocumentNotRequired',
        isCheckbox: true,
        isMandatory: true,
        tooltip: 'Please select if lease is unavailable.',
      },
      {
        label: '',
        isSubHeading: false,
        type: 'lease',
        isSubHeadingWithButton: true,
        buttonName: 'Upload Lease Document',
      },
      {
        label: '',
        name: 'lease.document',
        placeholder: '',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
      {
        label: 'Ledger',
        isSubHeading: true,
      },
      {
        label: 'Ledger Unavailable',
        name: 'ledger.isDocumentNotRequired',
        isCheckbox: true,
        isMandatory: true,
        tooltip: 'Please select if ledger is unavailable.',
      },
      {
        label: '',
        isSubHeading: false,
        type: 'ledger',
        isSubHeadingWithButton: true,
        buttonName: 'Upload Ledger Document',
      },
      {
        label: '',
        name: 'ledger.document',
        placeholder: '',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
    ],
  },
];

export const INDIVIDUALS_COLUMNS = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 25,
    edit: true,
    isMandatory: true,
    Cell: TextInputCell,
    alignItems: 'flex-start',
    showHelperText: true,
  },
  {
    Header: 'Middle Name',
    accessor: 'middleName',
    width: 25,
    edit: true,
    Cell: TextInputCell,
    alignItems: 'flex-start',
    showHelperText: true,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 25,
    edit: true,
    isMandatory: true,
    Cell: TextInputCell,
    alignItems: 'flex-start',
    showHelperText: true,
  },
  {
    Header: 'Suffix',
    accessor: 'suffix',
    width: 20,
    edit: true,
    Cell: TextInputCell,
    alignItems: 'flex-start',
    showHelperText: true,
  },
  {
    Header: '',
    width: 5,
    minWidth: 75,
    accessor: 'control',
    Cell: EditCell,
    alignItems: 'flex-start',
  },
];

export const DOCUMENT_COLUMNS = [
  {
    Header: 'Document Name',
    accessor: 'documentName',
    width: 552,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 552,
  },
  {
    Header: '',
    accessor: 'control',
    width: 30,
    Cell: DeleteCell,
  },
];

export const uploadDocumentsColumns = [
  {
    Header: 'Document name',
    accessor: 'documentName',
    width: 530,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 130,
    edit: true,
  },
  {
    Header: '',
    width: 60,
    accessor: 'control',
    Cell: DeleteCell,
  },
];

export const documentStrings = {
  title: 'Documents',
  boxes: [
    {
      title: 'Create new document',
      selectLabel: 'Select the document',
    },
    {
      title: 'Upload document',
    },
  ],
  upload: {
    title: 'Upload Document',
    text: 'Drop your documents here, or select',
    action: 'Click to browse',
    add: 'Add document',
    cancel: 'Cancel',
    submit: 'Upload',
  },
};
