/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';
import { CIVIL_MATTER_CASE } from '../../../global/Case.constants';

export const getCases = createAsyncThunk(
  'casesGeneral/getCases',
  async ({ filter, currentPage }) => {
    const {
      data: { result },
    } = await apiClient.post(`/api/cases/filter`, {
      currentPage,
      pageSize: 20,
      ...Object.keys(filter).reduce((acc, key) => {
        if (filter[key]) acc[key] = filter[key];
        return acc;
      }, {}),
    });
    return result;
  },
);

export const getPartiesAndPrecinct = createAsyncThunk(
  'casesGeneral/getPartiesAndPrecinct',
  async ({ caseId, stateCode }) => {
    const apiPath =
      stateCode === 'AZ'
        ? `/api/arizonaCases/${caseId}/partiesAndPrecinct`
        : `/api/nevadaCases/${caseId}/partiesAndPrecinct`;

    const { data } = await apiClient.get(apiPath);
    return data;
  },
);

export const getContactPersons = createAsyncThunk(
  'casesGeneral/getContactPersons',
  async ({ stateCode, ...params }) => {
    const { data } = await apiClient.post(
      `/api/${stateCode === 'AZ' ? 'arizona' : 'nevada'}Cases/${
        params.caseId
      }/combinedContacts/filter`,
      params,
    );

    return data;
  },
);

export const getContactPersonsByAddressId = createAsyncThunk(
  'casesGeneral/getContactPersonsByAddressId',
  async ({ id, ...params }) => {
    const { data } = await apiClient.post(`/api/addresses/${id}/combinedContacts/filter`, {
      ...params,
      addressId: id,
    });

    return data;
  },
);

export const getContactPersonsByManagement = createAsyncThunk(
  'casesGeneral/getContactPersonsByManagement',
  async (id) => {
    const { data } = await apiClient.get(`/api/managements/${id}/contacts`);

    return data;
  },
);

export const createCase = createAsyncThunk(
  'casesGeneral/createCase',
  async (body, { rejectWithValue }) => {
    const { stateCode } = body;
    const { caseType, ...rest } = body;

    let apiPath = '';

    if (caseType === CIVIL_MATTER_CASE) {
      apiPath = stateCode === 'AZ' ? '/api/arizonaCases/civils' : '/api/nevadaCases/civils';
    } else {
      apiPath = stateCode === 'AZ' ? '/api/arizonaCases' : '/api/nevadaCases';
    }

    try {
      const { data } = await apiClient.post(apiPath, rest);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateCase = createAsyncThunk(
  'casesGeneral/updateCase',
  async ({ id, body }, { rejectWithValue }) => {
    const { stateCode } = body;
    const { caseType, ...rest } = body;

    const apiPath = stateCode === 'AZ' ? `/api/arizonaCases/${id}` : `/api/nevadaCases/${id}`;

    try {
      const { data } = await apiClient.put(apiPath, rest);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const removeCase = createAsyncThunk('casesGeneral/removeCase', async (id) => {
  const { data } = await apiClient.delete(`/api/cases/${id}`);
  return data;
});

export const updateCaseNumber = createAsyncThunk(
  'casesGeneral/updateCaseNumber',
  async ({ id, ...rest }) => {
    const { data } = await apiClient.put(`/api/nevadaCases/${id}/caseInformation`, {
      caseId: id,
      ...rest,
    });
    return data;
  },
);

export const addDefendantToCase = createAsyncThunk(
  'casesGeneral/addDefendantToCase',
  async ({ id, body }) => {
    const { data } = await apiClient.post(`/api/cases/${id}/defendants`, body);
    return data;
  },
);

export const updateDefendantInCase = createAsyncThunk(
  'casesGeneral/updateDefendantInCase',
  async ({ id, defendantId, body }) => {
    const { data } = await apiClient.put(`/api/cases/${id}/defendants/${defendantId}`, body);
    return data;
  },
);

export const removeDefendantFromCase = createAsyncThunk(
  'casesGeneral/removeDefendantFromCase',
  async ({ id, defendantId }) => {
    const { data } = await apiClient.delete(`/api/cases/${id}/defendants/${defendantId}`);
    return data;
  },
);

export const addEntityToCase = createAsyncThunk(
  'casesGeneral/addEntityToCase',
  async ({ id, body }) => {
    const { data } = await apiClient.post(`/api/cases/${id}/entities`, body);
    return data;
  },
);

export const updateEntityInCase = createAsyncThunk(
  'casesGeneral/updateEntityInCase',
  async ({ id, caseEntityId, body }) => {
    const { data } = await apiClient.put(`/api/cases/${id}/entities/${caseEntityId}`, body);
    return data;
  },
);

export const removeEntityFromCase = createAsyncThunk(
  'casesGeneral/removeEntityFromCase',
  async ({ id, caseEntityId }) => {
    const { data } = await apiClient.delete(`/api/cases/${id}/entities/${caseEntityId}`);
    return data;
  },
);

export const updateViolationInCase = createAsyncThunk(
  'casesGeneral/updateViolationInCase',
  async ({ id, violationId, body }) => {
    const { data } = await apiClient.put(`/api/cases/${id}/violations/${violationId}`, body);
    return data;
  },
);

export const removeViolationFromCase = createAsyncThunk(
  'casesGeneral/removeViolationFromCase',
  async ({ id, violationId }) => {
    const { data } = await apiClient.delete(`/api/cases/${id}/violations/${violationId}`);
    return data;
  },
);

export const getCaseNotes = createAsyncThunk('casesGeneral/getCaseNotes', async (id) => {
  const { data } = await apiClient.get(`/api/cases/${id}/notes`);
  return data;
});

export const addNoteToCase = createAsyncThunk(
  'casesGeneral/addNoteToCase',
  async (addNoteToCaseObj) => {
    const { id, ...body } = addNoteToCaseObj;
    const { data } = await apiClient.post(`/api/cases/${id}/notes`, body);

    return data;
  },
);

export const getAddresses = createAsyncThunk(
  'casesGeneral/getAddresses',
  async (params = {}, thunkAPI) => {
    const {
      getAddresses: { items: prevItems = [] },
    } = thunkAPI.getState().casesGeneral;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/cases/addresses/filter`, params);

    const currentStateItems = items.map((address) => ({
      id: address.addressId,
      label: address?.community?.address,
      value: address.addressId,
      data: address,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getNoticeTypes = createAsyncThunk('casesGeneral/getNoticeTypes', async () => {
  const { data } = await apiClient.get('/api/nevadaCases/noticeTypes');
  return data;
});

export const getAllAttorneys = createAsyncThunk('casesGeneral/getAllAttorneys', async (id) => {
  const { data } = await apiClient.get(`/api/cases/${id}/attorneys`);
  return data;
});

export const uploadDocument = createAsyncThunk(
  'casesGeneral/uploadDocument',
  async ({ id, data: filesToUpload }) => {
    const formData = new FormData();

    filesToUpload.forEach((item, index) => {
      formData.append(`request[${index}].documentName`, item.name);
      formData.append(`request[${index}].type`, item.type);
      formData.append(`request[${index}].file`, item.file);
    });

    const { data } = await apiClient.post(`/api/cases/${id}/documents/upload`, formData, {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  },
);

export const deleteDocument = createAsyncThunk('casesGeneral/deleteDocument', async (args) => {
  const { id, ...restParams } = args;
  const { data } = await apiClient.delete(`/api/cases/${id}/documents`, {
    data: { ...restParams },
    headers: { 'Content-Type': 'application/json' },
  });

  return data;
});

export const getDocumentTemplates = createAsyncThunk(
  'casesGeneral/getDocumentTemplates',
  async ({ caseId, stateCode }) => {
    const apiPath =
      stateCode === 'AZ'
        ? `/api/arizonaCases/${caseId}/templates`
        : `/api/nevadaCases/${caseId}/templates`;
    const { data } = await apiClient.get(apiPath);
    return data;
  },
);

export const getDocuments = createAsyncThunk('casesGeneral/getDocuments', async (params) => {
  const { data } = await apiClient.post(`/api/cases/${params.id}/documents/filter`, params);

  return data;
});

export const createDocument = createAsyncThunk(
  'casesGeneral/createDocument',
  async ({ id, templateId, ...body }) => {
    const { data } = await apiClient.post(`/api/cases/${id}/documents/${templateId}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  },
);

export const getDocument = createAsyncThunk(
  'casesGeneral/getDocument',
  async ({ id, documentId }) => {
    const { data } = await apiClient.get(`/api/cases/${id}/documents/${documentId}`);

    return data;
  },
);

export const updateDocument = createAsyncThunk(
  'casesGeneral/updateDocument',
  async ({ id, documentId, ...body }) => {
    const { data } = await apiClient.put(`/api/cases/${id}/documents/${documentId}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  },
);

export const getCivilMattersPdf = createAsyncThunk(
  'casesGeneral/getCivilMattersPdf',
  async (id) => {
    const { data } = await apiClient.get(`/api/cases/${id}/civilMatters/export/pdf`);

    return data;
  },
);

export const getCivilMattersCsv = createAsyncThunk(
  'casesGeneral/getCivilMattersCsv',
  async (id) => {
    const { data } = await apiClient.get(`/api/cases/${id}/civilMatters/export/csv`);

    return data;
  },
);

export const hideDocumentforCP = createAsyncThunk(
  'casesGeneral/hideDocumentforCP',
  async ({ documentId, body }) => {
    const { data } = await apiClient.put(`/api/cases/documents/${documentId}/hide`, body);
    return data;
  },
);
const initialState = {
  getCases: [],
  partiesAndPrecinct: [],
  contactPersons: {
    items: [],
  },
  createCase: null,
  listOfCaseDocuments: [],
  document: null,
  updateCase: null,
  removeCase: null,
  updateCaseNumber: null,
  addDefendantToCase: null,
  updateDefendantInCase: null,
  removeDefendantFromCase: null,
  updateViolationInCase: null,
  removeViolationFromCase: null,
  notes: [],
  documentTemplates: [],
  documents: {},
  getAddresses: {
    items: [],
  },
  error: null,
  getNoticeTypes: [],
  getAllAttorneys: [],
  hideDocumentforCP: null,
};

export const casesGeneralSlice = createSlice({
  name: 'casesGeneral',
  initialState,
  reducers: {},
  extraReducers: {
    [getCases.fulfilled]: (state, action) => {
      state.getCases = action.payload;
    },
    [getCases.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getPartiesAndPrecinct.fulfilled]: (state, action) => {
      state.partiesAndPrecinct = action.payload;
    },
    [getPartiesAndPrecinct.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getContactPersons.fulfilled]: (state, action) => {
      state.contactPersons = action.payload.result;
    },
    [getContactPersons.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getContactPersonsByAddressId.fulfilled]: (state, action) => {
      state.contactPersons = action.payload.result;
    },
    [getContactPersonsByAddressId.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getContactPersonsByManagement.fulfilled]: (state, action) => {
      state.contactPersons = {
        items: action.payload.result.map((item) => {
          return {
            ...item,
            contactEmail: item.email,
            contactMobilePhone: item.mobilePhone,
            contactName: item.name,
            contactWorkPhone: item.workPhone,
            contactTitle: item.title,
          };
        }),
      };
    },
    [getContactPersonsByManagement.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createCase.fulfilled]: (state, action) => {
      state.createCase = action.payload;
    },
    [getDocument.fulfilled]: (state, action) => {
      state.document = action.payload;
    },
    [getDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateCase.fulfilled]: (state, action) => {
      state.updateCase = action.payload;
    },
    [updateCase.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [removeCase.fulfilled]: (state, action) => {
      state.removeCase = action.payload;
    },
    [removeCase.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateCaseNumber.fulfilled]: (state, action) => {
      state.updateCaseNumber = action.payload;
    },
    [updateCaseNumber.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [addDefendantToCase.fulfilled]: (state, action) => {
      state.addDefendantToCase = action.payload;
    },
    [addDefendantToCase.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateDefendantInCase.fulfilled]: (state, action) => {
      state.updateDefendantInCase = action.payload;
    },
    [updateDefendantInCase.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [removeDefendantFromCase.fulfilled]: (state, action) => {
      state.removeDefendantFromCase = action.payload;
    },
    [removeDefendantFromCase.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateViolationInCase.fulfilled]: (state, action) => {
      state.updateViolationInCase = action.payload;
    },
    [updateViolationInCase.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [removeViolationFromCase.fulfilled]: (state, action) => {
      state.removeViolationFromCase = action.payload;
    },
    [removeViolationFromCase.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCaseNotes.fulfilled]: (state, action) => {
      state.notes = action.payload.result;
    },
    [getCaseNotes.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [addNoteToCase.fulfilled]: (state, action) => {
      state.notes = action.payload.result;
    },
    [addNoteToCase.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.getAddresses = action.payload;
    },
    [getAddresses.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getDocuments.fulfilled]: (state, action) => {
      state.documents = action.payload.result;
    },
    [getDocuments.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getDocumentTemplates.fulfilled]: (state, action) => {
      state.documentTemplates = action.payload.result;
    },
    [getDocumentTemplates.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [uploadDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getNoticeTypes.fulfilled]: (state, action) => {
      state.getNoticeTypes = action.payload.result.map(({ noticeType }) => ({
        id: noticeType,
        label: noticeType,
        value: noticeType,
      }));
    },
    [getNoticeTypes.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAllAttorneys.fulfilled]: (state, action) => {
      state.getAllAttorneys = action.payload.result.map(
        ({ userId, firstName, lastName, attorneyRate }) => ({
          id: userId,
          label: [firstName, lastName].join(' '),
          value: userId,
          attorneyRate,
        }),
      );
    },
    [getAllAttorneys.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [hideDocumentforCP.fulfilled]: (state, action) => {
      state.hideDocumentforCP = action.payload;
    },
    [hideDocumentforCP.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default casesGeneralSlice.reducer;
