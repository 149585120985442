import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';

export const AddCourtDatesColumns = [
  {
    Header: 'Court Name',
    accessor: 'courtName',
    width: 560,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDate',
    width: 290,
  },
  {
    Header: 'Court Time',
    accessor: 'courtTime',
    width: 200,
  },
];

export const uploadedDocColumns = [
  {
    Header: 'Document Name',
    accessor: 'documentName',
    width: 94,
  },
  {
    Header: '',
    accessor: 'control',
    width: 6,
    Cell: DeleteCell,
  },
];

export const acceptedFilesExtensions = {
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};
