/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const createCommunity = createAsyncThunk(
  'createCommunity/createCommunity',
  async (createCommunityObj, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/communities`, createCommunityObj);

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

const initialState = {
  communityId: null,
  error: null,
};

export const createCommunitySlice = createSlice({
  name: 'createCommunity',
  initialState,
  reducers: {},
  extraReducers: {
    [createCommunity.fulfilled]: (state, action) => {
      state.communityId = action.payload.result;
    },
    [createCommunity.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default createCommunitySlice.reducer;
