import { Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { palette } from '../../../../../../theme/default';

export const DateCell = (props) => {
  const {
    cell: { value },
  } = props;
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
        color: palette.text.primary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {moment(value).format('L')}
    </Typography>
  );
};

DateCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
};
