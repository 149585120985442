import React from 'react';
import PropTypes from 'prop-types';
import { styled, Typography, List, ListItem } from '@mui/material';
import { palette } from '../../../theme/default';

const StyledList = styled(List)(() => ({
  '&.MuiList-root': {
    display: 'flex',
    alignItems: 'center',
    padding: 0,

    '& a': {
      display: 'flex',
      textDecoration: 'none',
      fontSize: 16,
      fontWeight: 600,
      width: 'auto',
      transition: 'all .2s ease-in',

      '&:last-child': {
        marginRight: 0,
      },
    },

    '& svg': {
      marginLeft: 8,
    },
  },
}));

const StyledTypography = styled(Typography)(
  ({ active, theme, disabled, activeWidth = 'auto', isListVisible }) => {
    const getTypographyColor = () => {
      if (active) {
        return theme.palette.text.primary;
        // eslint-disable-next-line no-else-return
      }
      if (disabled) {
        return theme.palette.text.disabled;
      }
      return theme.palette.text.secondary;
    };

    return {
      width: isListVisible ? activeWidth : 'auto',
      '&.MuiTypography-root': {
        alignItems: 'center',
        display: 'flex',
        padding: '8px 16px',
        fontSize: 16,
        fontWeight: 600,

        '&:hover': {
          cursor: disabled ? 'default' : 'pointer',
        },
      },
      '&.MuiTypography-root span': {
        alignItems: 'center',
        color: getTypographyColor(),
        fontSize: 16,
      },
    };
  },
);

const Tabs = ({ tabs, currentTab, onTabChange }) => {
  return (
    <StyledList data-testid="tabs_wrapper">
      {tabs.map((tab) => {
        if (tab.disabled) {
          return (
            <StyledTypography disabled key={tab.label} data-testid="disabled_tabs">
              <Typography variant="p">{tab.label}</Typography>
            </StyledTypography>
          );
        }
        return (
          <ListItem
            component="a"
            sx={{
              backgroundColor: currentTab?.id === tab.id ? palette.text.disabled : 'transparent',
              color: currentTab?.id === tab.id ? palette.text.primary : palette.text.secondary,
              cursor: 'pointer',
              borderRadius: '10px',
            }}
            key={tab.label}
            onClick={() => onTabChange(tab)}
            data-testid="list_item"
          >
            {tab.label}
          </ListItem>
        );
      })}
    </StyledList>
  );
};

const tabPropType = PropTypes.shape({
  label: PropTypes.string,
  id: PropTypes.func,
});

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(tabPropType).isRequired,
  currentTab: tabPropType,
  onTabChange: PropTypes.func,
};

Tabs.defaultProps = {
  currentTab: null,
  onTabChange: () => {},
};

export default Tabs;
