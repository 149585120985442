import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination, useBlockLayout } from 'react-table';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { Box } from '@mui/material';
import ShadowBlock from '../../atoms/ShadowBlock';
import TablePagination from '../TablePagination';

import { ChangeLogColumns, MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './ChangeLog.constants';
import style from './ChangeLog.module.scss';
import { palette, typography } from '../../../theme/default';
import EmptyBlock from '../EmptyBlock';

const ChangeLog = ({
  title,
  columns,
  loading,
  total,
  pageSize,
  isPagination,
  onFetchPage,
  rows: data,
  maxHeight,
}) => {
  const pageCount = useMemo(() => Math.ceil(total / pageSize), [total]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    rows,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: data || [],
      initialState: { pageSize },
      manualPagination: true,
      pageCount,
    },
    usePagination,
    useBlockLayout,
  );

  const getParams = (currentPage) => ({
    pageSize,
    currentPage,
  });

  const handleNextPage = () => {
    onFetchPage(getParams(pageIndex + 2));
    nextPage();
  };

  const handlePreviousPage = () => {
    onFetchPage(getParams(pageIndex));
    previousPage();
  };

  const handleGotoPage = (item) => {
    onFetchPage(getParams(item + 1));
    gotoPage(item);
  };

  const loader = (
    <div className={style.loaderContainer} data-testid="changelog_loader">
      <div className={style.loader} />
    </div>
  );

  const table = (
    <div className={style.tableWrapper}>
      <div className={style.tableContainer}>
        <div {...getTableProps()} className="table" style={{ maxHeight }}>
          <div {...getTableBodyProps()} className="body" data-testid="changelog_body">
            {page.map((row) => {
              prepareRow(row);
              return (
                <div
                  {...row.getRowProps()}
                  className="tr"
                  style={{ ...row.getRowProps(), width: '100%', display: 'flex' }}
                  data-testid="changelog_row"
                >
                  {row.cells.map((cell) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className="td"
                        style={{
                          ...cell.getCellProps().style,
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          width: '100%',
                          color: palette.text.tertiary,
                          ...typography.bodyS,
                          padding: '5px 0',
                        }}
                        data-testid="changelog_cell"
                      >
                        {cell.render('Cell', {
                          goNextPage: handleNextPage,
                          goPreviousPage: handlePreviousPage,
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isPagination && (
        <TablePagination
          previousPage={handlePreviousPage}
          canPreviousPage={canPreviousPage}
          nextPage={handleNextPage}
          canNextPage={canNextPage}
          rows={rows}
          page={page}
          total={total}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          gotoPage={handleGotoPage}
          pageSize={pageSize}
        />
      )}
    </div>
  );

  return (
    <ShadowBlock>
      <Stack spacing={4}>
        <Typography variant="h5">{title}</Typography>
        {loading && loader}
        {page.length === 0 && !loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            data-testid="changelog_placeholder"
          >
            <EmptyBlock title="Change Log is empty" />
          </Box>
        )}
        <Stack spacing={1}>{page.length > 0 && !loading && table}</Stack>
      </Stack>
    </ShadowBlock>
  );
};

ChangeLog.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  isPagination: PropTypes.bool,
  onFetchPage: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  maxHeight: PropTypes.string,
};

ChangeLog.defaultProps = {
  title: 'Change Log',
  maxHeight: 'auto',
  pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
  loading: false,
  isPagination: false,
  columns: ChangeLogColumns,
};

export default ChangeLog;
