export const printChecksBoxes = [
  {
    label: 'Forcible Checks',
    name: 'createForcible',
  },
  {
    label: 'Writ Checks',
    name: 'createWrit',
  },
  {
    label: 'Writ Checks (constable)',
    name: 'createWritConstable',
  },
];
