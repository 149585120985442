import PropTypes from 'prop-types';
import MButton from '../../../MUI/Button/MButton';
import FullscreenLoaderWithText from '../../../atoms/FullscreenLoaderWithText/FullscreenLoaderWithText';

export const PrintingDialog = ({ amount, cancelPrinting }) => {
  return (
    <FullscreenLoaderWithText
      title={
        <>
          Bulk document is generating.
          <br /> Please wait
        </>
      }
      description={amount ? `(${amount} document(s))` : ''}
      actions={
        <MButton data-testid="cancel_printing" size="large" onClick={cancelPrinting}>
          Cancel
        </MButton>
      }
    />
  );
};

PrintingDialog.propTypes = {
  cancelPrinting: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
};
