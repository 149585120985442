import { TextInputCell } from '../../../atoms/Table/TableCells/TextInputCell';
import { EditCell } from '../../../atoms/Table/TableCells/EditCell';
import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';
import { ActiveTextInputCell } from '../../../atoms/Table/TableCells/ActiveTextInputCell';
import { DocumentsTypeCell } from '../../../atoms/Table/TableCells/DocumentsTypeCell';

export const ClientPortalNoticeIntakeFormBoxes = [
  {
    boxTitle: 'Opposing Party',
    name: 'opposingParty',
    inputBoxes: [
      {
        label: 'Individual(s)',
        isSubHeading: true,
      },
      {
        label: 'First Name',
        name: 'individual.firstName',
        placeholder: 'Enter first name',
        width: '311px',
        isMandatory: true,
      },
      {
        label: 'Middle Name',
        name: 'individual.middleName',
        placeholder: 'Enter middle name',
        width: '311px',
        isMandatory: false,
      },
      {
        label: 'Last Name',
        name: 'individual.lastName',
        placeholder: 'Enter last name',
        width: '311px',
        isMandatory: true,
      },
      {
        label: 'Suffix',
        name: 'individual.suffix',
        placeholder: 'Enter suffix',
        width: '199px',
        isMandatory: false,
      },
      {
        isAddButtonWithTable: true,
        table: 'individualTable',
      },
    ],
  },
  {
    boxTitle: 'Address',
    name: 'address',
    inputBoxes: [
      {
        label: 'Address',
        name: 'addressInfo',
        placeholder: 'Enter address',
        width: '208px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'City',
        name: 'address.city',
        placeholder: '-',
        width: '208px',
        isDisabled: true,
      },
      {
        label: 'State',
        name: 'address.stateCode',
        placeholder: '-',
        width: '94px',
        isDisabled: true,
      },
      {
        label: 'ZIP Code',
        name: 'address.zipCode',
        placeholder: '-',
        width: '208px',
        isDisabled: true,
      },
      {
        label: 'Apartment',
        name: 'address.apartmentNumber',
        placeholder: 'xxxxx',
        width: '209px',
        isDisabled: (form) => !!form.watch('address.hasNoApartment'),
        isMandatory: true,
      },
      {
        label: 'No apartment number',
        name: 'address.hasNoApartment',
        isCheckbox: true,
        isChecked: false,
      },
      {
        label: 'Property Type',
        name: 'address.propertyType',
        placeholder: 'Select property type',
        width: '220px',
        isDropdown: true,
        isMandatory: false,
      },
    ],
  },
  {
    boxTitle: 'Summary of event',
    name: 'summary',
    inputBoxes: [
      {
        label: 'Summary of event',
        name: 'summaryOfEvents',
        placeholder: '',
        isTextArea: true,
      },
    ],
  },
  {
    boxTitle: 'Documents',
    name: 'documents',
    buttonText: 'Upload document',
    inputBoxes: [],
  },
];

export const PROPERTY_TYPE = [
  { id: 0, value: 'Apartment', label: 'Apartment', default: true },
  { id: 1, value: 'Mobile Home Park', label: 'Mobile Home Park' },
];

export const INDIVIDUALS_COLUMNS = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 25,
    edit: true,
    isMandatory: true,
    Cell: TextInputCell,
  },
  {
    Header: 'Middle Name',
    accessor: 'middleName',
    width: 25,
    edit: true,
    Cell: TextInputCell,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 25,
    edit: true,
    isMandatory: true,
    Cell: TextInputCell,
  },
  {
    Header: 'Suffix',
    accessor: 'suffix',
    width: 20,
    edit: true,
    Cell: TextInputCell,
  },
  {
    Header: '',
    width: 5,
    minWidth: 75,
    accessor: 'control',
    Cell: EditCell,
  },
];

export const documentStrings = {
  title: 'Documents',
  boxes: [
    {
      title: 'Create new document',
      selectLabel: 'Select the document',
    },
    {
      title: 'Upload document',
    },
  ],
  upload: {
    title: 'Upload Document',
    text: 'Drop your documents here, or select',
    action: 'Click to browse',
    add: 'Add document',
    cancel: 'Cancel',
    submit: 'Upload',
  },
  button: {
    print: 'Print selected',
    delete: 'Delete selected',
    create: 'Create new document',
    upload: 'Upload document',
    update: 'Update case',
    cancel: 'Cancel',
  },
};

export const uploadDocumentsColumns = [
  {
    Header: 'Document name',
    accessor: 'documentName',
    width: '530',
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: '130',
    Cell: ActiveTextInputCell,
    edit: true,
  },
  {
    Header: '',
    width: '38',
    accessor: 'control',
    Cell: DeleteCell,
  },
];

export const documentsColumns = [
  {
    Header: 'Document name',
    accessor: 'documentName',
    width: 552,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 552,
    edit: true,
    Cell: DocumentsTypeCell,
  },
  {
    Header: '',
    width: 30,
    accessor: 'control',
    Cell: DeleteCell,
  },
];
