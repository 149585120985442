import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@mui/material';
import moment from 'moment';

const DateCell = ({ value }) => {
  const isValidDate = moment(value).isValid();
  return value && isValidDate ? (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
      }}
    >
      {moment(value).format('L')}
    </Typography>
  ) : (
    ''
  );
};

DateCell.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
};

export default DateCell;
