import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { requestRefreshToken } from '../lib/libUtils';
import { tokenRepository } from '../lib/apiClient';

const SESSIONTIMEOUT = 1000 * 60 * 14; // 14 minutes
const PROMPTTIMEOUT = 58; // 59 seconds
const DEBOUNCESTEP = 500; // 1 second

export const useActivityWatcher = () => {
  const [isModal, setIsModal] = useState(false);
  const [secondsToLogout, setSecondsToLogout] = useState(PROMPTTIMEOUT);

  useEffect(() => {
    if (!isModal) {
      setSecondsToLogout(PROMPTTIMEOUT);
      return;
    }

    if (secondsToLogout === 0) {
      setIsModal(false);
    }

    if (secondsToLogout > 0) {
      setTimeout(() => {
        setSecondsToLogout(secondsToLogout - 1);
      }, 1000);
    }
  }, [secondsToLogout, isModal]);

  /* eslint-disable no-use-before-define */
  const onPrompt = () => {
    pause();
    setIsModal(true);
  };

  const { pause, reset } = useIdleTimer({
    timeout: SESSIONTIMEOUT,
    promptTimeout: PROMPTTIMEOUT,
    debounce: DEBOUNCESTEP,
    stopOnIdle: true,
    onPrompt,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    crossTab: true,
  });

  const okCallback = async () => {
    setIsModal(false);
    reset();
    const response = await requestRefreshToken(tokenRepository);
    const { accessToken, refreshToken } = response.data.result;
    tokenRepository.setToken(accessToken);
    tokenRepository.setRefreshToken(refreshToken);
  };

  return {
    isModal,
    secondsToLogout,
    okCallback,
  };
};
