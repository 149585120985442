export const subPageNavNew = [
  {
    label: 'Address',
    to: '/db/datahandling/newaddress/address',
    state: {
      navPage: 'Data Handling',
      subNav: 'Addresses',
    },
  },
  {
    label: 'Documents',
    to: '/db/datahandling/newaddress/documents',
    state: {
      navPage: 'Data Handling',
      subNav: 'Owner Entities',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/newaddress/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Addresses',
    },
  },
];

export const subPageNavEdit = [
  {
    label: 'Address',
    to: '/db/datahandling/editaddress/address',
    state: {
      navPage: 'Data Handling',
      subNav: 'Addresses',
    },
  },
  {
    label: 'Documents',
    to: '/db/datahandling/editaddress/documents',
    state: {
      navPage: 'Data Handling',
      subNav: 'Addresses',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/editaddress/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Addresses',
    },
  },
];
