/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sortBy, reverse, prop } from 'ramda';
import { apiClient } from '../../../lib/apiClient';

export const getPartiesAndPrecinctArizona = createAsyncThunk(
  'caseCp/getPartiesAndPrecinctArizona',
  async (id) => {
    const { data } = await apiClient.get(`/cp/api/arizonaCase/${id}/partiesAndPrecinct`);
    return data;
  },
);

export const getPartiesAndPrecinctNevada = createAsyncThunk(
  'caseCp/getPartiesAndPrecinctNevada',
  async (id) => {
    const { data } = await apiClient.get(`/cp/api/nevadaCases/${id}/partiesAndPrecinct`);
    return data;
  },
);

export const getCaseInformationArizona = createAsyncThunk(
  'caseCp/getCaseInformationArizona',
  async (id) => {
    const { data } = await apiClient.get(`/cp/api/arizonaCase/${id}/caseInformation`);
    return data;
  },
);

export const getCaseInformationNevada = createAsyncThunk(
  'caseCp/getCaseInformationNevada',
  async (id) => {
    const { data } = await apiClient.get(`/cp/api/nevadaCases/${id}/caseInformation`);
    return data;
  },
);

export const getRelativeCases = createAsyncThunk('caseCp/getRelativeCases', async (id) => {
  const { data } = await apiClient.get(`/cp/api/cases/${id}/relativeCases`);
  return data;
});

export const getDocuments = createAsyncThunk('caseCp/getDocuments', async (params) => {
  const { data } = await apiClient.post(`/cp/api/cases/${params.id}/documents/filter`, params);
  return data;
});

export const getDocument = createAsyncThunk('caseCp/getDocument', async ({ id, documentId }) => {
  const { data } = await apiClient.get(`/cp/api/cases/${id}/documents/${documentId}`);
  return data;
});

export const uploadDocument = createAsyncThunk(
  'caseCp/uploadDocument',
  async ({ id, data: filesToUpload }) => {
    const formData = new FormData();

    filesToUpload.forEach((item, index) => {
      formData.append(`request[${index}].documentName`, item.name);
      formData.append(`request[${index}].type`, item.type);
      formData.append(`request[${index}].file`, item.file);
    });

    const { data } = await apiClient.post(`/cp/api/cases/${id}/documents/upload`, formData);
    return data;
  },
);

export const updateDocumentInfo = createAsyncThunk(
  'caseCp/updateDocumentInfo',
  async ({ id, ...body }) => {
    const { data } = await apiClient.put(`/cp/api/cases/${id}/documentsInformation`, body);
    return data;
  },
);

export const getDocumentInfo = createAsyncThunk('caseCp/getDocumentInfo', async (id) => {
  const { data } = await apiClient.get(`/cp/api/cases/${id}/documentsInformation`);
  return data;
});

export const getAvailableRequestsNevada = createAsyncThunk(
  'caseCp/getAvailableRequestsNevada',
  async (id) => {
    const { data } = await apiClient.get(`cp/api/nevadaCases/${id}/availableRequests`);
    return data;
  },
);

export const getAvailableRequestsArizona = createAsyncThunk(
  'caseCp/getAvailableRequestsArizona',
  async (id) => {
    const { data } = await apiClient.get(`cp/api/arizonaCase/${id}/availableRequests`);
    return data;
  },
);

export const setRequestCancellationArizona = createAsyncThunk(
  'caseCp/setRequestCancellationArizona',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(`cp/api/arizonaCase/${id}/cancellation`);
      return data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.errorMessage);
    }
  },
);

export const setRequestDismissalArizona = createAsyncThunk(
  'caseCp/setRequestDismissalArizona',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(`cp/api/arizonaCase/${params.caseId}/dismissal`, params);
      return data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.errorMessage);
    }
  },
);

export const setRequestSatisfactionArizona = createAsyncThunk(
  'caseCp/setRequestSatisfactionArizona',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(`cp/api/arizonaCase/${id}/satisfaction`);
      return data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.errorMessage);
    }
  },
);

export const setRequestVacateArizona = createAsyncThunk(
  'caseCp/setRequestVacateArizona',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(`cp/api/arizonaCase/${id}/vacate`);
      return data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.errorMessage);
    }
  },
);

export const setRequestWritArizona = createAsyncThunk(
  'caseCp/setRequestWritArizona',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(`cp/api/arizonaCase/${id}/writ`);
      return data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.errorMessage);
    }
  },
);

export const getEviction = createAsyncThunk('caseCp/getEviction', async (id) => {
  const { data } = await apiClient.get(`cp/api/nevadaCases/${id}/eviction`);
  return data;
});

export const setRequestLockout = createAsyncThunk('caseCp/setRequestLockout', async (params) => {
  const { data } = await apiClient.put(`cp/api/nevadaCases/${params.caseId}/lockout`, params);
  return data;
});

export const setRequestLockoutCompleted = createAsyncThunk(
  'caseCp/setRequestLockoutCompleted',
  async (id) => {
    const { data } = await apiClient.put(`cp/api/nevadaCases/${id}/lockoutCompleted`, {
      caseId: id,
    });
    return data;
  },
);

export const setRequestRescind = createAsyncThunk('caseCp/setRequestRescind', async (params) => {
  const { data } = await apiClient.put(`cp/api/nevadaCases/${params.caseId}/rescind`, params);
  return data;
});

export const setRequestCancellation = createAsyncThunk(
  'caseCp/setRequestCancellation',
  async (id) => {
    const { data } = await apiClient.put(`cp/api/nevadaCases/${id}/cancellation`);
    return data;
  },
);

export const setRequestDismissal = createAsyncThunk(
  'caseCp/setRequestDismissal',
  async (params) => {
    const { data } = await apiClient.put(`cp/api/nevadaCases/${params.caseId}/dismissal`, params);
    return data;
  },
);

export const setRequestEviction = createAsyncThunk('caseCp/setRequestEviction', async (id) => {
  const { data } = await apiClient.put(`cp/api/nevadaCases/${id}/evictionrequest`);
  return data;
});

export const setEvictionWithDocs = createAsyncThunk(
  'caseCp/setEvictionWithDocs',
  async ({ id, formData }) => {
    const { data } = await apiClient.put(`/cp/api/nevadaCases/${id}/evictionWithDocs`, formData);
    return data;
  },
);

const initialState = {
  partiesAndPrecinctArizona: [],
  partiesAndPrecinctNevada: [],
  error: null,
  getCaseInformationArizona: null,
  getCaseInformationNevada: null,
  relativeCases: [],
  documents: [],
  document: null,
  documentInfo: {},
  requestsNevada: {},
  requestsArizona: {},
  evictionDocumentsInfo: {},
};

export const caseCpSlice = createSlice({
  name: 'caseCp',
  initialState,
  reducers: {},
  extraReducers: {
    [getRelativeCases.fulfilled]: (state, action) => {
      state.relativeCases = action.payload.result;
    },
    [getPartiesAndPrecinctArizona.fulfilled]: (state, action) => {
      state.partiesAndPrecinctArizona = action.payload.result;
    },
    [getPartiesAndPrecinctArizona.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getPartiesAndPrecinctNevada.fulfilled]: (state, action) => {
      state.partiesAndPrecinctNevada = action.payload.result;
    },
    [getPartiesAndPrecinctNevada.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCaseInformationArizona.fulfilled]: (state, action) => {
      state.getCaseInformationArizona = {
        ...action.payload.result,
        caseStatuses: reverse(sortBy(prop('caseStatusId'), action.payload.result.caseStatuses)),
      };
    },
    [getCaseInformationArizona.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCaseInformationNevada.fulfilled]: (state, action) => {
      state.getCaseInformationNevada = {
        ...action.payload.result,
        caseStatuses: reverse(sortBy(prop('caseStatusId'), action.payload.result.caseStatuses)),
      };
    },
    [getCaseInformationNevada.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getDocuments.fulfilled]: (state, action) => {
      state.documents = action.payload.result;
    },
    [getDocuments.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getDocument.fulfilled]: (state, action) => {
      state.document = action.payload;
    },
    [getDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getDocumentInfo.fulfilled]: (state, action) => {
      state.documentInfo = action.payload.result;
    },
    [getDocumentInfo.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [uploadDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateDocumentInfo.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAvailableRequestsNevada.fulfilled]: (state, action) => {
      state.requestsNevada = action.payload.result;
    },
    [getAvailableRequestsNevada.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAvailableRequestsArizona.fulfilled]: (state, action) => {
      state.requestsArizona = action.payload.result;
    },
    [getAvailableRequestsArizona.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getEviction.fulfilled]: (state, action) => {
      state.evictionDocumentsInfo = action.payload.result;
    },
    [getEviction.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default caseCpSlice.reducer;
