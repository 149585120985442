import React, { useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';
import { ReactComponent as Trash } from '../../../../assets/icons/Trash.svg';
import NotificationDialog from '../../../molecules/NotificationDialog';

const ClientPortalNonPaymentNoticeEditableCell = (props) => {
  const { row, deleteRow, data } = props;

  const { original } = row;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClickDelete = useCallback(() => {
    if (!original?.id) return;
    deleteRow(original.id);
    handleClose();
  }, [original?.id, deleteRow]);

  const handleCheckIsModal = () => {
    const isSomethingFilled = Object.values(row?.values).some((item) => !!item);
    if (isSomethingFilled) {
      handleOpen();
    } else {
      handleClickDelete();
    }
  };
  return (
    <>
      <NotificationDialog
        title="Confirm Delete"
        desc="Are you sure you want to delete selected item?"
        type="alert"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Delete"
        onClose={handleClose}
        onConfirm={handleClickDelete}
        isOpen={open}
      />
      <IconButton disabled={data.length <= 1} onClick={handleCheckIsModal}>
        <Trash />
      </IconButton>
    </>
  );
};

ClientPortalNonPaymentNoticeEditableCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({ id: PropTypes.string.isRequired }),
    values: PropTypes.shape({}),
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteRow: PropTypes.func.isRequired,
};

export default React.memo(ClientPortalNonPaymentNoticeEditableCell);
