import React, { useState } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import ClientPortalDashboardChart from './ClientPortalDashboardChart';
import ClientPortalDashboardCases from './ClientPortalDashboardCases';
import { useAvailableStatesList } from '../../../hooks/useAvailableStatesList';
import Dropdown from '../../atoms/Dropdown';
import { usePrimaryState } from '../../../hooks/usePrimaryState';

const StyledHeading = styled(Typography)({
  textAlign: 'justify',
});

const ClientPortalDashboard = () => {
  const states = useAvailableStatesList();
  const primaryState = usePrimaryState();

  const [state, setState] = useState(primaryState || states[0]);

  const handleChangeState = (val) => {
    setState(val);
  };

  return (
    <Stack padding="24px 32px" spacing={3} maxWidth="100vw">
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <StyledHeading variant="h3" data-testid="search_container_label">
          Dashboard
        </StyledHeading>
        <Dropdown
          label="Select state"
          options={states}
          onChange={handleChangeState}
          placeholder={states.length > 0 ? primaryState : 'State'}
          isSearchable={false}
          value={state}
        />
      </Box>
      <ClientPortalDashboardChart stateCode={state.value} />
      <ClientPortalDashboardCases stateCode={state.value} />
    </Stack>
  );
};

export default ClientPortalDashboard;
