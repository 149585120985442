import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Stack, Typography, Box, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import print from 'print-js';
import {
  searchFilters,
  approvedTableColumns,
  rejectedTableColumns,
  showDropdownOptions,
  MANAGEMENTS_PAGE_SIZE,
  COMMUNITIES_PAGE_SIZE,
  TABLE_PAGE_SIZE,
  SHOW_FIELD,
  NEVADA_STATE,
} from './Notices.constants';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup/CustomRadioGroup';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import SearchInputWithLabelAndIcon from '../../../atoms/SearchInputWithLabelAndIcon/SearchInputWithLabelAndIcon';
import DatePickerWithLabel from '../../../atoms/DatePickerWithLabel/DatePickerWithLabel';
import EmptyBlock from '../../../molecules/EmptyBlock';
import MButton from '../../../MUI/Button/MButton';
import Table from '../../../atoms/Table/Table';
import { palette } from '../../../../theme/default';
import { ReactComponent as PrinterWhite } from '../../../../assets/icons/PrinterWhite.svg';
import { ReactComponent as SendActive } from '../../../../assets/icons/SendActive.svg';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import {
  resetNotices,
  getCommunities,
  getCompanyManagementsList,
  resetManagementsList,
  resetCommunitiesList,
  getApprovedNevadaNotices,
  getRejectedNevadaNotices,
  redateNevadaCasesDocuments,
  getOpposingParty,
  resetOppositParty,
  getApprovedAZNotices,
  getRejectedAZNotices,
  getAwaitingAZNotices,
  getAwaitingNevadaNotices,
  redateAZCasesDocuments,
  approveNevadaCasesDocuments,
  approveAZCasesDocuments,
} from '../../../../store/slices/noticesSlice';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import { apiClient } from '../../../../lib/apiClient';
import { convertToBinaryFileUrl } from '../../../../utils/binaryHelpers';
import RedateDialog from './RedateDialog';
import RedateErrorsDialog from './RedateErrorsDialog';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import Dropdown from '../../../atoms/Dropdown';
import { useUserInfo } from '../../../../utils/roleHelpers';
import SearchInputWithLabel from '../../../atoms/SearchInputWithLabel/SearchInputWithLabel';
import ApproveDialog from './ApproveDialog';
import notificationUtils from '../../../../utils/notificationUtils';
import { PrintingDialog } from './PrintingDialog';
import NoticeSendDialog from './NoticeSendDialog';
import { usePrimaryState } from '../../../../hooks/usePrimaryState';

const Notices = () => {
  useSafeLocationState('Processing', 'Notices');
  const dispatch = useDispatch();

  const [isLoading, setisLoading] = useState(false);
  const [status, setStatus] = useState('Approved');
  const [checkedApprovedTableRows, setCheckedApprovedTableRows] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isSendDialogOpened, setSendDialogOpened] = useState(false);
  const [action, setAction] = useState('');
  const primaryState = usePrimaryState();

  const form = useForm({
    defaultValues: {
      show: showDropdownOptions.find((item) => item.default),
    },
  });

  const { managementCompanies, notices, communities, opposingParty } = useSelector(
    (store) => store.notices,
  );

  const { stateCodes } = useUserInfo();

  const states = useMemo(
    () =>
      stateCodes.map((stateCode) => ({
        id: stateCode,
        label: stateCode,
        value: stateCode,
      })),
    [stateCodes],
  );

  const [state, setState] = useState(primaryState || states[0]);

  const filterArgs = useMemo(
    () => ({
      stateCode: state?.value,
    }),
    [state?.value],
  );

  const {
    handleMenuScrollToBottom: managementCompaniesScrollTobottom,
    changeInput: managementCompaniesChangeInput,
  } = useSearchDropdown({
    hasNextPage: managementCompanies?.hasNextPage,
    getAction: ({ input, page }) =>
      getCompanyManagementsList({
        currentPage: page,
        pageSize: MANAGEMENTS_PAGE_SIZE,
        managementName: input,
        open: false,
      }),
  });

  const {
    handleMenuScrollToBottom: communitiesScrollTobottom,
    changeInput: communitiesChangeInput,
  } = useSearchDropdown({
    hasNextPage: communities?.hasNextPage,
    getAction: ({ input, page }) =>
      getCommunities({
        currentPage: page,
        stateCode: state?.value,
        pageSize: COMMUNITIES_PAGE_SIZE,
        communityName: input,
        open: false,
      }),
    actionArguments: filterArgs,
  });

  const {
    handleMenuScrollToBottom: opposingPartyScrollTobottom,
    changeInput: opposingPartyChangeInput,
  } = useSearchDropdown({
    hasNextPage: opposingParty?.hasNextPage,
    getAction: ({ input, page }) =>
      getOpposingParty({
        currentPage: page,
        stateCode: state?.value,
        pageSize: 10,
        fullName: input,
        open: false,
      }),
    actionArguments: filterArgs,
  });

  const onOpenManagementCompanyMenu = () => {
    setisLoading(true);
    dispatch(
      getCompanyManagementsList({
        currentPage: 1,
        pageSize: MANAGEMENTS_PAGE_SIZE,
        managementName: '',
        open: true,
      }),
    ).then(() => {
      setisLoading(false);
    });
  };

  const onOpenCommunitiesMenu = () => {
    setisLoading(true);
    dispatch(
      getCommunities({
        currentPage: 1,
        stateCode: state?.value,
        pageSize: COMMUNITIES_PAGE_SIZE,
        communityName: '',
        open: true,
      }),
    ).then(() => {
      setisLoading(false);
    });
  };

  const onOpenOppositPartyMenu = () => {
    setisLoading(true);
    dispatch(
      getOpposingParty({
        stateCode: state?.value,
        currentPage: 1,
        pageSize: 10,
        fullName: '',
        open: true,
      }),
    ).then(() => {
      setisLoading(false);
    });
  };

  const onCloseManagementCompanyMenu = () => {
    dispatch(resetManagementsList());
  };

  const onCloseCommunitiesMenu = () => {
    dispatch(resetCommunitiesList());
  };

  const onCloseOpposingPartyMenu = () => {
    dispatch(resetOppositParty());
  };

  const getHandlers = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case 'management':
          return {
            handleSearch: managementCompaniesChangeInput,
            onMenuScrollToBottom: managementCompaniesScrollTobottom,
            onMenuClose: onCloseManagementCompanyMenu,
            onMenuOpen: onOpenManagementCompanyMenu,
          };
        case 'community':
          return {
            handleSearch: communitiesChangeInput,
            onMenuScrollToBottom: communitiesScrollTobottom,
            onMenuClose: onCloseCommunitiesMenu,
            onMenuOpen: onOpenCommunitiesMenu,
          };
        case 'opposingParty':
          return {
            handleSearch: opposingPartyChangeInput,
            onMenuScrollToBottom: opposingPartyScrollTobottom,
            onMenuClose: onCloseOpposingPartyMenu,
            onMenuOpen: onOpenOppositPartyMenu,
          };
        default:
          return {
            handleSearch: null,
            onMenuScrollToBottom: null,
            onMenuClose: null,
            onMenuOpen: null,
          };
      }
    },
    [
      managementCompaniesScrollTobottom,
      managementCompaniesChangeInput,
      communitiesChangeInput,
      communitiesScrollTobottom,
      opposingPartyChangeInput,
      opposingPartyScrollTobottom,
      state,
    ],
  );

  const getOptions = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case 'show':
          return showDropdownOptions;
        case 'management':
          return managementCompanies?.items;
        case 'community':
          return communities?.items;
        case 'opposingParty':
          return opposingParty?.items;
        default:
          return [];
      }
    },
    [managementCompanies, communities, opposingParty],
  );

  const getDisabledState = useCallback(
    (fieldName) => {
      if (fieldName === SHOW_FIELD) {
        return !form.getValues('approvedRejected');
      }
      return false;
    },
    [form.watch('approvedRejected')],
  );

  const handleTableChecked = (selectedRows) => {
    setCheckedApprovedTableRows(selectedRows);
  };

  const combineFiltersForRequest = () => {
    const {
      dateServed,
      management,
      community,
      show,
      apartmentNumber,
      opposingParty: opposingPartyValue,
    } = form.getValues();

    return {
      dateServed: dateServed ? moment(dateServed, moment.ISO_8601) : undefined,
      managementId: management?.id,
      communityId: community?.id,
      opposingPartyFullName: opposingPartyValue?.label,
      isSent: show?.isSent ?? {},
      isPrinted: show?.isPrinted ?? {},
      apartmentNumber,
    };
  };

  const getNotices = ({ values, currentPage, setLoaderCb }) => {
    setLoaderCb(true);
    const formValues = combineFiltersForRequest();

    const { dateServed, managementId, communityId, opposingPartyFullName, apartmentNumber } =
      formValues;

    const { approvedRejected, show } = values;
    const { isSent, isPrinted } = show ?? {};
    const approvedCB =
      state.value === NEVADA_STATE ? getApprovedNevadaNotices : getApprovedAZNotices;
    const rejectedCB =
      state.value === NEVADA_STATE ? getRejectedNevadaNotices : getRejectedAZNotices;
    const awaitingCB =
      state.value === NEVADA_STATE ? getAwaitingNevadaNotices : getAwaitingAZNotices;

    let callbackToUse = approvedRejected === 'Approved' ? approvedCB : rejectedCB;
    if (approvedRejected === 'AwaitingApproval') {
      callbackToUse = awaitingCB;
    }

    return dispatch(
      callbackToUse({
        currentPage,
        pageSize: TABLE_PAGE_SIZE,
        stateCode: state?.value,
        showSent: isSent,
        showPrinted: isPrinted,
        showApproved: approvedRejected === 'Approved',
        ...(managementId && { managementId }),
        ...(communityId && { communityId }),
        ...(dateServed && { dateServed }),
        ...(opposingPartyFullName && { opposingPartyFullName }),
        ...(apartmentNumber && { apartmentNumber }),
      }),
    ).then(() => {
      setLoaderCb(false);
    });
  };

  const handleFetchDataPage = (currentPage) => {
    getNotices({
      values: form.getValues(),
      currentPage,
      setLoaderCb: setisLoading,
    });
  };

  useEffect(() => {
    handleFetchDataPage(1);
    setCheckedApprovedTableRows([]);
  }, [form.watch('approvedRejected'), state?.value]);

  const [isRedateDialogOpen, setIsRedateDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isRedateErrorDialogOpen, setIsRedateErrorDialogOpen] = useState(false);
  const [redateErrors, setRedateErrors] = useState([]);

  const handleRedateDialogOpen = () => setIsRedateDialogOpen(true);
  const handleApproveDialogOpen = () => setIsApproveDialogOpen(true);
  const handleRedateDialogClose = () => setIsRedateDialogOpen(false);
  const handleApproveDialogClose = () => setIsApproveDialogOpen(false);

  const handleRedateErrorDialogOpen = () => setIsRedateErrorDialogOpen(true);
  const handleRedateErrorDialogClose = () => setIsRedateErrorDialogOpen(false);
  const handleRedateErrorDialogClosed = () => setRedateErrors([]);
  const isPrintingEnabled = useRef(true);
  const abortController = useRef(undefined);

  const handleChangeRedate = (dateServed) => {
    setisLoading(true);
    handleRedateDialogClose();

    const formValues = combineFiltersForRequest();

    const {
      dateServed: dateServedFilter,
      managementId,
      communityId,
      apartmentNumber,
      opposingPartyFullName,
      isSent,
      isPrinted,
    } = formValues;

    const method =
      state?.value === NEVADA_STATE ? redateNevadaCasesDocuments : redateAZCasesDocuments;

    dispatch(
      method({
        selectedDocuments: allChecked
          ? []
          : checkedApprovedTableRows.map((item) => item.documentId),
        redateDateServed: dateServed,
        allChecked,
        showSent: isSent,
        showPrinted: isPrinted,
        ...(managementId && { managementId }),
        ...(communityId && { communityId }),
        ...(dateServedFilter && { dateServed: dateServedFilter }),
        ...(opposingPartyFullName && { opposingPartyFullName }),
        ...(apartmentNumber && { apartmentNumber }),
        status: form.getValues('approvedRejected'),
      }),
    ).then((result) => {
      const failedDocuments = result?.payload?.failedDocuments ?? [];
      if (failedDocuments.length) {
        setRedateErrors(failedDocuments.map((doc) => doc.failureReason));
        handleRedateErrorDialogOpen();
      }

      getNotices({
        values: form.getValues(),
        currentPage: notices?.currentPage,
        setLoaderCb: setisLoading,
      });
    });
  };

  const handleApprove = (comment) => {
    setisLoading(true);
    handleApproveDialogClose();
    const formValues = combineFiltersForRequest();

    const {
      dateServed,
      managementId,
      communityId,
      apartmentNumber,
      opposingPartyFullName,
      isSent,
      isPrinted,
    } = formValues;

    const method =
      state?.value === NEVADA_STATE ? approveNevadaCasesDocuments : approveAZCasesDocuments;
    const caseDocumentIds = allChecked
      ? []
      : checkedApprovedTableRows.map((item) => {
          return { documentId: item.documentId, caseId: item.caseId };
        });
    dispatch(
      method({
        caseDocumentIds,
        comment,
        allChecked,
        showSent: isSent,
        showPrinted: isPrinted,
        ...(managementId && { managementId }),
        ...(communityId && { communityId }),
        ...(dateServed && { dateServed }),
        ...(opposingPartyFullName && { opposingPartyFullName }),
        ...(apartmentNumber && { apartmentNumber }),
        status: form.getValues('approvedRejected'),
      }),
    ).then((result) => {
      if (!result.error) {
        notificationUtils.success('Notices have been approved successfully');
        setCheckedApprovedTableRows([]);
        getNotices({
          values: form.getValues(),
          currentPage: notices?.currentPage,
          setLoaderCb: setisLoading,
        });
      } else {
        notificationUtils.error('Failed, try again later');
      }
      setisLoading(false);
    });
  };

  const clearFilterHandler = () => {
    form.reset({
      approvedRejected: 'Approved',
      show: showDropdownOptions.find((item) => item.default),
      management: null,
      community: null,
      dateServed: null,
      opposingParty: null,
      apartmentNumber: '',
    });
    dispatch(resetNotices());
    setClearButtonVisible(false);
    handleFetchDataPage(1);
  };

  const handleSend = (email) => {
    setisLoading(true);
    const formValues = combineFiltersForRequest();

    const {
      dateServed,
      managementId,
      communityId,
      apartmentNumber,
      opposingPartyFullName,
      isSent,
      isPrinted,
    } = formValues;

    const url =
      state?.value === NEVADA_STATE
        ? '/api/nevadaCases/notices/documents/send/concat'
        : '/api/arizonaCases/notices/documents/send/concat';
    const noticesToSend = allChecked
      ? []
      : checkedApprovedTableRows.map((item) => {
          return {
            documentId: item.documentId,
            caseId: item.caseId,
          };
        });
    apiClient
      .post(url, {
        caseDocuments: noticesToSend,
        email,
        allChecked,
        showSent: isSent,
        showPrinted: isPrinted,
        ...(managementId && { managementId }),
        ...(communityId && { communityId }),
        ...(dateServed && { dateServed }),
        ...(opposingPartyFullName && { opposingPartyFullName }),
        ...(apartmentNumber && { apartmentNumber }),
        status: form.getValues('approvedRejected'),
      })
      .then(() => {
        getNotices({
          values: form.getValues(),
          currentPage: notices?.currentPage,
          setLoaderCb: setisLoading,
        });
        notificationUtils.success('Sent successfully');
      })
      .catch(() => {
        notificationUtils.error('Failed, try again later');
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const initialPrint = { files: [], shouldSendAfterPrint: false, shouldPrint: false, email: '' };
  const [filesToPrint, setFilesToPrint] = useState(initialPrint);

  const handleOpenSendDialog = (actionValue) => {
    setSendDialogOpened(true);
    setAction(actionValue);
  };

  const printOrPrintAndSend = (filesPrintOrSend) => {
    if (!filesPrintOrSend?.files?.length) return;
    if (!isPrintingEnabled.current) return;
    if (abortController?.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();
    const { files, shouldSendAfterPrint, email } = filesPrintOrSend;
    const formValues = combineFiltersForRequest();

    const {
      dateServed,
      managementId,
      communityId,
      apartmentNumber,
      opposingPartyFullName,
      isSent,
      isPrinted,
    } = formValues;

    setIsPrinting(true);
    const url =
      state?.value === NEVADA_STATE
        ? '/api/nevadaCases/notices/documents/concat'
        : '/api/arizonaCases/notices/documents/concat';

    apiClient
      .post(
        url,
        {
          caseDocuments: allChecked ? [] : files,
          allChecked,
          showSent: isSent,
          showPrinted: isPrinted,
          ...(managementId && { managementId }),
          ...(communityId && { communityId }),
          ...(dateServed && { dateServed }),
          ...(opposingPartyFullName && { opposingPartyFullName }),
          ...(apartmentNumber && { apartmentNumber }),
          status: form.getValues('approvedRejected'),
        },
        { signal: abortController?.current?.signal },
      )
      .then((response) => {
        if (!isPrintingEnabled.current) return;
        const {
          data: { result },
        } = response;
        const { contentType, content } = result;

        const fileURL = convertToBinaryFileUrl({ contentType, content });

        print({
          printable: fileURL,
          onPrintDialogClose: async () => {
            if (shouldSendAfterPrint) {
              const sendAndPrintUrl =
                state?.value === NEVADA_STATE
                  ? '/api/nevadaCases/notices/documents/sendAndPrint/concat'
                  : '/api/arizonaCases/notices/documents/sendAndPrint/concat';
              await apiClient.post(sendAndPrintUrl, {
                caseDocuments: allChecked ? [] : files,
                allChecked,
                ...(email && { email }),
                showSent: isSent,
                showPrinted: isPrinted,
                ...(managementId && { managementId }),
                ...(communityId && { communityId }),
                ...(dateServed && { dateServed }),
                ...(opposingPartyFullName && { opposingPartyFullName }),
                ...(apartmentNumber && { apartmentNumber }),
                status: form.getValues('approvedRejected'),
              });
            } else {
              const printUrl =
                state?.value === NEVADA_STATE
                  ? '/api/nevadaCases/notices/documents/print/concat'
                  : '/api/arizonaCases/notices/documents/print/concat';
              await apiClient.post(printUrl, {
                caseDocuments: allChecked ? [] : files,
                allChecked,
                showSent: isSent,
                showPrinted: isPrinted,
                ...(managementId && { managementId }),
                ...(communityId && { communityId }),
                ...(dateServed && { dateServed }),
                ...(opposingPartyFullName && { opposingPartyFullName }),
                ...(apartmentNumber && { apartmentNumber }),
                status: form.getValues('approvedRejected'),
              });
            }

            await getNotices({
              values: form.getValues(),
              currentPage: notices?.currentPage,
              setLoaderCb: setisLoading,
            });
            setFilesToPrint(initialPrint);

            if (shouldSendAfterPrint) {
              notificationUtils.success('Printed & Sent successfully');
            }
          },
        });
      })
      .catch(() => {
        if (isPrintingEnabled.current) {
          notificationUtils.error('Failed, try again later');
        }
      })
      .finally(() => {
        setIsPrinting(false);
        abortController.current = undefined;
      });
  };

  const onConfirmPrintAndSend = (email) => {
    if (action === 'send') {
      handleSend(email);
    } else {
      isPrintingEnabled.current = true;
      const documentsToPrint = checkedApprovedTableRows.map((item) => ({
        caseId: item.caseId,
        documentId: item.documentId,
      }));
      const files = {
        shouldSendAfterPrint: true,
        shouldPrint: false,
        files: documentsToPrint,
        email,
      };
      setFilesToPrint(files);
      printOrPrintAndSend(files);
    }
  };

  const printAsOne = () => {
    isPrintingEnabled.current = true;
    const documentsToPrint = checkedApprovedTableRows.map((item) => ({
      caseId: item.caseId,
      documentId: item.documentId,
    }));
    const files = { shouldSendAfterPrint: false, shouldPrint: true, files: documentsToPrint };
    setFilesToPrint(files);

    printOrPrintAndSend(files);
  };

  useEffect(() => {
    dispatch(resetNotices());
  }, [form.watch('approvedRejected')]);

  useEffect(() => {
    dispatch(resetNotices());
    setStatus(form.getValues('approvedRejected'));

    if (form.getValues('approvedRejected') === 'Rejected') {
      form.reset({
        approvedRejected: 'Rejected',
        show: showDropdownOptions.find((item) => item.default),
        management: null,
        community: null,
        dateServed: null,
        opposingParty: null,
        apartmentNumber: '',
      });
    }
  }, [form.watch('approvedRejected')]);

  useEffect(() => {
    dispatch(resetNotices());
    setStatus(form.getValues('approvedRejected'));

    form.reset({
      approvedRejected: 'Approved',
      show: showDropdownOptions.find((item) => item.default),
      management: null,
      community: null,
      dateServed: null,
      opposingParty: null,
      apartmentNumber: '',
    });
  }, [state?.value]);

  const isNoDataBlockVisible = useMemo(() => {
    return !notices?.totalRowsCount;
  }, [notices]);

  const isFooterDisabled = useMemo(() => {
    return isLoading || !checkedApprovedTableRows?.length;
  }, [isLoading, checkedApprovedTableRows]);

  const handleSubmit = form.handleSubmit((values) => {
    getNotices({ values, currentPage: 1, setLoaderCb: setisLoading });
    setClearButtonVisible(true);
  });

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  const handleStateChange = (stateSelected) => {
    setState(stateSelected);
  };

  const cancelPrinting = () => {
    if (abortController?.current) {
      abortController.current.abort();
    }
    isPrintingEnabled.current = false;
    setFilesToPrint(initialPrint);
    setIsPrinting(false);
  };

  const handleCloseSendDialog = () => {
    setSendDialogOpened(false);
  };

  const getRowId = useCallback((row) => {
    return row.documentId;
  }, []);

  const rows = useMemo(() => {
    return notices?.items || [];
  }, [notices?.items]);

  const columns = useMemo(() => {
    return status === 'Approved' || status === 'AwaitingApproval'
      ? approvedTableColumns
      : rejectedTableColumns;
  }, [status]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        px: 4,
        pb: '150px',
      }}
    >
      {isPrinting && (
        <PrintingDialog
          amount={allChecked ? notices?.totalRowsCount || 0 : filesToPrint?.files?.length || 0}
          cancelPrinting={cancelPrinting}
        />
      )}
      {isSendDialogOpened && (
        <NoticeSendDialog
          onConfirm={onConfirmPrintAndSend}
          onClose={handleCloseSendDialog}
          isOpen={isSendDialogOpened}
        />
      )}

      {isLoading ? (
        <Box position="fixed" top="50%" left="50%" zIndex={10} transform="translate(-50%, -50%)">
          <CircularProgress size={60} />
        </Box>
      ) : null}

      <>
        <Stack flex justifyContent="flex-start" alignItems="center" flexDirection="initial" mb={4}>
          <Typography variant="h3" pb={1} mr={2}>
            Notices Search
          </Typography>
          <Dropdown
            label="Select state"
            options={states}
            onChange={handleStateChange}
            value={state}
          />
        </Stack>
        <Form onSubmit={handleSubmit} form={form}>
          <Stack
            display="flex"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            columnGap={2}
            rowGap={1}
            flexWrap="wrap"
            mb={4}
          >
            {searchFilters.map((item) => {
              if (item.isDropdown) {
                return (
                  <Field
                    name={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, onCustomChange }) => {
                      const { handleSearch, onMenuScrollToBottom, onMenuClose, onMenuOpen } =
                        getHandlers(item.name);

                      return (
                        <SearchInputWithLabelAndIcon
                          key={item.name}
                          label={item.label}
                          placeholder={item.placeholder}
                          isDropdown={item.isDropdown}
                          options={getOptions(item.name)}
                          name={item.name}
                          width={item.width}
                          handleChange={onCustomChange(field.onChange)}
                          handleSearch={handleSearch}
                          onMenuScrollToBottom={onMenuScrollToBottom}
                          onMenuClose={onMenuClose}
                          onMenuOpen={onMenuOpen}
                          height={48}
                          value={field.value}
                          isDisabled={getDisabledState(item.name)}
                          containerMarginBottom="0px"
                          onEnter={handleSubmit}
                        />
                      );
                    }}
                  />
                );
              }

              if (item.isDate) {
                return (
                  <Field
                    name={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, onCustomChange }) => {
                      return (
                        <DatePickerWithLabel
                          key={item.name}
                          name={item.name}
                          label={item.label}
                          value={field.value}
                          dateFormat={item.dateFormat}
                          onChangeValue={onCustomChange(field.onChange)}
                          width={item.width}
                          disabled={getDisabledState(item.name)}
                          onEnter={handleSubmit}
                        />
                      );
                    }}
                  />
                );
              }

              if (item.isRadioButtons) {
                return (
                  <Field
                    key={item.name}
                    name={item.name}
                    render={({ field, onCustomChange }) => (
                      <CustomRadioGroup
                        data={item.data}
                        value={field.value}
                        label={item.label}
                        width={item.width}
                        radioButtonWidth={item.radioButtonWidth}
                        onChange={onCustomChange(field.onChange)}
                        defaultValue={getRadioGroupDefaultValue(item.data)}
                      />
                    )}
                  />
                );
              }

              if (item.isSpace) {
                return <div style={{ width: '100%' }} />;
              }

              return (
                <Field
                  key={item.label}
                  name={item.name}
                  isMandatory={item.isMandatory}
                  render={({ field, onCustomChange }) => (
                    <SearchInputWithLabel
                      type="text"
                      label={item.label}
                      placeholder={item.placeholder}
                      width={item.width}
                      name={item.name}
                      value={field.value}
                      onChangeValue={onCustomChange(field.onChange)}
                    />
                  )}
                />
              );
            })}
            <MButton variant="bordered" type="submit" disabled={isLoading}>
              Apply
            </MButton>
            {clearButtonVisible ? (
              <MButton
                variant="borderLess"
                onClick={clearFilterHandler}
                disabled={isLoading}
                data-testid="clear_btn"
              >
                Clear filter
              </MButton>
            ) : null}
          </Stack>
        </Form>
        {isNoDataBlockVisible && (
          <Stack alignItems="center" height="100%" display="flex" justifyContent="flex-start">
            <EmptyBlock
              title="No records"
              desc="Please use search and filters to display available notices"
              descFontSize="18px"
            />
          </Stack>
        )}
        {notices?.items?.length ? (
          <Table
            columns={columns}
            rows={rows}
            onCheckedRow={handleTableChecked}
            isWithCheckbox={status === 'Approved' || status === 'AwaitingApproval'}
            total={notices?.totalRowsCount}
            isPagination={notices?.totalPages > 1}
            onNextPage={handleFetchDataPage}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            pageSize={TABLE_PAGE_SIZE}
            getRowId={getRowId}
            checkAllRecords={(all) => setAllChecked(all)}
            checkedAll={allChecked}
          />
        ) : null}
        {status !== 'Rejected' && (
          <Box
            position="fixed"
            bottom={0}
            left={0}
            zIndex={10}
            width={1}
            p={4}
            bgcolor={palette.common.white}
            boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
          >
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              {status === 'Approved' ? (
                <MButton
                  variant="secondary"
                  size="large"
                  onClick={handleRedateDialogOpen}
                  sx={{ width: '144px', height: '48px' }}
                  disabled={isFooterDisabled}
                  data-testid="redate_btn"
                >
                  <Calender style={{ marginRight: '8px' }} />
                  Redate
                </MButton>
              ) : (
                <MButton
                  variant="secondary"
                  size="large"
                  onClick={handleApproveDialogOpen}
                  sx={{ width: '144px', height: '48px' }}
                  disabled={isFooterDisabled}
                  data-testid="approve_btn"
                >
                  Approve
                </MButton>
              )}
              <MButton
                size="large"
                sx={{ width: '144px', height: '48px' }}
                onClick={printAsOne}
                disabled={isFooterDisabled}
                data-testid="print_btn"
              >
                <PrinterWhite style={{ marginRight: '8px' }} />
                Print
              </MButton>
              <MButton
                size="large"
                sx={{ width: '144px', height: '48px' }}
                onClick={() => handleOpenSendDialog('send')}
                disabled={isFooterDisabled}
                data-testid="send_btn"
              >
                <SendActive style={{ marginRight: '8px' }} />
                Send
              </MButton>
              <MButton
                size="large"
                sx={{ width: '144px', height: '48px' }}
                onClick={() => handleOpenSendDialog('sendAndPrint')}
                disabled={isFooterDisabled}
                data-testid="print_send_btn"
              >
                Print & Send
              </MButton>
            </Stack>
          </Box>
        )}
      </>
      <ApproveDialog
        isOpen={isApproveDialogOpen}
        onClose={handleApproveDialogClose}
        onConfirm={handleApprove}
      />
      <RedateDialog
        isOpen={isRedateDialogOpen}
        onClose={handleRedateDialogClose}
        onConfirm={handleChangeRedate}
      />
      <RedateErrorsDialog
        isOpen={isRedateErrorDialogOpen}
        onClose={handleRedateErrorDialogClose}
        onClosed={handleRedateErrorDialogClosed}
        errors={redateErrors}
      />
    </Container>
  );
};

export default Notices;
