import React, { useCallback, useState } from 'react';
import { Dialog, Typography, Stack } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { ReactComponent as DocUploadIcon } from '../../../../assets/icons/document-upload.svg';
import { palette } from '../../../../theme/default';
import MButton from '../../../MUI/Button/MButton';
import Table from '../../../atoms/Table/Table';
import { acceptedFilesExtensions, uploadedDocColumns } from './AddCourtDates.constants';

const DialogStyles = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '784px',
      padding: '32px',
      borderRadius: '24px',
    },
  },
};

const UploadDoc = ({ title, isOpen, onConfirm, handleClose }) => {
  const [filetoUpload, setFileToUpload] = useState(null);
  const [updatedFile, setUpdateFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        setFileToUpload([{ documentName: file.name }]);

        const formData = new FormData();
        formData.append('File', file);
        setUpdateFile(formData);
      };
      reader.readAsArrayBuffer(file);
    }, []);
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFilesExtensions,
  });

  const handleUpload = () => {
    handleClose();
    onConfirm(updatedFile);
  };

  const handleDeleteRow = () => {
    setFileToUpload(null);
  };

  return (
    <Dialog fullWidth open={isOpen} sx={DialogStyles} onClose={handleClose}>
      <Typography variant="h4">{title}</Typography>
      {filetoUpload ? (
        <Stack mt={4} mb={4}>
          <Table columns={uploadedDocColumns} rows={filetoUpload} onDeleteRow={handleDeleteRow} />
        </Stack>
      ) : (
        <Stack {...getRootProps()} mt={4} mb={4}>
          <input {...getInputProps()} />
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt="50px"
            pb="48px"
            sx={{
              backgroundColor: palette.upload.dragAndDropArea,
              border: 'dashed 1px solid grey',
              borderRadius: '24px',
            }}
          >
            <DocUploadIcon />
            <Typography
              color={palette.text.secondary}
              fontSize="18px"
              fontWeight={500}
              mb={1}
              mt={1}
            >
              Drop your documents here, or select
            </Typography>
            <Typography
              color={palette.text.browseFile}
              fontSize="18px"
              fontWeight={400}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Click to browse
            </Typography>
          </Stack>
        </Stack>
      )}

      <Stack display="flex" flexDirection="row" justifyContent="flex-end" height="48px" gap={2}>
        <MButton variant="bordered" sx={{ width: '104px', fontSize: '16px' }} onClick={handleClose}>
          Cancel
        </MButton>
        <MButton
          sx={{ width: '104px', fontSize: '16px' }}
          onClick={handleUpload}
          disabled={!filetoUpload?.length}
        >
          Upload
        </MButton>
      </Stack>
    </Dialog>
  );
};

UploadDoc.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.bool.isRequired,
};

export default UploadDoc;
