import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from './RadioButtons.module.scss';

const RadioButtons = (props) => {
  const { data = [], defaultValue, name, label, onChange, isDisabled } = props;

  const [radioVal, setRadioVal] = useState('');

  useEffect(() => {
    setRadioVal(defaultValue);
  }, [defaultValue]);

  const handleChange = (e) => {
    setRadioVal(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className={styles.main_container}>
      <legend data-testid="radio_button_label">{label}</legend>
      <div
        className={styles.buttons_container}
        onChange={(e) => handleChange(e)}
        data-testid="radio_button_container"
      >
        {data.map((i) => {
          return (
            <RadioButton
              isDisabled={isDisabled}
              name={name}
              value={i.value}
              key={i.label}
              label={i.label}
              checked={radioVal === i.value}
            />
          );
        })}
      </div>
    </div>
  );
};

const RadioButton = ({ label, checked, name, value, isDisabled }) => {
  return (
    <StyledButton checked={checked} className={styles.label}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>{label}</label>
      <input
        value={value}
        name={name}
        type="radio"
        checked={checked}
        disabled={isDisabled}
        data-testid={`radio_button_${label}`}
      />
    </StyledButton>
  );
};

const StyledButton = styled.div`
  border: ${({ checked }) =>
    checked ? '4px solid var(--button_primary)' : '1px solid var(--border_line)'};
  border-radius: 10px;
  padding: 12px;
  margin-right: 8px;

  label {
    margin-right: 31px;
  }
`;

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  isDisabled: false,
};

RadioButtons.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  defaultValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

RadioButtons.defaultProps = {
  isDisabled: false,
};

export default RadioButtons;
