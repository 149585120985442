/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

// CommunityName, CurrentPage, PageSize
export const getCommunities = createAsyncThunk(
  'communities/getCommunities',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/communities/filter`, params);
    return data;
  },
);

export const getStates = createAsyncThunk('communities/states', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data;
});

const initialState = {
  communities: {},
  states: [],
  error: null,
};

export const communitySlice = createSlice({
  name: 'communities',
  initialState,
  reducers: {},
  extraReducers: {
    [getCommunities.fulfilled]: (state, action) => {
      state.communities = action.payload.result;
    },
    [getCommunities.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.result.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.code,
      }));
    },
  },
});

export default communitySlice.reducer;
