import axios from 'axios';
import qs from 'qs';
import { apiClient, tokenRepository } from '../lib/apiClient';
import { tokenKey } from '../lib/tokenRepository';

const BaseUrl = process.env.REACT_APP_BASE_URL;

export const login = async ({ loginPath, password, username }) => {
  try {
    const response = await apiClient.post(
      loginPath,
      { username, password },
      { headers: { 'Content-Type': 'application/json' } },
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getUsersByIds = (ids) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      usersIds: ids,
    },
    paramsSerializer: (params) => qs.stringify(params),
  };

  return axios
    .get(`${BaseUrl}api/getUsersByIds`, config)
    .then((response) => response.data)
    .catch((error) => error);
};

export const logout = (logoutPath) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenRepository.getToken(tokenKey)}`,
    },
  };
  return axios.delete(`${BaseUrl}${logoutPath}`, config);
};

export const getCurrentUser = () => JSON.parse(localStorage.getItem('user'));
