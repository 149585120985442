import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useSafeLocationState = (defNavPage = '', defSubNav = '') => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state ?? {
    ...(defNavPage && { navPage: defNavPage }),
    ...(defSubNav && { subNav: defSubNav }),
  };

  useEffect(() => {
    if (!location.state && (defNavPage || defSubNav)) {
      navigate(location.pathname, {
        replace: true,
        state,
      });
    }
  }, [location.state]);

  return state;
};

export default useSafeLocationState;
