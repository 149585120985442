import {
  Box,
  CircularProgress,
  Typography,
  Accordion,
  AccordionSummary,
  styled,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import {
  getCasesAssignedToAttorney,
  getSettings,
} from '../../../../store/slices/attorneyDashboardSlice';
import MDialog from '../../../atoms/MDialog/MDialog';
import MButton from '../../../MUI/Button/MButton';
import Table from '../../../atoms/Table/Table';
import { HearingsPopupColumns } from './CourtSchedule.constants';
import CalendarHeader from '../../../organisms/CalendarHeader/CalendarHeader';
import { BaseRightButtons } from '../../../organisms/CalendarHeader/CalendarRightPart';
import { ALL_ATTORNEYS } from '../../Reports/ProductivityByAttorney/ProductivityByAttorney.constants';
import CustomRightFilters, { TIMERS } from '../DashboardAttorney/CustomRightFilters';

import '../../../organisms/CalendarHeader/Calendar.sass';
import { useUserInfo } from '../../../../utils/roleHelpers';

const WrapperAccordion = styled(Accordion)(({ theme }) => {
  return {
    padding: '0 32px',
    boxShadow: `0 3px 20px ${theme.palette.shadow.boxContrast}`,
    '&': {
      border: 'none',
      borderRadius: '16px',
    },
    '&:first-of-type': {
      borderRadius: '16px',
    },
    '& .MuiAccordionSummary-root': {
      padding: '0',
    },
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
  };
});

const renderEventContent = ({ event, timeText, view: { type } }) => {
  const {
    title,
    extendedProps: { caseCount, hearingCases = [] },
    // eslint-disable-next-line
  } = event._def;

  const attorneys = hearingCases.reduce((acc, item) => {
    if (item.assignedAttorneyFullName) {
      acc.push(item.assignedAttorneyFullName);
    }
    return acc;
  }, []);

  const attorneyName = attorneys?.length > 1 ? 'Multiple Attorneys' : attorneys[0] || '';

  return {
    html: `<div class="fc-event-main-frame">
               <div class="fc-event-time">
      ${type === 'dayGridMonth' ? '' : `${timeText}<span class="fc-event-separator">/</span>`}
      <span class="fc-event-case-count">${caseCount}</span> case${caseCount === 1 ? '' : 's'} 
      <span class="fc-event-case-count"> ${attorneyName ? ` / ${attorneyName}` : ''}</span></div>
               <div class="fc-event-title-container">
                 <div class="fc-event-title fc-sticky">${title}</div>
               </div>
             </div>`,
  };
};

const renderDayHeaderContent = ({ date, text }) => {
  return {
    html: `<div class="fc-header-day-of-week">${text.split(' ')?.[0]}</div>
      <div class="fc-header-day-of-month">${moment(date).format('D')}</div>`,
  };
};

const dateFormat = 'YYYY-MM-DDTHH:mm';

const DashboardCalendar = ({ stateCode }) => {
  const [isLoader, setIsLoader] = useState(true);
  const [hearingsData, setHearingsData] = useState(null);
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();
  const operationId = useRef(0);
  const calendarRef = useRef(null);
  const [attorney, setAttorney] = useState(null);
  const [refresh, setRefresh] = useState(TIMERS[0]);
  const timer = useRef(NaN);
  const { UserGuid } = useUserInfo();

  const onCloseModal = () => setHearingsData(null);

  const onDatesSet = ({ start, end, view }) => {
    const { type } = view;
    setEvents([]);
    setIsLoader(true);

    operationId.current += 1;
    const myOperationId = operationId.current;

    const typeMap = {
      timeGridWeek: 'weekly',
      timeGridDay: 'daily',
      dayGridMonth: 'monthly',
    };
    const timePeriod = typeMap[type];

    const attorneyForQuery = attorney?.id === ALL_ATTORNEYS ? null : attorney?.id;
    let params = {
      period: timePeriod,
      FromDate: start,
      ToDate: end,
      stateCode,
    };
    if (attorneyForQuery) {
      params = { ...params, attorneyId: attorneyForQuery };
    }

    if (attorney) {
      // TODO change action
      dispatch(getCasesAssignedToAttorney(params))
        .then(({ payload: { result } }) => {
          if (myOperationId !== operationId.current) return;
          const getEventLengthInHours = (numberOfCases) => {
            if (numberOfCases < 10) return 1;
            if (numberOfCases < 50) return 2;
            return 2 + Math.floor(numberOfCases / 50) * 0.5;
          };

          if (timePeriod === 'daily') {
            const periodEvents = [];
            result.forEach(({ hour, minute, courts }) => {
              courts.forEach(({ courtName, caseCount, hearingCases }) => {
                const eventStart = moment(start)
                  .set('hour', hour)
                  .set('minute', minute ?? 0);
                periodEvents.push({
                  start: eventStart.format(dateFormat),
                  end: eventStart
                    .add(getEventLengthInHours(hearingCases.length), 'hour')
                    .format(dateFormat),
                  title: courtName,
                  extendedProps: {
                    courtName,
                    caseCount,
                    hearingCases,
                  },
                });
              });
            });

            setEvents(periodEvents);
          }

          if (timePeriod === 'weekly') {
            const periodEvents = [];

            result.forEach(({ hours, day, courtDate }) => {
              hours.forEach(({ hour, courts, minute }) => {
                courts.forEach(({ courtName, caseCount, hearingCases }) => {
                  const eventStart = moment(courtDate)
                    .set('date', day)
                    .set('hour', hour)
                    .set('minute', minute ?? 0);
                  periodEvents.push({
                    start: eventStart.format(dateFormat),
                    end: eventStart
                      .add(getEventLengthInHours(hearingCases.length), 'hour')
                      .format(dateFormat),
                    title: courtName,
                    extendedProps: {
                      courtName,
                      caseCount,
                      hearingCases,
                    },
                  });
                });
              });
            });

            setEvents(periodEvents);
          }

          if (timePeriod === 'monthly') {
            const periodEvents = [];

            result.forEach(({ day, courts }) => {
              courts.forEach(({ courtName, caseCount, hearingCases }) => {
                const eventStart = moment(start).set('date', day).set('hour', 0).add(1, 'month');
                periodEvents.push({
                  start: eventStart.format(dateFormat),
                  end: eventStart
                    .add(getEventLengthInHours(hearingCases.length), 'hour')
                    .format(dateFormat),
                  title: courtName,
                  extendedProps: {
                    courtName,
                    caseCount,
                    hearingCases,
                  },
                });
              });
            });

            setEvents(periodEvents);
          }
        })
        .finally(() => {
          setIsLoader(false);
        });
    } else {
      setEvents([]);
      setIsLoader(false);
    }
  };

  const onEventClick = ({
    event: {
      _def: { extendedProps },
    },
  }) => {
    setHearingsData(extendedProps);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (calendarRef.current) {
      const dayButton = document.getElementById('day_button');
      const toolbarTitle = document.getElementById('title_button');

      const onClick = () => {
        calendarRef.current.getApi().today();
      };

      if (dayButton) {
        dayButton.addEventListener('click', onClick);
      }
      if (toolbarTitle) {
        toolbarTitle.addEventListener('click', onClick);
      }

      return () => {
        if (dayButton) {
          dayButton.removeEventListener('click', onClick);
        }
        if (toolbarTitle) {
          toolbarTitle.removeEventListener('click', onClick);
        }
      };
    }
  }, [calendarRef]);

  const refetchDataSet = () => {
    // haven't found another way to get this dates to update calendar
    const apiCalendar = calendarRef.current?.getApi();
    const end = apiCalendar?.view?.activeEnd;
    const start = apiCalendar?.view?.activeStart;
    const view = apiCalendar?.view;
    if (start && end && view) {
      onDatesSet({ start, end, view });
    }
  };

  const fetchSettings = async () => {
    const res = await dispatch(getSettings(UserGuid));
    if (!res.error) {
      const value = TIMERS.find((item) => {
        const valueFromQuery =
          res.payload?.result?.schedulingAssistantDashboardRefreshTime || 'no_refresh';
        return item.value === valueFromQuery;
      });
      if (value) setRefresh(value);
    }
  };

  useEffect(() => {
    refetchDataSet();
  }, [stateCode, attorney]);

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    if (refresh.value !== TIMERS[0].value) {
      timer.current = setInterval(() => {
        refetchDataSet();
      }, refresh.value * 1000);
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [refresh, stateCode, attorney]);

  return (
    <WrapperAccordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ArrowDown data-testid="arrow_down_icon" />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h5" mt={2} mb={2} data-testid="calendar_title">
          Court Calendar
        </Typography>
      </AccordionSummary>

      <Box position="relative">
        {isLoader && (
          <Box
            position="absolute"
            zIndex={10}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
        <CalendarHeader
          calendarRef={calendarRef.current}
          customRightFilters={
            <>
              <BaseRightButtons calendarRef={calendarRef.current} />
              <CustomRightFilters
                stateCode={stateCode}
                attorney={attorney}
                setAttorney={setAttorney}
                refresh={refresh}
                setRefresh={setRefresh}
                isSchedulingAssistant
              />
            </>
          }
        />
        <FullCalendar
          ref={calendarRef}
          allDaySlot={false}
          height="900px"
          plugins={[momentPlugin, dayGridPlugin, timeGridPlugin]}
          initialView="timeGridDay"
          views={{
            day: {
              dayHeaders: false,
            },
          }}
          headerToolbar={false}
          events={events}
          datesSet={onDatesSet}
          eventClick={onEventClick}
          buttonText={{ day: 'Day', week: 'Week', month: 'Month' }}
          slotEventOverlap={false}
          firstDay={0}
          eventTimeFormat="h:mm A"
          eventBackgroundColor="#B1D9FF"
          eventBorderColor="#FFF"
          eventDisplay="block"
          fixedWeekCount={false}
          scrollTime="08:00:00"
          eventContent={renderEventContent}
          dayHeaderContent={renderDayHeaderContent}
        />
      </Box>
      <MDialog
        isOpen={hearingsData}
        title={
          <>
            <Typography sx={{ mb: 1 }} variant="h5">
              Hearing(s)
            </Typography>
            <Typography sx={{ mb: 4 }} variant="bodyL500" color="text.secondary">
              {hearingsData?.courtName}
            </Typography>
          </>
        }
        onClose={onCloseModal}
        maxWidth="1024px"
        fullWidth
        footerActions={
          <MButton size="large" onClick={onCloseModal}>
            OK
          </MButton>
        }
      >
        <Table
          columns={HearingsPopupColumns}
          rows={hearingsData?.hearingCases || []}
          maxHeight="40vh"
          loading={false}
        />
      </MDialog>
    </WrapperAccordion>
  );
};

DashboardCalendar.propTypes = {
  stateCode: PropTypes.string.isRequired,
};

export default DashboardCalendar;
