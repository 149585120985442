import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Container,
  Stack,
  Typography,
  Box,
  Accordion,
  styled,
  AccordionSummary,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { caseInformationForm } from './CivilMatterCaseInformationNevada.constants';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import Dropdown from '../../../atoms/Dropdown';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import MButton from '../../../MUI/Button/MButton';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import notificationUtils from '../../../../utils/notificationUtils';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import {
  getCaseInformation,
  getNevadaLegalAssistants,
} from '../../../../store/slices/cases/casesNevadaSlice';
import { updateCaseNumber } from '../../../../store/slices/cases/casesGeneralSlice';
import { fullNameFromObj } from '../../../../utils/fullNameFromObj';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
    '.MuiPaper-root': {
      boxShadow: '0px 3px 20px rgba(69, 80, 121, 0.13)',
      marginBottom: '16px',
      borderRadius: '24px',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '16px',
      height: '16px',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 32px 32px',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

const CaseInformation = ({ onUpdate }) => {
  const form = useForm();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = location.state;

  const [isLoader, setIsLoader] = useState(false);

  const { boxTitle, inputBoxes } = caseInformationForm;

  const { legalAssistants } = useSelector((state) => state.casesNevada);

  const getCaseInformationData = async () => {
    setIsLoader(true);
    try {
      const {
        result: {
          caseNumber,
          poNumber,
          envelopeNumber,
          evictionGrantedOn,
          noticeExpiresOn,
          assignedLegalAssistant,
        },
      } = await dispatch(getCaseInformation(id)).unwrap();

      const formValues = {
        caseNumber,
        poNumber,
        envelopeNumber,
        noticeExpiresOn,
        evictionGrantedOn,
        assignedLegalAssistant: assignedLegalAssistant
          ? {
              id: assignedLegalAssistant.userId,
              label: fullNameFromObj(assignedLegalAssistant),
              value: assignedLegalAssistant.userId,
            }
          : null,
      };

      form.reset(formValues);
    } catch (e) {
      // todo: Add Alert Component
      alert('---------------', e);
    } finally {
      setIsLoader(false);
    }
  };

  const fetchData = () => {
    getCaseInformationData();
    dispatch(getNevadaLegalAssistants(id));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const canAssignLegalAssistant = useAllowed([rolesDB.Admin, rolesDB.LegalAssistant]);

  const getDisabled = (name) => {
    switch (name) {
      case 'assignedLegalAssistant':
        return !canAssignLegalAssistant;
      default:
        return false;
    }
  };

  const getOptions = useCallback(
    (name) => {
      switch (name) {
        case 'assignedLegalAssistant':
          return legalAssistants;
        default:
          return [];
      }
    },
    [legalAssistants],
  );

  const handleSubmit = async (values) => {
    const {
      caseNumber,
      poNumber,
      envelopeNumber,
      evictionGrantedOn,
      noticeExpiresOn,
      assignedLegalAssistant,
    } = values;

    const evictionDate = evictionGrantedOn ? moment(evictionGrantedOn, moment.ISO_8601) : null;
    const noticeExpiresOnDate = noticeExpiresOn ? moment(noticeExpiresOn, moment.ISO_8601) : null;

    const envelopeNum = envelopeNumber || null;
    setIsLoader(true);
    try {
      await dispatch(
        updateCaseNumber({
          id,
          ...(caseNumber && { caseNumber }),
          ...(poNumber && { poNumber }),
          ...(envelopeNum && { envelopeNumber: envelopeNum }),
          ...(evictionDate && { evictionGrantedOn: evictionDate }),
          ...(noticeExpiresOnDate && { noticeExpiresOn: noticeExpiresOnDate }),
          ...(assignedLegalAssistant && {
            assignedLegalAssistantUserId: assignedLegalAssistant.id,
          }),
        }),
      ).unwrap();
      notificationUtils.success('Updated successfully');
      onUpdate?.();
      await getCaseInformationData();
    } catch (e) {
      // todo: Add Alert Component
      alert('---------------', e);
    }
    setIsLoader(false);
  };

  return (
    <Form onSubmit={handleSubmit} form={form}>
      <StyledContainer
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          width: 1,
        }}
      >
        <StyledAccordion defaultExpanded sx={{ p: 4 }}>
          <AccordionSummary expandIcon={<ArrowDown />} sx={{ padding: 0 }}>
            <Typography variant="h5">{boxTitle}</Typography>
          </AccordionSummary>
          <Stack direction="row" alignItems="center" columnGap={3} rowGap={2} flexWrap="wrap">
            {inputBoxes.map((item) => {
              const getWrapper = (children) =>
                item.withUpdate ? (
                  <Box alignItems="center" display="flex" key={`${item.label}Box`}>
                    {children}
                    <MButton
                      sx={{
                        ml: '24px',
                        mt: '28px',
                      }}
                      type="submit"
                      disabled={isLoader}
                    >
                      Update
                    </MButton>
                  </Box>
                ) : (
                  children
                );

              const getField = () => {
                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <Dropdown
                          value={field.value}
                          isAddDisabled={false}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          isColumn
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={onCustomChange(field.onChange)}
                          isDisabled={getDisabled(item.name) || item.isDisabled}
                          isMandatory={item.isMandatory}
                        />
                      )}
                    />
                  );
                }

                if (item.isDatePicker) {
                  return (
                    <div style={{ width: item.wrapperWidth || 'auto' }} key={item.label}>
                      <Field
                        name={item.name}
                        render={({ field, onCustomChange }) => (
                          <DateOrTimePicker
                            name={item.name}
                            label={item.label}
                            isDate
                            Svg={Calender}
                            selected={field.value}
                            width={item.width}
                            height={item.height}
                            isMandatory={item.isMandatory}
                            disabled={item.getDisabled ? item.getDisabled(form) : item.isDisabled}
                            placeholder={item.placeholder}
                            onChange={onCustomChange(field.onChange)}
                            setSelectedTimeOrDate={onCustomChange(field.onChange)}
                          />
                        )}
                      />
                    </div>
                  );
                }

                if (item.isSpace) {
                  return <div style={{ width: '100%' }} key={item.label} />;
                }

                return (
                  <Field
                    name={item.name}
                    key={item.name}
                    render={({ field }) => (
                      <EditInputWithLabel
                        type="text"
                        label={item.label}
                        placeholder={item.placeholder}
                        width={item.width}
                        name={item.name}
                        value={field.value}
                        onChange={field.onChange}
                        isDisabled={item.isDisabled}
                      />
                    )}
                  />
                );
              };
              return getWrapper(getField());
            })}
          </Stack>
        </StyledAccordion>
      </StyledContainer>
    </Form>
  );
};

CaseInformation.propTypes = {
  onUpdate: PropTypes.func,
};

CaseInformation.defaultProps = {
  onUpdate: null,
};

export default CaseInformation;
