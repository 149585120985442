/* eslint-disable */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const redateNevadaCasesDocuments = createAsyncThunk(
  'notices/redateNevadaCasesDocuments',
  async (params) => {
    const { data } = await apiClient.post(`/api/nevadaCases/documents/redate`, params);

    return data.result;
  },
);

export const redateAZCasesDocuments = createAsyncThunk(
  'notices/redateAZCasesDocuments',
  async (params) => {
    const { data } = await apiClient.post(`/api/arizonaCases/documents/redate`, params);

    return data.result;
  },
);

export const approveNevadaCasesDocuments = createAsyncThunk(
  'notices/approveNevadaCasesDocuments',
  async (params) => {
    const { data } = await apiClient.put(`/api/nevadaCases/documents/approve`, params);

    return data.result;
  },
);

export const approveAZCasesDocuments = createAsyncThunk(
  'notices/approveAZCasesDocuments',
  async (params) => {
    const { data } = await apiClient.put(`/api/arizonaCases/documents/approve`, params);

    return data.result;
  },
);

export const getRejectedNevadaNotices = createAsyncThunk(
  'notices/getRejectedNevadaNotices',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/nevadaCases/documents/notices/rejected/filter`,
      params,
    );

    return {
      ...data.result,
      items: data.result.items.map((item) => ({
        ...item,
        stateCode: params.stateCode,
      })),
    };
  },
);

export const getApprovedNevadaNotices = createAsyncThunk(
  'notices/getApprovedNevadaNotices',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/nevadaCases/documents/notices/approved/filter`,
      params,
    );
    return data.result;
  },
);

export const getAwaitingNevadaNotices = createAsyncThunk(
  'notices/getAwaitingNevadaNotices',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/nevadaCases/documents/notices/awaitingApproval/filter`,
      params,
    );
    return data.result;
  },
);

export const getRejectedAZNotices = createAsyncThunk(
  'notices/getRejectedAZNotices',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/arizonaCases/documents/notices/rejected/filter`,
      params,
    );

    return {
      ...data.result,
      items: data.result.items.map((item) => ({
        ...item,
        stateCode: params.stateCode,
      })),
    };
  },
);

export const getApprovedAZNotices = createAsyncThunk(
  'notices/getApprovedAZNotices',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/arizonaCases/documents/notices/approved/filter`,
      params,
    );
    return data.result;
  },
);

export const getAwaitingAZNotices = createAsyncThunk(
  'notices/getAwaitingAZNotices',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/arizonaCases/documents/notices/awaitingApproval/filter`,
      params,
    );
    return data.result;
  },
);

export const getCommunities = createAsyncThunk(
  'notices/getCommunities',
  async (params = {}, { getState }) => {
    const { open, ...rest } = params;

    const { data } = await apiClient.post(`/api/communities/filter`, {
      ...rest,
      stateCode: rest.stateCode,
      communityName: rest.communityName || '',
    });

    const {
      notices: { communities },
    } = getState();

    if (!open && !communities) return null;

    const prevValue = communities?.prevSearchValue || '';
    if (!open && prevValue !== rest.communityName) {
      return {
        ...data.result,
        prevSearchValue: rest.communityName,
        items: data.result.items.map((item) => ({
          id: item.communityId,
          label: item?.communityName,
          value: item?.communityName,
        })),
      };
    }

    if (!open && communities) {
      return {
        ...data.result,
        prevSearchValue: rest.communityName,
        items: communities.items.concat(
          data.result.items.map((item) => ({
            id: item.communityId,
            label: item?.communityName,
            value: item?.communityName,
          })),
        ),
      };
    }

    return {
      ...data.result,
      prevSearchValue: rest.communityName,
      items: data.result.items.map((item) => ({
        id: item.communityId,
        label: item?.communityName,
        value: item?.communityName,
      })),
    };
  },
);

export const getCompanyManagementsList = createAsyncThunk(
  'notices/getCompanyManagementsList',
  async (params, { getState }) => {
    const { open, ...rest } = params;

    const { data } = await apiClient.post(`/api/managements/filter`, rest, {
      headers: { 'Content-Type': 'application/json' },
    });

    const {
      notices: { managementCompanies },
    } = getState();

    if (!open && !managementCompanies) return null;

    const prevValue = managementCompanies?.prevSearchValue || '';
    if (!open && prevValue !== rest.managementName) {
      return {
        ...data.result,
        prevSearchValue: rest.managementName,
        items: data.result.items.map((item) => ({
          id: item.managementId,
          label: item?.managementName,
          value: item?.managementName,
        })),
      };
    }

    if (!open && managementCompanies) {
      return {
        ...data.result,
        prevSearchValue: rest.communityName,
        items: managementCompanies.items.concat(
          data.result.items.map((item) => ({
            id: item.managementId,
            label: item?.managementName,
            value: item?.managementName,
          })),
        ),
      };
    }

    return {
      ...data.result,
      prevSearchValue: rest.communityName,
      items: data.result.items.map((item) => ({
        id: item.managementId,
        label: item?.managementName,
        value: item?.managementName,
      })),
    };
  },
);

export const getOpposingParty = createAsyncThunk(
  'notices/getOpposingParty',
  // eslint-disable-next-line
  async (params, { getState }) => {
    const { open, ...rest } = params;

    // TODO check api method here
    const { data } = await apiClient.post(`/api/cases/defendants/filter`, rest, {
      headers: { 'Content-Type': 'application/json' },
    });

    const {
      notices: { opposingParty },
    } = getState();

    if (!open && !opposingParty) return null;

    const prevValue = opposingParty?.prevSearchValue || '';
    if (!open && prevValue !== rest.fullName) {
      return {
        ...data.result,
        prevSearchValue: rest.fullName,
        items: data.result.items.map((item) => ({
          id: item.caseDefendantId,
          label: item?.defendantFullName,
          value: item?.defendantFullName,
        })),
      };
    }

    if (!open && opposingParty) {
      return {
        ...data.result,
        prevSearchValue: rest.fullName,
        items: opposingParty.items.concat(
          data.result.items.map((item) => ({
            id: item.caseDefendantId,
            label: item?.defendantFullName,
            value: item?.defendantFullName,
          })),
        ),
      };
    }

    return {
      ...data.result,
      prevSearchValue: rest.fullName,
      items: data.result.items.map((item) => ({
        id: item.caseDefendantId,
        label: item?.defendantFullName,
        value: item?.defendantFullName,
      })),
    };
  },
);

export const resetManagementsList = createAction('notices/resetManagementsList', () => {
  return {
    type: 'notices/resetManagementsList',
  };
});

export const resetOppositParty = createAction('notices/resetOppositParty', () => {
  return {
    type: 'notices/resetOppositParty',
  };
});

export const resetCommunitiesList = createAction('notices/resetCommunitiesList', () => {
  return {
    type: 'notices/resetCommunitiesList',
  };
});

export const resetNotices = createAction('notices/resetNotices', () => {
  return {
    type: 'notices/resetNotices',
  };
});

const initialState = {
  managementCompanies: null,
  notices: null,
  communities: null,
  error: null,
  opposingParty: null,
};

export const noticesSlice = createSlice({
  name: 'notices',
  initialState,
  reducers: {},
  extraReducers: {
    [resetManagementsList]: (state) => {
      state.managementCompanies = {};
    },
    [resetCommunitiesList]: (state) => {
      state.communities = {};
    },
    [resetOppositParty]: (state) => {
      state.opposingParty = {};
    },
    [resetNotices]: (state) => {
      state.notices = null;
    },
    [getCompanyManagementsList.fulfilled]: (state, action) => {
      state.managementCompanies = action.payload;
    },
    [getOpposingParty.fulfilled]: (state, action) => {
      state.opposingParty = action.payload;
    },
    [getApprovedNevadaNotices.fulfilled]: (state, action) => {
      state.notices = action.payload;
    },
    [getRejectedNevadaNotices.fulfilled]: (state, action) => {
      state.notices = action.payload;
    },
    [getAwaitingNevadaNotices.fulfilled]: (state, action) => {
      state.notices = action.payload;
    },
    [getApprovedAZNotices.fulfilled]: (state, action) => {
      state.notices = action.payload;
    },
    [getRejectedAZNotices.fulfilled]: (state, action) => {
      state.notices = action.payload;
    },
    [getAwaitingAZNotices.fulfilled]: (state, action) => {
      state.notices = action.payload;
    },
    [getCommunities.fulfilled]: (state, action) => {
      state.communities = action.payload;
    },
  },
});

export default noticesSlice.reducer;
