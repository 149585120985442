import { LinearProgress, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  billBillingInstance,
  unbillBillingInstance,
  deleteBillingInstance,
} from '../../../../store/slices/generateInvoiceSlice';
import Dropdown from '../../../atoms/Dropdown';
import NotificationDialog from '../../../molecules/NotificationDialog';
import notificationUtils from '../../../../utils/notificationUtils';

const statuses = {
  UNBILLED: 'Unbilled',
  BILLED: 'Billed',
  REMOVED: 'Removed',
};

const options = [
  { value: statuses.UNBILLED, label: 'Unbilled', id: 1 },
  { value: statuses.BILLED, label: 'Billed', id: 2 },
];

const removeOption = { value: statuses.REMOVED, label: 'Removed', id: 3 };

const BillingStatusCell = ({
  row: {
    original: { billingInstanceId },
  },
  value: billingStatus,
  refreshPage,
  state: { pageIndex },
  goPreviousPage,
  rows,
  canPreviousPage,
}) => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [isRemoveDialogOpen, setOpenRemoveDialog] = useState(false);
  const shouldGoBack = rows?.length === 1 && canPreviousPage;
  const refresh = () => refreshPage(pageIndex + 1);

  const currentOptions =
    billingStatus === statuses.UNBILLED || billingStatus === statuses.REMOVED
      ? [...options, removeOption]
      : options;
  const value = currentOptions.find((option) => option.value === billingStatus) ?? null;

  const handleSetBillingStatus = async ({ value: nextValue }) => {
    if (nextValue === statuses.BILLED) {
      setIsLoader(true);
      await dispatch(billBillingInstance(billingInstanceId));
      refresh();
    } else if (nextValue === statuses.REMOVED) {
      setOpenRemoveDialog(true);
    } else {
      setIsLoader(true);
      await dispatch(unbillBillingInstance(billingInstanceId));
      refresh();
    }
  };

  const closeRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const confirmRemove = async () => {
    setIsLoader(true);
    try {
      await dispatch(deleteBillingInstance(billingInstanceId)).unwrap();
      if (shouldGoBack) goPreviousPage();
      else refreshPage();
    } catch {
      notificationUtils.error('Failed, try again later');
    } finally {
      closeRemoveDialog();
      setIsLoader(false);
    }
  };

  if (isLoader) {
    return (
      <Box data-testid="LinearProgress" flexGrow={1}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <NotificationDialog
        title="Instance Removal"
        desc={
          <>
            Removing a billing instance is irreversible.
            <br />
            Only the Database Administrator can restore a removed instance.
            <br />
            Do you want to continue?
          </>
        }
        type="none"
        buttonSecondaryText="Remove Instance"
        buttonPrimaryText="Cancel"
        onClose={closeRemoveDialog} // secondary button is remove action
        onConfirm={closeRemoveDialog} // primary button is cancel
        onCancel={confirmRemove} // secondary button is remove action
        isOpen={!!isRemoveDialogOpen}
        buttonSecondaryWidth="180px"
      />
      <Dropdown
        value={value}
        width="100%"
        isColumn
        options={currentOptions}
        onChange={handleSetBillingStatus}
        isDisabled={value?.value === statuses.REMOVED}
      />
    </>
  );
};

BillingStatusCell.propTypes = {
  value: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      billingInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  refreshPage: PropTypes.func.isRequired,
  state: PropTypes.shape({
    pageIndex: PropTypes.number.isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  goPreviousPage: PropTypes.func.isRequired,
};

export default BillingStatusCell;
