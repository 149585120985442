import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const BillableCell = ({ value, row }) => {
  const billable = Number(row?.original?.billable) || 0;
  const billableQuantityTotal = Number(row?.original?.billableQuantityTotal) || 0;

  if (row?.original?.isTotalRow) {
    return (
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        {billable > 0 ? `$${billable}` : 0}
        <br />
        {billableQuantityTotal > 0 ? `${billableQuantityTotal}h` : 0}
      </Typography>
    );
  }

  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
      }}
    >
      {value ? `$${value}` : '-'}
    </Typography>
  );
};

BillableCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      billable: PropTypes.string,
      billableQuantityTotal: PropTypes.string,
      isTotalRow: PropTypes.bool,
    }),
  }).isRequired,
};

BillableCell.defaultProps = {
  value: '',
};

export default BillableCell;
