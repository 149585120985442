import React from 'react';
import { Routes, Route } from 'react-router-dom';

import OwnerEntityProvider from '../../../services/OwnerEntityProvider';
import OwnerEntities from '../../../components/pages/DataHandling/Owner/OwnerEntities';
import OwnerEntity from '../../../components/pages/DataHandling/Owner/OwnerEntity';
import OwnerEntityAddress from '../../../components/pages/DataHandling/Owner/OwnerEntityAddress';
import OwnerEntityNotes from '../../../components/pages/DataHandling/Owner/OwnerEntityNotes';
import OwnerEntityContainer from '../../../components/pages/DataHandling/Owner/OwnerEntityContainer';
import DataHandling from '../../../components/pages/DataHandling';
import Document from '../../../components/pages/DataHandling/DocumentSection/Document';

import Courts from '../../../components/pages/DataHandling/Courts';
import Court from '../../../components/pages/DataHandling/Court';
import CourtContainer from '../../../components/pages/DataHandling/CourtContainer';
import CourtProvider from '../../../services/CourtProvider';
import CourtAddress from '../../../components/pages/DataHandling/CourtAddress';
import CourtNotes from '../../../components/pages/DataHandling/CourtNotes';

import AddressesTable from '../../../components/pages/DataHandling/Addresses/AddressesTable/AddressesTable';
import AddressContainer from '../../../components/pages/DataHandling/Addresses/AddressContainer';
import AddressProvider from '../../../services/AddressProvider';
import Address from '../../../components/pages/DataHandling/Addresses/Address/Address';
import AddressNotes from '../../../components/pages/DataHandling/Addresses/AddressNotes';

import CommunityProvider from '../../../services/CommunityProvider';
import Communities from '../../../components/pages/DataHandling/CommunitiesFlow/Communities';
import Community from '../../../components/pages/DataHandling/CommunitiesFlow/Community';
import CommunityAddress from '../../../components/pages/DataHandling/CommunitiesFlow/CommunityAddress';
import CommunityNotes from '../../../components/pages/DataHandling/CommunitiesFlow/CommunityNotes/CommunityNotes';
import CommunityContainer from '../../../components/pages/DataHandling/CommunitiesFlow/CommunityContainer/CommunityContainer';

import Managements from '../../../components/pages/DataHandling/ManagementFlow/Managements';
import ManagementContainer from '../../../components/pages/DataHandling/ManagementFlow/ManagementContainer';
import ManagementNotes from '../../../components/pages/DataHandling/ManagementFlow/ManagementNotes';
import Management from '../../../components/pages/DataHandling/ManagementFlow/Management';
import ManagementProvider from '../../../services/ManagementProvider';
import ManagementCommunities from '../../../components/pages/DataHandling/ManagementFlow/ManagementCommunities';
import ManagementAddresses from '../../../components/pages/DataHandling/ManagementFlow/ManagementAddresses';

import Contacts from '../../../components/pages/DataHandling/Contacts/Contacts';
import Contact from '../../../components/pages/DataHandling/Contacts/Contact';
import ContactNotes from '../../../components/pages/DataHandling/Contacts/ContactNotes/ContactNotes';
import ContactContainer from '../../../components/pages/DataHandling/Contacts/ContactContainer';
import ContactProvider from '../../../services/ContactProvider';

const DataBaseDataHandlingRoutes = () => (
  <Routes>
    <Route path="/" element={<DataHandling />}>
      <Route path="ownerentities" element={<OwnerEntities />} />
      <Route path="newownerentity" element={<OwnerEntityContainer />}>
        <Route path="ownerentity" element={<OwnerEntity />} />
      </Route>
      <Route
        path="editownerentity"
        element={
          <OwnerEntityProvider>
            <OwnerEntityContainer />
          </OwnerEntityProvider>
        }
      >
        <Route path="ownerentity" element={<OwnerEntity />} />
        <Route path="address" element={<OwnerEntityAddress />} />
        <Route path="documents" element={<Document />} />
        <Route path="notes" element={<OwnerEntityNotes />} />
      </Route>

      <Route path="courts" element={<Courts />} />
      <Route
        path="newcourt"
        element={
          <CourtProvider>
            <CourtContainer />
          </CourtProvider>
        }
      >
        <Route path="court" element={<Court />} />
        <Route path="address" element={<CourtAddress />} />
        <Route path="notes" element={<CourtNotes />} />
      </Route>
      <Route
        path="editcourt"
        element={
          <CourtProvider>
            <CourtContainer />
          </CourtProvider>
        }
      >
        <Route path="court" element={<Court />} />
        <Route path="address" element={<CourtAddress />} />
        <Route path="documents" element={<Document />} />
        <Route path="notes" element={<CourtNotes />} />
      </Route>

      <Route path="addresses" element={<AddressesTable />} />
      <Route path="newaddress" element={<AddressContainer />}>
        <Route path="address" element={<Address />} />
        <Route path="notes" element={<AddressNotes />} />
      </Route>
      <Route
        path="editaddress"
        element={
          <AddressProvider>
            <AddressContainer />
          </AddressProvider>
        }
      >
        <Route path="address" element={<Address />} />
        <Route path="documents" element={<Document />} />
        <Route path="notes" element={<AddressNotes />} />
      </Route>
      <Route path="managements" element={<Managements />} />

      <Route path="communities" element={<Communities />} />
      <Route
        path="newcommunity"
        element={
          <CommunityProvider>
            <CommunityContainer />
          </CommunityProvider>
        }
      >
        <Route path="community" element={<Community />} />
      </Route>

      <Route
        path="editcommunity"
        element={
          <CommunityProvider>
            <CommunityContainer />
          </CommunityProvider>
        }
      >
        <Route path="community" element={<Community />} />
        <Route path="address" element={<CommunityAddress />} />
        <Route path="documents" element={<Document />} />
        <Route path="notes" element={<CommunityNotes />} />
      </Route>

      <Route path="newmanagement" element={<ManagementContainer />}>
        <Route path="management" element={<Management />} />
        <Route path="communities" element={<ManagementCommunities />} />
        <Route path="addresses" element={<ManagementAddresses />} />
        <Route path="documents" element={<Document />} />
        <Route path="notes" element={<ManagementNotes />} />
      </Route>

      <Route
        path="editmanagement"
        element={
          <ManagementProvider>
            <ManagementContainer />
          </ManagementProvider>
        }
      >
        <Route path="management" element={<Management />} />
        <Route path="communities" element={<ManagementCommunities />} />
        <Route path="addresses" element={<ManagementAddresses />} />
        <Route path="documents" element={<Document />} />
        <Route path="notes" element={<ManagementNotes />} />
      </Route>

      <Route path="contacts" element={<Contacts />} />
      <Route path="newcontact" element={<ContactContainer />}>
        <Route path="contact" element={<Contact />} />
        <Route path="notes" element={<ContactNotes />} />
      </Route>
      <Route
        path="editcontact"
        element={
          <ContactProvider>
            <ContactContainer />
          </ContactProvider>
        }
      >
        <Route path="contact" element={<Contact />} />
        <Route path="notes" element={<ContactNotes />} />
      </Route>
    </Route>
  </Routes>
);

export default DataBaseDataHandlingRoutes;
