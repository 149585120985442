/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const createAddress = createAsyncThunk(
  'address/createAddress',
  async (createAddressObj, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post('/api/addresses', { ...createAddressObj });

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

const initialState = {
  addressId: null,
  error: null,
};

export const createAddressSlice = createSlice({
  name: 'createAddress',
  initialState,
  reducers: {},
  extraReducers: {
    [createAddress.fulfilled]: (state, action) => {
      state.addressId = action.payload.result;
    },
    [createAddress.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default createAddressSlice.reducer;
