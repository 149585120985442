export const loginConstants = {
  heading: 'Personal account',
  user: { placeholder: 'Username' },
  password: { placeholder: 'Password' },
  submitButton: 'Login',
  forgot: 'Forgot password?',
  'user.invalid.login.or.password': 'Incorrect username or password.',
  'user.is.inActive': 'Inactive user.',
  eula: 'End User License Agreement',
};

export const portalOptions = [
  { id: 0, label: 'Client Portal', value: 'clientPortal', path: '/cp/login', base: 'cp' },
  { id: 1, label: 'Data Base Portal', value: 'databasePortal', path: '/db/login', base: 'db' },
  {
    id: 2,
    label: 'Client Portal',
    value: 'clientPortal',
    path: '/client/login',
    base: 'client',
    hidden: true,
  },
];
