import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import MDialog from '../../../../atoms/MDialog';
import MButton from '../../../../MUI/Button/MButton';
import useSearchDropdown from '../../../../../hooks/useSearchDropdown';
import Dropdown from '../../../../atoms/Dropdown';
import CheckboxWithLabel from '../../../../molecules/CheckboxWithLabel';
import { getManagementAddresses } from '../../../../../store/slices/editManagementSlice';

const NewCorporateAddressDialog = ({
  isOpen,
  onClose,
  stateCode,
  managementName,
  managementId,
  onSubmit,
}) => {
  const { managementAddresses } = useSelector((state) => state.editManagement);

  const initialNullishAddress = {
    label: '-',
    value: null,
    id: null,
  };

  const addresses = managementAddresses?.items?.map((item) => {
    return {
      label: item.address,
      value: item.addressId,
      id: item.addressId,
    };
  });

  const [address, setAddress] = useState(null);
  const [checked, setChecked] = useState(false);

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: managementAddresses.hasNextPage,
    getAction: ({ input, page }) =>
      getManagementAddresses({
        currentPage: page,
        stateCode,
        address: input,
        id: managementId,
      }),
  });

  const changeAddress = (value) => {
    setAddress(value);
    if (value.value && checked) {
      setChecked(false);
    }
  };

  const handleCheckboxChange = (checkedVal) => {
    setChecked(checkedVal);
    if (checkedVal) {
      setAddress(initialNullishAddress);
    } else {
      setAddress(null);
    }
  };

  const handleSubmit = () => {
    onSubmit({
      managementCorporateAddressId: address.id,
      managementCorporateAddress: address.label,
    });
  };

  return (
    <MDialog
      isOpen={isOpen}
      onClose={onClose}
      title={
        stateCode === 'AZ'
          ? 'Set Up a New Arizona Corporate Address'
          : 'Set Up a New Nevada Corporate Address'
      }
      maxWidth="741px"
      minWidth="741px"
      scrollable
    >
      <Typography variant="bodyM">
        To remove {stateCode === 'AZ' ? 'Arizona' : 'Nevada'} corporate address you can set up a new
        corporate address for{' '}
        <Typography variant="bodyM" sx={{ fontWeight: 700 }}>
          {managementName}
        </Typography>{' '}
        management company
      </Typography>
      <Box sx={{ maxHeight: '300px' }} mt={3} mb={3}>
        <Dropdown
          value={address}
          label="Address"
          isColumn
          isMandatory
          placeholder="Select Address"
          onChange={changeAddress}
          options={addresses || []}
          onMenuScrollToBottom={() => handleMenuScrollToBottom()}
          onInputChange={changeInput}
          isSearchable
          filterOption={null}
          menuPosition="fixed"
        />
      </Box>

      <Box>
        <CheckboxWithLabel
          defaultValue={false}
          label="No corporate address"
          isChecked={checked}
          onChange={handleCheckboxChange}
        />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mt: 4 }}
        justifyContent="flex-end"
        data-testid="dialog_actions"
      >
        <MButton size="large" variant="bordered" onClick={onClose}>
          Cancel
        </MButton>
        <MButton size="large" onClick={handleSubmit}>
          Save
        </MButton>
      </Stack>
    </MDialog>
  );
};

NewCorporateAddressDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  stateCode: PropTypes.string.isRequired,
  managementName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  managementId: PropTypes.number.isRequired,
};

export default NewCorporateAddressDialog;
