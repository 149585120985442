import * as React from 'react';
import { Typography, Checkbox, Box } from '@mui/material';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { PropTypes } from 'prop-types';
import { palette } from '../../../theme/default';
import SearchLabel from '../SearchLabel/SearchLabel';
import { useRandomId } from '../../../hooks/useRandomId';

const StyledContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  fontWeight: 500,
  fontSize: 16,
}));

const selectAllOption = {
  id: 'all',
  label: 'Select/Unselect All',
  value: 'all',
};

const Option = (props) => {
  const { isSelected, label, data, allChecked, amountChecked } = props;

  return (
    <components.Option {...props}>
      <Checkbox
        sx={{
          '& .MuiSvgIcon-root': {
            fill: palette.buttonPrimary.active,
          },
          visibility: 'visible',
        }}
        checked={allChecked || isSelected}
        indeterminate={!allChecked && data.value === 'all' && amountChecked > 0}
      />
      <Typography data-testid="option">{label}</Typography>
    </components.Option>
  );
};

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  allChecked: PropTypes.bool.isRequired,
  amountChecked: PropTypes.number.isRequired,
};

const ValueContainer = ({ children, ...props }) => {
  const values = props.getValue();

  return (
    <components.ValueContainer {...props}>
      {!!values.length && (
        <span
          style={{
            maxWidth: '100%',
          }}
          data-testid="value_label"
        >
          {values.length} selected
        </span>
      )}
      {children}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  hasValue: PropTypes.bool.isRequired,
  getValue: PropTypes.func.isRequired,
};

const customStyles = (width, height) => {
  return {
    control: (provided) => ({
      ...provided,
      borderRadius: '0 10px 10px 0',
      border: `1px solid  ${palette.additional.lines}`,
      width: width || 'auto',
      height: `${height}px` || '48px',
      paddingLeft: '10px',
    }),
    Input: (provided) => ({
      ...provided,
    }),
    valueContainer: (provided) => ({
      ...provided,
      '& span': {
        backgroundColor: palette.text.disabled,
        borderRadius: '8px',
        padding: '4px 8px',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: palette.buttonSecondary.hoverBorder,
      fontWeight: 500,
      fontSize: '16px',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: palette.text.primary,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 15,
    }),
    multiValue: () => ({
      display: 'none',
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      minHeight: '39px',
      padding: '0 4px',
      backgroundColor: 'transparent',
      color: palette.text.primary,
      wordBreak: 'break-word',
      '&:hover': {
        backgroundColor: palette.additional.tag,
      },
    }),
  };
};

const SearchableCheckboxSelectWithLabel = React.forwardRef(
  (
    {
      isDisabled,
      label,
      options,
      onChange,
      placeholder,
      value,
      error,
      onInputChange,
      onEnter,
      onMenuScrollToBottom,
      showSelectAll,
      allOption,
      height,
      width,
    },
    ref,
  ) => {
    const id = useRandomId();
    const handleClickStopPropagation = (e) => {
      e.stopPropagation();
    };

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const onKeyDown = (e) => {
      if (e.key === 'Enter' && onEnter && !isMenuOpen) {
        e.preventDefault();
        onEnter(e);
      }
    };

    const handleMenuOpen = () => {
      setIsMenuOpen(true);
    };
    const handleMenuClose = () => {
      setIsMenuOpen(false);
    };
    const all = allOption || selectAllOption;

    const selectOptions = showSelectAll ? [all, ...options] : options;

    const handleChange = (nextValue) => {
      if (showSelectAll) {
        if (nextValue.includes(all)) {
          onChange(nextValue.length === selectOptions?.length ? [] : options);
        } else {
          onChange(nextValue);
        }
      } else {
        onChange(nextValue);
      }
    };

    // eslint-disable-next-line
    const OptionComponent = (props) => (
      <Option
        {...props}
        allChecked={value?.length === options?.length}
        amountChecked={value?.length}
      />
    );

    return (
      <StyledContainer ref={ref} data-testid="dropdown_wrapper">
        <SearchLabel value={label} height={height && `${height}px`} htmlFor={id} />
        <Box position="relative" onClick={handleClickStopPropagation} data-testid="dropdown_select">
          <Select
            isMulti
            isClearable={false}
            isDisabled={isDisabled}
            isSearchable
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option: OptionComponent, ValueContainer }}
            styles={customStyles(width, height)}
            placeholder={placeholder}
            options={selectOptions}
            onChange={handleChange}
            onInputChange={onInputChange}
            onMenuScrollToBottom={onMenuScrollToBottom}
            value={value}
            isError={!!error?.message}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            onKeyDown={onKeyDown}
          />
          {error?.message && (
            <Typography
              variant="bodyXS"
              color="additional.alert"
              component="span"
              position="absolute"
              left={0}
              top="calc(100% + 4px)"
              data-testid="error_message"
            >
              {error?.message}
            </Typography>
          )}
        </Box>
      </StyledContainer>
    );
  },
);

SearchableCheckboxSelectWithLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  height: PropTypes.number,
  width: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  onMenuScrollToBottom: PropTypes.func,
  onInputChange: PropTypes.func,
  onEnter: PropTypes.func,
  showSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

SearchableCheckboxSelectWithLabel.defaultProps = {
  value: [],
  height: 44,
  isDisabled: false,
  placeholder: '',
  error: '',
  onMenuScrollToBottom: () => {},
  onInputChange: () => {},
  onEnter: null,
  showSelectAll: false,
  allOption: null,
};

export default SearchableCheckboxSelectWithLabel;
