/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const createContact = createAsyncThunk(
  'contacts/createContact',
  // eslint-disable-next-line
  async (params = {}, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/contacts`, params);

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

const initialState = {
  contactId: null,
  error: null,
};

export const createContactSlice = createSlice({
  name: 'createContact',
  initialState,
  reducers: {},
  extraReducers: {
    [createContact.fulfilled]: (state, action) => {
      state.contactId = action.payload.result;
    },
    [createContact.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default createContactSlice.reducer;
