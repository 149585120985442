import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { ReactComponent as SearchWhite } from '../../../assets/icons/SearchWhite.svg';
import MButton from '../../MUI/Button/MButton';
import TextInput from '../../MUI/MUITextInput';

const Searcher = ({ inputPlaceholder, buttonText, onSearch, onChange, width }) => {
  const [valueSearch, setValueSearch] = useState('');

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setValueSearch(value);
      onChange?.(value);
    },
    [onChange],
  );

  const handleSearch = useCallback(() => {
    if (valueSearch && onSearch) {
      onSearch(valueSearch);
    }
  }, [valueSearch, onSearch]);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <Stack spacing={1} width={width} direction="row">
      <TextInput
        placeholder={inputPlaceholder}
        value={valueSearch}
        onChange={handleChange}
        search
        sx={{
          flexGrow: 1,
        }}
        onKeyDown={onKeyDown}
        data-testid="search_input_wrapper"
        inputProps={{ 'data-testid': 'search_input' }}
      />
      <MButton
        data-testid="search_button"
        variant="primary"
        size="large"
        onClick={handleSearch}
        startIcon={<SearchWhite />}
      >
        {buttonText}
      </MButton>
    </Stack>
  );
};

Searcher.propTypes = {
  inputPlaceholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  buttonText: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Searcher.defaultProps = {
  buttonText: 'Search',
  width: 1,
  onChange: () => {},
};

export default Searcher;
