import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { formatHours } from '../../../../../utils/formatHours';

const QuantityCell = ({ value, row }) => {
  const quantityTotal = Number(row?.original?.timeQuantity) || 0;

  if (row?.original?.isTotalRow) {
    return (
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 700,
        }}
      >
        <br />
        {formatHours(quantityTotal)}
      </Typography>
    );
  }
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
      }}
    >
      {value ? formatHours(value) : ''}
    </Typography>
  );
};

QuantityCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      timeQuantity: PropTypes.string,
      isTotalRow: PropTypes.bool,
    }),
  }).isRequired,
};

QuantityCell.defaultProps = {
  value: '',
};

export default QuantityCell;
