import { ReferenceNumberCell } from '../CaseSearch/CaseSearchCells';
import { StatusCell } from './TableCells/StatusCell';

export const BULK_DOCUMENT_PRINTING_LABEL = 'Bulk Document Handling';
export const UPLOAD_DOCUMENTS_LABEL = 'Upload Document';

export const BULK_DOCUMENT_PRINTING_STATE_DROPDOWN = [
  { value: 'AZ', label: 'AZ' },
  { value: 'NV', label: 'NV' },
];

export const ARIZONA_KEY = 'AZ';
export const NEVADA_KEY = 'NV';

export const DOCUMENT_TYPE_DROPDOWN = {
  [ARIZONA_KEY]: [
    { id: 1, value: 'Satisfaction', label: 'Satisfaction', default: false },
    { id: 0, value: 'Dismissal', label: 'Dismissal', default: true },
    { id: 2, value: 'Vacate', label: 'Vacate', default: false },
    { id: 3, value: 'Writ', label: 'Writ', default: false },
    { id: 4, value: 'Signed Judgment', label: 'Signed Judgment', default: false },
    { id: 5, value: 'Other', label: 'Other', default: false },
  ],
  [NEVADA_KEY]: [{ value: 'Dismissal', label: 'Dismissal', default: true }],
};

export const SENT_PRINTED = 'sent/printed';
export const UNSENT_UNPRINTED = 'unsent/unprinted';

export const STATUS_TYPE_DROPDOWN = [
  { id: 1, value: UNSENT_UNPRINTED, label: 'Unprinted', default: true },
  { id: 0, value: SENT_PRINTED, label: 'Printed', default: false },
];

export const DOCUMENTS_PAGE_SIZE = 10;

export const tableColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    width: 15,
    Cell: ReferenceNumberCell,
  },
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 15,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'defendantNames',
    width: 15,
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    width: 20,
  },
  {
    Header: 'Violation Type(s)',
    accessor: 'violationTypes',
    width: 20,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 20,
    Cell: StatusCell,
  },
];
