import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  Container,
  Stack,
  Typography,
  Box,
  Accordion,
  styled,
  AccordionSummary,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { caseStatusForm, caseStatusColumns } from './CivilMatterCaseInformationArizona.constants';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import Dropdown from '../../../atoms/Dropdown';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import MButton from '../../../MUI/Button/MButton';
import Table from '../../../atoms/Table/Table';
import {
  getArizonaStatuses,
  addArizonaStatusToCase,
  getArizonaStatusesForCase,
} from '../../../../store/slices/cases/caseInformationArizonaSlice';
import MTooltip from '../../../atoms/MTooltip';
import { apiClient } from '../../../../lib/apiClient';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import LoaderCover from '../../../atoms/LoaderCover';
import notificationUtils from '../../../../utils/notificationUtils';

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
    '.MuiPaper-root': {
      boxShadow: '0px 3px 20px rgba(69, 80, 121, 0.13)',
      marginBottom: '16px',
      borderRadius: '24px',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '16px',
      height: '16px',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 32px 32px',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

const CaseStatus = () => {
  const form = useForm();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = location.state;

  const [isLoader, setIsLoader] = useState(false);

  const { statuses, arizonaStatuses } = useSelector((state) => state.caseInformationArizona);
  const { boxTitle, inputBoxes, withAdd } = caseStatusForm;

  useEffect(() => {
    dispatch(getArizonaStatuses());
    dispatch(getArizonaStatusesForCase(id));
  }, []);

  const getOptions = useCallback(
    (name) => {
      return name === 'caseStatus.caseStatus' ? statuses : [];
    },
    [statuses],
  );

  // eslint-disable-next-line
  const deleteRow = async (caseStatusId) => {
    if (caseStatusId) {
      await apiClient.delete(`/api/arizonaCases/${id}/statusHistoryEntries/${caseStatusId}`);
      notificationUtils.success('Deleted successfully');
    } else {
      await apiClient.delete(`/api/arizonaCases/${id}/statuses/revert`);
      notificationUtils.success('Reverted successfully');
    }
    dispatch(getArizonaStatusesForCase(id));
  };

  const handleSubmit = async (formValues = form.getValues()) => {
    const { caseStatus } = formValues;

    setIsLoader(true);
    try {
      await dispatch(
        addArizonaStatusToCase({
          id,
          caseId: id,
          caseStatus: caseStatus.caseStatus.value,
          comment: caseStatus?.comment,
        }),
      ).unwrap();
      notificationUtils.success('Updated successfully');

      dispatch(getArizonaStatusesForCase(id));
      form.reset({
        caseStatus: {
          caseStatus: null,
          comment: '',
        },
      });
    } catch (e) {
      notificationUtils.error(e?.errorMessage || 'Something went wrong');
    }
    setIsLoader(false);
  };

  return (
    <Form onSubmit={handleSubmit} form={form}>
      {isLoader && <LoaderCover isFixed />}
      <StyledContainer
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          width: 1,
          position: 'relative',
        }}
      >
        <StyledAccordion defaultExpanded sx={{ p: 4 }}>
          <AccordionSummary expandIcon={<ArrowDown />} sx={{ padding: 0 }}>
            <Typography variant="h5">{boxTitle}</Typography>
          </AccordionSummary>
          <Stack direction="row" alignItems="center" columnGap={3} rowGap={2} flexWrap="wrap">
            {inputBoxes.map((item) => {
              if (item.isDropdown) {
                return (
                  <Field
                    key={item.name}
                    name={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, onCustomChange }) => (
                      <Dropdown
                        value={field.value}
                        isAddDisabled={false}
                        isSearchable
                        label={item.label}
                        width={item.width}
                        isColumn
                        placeholder={item.placeholder}
                        options={getOptions(item.name)}
                        onChange={onCustomChange(field.onChange)}
                        isDisabled={item.isDisabled}
                        isMandatory={item.isMandatory}
                      />
                    )}
                  />
                );
              }

              const editInputField = (
                <Field
                  name={item.name}
                  key={item.name}
                  render={({ field }) => (
                    <EditInputWithLabel
                      type="text"
                      label={item.label}
                      placeholder={item.placeholder}
                      width={item.width}
                      name={item.name}
                      value={field.value}
                      onChange={field.onChange}
                      isDisabled={item.isDisabled}
                      tooltip={item.tooltipText && <MTooltip text={item.tooltipText} />}
                    />
                  )}
                />
              );

              if (withAdd) {
                return (
                  <Box
                    alignItems="center"
                    display="flex"
                    flexGrow={1}
                    justifyContent="space-between"
                    key={`${item.label} Box`}
                  >
                    {editInputField}
                    <MButton
                      sx={{
                        ml: '24px',
                        mt: '28px',
                      }}
                      size="large"
                      type="submit"
                      disabled={isLoader}
                    >
                      Update
                    </MButton>
                  </Box>
                );
              }
              return editInputField;
            })}

            {arizonaStatuses && (
              <Stack mt={3} width="100%">
                <Table
                  columns={caseStatusColumns}
                  loading={false}
                  rows={arizonaStatuses}
                  onUpdateData={null}
                  onDeleteRow={deleteRow}
                />
              </Stack>
            )}
          </Stack>
        </StyledAccordion>
      </StyledContainer>
    </Form>
  );
};

export default CaseStatus;
