import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const newUserBoxes = [
  {
    name: '',
    inputBoxes: [
      {
        label: 'User information',
        isSubHeading: true,
      },
      {
        label: 'First Name',
        name: 'firstName',
        placeholder: 'Enter first name',
        width: '326px',
        isDropdown: false,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'Last Name',
        name: 'lastName',
        placeholder: 'Enter last name',
        width: '326px',
        isDropdown: false,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'User name',
        name: 'userName',
        placeholder: 'Enter user name',
        width: '326px',
        isDropdown: false,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'Email',
        name: 'email',
        placeholder: 'Enter email',
        width: '326px',
        isDropdown: false,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'User ID',
        name: 'userId',
        placeholder: '349800-89',
        width: '326px',
        isDropdown: false,
        isMandatory: false,
        isDisabled: true,
        isDate: false,
      },
      {
        label: 'Role',
        name: 'role',
        tooltip:
          'Need Admin access? Email your request to credentials@clarkwalker.com. Please note this will give you access to the entire portfolio for your management company. You will receive updates for all properties associated with the portfolio.',
        placeholder: 'Select role',
        width: '326px',
        isDropdown: true,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
        defaultValue: 'ClientPortalUser',
      },
      {
        label: 'Title',
        name: 'title',
        placeholder: 'Enter title',
        width: '326px',
        isMandatory: true,
      },
      {
        label: 'Work Number',
        name: 'workPhone',
        placeholder: 'Enter work number',
        width: '326px',
        isMandatory: true,
      },
      {
        label: 'Mobile Number',
        name: 'mobilePhone',
        placeholder: 'Enter mobile number',
        width: '326px',
      },
      {
        label: 'Password',
        name: 'password',
        placeholder: 'Enter password',
        width: '326px',
        isPassword: true,
        isDropdown: false,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'Confirm Password',
        name: 'confirmPassword',
        placeholder: 'Enter password',
        width: '326px',
        isPassword: true,
        isDropdown: false,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'Status',
        name: 'isActive',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '158px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Active', value: true, isDefault: true },
          { id: 1, label: 'Inactive', value: false },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'State',
        name: 'states',
        placeholder: '-',
        width: '158px',
        isDropdown: false,
        isDropdownMultiChip: true,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'Primary State',
        name: 'primaryStateCode',
        placeholder: '-',
        width: '326px',
        isDropdown: true,
      },
      {
        label: 'Management Company',
        name: 'management',
        placeholder: 'Select management company',
        width: '326px',
        isDropdown: true,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
    ],
  },
  {
    name: '',
    inputBoxes: [
      {
        label: 'Notifications',
        isSubHeading: true,
      },
      {
        label: 'Invoice',
        name: 'notification.isInvoice',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '158px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false, isDefault: true },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Daily Report',
        name: 'notification.isDaily',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '158px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false, isDefault: true },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Weekly Report',
        name: 'notification.isWeekly',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '158px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false, isDefault: true },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Monthly Report',
        name: 'notification.isMonthly',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '158px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false, isDefault: true },
        ],
        isMandatory: false,
        isDisabled: false,
      },
    ],
  },
  {
    name: '',
    inputBoxes: [
      {
        label: 'Assignment',
        isSubHeading: true,
        tooltip: `Setting Entire Portfolio to yes will grant access for every case of the management for the user. 
                  Setting it to no will limit the user's access to only cases for the selected communities and addresses.`,
      },
      {
        label: 'Entire Portfolio',
        name: 'hasEntirePortfolio',
        tooltip:
          'Need access to the entire portfolio? Email your request to credentials@clarkwalker.com. Please note that you will receive updates for all properties associated with the portfolio.',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '158px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false, isDefault: true },
        ],
        isMandatory: false,
        isDisabled: false,
      },
    ],
  },
  {
    name: '',
    inputBoxes: [
      {
        label: 'Communities & Addresses',
        isSubHeading: true,
        name: 'communitiesAndAddresses', // either communities or addresses are required
        isMandatory: true,
      },
      {
        label: 'Communities',
        isSubHeadingWithButton: true,
        buttonName: 'Add',
        searchInputPlaceholder: 'Search by Community Name',
      },
      {
        label: 'Communities',
        name: 'communities',
        width: 'auto',
        isDropdown: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
      {
        label: 'Addresses',
        isSubHeadingWithButton: true,
        buttonName: 'Add',
        searchInputPlaceholder: 'Search by Address',
      },
      {
        label: 'Addresses',
        name: 'addresses',
        width: 'auto',
        isDropdown: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
    ],
  },
];

export const newUserCommunitiesColumnsWithDeleteCell = [
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 96,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    Cell: DeleteCell,
  },
];

export const newUserCommunitiesColumns = [
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 100,
  },
];

export const newUserAddressColumnsWithDeleteCell = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 96,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    Cell: DeleteCell,
  },
];

export const newUserAddressColumns = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 100,
  },
];
