import { palette } from '../theme/default';

export const ALL_STATES = [
  { id: 1, value: 'AL', label: 'AL' },
  { id: 2, value: 'AK', label: 'AK' },
  { id: 3, value: 'AZ', label: 'AZ' },
  { id: 4, value: 'AR', label: 'AR' },
  { id: 5, value: 'AS', label: 'AS' },
  { id: 6, value: 'CA', label: 'CA' },
  { id: 7, value: 'CO', label: 'CO' },
  { id: 8, value: 'CT', label: 'CT' },
  { id: 9, value: 'DE', label: 'DE' },
  { id: 10, value: 'DC', label: 'DC' },
  { id: 11, value: 'FL', label: 'FL' },
  { id: 12, value: 'GA', label: 'GA' },
  { id: 13, value: 'GU', label: 'GU' },
  { id: 14, value: 'HI', label: 'HI' },
  { id: 15, value: 'ID', label: 'ID' },
  { id: 16, value: 'IL', label: 'IL' },
  { id: 17, value: 'IN', label: 'IN' },
  { id: 18, value: 'IA', label: 'IA' },
  { id: 19, value: 'KS', label: 'KS' },
  { id: 20, value: 'KY', label: 'KY' },
  { id: 21, value: 'LA', label: 'LA' },
  { id: 22, value: 'ME', label: 'ME' },
  { id: 23, value: 'MD', label: 'MD' },
  { id: 24, value: 'MA', label: 'MA' },
  { id: 25, value: 'MI', label: 'MI' },
  { id: 26, value: 'MN', label: 'MN' },
  { id: 27, value: 'MP', label: 'MP' },
  { id: 28, value: 'MS', label: 'MS' },
  { id: 29, value: 'MO', label: 'MO' },
  { id: 30, value: 'MT', label: 'MT' },
  { id: 31, value: 'ND', label: 'ND' },
  { id: 32, value: 'NE', label: 'NE' },
  { id: 33, value: 'NV', label: 'NV' },
  { id: 34, value: 'NH', label: 'NH' },
  { id: 35, value: 'NJ', label: 'NJ' },
  { id: 36, value: 'NM', label: 'NM' },
  { id: 37, value: 'NY', label: 'NY' },
  { id: 38, value: 'NC', label: 'NC' },
  { id: 39, value: 'OH', label: 'OH' },
  { id: 40, value: 'OK', label: 'OK' },
  { id: 41, value: 'OR', label: 'OR' },
  { id: 42, value: 'PA', label: 'PA' },
  { id: 43, value: 'PR', label: 'PR' },
  { id: 44, value: 'RI', label: 'RI' },
  { id: 45, value: 'SC', label: 'SC' },
  { id: 46, value: 'SD', label: 'SD' },
  { id: 47, value: 'TN', label: 'TN' },
  { id: 48, value: 'TX', label: 'TX' },
  { id: 49, value: 'TT', label: 'TT' },
  { id: 50, value: 'UT', label: 'UT' },
  { id: 51, value: 'VT', label: 'VT' },
  { id: 52, value: 'VA', label: 'VA' },
  { id: 53, value: 'VI', label: 'VI' },
  { id: 54, value: 'WA', label: 'WA' },
  { id: 55, value: 'WI', label: 'WI' },
  { id: 56, value: 'WV', label: 'WV' },
  { id: 57, value: 'WY', label: 'WY' },
];

export const ALL_USER_STATES = [
  { id: 1, value: 'AZ', label: 'AZ' },
  { id: 2, value: 'NV', label: 'NV' },
];

export const ALL_STATUSES = [
  {
    label: 'Notice Requested',
    value: 'Notice Requested',
    color: palette.statuses.cyan,
    tooltipDB: 'The client has requested that a notice be drafted and prepared for service.',
    tooltipCP: 'A notice has been requested to be drafted and served.',
  },
  {
    label: 'Notice Drafted',
    value: 'Notice Drafted',
    color: palette.statuses.cyan,
    tooltipDB: 'The requested notice has been drafted.',
    tooltipCP: 'The requested notice has been drafted according to the information provided.',
  },
  {
    label: 'Notice Served',
    value: 'Notice Served',
    color: palette.statuses.green,
    tooltipDB: 'The approved notice has been served by our process servers.',
    tooltipCP: 'The approved notice has been served by the process server.',
  },
  {
    label: 'Notice Approved',
    value: 'Notice Approved',
    color: palette.statuses.green,
    tooltipDB: 'The drafted notice has been approved by the client.',
    tooltipCP: 'The drafted notice has been approved.',
  },
  {
    label: 'Notice Expired',
    value: 'Notice Expired',
    color: palette.statuses.red,
    tooltipDB:
      'The notice is no longer available to be filed on and a new notice will need to be served.',
    tooltipCP:
      'The notice is no longer available to be filed on and a new notice will need to be served.',
  },
  {
    label: 'Eviction Requested',
    value: 'Eviction Requested',
    color: palette.statuses.cyan,
    tooltipDB:
      'The client has uploaded the supporting documents and requested that we file for eviction.',
    tooltipCP:
      'Supporting documents have been either received or uploaded and the case is ready for processing.',
  },
  {
    label: 'Eviction Prepared',
    value: 'Eviction Prepared',
    color: palette.statuses.cyan,
    tooltipDB: 'The eviction has been processed and is waiting to be filed.',
    tooltipCP: 'The forcible documents have been generated.',
  },
  {
    label: 'Eviction Prefiled',
    value: 'Eviction Prefiled',
    color: palette.statuses.cyan,
    tooltipDB:
      'The case has been processed but will not be sent to the court until the notice cure period has ended.',
    tooltipCP:
      'The forcible documents have been prepared and are ready to be filed once the notice has expired.',
  },
  {
    label: 'Eviction Filed',
    value: 'Eviction Filed',
    color: palette.statuses.green,
    tooltipDB: 'The case has been processed and filed with the courts.',
    tooltipCP: 'The case has been processed and filed with the courts.',
  },
  {
    label: 'Eviction Granted',
    value: 'Eviction Granted',
    color: palette.statuses.green,
    tooltipDB: 'The eviction has been granted and may be eligible for lockout.',
    tooltipCP: 'The eviction has been granted and may be eligible for lockout.',
  },
  {
    label: 'Eviction Ready To File',
    value: 'Eviction Ready To File',
    color: palette.statuses.blue,
    tooltipDB: 'The served notice has expired and is available to be filed on.',
    tooltipCP: 'The served notice has expired and is available to be filed on.',
  },
  {
    label: 'Initial Hearing',
    value: 'Initial Hearing',
    color: palette.statuses.grey,
    tooltipDB: 'The case has been filed with the court and a court date has been provided.',
    tooltipCP:
      'The court has accepted the filing of the case, and court dates have been confirmed.',
  },
  {
    label: 'Bench Trial',
    value: 'Bench Trial',
    color: palette.statuses.grey,
    tooltipDB:
      'The tenant has contested the case and a trial date has been set to be heard in front of a judge.',
    tooltipCP: 'The tenant has contested the case and a trial date has been set.',
  },
  {
    label: 'Jury Trial',
    value: 'Jury Trial',
    color: palette.statuses.grey,
    tooltipDB:
      'The tenant has contested the case and a trial date has been set to be heard in front of a jury.',
    tooltipCP: 'The tenant has contested the case and a trial date has been set.',
  },
  {
    label: 'Oral Argument',
    value: 'Oral Argument',
    color: palette.statuses.grey,
    tooltipDB: 'The attorney will prepare an oral argument and argue it before the court.',
    tooltipCP: 'The attorney will prepare an oral argument and argue it before the court.',
  },
  {
    label: 'Status Conference',
    value: 'Status Conference',
    color: palette.statuses.grey,
    tooltipDB: 'A status confrence has been set between the plaintiff and the defendant.',
    tooltipCP: 'A status confrence has been set between the plaintiff and the defendant.',
  },
  {
    label: 'Continuance',
    value: 'Continuance',
    color: palette.statuses.grey,
    tooltipDB: 'A continuance has been granted, pushing the court date out.',
    tooltipCP:
      'A continuance has been granted, pushing the court date out. Please see case details for specific information.',
  },
  {
    label: 'Reset',
    value: 'Reset',
    color: palette.statuses.grey,
    tooltipDB: 'The original court date has changed.',
    tooltipCP:
      'The original court date has changed. Please see case details for specific information.',
  },
  {
    label: 'Appeal',
    value: 'Appeal',
    color: palette.statuses.grey,
    tooltipDB: `The court's judgment has been appealed.`,
    tooltipCP: `The court's judgment has been appealed. Please see case details for specific information.`,
  },
  {
    label: 'Mediation',
    value: 'Mediation',
    color: palette.statuses.grey,
    tooltipDB: 'A mediation between the plaintiff and defendant has been scheduled.',
    tooltipCP:
      'A mediation between the plaintiff and defendant has been scheduled. Please see case details for specific information.',
  },
  {
    label: 'Evidentiary Hearing',
    value: 'Evidentiary Hearing',
    color: palette.statuses.grey,
    tooltipDB:
      'An evidentiary hearing has been scheduled to determine if there is enough evidence for the case to proceed.',
    tooltipCP:
      'An evidentiary hearing has been scheduled to determine if there is enough evidence for the case to proceed. Please see case details for specific information.',
  },
  {
    label: 'Hearing Vacated',
    value: 'Hearing Vacated',
    color: palette.statuses.red,
    tooltipDB: 'The scheduled hearing has been vacated or scratched from the court calendar.',
    tooltipCP:
      'The scheduled hearing has been vacated or scratched from the court calendar. Please see case details for specific information.',
  },
  {
    label: 'Judgment Granted',
    value: 'Judgment Granted',
    color: palette.statuses.green,
    tooltipDB: 'Judgment has been granted to the plaintiff by the courts.',
    tooltipCP: 'Judgment has been granted to the plaintiff by the courts.',
  },
  {
    label: 'Satisfied',
    value: 'Satisfied',
    color: palette.statuses.green,
    tooltipDB: 'A Satisfaction of Judment has been filed with the court.',
    tooltipCP: 'A Satisfaction of Judment has been filed with the court.',
  },
  {
    label: 'Vacated',
    value: 'Vacated',
    color: palette.statuses.green,
    tooltipDB: `The court's judgment has been vacated or scratched from the court records. Please see case details for specific information.`,
    tooltipCP: `The court's judgment has been vacated or scratched from the court records. Please see case details for specific information.`,
  },
  {
    label: 'Writ Ordered',
    value: 'Writ Ordered',
    color: palette.statuses.cyan,
    tooltipDB: 'A Writ of Restitution has been ordered and sent to the constables for execution.',
    tooltipCP: 'A Writ of Restitution has been ordered and sent to the constables for execution.',
  },
  {
    label: 'Dismissed',
    value: 'Dismissed',
    color: palette.statuses.red,
    tooltipDB:
      'The case has been accepted by the court and a cancellation has been requested by the client, a motion to dismiss has been filed.',
    tooltipCP:
      'The case has been accepted by the court and a cancellation has been requested, a motion to dismiss has been filed. Please see status comments for a specific explanation.',
  },
  {
    label: 'Cancellation Requested', // will be removed
    value: 'Cancellation Requested',
    color: palette.statuses.red,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'Cancelled',
    value: 'Cancelled',
    color: palette.statuses.red,
    tooltipDB: 'Prior to any work leaving our door, the client has cancelled their request.',
    tooltipCP:
      'The case has not been accepted by the court and a cancellation has been requested, no further proceedings will occur for this case. Please see status comments for a specific explanation.',
  },
  {
    label: 'Delay Filling',
    value: 'Delay Filling',
    color: palette.statuses.red,
    tooltipDB:
      'Payment has been accpeted prior to the service of the notice, the forcible is availbe to file pending the new month.',
    tooltipCP:
      'Payment has been accpeted prior to the service of the notice, the forcible is availbe to file pending the new month.',
  },
  {
    label: 'Denied',
    value: 'Denied',
    color: palette.statuses.red,
    tooltipDB: 'The requested action has been denied by an attorney.',
    tooltipCP:
      'The requested action cannot be completed, please see status comments for a specific explanation.',
  },
  {
    label: 'Deleted',
    value: 'Deleted',
    color: palette.statuses.red,
    tooltipDB:
      'The reference number has been deleted and is no longer able to be viewed by the client as well as will no longer trigger any billing instances.',
    tooltipCP: '',
  },
  {
    label: 'Rescinded',
    value: 'Rescinded',
    color: palette.statuses.red,
    tooltipDB:
      'The filing has been cancelled by the client after the eviction has already been granted, a motion to rescind has been filed.',
    tooltipCP:
      'The filing has been cancelled by the client after the eviction has already been granted, a motion to rescind has been filed. Please see status comments for a specific explanation.',
  },
  {
    label: 'Matter Initiated',
    value: 'Matter Initiated',
    color: palette.statuses.magenta,
    tooltipDB: "A new reference number has been created to complete the client's request.",
    tooltipCP:
      'A request for an action within the normal eviction process has been made. Please see case details for specific information.',
  },
  {
    label: 'Civil Matter',
    value: 'Civil Matter',
    color: palette.statuses.magenta,
    tooltipDB:
      'An attorney has created a civil case. Please see case details for specific information.',
    tooltipCP:
      'A request for an action outside the normal eviction process has been made. Please see case details for specific information.',
  },
  {
    label: 'Matter Closed',
    value: 'Matter Closed',
    color: palette.statuses.magenta,
    tooltipDB: 'The civil matter has closed.',
    tooltipCP: 'The requested action outside the normal eviction process has been completed.',
  },
  {
    label: 'Awaiting Approval',
    value: 'Awaiting Approval',
    color: palette.statuses.yellow,
    tooltipDB:
      "The drafted notice is currently awaiting the client's approval before it can be served.",
    tooltipCP:
      'The drafted notice is currently awaiting approval before it can be sent out for service.',
  },
  {
    label: 'Awaiting Verbiage',
    value: 'Awaiting Verbiage',
    color: palette.statuses.yellow,
    tooltipDB: 'The requested action is awaiting verbiage from an attorney.',
    tooltipCP: 'The requested action is awaiting verbiage from an attorney.',
  },
  {
    label: 'Proposed Form of Judgment Prepared',
    value: 'Proposed Form of Judgment Prepared',
    color: palette.statuses.grey,
    tooltipDB: 'The Proposed Form of Judgment has been prepared and is ready to be filed.',
    tooltipCP: '',
  },
  {
    label: 'Proposed Form of Judgment Filed',
    value: 'Proposed Form of Judgment Filed',
    color: palette.statuses.grey,
    tooltipDB: 'The Proposed Form of Judgment has been filed.',
    tooltipCP: '',
  },
  {
    label: 'Case Stayed',
    value: 'Case Stayed',
    color: palette.statuses.red,
    tooltipDB:
      'The case has been placed on hold pending the filing of the Motion to Place on Calendar.',
    tooltipCP:
      'The case has been placed on hold pending the filing of the Motion to Place on Calendar.',
  },
  {
    label: 'Lockout Ready To File',
    value: 'Lockout Ready To File',
    color: palette.statuses.blue,
    tooltipDB:
      'The eviction has been granted and the lockout order has been issued to the constables. The lockout is available to be filed.',
    tooltipCP:
      'The eviction has been granted and the lockout order has been issued to the constables. The lockout is available to be filed.',
  },
  {
    label: 'Lockout Solicited',
    value: 'Lockout Solicited',
    color: palette.statuses.cyan,
    tooltipDB:
      'Case Management team has reached out to client for permission to proceed with lockout.',
    tooltipCP:
      'Case Management team has reached out to you to confirm that you would like to proceed with lockout.',
  },
  {
    label: 'Lockout Requested',
    value: 'Lockout Requested',
    color: palette.statuses.cyan,
    tooltipDB: 'The client has requested lockout.',
    tooltipCP: 'A lockout has been requested to be sent to the constable.',
  },
  {
    label: 'Lockout Filed',
    value: 'Lockout Filed',
    color: palette.statuses.green,
    tooltipDB: 'Case Management team has filed the lockout with the constable.',
    tooltipCP: 'The lockout has been filed with the constable.',
  },
  {
    label: 'Lockout Completed',
    value: 'Lockout Completed',
    color: palette.statuses.green,
    tooltipDB: 'The client has verified that the client was locked out.',
    tooltipCP: 'The lockout has been successfully executed.',
  },
  {
    label: 'Lockout Expired',
    value: 'Lockout Expired',
    color: palette.statuses.red,
    tooltipDB: 'The lockout order issued by the court has expired and can no longer be filed upon.',
    tooltipCP: 'The lockout order issued by the court has expired and can no longer be filed upon.',
  },
  {
    label: 'Lockout Cancelled',
    value: 'Lockout Cancelled',
    color: palette.statuses.red,
    tooltipDB: 'The lockout was filed, but was later cancelled by the client.',
    tooltipCP:
      'The lockout was filed, but was later cancelled. Please see status comments for a specific explanation.',
  },
  {
    label: 'Lockout Denied',
    value: 'Lockout Denied',
    color: palette.statuses.red,
    tooltipDB: 'The lockout order has been denied by the court.',
    tooltipCP: 'The lockout order has been denied by the court.',
  },
  {
    label: 'Matter Resolved',
    value: 'Matter Resolved',
    color: palette.statuses.green,
    tooltipDB: 'The civil matter has been resolved.',
    tooltipCP: 'The requested action outside the normal eviction process has been resolved.',
  },
  {
    label: 'Attorney Review',
    value: 'Attorney Review',
    color: palette.statuses.yellow,
    tooltipDB:
      'There are some details about the case that may require legal advice and need to be reviewed by an attorney.',
    tooltipCP:
      'There are some details about the case that may require legal advice and need to be reviewed by an attorney.',
  },
  {
    label: 'Follow-Up',
    value: 'Follow-Up',
    color: palette.statuses.yellow,
    tooltipDB: 'The case is missing important information to be successfully processed.',
    tooltipCP: 'The case is missing important information to be successfully processed.',
  },
  {
    label: 'Inbound Follow-Up',
    value: 'Inbound Follow-Up',
    color: palette.statuses.yellow,
    tooltipDB:
      'The client has not submitted all of the documents necessary to file for eviction, and we have reached out to receive them.',
    tooltipCP: '',
  },
  {
    label: 'Outbound Follow-Up',
    value: 'Outbound Follow-Up',
    color: palette.statuses.yellow,
    tooltipDB: 'Clarification is needed from the client before the eviction process can continue.',
    tooltipCP: '',
  },
  {
    label: 'General Follow-Up',
    value: 'General Follow-Up',
    color: palette.statuses.yellow,
    tooltipDB:
      'Case Management team is waiting for additional information from the court pertaining to the case.',
    tooltipCP: '',
  },
  {
    label: 'Straight Eviction Granted', // was renamed as Eviction Granted
    value: 'Straight Eviction Granted',
    color: palette.statuses.green,
    tooltipDB: 'The eviction has been granted and may be eligible for lockout.',
    tooltipCP: 'The eviction has been granted and may be eligible for lockout.',
  },
  {
    label: 'Printed',
    value: 'Printed',
    color: palette.statuses.frostedMint,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'Unprinted',
    value: 'Unprinted',
    color: palette.statuses.grey,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'Sent',
    value: 'Sent',
    color: palette.statuses.anakiwa,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'Unsent',
    value: 'Unsent',
    color: palette.statuses.yellow,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'Printed & Sent',
    value: 'Printed & Sent',
    color: palette.statuses.periwinkle,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'Unprinted & Unsent',
    value: 'Unprinted & Unsent',
    color: palette.statuses.red,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'transparent',
    value: 'transparent',
    color: palette.statuses.periwinkle,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'Email Not Found',
    value: 'Email Not Found',
    color: palette.statuses.orange,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'Billed',
    value: 'Billed',
    color: palette.statuses.green,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'Unbilled',
    value: 'Unbilled',
    color: palette.statuses.red,
    tooltipDB: '',
    tooltipCP: '',
  },
  {
    label: 'Archived',
    value: 'Archived',
    color: palette.statuses.black,
    tooltipDB:
      'This case’s information and documents are currently being stored in a separate location.',
    tooltipCP:
      'This case’s information and documents are currently being stored in a separate location.',
    textColor: '#FFFFFF',
  },
].map((i, index) => ({
  id: index + 1,
  ...i,
}));
