/* eslint-disable no-await-in-loop */
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import { EvictionFiledColumns, EvictionFiledFormBoxes } from './evictionFiledDaily.constants';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import CheckboxSelect from '../../../atoms/CheckboxSelect';
import {
  createEvictionFiledReport,
  getEvictionFiledReport,
  getCourtsForEvictionFiledReport,
  getEvictionFiledReportAsPdf,
  getCountiesForEvictionFiledReport,
  getStatesForEvictionFiled,
  clearCourtScheduleCourts,
  clearEvictionFiled,
} from '../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import Dropdown from '../../../atoms/Dropdown';
import Table from '../../../atoms/Table/Table';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import { useAvailableStatesList } from '../../../../hooks/useAvailableStatesList';
import useAsyncLoadDropdown from '../../../../hooks/useAsyncLoadDropdown';
import notificationUtils from '../../../../utils/notificationUtils';
import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';
import ProgressModal from '../ProductivityByAttorney/ProgressModal';
import { ReactComponent as Export } from '../../../../assets/icons/document-download-blue.svg';

const EvictionFiled = () => {
  const isAdmin = useAllowed([rolesDB.Admin]);
  const userStates = useAvailableStatesList();

  const [isLoader, setIsLoader] = useState(false);
  const [lastReportId, setLastReportId] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [isRunClicked, setRunClicked] = useState(false);
  const [isCreateInProgress, setCreateInProgress] = useState(false);

  const dispatch = useDispatch();
  const { states, evictionFiledCourts, evictionFiledCounties } = useSelector(
    (state) => state.reports,
  );

  const form = useForm({
    defaultValues: {
      selectedDate: moment(),
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    dispatch(getStatesForEvictionFiled());
  }, []);

  const formStateCode = form.watch('stateCode');
  const county = form.watch('county');

  useEffect(() => {
    if (!formStateCode) {
      return;
    }
    form.setValue('county', []);
    form.setValue('courts', []);
    setIsLoader(true);
    dispatch(getCountiesForEvictionFiledReport(formStateCode?.value)).finally(() => {
      setIsLoader(false);
    });
  }, [formStateCode]);

  useEffect(() => {
    if (!formStateCode) {
      return;
    }
    form.setValue('courts', []);
  }, [county]);

  const countyLabels = form.watch('county');

  const courtsArguments = useMemo(() => {
    return {
      countyNames: countyLabels?.map((item) => item?.label),
    };
  }, [countyLabels]);

  const { handleMenuScrollToBottom } = useAsyncLoadDropdown({
    hasNextPage: evictionFiledCourts?.hasNextPage,
    actionArguments: courtsArguments,
    getAction: ({ page, ...args }) =>
      getCourtsForEvictionFiledReport({
        currentPage: page,
        pageSize: 1000,
        ...args,
      }),
    clearCallback: () => {
      dispatch(clearCourtScheduleCourts());
    },
  });

  const getOptions = (name) => {
    switch (name) {
      case 'stateCode':
        return isAdmin ? states : userStates;
      case 'county':
        return evictionFiledCounties;
      case 'courts':
        return evictionFiledCourts?.items || [];
      default:
        return [];
    }
  };

  const getDisabled = (name) => {
    switch (name) {
      case 'county':
        return !formStateCode;
      case 'courts':
        return !formStateCode || !form.watch('county')?.length;
      default:
        return false;
    }
  };

  const exportToPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsLoader(true);
      const data = await dispatch(getEvictionFiledReportAsPdf(lastReportId)).unwrap();
      openOrDownloadBinary({
        content: data.content,
        fileName: data.originalFileName,
        contentType: data.contentType,
      });
    } catch (error) {
      notificationUtils.error(error.message || 'Something went wrong');
    } finally {
      setIsLoader(false);
    }
  };

  const handleSubmit = async (values) => {
    setRunClicked(true);
    const data = {
      selectedDate: values.selectedDate,
      countyNames: values.county?.map((item) => item.value),
      courtIds: values.courts?.map((item) => item.value),
      stateCode: values.stateCode.value,
    };

    setCreateInProgress(true);

    try {
      const { reportId } = await dispatch(createEvictionFiledReport(data)).unwrap();
      setLastReportId(reportId);
      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code, message },
        } = await dispatch(getEvictionFiledReport(reportId)).unwrap();
        if (code === 'Completed') {
          setTableData(items);
          done = true;
        } else if (code === 'Error') {
          notificationUtils.error(message);
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      notificationUtils.error(e.message || 'Something went wrong');
    }
    setCreateInProgress(false);
  };

  const clearValues = () => {
    form.reset({
      dateSelected: moment(),
      stateCode: null,
    });
    setRunClicked(false);
    dispatch(clearEvictionFiled());
    setTableData(null);
  };

  return (
    <Stack direction="column" mb={6}>
      <ShadowBlock>
        {isLoader && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100vw"
            height="100vh"
            position="absolute"
            top="0"
            left="0"
            zIndex={10}
          >
            <CircularProgress />
          </Box>
        )}
        <ProgressModal isOpen={isCreateInProgress} />
        <Form form={form} onSubmit={handleSubmit}>
          {EvictionFiledFormBoxes.map((i) => (
            <Stack
              direction="row"
              alignItems="center"
              columnGap={3}
              rowGap={2}
              flexWrap="wrap"
              py={1}
            >
              {i.inputBoxes.map((item) => {
                if (item.isCheckboxSelect) {
                  return (
                    <Field
                      key={item.name}
                      isMandatory={item.isMandatory}
                      name={item.name}
                      render={({ field, onCustomChange, error }) => (
                        <CheckboxSelect
                          label={item.label}
                          value={field.value}
                          width={item.width}
                          error={error}
                          onMenuScrollToBottom={handleMenuScrollToBottom}
                          isMandatory={item.isMandatory}
                          placeholder={item.placeholder}
                          onChange={onCustomChange(field.onChange)}
                          options={getOptions(item.name)}
                          showSelectAll={item.showSelectAll}
                          isDisabled={getDisabled(item.name)}
                        />
                      )}
                    />
                  );
                }

                if (item.isDatePicker) {
                  return (
                    <Field
                      name={item.name}
                      key={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange, error }) => (
                        <DateOrTimePicker
                          name={item.name}
                          label={item.label}
                          isDate
                          error={error}
                          Svg={Calender}
                          selected={field.value}
                          isMandatory={item.isMandatory}
                          width={item.width}
                          height={item.height}
                          placeholder={item.placeholder}
                          setSelectedTimeOrDate={onCustomChange(field.onChange)}
                        />
                      )}
                    />
                  );
                }

                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <Dropdown
                          value={field.value}
                          isAddDisabled={false}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          isColumn
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={onCustomChange(field.onChange)}
                          isDisabled={getDisabled(item.name)}
                          isMandatory={item.isMandatory}
                        />
                      )}
                    />
                  );
                }

                return null;
              })}
            </Stack>
          ))}
          <Stack direction="row" mt={2} gap={2}>
            <MButton
              type="submit"
              size="medium"
              data-testid="run_report_btn"
              disabled={isLoader || !form.formState.isValid}
            >
              Run Report
            </MButton>
            {isRunClicked && (
              <MButton variant="borderLess" onClick={clearValues} data-testid="clear_btn">
                Clear
              </MButton>
            )}
          </Stack>
        </Form>
      </ShadowBlock>

      {tableData ? (
        <Box mt={4}>
          <ShadowBlock>
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  '&': {
                    minHeight: 0,
                    padding: '0 0 32px',
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: 0,
                  },
                }}
              >
                <Typography variant="h5" fontWeight={600}>
                  Eviction Filed Daily Report
                </Typography>
                <MButton
                  type="button"
                  onClick={exportToPdf}
                  size="medium"
                  variant="borderLess"
                  startIcon={<Export />}
                  disabled={isLoader || !form.formState.isValid || !lastReportId}
                  data-testid="export_btn"
                >
                  Export Report
                </MButton>
              </Stack>
              <Box>
                {tableData?.map((data) => {
                  return (
                    <Stack direction="column" mb={3}>
                      <Typography mb={1} variant="bodyL500" fontWeight={600}>
                        {data.courtName} ({data.countyName})
                      </Typography>
                      <Table columns={EvictionFiledColumns} rows={data.cases} />
                    </Stack>
                  );
                })}
              </Box>
            </Box>
          </ShadowBlock>
        </Box>
      ) : null}
    </Stack>
  );
};

export default EvictionFiled;
