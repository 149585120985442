import React, { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';

// Icons
import { ReactComponent as Accept } from '../../../../assets/icons/accept.svg';
import { ReactComponent as Closed } from '../../../../assets/icons/closed.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/Edit.svg';
import { ReactComponent as Trash } from '../../../../assets/icons/Trash.svg';

export const EditCell = ({
  row: { id, index },
  setEditableRowIndex,
  editableRowIndex,
  cancelEditing,
  updateData,
  deleteRow,
}) => {
  const handleClickEdit = useCallback(() => {
    setEditableRowIndex(index);
  }, [index, setEditableRowIndex]);

  const handleClickDelete = useCallback(() => {
    deleteRow(id);
  }, [id, deleteRow]);

  return editableRowIndex === index ? (
    <>
      <IconButton onClick={updateData} data-testid="accept">
        <Accept />
      </IconButton>
      <IconButton onClick={cancelEditing} data-testid="cancel">
        <Closed />
      </IconButton>
    </>
  ) : (
    <>
      <IconButton onClick={handleClickEdit} data-testid="edit">
        <Edit />
      </IconButton>
      <IconButton onClick={handleClickDelete} data-testid="delete">
        <Trash />
      </IconButton>
    </>
  );
};

EditCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    index: PropTypes.number,
  }).isRequired,
  setEditableRowIndex: PropTypes.func.isRequired,
  editableRowIndex: PropTypes.number.isRequired,
  cancelEditing: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
};
