import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ClientPortalAdmin from '../../../components/pages/ClientPortalAdmin';
import ClientPortalUsers from '../../../components/pages/ClientPortalAdmin/ClientPortalUsersFlow';
import ClientPortalUser from '../../../components/pages/ClientPortalAdmin/ClientPortalUsersFlow/ClientPortalUser';

const ClientPortalAdminRoutes = () => (
  <Routes>
    <Route path="*" element={<ClientPortalAdmin />}>
      <Route path="users" element={<ClientPortalUsers />} />
      <Route path="users/edituser" element={<ClientPortalUser />} />
      <Route path="users/newuser" element={<ClientPortalUser />} />
    </Route>
  </Routes>
);

export default ClientPortalAdminRoutes;
