import { Box } from '@mui/material';
import moment from 'moment';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';
import { DropdownCell } from '../../../atoms/Table/TableCells/DropdownCell';
import { EditCell } from '../../../atoms/Table/TableCells/EditCell';
import { TextInputCell } from '../../../atoms/Table/TableCells/TextInputCell';
import CaseStatus from '../../../molecules/CaseStatus';
import { CaseInformationEditableCell } from '../CaseInformationArizona/TableCells/CaseInformationArizonaEditableCell';
import { ReferenceNumberCell } from '../CaseSearch/CaseSearchCells';
import { TextCell, StatusCell, DateCell } from './TableCells';
import { CaseStatusDeleteCell } from './TableCells/CaseStatusDeleteCell';
import { DuplicateCell } from './TableCells/DuplicateCell';

export const nevadaRelatedCasesColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    width: 13,
    Cell: ReferenceNumberCell,
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    width: 10,
    Cell: TextCell,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'opposingPartyName',
    width: 30,
    Cell: TextCell,
  },
  {
    Header: 'Apartment #',
    accessor: 'apartmentNumber',
    width: 12,
    Cell: TextCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 18,
    Cell: StatusCell,
  },
  {
    Header: 'Creation Date',
    accessor: 'creationDate',
    width: 13,
    Cell: DateCell,
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    width: 13,
    Cell: DateCell,
  },
  {
    Header: 'Duplicate',
    accessor: 'isPossibleDuplicate',
    width: 10,
    Cell: DuplicateCell,
  },
];

const date = 'mm/dd/yyyy';

export const OTHER_THAN = 'Other-Than';
export const NON_PAYMENT = 'Non-Payment';

export const noticeRequestDropdownBlockToggler = [
  { id: 0, value: OTHER_THAN, label: OTHER_THAN },
  { id: 1, value: NON_PAYMENT, label: NON_PAYMENT },
];

export const caseInformationForm = {
  boxTitle: 'Case Information',
  name: 'caseInformation',
  withAdd: false,
  inputBoxes: [
    {
      label: 'Case #',
      name: 'caseNumber',
      placeholder: 'xxxxxx',
      width: '199px',
      isMandatory: false,
    },
    {
      label: 'P.O./File #',
      name: 'poNumber',
      placeholder: 'P.O./File #',
      width: '199px',
      isMandatory: false,
    },
    {
      label: 'Envelope #',
      name: 'envelopeNumber',
      placeholder: 'xxxxxx',
      width: '199px',
    },
    {
      label: 'Notice Expires On',
      name: 'noticeExpiresOn',
      width: '226px',
      placeholder: date,
      isDatePicker: true,
      isMandatory: false,
      height: '48px',
    },
    {
      label: 'Eviction Granted On',
      name: 'evictionGrantedOn',
      width: '226px',
      placeholder: date,
      isDatePicker: true,
      isMandatory: false,
      height: '48px',
    },
    {
      label: 'Assigned Legal Assistant',
      name: 'assignedLegalAssistant',
      placeholder: "Enter legal assistant's name",
      width: '300px',
      isDropdown: true,
      isMandatory: false,
      withUpdate: true,
    },
  ],
};

export const caseInformationBoxes = [
  {
    boxTitle: 'Procedural History',
    name: 'proceduralHistory',
    withAdd: true,
    inputBoxes: [
      {
        label: 'Assigned Attorney',
        name: 'proceduralHistoryFields.assignedAttorney',
        placeholder: "Enter attorney's name",
        width: '477px',
        isDropdown: true,
        isMandatory: false,
      },
      {
        label: 'Hearing Type',
        name: 'proceduralHistoryFields.hearingType',
        placeholder: 'Default option',
        width: '477px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        isSpace: true,
      },
      {
        label: 'Court Date',
        name: 'proceduralHistoryFields.courtDate',
        width: '226px',
        placeholder: date,
        isDatePicker: true,
        isMandatory: true,
        height: '48px',
      },
      {
        label: 'Court Time',
        name: 'proceduralHistoryFields.courtTime',
        placeholder: 'hh:mm',
        width: '226px',
        height: '48px',
        isTimePicker: true,
        isMandatory: true,
      },
      {
        label: 'Dismissed by Judge',
        name: 'proceduralHistoryFields.dismissedByJudge',
        isCheckbox: true,
      },
    ],
  },
  {
    boxTitle: 'Case Status',
    name: 'caseStatus',
    withAdd: true,
    inputBoxes: [
      {
        label: 'Status',
        name: 'caseStatusFields.caseStatus',
        placeholder: 'Enter status name',
        width: '311px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'Comment',
        name: 'caseStatusFields.comment',
        tooltipText: 'Comments are visible to clients.',
        placeholder: 'Enter comment',
        width: '533px',
      },
    ],
  },
  {
    boxTitle: 'Related Cases',
    name: 'relatedCases',
    inputBoxes: [
      {
        isRelatedCasesTable: true,
        columns: nevadaRelatedCasesColumns,
      },
    ],
  },
];

export const proceduralHistoryColumns = [
  {
    Header: 'Assigned Attorney',
    accessor: 'assignedAttorney',
    width: 20,
    edit: true,
    Cell: DropdownCell,
    options: 'attorneys',
    saveOnBlur: true,
  },
  {
    Header: 'Hearing Type',
    accessor: 'hearingType',
    width: 20,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDate',
    width: 20,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('L') : '',
  },
  {
    Header: 'Court Time',
    accessor: 'courtTime',
    width: 20,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('LT') : '',
  },

  {
    Header: 'Status',
    width: 15,
    accessor: 'status',
    Cell: CaseStatus,
  },
  {
    Header: '',
    accessor: 'control',
    width: 6,
    minWidth: '48px',
    Cell: (props) => {
      // eslint-disable-next-line react/prop-types, react/destructuring-assignment
      const isAttorneyAssigned = !!props.row.original.assignedAttorney;

      const canDelete = useAllowed([rolesDB.Admin]);
      return (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {isAttorneyAssigned && canDelete && <DeleteCell {...props} />}
          {!isAttorneyAssigned && <CaseInformationEditableCell {...props} showDelete={canDelete} />}
        </Box>
      );
    },
  },
];

export const caseStatusColumns = [
  {
    Header: 'Status',
    accessor: 'status',
    width: 24,
    Cell: CaseStatus,
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    width: 22,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Updated By',
    accessor: 'updatedBy',
    width: 22,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 24,
  },
  {
    Header: '',
    accessor: 'control',
    width: 8,
    minWidth: 80,
    Cell: (props) => {
      return <CaseStatusDeleteCell {...props} />;
    },
  },
];

export const tenancyTypes = [
  { label: '-', value: null, id: 0 },
  { label: 'Periodic month-to-month', value: 'Periodic month-to-month', id: 1 },
  { label: 'Periodic week-to-week', value: 'Periodic week-to-week', id: 2 },
  { label: 'Fixed term', value: 'Fixed term', id: 3 },
  { label: 'Tenancy-at-will', value: 'Tenancy-at-will', id: 4 },
  { label: 'Other', value: 'Other', id: 5 },
];

export const hearingTypes = [
  { label: 'Initial Hearing', value: 'Initial Hearing', id: 1 },
  { label: 'Bench Trial', value: 'Bench Trial', id: 2 },
  { label: 'Jury Trial', value: 'Jury Trial', id: 3 },
  { label: 'Oral Argument', value: 'Oral Argument', id: 4 },
  { label: 'Status Conference', value: 'Status Conference', id: 5 },
  { label: 'Continuance', value: 'Continuance', id: 6 },
  { label: 'Reset', value: 'Reset', id: 7 },
  { label: 'Appeal', value: 'Appeal', id: 8 },
  { label: 'Evidentiary Hearing', value: 'Evidentiary Hearing', id: 9 },
  { label: 'Case Stayed', value: 'Case Stayed', id: 10 },
  { label: 'Mediation', value: 'Mediation', id: 11 },
];

export const violationTypes = [
  { label: '-', value: null, id: 0 },
  { label: 'Nuisance Business', value: 'Nuisance Business', id: 1 },
  { label: 'Waste Business', value: 'Waste Business', id: 2 },
  { label: 'Assigning Business', value: 'Assigning Business', id: 3 },
  { label: 'Subletting Business', value: 'Subletting Business', id: 4 },
  { label: 'Unlawful Business', value: 'Unlawful Business', id: 5 },
  { label: 'Controlled Substance Violation', value: 'Controlled Substance Violation', id: 6 },
  { label: 'Perform Lease Condition', value: 'Perform Lease Condition', id: 7 },
  { label: 'Tenancy-At-Will', value: 'Tenancy-At-Will', id: 8 },
  { label: 'No Cause', value: 'No Cause', id: 9 },
  { label: 'Unlawful Detainer', value: 'Unlawful Detainer', id: 10 },
  { label: '5 Day to Quit', value: '5 Day to Quit', id: 11 },
];

export const violationColumns = [
  {
    Header: 'Selected Violations',
    accessor: 'noticeType',
    width: 240,
  },
  {
    Header: 'Date Served',
    accessor: 'dateServed',
    width: 142,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Delivered By',
    accessor: 'deliveredBy',
    width: 188,
  },
  {
    Header: 'Process Server',
    accessor: 'processServer',
    width: 188,
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: 400,
    edit: true,
    Cell: TextInputCell,
  },
  {
    width: 60,
    Header: '',
    accessor: 'control',
    Cell: EditCell,
  },
];
