import React from 'react';
import Container from '@mui/material/Container';
import ProceduralHistory from './ProceduralHistory';
import CaseStatus from './CaseStatus';
import Violations from './Violations';
import Damages from './Damages';
import RelatedCases from './RelatedCases';
import CaseInformation from './CaseInformation';

const CaseInformationArizona = () => {
  const [damagesFormData, setDamagesFormData] = React.useState({
    form: null,
    onSubmit: null,
  });

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      <CaseInformation />
      <ProceduralHistory />
      <CaseStatus />
      <RelatedCases />
      <Damages liftFormData={setDamagesFormData} />
      <Violations damagesFormData={damagesFormData} />
    </Container>
  );
};

export default CaseInformationArizona;
