import { configureStore } from '@reduxjs/toolkit';

import createCaseSliceReducer from './slices/createCaseSlice';
import casesSliceReducer from './slices/casesSlice';
import casesFilterSliceReducer from './slices/casesFilterSlice';

import createCommunitySliceReducer from './slices/createCommunitySlice';
import editCommunitySliceReducer from './slices/editCommunitySlice';
import communitiesSliceReducer from './slices/communitiesSlice';

import createContactSliceReducer from './slices/createContactSlice';
import editContactSliceReducer from './slices/editContactSlice';
import contactsSliceReducer from './slices/contactsSlice';

import createCourtSliceReducer from './slices/createCourtSlice';
import editCourtSliceReducer from './slices/editCourtSlice';
import courtsSliceReducer from './slices/courtsSlice';

import nevadaCourtsReducer from './slices/courts/nevadaCourtsSlice';
import arizonaCourtsReducer from './slices/courts/arizonaCourtsSlice';

import createOwnerEntitySliceReducer from './slices/createOwnerEntitySlice';
import editOwnerEntitySliceReducer from './slices/editOwnerEntitySlice';
import ownerEntitiesReducer from './slices/ownerEntitiesSlice';
import createAddressSliceReducer from './slices/addresses/createAddressSlice';
import editAddressSliceReducer from './slices/addresses/editAddressSlice';
import addressesSliceReducer from './slices/addresses/addressesSlice';
import arizonaAddressesReducer from './slices/addresses/arizonaAddressesSlice';
import nevadaAddressesReducer from './slices/addresses/nevadaAddressesSlice';

import casesGeneralSliceReducer from './slices/cases/casesGeneralSlice';
import casesGeneralCpSliceReducer from './slices/cases/casesGeneralCpSlice';
import caseCpSliceReducer from './slices/cases/caseCpSlice';
import casesArizonaSliceReducer from './slices/cases/casesArizonaSlice';
import casesNevadaSliceReducer from './slices/cases/casesNevadaSlice';

import managementSliceReducer from './slices/managementSlice';
import editManagementSliceReducer from './slices/editManagementSlice';

import adminUsersSliceReducer from './slices/adminUsersSlice';
import adminRolesSliceRecucer from './slices/adminRolesSlice';
import adminClientRequestNotificationsSliceReducer from './slices/adminClientRequestNotificationsSlice';

import caseInformationArizonaReducer from './slices/cases/caseInformationArizonaSlice';

import clientPortalAdminUsersReducer from './slices/clientPortalAdminUsers';

import NoticeIntakeReducer from './slices/requests/noticeIntakeSlice';
import requestsSliceReducer from './slices/requests/requestsSlice';
import nonPaymentRequestReducer from './slices/requests/nonPaymentRequest';

import proposedFormOfJudgementsReducer from './slices/schedule/proposedFormOfJudgementsSlice';
import attorneyAssignmentsReducer from './slices/schedule/attorneyAssignmentsSlice';
import addCourtDatesReducer from './slices/schedule/addCourtDatesSlice';

import documentsReducer from './slices/dbAdmin/documentsSlice';

import reportsReducer from './slices/reportsSlice';

import generateInvoiceReducer from './slices/generateInvoiceSlice';

import bulkDocumentPrintingReducer from './slices/schedule/bulkDocumentPrintingSlice';

import clientPortalDashboardReducer from './slices/clientPortalDashboardSlice';

import noticesReducer from './slices/noticesSlice';
import attorneyDashboardReducer from './slices/attorneyDashboardSlice';
import legalAssistantDashboardReducer from './slices/legalAssistantDashboardSlice';

import invoicesReducer from './slices/invoicesSlice';

import logsReducer from './slices/logsSlice';

import documentsDataHandlingReducer from './slices/documentsSlice';
import feeScheduleReducer from './slices/feeScheduleSlice';
import adminLoginReducer from './slices/adminLoginSlice';
import userProfileReducer from './slices/userProfileSlice';

export const createStore = (preloadedState) =>
  configureStore({
    reducer: {
      cases: casesSliceReducer,
      casesFilters: casesFilterSliceReducer,
      createCase: createCaseSliceReducer,
      casesGeneral: casesGeneralSliceReducer,
      casesGeneralCp: casesGeneralCpSliceReducer,
      caseCp: caseCpSliceReducer,
      casesArizona: casesArizonaSliceReducer,
      casesNevada: casesNevadaSliceReducer,
      createCommunity: createCommunitySliceReducer,
      editCommunity: editCommunitySliceReducer,
      communities: communitiesSliceReducer,
      courts: courtsSliceReducer,
      createCourt: createCourtSliceReducer,
      editCourt: editCourtSliceReducer,
      arizonaCourts: arizonaCourtsReducer,
      nevadaCourts: nevadaCourtsReducer,
      contacts: contactsSliceReducer,
      editContact: editContactSliceReducer,
      createContact: createContactSliceReducer,
      createOwnerEntity: createOwnerEntitySliceReducer,
      editOwnerEntity: editOwnerEntitySliceReducer,
      ownerEntities: ownerEntitiesReducer,
      addresses: addressesSliceReducer,
      editAddress: editAddressSliceReducer,
      arizonaAddresses: arizonaAddressesReducer,
      nevadaAddresses: nevadaAddressesReducer,
      management: managementSliceReducer,
      editManagement: editManagementSliceReducer,
      createAddress: createAddressSliceReducer,
      adminUsers: adminUsersSliceReducer,
      adminRoles: adminRolesSliceRecucer,
      adminClientRequestNotifications: adminClientRequestNotificationsSliceReducer,
      caseInformationArizona: caseInformationArizonaReducer,
      clientPortalAdminUsers: clientPortalAdminUsersReducer,
      noticeIntake: NoticeIntakeReducer,
      requests: requestsSliceReducer,
      nonPaymentRequest: nonPaymentRequestReducer,
      proposedFormOfJudgements: proposedFormOfJudgementsReducer,
      addCourtDates: addCourtDatesReducer,
      attorneyAssignments: attorneyAssignmentsReducer,
      adminDocuments: documentsReducer,
      documentsDataHandling: documentsDataHandlingReducer,
      reports: reportsReducer,
      bulkDocumentPrinting: bulkDocumentPrintingReducer,
      clientPortalDashboard: clientPortalDashboardReducer,
      generateInvoice: generateInvoiceReducer,
      notices: noticesReducer,
      attorneyDashboard: attorneyDashboardReducer,
      legalAssistantDashboard: legalAssistantDashboardReducer,
      invoices: invoicesReducer,
      logs: logsReducer,
      feeSchedule: feeScheduleReducer,
      adminLogin: adminLoginReducer,
      userProfile: userProfileReducer,
    },
    preloadedState,
  });
