import React, { useCallback, createContext, useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';
import { ReactComponent as Accept } from '../../../../assets/icons/accept.svg';
import { ReactComponent as Closed } from '../../../../assets/icons/closed.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/Edit.svg';
import { ReactComponent as Trash } from '../../../../assets/icons/Trash.svg';
import NotificationDialog from '../../../molecules/NotificationDialog';

export const EditableContext = createContext({ isDeletable: false });

const PartiesAndPrecinctEditableCell = ({
  row: { id, index, original },
  setEditableRowIndex,
  editableRowIndex,
  cancelEditing,
  updateData,
  deleteRow,
}) => {
  const [deleteModalText, setDeleteModalText] = useState(null);
  const { isDeletable } = useContext(EditableContext);

  const handleClickEdit = useCallback(() => {
    setEditableRowIndex(index);
  }, [index, setEditableRowIndex]);

  const handleCloseModal = useCallback(() => setDeleteModalText(null), []);

  const handleConfirmModal = useCallback(() => {
    deleteRow(id);
    handleCloseModal();
  }, [handleCloseModal, deleteRow, id]);

  const handleDeleteClick = useCallback(() => {
    if (original.caseEntityId) {
      setDeleteModalText('Are you sure you want to delete the Entity?');
    } else if (original.caseDefendantId) {
      setDeleteModalText('Are you sure you want to delete the Individual?');
    } else {
      deleteRow(id);
    }
  }, [deleteRow, id]);

  return editableRowIndex === index ? (
    <>
      <IconButton onClick={updateData}>
        <Accept />
      </IconButton>
      <IconButton onClick={cancelEditing}>
        <Closed />
      </IconButton>
    </>
  ) : (
    <>
      <IconButton onClick={handleClickEdit}>
        <Edit />
      </IconButton>
      {isDeletable && (
        <>
          <IconButton onClick={handleDeleteClick}>
            <Trash />
          </IconButton>
          <NotificationDialog
            title="Are you sure?"
            desc={deleteModalText}
            type="alert"
            buttonSecondaryText="Cancel"
            buttonPrimaryText="Delete"
            onClose={handleCloseModal}
            onConfirm={handleConfirmModal}
            isOpen={Boolean(deleteModalText)}
          />
        </>
      )}
    </>
  );
};

PartiesAndPrecinctEditableCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({
      caseDefendantId: PropTypes.number,
      caseEntityId: PropTypes.number,
    }),
  }).isRequired,
  setEditableRowIndex: PropTypes.func.isRequired,
  editableRowIndex: PropTypes.number.isRequired,
  cancelEditing: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
};

export default PartiesAndPrecinctEditableCell;
