/* eslint-disable react/prop-types */
import React from 'react';
import MRadioButton from '../../../../atoms/MRadioButton';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const OwnerEntityAddressColumns = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 45,
  },
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 35,
  },
  {
    Header: () => <div style={{ textAlign: 'center' }}>On Hold</div>,
    accessor: 'isOnHold',
    width: 20,
    Cell: ({ row }) => (
      <div style={{ textAlign: 'center' }}>
        <MRadioButton isTogglable={false} isChecked={row?.original?.isOnHold} />
      </div>
    ),
  },
];
