import moment from 'moment';
import { styled } from '@mui/material';
import { PrintCell } from '../../../atoms/Table/TableCells/PrintCell';
import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';
import { ActiveTextInputCell } from '../../../atoms/Table/TableCells/ActiveTextInputCell';
import { DocumentsTypeCell } from '../../../atoms/Table/TableCells/DocumentsTypeCell';
import { DocumentsLinkCell } from './DocumentsLinkCell';
import { DeliveredByCell } from './DeliveredByCell';
import { SentPrintedCell } from '../Notices/TableCells/SentPrintedCell';
import { HideDocumentCell } from '../../../atoms/Table/TableCells/HideDocumentCell';
import MTooltip from '../../../atoms/MTooltip';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const documentStrings = {
  title: 'Documents',
  boxes: [
    {
      title: 'Create new document',
      selectLabel: 'Select the document',
    },
    {
      title: 'Upload document',
    },
  ],
  uploadNew: {
    title: 'Upload Document',
    text: 'Drop your documents here,',
    action: 'or select',
    additionalInfo: 'Supports PDF, JPEG, PNG, TIFF, DOCS, DOC, Excel files up to 30MB',
    add: 'Add document',
    cancel: 'Cancel',
    submit: 'Upload',
  },
  upload: {
    title: 'Upload Document',
    text: 'Drop your documents here, ',
    action: 'or select',
    add: 'Add document',
    cancel: 'Cancel',
    submit: 'Upload',
  },
  button: {
    print: 'Print selected',
    delete: 'Delete selected',
    create: 'Create new document',
    upload: 'Upload document',
    update: 'Update case',
    cancel: 'Cancel',
  },
};

const StyledSpan = styled('span')({
  fontSize: '16px',
});

export const documentsColumnsDB = {
  NV: [
    {
      Header: 'Document name',
      accessor: 'documentName',
      width: 20,
      Cell: DocumentsLinkCell,
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 15,
      edit: true,
      Cell: DocumentsTypeCell,
    },
    {
      Header: 'Delivered By',
      accessor: 'deliveredBy',
      width: 20,
      Cell: (row) => <DeliveredByCell {...row} />,
    },
    {
      Header: 'Created Date',
      accessor: 'createdDate',
      width: 15,
    },
    {
      Header: 'Created Time',
      accessor: 'createdTime',
      width: 15,
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      width: 15,
    },
    {
      Header: 'Source',
      accessor: 'source',
      width: 15,
    },
    {
      Header: 'Status',
      accessor: 'wasPrinted',
      width: 15,
      Cell: SentPrintedCell,
    },
    {
      Header: (
        <StyledSpan>
          Hide
          <MTooltip
            text="If checkbox is selected, the document is hidden from Client Portal"
            customStyles={{ position: 'absolute', top: '-10px', right: '-25px' }}
          />
        </StyledSpan>
      ),
      accessor: 'isHidden',
      width: 10,
      Cell: HideDocumentCell,
    },
    {
      Header: '',
      width: 5,
      minWidth: 70,
      accessor: 'control',
      Cell: PrintCell,
    },
  ],
  AZ: [
    {
      Header: 'Document name',
      accessor: 'documentName',
      width: 20,
      Cell: DocumentsLinkCell,
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 15,
      edit: true,
      Cell: DocumentsTypeCell,
    },
    {
      Header: 'Delivered By',
      accessor: 'deliveredBy',
      width: 20,
      Cell: (row) => <DeliveredByCell {...row} />,
    },
    {
      Header: 'Created Date',
      accessor: 'createdDate',
      width: 15,
    },
    {
      Header: 'Created Time',
      accessor: 'createdTime',
      width: 15,
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      width: 15,
    },
    {
      Header: 'Source',
      accessor: 'source',
      width: 15,
    },
    {
      Header: 'Status',
      accessor: 'wasPrinted',
      width: 15,
      Cell: SentPrintedCell,
    },
    {
      Header: (
        <StyledSpan>
          Hide
          <MTooltip
            text="If checkbox is selected, the document is hidden from Client Portal"
            customStyles={{ position: 'absolute', top: '-10px', right: '-25px' }}
          />
        </StyledSpan>
      ),
      accessor: 'isHidden',
      width: 10,
      Cell: HideDocumentCell,
    },
    {
      Header: '',
      width: 5,
      minWidth: 70,
      accessor: 'control',
      Cell: PrintCell,
    },
  ],
};

export const documentsColumnsCP = [
  {
    Header: 'Document name',
    accessor: 'documentName',
    width: 20,
    Cell: (props) => {
      return <DocumentsLinkCell {...props} isDB={false} />;
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 15,
    edit: false,
    Cell: (props) => {
      return <DocumentsTypeCell {...props} isDB={false} />;
    },
  },
  {
    Header: 'Created Date',
    accessor: 'createdDate',
    width: 20,
  },
  {
    Header: 'Created Time',
    accessor: 'createdTime',
    width: 20,
  },
  {
    Header: 'Created By',
    accessor: 'createdBy',
    width: 20,
  },
];

export const uploadDocumentsColumns = [
  {
    Header: 'Document name',
    accessor: 'name',
    width: 47,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 47,
    Cell: ActiveTextInputCell,
    edit: true,
  },
  {
    Header: '',
    width: 6,
    accessor: 'control',
    Cell: DeleteCell,
  },
];

export const violationColumns = [
  {
    Header: 'Notice Type',
    accessor: 'noticeType',
  },
  {
    Header: 'Date Served',
    accessor: 'dateServed',
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Prefile Date',
    accessor: 'prefileDate',
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Delivered By',
    accessor: 'deliveredBy',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Violation Type',
    accessor: 'noticeTypeGroup',
  },
];
