/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';
import formatDate from '../../../utils/formatDate';

export const getAddress = createAsyncThunk('nevadaAddresses/getAddress', async (id) => {
  const { data } = await apiClient.get(`/api/nevadaAddresses/${id}`);
  return data;
});

export const updateAddress = createAsyncThunk(
  'nevadaAddresses/updateAddress',
  async (editAddressObj, { rejectWithValue }) => {
    try {
      const { id, ...fields } = editAddressObj;
      const { data } = await apiClient.put(`/api/nevadaAddresses/${id}`, fields);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createAddress = createAsyncThunk(
  'nevadaAddresses/createAddress',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/nevadaAddresses`, params);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const resetEditAddressError = createAction('nevadaAddresses/resetEditAddressError', () => ({
  type: 'nevadaAddresses/resetEditAddressError',
}));

const initialState = {
  getAddress: {},
  editAddressError: null,
  addressId: null,
};

export const nevadaAddressesSlice = createSlice({
  name: 'nevadaAddresses',
  initialState,
  reducers: {},
  extraReducers: {
    [createAddress.fulfilled]: (state, action) => {
      state.addressId = action.payload.result;
    },
    [createAddress.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAddress.fulfilled]: (state, action) => {
      const { result } = action.payload;
      const formattedResult = {
        ...result,
        ownerEntityRefs: result.ownerEntityRefs.map((item) => ({
          ...item,
          endDate: formatDate(item?.endDate, 'MM/DD/YYYY'),
          startDate: formatDate(item?.startDate, 'MM/DD/YYYY'),
        })),
      };

      state.getAddress = formattedResult;
    },
    [updateAddress.rejected]: (state, action) => {
      state.editAddressError = action.payload;
    },
    [resetEditAddressError]: (state) => {
      state.editAddressError = null;
    },
  },
});

export default nevadaAddressesSlice.reducer;
