import React, { useEffect, useMemo } from 'react';
import { Box, styled, Typography, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  listConfig,
  CASES_TO_BE_ENTERED,
  FOLLOW_UPS,
  NOTICES_TO_BE_DRAFTED,
  PREFILES_TO_BE_PRINTED,
} from './DashboardLegalAssistantLists.constants';
import { ReactComponent as Calender } from '../../../../../assets/icons/Calender.svg';
import Table from '../../../../atoms/Table/Table';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import PeriodButtons from '../../../../atoms/PeriodButtons';
import LoaderCover from '../../../../atoms/LoaderCover';
import DateOrTimePicker from '../../../../atoms/DateOrTimePicker';
import { getIsDateTodayOrInFuture } from '../../../../../utils/dateHelpers';

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  padding: '32px',
  border: `1px solid ${theme.palette.additional.lines}`,
  borderRadius: '16px',
  maxWidth: 'calc(100vw - 328px - 64px - 24px)',
  flexGrow: 1,
}));

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const convertPeriodFromSettingsToBoolean = (period, type) => {
  switch (type) {
    case 'casesToBeEntered': {
      if (period === 'all cases') {
        return false;
      }
      if (period === 'my cases') {
        return true;
      }
      return 'unassigned';
    }
    case 'noticesToBeDrafted': {
      if (period === 'all notices') {
        return false;
      }
      if (period === 'my notices') {
        return true;
      }
      return 'unassigned';
    }
    case 'prefilesToBePrinted': {
      if (period === 'my prefiles') {
        return true;
      }
      return false;
    }
    case 'followUps': {
      if (period === 'my cases') {
        return true;
      }
      return false;
    }

    default:
      return false;
  }
};

export const convertPeriodNameToSettingsName = (type) => {
  switch (type) {
    case CASES_TO_BE_ENTERED: {
      return 'casesToBeEntered';
    }
    case NOTICES_TO_BE_DRAFTED: {
      return 'noticesToBeDrafted';
    }
    case PREFILES_TO_BE_PRINTED: {
      return 'prefilesToBePrinted';
    }
    case FOLLOW_UPS: {
      return 'followUps';
    }

    default:
      return '';
  }
};

const ClientPortalDashboardList = ({
  type,
  isLoader,
  setIsLoader,
  stateCode,
  handleCheckedRow,
  isTableLoader,
  handleFetchDataPage,
  period,
  setPeriod,
  date,
  setDate,
}) => {
  const { title, periods, defaultPeriod, columns } = listConfig[type];

  const lists = useSelector((state) => state.legalAssistantDashboard.lists);
  const settings = useSelector((state) => state.legalAssistantDashboard.settings);
  const settingsForCards =
    stateCode === 'AZ' ? settings?.arizonaLegalAssistant : settings?.nevadaLegalAssistant;

  const currentType = convertPeriodNameToSettingsName(type);
  const currentPeriod = settingsForCards?.[currentType];
  const convertedSettings = convertPeriodFromSettingsToBoolean(currentPeriod, currentType);

  const defaultPeriodFromSettings =
    typeof convertedSettings === 'boolean' || convertedSettings === 'unassigned'
      ? convertedSettings
      : defaultPeriod;

  const data = lists?.[type];
  const isBulkAssign =
    [CASES_TO_BE_ENTERED, NOTICES_TO_BE_DRAFTED].includes(type) && period !== true;

  const refetch = async (periodFromSettings) => {
    setIsLoader(true);
    try {
      await handleFetchDataPage(1, periodFromSettings);
    } finally {
      setIsLoader(false);
    }
  };
  useEffect(() => {
    refetch(defaultPeriodFromSettings);
    if (period !== defaultPeriodFromSettings) setPeriod(defaultPeriodFromSettings);
  }, [type, stateCode, defaultPeriodFromSettings]);

  useEffect(() => {
    if (
      [PREFILES_TO_BE_PRINTED, FOLLOW_UPS, CASES_TO_BE_ENTERED, NOTICES_TO_BE_DRAFTED].includes(
        type,
      )
    ) {
      refetch();
    }
  }, [period, date]);

  const columnsList = useMemo(() => {
    const isValidDate = moment(date).isValid();

    // we show legalAssistant column only for all
    const columnsByPeriod =
      typeof columns === 'function'
        ? columns({
            period,
            stateCode,
            isDateInThePast: date && isValidDate && !getIsDateTodayOrInFuture(date),
          })
        : columns;

    return columnsByPeriod;
  }, [columns, period, stateCode, date]);

  const changeDate = (selected) => {
    setDate(selected);
  };

  return (
    <Wrapper>
      {isLoader && <LoaderCover />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={4}
        height="40px"
        width="100%"
      >
        <Typography variant="h5">{title}</Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <PeriodButtons
            currentPeriod={period}
            isDisabled={isLoader}
            onPeriodChange={(p) => {
              setPeriod(p);
            }}
            periods={periods}
          />
          {type === PREFILES_TO_BE_PRINTED && (
            <Box ml={1}>
              <DateOrTimePicker
                name="date"
                isDate
                Svg={Calender}
                selected={date}
                width="200px"
                height="48px"
                setSelectedTimeOrDate={changeDate}
              />
            </Box>
          )}
        </Stack>
      </Stack>
      {!isLoader && !!data?.items?.length && (
        <Box flexGrow={1}>
          <Table
            columns={columnsList}
            rows={data?.items ?? []}
            total={data?.totalRowsCount}
            isPagination={data?.totalPages > 1}
            onNextPage={handleFetchDataPage}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            onRefreshPage={handleFetchDataPage}
            pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            loading={isTableLoader}
            isWithCheckbox={isBulkAssign}
            onCheckedRow={handleCheckedRow}
            overflowHidden={false}
          />
        </Box>
      )}
      {!isLoader && !data?.items?.length && (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" flexGrow={1}>
          <EmptyBlock title="No records" />
        </Box>
      )}
    </Wrapper>
  );
};

ClientPortalDashboardList.propTypes = {
  type: PropTypes.oneOf([
    CASES_TO_BE_ENTERED,
    FOLLOW_UPS,
    NOTICES_TO_BE_DRAFTED,
    PREFILES_TO_BE_PRINTED,
  ]).isRequired,
  isLoader: PropTypes.bool.isRequired,
  setIsLoader: PropTypes.func.isRequired,
  stateCode: PropTypes.string.isRequired,
  handleCheckedRow: PropTypes.func.isRequired,
  isTableLoader: PropTypes.bool.isRequired,
  period: PropTypes.oneOf([PropTypes.bool, PropTypes.string]).isRequired,
  setPeriod: PropTypes.func.isRequired,
  handleFetchDataPage: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
};

export default React.memo(ClientPortalDashboardList);
