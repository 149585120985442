/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Stack, Container, styled, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import {
  CaseComparisonByEmployeeFormBoxes,
  stateCodesOptions,
} from './CaseComparisonByEmployee.constants';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import CheckboxSelect from '../../../atoms/CheckboxSelect';
import {
  createCaseComparisonByEmployeeReport,
  getCaseComparisonByEmployeeReport,
  getListOfAvailableSubReportForCaseComparison,
} from '../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import Dropdown from '../../../atoms/Dropdown';
import Table from '../../../atoms/Table/Table';
import useAuth from '../../../../hooks/useAuth';
import PieChart from './PieChart';
import { rolesDB } from '../../../../utils/roleHelpers';
import './table.css';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { palette } from '../../../../theme/default';
import ProgressModal from '../ProductivityByAttorney/ProgressModal';
import notificationUtils from '../../../../utils/notificationUtils';

const StyledContainer = styled(Container)({
  '&': {
    marginTop: '32px',
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
    },
    '.MuiPaper-root': {
      marginBottom: '16px',
      borderRadius: '16px',
      boxShadow: '0px 3px 32px rgb(106 114 142 / 10%)',
    },
  },
});

const CaseComparisonByEmployee = () => {
  const { userInfo } = useAuth();

  const [isLoader, setIsLoader] = useState(false);
  const [currentStates, setCurrentStates] = useState([]);
  const [chartItems, setChartItems] = useState(null);
  const [requestedStatus, setRequestedStatus] = useState('');
  const dispatch = useDispatch();
  const { subReports } = useSelector((state) => state.reports);

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const startDate = form.watch('fromDate');
  useEffect(() => {
    dispatch(getListOfAvailableSubReportForCaseComparison());
  }, []);

  const isAdmin = userInfo.roles.includes(rolesDB.Admin);

  const getOptions = (name) => {
    switch (name) {
      case 'stateCodes':
        return isAdmin
          ? stateCodesOptions
          : stateCodesOptions.filter(({ value }) => userInfo.StateCode === value);
      case 'subReport':
        return subReports;
      default:
        return [];
    }
  };

  const handleSubmit = async (values) => {
    const states = values.stateCodes.map((item) => item.value);
    const data = {
      fromDate: values.fromDate,
      toDate: values.toDate,
      stateCodes: values.stateCodes.map((item) => item.value),
      caseStatus: values.subReport.value,
    };
    setCurrentStates(states);
    setIsLoader(true);

    try {
      const { reportId } = await dispatch(createCaseComparisonByEmployeeReport(data)).unwrap();
      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code },
        } = await dispatch(getCaseComparisonByEmployeeReport(reportId)).unwrap();
        if (code === 'Completed') {
          setChartItems(items);
          setRequestedStatus(values.subReport.value);
          done = true;
        } else if (code === 'Error') {
          // TODO: Add error popup
          // alert('--------------', message);
          notificationUtils.error('Failed, try again later');
          setChartItems(null);
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      // TODO: Add error popup
      // alert('--------------', e);
      notificationUtils.error('Failed, try again later');
      setChartItems(null);
    }
    setIsLoader(false);
  };

  const createColumns = () => {
    const bothStates = currentStates.length === 2;
    if (bothStates) {
      return [
        {
          Header: 'User',
          accessor: 'user',
          width: 100,
          minWidth: 150,
        },
        {
          Header: 'AZ',
          accessor: 'AZ',
          width: 100,
          minWidth: 150,
        },
        {
          Header: 'NV',
          accessor: 'NV',
          width: 100,
          minWidth: 150,
        },
        {
          Header: 'Total',
          accessor: 'total',
          // eslint-disable-next-line
          Cell: (props) => <Box className="total">{props.value}</Box>,
          width: 100,
          minWidth: 150,
        },
      ];
    }
    if (currentStates.includes('AZ')) {
      return [
        {
          Header: 'User',
          accessor: 'user',
          width: 70,
          minWidth: 150,
        },
        {
          Header: 'AZ',
          accessor: 'AZ',
          width: 30,
          minWidth: 150,
          // eslint-disable-next-line
          Cell: (props) => <Box className="total">{props.value}</Box>,
        },
      ];
    }

    return [
      {
        Header: 'User',
        accessor: 'user',
        width: 70,
        minWidth: 150,
      },
      {
        Header: 'NV',
        accessor: 'NV',
        width: 30,
        minWidth: 150,
        // eslint-disable-next-line
        Cell: (props) => <Box className="total">{props.value}</Box>,
      },
    ];
  };

  const getMinDate = (name) => {
    switch (name) {
      case 'toDate':
        return startDate;
      default:
        return null;
    }
  };

  const getMaxDate = (name) => {
    switch (name) {
      case 'toDate':
        return moment();
      default:
        return null;
    }
  };

  const rowsAdapter = (tableData) => {
    const users = tableData.employees.map((item) => `${item.firstName} ${item.lastName}`);

    const hasDuplicates = new Set(users).size !== users.length;
    const rows = tableData.employees.map((row) => {
      const name = hasDuplicates
        ? `${row.firstName} ${row.lastName} (${row.userName})`
        : `${row.firstName} ${row.lastName}`;
      return {
        user: name,
        AZ: row.states.find((item) => item.stateCode === 'AZ')?.value || '0',
        NV: row.states.find((item) => item.stateCode === 'NV')?.value || '0',
        total: row.total,
      };
    });
    rows.sort((a, b) => b.total - a.total);
    return rows;
  };

  const formattedDataAZ = chartItems?.[0]?.employees?.reduce((acc, item) => {
    const valueByState = item.states.filter((state) => state.stateCode === 'AZ');

    if (valueByState.length) {
      acc.push({
        name: `${item.firstName} ${item.lastName}`,
        value: valueByState[0]?.value || 0,
      });
    }
    return acc;
  }, []);

  const formattedDataNV = chartItems?.[0]?.employees?.reduce((acc, item) => {
    const valueByState = item.states.filter((state) => state.stateCode === 'NV');

    if (valueByState.length) {
      acc.push({
        name: `${item.firstName} ${item.lastName}`,
        value: valueByState[0]?.value || 0,
      });
    }
    return acc;
  }, []);

  return (
    <Stack direction="column" mb={6}>
      <ShadowBlock>
        {isLoader && <ProgressModal />}
        <Form form={form} onSubmit={handleSubmit}>
          {CaseComparisonByEmployeeFormBoxes.map((i) => (
            <Stack
              direction="row"
              alignItems="center"
              columnGap={3}
              rowGap={2}
              flexWrap="wrap"
              py={1}
            >
              {i.inputBoxes.map((item) => {
                if (item.isCheckboxSelect) {
                  return (
                    <Field
                      key={item.name}
                      isMandatory={item.isMandatory}
                      name={item.name}
                      render={({ field, onCustomChange, error }) => (
                        <CheckboxSelect
                          label={item.label}
                          value={field.value}
                          width={item.width}
                          error={error}
                          isMandatory={item.isMandatory}
                          placeholder={item.placeholder}
                          onChange={onCustomChange(field.onChange)}
                          options={getOptions(item.name)}
                        />
                      )}
                    />
                  );
                }

                if (item.isDatePicker) {
                  return (
                    <Field
                      name={item.name}
                      key={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange, error }) => (
                        <DateOrTimePicker
                          name={item.name}
                          label={item.label}
                          isDate
                          error={error}
                          Svg={Calender}
                          selected={field.value}
                          isMandatory={item.isMandatory}
                          width={item.width}
                          height={item.height}
                          placeholder={item.placeholder}
                          setSelectedTimeOrDate={onCustomChange(field.onChange)}
                          minDate={getMinDate(item.name)}
                          maxDate={getMaxDate(item.name)}
                        />
                      )}
                    />
                  );
                }

                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <Dropdown
                          value={field.value}
                          isAddDisabled={false}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          isColumn
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={onCustomChange(field.onChange)}
                          isDisabled={item.isDisabled}
                          isMandatory={item.isMandatory}
                        />
                      )}
                    />
                  );
                }

                return null;
              })}
            </Stack>
          ))}
          <Stack direction="row" mt={2}>
            <MButton
              data-testid="run_report_btn"
              type="submit"
              size="medium"
              disabled={isLoader || !form.formState.isValid}
            >
              Run Report
            </MButton>
          </Stack>
        </Form>
      </ShadowBlock>

      {chartItems && !isLoader ? (
        <StyledContainer maxWidth={false} disableGutters>
          <ShadowBlock>
            <Typography variant="h5" fontWeight={600} data-testid="report_title">
              {requestedStatus} by Employee(s)
            </Typography>
            {chartItems[0]?.employees?.length ? (
              <>
                <Stack pt={4} direction="row" gap={2} flexWrap="inherit">
                  {currentStates.find((item) => item === 'AZ') && (
                    <Box
                      style={{
                        height: '350px',
                        width: currentStates?.length > 1 ? '50%' : '100%',
                        borderRadius: 6,
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
                      }}
                    >
                      {formattedDataAZ?.length ? (
                        <PieChart data={formattedDataAZ} stateCode="AZ" />
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                          flexGrow={1}
                        >
                          <EmptyBlock title="No Records" />
                        </Box>
                      )}
                    </Box>
                  )}
                  {currentStates.find((item) => item === 'NV') && (
                    <Box
                      style={{
                        height: '350px',
                        width: currentStates?.length > 1 ? '50%' : '100%',
                        borderRadius: 6,
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
                      }}
                    >
                      {formattedDataNV?.length ? (
                        <PieChart data={formattedDataNV} stateCode="NV" />
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                          flexGrow={1}
                        >
                          <EmptyBlock title="No Records" />
                        </Box>
                      )}
                    </Box>
                  )}
                </Stack>
                {chartItems?.map((items) => {
                  return (
                    <Box mb={2}>
                      <Table columns={createColumns(items)} rows={rowsAdapter(items)} />
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                flexGrow={1}
              >
                <EmptyBlock title="No records" desc="Please change filters to display report" />{' '}
              </Box>
            )}
          </ShadowBlock>
        </StyledContainer>
      ) : null}
    </Stack>
  );
};

export default CaseComparisonByEmployee;
