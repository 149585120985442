import PropTypes from 'prop-types';

export const EmailCell = ({ value }) => {
  return (
    <a
      style={{ wordBreak: 'break-all', color: 'inherit' }}
      href={`mailto:${value}`}
      data-testid="email_cell"
    >
      {value}
    </a>
  );
};

EmailCell.propTypes = {
  value: PropTypes.string,
};

EmailCell.defaultProps = {
  value: '',
};
