/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Stack, CircularProgress } from '@mui/material';
import Table from '../../../atoms/Table/Table';
import MButton from '../../../MUI/Button/MButton';
import {
  addAddressesToTheTableInUserCreationMode,
  editAddresses,
  getAddressesList,
  getUserDetails,
} from '../../../../store/slices/clientPortalAdminUsers';
import { newUserAddressColumns } from './ClientPortalAdmin.constants';
import { MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './ClientPortalAdmin.constants';
import { useLocation } from 'react-router-dom';
import EmptyBlock from '../../../molecules/EmptyBlock';
import Searcher from '../../../molecules/Searcher';
import MDialog from '../../../atoms/MDialog';

const ClientPortalAddressesModal = ({
  isOpen,
  title,
  buttonSecondaryText,
  buttonPrimaryText,
  onClose,
  inputPlaceholder,
  managementId,
  alreadyAdded,
  stateCode,
}) => {
  const dispatch = useDispatch();
  const {
    state: { id },
  } = useLocation();

  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const { addresses } = useSelector((state) => state.clientPortalAdminUsers);
  const [searchValue, setSearchValue] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  useEffect(() => {
    if (!managementId) return;

    setIsLoaderTable(true);
    dispatch(
      getAddressesList({
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        address: '',
        managementId,
        stateCode,
      }),
    ).then(() => setIsLoaderTable(false));
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && searchValue === '') {
      setIsLoaderTable(true);
      dispatch(
        getAddressesList({
          currentPage: 1,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          address: '',
          managementId,
          stateCode,
        }),
      ).then(() => setIsLoaderTable(false));
    }
  }, [searchValue]);

  const handleCheckedRow = useCallback((selectedRows) => {
    setCheckedRows(selectedRows.map((item) => item.addressId));
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      dispatch(
        getAddressesList({
          currentPage: currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          address: searchValue,
          managementId,
          stateCode,
        }),
      );
    },
    [searchValue, title],
  );

  const handleInputChange = (value) => {
    setSearchValue(value);
  };

  const addItemsIfEditMode = () => {
    const rowsSet = new Set(checkedRows);

    alreadyAdded &&
      alreadyAdded.forEach((item) => {
        rowsSet.add(item.addressId);
      });

    dispatch(
      editAddresses({
        userId: id,
        addressIds: Array.from(rowsSet.values()),
      }),
    ).then(() => {
      dispatch(getUserDetails(id)).then(onClose);
    });
  };

  const addItemsIfUserCreationMode = () => {
    const alreadyAddedIds = alreadyAdded.map((item) => item.addressId);

    const rows = addresses.items
      .filter(
        (item) => checkedRows.includes(item.addressId) && !alreadyAddedIds.includes(item.addressId),
      )
      .map((item) => ({
        addressId: item.addressId,
        address: item.address,
      }));
    dispatch(addAddressesToTheTableInUserCreationMode(rows));
    onClose();
  };

  const handleSearchByInputValue = () => {
    if (isLoaderTable || !searchValue) return;

    dispatch(
      getAddressesList({
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        address: searchValue,
        managementId,
        stateCode,
      }),
    );
  };

  const handleConfirm = useCallback(() => {
    return id ? addItemsIfEditMode() : addItemsIfUserCreationMode();
  }, [checkedRows?.length, title]);

  return (
    <MDialog
      data-testid="MDialog-addressModal"
      onClose={onClose}
      open={isOpen}
      maxWidth="md"
      fullWidth
    >
      <Stack spacing={4} direction="column" display="flex">
        <Typography variant="h4">{title}</Typography>
        <Stack display="flex" direction="row" alignItems="center" height="48px">
          <Searcher
            inputPlaceholder={inputPlaceholder}
            onSearch={handleSearchByInputValue}
            onChange={handleInputChange}
          />
        </Stack>
        {isLoaderTable ? (
          <Stack flexGrow={1} justifyContent="center" alignItems="center">
            <CircularProgress data-testid="CircularProgress" />
          </Stack>
        ) : (
          <Stack
            flexGrow={1}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ height: '40vh' }}
          >
            {managementId && addresses?.items?.length ? (
              <Table
                data-testid="AddressesModal"
                columns={newUserAddressColumns}
                rows={addresses.items}
                total={addresses.totalRowsCount}
                isPagination={addresses.totalPages > 1}
                onNextPage={handleFetchDataPage}
                onPreviousPage={handleFetchDataPage}
                onGotoPage={handleFetchDataPage}
                pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
                loading={isLoaderTable}
                isWithCheckbox={true}
                onCheckedRow={handleCheckedRow}
              />
            ) : (
              <EmptyBlock
                title="Addresses not found"
                desc="Сhange the search value"
                margin="auto"
              />
            )}
          </Stack>
        )}
        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
          {buttonSecondaryText && (
            <MButton
              data-testid="secondaryBtn"
              variant="secondary"
              size="large"
              onClick={onClose}
              sx={{ width: '104px' }}
            >
              {buttonSecondaryText}
            </MButton>
          )}
          {buttonPrimaryText && (
            <MButton
              data-testid="primaryBtn"
              size="large"
              onClick={handleConfirm}
              sx={{ width: '104px' }}
              disabled={false}
            >
              {buttonPrimaryText}
            </MButton>
          )}
        </Stack>
      </Stack>
    </MDialog>
  );
};

ClientPortalAddressesModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  managementId: PropTypes.string,
  onClose: PropTypes.func,
  inputPlaceholder: PropTypes.string.isRequired,
  alreadyAdded: PropTypes.instanceOf(Array),
  stateCode: PropTypes.string,
};

ClientPortalAddressesModal.defaultProps = {
  isOpen: false,
  title: '',
  buttonSecondaryText: '',
  managementId: null,
  buttonPrimaryText: '',
  onClose: null,
  alreadyAdded: [],
  stateCode: '',
};

export default ClientPortalAddressesModal;
