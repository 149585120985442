/* eslint-disable no-prototype-builtins */
/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const deleteCourt = createAsyncThunk(
  'court/deleteCourt',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.delete(`/api/courts/${id}`);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const getCourt = createAsyncThunk('court/getCourt', async (id) => {
  const { data } = await apiClient.get(`/api/courts/${id}`);

  const { trialDateTime } = data.result.additionalInfo;
  data.result.additionalInfo.trialDate = trialDateTime || new Date();
  data.result.additionalInfo.trialTime = trialDateTime || new Date();

  return data;
});

export const getStates = createAsyncThunk('court/states', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data;
});

export const getCounties = createAsyncThunk('court/counties', async (stateCode) => {
  const queryParam = `?StateCode=${stateCode}`;
  const { data } = await apiClient.get(`/api/counties${stateCode && queryParam}`);
  return data;
});

export const editCourt = createAsyncThunk(
  'court/editCourt',
  async (editCourtObj, { rejectWithValue }) => {
    const { id, ...fields } = editCourtObj;
    try {
      const { data } = await apiClient.put(`/api/courts/${id}`, { ...fields });
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const getCourtAddresses = createAsyncThunk(
  'court/getCourtAddresses',
  async (editCourtAddressesObj) => {
    const { id, ...fields } = editCourtAddressesObj;
    const { data } = await apiClient.post(`/api/courts/${id}/addresses/filter`, fields);
    return data;
  },
);

export const editCourtNotes = createAsyncThunk(
  'court/editCourtNotes',
  async (editCourtNotesObj) => {
    const { id, ...fields } = editCourtNotesObj;
    const { data } = await apiClient.post(`/api/courts/${id}/notes`, fields);
    return data;
  },
);

export const getCourtNotes = createAsyncThunk('court/getCourtNotes', async (id) => {
  const { data } = await apiClient.get(`/api/courts/${id}/notes`);
  return data;
});

export const getAddresses = createAsyncThunk('court/getAddresses', async (params) => {
  const { data } = await apiClient.post(`/api/addresses/filter`, params);
  return data;
});

export const editCourtTemplates = createAsyncThunk(
  'court/editCourtTemplates',
  async ({ stateCode, editData }) => {
    const api =
      stateCode === 'AZ'
        ? `/api/arizonaCourts/${editData.courtId}/templates`
        : `/api/nevadaCourts/${editData.courtId}/templates`;

    const { data } = await apiClient.post(api, editData);
    return data;
  },
);

export const getCourtTemplates = createAsyncThunk(
  'court/getCourtTemplates',
  async ({ stateCode, id }) => {
    const api =
      stateCode === 'AZ'
        ? `/api/arizonaCourts/${id}/templates`
        : `/api/nevadaCourts/${id}/templates`;

    const { data } = await apiClient.get(api);
    return data;
  },
);

export const getCourtsTemplatesAll = createAsyncThunk(
  'court/getCourtsTemplatesAll',
  async ({ stateCode: { value } }) => {
    const api =
      value === 'AZ'
        ? '/api/arizonaCourts/templates/available'
        : '/api/nevadaCourts/templates/available';
    const { data } = await apiClient.get(api);
    return data;
  },
);

export const getCourtTemplatesTypes = createAsyncThunk('court/getCourtTemplatesTypes', async () => {
  const { data } = await apiClient.get(`/api/templates/types`);
  return data;
});

export const deleteCourtTemplate = createAsyncThunk(
  'court/deleteCourtTemplate',
  async (deleteCourtTemplateObj) => {
    const { id, templateId, stateCode } = deleteCourtTemplateObj;

    const api =
      stateCode === 'AZ'
        ? `/api/arizonaCourts/${id}/templates/${templateId}`
        : `/api/nevadaCourts/${id}/templates/${templateId}`;

    const { data } = await apiClient.delete(api);
    return data;
  },
);

export const getCourtTemplatesNoticeTypes = createAsyncThunk(
  'court/getCourtTemplatesNoticeTypes',
  async ({ stateCode }) => {
    const { data } = await apiClient.get(`/api/templates/noticeTypes?stateCode=${stateCode}`);
    return data;
  },
);

export const getCourts = createAsyncThunk('court/getCourts', async (params = {}, thunkAPI) => {
  const {
    data: {
      result: { items, hasNextPage },
    },
  } = await apiClient.post(`/api/courts/filter`, params);

  const {
    courts: { items: prevStateItems = [] },
  } = thunkAPI.getState().editCourt;

  const currentStateItems = items.map((item) => ({
    id: item.courtId,
    value: item.courtName,
    label: item.courtName,
    type: item.type,
  }));

  return params.hasOwnProperty('currentPage')
    ? {
        items: [...prevStateItems, ...currentStateItems],
        hasNextPage,
      }
    : {
        items: [...currentStateItems],
        hasNextPage,
      };
});

export const resetCourts = createAction('court/resetCourts');

export const resetEditCourtSliceState = createAction('court/resetEditCourtSliceState', () => {
  return {
    type: 'court/resetEditCourtSliceState',
  };
});
const initialState = {
  notes: [],
  addresses: {},
  contacts: [],
  managements: [],
  error: null,
  states: [],
  counties: [],
  courts: {},
  addressesAll: {},
  templates: [],
  templatesAll: [],
  templateNoticeTypes: [],
  templateTypes: [],
};

export const editCourtSlice = createSlice({
  name: 'editCourt',
  initialState,
  reducers: {},
  extraReducers: {
    [resetEditCourtSliceState]: (state) => {
      state.counties = [];
      state.states = [];
    },
    [deleteCourtTemplate.fulfilled]: (state, action) => {
      state.error = action.payload;
    },
    [editCourtTemplates.fulfilled]: (state, action) => {
      state.defaultTemplates = action.payload.result;
    },
    [editCourtTemplates.fulfilled]: (state, action) => {
      state.error = action.payload;
    },
    [getCourtTemplates.fulfilled]: (state, action) => {
      state.defaultTemplates = action.payload.result;
    },
    [getCourtTemplatesTypes.fulfilled]: (state, action) => {
      state.templateTypes = action.payload.result.map((item, id) => ({
        id,
        label: item.templateType,
        value: item.templateType,
      }));
    },
    [getCourtTemplatesNoticeTypes.fulfilled]: (state, action) => {
      state.templateNoticeTypes = action.payload.result.map((item, id) => ({
        id,
        label: item.noticeType,
        value: item.noticeType,
      }));
    },
    [getCourtsTemplatesAll.fulfilled]: (state, action) => {
      state.templatesAll = action.payload.result.map((item) => ({
        ...item,
        id: item.templateId,
        value: item.name,
        label: item.name,
      }));
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.addressesAll = action.payload.result;
    },
    [getCourtNotes.fulfilled]: (state, action) => {
      state.notes = action.payload.result;
    },
    [editCourtNotes.fulfilled]: (state, action) => {
      state.notes = action.payload;
    },
    [getCourtAddresses.fulfilled]: (state, action) => {
      state.addresses = action.payload.result;
    },
    [deleteCourt.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCourt.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.result.map((item) => ({
        id: item.code,
        value: item.id,
        label: item.code,
      }));
    },
    [getCounties.fulfilled]: (state, action) => {
      state.counties = action.payload.result.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
      }));
    },
    [editCourt.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCourts.fulfilled]: (state, action) => {
      state.courts = action.payload;
    },
    [getCourts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [resetCourts]: (state) => {
      state.courts = [];
    },
  },
});

export default editCourtSlice.reducer;
