import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Stack, Typography, Container, Box } from '@mui/material';
import ShadowBlock from '../../atoms/ShadowBlock';
import { ReactComponent as DocumentText } from '../../../assets/icons/DocumentText.svg';
import { ReactComponent as DocumentCopy } from '../../../assets/icons/DocumentCopy.svg';
import { palette } from '../../../theme/default';
import SubPageHeading from '../../organisms/SubPageHeading';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const StyledWrapper = ({ title, icon, to, navPage, subNav, subSubNavigation, disabled }) => {
  return (
    <Stack
      component={disabled ? Box : Link}
      to={to}
      state={{
        subSubNavigation,
        navPage,
        subNav,
      }}
      sx={{ textDecoration: 'none', opacity: disabled ? 0.5 : 1 }}
      width="calc((100% - 24px)/2)"
    >
      <ShadowBlock>
        <Stack alignItems="center" spacing={3}>
          <Typography variant="h5" color="text.secondary" align="center" whiteSpace="pre-line">
            {title}
          </Typography>
          {icon}
        </Stack>
      </ShadowBlock>
    </Stack>
  );
};

const ClientPortalNotiesRequestsArizona = () => {
  const base = useBaseUrl();
  return (
    <>
      <SubPageHeading
        subPageHeading="Notice Request"
        nav={{
          to: `/${base}/requests`,
        }}
      />
      <Stack height="80%" margin="0 32px" direction="column">
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            m: 'auto',
            px: 4,
            width: 1,
            maxWidth: 1250,
          }}
        >
          <Stack spacing={3} direction="row" justifyContent="stretch">
            <StyledWrapper
              title={`Non-Payment\n Notice Request`}
              icon={<DocumentText color={palette.buttonPrimary.active} />}
              to="non_payment_notice/AZ"
              navPage="New Requests"
              subNav="Notice Requests"
              subSubNavigation="Non-Payment Notices"
            />
            <StyledWrapper
              title={`Non-Compliance\n Notice Request`}
              icon={<DocumentCopy color={palette.buttonPrimary.active} />}
              to="non_compliance_notice/AZ"
              navPage="New Requests"
              subNav="Notice Requests"
              subSubNavigation="Non-Compliance Notice"
            />
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

StyledWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  navPage: PropTypes.string.isRequired,
  subNav: PropTypes.string.isRequired,
  subSubNavigation: PropTypes.string,
  icon: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

StyledWrapper.defaultProps = {
  subSubNavigation: '',
  disabled: false,
};

export default ClientPortalNotiesRequestsArizona;
