import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { LightTooltip } from '../../../../atoms/MTooltip/MTooltip';

const CommentCell = ({ value }) => {
  return (
    <LightTooltip title={value}>
      <Typography
        textTransform="none"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        fontSize={15}
        fontWeight={500}
      >
        {value}
      </Typography>
    </LightTooltip>
  );
};

CommentCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default CommentCell;
