import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as Add } from '../../../assets/icons/Add.svg';
import defaultImg from '../../../assets/FolderDocs.png';
import MButton from '../../MUI/Button/MButton';

const EmptyBlock = ({ title, desc, buttonText, onClick, width, descFontSize }) => {
  const handleClickButton = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <Stack
      spacing={6}
      width={width || '524px'}
      alignItems="center"
      data-testid="empty_block_wrapper"
    >
      <img src={defaultImg} width="220px" alt="" data-testid="image" />
      <Stack spacing={2}>
        {title && (
          <Typography variant="h4" textAlign="center" data-testid="title">
            {title}
          </Typography>
        )}
        {desc && (
          <Typography
            variant="bodyL500"
            color="text.secondary"
            textAlign="center"
            fontSize={descFontSize && descFontSize}
            data-testid="description"
          >
            {desc}
          </Typography>
        )}
      </Stack>
      {buttonText && (
        <MButton
          data-testid="button"
          variant="primary"
          size="large"
          onClick={handleClickButton}
          startIcon={<Add />}
        >
          {buttonText}
        </MButton>
      )}
    </Stack>
  );
};

EmptyBlock.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  desc: PropTypes.string,
  descFontSize: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

EmptyBlock.defaultProps = {
  title: '',
  width: '',
  desc: '',
  descFontSize: '',
  buttonText: '',
  onClick: () => null,
};

export default EmptyBlock;
