/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const getCaseInformation = createAsyncThunk('casesNevada/getCaseInformation', async (id) => {
  const { data } = await apiClient.get(`/api/nevadaCases/${id}/caseInformation`);
  return data;
});

export const getNevadaLegalAssistants = createAsyncThunk(
  'casesNevada/getNevadaLegalAssistants',
  async (id) => {
    const { data } = await apiClient.get(`/api/nevadaCases/${id}/legalAssistants`);
    return data.result.map(({ userId, firstName, lastName }) => {
      return {
        id: userId,
        label: `${firstName} ${lastName}`,
        value: userId,
      };
    });
  },
);

export const updateNoticeRequest = createAsyncThunk(
  'casesNevada/updateNoticeRequest',
  async ({ id, body }) => {
    const { data } = await apiClient.put(`/api/nevadaCases/${id}/noticeRequest`, body);
    return data;
  },
);

export const updateEvictionRequest = createAsyncThunk(
  'casesNevada/updateEvictionRequest',
  async ({ id, body }) => {
    const { data } = await apiClient.put(`/api/nevadaCases/${id}/evictionRequest`, body);
    return data;
  },
);

export const getCaseViolations = createAsyncThunk('casesNevada/getCaseViolations', async (id) => {
  const { data } = await apiClient.get(`/api/nevadaCases/${id}/violations`);
  return data;
});

export const addCaseViolation = createAsyncThunk(
  'casesNevada/addCaseViolation',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/nevadaCases/${id}/violations`, body);
      return data;
    } catch (error) {
      const errorStatus = error.response.status;
      if (errorStatus === 400) {
        return rejectWithValue(error?.response?.data?.errorMessage);
      }
      return error;
    }
  },
);

export const getCaseProcedularHistoryEntries = createAsyncThunk(
  'casesNevada/getCaseProcedularHistoryEntries',
  async (id) => {
    const { data } = await apiClient.get(`/api/nevadaCases/${id}/proceduralHistoryEntries`);
    return data;
  },
);

export const addCaseProceduralHistoryEntry = createAsyncThunk(
  'casesNevada/addCaseProceduralHistoryEntry',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(
        `/api/nevadaCases/${id}/proceduralHistoryEntries`,
        body,
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const updateCaseProceduralHistoryEntry = createAsyncThunk(
  'casesNevada/addCaseProceduralHistoryEntry',
  async ({ id, proceduralHistoryEntryId, body }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(
        `/api/nevadaCases/${id}/proceduralHistoryEntries/${proceduralHistoryEntryId}`,
        body,
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const removeCaseProceduralHistoryEntry = createAsyncThunk(
  'casesNevada/removeCaseProceduralHistoryEntry',
  async ({ id, proceduralEntryId }) => {
    const { data } = await apiClient.delete(
      `/api/nevadaCases/${id}/proceduralHistoryEntries/${proceduralEntryId}`,
    );
    return data;
  },
);

export const getListOfAllStatusesForCase = createAsyncThunk(
  'casesNevada/getListOfAllStatusesForCase',
  async () => {
    const { data } = await apiClient.get(`/api/nevadaCases/statuses?OnlyManual=true`);
    return data;
  },
);

export const getStatusesForCase = createAsyncThunk('casesNevada/getStatusesForCase', async (id) => {
  const { data } = await apiClient.get(`/api/nevadaCases/${id}/statuses`);

  const caseStatusToRevert = data?.result?.find((item) => {
    const isSystem = item.updatedBy.trim() === 'system';
    const isArchived = item.status === 'Archived';
    return isArchived || !isSystem;
  });

  let statusObj = null;
  if (caseStatusToRevert) {
    statusObj = await apiClient.get(`/api/nevadaCases/${id}/statuses/canRevert`);
  }

  const adoptedStatuses = data?.result.map((item) => {
    if (item.caseStatusId === caseStatusToRevert?.caseStatusId) {
      return {
        ...item,
        caseId: id,
        mayBeReverted: statusObj?.data?.result,
      };
    }
    return { ...item, item, mayBeReverted: false };
  });

  return adoptedStatuses;
});

export const addStatusToCase = createAsyncThunk(
  'casesNevada/addStatusToCase',
  async ({ id, body }) => {
    const { data } = await apiClient.post(`/api/nevadaCases/${id}/statuses`, body);
    return data;
  },
);

export const createCasesFromBulk = createAsyncThunk(
  'casesNevada/createCasesFromBulk',
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('DateServed', data.DateServed);
      formData.append('File', data.File || new Blob(), data.File?.name || '');
      const { data: resData } = await apiClient.post(`/api/nevadaCases/bulk`, formData);

      return resData;
    } catch (error) {
      const errorStatus = error.response.status;
      if (errorStatus === 400) {
        return rejectWithValue(error.response.data.errorMessage);
      }
      return rejectWithValue(error.response.data.errorMessage);
    }
  },
);

export const printNevadaCaseDocument = createAsyncThunk(
  'casesGeneral/printNevadaCaseDocument',
  async (args) => {
    const { id, ...body } = args;
    const { data } = await apiClient.post(`/api/nevadaCases/${id}/documents/print`, body);

    return data;
  },
);

export const getNevadaRelatedCases = createAsyncThunk(
  'casesNevada/getNevadaRelatedCases',
  async ({ caseId }) => {
    const { data } = await apiClient.get(`/api/nevadaCases/${caseId}/relativeCases`);
    return data;
  },
);

export const deleteLastNevadeCaseStatus = createAsyncThunk(
  'casesNevada/deleteLastNevadeCaseStatus',
  async ({ id }) => {
    const { data } = await apiClient.delete(`/api/nevadaCases/${id}/statuses/revert`);
    return data;
  },
);

export const deleteNevadeCaseStatusById = createAsyncThunk(
  'casesNevada/deleteNevadeCaseStatusById',
  async ({ id, caseStatusId }) => {
    const { data } = await apiClient.delete(
      `/api/nevadaCases/${id}/statusHistoryEntries/${caseStatusId}`,
    );
    return data;
  },
);

export const getNevadaViolationTemplatesForCase = createAsyncThunk(
  'casesNevada/getNevadaViolationTemplatesForCase',
  async (caseId) => {
    const { data } = await apiClient.get(`/api/nevadaCases/${caseId}/violations/templates`);
    return data;
    // return {
    //   result: [
    //     {
    //       templateId: 624,
    //       templateName: 'APT - Abandoned Property, Notice (NEW LAW)',
    //       templateType: 'Notice',
    //     },
    //   ],
    // };
  },
);

export const getNevadaNoticeTemplateData = createAsyncThunk(
  'casesNevada/getNevadaNoticeTemplateData',
  async ({ id, templateId }) => {
    const {
      data: { result },
    } = await apiClient.get(`/api/nevadaCases/${id}/templates/${templateId}/fields`);

    // const result = {
    //   fields: [
    //     {
    //       label: 'Exemption request',
    //       key: 'ExemptionRequest',
    //       type: 'checkbox',
    //       required: true,
    //       value: false,
    //     },
    //     {
    //       label: 'Public Sale Date',
    //       key: 'PublicSaleDate',
    //       type: 'date',
    //       required: true,
    //       value: null,
    //     },
    //     {
    //       label: 'Public Sale Time',
    //       key: 'PublicSaleTime',
    //       type: 'time',
    //       required: true,
    //       value: null,
    //     },
    //     {
    //       label: 'Public Sale Lot Number',
    //       key: 'PublicSaleLotNum',
    //       type: 'int',
    //       required: true,
    //       value: null,
    //     },
    //     {
    //       label: 'Minimum bid for the items',
    //       key: 'PublicSaleSum',
    //       type: 'float',
    //       required: true,
    //       value: 0.0,
    //     },
    //   ],
    // };
    return {
      id,
      templateId,
      fields: result.fields,
    };
  },
);

export const createNevadaDocument = createAsyncThunk(
  'casesNevada/createDocument',
  async ({ id, templateId, ...body }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/nevadaCases/${id}/documents/${templateId}`, body);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const initialState = {
  getCaseInformation: null,
  getCaseInformationError: null,
  updateNoticeRequest: null,
  updateNoticeRequestError: null,
  updateEvictionRequest: null,
  updateEvictionRequestError: null,
  getCaseViolations: null,
  getCaseViolationsError: null,
  addCaseViolation: null,
  addCaseViolationError: null,
  getCaseProcedularHistoryEntries: null,
  getCaseProcedularHistoryEntriesError: null,
  addCaseProceduralHistoryEntry: null,
  addCaseProceduralHistoryEntryError: null,
  updateCaseProceduralHistoryEntryError: null,
  getListOfAllStatusesForCase: [],
  getListOfAllStatusesForCaseError: null,
  getStatusesForCase: null,
  getStatusesForCaseError: null,
  addStatusToCase: null,
  addStatusToCaseError: null,
  error: null,
  relatedNevadaCases: null,
  legalAssistants: [],
  violationTemplates: [],
  noticeTemplateData: null,
};

export const casesNevadaSlice = createSlice({
  name: 'casesNevada',
  initialState,
  reducers: {},
  extraReducers: {
    [getNevadaRelatedCases.fulfilled]: (state, action) => {
      state.relatedNevadaCases = action.payload.result;
    },
    [getCaseInformation.fulfilled]: (state, action) => {
      state.getCaseInformation = action.payload;
    },
    [getCaseInformation.rejected]: (state, action) => {
      state.getCaseInformationError = action.payload;
    },
    [updateNoticeRequest.fulfilled]: (state, action) => {
      state.updateNoticeRequest = action.payload;
    },
    [updateNoticeRequest.rejected]: (state, action) => {
      state.updateNoticeRequestError = action.payload;
    },
    [updateEvictionRequest.fulfilled]: (state, action) => {
      state.updateEvictionRequest = action.payload;
    },
    [updateEvictionRequest.rejected]: (state, action) => {
      state.updateEvictionRequestError = action.payload;
    },
    [getCaseViolations.fulfilled]: (state, action) => {
      state.getCaseViolations = action.payload;
    },
    [getCaseViolations.rejected]: (state, action) => {
      state.getCaseViolationsError = action.payload;
    },
    [addCaseViolation.fulfilled]: (state, action) => {
      state.addCaseViolation = action.payload;
    },
    [addCaseViolation.rejected]: (state, action) => {
      state.addCaseViolationError = action.payload;
    },
    [getCaseProcedularHistoryEntries.fulfilled]: (state, action) => {
      state.getCaseProcedularHistoryEntries = action.payload;
    },
    [getCaseProcedularHistoryEntries.rejected]: (state, action) => {
      state.getCaseProcedularHistoryEntriesError = action.payload;
    },
    [addCaseProceduralHistoryEntry.fulfilled]: (state, action) => {
      state.addCaseProceduralHistoryEntry = action.payload;
    },
    [addCaseProceduralHistoryEntry.rejected]: (state, action) => {
      state.addCaseProceduralHistoryEntryError = action.payload;
    },
    [updateCaseProceduralHistoryEntry.rejected]: (state, action) => {
      state.updateCaseProceduralHistoryEntryError = action.payload;
    },
    [getListOfAllStatusesForCase.fulfilled]: (state, action) => {
      state.getListOfAllStatusesForCase = action.payload.result.map(({ value }) => ({
        id: value,
        label: value,
        value,
      }));
    },
    [getListOfAllStatusesForCase.rejected]: (state, action) => {
      state.getListOfAllStatusesForCaseError = action.payload;
    },
    [getStatusesForCase.fulfilled]: (state, action) => {
      state.getStatusesForCase = action.payload;
    },
    [getStatusesForCase.rejected]: (state, action) => {
      state.getStatusesForCaseError = action.payload;
    },
    [addStatusToCase.fulfilled]: (state, action) => {
      state.addStatusToCase = action.payload;
    },
    [addStatusToCase.rejected]: (state, action) => {
      state.addStatusToCaseError = action.payload;
    },
    [printNevadaCaseDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createNevadaDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getNevadaLegalAssistants.fulfilled]: (state, action) => {
      state.legalAssistants = action.payload;
    },
    [getNevadaViolationTemplatesForCase.fulfilled]: (state, action) => {
      state.violationTemplates = action.payload.result.map((t) => ({
        id: t.templateId,
        label: t.templateName,
        type: t.templateType,
        value: t.templateId,
      }));
    },
    [getNevadaNoticeTemplateData.fulfilled]: (state, action) => {
      state.noticeTemplateData = action.payload;
    },
  },
});

export default casesNevadaSlice.reducer;
