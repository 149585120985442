import React from 'react';
import { styled } from '@mui/material';
import { PropTypes } from 'prop-types';

const StyledLabel = styled('label')(({ theme, height }) => ({
  width: 'auto',
  height: height || '48px',
  padding: '0 8px 0 12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: 16,
  borderRadius: '10px 0 0 10px',
  borderLeft: `1px solid ${theme.palette.additional.lines}`,
  borderTop: `1px solid ${theme.palette.additional.lines}`,
  borderBottom: `1px solid ${theme.palette.additional.lines}`,
  whiteSpace: 'nowrap',
}));

const SearchLabel = ({ value, height, htmlFor }) => {
  return (
    <StyledLabel height={height} htmlFor={htmlFor} data-testid="search_label">
      {value}
    </StyledLabel>
  );
};

SearchLabel.propTypes = {
  value: PropTypes.string,
  height: PropTypes.number,
  htmlFor: PropTypes.string,
};

SearchLabel.defaultProps = {
  value: '',
  height: 0,
  htmlFor: '',
};

export default SearchLabel;
