/* eslint-disable */
import { styled } from '@mui/material';
import Button from '@mui/material/Button';

const MButton = styled(Button)(({ theme, variant, size }) => {
  const sizeStyles =
    size === 'small'
      ? {
          borderRadius: '8px',
          height: '28px',
          padding: '4px 16px',
          fontSize: theme.typography.buttonSmall.fontSize,
          fontWeight: theme.typography.buttonSmall.fontWeight,
          lineHeight: theme.typography.buttonSmall.lineHeight,
        }
      : size === 'large'
      ? {
          borderRadius: '10px',
          padding: '12px 24px',
          fontSize: theme.typography.buttonLarge.fontSize,
          fontWeight: theme.typography.buttonLarge.fontWeight,
          lineHeight: theme.typography.buttonLarge.lineHeight,
        }
      : {
          borderRadius: '10px',
          padding: '8px 16px',
          fontSize: theme.typography.buttonSmall.fontSize,
          fontWeight: theme.typography.buttonSmall.fontWeight,
          lineHeight: theme.typography.buttonSmall.lineHeight,
        };

  const variantStyles =
    variant === 'borderLess'
      ? {
          border: 'none',
          color: theme.palette.buttonPrimary.active,
        }
      : variant === 'bordered'
      ? {
          color: theme.palette.buttonPrimary.active,
          border: `1px solid ${theme.palette.buttonPrimary.active}`,
          '&.Mui-disabled': {
            borderColor: theme.palette.buttonSecondary.disabledBorder,
          },
        }
      : variant === 'minimal'
      ? {
          color: theme.palette.text.secondary,
          ':hover': {
            backgroundColor: theme.palette.buttonSecondary.activeBg,
            color: theme.palette.text.primary,
          },
          ':focus': {
            border: `1px solid ${theme.palette.buttonSecondary.activeBorder}`,
            color: theme.palette.text.primary,
          },
          ':active': {
            backgroundColor: theme.palette.buttonSecondary.pressedBg,
            color: theme.palette.text.primary,
          },
          '&.Mui-disabled': {
            color: theme.palette.text.tertiary,
          },
        }
      : variant === 'secondary'
      ? {
          color: theme.palette.text.secondary,
          border: `1px solid ${theme.palette.buttonSecondary.activeBorder}`,
          backgroundColor: theme.palette.buttonSecondary.activeBg,
          ':hover': {
            borderColor: theme.palette.buttonSecondary.hoverBorder,
            color: theme.palette.text.primary,
          },
          ':focus': {
            borderColor: theme.palette.buttonSecondary.hoverBorder,
            color: theme.palette.text.primary,
          },
          ':active': {
            backgroundColor: theme.palette.buttonSecondary.pressedBg,
            borderColor: theme.palette.buttonSecondary.pressedBorder,
            color: theme.palette.text.primary,
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.buttonSecondary.disabledBorder,
            color: theme.palette.text.tertiary,
          },
        }
      : variant === 'additional'
      ? {
          color: theme.palette.buttonPrimary.active,
          border: '1px solid transparent',
          ':hover': {
            color: theme.palette.buttonPrimary.hover,
            backgroundColor: 'transparent',
          },
          ':focus': {
            borderColor: theme.palette.buttonSecondary.activeBorder,
            color: theme.palette.buttonPrimary.active,
            ':hover': {
              color: theme.palette.buttonPrimary.hover,
              backgroundColor: 'transparent',
            },
          },
          ':active': {
            color: theme.palette.buttonPrimary.pressed,
          },
          '&.Mui-disabled': {
            color: theme.palette.buttonPrimary.disabled,
          },
        }
      : {
          backgroundColor: theme.palette.buttonPrimary.active,
          color: theme.palette.common.white,
          ':hover': {
            backgroundColor: theme.palette.buttonPrimary.hover,
          },
          ':active': {
            backgroundColor: theme.palette.buttonPrimary.pressed,
          },
          '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.buttonPrimary.disabled,
          },
        };

  return {
    textTransform: 'none',
    ...variantStyles,
    ...sizeStyles,
  };
});

export default MButton;
