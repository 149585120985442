export const subPageNavNew = [
  {
    label: 'Community',
    to: '/db/datahandling/newcommunity/community',
    state: {
      navPage: 'Data Handling',
      subNav: 'Communities',
    },
  },
  {
    label: 'Address',
    to: '/db/datahandling/newcommunity/address',
    state: {
      navPage: 'Data Handling',
      subNav: 'Communities',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/newcommunity/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Communities',
    },
  },
];

export const subPageNavEdit = [
  {
    label: 'Community',
    to: '/db/datahandling/editcommunity/community',
    state: {
      navPage: 'Data Handling',
      subNav: 'Communities',
    },
  },
  {
    label: 'Address',
    to: '/db/datahandling/editcommunity/address',
    state: {
      navPage: 'Data Handling',
      subNav: 'Communities',
    },
  },
  {
    label: 'Documents',
    to: '/db/datahandling/editcommunity/documents',
    state: {
      navPage: 'Data Handling',
      subNav: 'Communities',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/editcommunity/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Communities',
    },
  },
];
