export const userInformationBoxes = [
  {
    label: 'First Name',
    name: 'firstName',
    placeholder: 'Enter first name',
    width: '326px',
    isDropdown: false,
    isMandatory: true,
    isDisabled: false,
    isDate: false,
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Enter last name',
    width: '326px',
    isDropdown: false,
    isMandatory: true,
    isDisabled: false,
    isDate: false,
  },
  {
    label: 'User name',
    name: 'userName',
    placeholder: 'Enter user name',
    width: '326px',
    isDropdown: false,
    isMandatory: true,
    isDisabled: false,
    isDate: false,
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Enter email',
    width: '326px',
    isDropdown: false,
    isMandatory: true,
    isDisabled: false,
    isDate: false,
  },
  {
    label: 'Role',
    name: 'role',
    placeholder: 'Select role',
    width: '326px',
    isDropdown: true,
    isMandatory: true,
    tooltip:
      'Need Admin access? Email your request to credentials@clarkwalker.com. Please note this will give you access to the entire portfolio for your management company. You will receive updates for all properties associated with the portfolio.',
    isDisabled: true,
    isDate: false,
  },
  {
    label: 'Title',
    name: 'title',
    placeholder: 'Enter title',
    width: '326px',
    isMandatory: true,
  },
  {
    label: 'Work Number',
    name: 'workPhone',
    placeholder: 'Enter work number',
    width: '326px',
    isMandatory: true,
  },
  {
    label: 'Mobile Number',
    name: 'mobilePhone',
    placeholder: 'Enter mobile number',
    width: '326px',
  },
  {
    label: 'Password',
    name: 'password',
    placeholder: 'Enter password',
    width: '326px',
    isPassword: true,
    isDropdown: false,
    isMandatory: true,
    isDisabled: false,
    isDate: false,
    tooltip:
      'Please take note or remember the password you have provided, because of security reasons no one else will be able to look it up later!',
  },
  {
    label: 'Confirm Password',
    name: 'confirmPassword',
    placeholder: 'Enter password',
    width: '326px',
    isPassword: true,
    isDropdown: false,
    isMandatory: true,
    isDisabled: false,
    isDate: false,
  },
  {
    label: 'State',
    name: 'states',
    placeholder: '-',
    width: '158px',
    isDropdown: false,
    isDropdownMultiChip: true,
    isMandatory: true,
    isDisabled: false,
    isDate: false,
  },
  {
    label: 'Primary State',
    name: 'primaryStateCode',
    placeholder: '-',
    width: '326px',
    isDropdown: true,
  },
  {
    label: 'Management Company',
    name: 'management',
    placeholder: 'Select management company',
    width: '326px',
    isDropdown: true,
    isMandatory: true,
    isDisabled: true,
    isDate: false,
  },
];
