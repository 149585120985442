/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getManagementNotes = createAsyncThunk(
  'editManagement/getManagementNote',
  async (id) => {
    const { data } = await apiClient.get(`/api/managements/${id}/notes`);
    return data;
  },
);

export const editManagementNotes = createAsyncThunk(
  'editManagement/editManagementNote',
  async (editManagementNotesObj) => {
    const { id, ...fields } = editManagementNotesObj;
    const { data } = await apiClient.post(`/api/managements/${id}/notes`, fields);
    return data;
  },
);

export const getManagement = createAsyncThunk('editManagement/getManagement', async (id) => {
  const { data } = await apiClient.get(`/api/managements/${id}`);
  return data;
});

export const getStates = createAsyncThunk('editManagement/states', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data;
});

export const getContactPersons = createAsyncThunk(
  'editManagement/getContactPersons',
  async (params) => {
    const { data } = await apiClient.post(`/api/contacts/filter`, {
      ...params,
    });
    return data;
  },
);

export const getManagementCommunitiesAll = createAsyncThunk(
  'editManagement/getManagementCommunitiesAll',
  async (obj) => {
    const { data } = await apiClient.post(`/api/communities/filter`, obj);
    return data;
  },
);

export const getManagementAddresses = createAsyncThunk(
  'editManagement/getManagementAddresses',
  async (obj) => {
    const { id, ...fields } = obj;
    const { data } = await apiClient.post(`/api/managements/${id}/addresses/filter`, fields);
    return data;
  },
);

export const getAddressesAll = createAsyncThunk(
  'editManagement/getAddressesAll',
  async (params) => {
    const { data } = await apiClient.post(`/api/addresses/filter`, params);
    return data;
  },
);

export const editManagementAddresses = createAsyncThunk(
  'editManagement/editManagementAddresses',
  async (editCommunityAddressesObj, { rejectWithValue }) => {
    try {
      const { id, ...fields } = editCommunityAddressesObj;
      const { data } = await apiClient.post(`/api/managements/${id}/addresses`, fields);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resetEditManagementAddressesError = createAction(
  'editManagement/resetEditManagementAddressesError',
  () => ({
    type: 'management/resetEditManagementAddressesError',
  }),
);

export const deleteManagementContactPerson = createAsyncThunk(
  'editManagement/deleteManagementContactPerson',
  async (args) => {
    const { id, contactId } = args;
    const { data } = await apiClient.delete(`/api/managements/${id}/contacts/${contactId}`);
    return data;
  },
);

export const editManagementContacts = createAsyncThunk(
  'editManagement/editManagementContacts',
  async (editData) => {
    const { managementId } = editData;
    const { data } = await apiClient.post(`/api/managements/${managementId}/contacts`, editData);
    return data;
  },
);

export const getManagementContacts = createAsyncThunk(
  'editManagement/getManagementContacts',
  async (id) => {
    const { data } = await apiClient.get(`/api/managements/${id}/contacts`);

    return data;
  },
);

export const editManagementCommunities = createAsyncThunk(
  'editManagement/editManagementCommunities',
  async (editData, { rejectWithValue }) => {
    try {
      const { id, ...rest } = editData;
      const { data } = await apiClient.post(`/api/managements/${id}/communities`, rest);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resetEditManagementCommunities = createAction(
  'address/resetEditManagementCommunities',
  () => ({
    type: 'address/resetEditManagementCommunities',
  }),
);

export const deleteManagementCommunities = createAsyncThunk(
  'editManagement/deleteManagementCommunities',
  async (editData) => {
    const { id, ...rest } = editData;
    const { data } = await apiClient.delete(`/api/managements/${id}/communities`, rest);
    return data;
  },
);

export const editManagement = createAsyncThunk(
  'editManagement/deleteManagementCommunities',
  async (editData, { rejectWithValue }) => {
    const { id, ...rest } = editData;
    try {
      const { data } = await apiClient.put(`/api/managements/${id}`, rest);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const getManagementCommunities = createAsyncThunk(
  'editManagement/deleteManagementCommunities',
  async (editData) => {
    const { id, ...rest } = editData;
    const { data } = await apiClient.post(`/api/managements/${id}/communities/filter`, rest);
    return data;
  },
);

export const deleteManagementCommunity = createAsyncThunk(
  'editManagement/deleteManagementCommunity',
  async (deleteManagementCommunityObj) => {
    const { id, ...fields } = deleteManagementCommunityObj;
    const { data } = await apiClient.delete(`/api/managements/${id}/communities`, {
      data: fields,
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  },
);

export const createNewManagement = createAsyncThunk(
  'editManagement/createNewManagement',
  async (newManagement, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/managements`, newManagement);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

const initialState = {
  editableManagement: {},
  editableManagementContacts: [],
  contactPersons: [],
  managementCommunitesAll: [],
  managementCommunites: {},
  managementAddresses: [],
  addressesAll: [],
  notes: [],
  states: [],
  managementAddressesError: null,
  editManagementCommunitiesError: null,
};

export const editManagementSlice = createSlice({
  name: 'editManagement',
  initialState,
  reducers: {},
  extraReducers: {
    [createNewManagement.fulfilled]: (state, action) => {
      state.error = action.payload;
    },
    [getManagementCommunities.fulfilled]: (state, action) => {
      state.managementCommunites = action.payload.result;
    },
    [deleteManagementCommunity.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editManagement.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteManagementContactPerson.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteManagementCommunities.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editManagementAddresses.rejected]: (state, action) => {
      state.managementAddressesError = action.payload.response.data;
    },
    [resetEditManagementAddressesError]: (state) => {
      state.managementAddressesError = null;
    },
    [editManagementContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getManagementContacts.fulfilled]: (state, action) => {
      state.editableManagementContacts = action.payload.result;
    },
    [getManagementContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editManagementCommunities.fulfilled]: (state, action) => {
      state.addressesAll = action.payload.result;
    },
    [editManagementCommunities.rejected]: (state, action) => {
      state.editManagementCommunitiesError = action.payload;
    },
    [resetEditManagementCommunities]: (state) => {
      state.editManagementCommunitiesError = null;
    },
    [getAddressesAll.fulfilled]: (state, action) => {
      state.addressesAll = action.payload.result;
    },
    [getAddressesAll.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getManagementAddresses.fulfilled]: (state, action) => {
      state.managementAddresses = action.payload.result;
    },
    [getManagementAddresses.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getManagementCommunitiesAll.fulfilled]: (state, action) => {
      state.managementCommunitesAll = action.payload.result;
    },
    [getManagementCommunitiesAll.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getContactPersons.fulfilled]: (state, action) => {
      state.contactPersons = action.payload.result;
    },
    [getContactPersons.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.result.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.code,
      }));
    },
    [getStates.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getManagement.fulfilled]: (state, action) => {
      state.editableManagement = action.payload.result;
    },
    [getManagement.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getManagementNotes.fulfilled]: (state, action) => {
      state.notes = action.payload.result;
    },
    [getManagementNotes.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editManagementNotes.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default editManagementSlice.reducer;
