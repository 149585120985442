/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getTypeofEntity = (path) => {
  if (path.includes('editownerentity')) return 'ownerEntities';
  if (path.includes('editmanagement')) return 'managements';
  if (path.includes('editcommunity')) return 'communities';
  if (path.includes('editcourt')) return 'courts';
  if (path.includes('editaddress')) return 'addresses';
  return '';
};

export const getKeyId = (entityName) => {
  switch (entityName) {
    case 'ownerEntities':
      return 'ownerEntityId';
    case 'managements':
      return 'managementId';
    case 'communities':
      return 'communityId';
    case 'addresses':
      return 'addressId';
    case 'courts':
      return 'courtId';
    default:
      return '';
  }
};
export const uploadDocument = createAsyncThunk(
  'documents/uploadDocument',
  async ({ id: entityId, data: filesToUpload, typeofEntity }) => {
    const formData = new FormData();

    filesToUpload.forEach((item, index) => {
      formData.append(`request[${index}].documentName`, item.name);
      formData.append(`request[${index}].type`, item.type);
      formData.append(`request[${index}].file`, item.file);
    });

    const { data } = await apiClient.post(
      `/api/${typeofEntity}/${entityId}/flowdocuments/upload`,
      formData,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );

    return data;
  },
);

export const deleteDocument = createAsyncThunk('documents/deleteDocument', async (args) => {
  const { id, typeofEntity, ...rest } = args;
  const keyId = getKeyId(typeofEntity);
  const parameters = { [keyId]: id, ...rest };
  const { data } = await apiClient.delete(`/api/${typeofEntity}/${id}/flowdocuments`, {
    data: parameters,
    headers: { 'Content-Type': 'application/json' },
  });

  return data;
});

export const getDocument = createAsyncThunk(
  'documents/getDocument',
  async ({ typeofEntity, documentId, id }) => {
    const { data } = await apiClient.get(`/api/${typeofEntity}/${id}/flowdocuments/${documentId}`);

    return data;
  },
);

export const getDocuments = createAsyncThunk('documents/getDocuments', async (params) => {
  const { id, typeofEntity, ...rest } = params;
  const keyId = getKeyId(typeofEntity);
  const parameters = { [keyId]: id, ...rest };
  const { data } = await apiClient.post(
    `/api/${typeofEntity}/${id}/flowdocuments/filter`,
    parameters,
  );
  return data;
});

export const updateDocument = createAsyncThunk(
  'documents/updateDocument',
  async ({ id, documentId, typeofEntity, ...rest }) => {
    const keyId = getKeyId(typeofEntity);
    const updateBody = { [keyId]: id, flowDocumentId: documentId, ...rest };
    const { data } = await apiClient.put(
      `/api/${typeofEntity}/${id}/flowdocuments/${documentId}`,
      updateBody,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );

    return data;
  },
);

export const printDocument = createAsyncThunk('documents/printDocument', async (args) => {
  const { id: documentId, typeofEntity } = args;
  const { data } = await apiClient.put(`/api/${typeofEntity}/flowdocuments/${documentId}/print`);

  return data;
});

export const printDocuments = createAsyncThunk('documents/printDocuments', async (args) => {
  const { typeofEntity, documentIds: flowDocumentIds } = args;
  const { data } = await apiClient.put(`/api/${typeofEntity}/flowdocuments/print`, {
    flowDocumentIds,
  });

  return data;
});

const initialState = {
  documents: {},
  document: null,
  error: null,
};

export const casesSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: {
    [getDocuments.fulfilled]: (state, action) => {
      state.documents = action.payload.result;
    },
    [getDocuments.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [uploadDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getDocument.fulfilled]: (state, action) => {
      state.document = action.payload;
    },
    [getDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default casesSlice.reducer;
