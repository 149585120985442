export const isPasswordValid = (password) =>
  Boolean(/^(?=(?:[^A-Z]*[A-Z]))(?=\D*\d).{8,}$/.test(password));
export const invalidPasswordErrorMessage =
  'Your password needs to contain at least one numerical, one upper case and consist of at least 8 characters!';

export const isEmailValid = (email) =>
  Boolean(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(
      email,
    ),
  );
export const invalidEmailErrorMessage = 'The Email field is not a valid e-mail address.';
export const invalidCommunitiesAndAddressesMessage = 'Add at least one community or address';
