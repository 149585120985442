import React, { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DashboardLegalAssistantCard from './DashboardAttorneyCard';
import { cardsListByState } from './DashboardAttorney.constants';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { getDashboardStats } from '../../../../store/slices/attorneyDashboardSlice';
import DashboardAttorneyList from './DashboardAttorneyList';
import { ATTORNEYS_EXTRA_OPTION } from '../../Reports/ProductivityByAttorney/ProductivityByAttorney.constants';
import useAuth from '../../../../hooks/useAuth';
import { rolesDB } from '../../../../utils/roleHelpers';

const DashboardAttorneyLists = ({ state }) => {
  const [activeCard, setActiveCard] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [coverLoader, setCoverLoader] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const { stats } = useSelector((store) => store.attorneyDashboard);
  const dispatch = useDispatch();
  const cards = useMemo(() => cardsListByState[state?.value], [state?.value]);
  const { userInfo } = useAuth();
  const isCurrentUserAttorney = userInfo.roles.includes(rolesDB.Attorney);

  const loadStats = () => {
    setIsLoader(true);
    dispatch(
      getDashboardStats({
        stateCode: state?.value,
        cards,
        // maybe in the future we'll need to check total by default attorney
        // ...(isCurrentUserAttorney && { attorney: userInfo.UserGuid }),
      }),
    ).finally(() => setIsLoader(false));
  };

  const onCardClick = (id) => () => {
    setCoverLoader(true);
    setActiveCard(id);
  };

  useEffect(() => {
    loadStats();
    onCardClick(cards[0].id)();
  }, [state?.value, cards]);

  return (
    <Stack direction="row" columnGap={3} alignItems="stretch" minHeight="762px">
      <Stack
        rowGap={2}
        position="relative"
        direction="column"
        minHeight="230px"
        flexWrap="wrap"
        width="328px"
      >
        {isLoader && (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            zIndex={10}
            transform="translate(-50%, -50%)"
          >
            <CircularProgress />
          </Box>
        )}
        {cards.map(({ title, icon, id, isDisabled, base }) => (
          <DashboardLegalAssistantCard
            key={id}
            isActive={activeCard === id}
            onClick={onCardClick(id)}
            title={title}
            icon={icon}
            count={stats?.[id] ?? 0}
            base={base}
            isDisabled={isLoader || isDisabled || activeCard === id}
          />
        ))}
      </Stack>
      {activeCard ? (
        <DashboardAttorneyList
          isLoader={listLoader}
          setIsLoader={setListLoader}
          stateCode={state?.value}
          type={activeCard}
          setCoverLoader={setCoverLoader}
          coverLoader={coverLoader}
          defaultAttorneyId={
            isCurrentUserAttorney ? userInfo.UserGuid : ATTORNEYS_EXTRA_OPTION.value
          }
        />
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <EmptyBlock title="Please select a card" />
        </Box>
      )}
    </Stack>
  );
};

DashboardAttorneyLists.propTypes = {
  state: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default DashboardAttorneyLists;
