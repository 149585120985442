import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const LoaderCover = ({ isFixed }) => (
  <Box
    position={isFixed ? 'fixed' : 'absolute'}
    zIndex={isFixed ? 15 : 10}
    width="100%"
    height="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    top="0"
    bottom="0"
    left="0"
    right="0"
    backgroundColor={isFixed ? 'rgba(0,0,0,.2)' : 'none'}
  >
    <CircularProgress size={isFixed ? 50 : 40} data-testid="circular_progress" />
  </Box>
);

LoaderCover.propTypes = {
  isFixed: PropTypes.bool,
};
LoaderCover.defaultProps = {
  isFixed: false,
};

export default LoaderCover;
