import { MakeActiveOrDownloadCell } from './TableCells/MakeActiveOrDownloadCell';
import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';
import { DateCell } from './TableCells/DateCell';
import { ActiveCell } from './TableCells/ActiveCell';

export const FIVE_MEGABYTES_IN_BYTES = 5242880;

export const MANUALLY_GENERATED_RADIO_BUTTONS = [
  { label: 'Yes', value: true, isDefault: true },
  { label: 'No', value: false },
];

export const documentTemplateHistoryColumns = [
  {
    Header: 'Document Name',
    accessor: 'name',
    width: 40,
  },
  {
    Header: 'Version #',
    accessor: 'version',
    width: 25,
  },
  {
    Header: 'Uploaded Date',
    accessor: 'uploadedDate',
    width: 15,
    Cell: DateCell,
  },
  {
    Header: 'Active',
    accessor: 'isActive',
    width: 15,
    Cell: ActiveCell,
  },
  {
    Header: '',
    accessor: 'control',
    width: 11,
    Cell: MakeActiveOrDownloadCell,
  },
];

export const documentsLabelAndDropDown = {
  label: 'Template History',
  dropdownLabel: 'Select state',
  options: [
    { value: 'AZ', label: 'AZ' },
    { value: 'NV', label: 'NV' },
  ],
};

export const documentUploadsTableColumns = [
  {
    Header: 'Document Name',
    accessor: 'documentName',
    width: 94,
  },
  {
    Header: '',
    accessor: 'control',
    width: 6,
    Cell: DeleteCell,
  },
];

export const COURTS_PAGE_SIZE = 10;
