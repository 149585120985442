import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { entityTypes } from './ClientBillingNameCell';

const RangeCell = ({ value, row, rowClick }) => {
  const handleRowClick = () => {
    if (row.original.type !== entityTypes.MANAGEMENT) return;
    rowClick({ value: row.original.managementName });
  };

  return (
    <Typography
      sx={{
        cursor: row.original.type === entityTypes.MANAGEMENT && 'pointer',
        fontWeight: row.original.type === entityTypes.MANAGEMENT ? 600 : 500,
        fontSize: row.original.type === entityTypes.MANAGEMENT ? '18px' : '16px',
      }}
      onClick={handleRowClick}
      data-testid="range_cell"
    >
      {value}
    </Typography>
  );
};

RangeCell.propTypes = {
  value: PropTypes.number,
  row: PropTypes.shape({
    original: PropTypes.shape({
      expanded: PropTypes.bool,
      visible: PropTypes.bool,
      managementName: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
  rowClick: PropTypes.func,
};

RangeCell.defaultProps = {
  value: '',
  rowClick: null,
};

export default RangeCell;
