import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import print from 'print-js';
import DocumentsPopup from '../../../organisms/Document/MDocumentsPopup';

import {
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
  documentStrings,
  uploadDocumentsColumns,
  documentsColumnsDB,
} from './Document.constants';

import Table from '../../../atoms/Table/Table';
import Form from '../../../atoms/Form';
import { palette } from '../../../../theme/default';
import UploadDocument from './UploadDocument';
import {
  getDocuments as getDocumentsDB,
  deleteDocument,
  uploadDocument as uploadDocumentDB,
  updateDocument,
  getTypeofEntity,
  printDocuments,
} from '../../../../store/slices/documentsSlice';
import { apiClient } from '../../../../lib/apiClient';
import { convertToBinaryFileUrl } from '../../../../utils/binaryHelpers';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import notificationUtils from '../../../../utils/notificationUtils';
import LoaderCover from '../../../atoms/LoaderCover';
import Dropzone from '../../../atoms/Dropzone';
import { DocumentSection } from './DocumentSection';
import FullscreenLoaderWithText from '../../../atoms/FullscreenLoaderWithText/FullscreenLoaderWithText';
import MButton from '../../../MUI/Button/MButton';

// TODO check apis fields
const documentsMapper = (item) => ({
  ...item,
  uploadDate: moment(item.createdDate).format('L'),
  uploadTime: moment(item.createdDate).format('LT'),
  uploadedBy: item.createdBy,
});

// This component is a common component if apis will be the same with different body only
const Document = () => {
  const [isDragAndDrop, setIsDrag] = useState(true);
  const [uploadFilesData, setUploadFilesData] = useState([]);
  const [selectedRowIndexes, setSelectedRowIndexes] = useState([]);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [selectedDocNames, setSelectedDocNames] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const { documents: documentsDB } = useSelector((state) => state.documentsDataHandling);
  const [isPrinting, setIsPrinting] = useState(false);
  const timer = useRef();
  const {
    pathname,
    state: { id: entityItemId, stateCode },
  } = useLocation();

  const typeofEntity = getTypeofEntity(pathname);

  const canDeleteDocument = useAllowed([rolesDB.Admin]);

  const { documents, getDocuments, uploadDocument } = useMemo(() => {
    return {
      documents: documentsDB,
      getDocuments: getDocumentsDB,
      uploadDocument: uploadDocumentDB,
    };
  }, [documentsDB, stateCode]);

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: { isLeaseUnavailable: false, isLedgerUnavailable: false },
  });

  const formUploads = useForm({
    defaultValues: {
      uploadDocumentsArray: [],
    },
  });

  const uploadDocumentsArray = useFieldArray({
    control: formUploads.control,
    name: 'uploadDocumentsArray',
  });

  const documentsArray = useFieldArray({
    control: form.control,
    name: 'documents',
    keyName: 'formId',
  });

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    uploadDocumentsArray.remove();
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      uploadDocumentsArray.replace(
        acceptedFiles.map((file) => ({
          name: file.name,
          type: 'Notice',
          file,
        })),
      );
    },
    [uploadDocumentsArray],
  );

  useEffect(() => {
    form.reset({
      documents: documents?.items?.map((item) => documentsMapper(item)),
    });
  }, [documents]);

  useEffect(() => {
    setIsDrag(!uploadDocumentsArray.fields.length);
  }, [uploadDocumentsArray.fields.length]);

  useEffect(() => {
    if (uploadFilesData.length) {
      setIsDocumentsLoading(true);
      dispatch(uploadDocument({ id: entityItemId, data: uploadFilesData, typeofEntity })).then(
        (response) => {
          if (response.error) {
            notificationUtils.error('Failed, try again later');
            setIsDocumentsLoading(false);
          } else {
            notificationUtils.success('Uploaded successfully');
            dispatch(
              getDocuments({
                id: entityItemId,
                pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
                typeofEntity,
              }),
            ).then(() => {
              setIsDocumentsLoading(false);
            });
          }
        },
      );
    }
  }, [uploadFilesData]);

  useEffect(() => {
    dispatch(
      getDocuments({ id: entityItemId, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE, typeofEntity }),
    ).then(() => {
      setIsLoader(false);
    });

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const handleUploadDocuments = () => {
    const values = formUploads.watch();
    setUploadFilesData(values.uploadDocumentsArray);
    handleClose();
  };

  const dragAndDrop = (
    <Dropzone
      onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
      multiple
      customContent={
        <>
          <Typography variant="bodyL500" color="text.secondary" sx={{ my: 1 }}>
            {documentStrings.upload.text}
            <Typography
              variant="bodyL"
              sx={{
                color: 'buttonPrimary.active',
                textDecoration: 'underline',
                marginLeft: 0.5,
              }}
            >
              {documentStrings.upload.action}
            </Typography>
          </Typography>
          <Typography variant="bodyXS" color="text.secondary" sx={{ my: 1 }}>
            {documentStrings.upload.additionalInfo}
          </Typography>
        </>
      }
    />
  );

  const uploadTable = (
    <Form form={formUploads}>
      <Box
        sx={{
          overflowY: 'scroll',
          maxHeight: '240px',
          width: '732px',
          margin: '32px 0',
          '&::-webkit-scrollbar': {
            width: '4px',
            marginLeft: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: palette.additional.lines,
          },
          '&::-webkit-scrollbar-thumb': {
            background: palette.additional.thumb,
          },
        }}
      >
        <Table
          loading={false}
          columns={uploadDocumentsColumns}
          rows={uploadDocumentsArray.fields}
          onUpdateData={uploadDocumentsArray.update}
          onDeleteRow={uploadDocumentsArray.remove}
        />
      </Box>
    </Form>
  );

  const handleChangeDocumentTypeInPopup = (event) => {
    if (event.target?.files?.length) {
      const file = event.target.files[0];
      uploadDocumentsArray.prepend({
        name: file.name,
        type: 'Notice',
        file,
      });
    }
  };

  const handleCheckedRow = (documentRows, ids) => {
    const selectedDocIds = documentRows.map((item) => +item.documentId);
    const documentNames = documentRows.map((item) => item.documentName);
    setSelectedRowIndexes(ids);
    setSelectedDocumentIds(selectedDocIds);
    setSelectedDocNames(documentNames);
  };

  const handleDeleteDocuments = async () => {
    setIsDocumentsLoading(true);
    const res = await dispatch(
      deleteDocument({
        id: entityItemId,
        selectedDocuments: [
          ...selectedDocumentIds.map((selectedDocumentId) => ({ id: selectedDocumentId })),
        ],
        removeAllDocuments: documents?.totalRowsCount === selectedRowIndexes.length,
        typeofEntity,
      }),
    );
    if (res.error) {
      notificationUtils.error('Failed, try again later');
      setIsDocumentsLoading(false);
    } else {
      notificationUtils.success('Deleted successfully');

      dispatch(
        getDocuments({
          id: entityItemId,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          typeofEntity,
        }),
      ).then(() => {
        setIsDocumentsLoading(false);
      });
    }
  };

  const printAsOne = (e) => {
    e.stopPropagation();
    if (!selectedDocumentIds.length) return;

    const isDifferentFromPdf = selectedDocNames.some((item) => !item.includes('.pdf'));
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (isDifferentFromPdf) {
      setIsDocumentsLoading(true);
      timer.current = setTimeout(() => setIsDocumentsLoading(false), 1000);
    } else {
      if (selectedDocumentIds.length > 10) {
        notificationUtils.error(
          <>
            Number of files exceeds 10. Kindly <br />
            select a smaller number of documents
          </>,
        );
        return;
      }
      setIsPrinting(true);

      apiClient
        .post(`/api/${typeofEntity}/flowdocuments/concat`, { documentIds: selectedDocumentIds })
        .then((response) => {
          const {
            data: { result },
          } = response;
          const { contentType, content } = result;

          const fileURL = convertToBinaryFileUrl({ contentType, content });

          print({
            printable: fileURL,
            onPrintDialogClose: () => {
              dispatch(
                printDocuments({
                  documentIds: selectedDocumentIds,
                  typeofEntity,
                }),
              ).then(() => {
                setIsPrinting(false);
              });
            },
          });
        })
        .finally(() => {
          setIsPrinting(false);
        });
    }
  };

  const cancelPrinting = () => {
    setSelectedDocumentIds([]);
    setIsPrinting(false);
  };

  const handleFetchDataPage = (currentPage) => {
    setIsDocumentsLoading(true);
    dispatch(
      getDocuments({
        id: entityItemId,
        currentPage,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        typeofEntity,
      }),
    ).then(() => {
      setIsDocumentsLoading(false);
    });
  };

  const handleChangeDocumentType = (editableRowIndex, editableRow) => {
    dispatch(
      updateDocument({
        id: entityItemId,
        documentId: editableRow.documentId,
        type: editableRow.type,
        typeofEntity,
      }),
    ).then(() => {
      notificationUtils.success('Updated successfully');
      documentsArray.update(editableRowIndex, editableRow);
    });
  };

  const handleChangeDocumentName = useCallback(
    async ({ documentId, documentName }) => {
      await dispatch(
        updateDocument({
          id: entityItemId,
          documentId,
          name: documentName,
          typeofEntity,
        }),
      ).then(() => {
        notificationUtils.success('Updated successfully');
      });
    },
    [entityItemId, documentsArray],
  );

  if (isLoader) return <LoaderCover />;

  return (
    <>
      {isPrinting && (
        <FullscreenLoaderWithText
          title={
            <>
              Bulk document is generating.
              <br /> Please wait
            </>
          }
          description={
            selectedDocumentIds.length ? `(${selectedDocumentIds.length} document(s))` : ''
          }
          actions={
            <MButton size="large" onClick={cancelPrinting}>
              Cancel
            </MButton>
          }
        />
      )}
      {!isDocumentsLoading && !documentsArray.fields.length ? (
        <UploadDocument handleOpen={handleOpen} />
      ) : (
        <Form form={form} height="100%">
          <DocumentSection
            columns={documentsColumnsDB}
            disabledUpload={isLoader}
            handleOpenUpload={handleOpen}
            handleChangeDocumentName={handleChangeDocumentName}
            rows={documentsArray.fields}
            isDocumentsLoading={isDocumentsLoading}
            handleChangeDocumentType={handleChangeDocumentType}
            handleCheckedRow={handleCheckedRow}
            isPagination={documents?.totalPages > 1}
            handleFetchDataPage={handleFetchDataPage}
            totalRowsCount={documents?.totalRowsCount}
            handlePrintDocuments={printAsOne}
            selectedDocumentIds={selectedDocumentIds}
            canDeleteDocument={canDeleteDocument}
            handleDeleteDocuments={handleDeleteDocuments}
          />
        </Form>
      )}
      <DocumentsPopup
        open={open}
        handleChange={handleChangeDocumentTypeInPopup}
        handleClose={handleClose}
        isDragAndDrop={isDragAndDrop}
        dragAndDrop={dragAndDrop}
        uploadTable={uploadTable}
        uploadHandler={handleUploadDocuments}
        documentStrings={documentStrings}
      />
    </>
  );
};

export default Document;
