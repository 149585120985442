import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Tag from '../../atoms/Tag';
import { ALL_STATUSES } from '../../../global/constants';
import useAuth from '../../../hooks/useAuth';
import { LightTooltip } from '../../atoms/MTooltip/MTooltip';

const CaseStatus = ({ value: status }) => {
  const { userInfo } = useAuth();
  const isDB = !userInfo.isClientPortalUser;
  const statusOption = ALL_STATUSES.find((o) => o.value === status);
  const tooltipText = isDB ? statusOption?.tooltipDB : statusOption?.tooltipCP;
  const textColor = statusOption?.textColor;

  const backgroundColor = useMemo(() => {
    if (!status) return 'transparent';
    if (statusOption) return statusOption.color;
    return 'transparent';
  }, [status]);

  const tagWithBgColor = <Tag backgroundColor={backgroundColor} text={status} color={textColor} />;

  if (tooltipText) {
    return (
      <LightTooltip title={tooltipText} placement="top">
        <div>{tagWithBgColor}</div>
      </LightTooltip>
    );
  }
  return tagWithBgColor;
};

CaseStatus.propTypes = {
  value: PropTypes.string.isRequired,
};

export default CaseStatus;
