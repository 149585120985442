/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getAvailableRoles = createAsyncThunk('adminRoles/getAvailableRoles', async () => {
  const { data } = await apiClient.get(`/api/roles`);
  return data;
});

export const getUsersForRole = createAsyncThunk('adminRoles/getUsersForRole', async (id) => {
  const { data } = await apiClient.get(`/api/roles/${id}/users`);
  return data;
});

export const addUserForRole = createAsyncThunk('adminRoles/addUserForRole', async (params) => {
  const { id, userId } = params;
  const { data } = await apiClient.post(`/api/roles/${id}/users`, { userId });
  return data;
});

export const deleteUserFromRole = createAsyncThunk(
  'adminRoles/deleteUserFromRole',
  async (params) => {
    const { id, ...rest } = params;
    const { data } = await apiClient.delete(`/api/roles/${id}/users`, {
      data: rest,
      headers: { 'Content-Type': 'application/json' },
    });
    return data;
  },
);

export const getAllUsers = createAsyncThunk('adminRoles/getAllUsers', async (params) => {
  const { data } = await apiClient.post(`/api/users/filter`, {
    ...params,
    headers: { 'Content-Type': 'application/json' },
  });
  return {
    ...data.result,
    items: data.result.items.map((item) => ({
      id: item.userGuid,
      value: `${item.firstName} ${item.lastName}`,
      label: `${item.firstName} ${item.lastName}`,
    })),
  };
});

export const resetRoleUsers = createAction('adminRoles/resetRoleUsers', () => ({
  type: 'adminRoles/resetRoleUsers',
}));

const initialState = {
  allUsers: [],
  roles: [],
  roleUsers: [],
};

export const adminRolesSlice = createSlice({
  name: 'adminRoles',
  initialState,
  reducers: {},
  extraReducers: {
    [resetRoleUsers]: (state) => {
      state.roleUsers = [];
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.allUsers = action.payload;
    },
    [getAvailableRoles.fulfilled]: (state, action) => {
      state.roles = action.payload.result;
    },
    [getAvailableRoles.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getUsersForRole.fulfilled]: (state, action) => {
      state.roleUsers = action.payload.result;
    },
    [getUsersForRole.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [addUserForRole.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteUserFromRole.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default adminRolesSlice.reducer;
