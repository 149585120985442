/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getCases = createAsyncThunk('cases/getCases', async (args) => {
  const { filter, currentPage } = args;

  const { data } = await apiClient.post(`/api/Case/getCases`, {
    currentPage,
    rowsCount: 20,
    filter,
  });

  return data;
});

const initialState = {
  cases: {},
  error: null,
};

export const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {},
  extraReducers: {
    [getCases.fulfilled]: (state, action) => {
      state.cases = action.payload;
    },
    [getCases.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default casesSlice.reducer;
