import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from '../../atoms/Dropdown';
import useAuth from '../../../hooks/useAuth';
import { ALL_STATES } from '../../../global/constants';
import useSafeLocationState from '../../../hooks/useSafeLocationState';
import Page from '../../organisms/Page/Page';
import LoaderCover from '../../atoms/LoaderCover';
import { apiClient } from '../../../lib/apiClient';
import { documentsColumns } from './DocumentsColumns.constants';
import Table from '../../atoms/Table/Table';
import { ReactComponent as USAmap } from '../../../assets/icons/USAmap.svg';
import { ReactComponent as Upload } from '../../../assets/icons/document-upload-white.svg';

import { deleteDocument, getDocuments } from '../../../store/slices/feeScheduleSlice';
import NotificationDialog from '../../molecules/NotificationDialog';
import notificationUtils from '../../../utils/notificationUtils';
import MButton from '../../MUI/Button/MButton';
import UploadFeeScheduleDocument from './UploadFeeScheduleDialog';
import { rolesDB } from '../../../utils/roleHelpers';
import EmptyBlock from '../../molecules/EmptyBlock';
import { usePrimaryState } from '../../../hooks/usePrimaryState';

const ClientPortalFeeSchedule = () => {
  const stateLocation = useSafeLocationState('Fee Schedule');

  const primaryState = usePrimaryState();
  const {
    userInfo: { StateCode, roles },
  } = useAuth();

  const isAdmin = roles.includes(rolesDB.Admin);

  const mapStateCode = Array.isArray(StateCode)
    ? StateCode.map((state) => {
        return ALL_STATES.find((item) => item.label === state);
      })
    : [ALL_STATES.find((item) => item.label === StateCode)];

  const initialState = stateLocation.stateCode
    ? mapStateCode.find((item) => item.label === stateLocation.stateCode)
    : mapStateCode[0];

  const [stateValue, setStateValue] = useState(primaryState || initialState);
  const [year, setYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [openUpload, setUploadOpen] = useState(false);
  const refMap = useRef(null);
  const azAllowed = mapStateCode.find((item) => item.value === 'AZ');
  const nvAllowed = mapStateCode.find((item) => item.value === 'NV');

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { documents } = useSelector((state) => state.feeSchedule);

  /* eslint-disable no-param-reassign */
  const setStateActive = (element, title, state) => {
    if (state === 'active') {
      element.setAttribute('fill', '#98ABFF');
      element.setAttribute('stroke', '#000000');
      element.style.cursor = 'default';
      title.setAttribute('fill', 'black');
    } else if (state === 'inactive') {
      element.setAttribute('fill', '#EBEDFA');
      element.setAttribute('stroke', '#000000');
      element.style.cursor = 'pointer';
      title.setAttribute('fill', 'black');
    } else if (state === 'disabled') {
      element.setAttribute('fill', '#FAFAFA');
      element.setAttribute('stroke', '#B3B3B3');
      element.style.cursor = 'not-allowed';
      title.setAttribute('fill', '#B4B4B4');
    }
  };
  /* eslint-enable no-param-reassign */

  const handleChangeState = useCallback((value) => {
    setStateValue(value);
  }, []);

  const fetchDocuments = (params = {}) => {
    setIsLoading(true);

    dispatch(
      getDocuments({
        year: year?.value,
        stateCode: stateValue.value,
        ...params,
      }),
    ).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const fetchYears = async () => {
      setIsLoading(true);

      try {
        const { data: yearsList } = await apiClient.get(
          `/cp/api/feeSchedules?stateCode=${stateValue.value}`,
        );

        const list = yearsList.result.map((item) => ({
          value: item.year,
          label: item.year,
        }));

        const currentYear = new Date().getFullYear();

        const selectedIsAvailable = list.find((item) => item.value === year?.value);
        const isCurrentYearAvailable = list.find((item) => item.value === currentYear);

        let nextYearValue = year;

        if (!selectedIsAvailable && isCurrentYearAvailable) {
          nextYearValue = { value: currentYear, label: currentYear };
        }

        if (!selectedIsAvailable && !isCurrentYearAvailable) {
          nextYearValue = null;
        }

        if (nextYearValue) {
          fetchDocuments({ year: nextYearValue.value });
        }

        setYear(nextYearValue);
        setYearOptions(list);
      } catch (e) {
        notificationUtils.error('Failed, try again later');
      } finally {
        setIsLoading(false);
      }
    };

    fetchYears();
  }, [stateValue]);

  useEffect(() => {
    const NVtitle = refMap.current?.getElementById('NV_map_title');
    const AZtitle = refMap.current?.getElementById('AZ_map_title');
    const AZstate = refMap.current?.querySelector('[data-name=AZ]');
    const NVstate = refMap.current?.querySelector('[data-name=NV]');

    if (stateValue.value === 'AZ' && azAllowed) {
      setStateActive(AZstate, AZtitle, 'active');
      if (nvAllowed) {
        setStateActive(NVstate, NVtitle, 'inactive');
      } else {
        setStateActive(NVstate, NVtitle, 'disabled');
      }
    } else if (stateValue.value === 'NV' && nvAllowed) {
      setStateActive(NVstate, NVtitle, 'active');
      if (azAllowed) {
        setStateActive(AZstate, AZtitle, 'inactive');
      } else {
        setStateActive(AZstate, AZtitle, 'disabled');
      }
    }
  }, [stateValue, azAllowed, nvAllowed]);

  const handleClose = () => {
    setDeleteId(null);
    setOpenConfirmationDialog(false);
  };

  const handleOpen = (rowId, { id }) => {
    setDeleteId(id);
    setOpenConfirmationDialog(true);
  };

  const handleClickDelete = async () => {
    setIsLoading(true);
    setDeleteId(null);
    try {
      await dispatch(deleteDocument({ documentId: deleteId })).unwrap();
      fetchDocuments();
      notificationUtils.success('Deleted successfully');
    } catch (e) {
      notificationUtils.error(typeof e === 'string' ? e : 'Failed, try again later');
    } finally {
      handleClose();
      setIsLoading(false);
    }
  };

  const handleClickMap = (ev) => {
    const allowState = mapStateCode.find((item) => item.value === ev.target.dataset.name);

    if (!allowState) return;
    handleChangeState(allowState);

    const NVtitle = refMap.current?.getElementById('NV_map_title');
    const AZtitle = refMap.current?.getElementById('AZ_map_title');
    const AZstate = refMap.current?.querySelector('[data-name=AZ]');
    const NVstate = refMap.current?.querySelector('[data-name=NV]');

    if (ev.target.dataset.name === 'NV') {
      setStateActive(ev.target, NVtitle, 'active');
      if (azAllowed) {
        setStateActive(AZstate, AZtitle, 'inactive');
      } else {
        setStateActive(AZstate, AZtitle, 'disabled');
      }
    }
    if (ev.target.dataset.name === 'AZ') {
      setStateActive(ev.target, AZtitle, 'active');
      if (nvAllowed) {
        setStateActive(NVstate, NVtitle, 'inactive');
      } else {
        setStateActive(NVstate, NVtitle, 'disabled');
      }
    }
  };

  const handleUpload = () => {
    setUploadOpen(true);
  };

  const handleCloseUpload = (isSuccess) => {
    setUploadOpen(false);
    if (isSuccess) {
      fetchDocuments();
    }
  };

  const rowsAdapter = useMemo(
    () =>
      documents?.map((item) => ({
        ...item,
        control: {
          disabled: documents.length <= 1,
          hidden: !isAdmin,
        },
      })),
    [documents, isAdmin],
  );

  const handleApply = () => {
    fetchDocuments();
  };

  return (
    <Page navPage="Fee Schedule">
      {isLoading && <LoaderCover />}

      <NotificationDialog
        title="Confirm Delete"
        desc="Are you sure you want to delete selected item?"
        type="alert"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Delete"
        onClose={handleClose}
        onConfirm={handleClickDelete}
        isOpen={openConfirmationDialog}
      />

      {openUpload && (
        <UploadFeeScheduleDocument
          isOpen={openUpload}
          onClose={handleCloseUpload}
          availableStates={mapStateCode}
        />
      )}

      <Stack height="100%" margin="0 32px" direction="column">
        <Stack spacing={4} direction="row" alignItems="center">
          <Stack direction="row" alignItems="center">
            <Typography variant="h4">Fee Schedule</Typography>
            <Dropdown
              label="Select state"
              options={mapStateCode}
              onChange={handleChangeState}
              value={stateValue}
            />
          </Stack>
        </Stack>
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            mt: 4,
            ml: 0,
            mr: 0,
            width: 1,
            maxWidth: '100vw',
            overflow: 'hidden',
          }}
        >
          <Stack
            pl={0.5}
            direction="row"
            alignItems="flex-end"
            mb={2}
            justifyContent="space-between"
          >
            <Stack pl={0.5} direction="row" alignItems="flex-end">
              <Box mr={1}>
                <Dropdown
                  isColumn
                  label="Year"
                  value={year}
                  name="year"
                  width="200px"
                  placeholder="Select year"
                  onChange={setYear}
                  options={yearOptions}
                  height={44}
                  onEnter={handleApply}
                />
              </Box>

              <MButton
                sx={{ width: '65px', position: 'relative', top: '-4px' }}
                onClick={handleApply}
                data-testid="document_upload_btn"
              >
                Apply
              </MButton>
            </Stack>

            {isAdmin && (
              <Box>
                <MButton
                  sx={{
                    width: '235px',
                    fontSize: '16px',
                    position: 'relative',
                    height: '48px',
                  }}
                  startIcon={<Upload />}
                  onClick={handleUpload}
                  data-testid="document_upload_btn"
                >
                  Upload Fee Schedule
                </MButton>
              </Box>
            )}
          </Stack>

          <Stack spacing={2} direction="row" justifyContent="space-between">
            <Box sx={{ width: '45%', minWidth: '450px', margin: rowsAdapter?.length ? 0 : 'auto' }}>
              {rowsAdapter?.length ? (
                <Table
                  columns={documentsColumns}
                  rows={rowsAdapter}
                  maxHeight="35vh"
                  total={documents.length}
                  isPagination={false}
                  onDeleteRow={handleOpen}
                  pageSize={rowsAdapter.length}
                />
              ) : (
                <EmptyBlock
                  title="No Documents"
                  desc="Please use filters to display available fee schedules"
                />
              )}
            </Box>
            <Box sx={{ width: '55%', display: 'flex' }}>
              <USAmap ref={refMap} onClick={handleClickMap} height="auto" />
            </Box>
          </Stack>
        </Container>
      </Stack>
    </Page>
  );
};

export default ClientPortalFeeSchedule;
