import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { getLinkToNewTab, navigateToNewTab } from '../../../utils/toNewTab';

const StateLink = ({ to, state, alwaysOpenInNewTab, children, ...props }) => {
  const navigate = useNavigate();

  const onClick = (e) => {
    e.preventDefault();
    if (alwaysOpenInNewTab) {
      navigateToNewTab(to, state);
    } else {
      navigate(to, { state });
    }
  };

  return (
    <Link
      data-testid="state_link"
      {...props}
      to={getLinkToNewTab(to, state)}
      state={state}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

StateLink.propTypes = {
  to: PropTypes.string.isRequired,
  state: PropTypes.shape({}),
  alwaysOpenInNewTab: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

StateLink.defaultProps = {
  state: undefined,
  alwaysOpenInNewTab: false,
};

export default StateLink;
