import { useContext, useMemo } from 'react';
import AuthContext from '../context/AuthContext';
import { tokenRepository } from '../lib/apiClient';
import { clearLocalStorageAndMoveToLoginPage } from '../lib/libUtils';
import getDecodedJwtToken from './getDecodedJwtToken';

export const rolesDB = {
  LegalAssistant: 'LegalAssistant',
  SchedulingAssistant: 'SchedulingAssistant',
  Attorney: 'Attorney',
  Accounting: 'Accounting',
  Admin: 'Admin',
};

const rolesByPriorityDB = [
  rolesDB.Admin,
  rolesDB.Attorney,
  rolesDB.LegalAssistant,
  rolesDB.SchedulingAssistant,
  rolesDB.Accounting,
];

export const rolesCP = {
  ClientPortalAdmin: 'ClientPortalAdmin',
  ClientPortalUser: 'ClientPortalUser', // the same as User
};

const rolesByPriorityCP = [
  rolesDB.Admin,
  rolesCP.ClientPortalAdmin,
  rolesDB.Attorney,
  rolesDB.LegalAssistant,
  rolesDB.SchedulingAssistant,
  rolesDB.Accounting,
  rolesCP.ClientPortalUser,
];

export const defaultPageByRoleDB = {
  [rolesDB.Attorney]: '/db/dashboard/attorney',
  [rolesDB.LegalAssistant]: '/db/dashboard/legalassistant',
  [rolesDB.SchedulingAssistant]: '/db/dashboard/schedulingassistant',
  [rolesDB.Accounting]: '/db/processing/casesearch',
  [rolesDB.Admin]: '/db/dashboard/attorney',
};

export const defaultPageByRoleCP = (base = 'cp') => {
  return {
    [rolesCP.ClientPortalAdmin]: `/${base}/dashboard`,
    [rolesCP.ClientPortalUser]: `/${base}/dashboard`,
    [rolesDB.Attorney]: `/${base}/dashboard`,
    [rolesDB.LegalAssistant]: `/${base}/dashboard`,
    [rolesDB.SchedulingAssistant]: `/${base}/dashboard`,
    [rolesDB.Accounting]: `/${base}/dashboard`,
    [rolesDB.Admin]: `/${base}/dashboard`,
  };
};

const dashboardsDB = {
  [rolesDB.Attorney]: {
    label: 'Attorney',
    to: defaultPageByRoleDB[rolesDB.Attorney],
  },
  [rolesDB.LegalAssistant]: {
    label: 'Legal Assistant',
    to: defaultPageByRoleDB[rolesDB.LegalAssistant],
  },
  [rolesDB.SchedulingAssistant]: {
    label: 'Scheduling Assistant',
    to: defaultPageByRoleDB[rolesDB.SchedulingAssistant],
  },
};

const noDefaultPageFound = () => {
  try {
    alert(
      `The system does not recognize your user.
Please reach out to your admin to resolve the issue.
After pressing an OK button, you will be logged out of the system.`,
    );
    clearLocalStorageAndMoveToLoginPage(tokenRepository);
  } catch (e) {
    console.error(e);
  }
  return '';
};

const getUserPageBasedOnTokenRoles = (token) => {
  if (token) {
    const decodedToken = getDecodedJwtToken(token);
    const isDB = !decodedToken?.isClientPortalUser;
    const { roles = [] } = decodedToken;

    if (isDB) {
      const role = rolesByPriorityDB.find((dbRole) => roles.includes(dbRole));
      return defaultPageByRoleDB[role];
    }
    const isClientPortal = window.location.pathname.startsWith('/cp/');
    const base = decodedToken?.isClientPortalUser && isClientPortal ? 'cp' : 'client';

    const role = rolesByPriorityCP.find((cpRole) => roles.includes(cpRole));
    return defaultPageByRoleCP(base)[role];
  }
  return '';
};

export const getDefaultPage = (token = tokenRepository.getToken(), landingPage = '') => {
  if (!token) noDefaultPageFound();
  if (landingPage) {
    return landingPage;
  }
  const page = getUserPageBasedOnTokenRoles(token);
  if (!page) noDefaultPageFound();
  return page;
};

export function getDashboardBarConfig(token = tokenRepository.getToken()) {
  const { roles = [] } = getDecodedJwtToken(token);
  const whitelist = [
    rolesDB.Admin,
    rolesDB.Attorney,
    rolesDB.LegalAssistant,
    rolesDB.SchedulingAssistant,
  ];

  if (roles.includes(rolesDB.Admin)) {
    return {
      label: 'Dashboard',
      pathname: 'dashboard',
      dropdownNavigationConfig: [...Object.values(dashboardsDB)],
      dropdownWidth: '146px',
      whitelist,
    };
  }

  const availableDashboards = Object.keys(dashboardsDB)
    .filter((role) => roles.includes(role))
    .map((role) => dashboardsDB[role]);

  if (availableDashboards.length > 1) {
    return {
      label: 'Dashboard',
      dropdownNavigationConfig: availableDashboards,
      dropdownWidth: '146px',
      whitelist,
    };
  }

  return {
    label: 'Dashboard',
    to: availableDashboards[0]?.to,
    whitelist,
  };
}

export const useUserInfo = () => {
  const { userInfo } = useContext(AuthContext);
  return userInfo || {};
};

export const useAllowed = (whitelist = []) => {
  const { roles } = useUserInfo();

  const allowed = useMemo(() => {
    return Boolean(roles?.filter((role) => whitelist.includes(role)).length);
  }, [whitelist]);

  return allowed;
};
