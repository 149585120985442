import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SubPageHeading from '../../../../organisms/SubPageHeading';
import { subPageNavEdit, subPageNavNew } from './CommunityContainer.constants';

const CommunityContainer = () => {
  const location = useLocation();

  const { navPage = 'Data Handling', subNav = 'Communities', name, state } = location.state || {};

  const subPageNavEditExtended = subPageNavEdit.map((item) => ({
    ...item,
    state: location.state,
  }));

  return (
    <>
      <SubPageHeading
        subPageNav={name ? subPageNavEditExtended : subPageNavNew}
        subPageHeading={name || 'New Community'}
        nav={{
          to: '/db/datahandling/communities',
          state: {
            navPage,
            subNav,
            state,
          },
        }}
      />
      <Outlet />
    </>
  );
};

export default CommunityContainer;
