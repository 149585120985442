import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';
import { palette } from '../../../theme/default/palette';

const StyledCheckbox = styled(Checkbox)({
  marginRight: 8,
  marginLeft: 0,
  width: 16,
  height: 16,
  padding: 0,
  borderRadius: 4,
  border: '1px solid',
  borderColor: palette.buttonPrimary.active,
  backgroundColor: palette.common.white,
  position: 'relative',
  transition: 'all 0.2s ease-in',

  '&:after': {
    // eslint-disable-next-line
    content: '""',
    position: 'absolute',
    display: 'none',
    top: '50%',
    left: '50%',
    width: 6,
    height: 10,
    borderStyle: 'solid',
    borderColor: palette.common.white,
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(45deg)',
    translate: '-50% -65%',
  },

  '&:hover': {
    borderColor: palette.buttonPrimary.pressed,
    backgroundColor: palette.common.white,
  },

  '&.Mui-checked': {
    backgroundColor: palette.buttonPrimary.active,

    '&:after': {
      display: 'block',
    },

    '&:hover': {
      borderColor: palette.buttonPrimary.hover,
      backgroundColor: palette.buttonPrimary.hover,
    },
  },

  '&.Mui-disabled': {
    borderColor: palette.text.disabled,
    backgroundColor: palette.text.disabled,

    '&:hover': {
      borderColor: palette.buttonPrimary.disabled,
      backgroundColor: palette.buttonPrimary.disabled,
    },
  },

  '&.Mui-error': {
    borderColor: palette.additional.alert,
  },

  '& .MuiSvgIcon-root': {
    display: 'none',
  },
});

const MCheckbox = (props) => {
  const { isChecked, isDisabled, onChange, sx, indeterminate, indeterminateIcon } = props;

  const handleClickStopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <Box
      onClick={handleClickStopPropagation}
      display="flex"
      alignItems="center"
      justifyContent="center"
      data-testid="checkbox_wrapper"
      sx={sx}
    >
      <StyledCheckbox
        inputProps={{ 'data-testid': 'checkbox_input' }}
        size="medium"
        checked={isChecked}
        disabled={isDisabled}
        onChange={onChange}
        data-testid="checkbox"
        indeterminate={indeterminate}
        indeterminateIcon={indeterminateIcon}
      />
    </Box>
  );
};

MCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.shape({}),
  indeterminate: PropTypes.bool,
  indeterminateIcon: PropTypes.elementType,
};

MCheckbox.defaultProps = {
  isChecked: false,
  isDisabled: false,
  onChange: () => null,
  sx: undefined,
  indeterminate: false,
  indeterminateIcon: undefined,
};

export default MCheckbox;
