import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { documentStrings } from './Document.constants';
import MButton from '../../../MUI/Button/MButton';
import ShadowBlock from '../../../atoms/ShadowBlock';
import { ReactComponent as Upload } from '../../../../assets/icons/document-upload-active.svg';

const UploadDocument = ({ handleOpen }) => {
  return (
    <Box sx={{ mx: 4, p: 4, borderRadius: 2 }} data-testid="upload_document_wrapper">
      <Typography variant="h5" sx={{ mb: 15 }} data-testid="title">
        {documentStrings.title}
      </Typography>
      <Stack spacing={2} direction="row" justifyContent="center">
        <ShadowBlock>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '176px',
              width: '336px',
            }}
          >
            <Typography variant="h5" data-testid="box_title">
              {documentStrings.boxTitle}
            </Typography>
            <MButton variant="borderLess" onClick={handleOpen} data-testid="upload_button">
              <Upload />
            </MButton>
          </Box>
        </ShadowBlock>
      </Stack>
    </Box>
  );
};

UploadDocument.propTypes = {
  handleOpen: PropTypes.func.isRequired,
};

export default UploadDocument;
