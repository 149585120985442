import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { attorneyLabelAndDropDown } from './AttorneyAssignments.constants';
import Dropdown from '../../../atoms/Dropdown';
import {
  getStates,
  setCurrentState,
} from '../../../../store/slices/schedule/attorneyAssignmentsSlice';
import { usePrimaryState } from '../../../../hooks/usePrimaryState';

const AttorneyAssignmentsHeader = () => {
  const dispatch = useDispatch();
  const primaryState = usePrimaryState();
  const [chosenState, setChosenState] = useState(primaryState);
  const { states } = useSelector((state) => state.attorneyAssignments);

  const handleDropdownChange = (value) => {
    setChosenState(value);
    dispatch(setCurrentState(value));
  };

  useEffect(() => {
    dispatch(getStates()).then(({ payload }) => {
      handleDropdownChange(primaryState || payload[0]);
    });
  }, []);

  return (
    <Stack flex justifyContent="flex-start" alignItems="center" flexDirection="initial">
      <Typography variant="h3" pb={1}>
        {attorneyLabelAndDropDown.label}
      </Typography>
      <Dropdown
        label={attorneyLabelAndDropDown.dropdownLabel}
        options={states}
        onChange={handleDropdownChange}
        placeholder="-"
        isSearchable={false}
        value={chosenState}
      />
    </Stack>
  );
};

export default AttorneyAssignmentsHeader;
