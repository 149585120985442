/* eslint-disable */
export function roundNumToThousands(input) {
  if (!input || isNaN(input)) return 0;
  if (input.endsWith('.')) return input;

  const parsed = parseFloat(input);

  if (Number.isInteger(parsed)) {
    return parsed;
  }
  // ??
  if (input.endsWith('.')) {
    return parsed;
  }
  const fixed = parsed.toFixed(2);
  return fixed.endsWith('0') ? fixed.slice(0, -1) : fixed;
}
