import { Box } from '@mui/material';
import React from 'react';
import EmptyBlock from '../../molecules/EmptyBlock';

const DashboardEmpty = () => {
  return (
    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
      <EmptyBlock title="No Dashboards Available" />
    </Box>
  );
};

export default DashboardEmpty;
