import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography, Box, styled } from '@mui/material';
import {
  getAllUsers,
  deleteUser,
  getAvailableRoles,
  getUser,
  resetEditableUser,
  getAvailableStates,
} from '../../../../store/slices/adminUsersSlice';
import Table from '../../../atoms/Table/Table';
import { UsersTableColumns, MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './users.constants';
import MButton from '../../../MUI/Button/MButton';
import AddButton from '../../../atoms/AddButton/AddButton';
import AddNewUserDialog from './AddNewUserDialog';
import EmptyBlock from '../../../molecules/EmptyBlock';
import SearchInputWithLabelAndIcon from '../../../atoms/SearchInputWithLabelAndIcon/SearchInputWithLabelAndIcon';
import SearchInputWithLabel from '../../../atoms/SearchInputWithLabel/SearchInputWithLabel';
import { apiClient } from '../../../../lib/apiClient';
import notificationUtils from '../../../../utils/notificationUtils';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import SearchableCheckboxSelectWithLabel from '../../../atoms/SearchableCheckboxSelectWithLabel/SearchableCheckboxSelectWithLabel';

const StyledSearchFieldsContainer = styled(Stack)({
  maxWidth: '100%',
  display: 'flex',
  padding: '30px 0',
  flexWrap: 'wrap',
});

const Users = () => {
  useSafeLocationState('Admin', 'Users');
  const dispatch = useDispatch();

  const { usersTable, roles, states } = useSelector((state) => state.adminUsers);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedState, setSelectedState] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [tableLoading, setTableLoading] = useState('');
  const [dialogMode, setDialogMode] = useState(null);

  useEffect(() => {
    setTableLoading(true);
    dispatch(
      getAllUsers({
        currentPage: 1,
        pageSize: 10,
        firstName,
        lastName,
        email,
        stateCodes: selectedState || [],
        roleId: selectedRole?.id || '',
      }),
    ).then(() => setTableLoading(false));
    dispatch(getAvailableRoles());
    dispatch(getAvailableStates());
  }, []);

  const handleDeleteUser = (rowId, { userGuid }) => {
    if (!userGuid) return null;
    return dispatch(deleteUser({ userId: userGuid }));
  };

  const handleApply = () => {
    setTableLoading(true);
    dispatch(
      getAllUsers({
        currentPage: 1,
        pageSize: 10,
        firstName,
        lastName,
        email,
        stateCodes: selectedState.map((item) => item.value),
        roleId: selectedRole?.id || '',
      }),
    ).then(() => setTableLoading(false));
  };

  const handleReset = () => {
    setTableLoading(true);
    setSelectedRole(null);
    setSelectedState([]);
    setFirstName('');
    setLastName('');
    setEmail('');
    dispatch(
      getAllUsers({
        currentPage: 1,
        pageSize: 10,
        firstName: '',
        lastName: '',
        email: '',
        roleId: '',
      }),
    ).then(() => {
      setTableLoading(false);
    });
  };

  const handleSelectRole = (value) => {
    setSelectedRole(value);
  };

  const handleSelectState = (value) => {
    setSelectedState(value);
  };

  const handleChangeFirstName = (value) => {
    setFirstName(value);
  };

  const handleChangeLastName = (value) => {
    setLastName(value);
  };

  const handleChangeEmail = (value) => {
    setEmail(value);
  };

  const handleOpenAddewUser = () => {
    setDialogMode('create');
  };

  const [confirmError, setConfirmError] = useState({});

  const highlightConfirmError = (error) => {
    if (error.response.data.errorCode === 'user.email.should.be.unique') {
      setConfirmError({ email: 'E-mail address is already in use.' });
    }
    if (error.response.data.errorCode === 'user.userName.should.be.unique') {
      setConfirmError({ userName: error.response.data.errorMessage });
    }
  };

  const resetDialogState = () => {
    setConfirmError(null);
    setDialogMode(null);
    setTableLoading(false);
    dispatch(resetEditableUser());
  };

  const onConfirmDialog = async (userInfo) => {
    setTableLoading(true);
    if (userInfo.userId) {
      const { userId, ...rest } = userInfo;
      await apiClient
        .put(`/api/users/${userId}`, rest)
        .then(() =>
          dispatch(
            getAllUsers({
              currentPage: usersTable.currentPage,
              pageSize: 10,
              firstName,
              lastName,
              email,
              stateCodes: selectedState.map((item) => item.value),
              roleId: selectedRole?.id || '',
            }),
          ).then(() => {
            notificationUtils.success('Saved successfully');
            resetDialogState();
          }),
        )
        .catch((error) => {
          setTableLoading(false);
          highlightConfirmError(error);
        });
    } else {
      const { userId, ...rest } = userInfo;
      await apiClient
        .post(`/api/users`, rest)
        .then(() =>
          dispatch(
            getAllUsers({
              currentPage: usersTable.currentPage,
              pageSize: 10,
              firstName,
              lastName,
              email,
              stateCodes: selectedState.map((item) => item.value),
              roleId: selectedRole?.id || '',
            }),
          ).then(() => {
            notificationUtils.success('Saved successfully');
            resetDialogState();
          }),
        )
        .catch((error) => {
          setTableLoading(false);
          highlightConfirmError(error);
        });
    }
  };

  const onCloseDialog = () => {
    resetDialogState();
  };

  const handleClickTitleRowTable = useCallback(
    (user) => () => {
      dispatch(getUser(user.userGuid));
      setDialogMode('edit');
    },
    [],
  );

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setTableLoading(true);
      dispatch(
        getAllUsers({
          currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          firstName,
          lastName,
          email,
          stateCodes: selectedState.map((item) => item.value),
          roleId: selectedRole?.id || '',
        }),
      ).then(() => {
        setTableLoading(false);
      });
    },
    [firstName, lastName, email, selectedRole?.id, selectedState],
  );

  const rowsAdapter = usersTable?.items?.map((item) => ({
    ...item,
    firstName: (
      <Typography
        component="a"
        variant="bodyS"
        color="text.primary"
        sx={{
          cursor: 'pointer',
          textDecorationLine: 'underline',
        }}
        onClick={handleClickTitleRowTable(item)}
      >
        {item.firstName}
      </Typography>
    ),
    control: {
      autoRefresh: true,
    },
  }));

  return (
    <Box width="80vw" marginX="auto">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Users</Typography>
        <AddButton onClick={handleOpenAddewUser} label="Add new user" width="auto" />
        {dialogMode && (
          <AddNewUserDialog
            title={dialogMode === 'create' ? 'New user' : 'User Editing'}
            isOpen={!!dialogMode}
            onClose={onCloseDialog}
            onConfirm={onConfirmDialog}
            buttonSecondaryText="Cancel"
            buttonPrimaryText="Save"
            confirmError={confirmError}
          />
        )}
      </Stack>
      <StyledSearchFieldsContainer>
        <Stack
          gap={1}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="baseline"
        >
          <SearchInputWithLabel
            label="First Name"
            placeholder="Enter first name"
            isDropdown={false}
            onChangeValue={handleChangeFirstName}
            value={firstName}
            width={200}
            onEnter={handleApply}
          />
          <SearchInputWithLabel
            label="Last Name"
            placeholder="Enter last name"
            isDropdown={false}
            onChangeValue={handleChangeLastName}
            value={lastName}
            width={200}
            onEnter={handleApply}
          />
          <SearchInputWithLabel
            label="Email"
            placeholder="Enter email"
            isDropdown={false}
            onChangeValue={handleChangeEmail}
            value={email}
            width={200}
            onEnter={handleApply}
          />
          <SearchInputWithLabelAndIcon
            label="Role"
            placeholder="Select a role"
            value={selectedRole}
            isDropdown
            options={roles}
            handleChange={handleSelectRole}
            width={200}
            onEnter={handleApply}
          />
          <SearchableCheckboxSelectWithLabel
            label="State"
            value={selectedState}
            placeholder="Select state"
            options={states}
            width={200}
            onEnter={handleApply}
            onChange={handleSelectState}
          />
          <MButton
            variant="primary"
            onClick={handleApply}
            disabled={tableLoading}
            data-testid="users_apply_btn"
          >
            Apply
          </MButton>
          <MButton
            variant="bordered"
            onClick={handleReset}
            disabled={tableLoading}
            data-testid="users_reset_btn"
          >
            Reset
          </MButton>
        </Stack>
      </StyledSearchFieldsContainer>
      {!usersTable?.items?.length && !tableLoading && (
        <Stack alignItems="center" mt={18}>
          <EmptyBlock
            title="No users found"
            desc="Please check the filters for correctness and search again."
          />
        </Stack>
      )}
      {usersTable?.items && (
        <Table
          columns={UsersTableColumns}
          rows={rowsAdapter}
          total={usersTable?.totalRowsCount}
          isPagination={usersTable?.totalPages > 1}
          onDeleteRow={handleDeleteUser}
          onRefreshPage={handleFetchDataPage}
          onNextPage={handleFetchDataPage}
          onPreviousPage={handleFetchDataPage}
          onGotoPage={handleFetchDataPage}
          pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
          loading={tableLoading}
        />
      )}
    </Box>
  );
};

export default Users;
