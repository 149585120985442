import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import { Box, Container, Typography, Stack, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
  newUserBoxes,
  newUserCommunitiesColumnsWithDeleteCell,
  newUserAddressColumnsWithDeleteCell,
} from './ClientPortalAdmin.constants';
import Form from '../../../atoms/Form';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import Field from '../../../atoms/Field';
import Dropdown from '../../../atoms/Dropdown';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup/CustomRadioGroup';
import {
  getAllClientPortalRoles,
  getAllClientPortalStates,
  getCompanyManagementsList,
  getUserDetails,
  createNewUser,
  updateUser,
  editCommunities,
  editAddresses,
  deleteAddressesFromTheTableInUserCreationMode,
  deleteCommunityFromTheTableInUserCreationMode,
  resetUserAddressesAndCommunitiesOnCreation,
} from '../../../../store/slices/clientPortalAdminUsers';
import Table from '../../../atoms/Table/Table';
import MultipleSelectChip from '../../../atoms/MultipleSelectChip';
import Password from '../../../atoms/Password/Password';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import MTooltip from '../../../atoms/MTooltip';
import AuthContext from '../../../../context/AuthContext';
import { rolesCP, rolesDB } from '../../../../utils/roleHelpers';
import {
  invalidCommunitiesAndAddressesMessage,
  invalidEmailErrorMessage,
  invalidPasswordErrorMessage,
  isEmailValid,
  isPasswordValid,
} from '../../../../utils/formHelpers';
import notificationUtils from '../../../../utils/notificationUtils';
import ShadowBlock from '../../../atoms/ShadowBlock';
import { SectionTitle } from './ClientPortalUserPartials/SectionTitle';
import { CommunititesAndAddressSection } from './ClientPortalUserPartials/CommunitiesAndAddressSection';
import { useBaseUrl } from '../../../../hooks/useBaseUrl';

const ClientPortalUser = () => {
  const form = useForm();
  const values = form.getValues();
  const isEdit = Boolean(values.userId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const base = useBaseUrl();
  const { userInfo } = useContext(AuthContext);

  const isClientAdmin = useMemo(() => {
    return userInfo.roles.includes(rolesCP.ClientPortalAdmin);
  }, [userInfo]);

  const isDBAdmin = useMemo(() => {
    return userInfo.roles.includes(rolesDB.Admin);
  }, [userInfo]);

  const {
    state: { id, subSubNavigation },
  } = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const { roles, states, companyManagements, userDetails, createNewUserError } = useSelector(
    (state) => state.clientPortalAdminUsers,
  );

  useEffect(() => {
    if (id) return;

    if (isClientAdmin) {
      if (companyManagements && companyManagements?.items) {
        const [company] = companyManagements.items;
        form.setValue('management', company);
      }
    }
  }, [id, companyManagements, isClientAdmin]);

  useEffect(() => {
    if (!createNewUserError) return;

    if (createNewUserError.errorCode === 'value.is.invalid') {
      form.setError('email', { message: 'Value of Email is invalid' });
    }
  }, [createNewUserError]);

  useEffect(() => {
    if (!id) {
      Promise.all([
        dispatch(getAllClientPortalRoles()),
        dispatch(getAllClientPortalStates()),
      ]).finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      Promise.all([
        dispatch(getUserDetails(id)),
        dispatch(getAllClientPortalRoles()),
        dispatch(getAllClientPortalStates()),
      ])
        .then(([fetchedUserDetails]) => {
          const { result } = fetchedUserDetails.payload;

          const primaryStateFromQuery = result.states.find((state) => {
            return state.stateCode === result.primaryStateCode;
          });

          const currentPrimaryState = primaryStateFromQuery
            ? {
                id: primaryStateFromQuery.stateCode,
                label: primaryStateFromQuery.stateCode,
                value: primaryStateFromQuery.stateCode,
              }
            : {
                id: result.states[0].stateCode,
                label: result.states[0].stateCode,
                value: result.states[0].stateCode,
              };

          form.reset({
            ...result,
            role: result.role
              ? {
                  id: result.role.roleId,
                  label: result.role.roleName,
                  value: result.role.roleId,
                }
              : null,
            management: result.managementCompany
              ? {
                  id: result.managementCompany.managementId,
                  label: result.managementCompany.managementCompanyName,
                  value: result.managementCompany.managementId,
                }
              : null,
            states: result.states.map((item) => item.stateCode),
            primaryStateCode: currentPrimaryState,
          });
        })
        .catch((error) => {
          console.error('Error fetching user details:', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  const management = form.watch('management');

  const managementId = useMemo(() => {
    return form.watch('management')?.id || null;
  }, [management]);

  useEffect(() => {
    if (!id) {
      form.setValue('communities', []);
      form.setValue('addresses', []);
      dispatch(resetUserAddressesAndCommunitiesOnCreation());
    }
  }, [managementId, id]);

  useEffect(() => {
    if (userDetails?.communities) {
      form.setValue('communities', userDetails?.communities);
    }
    if (userDetails?.addresses) {
      form.setValue('addresses', userDetails?.addresses);
    }
  }, [userDetails?.communities, userDetails?.addresses]);

  useEffect(() => {
    if (values?.communities?.length || values?.addresses?.length) {
      form.clearErrors('communitiesAndAddresses');
    }
  }, [values?.communities, values?.addresses]);

  useEffect(() => {
    if (!isEdit && roles && !isDBAdmin) {
      form.setValue(
        'role',
        roles.find((item) => item.value === 'User'),
      );
    } else if (roles && !id) {
      form.setValue(
        'role',
        roles.find((item) => item.value === 'ClientPortalUser'),
      );
    }
  }, [roles]);

  const entirePortfolio = form.watch('hasEntirePortfolio');

  useEffect(() => {
    if (entirePortfolio) {
      form.clearErrors('communitiesAndAddresses');
      if (id) {
        dispatch(
          editAddresses({
            userId: id,
            addressIds: [],
          }),
        ).then(() => form.setValue('addresses', []));

        dispatch(
          editCommunities({
            userId: id,
            communityIds: [],
          }),
        ).then(() => form.setValue('communities', []));
      } else {
        form.setValue('communities', []);
        form.setValue('addresses', []);
        dispatch(resetUserAddressesAndCommunitiesOnCreation());
      }
    }
  }, [entirePortfolio]);

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: companyManagements.hasNextPage,
    getAction: ({ input, page }) =>
      getCompanyManagementsList({
        currentPage: page,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        managementName: input,
      }),
  });

  const shouldSearchOnScroll = (fieldName) => {
    if (fieldName === 'management') {
      return { handleMenuScrollToBottom, changeInput };
    }
    return null;
  };

  const getTableColumns = useCallback((tableName) => {
    switch (tableName) {
      case 'communities':
        return newUserCommunitiesColumnsWithDeleteCell;
      case 'addresses':
        return newUserAddressColumnsWithDeleteCell;
      default:
        return [];
    }
  }, []);

  const getOptions = useCallback(
    (dropdownName) => {
      switch (dropdownName) {
        case 'role':
          return roles;
        case 'states':
        case 'primaryStateCode':
          return states;
        case 'management':
          return companyManagements?.items || [];
        default:
          return [];
      }
    },
    [roles, states, companyManagements],
  );

  const handleCancel = () => {
    navigate(`/${base}/admin/users`, {
      state: {
        id,
        navPage: 'Admin',
        subNav: 'Users',
        subSubNavigation: 'Users',
      },
    });
  };

  const handleDeleteRow = (tableName, row) => {
    const formValues = form.getValues();

    let refreshedList = null;

    if (tableName === 'communities') {
      refreshedList = formValues.communities.filter((item) => {
        return item.communityId !== row.communityId;
      });
      dispatch(deleteCommunityFromTheTableInUserCreationMode(refreshedList));
    }

    if (tableName === 'addresses') {
      refreshedList = formValues.addresses.filter((item) => {
        return item.addressId !== row.addressId;
      });
      dispatch(deleteAddressesFromTheTableInUserCreationMode(refreshedList));
    }

    if (id && tableName === 'communities') {
      dispatch(
        editCommunities({
          userId: id,
          communityIds: refreshedList.map((item) => item.managementId),
        }),
      );
    }
    if (id && tableName === 'addresses') {
      dispatch(
        editAddresses({
          userId: id,
          addressIds: refreshedList.map((item) => item.addressId),
        }),
      );
    }
  };

  const defineFieldDisabledOrNot = useCallback(
    (fieldName, isDisabled) => {
      if (id) {
        if (fieldName === 'management') {
          return true;
        }
      }
      return isDisabled;
    },
    [userDetails],
  );

  const checkDisabledField = useCallback(
    (fieldName, isDisabled) => {
      if (fieldName === 'management' && (isClientAdmin || id)) {
        return true;
      }
      if (fieldName === 'role' && !isDBAdmin) return true;

      if (fieldName === 'primaryStateCode') {
        const chosenStates = form.watch('states');
        return !chosenStates || chosenStates?.length <= 1;
      }
      return isDisabled;
    },
    [userDetails, form.watch('states')],
  );

  const handleSubmit = async (formValues) => {
    const { password, confirmPassword, userName, email, primaryStateCode } = formValues;

    if (!/^([a-zA-Z0-9]+)$/.test(userName)) {
      form.setError('userName', { message: 'Username can only contain letters or digits.' });
      return;
    }

    if (!isEmailValid(email)) {
      form.setError('email', { message: invalidEmailErrorMessage });
      return;
    }

    if (!id || (id && password) || confirmPassword) {
      if (password || confirmPassword) {
        if (confirmPassword && password && confirmPassword !== password) {
          form.setError('confirmPassword', { message: 'Passwords do not match' });
          return;
        }
        if (password !== confirmPassword) {
          form.setError('confirmPassword', { message: 'Passwords do not match' });
          return;
        }
        if (!isPasswordValid(password)) {
          form.setError('password', { message: invalidPasswordErrorMessage });
          return;
        }
      }
    }

    setIsLoading(true);

    const userFormValues = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      userName: formValues.userName,
      email: formValues.email,
      title: formValues.title,
      mobilePhone: formValues.mobilePhone,
      workPhone: formValues.workPhone,
      isActive: formValues.isActive,
      password: formValues.password,
      confirmPassword: formValues.confirmPassword,
      defaultRoleId: formValues?.role?.id || null,
      managementId: formValues.management.id,
      stateCodes: formValues.states,
      primaryStateCode: primaryStateCode?.value || formValues.states[0],
      hasEntirePortfolio: formValues.hasEntirePortfolio,
      isInvoiceEnabled: formValues.notification.isInvoice,
      isDailyReportEnabled: formValues.notification.isDaily,
      isWeeklyReportEnabled: formValues.notification.isWeekly,
      isMonthlyReportEnabled: formValues.notification.isMonthly,
    };

    try {
      if (isEdit) {
        userFormValues.userId = formValues.userId;
      } else {
        userFormValues.communityIds =
          formValues?.communities?.map((item) => item.communityId) || [];
        userFormValues.addressIds = formValues?.addresses?.map((item) => item.addressId) || [];
      }

      const action = isEdit ? updateUser : createNewUser;

      await dispatch(action(userFormValues)).unwrap();
      notificationUtils.success(`Saved successfully`);

      navigate(`/${base}/admin/users`, {
        state: {
          navPage: 'Admin',
          subNav: 'Users',
        },
      });
    } catch (e) {
      const { errorCode, errorMessage } = e;
      const setFieldError = (name, message = errorMessage) =>
        form.setError(name, { type: 'custom', message }, { shouldFocus: true });

      if (errorCode === 'user.email.should.be.unique') {
        setFieldError('email', 'E-mail address is already in use.');
      } else if (errorCode === 'user.userName.should.be.unique') {
        setFieldError('userName');
      } else {
        notificationUtils.error(errorMessage);
      }
    }
    setIsLoading(false);
  };

  const statesToUseInSearch = useMemo(() => {
    const chosenStates = form.watch('states');
    if (chosenStates?.length === 1) {
      return chosenStates[0];
    }
    return '';
  }, [form.watch('states')]);

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  const getIsComAndAddSection = (section) => {
    return section.inputBoxes.some((item) => item.name === 'communitiesAndAddresses');
  };

  // this function is needed because we want to validate 1 of 2 fields as mandatory
  // either communities or addreses
  // and validation at the same time as mandatory fields
  const validateComAndAddBeforeSubmit = useCallback(() => {
    if (!values.communities.length && !values.addresses.length && !entirePortfolio) {
      form.setError('communitiesAndAddresses');
    }
  }, [values.communities, values.addresses, entirePortfolio]);

  const getOnChange = useCallback(
    (onChange, name) => (valueOrEvent) => {
      switch (name) {
        case 'states': {
          form.setValue('states', valueOrEvent);
          if (valueOrEvent.length === 1) {
            const state = states?.find((item) => item.value === valueOrEvent[0]);
            form.setValue('primaryStateCode', state);
          }
          return onChange(valueOrEvent);
        }
        case 'primaryStateCode': {
          const state = states?.find((item) => item.value === valueOrEvent);
          form.setValue('primaryStateCode', state);
          return onChange(valueOrEvent);
        }
        default:
          return onChange(valueOrEvent);
      }
    },
    [states],
  );

  const selectedState = form.watch('states') ?? [];
  const getMandatory = useCallback(
    (name) => {
      switch (name) {
        case 'primaryStateCode':
          return Boolean(selectedState.length >= 2);
        default:
          return false;
      }
    },
    [selectedState.length],
  );

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      {!isLoading ? (
        <>
          <Typography variant="h4">{subSubNavigation}</Typography>
          <Form onSubmit={handleSubmit} form={form}>
            <Stack gap={4} mb={4} mt={4}>
              {newUserBoxes?.map((data) => {
                if (getIsComAndAddSection(data) && entirePortfolio) return null;
                return (
                  <ShadowBlock variant="dropdownCard" key={data.boxTitle}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      columnGap={3}
                      rowGap={3}
                      flexWrap="wrap"
                    >
                      {data.inputBoxes.map((item) => {
                        if (item.isSubHeadingWithButton) {
                          return (
                            <CommunititesAndAddressSection
                              label={item.label}
                              statesToUseInSearch={statesToUseInSearch}
                              disabled={form.watch('hasEntirePortfolio')}
                              buttonName={item.buttonName}
                              buttonIcon={item.buttonIcon}
                              alreadyAddedCommunities={form.watch('communities')}
                              alreadyAddedAddresses={form.watch('addresses')}
                              searchInputPlaceholder={item.searchInputPlaceholder}
                              managementId={managementId}
                            />
                          );
                        }
                        if (item.isDropdownMultiChip) {
                          return (
                            <Field
                              key={item.label}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              render={({ field, error, onCustomChange }) => (
                                <MultipleSelectChip
                                  value={field.value || []}
                                  isAddDisabled={false}
                                  label={item.label}
                                  width={item.width}
                                  isColumn
                                  error={error}
                                  isMandatory={item.isMandatory}
                                  placeholder={item.placeholder}
                                  options={getOptions(item.name)}
                                  onChange={getOnChange(onCustomChange(field.onChange), field.name)}
                                />
                              )}
                            />
                          );
                        }

                        if (item.isSubHeading) {
                          return (
                            <SectionTitle
                              label={item.label}
                              tooltip={item.tooltip}
                              isMandatory={item.isMandatory}
                            />
                          );
                        }

                        if (item.isDropdown) {
                          const isMandatory = getMandatory(item.name) || item.isMandatory;

                          return (
                            <Field
                              key={item.label}
                              name={item.name}
                              isMandatory={isMandatory}
                              render={({ field, error, onCustomChange }) => {
                                const isPrimaryStateCode = field.name === 'primaryStateCode';
                                const fieldValue = form.watch(field.name);

                                const primaryCode = states?.find(
                                  (code) => code.value === fieldValue?.value,
                                );
                                return (
                                  <Dropdown
                                    value={isPrimaryStateCode ? primaryCode : field.value}
                                    isDisabled={checkDisabledField(item.name, item.isDisabled)}
                                    isSearchable
                                    label={item.label}
                                    tooltip={
                                      field.name === 'role' && !isEdit && !isDBAdmin
                                        ? item.tooltip
                                        : ''
                                    }
                                    width={item.width}
                                    name={item.name}
                                    isColumn
                                    error={error}
                                    isMandatory={isMandatory}
                                    placeholder={item.placeholder}
                                    options={getOptions(item.name)}
                                    onChange={getOnChange(
                                      onCustomChange(field.onChange),
                                      field.name,
                                    )}
                                    onInputChange={shouldSearchOnScroll(item.name)?.changeInput}
                                    onMenuScrollToBottom={
                                      shouldSearchOnScroll(item.name)?.handleMenuScrollToBottom
                                    }
                                  />
                                );
                              }}
                            />
                          );
                        }

                        if (item.isRadioButtons) {
                          return (
                            <Field
                              key={item.label}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              render={({ field, onCustomChange }) => (
                                <CustomRadioGroup
                                  data={item.data}
                                  value={field.value}
                                  label={item.label}
                                  width={item.width}
                                  tooltip={
                                    field.name === 'hasEntirePortfolio' && !isDBAdmin ? (
                                      <MTooltip
                                        text={item.tooltip}
                                        customStyles={{
                                          position: 'absolute',
                                          top: '-10px',
                                          right: '-30px',
                                        }}
                                      />
                                    ) : null
                                  }
                                  radioButtonWidth={item.radioButtonWidth}
                                  onChange={onCustomChange(field.onChange)}
                                  defaultValue={getRadioGroupDefaultValue(item.data)}
                                  disabled={item.name === 'hasEntirePortfolio' && !isDBAdmin}
                                />
                              )}
                            />
                          );
                        }

                        if (item?.isSpace) {
                          return <div style={{ width: '100%' }} />;
                        }

                        if (item.isTable) {
                          return (
                            <Field
                              key={item.label}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              render={({ field }) => {
                                return field?.value?.length ? (
                                  <Table
                                    columns={getTableColumns(item.name)}
                                    rows={field.value}
                                    total={field?.value?.length}
                                    pagination={false}
                                    loading={false}
                                    isWithCheckbox={false}
                                    onDeleteRow={(index, original) =>
                                      handleDeleteRow(item.name, original)
                                    }
                                  />
                                ) : null;
                              }}
                            />
                          );
                        }

                        if (item.isPassword) {
                          return (
                            <Field
                              key={item.label}
                              name={item.name}
                              isMandatory={id ? false : item.isMandatory}
                              render={({ field, error, onCustomChange }) => (
                                <Password
                                  isMandatory={id ? false : item.isMandatory}
                                  error={error}
                                  width={item.width}
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  onChange={onCustomChange(field.onChange)}
                                  value={field.value}
                                  filled={!!id}
                                />
                              )}
                            />
                          );
                        }

                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, error }) => (
                              <EditInputWithLabel
                                type="text"
                                error={error}
                                label={item.label}
                                placeholder={item.placeholder}
                                width={item.width}
                                name={item.name}
                                isMandatory={item.isMandatory}
                                value={field.value}
                                onChange={field.onChange}
                                isDisabled={defineFieldDisabledOrNot(item.name, item.isDisabled)}
                              />
                            )}
                          />
                        );
                      })}
                    </Stack>
                    {form.getFieldState('communitiesAndAddresses').error &&
                      getIsComAndAddSection(data) && (
                        <Typography color="error">
                          {invalidCommunitiesAndAddressesMessage}
                        </Typography>
                      )}
                  </ShadowBlock>
                );
              })}
            </Stack>
            <Box
              position="absolute"
              left="50%"
              bottom={0}
              sx={{ transform: 'translateX(-50%)' }}
              zIndex={10}
              width={1}
              p={4}
              boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
            >
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <MButton
                  variant="secondary"
                  size="large"
                  onClick={handleCancel}
                  sx={{ width: '144px' }}
                >
                  Cancel
                </MButton>
                <MButton
                  type="submit"
                  size="large"
                  name="Submit community"
                  sx={{ width: '144px' }}
                  onClick={validateComAndAddBeforeSubmit}
                >
                  Save
                </MButton>
              </Stack>
            </Box>
          </Form>
        </>
      ) : (
        <Box
          position="absolute"
          top="50vh"
          left="50%"
          zIndex={10}
          transform="translate(-50%, -50%)"
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default ClientPortalUser;
