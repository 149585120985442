import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
  Stack,
  Typography,
  Container,
  Box,
  CircularProgress,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import print from 'print-js';
import {
  getStates,
  resetProposedFormOfJudgementsPage,
  resetCourtNamesList,
  resetJudgementsList,
  getJudgementsList,
  getCourtNamesList,
} from '../../../../store/slices/schedule/proposedFormOfJudgementsSlice';
import SearchInputWithLabelAndIcon from '../../../atoms/SearchInputWithLabelAndIcon/SearchInputWithLabelAndIcon';
import DatePickerWithLabel from '../../../atoms/DatePickerWithLabel/DatePickerWithLabel';
import {
  JUDGEMENTS_PAGE_SIZE,
  ACCORDION_TABLE_COLUMNS,
  SHOW_DROPDOWN_OPTIONS,
  PROPOSED_FORM_OF_JUDGEMENTS_FITER,
  UNSENT_UNPRINTED,
  SENT_PRINTED,
  COURTNAMES_PAGE_SIZE,
} from './ProposedFormOfJudgments.constants';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import { ReactComponent as PrinterActive } from '../../../../assets/icons/PrinterWhiteIcon.svg';
import { ReactComponent as SendActive } from '../../../../assets/icons/SendActive.svg';
import MCheckbox from '../../../atoms/MCheckbox';
import Table from '../../../atoms/Table/Table';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { convertToBinaryFileUrl } from '../../../../utils/binaryHelpers';
import { apiClient } from '../../../../lib/apiClient';
import MPagination from '../../../atoms/MPagination';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import FullscreenLoaderWithText from '../../../atoms/FullscreenLoaderWithText/FullscreenLoaderWithText';
import { getCounties } from '../../../../store/slices/editCourtSlice';
import SearchableCheckboxSelectWithLabel from '../../../atoms/SearchableCheckboxSelectWithLabel/SearchableCheckboxSelectWithLabel';

const ProposedFormOfJudgments = () => {
  useSafeLocationState('Schedule', 'Proposed Form of Judgments');
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {
      courtName: null,
      show: null,
      courtDate: null,
      county: null,
    },
  });

  const [checkedRows, setCheckedRows] = useState({});
  const [selectedState, setSelectedState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [counties, setCounties] = useState([]);

  const { courtNamesList, judgementsList } = useSelector((state) => state.proposedFormOfJudgements);

  const getCountyList = async () => {
    const res = await dispatch(getCounties('AZ'));
    const result = res.payload?.result?.map((item) => {
      return {
        id: item.id,
        label: item.name,
        value: item.id,
      };
    });
    setCounties(result);
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(getStates())
      .then(() => {
        setSelectedState({ id: 1, label: 'AZ', value: 'AZ' });
        getCountyList();
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      dispatch(resetProposedFormOfJudgementsPage());
    };
  }, []);

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: courtNamesList?.hasNextPage,
    getAction: ({ input, page, value }) => {
      const values = form.getValues();
      const countiesValues = values.county?.length ? values.county.map((item) => item.id) : [];
      return getCourtNamesList({
        currentPage: page,
        stateCode: value || '',
        pageSize: COURTNAMES_PAGE_SIZE,
        courtName: input,
        counties: countiesValues,
        open: false,
      });
    },
    actionArguments: selectedState,
  });

  const onCloseCourtNameMenu = () => {
    dispatch(resetCourtNamesList());
  };

  const onOpenCourtNameMenu = () => {
    setIsLoading(true);
    const values = form.getValues();
    const countiesValues = values.county?.length ? values.county.map((item) => item.id) : [];
    dispatch(
      getCourtNamesList({
        currentPage: 1,
        stateCode: selectedState?.value,
        pageSize: COURTNAMES_PAGE_SIZE,
        courtName: '',
        open: true,
        counties: countiesValues,
      }),
    ).then(() => {
      setIsLoading(false);
    });
  };

  const getHandlers = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case 'courtName':
          return {
            handleSearch: changeInput,
            onMenuScrollToBottom: handleMenuScrollToBottom,
            onMenuClose: onCloseCourtNameMenu,
            onMenuOpen: onOpenCourtNameMenu,
          };
        default:
          return {
            handleSearch: null,
            onMenuScrollToBottom: null,
            onMenuClose: null,
            onMenuOpen: null,
          };
      }
    },
    [changeInput, handleMenuScrollToBottom],
  );

  const getOptions = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case 'courtName':
          return courtNamesList?.items || [];
        case 'county':
          return counties || [];
        case 'show':
          return SHOW_DROPDOWN_OPTIONS;
        default:
          return [];
      }
    },
    [courtNamesList, counties],
  );

  const clearFilterHandler = () => {
    dispatch(resetJudgementsList());
    form.reset({
      courtName: null,
      show: null,
      courtDate: null,
      county: null,
    });
  };

  const handleCheckbox = (idx, items) => {
    setCheckedRows((prev) => {
      const prevCopy = _.cloneDeep(prev);

      if (prevCopy[idx]) {
        delete prevCopy[idx];
        return { ...prevCopy };
      }

      return {
        ...prevCopy,
        [idx]: items,
      };
    });
  };

  const adaptRows = (rows) => {
    return rows.map((item) => ({
      ...item,
      currentState: selectedState?.value,
    }));
  };

  const isCheckboxDisabled = (rowItems) => {
    return rowItems.items.some((i) => i.status === 'Processing');
  };

  const isFooterDisabled = useMemo(() => {
    return _.isEmpty(checkedRows);
  }, [Object.values(checkedRows)]);

  const getDocumentsIdsOfCheckedRows = useMemo(() => {
    if (!Object.values(checkedRows)?.length) return [];
    return Object.values(checkedRows)
      .flatMap((item) => item)
      .map((item) => ({
        caseId: item.caseId,
        documentId: item.documentId,
      }));
  }, [Object.values(checkedRows)?.length]);

  const prepareGetJudgementsListParams = ({ values, currentPage }) => {
    return {
      currentPage,
      pageSize: JUDGEMENTS_PAGE_SIZE,
      ...(values?.courtName && { courtName: values.courtName.value }),
      // check if we use id
      ...(values?.county?.length && { counties: values.county.map((item) => item.id) }),
      ...(values?.courtDate && {
        courtDate: moment(values.courtDate, moment.ISO_8601).format('YYYY-MM-DDTHH:mm:ss'),
      }),
      ...(values?.show && {
        showUnPrintedAndUnSentOnly: values.show.value === UNSENT_UNPRINTED,
        showPrintedOrSentOnly: values.show.value === SENT_PRINTED,
      }),
    };
  };

  const handleChangePage = (e, page) => {
    dispatch(
      getJudgementsList(
        prepareGetJudgementsListParams({ values: form.getValues(), currentPage: page }),
      ),
    );
  };

  const handleSend = useCallback(() => {
    setIsLoading(true);
    const params = getDocumentsIdsOfCheckedRows.map((item) => ({ documentId: item.documentId }));
    apiClient
      .post(`/api/arizonaCases/judgements/send`, { selectedDocumentIds: params })
      .then(() => {
        const values = form.getValues();
        dispatch(
          getJudgementsList(prepareGetJudgementsListParams({ values, currentPage: 1 })),
        ).finally(() => {
          setCheckedRows({});
          setIsLoading(false);
        });
      });
  }, [getDocumentsIdsOfCheckedRows]);

  const [filesToPrint, setFilesToPrint] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const isPrintingEnabled = useRef(true);

  const prepareDocuments = ({ documents, shouldPrint, shouldPrintSend }) => {
    return documents.map((item) => ({
      shouldPrint,
      shouldPrintSend,
      caseId: item.caseId,
      documentId: item.documentId,
    }));
  };

  const handlePrint = useCallback(() => {
    isPrintingEnabled.current = true;
    setFilesToPrint(
      prepareDocuments({
        documents: getDocumentsIdsOfCheckedRows,
        shouldPrint: true,
      }),
    );
  }, [getDocumentsIdsOfCheckedRows]);

  const handlePrintSend = useCallback(() => {
    isPrintingEnabled.current = true;
    setFilesToPrint(
      prepareDocuments({
        documents: getDocumentsIdsOfCheckedRows,
        shouldPrintSend: true,
      }),
    );
  }, [getDocumentsIdsOfCheckedRows]);

  useEffect(() => {
    if (filesToPrint.length === 0) {
      setIsPrinting(false);
      return;
    }
    if (!isPrintingEnabled.current) return;

    setIsPrinting(true);
    const [firstDocument, ...rest] = filesToPrint;
    const { caseId, documentId, shouldPrint, shouldPrintSend } = firstDocument;

    apiClient
      .get(`/api/cases/${caseId}/documents/${documentId}`)
      .then((response) => {
        if (!isPrintingEnabled.current) return;
        const {
          data: { result },
        } = response;
        const { contentType, content } = result;

        const fileURL = convertToBinaryFileUrl({ contentType, content });

        print({
          printable: fileURL,
          onPrintDialogClose: async () => {
            if (shouldPrintSend) {
              await apiClient.post(`/api/arizonaCases/judgements/sendAndPrint`, {
                selectedDocumentIds: [{ documentId }],
              });
            }
            if (shouldPrint) {
              await apiClient.post(`/api/arizonaCases/judgements/print`, {
                selectedDocumentIds: [{ documentId }],
              });
            }

            if (!rest.length) {
              dispatch(
                getJudgementsList(
                  prepareGetJudgementsListParams({
                    values: form.getValues(),
                    currentPage: 1,
                  }),
                ),
              ).finally(() => {
                setCheckedRows({});
              });
            }

            setFilesToPrint(rest);
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filesToPrint.length]);

  const cancelPrinting = () => {
    isPrintingEnabled.current = false;
    setFilesToPrint([]);
    setIsPrinting(false);
  };

  const printAsOne = (e, item) => {
    e.stopPropagation();
    isPrintingEnabled.current = true;
    const documentIds = item.items.map((i) => i.documentId);

    setIsPrinting(true);
    apiClient.post(`/api/cases/documents/concat`, { documentIds }).then((response) => {
      if (!isPrintingEnabled.current) return;
      const {
        data: { result },
      } = response;
      const { contentType, content } = result;

      const fileURL = convertToBinaryFileUrl({ contentType, content });

      print({
        printable: fileURL,
        onPrintDialogClose: () => {
          apiClient
            .post(`/api/arizonaCases/judgements/print`, {
              selectedDocumentIds: documentIds.map((documentId) => ({ documentId })),
            })
            .finally(() => {
              setIsPrinting(false);
            });
        },
      });
    });
  };

  const handleSubmit = form.handleSubmit((values) => {
    setCheckedRows({});
    setIsLoading(true);
    dispatch(getJudgementsList(prepareGetJudgementsListParams({ values, currentPage: 1 }))).then(
      () => setIsLoading(false),
    );
  });

  const handleChangeCounties = (ev, field) => {
    field.onChange(ev);
    form.setValue('courtName', null);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        px: 4,
      }}
    >
      {isLoading ? (
        <Box
          position="absolute"
          width="100%"
          height="calc(100% - 80px)"
          top="-80px"
          left="0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={10}
          transform="translate(-50%, -50%)"
        >
          <CircularProgress />
        </Box>
      ) : null}
      {isPrinting && (
        <FullscreenLoaderWithText
          title="Document is loading. Please wait."
          description={filesToPrint.length ? `(${filesToPrint.length} document(s) left)` : ''}
          actions={
            <MButton size="large" onClick={cancelPrinting}>
              Cancel
            </MButton>
          }
        />
      )}
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '32px',
        }}
      >
        <Typography variant="h3" pb={1}>
          Proposed Form of Judgments
        </Typography>
      </Stack>
      <Form onSubmit={handleSubmit} form={form}>
        <Stack
          display="flex"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          columnGap={3}
          rowGap={1}
          flexWrap="wrap"
          mb={4}
        >
          {PROPOSED_FORM_OF_JUDGEMENTS_FITER.map((item) => {
            const { handleSearch, onMenuScrollToBottom, onMenuClose, onMenuOpen } = getHandlers(
              item.name,
            );

            if (item.isCheckboxSelect) {
              return (
                <Field
                  key={item.name}
                  isMandatory={item.isMandatory}
                  name={item.name}
                  render={({ field, onCustomChange, error }) => (
                    <SearchableCheckboxSelectWithLabel
                      label={item.label}
                      value={field.value}
                      width={item.width}
                      error={error}
                      isMandatory={item.isMandatory}
                      isDisabled={item.isDisabled}
                      placeholder={item.placeholder}
                      onChange={onCustomChange((ev) => handleChangeCounties(ev, field))}
                      options={getOptions(item.name)}
                      onEnter={handleSubmit}
                      showSelectAll
                      allOption={{
                        id: 'all',
                        label: 'All counties',
                        value: 'all',
                      }}
                    />
                  )}
                />
              );
            }
            if (item.isDropdown) {
              return (
                <Field
                  name={item.name}
                  isMandatory={item.isMandatory}
                  render={({ field, onCustomChange }) => {
                    return (
                      <SearchInputWithLabelAndIcon
                        key={item.name}
                        label={item.label}
                        placeholder={item.placeholder}
                        isDropdown={item.isDropdown}
                        options={getOptions(item.name)}
                        name={item.name}
                        width={item.width}
                        height={item.height}
                        handleChange={onCustomChange(field.onChange)}
                        handleSearch={handleSearch}
                        onMenuScrollToBottom={onMenuScrollToBottom}
                        onMenuClose={onMenuClose}
                        onMenuOpen={onMenuOpen}
                        value={field.value}
                        isDisabled={false}
                        containerMarginBottom="0px"
                        onEnter={handleSubmit}
                      />
                    );
                  }}
                />
              );
            }

            if (item.isDate) {
              return (
                <Field
                  name={item.name}
                  isMandatory={item.isMandatory}
                  render={({ field, onCustomChange }) => {
                    return (
                      <DatePickerWithLabel
                        key={item.name}
                        name={item.name}
                        label={item.label}
                        value={field.value}
                        dateFormat={item.dateFormat}
                        onChangeValue={onCustomChange(field.onChange)}
                        width={item.width}
                        height={item.height}
                        disabled={false}
                        onEnter={handleSubmit}
                      />
                    );
                  }}
                />
              );
            }
            return null;
          })}
          <Stack display="flex" direction="row" columnGap={1} alignItems="center">
            <MButton
              variant="bordered"
              type="submit"
              disabled={isLoading}
              sx={{
                maxHeight: '36px',
              }}
            >
              Apply
            </MButton>
            <MButton variant="borderLess" onClick={clearFilterHandler} disabled={isLoading}>
              Clear filter
            </MButton>
          </Stack>
        </Stack>
      </Form>
      <Stack gap={2} pb={25}>
        {!judgementsList ? (
          <Stack
            alignItems="center"
            height="100%"
            display="flex"
            justifyContent="flex-start"
            mt={10}
          >
            <EmptyBlock
              desc="Use filters to find proposed form of judgments"
              descFontSize="32px"
              width="auto"
            />
          </Stack>
        ) : null}
        {judgementsList && !judgementsList?.items?.length ? (
          <Stack alignItems="center" height="100%" display="flex" justifyContent="center">
            <EmptyBlock desc="You don't have any judgments yet" descFontSize="32px" />
          </Stack>
        ) : null}
        {judgementsList?.items?.length
          ? judgementsList.items.map((item, idx) => {
              return (
                <Accordion
                  sx={{
                    '&': {
                      border: `2px solid ${palette.additional.lines}`,
                      borderRadius: '16px !important',
                      boxShadow: 'none',
                    },
                    '&:before': {
                      display: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Stack direction="row" alignItems="center" width="100%">
                      <MCheckbox
                        isChecked={checkedRows[idx]}
                        onChange={() => handleCheckbox(idx, item.items)}
                        isDisabled={isCheckboxDisabled(item)}
                      />
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="bodyL500" mr={2}>
                          {item.courtName}
                        </Typography>
                        <Typography variant="bodyL500">
                          {moment(item.courtDate).format('L')}
                        </Typography>
                      </Box>
                      <MButton
                        sx={{ ml: 'auto', mr: 3 }}
                        size="medium"
                        startIcon={<PrinterActive />}
                        onClick={(e) => printAsOne(e, item)}
                      >
                        Print
                      </MButton>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table columns={ACCORDION_TABLE_COLUMNS} rows={adaptRows(item.items)} />
                  </AccordionDetails>
                </Accordion>
              );
            })
          : null}
        {judgementsList?.totalPages > 1 && (
          <MPagination
            currentPage={judgementsList?.currentPage}
            count={judgementsList?.totalPages}
            handleChange={handleChangePage}
          />
        )}
      </Stack>
      <Box
        position="absolute"
        left="50%"
        bottom={0}
        sx={{ transform: 'translateX(-50%)' }}
        zIndex={10}
        width={1}
        p={4}
        bgcolor="#FFF"
        boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
      >
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <MButton
            size="large"
            sx={{ height: '48px', width: '144px' }}
            startIcon={<PrinterActive />}
            onClick={handlePrint}
            disabled={isFooterDisabled}
          >
            Print
          </MButton>
          <MButton
            size="large"
            sx={{ height: '48px', width: '144px' }}
            startIcon={<SendActive />}
            onClick={handleSend}
            disabled={isFooterDisabled}
          >
            Send
          </MButton>
          <MButton
            size="large"
            sx={{ height: '48px', width: '155px' }}
            onClick={handlePrintSend}
            disabled={isFooterDisabled}
          >
            Send and Print
          </MButton>
        </Stack>
      </Box>
    </Container>
  );
};

export default ProposedFormOfJudgments;
