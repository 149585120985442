import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { palette } from '../../../theme/default/palette';

const variants = {
  default: {
    boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
  },
  dropdownCard: {
    boxShadow: `0px 3px 20px ${palette.shadow.boxContrast}`,
  },
};

const ShadowBlock = ({ children, variant, customStyles }) => {
  const styles = useMemo(
    () => ({
      borderRadius: 6,
      p: 4,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      ...(variants[variant] || variants.default),
      ...customStyles,
    }),
    [variant],
  );

  return (
    <Box component="section" sx={styles} data-testid="shadow_block">
      {children}
    </Box>
  );
};

ShadowBlock.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  customStyles: PropTypes.shape({}),
};

ShadowBlock.defaultProps = {
  variant: 'default',
  customStyles: {},
};

export default ShadowBlock;
