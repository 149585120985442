import { useEffect, useState } from 'react';

export const useEllipsisTooltip = (ref, additionalDependency) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (ref && (ref.offsetHeight < ref.scrollHeight || ref.offsetWidth < ref.scrollWidth)) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [ref, window.innerWidth, ref?.textContent, ref?.value, additionalDependency]);

  return {
    showTooltip,
  };
};
