import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SubPageHeading from '../../../organisms/SubPageHeading';
import {
  subPageHeading,
  subPageNavAZ,
  subPageNavNV,
  subPageNavCivilMatterAZ,
  subPageNavCivilMatterNV,
} from './ViewCase.constants';
import { CIVIL_MATTER_CASE } from '../../../../global/Case.constants';
// import EstimatedFeesBlock from '../../../molecules/EstimatedFeesBlock/EstimatedFeesBlock';
import { apiClient } from '../../../../lib/apiClient';
import { useBaseUrl } from '../../../../hooks/useBaseUrl';

const ViewCase = () => {
  const {
    state: { id, navPage, subNav, defendantName = '', refNum = '', stateCode, caseType },
  } = useLocation();
  const base = useBaseUrl();
  // const [estimatedFeesAmount, setEstimatedFeesAmount] = useState(null);
  const [setEstimatedFeesAmount] = useState(null);

  const subPageNavExtended = useMemo(() => {
    let subNavigation = stateCode === 'AZ' ? subPageNavAZ(base) : subPageNavNV(base);

    if (caseType === CIVIL_MATTER_CASE) {
      subNavigation =
        stateCode === 'AZ' ? subPageNavCivilMatterAZ(base) : subPageNavCivilMatterNV(base);
    }

    return subNavigation.map((item) => ({
      ...item,
      state: {
        stateCode,
        navPage,
        subNav,
        id,
        defendantName,
        refNum,
        caseType: caseType || null,
      },
    }));
  }, [stateCode, id, defendantName]);

  useEffect(() => {
    const fetcher = async () => {
      const apiPath =
        stateCode === 'AZ' ? `/cp/api/arizonaCase/${id}/fees` : `/cp/api/nevadaCases/${id}/fees`;

      const {
        data: { result },
      } = await apiClient.get(apiPath);
      setEstimatedFeesAmount(result?.estimatedAmount ?? null);
    };
    fetcher();
  }, [id, stateCode]);

  return (
    <>
      <SubPageHeading
        subPageHeading={
          defendantName && refNum ? `Ref. # ${refNum} | ${defendantName}` : subPageHeading
        }
        // temporary hidden functionality according to CLAR-1065
        // subPageHeadingRight={<EstimatedFeesBlock estimatedAmount={estimatedFeesAmount} />}
        subPageNav={subPageNavExtended}
        nav={{
          to: `/${base}/cases/casesearch`,
          state: {
            navPage: 'Cases',
          },
        }}
      />
      <Outlet />
    </>
  );
};

export default ViewCase;
