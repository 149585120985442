/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getManagements = createAsyncThunk('management/getManagements', async (params) => {
  const { data } = await apiClient.post(`/api/managements/filter`, params);

  return data;
});

export const deleteManagement = createAsyncThunk(
  'management/deleteManagement',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.delete(`/api/managements/${id}`);

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

const initialState = {
  managements: {},
  error: null,
};

export const managementSlice = createSlice({
  name: 'management',
  initialState,
  reducers: {},
  extraReducers: {
    [getManagements.fulfilled]: (state, action) => {
      state.managements = action.payload.result;
    },
    [getManagements.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteManagement.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default managementSlice.reducer;
