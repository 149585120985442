import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Container, Stack } from '@mui/material';
import ShadowBlock from '../../../../atoms/ShadowBlock';
import ChangeLog from '../../../../molecules/ChangeLog';
import AddCommentNew from '../../../../molecules/AddComment/AddCommentNew';
import { dataAddComment } from '../../../../molecules/AddComment/constant';
import NotesNew from '../../../../organisms/Notes/NotesNew';
import {
  getAddressNotes,
  editAddressNotes,
} from '../../../../../store/slices/addresses/editAddressSlice';
import { MAX_AMOUNT_OF_ITEMS_ON_PAGE } from '../../../../molecules/ChangeLog/ChangeLog.constants';
import { getAddressLogs } from '../../../../../store/slices/logsSlice';

const AddressNotes = () => {
  const dispatch = useDispatch();
  const {
    state: { id },
  } = useLocation();
  const [isLoader, setIsLoader] = useState(true);
  const { notes } = useSelector((state) => state.editAddress);

  const [isLogLoader, setIsLogLoader] = useState(true);
  const data = useSelector((state) => state.logs.addressLogs);

  const onFetchPage = useCallback(
    (params = { pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE, currentPage: 1 }) => {
      setIsLogLoader(true);

      return dispatch(
        getAddressLogs({
          ...params,
          id,
        }),
      ).then(() => {
        setIsLogLoader(false);
      });
    },
    [id],
  );

  useEffect(() => {
    onFetchPage();
  }, [id]);

  useEffect(() => {
    dispatch(getAddressNotes(id)).then(() => setIsLoader(false));
  }, [id]);

  const handleAddComment = useCallback((comment) => {
    setIsLoader(true);
    dispatch(
      editAddressNotes({
        id,
        addressId: id,
        comment,
      }),
    ).then(() => dispatch(getAddressNotes(id)).then(() => setIsLoader(false)));
  }, []);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        px: 4,
        maxWidth: 1542,
        width: 1,
      }}
    >
      <Stack spacing={4} pb={4}>
        <ShadowBlock>
          <>
            {!!notes?.length && !isLoader && <NotesNew title="Address Notes" list={notes} />}
            {isLoader && (
              <Box
                position="absolute"
                top="50%"
                left="50%"
                zIndex={10}
                transform="translate(-50%, -50%)"
              >
                <CircularProgress />
              </Box>
            )}
            <AddCommentNew
              title={dataAddComment.title}
              inputPlaceholder={dataAddComment.inputPlaceholder}
              buttonText={dataAddComment.buttonText}
              onAddComment={handleAddComment}
            />
          </>
        </ShadowBlock>

        <ChangeLog
          rows={data?.items ?? []}
          total={data?.totalRowsCount}
          isPagination={data?.totalPages > 1}
          onFetchPage={onFetchPage}
          loading={isLogLoader}
        />
      </Stack>
    </Container>
  );
};

export default AddressNotes;
