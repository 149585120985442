import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useSearchDropdown = ({
  hasNextPage,
  getAction,
  callback = () => {},
  actionArguments,
  disabled = false,
}) => {
  const [page, setPage] = useState(1);
  const [input, setInput] = useState('');
  const dispatch = useDispatch();

  const changeInput = (value = '') => {
    setInput(value);
    setPage(1);
  };

  const handleMenuScrollToBottom = (hasNextPageArg) => {
    if (hasNextPage || hasNextPageArg) {
      setPage((x) => x + 1);
    }
  };

  const getDebounced = useCallback(
    debounce((params) => {
      const { input: value } = params;
      const searchValue = value?.length;

      const call = getAction
        ? (data) => {
            if (getAction(data)) dispatch(getAction(data));
          }
        : callback;

      if (!searchValue || searchValue >= 3) {
        call({ ...params, ...actionArguments });
      }
    }, 500),
    [actionArguments],
  );

  useEffect(() => {
    if (!disabled) {
      getDebounced({
        page,
        input,
      });
    }
  }, [page, input, getDebounced, disabled]);

  return { changeInput, handleMenuScrollToBottom };
};

export default useSearchDropdown;
