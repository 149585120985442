import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

// import useAuth from '../../../hooks/useAuth';
import { useAvailableStatesList } from '../../../hooks/useAvailableStatesList';
import Field from '../../atoms/Field';
import Dropdown from '../../atoms/Dropdown';
import { optionsByState } from './LoginWidget.constants';

const LADashboardSettings = ({ form }) => {
  const { settings } = useSelector((state) => state.legalAssistantDashboard);

  const states = useAvailableStatesList();
  const [currentStateForLADashboard, setCurrentStateForLADashboard] = useState(states[0]);

  useEffect(() => {
    const formState = form.getValues('LAdashboard.state');
    if (currentStateForLADashboard.value !== formState) {
      form.setValue('LAdashboard.state', currentStateForLADashboard);
    }
  }, [currentStateForLADashboard, form.getValues('LAdashboard.state')]);

  useEffect(() => {
    if (settings) {
      if (currentStateForLADashboard?.value === 'AZ') {
        const defaultNoticesToBeDrafted = optionsByState.AZ.noticesToBeDrafted.find(
          (item) => !!item.default,
        );
        const defaultCasesToBeEntered = optionsByState.AZ.casesToBeEntered.find(
          (item) => !!item.default,
        );
        const defaultPrefilesToBePrinted = optionsByState.AZ.prefilesToBePrinted.find(
          (item) => !!item.default,
        );
        const defaultFollowUps = optionsByState.AZ.followUps.find((item) => !!item.default);

        const currentNoticesToBeDrafted = optionsByState.AZ.noticesToBeDrafted.find(
          (item) => item.value === settings.arizonaLegalAssistant?.noticesToBeDrafted,
        );
        const currentCasesToBeEntered = optionsByState.AZ.casesToBeEntered.find(
          (item) => item.value === settings.arizonaLegalAssistant?.casesToBeEntered,
        );
        const currentPrefilesToBePrinted = optionsByState.AZ.prefilesToBePrinted.find(
          (item) => item.value === settings.arizonaLegalAssistant?.prefilesToBePrinted,
        );
        const currentFollowUps = optionsByState.AZ.followUps.find(
          (item) => item.value === settings.arizonaLegalAssistant?.followUps,
        );

        form.setValue(
          'LAdashboard.noticesToBeDrafted',
          currentNoticesToBeDrafted || defaultNoticesToBeDrafted,
        );
        form.setValue(
          'LAdashboard.casesToBeEntered',
          currentCasesToBeEntered || defaultCasesToBeEntered,
        );
        form.setValue(
          'LAdashboard.prefilesToBePrinted',
          currentPrefilesToBePrinted || defaultPrefilesToBePrinted,
        );
        form.setValue('LAdashboard.followUps', currentFollowUps || defaultFollowUps);
      } else {
        const defaultNoticesToBeDrafted = optionsByState.NV.noticesToBeDrafted.find(
          (item) => !!item.default,
        );
        const defaultCasesToBeEntered = optionsByState.NV.casesToBeEntered.find(
          (item) => !!item.default,
        );
        const defaultFollowUps = optionsByState.NV.followUps.find((item) => !!item.default);

        const currentNoticesToBeDrafted = optionsByState.AZ.noticesToBeDrafted.find(
          (item) => item.value === settings.nevadaLegalAssistant?.noticesToBeDrafted,
        );
        const currentCasesToBeEntered = optionsByState.AZ.casesToBeEntered.find(
          (item) => item.value === settings.nevadaLegalAssistant?.casesToBeEntered,
        );

        const currentFollowUps = optionsByState.AZ.followUps.find(
          (item) => item.value === settings.nevadaLegalAssistant?.followUps,
        );

        form.setValue(
          'LAdashboard.noticesToBeDrafted',
          currentNoticesToBeDrafted || defaultNoticesToBeDrafted,
        );
        form.setValue(
          'LAdashboard.casesToBeEntered',
          currentCasesToBeEntered || defaultCasesToBeEntered,
        );
        form.setValue('LAdashboard.followUps', currentFollowUps || defaultFollowUps);
      }
    }
  }, [settings, currentStateForLADashboard]);

  const onStateChangeForLADashboard = (ev) => {
    form.setValue('LAdashboard.state', ev.target.value);
    setCurrentStateForLADashboard(ev.target.value);
  };

  return (
    <>
      <Typography variant="h5" sx={{ marginBottom: '16px', marginTop: '32px' }}>
        Legal Assistant Dashboard
      </Typography>
      <Typography variant="bodyM" color="text.secondary" paragraph mb={1}>
        Configure default values for displaying Legal Assistant dashboard sections
      </Typography>

      <Field
        name="LAdashboard.state"
        isMandatory
        render={({ field, onCustomChange }) => {
          return (
            <Dropdown
              label={
                <Typography
                  variant="bodyM"
                  color="text.secondary"
                  mb={0}
                  sx={{ position: 'relative', left: '-16px' }}
                  width="167px"
                >
                  Select state
                </Typography>
              }
              name="LAdashboard.state"
              value={field.value || states[0]}
              onChange={onCustomChange(onStateChangeForLADashboard)}
              options={states}
            />
          );
        }}
      />
      <Stack direction="row" alignItems="center" mb={1} mt={1}>
        <Typography
          textAlign="right"
          variant="bodyM"
          color="text.secondary"
          paragraph
          mr={2}
          mb={0}
          width="167px"
        >
          Notices To Be Drafted
        </Typography>
        <Field
          name="LAdashboard.noticesToBeDrafted"
          isMandatory
          render={({ field, onCustomChange }) => (
            <Dropdown
              isColumn
              width="493px"
              value={field.value}
              onChange={onCustomChange(field.onChange)}
              options={
                currentStateForLADashboard.value === 'AZ'
                  ? optionsByState.AZ.noticesToBeDrafted
                  : optionsByState.NV.noticesToBeDrafted
              }
            />
          )}
        />
      </Stack>

      <Stack direction="row" alignItems="center" mb={1}>
        <Typography
          textAlign="right"
          variant="bodyM"
          color="text.secondary"
          paragraph
          mr={2}
          mb={0}
          width="167px"
        >
          Cases To Be Entered
        </Typography>
        <Field
          name="LAdashboard.casesToBeEntered"
          isMandatory
          render={({ field, onCustomChange }) => (
            <Dropdown
              isColumn
              width="493px"
              value={field.value}
              onChange={onCustomChange(field.onChange)}
              options={
                currentStateForLADashboard.value === 'AZ'
                  ? optionsByState.AZ.casesToBeEntered
                  : optionsByState.NV.casesToBeEntered
              }
            />
          )}
        />
      </Stack>
      {form.getValues('LAdashboard.state')?.value === 'AZ' && (
        <Stack direction="row" alignItems="center" mb={1}>
          <Typography
            textAlign="right"
            variant="bodyM"
            color="text.secondary"
            paragraph
            mr={2}
            mb={0}
            width="167px"
          >
            Prefiles To Be Printed
          </Typography>
          <Field
            name="LAdashboard.prefilesToBePrinted"
            isMandatory
            render={({ field, onCustomChange }) => (
              <Dropdown
                isColumn
                width="493px"
                value={field.value}
                onChange={onCustomChange(field.onChange)}
                options={optionsByState.AZ.prefilesToBePrinted}
              />
            )}
          />
        </Stack>
      )}
      <Stack direction="row" alignItems="center" mb={4}>
        <Typography
          textAlign="right"
          variant="bodyM"
          color="text.secondary"
          paragraph
          mr={2}
          mb={0}
          width="167px"
        >
          Follow-Ups
        </Typography>
        <Field
          name="LAdashboard.followUps"
          isMandatory
          render={({ field, onCustomChange }) => (
            <Dropdown
              isColumn
              width="493px"
              value={field.value}
              onChange={onCustomChange(field.onChange)}
              options={
                currentStateForLADashboard.value === 'AZ'
                  ? optionsByState.AZ.followUps
                  : optionsByState.NV.followUps
              }
            />
          )}
        />
      </Stack>
    </>
  );
};

LADashboardSettings.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    watch: PropTypes.func,
    setValue: PropTypes.func,
  }).isRequired,
};

export default LADashboardSettings;
