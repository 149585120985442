/* eslint-disable */
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useTable, usePagination, useRowSelect, useBlockLayout } from 'react-table';
import { PropTypes } from 'prop-types';
import { clone } from 'ramda';
import MCheckbox from '../MCheckbox';
import TablePagination from '../../molecules/TablePagination';
import style from './Table.module.scss';
import { useSticky } from 'react-table-sticky';
import styled from 'styled-components';
import useWindowSize from '../../../hooks/useWindowSize';
import { palette } from '../../../theme/default';
import { ReactComponent as SortIcon } from '../../../../src/assets/icons/Sort.svg';
import { ReactComponent as SortAscIcon } from '../../../../src/assets/icons/SortAsc.svg';
import { ReactComponent as SortDescIcon } from '../../../../src/assets/icons/SortDesc.svg';
import { IconButton } from '@mui/material';
import { SORT_ORDER_ASC } from '../../../hooks/useSortTable';

const Styles = styled.div`
  .table {
    .th {
      background-color: #ebedfa;
      padding: 8px;
      text-align: left;
      color: #54577a;
      font-size: 16px;
      font-weight: 600;
    }
    .td {
      background-color: #fff;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid var(--table_border);
      padding: 8px;
      color: #030410;
    }

    .th,
    .td {
      overflow: ${(props) => (props.overflowHidden ? 'hidden' : 'visible')};
      :last-child {
        border-right: 0;
      }
    }

    .body .tr:last-of-type .td {
      border-bottom: 1px solid #ebedfa;
    }

    &.sticky {
      overflowx: scroll;

      .header {
        position: sticky;
        z-index: 5;
      }

      .header {
        top: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 4px 25px 10px 0px rgb(0 0 0 / 4%);
      }

      [data-sticky-first-right-td] {
        box-shadow: -4px 25px 10px 0px rgb(0 0 0 / 4%);
      }
    }
  }
`;

export const TableLoader = () => (
  <div className={style.loaderContainer} data-testid="table_loader">
    <div className={style.loader} />
  </div>
);

const Table = ({
  pageSize = 20,
  columns,
  loading = false,
  total,
  onChange,
  rows: data,
  isPagination = false,
  onUpdateData,
  onDeleteRow,
  onRowClick,
  onPrintRow,
  onDownloadRow,
  onMakeActiveRow,
  onCheckedRow,
  isWithCheckbox = false,
  onNextPage,
  onPreviousPage,
  onGotoPage,
  onRefreshPage,
  hasFixedColumns,
  maxHeight,
  disableTableEdit,
  getRowIndexToEdit,
  showFixedColumnsAtWidth,
  overflowHidden,
  autoResetPage,
  onPagination,
  hasTotalRow,
  onModalOpenWhenClickEdit,
  onSortTable,
  sortField,
  sortOrder,
  stickyWithRightButton,
  getRowId,
  checkAllRecords,
  checkedAll,
  hideAllCheckbox,
  refAll,
  wasAllOperated,
}) => {
  const tableRef = useRef();
  const allChecked = useRef(false);
  const { width } = useWindowSize();

  const pageCount = useMemo(() => Math.ceil(total / pageSize), [total]);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [editableRow, setEditableRow] = useState({});

  const handleSetEditableRowIndex = (index) => {
    // PREVENT OPEN EDIT FIELDS IN THE TABLE.
    if (disableTableEdit) {
      getRowIndexToEdit(index);
      return;
    }

    setEditableRow(clone(data[index]));
    setEditableRowIndex(index);
  };

  const handleUpdateMyData = (index) => {
    onChange(data[index]);
  };

  const handleUpdateData = async () => {
    if (onUpdateData) {
      const result = await onUpdateData(editableRowIndex, editableRow);
      if (result === false) return;
    }
    setEditableRowIndex(null);
  };
  const handleCancelEditing = () => {
    setEditableRowIndex(null);
    setEditableRow({});
  };
  const handleChangeCell = (cellName, cellValue) => {
    setEditableRow((row) => ({ ...row, [cellName]: cellValue }));
  };

  const handleDeleteRow = (index, original) => {
    return onDeleteRow?.(index, original, data[index]);
  };

  const handleModalOpenWhenClickEdit = (index, original) => {
    onModalOpenWhenClickEdit(index, original);
  };

  const handleRowClick = (row) => {
    onRowClick?.(row);
  };

  const handlePrintRow = (index, original) => {
    onPrintRow?.(index, original, data[index]);
  };

  const handleDownloadRow = (index, original) => {
    onDownloadRow?.(index, original, data[index]);
  };

  const handleMakeActiveRow = (index, original) => {
    onMakeActiveRow?.(index, original, data[index]);
  };

  const getSortIcon = (columnName) => {
    const isActive = columnName === sortField;
    if (!sortOrder || !isActive) {
      return <SortIcon />;
    }
    return sortOrder === SORT_ORDER_ASC ? <SortAscIcon /> : <SortDescIcon />;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data: data ? data : [],
      initialState: { pageSize },
      manualPagination: true,
      pageCount: pageCount,
      updateMyData: handleUpdateMyData,
      editableRowIndex,
      setEditableRowIndex: handleSetEditableRowIndex,
      setEditableRow,
      updateData: handleUpdateData,
      changeValueCell: handleChangeCell,
      cancelEditing: handleCancelEditing,
      deleteRow: handleDeleteRow,
      openModalWhenClickEdit: handleModalOpenWhenClickEdit,
      rowClick: handleRowClick,
      printRow: handlePrintRow,
      downloadRow: handleDownloadRow,
      makeActive: handleMakeActiveRow,
      autoResetPage,
      getRowId,
    },
    usePagination,
    useRowSelect,
    useBlockLayout,
    useSticky,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return isWithCheckbox
          ? [
              {
                id: 'selection',
                // eslint-disable-next-line
                Header: ({ getToggleAllRowsSelectedProps }) => {
                  const handleChange = (ev, checked) => {
                    allChecked.current = checked;
                    getToggleAllRowsSelectedProps().onChange(ev, checked);
                    checkAllRecords?.(checked);
                  };

                  const isChecked = checkAllRecords
                    ? allChecked.current ||
                      (total === data?.length && getToggleAllRowsSelectedProps().checked)
                    : getToggleAllRowsSelectedProps().checked;

                  return (
                    <MCheckbox
                      {...getToggleAllRowsSelectedProps()}
                      isChecked={isChecked}
                      onChange={handleChange}
                      sx={{ visibility: hideAllCheckbox ? 'hidden' : 'visible' }}
                    />
                  );
                },
                accessor: 'checkbox',
                width: 40,
                minWidth: 40,
                maxWidth: 40,
                // eslint-disable-next-line
                Cell: ({ row }) => {
                  const handleChange = (ev) => {
                    row.getToggleRowSelectedProps().onChange(ev);
                    if (!ev.target.checked) {
                      allChecked.current = false;
                      checkAllRecords?.(false);
                    }
                  };
                  return (
                    <MCheckbox
                      // eslint-disable-next-line
                      {...row.getToggleRowSelectedProps()}
                      // eslint-disable-next-line
                      isChecked={
                        row?.original?.isCheckboxDisabled
                          ? false
                          : row.getToggleRowSelectedProps().checked
                      }
                      isDisabled={row?.original?.isCheckboxDisabled}
                      onChange={handleChange}
                    />
                  );
                },
              },
              ...columns,
            ]
          : columns;
      });
    },
  );

  const hasStickyElement = hasFixedColumns && width <= showFixedColumnsAtWidth;

  const totalWidth = useMemo(
    () => headerGroups?.[0]?.headers.reduce((sum, column) => sum + (column.totalWidth || 0), 0),
    [],
  );

  useEffect(() => {
    // this functionality for Notices, as we set all for all pages
    if (checkedAll) {
      toggleAllRowsSelected(checkedAll);
    }
  }, [checkedAll, page]);

  useEffect(() => {
    // it can be applied as common approach to operate by all checked outside the table
    // TODO: refactoring to combine Notices approach and this one
    if (refAll && wasAllOperated) {
      toggleAllRowsSelected(refAll.checked);
    }
  }, [refAll?.checked, wasAllOperated]);

  useEffect(() => {
    if (isWithCheckbox) {
      const documents = selectedFlatRows
        .map((data) => data.original)
        .filter((item) => !item.isCheckboxDisabled);
      const selectedIds = [...Object.keys(selectedRowIds)];
      onCheckedRow && onCheckedRow(documents, selectedIds);
    }
  }, [selectedFlatRows]);

  const handleNextPage = () => {
    onNextPage(pageIndex + 2);
    nextPage();
  };

  const handleRefreshPage = () => {
    onRefreshPage(pageIndex + 1);
  };

  const handlePreviousPage = () => {
    onPreviousPage(pageIndex);
    previousPage();
  };

  const handleGotoPage = (item) => {
    onGotoPage(item + 1);
    gotoPage(item);
  };

  const handleSortTable = (columnId, columnSortDescFirst) => {
    onSortTable(columnId, columnSortDescFirst);
    gotoPage(0);
  };

  useEffect(() => {
    onPagination?.({
      pageIndex,
      nextPage,
      previousPage,
      currentPageSize: page.length,
      canNextPage,
    });
  }, [pageIndex, nextPage, previousPage, page.length, canNextPage]);

  if (loading) {
    return <TableLoader />;
  }

  if (isWithCheckbox) {
    headerGroups[0].headers[0].sticky = 'left';
  }

  if (stickyWithRightButton) {
    headerGroups[headerGroups.length - 1].headers[
      headerGroups[headerGroups.length - 1].headers.length - 2
    ].sticky = 'right';
  }

  const colWidths = [];

  return (
    page.length > 0 && (
      <div className={style.tableWrapper} data-testid="table_with_pages">
        <div
          className={style.tableContainer}
          style={{ overflowY: overflowHidden ? 'auto' : 'visible' }}
        >
          <Styles overflowHidden={overflowHidden}>
            <div
              ref={tableRef}
              {...getTableProps()}
              className={`table ${hasStickyElement && 'sticky'}`}
              style={{ maxHeight, width: hasStickyElement ? totalWidth : '100%' }}
            >
              <div className="header">
                {headerGroups.map((headerGroup) => {
                  return (
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      className="tr"
                      style={{
                        ...headerGroup.getHeaderGroupProps(),
                        width: '100%',
                        display: 'flex',
                      }}
                      data-testid="header_group"
                    >
                      {headerGroup.headers.map((column, id) => {
                        colWidths.push({
                          width: `${column.width}%`,
                          minWidth: `${column.minWidth}px`,
                          maxWidth: isWithCheckbox && (id === 0 ? `${column.minWidth}px` : null),
                        });
                        return (
                          <div
                            {...column.getHeaderProps()}
                            className="th"
                            data-testid="header"
                            style={{
                              ...column.getHeaderProps().style,
                              display: 'flex',
                              justifyContent: 'flex-start',
                              gap: '8px',
                              alignItems: 'center',
                              width: `${column.width}%`,
                              minWidth: `${column.minWidth}px`,
                              maxWidth:
                                isWithCheckbox && (id === 0 ? `${column.minWidth}px` : null),
                            }}
                          >
                            {column.render('Header')}
                            {column.isSortable ? (
                              <IconButton
                                data-testid={`sort_${column.id}_button`}
                                onClick={() => handleSortTable(column.id, column.sortDescFirst)}
                              >
                                {getSortIcon(column.id)}
                              </IconButton>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <div {...getTableBodyProps()} className="body">
                {page.map((row, index) => {
                  const isTotalRow = hasTotalRow && page.length - 1 === index;
                  prepareRow(row);
                  return (
                    <div
                      key={index}
                      {...row.getRowProps()}
                      className={row.original?.className ? `tr ${row.original?.className}` : 'tr'}
                      style={{
                        ...row.getRowProps(),
                        minWidth: '100%',
                        display: 'flex',
                      }}
                      data-testid="row"
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <div
                            key={index}
                            {...cell.getCellProps()}
                            className="td"
                            data-testid="cell"
                            style={{
                              ...cell.getCellProps().style,
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: columns[index]?.alignItems || 'center',
                              width: colWidths[index].width,
                              minWidth: colWidths[index].minWidth,
                              maxWidth: colWidths[index].maxWidth,
                              ...(isTotalRow && {
                                borderBottom: `1px solid #AEB1D2`,
                                borderTop: `2px solid #AEB1D2`,
                                backgroundColor: palette.additional.tableTotalBackground,
                                fontSize: '16px',
                                fontWeight: 500,
                              }),
                            }}
                          >
                            {cell.render('Cell', {
                              goNextPage: handleNextPage,
                              goPreviousPage: handlePreviousPage,
                              refreshPage: handleRefreshPage,
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </Styles>
        </div>
        {isPagination && (
          <TablePagination
            previousPage={handlePreviousPage}
            canPreviousPage={canPreviousPage}
            nextPage={handleNextPage}
            canNextPage={canNextPage}
            page={hasTotalRow ? page.slice(0, -1) : page}
            total={total}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            gotoPage={handleGotoPage}
            pageSize={pageSize}
          />
        )}
      </div>
    )
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  loading: PropTypes.bool,
  isWithCheckbox: PropTypes.bool,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  showFixedColumnsAtWidth: PropTypes.number,
  maxHeight: PropTypes.string,
  onChange: PropTypes.func,
  isPagination: PropTypes.bool,
  onUpdateData: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onRowClick: PropTypes.func,
  onPagination: PropTypes.func,
  onPrintRow: PropTypes.func,
  onDownloadRow: PropTypes.func,
  onMakeActiveRow: PropTypes.func,
  onCheckedRow: PropTypes.func,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onRefreshPage: PropTypes.func,
  getRowIndexToEdit: PropTypes.func,
  onGotoPage: PropTypes.func,
  rows: PropTypes.array,
  hasFixedColumns: PropTypes.bool,
  disableTableEdit: PropTypes.bool,
  overflowHidden: PropTypes.bool,
  autoResetPage: PropTypes.bool,
  hasTotalRow: PropTypes.bool,
  stickyWithRightButton: PropTypes.bool,
  checkAllRecords: PropTypes.func,
  checkedAll: PropTypes.bool,
  getRowId: PropTypes.func,
  hideAllCheckbox: PropTypes.bool,
  wasAllOperated: PropTypes.bool,
};

Table.defaultProps = {
  hasFixedColumns: false,
  isWithCheckbox: false,
  maxHeight: 'auto',
  overflowHidden: true,
  showFixedColumnsAtWidth: 0,
  autoResetPage: true,
  hasTotalRow: false,
  stickyWithRightButton: false,
  getRowId: undefined,
  checkedAll: false,
  checkAllRecords: null,
  hideAllCheckbox: false,
  wasAllOperated: false,
};

export default Table;
