export const defaultOptionPage = {
  value: 'Case Search',
  label: 'Case Search',
  href: '/db/processing/casesearch',
};

export const defaultCPOptionPage = {
  value: 'Cases',
  label: 'Cases',
  href: '/cp/cases/casesearch',
};

export const defaultClientOptionPage = {
  value: 'Cases',
  label: 'Cases',
  href: '/client/cases/casesearch',
};

export const optionsByState = {
  AZ: {
    noticesToBeDrafted: [
      { value: 'all notices', label: 'All notices', default: true },
      { value: 'unassigned', label: 'Unassigned' },
      { value: 'my notices', label: 'My notices' },
    ],
    casesToBeEntered: [
      { value: 'all cases', label: 'All cases' },
      { value: 'unassigned', label: 'Unassigned' },
      { value: 'my cases', label: 'My Cases', default: true },
    ],
    prefilesToBePrinted: [
      { value: 'all prefiles', label: 'All prefiles' },
      { value: 'my prefiles', label: 'My prefiles', default: true },
    ],
    followUps: [
      { value: 'all cases', label: 'All cases' },
      { value: 'my cases', label: 'My Cases', default: true },
    ],
  },
  NV: {
    noticesToBeDrafted: [
      { value: 'all notices', label: 'All notices', default: true },
      { value: 'unassigned', label: 'Unassigned' },
      { value: 'my notices', label: 'My notices' },
    ],
    casesToBeEntered: [
      { value: 'all cases', label: 'All cases' },
      { value: 'unassigned', label: 'Unassigned' },
      { value: 'my cases', label: 'My Cases', default: true },
    ],
    followUps: [
      { value: 'all cases', label: 'All cases' },
      { value: 'my cases', label: 'My Cases', default: true },
    ],
  },
};

export const attorneyDashboardOptions = {
  calendarRange: [
    { value: 'day', label: 'Day', default: true },
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
  ],
  attorneyReviewDate: [
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week', default: true },
    { value: 'month', label: 'Month' },
    { value: 'all', label: 'All' },
  ],
};
