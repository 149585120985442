/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const editOwnerEntity = createAsyncThunk(
  'ownerEntity/editOwnerEntity',
  async (editOwnerEntityObj, { rejectWithValue }) => {
    const { id, ...fields } = editOwnerEntityObj;
    try {
      const { data } = await apiClient.put(`/api/ownerEntities/${id}`, fields);

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const getOwnerEntity = createAsyncThunk('ownerEntity/getOwnerEntity', async (id) => {
  const { data } = await apiClient.get(`/api/ownerEntities/${id}`);

  return data;
});

export const deleteOwnerEntity = createAsyncThunk(
  'ownerEntity/deleteOwnerEntity',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.delete(`/api/ownerEntities/${id}`);

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const editOwnerEntityNotes = createAsyncThunk(
  'ownerEntity/editCommunityNotes',
  async (editOwnerEntityNotesObj) => {
    const { id, ...fields } = editOwnerEntityNotesObj;
    const { data } = await apiClient.post(`/api/ownerEntities/${id}/notes`, fields);

    return data;
  },
);

export const getOwnerEntityNotes = createAsyncThunk(
  'ownerEntity/getOwnerEntityNotes',
  async (id) => {
    const { data } = await apiClient.get(`/api/ownerEntities/${id}/notes`);

    return data;
  },
);

export const getOwnerEntityAddresses = createAsyncThunk(
  'ownerEntity/getOwnerEntityAddresses',
  async (getOwnerEntityAddressesObj) => {
    const { id, ...fields } = getOwnerEntityAddressesObj;
    const { data } = await apiClient.post(`/api/ownerEntities/${id}/addresses/filter`, fields);

    return data;
  },
);

export const editOwnerEntityContacts = createAsyncThunk(
  'ownerEntity/editOwnerEntityContacts',
  async (editOwnerEntityContactsObj) => {
    const { id, ...fields } = editOwnerEntityContactsObj;
    const { data } = await apiClient.post(`/api/ownerEntities/${id}/contacts`, fields);

    return data;
  },
);

export const getOwnerEntityContacts = createAsyncThunk(
  'ownerEntity/getOwnerEntityContacts',
  async (id) => {
    const { data } = await apiClient.get(`/api/ownerEntities/${id}/contacts`);

    return data;
  },
);

export const deleteOwnerEntityContacts = createAsyncThunk(
  'ownerEntity/deleteOwnerEntityContacts',
  async (args) => {
    const { id, contactId } = args;
    const { data } = await apiClient.delete(`/api/ownerEntities/${id}/contacts/${contactId}`);
    return data;
  },
);

export const getContacts = createAsyncThunk('ownerEntity/getContacts', async (params = {}) => {
  const { data } = await apiClient.post(`/api/contacts/filter`, { params });
  return data;
});

export const getStates = createAsyncThunk('ownerEntity/states', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data;
});

const initialState = {
  editableOwnerEntity: {},
  editableOwnerEntityContacts: [],
  notes: [],
  addresses: {},
  contacts: [],
  states: [],
  error: null,
};

export const editCommunitySlice = createSlice({
  name: 'editOwnerEntity',
  initialState,
  reducers: {},
  extraReducers: {
    [editOwnerEntity.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getOwnerEntity.fulfilled]: (state, action) => {
      state.editableOwnerEntity = action.payload.result;
    },
    [getOwnerEntity.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteOwnerEntity.fulfilled]: (state, action) => {
      state.editableOwnerEntity = action.payload;
    },
    [deleteOwnerEntity.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editOwnerEntityNotes.fulfilled]: (state, action) => {
      state.notes = action.payload;
    },
    [editOwnerEntityNotes.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getOwnerEntityNotes.fulfilled]: (state, action) => {
      state.notes = action.payload.result;
    },
    [getOwnerEntityNotes.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getOwnerEntityAddresses.fulfilled]: (state, action) => {
      state.addresses = action.payload.result;
    },
    [getOwnerEntityAddresses.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editOwnerEntityContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getOwnerEntityContacts.fulfilled]: (state, action) => {
      state.editableOwnerEntityContacts = action.payload.result;
    },
    [getOwnerEntityContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteOwnerEntityContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getContacts.fulfilled]: (state, action) => {
      state.contacts = action.payload.result;
    },
    [getContacts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.result.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.code,
      }));
    },
    [getStates.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default editCommunitySlice.reducer;
