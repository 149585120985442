import React from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Page from '../../../organisms/Page/Page';

const Processing = () => {
  const { refNum, defendantName } = useParams();
  const { state } = useLocation();

  const navPageString = state && state.navPage;
  const subNavString = state && state.subNav;
  const subSubNavigation = state && state.subSubNavigation;

  return (
    <Page
      navPage={navPageString}
      subNavigation={subNavString}
      subSubNavigation={subSubNavigation}
      id={refNum}
      caseName={defendantName}
    >
      <Outlet />
    </Page>
  );
};

export default Processing;
