import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import uniqBy from 'lodash/uniqBy';
import { Box, CircularProgress, Container, Stack, Typography, styled } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import Dropdown from '../../../../atoms/Dropdown';
import Form from '../../../../atoms/Form';
import Field from '../../../../atoms/Field';
import Table from '../../../../atoms/Table/Table';
import EditInputWithLabel from '../../../../molecules/EditInputWithLabel';
import DialogContacts from '../../../../organisms/DialogContacts';
import { palette } from '../../../../../theme/default';
import { createOwnerEntity } from '../../../../../store/slices/createOwnerEntitySlice';
import {
  editOwnerEntity,
  getOwnerEntity,
  getContacts,
  editOwnerEntityContacts,
  deleteOwnerEntityContacts,
  getOwnerEntityContacts,
} from '../../../../../store/slices/editOwnerEntitySlice';
import useOwnerEntity from '../../../../../hooks/useOwnerEntity';
import { ownerEntityHeading, ownerEntityBoxes, contactColumns } from './OwnerEntity.constants';
import { ALL_STATES } from '../../../../../global/constants';
import { useNavigationBlockPopup } from '../../../../../hooks/useNavigationBlockPopup';
import notificationUtils from '../../../../../utils/notificationUtils';
import LoaderCover from '../../../../atoms/LoaderCover';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';

const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

const OwnerEntity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    state: { navPage, subNav, id },
  } = useLocation();
  const { popup, handleChangeBlockerByDefault } = useNavigationBlockPopup({
    blockerByDefault: !id,
  });

  const [isPageLoader, setIsPageLoader] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [isOpenDialogContacts, setIsOpenDialogContacts] = useState(false);

  const { editableOwnerEntity, editableOwnerEntityContacts } = useSelector(
    (state) => state.editOwnerEntity,
  );
  const { state, actions } = useOwnerEntity();

  const form = useForm({ defaultValues: state });
  const canUpdate = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);
  useEffect(() => {
    if (id) {
      dispatch(getOwnerEntity(id)).then(() => setIsPageLoader(false));
    } else {
      setIsPageLoader(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      form.reset({
        ...editableOwnerEntity,
        contactInfo: {
          ...editableOwnerEntity.contactInfo,
          stateCode: ALL_STATES.find(
            (item) => item.label === editableOwnerEntity?.contactInfo?.stateCode,
          ),
        },
      });
    }
  }, [id, editableOwnerEntity]);

  useEffect(() => {
    if (id) {
      form.setValue('contacts', editableOwnerEntityContacts);
    }
  }, [id, editableOwnerEntityContacts]);

  useEffect(() => {
    dispatch(getContacts());
  }, []);

  const getOptions = useCallback((inputBoxesItemName) => {
    switch (inputBoxesItemName) {
      case 'contactInfo.stateCode':
        return ALL_STATES;
      default:
        return [];
    }
  }, []);

  const handleClickAddContact = useCallback(() => {
    setIsOpenDialogContacts(true);
  }, []);

  // eslint-disable-line no-unused-vars
  const handleDeleteContact = (idRow, { contactId }) => {
    if (id) {
      setIsLoader(true);
      dispatch(deleteOwnerEntityContacts({ id, contactId })).then(() => {
        setIsLoader(false);
        dispatch(getOwnerEntityContacts(id));
      });
    } else {
      const addedContacts = form.getValues('contacts') || [];
      const filteredContacts = addedContacts.filter((item) => item.contactId !== contactId);
      form.setValue('contacts', filteredContacts);
    }
  };

  const handleSubmit = useCallback((formFields) => {
    setIsLoader(true);
    if (id) {
      dispatch(
        editOwnerEntity({
          ...formFields,
          id,
          contactInfo: {
            ...formFields?.contactInfo,
            stateCode: formFields?.contactInfo?.stateCode?.label,
          },
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(getOwnerEntity(id));
          notificationUtils.success('Updated successfully');
        })
        .catch((rejectedValueError) => {
          notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
        })
        .finally(() => {
          setIsLoader(false);
        });
    } else {
      handleChangeBlockerByDefault(false);
      dispatch(
        createOwnerEntity({
          ...formFields,
          contactInfo: {
            ...formFields?.contactInfo,
            stateCode: formFields?.contactInfo?.stateCode?.label,
          },
        }),
      )
        .unwrap()
        .then((originalPromiseResult) => {
          const { result } = originalPromiseResult;
          notificationUtils.success('Created successfully');
          navigate('/db/datahandling/editownerentity/ownerentity', {
            state: {
              navPage,
              subNav,
              id: result,
              name: formFields.name,
            },
          });
          actions?.clear();
          form.reset();
        })
        .catch((rejectedValueError) => {
          notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
        })
        .finally(() => {
          setIsLoader(false);
        });
    }
  }, []);

  const handleCancel = useCallback(() => {
    navigate('/db/datahandling/ownerentities', {
      state: {
        navPage,
        subNav,
      },
    });
  }, []);

  const handleConfirmDialogContacts = useCallback(
    (selectedIdsContacts) => {
      if (id) {
        dispatch(
          editOwnerEntityContacts({
            id,
            ownerEntityId: id,
            selectedContacts: selectedIdsContacts,
          }),
        ).then(() => {
          dispatch(getOwnerEntityContacts(id));
        });
      } else {
        const currentContacts = form.getValues('contacts') || [];
        const contacts = currentContacts.concat(selectedIdsContacts);
        const uniqValues = uniqBy(contacts, (e) => e.contactId);
        form.setValue('contacts', uniqValues);
      }
      setIsOpenDialogContacts(false);
    },
    [id],
  );

  const handleCloseDialogContacts = useCallback(() => {
    setIsOpenDialogContacts(false);
  }, []);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      {popup}
      {!isPageLoader ? (
        <>
          {isLoader && <LoaderCover isFixed />}
          <Typography variant="h4">{ownerEntityHeading}</Typography>
          <Form onSubmit={handleSubmit} form={form}>
            {ownerEntityBoxes?.map((data) => {
              return (
                <Container
                  key={data.name}
                  maxWidth={false}
                  disableGutters
                  sx={{
                    mx: 'auto',
                    mt: 4,
                    width: 1,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={3}
                    rowGap={3}
                    flexWrap="wrap"
                  >
                    {data.inputBoxes.map((item) => {
                      if (item.isDropdown) {
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, onCustomChange, error }) => {
                              if (id && !canUpdate)
                                return (
                                  <EditInputWithLabel
                                    type="text"
                                    label={item.label}
                                    width={item.width}
                                    name={item.name}
                                    value={field.value?.label || '-'}
                                    readOnly
                                  />
                                );
                              return (
                                <Dropdown
                                  value={field.value}
                                  isAddDisabled={false}
                                  isSearchable
                                  key={item.label}
                                  label={item.label}
                                  width={item.width}
                                  isColumn
                                  placeholder={item.placeholder}
                                  options={getOptions(item.name)}
                                  onChange={onCustomChange(field.onChange)}
                                  isMandatory={item.isMandatory}
                                  error={error}
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item.isSubHeading) {
                        return (
                          <StyledTypography width={1} mb={1} variant="bodyL500" key={item.label}>
                            {item.label}
                          </StyledTypography>
                        );
                      }

                      if (item.isSubHeadingWithButton) {
                        return (
                          <Stack
                            direction="row"
                            alignContent="center"
                            columnGap={2}
                            mt={3}
                            key={item.label}
                            width={1}
                          >
                            <StyledTypography variant="bodyL500">{item.label}</StyledTypography>
                            <MButton startIcon={item.buttonIcon} onClick={handleClickAddContact}>
                              {item.buttonName}
                            </MButton>
                          </Stack>
                        );
                      }

                      if (item.isTable) {
                        return (
                          <>
                            <Field
                              key={item.label}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              render={({ field }) => {
                                return (
                                  !!field?.value?.length && (
                                    <Table
                                      columns={contactColumns}
                                      rows={field.value.map((contact) => ({
                                        ...contact,
                                        control: {
                                          hideDelete: !contact?.contactId,
                                        },
                                      }))}
                                      total={field?.value?.length}
                                      onDeleteRow={handleDeleteContact}
                                      loading={false}
                                    />
                                  )
                                );
                              }}
                            />
                            <DialogContacts
                              title="Contact Person"
                              buttonSecondaryText="Cancel"
                              buttonPrimaryText="Add"
                              onClose={handleCloseDialogContacts}
                              onConfirm={handleConfirmDialogContacts}
                              isOpen={isOpenDialogContacts}
                            />
                          </>
                        );
                      }

                      return (
                        <Field
                          key={item.label}
                          name={item.name}
                          isMandatory={item.isMandatory}
                          render={({ field, error }) => (
                            <EditInputWithLabel
                              type="text"
                              label={item.label}
                              placeholder={item.placeholder}
                              width={item.width}
                              name={item.name}
                              value={id && !canUpdate ? field.value || '-' : field.value}
                              onChange={field.onChange}
                              isDisabled={item.isDisabled}
                              isMandatory={((id && canUpdate) || !id) && item.isMandatory}
                              error={error}
                              readOnly={id && !canUpdate}
                            />
                          )}
                        />
                      );
                    })}
                  </Stack>
                </Container>
              );
            })}
            {((id && canUpdate) || !id) && (
              <Box
                position="absolute"
                left="50%"
                bottom={0}
                sx={{ transform: 'translateX(-50%)' }}
                zIndex={10}
                width={1}
                p={4}
                boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
              >
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <MButton
                    variant="secondary"
                    size="large"
                    onClick={handleCancel}
                    sx={{ width: '144px' }}
                    disabled={isLoader}
                  >
                    Cancel
                  </MButton>
                  <MButton
                    type="submit"
                    size="large"
                    name="Submit community"
                    sx={{ width: '144px' }}
                    disabled={isLoader}
                  >
                    {id ? 'Update' : 'Create'}
                  </MButton>
                </Stack>
              </Box>
            )}
          </Form>
        </>
      ) : (
        <Box
          position="absolute"
          top="50vh"
          left="50%"
          zIndex={10}
          transform="translate(-50%, -50%)"
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default OwnerEntity;
