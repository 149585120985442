import React, { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../MUI/MUITextInput';

export const ActiveTextInputCell = ({
  value: initialValue,
  changeValueCell,
  column,
  editableRowIndex,
  setEditableRowIndex,
  row: { index },
  updateData,
}) => {
  const [value, setValue] = useState('');

  const handleClickEdit = useCallback(() => {
    setEditableRowIndex(index);
  }, [index, setEditableRowIndex]);

  const handleChange = useCallback(
    (e) => {
      const {
        target: { value: inputValue },
      } = e;
      if (editableRowIndex !== index) setEditableRowIndex(index);
      setValue(inputValue);
      changeValueCell(
        column.id,
        typeof initialValue === 'object' ? { ...initialValue, value: inputValue } : inputValue,
      );
    },
    [handleClickEdit, editableRowIndex, index, setEditableRowIndex],
  );

  useLayoutEffect(() => {
    setValue(typeof initialValue === 'object' ? initialValue.value : initialValue);
  }, [initialValue]);

  if (column.edit) {
    return (
      <TextInput
        value={value}
        onChange={handleChange}
        onClick={handleClickEdit}
        onFocus={handleClickEdit}
        onBlur={updateData}
        height={32}
        data-testid="input_wrapper"
        inputProps={{ 'data-testid': 'input' }}
      />
    );
  }

  return value;
};

ActiveTextInputCell.propTypes = {
  value: PropTypes.string.isRequired,
  changeValueCell: PropTypes.func.isRequired,
  column: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    edit: PropTypes.bool,
  }).isRequired,
  updateData: PropTypes.func.isRequired,
  editableRowIndex: PropTypes.number.isRequired,
  setEditableRowIndex: PropTypes.func.isRequired,
  row: PropTypes.shape({ index: PropTypes.number }).isRequired,
};
