import { LinearProgress, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { apiClient } from '../../../../../lib/apiClient';
import NotificationDialog from '../../../../molecules/NotificationDialog';
import MButton from '../../../../MUI/Button/MButton';
import { rolesDB, useUserInfo } from '../../../../../utils/roleHelpers';
import Dropdown from '../../../../atoms/Dropdown';
import notificationUtils from '../../../../../utils/notificationUtils';
import { BulkAssignToOtherDialog } from '../BulkAssignToOtherDialog';
import { LightTooltip } from '../../../../atoms/MTooltip/MTooltip';

const AssignToMeCell = ({
  row: { original },
  value: canAssignReviewingAttorney,
  refreshPage,
  state: { pageIndex },
  // card,
  stateCode,
}) => {
  const { roles, UserGuid } = useUserInfo();
  const { caseId } = original;

  const [otherModalOpen, setOtherModalOpen] = useState(false);
  const isRoleAttorney = roles.includes(rolesDB.Attorney);
  const isRoleAdmin = roles.includes(rolesDB.Admin);
  const bothRoles = isRoleAttorney && isRoleAdmin;

  const [isLoader, setIsLoader] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [error, setError] = useState('');

  const refresh = () => refreshPage(pageIndex + 1);

  const handleClose = () => {
    setShowErrorDialog(false);
    refresh();
  };

  const handleClosed = () => setError('');

  const assignToMe = async () => {
    setIsLoader(true);
    const url = `/api/dashboards/attorney/cards/evictionMatters/assignAttorneyReviewer`;
    try {
      await apiClient.put(url, { caseIds: [caseId], userId: UserGuid });
      notificationUtils.success('Assigned successfully');
      refresh();
    } catch (e) {
      console.log(e);
      setError(
        e.response.data?.errorMessage ||
          'Case has been recently reassigned. Please try again later.',
      );
      setShowErrorDialog(true);
    } finally {
      setIsLoader(false);
    }
  };

  const closeOtherModal = () => {
    setOtherModalOpen(false);
  };

  const assignToOther = async (chosenAssigneeId) => {
    setIsLoader(true);
    const url = `/api/dashboards/attorney/cards/evictionMatters/assignAttorneyReviewer`;
    const ids = [caseId];

    try {
      await apiClient.put(url, { caseIds: ids, userId: chosenAssigneeId });
      notificationUtils.success('Assigned successfully');
      refresh();
    } catch {
      notificationUtils.error('Failed, try again later');
    } finally {
      setIsLoader(false);
    }
    closeOtherModal();
  };

  const handleChange = (option) => {
    if (option.value === 'assignToMe') {
      assignToMe();
    } else if (option.value === 'assignToOther') {
      setOtherModalOpen(true);
    }
  };

  if (isLoader) {
    return (
      <Box sx={{ width: '130px' }}>
        <LinearProgress />
      </Box>
    );
  }

  const renderButton = () => {
    let component = (
      <MButton
        variant="secondary"
        size="small"
        disabled={!canAssignReviewingAttorney}
        onClick={() => setOtherModalOpen(true)}
        sx={{ width: '140px', whiteSpace: 'nowrap' }}
        data-testid="assign_to_other_btn"
      >
        Assign to other
      </MButton>
    );
    if (bothRoles) {
      const options = [
        { value: 'assignToMe', label: 'Assign To Me', id: 'assignToMe' },
        { value: 'assignToOther', label: 'Assign To Other', id: 'assignToOther' },
      ];

      component = (
        <Dropdown
          isMandatory
          isColumn
          placeholder="Assign"
          width="100px"
          height="38px"
          options={options}
          value={null}
          onChange={handleChange}
          testid="assign_select"
          menuPosition="fixed"
          menuStyles={{ width: '150px', right: '0' }}
          isDisabled={!canAssignReviewingAttorney}
        />
      );
    } else if (isRoleAttorney) {
      component = (
        <MButton
          variant="secondary"
          size="small"
          disabled={!canAssignReviewingAttorney}
          onClick={assignToMe}
          data-testid="assign_to_me_btn"
        >
          Assign to me
        </MButton>
      );
      if (!canAssignReviewingAttorney) {
        return (
          <LightTooltip
            sx={{ position: 'absolute' }}
            placement="top"
            title="Case has already been assigned for review"
          >
            <div>{component}</div>
          </LightTooltip>
        );
      }
      return component;
    }

    if (!canAssignReviewingAttorney) {
      return (
        <LightTooltip
          sx={{ position: 'absolute' }}
          placement="top"
          title="Case has already been assigned for review"
        >
          <div>{component}</div>
        </LightTooltip>
      );
    }
    return component;
  };
  return (
    <>
      {otherModalOpen && (
        <BulkAssignToOtherDialog
          title="Assign Cases"
          onClose={closeOtherModal}
          handleSubmit={assignToOther}
          stateCode={stateCode}
        />
      )}
      {renderButton()}
      {showErrorDialog && (
        <NotificationDialog
          type="alert"
          desc={error}
          buttonPrimaryText="Ok"
          onClose={handleClose}
          onClosed={handleClosed}
          onConfirm={handleClose}
          isOpen
          width="400px"
        />
      )}
    </>
  );
};

AssignToMeCell.propTypes = {
  value: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  refreshPage: PropTypes.func.isRequired,
  state: PropTypes.shape({
    pageIndex: PropTypes.number.isRequired,
  }).isRequired,
  // card: PropTypes.string.isRequired,
  stateCode: PropTypes.string.isRequired,
};

export default AssignToMeCell;
