/* eslint-disable */
import * as React from 'react';
import { ReactComponent as Asterisk } from '../../../assets/icons/asterisk.svg';
import { palette } from '../../../theme/default';
import {
  Box,
  Typography,
  Stack,
  styled,
  OutlinedInput,
  MenuItem,
  Select,
  Chip,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { ReactComponent as SelectArrowDown } from '../../../assets/icons/SelectArrowDown.svg';

const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

export default function MultipleSelectChip({
  height,
  value,
  options,
  width,
  label,
  isMandatory,
  onChange,
  placeholder,
  error,
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(value);
  };

  return (
    <Stack display="flex" direction="column" position="relative">
      <Typography
        variant="buttonLarge"
        color="text.secondary"
        marginBottom={0.5}
        component="label"
        data-testid="multiple_select_chip_label"
      >
        {label}
        {isMandatory && (
          <StyledSpan data-testid="multiple_select_chip_asterisk">
            <Asterisk />
          </StyledSpan>
        )}
      </Typography>
      <Select
        sx={{
          height: height,
          width: { width },
          borderRadius: '10px',
          border: `1px solid ${palette.buttonSecondary.disabledBorder}`,
          fieldset: {
            border: 'none',
          },
          '&:hover': {
            borderRadius: '10px',
            border: `1px solid ${palette.buttonPrimary.active}`,
          },
          '&>div': {
            padding: '12px',
          },
        }}
        multiple
        displayEmpty
        IconComponent={SelectArrowDown}
        value={value}
        onChange={handleChange}
        input={<OutlinedInput />}
        data-testid="multiple_select_chip"
        inputProps={{ 'data-testid': 'multiple_select_chip_input' }}
        renderValue={(selected) => {
          if (!selected) return;

          if (selected.length === 0) {
            return (
              <Typography variant="bodyM" color={palette.text.secondary}>
                {placeholder}
              </Typography>
            );
          }
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{ borderRadius: '10px', '& span': { fontSize: '16px', fontWeight: 500 } }}
                />
              ))}
            </Box>
          );
        }}
        error={!!error?.message}
      >
        {options &&
          options.map((item) => (
            <MenuItem
              key={item.id}
              value={item.label}
              sx={{
                height: '32px',
              }}
              data-testid="multiple_select_chip_option"
            >
              <Checkbox checked={value?.indexOf(item.label) > -1} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
      </Select>
      {error?.message && (
        <Typography
          variant="bodyXS"
          color="additional.alert"
          component="span"
          position="absolute"
          left={0}
          top="calc(100% + 4px)"
          data-testid="multiple_select_chip_error_msg"
        >
          {error?.message}
        </Typography>
      )}
    </Stack>
  );
}

MultipleSelectChip.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  height: PropTypes.number,
  width: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.string,
};

MultipleSelectChip.defaultProps = {
  height: 'auto',
  value: undefined,
  isMandatory: false,
  error: '',
};
