import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { PropTypes } from 'prop-types';
import { ButtonBase, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import useAuth from '../../../hooks/useAuth';
import { ReactComponent as User } from '../../../assets/icons/User.svg';
import { ReactComponent as Eye } from '../../../assets/icons/Eye.svg';
import MButton from '../../MUI/Button/MButton';
import MBaseInputWithIcon from '../../atoms/MBaseInputWithIcon';
import ShadowBlock from '../../atoms/ShadowBlock/ShadowBlock';
import { loginConstants } from './Login.constants';
import MTooltip from '../../atoms/MTooltip';
import LoginLayout from './LoginLayout';

const Login = ({ clientOnly }) => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const navigate = useNavigate();

  const { onLogin, error, isRequesting } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [loginErr, setLoginErr] = useState('');

  useEffect(() => {
    if (error) {
      setLoginErr(loginConstants[error?.errorCode]);
    }
  }, [error]);

  return (
    <LoginLayout
      clientOnly={clientOnly}
      renderForm={({ portal }) => (
        <ShadowBlock>
          <Box
            sx={{
              p: 7,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <form onSubmit={handleSubmit(onLogin)}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box component="img" width="239px" height="97px" src={logo} alt="logotype" />
                <Box
                  sx={{ my: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Typography variant="bodySThin">{loginConstants.heading}</Typography>
                </Box>
                <Stack spacing={2}>
                  <MBaseInputWithIcon
                    svg={<User />}
                    width={262}
                    placeholder={loginConstants.user.placeholder}
                    control={control}
                    regConfig={{ required: true }}
                    name="username"
                    error={loginErr}
                  />
                  <MBaseInputWithIcon
                    svg={
                      <ButtonBase
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        data-testid="show_pass"
                      >
                        <Eye />
                      </ButtonBase>
                    }
                    width={262}
                    placeholder={loginConstants.password.placeholder}
                    control={control}
                    regConfig={{ required: true }}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    error={loginErr}
                  />
                </Stack>
                {loginErr && loginErr.length > 0 && (
                  <Typography
                    variant="bodyXS"
                    sx={{ color: 'additional.alert', m: '6px auto 0 0' }}
                  >
                    {loginErr}
                  </Typography>
                )}
                <Stack spacing={2} sx={{ mt: 4, display: 'flex', alignItems: 'center' }}>
                  <MButton
                    type="submit"
                    sx={{ width: '262px', height: '48px' }}
                    disabled={isRequesting}
                  >
                    <Typography variant="buttonLarge">{loginConstants.submitButton}</Typography>
                  </MButton>
                  <Typography
                    variant="buttonSmall"
                    color="text.secondary"
                    sx={{
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    data-testid="forgot_link"
                    onClick={() => navigate(`/${portal.base}/request-recovery`)}
                  >
                    {loginConstants.forgot}
                  </Typography>
                </Stack>
              </Box>
            </form>
          </Box>
          <Typography
            component="a"
            variant="buttonSmall"
            color="text.secondary"
            sx={{
              userSelect: 'none',
              textAlign: 'center',
              textTransform: 'none',
            }}
            href="/pdf/eula.pdf"
            target="_blank"
          >
            {loginConstants.eula}
            <MTooltip
              tooltipVariant="lightviolette"
              text="Login into the system you confirm that the end user license agreement was read and agreed"
            />
          </Typography>
        </ShadowBlock>
      )}
    />
  );
};

Login.propTypes = {
  clientOnly: PropTypes.bool,
};

Login.defaultProps = {
  clientOnly: false,
};

export default Login;
