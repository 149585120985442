import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, styled, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MButton from '../../MUI/Button/MButton';
import { palette } from '../../../theme/default';
import {
  getQuickBookConnectionStatus,
  getQuickBookConnectionUrl,
  updateQuickBookConnection,
} from '../../../store/slices/generateInvoiceSlice';
import { messagesByStatus } from './QuickBookConnect.constants';

const Banner = styled('div')({
  backgroundColor: palette.text.disabled,
  border: `1px solid ${palette.text.tertiary}`,
  color: palette.text.secondary,
  borderRadius: '10px',
  padding: '8px 16px',
  display: 'flex',
  alignItems: 'center',
  minHeight: '36px',
});

const QuickBookConnect = () => {
  const [isLoader, setIsLoader] = useState(true);
  const { status } = useSelector((state) => state.generateInvoice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isActive = Boolean(status?.isActive);
  const redirectUrl = window.location.origin + window.location.pathname;
  const [searchParams] = useSearchParams();
  const { code = '', realmId = '', state = '' } = Object.fromEntries(searchParams);

  const loadData = async () => {
    if (realmId && code && state) {
      await dispatch(
        updateQuickBookConnection({
          redirectUrl,
          code,
          realmId,
          state,
        }),
      );
      navigate(window.location.pathname, { replace: true });
    }
    await dispatch(getQuickBookConnectionStatus());
    setIsLoader(false);
  };
  useEffect(() => {
    loadData();
  }, []);

  const message = messagesByStatus[isActive ? 'active' : 'inactive'];

  const connect = () => {
    dispatch(getQuickBookConnectionUrl(redirectUrl));
    setIsLoader(true);
  };

  if (isLoader) {
    return (
      <Box width="450px" data-testid="quickbookconnect_loader">
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Banner data-testid="quickbookconnect_wrapper">
        {message.icon}
        <Typography
          variant="bodyS"
          lineHeight={1}
          marginLeft={1}
          data-testid="quickbookconnect_text"
        >
          {message.text}
        </Typography>
      </Banner>
      {!isActive && (
        <MButton sx={{ marginLeft: '8px' }} onClick={connect} data-testid="quickbookconnect_button">
          Connect
        </MButton>
      )}
    </>
  );
};

export default QuickBookConnect;
