import React, { useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';

import { ReactComponent as DocumentDownload } from '../../../../assets/icons/DocumentDownload.svg';
import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';
import { apiClient } from '../../../../lib/apiClient';
import notificationUtils from '../../../../utils/notificationUtils';

const DownloadVersionCell = ({ row: { original } }) => {
  const [isLoader, setIsLoader] = useState(false);

  const handleDownload = async () => {
    if (!isLoader) {
      setIsLoader(true);
      try {
        const { data } = await apiClient.get(`/cp/api/legalForms/${original.id}`);
        openOrDownloadBinary({
          contentType: data.result.contentType,
          content: data.result.content,
          fileName: data.result.originalFileName,
        });
      } catch {
        notificationUtils.error('Failed, try again later');
      } finally {
        setIsLoader(false);
      }
    }
  };

  if (isLoader) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <IconButton onClick={handleDownload}>
      <DocumentDownload />
    </IconButton>
  );
};

DownloadVersionCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    original: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default DownloadVersionCell;
