import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';

export const UsersTableColumns = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 40,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 40,
  },
  {
    Header: '',
    accessor: 'control',
    width: 7,
    Cell: DeleteCell,
  },
];
