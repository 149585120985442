/* eslint-disable react/prop-types */
import React from 'react';
import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';
import MRadioButton from '../../../atoms/MRadioButton';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;
export const MAX_HEIGHT_DIALOG = 624;
export const MAX_HEIGHT_DIALOG_TABLE = 326;

export const CourtAddressColumns = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 40,
  },
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 40,
  },
  {
    Header: () => <div style={{ textAlign: 'center' }}>On Hold</div>,
    accessor: 'isOnHold',
    width: 16,
    Cell: ({ row }) => (
      <div style={{ textAlign: 'center' }}>
        <MRadioButton isChecked={row?.original?.isOnHold} />
      </div>
    ),
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];

export const AddressesAllColumns = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 60,
  },
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 25,
  },
  {
    Header: () => <div style={{ textAlign: 'center' }}>On Hold</div>,
    accessor: 'isOnHold',
    width: 15,
    Cell: ({ row }) => (
      <div style={{ textAlign: 'center' }}>
        <MRadioButton isChecked={row?.original?.isOnHold} />
      </div>
    ),
  },
];
