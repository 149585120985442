import React from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';
import { Stack } from '@mui/system';
import { ReactComponent as Trash } from '../../../../../assets/icons/Trash.svg';
import { ReactComponent as Revert } from '../../../../../assets/icons/restore.svg';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';

export const CaseStatusDeleteCell = ({ row: { original }, deleteRow }) => {
  const { mayBeReverted } = original;

  const handleClickRevert = () => deleteRow();
  const handleClickDelete = () => deleteRow(original.caseStatusId);

  const canDelete = useAllowed([rolesDB.Admin]);

  return (
    <Stack width="100%" justifyContent="flex-end" direction="row">
      {mayBeReverted && (
        <IconButton onClick={handleClickRevert} title="Revert">
          <Revert />
        </IconButton>
      )}
      {canDelete && (
        <IconButton onClick={handleClickDelete} title="Delete">
          <Trash />
        </IconButton>
      )}
    </Stack>
  );
};

CaseStatusDeleteCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseStatusId: PropTypes.string,
      mayBeReverted: PropTypes.bool,
    }),
  }).isRequired,
  deleteRow: PropTypes.func.isRequired,
};
