/* eslint-disable */
import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography, Box } from '@mui/material';
import {
  getAllClientPortalUsers,
  deleteUserById,
  getCompanyManagementsList,
  resetUserDetailsState,
  getAllClientPortalRoles,
} from '../../../../store/slices/clientPortalAdminUsers';
import Table, { TableLoader } from '../../../atoms/Table/Table';
import {
  UsersTableColumns,
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
  USER_STATUSES,
  USER_ASSIGNMENTS,
} from './users.constants';
import MButton from '../../../MUI/Button/MButton';
import AddButton from '../../../atoms/AddButton/AddButton';
import EmptyBlock from '../../../molecules/EmptyBlock';
import SearchInputWithLabel from '../../../atoms/SearchInputWithLabel/SearchInputWithLabel';
import SearchInputWithLabelAndIcon from '../../../atoms/SearchInputWithLabelAndIcon/SearchInputWithLabelAndIcon';
import { useNavigate } from 'react-router-dom';
import { debounce, last } from 'lodash';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import AuthContext from '../../../../context/AuthContext';
import { rolesCP } from '../../../../utils/roleHelpers';
import SelfEnrollmentDialog from './SelfEnrollmentDialog';
import StateLink from '../../../atoms/StateLink/StateLink';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import { useBaseUrl } from '../../../../hooks/useBaseUrl';

const ClientPortalUsers = () => {
  useSafeLocationState('Admin', 'Users');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const base = useBaseUrl();

  const { userInfo } = useContext(AuthContext);

  const isClientAdmin = useMemo(() => {
    return userInfo.roles.includes(rolesCP.ClientPortalAdmin);
  }, [userInfo]);

  const { usersTable, companyManagements, roles } = useSelector(
    (state) => state.clientPortalAdminUsers,
  );
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');
  const [hasEntirePortfolio, setHasEntirePortfolio] = useState('');
  const [managementCompany, setManagementCompany] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [isSelfEnrollmentDialogOpen, setSelfEnrollmentDialogOpen] = useState(false);

  const closeSelfEnrollmentDialog = useCallback(() => {
    setSelfEnrollmentDialogOpen(false);
  }, []);

  useEffect(() => {
    setTableLoading(true);
    Promise.all([
      dispatch(getAllClientPortalRoles()),
      dispatch(
        getAllClientPortalUsers({
          currentPage: 1,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          firstName,
          lastName,
          userName,
          email,
          roleId: role?.id || '',
          status: status?.value || '',
          hasEntirePortfolio: hasEntirePortfolio?.value || '',
          ...(!isClientAdmin && { management: null }),
        }),
      ),
    ]).then(() => {
      setIsLoader(false);
      setTableLoading(false);
    });

    if (!isClientAdmin) {
      dispatch(
        getCompanyManagementsList({
          currentPage: 1,
          pageSize: 20,
          managementName: '',
        }),
      );
    }

    dispatch(resetUserDetailsState());
  }, []);

  const handleDeleteUserById = (rowId, { userGuid }) => {
    return dispatch(deleteUserById(userGuid));
  };

  const handleApply = () => {
    setIsLoader(true);
    dispatch(
      getAllClientPortalUsers({
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        firstName,
        lastName,
        userName,
        email,
        roleId: role?.id || '',
        status: status?.value || '',
        hasEntirePortfolio: hasEntirePortfolio?.value || '',
        ...(!isClientAdmin && { management: managementCompany?.label || null }),
      }),
    ).then(() => setIsLoader(false));
  };

  const handleReset = () => {
    setIsLoader(true);
    setFirstName('');
    setLastName('');
    setUserName('');
    setEmail('');
    setRole('');
    setStatus('');
    setHasEntirePortfolio('');
    setManagementCompany('');
    dispatch(
      getAllClientPortalUsers({
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        roleId: '',
        status: '',
        hasEntirePortfolio: '',
        ...(!isClientAdmin && { management: null }),
      }),
    ).then(() => {
      setIsLoader(false);
    });
  };

  const searchWhenType = debounce((value = '') => {
    dispatch(
      getCompanyManagementsList({
        currentPage: 1,
        pageSize: 20,
        managementName: value,
      }),
    );
  }, 300);

  const handleChangeFirstName = (value) => setFirstName(value);
  const handleChangeLastName = (value) => setLastName(value);
  const handleChangeUserName = (value) => setUserName(value);
  const handleChangeEmail = (value) => setEmail(value);
  const handleSelectRole = (value) => setRole(value);
  const handleSelectStatus = (value) => setStatus(value);
  const handleSelectHasEntirePortfolio = (value) => setHasEntirePortfolio(value);
  const handleSelectManagementCompany = (value) => setManagementCompany(value);

  const redirectToNewUserPage = () => {
    navigate(`/${base}/admin/users/newuser`, {
      state: {
        navPage: 'Admin',
        subNav: 'Users',
        subSubNavigation: 'Add New User',
      },
    });
  };

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setTableLoading(true);
      dispatch(
        getAllClientPortalUsers({
          currentPage: currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          firstName,
          lastName,
          userName,
          email,
          roleId: role?.id || '',
          status: status?.value || '',
          hasEntirePortfolio: hasEntirePortfolio?.value || '',
          ...(!isClientAdmin && { management: managementCompany?.label || null }),
        }),
      ).then(() => {
        setTableLoading(false);
      });
    },
    [
      firstName,
      lastName,
      userName,
      email,
      role,
      status,
      hasEntirePortfolio,
      managementCompany?.label,
    ],
  );

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: companyManagements.hasNextPage,
    getAction: ({ input, page }) => {
      if (!isClientAdmin) {
        getCompanyManagementsList({
          currentPage: page,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          managementName: input,
        });
      }
      return null;
    },
  });

  const rowsAdapter = usersTable?.items?.map((item) => ({
    ...item,
    firstName: (
      <StateLink
        to={`/${base}/admin/users/edituser`}
        state={{
          id: item.userGuid,
          navPage: 'Admin',
          subNav: 'Users',
          subSubNavigation: 'Edit User',
        }}
      >
        <Typography
          variant="link"
          color="text.primary"
          sx={{
            cursor: 'pointer',
            textDecorationLine: 'underline',
            textTransform: 'none',
          }}
        >
          {item.firstName}
        </Typography>
      </StateLink>
    ),
    control: {
      autoRefresh: true,
    },
  }));

  const filtersMapper = [
    {
      value: firstName,
      label: 'First Name',
      placeholder: 'Enter first name',
      width: '228px',
      onChangeValue: handleChangeFirstName,
    },
    {
      value: lastName,
      placeholder: 'Enter last name',
      label: 'Last Name',
      width: '228px',
      onChangeValue: handleChangeLastName,
    },
    {
      value: userName,
      label: 'User Name',
      placeholder: 'Enter user name',
      width: '228px',
      onChangeValue: handleChangeUserName,
    },
    {
      value: email,
      label: 'Email',
      placeholder: 'Enter email',
      width: '228px',
      onChangeValue: handleChangeEmail,
    },
    {
      isDropdown: true,
      isDisabled: !roles.length,
      options: roles,
      value: role,
      label: 'Role',
      placeholder: 'Select role',
      width: '198px',
      onChangeValue: handleSelectRole,
    },
    {
      isDropdown: true,
      options: USER_STATUSES,
      value: status,
      label: 'Status',
      placeholder: 'Select status',
      width: '198px',
      onChangeValue: handleSelectStatus,
    },
    {
      isDropdown: true,
      options: USER_ASSIGNMENTS,
      value: hasEntirePortfolio,
      label: 'Assignment',
      placeholder: 'Select assignment',
      width: '198px',
      onChangeValue: handleSelectHasEntirePortfolio,
    },
  ];

  return (
    <>
      <Box width="80vw" marginX="auto">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">Users</Typography>
          <Stack direction="row" columnGap={2}>
            <AddButton onClick={redirectToNewUserPage} label="Add new user" width="auto" />
            <MButton onClick={() => setSelfEnrollmentDialogOpen(true)} variant="secondary">
              Generate Self Enrollment Link(s)
            </MButton>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          mb={4}
          mt={4}
          flex
          alignItems="center"
          flexWrap="wrap"
          columnGap={1}
          rowGap={2}
        >
          {filtersMapper.map((item) => {
            if (item.isDropdown) {
              return (
                <SearchInputWithLabelAndIcon
                  key={item.label}
                  placeholder={item.placeholder}
                  label={item.label}
                  width={item.width}
                  value={item.value}
                  options={item.options}
                  handleChange={item.onChangeValue}
                  isDisabled={item.isDisabled}
                  isSearchable={false}
                  containerMarginBottom="0px"
                />
              );
            }
            return (
              <SearchInputWithLabel
                data-testid="searchInputSelect"
                key={item.label}
                type="text"
                placeholder={item.placeholder}
                label={item.label}
                width={item.width}
                value={item.value}
                onChangeValue={item.onChangeValue}
                height={44}
                onEnter={handleApply}
              />
            );
          })}
          {!isClientAdmin && (
            <SearchInputWithLabelAndIcon
              value={managementCompany}
              width="284px"
              label="Management Company"
              placeholder="Select management company"
              options={companyManagements.items}
              handleChange={handleSelectManagementCompany}
              onInputChange={changeInput}
              handleSearch={searchWhenType}
              isColumn
              height={44}
              onMenuScrollToBottom={handleMenuScrollToBottom}
              onEnter={handleApply}
              containerMarginBottom="0px"
            />
          )}
          <Stack gap={1} display="flex" flexDirection="row">
            <MButton data-testid="applyBtn" variant="primary" onClick={handleApply}>
              Apply
            </MButton>
            <MButton variant="bordered" onClick={handleReset}>
              Reset
            </MButton>
          </Stack>
        </Stack>
        {!usersTable?.items?.length && !tableLoading && (
          <Stack alignItems="center" mt={18}>
            <EmptyBlock
              title="No users found"
              desc="Please check the filters for correctness and search again."
            />
          </Stack>
        )}
        {usersTable?.items && !isLoader ? (
          <Table
            columns={UsersTableColumns({ isAdmin: isClientAdmin })}
            rows={rowsAdapter}
            total={usersTable?.totalRowsCount}
            isPagination={usersTable?.totalPages > 1}
            onDeleteRow={handleDeleteUserById}
            onRefreshPage={handleFetchDataPage}
            onNextPage={handleFetchDataPage}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            loading={tableLoading}
          />
        ) : (
          <TableLoader />
        )}
      </Box>
      <SelfEnrollmentDialog
        isOpen={isSelfEnrollmentDialogOpen}
        onClose={closeSelfEnrollmentDialog}
      />
    </>
  );
};

export default ClientPortalUsers;
