import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box, Stack, Container, Typography, styled } from '@mui/material';

import Form from '../../atoms/Form';
import CheckboxWithLabel from '../../molecules/CheckboxWithLabel';
import MButton from '../../MUI/Button/MButton';
import { ReactComponent as Add } from '../../../assets/icons/Add.svg';

import { getAddresses } from '../../../store/slices/requests/noticeIntakeSlice';
import { createEvictionIntake } from '../../../store/slices/requests/requestsSlice';
import Table from '../../atoms/Table/Table';
import useSearchDropdown from '../../../hooks/useSearchDropdown';
import { palette } from '../../../theme/default';
import Field from '../../atoms/Field';
import EditInputWithLabel from '../../molecules/EditInputWithLabel/EditInputWithLabel';
import Dropdown from '../../atoms/Dropdown';
import Dropzone from '../../atoms/Dropzone';
import {
  INDIVIDUALS_COLUMNS,
  DOCUMENT_COLUMNS,
  documentStrings,
  uploadDocumentsColumns,
  evictionIntakeFormConfigForArizona,
  evictionIntakeFormConfigForNevada,
  PROPERTY_TYPE,
  columnsList,
} from './ClientPortalEvictionIntakeForm.constants';
import DocumentsPopup from '../Processing/Document/MDocumentsPopup';
import { addObjToFormData } from '../../../utils/convertObjToFormData';
import MTooltip from '../../atoms/MTooltip';
import notificationUtils from '../../../utils/notificationUtils';
import NotificationDialog from '../../molecules/NotificationDialog';
import LoaderCover from '../../atoms/LoaderCover';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

const mandatoryError = { type: 'required', message: 'Mandatory field' };

const ClientPortalEvictionIntakeForm = ({ stateCode }) => {
  const addresses = useSelector((state) => state.noticeIntake.getAddresses);
  const base = useBaseUrl();
  const [isLoader, setIsLoader] = useState(false);
  const [isOpenedDownloadDocumentPopup, setIsOpenedDownloadDocumentPopup] = useState(false);
  const [uploadFilesData, setUploadFilesData] = useState([]);
  const [isDragAndDrop, setIsDrag] = useState(true);
  const [clickedDocumentType, setClickedDocumentType] = useState('');
  const [showDuplicationPopup, setShowDuplicationPopup] = useState(false);
  const [duplicationError, setDuplicationError] = useState(null);

  const hideDuplicationPopup = () => setShowDuplicationPopup(false);

  const formUploads = useForm({
    defaultValues: {
      uploadNoticeDocumentsArray: [],
      uploadLedgerDocumentsArray: [],
      uploadLeaseDocumentsArray: [],
    },
  });

  const uploadNoticeDocumentsArray = useFieldArray({
    control: formUploads.control,
    name: 'uploadNoticeDocumentsArray',
  });

  const uploadLeaseDocumentsArray = useFieldArray({
    control: formUploads.control,
    name: 'uploadLeaseDocumentsArray',
  });

  const uploadLedgerDocumentsArray = useFieldArray({
    control: formUploads.control,
    name: 'uploadLedgerDocumentsArray',
  });

  const getUploadDocumentsArray = (itemName) => {
    switch (itemName) {
      case 'notice':
        return uploadNoticeDocumentsArray;
      case 'lease':
        return uploadLeaseDocumentsArray;
      case 'ledger':
        return uploadLedgerDocumentsArray;
      default:
        return null;
    }
  };

  const getUploadsDocumentsArrayName = (itemName) => {
    switch (itemName) {
      case 'notice':
        return 'uploadNoticeDocumentsArray';
      case 'lease':
        return 'uploadLeaseDocumentsArray';
      case 'ledger':
        return 'uploadLedgerDocumentsArray';
      default:
        return null;
    }
  };

  useEffect(() => {
    setIsDrag(!uploadNoticeDocumentsArray.fields.length);
  }, [uploadNoticeDocumentsArray.fields.length]);

  useEffect(() => {
    setIsDrag(!uploadLeaseDocumentsArray.fields.length);
  }, [uploadLeaseDocumentsArray.fields.length]);

  useEffect(() => {
    setIsDrag(!uploadLedgerDocumentsArray.fields.length);
  }, [uploadLedgerDocumentsArray.fields.length]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    state: { navPage, subNav },
  } = useLocation();

  const form = useForm({
    defaultValues: {},
    resolver: async (data) => {
      const errors = {};

      if (typeof data.address?.id !== 'number') {
        errors.address = mandatoryError;
      }

      if (!data.community.hasNoApartment && !data.community.apartmentNumber)
        errors.community = {
          apartmentNumber: mandatoryError,
        };

      return { values: data, errors };
    },
  });

  const { watch } = form;

  watch(['ledger.isDocumentNotRequired', 'lease.isDocumentNotRequired']);

  const documentsNoticeArray = useFieldArray({
    control: form.control,
    name: 'notice.document',
  });

  const documentsLeaseArray = useFieldArray({
    control: form.control,
    name: 'lease.document',
  });

  const documentsLedgerArray = useFieldArray({
    control: form.control,
    name: 'ledger.document',
  });

  const getDocumentsArray = (itemName) => {
    switch (itemName) {
      case 'notice.document':
        return documentsNoticeArray;
      case 'lease.document':
        return documentsLeaseArray;
      case 'ledger.document':
        return documentsLedgerArray;
      default:
        return null;
    }
  };

  const individualFields = useFieldArray({
    control: form.control,
    name: 'individuals',
  });

  const opposingPartyLength = individualFields.fields.length;

  const handleCloseDownloadDocumentPopup = () => {
    getUploadDocumentsArray(clickedDocumentType).remove();
    setIsOpenedDownloadDocumentPopup(false);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const typeWithCapitalizedFirstLetter =
        clickedDocumentType.charAt(0).toUpperCase() + clickedDocumentType.slice(1);

      getUploadDocumentsArray(clickedDocumentType).replace(
        acceptedFiles.map((file) => ({
          documentName: file.name,
          type: typeWithCapitalizedFirstLetter,
          file,
        })),
      );
    },
    [
      uploadLeaseDocumentsArray,
      uploadNoticeDocumentsArray,
      uploadLedgerDocumentsArray,
      clickedDocumentType,
    ],
  );

  useEffect(() => {
    const hasNumberValue = form.getValues('community.hasNoApartment');
    if (hasNumberValue) {
      form.setValue('community.apartmentNumber', '');
    }
  }, [form.watch('community.hasNoApartment')]);

  const dragAndDrop = (
    <Box sx={{ my: 4, width: '100%' }}>
      <Dropzone multiple={false} onDrop={onDrop} />
    </Box>
  );

  useEffect(() => {
    if (uploadFilesData.length) {
      form.setValue(`${clickedDocumentType}.document`, uploadFilesData);
    }
  }, [uploadFilesData]);

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: addresses.hasNextPage,
    getAction: ({ input, page }) =>
      getAddresses({
        currentPage: page,
        stateCode,
        address: input,
      }),
  });

  const handleError = (e) => {
    if (e?.errorCode === 'case.evictionIntake.has.possibleDuplicates') {
      const caseDuplicatesOrRelatives = e?.errorData?.caseDuplicatesOrRelatives?.map((item) => {
        return {
          ...item,
          caseId: item.id,
        };
      });

      setDuplicationError({
        message: e.errorMessage,
        caseDuplicatesOrRelatives: caseDuplicatesOrRelatives ?? [],
      });
      setShowDuplicationPopup(true);
    } else {
      notificationUtils.error(e?.errorMessage || 'Failed, try again later');
    }
  };

  const handleSubmit = useCallback((values) => {
    const evictionValues = {
      AddressId: values.address.id,
      ApartmentNumber: values.community.apartmentNumber || '',
      HasNoApartmentNumber: !!values.community.hasNoApartment,
      Individuals: values.individuals.map((individual) => ({
        firstName: individual.firstName || '',
        lastName: individual.lastName || '',
        middleName: individual.middleName || '',
        suffix: individual.suffix || '',
      })),
      Entities: [],
      DeliveredBy: values.notice.deliveredBy?.value ?? null,
      NoticeDocument: values.notice.document[0].file,
      NoticeDocumentName: values.notice.document[0].documentName,
      IsLeaseUnavailable: values.lease.isDocumentNotRequired ?? false,
      LeaseDocumentName: values.lease.document?.[0]?.documentName ?? null,
      LeaseDocument: values.lease.document?.[0]?.file ?? null,
      IsLedgerUnavailable: values.ledger.isDocumentNotRequired ?? false,
      LedgerDocumentName: values.ledger.document?.[0]?.documentName ?? null,
      LedgerDocument: values.ledger.document?.[0]?.file ?? null,
      hasSkipDuplicationCheck: values.hasSkipDuplicationCheck || false,
      ...(values.community?.propertyType && { propertyType: values.community.propertyType.value }),
    };

    const data = addObjToFormData(evictionValues);

    setIsLoader(true);
    dispatch(createEvictionIntake(data))
      .unwrap()
      .then(() => {
        notificationUtils.success('Created successfully');
        navigate(`/${base}/requests`, {
          state: {
            navPage,
          },
        });
      })
      .catch((rejectedValueError) => {
        handleError(rejectedValueError);
        setIsLoader(false);
      })
      .finally(() => {
        if (evictionValues.hasSkipDuplicationCheck) setShowDuplicationPopup(false);
      });
  }, []);

  const handleCancel = useCallback(() => {
    form.reset();
    navigate(`/${base}/requests`, {
      state: {
        navPage,
        subNav,
      },
    });
  }, []);

  const handleAddDefendant = (i) => async () => {
    const values = form.getValues();
    const boxes = i.inputBoxes.filter((b) => !!b?.name?.startsWith('individual.'));
    const errors = boxes.map(({ name, isMandatory }) => ({
      name,
      error: isMandatory && !form.getValues(name) ? mandatoryError : null,
    }));
    errors.forEach(({ name, error }) => form.setError(name, error));
    if (errors.find((e) => e.error)) {
      return;
    }
    individualFields.prepend(values.individual);
    boxes.forEach((box) => form.setValue(box.name, ''));
  };

  const handleUpdateDefendant = async (index, values) => {
    const areFieldsInvalid = INDIVIDUALS_COLUMNS.some((c) => c.isMandatory && !values[c.accessor]);
    if (areFieldsInvalid) return false;
    individualFields.update(index, values);
    return true;
  };

  const handleUploadDocument = (type) => {
    setIsOpenedDownloadDocumentPopup(true);
    setClickedDocumentType(type);
  };

  const uploadTable = (
    <Form form={formUploads}>
      <Box
        sx={{
          overflowY: 'scroll',
          maxHeight: '240px',
          width: '732px',
          margin: '32px 0',
          '&::-webkit-scrollbar': {
            width: '4px',
            marginLeft: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: palette.additional.lines,
          },
          '&::-webkit-scrollbar-thumb': {
            background: palette.additional.thumb,
          },
        }}
      >
        <Table
          loading={false}
          columns={uploadDocumentsColumns}
          rows={getUploadDocumentsArray(clickedDocumentType)?.fields}
          onUpdateData={getUploadDocumentsArray(clickedDocumentType)?.update}
          onDeleteRow={getUploadDocumentsArray(clickedDocumentType)?.remove}
        />
      </Box>
    </Form>
  );

  const handleUploadDocuments = () => {
    const values = formUploads.getValues();
    setUploadFilesData(values[getUploadsDocumentsArrayName(clickedDocumentType)]);

    handleCloseDownloadDocumentPopup();
  };

  const addressId = useWatch({ name: 'address.id', control: form.control });

  useEffect(() => {
    if (addressId && addresses?.items?.length) {
      const address = addresses.items.find((addr) => addr.id === addressId);
      if (address) {
        const values = form.getValues();

        let propType = values.community?.propertyType;

        if (!propType) {
          propType = PROPERTY_TYPE.find((item) => item.default);
        }

        form.reset({
          ...values,
          community: {
            apartmentNumber: values?.community?.apartmentNumber ?? '',
            hasNoApartment: values?.community?.hasNoApartment ?? false,
            propertyType: propType,
            ...address.data,
          },
        });
      }
    }
  }, [addressId]);

  const getOptions = (inputBoxesItemName) => {
    switch (inputBoxesItemName) {
      case 'community.propertyType':
        return PROPERTY_TYPE;
      case 'notice.deliveredBy':
        // hardcore values agreed with BA
        return [
          {
            id: 'Hand Delivered',
            label: 'Hand Delivered',
            value: 'Hand Delivered',
          },
          {
            id: 'Certified Mail',
            label: 'Certified Mail',
            value: 'Certified Mail',
          },
        ];
      case 'address':
        return addresses.items;
      default:
        return [];
    }
  };

  const getShowMandatory = useCallback(
    (fieldName, initial = false) => {
      switch (fieldName) {
        default:
          return initial;
      }
    },
    [opposingPartyLength],
  );

  const getDefaultValue = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case 'community.propertyType':
          return PROPERTY_TYPE.find((item) => item.default);
        default:
          return [];
      }
    },
    [PROPERTY_TYPE],
  );

  const handleDeleteDocument = (docType) => (id) => {
    getDocumentsArray(docType).remove(id);
  };

  const isSubmitBtnEnabled =
    form.getValues('notice.document')?.length &&
    (form.getValues('lease.isDocumentNotRequired') || form.getValues('lease.document')?.length) &&
    (form.getValues('ledger.isDocumentNotRequired') || form.getValues('ledger.document')?.length);

  const formFieldsCollection = useMemo(() => {
    if (stateCode === 'NV') {
      return evictionIntakeFormConfigForNevada;
    }
    if (stateCode === 'AZ') {
      return evictionIntakeFormConfigForArizona;
    }
    return null;
  }, [stateCode]);

  const defineFieldDisabled = useCallback(
    (fieldName, disabled) => {
      if (fieldName === 'community.apartmentNumber') {
        return form.getValues('community.hasNoApartment');
      }
      return disabled;
    },
    [form.watch('community.hasNoApartment')],
  );

  const createRequestWithPossibleDuplicate = () => {
    handleSubmit({
      ...form.watch(),
      hasSkipDuplicationCheck: true,
    });
  };

  const renderDuplicateContent = () => {
    return (
      <Box>
        <Table
          columns={columnsList}
          rows={duplicationError?.caseDuplicatesOrRelatives ?? []}
          total={duplicationError?.caseDuplicatesOrRelatives?.length}
        />
      </Box>
    );
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      {isLoader && <LoaderCover isFixed />}
      <NotificationDialog
        title="Possible Duplicate Request"
        desc={duplicationError?.message}
        type="alert"
        width="800px"
        maxWidth="800px"
        buttonSecondaryText="Back to Request"
        buttonSecondaryWidth="175px"
        buttonPrimaryWidth="167px"
        buttonPrimaryText="Submit Request"
        onClose={hideDuplicationPopup}
        onCancel={hideDuplicationPopup}
        onConfirm={createRequestWithPossibleDuplicate}
        isOpen={showDuplicationPopup}
        content={renderDuplicateContent()}
      />
      <Typography variant="h4">Eviction Intake Form</Typography>
      <Form form={form} onSubmit={handleSubmit}>
        {formFieldsCollection &&
          formFieldsCollection.map((formConfigItem) => (
            <Container
              key={formConfigItem.boxTitle}
              maxWidth={false}
              disableGutters
              sx={{
                mx: 'auto',
                mt: 4,
                width: 1,
              }}
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h5">{formConfigItem.boxTitle}</Typography>
                {!!formConfigItem.info && (
                  <Typography variant="bodyM" color="text.secondary">
                    {formConfigItem.info}
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" alignItems="center" columnGap={3} rowGap={2} flexWrap="wrap">
                {formConfigItem.inputBoxes.map((item) => {
                  if (item.isDropdown) {
                    return (
                      <Field
                        key={item.label}
                        isMandatory={item.isMandatory}
                        name={item.name}
                        render={({ field, onCustomChange, error }) => (
                          <Dropdown
                            key={item.label}
                            label={item.label}
                            value={field.value}
                            width={item.width}
                            error={error}
                            isColumn
                            isMandatory={item.isMandatory}
                            placeholder={item.placeholder}
                            onChange={onCustomChange(field.onChange)}
                            options={getOptions(item.name)}
                            defaultValue={getDefaultValue(item.name)}
                            onMenuScrollToBottom={handleMenuScrollToBottom}
                            onInputChange={changeInput}
                            isSearchable
                            filterOption={null}
                          />
                        )}
                      />
                    );
                  }

                  if (item.isAddButtonWithTable) {
                    if (item.table === 'individualTable') {
                      return (
                        <React.Fragment key={item.table}>
                          <MButton
                            sx={{ marginTop: '32px', marginLeft: 'auto' }}
                            startIcon={<Add />}
                            onClick={handleAddDefendant(formConfigItem)}
                          >
                            Add
                          </MButton>
                          <Box mt={2} width="100%">
                            <Table
                              columns={INDIVIDUALS_COLUMNS}
                              isLoading={false}
                              onUpdateData={handleUpdateDefendant}
                              onDeleteRow={individualFields.remove}
                              rows={individualFields.fields}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    }

                    return null;
                  }

                  if (item.isCheckbox) {
                    return (
                      <Field
                        key={item.label}
                        name={item.name}
                        render={({ field, onCustomChange }) => {
                          return (
                            <CheckboxWithLabel
                              isDisabled={item.isDisabled}
                              key={item.label}
                              label={
                                <>
                                  {item.label}
                                  {item?.tooltip && <MTooltip text={item?.tooltip} />}
                                </>
                              }
                              isChecked={field.value}
                              onChange={onCustomChange(field.onChange)}
                              marginTop="25px"
                            />
                          );
                        }}
                      />
                    );
                  }

                  if (item?.isSpace) {
                    return <div style={{ width: '100%' }} />;
                  }

                  if (item.isSubHeading) {
                    return (
                      <StyledTypography width={1} mb={1} variant="bodyL500" key={item.label}>
                        {item.label}
                      </StyledTypography>
                    );
                  }

                  if (item.isSubHeadingWithButton) {
                    const isButtonDisabled =
                      form.getValues(`${item.type}.isDocumentNotRequired`) ||
                      form.getValues(`${item.type}.document`)?.length;

                    return (
                      <MButton
                        size="large"
                        sx={{ width: '240px', marginTop: '32px', marginLeft: 'auto' }}
                        onClick={() => handleUploadDocument(item.type)}
                        disabled={isButtonDisabled}
                      >
                        {item.buttonName}
                      </MButton>
                    );
                  }

                  if (item.isTable) {
                    return (
                      <Field
                        key={item.label}
                        name={item.name}
                        isMandatory={item.isMandatory}
                        render={({ field }) => {
                          return (
                            !!field?.value?.length && (
                              <Table
                                columns={DOCUMENT_COLUMNS}
                                rows={getDocumentsArray(item.name).fields}
                                loading={false}
                                onDeleteRow={handleDeleteDocument(item.name)}
                              />
                            )
                          );
                        }}
                      />
                    );
                  }

                  return (
                    <Field
                      key={item.label}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, error }) => (
                        <EditInputWithLabel
                          error={error}
                          isMandatory={getShowMandatory(item.name, item.isMandatory)}
                          isDisabled={defineFieldDisabled(item.name, item.isDisabled)}
                          key={item.label}
                          type="text"
                          label={item.label}
                          name={item.name}
                          value={field.value}
                          placeholder={item.placeholder}
                          width={item.width}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  );
                })}
              </Stack>
            </Container>
          ))}
        <Box
          position="absolute"
          left="50%"
          bottom={0}
          sx={{ transform: 'translateX(-50%)' }}
          zIndex={10}
          width={1}
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton
              variant="secondary"
              size="large"
              onClick={handleCancel}
              sx={{ width: '144px' }}
            >
              Cancel
            </MButton>
            <MButton
              type="submit"
              size="large"
              name="Submit eviction intake form"
              sx={{ width: '144px' }}
              disabled={!isSubmitBtnEnabled || !opposingPartyLength || isLoader}
            >
              Request
            </MButton>
          </Stack>
        </Box>
        <DocumentsPopup
          open={isOpenedDownloadDocumentPopup}
          handleClose={handleCloseDownloadDocumentPopup}
          isDragAndDrop={isDragAndDrop}
          dragAndDrop={dragAndDrop}
          uploadTable={uploadTable}
          uploadHandler={handleUploadDocuments}
          documentStrings={documentStrings}
          single
        />
      </Form>
    </Container>
  );
};

ClientPortalEvictionIntakeForm.propTypes = {
  stateCode: PropTypes.string.isRequired,
};

export default ClientPortalEvictionIntakeForm;
