import React, { useEffect, useState } from 'react';
import { Box, styled, Typography, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { chartTimePeriods } from './DashboardLegalAssistantChart.constants';
import { getMetricsForLegalAssistant } from '../../../../../store/slices/legalAssistantDashboardSlice';
import { useLegalAssitantDashboardLineChart } from './hooks';
import PeriodButtons from '../../../../atoms/PeriodButtons';
import LoaderCover from '../../../../atoms/LoaderCover';

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '24px',
  border: `1px solid ${theme.palette.additional.lines}`,
  borderRadius: '16px',
}));

const DashboardLegalAssistantChart = ({ state }) => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(true);
  const [timePeriod, setTimePeriod] = useState('1W');

  useEffect(() => {
    setIsLoader(true);
    dispatch(getMetricsForLegalAssistant({ period: timePeriod, stateCode: state.value })).then(() =>
      setIsLoader(false),
    );
  }, [timePeriod, state]);

  const { data, options, plugins } = useLegalAssitantDashboardLineChart({ period: timePeriod });

  return (
    <Wrapper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" rowGap={1}>
        <Typography variant="h5">Personal Metrics</Typography>

        <PeriodButtons
          currentPeriod={timePeriod}
          isDisabled={isLoader}
          onPeriodChange={setTimePeriod}
          periods={chartTimePeriods}
        />
      </Stack>
      <Box sx={{ minHeight: '276px' }}>
        {isLoader ? (
          <LoaderCover />
        ) : (
          <Line height="276px" data={data} options={options} plugins={plugins} />
        )}
      </Box>
    </Wrapper>
  );
};

DashboardLegalAssistantChart.propTypes = {
  state: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default DashboardLegalAssistantChart;
