import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { styled, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { SnackbarProvider, MaterialDesignContent, closeSnackbar } from 'notistack';

import { theme } from './theme/theme';
import { palette } from './theme/default';

import router from './routes';

import './index.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: palette.additional.success,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: palette.additional.alert,
  },
  cursor: 'pointer',
}));

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
      // eslint-disable-next-line
      action={(snackbarId) => (
        <button
          type="button"
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0,
            width: '100%',
            height: '100%',
            cursor: 'pointer',
          }}
          onClick={() => closeSnackbar(snackbarId)}
        >
          Dismiss
        </button>
      )}
      maxSnack={5}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    />
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <RouterProvider router={router} />
    </LocalizationProvider>
  </ThemeProvider>
);

export default App;
