import { DateCell } from './cells/DateCell';
import NameCell from './cells/NameCell';

export const stateCodesOptions = [
  { value: 'AZ', label: 'AZ' },
  { value: 'NV', label: 'NV' },
];

export const periodOptions = [
  { value: '30', label: '30 days' },
  { value: '60', label: '60 days' },
  { value: '90', label: '90 days' },
  { value: 'more', label: 'more than 90 days' },
];

export const ClientAcquisitionFormBoxes = [
  {
    name: 'dataFields',
    inputBoxes: [
      {
        label: 'State',
        name: 'stateCode',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'From Date',
        name: 'fromDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'To Date',
        name: 'toDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
    ],
  },
];

export const ClientAcquisitionFormBoxesColumns = ({ stateCode }) => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props) => <NameCell {...props} stateCode={stateCode} />,
      width: 70,
    },
    {
      Header: 'Creation Date',
      accessor: 'creationDate',
      Cell: DateCell,
      width: 30,
    },
  ];
};
