import React, { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';

import { ReactComponent as Trash } from '../../../../assets/icons/Trash.svg';

const DeleteCell = ({ row: { id, original }, deleteRow, value: { hidden } = {} }) => {
  const handleClickDelete = useCallback(() => {
    deleteRow(id, original);
  }, [id, deleteRow]);

  if (hidden) {
    return null;
  }

  return (
    <IconButton onClick={handleClickDelete} aria-label="Delete" data-testid="delete_button">
      <Trash data-testid="trash_icon" />
    </IconButton>
  );
};

DeleteCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    original: PropTypes.shape({}),
  }).isRequired,
  deleteRow: PropTypes.func.isRequired,
  value: PropTypes.shape({
    hidden: PropTypes.bool,
  }).isRequired,
};

export default DeleteCell;
