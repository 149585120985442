import React, { useState } from "react";
import CaseContext from "../context/CaseContext";
import PropTypes from "prop-types";

export const defaultState = {
	apartmentNum: "",
	address: {
		address: {},
		city: "",
		state: "",
		zipCode: "",
	},
	community: {
		city: "",
		commName: "",
		communityOwnerEntity: "",
		email: "",
		governmentBackedLoan: {},
		managementName: "",
		noApartmentNumber: false,
		phone: "",
	},
	court: {
		address: "",
		city: "",
		courtName: "",
		fax: "",
		phone: "",
		room: "",
		state: "",
		zipCode: "",
	},
	caseDefendants: [
		// {
		// 	caseDefId: 0,
		// 	firstName: "",
		// 	middleName: "",
		// 	lastName: "",
		// 	email: "",
		// 	phone: "",
		// 	suffix: ""
		// }
	],
	proceduralHistory: [
		// {
		// 	attorneyId: 0,
		// 	proceduralHistoryId: 0,
		// 	hearingTypeId: 0,
		// 	hearingType: "",
		// 	attorney: "",
		// 	courtDate: "",
		// 	writDate: "",
		// 	status: "",
		// 	createdDate: ""
		// }
	],
	caseViolations: [
		// {
		// 	caseViolationId: 0,
		// 	noticeTypeId: 0,
		// 	deliveredById: 0,
		// 	deliveredbyName: "",
		// 	caseViolationName: "",
		// 	violationDescription: "",
		// 	dateTimeCreated: "",
		// 	prefileDate: ""
		// }
	],
	damages: {
		attorneyFee: 0,
		attorneyFeeImmediate: 0,
		courtCosts: 0,
		rentTypeId: 0,
		rentType: "",
		weekdayId: 0,
		firstUnpaidDue: null,
		nextRentalDue: null,
		rentValue: 0,
		isSubsidizedHousing: true,
		contractRent: 0,
		overrideRentDue: 0,
		lateFeesStart: null,
		lateFeesEnd: null,
		serviceOfProcess: 0,
		nsfCharges: 0,
		previousBalance: 0,
		utilities: 0,
		credit: 0,
		otherCharges: 0,
		monthToMonth: 0,
		concession: 0,
	},
	caseNoticeRequest: {
		noticeRequestId: 0,
		hasCdcDeclaration: true,
		lateFeeAssessedOn: null,
		rentBecameDelinquentOn: null,
		periodicRent: 0,
		currentAmountDue: 0,
		pastAmountDue: 0,
		lateFee: 0,
	},
	caseNotes: [],
	documents: [
		/*{
				id: 0,
				name: "",
				type: "",
				fileName: "",
				link: "",
				createdBy: "",
				createdDate: null,
			}*/
	],
	caseEvictionRequest: {
		evictionRequestId: 0,
		attorneyFee: 0,
		attorneyFeeImmediate: 0,
		courtCost: 0,
		tenancyCommencedOn: null,
		rentBacameDelinquentOn: null,
		hasWrittenRentalAgreement: true,
		isRentSubsidized: true,
		periodicRent: 0,
		rentDeposit: 0,
		securityDeposit: 0,
		cleaningDeposit: 0,
		owedPeriodicRent: 0,
		owedLateFee: 0,
		lateFeeAssessedOn: null,
		isMediationRequested: true,
		mediationMode: "",
		mediatorPhoneNumber: "",
		mediatorEmail: "",
		mediatorMailingAddress: "",
	},
};

const CasesProvider = ({ children }) => {
	const [state, setState] = useState(defaultState);

	const defaultContext = {
		state,
		actions: {
			update: (form) =>
				setState((state) => {
					return { ...state, ...form };
				}),
			clear: () => setState(defaultState),
		},
	};

	return (
		<CaseContext.Provider value={defaultContext}>
			{children}
		</CaseContext.Provider>
	);
};
CasesProvider.propTypes = {
	children: PropTypes.element,
};

export default CasesProvider;
