import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import Table from '../../../../atoms/Table/Table';
import Searcher from '../../../../molecules/Searcher';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import NotificationDialog from '../../../../molecules/NotificationDialog';
import MButton from '../../../../MUI/Button/MButton';
import { ReactComponent as Cross } from '../../../../../assets/icons/Cross.svg';
import { palette } from '../../../../../theme/default';
import {
  ManagementCommunityColumns,
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
} from './ManagementCommunity.constants';
import ManagementCommunityDialog from './ManagementCommunityDialog';
import {
  editManagementCommunities,
  getManagementCommunities,
  deleteManagementCommunity,
  resetEditManagementCommunities,
} from '../../../../../store/slices/editManagementSlice';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/Add.svg';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';
import StateLink from '../../../../atoms/StateLink/StateLink';

const ManagementCommunity = () => {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [isOpenDialogAddress, setIsOpenDialogAddress] = useState(false);
  const [isOpenNotificationDialog, setIsOpenNotificationDialog] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const [isShowAdditionalButton, setIsShowAdditionalButton] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isErrorNotification, setIsErrorNotification] = useState(false);
  const canAdd = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);
  const canRemove = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);

  const {
    state: { id },
  } = useLocation();
  const { managementCommunites, editManagementCommunitiesError } = useSelector(
    (state) => state.editManagement,
  );

  useEffect(() => {
    dispatch(
      getManagementCommunities({ id, currentPage: 1, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE }),
    ).then(() => setIsLoader(false));
  }, [id]);

  const handleCloseErrorMessage = useCallback(() => {
    setIsErrorNotification(false);
    dispatch(resetEditManagementCommunities());
  });

  useEffect(() => {
    if (editManagementCommunitiesError?.errorMessage) {
      setTimeout(() => {
        setIsErrorNotification(true);
      }, 1000);
    }
  }, [editManagementCommunitiesError]);

  const handleChangeEmpty = useCallback((searchElement) => {
    if (!searchElement) {
      setIsLoaderTable(true);
      dispatch(
        getManagementCommunities({ id, currentPage: 1, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE }),
      ).then(() => {
        setSearchValue('');
        setIsLoaderTable(false);
      });
    }
  }, []);

  const handleSearch = useCallback((searchElement) => {
    setSearchValue(searchElement);
    setIsLoaderTable(true);
    dispatch(
      getManagementCommunities({
        id,
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        communityName: searchElement,
      }),
    ).then(() => {
      setIsLoaderTable(false);
    });
  }, []);

  const handleAddCommunity = useCallback(() => {
    setIsOpenDialogAddress(true);
  }, []);

  const handleCheckedRow = useCallback(
    (selectedRows) => {
      setIsShowAdditionalButton(selectedRows.length > 0);
      setSelectedIds(selectedRows.map((item) => ({ communityId: item.communityId })));
    },
    [isShowAdditionalButton],
  );

  useEffect(() => {
    if (isShowAdditionalButton && !managementCommunites?.items?.length) {
      setIsShowAdditionalButton(false);
    }
  }, [isShowAdditionalButton, managementCommunites?.items?.length]);

  const handleDeleteCommunityAddress = useCallback(
    (idRow, original) => {
      dispatch(
        deleteManagementCommunity({
          id,
          managementId: id,
          selectedCommunities: [
            {
              communityId: original.communityId,
            },
          ],
          removeAllCommunities: false,
        }),
      ).then(() => {
        setIsLoaderTable(true);
        dispatch(
          getManagementCommunities({
            id,
            currentPage: managementCommunites?.currentPage,
            pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          }),
        ).then(() => {
          setIsLoaderTable(false);
        });
      });
    },
    [id],
  );

  const handleDeleteCommunityAddresses = useCallback(() => {
    setIsOpenNotificationDialog(true);
  }, []);

  const handleConfirmNotificationDialog = useCallback(() => {
    dispatch(
      deleteManagementCommunity({
        id,
        managementId: id,
        selectedCommunities: selectedIds,
        removeAllCommunities: false,
      }),
    ).then(() => {
      setIsLoaderTable(true);
      dispatch(
        getManagementCommunities({
          id,
          currentPage: managementCommunites?.currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    });
    setIsOpenNotificationDialog(false);
  }, [id, selectedIds]);

  const handleCloseNotificationDialog = useCallback(() => {
    setIsOpenNotificationDialog(false);
  }, []);

  const handleConfirmDialogCommunity = useCallback(
    (selectedCommunityIds) => {
      dispatch(
        editManagementCommunities({
          id,
          managementId: id,
          selectedCommunities: selectedCommunityIds,
        }),
      ).then(() => {
        setIsLoaderTable(true);
        dispatch(
          getManagementCommunities({ id, currentPage: 1, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE }),
        ).then(() => {
          setIsLoaderTable(false);
        });
      });
      setIsOpenDialogAddress(false);
    },
    [id],
  );

  const handleCloseDialogCommunity = useCallback(() => {
    setIsOpenDialogAddress(false);
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      dispatch(
        getManagementCommunities({
          id,
          currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          communityName: searchValue,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue],
  );

  const errorDialog = (
    <NotificationDialog
      title="Error"
      desc={editManagementCommunitiesError?.errorMessage}
      type="alert"
      buttonSecondaryText=""
      buttonPrimaryText="Ok"
      onClose={handleCloseErrorMessage}
      onConfirm={handleCloseErrorMessage}
      isOpen={isErrorNotification}
    />
  );

  const rowsAdapter = useMemo(() => {
    return managementCommunites?.items?.map((item) => ({
      ...item,
      communityName: (
        <StateLink
          to="/db/datahandling/editcommunity/community"
          state={{
            navPage: 'Data Handling',
            subNav: 'Communities',
            id: item.communityId,
            name: item.communityName,
          }}
          alwaysOpenInNewTab
        >
          <Typography
            variant="link"
            color="text.primary"
            sx={{
              cursor: 'pointer',
              textDecorationLine: 'underline',
              textTransform: 'none',
            }}
          >
            {item.communityName}
          </Typography>
        </StateLink>
      ),
      control: {
        hideDelete: !canRemove,
        autoRefresh: false,
      },
    }));
  }, [managementCommunites?.items, canRemove]);

  if (isLoader)
    return (
      <Box position="absolute" top="50vh" left="50%" zIndex={10} transform="translate(-50%, -50%)">
        <CircularProgress />
      </Box>
    );

  if (!isLoader && !searchValue && !managementCommunites?.items?.length) {
    return (
      <Stack height="calc(100vh - 80px - 72px)" direction="column">
        <Box
          margin="auto"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <EmptyBlock
            title="You don’t have any management communities yet"
            desc={canAdd ? 'To get started click on “Add community” button below' : ''}
          />
          {canAdd && (
            <Stack mt={6}>
              <MButton
                startIcon={<AddIcon />}
                width={144}
                size="large"
                onClick={handleAddCommunity}
              >
                Add Community
              </MButton>
            </Stack>
          )}
        </Box>
        {canAdd && (
          <Box
            width={1}
            p={4}
            marginTop="150px"
            boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
          >
            <ManagementCommunityDialog
              title="Add Community"
              buttonSecondaryText="Cancel"
              buttonPrimaryText="Add"
              onClose={handleCloseDialogCommunity}
              onConfirm={handleConfirmDialogCommunity}
              isOpen={isOpenDialogAddress}
            />
            {errorDialog}
          </Box>
        )}
      </Stack>
    );
  }

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          px: 4,
          maxWidth: 1066,
          width: 1,
          pb: 18,
        }}
      >
        <Stack spacing={3}>
          <Typography variant="h5">Management Communities</Typography>
          <Searcher
            inputPlaceholder="Search by Address"
            onSearch={handleSearch}
            onChange={handleChangeEmpty}
          />
          {!!rowsAdapter?.length && !isLoader && (
            <Table
              columns={ManagementCommunityColumns}
              rows={rowsAdapter}
              total={managementCommunites?.totalRowsCount}
              isPagination={managementCommunites?.totalPages > 1}
              onNextPage={handleFetchDataPage}
              onPreviousPage={handleFetchDataPage}
              onGotoPage={handleFetchDataPage}
              pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
              onDeleteRow={handleDeleteCommunityAddress}
              loading={isLoaderTable}
              onCheckedRow={handleCheckedRow}
              isWithCheckbox
            />
          )}
          {!managementCommunites?.items?.length && !isLoader && searchValue && (
            <Stack direction="column" paddingTop="50px">
              <Box margin="auto">
                <EmptyBlock
                  title="Management communities not found"
                  desc="Сhange the search value"
                  margin="auto"
                />
              </Box>
            </Stack>
          )}
        </Stack>
      </Container>
      {(canRemove || canAdd) && (
        <Box
          position="absolute"
          left="50%"
          bottom={0}
          sx={{ transform: 'translateX(-50%)' }}
          zIndex={10}
          width={1}
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            {isShowAdditionalButton && canRemove && (
              <MButton
                variant="additional"
                size="large"
                startIcon={<Cross width="18px" height="18px" />}
                onClick={handleDeleteCommunityAddresses}
                sx={{ mr: 'auto' }}
              >
                Remove selected
              </MButton>
            )}
            {canAdd && (
              <MButton width={144} size="large" onClick={handleAddCommunity}>
                Add Community
              </MButton>
            )}
          </Stack>
          <NotificationDialog
            title="Confirm delete"
            desc="Are you sure you want to delete the selected items?"
            type="alert"
            buttonSecondaryText="Cancel"
            buttonPrimaryText="Delete"
            onClose={handleCloseNotificationDialog}
            onConfirm={handleConfirmNotificationDialog}
            isOpen={isOpenNotificationDialog}
          />
          <ManagementCommunityDialog
            title="Add Community"
            buttonSecondaryText="Cancel"
            buttonPrimaryText="Add"
            onClose={handleCloseDialogCommunity}
            onConfirm={handleConfirmDialogCommunity}
            isOpen={isOpenDialogAddress}
          />
        </Box>
      )}
      {errorDialog}
    </>
  );
};

export default ManagementCommunity;
