import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/system";


const MInputBase = styled(InputBase)(({theme, width, isNoBorder})=>({
	"& .MuiInputBase-input": {
		width: `${width}px`,
		border: `${isNoBorder ? null : "1px solid"}`,
		borderColor: theme.palette.additional.lines,
		borderRadius: "10px",
		padding: "12px",
		boxSizing: "border-box",
		backgroundColor: theme.palette.common.white,
		fontWeight: "500",
		fontSize: 16,
		color: theme.palette.text.secondary,
		"&:focus": {
			borderColor: theme.palette.additional.lines,
			backgroundColor: theme.palette.common.white,
			borderRadius: "10px",
		},
		"&:active": {
			borderColor: theme.palette.additional.lines,
			backgroundColor: theme.palette.common.white,
			borderRadius: "10px",
		}
	},
	
}));

export default MInputBase;