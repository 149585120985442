import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import StateLink from '../../../atoms/StateLink/StateLink';
import { palette } from '../../../../theme/default';
import CaseStatus from '../../../molecules/CaseStatus';
import { DefendantCell } from '../../Processing/CaseSearch/CaseSearchCells';
import LegalCategoryCell from './TableCells/LegalCategoryCell';
import TextEllipsisCell from './TableCells/TextEllipsisCell';
import { ReactComponent as AttorneyReviewIcon } from '../../../../assets/icons/attorneyReview.svg';
import { ReactComponent as DeadlineIcon } from '../../../../assets/icons/deadlines.svg';
import { ReactComponent as CivilMattersIcon } from '../../../../assets/icons/civilMatters.svg';
import AssignToMeCell from './TableCells/AssignToCell';
import MTooltip from '../../../atoms/MTooltip/MTooltip';
import CompleteCell from './TableCells/CompleteCell';

export const UNASSIGNED = 'Unassigned';
export const UNASSIGNED_EXTRA_OPTION = { id: UNASSIGNED, value: UNASSIGNED, label: UNASSIGNED };

export const ATTORNEY_REVIEW = 'attorneyReview';
export const DEADLINES_DUE_DATES = 'deadlineDueDates';
export const CIVIL_MATTERS = 'civilMatters';

export const cardsListByState = {
  AZ: [
    {
      id: ATTORNEY_REVIEW,
      title: 'Attorney Review',
      base: 'case',
      icon: <AttorneyReviewIcon />,
    },
    {
      id: DEADLINES_DUE_DATES,
      title: 'Deadlines & Due Dates',
      base: 'case',
      icon: <DeadlineIcon />,
    },
    {
      id: CIVIL_MATTERS,
      title: 'Civil Matters',
      base: 'case',
      icon: <CivilMattersIcon />,
    },
  ],
  NV: [
    {
      id: ATTORNEY_REVIEW,
      title: 'Attorney Review',
      base: 'case',
      icon: <AttorneyReviewIcon />,
    },
    {
      id: DEADLINES_DUE_DATES,
      title: 'Deadlines & Due Dates',
      base: 'case',
      icon: <DeadlineIcon />,
    },
    {
      id: CIVIL_MATTERS,
      title: 'Civil Matters',
      base: 'case',
      icon: <CivilMattersIcon />,
    },
  ],
};

export const AddCourtDatesColumns = [
  {
    Header: 'Court Name',
    accessor: 'courtName',
    width: 560,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDate',
    width: 290,
  },
  {
    Header: 'Court Time',
    accessor: 'courtTime',
    width: 200,
  },
];

export const CaseNumberCell = ({ value, row }) => {
  const to = `/db/processing/case/${row.original.caseId}`;

  return (
    <Link to={to} target="_blank" data-testid="case_number_link">
      <Typography
        sx={{ cursor: 'pointer' }}
        textTransform="none"
        variant="link"
        color={palette.text.primary}
        data-testid="case_number_cell"
      >
        {value || 'No Case #'}
      </Typography>
    </Link>
  );
};

CaseNumberCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
};

export const ReferenceCell = ({ value, row, card }) => {
  const path = `/db/processing/case/${
    row.original.civilMatterCaseId || row.original.caseId || row.original.id
  }`;

  const caseTab = `civilmatters${row.original.stateCode === 'AZ' ? 'arizona' : 'nevada'}`;

  const state = {
    caseTab: card === CIVIL_MATTERS ? caseTab : '',
  };

  return (
    <StateLink to={path} state={state} alwaysOpenInNewTab>
      <Typography
        sx={{ cursor: 'pointer' }}
        variant="link"
        color={palette.text.primary}
        textTransform="none"
        data-testid="ref_cell"
      >
        {value || 'No Case #'}
      </Typography>
    </StateLink>
  );
};

ReferenceCell.propTypes = {
  value: PropTypes.string.isRequired,
  card: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      caseId: PropTypes.string,
      opposingParty: PropTypes.string,
      referenceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      civilMatterCaseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      stateCode: PropTypes.string,
      matterType: PropTypes.string,
    }),
  }).isRequired,
};

export const HearingsPopupColumns = [
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    Cell: CaseNumberCell,
  },
  {
    Header: 'Community',
    accessor: 'communityName',
  },
  {
    Header: 'Opposing Party',
    accessor: 'oppositeParty',
    Cell: DefendantCell,
  },
  {
    Header: 'Violation Type',
    accessor: 'violationType',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CaseStatus,
  },
];

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const listConfig = {
  [ATTORNEY_REVIEW]: {
    title: 'Attorney Review',
    columns: (stateCode) => [
      {
        Header: 'Reference #',
        accessor: 'referenceNumber',
        Cell: (props) => <ReferenceCell card={ATTORNEY_REVIEW} {...props} />,
        isSortable: true,
        sortDescFirst: true,
        width: 15,
        minWidth: 174,
      },
      {
        Header: 'Management',
        accessor: 'management',
        isSortable: true,
        sortDescFirst: false,
        width: 15,
        minWidth: 174,
      },
      {
        Header: 'Community',
        accessor: 'community',
        isSortable: true,
        sortDescFirst: false,
        width: 10,
        minWidth: 174,
      },
      {
        Header: 'Opposing Party',
        accessor: 'opposingParty',
        width: 15,
        minWidth: 174,
      },
      {
        Header: 'Apartment #',
        accessor: 'apartment',
        width: 10,
        minWidth: 174,
      },
      {
        Header: 'Reviewing Attorney',
        accessor: 'reviewingAttorneyFullName',
        width: 15,
        minWidth: 174,
      },
      {
        Header: 'Creation Date',
        accessor: 'created',
        Cell: ({ value }) => (value ? moment(value).format('L') : ''),
        isSortable: true,
        sortDescFirst: true,
        width: 10,
        minWidth: 174,
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdatedDate',
        Cell: ({ value }) => (value ? moment(value).format('L') : ''),
        isSortable: true,
        sortDescFirst: true,
        width: 10,
        minWidth: 174,
      },
      {
        Header: (
          <MTooltip
            text="Assignment of attorney review is separate from court assignment"
            customStyles={{ marginLeft: '80px' }}
          />
        ),
        accessor: 'canAssignReviewingAttorney',
        Cell: (props) => AssignToMeCell({ ...props, card: ATTORNEY_REVIEW, stateCode }),
        width: 10,
        minWidth: 130,
      },
    ],
  },
  [DEADLINES_DUE_DATES]: {
    title: 'Deadlines & Due Dates',
    periods: [
      { label: 'Day', value: 'day' },
      { label: 'Week', value: 'week' },
      { label: 'Month', value: 'month' },
      { label: 'All', value: 'all' },
    ],
    defaultPeriod: 'week',
    columns: (stateCode) => [
      {
        Header: 'Reference #',
        accessor: 'referenceNumber',
        Cell: (props) => <ReferenceCell card={DEADLINES_DUE_DATES} {...props} />,
        isSortable: true,
        sortDescFirst: true,
        width: 10,
        minWidth: 174,
      },
      {
        Header: 'Management',
        accessor: 'management',
        isSortable: true,
        sortDescFirst: false,
        width: 15,
        minWidth: 174,
      },
      {
        Header: 'Community',
        accessor: 'community',
        isSortable: true,
        sortDescFirst: false,
        width: 10,
        minWidth: 174,
      },
      {
        Header: 'Opposing Party',
        accessor: 'opposingParty',
        width: 15,
        minWidth: 174,
      },
      {
        Header: 'Deadline',
        accessor: 'deadline',
        isSortable: true,
        sortDescFirst: true,
        width: 10,
        minWidth: 174,
        Cell: ({ value }) => (value ? moment(value).format('L') : ''),
      },
      {
        Header: 'Due date',
        accessor: 'dueDate',
        isSortable: true,
        sortDescFirst: false,
        width: 10,
        minWidth: 174,
        Cell: ({ value }) => (value ? moment(value).format('L') : ''),
      },
      {
        Header: 'Attorney',
        accessor: 'attorney',
        width: 10,
        minWidth: 174,
        Cell: ({ value }) => (value ? `${value.firstName} ${value.lastName}` : ''),
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 10,
        minWidth: 174,
      },
      {
        Header: '',
        accessor: 'canComplete', // check if we need it
        Cell: (props) => CompleteCell({ ...props, stateCode }),
        width: 10,
        minWidth: 130,
      },
    ],
  },
  [CIVIL_MATTERS]: {
    title: 'Civil Matters',
    columns: [
      {
        Header: 'Reference #',
        accessor: 'referenceNumber',
        Cell: (props) => <ReferenceCell card={CIVIL_MATTERS} {...props} />,
        isSortable: true,
        sortDescFirst: true,
        width: 15,
        minWidth: 174,
      },
      {
        Header: 'Management',
        accessor: 'management',
        isSortable: true,
        sortDescFirst: false,
        width: 15,
        minWidth: 174,
      },
      {
        Header: 'Community',
        accessor: 'community',
        isSortable: true,
        sortDescFirst: false,
        width: 15,
        minWidth: 174,
      },
      {
        Header: 'Opposing Party',
        accessor: 'opposingParty',
        width: 10,
        minWidth: 174,
      },
      {
        Header: 'Attorney',
        accessor: 'attorney',
        Cell: ({ value }) => (value ? `${value.firstName} ${value.lastName}` : ''),
        width: 10,
        minWidth: 174,
      },
      {
        Header: 'Creation Date',
        accessor: 'created',
        Cell: ({ value }) => (value ? moment(value).format('L') : ''),
        isSortable: true,
        sortDescFirst: true,
        width: 10,
        minWidth: 174,
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdatedDate',
        Cell: ({ value }) => (value ? moment(value).format('L') : ''),
        isSortable: true,
        sortDescFirst: true,
        width: 10,
        minWidth: 174,
      },
      {
        Header: 'Category',
        accessor: 'timeCategoryHistory',
        Cell: LegalCategoryCell,
        width: 15,
        minWidth: 174,
      },
    ],
  },
};

export const LegalCategoriesHistoryColumns = [
  {
    Header: 'Date',
    accessor: 'createdDate',
    width: 20,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Category',
    accessor: 'name',
    width: 35,
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: 45,
    Cell: TextEllipsisCell,
  },
];
