import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import OwnerEntityContext from '../context/OwnerEntityContext';

export const defaultState = {
  name: '',
  contactInfo: {
    stateCode: '',
    city: '',
    address: '',
    zipCode: '',
    phone: '',
    email: '',
  },
  contacts: [],
};

const OwnerEntityProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const defaultContext = useMemo(
    () => ({
      state,
      actions: {
        update: (form) => setState(() => ({ ...state, ...form })),
        clear: () => setState(defaultState),
      },
    }),
    [state],
  );

  return (
    <OwnerEntityContext.Provider value={defaultContext}>{children}</OwnerEntityContext.Provider>
  );
};

OwnerEntityProvider.propTypes = {
  children: PropTypes.element,
};

OwnerEntityProvider.defaultProps = {
  children: null,
};

export default OwnerEntityProvider;
