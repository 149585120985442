import React from 'react';
import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';
import { ReactComponent as AddIcon } from '../../../../assets/icons/Add.svg';
import getFeesAndCosts from './CourtFeesAndCosts.constants';

export const courtHeading = 'Court Properties';

export const courtBoxes = [
  {
    name: '',
    inputBoxes: [
      {
        label: 'Court',
        isSubHeading: true,
      },
      {
        label: 'Court Name',
        name: 'name',
        placeholder: 'Enter court name',
        width: '326px',
        isMandatory: true,
      },
      {
        label: 'Court Type',
        name: 'type',
        placeholder: 'Select court type',
        width: '326px',
        isDropdown: true,
        isMandatory: true,
        state: 'AZ',
      },
      {
        label: 'Superior Court Name',
        name: 'superiorCourtName',
        placeholder: 'Select court',
        width: '326px',
        isDropdown: true,
        isMandatory: true,
        state: 'AZ',
      },
      {
        label: 'Lockout Available',
        name: 'additionalInfo.hasLockoutAvailable',
        isCheckbox: true,
        isMandatory: false,
        state: 'NV',
      },
    ],
  },
  {
    name: 'contactInfo',
    inputBoxes: [
      {
        label: 'Address',
        isSubHeading: true,
      },
      {
        label: 'Address',
        name: 'contactInfo.address',
        placeholder: 'Enter address',
        width: '444px',
        isMandatory: true,
      },
      {
        label: 'City',
        name: 'contactInfo.city',
        placeholder: 'Enter city',
        width: '208px',
        isMandatory: true,
      },
      {
        label: 'State',
        name: 'contactInfo.stateCode',
        placeholder: <span>&#8212;</span>,
        width: '94px',
        isDropdown: true,
        isMandatory: true,
        isDisabled: true,
      },
      {
        label: 'ZIP Code',
        name: 'contactInfo.zipCode',
        placeholder: 'xx xx xx',
        width: '208px',
        isMandatory: true,
      },
      {
        label: 'County',
        name: 'contactInfo.countyName',
        placeholder: 'Select county',
        width: '326px',
        isDropdown: true,
        isMandatory: true,
      },
    ],
  },
  {
    name: 'contactInfo',
    inputBoxes: [
      {
        label: 'Contact Info',
        isSubHeading: true,
      },
      {
        label: 'Phone',
        name: 'contactInfo.phone',
        placeholder: '+1(480)xx xx xx xx',
        width: '326px',
        isMandatory: true,
      },
      {
        label: 'Email',
        name: 'contactInfo.email',
        placeholder: 'Enter email',
        width: '326px',
      },
      {
        label: 'Fax',
        name: 'contactInfo.fax',
        placeholder: '+1(480)xx xx xx xx',
        width: '326px',
      },
      {
        label: 'Court Website',
        name: 'contactInfo.webSite',
        placeholder: 'Enter website',
        width: '326px',
      },
      {
        isSpace: true,
      },
      {
        label: 'Constable Name',
        name: 'contactInfo.constableName',
        placeholder: 'Enter constable name',
        width: '326px',
      },
      {
        label: 'Constable email',
        name: 'contactInfo.constableEmail',
        placeholder: 'Enter constable email',
        width: '326px',
      },
      {
        label: 'Constable phone',
        name: 'contactInfo.constablePhone',
        placeholder: 'Enter constable phone',
        width: '326px',
      },
      {
        isSpace: true,
      },
      {
        label: 'Process Server Name',
        name: 'contactInfo.processServerName',
        placeholder: 'Enter process server name',
        width: '326px',
      },
      {
        label: 'Process Server Email',
        name: 'contactInfo.processServerEmail',
        placeholder: 'Enter process server email',
        width: '326px',
      },
      {
        label: 'Process Server Phone',
        name: 'contactInfo.processServerPhone',
        placeholder: 'Enter process server phone',
        width: '326px',
      },
      {
        isSpace: true,
      },
      {
        label: 'Virtual Court #',
        name: 'contactInfo.virtualCourtNumber',
        placeholder: 'Enter virtual court #',
        width: '326px',
      },
      {
        label: 'Court Room Number',
        name: 'contactInfo.courtRoomNumber',
        placeholder: 'Enter court room number',
        width: '326px',
      },
      {
        label: 'Virtual Meeting #',
        name: 'contactInfo.virtualMeetingNumber',
        placeholder: 'Enter virtual meeting #',
        width: '326px',
      },
      {
        label: 'Virtual Meeting Link',
        name: 'contactInfo.virtualMeetingLink',
        placeholder: 'Enter virtual meeting link',
        width: '326px',
      },
      {
        isSpace: true,
      },
      {
        label: 'Meeting ID #',
        name: 'contactInfo.meetingIdNumber',
        placeholder: 'Enter meeting ID #',
        width: '446px',
        isMultiline: true,
      },
    ],
  },
  {
    name: 'feesAndCosts',
    inputBoxes: getFeesAndCosts,
  },
  {
    name: 'additionalInfo',
    inputBoxes: [
      {
        label: 'Additional Information',
        isSubHeading: true,
      },
      {
        label: 'Justice of the Peace',
        name: 'additionalInfo.justiceOfPeaceNumber',
        placeholder: 'Enter justice of the peace',
        width: '326px',
      },
      {
        label: 'Hearing Address',
        name: 'additionalInfo.hearingAddress',
        placeholder: 'Enter address',
        width: '326px',
      },
      {
        label: 'Trial Date',
        name: 'additionalInfo.trialDate',
        placeholder: 'mm/dd/yyyy',
        width: '199px',
        height: '48px',
        isDate: true,
      },
      {
        label: 'Trial Time',
        name: 'additionalInfo.trialTime',
        placeholder: 'hh:mm',
        width: '199px',
        height: '48px',
        isTime: true,
      },
      {
        label: 'In/Out of Town',
        name: 'additionalInfo.isInTown',
        placeholder: '',
        width: '330px',
        radioButtonWidth: '160px',
        isRadioButtons: true,
        data: [
          { id: 0, label: 'In Town', value: true },
          { id: 1, label: 'Out Of Town', value: false },
        ],
      },
    ],
  },
  {
    name: 'secondaryKeys',
    inputBoxes: [
      {
        label: 'Secondary Keys',
        isSubHeading: true,
      },
      {
        label: 'Customer Ref_List ID',
        name: 'secondaryKeys.customerRefListId',
        placeholder: 'Enter customer ID',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
    ],
  },
  {
    name: 'defaultTemplates',
    inputBoxes: [
      {
        label: 'Default Templates',
        isSubHeading: false,
        isSubHeadingWithButton: true,
        buttonName: 'Add',
        buttonIcon: <AddIcon />,
      },
      {
        label: '',
        name: 'defaultTemplates',
        placeholder: '',
        width: '444px',
        isTable: true,
      },
    ],
  },
];

export const defaultTemplatesColumns = [
  {
    Header: 'Template Name',
    accessor: 'templateName',
    width: 20,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 20,
  },
  {
    Header: 'Notice Type',
    accessor: 'noticeType',
    width: 20,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];

export const CourtTemplateColumns = [
  {
    Header: 'Template name',
    accessor: 'templateName',
    width: 50,
  },
  {
    Header: 'Type',
    accessor: 'templateType',
    width: 16,
  },
  {
    Header: 'Notice Type',
    accessor: 'noticeType',
    width: 30,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];

export const TemplatesAllColumns = [
  {
    Header: 'Template name',
    accessor: 'name',
    width: 45,
  },
  {
    Header: 'Type',
    accessor: 'templateType',
    width: 25,
  },
  {
    Header: 'Notice Type',
    accessor: 'noticeType',
    width: 25,
  },
];
