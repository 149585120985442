import React from 'react';
import { styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import MTooltip from '../../../../atoms/MTooltip';
import { ReactComponent as Asterisk } from '../../../../../assets/icons/asterisk.svg';
import { palette } from '../../../../../theme/default';

export const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

export const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

export const SectionTitle = ({ label, tooltip, isMandatory }) => {
  return (
    <StyledTypography width={1} mb={1} variant="bodyL500" key={label}>
      {label}
      {tooltip && (
        <MTooltip
          text={tooltip}
          customStyles={{
            position: 'absolute',
            top: '-10px',
            right: '-30px',
          }}
        />
      )}
      {isMandatory && (
        <StyledSpan style={{ alignSelf: 'flex-start' }}>
          <Asterisk />
        </StyledSpan>
      )}
    </StyledTypography>
  );
};

SectionTitle.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  isMandatory: PropTypes.bool,
};

SectionTitle.defaultProps = {
  label: '',
  tooltip: '',
  isMandatory: false,
};
