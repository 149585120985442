import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const ContactsColumns = [
  {
    Header: 'Contact Name',
    accessor: 'contactName',
    width: 48,
  },
  {
    Header: 'Title',
    accessor: 'contactTitle',
    width: 48,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];
