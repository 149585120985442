/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const createCourt = createAsyncThunk(
  'createCourt/createCourt',
  async (createCourtObj, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/courts`, { ...createCourtObj });

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

const initialState = {
  courtId: null,
  error: null,
};

export const createCourtSlice = createSlice({
  name: 'createCourt',
  initialState,
  reducers: {},
  extraReducers: {
    [createCourt.fulfilled]: (state, action) => {
      state.courtId = action.payload.result;
    },
    [createCourt.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default createCourtSlice.reducer;
