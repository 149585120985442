import React from 'react';
import { Navigate } from 'react-router-dom';
import { useBaseUrl } from '../../hooks/useBaseUrl';

const ToLoginPageRedirection = () => {
  const base = useBaseUrl();
  const href = `/${base}/login`;

  return <Navigate to={href} replace />;
};

export default ToLoginPageRedirection;
