import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import Table from '../../../../atoms/Table/Table';
import Searcher from '../../../../molecules/Searcher';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import NotificationDialog from '../../../../molecules/NotificationDialog';
import MButton from '../../../../MUI/Button/MButton';
import { ReactComponent as Cross } from '../../../../../assets/icons/Cross.svg';
import { palette } from '../../../../../theme/default';
import { CommunityAddressColumns, MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './CommunityAddress.constants';
import {
  getCommunityAddresses,
  deleteCommunityAddresses,
  resetCommunityError,
} from '../../../../../store/slices/editCommunitySlice';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';

const CommunityAddress = () => {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [isOpenNotificationDialog, setIsOpenNotificationDialog] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const [isShowAdditionalButton, setIsShowAdditionalButton] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isErrorNotification, setIsErrorNotification] = useState(false);
  const canDelete = useAllowed(rolesDB.Accounting, rolesDB.Attorney, rolesDB.Admin);

  const {
    state: { id, state: stateCode },
  } = useLocation();
  const { addresses, communityAddressError } = useSelector((state) => state.editCommunity);

  const currentPageDelete =
    addresses?.items?.length === 1 && addresses?.currentPage
      ? addresses.currentPage - 1
      : addresses?.currentPage;

  const handleCloseErrorMessage = useCallback(() => {
    setIsErrorNotification(false);
    dispatch(resetCommunityError());
  });

  useEffect(() => {
    if (communityAddressError?.errorMessage) {
      setTimeout(() => {
        setIsErrorNotification(true);
      }, 1000);
    }
  }, [communityAddressError]);

  useEffect(() => {
    dispatch(
      getCommunityAddresses({
        id,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        stateCode: stateCode?.value,
      }),
    ).then(() => setIsLoader(false));
  }, [id, stateCode?.value]);

  const handleChangeEmpty = useCallback(
    (searchElement) => {
      if (!searchElement) {
        setIsLoaderTable(true);
        dispatch(
          getCommunityAddresses({
            id,
            pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
            stateCode: stateCode?.value,
          }),
        ).then(() => {
          setSearchValue('');
          setIsLoaderTable(false);
        });
      }
    },
    [stateCode?.value],
  );

  const handleSearch = useCallback(
    (searchElement) => {
      setSearchValue(searchElement);
      setIsLoaderTable(true);
      dispatch(
        getCommunityAddresses({
          id,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          address: searchElement,
          stateCode: stateCode?.value,
        }),
      ).then(() => setIsLoaderTable(false));
    },
    [stateCode?.value],
  );

  const handleCheckedRow = useCallback(
    (selectedRows) => {
      setIsShowAdditionalButton(selectedRows.length > 0);
      setSelectedIds(selectedRows.map((item) => ({ addressId: item.addressId })));
    },
    [isShowAdditionalButton],
  );

  useEffect(() => {
    if (isShowAdditionalButton && !addresses?.items?.length) {
      setIsShowAdditionalButton(false);
    }
  }, [isShowAdditionalButton, addresses?.items?.length]);

  const handleDeleteCommunityAddress = useCallback(
    (idRow, original) => {
      dispatch(
        deleteCommunityAddresses({
          id,
          communityId: id,
          selectedAddresses: [
            {
              addressId: original.addressId,
            },
          ],
          removeAllAddresses: false,
        }),
      ).then(() => {
        setIsLoaderTable(true);
        dispatch(
          getCommunityAddresses({
            id,
            pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
            currentPage: currentPageDelete,
            stateCode: stateCode?.value,
          }),
        ).then(() => {
          setIsLoaderTable(false);
        });
      });
    },
    [currentPageDelete, stateCode?.value],
  );

  const handleDeleteCommunityAddresses = useCallback(() => {
    setIsOpenNotificationDialog(true);
  }, []);

  const handleConfirmNotificationDialog = useCallback(() => {
    dispatch(
      deleteCommunityAddresses({
        id,
        communityId: id,
        selectedAddresses: selectedIds,
        removeAllAddresses: false,
      }),
    ).then(() => {
      setIsLoaderTable(true);
      dispatch(
        getCommunityAddresses({
          id,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          stateCode: stateCode?.value,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    });
    setIsOpenNotificationDialog(false);
  }, [id, selectedIds, stateCode?.value]);

  const handleCloseNotificationDialog = useCallback(() => {
    setIsOpenNotificationDialog(false);
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      dispatch(
        getCommunityAddresses({
          id,
          currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          address: searchValue,
          stateCode: stateCode?.value,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue, stateCode?.value],
  );

  const rowsAdapter = useMemo(() => {
    return addresses?.items?.map((item) => ({
      ...item,
      control: {
        hideDelete: !canDelete,
        autoRefresh: true,
      },
    }));
  }, [addresses?.items, canDelete]);

  if (isLoader) {
    return (
      <Box position="absolute" top="50vh" left="50%" zIndex={10} transform="translate(-50%, -50%)">
        <CircularProgress />
      </Box>
    );
  }

  const errorDialog = (
    <NotificationDialog
      title="Error"
      desc={communityAddressError?.errorMessage}
      type="alert"
      buttonSecondaryText=""
      buttonPrimaryText="Ok"
      onClose={handleCloseErrorMessage}
      onConfirm={handleCloseErrorMessage}
      isOpen={isErrorNotification}
    />
  );

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        px: 4,
        maxWidth: 1066,
        width: 1,
        pb: 20,
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h5">Community Addresses</Typography>
        <Searcher
          inputPlaceholder="Search by Address"
          onSearch={handleSearch}
          onChange={handleChangeEmpty}
        />
        {!!addresses?.items?.length && (
          <Table
            columns={CommunityAddressColumns}
            rows={rowsAdapter}
            total={addresses?.totalRowsCount}
            isPagination={addresses?.totalPages > 1}
            onNextPage={handleFetchDataPage}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            onDeleteRow={handleDeleteCommunityAddress}
            loading={isLoaderTable}
            onCheckedRow={handleCheckedRow}
            isWithCheckbox={canDelete}
          />
        )}
        {!addresses?.items?.length && !searchValue && (
          <Stack direction="column" paddingTop="100px">
            <Box margin="auto">
              <EmptyBlock title="You don’t have any community addresses yet" />
            </Box>
          </Stack>
        )}
        {!addresses?.items?.length && searchValue && (
          <Stack direction="column" paddingTop="50px">
            <Box margin="auto">
              <EmptyBlock title="Community addresses not found" desc="Сhange the search value" />
            </Box>
          </Stack>
        )}
      </Stack>
      {isShowAdditionalButton && canDelete && (
        <Box
          position="absolute"
          left="50%"
          bottom={0}
          sx={{ transform: 'translateX(-50%)' }}
          zIndex={10}
          width={1}
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton
              variant="additional"
              size="large"
              startIcon={<Cross width="18px" height="18px" />}
              onClick={handleDeleteCommunityAddresses}
              sx={{ mr: 'auto' }}
            >
              Remove selected
            </MButton>
          </Stack>
          <NotificationDialog
            title="Confirm delete"
            desc="Are you sure you want to delete the selected items?"
            type="alert"
            buttonSecondaryText="Cancel"
            buttonPrimaryText="Delete"
            onClose={handleCloseNotificationDialog}
            onConfirm={handleConfirmNotificationDialog}
            isOpen={isOpenNotificationDialog}
          />
        </Box>
      )}
      {errorDialog}
    </Container>
  );
};

export default CommunityAddress;
