import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { ReactComponent as EyeSlashIcon } from '../../../assets/icons/EyeSlash.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/Eye.svg';
import { palette } from '../../../theme/default';
import { ReactComponent as Asterisk } from '../../../assets/icons/asterisk.svg';

const StyledInput = styled(OutlinedInput)(({ error, endAdornment }) => ({
  '&': {
    margin: 0,
    height: '48px',
    borderRadius: '10px',
    border: `1px solid ${error ? palette.additional.alert : palette.additional.lines}`,
    fontWeight: 500,
    fontSize: '16px',
  },
  '& fieldset': {
    border: 'none',
  },
  '& input': {
    padding: endAdornment ? '12px' : '12px 12px 12px 0',
    fontSize: '16px',
    fontWeight: 500,
    color: palette.text.secondary,
  },
  '& ::placeholder': {
    fontSize: '16px',
    fontWeight: 500,
    color: palette.text.secondary,
    opacity: 1,
  },
}));

const StyledInputLabel = styled(InputLabel)({
  '&': {
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '4px',
    color: palette.text.secondary,
    overflow: 'visible',
  },
});

const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

const filledValue = '************';

function Password({
  width,
  value,
  onChange,
  label,
  placeholder,
  error,
  isMandatory,
  tooltip,
  filled,
  endAdornment,
}) {
  const [fieldValue, setFieldValue] = useState(value);
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const passwValue = event.target.value;

    setFieldValue(passwValue);
    onChange(passwValue);
  };

  const getValue = () => {
    if (!isFocused && !showPassword && filled && !fieldValue) {
      return filledValue;
    }
    return fieldValue;
  };

  const hideEye = filled && !value;

  return (
    <Stack direction="column" position="relative" data-testid="password">
      <StyledInputLabel htmlFor="outlined-adornment-password" data-testid="password_label">
        {label}
        {isMandatory && (
          <StyledSpan data-testid="password_mandatory_asterisk">
            <Asterisk />
          </StyledSpan>
        )}
        {tooltip}
      </StyledInputLabel>
      <StyledInput
        error={error.message}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        sx={{ m: 0, width }}
        type={showPassword ? 'text' : 'password'}
        value={getValue()}
        onChange={handleChange}
        autoComplete="new-password"
        id="outlined-adornment-password"
        placeholder={placeholder}
        inputProps={{ 'data-testid': 'password_input' }}
        endAdornment={
          endAdornment ? (
            <InputAdornment position="end" sx={{ visibility: hideEye ? 'hidden' : 'visible' }}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                data-testid="password_visibility_icon_end"
              >
                {showPassword ? <EyeIcon width="24px" height="24px" /> : <EyeSlashIcon />}
              </IconButton>
            </InputAdornment>
          ) : null
        }
        startAdornment={
          !endAdornment ? (
            <InputAdornment position="start" sx={{ visibility: hideEye ? 'hidden' : 'visible' }}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="start"
                data-testid="password_visibility_icon_start"
              >
                {showPassword ? <EyeIcon width="24px" height="24px" /> : <EyeSlashIcon />}
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
      {error.message && (
        <Typography
          variant="bodyXS"
          color="additional.alert"
          component="span"
          position="absolute"
          left={0}
          top="calc(100% + 4px)"
        >
          {error.message}
        </Typography>
      )}
    </Stack>
  );
}

Password.propTypes = {
  width: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.objectOf,
  isMandatory: PropTypes.bool,
  tooltip: PropTypes.node,
  endAdornment: PropTypes.bool,
  filled: PropTypes.bool,
};

Password.defaultProps = {
  width: '',
  value: '',
  placeholder: '',
  label: '',
  onChange: () => {},
  error: {},
  isMandatory: false,
  tooltip: null,
  endAdornment: true,
  filled: false,
};

export default Password;
