import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { palette } from '../../../theme/default';

const FullscreenLoaderWithText = ({ title, description, actions }) => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.4)',
        zIndex: 20,
        userSelect: 'none',
      }}
    >
      <Stack
        gap={2}
        alignItems="center"
        padding={4}
        borderRadius={4}
        backgroundColor={palette.common.white}
        boxShadow={`0px 3px 32px ${palette.shadow.boxBlur}`}
        maxWidth="500px"
        data-testid="loader_wrapper"
      >
        {title && (
          <Typography variant="h5" data-testid="loader_title">
            {title}
          </Typography>
        )}
        <LinearProgress sx={{ my: 2, width: '100%' }} data-testid="loader_progress" />
        {description && (
          <Typography variant="bodyM" data-testid="loader_description">
            {description}
          </Typography>
        )}
        {actions && (
          <Stack direction="row" gap={2} pt={2} data-testid="loader_actions">
            {actions}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

FullscreenLoaderWithText.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  actions: PropTypes.node,
};

FullscreenLoaderWithText.defaultProps = {
  title: '',
  description: '',
  actions: null,
};

export default FullscreenLoaderWithText;
