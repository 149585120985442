import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MDialog from '../../../atoms/MDialog';
import { getUsersForNotificationRules } from '../../../../store/slices/adminClientRequestNotificationsSlice';
import Table, { TableLoader } from '../../../atoms/Table/Table';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import EmptyBlock from '../../../molecules/EmptyBlock';
import MButton from '../../../MUI/Button/MButton';
import {
  MAX_AMOUNT_OF_ITEMS_IN_MODAL,
  SelectUsersTableColumns,
} from './ClientRequestNotifications.constants';
import Dropdown from '../../../atoms/Dropdown';

const UsersDialog = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const { states, users } = useSelector((state) => state.adminClientRequestNotifications);
  const [isLoading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const fetchUsersForNotificationRules = (currentPage = 1, extendWithFilters = true) => {
    setTableLoading(true);

    const params = {
      pageSize: MAX_AMOUNT_OF_ITEMS_IN_MODAL,
      currentPage,
    };

    if (extendWithFilters) {
      if (selectedState) params.stateCode = selectedState.value;
      if (firstName) params.firstName = firstName;
      if (lastName) params.lastName = lastName;
    }

    return dispatch(getUsersForNotificationRules(params)).then(() => {
      setTableLoading(false);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (isOpen) {
      fetchUsersForNotificationRules();
    } else {
      setIsLoading(true);
    }
  }, [isOpen]);

  const canSave = selectedUsers?.length;

  const resetFields = () => {
    setSelectedUsers([]);
    setFirstName('');
    setLastName('');
    setSelectedState(null);
  };

  const handleReset = () => {
    resetFields();
    setIsLoading(true);
    fetchUsersForNotificationRules(1, false);
  };

  const handleSearch = () => {
    setIsLoading(true);
    fetchUsersForNotificationRules();
  };

  const handleClose = () => {
    onClose(null);
    resetFields();
  };

  const handleSubmit = () => {
    onClose(selectedUsers);
    resetFields();
  };

  const onInputChange =
    (setter) =>
    ({ target: { value } }) =>
      setter(value);

  const handleCheckedRow = (selectedRows) => {
    setSelectedUsers(selectedRows);
  };

  return (
    <MDialog
      fullWidth
      maxWidth="md"
      title="User(s)"
      isOpen={isOpen}
      onClose={handleClose}
      scrollable
      footerActions={[
        <MButton
          data-testid="cancel_add_user_btn"
          sx={{ width: '150px' }}
          variant="bordered"
          size="large"
          onClick={handleClose}
        >
          Cancel
        </MButton>,
        <MButton
          sx={{ width: '150px' }}
          disabled={!canSave}
          variant="primary"
          size="large"
          onClick={handleSubmit}
          data-testid="add_users_btn"
        >
          Add
        </MButton>,
      ]}
    >
      <Stack direction="column" gap={3}>
        <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
          <EditInputWithLabel
            name="firstName"
            onChange={onInputChange(setFirstName)}
            label="First Name"
            placeholder="Enter first name"
            width="190px"
            value={firstName}
          />
          <EditInputWithLabel
            name="lastName"
            onChange={onInputChange(setLastName)}
            label="Last Name"
            placeholder="Enter last name"
            width="190px"
            value={lastName}
          />
          <Dropdown
            isColumn
            label="State"
            value={selectedState}
            width="160px"
            placeholder="Select state"
            onChange={setSelectedState}
            options={states}
          />
          <Stack gap={1} display="flex" flexDirection="row" pt={3.5}>
            <MButton variant="primary" data-testid="search_btn" size="large" onClick={handleSearch}>
              Search
            </MButton>
            <MButton
              variant="borderLess"
              data-testid="clear_btn"
              size="large"
              onClick={handleReset}
            >
              Clear filter
            </MButton>
          </Stack>
        </Stack>
        <Box height="max(calc(100vh - 400px), 250px)">
          {isLoading && <TableLoader />}
          {!users.items.length && !isLoading && !tableLoading && (
            <Stack alignItems="center" justifyContent="center" height="100%">
              <EmptyBlock title="No users for client request notification rule found" />
            </Stack>
          )}
          {!!users.items.length && !isLoading && (
            <Table
              isWithCheckbox
              columns={SelectUsersTableColumns}
              rows={users.items}
              total={users.totalRowsCount}
              isPagination={users?.totalPages > 1}
              onCheckedRow={handleCheckedRow}
              onNextPage={fetchUsersForNotificationRules}
              onPreviousPage={fetchUsersForNotificationRules}
              onGotoPage={fetchUsersForNotificationRules}
              pageSize={MAX_AMOUNT_OF_ITEMS_IN_MODAL}
              loading={tableLoading}
            />
          )}
        </Box>
      </Stack>
    </MDialog>
  );
};

UsersDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UsersDialog;
