import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Stack, Typography, Accordion, styled, AccordionSummary } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  proceduralHistoryForm,
  hearingTypes,
  proceduralHistoryColumns,
  proceduralHistoryFormEvictionMatter,
  proceduralHistoryColumnsEvictionMatter,
} from './CaseInformationArizona.constants';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import Dropdown from '../../../atoms/Dropdown';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import { ReactComponent as Clock } from '../../../../assets/icons/Clock.svg';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import MButton from '../../../MUI/Button/MButton';
import Table from '../../../atoms/Table/Table';
import {
  getArizonaAttorneys,
  addArizonaProceduralHistoryEntry,
  updateArizonaProceduralHistoryEntry,
  getArizonaProceduralHistoryEntries,
  resetArizonaCaseInformation,
  getArizonaStatusesForCase,
  getArizonaWritDate,
  removeArizonaProceduralHistoryEntry,
} from '../../../../store/slices/cases/caseInformationArizonaSlice';
import formatDateAndTimeToISO from '../../../../utils/formatDateAndTimeToISO';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import LoaderCover from '../../../atoms/LoaderCover';
import notificationUtils from '../../../../utils/notificationUtils';

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
    '.MuiPaper-root': {
      boxShadow: '0px 3px 20px rgba(69, 80, 121, 0.13)',
      marginBottom: '16px',
      borderRadius: '24px',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '16px',
      height: '16px',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 32px 32px',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

const ProceduralHistory = () => {
  const form = useForm();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id, caseType } = location.state;

  const [isLoader, setIsLoader] = useState(false);

  const baseForm =
    caseType === 'Eviction Matter' ? proceduralHistoryFormEvictionMatter : proceduralHistoryForm;

  const { boxTitle, inputBoxes, withAdd } = baseForm;

  const columns =
    caseType === 'Eviction Matter'
      ? proceduralHistoryColumnsEvictionMatter
      : proceduralHistoryColumns;
  const { attorneys, proceduralHistoryEntries } = useSelector(
    (state) => state.caseInformationArizona,
  );

  useEffect(() => {
    dispatch(getArizonaProceduralHistoryEntries(id));
    dispatch(getArizonaAttorneys(id));

    return () => dispatch(resetArizonaCaseInformation());
  }, []);

  const judgmentGranted = form.watch('proceduralHistory.judgmentGranted');
  const dismissedByJudge = form.watch('proceduralHistory.dismissedByJudge');
  const courtDate = form.watch('proceduralHistory.courtDate');

  useEffect(() => {
    if (judgmentGranted || dismissedByJudge) {
      const [data] = proceduralHistoryEntries;
      if (data) {
        form.setValue(
          'proceduralHistory.assignedAttorney',
          attorneys.find((item) => item.label === data.assignedAttorney),
        );
        form.setValue(
          'proceduralHistory.hearingType',
          hearingTypes.find((item) => item.label === data.hearingType),
        );
        form.setValue('proceduralHistory.courtDate', data.courtDateTime);
        form.setValue('proceduralHistory.courtTime', data.courtDateTime);
        form.setValue('proceduralHistory.dateOfWrit', dismissedByJudge ? null : data.dateOfWrit);
      }
    } else {
      form.setValue('proceduralHistory.assignedAttorney', '');
      form.setValue('proceduralHistory.hearingType', '');
      form.setValue('proceduralHistory.courtDate', '');
      form.setValue('proceduralHistory.courtTime', '');
      form.setValue('proceduralHistory.dateOfWrit', '');
    }
  }, [attorneys, hearingTypes, judgmentGranted, dismissedByJudge]);

  useEffect(() => {
    if (courtDate && !dismissedByJudge) {
      setIsLoader(true);
      const req = dispatch(getArizonaWritDate({ courtDate, caseId: id }))
        .unwrap()
        .then((res) => {
          if (res) {
            form.setValue('proceduralHistory.dateOfWrit', moment(res));
          }
        })
        .catch(() => {})
        .finally(() => setIsLoader(false));
      return () => req.abort?.();
    }
    return () => {};
  }, [courtDate]);

  const getOptions = useCallback(
    (name) => {
      switch (name) {
        case 'proceduralHistory.hearingType':
          return hearingTypes;
        case 'proceduralHistory.assignedAttorney':
          return attorneys;
        default:
          return [];
      }
    },
    [attorneys],
  );

  const getDisabled = useCallback(
    (name) => {
      switch (name) {
        case 'proceduralHistory.judgmentGranted':
          return !!dismissedByJudge;
        case 'proceduralHistory.dismissedByJudge':
          return !!judgmentGranted;
        default:
          return false;
      }
    },
    [dismissedByJudge, judgmentGranted],
  );

  const handleSubmit = (data) => {
    const { proceduralHistory } = data;
    setIsLoader(true);
    const formValues = {
      id,
      caseId: id,
      hearingType: proceduralHistory.hearingType.value,
      attorneyUserId: proceduralHistory.assignedAttorney?.id,
      dateOfWrit: proceduralHistory.dateOfWrit || null,
      courtDateTime: null,
      hasJudgmentGranted: Boolean(proceduralHistory.judgmentGranted),
      hasDismissedByJudge: Boolean(proceduralHistory.dismissedByJudge),
      comment: proceduralHistory.comment || '',
      IsAmmendedTime: Boolean(proceduralHistory.isAmmendedTime),
      isTentative: Boolean(proceduralHistory.isTentative),
    };

    if (proceduralHistory.courtDate) {
      formValues.courtDateTime = formatDateAndTimeToISO(
        proceduralHistory.courtDate,
        proceduralHistory.courtTime || moment(),
      );
    }

    dispatch(addArizonaProceduralHistoryEntry(formValues))
      .unwrap()
      .then(() => {
        dispatch(getArizonaProceduralHistoryEntries(id));
        dispatch(getArizonaStatusesForCase(id));
        form.reset();
        notificationUtils.success('Added successfully');
      })
      .catch((e) => notificationUtils.error(e?.errorMessage || 'Something went wrong'))
      .finally(() => setIsLoader(false));
  };

  const onProceduralHistoryUpdate = async (row, { assignedAttorney, proceduralHistoryEntryId }) => {
    if (assignedAttorney?.value) {
      setIsLoader(true);
      try {
        await dispatch(
          updateArizonaProceduralHistoryEntry({
            id,
            proceduralHistoryEntryId,
            body: {
              caseId: id,
              attorneyUserId: assignedAttorney.value,
              caseProceduralHistoryEntryId: proceduralHistoryEntryId,
            },
          }),
        ).unwrap();
        await dispatch(getArizonaProceduralHistoryEntries(id)).unwrap();
        notificationUtils.success('Updated successfully');
      } catch (e) {
        notificationUtils.error(e?.errorMessage || 'Something went wrong');
      }
      setIsLoader(false);
    }
  };

  const onProceduralHistoryDelete = (value, original) => {
    const payload = {
      id,
      proceduralEntryId: original.proceduralHistoryEntryId,
    };

    dispatch(removeArizonaProceduralHistoryEntry(payload))
      .then(() => {
        dispatch(getArizonaProceduralHistoryEntries(id));
        notificationUtils.success('Deleted successfully');
      })
      .catch((e) => notificationUtils.error(e?.errorMessage || 'Something went wrong'));
  };

  const rowsAdapter = useMemo(
    () =>
      proceduralHistoryEntries?.map((item) => ({
        ...item,
        ...(item.assignedAttorney ? {} : { attorneys }),
      })),
    [attorneys, proceduralHistoryEntries],
  );

  const mandatoryFields = useMemo(
    () => inputBoxes.filter((i) => i.isMandatory).map((i) => i.name),
    [],
  );

  const areMandatoryFieldsFilled = useMemo(() => {
    const checkIfFieldsNotEmpty = (fields) => {
      for (let i = 0; i < fields.length; i += 1) {
        if (!fields[i]) return false;
      }
      return true;
    };
    return mandatoryFields.length ? checkIfFieldsNotEmpty(form.watch(mandatoryFields)) : true;
  }, [...form.watch(mandatoryFields)]);

  return (
    <Form onSubmit={handleSubmit} form={form}>
      {isLoader && <LoaderCover isFixed />}
      <StyledContainer
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          px: 4,
          maxWidth: 1542,
          width: 1,
          position: 'relative',
        }}
      >
        <StyledAccordion defaultExpanded sx={{ p: 4 }}>
          <AccordionSummary expandIcon={<ArrowDown />} sx={{ padding: 0 }}>
            <Typography variant="h5">{boxTitle}</Typography>
          </AccordionSummary>
          <Stack direction="row" alignItems="center" columnGap={3} rowGap={2} flexWrap="wrap">
            {inputBoxes.map((item) => {
              if (item.isDropdown) {
                return (
                  <Field
                    key={item.name}
                    name={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, onCustomChange }) => (
                      <Dropdown
                        value={field.value}
                        isAddDisabled={false}
                        isSearchable
                        label={item.label}
                        width={item.width}
                        isColumn
                        placeholder={item.placeholder}
                        options={getOptions(item.name)}
                        onChange={onCustomChange(field.onChange)}
                        isDisabled={item.isDisabled}
                        isMandatory={item.isMandatory}
                      />
                    )}
                  />
                );
              }

              if (item.isSpace) {
                return <div style={{ width: '100%' }} key={item.label} />;
              }

              if (item.isCheckbox) {
                return (
                  <Field
                    key={item.name}
                    name={item.name}
                    render={({ field, onCustomChange }) => (
                      <CheckboxWithLabel
                        label={item.label}
                        isChecked={field.value}
                        isDisabled={getDisabled(item.name)}
                        onChange={onCustomChange(field.onChange)}
                        marginTop="25px"
                      />
                    )}
                  />
                );
              }

              if (item.isDatePicker) {
                return (
                  <EditInputWithLabel
                    label={item.label}
                    key={item.name}
                    isMandatory={item.isMandatory}
                  >
                    <Field
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <DateOrTimePicker
                          name={item.name}
                          isDate
                          Svg={Calender}
                          selected={field.value}
                          width={item.width}
                          height={item.height}
                          placeholder={item.placeholder}
                          setSelectedTimeOrDate={onCustomChange(field.onChange)}
                        />
                      )}
                    />
                  </EditInputWithLabel>
                );
              }

              if (item.isTimePicker) {
                return (
                  <EditInputWithLabel
                    label={item.label}
                    key={item.name}
                    isMandatory={item.isMandatory}
                  >
                    <Field
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <DateOrTimePicker
                          name={item.name}
                          isTime
                          Svg={Clock}
                          selected={field.value}
                          setSelectedTimeOrDate={onCustomChange(field.onChange)}
                          width={item.width}
                          height={item.height}
                          placeholder={item.placeholder}
                        />
                      )}
                    />
                  </EditInputWithLabel>
                );
              }

              const editInputField = (
                <Field
                  name={item.name}
                  key={item.name}
                  render={({ field }) => (
                    <EditInputWithLabel
                      type="text"
                      label={item.label}
                      placeholder={item.placeholder}
                      width={item.width}
                      name={item.name}
                      value={field.value}
                      onChange={field.onChange}
                      isDisabled={item.isDisabled}
                    />
                  )}
                />
              );

              return editInputField;
            })}

            {withAdd && (
              <>
                <MButton
                  disabled={!areMandatoryFieldsFilled || isLoader}
                  startIcon={<Add />}
                  sx={{
                    ml: 'auto',
                    mt: '28px',
                  }}
                  type="submit"
                >
                  Add
                </MButton>
                <Table
                  columns={columns}
                  loading={false}
                  rows={rowsAdapter}
                  onUpdateData={onProceduralHistoryUpdate}
                  onDeleteRow={onProceduralHistoryDelete}
                  overflowHidden={false}
                />
              </>
            )}
          </Stack>
        </StyledAccordion>
      </StyledContainer>
    </Form>
  );
};

export default ProceduralHistory;
