import React, { useEffect, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import MButton from '../../../MUI/Button/MButton';
import Dropdown from '../../../atoms/Dropdown';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import Field from '../../../atoms/Field';
import Form from '../../../atoms/Form';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import {
  generateSelfEnrollmentLinks,
  getCompanyManagementsList,
} from '../../../../store/slices/clientPortalAdminUsers';
import MDialog from '../../../atoms/MDialog';
import LoaderCover from '../../../atoms/LoaderCover';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/CopyDark.svg';

const SelfEnrollmentDialog = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { companyManagements } = useSelector((state) => state.clientPortalAdminUsers);
  const [isLoading, setIsLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [isLinksOpen, setLinksOpen] = useState(false);

  const canSetManagement = useAllowed([rolesDB.Admin]);

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: companyManagements.hasNextPage,
    getAction: ({ input, page }) =>
      getCompanyManagementsList({
        currentPage: page,
        pageSize: 20,
        managementName: input,
      }),
  });

  useEffect(() => {
    if (isOpen) {
      form.reset({
        management: null,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!canSetManagement && companyManagements?.items?.length) {
      form.reset({
        management: companyManagements.items[0],
      });
    }
  }, [companyManagements.items, canSetManagement, isOpen]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const { management, numberOfLinks } = data;

    try {
      const baseUrl = `${window.location.origin}/self-enrollment/`;
      const {
        result: { tokens },
      } = await dispatch(
        generateSelfEnrollmentLinks({
          managementId: management.value,
          groupSize: +numberOfLinks,
        }),
      ).unwrap();
      setLinks(tokens.map(({ token }) => `${baseUrl}${token}`));
      setLinksOpen(true);
      onClose();
    } catch (e) {
      // TODO: Add error popup
      alert('--------------', e);
    }
    setIsLoading(false);
  };

  const copyToClipboard = (text) => navigator.clipboard.writeText(text);

  return (
    <>
      <MDialog
        onClose={onClose}
        isOpen={isOpen}
        minWidth="sm"
        fullWidth
        title="Self Enrollment Link(s)"
        titleVariant="h4"
        footerActions={
          <>
            <MButton variant="secondary" size="large" disabled={isLoading} onClick={onClose}>
              Close
            </MButton>
            <MButton
              type="submit"
              size="large"
              disabled={!form.formState.isValid || isLoading}
              onClick={form.handleSubmit(onSubmit)}
            >
              Generate Link(s)
            </MButton>
          </>
        }
      >
        {isLoading && <LoaderCover />}
        <Form data-testid="generate_links_form" form={form}>
          <Stack spacing={4} direction="column">
            <Field
              name="management"
              isMandatory
              render={({ field, onCustomChange }) => (
                <Dropdown
                  value={field.value}
                  isSearchable
                  label="Management Company"
                  width="100%"
                  isColumn
                  placeholder="Select management company"
                  options={companyManagements?.items || []}
                  onChange={onCustomChange(field.onChange)}
                  onInputChange={changeInput}
                  onMenuScrollToBottom={handleMenuScrollToBottom}
                  isDisabled={!canSetManagement}
                />
              )}
            />
            <Field
              name="numberOfLinks"
              isMandatory
              render={({ field }) => {
                return (
                  <EditInputWithLabel
                    label="Number of Link(s)"
                    width="158px"
                    type="int"
                    name="numberOfLinks"
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </Stack>
        </Form>
      </MDialog>
      <MDialog
        onClose={() => setLinksOpen(false)}
        scrollable
        isOpen={isLinksOpen}
        minWidth="sm"
        fullWidth
        title="Link(s)"
        titleVariant="h4"
        footerActions={
          <>
            <MButton variant="secondary" size="large" onClick={() => setLinksOpen(false)}>
              Close
            </MButton>
            <MButton
              type="submit"
              size="large"
              onClick={() => {
                copyToClipboard(links.join('\n'));
              }}
            >
              Copy Link(s)
            </MButton>
          </>
        }
      >
        <Stack data-testid="links-table" rowGap={3}>
          {links.map((link) => (
            <Stack direction="row" columnGap={1} key={link} alignItems="end" flexWrap="nowrap">
              <EditInputWithLabel label="Generated Link" width="100%" value={link} />
              <IconButton
                sx={{ width: '50px', height: '50px' }}
                onClick={() => copyToClipboard(link)}
              >
                <CopyIcon />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      </MDialog>
    </>
  );
};

SelfEnrollmentDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

SelfEnrollmentDialog.defaultProps = {
  isOpen: false,
  onClose: null,
};

export default SelfEnrollmentDialog;
