import { ReferenceNumberCell } from '../../Processing/CaseSearch/CaseSearchCells';

export const stateCodesOptions = [
  { value: 'AZ', label: 'AZ' },
  { value: 'NV', label: 'NV' },
];

export const EvictionFiledFormBoxes = [
  {
    name: 'dataFields',
    inputBoxes: [
      {
        label: 'Date',
        name: 'selectedDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'State',
        name: 'stateCode',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'County(s)',
        name: 'county',
        placeholder: 'Select county(s)',
        width: '226px',
        isCheckboxSelect: true,
        showSelectAll: true,
      },
      {
        label: 'Court(s)',
        name: 'courts',
        placeholder: 'Select court(s)',
        width: '226px',
        isCheckboxSelect: true,
        showSelectAll: true,
      },
    ],
  },
];

export const EvictionFiledColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    Cell: (props) => <ReferenceNumberCell {...props} isBlank />,
  },
  {
    Header: 'Owner Entity',
    accessor: 'ownerEntities',
  },
  {
    Header: 'Opposing Party',
    accessor: 'opposingParty',
  },
  {
    Header: 'Notes',
    accessor: 'notes',
  },
];
