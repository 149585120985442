import { PropTypes } from 'prop-types';

export const IconCell = ({ isActive, config }) => {
  const { icon: activeIcon } = config.find((item) => item.role === 'active');
  const { icon: disabledIcon } = config.find((item) => item.role === 'disabled');

  return isActive ? activeIcon : disabledIcon;
};

IconCell.propTypes = {
  isActive: PropTypes.bool.isRequired,
  config: PropTypes.arrayOf(
    PropTypes.shape({ icon: PropTypes.node.isRequired, role: PropTypes.string.isRequired }),
  ).isRequired,
};
