import React from 'react';
import { Box, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import {
  editAssignedAttorneysList,
  refreshPage,
} from '../../../../store/slices/schedule/attorneyAssignmentsSlice';
import notificationUtils from '../../../../utils/notificationUtils';

const AttorneyAssignmentsFooter = () => {
  const dispatch = useDispatch();

  const { newCourtAttorney, currentState } = useSelector((state) => state.attorneyAssignments);

  const handleAssignAttorney = () => {
    dispatch(
      editAssignedAttorneysList({
        stateCode: currentState,
        attorneyAssignments: Object.values(newCourtAttorney),
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(refreshPage(true));
      })
      .catch((rejectedValueError) => {
        notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
      });
  };

  return (
    <Box
      position="absolute"
      left="50%"
      bottom={0}
      sx={{ transform: 'translateX(-50%)' }}
      zIndex={10}
      width={1}
      p={4}
      bgcolor="#FFF"
      boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
    >
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <MButton
          size="large"
          sx={{ height: '48px' }}
          onClick={handleAssignAttorney}
          disabled={!Object.keys(newCourtAttorney).length}
        >
          Assign attorney
        </MButton>
      </Stack>
    </Box>
  );
};

export default AttorneyAssignmentsFooter;
