/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { apiClient } from '../../lib/apiClient';

export const getCourts = createAsyncThunk('court/getCourts', async (params) => {
  const { data } = await apiClient.post(`/api/courts/filter`, { ...params });
  data.result.items = data.result.items.map((item) => ({
    ...item,
    trialDate: item.trialDateTime ? moment(item.trialDateTime).format('l') : '',
    trialTime: item.trialDateTime ? moment(item.trialDateTime).format('LT') : '',
  }));
  return data;
});

export const getStates = createAsyncThunk('court/states', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data;
});

const initialState = {
  courts: {},
  states: [],
  error: null,
};

export const courtsSlice = createSlice({
  name: 'courts',
  initialState,
  reducers: {},
  extraReducers: {
    [getCourts.fulfilled]: (state, action) => {
      state.courts = action.payload.result;
    },
    [getCourts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.result.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.code,
      }));
    },
  },
});

export default courtsSlice.reducer;
