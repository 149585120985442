import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import CourtContext from '../context/CourtContext';

export const defaultState = {
  courtId: 0,
  name: '',
  type: {
    id: 1,
    label: 'Justice',
    value: 'Justice',
  },
  feesAndCosts: {
    attorneyFee: '',
    attorneyFeeImmediate: '',
    courtCost: '',
    forcibleCost: '',
    dismissalFee: '',
    courtFillingFee: '',
    judgementFee: '',
    satisfactionFee: '',
    writFee: '',
    processServerFee: '',
    processServerFeeBillable: false,
    immediateNoticeFee: '',
    writCostJc: '',
    writConstableCost: '',
    milage: '',
    maxTotalLateCharges: '',
  },
  additionalInfo: {
    justiceOfPeaceNumber: '',
    hearingAddress: '',
    trialDateTime: null,
    isInTown: true,
    trialDate: '',
    trialTime: '',
  },
  contactInfo: {
    countyId: 0,
    countyName: null,
    stateCode: null,
    city: '',
    address: '',
    zipCode: '',
    phone: '',
    email: null,
    fax: null,
    webSite: '',
    virtualCourtNumber: '',
    meetingIdNumber: '',
    courtRoomNumber: '',
    virtualMeetingNumber: '',
    virtualMeetingLink: '',
  },
  defaultTemplates: [],
  courtTypes: [
    {
      id: 1,
      label: 'Justice',
      value: 'Justice',
    },
    {
      id: 2,
      label: 'Superior',
      value: 'Superior',
    },
  ],
};

const CourtProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const defaultContext = useMemo(
    () => ({
      state,
      actions: {
        update: (form) => setState(() => ({ ...state, ...form })),
        clear: () => setState(defaultState),
      },
    }),
    [state],
  );

  return <CourtContext.Provider value={defaultContext}>{children}</CourtContext.Provider>;
};

CourtProvider.propTypes = {
  children: PropTypes.element,
};

CourtProvider.defaultProps = {
  children: null,
};

export default CourtProvider;
