import { useEffect, useState } from 'react';
import { unstable_useBlocker as useBlocker, useNavigate } from 'react-router-dom';
import NotificationDialog from '../components/molecules/NotificationDialog';
import useAuth from './useAuth';

export const useNavigationBlockPopup = ({
  title = 'Are you sure?',
  description = 'There are unsaved changes on screen. You will loose unsaved modifications if you navigate away.',
  confirmButton = 'Yes',
  cancelButton = 'No',
  blockerByDefault = true,
  onConfirm,
  onClose,
} = {}) => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const { isLogout, onLogout, setLogout } = useAuth();
  const [blocker, setBlocker] = useState(blockerByDefault);

  const { reset, proceed, state } = useBlocker(blocker);

  useEffect(() => {
    sessionStorage.setItem('blocker', blockerByDefault ? 'active' : 'inactive');
  }, [blockerByDefault]);

  useEffect(() => {
    if (state === 'blocked') {
      setOpen(true);
    }
  }, [state]);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
    reset?.();
    // reset isLogout if we click cancel
    // it gives an ability to continue to operate with app
    if (isLogout) {
      setLogout(false);
    }
  };

  const handleChangeBlockerByDefault = (val) => {
    setBlocker(val);
    // reset blocker state
    sessionStorage.setItem('blocker', val ? 'active' : 'inactive');
  };

  const handleConfirm = async () => {
    setOpen(false);
    // if there was an action of logout
    // we need to logout after the confirmation for blocker
    sessionStorage.setItem('blocker', 'inactive');
    if (isLogout) {
      await onLogout();
    } else {
      onConfirm?.();
    }
    proceed?.();
  };
  const navigateWithoutBlock = (to, options) => {
    setBlocker(false);
    // Needed because setState is async, and useBlocker doesnt have enough time to update and still blocks transition
    setTimeout(() => {
      navigate(to, options);
    }, 100);
  };

  const popup = (
    <NotificationDialog
      title={title}
      desc={description}
      type="alert"
      buttonSecondaryText={cancelButton}
      buttonPrimaryText={confirmButton}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isOpen={isOpen}
    />
  );

  return {
    popup,
    setBlocker,
    navigateWithoutBlock,
    handleChangeBlockerByDefault,
  };
};
