import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import notificationUtils from '../../../../utils/notificationUtils';
import SearchInputWithLabel from '../../../atoms/SearchInputWithLabel/SearchInputWithLabel';
import Field from '../../../atoms/Field';
import Form from '../../../atoms/Form';
import MButton from '../../../MUI/Button/MButton';
import EmptyBlock from '../../../molecules/EmptyBlock';
import Table from '../../../atoms/Table/Table';
import LoaderCover from '../../../atoms/LoaderCover';
import ShadowBlock from '../../../atoms/ShadowBlock';
import { billingInstancesTableColumns } from './BillingInstances.constants';
import { apiClient } from '../../../../lib/apiClient';

const BillingInstances = () => {
  useSafeLocationState('Accounting', 'Billing Instances');
  const form = useForm();
  const [isLoading, setLoading] = useState(false);
  const [tableLoading, setIsTableLoader] = useState(false);
  const [billingInstances, setInstances] = useState(null);

  const refNum = form.watch('referenceNumber');

  const handleFetchDataPage = async (currentPage = 1) => {
    setIsTableLoader(true);

    const params = {
      pageSize: 10,
      currentPage,
      caseReferenceNumber: refNum,
    };

    try {
      const res = await apiClient.post(`/api/invoices/billingInstancesByCase/filter`, params);
      setInstances(res.data.result);
    } catch {
      notificationUtils.error('Failed, try again later');
    } finally {
      setIsTableLoader(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      handleFetchDataPage();
    } catch (e) {
      notificationUtils.error('Unknown case number');
      setInstances(null);
    }
    setLoading(false);
  };

  return (
    <Stack>
      <Typography variant="h4" margin="32px 32px 64px" data-testid="page_title">
        Billing Instances
      </Typography>

      <Form form={form} onSubmit={handleSubmit}>
        <Stack direction="row" ml={4}>
          <Field
            name="referenceNumber"
            render={({ field, onCustomChange }) => (
              <SearchInputWithLabel
                label="Reference #"
                placeholder="xxxxxxxxx"
                onChangeValue={onCustomChange(field.onChange)}
                name="referenceNumber"
                value={field.value}
                width="200px"
              />
            )}
          />
          <MButton
            data-testid="apply_btn"
            disabled={!refNum}
            sx={{ marginLeft: '24px' }}
            variant="secondary"
            type="submit"
          >
            Apply
          </MButton>
        </Stack>
      </Form>
      {isLoading && <LoaderCover />}
      {billingInstances && !isLoading && (
        <Box width="95%" margin="0 auto" mt={6}>
          <ShadowBlock>
            <Stack position="relative">
              {billingInstances?.items?.length ? (
                <Box
                  height={tableLoading ? '850px' : 'auto'}
                  display="flex"
                  alignItems="center"
                  pb={4}
                >
                  <Table
                    columns={billingInstancesTableColumns}
                    rows={billingInstances.items ?? []}
                    total={billingInstances?.totalRowsCount}
                    isPagination={billingInstances?.totalPages > 1}
                    onNextPage={handleFetchDataPage}
                    onPreviousPage={handleFetchDataPage}
                    onGotoPage={handleFetchDataPage}
                    onRefreshPage={handleFetchDataPage}
                    pageSize={10}
                    loading={tableLoading}
                    overflowHidden={false}
                  />
                </Box>
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                  <EmptyBlock title="No Records" />
                </Box>
              )}
            </Stack>
          </ShadowBlock>
        </Box>
      )}
    </Stack>
  );
};

export default BillingInstances;
