/* eslint-disable no-prototype-builtins */
/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';
import formatDate from '../../../utils/formatDate';

export const getAddresses = createAsyncThunk(
  'editAddress/getAddresses',
  async (params = {}, thunkAPI) => {
    const {
      getAddresses: { items: prevItems = [] },
    } = thunkAPI.getState().editAddress;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/addresses/filter`, params);

    const currentStateItems = items.map((address) => ({
      id: address.addressId,
      label: address?.address,
      value: address.addressId,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getOwnerEntities = createAsyncThunk(
  'editAddress/getOwnerEntities',
  async (params = {}, thunkAPI) => {
    const {
      ownerEntities: { items: prevStateItems = [] },
    } = thunkAPI.getState().editAddress;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/ownerEntities/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.ownerEntityId,
      value: item.ownerEntityName,
      label: item.ownerEntityName,
    }));

    return params.hasOwnProperty('currentPage')
      ? {
          items: [...prevStateItems, ...currentStateItems],
          hasNextPage,
        }
      : {
          items: [...currentStateItems],
          hasNextPage,
        };
  },
);

export const getOwnerEntityRefs = createAsyncThunk('address/getOwnerEntityRefs', async (id) => {
  const { data } = await apiClient.get(`/api/addresses/${id}/ownerEntityRefs`);

  return data;
});

export const addOwnerEntityRef = createAsyncThunk(
  'editAddress/addOwnerEntityRef',
  async (params = {}) => {
    const { id, ...restOfParams } = params;
    const { data } = await apiClient.post(`/api/addresses/${id}/ownerEntityRefs`, restOfParams);

    return data;
  },
);

export const editOwnerEntityRef = createAsyncThunk(
  'editAddress/editOwnerEntityRef',
  async (params = {}) => {
    const { ownerEntityRefId, id, ...restOfParams } = params;

    const { data } = await apiClient.put(
      `/api/addresses/${id}/ownerEntityRefs/${ownerEntityRefId}`,
      restOfParams,
    );

    return data;
  },
);

export const disableOwnerEntityRef = createAsyncThunk(
  'editAddress/editOwnerEntityRef',
  async (params = {}) => {
    const { ownerEntityRefId, id } = params;

    const { data } = await apiClient.put(
      `/api/addresses/${id}/ownerEntityRefs/${ownerEntityRefId}/disable`,
    );

    return data;
  },
);

export const getManagements = createAsyncThunk(
  'editAddress/managements',
  async (params = {}, thunkAPI) => {
    const {
      managements: { items: prevStateItems = [] },
    } = thunkAPI.getState().editAddress;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/managements/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.managementId,
      value: item.managementName,
      label: item.managementName,
    }));

    return params.hasOwnProperty('currentPage')
      ? {
          items: [...prevStateItems, ...currentStateItems],
          hasNextPage,
        }
      : {
          items: [...currentStateItems],
          hasNextPage,
        };
  },
);

export const getCommunities = createAsyncThunk(
  'editAddress/communties',
  async (params = {}, thunkAPI) => {
    const {
      communities: { items: prevStateItems = [] },
    } = thunkAPI.getState().editAddress;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/communities/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.communityId,
      value: item.communityName,
      label: item.communityName,
    }));

    return params.hasOwnProperty('currentPage')
      ? {
          items: [...prevStateItems, ...currentStateItems],
          hasNextPage,
        }
      : {
          items: [...currentStateItems],
          hasNextPage,
        };
  },
);

export const getCourts = createAsyncThunk('editAddress/courts', async (params = {}, thunkAPI) => {
  const {
    data: {
      result: { items, hasNextPage },
    },
  } = await apiClient.post(`/api/courts/filter`, params);

  const {
    courts: { items: prevStateItems = [] },
  } = thunkAPI.getState().editAddress;

  const currentStateItems = items.map((item) => ({
    id: item.courtId,
    value: item.courtName,
    label: item.courtName,
    type: item.type,
    superiorCourtId: item.superiorCourtId,
    superiorCourtName: item.superiorCourtName,
  }));

  return params.hasOwnProperty('currentPage')
    ? {
        items: [...prevStateItems, ...currentStateItems],
        hasNextPage,
      }
    : {
        items: [...currentStateItems],
        hasNextPage,
      };
});

export const resetCourts = createAction('editAddress/resetCourts');

export const getStates = createAsyncThunk('editAddress/states', async () => {
  const {
    data: { result },
  } = await apiClient.get(`/api/states`);

  const items = result.map((item) => ({
    id: item.id,
    value: item.code,
    label: item.code,
  }));

  return items;
});

export const deleteAddress = createAsyncThunk(
  'editAddress/deleteAddress',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.delete(`/api/addresses/${id}`);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const getAddress = createAsyncThunk('editAddress/getAddress', async (id) => {
  const { data } = await apiClient.get(`/api/addresses/${id}`);

  return data;
});

export const editAddress = createAsyncThunk(
  'editAddress/editAddress',
  async (editAddressObj, { rejectWithValue }) => {
    try {
      const { id, ...fields } = editAddressObj;
      const { data } = await apiClient.put(`/api/addresses/${id}`, fields);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resetEditAddressError = createAction('editAddress/resetEditAddressError', () => ({
  type: 'address/resetEditAddressError',
}));

export const editAddressNotes = createAsyncThunk(
  'editAddress/editAddressNotes',
  async (editAddressNotesObj) => {
    const { id, ...fields } = editAddressNotesObj;
    const { data } = await apiClient.post(`/api/addresses/${id}/notes`, fields);

    return data;
  },
);

export const getAddressNotes = createAsyncThunk('editAddress/getAddressNotes', async (id) => {
  const { data } = await apiClient.get(`/api/addresses/${id}/notes`);
  return data;
});

const initialState = {
  editableAddress: {},
  ownerEntities: {},
  ownerEntityRefs: [],
  managements: {},
  communities: {},
  courts: {},
  states: [],
  notes: [],
  qBsClass: ['Flagstaff', 'Phoenix', 'Tuscan'],
  error: null,
  editAddressError: null,
  getAddresses: {
    items: [],
  },
};

export const editAddressSlice = createSlice({
  name: 'editAddress',
  initialState,
  reducers: {},
  extraReducers: {
    [deleteAddress.fulfilled]: (state, action) => {
      state.editableAddress = action.payload;
    },
    [deleteAddress.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.getAddresses = action.payload;
    },
    [getAddresses.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAddress.fulfilled]: (state, action) => {
      const { result } = action.payload;
      const formattedResult = {
        ...result,
        ownerEntityRefs: result.ownerEntityRefs.map((item) => ({
          ...item,
          endDate: formatDate(item?.endDate, 'MM/DD/YYYY'),
          startDate: formatDate(item?.startDate, 'MM/DD/YYYY'),
        })),
      };

      state.editableAddress = formattedResult;
    },
    [getAddress.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editAddress.rejected]: (state, action) => {
      state.editAddressError = action.payload;
    },
    [resetEditAddressError]: (state) => {
      state.editAddressError = null;
    },
    [editOwnerEntityRef.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [disableOwnerEntityRef.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getOwnerEntities.fulfilled]: (state, action) => {
      state.ownerEntities = action.payload;
    },
    [getOwnerEntities.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getOwnerEntityRefs.fulfilled]: (state, action) => {
      const { result } = action.payload;
      const formattedResult = result.map((item) => ({
        ...item,
        endDate: formatDate(item?.endDate, 'MM/DD/YYYY'),
        startDate: formatDate(item?.startDate, 'MM/DD/YYYY'),
      }));

      state.ownerEntityRefs = formattedResult;
    },
    [getOwnerEntityRefs.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [addOwnerEntityRef.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getManagements.fulfilled]: (state, action) => {
      state.managements = action.payload;
    },
    [getManagements.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCommunities.fulfilled]: (state, action) => {
      state.communities = action.payload;
    },
    [getCommunities.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCourts.fulfilled]: (state, action) => {
      state.courts = action.payload;
    },
    [getCourts.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [resetCourts]: (state) => {
      state.courts = [];
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload;
    },
    [getStates.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAddressNotes.fulfilled]: (state, action) => {
      state.notes = action.payload.result;
    },
    [editAddressNotes.fulfilled]: (state, action) => {
      state.notes = action.payload;
    },
  },
});

export default editAddressSlice.reducer;
