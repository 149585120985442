import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Typography, Box } from '@mui/material';
import InputCell from './InputCell';

const ApartmentInput = ({ row, updateValue, cell }) => {
  const { value, column } = cell;
  const [isEdit, setEdit] = useState(false);
  const [val, setVal] = useState(value);
  const isDisabled = !!row.original.hasNoAppartmentNumber;
  const error = row.original?.errors?.[column.id];
  const touched = row.original?.touched?.[column.id];

  useEffect(() => {
    if (val !== value) {
      setVal(value);
    }
    setEdit(false);
  }, [row.original?.id, column.id]);

  useEffect(() => {
    if (!isDisabled && !val && row.original?.touched?.hasNoAppartmentNumber) {
      updateValue(row.original.id, {
        id: column.id,
        value: val,
        error: 'Mandatory field',
        touched: true,
      });
    } else if (isDisabled) {
      setVal('');
    }
  }, [isDisabled]);

  const onChange = (ev) => {
    setVal(ev.target.value);
  };

  const handleFocus = useCallback(() => {
    setEdit(true);
  }, [row.original.id]);

  const onBlur = useCallback(() => {
    if (!row.original?.id) return;
    if (!val) {
      updateValue(row.original.id, {
        id: column.id,
        value: val,
        error: 'Mandatory field',
        touched: true,
      });
    } else {
      setEdit(false);
      updateValue(row.original.id, {
        id: column.id,
        value: val,
        error: undefined,
        touched: true,
      });
    }
  }, [val, column.id, row.original.id]);

  const message = useMemo(() => {
    if (error && touched) return 'Mandatory field';
    return null;
  }, [error, touched, row.original.id, column.id]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <InputCell
        isNoBorder={!isEdit && !message}
        onBlur={onBlur}
        onFocus={handleFocus}
        data-testid="cell_value_input"
        value={val}
        onChange={onChange}
        placeholder="XXXXXXX"
        error={!!message}
        disabled={isDisabled}
      />
      {message && !value && (
        <Typography sx={{ position: 'absolute', bottom: '-15px', fontSize: '12px' }} color="error">
          {message}
        </Typography>
      )}
    </Box>
  );
};

ApartmentInput.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    column: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({
      id: PropTypes.number,
      errors: PropTypes.shape({ apartmentNumber: PropTypes.string }),
      hasNoAppartmentNumber: PropTypes.bool,
      touched: PropTypes.shape({
        apartmentNumber: PropTypes.bool,
        hasNoAppartmentNumber: PropTypes.bool,
      }),
    }),
  }).isRequired,
  updateValue: PropTypes.func.isRequired,
};

ApartmentInput.defaultProps = {
  cell: {
    value: '',
    column: {
      id: '',
    },
  },
};

export default React.memo(ApartmentInput);
