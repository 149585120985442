import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import AuthContext from '../context/AuthContext';
import { login, logout } from './auth.service';
import { tokenRepository } from '../lib/apiClient';
import getDecodedJwtToken from '../utils/getDecodedJwtToken';
import { getDefaultPage, rolesDB } from '../utils/roleHelpers';
import { tokenKey } from '../lib/tokenRepository';
import { getLandingPagesByRoles } from '../components/organisms/TopNavBar/TopNavBar.constants';
import { getCPUserProfile, getUserProfile } from '../store/slices/userProfileSlice';
import { useBaseUrl } from '../hooks/useBaseUrl';

const LoginApi = {
  db: {
    logoutPath: '/api/logout',
    loginPath: '/api/login',
    redirectAfterLogout: '/db/login',
  },
  cp: {
    logoutPath: '/cp/api/logout',
    loginPath: '/cp/api/login',
    redirectAfterLogout: '/cp/login',
  },
  client: {
    logoutPath: '/cp/api/logout',
    loginPath: '/cp/api/login',
    redirectAfterLogout: '/client/login',
  },
};

const AuthProvider = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.userProfile);
  const token = tokenRepository.getToken();

  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [isRequesting, setIsRequesting] = useState('');
  const dispatch = useDispatch();
  const [isLogout, setLogout] = useState(false);
  // const path = pathname.includes('/db/') ? 'db' : 'cp';
  const base = useBaseUrl();
  const { loginPath, logoutPath, redirectAfterLogout } = LoginApi[base];

  if (token && !user) {
    try {
      const decodedToken = getDecodedJwtToken(token);
      setUser(decodedToken);
    } catch (e) {
      if (e.message.includes('Invalid token specified')) {
        tokenRepository.removeTokens();
        navigate(`/${base}/login`);
      }
    }
  }

  const handleLogin = useCallback(
    async (loginPayload) => {
      setIsRequesting(true);
      const { password, username } = loginPayload;

      const response = await login({ password, username, loginPath });
      if (response?.result?.accessToken) {
        const { accessToken, refreshToken } = response.result;

        tokenRepository.setToken(accessToken);
        tokenRepository.setRefreshToken(refreshToken);

        const decodedToken = getDecodedJwtToken(accessToken);

        const availablePagesByRoles = decodedToken?.roles
          ? getLandingPagesByRoles(
              decodedToken.roles,
              decodedToken.isClientPortalUser,
              base,
              decodedToken.stateCodes.includes('AZ') ? [] : ['Legal Forms'],
            )
          : [];
        const method = decodedToken.isClientPortalUser ? getCPUserProfile : getUserProfile;
        const res = await dispatch(method());
        const profileRes = res.payload.result;

        const chosenPage = availablePagesByRoles.find(
          (item) => item.label === profileRes?.landingPage,
        );
        const isCPUserFromDB =
          decodedToken.isClientPortalUser &&
          decodedToken.roles.some((role) => {
            return Object.values(rolesDB).includes(role);
          });

        const chosenDefaultPage = isCPUserFromDB ? `/${base}/cases/casesearch` : chosenPage?.href;

        setUser(decodedToken);
        navigate(getDefaultPage(decodedToken, chosenDefaultPage), {
          replace: true,
        });
        setError(null);
      }

      if (response.name === 'AxiosError') {
        setError(response.response.data);
      }

      setIsRequesting(false);
    },
    [pathname, loginPath],
  );

  const handleLogout = useCallback(async () => {
    setLogout(true);
    const blocker = sessionStorage.getItem('blocker');
    if (blocker === 'active') {
      // to activate blocker
      navigate(redirectAfterLogout);
    } else {
      setLogout(false);
      setIsRequesting(true);
      try {
        await logout(logoutPath);
      } finally {
        tokenRepository.removeTokens();
        navigate(redirectAfterLogout);
        setUser(null);
        setIsRequesting(false);
      }
    }
  }, [pathname, logoutPath, redirectAfterLogout]);

  useEffect(() => {
    const listenToken = (event) => {
      if (event.storageArea === localStorage) {
        if (token && !event.storageArea.length) {
          setUser(null);
          navigate(redirectAfterLogout);
        }

        if (event.key === tokenKey && event.newValue) {
          const decodedToken = getDecodedJwtToken(event.newValue);

          const availablePagesByRoles = decodedToken?.roles
            ? getLandingPagesByRoles(
                decodedToken.roles,
                decodedToken.isClientPortalUser,
                base,
                decodedToken.stateCodes.includes('AZ') ? [] : ['Legal Forms'],
              )
            : [];
          const chosenPage = availablePagesByRoles.find(
            (item) => item.label === profile?.landingPage,
          );
          const isCPUserFromDB =
            decodedToken.isClientPortalUser &&
            decodedToken.roles.some((role) => {
              return Object.values(rolesDB).includes(role);
            });

          const chosenDefaultPage = isCPUserFromDB ? `/${base}/cases/casesearch` : chosenPage?.href;
          setUser(decodedToken);
          if (!event.oldValue) {
            navigate(getDefaultPage(decodedToken, chosenDefaultPage), {
              replace: true,
            });
          }
        }
      }
    };

    window.addEventListener('storage', listenToken);

    return () => {
      window.removeEventListener('storage', listenToken);
    };
  }, [pathname, redirectAfterLogout, token]);

  const value = useMemo(
    () => ({
      error,
      isRequesting,
      userInfo: user,
      onLogin: handleLogin,
      onLogout: handleLogout,
      isLogout,
      setLogout,
    }),
    [user, handleLogin, handleLogout, error, isRequesting, isLogout, base],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
