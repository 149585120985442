import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, CircularProgress, Container, Stack, Typography, styled } from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import Dropdown from '../../../atoms/Dropdown';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import { palette } from '../../../../theme/default';
import {
  getCounties,
  getStates,
  deleteCourtTemplate,
  getCourtTemplates,
  editCourtTemplates,
  resetEditCourtSliceState,
  getCourts,
  resetCourts,
} from '../../../../store/slices/editCourtSlice';
import useCourt from '../../../../hooks/useCourt';
import { courtHeading, courtBoxes, CourtTemplateColumns } from './Court.constants';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup/CustomRadioGroup';
import formatDateAndTimeToISO from '../../../../utils/formatDateAndTimeToISO';
import Table from '../../../atoms/Table/Table';
import MButton from '../../../MUI/Button/MButton';
import CourtTemplatesDialog from './CourtTemplatesDialog';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Clock } from '../../../../assets/icons/Clock.svg';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import { useNavigationBlockPopup } from '../../../../hooks/useNavigationBlockPopup';
import { useStateSpecificStore } from './hooks';
import { convertObjectValuesToNumber } from '../../../../utils/convertObjectValuesToNumbers';
import notificationUtils from '../../../../utils/notificationUtils';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import LoaderCover from '../../../atoms/LoaderCover';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import { INITIAL_CURRENT_PAGE } from '../Addresses/Address/Address.constants';
import MultilineTextField from '../../../atoms/MultilineTextField/MultilineTextField';

const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

const Court = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    state: { navPage, subNav, id, state: defaultStateCode, type },
  } = useLocation();
  const { popup, handleChangeBlockerByDefault } = useNavigationBlockPopup({
    blockerByDefault: !id,
  });
  const { states, counties, courts, defaultTemplates } = useSelector((state) => state.editCourt);
  const { state, actions } = useCourt();
  const [isPageLoader, setIsPageLoader] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [courtsCurrentPage, setCourtsCurrentPage] = useState(INITIAL_CURRENT_PAGE);
  const form = useForm({ defaultValues: state });

  const stateCodeValue = form.watch('contactInfo.stateCode');

  const stateAndActions = useStateSpecificStore({
    stateCode: defaultStateCode.value,
    type: !id ? form.watch('type')?.value : type,
  });

  useEffect(() => {
    dispatch(getStates());

    if (id) {
      dispatch(stateAndActions.get({ id })).then(() => setIsPageLoader(false));
    } else {
      setIsPageLoader(false);
    }
  }, [id]);

  useEffect(() => {
    if (!id) dispatch(resetEditCourtSliceState());
  }, []);

  useEffect(() => {
    if (defaultStateCode?.value) {
      dispatch(getCounties(defaultStateCode.value));
      dispatch(
        getCourts({
          stateCode: defaultStateCode.value,
          courtType: 'Superior',
        }),
      );

      if (!id) {
        form.setValue('contactInfo.stateCode', defaultStateCode);
      }
    }
  }, [defaultStateCode?.value]);

  useEffect(() => {
    if (id && !form.formState.isDirty) {
      form.reset({
        ...stateAndActions.court,
        type: state.courtTypes.find((t) => t.value === type),
        additionalInfo: {
          ...stateAndActions.court.additionalInfo,
          trialDate: stateAndActions.court?.additionalInfo?.trialDateTime || null,
          trialTime: stateAndActions.court?.additionalInfo?.trialDateTime || null,
        },
        contactInfo: {
          ...stateAndActions.court.contactInfo,
          stateCode: defaultStateCode,
          countyName: counties?.find(
            (item) => item.id === stateAndActions.court?.contactInfo?.countyId,
          ),
        },
      });
      form.setValue(
        'contactInfo.countyName',
        counties?.find((item) => item.id === stateAndActions.court?.contactInfo?.countyId),
      );
      form.setValue(
        'superiorCourtName',
        courts?.items?.find((item) => item.id === stateAndActions.court?.superiorCourtId),
      );
    }
  }, [id, stateAndActions.court, stateAndActions.feesAndCosts, defaultTemplates, counties]);

  useEffect(() => {
    if (id && !isPageLoader) {
      form.setValue('defaultTemplates', defaultTemplates);
    }
  }, [defaultTemplates]);

  const getOptions = useCallback(
    (inputBoxesItemName) => {
      switch (inputBoxesItemName) {
        case 'contactInfo.stateCode':
          return states;
        case 'contactInfo.countyName':
          return counties;
        case 'type':
          return state.courtTypes;
        case 'superiorCourtName':
          return courts.items;
        default:
          return [];
      }
    },
    [counties, states, courts],
  );

  const getDisabled = useCallback(
    (name, isDisabled = false) => {
      switch (name) {
        case 'type':
          return Boolean(id);
        default:
          return isDisabled;
      }
    },
    [id],
  );

  const [isOpenDialogTemplates, setIsOpenDialogTemplates] = useState(false);

  const handleAddCourtTemplates = useCallback(() => {
    setIsOpenDialogTemplates(true);
  }, []);

  const handleConfirmDialogTemplates = useCallback(
    (selectedTemplateIds) => {
      setIsOpenDialogTemplates(false);
      if (id) {
        const templates = selectedTemplateIds.map((item) => ({
          templateId: item.templateId,
        }));
        const editData = {
          courtId: id,
          selectedTemplates: templates,
        };
        dispatch(editCourtTemplates({ stateCode: defaultStateCode.value, editData })).then(() => {
          dispatch(getCourtTemplates({ stateCode: defaultStateCode.value, id }));
        });
      } else {
        const currentValue = form.getValues('defaultTemplates') || [];
        const values = currentValue.concat(selectedTemplateIds);
        const uniqValues = uniqBy(values, (e) => e.templateId);
        form.setValue('defaultTemplates', uniqValues);
      }
    },
    [id],
  );

  // eslint-disable-line no-unused-vars
  const handleRemoveTamplate = (rowId, { templateId }) => {
    if (id) {
      dispatch(deleteCourtTemplate({ id, templateId, stateCode: defaultStateCode.value })).then(
        () => {
          dispatch(getCourtTemplates({ stateCode: defaultStateCode.value, id }));
        },
      );
    } else {
      const addedTamplates = form.getValues('defaultTemplates') || [];
      const filteredTemplates = addedTamplates.filter((item) => item.templateId !== templateId);
      form.setValue('defaultTemplates', filteredTemplates);
    }
  };

  const handleCloseDialogTemplates = useCallback(() => {
    setIsOpenDialogTemplates(false);
  }, []);

  const defineTrialDateValue = (trialDate, trialTime) => {
    if (trialDate && trialTime) {
      return formatDateAndTimeToISO(trialDate, trialTime);
    }
    return null;
  };

  useEffect(() => {
    if (courtsCurrentPage > INITIAL_CURRENT_PAGE) {
      dispatch(getCourts({ currentPage: courtsCurrentPage, stateCode: stateCodeValue.value }));
    }
  }, [courtsCurrentPage]);

  const handleMenuScrollToBottomCourts = () => {
    if (courts.hasNextPage) {
      setCourtsCurrentPage(courtsCurrentPage + 1);
    }
  };

  const handleMenuScrollToBottom = (inputBoxesItemName) => {
    switch (inputBoxesItemName) {
      case 'superiorCourtName':
        return handleMenuScrollToBottomCourts;
      default:
        return [];
    }
  };

  const handleDropdownInputChange = (fieldName, dropdownInputValue) => {
    switch (fieldName) {
      case 'superiorCourtName':
        if (dropdownInputValue) {
          dispatch(
            getCourts({
              courtName: dropdownInputValue,
              stateCode: defaultStateCode.value,
              courtType: 'Superior',
            }),
          );
        } else {
          dispatch(resetCourts());
          setCourtsCurrentPage(INITIAL_CURRENT_PAGE);
        }
        break;

      default:
        break;
    }
  };

  const handleMenuOpen = (fieldName) => () => {
    switch (fieldName) {
      case 'superiorCourtName':
        dispatch(
          getCourts({
            stateCode: defaultStateCode.value,
            courtType: 'Superior',
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleSubmit = useCallback(
    (formFields) => {
      const { trialDate, trialTime } = formFields.additionalInfo;
      const { countyName, stateCode, ...restContactInfo } = formFields.contactInfo;
      const dataToSend = {
        name: formFields.name,
        defaultTemplates: formFields.defaultTemplates,
        contactInfo: {
          ...restContactInfo,
          courtId: formFields.courtId,
          countyId: formFields?.contactInfo?.countyName?.value,
        },
        additionalInfo: {
          justiceOfPeaceNumber: formFields.additionalInfo?.justiceOfPeaceNumber,
          hearingAddress: formFields.additionalInfo?.hearingAddress,
          isInTown: formFields.additionalInfo?.isInTown,
          trialDateTime: defineTrialDateValue(trialDate, trialTime),
        },
      };

      if (defaultStateCode.value === 'AZ') {
        // superiorCourtName select exists only for justice court type
        dataToSend.superiorCourtId = formFields.superiorCourtName?.id;
        dataToSend.internalFeesAndCosts = convertObjectValuesToNumber(
          formFields.internalFeesAndCosts,
        );
        dataToSend.externalFeesAndCosts = formFields.externalFeesAndCosts;
        dataToSend.secondaryKeys = formFields.secondaryKeys;
      }

      if (defaultStateCode.value === 'NV') {
        dataToSend.additionalInfo.hasLockoutAvailable =
          !!formFields.additionalInfo.hasLockoutAvailable;
        dataToSend.feesAndCosts = convertObjectValuesToNumber(formFields.feesAndCosts);
      }

      setIsLoader(true);
      if (id) {
        dispatch(
          stateAndActions.update({
            ...dataToSend,
            courtId: formFields.courtId,
            id: formFields.courtId,
          }),
        )
          .unwrap()
          .then(() => {
            dispatch(stateAndActions.get({ id }));
            notificationUtils.success('Updated successfully');
          })
          .catch((rejectedValueError) => {
            notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
          })
          .finally(() => {
            setIsLoader(false);
          });
      } else {
        handleChangeBlockerByDefault(false);
        dataToSend.defaultTemplates = [];

        if (formFields.defaultTemplates?.length) {
          const mappedTemplates = formFields.defaultTemplates.map((item) => ({
            templateId: item.templateId,
          }));
          dataToSend.defaultTemplates = mappedTemplates;
        }
        dispatch(stateAndActions.create(dataToSend))
          .unwrap()
          .then((originalPromiseResult) => {
            const { result } = originalPromiseResult;
            notificationUtils.success('Created successfully');
            navigate('/db/datahandling/editcourt/court', {
              state: {
                navPage,
                subNav,
                id: result,
                name: formFields.name,
                state: formFields.contactInfo.stateCode,
                type: formFields.type.label,
              },
            });
            actions?.clear();
            form?.reset();
          })
          .catch((rejectedValueError) => {
            notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
          })
          .finally(() => {
            setIsLoader(false);
          });
      }
    },
    [stateAndActions],
  );

  const handleCancel = useCallback(() => {
    navigate('/db/datahandling/courts', {
      state: {
        navPage,
        subNav,
        state: defaultStateCode,
      },
    });
  }, []);

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  const inputStateCode = stateCodeValue?.label || defaultStateCode.value;
  const inputCourtType = form.watch('type')?.value;

  const canAddDefaultTemplates = useAllowed([rolesDB.Admin]);
  const canDeleteDefaultTemplates = useAllowed([rolesDB.Admin]);
  const canUpdateCourt = useAllowed([
    rolesDB.Admin,
    rolesDB.Accounting,
    rolesDB.Attorney,
    // rolesDB.LegalAssistant,
  ]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      {popup}
      {!isPageLoader ? (
        <>
          {isLoader && <LoaderCover isFixed />}
          <Typography variant="h4">{courtHeading}</Typography>
          <Form onSubmit={handleSubmit} form={form}>
            {courtBoxes
              ?.filter((courtBox) => {
                if (courtBox.name === 'secondaryKeys') {
                  if (inputStateCode === 'AZ' && inputCourtType === 'Justice') return true;
                  return false;
                }

                return true;
              })
              .map((data) => {
                const inputBoxes =
                  typeof data.inputBoxes === 'function'
                    ? data.inputBoxes({
                        stateCode: inputStateCode,
                        courtType: inputCourtType,
                      })
                    : data.inputBoxes;
                return (
                  <Container
                    key={data.boxTitle}
                    maxWidth={false}
                    disableGutters
                    sx={{
                      mx: 'auto',
                      mt: 4,
                      width: 1,
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      columnGap={3}
                      rowGap={2}
                      flexWrap="wrap"
                    >
                      {inputBoxes
                        .filter((item) => {
                          if (item.name === 'superiorCourtName' && inputCourtType !== 'Justice') {
                            return false;
                          }

                          return true;
                        })
                        .map((item) => {
                          const key =
                            typeof data.inputBoxes === 'function'
                              ? `${item.label}-${item.name}`
                              : item.name;
                          if (item.state && item.state !== inputStateCode) return null;
                          if (item.isDropdown) {
                            return (
                              <Field
                                key={key}
                                name={item.name}
                                isMandatory={item.isMandatory}
                                render={({ field, onCustomChange, error }) => {
                                  if (id && !canUpdateCourt) {
                                    return (
                                      <EditInputWithLabel
                                        type="text"
                                        label={item.label}
                                        width={item.width}
                                        name={item.name}
                                        value={field.value?.label || '-'}
                                        readOnly
                                      />
                                    );
                                  }
                                  return (
                                    <Dropdown
                                      value={field.value}
                                      isAddDisabled={false}
                                      isSearchable
                                      label={item.label}
                                      width={item.width}
                                      isColumn
                                      error={error}
                                      isMandatory={item.isMandatory}
                                      placeholder={item.placeholder}
                                      options={getOptions(item.name)}
                                      isDisabled={getDisabled(item.name, item.isDisabled)}
                                      onMenuScrollToBottom={handleMenuScrollToBottom(item.name)}
                                      onInputChange={(inputValue) =>
                                        handleDropdownInputChange(item.name, inputValue)
                                      }
                                      onMenuOpen={handleMenuOpen(item.name)}
                                      onChange={onCustomChange(field.onChange)}
                                    />
                                  );
                                }}
                              />
                            );
                          }

                          if (item.isRadioButtons) {
                            return (
                              <Field
                                key={key}
                                name={item.name}
                                isMandatory={item.isMandatory}
                                render={({ field, onCustomChange }) => {
                                  if (id && !canUpdateCourt)
                                    return (
                                      <EditInputWithLabel
                                        type="text"
                                        label={item.label}
                                        width={item.width}
                                        name={item.name}
                                        value={
                                          item.data.find((i) => i.value === field.value)?.label ||
                                          '-'
                                        }
                                        readOnly
                                      />
                                    );
                                  return (
                                    <CustomRadioGroup
                                      data={item.data}
                                      value={field.value}
                                      label={item.label}
                                      width={item.width}
                                      radioButtonWidth={item.radioButtonWidth}
                                      onChange={onCustomChange(field.onChange)}
                                      defaultValue={getRadioGroupDefaultValue(item.data)}
                                    />
                                  );
                                }}
                              />
                            );
                          }

                          if (item.isCheckbox) {
                            return (
                              <Field
                                key={item.label}
                                name={item.name}
                                render={({ field, onCustomChange }) => {
                                  return (
                                    <CheckboxWithLabel
                                      isDisabled={item.isDisabled}
                                      key={item.label}
                                      label={item.label}
                                      isChecked={field.value}
                                      onChange={onCustomChange(field.onChange)}
                                      marginTop="24px"
                                    />
                                  );
                                }}
                              />
                            );
                          }

                          if (item?.isSpace) {
                            return <div style={{ width: '100%' }} />;
                          }

                          if (item.isDate) {
                            return (
                              <Field
                                key={key}
                                name={item.name}
                                isMandatory={item.isMandatory}
                                render={({ field, onCustomChange }) => {
                                  if (id && !canUpdateCourt)
                                    return (
                                      <EditInputWithLabel
                                        type="text"
                                        label={item.label}
                                        width={item.width}
                                        name={item.name}
                                        value={item.value || '-'}
                                        readOnly
                                      />
                                    );
                                  return (
                                    <DateOrTimePicker
                                      onChange={onCustomChange(field.onChange)}
                                      name={item.name}
                                      label={item.label}
                                      width={item.width}
                                      selected={field.value}
                                      Svg={Calender}
                                      isDate
                                      setSelectedTimeOrDate={onCustomChange(field.onChange)}
                                      height={item.height}
                                      placeholder={item.placeholder}
                                    />
                                  );
                                }}
                              />
                            );
                          }

                          if (item.isTime) {
                            return (
                              <Field
                                key={key}
                                name={item.name}
                                isMandatory={item.isMandatory}
                                render={({ field, onCustomChange }) => {
                                  if (id && !canUpdateCourt)
                                    return (
                                      <EditInputWithLabel
                                        type="text"
                                        label={item.label}
                                        width={item.width}
                                        name={item.name}
                                        value={item.value || '-'}
                                        readOnly
                                      />
                                    );
                                  return (
                                    <DateOrTimePicker
                                      onChange={onCustomChange(field.onChange)}
                                      name={item.name}
                                      label={item.label}
                                      width={item.width}
                                      selected={field.value}
                                      Svg={Clock}
                                      isTime
                                      setSelectedTimeOrDate={onCustomChange(field.onChange)}
                                      height={item.height}
                                      placeholder={item.placeholder}
                                    />
                                  );
                                }}
                              />
                            );
                          }

                          if (item.isSubHeading) {
                            return (
                              <StyledTypography width={1} mb={1} variant="bodyL500" key={key}>
                                {item.label}
                              </StyledTypography>
                            );
                          }

                          if (item.isSubSubHeading) {
                            return (
                              <StyledTypography
                                width={1}
                                mb={1}
                                variant="bodyL500"
                                color="text.tertiary"
                                key={key}
                              >
                                {item.label}
                              </StyledTypography>
                            );
                          }

                          if (item.isSubHeadingWithButton) {
                            return (
                              <Stack
                                direction="row"
                                alignContent="center"
                                columnGap={2}
                                mt={3}
                                key={key}
                              >
                                <StyledTypography width={1} variant="bodyL500">
                                  {item.label}
                                </StyledTypography>
                                {item.label === 'Default Templates' &&
                                !canAddDefaultTemplates ? null : (
                                  <MButton
                                    onClick={handleAddCourtTemplates}
                                    startIcon={item.buttonIcon}
                                  >
                                    {item.buttonName}
                                  </MButton>
                                )}
                              </Stack>
                            );
                          }

                          if (item.isTable) {
                            return (
                              <>
                                <Field
                                  key={key}
                                  name={item.name}
                                  isMandatory={item.isMandatory}
                                  render={({ field }) => {
                                    return field?.value?.length ? (
                                      <Table
                                        columns={CourtTemplateColumns}
                                        rows={field.value.map((template) => ({
                                          ...template,
                                          control: {
                                            hideDelete: !canDeleteDefaultTemplates,
                                          },
                                        }))}
                                        total={field?.value?.length}
                                        pagination={false}
                                        onDeleteRow={handleRemoveTamplate}
                                        loading={false}
                                        onCheckedRow={() => null}
                                        isWithCheckbox={false}
                                      />
                                    ) : null;
                                  }}
                                />
                                {isOpenDialogTemplates && (
                                  <CourtTemplatesDialog
                                    title="Add Templates"
                                    buttonSecondaryText="Cancel"
                                    buttonPrimaryText="Add"
                                    onClose={handleCloseDialogTemplates}
                                    onConfirm={handleConfirmDialogTemplates}
                                    isOpen={isOpenDialogTemplates}
                                  />
                                )}
                              </>
                            );
                          }

                          if (item.isFieldWithHeading) {
                            return (
                              <Box display="flex" flexDirection="column">
                                <StyledTypography width={1} mb={1} variant="bodyM" key={key}>
                                  {item.heading}
                                </StyledTypography>
                                <Field
                                  key={key}
                                  name={item.name}
                                  isMandatory={item.isMandatory}
                                  render={({ field, error }) => (
                                    <EditInputWithLabel
                                      type="text"
                                      error={error}
                                      label={item.label}
                                      placeholder={item.placeholder}
                                      width={item.width}
                                      name={item.name}
                                      isMandatory={
                                        ((id && canUpdateCourt) || !id) && item.isMandatory
                                      }
                                      value={field.value}
                                      onChange={field.onChange}
                                      isDisabled={item.isDisabled}
                                      readOnly={id && !canUpdateCourt}
                                    />
                                  )}
                                />
                              </Box>
                            );
                          }

                          if (item.isMultiline) {
                            return (
                              <Field
                                key={key}
                                name={item.name}
                                isMandatory={item.isMandatory}
                                render={({ field, error }) => (
                                  <MultilineTextField
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    width={item.width}
                                    name={item.name}
                                    rows={2}
                                    value={id && !canUpdateCourt ? field.value || '-' : field.value}
                                    disabled={item.isDisabled}
                                    error={error}
                                    onChange={field.onChange}
                                  />
                                )}
                              />
                            );
                          }

                          return (
                            <Field
                              key={key}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              render={({ field, error }) => (
                                <EditInputWithLabel
                                  type="text"
                                  error={error}
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  width={item.width}
                                  name={item.name}
                                  isMandatory={((id && canUpdateCourt) || !id) && item.isMandatory}
                                  value={id && !canUpdateCourt ? field.value || '-' : field.value}
                                  onChange={field.onChange}
                                  isDisabled={item.isDisabled}
                                  readOnly={!canUpdateCourt}
                                />
                              )}
                            />
                          );
                        })}
                    </Stack>
                  </Container>
                );
              })}
            {((id && canUpdateCourt) || !id) && (
              <Box
                position="fixed"
                left={0}
                bottom={0}
                zIndex={10}
                backgroundColor="white"
                width={1}
                p={4}
                boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
              >
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <MButton
                    variant="secondary"
                    size="large"
                    onClick={handleCancel}
                    sx={{ width: '144px' }}
                    disabled={isLoader}
                  >
                    Cancel
                  </MButton>
                  <MButton
                    type="submit"
                    size="large"
                    name="Submit community"
                    sx={{ width: '144px' }}
                    disabled={isLoader}
                  >
                    {id ? 'Update' : 'Create'}
                  </MButton>
                </Stack>
              </Box>
            )}
          </Form>
        </>
      ) : (
        <Box
          position="absolute"
          top="50vh"
          left="50%"
          zIndex={10}
          transform="translate(-50%, -50%)"
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default Court;
