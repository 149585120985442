import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Page.module.scss';
import { ReactComponent as ArrowRight } from '../../../assets/icons/ArrowRight.svg';

const Page = ({ children, navPage, subNavigation, subSubNavigation, actions }) => {
  const location = useLocation();

  const { refNum = '', defendantName = '' } = location?.state ?? {};

  return (
    <div className={styles.page_container} data-testid="page_container">
      <div className={styles.bar}>
        <div className={styles.navigation}>
          <div data-testid="page_navpage">{navPage}</div>
          {subNavigation && (
            <>
              <ArrowRight />
              <div data-testid="page_subnavigation">{subNavigation}</div>
            </>
          )}
          {subSubNavigation && (
            <>
              <ArrowRight /> <div data-testid="page_subsubnavigation">{subSubNavigation}</div>
            </>
          )}
          {refNum && defendantName && (
            <>
              <ArrowRight />{' '}
              <div data-testid="page_refnum">{`Ref. #${refNum} | ${defendantName}`}</div>
            </>
          )}
        </div>
        <div className={styles.actions} data-testid="page_action">
          {actions}
        </div>
      </div>
      <div className={styles.children_box} data-testid="page_children_box">
        {children}
      </div>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.node,
  navPage: PropTypes.string,
  subNavigation: PropTypes.string,
  subSubNavigation: PropTypes.string,
};

Page.defaultProps = {
  children: null,
  actions: null,
  navPage: '',
  subNavigation: '',
  subSubNavigation: '',
};

export default Page;
