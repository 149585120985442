const isObjectNotFile = (val) => typeof val === 'object' && val !== null && !(val instanceof File);

export const addArrayToFormData = (arr, name, initialFormData) => {
  const formData = initialFormData || new FormData();
  Object.keys(arr).forEach((i) => {
    const val = arr[i];
    if (isObjectNotFile(val)) {
      Object.keys(val).forEach((key) => {
        if (Array.isArray(val[key])) {
          addArrayToFormData(val[key], `${name}[${i}].${key}`, formData);
        } else {
          formData.append(`${name}[${i}].${key}`, val[key]);
        }
      });
    } else {
      formData.append(`${name}[${i}]`, val);
    }
  });
  return formData;
};

export const addObjToFormData = (obj, initialFormData, upperKey = '') => {
  const formData = initialFormData || new FormData();
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    const concatenatedKey = upperKey ? `${upperKey}.${key}` : key;
    if (Array.isArray(val)) {
      addArrayToFormData(val, concatenatedKey, formData);
    } else if (isObjectNotFile(val)) {
      addObjToFormData(val, formData, key);
    } else {
      formData.append(concatenatedKey, val);
    }
  });
  return formData;
};
