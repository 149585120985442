import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Table, { TableLoader } from '../../../atoms/Table/Table';
import SearchContainer from '../../../molecules/SearchContainer';
import { caseSearch, searchFields, clearFilterString, tableColumns } from './CaseSearch.constants';
import * as dbCasesFilterActions from '../../../../store/slices/casesFilterSlice';
import * as dbCasesActions from '../../../../store/slices/cases/casesGeneralSlice';
import * as cpCasesActions from '../../../../store/slices/cases/casesGeneralCpSlice';
import BulkCaseCreateDialog from './BulkCaseCreateDialog/BulkCaseCreateDialog';
import styles from './CaseSearch.module.scss';
import useCases from '../../../../hooks/useCases';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import { CIVIL_MATTER_CASE, EVICTION_MATTER_CASE } from '../../../../global/Case.constants';
import { useUserInfo } from '../../../../utils/roleHelpers';
import { MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './CaseSearchCells';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { useAvailableStatesList } from '../../../../hooks/useAvailableStatesList';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import { apiClient, tokenRepository } from '../../../../lib/apiClient';
import { withWasPageReloaded } from '../../../../hooks/usePerformanceObserver';
import { usePrimaryState } from '../../../../hooks/usePrimaryState';

const CaseSearch = ({ isCP, isDB, wasPageReloaded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { caseIds, preserveFilters = true } = useSafeLocationState(
    ...(isDB ? ['Processing', 'Cases'] : ['Cases']),
  );

  const preserveFiltersOnLoad = !wasPageReloaded && preserveFilters;
  const userInfo = useUserInfo();
  const primaryState = usePrimaryState();

  const states = useAvailableStatesList();

  const dispatch = useDispatch();
  const dbCases = useSelector((state) => state.casesGeneral.getCases);
  const dbFilters = useSelector((state) => state.casesFilters);

  const cpCasesAndFilters = useSelector((state) => state.casesGeneralCp);

  const [ukey, setUkey] = useState(uuidv4());
  const [legalCategories, setLegalCategories] = useState([]);
  const savedSearchParams = tokenRepository.getCaseSearchParams();
  const [params, setParams] = useState({
    stateCode: primaryState.value || userInfo.stateCodes[0],
    ...((preserveFiltersOnLoad && savedSearchParams?.params) || {}),
    ...(caseIds ? { caseIds } : {}),
  });
  const [submitClicked, setSubmitClicked] = useState(
    !!(
      caseIds ||
      (preserveFiltersOnLoad && Object.keys(savedSearchParams?.params || {}).length > 1)
    ),
  );
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [isBulkCaseCreateOpen, setIsBulkCaseCreateOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(
    (preserveFiltersOnLoad && savedSearchParams?.selectedValues) || {},
  );

  useEffect(() => {
    setParams((prevParams) => {
      return {
        ...prevParams,
        stateCode: primaryState.value,
      };
    });
  }, []);

  useEffect(() => {
    tokenRepository.setCaseSearchParams({ params, selectedValues });
  }, [params, selectedValues]);

  const {
    data,
    total,
    totalPages,
    filters: {
      communities,
      propertyAddresses,
      opposingParty,
      managementCompanies,
      jurisdictions,
      statusesByStateCode,
    },
    actions,
    onAdd,
  } = useMemo(() => {
    if (isDB) {
      return {
        filters: dbFilters,
        data: dbCases.items,
        actions: { ...dbCasesFilterActions, ...dbCasesActions },
        total: dbCases.totalRowsCount,
        totalPages: dbCases.totalPages,
        onAdd: (caseMatterType) => {
          const isCivilMatterCase = caseMatterType === CIVIL_MATTER_CASE;
          const path = isCivilMatterCase
            ? '/db/processing/newcivilmattercase/partiesandprecinct'
            : '/db/processing/newcase/partiesandprecinct';
          navigate(path, {
            state: {
              navPage: 'Processing',
              subNav: 'Cases',
              subSubNavigation: 'New Case',
              stateCode: params.stateCode,
              caseType: isCivilMatterCase ? CIVIL_MATTER_CASE : EVICTION_MATTER_CASE,
            },
          });
        },
      };
    }
    if (isCP) {
      return {
        filters: cpCasesAndFilters,
        data: cpCasesAndFilters.getCases?.items ?? [],
        actions: cpCasesActions,
        total: cpCasesAndFilters.getCases.totalRowsCount,
        totalPages: cpCasesAndFilters.getCases.totalPages,
      };
    }
    return {
      data: [],
      filters: {},
      actions: {},
      total: 0,
      totalPages: 0,
    };
  }, [isCP, isDB, dbCases, dbFilters, cpCasesAndFilters]);

  const cases = useCases();

  const handleFetchDataPage = useCallback(
    (currentPage = 1, nextParams = params) => {
      setTableLoading(true);
      return dispatch(actions.getCases({ currentPage, filter: nextParams })).then(() => {
        setTableLoading(false);
        setLoading(false);
      });
    },
    [params, actions.getCases],
  );

  const setFilterPreservation = useCallback(
    (value) => {
      navigate(location.pathname, {
        preventScrollReset: true,
        replace: true,
        state: {
          ...(location?.state || {}),
          preserveFilters: value,
        },
      });
    },
    [location?.state, navigate],
  );

  useEffect(() => {
    dispatch(actions.getStates());
    handleFetchDataPage();
    if (!preserveFilters) {
      setFilterPreservation(true);
    }
  }, []);

  useEffect(() => {
    dispatch(actions.getCaseStatusesByStateCode(params?.stateCode));
  }, [params?.stateCode]);

  useEffect(() => {
    const fetchCategories = async () => {
      const url = isDB
        ? `/api/cases/civilMatters/time/categories`
        : `/cp/api/cases/civilMatters/time/categories`;
      const response = await apiClient.get(url);
      const nextCategories = response?.data?.result?.map(
        ({ id: categoryId, name: categoryName }) => {
          return {
            id: categoryId,
            value: categoryId,
            label: categoryName,
          };
        },
      );

      setLegalCategories(nextCategories);
    };

    fetchCategories();
  }, [apiClient, setLegalCategories]);

  const { columns, search, emptyData } = useMemo(() => {
    if (isCP)
      return {
        columns: tableColumns.cp,
        search: searchFields.cp(params?.stateCode),
        emptyData: {
          title: "You don't have any cases yet",
          desc: "You don't have any cases created in the last 90 days, or which matches the filtering criteria.",
        },
      };
    if (isDB)
      return {
        columns: tableColumns.db,
        search: searchFields.db(params?.stateCode),
        emptyData: {
          title: "You don't have any cases yet",
          desc: 'Every case you create will appear here. To get started click on “New Eviction Matter” button below',
          buttonText: 'New Eviction Matter',
          onClick: onAdd,
        },
      };
    return {
      columns: [],
      search: [],
      emptyData: {},
    };
  }, [isCP, isDB, onAdd, params?.stateCode]);

  const submitHandler = () => {
    const { categories, ...rest } = params;
    const newParams = { ...rest, currentActivityCategoryIds: categories };
    setLoading(true);
    setParams(newParams);
    handleFetchDataPage(1, newParams);
    setSubmitClicked(true);
  };

  const clearFilterHandler = (defaultParams = { stateCode: params.stateCode }) => {
    setSelectedValues({});
    setSubmitClicked(false);
    setUkey(uuidv4());
    setParams(defaultParams);
    setLoading(true);
    handleFetchDataPage(1, defaultParams);
  };

  const handleDropdownChange = (name) => (selectedOption) => {
    const key = name === 'state' ? `${name}Code` : `${name}Id`;
    const result = selectedOption.value;
    cases.actions.update({ ...cases.state, stateCode: selectedOption.value });
    const nextParams = { [key]: result };
    setParams(nextParams);
    setLoading(true);
    clearFilterHandler(nextParams);
  };

  const handleInputChange = (value, name) => {
    setParams((prev) => {
      return { ...prev, [name]: value };
    });

    if (name === 'showArchived' && value === true) {
      setSelectedValues((values) => ({
        ...values,
        status: '',
      }));
    }
  };

  const handleChange = (key) => (selectedOption) => {
    setSelectedValues((values) => ({
      ...values,
      [key]: selectedOption,
    }));

    const result =
      key === 'categories' ? selectedOption?.map((item) => item.id) : selectedOption?.value;
    const nextParams = {
      ...params,
      [key]: ['communityId', 'addressId'].includes(key) ? Number(result) : result,
    };

    if (key === 'status') {
      nextParams.showArchived = false;
    }
    setParams(nextParams);
  };

  const filterArgs = useMemo(
    () => ({
      stateCode: params?.stateCode,
    }),
    [params?.stateCode],
  );

  const addressDropdown = useSearchDropdown({
    hasNextPage: propertyAddresses?.hasNextPage,
    getAction: ({ input, page, ...args }) =>
      actions.getPropertyAddresses({
        currentPage: page,
        pageSize: 20,
        address: input,
        ...args,
      }),
    actionArguments: filterArgs,
    disabled: isCP,
  });

  const communityDropdown = useSearchDropdown({
    hasNextPage: communities?.hasNextPage,
    getAction: ({ input, page, ...args }) =>
      actions.getCommunities({
        currentPage: page,
        pageSize: 20,
        communityName: input,
        ...args,
      }),
    actionArguments: filterArgs,
  });

  const opposingPartyDropdown = useSearchDropdown({
    hasNextPage: opposingParty?.hasNextPage,
    getAction: ({ input, page, ...args }) =>
      actions.getOpposingParty({
        currentPage: page,
        pageSize: 20,
        opposingPartyFullName: input,
        ...args,
      }),
    actionArguments: filterArgs,
  });

  const managementCompanyDropdown = useSearchDropdown({
    hasNextPage: managementCompanies?.hasNextPage,
    getAction: ({ input, page }) =>
      actions.getCompanyManagementsList({
        currentPage: page,
        pageSize: 20,
        managementName: input,
      }),
    disabled: isCP,
  });

  const jurisdictionDropdown = useSearchDropdown({
    hasNextPage: jurisdictions?.hasNextPage,
    getAction: ({ input, page, ...args }) =>
      actions.getJurisdictions({
        currentPage: page,
        pageSize: 20,
        courtName: input,
        ...args,
      }),
    disabled: isCP,
    actionArguments: filterArgs,
  });

  const getDropdownProps = (name) => {
    switch (name) {
      case 'communityId':
        return {
          options: communities?.items,
          handleSearch: communityDropdown.changeInput,
          handleMenuScrollToBottom: communityDropdown.handleMenuScrollToBottom,
          value: selectedValues.communityId,
        };
      case 'categories':
        return {
          options: legalCategories,
          value: selectedValues.categories,
        };
      case 'addressId':
        return {
          options: propertyAddresses?.items,
          handleSearch: addressDropdown.changeInput,
          handleMenuScrollToBottom: addressDropdown.handleMenuScrollToBottom,
          value: selectedValues.addressId,
        };
      case 'opposingPartyFullName':
        return {
          options: opposingParty?.items,
          handleSearch: opposingPartyDropdown.changeInput,
          handleMenuScrollToBottom: opposingPartyDropdown.handleMenuScrollToBottom,
          value: selectedValues.opposingPartyFullName,
        };
      case 'status':
        return {
          options: statusesByStateCode,
          value: selectedValues.status,
          handleSearch: null,
        };
      case 'managementId':
        return {
          options: managementCompanies?.items,
          handleSearch: managementCompanyDropdown.changeInput,
          handleMenuScrollToBottom: managementCompanyDropdown.handleMenuScrollToBottom,
          value: selectedValues.managementId,
        };
      case 'courtId':
        return {
          options: jurisdictions?.items,
          handleSearch: jurisdictionDropdown.changeInput,
          handleMenuScrollToBottom: jurisdictionDropdown.handleMenuScrollToBottom,
          value: selectedValues.courtId,
        };
      default:
        return {};
    }
  };

  const handleBulkCaseCreateClose = () => setIsBulkCaseCreateOpen(false);

  return (
    <>
      <SearchContainer
        caseSearch={caseSearch}
        searchFields={search}
        clearFilterString={clearFilterString}
        submitClicked={submitClicked}
        submitHandler={submitHandler}
        clearFilterHandler={clearFilterHandler}
        handleChange={handleChange}
        handleDropdownChange={handleDropdownChange}
        handleInputChange={handleInputChange}
        getDropdownProps={getDropdownProps}
        setIsBulkCaseCreateOpen={setIsBulkCaseCreateOpen}
        params={params}
        ukey={ukey}
        states={states}
        filter={params}
        onAdd={onAdd}
        isLoading={loading}
      />
      <div className={styles.tableWrapper}>
        {loading && <TableLoader />}
        {total === 0 && !loading && (
          <Stack alignItems="center" paddingTop="50px">
            <EmptyBlock
              title={emptyData.title}
              desc={emptyData.desc}
              buttonText={emptyData.buttonText}
              onClick={emptyData.onClick}
            />
          </Stack>
        )}
        {!loading && !!total && (
          <Table
            columns={columns}
            rows={data || []}
            total={total}
            isPagination={totalPages > 1}
            onNextPage={handleFetchDataPage}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            loading={tableLoading}
          />
        )}
      </div>
      <BulkCaseCreateDialog
        stateCode={params?.stateCode}
        isOpen={isBulkCaseCreateOpen}
        onClose={handleBulkCaseCreateClose}
      />
    </>
  );
};

CaseSearch.propTypes = {
  isCP: PropTypes.bool,
  isDB: PropTypes.bool,
  wasPageReloaded: PropTypes.bool,
};

CaseSearch.defaultProps = {
  isCP: false,
  isDB: false,
  wasPageReloaded: false,
};

export default withWasPageReloaded(CaseSearch);
