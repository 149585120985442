import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Table from '../../../atoms/Table/Table';
import Searcher from '../../../molecules/Searcher';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { getCourts, getStates } from '../../../../store/slices/courtsSlice';
import { deleteCourt } from '../../../../store/slices/editCourtSlice';
import { CourtsColumns, MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './Courts.constants';
import MButton from '../../../MUI/Button/MButton';
import Dropdown from '../../../atoms/Dropdown';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import StateLink from '../../../atoms/StateLink/StateLink';
import BottomBar from '../../../atoms/BottomBar/BottomBar';
import { usePrimaryState } from '../../../../hooks/usePrimaryState';

const Courts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const primaryState = usePrimaryState();

  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const { navPage, subNav, state: defaultState } = useSafeLocationState('Data Handling', 'Courts');
  const { courts, states } = useSelector((state) => state.courts);
  const [state, setState] = useState(primaryState || defaultState || null);
  const getCourtsData = useCallback(
    ({ currentPage = null, courtName = null, stateCode = state?.value } = {}) => {
      if (stateCode !== state?.value) setIsLoader(true);
      const params = {
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        stateCode,
      };
      if (currentPage !== null) params.currentPage = currentPage;
      if (courtName !== null) params.courtName = courtName;
      return dispatch(getCourts(params)).then(() => setIsLoader(false));
    },
    [dispatch, setIsLoader, state],
  );

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      getCourtsData({
        currentPage,
        courtName: searchValue,
      }).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue, getCourtsData],
  );

  const handleChangeEmpty = useCallback(
    (searchElement) => {
      if (!searchElement) {
        setIsLoaderTable(true);
        getCourtsData().then(() => {
          setSearchValue('');
          setIsLoaderTable(false);
        });
      }
    },
    [getCourtsData],
  );

  const handleSearch = useCallback(
    (searchElement) => {
      setSearchValue(searchElement);
      setIsLoaderTable(true);
      getCourtsData({ courtName: searchElement }).then(() => setIsLoaderTable(false));
    },
    [getCourtsData],
  );

  const handleStateChange = useCallback(
    (value) => {
      setState(value);
      setIsLoaderTable(true);
      getCourtsData({
        courtName: searchValue,
        stateCode: value?.value,
      }).then(() => setIsLoaderTable(false));
    },
    [getCourtsData],
  );

  const handleCreateCourt = useCallback(() => {
    navigate('/db/datahandling/newcourt/court', {
      state: {
        navPage,
        subNav,
        state,
        type: 'Justice',
      },
    });
  }, [state]);

  const handleDeleteCourt = useCallback(
    (id, original) => {
      return dispatch(deleteCourt(original.courtId));
    },
    [state?.value],
  );

  useEffect(() => {
    dispatch(getStates());
  }, []);

  useEffect(() => {
    if (states.length) {
      handleStateChange(primaryState || defaultState || states[0]);
    }
  }, [states]);

  const canDelete = useAllowed([rolesDB.Admin]);
  const canCreateCourt = useAllowed([rolesDB.Admin, rolesDB.Attorney, rolesDB.Accounting]);

  const rowsAdapter = courts?.items?.map((item) => ({
    ...item,
    courtName: (
      <StateLink
        to="/db/datahandling/editcourt/court"
        state={{ navPage, subNav, id: item.courtId, name: item.courtName, state, type: item.type }}
      >
        <Typography
          variant="link"
          color="text.primary"
          sx={{
            cursor: 'pointer',
            textDecorationLine: 'underline',
            textTransform: 'none',
          }}
        >
          {item.courtName}
        </Typography>
      </StateLink>
    ),
    control: {
      hideDelete: !canDelete,
      autoRefresh: true,
    },
  }));

  if (!isLoader && !rowsAdapter?.length && !searchValue) {
    return (
      <Stack height="calc(100vh - 80px - 72px)" direction="column">
        <Stack display="flex" flexDirection="row">
          <Typography variant="h3" margin="0 32px">
            Court Search
          </Typography>
          <Dropdown
            label="Select state"
            options={states}
            onChange={handleStateChange}
            value={state}
          />
        </Stack>
        <Box margin="auto">
          <EmptyBlock
            title="You don’t have any courts yet"
            desc={
              canCreateCourt
                ? 'Every court you create will appear here. To get started click on “Create new court” button below'
                : ''
            }
            buttonText={canCreateCourt ? 'Create new court' : ''}
            onClick={canCreateCourt ? handleCreateCourt : undefined}
          />
        </Box>
      </Stack>
    );
  }

  return (rowsAdapter?.length && !isLoader) || searchValue ? (
    <>
      <Box width="1066px" marginX="auto" pb={18}>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5">Courts</Typography>
            <Box paddingTop={1}>
              <Dropdown
                label="Select state"
                options={states}
                onChange={handleStateChange}
                value={state}
              />
            </Box>
          </Stack>
          <Searcher
            inputPlaceholder="Search by Court Name"
            onSearch={handleSearch}
            onChange={handleChangeEmpty}
          />
          {!isLoader && !rowsAdapter?.length && searchValue ? (
            <Box paddingTop="100px" display="flex" justifyContent="center">
              <EmptyBlock
                title="Courts are not found"
                desc="Сhange the search value"
                margin="auto"
              />
            </Box>
          ) : (
            <Table
              columns={CourtsColumns}
              rows={rowsAdapter}
              total={courts.totalRowsCount}
              isPagination={courts?.totalPages > 1}
              onNextPage={handleFetchDataPage}
              onPreviousPage={handleFetchDataPage}
              onGotoPage={handleFetchDataPage}
              pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
              onDeleteRow={handleDeleteCourt}
              onRefreshPage={handleFetchDataPage}
              loading={isLoaderTable}
            />
          )}
        </Stack>
      </Box>
      {canCreateCourt && (
        <BottomBar>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton sx={{ width: '144px' }} size="large" onClick={handleCreateCourt}>
              Create New
            </MButton>
          </Stack>
        </BottomBar>
      )}
    </>
  ) : (
    <Box position="absolute" top="50vh" left="50%" zIndex={10} transform="translate(-50%, -50%)">
      <CircularProgress />
    </Box>
  );
};

export default Courts;
