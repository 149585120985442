import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const QuantityCell = ({ value }) => {
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
      }}
    >
      {value ? `${value}h` : ''}
    </Typography>
  );
};

QuantityCell.propTypes = {
  value: PropTypes.string,
};

QuantityCell.defaultProps = {
  value: '',
};

export default QuantityCell;
