import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useAsyncLoadDropdown = ({
  hasNextPage,
  getAction,
  callback = () => {},
  clearCallback = () => {},
  actionArguments,
  disabled = false,
}) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const handleMenuScrollToBottom = () => {
    if (hasNextPage) {
      setPage((x) => x + 1);
    }
  };

  const getDebounced = useCallback(
    debounce((params) => {
      const call = getAction ? (data) => dispatch(getAction(data)) : callback;

      call({ ...params, ...actionArguments });
    }, 500),
    [actionArguments],
  );

  useEffect(() => {
    clearCallback();
    setPage(1);
  }, [actionArguments]);

  useEffect(() => {
    if (!disabled) {
      getDebounced({
        page,
      });
    }
  }, [page, getDebounced, disabled]);

  return { handleMenuScrollToBottom };
};

export default useAsyncLoadDropdown;
