import { LinearProgress, Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  approveCaseDocument,
  rejectCaseDocument,
} from '../../../../../store/slices/clientPortalDashboardSlice';
import Dropdown from '../../../../atoms/Dropdown';
import Field from '../../../../atoms/Field';
import Form from '../../../../atoms/Form';
import MDialog from '../../../../atoms/MDialog/MDialog';
import EditInputWithLabel from '../../../../molecules/EditInputWithLabel/EditInputWithLabel';
import MButton from '../../../../MUI/Button/MButton';

const reasonsDropdownOptions = [
  { label: 'Tenant paid', value: 'Tenant paid' },
  { label: 'Tenant skipped', value: 'Tenant skipped' },
  { label: 'Tenant turned in keys', value: 'Tenant turned in keys' },
  { label: 'Tenant promised to pay', value: 'Tenant promised to pay' },
  { label: 'Info on notice is wrong', value: 'Info on notice is wrong', isCommentMandatory: true },
  { label: 'Other', value: 'Other', isCommentMandatory: true },
];

const PendingApprovalCell = ({
  row: {
    original: { caseId, documentId, stateCode },
  },
  refreshPage,
  goPreviousPage,
  rows,
  canPreviousPage,
}) => {
  const [isLoader, setIsLoader] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const shouldGoBack = rows.length === 1 && canPreviousPage;
  const onSubmit = (values) => {
    setIsLoader(true);
    dispatch(
      rejectCaseDocument({
        caseId,
        documentId,
        stateCode,
        reason: values.reason.value,
        comment: values.comment,
      }),
    ).then(() => {
      setIsLoader(false);
      if (shouldGoBack) goPreviousPage();
      else refreshPage();
    });
  };

  const onApproveClick = () => {
    setIsLoader(true);
    dispatch(approveCaseDocument({ caseId, documentId, stateCode })).then(() => {
      setIsLoader(false);
      if (shouldGoBack) goPreviousPage();
      else refreshPage();
    });
  };
  const onRejectClick = () => {
    setIsRejectModalOpen(true);
  };
  const closeModal = () => {
    setIsRejectModalOpen(false);
    form.reset();
  };

  const isCommentMandatory = !!form.watch('reason')?.isCommentMandatory;

  useEffect(() => {
    const comment = form.watch('comment');
    if (!isCommentMandatory && !comment) {
      form.trigger('comment');
    }
  }, [isCommentMandatory]);

  if (isLoader) {
    return (
      <Box sx={{ width: '200px' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Stack direction="row" spacing={2.5} paddingX="10px">
        <MButton variant="secondary" size="small" onClick={onApproveClick}>
          Approve
        </MButton>

        <MButton variant="secondary" size="small" onClick={onRejectClick}>
          Reject
        </MButton>
      </Stack>
      {isRejectModalOpen && (
        <MDialog
          title={
            <Typography sx={{ mb: 4 }} variant="h4">
              Reason for Rejection
            </Typography>
          }
          onClose={closeModal}
          maxWidth={false}
          footerActions={
            <>
              <MButton
                variant="secondary"
                size="large"
                onClick={closeModal}
                sx={{ width: '104px' }}
              >
                Cancel
              </MButton>
              <MButton
                type="submit"
                size="large"
                disabled={!form.formState.isValid}
                onClick={form.handleSubmit(onSubmit)}
                sx={{ width: '104px' }}
              >
                Reject
              </MButton>
            </>
          }
        >
          <Form form={form}>
            <Stack direction="row" columnGap={3}>
              <Field
                isMandatory
                name="reason"
                render={({ field, onCustomChange, error }) => (
                  <Dropdown
                    value={field.value}
                    isAddDisabled={false}
                    isSearchable
                    label="Reason"
                    width="519px"
                    isColumn
                    placeholder="Select reason"
                    options={reasonsDropdownOptions}
                    onChange={onCustomChange(field.onChange)}
                    isMandatory
                    error={error}
                  />
                )}
              />
              <Field
                name="comment"
                isMandatory={isCommentMandatory}
                render={({ field, error }) => (
                  <EditInputWithLabel
                    error={error}
                    isMandatory={isCommentMandatory}
                    type="text"
                    label="Comment"
                    name="comment"
                    value={field.value}
                    placeholder="Enter comment"
                    width="519px"
                    onChange={field.onChange}
                  />
                )}
              />
            </Stack>
          </Form>
        </MDialog>
      )}
    </>
  );
};

PendingApprovalCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      documentId: PropTypes.number.isRequired,
      stateCode: PropTypes.string.isRequired,
    }),
  }).isRequired,
  refreshPage: PropTypes.func.isRequired,
  goPreviousPage: PropTypes.func.isRequired,
  state: PropTypes.shape({
    pageIndex: PropTypes.number.isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
};

export default PendingApprovalCell;
