import { DateCell } from './TableCells/DateCell';
import { StatusCell } from './TableCells/StatusCell';
import { InvoiceNumberCell } from './TableCells/InvoiceNumberCell';

export const INVOICES_LABEL = 'Invoices';
export const DROPDOWN_SIZE = 10;
export const PAGE_SIZE = 10;

export const COMMUNITY_FIELD_NAME = 'community';
export const INVOICENUM_FIELD_NAME = 'invoiceNum';
export const STATUS_FIELD_NAME = 'status';
export const FROMDATE_FIELD_NAME = 'fromDate';
export const TODATE_FIELD_NAME = 'toDate';

export const INVOICE_STATUSES = [
  { label: 'Due', value: 'Due' },
  { label: 'OverDue', value: 'OverDue' },
  { label: 'Paid', value: 'Paid' },
];

export const INVOICES_FILTER = [
  {
    label: 'Invoice #',
    name: INVOICENUM_FIELD_NAME,
    width: 237,
    placeholder: 'Enter invoice number',
  },
  {
    label: 'Community',
    name: COMMUNITY_FIELD_NAME,
    width: 219,
    placeholder: 'Select community',
    isDropdown: true,
  },
  {
    label: 'Status',
    name: STATUS_FIELD_NAME,
    width: 258,
    placeholder: 'Select status',
    isDropdown: true,
  },
  {
    label: 'From Date',
    name: FROMDATE_FIELD_NAME,
    placeholder: 'mm/dd/yyyy',
    width: 229,
    height: '44px',
    isDatePicker: true,
    dateFormat: 'MM/DD/YY',
  },
  {
    label: 'To Date',
    name: TODATE_FIELD_NAME,
    placeholder: 'hh:mm',
    width: 251,
    height: '44px',
    isDatePicker: true,
    dateFormat: 'MM/DD/YY',
  },
  {
    isFilterActions: true,
  },
];

export const INVOICES_TABLE_COLUMNS = [
  {
    Header: 'Invoice #',
    accessor: 'invoiceNumber',
    width: 25,
    Cell: InvoiceNumberCell,
  },
  {
    Header: 'Invoice Date',
    accessor: 'invoiceDate',
    width: 25,
    Cell: DateCell,
  },
  {
    Header: 'Due Date',
    accessor: 'dueDate',
    width: 25,
    Cell: DateCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 25,
    Cell: StatusCell,
  },
];
