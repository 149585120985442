import { useEffect, useMemo, useState } from 'react';

const defaultData = [];
const useManualPagination = ({ data = defaultData, pageSize = 10 }) => {
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const totalRowsCount = useMemo(() => {
    if (!data.length) return 0;
    return data.length;
  }, [data.length]);

  const isPagination = useMemo(() => {
    return Math.ceil(data.length / pageSize) > 1;
  }, [totalRowsCount, pageSize]);

  useEffect(() => {
    if (currentPage === 1) {
      setRows(data.slice(0, pageSize));
    } else {
      setCurrentPage(1);
    }
  }, [data]);

  useEffect(() => {
    setRows(data.slice((currentPage - 1) * pageSize, currentPage * pageSize));
  }, [currentPage, pageSize]);

  return {
    totalRowsCount,
    isPagination,
    setCurrentPage,
    rows,
  };
};

export default useManualPagination;
