import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ManagementContext from '../context/ManagementContext';

export const defaultState = {
  result: {
    managementId: 0,
    managementName: '',
    managementCode: '',
    contactInfo: {
      stateCode: '',
      city: '',
      address: '',
      zipCode: '',
      phone: '',
      fax: null,
      website: null,
      email: null,
    },
    invoicing: {
      isDeactivated: false,
      isOnHold: false,
      onHoldDescription: '',
      isAutoInvoicing: false,
      isEmailInvoice: false,
      invoiceFrequency: '',
      invoiceDetail: '',
    },
    secondaryKeys: {
      customerRefListId: '-',
      customerRefFullName: '-',
    },
    contacts: [],
  },
  errorCode: null,
  errorMessage: null,
  invalidField: null,
  isSuccess: true,
};

const ManagementProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const defaultContext = useMemo(
    () => ({
      state,
      actions: {
        update: (form) => setState(() => ({ ...state, ...form })),
        clear: () => setState(defaultState),
      },
    }),
    [state],
  );

  return <ManagementContext.Provider value={defaultContext}>{children}</ManagementContext.Provider>;
};

ManagementProvider.propTypes = {
  children: PropTypes.element,
};

ManagementProvider.defaultProps = {
  children: null,
};

export default ManagementProvider;
