import React, { forwardRef, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { Box, Typography } from '@mui/material';
import style from './Dropdown.module.scss';
import { ReactComponent as Asterisk } from '../../../assets/icons/asterisk.svg';
import { palette } from '../../../theme/default';
import { getRandomId } from '../../../utils/randomId';
import MTooltip from '../MTooltip';

const customStyles = ({ width, backgroundColor, placeholderStyles }) => ({
  control: (provided) => ({
    ...provided,
    border: 0,
    width: width || 'auto',
    backgroundColor,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0 0 5px',
  }),
  placeholder: (provided) => ({
    ...provided,
    ...placeholderStyles,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: 'var(--text_primary)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--text_primary)',
    fontWeight: 500,
    fontSize: 16,
  }),

  menu: (provided) => ({
    ...provided,
    zIndex: 10000,
  }),
});

const Dropdown = forwardRef((props) => {
  const {
    id,
    isDisabled,
    isMandatory,
    label,
    options,
    onChange,
    placeholder,
    placeholderStyles,
    isColumn,
    width,
    isSearchable,
    defaultValue,
    value,
    error,
    onMenuScrollToBottom,
    onInputChange,
    onMenuOpen,
    filterOption,
    onBlur,
    onEnter,
    backgroundColor,
    tooltip,
    testid,
    startIcon,
    height,
    menuStyles,
    maxMenuHeight,
    readOnly,
    menuPosition,
    customMessageStyles,
    menuShouldBlockScroll,
    menuPlacement,
    columnControlStyles,
  } = props;

  const ifColumn = {
    ...customStyles,
    control: (provided, state) => ({
      ...provided,
      borderWidth: readOnly && !error ? 0 : '1px',
      borderStyle: 'solid',
      borderColor: state.selectProps.isError ? 'var(--alert)' : 'var(--border_line)',
      borderRadius: '10px',
      height: height || '48px',
      width: 'auto',
      backgroundColor: palette.common.white,
      ...(columnControlStyles || {}),
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 12px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: 'var(--text_primary)',
        display: readOnly ? 'none' : 'inherit',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: palette.text.secondary,
      fontWeight: 500,
      fontSize: 16,
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10000,
      ...menuStyles,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 10000,
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontSize: 14,
    }),
    singleValue: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: isDisabled ? palette.text.disabledText : 'var(--text_primary)',
      fontWeight: 500,
      fontSize: 16,
    }),
  };

  const handleChange = (inputValue) => {
    onChange(inputValue);
  };

  const handleInputChange = (inputValue) => {
    onInputChange(inputValue);
  };

  const handleClickStopPropagation = (e) => {
    e.stopPropagation();
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && onEnter && !isMenuOpen) {
      e.preventDefault();
      onEnter(e);
    }
  };

  const handleMenuOpen = (...args) => {
    setIsMenuOpen(true);
    onMenuOpen?.(...args);
  };
  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const blur = (ev) => {
    handleMenuClose();
    onBlur(ev);
  };

  const inputId = useMemo(() => {
    if (id) return id;
    return getRandomId();
  }, [id]);

  // eslint-disable-next-line
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {startIcon || null}
      {children}
    </components.SingleValue>
  );

  return (
    <StyledDropdown
      className={`${!isColumn && style.dropdown_container}`}
      isColumn={isColumn}
      width={width}
      data-testid="dropdown"
    >
      {label && (
        <Typography
          variant="buttonLarge"
          color="text.secondary"
          component="label"
          htmlFor={inputId}
          data-testid="dropdown_label"
        >
          {label}
          {isMandatory && (
            <StyledSpan data-testid="dropdown_label_asterisk">
              <Asterisk />
            </StyledSpan>
          )}
          {tooltip && (
            <MTooltip
              text={tooltip}
              customStyles={{ position: 'absolute', top: '-10px', right: '-30px' }}
            />
          )}
        </Typography>
      )}
      <Box
        position="relative"
        onClick={handleClickStopPropagation}
        data-testid={testid || 'select_wrapper'}
      >
        <Select
          data-testid="select_dropdown"
          maxMenuHeight={maxMenuHeight}
          inputId={inputId}
          isDisabled={isDisabled}
          isSearchable={isSearchable}
          styles={isColumn ? ifColumn : customStyles({ width, backgroundColor, placeholderStyles })}
          placeholder={placeholder}
          options={options}
          onInputChange={handleInputChange}
          onChange={handleChange}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          onKeyDown={onKeyDown}
          onBlur={blur}
          value={value}
          onMenuScrollToBottom={onMenuScrollToBottom}
          defaultValue={defaultValue}
          isError={!!error?.message}
          filterOption={filterOption}
          menuPosition={menuPosition}
          menuShouldBlockScroll={menuShouldBlockScroll}
          menuPlacement={menuPlacement}
          menuIsOpen={isMenuOpen}
          components={{
            SingleValue,
          }}
        />
        {error?.message && (
          <Typography
            variant="bodyXS"
            color="additional.alert"
            component="span"
            position="absolute"
            left={0}
            top="calc(100% + 4px)"
            data-testid="dropdown_error_msg"
            sx={{ ...customMessageStyles }}
          >
            {error?.message}
          </Typography>
        )}
      </Box>
    </StyledDropdown>
  );
});

const StyledDropdown = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  width: ${({ width }) => width};
  label {
    margin-bottom: ${({ isColumn }) => (isColumn ? '4px' : null)};
  }
`;

const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

Dropdown.propTypes = {
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderStyles: PropTypes.shape({
    fontSize: PropTypes.string,
    color: PropTypes.string,
    fonrWeight: PropTypes.number,
  }),
  backgroundColor: PropTypes.string,
  isColumn: PropTypes.bool,
  isMandatory: PropTypes.bool,
  width: PropTypes.number.isRequired,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.shape({
    message: PropTypes.string,
    ref: PropTypes.shape({
      name: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
  onMenuScrollToBottom: PropTypes.func,
  onInputChange: PropTypes.func,
  filterOption: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onEnter: PropTypes.func,
  tooltip: PropTypes.string,
  testid: PropTypes.string,
  startIcon: PropTypes.ReactComponent,
  height: PropTypes.string,
  menuStyles: PropTypes.shape({}),
  maxMenuHeight: PropTypes.number,
  readOnly: PropTypes.bool,
  menuPosition: PropTypes.string,
  customMessageStyles: PropTypes.shape({}),
  columnControlStyles: PropTypes.shape({}),
  menuShouldBlockScroll: PropTypes.bool,
  menuPlacement: PropTypes.string,
};

Dropdown.defaultProps = {
  id: '',
  isDisabled: false,
  placeholder: '',
  placeholderStyles: {
    color: 'var(--text_primary)',
    fontWeight: 500,
    fontSize: 18,
  },
  isColumn: false,
  isSearchable: false,
  defaultValue: '',
  value: undefined,
  isMandatory: false,
  error: {},
  onMenuScrollToBottom: () => {},
  onInputChange: () => {},
  onMenuOpen: () => {},
  filterOption: undefined,
  onBlur: () => {},
  onChange: () => {},
  backgroundColor: 'inherit',
  onEnter: null,
  tooltip: '',
  testid: '',
  startIcon: null,
  height: '',
  menuStyles: {},
  maxMenuHeight: undefined,
  readOnly: false,
  menuPosition: '',
  customMessageStyles: {},
  menuShouldBlockScroll: false,
  menuPlacement: 'bottom',
  columnControlStyles: null,
};

export default React.memo(Dropdown);
