import axios from 'axios';
import { isPlainObject } from 'lodash';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function requestRefreshToken(tokenRepository) {
  const token = tokenRepository.getToken();
  const refreshToken = tokenRepository.getRefreshToken();
  let decodedToken = null;

  try {
    decodedToken = jwtDecode(token);
    let refreshApi = '/api/refresh';

    // decoded token returns "IsClientPortalUser": "True" or "IsClientPortalUser": "False"
    // we also can use from utils
    // but we'll have dep cycle then
    if (decodedToken.IsClientPortalUser === 'True') {
      refreshApi = '/cp/api/refresh';
    }

    return axios.post(`${BASE_URL}${refreshApi}`, { refreshToken });
  } catch (error) {
    return error;
  }
}

export function clearLocalStorageAndMoveToLoginPage(tokenRepository) {
  const isClientPortal = window.location.pathname.startsWith('/cp/');
  const isClientOnlyPortal = window.location.pathname.startsWith('/client/');

  let page = '/db/login';

  if (isClientPortal) {
    page = '/cp/login';
  }

  if (isClientOnlyPortal) {
    page = '/client/login';
  }

  tokenRepository.removeTokens();
  document.location.href = page;
}

export function dateTransformer(data) {
  if (data instanceof moment) {
    return data.format('YYYY-MM-DDTHH:mm:ss');
  }
  if (data instanceof Date) {
    return moment(data).format('YYYY-MM-DDTHH:mm:ss');
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer);
  }
  if (isPlainObject(data)) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, dateTransformer(value)]),
    );
  }
  return data;
}
