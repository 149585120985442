/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getCommunitiesList = createAsyncThunk(
  'invoices/getCommunitiesList',
  async (params, { getState }) => {
    const { open, ...rest } = params;
    const { data } = await apiClient.post(`/cp/api/communities/filter`, rest);

    const {
      invoices: { communities },
    } = getState();

    if (!open && !communities) return null;

    const prevValue = communities?.prevSearchValue || '';

    if (!open && prevValue !== rest.communityName) {
      return {
        ...data.result,
        prevSearchValue: rest.communityName,
        items: data.result.items.map((item) => ({
          id: item.communityId,
          value: item.communityName,
          label: item.communityName,
        })),
      };
    }

    if (!open && communities) {
      return {
        ...data.result,
        prevSearchValue: rest.communityName,
        items: communities.items.concat(
          data.result.items.map((item) => ({
            id: item.communityId,
            value: item.communityName,
            label: item.communityName,
          })),
        ),
      };
    }

    return {
      ...data.result,
      prevSearchValue: rest.communityName,
      items: data.result.items.map((item) => ({
        id: item.communityId,
        value: item.communityName,
        label: item.communityName,
      })),
    };
  },
);

export const getAddressesList = createAsyncThunk(
  'invoices/getAddressesList',
  async (params, { getState }) => {
    const { open, ...rest } = params;
    const { data } = await apiClient.post(`/cp/api/addresses/filter`, rest);

    const {
      invoices: { addresses },
    } = getState();

    if (!open && !addresses) return null;

    const prevValue = addresses?.prevSearchValue || '';

    if (!open && prevValue !== rest.address) {
      return {
        ...data.result,
        prevSearchValue: rest.address,
        items: data.result.items.map((item) => ({
          id: item.addressId,
          value: item.address,
          label: item.address,
        })),
      };
    }

    if (!open && addresses) {
      return {
        ...data.result,
        prevSearchValue: rest.communityName,
        items: addresses.items.concat(
          data.result.items.map((item) => ({
            id: item.addressId,
            value: item.address,
            label: item.address,
          })),
        ),
      };
    }

    return {
      ...data.result,
      prevSearchValue: rest.address,
      items: data.result.items.map((item) => ({
        id: item.addressId,
        value: item.address,
        label: item.address,
      })),
    };
  },
);

export const getInvoices = createAsyncThunk('invoices/getInvoices', async (params) => {
  const { data } = await apiClient.post(`/cp/api/invoices/filter`, params);

  return data.result;
});

export const resetInvoices = createAction('invoices/resetInvoices', () => {
  return {
    type: 'invoices/resetInvoices',
  };
});

export const resetAddresses = createAction('invoices/resetAddresses', () => {
  return {
    type: 'invoices/resetAddresses',
  };
});

export const resetCommunities = createAction('invoices/resetCommunities', () => {
  return {
    type: 'invoices/resetCommunities',
  };
});

const initialState = {
  error: null,
  status: null,
  invoices: null,
  addresses: null,
  communities: null,
};

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: {
    [getInvoices.fulfilled]: (state, action) => {
      state.invoices = action.payload;
    },
    [resetInvoices]: (state) => {
      state.invoices = null;
    },
    [getCommunitiesList.fulfilled]: (state, action) => {
      state.communities = action.payload;
    },
    [resetCommunities]: (state) => {
      state.communities = {};
    },
    [getAddressesList.fulfilled]: (state, action) => {
      state.addresses = action.payload;
    },
    [resetAddresses]: (state) => {
      state.addresses = {};
    },
  },
});

export default invoicesSlice.reducer;
