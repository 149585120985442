/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack, Box } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import Table from '../../../../atoms/Table/Table';
import { ContactPersonsAllColumns, MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './Management.constants';
import Searcher from '../../../../molecules/Searcher';
import { getContactPersons } from '../../../../../store/slices/editManagementSlice';
import MDialog from '../../../../atoms/MDialog';
import LoaderCover from '../../../../atoms/LoaderCover';
import EmptyBlock from '../../../../molecules/EmptyBlock';

const ManagementContactPersonsDialog = ({
  isOpen,
  title,
  buttonSecondaryText,
  buttonPrimaryText,
  onClose,
  onConfirm,
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const { contactPersons } = useSelector((state) => state.editManagement);
  const [tableData, setTableData] = useState(contactPersons);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [noticeType, setNoticeType] = useState('');

  useEffect(() => {
    if (isOpen) {
      dispatch(getContactPersons({ currentPage: 1, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(
        () => {
          setIsLoader(false);
        },
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (contactPersons?.items?.length) {
      setTableData(contactPersons);
    }
  }, [contactPersons]);

  useEffect(() => {
    if (!name && !type && !noticeType) {
      setTableData(contactPersons);
      return;
    }
    let tempData = tableData;

    if (!tempData.length) {
      tempData = contactPersons;
    }

    setTableData(
      tempData.filter((item) => {
        return (
          item.name.includes(name) &&
          item.noticeType.includes(noticeType) &&
          item.templateType.includes(type)
        );
      }),
    );
  }, [name, type, noticeType]);

  const handleCheckedRow = useCallback((selectedRows) => {
    setSelectedIds(
      selectedRows.map((item) => ({
        ...item,
      })),
    );
  }, []);

  const handleClose = useCallback(() => {
    setSearchValue('');
    onClose?.();
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm?.(
      selectedIds.map((item) => ({
        contactId: item.contactId,
        name: item.contactName,
        title: item.contactTitle,
        workPhone: item.contactWorkPhone,
        mobilePhone: item.contactMobilePhone,
        email: item.contactEmail,
      })),
    );
  }, [selectedIds, onConfirm]);

  const handleChangeEmpty = useCallback((searchElement) => {
    if (!searchElement) {
      setIsLoaderTable(true);
      dispatch(getContactPersons({ currentPage: 1, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(
        () => {
          setIsLoaderTable(false);
          setSearchValue('');
          setIsSearch(true);
        },
      );
    }
  }, []);

  const handleSearch = useCallback((searchElement) => {
    setSearchValue(searchElement);
    setIsLoader(true);
    dispatch(
      getContactPersons({
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        contactName: searchElement,
      }),
    ).then(() => {
      setIsLoaderTable(false);
      setIsLoader(false);
    });
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      dispatch(
        getContactPersons({
          currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          contactName: searchValue,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue],
  );

  return (
    <MDialog
      onClose={onClose}
      title={title}
      titleVariant="h4"
      isOpen={isOpen}
      maxWidth="xl"
      fullWidth
      scrollable
      footerActions={
        <>
          {buttonSecondaryText && (
            <MButton variant="secondary" size="large" onClick={handleClose} sx={{ width: '104px' }}>
              {buttonSecondaryText}
            </MButton>
          )}
          {buttonPrimaryText && (
            <MButton
              size="large"
              onClick={handleConfirm}
              sx={{ width: '104px' }}
              disabled={!selectedIds.length}
            >
              {buttonPrimaryText}
            </MButton>
          )}
        </>
      }
    >
      <Stack spacing={4}>
        <Searcher
          inputPlaceholder="Search by Contact Person"
          onSearch={handleSearch}
          onChange={handleChangeEmpty}
        />
        <Box height="calc(35vh + 72px + 24px)" width="100%" position="relative">
          {isLoader && <LoaderCover />}
          {!isLoader &&
            (contactPersons?.items?.length ? (
              <Table
                columns={ContactPersonsAllColumns}
                rows={tableData?.items}
                total={tableData?.totalRowsCount}
                isPagination={contactPersons?.totalPages > 1}
                onCheckedRow={handleCheckedRow}
                isWithCheckbox
                loading={isLoaderTable}
                onNextPage={handleFetchDataPage}
                onPreviousPage={handleFetchDataPage}
                onGotoPage={handleFetchDataPage}
                pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
                maxHeight="35vh"
              />
            ) : (
              <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                <EmptyBlock title="Contact persons weren't found" desc="Сhange the search value" />
              </Box>
            ))}
        </Box>
      </Stack>
    </MDialog>
  );
};

ManagementContactPersonsDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

ManagementContactPersonsDialog.defaultProps = {
  isOpen: false,
  title: '',
  buttonSecondaryText: '',
  buttonPrimaryText: '',
  onClose: null,
  onConfirm: null,
};

export default ManagementContactPersonsDialog;
