import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

export const useBaseUrl = () => {
  const { pathname } = useLocation();

  const base = useMemo(() => {
    const isDBPortal = _.startsWith(pathname, '/db/');

    if (isDBPortal) return 'db';
    const isClientOnlyPortal = _.startsWith(pathname, '/client');
    if (isClientOnlyPortal) return 'client';
    return 'cp';
  }, [pathname]);

  return base;
};
