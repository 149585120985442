/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getAllClientPortalUsers = createAsyncThunk(
  'adminusers/getAllClientPortalUsers',
  async (params) => {
    const { data } = await apiClient.post('/cp/api/users/filter', params);
    return data;
  },
);

export const getAllClientPortalRoles = createAsyncThunk(
  'adminusers/getAllClientPortalRoles',
  async () => {
    const { data } = await apiClient.get('/cp/api/roles');
    return data.result.map((item) => ({
      id: item.roleId,
      value: item.name,
      label: item.name,
    }));
  },
);

export const getAllClientPortalStates = createAsyncThunk(
  'adminusers/getAllClientPortalStates',
  async () => {
    const { data } = await apiClient.get('/cp/api/states');
    return data.result.map((item) => ({
      id: item.id,
      value: item.code,
      label: item.code,
    }));
  },
);

export const deleteUserById = createAsyncThunk('adminusers/deleteUserById', async (userId) => {
  const { data } = await apiClient.delete(`/cp/api/users/${userId}`);
  return data;
});

export const getCommunities = createAsyncThunk('adminusers/getCommunities', async (userId) => {
  const { data } = await apiClient.get(`/cp/api/users/${userId}/communities`);
  return data;
});

export const editCommunities = createAsyncThunk('adminusers/editCommunities', async (params) => {
  const { userId, ...rest } = params;
  const { data } = await apiClient.put(`/cp/api/users/${userId}/communities`, rest);
  return data;
});

export const getAddresses = createAsyncThunk('adminusers/getAddresses', async (userId) => {
  const { data } = await apiClient.get(`/cp/api/users/${userId}/addresses`);
  return data;
});

export const editAddresses = createAsyncThunk('adminusers/editAddresses', async (params) => {
  const { userId, ...rest } = params;
  const { data } = await apiClient.put(`/cp/api/users/${userId}/addresses`, rest);
  return data;
});

export const createNewUser = createAsyncThunk(
  'adminusers/createNewUser',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/cp/api/users`, params);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const updateUser = createAsyncThunk(
  'adminusers/updateUser',
  async (params, { rejectWithValue }) => {
    const { userId } = params;
    try {
      const { data } = await apiClient.put(`/cp/api/users/${userId}`, {
        ...params,
        headers: { 'Content-Type': 'application/json' },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getUserDetails = createAsyncThunk('adminusers/getUserDetails', async (userId) => {
  const { data } = await apiClient.get(`/cp/api/users/${userId}`);
  return data;
});

export const getCommunitiesList = createAsyncThunk(
  'adminusers/getCommunitiesList',
  async (params) => {
    const { data } = await apiClient.post(`/cp/api/communities/filter`, params);
    return data;
  },
);

export const getAddressesList = createAsyncThunk('adminusers/getAddressesList', async (params) => {
  const { data } = await apiClient.post(`/cp/api/addresses/filter`, params);
  return data;
});

export const getCompanyManagementsList = createAsyncThunk(
  'adminusers/getCompanyManagementsList',
  // eslint-disable-next-line
  async (params = {}, thunkAPI) => {
    const {
      companyManagements: { items: prevItems = [] },
    } = thunkAPI.getState().clientPortalAdminUsers;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/cp/api/managements/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.managementId,
      label: item?.managementName,
      value: item?.managementId,
      data: item,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const addCommunityToTheTableInUserCreationMode = createAction(
  'adminusers/addCommunityToTheTableInUserCreationMode',
  (rowsToAdd) => {
    return {
      type: 'adminusers/addCommunityToTheTableInUserCreationMode',
      payload: rowsToAdd,
    };
  },
);

export const addAddressesToTheTableInUserCreationMode = createAction(
  'adminusers/addAddressesToTheTableInUserCreationMode',
  (rowsToAdd) => {
    return {
      type: 'adminusers/addAddressesToTheTableInUserCreationMode',
      payload: rowsToAdd,
    };
  },
);

export const deleteAddressesFromTheTableInUserCreationMode = createAction(
  'adminusers/deleteAddressesFromTheTableInUserCreationMode',
  (rowsToAdd) => {
    return {
      type: 'adminusers/deleteAddressesFromTheTableInUserCreationMode',
      payload: rowsToAdd,
    };
  },
);

export const deleteCommunityFromTheTableInUserCreationMode = createAction(
  'adminusers/deleteCommunityFromTheTableInUserCreationMode',
  (rowsToAdd) => {
    return {
      type: 'adminusers/deleteCommunityFromTheTableInUserCreationMode',
      payload: rowsToAdd,
    };
  },
);

export const resetUserDetailsState = createAction('adminusers/resetUserDetailsState', () => {
  return {
    type: 'adminusers/resetUserDetailsState',
  };
});

export const resetUserAddressesAndCommunitiesOnCreation = createAction(
  'adminusers/resetUserAddressesAndCommunitiesOnCreation',
);

export const generateSelfEnrollmentLinks = createAsyncThunk(
  'adminusers/generateSelfEnrollmentLinks',
  async (params) => {
    const { data } = await apiClient.post(`/cp/api/SelfEnrollments/tokens/groups`, params);
    return data;
  },
);

const initialState = {
  usersTable: [],
  roles: [],
  error: null,
  communities: {},
  addresses: {},
  userDetails: {},
  companyManagements: {
    items: [],
  },
  createNewUserError: null,
};

export const ClientPortalAdminUsers = createSlice({
  name: 'clientPortalAdminUsers',
  initialState,
  reducers: {},
  extraReducers: {
    [resetUserDetailsState]: (state) => {
      state.userDetails = {};
    },
    [resetUserAddressesAndCommunitiesOnCreation]: (state) => {
      state.userDetails.addresses = [];
      state.userDetails.communities = [];
    },
    [addAddressesToTheTableInUserCreationMode]: (state, action) => {
      const alreadyAdded = state?.userDetails?.addresses || [];
      state.userDetails.addresses = [...alreadyAdded, ...action.payload];
    },
    [deleteAddressesFromTheTableInUserCreationMode]: (state, action) => {
      state.userDetails.addresses = action.payload;
    },
    [addCommunityToTheTableInUserCreationMode]: (state, action) => {
      const alreadyAdded = state?.userDetails?.communities || [];
      state.userDetails.communities = [...alreadyAdded, ...action.payload];
    },
    [deleteCommunityFromTheTableInUserCreationMode]: (state, action) => {
      state.userDetails.communities = action.payload;
    },
    [getAddressesList.fulfilled]: (state, action) => {
      state.addresses = action.payload.result;
    },
    [getCommunitiesList.fulfilled]: (state, action) => {
      state.communities = action.payload.result;
    },
    [getCompanyManagementsList.fulfilled]: (state, action) => {
      state.companyManagements = action.payload;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.userDetails = action.payload.result;
    },
    [updateUser.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createNewUser.rejected]: (state, action) => {
      state.createNewUserError = action.payload;
    },
    [editAddresses.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [editCommunities.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.addresses = action.payload;
    },
    [getCommunities.fulfilled]: (state, action) => {
      state.communities = action.payload;
    },
    [deleteUserById.fulfilled]: (state, action) => {
      state.error = action.payload;
    },
    [getAllClientPortalStates.fulfilled]: (state, action) => {
      state.states = action.payload;
    },
    [getAllClientPortalStates.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAllClientPortalRoles.fulfilled]: (state, action) => {
      state.roles = action.payload;
    },
    [getAllClientPortalRoles.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAllClientPortalUsers.fulfilled]: (state, action) => {
      state.usersTable = action.payload.result;
    },
    [getAllClientPortalUsers.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default ClientPortalAdminUsers.reducer;
