import React, { useRef } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { LightTooltip } from '../../../../atoms/MTooltip/MTooltip';
import { useEllipsisTooltip } from '../../../../../hooks/useEllipsisTooltip';

const TextEllipsis = ({ value, row: { original } }) => {
  const { isTotalRow } = original;
  if (isTotalRow) return null;
  const ref = useRef(null);
  const { showTooltip } = useEllipsisTooltip(ref.current);

  if (showTooltip) {
    return (
      <LightTooltip title={value} placement="top">
        <Typography
          sx={{
            textAlign: 'right',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '94%',
            fontSize: '16px',
            fontWeight: 500,
          }}
          ref={ref}
        >
          {value}
        </Typography>
      </LightTooltip>
    );
  }
  return (
    <Typography
      sx={{
        textAlign: 'right',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '94%',
        fontSize: '16px',
        fontWeight: 500,
      }}
      ref={ref}
    >
      {value}
    </Typography>
  );
};

TextEllipsis.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      isTotalRow: PropTypes.bool,
    }),
  }).isRequired,
};

TextEllipsis.defaultProps = {
  value: '',
};

export default TextEllipsis;
