import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import Dropzone from 'react-dropzone';

import { ReactComponent as Upload } from '../../../assets/icons/document-upload-white.svg';
import MButton from '../../MUI/Button/MButton';
import EditInputWithLabel from '../EditInputWithLabel';
import Field from '../../atoms/Field';
import { stringAvatar } from '../../../utils/stringAvatar';
import useAuth from '../../../hooks/useAuth';
import Dropdown from '../../atoms/Dropdown';
import { useAvailableStatesList } from '../../../hooks/useAvailableStatesList';
import {
  defaultOptionPage,
  defaultCPOptionPage,
  defaultClientOptionPage,
} from './LoginWidget.constants';
import { convertToBinaryFile, convertToBinaryFileUrl } from '../../../utils/binaryHelpers';
import notificationUtils from '../../../utils/notificationUtils';
import { getLandingPagesByRoles } from '../../organisms/TopNavBar/TopNavBar.constants';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const DefaultAvatar = () => {
  const { userInfo } = useAuth();

  const userName = `${userInfo?.FirstName || ''} ${userInfo?.LastName || ''}`;
  const { sx, children } = stringAvatar(userName);

  return (
    <Avatar data-testid="avatar" sx={{ width: 120, height: 120, fontSize: 20, ...sx }}>
      {children}
    </Avatar>
  );
};
const Profile = ({ form }) => {
  const { profile } = useSelector((state) => state.userProfile);
  const states = useAvailableStatesList();
  const { userInfo } = useAuth();
  const { isClientPortalUser } = userInfo;
  const [isError, setError] = useState(false);
  const [source, setSrc] = useState(null);
  let excludedPages = [];
  if (!userInfo.stateCodes?.includes('AZ')) {
    excludedPages = ['Legal Forms'];
  }
  const base = useBaseUrl();
  const pagesOptions = getLandingPagesByRoles(
    userInfo.roles,
    isClientPortalUser,
    base,
    excludedPages,
  );

  const clientPortalOption = base === 'client' ? defaultClientOptionPage : defaultCPOptionPage;
  const defaultOption = isClientPortalUser ? clientPortalOption : defaultOptionPage;

  const setInitialFile = () => {
    const file = convertToBinaryFile({
      contentType: profile.imageContent.contentType,
      content: profile.imageContent.content,
      fileName: 'avatar',
    });
    form.setValue('file', file);
    const src = convertToBinaryFileUrl({
      contentType: profile.imageContent.contentType,
      content: profile.imageContent.content,
      fileName: 'avatar',
    });

    form.setValue('imageSrc', src);
    setSrc(src);
    form.setValue('fileExtension', profile.imageContent.contentType);
  };

  useEffect(() => {
    if (isError) {
      notificationUtils.error(`File is too large`, {
        autoHideDuration: 5000,
      });
      if (profile.imageContent?.content) {
        setInitialFile();
      } else {
        form.setValue('file', undefined);
        form.setValue('imageSrc', '');
        setSrc(null);
        form.setValue('fileExtension', '');
      }
    }
  }, [isError]);

  useEffect(() => {
    if (profile) {
      form.setValue('firstName', profile.firstName);
      form.setValue('lastName', profile.lastName);
      form.setValue('middleName', profile.middleName);
      form.setValue('suffix', profile.suffix);
      form.setValue('userName', profile.userName);
      form.setValue('email', profile.email);
      const stateOption = states.find((item) => {
        if (profile.primaryState) {
          return item.value === profile.primaryState;
        }
        return item.value === userInfo.stateCodes[0];
      });

      form.setValue('primaryState', stateOption);
      if (profile.landingPage) {
        const currentOption =
          pagesOptions.find((item) => item.label === profile.landingPage) || defaultOption;
        form.setValue('landingPage', currentOption);
      } else {
        form.setValue('landingPage', defaultOption);
      }

      if (profile.imageContent?.content) {
        setInitialFile();
      }
    }
  }, [profile]);

  const customValidator = (file) => {
    const image = new Image();

    image.addEventListener('load', () => {
      if (image.width > 120 || image.height > 120) {
        setError(true);
      } else {
        setError(false);
      }

      return null;
    });
    image.src = URL.createObjectURL(file);
    form.setValue('imageSrc', image.src);
    setSrc(image.src);
  };

  const onDrop = ([file]) => {
    setError(false);
    customValidator(file);
    form.setValue('file', file);
    form.setValue('fileExtension', file.type);
  };

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      rejectedFiles.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          if (error.code === 'file-invalid-type') {
            notificationUtils.error(`File type is not supported - ${file.name}`, {
              autoHideDuration: 5000,
            });
          }
          if (error.code === 'file-too-large') {
            notificationUtils.error(`File is too large - ${file.name}`, { autoHideDuration: 5000 });
          }
        });
      });
    }
    onDrop(acceptedFiles, rejectedFiles);
  };

  return (
    <Box>
      <Stack direction="row" mb={5}>
        <Box
          sx={{
            marginRight: '32px',
            marginTop: '32px',
            height: '120px',
            borderRadius: '50%',
            minWidth: '120px',
          }}
        >
          {source ? (
            <img
              src={source}
              alt="image_login"
              style={{
                height: '120px',
                width: '120px',
                borderRadius: '50%',
              }}
              data-testid="logo"
            />
          ) : (
            <DefaultAvatar />
          )}
        </Box>
        <Box alignSelf="flex-end" flex>
          <Dropzone
            onDrop={handleDrop}
            accept={{
              'image/png': ['.png'],
              'image/jpg': ['.jpg'],
              'image/jpeg': ['.jpeg'],
            }}
            maxSize={1024 * 100}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} data-testid="dropzone_input" />
                <MButton
                  startIcon={<Upload width="20px" height="20px" />}
                  data-testid="upload_button"
                  sx={{ marginBottom: 2 }}
                >
                  Upload New Photo
                </MButton>
              </div>
            )}
          </Dropzone>
          <Typography variant="bodyXS" paragraph sx={{ marginBottom: 0 }}>
            Supports JPG, JPEG, PNG files up to 100KB and up to 120x120px
          </Typography>
        </Box>
      </Stack>
      <Box>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Field
            name="firstName"
            isMandatory
            render={({ field, error }) => {
              return (
                <EditInputWithLabel
                  label="First Name"
                  width="326px"
                  name="firstName"
                  value={field.value}
                  onChange={field.onChange}
                  isMandatory
                  placeholder="Enter First Name"
                  error={error}
                />
              );
            }}
          />
          <Field
            name="lastName"
            isMandatory
            render={({ field, error }) => {
              return (
                <EditInputWithLabel
                  label="Last Name"
                  width="326px"
                  name="firstName"
                  value={field.value}
                  onChange={field.onChange}
                  isMandatory
                  placeholder="Enter Last Name"
                  error={error}
                />
              );
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Field
            name="middleName"
            render={({ field }) => {
              return (
                <EditInputWithLabel
                  label="Middle Name"
                  width="326px"
                  name="middleName"
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter Middle Name"
                />
              );
            }}
          />
          <Field
            name="suffix"
            render={({ field }) => {
              return (
                <EditInputWithLabel
                  label="Suffix"
                  width="326px"
                  name="suffix"
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter suffix"
                />
              );
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Field
            name="userName"
            isMandatory
            render={({ field, error }) => {
              return (
                <EditInputWithLabel
                  label="User Name"
                  width="326px"
                  name="userName"
                  value={field.value}
                  onChange={field.onChange}
                  isMandatory
                  placeholder="Enter User Name"
                  error={error}
                />
              );
            }}
          />
          <Field
            name="email"
            isMandatory
            render={({ field, error }) => {
              return (
                <EditInputWithLabel
                  label="Email"
                  width="326px"
                  name="email"
                  value={field.value}
                  onChange={field.onChange}
                  isMandatory
                  placeholder="Enter Email"
                  error={error}
                />
              );
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Field
            name="primaryState"
            isMandatory
            render={({ field, onCustomChange, error }) => (
              <Dropdown
                value={field.value}
                isMandatory
                label="Primary State"
                width="326px"
                isColumn
                placeholder="Select Primary State"
                options={states}
                onChange={onCustomChange(field.onChange)}
                isDisabled={states?.length < 2}
                error={error}
                columnControlStyles={{ opacity: states?.length < 2 ? 0.5 : 1 }}
              />
            )}
          />
          <Field
            name="landingPage"
            isMandatory
            render={({ field, onCustomChange, error }) => (
              <Dropdown
                value={field.value}
                isMandatory
                label="Landing Page"
                width="326px"
                isColumn
                placeholder="Select Landing Page"
                options={pagesOptions}
                onChange={onCustomChange(field.onChange)}
                menuPosition="fixed"
                menuPlacement="bottom"
                error={error}
              />
            )}
          />
        </Stack>
      </Box>
    </Box>
  );
};

Profile.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    watch: PropTypes.func,
    setValue: PropTypes.func,
  }).isRequired,
};

export default Profile;
