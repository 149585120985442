import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MDialog from '../../../atoms/MDialog';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import MButton from '../../../MUI/Button/MButton';

const RedateDialog = ({ isOpen, onClose, onConfirm }) => {
  const [redateDate, setRedateDate] = useState(moment().startOf('day'));

  const handleClosed = () => {
    setRedateDate(moment().startOf('day'));
  };

  return (
    <MDialog
      isOpen={isOpen}
      onClose={onClose}
      onClosed={handleClosed}
      title="Redate"
      maxWidth="xs"
      fullWidth
      footerActions={[
        <MButton variant="secondary" size="large" onClick={onClose}>
          Cancel
        </MButton>,
        <MButton
          type="submit"
          size="large"
          disabled={!redateDate}
          onClick={() => onConfirm(redateDate)}
        >
          Confirm
        </MButton>,
      ]}
    >
      <DateOrTimePicker
        isDate
        label="Date Served"
        placeholder="MM/DD/YYYY"
        height="48px"
        Svg={Calender}
        selected={redateDate}
        setSelectedTimeOrDate={setRedateDate}
      />
    </MDialog>
  );
};

RedateDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

RedateDialog.defaultProps = {};

export default RedateDialog;
