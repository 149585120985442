/* eslint-disable */
import React, { useEffect, useState } from 'react';
import MDialog from '../../../../atoms/MDialog';
import { Stack } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import Form from '../../../../atoms/Form';
import { useForm } from 'react-hook-form';
import Field from '../../../../atoms/Field';
import CustomRadioGroup from '../../../../atoms/CustomRadioGroup/CustomRadioGroup';
import EditInputWithLabel from '../../../../molecules/EditInputWithLabel';

const FORM_FIELDS = [
  {
    label: 'Animal',
    name: 'animal',
    isRadioButtons: true,
    radioButtonWidth: '100px',
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Type/Breed',
    placeholder: 'Type/Breed',
    name: 'typeBreed',
  },
  {
    label: 'Vicious',
    name: 'vicious',
    isRadioButtons: true,
    radioButtonWidth: '100px',
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Is the property still occupied?',
    name: 'isOccupied',
    isRadioButtons: true,
    radioButtonWidth: '100px',
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Is the property sublet?',
    name: 'isPropertySublet',
    isRadioButtons: true,
    radioButtonWidth: '100px',
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Has there been recent law enforcement response?',
    name: 'lawEnforcementResponse',
    isRadioButtons: true,
    radioButtonWidth: '100px',
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Are there known weapons inside the residence or is the person known to carry weapons?',
    name: 'isWeapon',
    isRadioButtons: true,
    radioButtonWidth: '100px',
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Do any occupants have a history of violence?',
    name: 'historyOfViolence',
    isRadioButtons: true,
    radioButtonWidth: '100px',
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Is there known drug activity on the property?',
    name: 'isDrugActivity',
    isRadioButtons: true,
    radioButtonWidth: '100px',
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false },
    ],
  },
];

const defaultValues = {
  animal: false,
  typeBreed: '',
  vicious: false,
  isOccupied: false,
  isPropertySublet: false,
  lawEnforcementResponse: false,
  isWeapon: false,
  historyOfViolence: false,
  isDrugActivity: false,
};

const ConstableInstructions = ({ isOpen, handleConfirm, handleClose }) => {
  const [isLoading, setLoading] = useState(false);

  const form = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (!isOpen) {
      form.reset(defaultValues);
    } else setLoading(false);
  }, [isOpen]);

  const handleSubmit = async (values) => {
    setLoading(true);

    await handleConfirm({
      IsAnimal: values.animal,
      TypeBreed: values.typeBreed,
      Vicious: values.vicious,
      IsOccupied: values.isOccupied,
      IsSublets: values.isPropertySublet,
      HasResponses: values.lawEnforcementResponse,
      HasWeapons: values.lawEnforcementResponse,
      HasViolance: values.historyOfViolence,
      HasDrugs: values.isDrugActivity,
    });
  };

  return (
    <MDialog
      onClose={handleClose}
      isOpen={isOpen}
      maxWidth="733px"
      fullWidth
      title="Constable Instructions"
      scrollable
    >
      <Form onSubmit={handleSubmit} form={form}>
        <Stack display="flex" flexWrap="wrap" flexDirection="row" columnGap="88px" rowGap={3}>
          {FORM_FIELDS?.map((item) => {
            if (item.isRadioButtons) {
              return (
                <Field
                  key={item.label}
                  name={item.name}
                  isMandatory={item.isMandatory}
                  render={({ field, onCustomChange }) => (
                    <CustomRadioGroup
                      data={item.data}
                      value={field.value}
                      label={item.label}
                      width={item.width}
                      radioButtonWidth={item.radioButtonWidth}
                      onChange={onCustomChange(field.onChange)}
                    />
                  )}
                />
              );
            }

            return (
              <Field
                key={item.label}
                name={item.name}
                isMandatory={item.isMandatory}
                render={({ field, error }) => (
                  <EditInputWithLabel
                    type="text"
                    error={error}
                    label={item.label}
                    placeholder={item.placeholder}
                    width={item.width}
                    name={item.name}
                    isMandatory={item.isMandatory}
                    value={field.value}
                    onChange={field.onChange}
                    isDisabled={item.isDisabled}
                  />
                )}
              />
            );
          })}
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
          <MButton variant="secondary" size="large" onClick={handleClose} sx={{ width: '104px' }}>
            Cancel
          </MButton>
          <MButton type="submit" size="large" sx={{ width: '104px' }} disabled={isLoading}>
            Create
          </MButton>
        </Stack>
      </Form>
    </MDialog>
  );
};

export default ConstableInstructions;
