export const subPageNavNew = [
  {
    label: 'Contact',
    to: '/db/datahandling/newcontact/contact',
    state: {
      navPage: 'Data Handling',
      subNav: 'Contacts',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/newcontact/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Contacts',
    },
  },
];

export const subPageNavEdit = [
  {
    label: 'Contact',
    to: '/db/datahandling/editcontact/contact',
    state: {
      navPage: 'Data Handling',
      subNav: 'Contacts',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/editcontact/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Contacts',
    },
  },
];
