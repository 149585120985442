import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import MDialog from '../../../atoms/MDialog';
import { ReactComponent as InfoCircleRed } from '../../../../assets/icons/InfoCircleRed.svg';
import MButton from '../../../MUI/Button/MButton';

const RedateErrorsDialog = ({ isOpen, onClose, onClosed, errors }) => {
  return (
    <MDialog
      isOpen={isOpen}
      onClose={onClose}
      onClosed={onClosed}
      title="Failed documents"
      maxWidth="md"
      fullWidth
      scrollable
      footerActions={
        <MButton size="large" onClick={onClose}>
          Close
        </MButton>
      }
    >
      <Stack rowGap={3}>
        {errors.map((e) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <span style={{ marginTop: '3px' }}>
              <InfoCircleRed style={{ flexShrink: 0 }} width={24} height={24} />
            </span>
            <Typography variant="bodyL500">{e}</Typography>
          </Stack>
        ))}
      </Stack>
    </MDialog>
  );
};

RedateErrorsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

RedateErrorsDialog.defaultProps = {};

export default RedateErrorsDialog;
