export const subPageHeading = 'New Case';
export const subPageNav = [
  {
    label: 'Parties & Precinct',
    to: '/db/processing/newcase/partiesandprecinct',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      subSubNavigation: 'New Case',
    },
  },
  {
    label: 'Case Information',
    to: '/db/processing/newcase/caseinformation',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      subSubNavigation: 'New Case',
    },
  },
  {
    label: 'Civil Matter',
    to: '/db/processing/newcase/civilmatter',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      subSubNavigation: 'New Case',
    },
  },
  {
    label: 'Document',
    to: '/db/processing/newcase/document',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      subSubNavigation: 'New Case',
    },
  },
  {
    label: 'Notes',
    to: '/db/processing/newcase/notes',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      subSubNavigation: 'New Case',
    },
  },
];
