import React from 'react';
import { Box, styled } from '@mui/material';
import PropTypes from 'prop-types';
import SearchLabel from '../SearchLabel/SearchLabel';
import DateOrTimePicker from '../DateOrTimePicker/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../assets/icons/Calender.svg';
import { useRandomId } from '../../../hooks/useRandomId';

const StyledSearchContainer = styled(Box)(({ height }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  height,
}));

const DatePickerWithLabel = ({
  label,
  dateFormat,
  name,
  onChangeValue,
  value,
  width,
  height,
  minDate,
  disabled,
  forbidManualInput,
  onEnter,
}) => {
  const handleChange = (data) => onChangeValue(data, name);

  const id = useRandomId();

  return (
    <StyledSearchContainer height={height}>
      <SearchLabel value={label} height={height} htmlFor={id} />
      <DateOrTimePicker
        id={id}
        disabled={disabled}
        minDate={minDate}
        name={name}
        isDate
        Svg={Calender}
        selected={value}
        width={width}
        height={height}
        borderRadius="0 10px 10px 0"
        dateFormat={dateFormat}
        setSelectedTimeOrDate={handleChange}
        forbidManualInput={forbidManualInput}
        onEnter={onEnter}
      />
    </StyledSearchContainer>
  );
};

DatePickerWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.string]),
  minDate: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  forbidManualInput: PropTypes.bool,
  onEnter: PropTypes.func,
};

DatePickerWithLabel.defaultProps = {
  dateFormat: 'mm/dd/yy',
  onChangeValue: () => {},
  value: null,
  minDate: '',
  width: '150px',
  height: '48px',
  disabled: false,
  forbidManualInput: false,
  onEnter: null,
};

export default DatePickerWithLabel;
