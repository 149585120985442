import { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';
import { ReactComponent as Accept } from '../../../../../assets/icons/accept.svg';
import { ReactComponent as Closed } from '../../../../../assets/icons/closed.svg';
import { ReactComponent as Edit } from '../../../../../assets/icons/Edit.svg';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';

const AddressOwnerEntityRefEditCell = ({
  row: { id, index } = {},
  setEditableRowIndex,
  editableRowIndex,
  cancelEditing,
  updateData,
  updateMyData,
  isActive,
}) => {
  const canUpdate = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);
  const handleClickEdit = useCallback(() => {
    setEditableRowIndex(index);
  }, [index, setEditableRowIndex]);

  const handleClickDeactivate = useCallback(() => {
    updateMyData(index);
  }, [id, updateMyData]);

  if (!canUpdate) return null;

  return editableRowIndex === index ? (
    <>
      <IconButton onClick={updateData}>
        <Accept />
      </IconButton>
      <IconButton onClick={cancelEditing}>
        <Closed />
      </IconButton>
    </>
  ) : (
    <>
      <IconButton onClick={handleClickEdit}>
        <Edit />
      </IconButton>
      {isActive && (
        <IconButton onClick={handleClickDeactivate}>
          <Closed />
        </IconButton>
      )}
    </>
  );
};

export default AddressOwnerEntityRefEditCell;

AddressOwnerEntityRefEditCell.propTypes = {
  row: PropTypes.shape({ id: PropTypes.number, index: PropTypes.number }).isRequired,
  setEditableRowIndex: PropTypes.func.isRequired,
  editableRowIndex: PropTypes.number.isRequired,
  cancelEditing: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  updateMyData: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};
