import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowLeftPagination } from '../../../assets/icons/ArrowLeftPagination.svg';
import { ReactComponent as ArrowRightPagination } from '../../../assets/icons/ArrowRightPagination.svg';
import styles from './TablePagination.module.scss';

const TablePagination = (props) => {
  const {
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    page,
    pageIndex,
    pageOptions,
    gotoPage,
    total,
    pageSize,
  } = props;

  return (
    <div className={styles.pagination} data-testid="table_pagination_wrapper">
      <div data-testid="table_pagination_amount_of_total">
        {pageIndex * pageSize + 1}-{pageIndex * pageSize + page.length} of {total}
      </div>
      <div className={styles.pageSelector} data-testid="page_selector">
        <button
          className={styles.arrowButton}
          type="button"
          onClick={previousPage}
          disabled={!canPreviousPage}
          data-testid="table_pagination_prev_btn"
        >
          <ArrowLeftPagination />
        </button>

        {
          pageOptions.reduce(
            (acc, item, i) => {
              const isNumbers =
                i === 0 ||
                i === pageOptions.length - 1 ||
                i === pageIndex ||
                i === pageIndex + 1 ||
                i === pageIndex - 1 ||
                i === pageIndex + 2 ||
                i === pageIndex - 2;

              if (isNumbers) {
                const isActive = pageIndex === i;
                acc.result.push(
                  <button
                    key={item}
                    className={isActive ? styles.activeButton : styles.numberButton}
                    type="button"
                    disabled={isActive}
                    onClick={() => gotoPage(item)}
                    data-testid="table_pagination_go_to_btn"
                  >
                    {item + 1}
                  </button>,
                );
                acc.lastWasDots = false;
              } else if (!acc.lastWasDots) {
                acc.result.push(<div key={item}>...</div>);
                acc.lastWasDots = true;
              }

              return acc;
            },
            { result: [], lastWasDots: false },
          ).result
        }

        <button
          className={styles.arrowButton}
          type="button"
          onClick={nextPage}
          disabled={!canNextPage}
          data-testid="table_pagination_next_btn"
        >
          <ArrowRightPagination />
        </button>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  previousPage: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  page: PropTypes.shape({ length: PropTypes.number }).isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    .isRequired,
  gotoPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default TablePagination;
