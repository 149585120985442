export const parseNumberFromString = (str, isFloat = true) => {
  const parse = isFloat ? parseFloat : (n) => parseInt(n, 10);
  const res = parse(str);
  if (Number.isNaN(res)) return null;
  return res;
};

export const amendZeros = (value) => {
  const stringifiedValue = typeof value === 'number' ? value.toString() : value;
  if (typeof stringifiedValue === 'string' && stringifiedValue !== '') {
    if (!/\./.test(stringifiedValue)) {
      return `${stringifiedValue}.00`;
    }
    if (!/\.\d{1}/.test(stringifiedValue)) {
      return `${stringifiedValue}00`;
    }
    if (!/\.\d{2}/.test(stringifiedValue)) {
      return `${stringifiedValue}0`;
    }
    return stringifiedValue;
  }
  return '';
};
