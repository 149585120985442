import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, styled, Stack, Typography } from '@mui/material';
import { beautifulDate } from '../../../utils/beautifulDate';
import { palette } from '../../../theme/default/palette';
import { stringAvatar } from '../../../utils/stringAvatar';

const sizeAvatar = 32;

const StyledStack = styled(Stack)({
  position: 'relative',

  '&:not(:last-child):before': {
    // eslint-disable-next-line
    content: '""',
    position: 'absolute',
    top: sizeAvatar,
    left: sizeAvatar,
    height: `calc(100% - ${sizeAvatar}px + 80px)`,
    width: 0,
    borderLeft: `1px dashed ${palette.additional.lines}`,
  },
});

const CommentNew = ({ createdAt, comment, name }) => {
  const { sx, children } = stringAvatar(name);

  const isJsonString = (str) => {
    try {
      const note = JSON.parse(str);
      return note;
    } catch (e) {
      return false;
    }
  };

  const note = isJsonString(comment);

  const renderNote = () => {
    if (!note) return null;

    switch (note.Type) {
      case 'case_status': {
        return (
          <>
            <Box>
              <Typography
                variant="bodyM"
                color="text.primary"
                whiteSpace="pre-wrap"
                sx={{ fontWeight: 600 }}
                data-testid="comment_text"
              >
                Status:
              </Typography>
              <Typography
                variant="bodyM"
                color="text.secondary"
                whiteSpace="pre-wrap"
                data-testid="comment_text"
                ml={0.5}
              >
                {note.Status || ''}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="bodyM"
                color="text.primary"
                whiteSpace="pre-wrap"
                sx={{ fontWeight: 600 }}
                data-testid="comment_text"
              >
                Comment:
              </Typography>
              <Typography
                variant="bodyM"
                color="text.secondary"
                whiteSpace="pre-wrap"
                data-testid="comment_text"
                ml={0.5}
              >
                {note.Comment || ''}
              </Typography>
            </Box>
          </>
        );
      }
      default: {
        return (
          <>
            <Box>
              <Typography
                variant="bodyM"
                color="text.primary"
                whiteSpace="pre-wrap"
                sx={{ fontWeight: 600 }}
                data-testid="comment_text"
              >
                Handling Attorney:
              </Typography>
              <Typography
                variant="bodyM"
                color="text.secondary"
                whiteSpace="pre-wrap"
                data-testid="comment_text"
                ml={0.5}
              >
                {note.HandlingAttorney || ''}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="bodyM"
                color="text.primary"
                whiteSpace="pre-wrap"
                data-testid="comment_text"
                sx={{ fontWeight: 600 }}
              >
                Deadline:
              </Typography>
              <Typography
                variant="bodyM"
                color="text.secondary"
                whiteSpace="pre-wrap"
                data-testid="comment_text"
                ml={0.5}
              >
                {note.Deadline || ''}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="bodyM"
                color="text.primary"
                whiteSpace="pre-wrap"
                data-testid="comment_text"
                sx={{ fontWeight: 600 }}
              >
                Due Date:
              </Typography>
              <Typography
                variant="bodyM"
                color="text.secondary"
                whiteSpace="pre-wrap"
                data-testid="comment_text"
                ml={0.5}
              >
                {note.DueDate || ''}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="bodyM"
                color="text.primary"
                whiteSpace="pre-wrap"
                data-testid="comment_text"
                sx={{ fontWeight: 600 }}
              >
                Description:
              </Typography>
              <Typography
                variant="bodyM"
                color="text.secondary"
                whiteSpace="pre-wrap"
                data-testid="comment_text"
                ml={0.5}
              >
                {note.Description || ''}
              </Typography>
            </Box>
          </>
        );
      }
    }
  };

  return (
    <StyledStack>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Avatar sx={{ width: sizeAvatar, height: sizeAvatar, fontSize: 14, ...sx }}>
            {children}
          </Avatar>
          <Typography variant="bodyL500" color="text.primary" data-testid="comment_name">
            {name}
          </Typography>
        </Stack>
        <span>
          <Typography variant="bodyS" color="text.primary">
            Creation Date:&nbsp;
          </Typography>
          <Typography variant="bodyS" color="text.secondary" data-testid="comment_created_at">
            {beautifulDate(createdAt)}
          </Typography>
        </span>
      </Stack>
      <Box backgroundColor="text.disabled" padding={2} marginLeft={11}>
        {note ? (
          renderNote()
        ) : (
          <Typography
            variant="bodyM"
            color="text.primary"
            whiteSpace="pre-wrap"
            data-testid="comment_text"
          >
            {comment}
          </Typography>
        )}
      </Box>
    </StyledStack>
  );
};

CommentNew.propTypes = {
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

export default CommentNew;
