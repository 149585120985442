import React from 'react';
import PropTypes from 'prop-types';
import TopNavBar from '../../organisms/TopNavBar';

import LogoutTimer from '../../organisms/LogoutTimer';

const Layout = ({ component: Component, base }) => {
  return (
    <>
      <TopNavBar />
      <main
        style={{
          height: 'calc(100vh - 80px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Component base={base} />
      </main>
      <LogoutTimer />
    </>
  );
};

Layout.propTypes = {
  component: PropTypes.node.isRequired,
  base: PropTypes.string,
};

Layout.defaultProps = {
  base: '',
};

export default Layout;
