import { styled } from '@mui/material';
import ClientPortalNonPaymentNoticeEditableCell from './TableCells/ClientPortalNonPaymentNoticeEditableCell';
import NonPaymentDefendantNameCell from './TableCells/NonPaymentDefendantNameCell';
import { InvalidCell } from '../../atoms/Table/TableCells/InvalidCell';
import { NonPaymentTextInputCell } from '../../atoms/Table/TableCells/NonPaymentTableCells/NonPaymentTextInputCell';
import ApartmentInput from './TableCells/ApartmentInput';
import AddressCell from './TableCells/AddressCell';
import CheckboxCell from './TableCells/CheckboxCell';
import DollarCell from './TableCells/DollarCell';
import DateCell from './TableCells/DateCell';
import EditableCell from './TableCells/EditableCell';
import { ReactComponent as Asterisk } from '../../../assets/icons/asterisk.svg';
import { palette } from '../../../theme/default';
import InputWithTooltipCell from './TableCells/InputWithTooltipCell';

export const OpposingPartyBoxes = [
  {
    boxTitle: 'Opposing Party',
    name: 'opposingParty',
    inputBoxes: [
      {
        label: 'Individual(s)',
        isSubHeading: true,
      },
      {
        label: 'First Name',
        name: 'individual.firstName',
        placeholder: 'Enter first name',
        width: '247px',
        isMandatory: true,
      },
      {
        label: 'Middle Name',
        name: 'individual.middleName',
        placeholder: 'Enter middle name',
        width: '247px',
        isMandatory: false,
      },
      {
        label: 'Last Name',
        name: 'individual.lastName',
        placeholder: 'Enter last name',
        width: '247px',
        isMandatory: true,
      },
      {
        label: 'Suffix',
        name: 'individual.suffix',
        placeholder: 'Enter suffix',
        width: '147px',
        isMandatory: false,
      },
      {
        isAddButtonWithTable: true,
        table: 'individualTable',
      },
    ],
  },
];
export const clientPortalNonPaymentNoticeDialogBoxes = [
  {
    boxTitle: 'Opposing Party',
    name: 'opposingParty',
    inputBoxes: [
      {
        label: 'Individual(s)',
        isSubHeading: true,
      },
      {
        label: 'First Name',
        name: 'individual.firstName',
        placeholder: 'Enter first name',
        width: '247px',
        isMandatory: true,
      },
      {
        label: 'Middle Name',
        name: 'individual.middleName',
        placeholder: 'Enter middle name',
        width: '247px',
        isMandatory: false,
      },
      {
        label: 'Last Name',
        name: 'individual.lastName',
        placeholder: 'Enter last name',
        width: '247px',
        isMandatory: true,
      },
      {
        label: 'Suffix',
        name: 'individual.suffix',
        placeholder: 'Enter suffix',
        width: '147px',
        isMandatory: false,
      },
      {
        isAddButtonWithTable: true,
        table: 'individualTable',
      },
    ],
  },
  {
    boxTitle: 'Address',
    name: 'address',
    inputBoxes: [
      {
        label: 'Address',
        name: 'address.address',
        placeholder: 'Enter Address',
        width: '247px',
        isDropdown: true,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
      },
      {
        label: 'Apartment',
        name: 'address.apartmentNumber',
        placeholder: 'XXXXXXXX',
        width: '247px',
        isDropdown: false,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: false,
      },
      {
        label: 'No apartment number',
        name: 'address.hasNoAppartmentNumber',
        placeholder: 'No apartment number',
        width: '210px',
        isDropdown: false,
        isCheckbox: true,
        isRadioButton: false,
        isMandatory: false,
      },
    ],
  },
  {
    boxTitle: 'Dates & Amounts',
    name: 'datesAmounts',
    inputBoxes: [
      {
        label: 'Late Fee Assessed',
        name: 'datesAmounts.lateFeeAssessed',
        placeholder: 'mm/dd/yyyy',
        width: '247px',
        height: '48px',
        isDropdown: false,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
        isDate: true,
      },
      {
        label: 'Periodic Rent',
        name: 'datesAmounts.periodicRent',
        placeholder: 'Enter periodic rent',
        width: '247px',
        isDropdown: false,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
      },
      {
        label: 'Rent Became Delinquent',
        name: 'datesAmounts.rentBecameDelinquent',
        placeholder: 'mm/dd/yyyy',
        width: '247px',
        height: '48px',
        isDropdown: false,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
        isDate: true,
      },
      {
        isSpace: true,
        label: 'spaceAfterZipCode',
      },
      {
        label: 'Current Amount Due',
        name: 'datesAmounts.currentAmountDue',
        placeholder: '0',
        width: '247px',
        isDropdown: false,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
      },
      {
        label: 'Past Amount Due',
        name: 'datesAmounts.pastAmountDue',
        placeholder: '0',
        width: '247px',
        isDropdown: false,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
      },
      {
        label: 'Late Fees',
        name: 'datesAmounts.lateFees',
        placeholder: '0',
        width: '247px',
        isDropdown: false,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
      },
      {
        isSpace: true,
        label: 'spaceAfterZipCode',
      },
      {
        label: 'Last Payment Date',
        name: 'datesAmounts.lastPaymentDate',
        placeholder: 'mm/dd/yyyy',
        width: '247px',
        height: '48px',
        isDropdown: false,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
        isDate: true,
      },
      {
        label: 'Last Payment Amount',
        name: 'datesAmounts.lastPaymentAmount',
        placeholder: '0',
        width: '247px',
        isDropdown: false,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
      },
      {
        isSpace: true,
        label: 'spaceAfterZipCode',
      },
      {
        label: 'Total Owed',
        name: 'datesAmounts.totalOwed',
        placeholder: '0',
        width: '247px',
        isDropdown: false,
        isCheckbox: false,
        isRadioButton: false,
        isMandatory: true,
        isDisabled: true,
        isCalculator: true,
      },
    ],
  },
];

export const getNonPaymentNoticeColumns = ({
  showErrorColumn,
  updateValue,
  handleMenuScrollToBottom,
  changeInput,
  onOpenAddressesMenu,
}) => [
  ...(showErrorColumn
    ? [
        {
          Header: 'Valid',
          accessor: '__error',
          sticky: 'left',
          width: 10,
          minWidth: 54,
          Cell: InvalidCell,
        },
      ]
    : []),
  {
    Header: 'Opposing Party Name',
    accessor: 'defendantName',
    sticky: 'left',
    width: 10,
    minWidth: 200,
    Cell: (props) => (
      <NonPaymentDefendantNameCell {...props} updateValue={updateValue} isMandatory />
    ),
  },
  {
    Header: 'Address',
    accessor: 'addressName',
    width: 10,
    minWidth: 200,
    Cell: (props) => (
      <AddressCell
        {...props}
        updateValue={updateValue}
        handleMenuScrollToBottom={handleMenuScrollToBottom}
        changeInput={changeInput}
        isMandatory
        onOpenAddressesMenu={onOpenAddressesMenu}
      />
    ),
  },
  {
    Header: 'Apartment',
    accessor: 'apartmentNumber',
    isMandatory: true,
    width: 10,
    minWidth: 140,
    Cell: (props) => <ApartmentInput {...props} updateValue={updateValue} />,
  },
  {
    Header: 'No Apartment Number',
    accessor: 'hasNoAppartmentNumber',
    isMandatory: true,
    width: 5,
    minWidth: 100,
    Cell: (props) => <CheckboxCell {...props} updateValue={updateValue} />,
  },
  {
    Header: 'Late Fee Assessed',
    accessor: 'lateFeeAssessed',
    width: 15,
    minWidth: 180,
    Cell: (props) => <DateCell {...props} updateValue={updateValue} isMandatory />,
  },
  {
    Header: 'Periodic Rent',
    accessor: 'periodicRent',
    width: 10,
    minWidth: 140,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: 'Rent Became Delinquent',
    accessor: 'rentBecameDelinquent',
    width: 10,
    minWidth: 180,
    Cell: (props) => <DateCell {...props} updateValue={updateValue} isMandatory />,
  },
  {
    Header: 'Current Amount Due',
    accessor: 'currentAmountDue',
    width: 10,
    minWidth: 140,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: 'Past Amount Due',
    accessor: 'pastAmountDue',
    width: 10,
    minWidth: 140,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: 'Late Fees',
    accessor: 'lateFees',
    width: 10,
    minWidth: 140,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: 'Last Payment Date',
    accessor: 'lastPaymentDate',
    width: 10,
    minWidth: 180,
    Cell: (props) => <DateCell {...props} updateValue={updateValue} />,
  },
  {
    Header: 'Last Payment Amount',
    accessor: 'lastPaymentAmount',
    width: 10,
    minWidth: 140,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: 'Total Owed',
    accessor: 'totalOwed',
    width: 5,
    minWidth: 150,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} isMandatory />,
  },
  {
    Header: '',
    accessor: 'control',
    Cell: ClientPortalNonPaymentNoticeEditableCell,
    sticky: 'right',
    width: 5,
    minWidth: 60,
  },
];

const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

export const getNonPaymentNoticeColumnsAZ = ({
  showErrorColumn,
  updateValue,
  handleMenuScrollToBottom,
  changeInput,
  onOpenAddressesMenu,
}) => [
  ...(showErrorColumn
    ? [
        {
          Header: <StyledSpan>Valid</StyledSpan>,
          accessor: '__error',
          sticky: 'left',
          width: 10,
          minWidth: 54,
          Cell: InvalidCell,
        },
      ]
    : []),
  {
    Header: (
      <StyledSpan>
        Opposing Party
        <Asterisk />
      </StyledSpan>
    ),
    accessor: 'defendantName',
    sticky: 'left',
    width: 10,
    minWidth: 200,
    Cell: (props) => <NonPaymentDefendantNameCell {...props} updateValue={updateValue} />,
  },
  {
    Header: (
      <StyledSpan>
        Address
        <Asterisk />
      </StyledSpan>
    ),
    accessor: 'addressName',
    width: 10,
    minWidth: 200,
    Cell: (props) => (
      <AddressCell
        {...props}
        updateValue={updateValue}
        handleMenuScrollToBottom={handleMenuScrollToBottom}
        changeInput={changeInput}
        onOpenAddressesMenu={onOpenAddressesMenu}
      />
    ),
  },
  {
    Header: (
      <StyledSpan>
        Apartment
        <Asterisk />
      </StyledSpan>
    ),
    accessor: 'apartmentNumber',
    isMandatory: true,
    width: 10,
    minWidth: 140,
    Cell: (props) => <ApartmentInput {...props} updateValue={updateValue} />,
  },
  {
    Header: <StyledSpan>No Apartment Number</StyledSpan>,
    accessor: 'hasNoAppartmentNumber',
    isMandatory: true,
    width: 5,
    minWidth: 80,
    Cell: (props) => <CheckboxCell {...props} updateValue={updateValue} />,
  },
  {
    Header: (
      <StyledSpan>
        Rent (Per Lease Agreement)
        <Asterisk />
      </StyledSpan>
    ),
    accessor: 'rentPerLeaseAgreement',
    width: 10,
    minWidth: 120,
    isMandatory: true,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} isMandatory />,
  },
  {
    Header: <StyledSpan>Total Late Fee</StyledSpan>,
    accessor: 'totalLateFee',
    width: 10,
    minWidth: 140,
    isMandatory: false,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: <StyledSpan>Daily Late Fee</StyledSpan>,
    accessor: 'dailyLateFee',
    width: 10,
    minWidth: 140,
    isMandatory: false,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: (
      <StyledSpan>
        Previous Balance / Credit
        <Asterisk />
      </StyledSpan>
    ),
    accessor: 'previousBalance',
    width: 10,
    minWidth: 140,
    isMandatory: true,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} isMandatory />,
  },
  {
    Header: <StyledSpan>Utilities</StyledSpan>,
    accessor: 'utilities',
    width: 10,
    minWidth: 180,
    isMandatory: false,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: <StyledSpan>NSF Charges (If Applicable)</StyledSpan>,
    accessor: 'nsfFee',
    width: 10,
    minWidth: 140,
    isMandatory: false,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: <StyledSpan>Notice Preperation / Service Fee</StyledSpan>,
    accessor: 'noticeFee',
    width: 10,
    minWidth: 140,
    isMandatory: false,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: <StyledSpan>Other Charges</StyledSpan>,
    accessor: 'otherCharges',
    width: 10,
    minWidth: 140,
    isMandatory: false,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: <StyledSpan>Other Charges Description</StyledSpan>,
    accessor: 'otherChargesDescription',
    width: 10,
    minWidth: 200,
    isMandatory: false,
    Cell: (props) => <InputWithTooltipCell {...props} updateValue={updateValue} />,
  },
  {
    Header: <StyledSpan>Total Due</StyledSpan>,
    accessor: 'totalDue',
    width: 5,
    minWidth: 150,
    Cell: (props) => <DollarCell {...props} updateValue={updateValue} />,
  },
  {
    Header: '',
    accessor: 'control',
    Cell: ClientPortalNonPaymentNoticeEditableCell,
    sticky: 'right',
    width: 5,
    minWidth: 60,
  },
];

export const INDIVIDUALS_COLUMNS = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 25,
    edit: true,
    isMandatory: true,
    Cell: NonPaymentTextInputCell,
  },
  {
    Header: 'Middle Name',
    accessor: 'middleName',
    width: 25,
    edit: true,
    Cell: NonPaymentTextInputCell,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 25,
    edit: true,
    isMandatory: true,
    Cell: NonPaymentTextInputCell,
  },
  {
    Header: 'Suffix',
    accessor: 'suffix',
    width: 20,
    edit: true,
    Cell: NonPaymentTextInputCell,
  },
  {
    Header: '',
    width: 5,
    minWidth: 75,
    accessor: 'control',
    Cell: EditableCell,
  },
];
