import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ViewCase from '../../../components/pages/Processing/ViewCase';
import CasesProvider from '../../../services/CasesProvider';
import CaseSearch from '../../../components/pages/Processing/CaseSearch';
import Processing from '../../../components/pages/Processing/Processing/Processing';
import PartiesAndPrecinctNonEditable from '../../../components/pages/Processing/PartiesAndPrecinct/PartiesAndPrecinctNonEditable';
import CaseInformationView from '../../../components/pages/Processing/CaseInformationView/CaseInformationView';
import Document from '../../../components/pages/Processing/Document/Document';
import RequestsNevada from '../../../components/pages/Processing/RequestsNevada';
import RequestEvictionNevada from '../../../components/pages/Processing/RequestsNevada/RequestEvictionNevada';
import RequestsArizona from '../../../components/pages/Processing/RequestsArizona';
import OpenCaseByLink from '../../../components/pages/Processing/OpenCaseByLink';
import CivilMatterView from '../../../components/pages/Processing/CivilMatterView';

const ClientPortalCasesRoutes = () => (
  <Routes>
    <Route
      path="*"
      element={
        <CasesProvider>
          <Processing />
        </CasesProvider>
      }
    >
      <Route path="casesearch" element={<CaseSearch isCP />} />
      <Route path="case/:id" element={<OpenCaseByLink />} />
      <Route path="viewcase" element={<ViewCase />}>
        <Route path="partiesandprecinct" element={<PartiesAndPrecinctNonEditable />} />
        <Route path="caseinformation" element={<CaseInformationView />} />
        <Route path="civilmatter" element={<CivilMatterView />} />
        <Route path="document" element={<Document isDB={false} />} />
        <Route path="requests" element={<RequestsNevada />} />
        <Route path="requests/requesteviction" element={<RequestEvictionNevada />} />
        <Route path="requestsarizona" element={<RequestsArizona />} />
      </Route>
    </Route>
  </Routes>
);

export default ClientPortalCasesRoutes;
