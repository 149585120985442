import React, { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';

import { ReactComponent as Trash } from '../../../../assets/icons/Trash.svg';

export const DeleteCell = ({
  row: { id, original },
  deleteRow,
  value: { hideDelete = false, autoRefresh = false } = {},
  rows,
  refreshPage,
  goPreviousPage,
  canPreviousPage,
}) => {
  const handleClickDelete = useCallback(() => {
    deleteRow(id, original)?.then(() => {
      if (autoRefresh) {
        if (rows.length === 1 && canPreviousPage) {
          goPreviousPage();
        } else {
          refreshPage();
        }
      }
    });
  }, [id, deleteRow]);

  return (
    <IconButton
      onClick={handleClickDelete}
      sx={{ visibility: hideDelete ? 'hidden' : 'visible' }}
      aria-label="Delete"
      data-testid="delete_button"
    >
      <Trash data-testid="trash_icon" />
    </IconButton>
  );
};

DeleteCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    original: PropTypes.shape({}),
  }).isRequired,
  deleteRow: PropTypes.func.isRequired,
  value: PropTypes.shape({
    hideDelete: PropTypes.bool,
    autoRefresh: PropTypes.bool,
  }),
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refreshPage: PropTypes.func.isRequired,
  goPreviousPage: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
};

DeleteCell.defaultProps = {
  value: {
    hideDelete: false,
    autoRefresh: false,
  },
};
