import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import Table from '../../../../atoms/Table/Table';
import Searcher from '../../../../molecules/Searcher';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import {
  ManagementAddressColumns,
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
} from './ManagementAddresses.constants';
import { getManagementAddresses } from '../../../../../store/slices/editManagementSlice';
import StateLink from '../../../../atoms/StateLink/StateLink';

const ManagementAddresses = () => {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const {
    state: { id },
  } = useLocation();
  const { managementAddresses } = useSelector((state) => state.editManagement);

  useEffect(() => {
    dispatch(getManagementAddresses({ id, currentPage: 1, pageSize: 10 })).then(() =>
      setIsLoader(false),
    );
  }, [id]);

  const handleChangeEmpty = useCallback((searchElement) => {
    if (!searchElement) {
      setIsLoaderTable(true);
      dispatch(
        getManagementAddresses({
          id,
          currentPage: 1,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          address: searchElement,
        }),
      ).then(() => {
        setIsLoaderTable(false);
        setSearchValue('');
      });
    }
  }, []);

  const handleSearch = useCallback((searchElement) => {
    setSearchValue(searchElement);
    setIsLoaderTable(true);
    dispatch(
      getManagementAddresses({
        id,
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        address: searchElement,
      }),
    ).then(() => {
      setIsLoaderTable(false);
    });
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      dispatch(
        getManagementAddresses({
          id,
          currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          address: searchValue,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue],
  );

  const rowsAdapter = managementAddresses?.items?.map((item) => ({
    ...item,
    address: (
      <StateLink
        to="/db/datahandling/editaddress/address"
        state={{
          navPage: 'Data Handling',
          subNav: 'Addresses',
          id: item.addressId,
          name: item.address,
          state: { value: item.stateCode },
        }}
        alwaysOpenInNewTab
      >
        <Typography
          variant="link"
          color="text.primary"
          sx={{
            cursor: 'pointer',
            textDecorationLine: 'underline',
            textTransform: 'none',
          }}
        >
          {item.address}
        </Typography>
      </StateLink>
    ),
    control: {
      hideDelete: true,
      autoRefresh: true,
    },
  }));

  if (isLoader)
    return (
      <Box position="absolute" top="50vh" left="50%" zIndex={10} transform="translate(-50%, -50%)">
        <CircularProgress />
      </Box>
    );

  if (!isLoader && !searchValue && !managementAddresses?.items?.length) {
    return (
      <Stack height="calc(100vh - 80px - 72px)" direction="column">
        <Box
          margin="auto"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <EmptyBlock title="You don’t have any management addresses  yet" />
        </Box>
      </Stack>
    );
  }

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        px: 4,
        maxWidth: 1066,
        width: 1,
        pb: 18,
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h5">Management Addresses</Typography>
        <Searcher
          inputPlaceholder="Search by Address"
          onSearch={handleSearch}
          onChange={handleChangeEmpty}
        />
        {!!managementAddresses?.items?.length && !isLoader && (
          <Table
            columns={ManagementAddressColumns}
            rows={rowsAdapter}
            total={managementAddresses?.totalRowsCount}
            isPagination={managementAddresses?.totalPages > 1}
            onNextPage={handleFetchDataPage}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            loading={isLoaderTable}
          />
        )}
        {!managementAddresses?.items?.length && !isLoader && searchValue && (
          <Stack direction="column" paddingTop="50px">
            <Box margin="auto">
              <EmptyBlock
                title="Management addresses not found"
                desc="Сhange the search value"
                margin="auto"
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default ManagementAddresses;
