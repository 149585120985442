import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import ShadowBlock from '../../atoms/ShadowBlock';
import { ReactComponent as DocumentText } from '../../../assets/icons/DocumentText.svg';
import { ReactComponent as DocumentCopy } from '../../../assets/icons/DocumentCopy.svg';
import { palette } from '../../../theme/default';

const StyledWrapper = ({ title, icon, to, navPage, subNav, subSubNavigation }) => {
  return (
    <Stack
      component={Link}
      to={to}
      state={{
        subSubNavigation,
        navPage,
        subNav,
      }}
      sx={{ textDecoration: 'none' }}
      width="calc((100% - 24px)/2)"
    >
      <ShadowBlock>
        <Stack alignItems="center" spacing={3}>
          <Typography variant="h5" color="text.secondary" align="center" whiteSpace="pre-line">
            {title}
          </Typography>
          {icon}
        </Stack>
      </ShadowBlock>
    </Stack>
  );
};

const ClientPortalNotiesRequestsNevada = () => {
  return (
    <Stack spacing={3} direction="row" justifyContent="center" alignItems="stretch">
      <StyledWrapper
        title={`Request\nNon-Payment Notice`}
        icon={<DocumentText color={palette.buttonPrimary.active} />}
        to="non_payment_notice/NV"
        navPage="Requests"
        subNav="Notice Requests"
        subSubNavigation="Non-Payment Notices"
      />
      <StyledWrapper
        title={`Request Other\nThan Notice`}
        icon={<DocumentCopy color={palette.buttonPrimary.active} />}
        to="noticeintakeform/NV"
      />
    </Stack>
  );
};

StyledWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  navPage: PropTypes.string.isRequired,
  subNav: PropTypes.string.isRequired,
  subSubNavigation: PropTypes.string,
  icon: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

StyledWrapper.defaultProps = {
  subSubNavigation: '',
};

export default ClientPortalNotiesRequestsNevada;
