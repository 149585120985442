import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { formatUSD } from '../../../../../utils/formatCurrency';
import { formatHours } from '../../../../../utils/formatHours';

const BillableCell = ({ value, row }) => {
  const billableTotal = Number(row?.original?.moneyBillable) || 0;
  const billableQuantityTotal = Number(row?.original?.timeBillable) || 0;

  if (row?.original?.isTotalRow) {
    return (
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          textAlign: 'right',
          width: '100%',
        }}
      >
        {formatUSD(billableTotal)}
        <br />
        {formatHours(billableQuantityTotal)}
      </Typography>
    );
  }

  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
        textAlign: 'right',
        width: '100%',
      }}
    >
      {value ? formatUSD(value) : null}
    </Typography>
  );
};

BillableCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      moneyBillable: PropTypes.string,
      timeBillable: PropTypes.string,
      isTotalRow: PropTypes.bool,
    }),
  }).isRequired,
};

BillableCell.defaultProps = {
  value: '',
};

export default BillableCell;
