import DownloadVersionCell from './TableCells/DownloadVersionCell';
import MakeActiveCell from './TableCells/MakeActiveCell';
import ActiveCell from './TableCells/ActiveCell';
import DateCell from './TableCells/DateCell';
import { DeleteCell } from '../../atoms/Table/TableCells/DeleteCell';

export const historyColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    width: 35,
  },
  {
    Header: 'Version #',
    accessor: 'version',
    width: 11,
  },
  {
    Header: 'Work #',
    accessor: 'contactWorkPhone',
    width: 15,
  },
  {
    Header: 'Uploaded Date',
    accessor: 'uploadedDate',
    width: 15,
    Cell: DateCell,
  },
  {
    Header: 'Active',
    accessor: 'isActive',
    width: 10,
    Cell: ActiveCell,
  },
  {
    Header: '',
    accessor: 'makeActive',
    width: 10,
    Cell: MakeActiveCell,
  },
  {
    Header: '',
    accessor: 'download',
    width: 4,
    Cell: DownloadVersionCell,
  },
];

export const uploadDocumentsColumns = [
  {
    Header: 'Document Name',
    accessor: 'name',
    width: 94,
  },
  {
    Header: '',
    width: 6,
    accessor: 'control',
    Cell: DeleteCell,
  },
];
