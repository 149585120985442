import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import Table from '../../../../atoms/Table/Table';
import Searcher from '../../../../molecules/Searcher';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import { getCombinedContacts } from '../../../../../store/slices/contactsSlice';
import { deleteContact } from '../../../../../store/slices/editContactSlice';
import { ContactsColumns, MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './Contacts.constants';
import MButton from '../../../../MUI/Button/MButton';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';
import useSafeLocationState from '../../../../../hooks/useSafeLocationState';
import StateLink from '../../../../atoms/StateLink/StateLink';
import BottomBar from '../../../../atoms/BottomBar/BottomBar';
import notificationUtils from '../../../../../utils/notificationUtils';

const Contacts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const { navPage, subNav } = useSafeLocationState('Data Handling', 'Contacts');
  const contacts = useSelector((state) => state.contacts.combinedContacts);

  const canCreate = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);
  const canDelete = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);

  useEffect(() => {
    dispatch(getCombinedContacts({ pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() =>
      setIsLoader(false),
    );
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      dispatch(
        getCombinedContacts({
          currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          contactName: searchValue,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue],
  );

  const handleChangeEmpty = useCallback((searchElement) => {
    if (!searchElement) {
      setIsLoaderTable(true);
      dispatch(getCombinedContacts({ pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() => {
        setIsLoaderTable(false);
        setSearchValue('');
      });
    }
  }, []);

  const handleSearch = useCallback((searchElement) => {
    setSearchValue(searchElement);
    setIsLoaderTable(true);
    dispatch(getCombinedContacts({ contactName: searchElement })).then(() => {
      setIsLoaderTable(false);
    });
  }, []);

  const handleCreateContact = useCallback(() => {
    navigate('/db/datahandling/newcontact/contact', {
      state: {
        navPage,
        subNav,
      },
    });
  }, []);

  const handleDeleteContact = useCallback(
    (id, original) => {
      return dispatch(deleteContact(original.contactId))
        .unwrap()
        .catch((e) => {
          if (e?.errorCode && e.errorCode.startsWith('contact.used')) {
            notificationUtils.error(e.errorMessage);
          } else {
            notificationUtils.error('Unexpected error');
          }
        });
    },
    [searchValue],
  );

  const rowsAdapter = contacts?.items?.map((item) => ({
    ...item,
    contactName: (
      <StateLink
        to="/db/datahandling/editcontact/contact"
        state={{ navPage, subNav, contact: item, id: item?.contactId }}
      >
        <Typography
          variant="link"
          color="text.primary"
          sx={{
            cursor: 'pointer',
            textDecorationLine: 'underline',
            textTransform: 'none',
          }}
        >
          {item.contactName}
        </Typography>
      </StateLink>
    ),
    control: {
      hideDelete: !canDelete || !item?.contactId,
      autoRefresh: true,
    },
  }));

  if (!isLoader && !rowsAdapter?.length && !searchValue) {
    return (
      <Stack height="calc(100vh - 80px - 72px)" direction="column">
        <Typography variant="h3" margin="0 32px">
          Contacts Search
        </Typography>
        <Box margin="auto">
          <EmptyBlock
            title="You don’t have any contacts yet"
            desc={
              canCreate
                ? 'Every contact you create will appear here. To get started click on “Create new contact” button below'
                : ''
            }
            buttonText={canCreate ? 'Create new contact' : ''}
            onClick={canCreate ? handleCreateContact : undefined}
          />
        </Box>
      </Stack>
    );
  }

  return (rowsAdapter?.length && !isLoader) || searchValue ? (
    <>
      <Box width="1066px" marginX="auto" pb={18}>
        <Stack spacing={3}>
          <Typography variant="h5">Contacts</Typography>
          <Searcher
            inputPlaceholder="Search by Contact Name"
            onSearch={handleSearch}
            onChange={handleChangeEmpty}
          />
          {!isLoader && !rowsAdapter?.length && searchValue ? (
            <Box paddingTop="100px" display="flex" justifyContent="center">
              <EmptyBlock
                title="Contacts are not found"
                desc="Сhange the search value"
                margin="auto"
              />
            </Box>
          ) : (
            <Table
              columns={ContactsColumns}
              rows={rowsAdapter}
              total={contacts?.totalRowsCount}
              isPagination={contacts?.totalPages > 1}
              onNextPage={handleFetchDataPage}
              onPreviousPage={handleFetchDataPage}
              onGotoPage={handleFetchDataPage}
              pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
              onDeleteRow={handleDeleteContact}
              onRefreshPage={handleFetchDataPage}
              loading={isLoaderTable}
            />
          )}
        </Stack>
      </Box>
      {canCreate && (
        <BottomBar>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton width={144} size="large" onClick={handleCreateContact}>
              Create New
            </MButton>
          </Stack>
        </BottomBar>
      )}
    </>
  ) : (
    <Box position="absolute" top="50vh" left="50%" zIndex={10} transform="translate(-50%, -50%)">
      <CircularProgress />
    </Box>
  );
};

export default Contacts;
