import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CaseSearch from '../../../components/pages/Processing/CaseSearch';
import EditCase from '../../../components/pages/Processing/EditCase';
import CasesProvider from '../../../services/CasesProvider';
import Processing from '../../../components/pages/Processing/Processing/Processing';
import PartiesAndPrecinct from '../../../components/pages/Processing/PartiesAndPrecinct';
import CaseInformationNevada from '../../../components/pages/Processing/CaseInformationNevada';
import Document from '../../../components/pages/Processing/Document';
import CaseNotes from '../../../components/pages/Processing/CaseNotes';
import NewCase from '../../../components/pages/Processing/NewCase/NewCase';
import NewCivilMatterCase from '../../../components/pages/Processing/NewCivilMatterCase';
import CaseInformationArizona from '../../../components/pages/Processing/CaseInformationArizona';
import CivilMatterCaseInformationArizona from '../../../components/pages/Processing/CivilMatterCaseInformationArizona';
import CivilMatterCaseInformationNevada from '../../../components/pages/Processing/CivilMatterCaseInformationNevada';
import CivilMattersArizona from '../../../components/pages/Processing/CivilMattersArizona';
import CivilMattersNevada from '../../../components/pages/Processing/CivilMattersNevada';

import Notices from '../../../components/pages/Processing/Notices';
import BulkDocumentPrinting from '../../../components/pages/Processing/BulkDocumentPrinting/BulkDocumentPrinting';
import OpenCaseByLink from '../../../components/pages/Processing/OpenCaseByLink';

const DataBaseProcessingRoutes = () => (
  <Routes>
    <Route
      path="*"
      element={
        <CasesProvider>
          <Processing />
        </CasesProvider>
      }
    >
      <Route path="casesearch" element={<CaseSearch isDB />} />

      <Route path="case/:id" element={<OpenCaseByLink />} />

      <Route path="editcase" element={<EditCase />}>
        <Route path="partiesandprecinct" element={<PartiesAndPrecinct />} />
        <Route path="caseinformationnevada" element={<CaseInformationNevada />} />
        <Route path="caseinformationarizona" element={<CaseInformationArizona />} />
        <Route
          path="civilmatterscaseinformationarizona"
          element={<CivilMatterCaseInformationArizona />}
        />
        <Route
          path="civilmatterscaseinformationnevada"
          element={<CivilMatterCaseInformationNevada />}
        />
        <Route path="civilmattersarizona" element={<CivilMattersArizona />} />
        <Route path="civilmattersnevada" element={<CivilMattersNevada />} />
        <Route path="document" element={<Document isEditable />} />
        <Route path="notes" element={<CaseNotes isEditable />} />
      </Route>

      <Route path="newcase" element={<NewCase />}>
        <Route path="partiesandprecinct" element={<PartiesAndPrecinct />} />
      </Route>

      <Route path="newcivilmattercase" element={<NewCivilMatterCase />}>
        <Route path="partiesandprecinct" element={<PartiesAndPrecinct />} />
      </Route>

      <Route path="notices" element={<Notices />} />

      <Route path="bulkdocumentprinting" element={<BulkDocumentPrinting />} />
    </Route>
  </Routes>
);

export default DataBaseProcessingRoutes;
