import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  Typography,
  Stack,
  Container,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { prop, sortBy, reverse } from 'ramda';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';
import Form from '../../../atoms/Form';
import Dropdown from '../../../atoms/Dropdown';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import Table from '../../../atoms/Table/Table';
import {
  caseInformationBoxes,
  proceduralHistoryColumns,
  caseStatusColumns,
  hearingTypes,
} from './CivilMatterCaseInformationNevada.constants';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import { ReactComponent as Clock } from '../../../../assets/icons/Clock.svg';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import useCases from '../../../../hooks/useCases';
import { isEmptyObjValues } from '../../../../utils/isEmptyObjValues';
import {
  addCaseProceduralHistoryEntry,
  addStatusToCase,
  deleteLastNevadeCaseStatus,
  deleteNevadeCaseStatusById,
  getCaseProcedularHistoryEntries,
  getListOfAllStatusesForCase,
  getNevadaRelatedCases,
  getStatusesForCase,
  removeCaseProceduralHistoryEntry,
  updateCaseProceduralHistoryEntry,
} from '../../../../store/slices/cases/casesNevadaSlice';
import { getAllAttorneys } from '../../../../store/slices/cases/casesGeneralSlice';
import formatDateAndTimeToISO from '../../../../utils/formatDateAndTimeToISO';
import MTooltip from '../../../atoms/MTooltip';
import CaseInformation from './CaseInformation';
import LoaderCover from '../../../atoms/LoaderCover';
import notificationUtils from '../../../../utils/notificationUtils';

const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
    '.MuiPaper-root': {
      boxShadow: '0px 3px 20px rgba(69, 80, 121, 0.13)',
      marginBottom: '16px',
      borderRadius: '24px',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiAccordionSummary-root': {
      padding: '0 32px',
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '32px 0',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '16px',
      height: '16px',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 32px 32px',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

const CivilMatterCaseInformationNevada = () => {
  const {
    state: { id, stateCode },
  } = useLocation();
  // eslint-disable-next-line
  const { getListOfAllStatusesForCase: statusOptions, relatedNevadaCases } = useSelector(
    (state) => state.casesNevada,
  );
  const { getAllAttorneys: attorneys } = useSelector((state) => state.casesGeneral);

  const { state } = useCases();
  const [isLoader, setIsLoader] = useState(false);

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: state,
  });

  const proceduralHistoryFields = useFieldArray({
    control: form.control,
    name: 'proceduralHistory',
  });
  const caseStatusFields = useFieldArray({
    control: form.control,
    name: 'caseStatus',
  });

  useEffect(() => {
    if (id) {
      dispatch(getNevadaRelatedCases({ caseId: id }));
    }
  }, [id]);

  const dropDownOptions = useMemo(() => {
    return {
      'proceduralHistoryFields.assignedAttorney': attorneys || [],
      'proceduralHistoryFields.hearingType': hearingTypes || [],
      'caseStatusFields.caseStatus': statusOptions || [],
    };
  }, [statusOptions, attorneys]);

  const getCaseInformationData = async () => {
    setIsLoader(true);
    try {
      const proceduralHistoryReq = dispatch(getCaseProcedularHistoryEntries(id)).unwrap();
      const statusesReq = dispatch(getStatusesForCase(id)).unwrap();

      const { result: proceduralHistory } = await proceduralHistoryReq;
      const statuses = await statusesReq;

      proceduralHistoryFields.replace(proceduralHistory);
      caseStatusFields.replace(reverse(sortBy(prop('caseStatusId'), statuses)));
    } catch (e) {
      // todo: Add Alert Component
      alert('---------------', e);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCaseInformationData();
      dispatch(getListOfAllStatusesForCase());
      dispatch(getAllAttorneys(id));
    }
  }, [id]);

  const dismissedByJudge = form.watch('proceduralHistoryFields.dismissedByJudge');

  useEffect(() => {
    if (dismissedByJudge) {
      const [data] = form.watch('proceduralHistory');
      if (data) {
        form.setValue(
          'proceduralHistoryFields.assignedAttorney',
          attorneys.find((item) => item.label === data.assignedAttorney),
        );
        form.setValue(
          'proceduralHistoryFields.hearingType',
          hearingTypes.find((item) => item.label === data.hearingType),
        );
        form.setValue('proceduralHistoryFields.courtDate', data.courtDateTime);
        form.setValue('proceduralHistoryFields.courtTime', data.courtDateTime);
      }
    } else {
      form.setValue('proceduralHistoryFields.assignedAttorney', '');
      form.setValue('proceduralHistoryFields.hearingType', '');
      form.setValue('proceduralHistoryFields.courtDate', '');
      form.setValue('proceduralHistoryFields.courtTime', '');
    }
  }, [dismissedByJudge, attorneys]);

  const addCaseStatus = async () => {
    const values = form.getValues('caseStatusFields');

    setIsLoader(true);
    try {
      await dispatch(
        addStatusToCase({
          id,
          body: {
            caseId: id,
            caseStatus: values.caseStatus?.value,
            comment: values.comment,
          },
        }),
      ).unwrap();
      notificationUtils.success('Added successfully');
      await getCaseInformationData();
      ['caseStatus.caseStatus', 'caseStatus.comment'].forEach((inputName) =>
        form.setValue(inputName, ''),
      );
    } catch (e) {
      // todo: Add Alert Component
      alert('---------------', e);
    }
    setIsLoader(false);
  };

  const checkFillingFields = ({ name, inputBoxes }) => {
    const inputBoxesFields = typeof inputBoxes === 'function' ? inputBoxes() : inputBoxes;

    const fieldInputNames = inputBoxesFields.map((item) => item.name).filter((i) => i);
    const watching = form.watch(name);
    const mandatoryFields = inputBoxesFields.filter((i) => i.isMandatory).map((i) => i.name);
    const checkIfFieldsNotEmpty = (fields) => {
      for (let i = 0; i < fields.length; i += 1) {
        if (!fields[i]) return false;
      }
      return true;
    };
    const areMandatoryFieldsFilled = mandatoryFields.length
      ? checkIfFieldsNotEmpty(form.watch(mandatoryFields))
      : true;

    switch (name) {
      case 'proceduralHistory': {
        const columns = [...proceduralHistoryColumns];
        columns[0] = { ...columns[0], options: attorneys };
        return {
          watching,
          isValid: areMandatoryFieldsFilled,
          add: async () => {
            const values = form.getValues('proceduralHistoryFields');
            setIsLoader(true);
            try {
              await dispatch(
                addCaseProceduralHistoryEntry({
                  id,
                  body: {
                    caseId: id,
                    attorneyUserId: values?.assignedAttorney?.id,
                    hearingType: values.hearingType?.value,
                    courtDateTime: formatDateAndTimeToISO(values.courtDate, values.courtTime),
                    hasDismissedByJudge: Boolean(values.dismissedByJudge),
                  },
                }),
              ).unwrap();
              notificationUtils.success('Added successfully');
              await getCaseInformationData();
              fieldInputNames.forEach((inputName) => form.setValue(inputName, ''));
            } catch (e) {
              notificationUtils.error(e?.errorMessage || 'Something went wrong');
            }
            setIsLoader(false);
          },
          update: async (row, { assignedAttorney, proceduralHistoryEntryId }) => {
            if (assignedAttorney?.value) {
              setIsLoader(true);
              try {
                await dispatch(
                  updateCaseProceduralHistoryEntry({
                    id,
                    proceduralHistoryEntryId,
                    body: {
                      caseId: id,
                      attorneyUserId: assignedAttorney.value,
                      caseProceduralHistoryEntryId: proceduralHistoryEntryId,
                    },
                  }),
                ).unwrap();
                notificationUtils.success('Updated successfully');
                await getCaseInformationData();
              } catch (e) {
                notificationUtils.error(e?.errorMessage || 'Something went wrong');
              }
              setIsLoader(false);
            }
          },
          deleteRow: async (index) => {
            const entry = proceduralHistoryFields.fields[index];
            setIsLoader(true);
            try {
              await dispatch(
                removeCaseProceduralHistoryEntry({
                  proceduralEntryId: entry.proceduralHistoryEntryId,
                  id,
                }),
              ).unwrap();
              notificationUtils.success('Deleted successfully');
              await getCaseInformationData();
            } catch (e) {
              notificationUtils.error(e?.errorMessage || 'Something went wrong');
            }
            setIsLoader(false);
          },
          rows: proceduralHistoryFields.fields,
          columns,
        };
      }
      case 'caseStatus':
        // eslint-disable-next-line
        return {
          watching,
          isValid: areMandatoryFieldsFilled,
          add: addCaseStatus,
          deleteRow: (caseStatusId) => {
            dispatch(
              caseStatusId
                ? deleteNevadeCaseStatusById({ id, caseStatusId })
                : deleteLastNevadeCaseStatus({ id }),
            ).then(() => {
              getCaseInformationData();
              notificationUtils.success('Updated successfully');
            });
          },
          rows: caseStatusFields.fields,
          columns: caseStatusColumns,
        };
      default:
        return {
          watching,
        };
    }
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      <CaseInformation onUpdate={getCaseInformationData} />
      <Form form={form}>
        {caseInformationBoxes &&
          caseInformationBoxes.map((data) => {
            const { watching, isValid, columns, rows, add, update, deleteRow } =
              checkFillingFields(data);

            return (
              <StyledContainer
                key={data.boxTitle}
                maxWidth={false}
                disableGutters
                sx={{
                  mx: 'auto',
                  mt: 4,
                  width: 1,
                }}
              >
                {isLoader && <LoaderCover isFixed />}
                <StyledAccordion defaultExpanded>
                  <AccordionSummary expandIcon={<ArrowDown />}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="h5">{data.boxTitle}</Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack
                      direction="row"
                      alignItems="center"
                      columnGap={3}
                      rowGap={2}
                      flexWrap="wrap"
                    >
                      {data.inputBoxes.map((item) => {
                        if (item.isDropdown) {
                          return (
                            <Field
                              key={item.name}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              render={({ field, onCustomChange }) => (
                                <Dropdown
                                  value={field.value}
                                  isAddDisabled={isEmptyObjValues(watching)}
                                  isSearchable
                                  label={item.label}
                                  width={item.width}
                                  isColumn
                                  placeholder={item.placeholder}
                                  options={dropDownOptions[item.name]}
                                  onChange={onCustomChange(field.onChange)}
                                  isDisabled={item.isDisabled}
                                  isMandatory={item.isMandatory}
                                />
                              )}
                            />
                          );
                        }

                        if (item.isCheckbox) {
                          return (
                            <Field
                              key={item.name}
                              name={item.name}
                              render={({ field, onCustomChange }) => (
                                <CheckboxWithLabel
                                  label={item.label}
                                  defaultValue={field.value}
                                  onChange={onCustomChange(field.onChange)}
                                  marginTop="25px"
                                  isChecked={field.value}
                                />
                              )}
                            />
                          );
                        }

                        if (item.isSpace) {
                          return <div style={{ width: '100%' }} key={item.label} />;
                        }

                        if (item.isSubHeading) {
                          return (
                            <StyledTypography width={1} mb={1} variant="bodyL500" key={item.label}>
                              {item.label}
                            </StyledTypography>
                          );
                        }

                        if (item.isLabelGroupHeading) {
                          return (
                            <StyledTypography width={1} mb={1} variant="bodyM" key={item.label}>
                              {item.label}
                            </StyledTypography>
                          );
                        }

                        if (item.isDatePicker) {
                          return (
                            <div style={{ width: item.wrapperWidth || 'auto' }} key={item.label}>
                              <Field
                                name={item.name}
                                render={({ field, onCustomChange }) => (
                                  <DateOrTimePicker
                                    name={item.name}
                                    label={item.label}
                                    isDate
                                    Svg={Calender}
                                    selected={field.value}
                                    width={item.width}
                                    height={item.height}
                                    isMandatory={item.isMandatory}
                                    disabled={
                                      item.getDisabled ? item.getDisabled(form) : item.isDisabled
                                    }
                                    placeholder={item.placeholder}
                                    onChange={onCustomChange(field.onChange)}
                                    setSelectedTimeOrDate={onCustomChange(field.onChange)}
                                  />
                                )}
                              />
                            </div>
                          );
                        }

                        if (item.isTimePicker) {
                          return (
                            <div style={{ width: item.wrapperWidth || 'auto' }} key={item.label}>
                              <Field
                                name={item.name}
                                render={({ field, onCustomChange }) => (
                                  <DateOrTimePicker
                                    name={item.name}
                                    label={item.label}
                                    isMandatory={item.isMandatory}
                                    isTime
                                    Svg={Clock}
                                    selected={field.value}
                                    onChange={onCustomChange(field.onChange)}
                                    setSelectedTimeOrDate={onCustomChange(field.onChange)}
                                    width={item.width}
                                    height={item.height}
                                    placeholder={item.placeholder}
                                  />
                                )}
                              />
                            </div>
                          );
                        }

                        if (item.isRelatedCasesTable) {
                          return relatedNevadaCases ? (
                            <Table columns={item.columns} rows={relatedNevadaCases} />
                          ) : (
                            <Typography variant="bodyL500" textAlign="center">
                              Currently there is no Related Cases.
                            </Typography>
                          );
                        }

                        return (
                          <Field
                            name={item.name}
                            key={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, error }) => {
                              return (
                                <EditInputWithLabel
                                  error={error}
                                  isMandatory={item.isMandatory}
                                  type="text"
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  width={item.width}
                                  name={item.name}
                                  value={field.value}
                                  onChange={field.onChange}
                                  tooltip={
                                    item?.tooltipText &&
                                    stateCode === 'NV' && <MTooltip text={item.tooltipText} />
                                  }
                                  isDisabled={
                                    item.getDisabled ? item.getDisabled(form) : item.isDisabled
                                  }
                                />
                              );
                            }}
                          />
                        );
                      })}

                      {data.withAdd && (
                        <>
                          <MButton
                            disabled={!isValid || isLoader}
                            startIcon={<Add />}
                            sx={{
                              ml: 'auto',
                              mt: '28px',
                            }}
                            onClick={add}
                          >
                            Add
                          </MButton>
                          <Table
                            columns={columns}
                            loading={false}
                            overflowHidden={false}
                            rows={rows}
                            onUpdateData={update}
                            onDeleteRow={deleteRow}
                          />
                        </>
                      )}
                    </Stack>
                  </AccordionDetails>
                </StyledAccordion>
              </StyledContainer>
            );
          })}
      </Form>
    </Container>
  );
};

export default CivilMatterCaseInformationNevada;
