import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import LoaderCover from '../../atoms/LoaderCover';
import { apiClient, tokenRepository } from '../../../lib/apiClient';
import NotificationDialog from '../../molecules/NotificationDialog';
import ForgotPasswordLayout from './ForgotPasswordLayout';
import MButton from '../../MUI/Button/MButton';
import MTooltip from '../../atoms/MTooltip/MTooltip';
import Field from '../../atoms/Field';
import Password from '../../atoms/Password/Password';
import Form from '../../atoms/Form';
import { invalidPasswordErrorMessage, isPasswordValid } from '../../../utils/formHelpers';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const ForgotPassword = () => {
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const navigate = useNavigate();
  const { portal = 'cp' } = useParams();
  const base = useBaseUrl();
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');

  const goToLogin = () => {
    tokenRepository.removeTokens();
    navigate(`/${base}/login`);
  };

  useEffect(() => {
    if (!tokenRepository.getTemporaryToken()) goToLogin();
  }, []);

  const onSubmit = async ({ password, confirmPassword }) => {
    const errors = {};

    if (password || confirmPassword) {
      if (password !== confirmPassword) {
        errors.confirmPassword = { message: 'Passwords do not match' };
      }
      if (!isPasswordValid(password)) {
        errors.password = {
          message: invalidPasswordErrorMessage,
        };
      }
    }

    if (Object.keys(errors).length !== 0) {
      Object.keys(errors).forEach((e) => {
        form.setError(e, errors[e]);
      });
      return;
    }
    setIsLoading(true);
    const apiByPortal = {
      db: `/api/passwordRecoveries`,
      cp: `/cp/api/passwordRecoveries`,
      client: `/cp/api/passwordRecoveries`,
    };
    try {
      const token = tokenRepository.getTemporaryToken();
      const body = {
        token,
        password,
        confirmPassword,
      };
      await apiClient.post(apiByPortal[portal], body);
      setShowSuccess(true);
    } catch (e) {
      setError(e.response?.data?.errorMessage || 'Unexpected server error');
      setShowError(true);
    }
    setIsLoading(false);
  };

  const closeErrorDialog = () => {
    setShowError(false);
  };

  const { isValid } = form.formState;

  return (
    <ForgotPasswordLayout
      title={
        <Typography variant="bodySThin">
          New Password
          <MTooltip
            tooltipVariant="lightviolette"
            arrow
            text="Please take note or remember the password you have provided, because of security reasons no one else will be able to look it up later!"
          />
        </Typography>
      }
    >
      {isLoading && <LoaderCover />}
      <Form onSubmit={onSubmit} form={form}>
        <Stack pb={3} gap={3} alignItems="center" width="262px">
          <Field
            name="password"
            isMandatory
            render={({ field, error: fieldError, onCustomChange }) => (
              <Box pb={fieldError ? 3 : 0}>
                <Password
                  error={fieldError}
                  width="100%"
                  placeholder="New password"
                  onChange={onCustomChange(field.onChange)}
                  value={field.value}
                  endAdornment={false}
                />
              </Box>
            )}
          />
          <Field
            name="confirmPassword"
            isMandatory
            render={({ field, error: fieldError, onCustomChange }) => (
              <Password
                error={fieldError}
                width="100%"
                placeholder="Confirm new password"
                onChange={onCustomChange(field.onChange)}
                value={field.value}
                endAdornment={false}
              />
            )}
          />
        </Stack>
        <Stack gap={2} direction="column" alignItems="center">
          <MButton
            type="submit"
            sx={{ width: '262px', height: '48px' }}
            disabled={isLoading || !isValid}
            data-testid="forgot_password_save_btn"
          >
            <Typography variant="buttonLarge">Save</Typography>
          </MButton>
          <Typography
            variant="buttonSmall"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={goToLogin}
            data-testid="forgot_password_cancel_btn"
          >
            Cancel
          </Typography>
        </Stack>
      </Form>
      <NotificationDialog
        desc={error}
        buttonPrimaryText="Ok"
        onClose={closeErrorDialog}
        onConfirm={closeErrorDialog}
        isOpen={showError}
        type="alert"
        width="528px"
      />
      <NotificationDialog
        desc="New password has been saved for your account."
        buttonPrimaryText="Ok"
        onClose={goToLogin}
        onConfirm={goToLogin}
        isOpen={showSuccess}
        width="528px"
      />
    </ForgotPasswordLayout>
  );
};

export default ForgotPassword;
