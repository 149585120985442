import React from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as Clock } from '../../../../../assets/icons/Clock.svg';
import { ReactComponent as Dollar } from '../../../../../assets/icons/DollarInCircle.svg';

const TypeCell = ({
  row: {
    original: { type },
  },
}) => {
  const Icon = {
    Time: <Clock />,
    Expense: <Dollar />,
  };

  return (
    <Stack flex justifyContent="center" alignItems="center" width="100%">
      {Icon[type]}
    </Stack>
  );
};

TypeCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default TypeCell;
