import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AuthContext from '../../../../context/AuthContext';
import { palette } from '../../../../theme/default';
import { useBaseUrl } from '../../../../hooks/useBaseUrl';

export const prepareDefendantName = (name = '') => {
  const names = name.split(',');
  if (names.length <= 1) return names[0].trim();
  return `${names[0].trim()}, Et Al.`;
};

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 20;

export const ReferenceNumberCell = ({ value, row, isBlank }) => {
  const { userInfo } = useContext(AuthContext);
  const base = useBaseUrl();
  const isClientPortal = userInfo.isClientPortalUser;

  const to = isClientPortal
    ? `/${base}/cases/case/${row.original.caseId}`
    : `/db/processing/case/${row.original.caseId}`;

  if (!row.original.caseId)
    return (
      <Typography variant="bodyM" color={palette.text.primary}>
        {value}
      </Typography>
    );

  return (
    <Link to={to} target={isBlank ? '_blank' : '_self'}>
      <Typography sx={{ cursor: 'pointer' }} variant="link" color={palette.text.primary}>
        {value}
      </Typography>
    </Link>
  );
};

ReferenceNumberCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  isBlank: PropTypes.bool,
};

ReferenceNumberCell.defaultProps = {
  isBlank: false,
};

export const DateCell = ({ value }) => (value ? moment(value).format('MM/DD/YYYY') : '');

const DEFENDANTS_MAX_NAME_LENGTH = 50;

export const DefendantCell = ({ value = '' }) => {
  if (value.length < DEFENDANTS_MAX_NAME_LENGTH) return value;
  return (
    <Typography title={value} variant="bodyM">{`${value.slice(
      0,
      DEFENDANTS_MAX_NAME_LENGTH - 2,
    )}...`}</Typography>
  );
};

DefendantCell.propTypes = {
  value: PropTypes.string,
};

DefendantCell.defaultProps = {
  value: '',
};
