import React from 'react';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import EmptyBlock from '../../../molecules/EmptyBlock';

const AttorneyAssignmentsEmptyBlock = () => {
  const { showNotFound, showUseFiltersMessage } = useSelector((state) => state.attorneyAssignments);

  if (showUseFiltersMessage) {
    return (
      <Stack
        alignItems="center"
        height="100%"
        display="flex"
        justifyContent="flex-start"
        mt={10}
        mb={30}
      >
        <EmptyBlock
          desc="Use filters to find assigned attorneys"
          descFontSize="32px"
          width="auto"
        />
      </Stack>
    );
  }

  if (showNotFound) {
    return (
      <Stack
        alignItems="center"
        height="100%"
        display="flex"
        justifyContent="flex-start"
        mt={10}
        mb={30}
      >
        <EmptyBlock
          desc="You don't have any attorney assignment yet"
          descFontSize="32px"
          width="auto"
        />
      </Stack>
    );
  }

  return null;
};

export default AttorneyAssignmentsEmptyBlock;
