import React from 'react';
import { Container } from '@mui/material';
import AttorneyAssignmentsHeader from './AttorneyAssignmentsHeader';
import AttorneyAssignmentsEmptyBlock from './AttorneyAssignmentsEmptyBlock';
import AttorneyAssignmentsFilters from './AttorneyAssignmentsFilters';
import AttorneyAssignmentsAccordions from './AttorneyAssignmentsAccordions';
import AttorneyAssignmentsFooter from './AttorneyAssignmentsFooter';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';

const AttorneyAssignments = () => {
  useSafeLocationState('Schedule', 'Attorney Assignments');

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        px: 4,
      }}
    >
      <AttorneyAssignmentsHeader />
      <AttorneyAssignmentsFilters />
      <AttorneyAssignmentsAccordions />
      <AttorneyAssignmentsEmptyBlock />
      <AttorneyAssignmentsFooter />
    </Container>
  );
};

export default AttorneyAssignments;
