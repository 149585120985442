/* eslint-disable react/prop-types */
import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';

export const DOCUMENT_COLUMNS_NOTICE = [
  {
    Header: 'Document Name',
    accessor: 'noticeName',
    width: 48,
  },
  {
    Header: 'Type',
    accessor: 'noticeType',
    width: 48,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: ({ row, deleteRow }) =>
      row?.original?.noticeName && row?.original?.isDeleteRow ? (
        <DeleteCell deleteRow={deleteRow} row={row} />
      ) : (
        <div style={{ minWidth: '38px', width: '4%' }} />
      ),
  },
];

export const DOCUMENT_COLUMNS_LEASE = [
  {
    Header: 'Document Name',
    accessor: 'leaseName',
    width: 48,
  },
  {
    Header: 'Type',
    accessor: 'leaseType',
    width: 48,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: ({ row, deleteRow }) =>
      row?.original?.leaseName && row?.original?.isDeleteRow ? (
        <DeleteCell deleteRow={deleteRow} row={row} />
      ) : (
        <div style={{ minWidth: '38px', width: '4%' }} />
      ),
  },
];

export const DOCUMENT_COLUMNS_LEDGER = [
  {
    Header: 'Document Name',
    accessor: 'ledgerName',
    width: 48,
  },
  {
    Header: 'Type',
    accessor: 'ledgerType',
    width: 48,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: ({ row, deleteRow }) =>
      row?.original?.ledgerName && row?.original?.isDeleteRow ? (
        <DeleteCell deleteRow={deleteRow} row={row} />
      ) : (
        <div style={{ minWidth: '38px', width: '4%' }} />
      ),
  },
];
