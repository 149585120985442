import React from 'react';
import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/Add.svg';

export const managementHeading = 'Management Properties';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;
export const MAX_HEIGHT_DIALOG = 624;
export const MAX_HEIGHT_DIALOG_TABLE = 326;

export const managementBoxes = [
  {
    name: '',
    inputBoxes: [
      {
        label: 'Company',
        isSubHeading: true,
      },
      {
        label: 'Company Name',
        name: 'managementName',
        placeholder: 'Management Services Inc.',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'Management Code',
        name: 'managementCode',
        placeholder: 'Enter management code',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
    ],
  },
  {
    name: 'contactInfo',
    inputBoxes: [
      {
        label: 'Contact Info',
        isSubHeading: true,
      },
      {
        label: 'Address',
        name: 'contactInfo.address',
        placeholder: 'Enter address',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'City',
        name: 'contactInfo.city',
        placeholder: 'Enter city',
        width: '208px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'State',
        name: 'contactInfo.stateCode',
        placeholder: <span>&#8212;</span>,
        width: '94px',
        isDropdown: true,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'ZIP Code',
        name: 'contactInfo.zipCode',
        placeholder: 'xx xx xx',
        width: '208px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'spaceAfterZipCode',
        isSpace: true,
      },
      {
        label: 'Email',
        name: 'contactInfo.email',
        placeholder: 'Enter email',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'Phone',
        name: 'contactInfo.phone',
        placeholder: 'Enter phone',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isDate: false,
      },
      {
        isSpace: true,
        label: 'spaceAfterPhone',
      },
      {
        label: 'Website',
        name: 'contactInfo.website',
        placeholder: 'Enter website',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isDate: false,
      },
      {
        label: 'Fax',
        name: 'contactInfo.fax',
        placeholder: '+1 (480) xx xx xx xx',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isDate: false,
      },
    ],
  },
  {
    name: 'contacts',
    inputBoxes: [
      {
        label: 'Contact Persons',
        isSubHeading: false,
        isSubHeadingWithButton: true,
        buttonName: 'Add',
        buttonIcon: <AddIcon />,
      },
      {
        label: '',
        name: 'contacts',
        placeholder: '',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
    ],
  },
  {
    name: 'invoicing',
    inputBoxes: [
      {
        label: 'Invoicing',
        isSubHeading: true,
      },
      {
        label: 'Status',
        name: 'invoicing.isDeactivated',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: 'auto',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Active', value: false, isDefault: true },
          { id: 1, label: 'Inactive', value: true },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'On Hold',
        name: 'invoicing.isOnHold',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '116px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true, isDefault: true },
          { id: 1, label: 'No', value: false },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'On Hold Description',
        name: 'invoicing.onHoldDescription',
        placeholder: 'Enter on hold description',
        width: '544px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        isSpace: true,
        label: 'spaceAfterOnHoldDescription',
      },
      {
        label: 'Automate Invoicing',
        name: 'invoicing.isAutoInvoicing',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '116px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false, isDefault: true },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Email Invoice',
        name: 'invoicing.isEmailInvoice',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '116px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true, isDefault: true },
          { id: 1, label: 'No', value: false },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Invoice Frequency',
        name: 'invoicing.invoiceFrequency',
        width: '260px',
        isDropdown: true,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Invoicing Details',
        name: 'invoicing.invoicingDetail',
        width: '260px',
        isDropdown: true,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
    ],
  },
  {
    name: '',
    inputBoxes: [
      {
        label: 'Secondary Keys',
        isSubHeading: true,
      },
      {
        label: 'Customer Ref_List ID',
        name: 'secondaryKeys.customerRefListId',
        placeholder: 'Enter customer  ID',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Customer Ref_Full Name',
        name: 'secondaryKeys.customerRefFullName',
        placeholder: 'Enter customer name',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
    ],
  },
];

export const defaultTemplatesColumns = [
  {
    Header: 'Template Name',
    accessor: 'templateName',
    width: 20,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 20,
  },
  {
    Header: 'Notice Type',
    accessor: 'noticeType',
    width: 20,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    Cell: DeleteCell,
  },
];

export const ContactPersonsColumns = [
  {
    Header: 'Full Name',
    accessor: 'name',
    width: 20,
  },
  {
    Header: 'Title',
    accessor: 'title',
    width: 15,
  },
  {
    Header: 'Work #',
    accessor: 'workPhone',
    width: 15,
  },
  {
    Header: 'Mobile #',
    accessor: 'mobilePhone',
    width: 15,
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 25,
  },
  {
    Header: '',
    accessor: 'control',
    width: 3,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];

export const ContactPersonsAllColumns = [
  {
    Header: 'Full Name',
    accessor: 'contactName',
    width: 22,
  },
  {
    Header: 'Title',
    accessor: 'contactTitle',
    width: 20,
  },
  {
    Header: 'Work #',
    accessor: 'contactWorkPhone',
    width: 15,
  },
  {
    Header: 'Mobile #',
    accessor: 'contactMobilePhone',
    width: 15,
  },
  {
    Header: 'Email',
    accessor: 'contactEmail',
    width: 25,
  },
];

export const invoiceDropdown = [
  {
    id: 1,
    value: 'Bi-Monthly',
    label: 'Bi-Monthly',
    isDefault: false,
  },
  {
    id: 2,
    value: 'Weekly',
    label: 'Weekly',
    isDefault: false,
  },
  {
    id: 3,
    value: 'Monthly',
    label: 'Monthly',
    isDefault: true,
  },
];

export const invoiceDetailDropdown = [
  {
    id: 1,
    value: 'Management',
    label: 'Management',
    isDefault: true,
  },
  {
    id: 2,
    value: 'Address',
    label: 'Address',
    isDefault: false,
  },
];
