/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const getCaseInformation = createAsyncThunk(
  'casesArizona/getCaseInformation',
  async (id) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${id}/caseInformation`);
    return data;
  },
);

export const createCasesFromBulkArizona = createAsyncThunk(
  'casesArizona/createCasesFromBulkArizona',
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('DateServed', data.DateServed);
      formData.append('File', data.File || new Blob(), data.File?.name || '');
      const { data: resData } = await apiClient.post(`/api/arizonaCases/bulk`, formData);

      return resData;
    } catch (error) {
      const errorStatus = error.response.status;
      if (errorStatus === 400) {
        return rejectWithValue(error.response.data.errorMessage);
      }
      return rejectWithValue(error.response.data.errorMessage);
    }
  },
);

export const updateCaseDamages = createAsyncThunk(
  'casesArizona/updateCaseDamages',
  async (id, body) => {
    const { data } = await apiClient.put(`/api/arizonaCases/${id}/damages`, body);
    return data;
  },
);

export const getCaseViolations = createAsyncThunk('casesArizona/getCaseViolations', async (id) => {
  const { data } = await apiClient.get(`/api/arizonaCases/${id}/violations`);
  return data;
});

export const addCaseViolation = createAsyncThunk(
  'casesArizona/addCaseViolation',
  async (id, body) => {
    const { data } = await apiClient.post(`/api/arizonaCases/${id}/violations`, body);
    return data;
  },
);

export const getCaseProcedularHistoryEntries = createAsyncThunk(
  'casesArizona/getCaseProcedularHistoryEntries',
  async (id) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${id}/proceduralHistoryEntries`);
    return data;
  },
);

export const printArizonaCaseDocument = createAsyncThunk(
  'casesGeneral/printArizonaCaseDocument',
  async (args) => {
    const { id, ...body } = args;
    const { data } = await apiClient.post(`/api/arizonaCases/${id}/documents/print`, body);

    return data;
  },
);

export const addCaseProceduralHistoryEntry = createAsyncThunk(
  'casesArizona/addCaseProceduralHistoryEntry',
  async (id, body) => {
    const { data } = await apiClient.post(`/api/arizonaCases/${id}/proceduralHistoryEntries`, body);
    return data;
  },
);

export const getListOfAllStatusesForCase = createAsyncThunk(
  'casesArizona/getListOfAllStatusesForCase',
  async () => {
    const { data } = await apiClient.get(`/api/arizonaCases/statuses?OnlyManual=true`);
    return data;
  },
);

export const getStatusesForCase = createAsyncThunk(
  'casesArizona/getStatusesForCase',
  async (id) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${id}/statuses`);
    return data;
  },
);

export const addStatusToCase = createAsyncThunk(
  'casesArizona/addStatusToCase',
  async (id, body) => {
    const { data } = await apiClient.post(`/api/arizonaCases/${id}/statuses`, body);
    return data;
  },
);

export const createArizonaDocument = createAsyncThunk(
  'casesArizona/createDocument',
  async ({ id, templateId, ...body }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(
        `/api/arizonaCases/${id}/documents/${templateId}`,
        body,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getArizonaNoticeDocumentData = createAsyncThunk(
  'casesArizona/getArizonaNoticeDocumentData',
  async ({ id, templateId }) => {
    const {
      data: { result },
    } = await apiClient.get(`/api/arizonaCases/${id}/templates/${templateId}/fields`);

    return {
      id,
      templateId,
      fields: result.fields,
    };
  },
);

export const getListOfAllViolationsForArizonaCaseDocumentGeneration = createAsyncThunk(
  'casesArizona/getListOfAllViolationsForArizonaCaseDocumentGeneration',
  async (id) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${id}/documents/violations`);
    return data;
  },
);

export const generateComplexComplaintDocumentForArizonaCase = createAsyncThunk(
  'casesArizona/generateComplexComplaintDocumentForArizonaCase ',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/arizonaCases/${params.caseId}/documents/complaints`,
      params,
    );
    return data;
  },
);

const initialState = {
  getCaseInformation: null,
  updateCaseDamages: null,
  getCaseViolations: null,
  addCaseViolation: null,
  getCaseProcedularHistoryEntries: null,
  addCaseProceduralHistoryEntry: null,
  getListOfAllStatusesForCase: null,
  getStatusesForCase: null,
  addStatusToCase: null,
  listOfAllViolationsForDocumentGeneration: [],
  noticeDocumentData: null,
  error: null,
};

export const casesArizonaSlice = createSlice({
  name: 'casesArizona',
  initialState,
  reducers: {},
  extraReducers: {
    [getCaseInformation.fulfilled]: (state, action) => {
      state.getCaseInformation = action.payload;
    },
    [updateCaseDamages.fulfilled]: (state, action) => {
      state.updateCaseDamages = action.payload;
    },
    [getCaseViolations.fulfilled]: (state, action) => {
      state.getCaseViolations = action.payload;
    },
    [addCaseViolation.fulfilled]: (state, action) => {
      state.addCaseViolation = action.payload;
    },
    [getCaseProcedularHistoryEntries.fulfilled]: (state, action) => {
      state.getCaseProcedularHistoryEntries = action.payload;
    },
    [addCaseProceduralHistoryEntry.fulfilled]: (state, action) => {
      state.addCaseProceduralHistoryEntry = action.payload;
    },
    [getListOfAllStatusesForCase.fulfilled]: (state, action) => {
      state.getListOfAllStatusesForCase = action.payload;
    },
    [getStatusesForCase.fulfilled]: (state, action) => {
      state.getStatusesForCase = action.payload;
    },
    [addStatusToCase.fulfilled]: (state, action) => {
      state.addStatusToCase = action.payload;
    },
    [printArizonaCaseDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createArizonaDocument.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getListOfAllViolationsForArizonaCaseDocumentGeneration.fulfilled]: (state, action) => {
      state.listOfAllViolationsForDocumentGeneration = action.payload.result;
    },
    [getArizonaNoticeDocumentData.fulfilled]: (state, action) => {
      state.noticeDocumentData = action.payload;
    },
  },
});

export default casesArizonaSlice.reducer;
