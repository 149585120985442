/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const deleteContact = createAsyncThunk(
  'contact/deleteContact',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.delete(`/api/contacts/${id}`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getContact = createAsyncThunk('contact/getContact', async (id) => {
  const { data } = await apiClient.get(`/api/contacts/${id}`);
  return data;
});

export const editContact = createAsyncThunk(
  'contact/editContact',
  async (editContactObj, { rejectWithValue }) => {
    const { id, ...fields } = editContactObj;
    try {
      const { data } = await apiClient.put(`/api/contacts/${id}`, fields);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const editContactNotes = createAsyncThunk(
  'contact/editContactNotes',
  async (editContactNotesObj) => {
    const { id, ...fields } = editContactNotesObj;
    const { data } = await apiClient.post(`/api/contacts/${id}/notes`, fields);
    return data;
  },
);

export const getContactNotes = createAsyncThunk('contact/getContactNotes', async (id) => {
  const { data } = await apiClient.get(`/api/contacts/${id}/notes`);
  return data;
});

const initialState = {
  editableContact: {},
  notes: [],
  error: null,
};

export const editContactSlice = createSlice({
  name: 'ediContact',
  initialState,
  reducers: {},
  extraReducers: {
    [deleteContact.fulfilled]: (state, action) => {
      state.editableContact = action.payload;
    },
    [deleteContact.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getContact.fulfilled]: (state, action) => {
      state.editableContact = action.payload.result;
    },
    [getContact.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getContactNotes.fulfilled]: (state, action) => {
      state.notes = action.payload.result;
    },
    [editContactNotes.fulfilled]: (state, action) => {
      state.notes = action.payload;
    },
  },
});

export default editContactSlice.reducer;
