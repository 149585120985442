import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;
export const MAX_AMOUNT_OF_ITEMS_IN_MODAL = 10;

const UsersCell = ({ value }) => {
  const label = value
    .map((u) => [u.firstName, u.lastName].filter((x) => x).join(' '))
    .join(', ')
    .slice(0, 150);

  return (
    <Typography
      sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
      variant="bodyM"
      color="text.primary"
    >
      {label.length > 150 ? `${label}...` : label}
    </Typography>
  );
};

UsersCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

export const ClientRequestNotificationsTableColumns = [
  {
    Header: 'Request Type',
    accessor: 'type',
    width: 32,
  },
  {
    Header: 'State(s)',
    accessor: 'stateCodes',
    Cell: ({ value }) => value?.join(', '),
    width: 16,
  },
  {
    Header: 'User(s)',
    accessor: 'users',
    Cell: UsersCell,
    width: 48,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: 48,
    Cell: DeleteCell,
  },
];

export const UsersTableColumns = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 30,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 30,
  },
  {
    Header: 'Role',
    accessor: 'roles',
    width: 20,
    Cell: ({ value }) => value?.join(', '),
  },
  {
    Header: 'State',
    accessor: 'stateCode',
    width: 15,
  },
  {
    Header: '',
    accessor: 'control',
    width: 5,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];

export const SelectUsersTableColumns = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 30,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 30,
  },
  {
    Header: 'Role',
    accessor: 'roles',
    width: 20,
    Cell: ({ value }) => value?.join(', '),
  },
  {
    Header: 'State',
    accessor: 'stateCode',
    width: 20,
  },
];
