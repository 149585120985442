import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const CommunitiesColumns = [
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 48,
  },
  {
    Header: 'Management Company',
    accessor: 'managementCompany',
    width: 48,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];
