import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Typography, Stack, styled, Box, IconButton } from '@mui/material';
import MButton from '../../../MUI/Button/MButton';
import MDialog from '../../../atoms/MDialog/MDialog';
import Form from '../../../atoms/Form';
import Dropzone from '../../../atoms/Dropzone';
import { ReactComponent as Document } from '../../../../assets/icons/Document.svg';
import { ReactComponent as Close } from '../../../../assets/icons/Close.svg';
import notificationUtils from '../../../../utils/notificationUtils';

const FileStack = styled(Stack)({
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
  border: '1px solid #DCE4FF',
  borderRadius: '10px',
});

const UploadImageDialog = ({ isOpen, onClose }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  // const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
  });

  useEffect(() => {
    if (isError) {
      notificationUtils.error(`File is too large`, {
        autoHideDuration: 5000,
      });
      form.setValue('File', undefined);
    }
  }, [isError]);

  const customValidator = (file) => {
    const image = new Image();

    image.addEventListener('load', () => {
      if (image.width > 800 || image.height > 600) {
        setError(true);
      } else {
        setError(false);
      }

      return null;
    });
    image.src = URL.createObjectURL(file);
    setImageSrc(image);
  };

  const handleDrop = ([file]) => {
    setError(false);
    customValidator(file);
    form.setValue('File', file);
  };

  const handleDelete = () => {
    form.setValue('File', null);
  };

  const onCancelPress = (isSubmitted, file) => {
    onClose(isSubmitted, imageSrc, file);
    form.reset();
  };

  const handleSubmit = form.handleSubmit((formValues) => {
    onCancelPress(true, formValues.File);
  });

  const file = form.watch('File');

  return (
    <MDialog
      isOpen={isOpen}
      onClose={() => onCancelPress(false)}
      title="Upload New Image"
      maxWidth="796px"
      minWidth="796px"
      footerActions={[
        <MButton size="large" variant="bordered" onClick={() => onCancelPress(false)}>
          Cancel
        </MButton>,
        <MButton size="large" onClick={handleSubmit} disabled={!file}>
          Upload
        </MButton>,
      ]}
    >
      <Form form={form} onSubmit={handleSubmit}>
        {file ? (
          <FileStack direction="row">
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Document style={{ margin: '0 20px 0 0' }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '90%',
                }}
              >
                <Typography
                  variant="bodyM"
                  sx={{
                    width: '650px',
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {file.name}
                </Typography>
                <IconButton onClick={handleDelete}>
                  <Close width="18px" height="18px" />
                </IconButton>
              </Box>
            </Stack>
          </FileStack>
        ) : (
          <Dropzone
            accept={{
              'image/*': ['.jpg', '.jpeg', '.png'],
            }}
            maxSize={1024 * 1024 * 2}
            onDrop={handleDrop}
            customContent={
              <>
                <Typography variant="bodyL500" color="text.secondary" sx={{ my: 1 }}>
                  Drop your image here,
                  <Typography
                    variant="bodyL"
                    sx={{
                      color: 'buttonPrimary.active',
                      textDecoration: 'underline',
                      marginLeft: 0.5,
                    }}
                  >
                    or select
                  </Typography>
                </Typography>
                <Typography variant="bodyXS" color="text.secondary" sx={{ my: 1 }}>
                  Supports JPG, JPEG, PNG files up to 2MB and up to 800x600px
                </Typography>
              </>
            }
          />
        )}
      </Form>
    </MDialog>
  );
};

UploadImageDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UploadImageDialog;
