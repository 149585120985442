import React, { useState } from 'react';
import { Stack, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { palette } from '../../../../../theme/default';
import MButton from '../../../../MUI/Button/MButton';
import ClientPortalCommunitiesModal from '../ClientPortalCommunitiesModal';
import ClientPortalAddressesModal from '../ClientPortalAddressesModal';

export const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

export const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

export const CommunititesAndAddressSection = ({
  label,
  disabled,
  managementId,
  searchInputPlaceholder,
  statesToUseInSearch,
  buttonName,
  buttonIcon,
  alreadyAddedCommunities,
  alreadyAddedAddresses,
}) => {
  const [currentModal, setCurrentModal] = useState(false);

  const onOpenModal = (labelModal) => {
    setCurrentModal(labelModal);
  };

  const onCloseModal = () => {
    setCurrentModal(null);
  };

  return (
    <Stack direction="row" alignContent="space-between" width="100%" key={label}>
      <StyledTypography width={1} variant="bodyL500">
        {label}
      </StyledTypography>
      <MButton onClick={() => onOpenModal(label)} startIcon={buttonIcon} disabled={disabled}>
        {buttonName}
      </MButton>
      {currentModal === 'Communities' && currentModal === label ? (
        <ClientPortalCommunitiesModal
          stateCode={statesToUseInSearch}
          alreadyAdded={alreadyAddedCommunities}
          managementId={managementId}
          title={label}
          buttonSecondaryText="Cancel"
          buttonPrimaryText="Save"
          onClose={onCloseModal}
          isOpen
          inputPlaceholder={searchInputPlaceholder}
        />
      ) : null}
      {currentModal === 'Addresses' && currentModal === label ? (
        <ClientPortalAddressesModal
          alreadyAdded={alreadyAddedAddresses}
          stateCode={statesToUseInSearch}
          managementId={managementId}
          title={label}
          buttonSecondaryText="Cancel"
          buttonPrimaryText="Save"
          onClose={onCloseModal}
          isOpen
          inputPlaceholder={searchInputPlaceholder}
        />
      ) : null}
    </Stack>
  );
};

CommunititesAndAddressSection.propTypes = {
  label: PropTypes.string,
  searchInputPlaceholder: PropTypes.string,
  buttonName: PropTypes.string,
  buttonIcon: PropTypes.node,
  disabled: PropTypes.bool,
  statesToUseInSearch: PropTypes.arrayOf(PropTypes.shape({})),
  managementId: PropTypes.string,
  alreadyAddedCommunities: PropTypes.bool,
  alreadyAddedAddresses: PropTypes.bool,
};

CommunititesAndAddressSection.defaultProps = {
  label: '',
  searchInputPlaceholder: '',
  buttonName: '',
  buttonIcon: null,
  disabled: false,
  statesToUseInSearch: [],
  managementId: '',
  alreadyAddedCommunities: false,
  alreadyAddedAddresses: false,
};
