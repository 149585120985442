import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { palette } from '../../../../../theme/default';
import Tag from '../../../../atoms/Tag';
import { ALL_STATUSES } from '../../../../../global/constants';

export const StatusCell = ({ value: status = 'Cancelled', ...rest }) => {
  const timeStamps = rest.row?.original?.timeStamp;

  const timeStamp = useMemo(() => {
    if (!timeStamps) return '';
    const listOfTimestamps = timeStamps.split(',');
    return ` - ${listOfTimestamps[listOfTimestamps.length - 1]}`;
  }, [timeStamps]);

  const backgroundColor = useMemo(() => {
    if (!status) return palette.statuses.transparent;
    const statusOption = ALL_STATUSES.find((o) => o.value === status);
    if (statusOption) return statusOption.color;
    return palette.statuses.transparent;
  }, [status]);

  return <Tag backgroundColor={backgroundColor} text={`${status} ${timeStamp}`} />;
};

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};
