import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';

import Form from '../../atoms/Form';
import MDialog from '../../atoms/MDialog';
import MButton from '../../MUI/Button/MButton';
import { ReactComponent as Save } from '../../../assets/icons/save-2.svg';
import Profile from './Profile';
import {
  getCPUserProfile,
  getUserProfile,
  updateUserProfile,
} from '../../../store/slices/userProfileSlice';
import notificationUtils from '../../../utils/notificationUtils';
import LoaderCover from '../../atoms/LoaderCover';
import useAuth from '../../../hooks/useAuth';
import Tabs from '../../atoms/Tabs/Tabs';
import DashboardSettings from './DashboardSettings';
import { rolesDB } from '../../../utils/roleHelpers';
import { updateSettings } from '../../../store/slices/legalAssistantDashboardSlice';
import { getSettings } from '../../../store/slices/attorneyDashboardSlice';

const tabs = [
  {
    label: 'Personal Information',
    id: 'profile',
    value: 'profile',
  },
  {
    label: 'Dashboard Settings',
    id: 'dashboard',
    value: 'dashboard',
  },
];

const ProfileDialog = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { userInfo } = useAuth();
  const isDashboardSettingAvailable =
    userInfo.roles.includes(rolesDB.Admin) ||
    userInfo.roles.includes(rolesDB.LegalAssistant) ||
    userInfo.roles.includes(rolesDB.Attorney);

  const isLADashboard =
    userInfo.roles.includes(rolesDB.Admin) || userInfo.roles.includes(rolesDB.LegalAssistant);
  const isAttorneyDashboard =
    userInfo.roles.includes(rolesDB.Admin) || userInfo.roles.includes(rolesDB.Attorney);

  const [tab, setTab] = useState(tabs[0]);

  const form = useForm({
    defaultValues: {
      imageSrc: '',
      image: null,
      LAdashboard: {
        state: {},
        noticesToBeDrafted: {},
        casesToBeEntered: {},
        followUps: {},
        prefilesToBePrinted: {},
      },
      attorneyDashboard: {
        state: {},
      },
    },
  });

  const handleSubmitProfile = async (values) => {
    setLoading(true);
    const { landingPage, primaryState, ...rest } = values;
    const profileValues = {
      ...rest,
      primaryState: primaryState.value,
      landingPage: landingPage.value,
    };
    try {
      await dispatch(
        updateUserProfile({ values: profileValues, isCP: userInfo.isClientPortalUser }),
      ).unwrap();
      if (userInfo.isClientPortalUser) {
        dispatch(getCPUserProfile());
      } else {
        dispatch(getUserProfile());
      }
      notificationUtils.success('Saved successfully');
      onClose();
    } catch {
      notificationUtils.error('Failed, try again later');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitDashboardSettings = async (values) => {
    setLoading(true);
    const { state, casesToBeEntered, followUps, noticesToBeDrafted, prefilesToBePrinted } =
      values.LAdashboard;

    const LAsettingsValues = {
      casesToBeEntered: casesToBeEntered.value,
      followUps: followUps.value,
      noticesToBeDrafted: noticesToBeDrafted.value,
      prefilesToBePrinted: state?.value === 'AZ' ? prefilesToBePrinted.value : null,
      stateCode: state?.value,
    };

    const attorneySettingsValues = {
      stateCode: values.attorneyDashboard.state?.value,
      courtCalendarDto: {
        calendarRange: values.attorneyDashboard.calendarRange.value,
        attorney: values.attorneyDashboard.calendarAttorney.value,
      },
      overviewDto: {
        attorneyReview: values.attorneyDashboard.attorneyReview.value,
        deadlinesDueDatesOption: values.attorneyDashboard.deadlinesDueDate.value,
        deadlinesDueDatesAttorney: values.attorneyDashboard.deadlinesDueDateAttorney.value,
        civilMattersAttorney: values.attorneyDashboard.civilMatters.value,
      },
    };
    try {
      await dispatch(
        updateSettings({
          values: {
            legalSettingDto: isLADashboard ? LAsettingsValues : null,
            attorneySettingDto: isAttorneyDashboard ? attorneySettingsValues : null,
          },
          userId: userInfo.UserGuid,
        }),
      ).unwrap();
      dispatch(getSettings(userInfo.UserGuid));
      notificationUtils.success('Saved successfully');
      onClose();
    } catch {
      notificationUtils.error('Saved successfully');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (values) => {
    switch (tab.value) {
      case 'profile': {
        return handleSubmitProfile(values);
      }
      case 'dashboard': {
        return handleSubmitDashboardSettings(values);
      }
      default:
        return null;
    }
  };

  const content = useMemo(() => {
    switch (tab.value) {
      case 'profile': {
        return <Profile form={form} />;
      }
      case 'dashboard': {
        return <DashboardSettings form={form} />;
      }
      default:
        return null;
    }
  }, [tab.value]);

  return (
    <MDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Profile Editing"
      maxWidth="741px"
      minWidth="741px"
      scrollable
    >
      {isLoading && <LoaderCover />}
      <Form form={form} onSubmit={handleSubmit}>
        {isDashboardSettingAvailable && <Tabs tabs={tabs} onTabChange={setTab} currentTab={tab} />}
        {content}

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 4 }}
          justifyContent="flex-end"
          data-testid="dialog_actions"
        >
          <MButton size="large" variant="bordered" onClick={onClose}>
            Cancel
          </MButton>
          <MButton size="large" type="submit" startIcon={<Save />}>
            Save
          </MButton>
        </Stack>
      </Form>
    </MDialog>
  );
};

ProfileDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProfileDialog;
