import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SubPageHeading from '../../../organisms/SubPageHeading';
import { subPageHeading, subPageNav } from './NewCivilMatterCase.constants';
import useCases from '../../../../hooks/useCases';

const NewCivilMatterCase = () => {
  const {
    state: { stateCode },
  } = useLocation();

  const { actions } = useCases();

  useEffect(() => {
    actions.clear();
  }, []);

  return (
    <>
      <SubPageHeading
        subPageHeading={subPageHeading}
        subPageNav={subPageNav}
        nav={{
          to: '/db/processing/casesearch',
          state: {
            navPage: 'Processing',
            subNav: 'Cases',
            stateCode,
          },
        }}
      />
      <Outlet />
    </>
  );
};

export default NewCivilMatterCase;
