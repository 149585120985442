import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import useAuth from '../../../hooks/useAuth';
import { useAvailableStatesList } from '../../../hooks/useAvailableStatesList';
import Field from '../../atoms/Field';
import Dropdown from '../../atoms/Dropdown';
import { attorneyDashboardOptions } from './LoginWidget.constants';
import { ATTORNEYS_EXTRA_OPTION } from '../../pages/Reports/ProductivityByAttorney/ProductivityByAttorney.constants';
import { rolesDB } from '../../../utils/roleHelpers';
import { apiClient } from '../../../lib/apiClient';
import { UNASSIGNED_EXTRA_OPTION } from '../../pages/Dashboard/DashboardAttorney/DashboardAttorney.constants';

const AttorneyDashboardSettings = ({ form }) => {
  const { settings } = useSelector((state) => state.legalAssistantDashboard);

  const states = useAvailableStatesList();
  const [currentStateForAttorneyDashboard, setCurrentStateForAttorneyDashboard] = useState(
    states[0],
  );
  const [attorneys, setAttorneys] = useState([]);
  const { userInfo } = useAuth();
  const isCurrentUserAttorney = userInfo.roles.includes(rolesDB.Attorney);

  useEffect(() => {
    const formState = form.getValues('attorneyDashboard.state');
    if (currentStateForAttorneyDashboard.value !== formState) {
      form.setValue('attorneyDashboard.state', currentStateForAttorneyDashboard);
    }
  }, [currentStateForAttorneyDashboard, form.getValues('attorneyDashboard.state')]);

  useEffect(() => {
    const fetcher = async () => {
      const { data } = await apiClient.get(
        `/api/cases/attorneys?stateCode=${currentStateForAttorneyDashboard?.value}`,
      );
      const list = data.result.map((item) => ({
        id: item.userId,
        value: item.userId,
        label: `${item.firstName} ${item.lastName}`,
      }));
      const attorneyList = list?.slice();
      setAttorneys(attorneyList);

      const currentUserAttorney = attorneyList.find((item) => item.id === userInfo.UserGuid);
      if (isCurrentUserAttorney && currentUserAttorney) {
        form.setValue('attorneyDashboard.calendarAttorney', currentUserAttorney);
        form.setValue('attorneyDashboard.attorneyReview', currentUserAttorney);
        form.setValue('attorneyDashboard.deadlinesDueDateAttorney', currentUserAttorney);
        form.setValue('attorneyDashboard.civilMatters', currentUserAttorney);
      } else {
        form.setValue('attorneyDashboard.calendarAttorney', null);
        form.setValue('attorneyDashboard.attorneyReview', ATTORNEYS_EXTRA_OPTION);
        form.setValue('attorneyDashboard.deadlinesDueDateAttorney', ATTORNEYS_EXTRA_OPTION);
        form.setValue('attorneyDashboard.civilMatters', ATTORNEYS_EXTRA_OPTION);
      }
    };
    fetcher();
  }, [currentStateForAttorneyDashboard?.value]);

  useEffect(() => {
    if (settings) {
      const defaultCalendarRange = attorneyDashboardOptions.calendarRange.find(
        (item) => !!item.default,
      );
      const formState = form.getValues('attorneyDashboard.state');

      const currentStateCode = formState.value === 'AZ' ? 'arizonaAttorney' : 'nevadaAttorney';
      const currentCalendarRange = attorneyDashboardOptions.calendarRange.find(
        (item) => item.value === settings[currentStateCode]?.courtCalendarDto?.calendarRange,
      );
      const defaultDeadlinesDueDate = attorneyDashboardOptions.attorneyReviewDate.find(
        (item) => !!item.default,
      );
      const currentDeadlinesDueDate = attorneyDashboardOptions.attorneyReviewDate.find(
        (item) => item.value === settings[currentStateCode]?.overviewDto?.deadlinesDueDatesOption,
      );

      const currentCalendarAttorney = settings[currentStateCode]?.courtCalendarDto?.attorney
        ? [ATTORNEYS_EXTRA_OPTION, UNASSIGNED_EXTRA_OPTION, ...attorneys].find(
            (item) => item.value === settings[currentStateCode]?.courtCalendarDto?.attorney,
          )
        : '';
      const currentAttorneyReview = [
        ATTORNEYS_EXTRA_OPTION,
        UNASSIGNED_EXTRA_OPTION,
        ...attorneys,
      ].find((item) => item.value === settings[currentStateCode]?.overviewDto?.attorneyReview);
      const currentDeadlinesDueDateAttorney = [
        ATTORNEYS_EXTRA_OPTION,
        UNASSIGNED_EXTRA_OPTION,
        ...attorneys,
      ].find(
        (item) => item.value === settings[currentStateCode]?.overviewDto?.deadlinesDueDatesAttorney,
      );
      const currentCivilMatters = [
        ATTORNEYS_EXTRA_OPTION,
        UNASSIGNED_EXTRA_OPTION,
        ...attorneys,
      ].find(
        (item) => item.value === settings[currentStateCode]?.overviewDto?.civilMattersAttorney,
      );
      form.setValue(
        'attorneyDashboard.calendarRange',
        currentCalendarRange || defaultCalendarRange,
      );

      form.setValue(
        'attorneyDashboard.deadlinesDueDate',
        currentDeadlinesDueDate || defaultDeadlinesDueDate,
      );
      if (currentAttorneyReview) {
        form.setValue('attorneyDashboard.attorneyReview', currentAttorneyReview);
      }

      if (currentCalendarAttorney) {
        form.setValue('attorneyDashboard.calendarAttorney', currentCalendarAttorney);
      }

      if (currentDeadlinesDueDateAttorney) {
        form.setValue(
          'attorneyDashboard.deadlinesDueDateAttorney',
          currentDeadlinesDueDateAttorney,
        );
      }

      if (currentCivilMatters) {
        form.setValue('attorneyDashboard.civilMatters', currentCivilMatters);
      }
    }
  }, [settings, currentStateForAttorneyDashboard, attorneys]);

  const onStateChangeForAttorneyDashboard = (ev) => {
    form.setValue('attorneyDashboard.state', ev.target.value);
    setCurrentStateForAttorneyDashboard(ev.target.value);
  };

  const onAttorneySet = (ev) => {
    form.setValue('attorneyDashboard.calendarAttorney', ev.target.value);
  };

  return (
    <>
      <Typography variant="h5" sx={{ marginBottom: '16px', marginTop: '32px' }}>
        Attorney Dashboard
      </Typography>
      <Typography variant="bodyM" color="text.secondary" paragraph mb={1}>
        Configure default values for displaying Attorney dashboard sections
      </Typography>
      <Field
        name="attorneyDashboard.state"
        isMandatory
        render={({ field, onCustomChange }) => {
          return (
            <Dropdown
              label={
                <Typography
                  variant="bodyM"
                  color="text.secondary"
                  mb={0}
                  sx={{ position: 'relative', left: '-16px' }}
                  width="167px"
                >
                  Select state
                </Typography>
              }
              name="attorneyDashboard.state"
              value={field.value || states[0]}
              onChange={onCustomChange(onStateChangeForAttorneyDashboard)}
              options={states}
            />
          );
        }}
      />
      <Typography
        paragraph
        variant="bodyL"
        sx={{ marginBottom: '16px', fontWeight: 600, marginTop: '32px' }}
      >
        Court Calendar
      </Typography>
      <Stack direction="row" alignItems="center" mb={1} mt={1}>
        <Typography
          textAlign="right"
          variant="bodyM"
          color="text.secondary"
          paragraph
          mr={2}
          mb={0}
          width="167px"
        >
          Calendar Range
        </Typography>
        <Field
          name="attorneyDashboard.calendarRange"
          isMandatory
          render={({ field, onCustomChange }) => (
            <Dropdown
              isColumn
              width="493px"
              value={field.value}
              onChange={onCustomChange(field.onChange)}
              options={attorneyDashboardOptions.calendarRange}
            />
          )}
        />
      </Stack>
      <Stack direction="row" alignItems="center" mb={1} mt={1}>
        <Typography
          textAlign="right"
          variant="bodyM"
          color="text.secondary"
          paragraph
          mr={2}
          mb={0}
          width="167px"
        >
          Attorney
        </Typography>
        <Field
          name="attorneyDashboard.calendarAttorney"
          isMandatory
          render={({ field, onCustomChange }) => (
            <Dropdown
              isColumn
              width="493px"
              value={field.value}
              isSearchable
              onChange={onCustomChange(onAttorneySet)}
              options={[ATTORNEYS_EXTRA_OPTION, ...attorneys]}
            />
          )}
        />
      </Stack>
      <Typography
        paragraph
        variant="bodyL"
        sx={{ marginBottom: '16px', fontWeight: 600, marginTop: '32px' }}
      >
        Overview
      </Typography>
      <Stack direction="row" alignItems="center" mb={1} mt={1}>
        <Typography
          textAlign="right"
          variant="bodyM"
          color="text.secondary"
          paragraph
          mr={2}
          mb={0}
          width="167px"
        >
          Attorney Review
        </Typography>
        <Field
          name="attorneyDashboard.attorneyReview"
          isMandatory
          render={({ field, onCustomChange }) => (
            <Dropdown
              isColumn
              width="493px"
              value={field.value}
              onChange={onCustomChange(field.onChange)}
              isSearchable
              options={[ATTORNEYS_EXTRA_OPTION, UNASSIGNED_EXTRA_OPTION, ...attorneys]}
            />
          )}
        />
      </Stack>

      <Stack direction="row" alignItems="center" mb={1} mt={1}>
        <Typography
          textAlign="right"
          variant="bodyM"
          color="text.secondary"
          paragraph
          mr={2}
          mb={0}
          width="167px"
        >
          Deadlines & Due Dates <br />
          (Date)
        </Typography>
        <Field
          name="attorneyDashboard.deadlinesDueDate"
          isMandatory
          render={({ field, onCustomChange }) => (
            <Dropdown
              isColumn
              width="493px"
              value={field.value}
              onChange={onCustomChange(field.onChange)}
              options={attorneyDashboardOptions.attorneyReviewDate}
            />
          )}
        />
      </Stack>

      <Stack direction="row" alignItems="center" mb={1} mt={1}>
        <Typography
          textAlign="right"
          variant="bodyM"
          color="text.secondary"
          paragraph
          mr={2}
          mb={0}
          width="167px"
        >
          Deadlines & Due Dates <br />
          (Attorney)
        </Typography>
        <Field
          name="attorneyDashboard.deadlinesDueDateAttorney"
          isMandatory
          render={({ field, onCustomChange }) => (
            <Dropdown
              isColumn
              width="493px"
              value={field.value}
              onChange={onCustomChange(field.onChange)}
              isSearchable
              options={[ATTORNEYS_EXTRA_OPTION, ...attorneys]}
            />
          )}
        />
      </Stack>

      <Stack direction="row" alignItems="center" mb={1} mt={1}>
        <Typography
          textAlign="right"
          variant="bodyM"
          color="text.secondary"
          paragraph
          mr={2}
          mb={0}
          width="167px"
        >
          Civil Matters
        </Typography>
        <Field
          name="attorneyDashboard.civilMatters"
          isMandatory
          render={({ field, onCustomChange }) => (
            <Dropdown
              isColumn
              width="493px"
              value={field.value}
              onChange={onCustomChange(field.onChange)}
              isSearchable
              options={[ATTORNEYS_EXTRA_OPTION, UNASSIGNED_EXTRA_OPTION, ...attorneys]}
            />
          )}
        />
      </Stack>
    </>
  );
};

AttorneyDashboardSettings.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    watch: PropTypes.func,
    setValue: PropTypes.func,
    reset: PropTypes.func,
  }).isRequired,
};

export default AttorneyDashboardSettings;
