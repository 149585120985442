import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import Dropdown from '../../atoms/Dropdown';
import { portalOptions } from './Login.constants';
import LoginPageInformationBlock from '../../molecules/LoginPageInformationBlock/LoginPageInformationBlock';

const LoginLayout = ({ children, renderForm, clientOnly }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const portalDBOrCpOptions = portalOptions.filter((item) => !item.hidden);

  const [portal, setPortal] = useState(null);

  useEffect(() => {
    if (pathname) {
      setPortal(portalOptions.find((item) => item.path === pathname));
    }
  }, [pathname]);

  const handleChangePortal = (selectedValue) => {
    setPortal(selectedValue);
    navigate(selectedValue.path);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {!clientOnly && (
        <Stack position="absolute" top="0" right="0" pr={4} pt={2}>
          <Dropdown
            width="166px"
            value={portal}
            onChange={handleChangePortal}
            options={portalDBOrCpOptions}
          />
        </Stack>
      )}
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingRight: 4, paddingLeft: 4, margin: '0 auto' }}
        width="100vw"
        maxWidth="1200px"
      >
        <LoginPageInformationBlock />
        {renderForm({ portal })}
        {children}
      </Stack>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
  renderForm: PropTypes.func,
  clientOnly: PropTypes.bool,
};

LoginLayout.defaultProps = {
  children: null,
  renderForm: () => null,
  clientOnly: false,
};

export default LoginLayout;
