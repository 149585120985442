import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import MTooltip from '../../../../atoms/MTooltip';

const TextEllipsis = ({
  value,
  row: {
    original: { isTotalRow },
  },
}) => {
  if (isTotalRow) return null;

  return (
    <>
      <Typography
        sx={{
          textAlign: 'right',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '94%',
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        {value}
      </Typography>
      <MTooltip text={value} tooltipVariant="lightviolete" />
    </>
  );
};

TextEllipsis.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      isTotalRow: PropTypes.bool,
    }),
  }).isRequired,
};

TextEllipsis.defaultProps = {
  value: '',
};

export default TextEllipsis;
