import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import MTooltip from '../../atoms/MTooltip';
import { formatUSD } from '../../../utils/formatCurrency';

const estimatedTooltipMsgHtml =
  '<span style="font-size: 12px"><b style="font-size: 12px">Disclaimer:</b> The amount displayed here is an estimate of total legal fees and may vary on the final invoice. Please refer to the final invoice for the precise amount.<span>';

const EstimatedFeesBlock = ({ estimatedAmount }) => {
  if (estimatedAmount === null) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="baseline" mr={3}>
      <Typography color="text.secondary" whiteSpace="nowrap" mr={1} fontSize={16}>
        Estimated Legal Fees
      </Typography>
      <Typography variant="buttonLarge" data-testid="estimated_fee_amount">
        {formatUSD(estimatedAmount)}
      </Typography>
      <MTooltip
        text={<div dangerouslySetInnerHTML={{ __html: estimatedTooltipMsgHtml }} />}
        customStyles={{
          position: 'absolute',
          top: '-16px',
          right: '-26px',
        }}
      />
    </Stack>
  );
};

EstimatedFeesBlock.propTypes = { estimatedAmount: PropTypes.number };

EstimatedFeesBlock.defaultProps = { estimatedAmount: null };

export default EstimatedFeesBlock;
