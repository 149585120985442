import { palette } from '../../../theme/default';
import { ReactComponent as Danger } from '../../../assets/icons/Danger.svg';
import { ReactComponent as TickCircle } from '../../../assets/icons/TickCircle.svg';

export const messagesByStatus = {
  active: {
    text: (
      <>
        Connection to QuickBooks is{' '}
        <span style={{ color: palette.connectionStatuses.success }}>active</span>
      </>
    ),
    icon: <TickCircle />,
  },
  inactive: {
    text: (
      <>
        Connection to QuickBooks is{' '}
        <span style={{ color: palette.connectionStatuses.alert }}>inactive</span>, please renew
        connection
      </>
    ),
    icon: <Danger />,
  },
};
