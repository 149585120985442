import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import DocumentsTemplatesHeader from './DocumentsTemplatesHeader';
import SearchInputWithLabelAndIcon from '../../../../atoms/SearchInputWithLabelAndIcon/SearchInputWithLabelAndIcon';
import MButton from '../../../../MUI/Button/MButton';
import {
  getAllExisitingTemplates,
  getTemplatesGroups,
  downloadActiveTemplateByGroupId,
  getTemplatesNoticeTypes,
  resetTemplatesGroupes,
} from '../../../../../store/slices/dbAdmin/documentsSlice';
import { documentTemplatesColumns } from './DocumentsTemplates.constants';
import Table from '../../../../atoms/Table/Table';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import useSafeLocationState from '../../../../../hooks/useSafeLocationState';

const DocumentsTemplates = () => {
  const dispatch = useDispatch();

  const { filters, isSteppedBackFromDocumentsHistory } = useSafeLocationState('Admin', 'Documents');

  const { currentState, templatesGroups, templatesNoticeTypes, allExisitingTemplates } =
    useSelector((state) => state.adminDocuments);

  const [templateName, setTemplateName] = useState(null);
  const [noticeType, setNoticeType] = useState(null);

  useEffect(() => {
    if (isSteppedBackFromDocumentsHistory) {
      if (!currentState?.value) return;

      dispatch(getTemplatesNoticeTypes(currentState?.value)).then(() => {
        dispatch(
          getAllExisitingTemplates({
            templateName: filters?.templateName?.value,
            noticeType: filters?.noticeType?.value,
            stateCode: filters?.currentState?.value,
          }),
        ).then((response) => {
          const templateNameValue = response.payload.find(
            (item) => item.value === filters?.templateName?.value,
          );

          if (templateNameValue) {
            setTemplateName(templateNameValue);
          }

          if (filters?.noticeType?.value) {
            setNoticeType(filters?.noticeType);
          }

          dispatch(
            getTemplatesGroups({
              templateName: filters?.templateName?.value,
              stateCode: filters?.currentState?.value,
              noticeType: filters?.noticeType?.value,
            }),
          );
        });
      });
    }
  }, [isSteppedBackFromDocumentsHistory, currentState]);

  useEffect(() => {
    if (isSteppedBackFromDocumentsHistory) return;
    if (!currentState?.value) return;

    dispatch(getTemplatesNoticeTypes(currentState?.value)).then(() => {
      setNoticeType(filters?.noticeType);
    });
  }, [isSteppedBackFromDocumentsHistory, currentState]);

  const handleClearFilters = () => {
    setNoticeType(null);
    setTemplateName(null);
    dispatch(resetTemplatesGroupes());
  };

  useEffect(() => {
    if (!currentState?.value) return;
    handleClearFilters();
  }, [currentState?.value]);

  useEffect(() => {
    if (isSteppedBackFromDocumentsHistory) return;

    if (!currentState?.value) return;

    dispatch(
      getAllExisitingTemplates({
        templateName: templateName?.value,
        noticeType: noticeType?.value,
        stateCode: currentState?.value,
      }),
    );
  }, [templateName?.value, noticeType?.value, currentState?.value]);

  const handleTemplateNameSearch = useCallback(
    (value) => {
      if (value?.length < 3) return;

      dispatch(
        getAllExisitingTemplates({
          templateName: value,
          noticeType: noticeType?.value,
          stateCode: currentState?.value,
        }),
      );
    },
    [noticeType?.value, currentState?.value],
  );

  const handleTemplateNameCloseMenu = useCallback(() => {
    dispatch(
      getAllExisitingTemplates({
        templateName: '',
        noticeType: noticeType?.value,
        stateCode: currentState?.value,
      }),
    );
  }, [noticeType?.value, currentState?.value]);

  const handleTemplateNameOpenMenu = useCallback(() => {
    dispatch(
      getAllExisitingTemplates({
        templateName: '',
        noticeType: noticeType?.value,
        stateCode: currentState?.value,
      }),
    );
  }, [noticeType?.value, currentState?.value]);

  const handleSelectTemplate = (value) => {
    setTemplateName(value);
  };

  const handleChangeNoticeType = (value) => {
    setNoticeType(value);
  };

  const handleApply = () => {
    dispatch(
      getTemplatesGroups({
        templateName: templateName?.value,
        stateCode: currentState?.value,
        noticeType: noticeType?.value,
      }),
    );
  };

  const handleDownloadFile = (id, file) => {
    dispatch(downloadActiveTemplateByGroupId(file.templateGroupId)).then((res) => {
      const { content } = res.payload.data.result;
      const { contentType } = res.payload.data.result;
      const fileName = `${res.payload.data.result.originalFileName}.docx`;
      const uri = `data:${contentType};base64,${content}`;
      saveAs(uri, fileName);
    });
  };

  const iaApplyButtonDisabled = useMemo(() => {
    return !(templateName || noticeType);
  }, [templateName, noticeType]);

  const adaptedTableRows = useMemo(() => {
    return templatesGroups.map((item) => ({
      ...item,
      filters: {
        templateName,
        noticeType,
        currentState,
        templatesGroups,
      },
    }));
  }, [templatesGroups, templateName, noticeType, currentState]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        px: 4,
      }}
      data-testid="documents_templates_wrapper"
    >
      <DocumentsTemplatesHeader />
      <Stack flex direction="initial" alignItems="center" mt={4} mb={4}>
        <Stack flex direction="initial" gap={3}>
          <SearchInputWithLabelAndIcon
            options={allExisitingTemplates}
            placeholder="Select Template Name"
            label="Template Name"
            width="226px"
            height={44}
            isDropdown
            value={templateName}
            handleChange={handleSelectTemplate}
            handleSearch={handleTemplateNameSearch}
            onMenuClose={handleTemplateNameCloseMenu}
            onMenuOpen={handleTemplateNameOpenMenu}
            onEnter={handleApply}
            testid="search_input_template_type"
          />
          <SearchInputWithLabelAndIcon
            label="Notice type"
            options={templatesNoticeTypes}
            isDropdown
            value={noticeType}
            placeholder="Select Notice Type"
            handleChange={handleChangeNoticeType}
            width="288px"
            height={44}
            isSearchable={false}
            onEnter={handleApply}
            testid="search_input_notice_type"
          />
          <Stack
            flex
            gap={1}
            mb="12px"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <MButton
              type="submit"
              onClick={handleApply}
              disabled={iaApplyButtonDisabled}
              data-testid="documents_templates_apply_btn"
            >
              Apply
            </MButton>
            <MButton
              variant="bordered"
              onClick={handleClearFilters}
              data-testid="documents_templates_clear_filter_btn"
            >
              Clear Filter
            </MButton>
          </Stack>
        </Stack>
      </Stack>
      {templatesGroups.length ? (
        <Table
          width="1000px"
          rows={adaptedTableRows}
          columns={documentTemplatesColumns}
          onDownloadRow={handleDownloadFile}
        />
      ) : (
        <Stack w="100%" flexGrow={1} alignSelf="center" justifyContent="center">
          <EmptyBlock
            title="You don’t have any document templates yet"
            desc="Please use filter to find your document templates"
          />
        </Stack>
      )}
    </Container>
  );
};

export default DocumentsTemplates;
