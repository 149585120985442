import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { isBoolean } from 'lodash';
import {
  documentsLabelAndDropDown,
  documentTemplateHistoryColumns,
  MANUALLY_GENERATED_RADIO_BUTTONS,
} from './DocumentTemplateHistory.constants';
import MButton from '../../../../MUI/Button/MButton';
import DocumentTemplateHistoryUpload from './DocumentTemplateHistoryUpload';
import { ReactComponent as UploadDocumentIcon } from '../../../../../assets/icons/document-upload-white.svg';
import Table from '../../../../atoms/Table/Table';
import NotificationDialog from '../../../../molecules/NotificationDialog';
import {
  getTemplatesHistoryByGroupId,
  makeTemplateActive,
  downloadTemplateById,
  getStates,
  getTemplatesNoticeTypes,
} from '../../../../../store/slices/dbAdmin/documentsSlice';
import { apiClient } from '../../../../../lib/apiClient';
import EditInputWithLabel from '../../../../molecules/EditInputWithLabel/EditInputWithLabel';
import CustomRadioGroup from '../../../../atoms/CustomRadioGroup/CustomRadioGroup';
import MultilineTextField from '../../../../atoms/MultilineTextField/MultilineTextField';
import Dropdown from '../../../../atoms/Dropdown';
import notificationUtils from '../../../../../utils/notificationUtils';
import LoaderCover from '../../../../atoms/LoaderCover';

const DocumentTemplateHistory = () => {
  const dispatch = useDispatch();
  const {
    state: { templateGroupId, templateId, noticeType, templateType, stateCode },
  } = useLocation();

  const { templateHistory, templatesNoticeTypes, states } = useSelector(
    (state) => state.adminDocuments,
  );

  const [isForManualGeneration, setIsForManualGeneration] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (isBoolean(templateHistory?.isForManualGeneration)) {
      setIsForManualGeneration(templateHistory?.isForManualGeneration);
    }
  }, [templateHistory]);

  useEffect(() => {
    dispatch(getStates());
    dispatch(getTemplatesNoticeTypes(stateCode));
  }, []);

  useEffect(() => {
    dispatch(getTemplatesHistoryByGroupId({ templateGroupId }));
  }, [templateGroupId]);

  const [revertDialog, setRevertDialog] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(null);

  const handleOpenUploadVariables = () => {
    setModalIsOpen(true);
  };

  const handleConfirmDocumentsUpload = useCallback(
    (data, fileInfo) => {
      setModalIsOpen(false);

      const { isFileMoreThanFiveMb, isDocxFormat, documentName } = fileInfo[0];

      if (!isDocxFormat) {
        setTimeout(() => {
          setFileUploadError({
            title: 'File Error',
            desc: 'File is not in correct format. Please use .docx.',
          });
        }, 200);
        return;
      }

      if (isFileMoreThanFiveMb) {
        setTimeout(() => {
          setFileUploadError({
            title: 'File Error',
            desc: 'File is more than 5 Mb.',
          });
        }, 200);
        return;
      }

      setIsLoader(true);

      apiClient
        .post(
          `/api/templates/groups/${templateGroupId}/upload`,
          {
            File: data,
            Name: documentName.replace(/\.[^/.]+$/, ''),
            NoticeType: noticeType,
            TemplateType: templateType,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        )
        .then(() => {
          notificationUtils.success('Uploaded successfully');
          dispatch(getTemplatesHistoryByGroupId({ templateGroupId }));
        })
        .catch((error) => {
          const {
            response: { data: errorData },
          } = error;
          const { errorCode, errorMessage } = errorData;

          if (errorCode === 'value.is.invalid') {
            setFileUploadError({
              title: 'File Error',
              desc: errorMessage,
            });
          }

          if (errorCode === 'record.not.found') {
            setFileUploadError({
              title: 'Incorrect Variable(s)',
              desc: errorMessage,
            });
          }
        })
        .finally(() => {
          setIsLoader(false);
        });
    },
    [templateGroupId],
  );

  const handleCloseDownloadVariables = () => {
    setModalIsOpen(false);
  };

  const handleMakeActive = ({ templateId: tempId, templateGroupId: groupId }) => {
    dispatch(makeTemplateActive({ templateId: tempId, groupId })).then(() => {
      notificationUtils.success('Updated successfully');
      dispatch(getTemplatesHistoryByGroupId({ templateGroupId }));
    });
  };

  const handleDownloadTemplate = (templId) => {
    dispatch(downloadTemplateById(templId)).then((res) => {
      const { content } = res.payload.data.result;
      const { contentType } = res.payload.data.result;
      const fileName = `${res.payload.data.result.originalFileName}`;
      const uri = `data:${contentType};base64,${content}`;
      saveAs(uri, fileName);
    });
  };

  const closeUploadErrorNotification = () => {
    setFileUploadError(null);
  };

  const noticeTypeDropdownPlaceholder = useMemo(() => {
    return !noticeType ? '-' : null;
  }, [noticeType, templatesNoticeTypes]);

  const selectedDropDownState = useMemo(() => {
    return states ? states.find((item) => item.value === stateCode) : null;
  }, [states, stateCode]);

  const onChangeManualGeneration = (value) => {
    setIsForManualGeneration(value);
  };

  const onUpdate = useCallback(async () => {
    setIsLoader(true);
    await apiClient.put(`/api/templates/groups/${templateGroupId}`, {
      templateGroupId,
      isForManualGeneration,
    });
    notificationUtils.success('Updated successfully');
    dispatch(getTemplatesHistoryByGroupId({ templateGroupId }));
    setIsLoader(false);
  }, [isForManualGeneration, templateGroupId, templateId]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        px: 4,
      }}
      data-testid="document_template_history_wrapper"
    >
      {isLoader && <LoaderCover isFixed />}
      <Stack flex alignItems="center" flexDirection="initial" justifyContent="flex-start" mb={4}>
        <Typography variant="h3" pb={1}>
          {documentsLabelAndDropDown.label}
        </Typography>
        <Stack flexGrow={1} />
        <MButton
          startIcon={<UploadDocumentIcon />}
          onClick={handleOpenUploadVariables}
          data-testid="document_template_history_upload_btn"
        >
          Upload Template
        </MButton>
        <DocumentTemplateHistoryUpload
          isOpen={modalIsOpen}
          title="Upload Template"
          onConfirm={handleConfirmDocumentsUpload}
          handleClose={handleCloseDownloadVariables}
        />
      </Stack>
      <Stack display="flex" flexDirection="row" gap={3} mt={4} mb={4}>
        <EditInputWithLabel
          type="text"
          label="Template Name"
          width="280px"
          value={templateHistory?.templateName}
          isDisabled
        />
        <EditInputWithLabel
          type="text"
          label="Template Type"
          width="280px"
          value={templateHistory?.templateType}
          isDisabled
        />
        <MultilineTextField
          label="Notice Type"
          width="100%"
          placeholder={noticeTypeDropdownPlaceholder}
          value={templateHistory?.noticeType}
          disabled
        />
        <Dropdown
          label="State"
          isColumn
          width="360px"
          options={states}
          isDisabled
          value={selectedDropDownState}
        />
        <CustomRadioGroup
          data={MANUALLY_GENERATED_RADIO_BUTTONS}
          value={isForManualGeneration}
          onChange={onChangeManualGeneration}
          label="Manually Generated"
          width="209px"
          radioButtonWidth="100px"
        />
        <MButton
          onClick={onUpdate}
          sx={{
            alignSelf: 'center',
            marginTop: '26px',
          }}
          data-testid="document_template_history_update_btn"
        >
          Update
        </MButton>
      </Stack>
      <Table
        width="1000px"
        rows={templateHistory?.templates || []}
        onMakeActiveRow={handleMakeActive}
        columns={documentTemplateHistoryColumns}
        onDownloadRow={handleDownloadTemplate}
      />
      <NotificationDialog
        title="Do you want to revert to previous version or delete it?"
        desc="Deleting template will also delete all document generation rules connected to the template."
        type="alert"
        buttonSecondaryText="Revert"
        buttonPrimaryText="Delete"
        buttonSecondaryVariant=""
        extraButtonText="Cancel"
        onClose={() => setRevertDialog(false)}
        onConfirm={() => setRevertDialog(true)}
        isOpen={revertDialog}
      />
      <NotificationDialog
        title={fileUploadError?.title}
        desc={fileUploadError?.desc}
        type="alert"
        buttonSecondaryText=""
        buttonPrimaryText="Ok"
        onClose={closeUploadErrorNotification}
        onConfirm={closeUploadErrorNotification}
        isOpen={fileUploadError}
      />
    </Container>
  );
};

export default DocumentTemplateHistory;
