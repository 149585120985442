export const getLinkToNewTab = (path, state = {}) => {
  const { origin } = window.location;
  const params = Object.keys(state).reduce((acc, key) => {
    const value = state[key];
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      acc[`encoded_${key}`] = encodeURIComponent(JSON.stringify(value));
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
  const query = `?${new URLSearchParams({ ...params, path }).toString()}`;
  return `${origin}${query}`;
};

export const decodeParams = (params) =>
  Object.keys(params).reduce((acc, key) => {
    const value = params[key];
    if (key.startsWith('encoded_')) {
      const newKey = key.replace('encoded_', '');
      acc[newKey] = JSON.parse(decodeURIComponent(value));
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

export const navigateToNewTab = (path, state = {}) => {
  window.open(getLinkToNewTab(path, state), '_blank');
};
