import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, CircularProgress, Container, Stack, Typography, styled } from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import Dropdown from '../../../../atoms/Dropdown';
import Form from '../../../../atoms/Form';
import Field from '../../../../atoms/Field';
import EditInputWithLabel from '../../../../molecules/EditInputWithLabel';
import { palette } from '../../../../../theme/default';
import {
  getManagement,
  deleteManagementContactPerson,
  editManagementContacts,
  editManagement,
  createNewManagement,
  getManagementContacts,
} from '../../../../../store/slices/editManagementSlice';
import useManagement from '../../../../../hooks/useManagement';
import {
  managementHeading,
  managementBoxes,
  ContactPersonsColumns,
  invoiceDropdown,
  invoiceDetailDropdown,
} from './Management.constants';
import CustomRadioGroup from '../../../../atoms/CustomRadioGroup/CustomRadioGroup';
import Table from '../../../../atoms/Table/Table';
import MButton from '../../../../MUI/Button/MButton';
import ManagementContactPersonsDialog from './ManagementContactPersonsDialog';
import { ALL_STATES } from '../../../../../global/constants';
import { useNavigationBlockPopup } from '../../../../../hooks/useNavigationBlockPopup';
import notificationUtils from '../../../../../utils/notificationUtils';
import LoaderCover from '../../../../atoms/LoaderCover';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';

const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

const Management = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    state: { navPage, subNav, id },
  } = useLocation();
  const { editableManagement, editableManagementContacts } = useSelector(
    (state) => state.editManagement,
  );
  const { state, actions } = useManagement();
  const [isPageLoader, setIsPageLoader] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const form = useForm({ defaultValues: state });
  const { popup, handleChangeBlockerByDefault } = useNavigationBlockPopup({
    blockerByDefault: !id,
  });
  const canUpdate = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);
  useEffect(() => {
    if (id) {
      dispatch(getManagement(id)).then(() => setIsPageLoader(false));
    } else {
      setIsPageLoader(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      form.reset({
        ...editableManagement,
        contactInfo: {
          ...editableManagement.contactInfo,
          stateCode: ALL_STATES.find(
            (item) => item.label === editableManagement?.contactInfo?.stateCode,
          ),
        },
        invoicing: {
          ...editableManagement.invoicing,
          invoiceFrequency: invoiceDropdown.find(
            (item) => item.value === editableManagement?.invoicing?.invoiceFrequency,
          ),
          invoicingDetail: invoiceDetailDropdown.find(
            (item) => item.value === editableManagement?.invoicing?.invoicingDetail,
          ),
        },
      });
    } else {
      form.setValue(
        'invoicing.invoiceFrequency',
        invoiceDropdown.find((item) => item.isDefault),
      );
      form.setValue(
        'invoicing.invoicingDetail',
        invoiceDetailDropdown.find((item) => item.isDefault),
      );
    }
  }, [id, editableManagement]);

  useEffect(() => {
    if (id) {
      form.setValue('contacts', editableManagementContacts);
    }
  }, [id, editableManagementContacts]);

  const getOptions = React.useCallback((inputBoxesItemName) => {
    switch (inputBoxesItemName) {
      case 'contactInfo.stateCode':
        return ALL_STATES;
      case 'invoicing.invoiceFrequency':
        return invoiceDropdown || [];
      case 'invoicing.invoicingDetail':
        return invoiceDetailDropdown || [];
      default:
        return [];
    }
  }, []);

  const [isOpenContactPersonDialog, setIsOpenContactPersonDialog] = useState(false);

  const handleAddManagementAddresses = useCallback(() => {
    setIsOpenContactPersonDialog(true);
  }, []);

  const handleConfirmContactPersonsDialog = useCallback(
    (selectedContactIds) => {
      setIsOpenContactPersonDialog(false);
      if (id) {
        dispatch(
          editManagementContacts({
            managementId: id,
            selectedContacts: selectedContactIds,
          }),
        ).then(() => {
          dispatch(getManagementContacts(id));
        });
      } else {
        const currentContacts = form.getValues('contacts') || [];
        const contacts = currentContacts.concat(selectedContactIds);
        const uniqValues = uniqBy(contacts, (e) => e.contactId);
        form.setValue('contacts', uniqValues);
      }
    },
    [id],
  );

  // eslint-disable-line no-unused-vars
  const handleDeleteContactPersons = (rowId, { contactId }) => {
    if (id) {
      dispatch(deleteManagementContactPerson({ id, contactId })).then(() => {
        dispatch(getManagementContacts(id));
      });
    } else {
      const addedContacts = form.getValues('contacts') || [];
      const filteredContacts = addedContacts.filter((item) => item.contactId !== contactId);
      form.setValue('contacts', filteredContacts);
    }
  };

  const handleCloseContactPersonsDialog = useCallback(() => {
    setIsOpenContactPersonDialog(false);
  }, []);

  const handleSubmit = useCallback((formFields) => {
    const managementFormData = {
      managementName: formFields.managementName || '',
      managementCode: formFields.managementCode || '',
      contactInfo: {
        stateCode: formFields.contactInfo.stateCode.value || '',
        city: formFields.contactInfo.city || '',
        address: formFields.contactInfo.address || '',
        zipCode: formFields.contactInfo.zipCode || '',
        phone: formFields.contactInfo.phone || '',
        fax: formFields.contactInfo.fax || '',
        email: formFields.contactInfo.email || '',
        website: formFields.contactInfo.website || '',
      },
      invoicing: {
        isDeactivated: formFields.invoicing.isDeactivated,
        isOnHold: formFields.invoicing.isOnHold,
        onHoldDescription: formFields.invoicing.onHoldDescription || '',
        isAutoInvoicing: formFields.invoicing.isAutoInvoicing,
        isEmailInvoice: formFields.invoicing.isEmailInvoice,
        invoiceFrequency: formFields.invoicing.invoiceFrequency.value,
        invoicingDetail: formFields.invoicing.invoicingDetail.value,
      },
      secondaryKeys: {
        customerRefListId: formFields?.secondaryKeys?.customerRefListId,
        customerRefFullName: formFields?.secondaryKeys?.customerRefFullName,
      },
    };

    setIsLoader(true);
    if (id) {
      managementFormData.managementId = formFields.managementId;
      dispatch(
        editManagement({
          ...managementFormData,
          managementId: formFields.managementId,
          id: formFields.managementId,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(getManagement(id));
          notificationUtils.success('Updated successfully');
        })
        .catch((rejectedValueError) => {
          notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
        })
        .finally(() => {
          setIsLoader(false);
        });
    } else {
      managementFormData.contacts = [];
      if (formFields?.contacts?.length) {
        const mappedContact = formFields.contacts.map((item) => ({ contactId: item.contactId }));
        managementFormData.contacts = mappedContact;
      }
      handleChangeBlockerByDefault(false);
      dispatch(createNewManagement(managementFormData))
        .unwrap()
        .then((originalPromiseResult) => {
          const { result } = originalPromiseResult;
          notificationUtils.success('Created successfully');
          navigate('/db/datahandling/editmanagement/management', {
            state: {
              navPage,
              subNav,
              id: result,
              name: managementFormData.managementName,
            },
          });
          actions?.clear();
          form.reset();
        })
        .catch((rejectedValueError) => {
          notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
        })
        .finally(() => {
          setIsLoader(false);
        });
    }
  }, []);

  const handleCancel = useCallback(() => {
    navigate('/db/datahandling/managements', {
      state: {
        navPage,
        subNav,
      },
    });
  }, []);

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      {popup}
      {!isPageLoader ? (
        <>
          {isLoader && <LoaderCover isFixed />}
          <Typography variant="h4">{managementHeading}</Typography>
          <Form onSubmit={handleSubmit} form={form}>
            {managementBoxes?.map((data) => {
              return (
                <Container
                  key={data.name}
                  maxWidth={false}
                  disableGutters
                  sx={{
                    mx: 'auto',
                    mt: 4,
                    width: 1,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={3}
                    rowGap={2}
                    flexWrap="wrap"
                  >
                    {data.inputBoxes.map((item) => {
                      if (item.isDropdown) {
                        const options = getOptions(item.name);
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, onCustomChange, error }) => {
                              if (id && !canUpdate)
                                return (
                                  <EditInputWithLabel
                                    type="text"
                                    label={item.label}
                                    width={item.width}
                                    name={item.name}
                                    value={field?.value?.label || '-'}
                                    readOnly
                                  />
                                );
                              return (
                                <Dropdown
                                  value={field.value}
                                  isSearchable
                                  label={item.label}
                                  width={item.width}
                                  isColumn
                                  error={error}
                                  isMandatory={item.isMandatory}
                                  isDisabled={item.isDisabled}
                                  placeholder={item.placeholder}
                                  options={options}
                                  onChange={onCustomChange(field.onChange)}
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item.isRadioButtons) {
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, onCustomChange }) => {
                              if (id && !canUpdate) {
                                return (
                                  <EditInputWithLabel
                                    type="text"
                                    label={item.label}
                                    width={item.width}
                                    name={item.name}
                                    value={
                                      item.data.find((i) => i.value === field.value)?.label || '-'
                                    }
                                    readOnly
                                  />
                                );
                              }
                              return (
                                <CustomRadioGroup
                                  data={item.data}
                                  value={field.value}
                                  label={item.label}
                                  width={item.width}
                                  defaultValue={getRadioGroupDefaultValue(item.data)}
                                  radioButtonWidth={item.radioButtonWidth}
                                  onChange={onCustomChange(field.onChange)}
                                  disabled={item.isDisabled}
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item?.isSpace) {
                        return <div style={{ width: '100%' }} key={item.label} />;
                      }

                      if (item.isSubHeading) {
                        return (
                          <StyledTypography width={1} mb={1} variant="bodyL500" key={item.label}>
                            {item.label}
                          </StyledTypography>
                        );
                      }

                      if (item.isSubHeadingWithButton) {
                        return (
                          <Stack
                            direction="row"
                            alignContent="center"
                            columnGap={2}
                            mt={3}
                            key={item.label}
                          >
                            <StyledTypography width={1} variant="bodyL500">
                              {item.label}
                            </StyledTypography>
                            <MButton
                              onClick={handleAddManagementAddresses}
                              startIcon={item.buttonIcon}
                            >
                              {item.buttonName}
                            </MButton>
                          </Stack>
                        );
                      }

                      if (item.isTable) {
                        return (
                          <>
                            <Field
                              key={item.label}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              render={({ field }) => {
                                return field?.value?.length ? (
                                  <Table
                                    columns={ContactPersonsColumns}
                                    rows={field.value.map((contact) => ({
                                      ...contact,
                                      control: {
                                        hideDelete: !contact?.contactId,
                                      },
                                    }))}
                                    total={field?.value?.length}
                                    pagination={false}
                                    onDeleteRow={handleDeleteContactPersons}
                                    loading={false}
                                  />
                                ) : null;
                              }}
                            />
                            <ManagementContactPersonsDialog
                              title="Contact Person"
                              buttonSecondaryText="Cancel"
                              buttonPrimaryText="Add"
                              onClose={handleCloseContactPersonsDialog}
                              onConfirm={handleConfirmContactPersonsDialog}
                              isOpen={isOpenContactPersonDialog}
                            />
                          </>
                        );
                      }

                      return (
                        <Field
                          key={item.label}
                          name={item.name}
                          isMandatory={item.isMandatory}
                          render={({ field, error }) => (
                            <EditInputWithLabel
                              type="text"
                              error={error}
                              label={item.label}
                              placeholder={item.placeholder}
                              width={item.width}
                              name={item.name}
                              isMandatory={((id && canUpdate) || !id) && item.isMandatory}
                              value={id && !canUpdate ? field.value || '-' : field.value}
                              onChange={field.onChange}
                              isDisabled={item.isDisabled}
                              readOnly={id && !canUpdate}
                            />
                          )}
                        />
                      );
                    })}
                  </Stack>
                </Container>
              );
            })}
            {((id && canUpdate) || !id) && (
              <Box
                position="absolute"
                left="50%"
                bottom={0}
                sx={{ transform: 'translateX(-50%)' }}
                zIndex={10}
                width={1}
                p={4}
                boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
              >
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <MButton
                    variant="secondary"
                    size="large"
                    onClick={handleCancel}
                    sx={{ width: '144px' }}
                    disabled={isLoader}
                  >
                    Cancel
                  </MButton>
                  <MButton
                    type="submit"
                    size="large"
                    name="Submit community"
                    sx={{ width: '144px' }}
                    disabled={isLoader}
                  >
                    {id ? 'Update' : 'Create'}
                  </MButton>
                </Stack>
              </Box>
            )}
          </Form>
        </>
      ) : (
        <Box
          position="absolute"
          top="50vh"
          left="50%"
          zIndex={10}
          transform="translate(-50%, -50%)"
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default Management;
