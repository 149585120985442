/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { reverse, sortBy, prop } from 'ramda';
import { apiClient } from '../../../lib/apiClient';

export const getArizonaAttorneys = createAsyncThunk(
  'caseInformationArizona/getArizonaAttorneys',
  async (id) => {
    const { data } = await apiClient.get(`/api/cases/${id}/attorneys`);
    return data.result.map(({ userId, firstName, lastName, attorneyRate }) => {
      return {
        id: userId,
        label: `${firstName} ${lastName}`,
        value: userId,
        attorneyRate,
      };
    });
  },
);

export const getArizonaLegalAssistants = createAsyncThunk(
  'caseInformationArizona/getArizonaLegalAssistants',
  async (id) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${id}/legalAssistants`);
    return data.result.map(({ userId, firstName, lastName }) => {
      return {
        id: userId,
        label: `${firstName} ${lastName}`,
        value: userId,
      };
    });
  },
);

export const getArizonaProceduralHistoryEntries = createAsyncThunk(
  'caseInformationArizona/getArizonaProceduralHistoryEntries',
  async (id) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${id}/proceduralHistoryEntries`);
    return data;
  },
);

export const addArizonaProceduralHistoryEntry = createAsyncThunk(
  'caseInformationArizona/addArizonaProceduralHistoryEntry',
  async ({ id, ...rest }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(
        `/api/arizonaCases/${id}/proceduralHistoryEntries`,
        rest,
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const updateArizonaProceduralHistoryEntry = createAsyncThunk(
  'caseInformationArizona/updateArizonaProceduralHistoryEntry',
  async ({ id, proceduralHistoryEntryId, body }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(
        `/api/arizonaCases/${id}/proceduralHistoryEntries/${proceduralHistoryEntryId}`,
        body,
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const removeArizonaProceduralHistoryEntry = createAsyncThunk(
  'caseInformationArizona/removeArizonaProceduralHistoryEntry',
  async ({ id, proceduralEntryId }) => {
    const { data } = await apiClient.delete(
      `/api/arizonaCases/${id}/proceduralHistoryEntries/${proceduralEntryId}`,
    );

    return data;
  },
);

export const getArizonaStatuses = createAsyncThunk(
  'caseInformationArizona/getArizonaStatuses',
  async () => {
    const { data } = await apiClient.get(`/api/arizonaCases/statuses?OnlyManual=true`);

    return data.result.map(({ value }, id) => ({
      id,
      value,
      label: value,
    }));
  },
);

export const addArizonaStatusToCase = createAsyncThunk(
  'caseInformationArizona/addArizonaStatusToCase',
  async ({ id, ...rest }) => {
    const { data } = await apiClient.post(`/api/arizonaCases/${id}/statuses`, rest);
    return data;
  },
);

export const getArizonaCaseInformation = createAsyncThunk(
  'caseInformationArizona/getArizonaCaseInformation',
  async (id) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${id}/caseInformation`);
    return data;
  },
);

export const getArizonaStatusesForCase = createAsyncThunk(
  'caseInformationArizona/getArizonaStatusesForCase',
  async (id) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${id}/statuses`);

    const caseStatusToRevert = data?.result?.find((item) => {
      const isSystem = item.updatedBy.trim() === 'system';
      const isArchived = item.status === 'Archived';
      return isArchived || !isSystem;
    });

    let statusObj = null;
    if (caseStatusToRevert) {
      statusObj = await apiClient.get(`/api/arizonaCases/${id}/statuses/canRevert`);
    }

    const adoptedStatuses = data?.result.map((item) => {
      if (item.caseStatusId === caseStatusToRevert?.caseStatusId) {
        return {
          ...item,
          caseId: id,
          mayBeReverted: statusObj?.data?.result,
        };
      }
      return { ...item, item, mayBeReverted: false };
    });

    return adoptedStatuses;
  },
);

export const getArizonaNoticeTypes = createAsyncThunk(
  'caseInformationArizona/getArizonaNoticeTypes',
  async () => {
    const { data } = await apiClient.get('/api/arizonaCases/noticeTypes');
    return data.result.map(({ noticeTypeGroup, noticeType }, id) => ({
      id,
      value: noticeType,
      label: noticeType,
      group: noticeTypeGroup,
    }));
  },
);

export const getArizonaViolationTemplatesForCase = createAsyncThunk(
  'caseInformationArizona/getArizonaViolationTemplatesForCase',
  async (caseId) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${caseId}/violations/templates`);
    return data;
  },
);

export const getArizonaNoticeTemplateData = createAsyncThunk(
  'caseInformationArizona/getArizonaNoticeTemplateData',
  async ({ id, templateId }) => {
    const {
      data: { result },
    } = await apiClient.get(`/api/arizonaCases/${id}/templates/${templateId}/fields`);

    return {
      id,
      templateId,
      fields: result.fields,
    };
  },
);

export const getArizonaViolationData = createAsyncThunk(
  'caseInformationArizona/getArizonaViolationData',
  async ({ violationId, id }) => {
    const {
      data: { result },
    } = await apiClient.get(`/api/arizonaCases/${id}/violations/${violationId}/fields`);
    return {
      violationId,
      id,
      fields: result.fields,
    };
  },
);

export const addArizonaCasesViolations = createAsyncThunk(
  'caseInformationArizona/addArizonaCasesViolations',
  async (values) => {
    const { id, ...rest } = values;
    const { data } = await apiClient.post(`/api/arizonaCases/${id}/violations`, rest);
    return data;
  },
);

export const getArizonaCasesViolations = createAsyncThunk(
  'caseInformationArizona/getArizonaCasesViolations',
  async (id) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${id}/violations`);
    return data;
  },
);

export const deleteArizonaCasesViolations = createAsyncThunk(
  'caseInformationArizona/deleteArizonaCasesViolations',
  async ({ id, violationId }) => {
    const { data } = await apiClient.delete(`/api/cases/${id}/violations/${violationId}`);
    return data;
  },
);

export const editArizonaCasesViolations = createAsyncThunk(
  'caseInformationArizona/editArizonaCasesViolations',
  async (editInfo) => {
    const { caseId, caseViolationId } = editInfo;
    const { data } = await apiClient.put(
      `/api/cases/${caseId}/violations/${caseViolationId}`,
      editInfo,
    );
    return data;
  },
);

export const updateArizonaCaseNumber = createAsyncThunk(
  'caseInformationArizona/updateArizonaCaseNumber',
  async ({ id, ...rest }) => {
    const { data } = await apiClient.put(`/api/arizonaCases/${id}/caseInformation`, {
      caseId: id,
      ...rest,
    });
    return data;
  },
);

export const updateDamages = createAsyncThunk(
  'caseInformationArizona/updateDamages',
  async (params) => {
    const { caseId, ...rest } = params;
    const { data } = await apiClient.put(`/api/arizonaCases/${caseId}/damages`, rest);
    return data;
  },
);

export const getAraizonaRelatedCases = createAsyncThunk(
  'caseInformationArizona/getAraizonaRelatedCases',
  async ({ caseId }) => {
    const { data } = await apiClient.get(`/api/arizonaCases/${caseId}/relativeCases`);
    return data;
  },
);

export const resetArizonaCaseInformation = createAction(
  'caseInformationArizona/resetArizonaCaseInformation',
  () => {
    return {
      type: 'caseInformationArizona/resetArizonaCaseInformation',
    };
  },
);

export const getArizonaPrefileDate = createAsyncThunk(
  'caseInformationArizona/getArizonaPrefileDate',
  async (params, { signal }) => {
    const { data } = await apiClient.post(`/api/arizonaCases/precalculations/fileDate`, params, {
      signal,
    });
    return data?.result || null;
  },
);

export const getArizonaWritDate = createAsyncThunk(
  'caseInformationArizona/getArizonaWritDate',
  async (params, { signal }) => {
    const { data } = await apiClient.post(`/api/arizonaCases/precalculations/writDate`, params, {
      signal,
    });
    return data?.result || null;
  },
);

const initialState = {
  proceduralHistoryEntries: [],
  arizonaCaseInformation: {},
  relatedArizonaCases: null,
  caseCreationDate: null,
  arizonaViolations: [],
  arizonaStatuses: [],
  violationData: null,
  hearingTypes: [],
  noticeTypes: [],
  noticeTemplateData: null,
  violationTemplates: [],
  attorneys: [],
  legalAssistants: [],
  statuses: [],
  errors: null,
};

export const caseInformationArizona = createSlice({
  name: 'caseInformationArizona',
  initialState,
  reducers: {},
  extraReducers: {
    [getAraizonaRelatedCases.fulfilled]: (state, action) => {
      state.relatedArizonaCases = action.payload.result;
    },
    [deleteArizonaCasesViolations.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [updateArizonaCaseNumber.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [removeArizonaProceduralHistoryEntry.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [addArizonaProceduralHistoryEntry.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [updateArizonaProceduralHistoryEntry.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [addArizonaCasesViolations.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [addArizonaStatusToCase.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getArizonaCasesViolations.fulfilled]: (state, action) => {
      state.arizonaViolations = action.payload.result;
    },
    [getArizonaCasesViolations.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getArizonaNoticeTypes.fulfilled]: (state, action) => {
      state.noticeTypes = action.payload;
    },
    [getArizonaNoticeTypes.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getArizonaNoticeTemplateData.fulfilled]: (state, action) => {
      state.noticeTemplateData = action.payload;
    },
    [getArizonaViolationTemplatesForCase.fulfilled]: (state, action) => {
      state.violationTemplates = action.payload.result.map((t) => ({
        id: t.templateId,
        label: t.templateName,
        type: t.templateType,
        value: t.templateId,
      }));
    },
    [getArizonaViolationData.fulfilled]: (state, action) => {
      state.violationData = action.payload;
    },
    [getArizonaStatusesForCase.fulfilled]: (state, action) => {
      state.arizonaStatuses = reverse(sortBy(prop('caseStatusId'), action.payload));
    },
    [getArizonaStatusesForCase.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [resetArizonaCaseInformation]: (state) => {
      state.arizonaCaseInformation = {};
    },
    [getArizonaCaseInformation.fulfilled]: (state, action) => {
      state.caseCreationDate = action.payload.result.creationDate;
      state.arizonaCaseInformation = action.payload.result;
    },
    [getArizonaCaseInformation.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getArizonaStatuses.fulfilled]: (state, action) => {
      state.statuses = action.payload;
    },
    [getArizonaStatuses.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getArizonaAttorneys.fulfilled]: (state, action) => {
      state.attorneys = action.payload;
    },
    [getArizonaLegalAssistants.fulfilled]: (state, action) => {
      state.legalAssistants = action.payload;
    },
    [getArizonaAttorneys.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getArizonaProceduralHistoryEntries.fulfilled]: (state, action) => {
      state.proceduralHistoryEntries = action.payload.result;
    },
    [getArizonaProceduralHistoryEntries.rejected]: (state, action) => {
      state.errors = action.payload;
    },
  },
});

export default caseInformationArizona.reducer;
