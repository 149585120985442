import React, { useEffect, useState } from 'react';
import { Box, styled, Typography, Stack, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { chartTimePeriods } from './ClientPortalDashboardChart.constants';
import { getListOfAllRequestsForChart } from '../../../../store/slices/clientPortalDashboardSlice';
import { useClientPortalDashboardLineChart } from './hooks';
import PeriodButtons from '../../../atoms/PeriodButtons';

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '24px',
  border: `1px solid ${theme.palette.additional.lines}`,
  borderRadius: '16px',
}));

const ClientPortalDashboardChart = ({ stateCode }) => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(true);
  const [timePeriod, setTimePeriod] = useState('1w');

  useEffect(() => {
    setIsLoader(true);
    dispatch(getListOfAllRequestsForChart({ timePeriod, stateCode })).then(() =>
      setIsLoader(false),
    );
  }, [timePeriod, stateCode]);

  const { data, options, plugins } = useClientPortalDashboardLineChart({ period: timePeriod });

  return (
    <Wrapper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" rowGap={1}>
        <Typography variant="h5">Requests</Typography>

        <PeriodButtons
          currentPeriod={timePeriod}
          isDisabled={isLoader}
          onPeriodChange={setTimePeriod}
          periods={chartTimePeriods}
        />
      </Stack>
      <Box sx={{ minHeight: '276px' }}>
        {isLoader ? (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            zIndex={10}
            transform="translate(-50%, -50%)"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Line height="276px" data={data} options={options} plugins={plugins} />
        )}
      </Box>
    </Wrapper>
  );
};

ClientPortalDashboardChart.propTypes = {
  stateCode: PropTypes.string.isRequired,
};

export default ClientPortalDashboardChart;
