import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/Add.svg';

export const communityHeading = 'Community Properties';

export const invoiceDetailDropdown = [
  {
    id: 1,
    value: 'Community',
    label: 'Community',
    isDefault: true,
  },
  {
    id: 2,
    value: 'Address',
    label: 'Address',
    isDefault: false,
  },
];

export const communityBoxes = [
  {
    name: '',
    inputBoxes: [
      {
        label: 'Community Name',
        name: 'communityName',
        placeholder: 'Enter community name',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'Community Code',
        name: 'communityCode',
        placeholder: '-',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Management Company',
        name: 'managementCompanyName',
        placeholder: 'Select management company',
        width: '326px',
        isDynamicSearchDropdown: true,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
    ],
  },
  {
    name: 'contactInfo',
    inputBoxes: [
      {
        label: 'Contact Info',
        isSubHeading: true,
      },
      {
        label: 'Address',
        name: 'contactInfo.address',
        placeholder: 'Enter address',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'City',
        name: 'contactInfo.city',
        placeholder: 'Enter city',
        width: '208px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'State',
        name: 'contactInfo.stateCode',
        placeholder: '-',
        width: '94px',
        isDropdown: true,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'ZIP Code',
        name: 'contactInfo.zipCode',
        placeholder: 'xx xx xx',
        width: '208px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'Phone',
        name: 'contactInfo.phone',
        placeholder: '+1(480)xx xx xx xx',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'Fax',
        name: 'contactInfo.fax',
        placeholder: '+1(480)xx xx xx xx',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Email',
        name: 'contactInfo.email',
        placeholder: 'Enter email',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
    ],
  },
  {
    name: '',
    inputBoxes: [
      {
        label: 'Owner Entity(s)',
        isSubHeading: true,
      },
      {
        label: '',
        name: 'ownerEntities',
        placeholder: '-',
        width: '676px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: true,
      },
    ],
  },
  {
    name: 'contacts',
    inputBoxes: [
      {
        label: 'Contact Persons',
        isSubHeading: false,
        isSubHeadingWithButton: true,
        buttonName: 'Add',
        buttonIcon: <AddIcon />,
      },
      {
        label: '',
        name: 'contacts',
        placeholder: '',
        width: '444px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
    ],
  },
  {
    name: 'invoicing',
    inputBoxes: [
      {
        label: 'Invoicing',
        isSubHeading: true,
      },
      {
        label: 'Status',
        name: 'invoicing.isDeactivated',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: 'auto',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Active', value: false },
          { id: 1, label: 'Inactive', value: true },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'On Hold',
        name: 'invoicing.isOnHold',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '116px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'On Hold Description',
        name: 'invoicing.onHoldDescription',
        placeholder: 'Enter on hold description',
        width: '464px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Automate Invoicing',
        name: 'invoicing.isAutoInvoicing',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '116px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false, isDefault: true },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Email Invoice',
        name: 'invoicing.isEmailInvoice',
        placeholder: '',
        width: 'auto',
        radioButtonWidth: '116px',
        isDropdown: false,
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false },
        ],
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Invoice Frequency',
        name: 'invoicing.invoiceFrequency',
        width: '232px',
        isDropdown: true,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Invoicing Details',
        name: 'invoicing.invoicingDetail',
        width: '260px',
        isDropdown: true,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
    ],
  },
  {
    name: 'secondaryKeys',
    inputBoxes: [
      {
        label: 'Secondary Keys',
        isSubHeading: true,
      },
      {
        label: 'Customer Ref_List ID',
        name: 'secondaryKeys.customerRefListId',
        placeholder: '-',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Customer Ref_Full Name',
        name: 'secondaryKeys.customerRefFullName',
        placeholder: '-',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: false,
      },
    ],
  },
];

export const contactColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    width: 20,
  },
  {
    Header: 'Title',
    accessor: 'title',
    width: 16,
  },
  {
    Header: 'Work #',
    accessor: 'workPhone',
    width: 20,
  },
  {
    Header: 'Mobile',
    accessor: 'mobilePhone',
    width: 20,
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 20,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];

export const invoiceDropdown = [
  {
    id: 1,
    value: 'Bi-Monthly',
    label: 'Bi-Monthly',
    isDefault: false,
  },
  {
    id: 2,
    value: 'Weekly',
    label: 'Weekly',
    isDefault: false,
  },
  {
    id: 3,
    value: 'Monthly',
    label: 'Monthly',
    isDefault: true,
  },
];
