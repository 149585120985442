const AZJustice = [
  {
    label: 'Internal Court Fees',
    name: 'internalCourtFees',
    isSubSubHeading: true,
  },
  {
    label: 'Attorney Fee',
    name: 'internalFeesAndCosts.attorneyFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Attoney Fee Immediate',
    name: 'internalFeesAndCosts.attorneyFeeImmediate',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Court Cost',
    name: 'internalFeesAndCosts.courtCost',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Max Total Late Charges',
    name: 'internalFeesAndCosts.maxTotalLateCharges',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  { isSpace: true },
  {
    label: 'Writ Cost',
    name: 'internalFeesAndCosts.writCostJC',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Writ Cost Constable',
    name: 'internalFeesAndCosts.writCostConstable',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Mileage',
    name: 'internalFeesAndCosts.milage',
    placeholder: '-',
    width: '290px',
  },
  {
    label: 'Forcible Cost',
    name: 'internalFeesAndCosts.forcibleCost',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  { isSpace: true },
  {
    label: 'Dismissal Fee (Court Attorney Fees - Notice of Voluntary Dismissal by Judge)',
    name: 'internalFeesAndCosts.dismissalFeeVoluntaryByJudge',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  {
    label: 'Dismissal Fee (Ex Parte Motion to Vacate Judgment)',
    name: 'internalFeesAndCosts.dismissalFeeVacateJudgement',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  {
    label: 'Notice Fee',
    name: 'internalFeesAndCosts.noticeFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'External Billing Fees',
    name: 'externalBillingFees',
    isSubSubHeading: true,
  },
  {
    label: 'Court Filing Fee',
    name: 'externalFeesAndCosts.filingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Judgment Fee',
    name: 'externalFeesAndCosts.judgementFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Judgment Fee Immediate',
    name: 'externalFeesAndCosts.judgementFeeImmediate',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Trial Fee',
    name: 'externalFeesAndCosts.trialFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Dismissal Fee',
    name: 'externalFeesAndCosts.dismissalFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  { isSpace: true },
  {
    label: 'Satisfaction Fee',
    name: 'externalFeesAndCosts.satisfactionFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Writ Fee',
    name: 'externalFeesAndCosts.writFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Eviction Processing Fee',
    name: 'externalFeesAndCosts.evictionProcessingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Trial Fee - Immediate',
    name: 'externalFeesAndCosts.trialFeeImmediate',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  { isSpace: true },
  {
    label: 'Dismissal Fee (Court Attorney Fees - Notice of Voluntary Dismissal by Judge)',
    name: 'externalFeesAndCosts.dismissalFeeVoluntaryByJudge',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  {
    label: 'Dismissal Fee (Ex Parte Motion to Vacate Judgment)',
    name: 'externalFeesAndCosts.dismissalFeeVacateJudgement',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  { isSpace: true },
  {
    label: 'Process Server Fee',
    name: 'externalFeesAndCosts.processServerFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Process Server Fee Billable',
    name: 'externalFeesAndCosts.processServerFeeBillable',
    placeholder: '',
    width: 'auto',
    radioButtonWidth: '100px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true, isDefault: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Notice Fee',
    name: 'externalFeesAndCosts.noticeFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Preparation Fee',
    name: 'externalFeesAndCosts.preparationFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
];

const AZSuperior = [
  {
    label: 'Internal Court Fees',
    name: 'internalCourtFees',
    isSubSubHeading: true,
  },
  {
    label: 'Superior Court Filing Fee',
    name: 'internalFeesAndCosts.filingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Superior Court Judgement Fee',
    name: 'internalFeesAndCosts.judgementFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Superior Court Writ (Pima)',
    name: 'internalFeesAndCosts.writPima',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Superior Court Writ of Restitution',
    name: 'internalFeesAndCosts.writOfRestitution',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Notice Fee',
    name: 'internalFeesAndCosts.noticeFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  { isSpace: true },
  {
    heading: 'Superior Court Attorney Fees - Notice of Voluntary Dismissal by Judge',
    label: 'Dismissal Fee',
    isFieldWithHeading: true,
    name: 'internalFeesAndCosts.dismissalFeeVoluntaryByJudge',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  {
    heading: 'Superior Court Attorney Fees - Notice of Voluntary Dismissal',
    label: 'Dismissal Fee',
    isFieldWithHeading: true,
    name: 'internalFeesAndCosts.dismissalFeeVoluntary',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  {
    label: 'External Billing Fees',
    name: 'externalBillingFees',
    isSubSubHeading: true,
  },
  {
    label: 'Superior Court Filing Fee',
    name: 'externalFeesAndCosts.filingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Superior Court Judgement Fee',
    name: 'externalFeesAndCosts.judgementFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Superior Court Writ (Pima)',
    name: 'externalFeesAndCosts.writPima',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Superior Court Writ of Restitution',
    name: 'externalFeesAndCosts.writOfRestitution',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  { isSpace: true },
  {
    label: 'Process Server Fee',
    name: 'externalFeesAndCosts.processServerFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Process Server Fee Billable',
    name: 'externalFeesAndCosts.processServerFeeBillable',
    placeholder: '',
    width: 'auto',
    radioButtonWidth: '100px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true, isDefault: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Notice Fee',
    name: 'externalFeesAndCosts.noticeFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Preparation Fee',
    name: 'externalFeesAndCosts.preparationFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Eviction Processing Fee',
    name: 'externalFeesAndCosts.evictionProcessingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  { isSpace: true },
  {
    heading: 'Superior Court Attorney Fees - Notice of Voluntary Dismissal by Judge',
    label: 'Dismissal Fee',
    isFieldWithHeading: true,
    name: 'externalFeesAndCosts.dismissalFeeVoluntaryByJudge',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  {
    heading: 'Superior Court Attorney Fees - Notice of Voluntary Dismissal',
    label: 'Dismissal Fee',
    isFieldWithHeading: true,
    name: 'externalFeesAndCosts.dismissalFeeVoluntary',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
];

const NV = [
  {
    label: 'Court Fees',
    name: 'courtFees',
    isSubSubHeading: true,
  },
  {
    label: 'Non-Payment Notice Fee',
    name: 'feesAndCosts.nonPaymentNoticeFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Other-Than Notice Fee',
    name: 'feesAndCosts.otherThanNoticeFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Mileage',
    name: 'feesAndCosts.milage',
    placeholder: '-',
    width: '290px',
  },
  {
    label: 'Attorney Fee - Summary Eviction',
    name: 'feesAndCosts.attorneyFeeSummaryEviction',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  { isSpace: true },
  {
    label: 'Court Filing Fee',
    name: 'feesAndCosts.courtFilingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Constable Fee',
    name: 'feesAndCosts.constableFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Rescission Fee',
    name: 'feesAndCosts.rescissionFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Preparation Fee',
    name: 'feesAndCosts.preparationFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Dismissal Fee',
    name: 'feesAndCosts.dismissalFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
];

const getFeesAndCosts = ({ stateCode, courtType }) => {
  if (stateCode === 'AZ' && courtType === 'Justice') return AZJustice;
  if (stateCode === 'AZ' && courtType === 'Superior') return AZSuperior;
  if (stateCode === 'NV') return NV;
  return [];
};

export default getFeesAndCosts;
