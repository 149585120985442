import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Typography, Stack, styled, IconButton } from '@mui/material';
import MButton from '../../../MUI/Button/MButton';
import MDialog from '../../../atoms/MDialog/MDialog';
import Form from '../../../atoms/Form';
import Dropzone from '../../../atoms/Dropzone';
import { ReactComponent as Document } from '../../../../assets/icons/Document.svg';
import LoaderCover from '../../../atoms/LoaderCover';
import notificationUtils from '../../../../utils/notificationUtils';
import { ReactComponent as Trash } from '../../../../assets/icons/Trash.svg';
import { addObjToFormData } from '../../../../utils/convertObjToFormData';
import {
  bulkAZNoticeRequest,
  bulkNoticeRequest,
} from '../../../../store/slices/requests/nonPaymentRequest';

const FileStack = styled(Stack)({
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
  border: '1px solid #DCE4FF',
  borderRadius: '10px',
});

const BulkNoticeRequestDialog = ({ isOpen, onClose, callback, stateCode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {
      file: null,
    },
    mode: 'onChange',
  });

  const onCancelPress = () => {
    onClose();
    form.reset();
  };

  const onSuccess = (data) => {
    onClose(data);
    form.reset();
  };

  const handleSubmit = form.handleSubmit((formValues) => {
    setIsLoading(true);
    callback?.(formValues.file);
    const method = stateCode === 'NV' ? bulkNoticeRequest : bulkAZNoticeRequest;
    dispatch(
      method(
        addObjToFormData({
          request: formValues.file,
        }),
      ),
    )
      .unwrap()
      .then((data) => {
        notificationUtils.success('Uploaded successfully');
        onSuccess(data);
      })
      .catch((e) => {
        notificationUtils.error(e || 'Failed, try again later');
      })
      .finally(() => setIsLoading(false));
  });

  const onDrop = (onChange) => (acceptedFiles) => {
    if (!acceptedFiles?.length) return;
    const file = acceptedFiles[0];
    onChange(file);
  };

  return (
    <MDialog
      isOpen={isOpen}
      onClose={onCancelPress}
      title="Bulk Notice Request"
      maxWidth="sm"
      fullWidth
      footerActions={[
        <MButton size="large" variant="bordered" onClick={onCancelPress}>
          Cancel
        </MButton>,
        <MButton
          size="large"
          onClick={handleSubmit}
          disabled={!form.formState.isValid || isLoading}
        >
          Upload
        </MButton>,
      ]}
    >
      {isLoading && <LoaderCover />}
      <Form form={form} onSubmit={handleSubmit}>
        <Controller
          name="file"
          control={form.control}
          rules={{ required: true && 'File is required' }}
          render={({ field: { onChange, value } }) =>
            value ? (
              <FileStack direction="row" px={3} alignItems="center">
                <Document style={{ margin: '0 20px 0 0' }} />
                <Typography
                  variant="bodyM"
                  sx={{
                    width: '650px',
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {value.name}
                </Typography>
                <IconButton onClick={() => onChange(null)}>
                  <Trash />
                </IconButton>
              </FileStack>
            ) : (
              <Dropzone
                multiple={false}
                onDrop={onDrop(onChange)}
                accept={{
                  'application/vnd.ms-excel': [],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                }}
              />
            )
          }
        />
      </Form>
    </MDialog>
  );
};

BulkNoticeRequestDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func,
  stateCode: PropTypes.string.isRequired,
};

BulkNoticeRequestDialog.defaultProps = {
  callback: null,
};

export default BulkNoticeRequestDialog;
