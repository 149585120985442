import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import ShadowBlock from '../../../atoms/ShadowBlock';
import { ReactComponent as DocumentText } from '../../../../assets/icons/DocumentText.svg';
import { ReactComponent as DocumentForward } from '../../../../assets/icons/DocumentForward.svg';
import { ReactComponent as DocumentCopy } from '../../../../assets/icons/DocumentCopy.svg';
import { palette } from '../../../../theme/default';
import {
  getAvailableRequestsArizona,
  setRequestCancellationArizona,
  setRequestDismissalArizona,
  setRequestSatisfactionArizona,
  // setRequestVacateArizona,
  setRequestWritArizona,
} from '../../../../store/slices/cases/caseCpSlice';
import { LightTooltip } from '../../../atoms/MTooltip/MTooltip';
import NotificationDialog from '../../../molecules/NotificationDialog';
import ReasonOfDismissal from '../RequestsNevada/ReasonOfDismissal';
import { formatUSD } from '../../../../utils/formatCurrency';

const SATISFACTION_REQUEST_TYPE = 'satisfaction';
const WRIT_REQUEST_TYPE = 'writ';
// const EVICTION_REQUEST_TYPE = 'eviction';
const DISMISSAL_REQUEST_TYPE = 'dismissal';
// const VACATE_REQUEST_TYPE = 'vacate';

const REQUEST_SATISFACTION_TOOLTIP =
  'You can only request a satisfaction for a case where the judgment has been granted.';
// const REQUEST_VACATE_TOOLTIP =
//   'You can only request a vacate for a case where the judgment has been granted.';
const REQUEST_WRIT_TOOLTIP =
  'You can only request a writ for a case where the judgment has been granted and writ date is available.';

const StyledWrapper = ({ title, icon, isActive, onClick, tooltip }) => {
  return (
    <LightTooltip title={!isActive && tooltip ? tooltip : null} placement="top">
      <Stack
        sx={{
          textDecoration: 'none',
          cursor: isActive ? 'pointer' : 'default',
        }}
        width="100%"
        maxWidth="400px"
        onClick={onClick}
      >
        <ShadowBlock>
          <Stack alignItems="center" spacing={3}>
            <Typography
              variant="h5"
              color={isActive ? 'text.secondary' : 'text.tertiary'}
              align="center"
              whiteSpace="pre-line"
            >
              {title}
            </Typography>
            {icon}
          </Stack>
        </ShadowBlock>
      </Stack>
    </LightTooltip>
  );
};

const RequestsArizona = () => {
  const {
    requestsArizona: {
      canRequestSatisfaction,
      // canRequestVacate,
      canRequestCancellation,
      canRequestWrit,
      canRequestDismissal,
      feeForRequestSatisfaction,
      feeForRequestWrit,
      // feeForRequestEviction,
      feeForRequestDismissal,
      // feeForRequestVacate,
    },
  } = useSelector((state) => state.caseCp);

  const [isErrorNotificationOpen, setIsErrorNotificationOpen] = useState(false);
  const [fees, setFees] = useState({});
  const [isFeesNotificationOpen, setIsFeesNotificationOpen] = useState(false);
  const [isReasonOfDismissalOpen, setIsReasonOfDismissalOpen] = useState(false);
  const [error, setError] = useState('');

  const {
    state: { id },
  } = useLocation();
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(true);

  const loadRequests = () =>
    dispatch(getAvailableRequestsArizona(id))
      .unwrap()
      .then(() => {
        setIsLoader(false);
      });

  useEffect(() => {
    loadRequests();
  }, []);

  const handleOpenModal = (isActive, setModalOpenState) => () => {
    if (isActive) setModalOpenState(true);
  };

  const handleSetStatus = (isActive, request) => () => {
    if (isActive) {
      setIsLoader(true);
      if (request) {
        dispatch(request(id))
          .unwrap()
          .catch((e) => {
            setError(e || 'Unexpectable error has occured');
            setIsErrorNotificationOpen(true);
          })
          .then(() => {
            loadRequests();
          });
      } else {
        loadRequests();
      }
    }
  };

  const handleSetStatusWithBody = (request, setModalOpenState) => async (data) => {
    const popupFields = Object.entries(data)
      .map(([key, value]) => {
        const convertValue = () => {
          if (typeof value === 'boolean') {
            return value ? 'True' : 'False';
          }
          return value;
        };
        return { key, value: convertValue() };
      })
      .filter((field) => field.value !== null);

    setIsLoader(true);
    await dispatch(
      request({
        caseId: id,
        popupFields,
      }),
    ).then(() => {
      loadRequests();
      setModalOpenState(false);
    });
  };

  const handleCloseReasonOfDismissModal = () => setIsReasonOfDismissalOpen(false);
  const handleCloseErrorNotification = () => setIsErrorNotificationOpen(false);
  const handleCloseFeesNotification = () => setIsFeesNotificationOpen(false);

  const getRequestFunc = (requestType) => {
    switch (requestType) {
      case SATISFACTION_REQUEST_TYPE:
        return handleSetStatus(canRequestSatisfaction, setRequestSatisfactionArizona);
      case WRIT_REQUEST_TYPE:
        return handleSetStatus(canRequestWrit, setRequestWritArizona);
      case DISMISSAL_REQUEST_TYPE:
        return handleOpenModal(canRequestDismissal, setIsReasonOfDismissalOpen);
      // case VACATE_REQUEST_TYPE:
      //   return handleSetStatus(canRequestVacate, setRequestVacateArizona);
      default:
        return null;
    }
  };

  const handleRequestClick = (requestType, requestFees) => {
    const getCanRequest = () => {
      switch (requestType) {
        case SATISFACTION_REQUEST_TYPE:
          return canRequestSatisfaction;
        case WRIT_REQUEST_TYPE:
          return canRequestWrit;
        case DISMISSAL_REQUEST_TYPE:
          return canRequestDismissal;
        // case VACATE_REQUEST_TYPE:
        //   return canRequestVacate;
        default:
          return false;
      }
    };
    const canRequest = getCanRequest();
    if (requestFees && canRequest) {
      setFees({ requestFees, requestType });
      setIsFeesNotificationOpen(true);
      return;
    }

    getRequestFunc(requestType)();
  };

  const handleConfirmFeesNotification = () => {
    setIsFeesNotificationOpen(false);

    getRequestFunc(fees.requestType)();
  };

  const renderFeesMessage = () => {
    return (
      <Typography variant="bodyL500">
        {`This action will trigger ${formatUSD(fees.requestFees)} fee.`}
        <br />
        Would you like to proceed?
      </Typography>
    );
  };

  return (
    <>
      <Stack height="calc(100vh - 80px - 72px - 130px)" mx={4} direction="column">
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            m: 'auto',
            px: 4,
            width: 1,
            maxWidth: 1320,
          }}
        >
          {isLoader && (
            <Box
              position="absolute"
              top="50%"
              left="50%"
              zIndex={10}
              transform="translate(-50%, -50%)"
            >
              <CircularProgress />
            </Box>
          )}
          <Stack
            rowGap={4}
            columnGap={3}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            flexWrap="wrap"
          >
            <StyledWrapper
              tooltip={!isLoader && !canRequestSatisfaction ? REQUEST_SATISFACTION_TOOLTIP : ''}
              title="Request Satisfaction"
              icon={
                <DocumentText
                  color={
                    canRequestSatisfaction ? palette.buttonPrimary.active : palette.text.tertiary
                  }
                />
              }
              // onClick={handleSetStatus(canRequestSatisfaction, setRequestSatisfactionArizona)}
              onClick={() =>
                handleRequestClick(SATISFACTION_REQUEST_TYPE, feeForRequestSatisfaction)
              }
              isActive={!isLoader && canRequestSatisfaction}
            />

            {/* <StyledWrapper
              tooltip={!isLoader && !canRequestVacate ? REQUEST_VACATE_TOOLTIP : ''}
              title="Request Vacate"
              icon={
                <DocumentForward
                  color={canRequestVacate ? palette.buttonPrimary.active : palette.text.tertiary}
                />
              }
              onClick={handleSetStatus(canRequestVacate, setRequestVacateArizona)}
              isActive={!isLoader && canRequestVacate}
            /> */}

            <StyledWrapper
              tooltip={!isLoader && !canRequestWrit ? REQUEST_WRIT_TOOLTIP : ''}
              title="Request Writ"
              icon={
                <DocumentCopy
                  color={canRequestWrit ? palette.buttonPrimary.active : palette.text.tertiary}
                />
              }
              // onClick={handleSetStatus(canRequestWrit, setRequestWritArizona)}
              onClick={() => handleRequestClick(WRIT_REQUEST_TYPE, feeForRequestWrit)}
              isActive={!isLoader && canRequestWrit}
            />

            <StyledWrapper
              title="Request Cancellation"
              icon={
                <DocumentForward
                  color={
                    canRequestCancellation ? palette.buttonPrimary.active : palette.text.tertiary
                  }
                />
              }
              to=""
              onClick={handleSetStatus(canRequestCancellation, setRequestCancellationArizona)}
              isActive={!isLoader && canRequestCancellation}
            />

            <StyledWrapper
              title="Request Dismissal"
              icon={
                <DocumentForward
                  color={canRequestDismissal ? palette.buttonPrimary.active : palette.text.tertiary}
                />
              }
              to=""
              // onClick={handleOpenModal(canRequestDismissal, setIsReasonOfDismissalOpen)}
              onClick={() => handleRequestClick(DISMISSAL_REQUEST_TYPE, feeForRequestDismissal)}
              isActive={!isLoader && canRequestDismissal}
            />
          </Stack>
        </Container>
      </Stack>
      <ReasonOfDismissal
        isOpen={isReasonOfDismissalOpen}
        onClose={handleCloseReasonOfDismissModal}
        handleConfirm={handleSetStatusWithBody(
          setRequestDismissalArizona,
          handleCloseReasonOfDismissModal,
        )}
      />
      <NotificationDialog
        title="Error"
        type="alert"
        desc={error}
        buttonSecondaryText=""
        buttonPrimaryText="Ok"
        onClose={handleCloseErrorNotification}
        onConfirm={handleCloseErrorNotification}
        isOpen={isErrorNotificationOpen}
      />
      <NotificationDialog
        title="Fee will be applied"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Proceed"
        onClose={handleCloseFeesNotification}
        onConfirm={handleConfirmFeesNotification}
        isOpen={isFeesNotificationOpen}
        content={renderFeesMessage()}
        width={528}
      />
    </>
  );
};

StyledWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  tooltip: PropTypes.func,
};

StyledWrapper.defaultProps = {
  isActive: false,
  onClick: () => null,
  tooltip: null,
};

export default RequestsArizona;
