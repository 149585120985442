import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Page from '../../organisms/Page/Page';

const ClientPortalRequestsContainer = () => {
  const {
    navPage: navPageString = 'Requests',
    subNav: subNavString = '',
    subSubNavigation = '',
  } = useLocation()?.state || {};

  return (
    <Page navPage={navPageString} subNavigation={subNavString} subSubNavigation={subSubNavigation}>
      <Outlet />
    </Page>
  );
};

export default ClientPortalRequestsContainer;
