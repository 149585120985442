import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ClientPortalRequests from '../../../components/pages/ClientPortalRequests';
import ClientPortalRequestsContainer from '../../../components/pages/ClientPortalRequests/ClientPortalRequestsContainer';
import ClientPortalEvictionRequests from '../../../components/pages/ClientPortalRequests/ClientPortalEvictionRequests';
// import ClientPortalNonPaymentNotice from '../../../components/pages/ClientPortalRequests/ClientPortalNonPaymentNotice';
import ClientPortalNoticeIntakeForm from '../../../components/pages/ClientPortalRequests/ClientPortalNoticeIntakeForm';
import ClientPortalEvictionIntakeForm from '../../../components/pages/ClientPortalRequests/ClientPortalEvictionIntakeForm';
import ClientPortalNonPaymentNoticeNV from '../../../components/pages/ClientPortalRequests/ClientPortalNonPaymentNoticeNV';
import ClientPortalNotiesRequestsArizona from '../../../components/pages/ClientPortalRequests/ClientPortalNotiesRequestsArizona';
import ClientPortalNonPaymentNoticeAZ from '../../../components/pages/ClientPortalRequests/ClientPortalNonPaymentNoticeAZ';

const ClientPortalRequestsRoutes = () => (
  <Routes>
    <Route path="*" element={<ClientPortalRequestsContainer />}>
      <Route index element={<ClientPortalRequests />} />
      <Route
        path="non_payment_notice/NV"
        // element={<ClientPortalNonPaymentNotice stateCode="NV" />}
        element={<ClientPortalNonPaymentNoticeNV stateCode="NV" />}
      />
      <Route
        path="notices/non_payment_notice/AZ"
        // element={<ClientPortalNonPaymentNotice stateCode="NV" />}
        element={<ClientPortalNonPaymentNoticeAZ stateCode="AZ" />}
      />
      <Route path="notices" element={<ClientPortalNotiesRequestsArizona />} />
      <Route
        path="notices/non_compliance_notice/AZ"
        element={<ClientPortalNoticeIntakeForm stateCode="AZ" />}
      />
      <Route path="noticeintakeform/NV" element={<ClientPortalNoticeIntakeForm stateCode="NV" />} />
      <Route path="evictionrequests" element={<ClientPortalEvictionRequests />} />
      <Route
        path="evictionintakeform"
        element={<ClientPortalEvictionIntakeForm stateCode="AZ" />}
      />
    </Route>
  </Routes>
);

export default ClientPortalRequestsRoutes;
