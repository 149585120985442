import { Box, Stack, Typography, styled } from '@mui/material';
import { useState } from 'react';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import Dropdown from '../../../atoms/Dropdown';
import { useAvailableStatesList } from '../../../../hooks/useAvailableStatesList';
import { usePrimaryState } from '../../../../hooks/usePrimaryState';
import DashboardCalendar from './DashboardCalendar';
import CourtScheduleReport from './CourtScheduleReport';

const StyledHeading = styled(Typography)({
  textAlign: 'justify',
});

const DashboardSchedulingAssistant = () => {
  useSafeLocationState('Dashboard', 'Scheduling Assistant');
  const states = useAvailableStatesList();
  const primaryState = usePrimaryState();
  const [state, setState] = useState(primaryState || states[0]);

  const handleChangeState = (val) => {
    setState(val);
  };

  return (
    <Stack padding="0 32px 32px" rowGap={4} data-testid="scheduling_assistant_page">
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <StyledHeading variant="h3" data-testid="search_container_label">
          Scheduling Assistant
        </StyledHeading>
        <Dropdown
          label="Select state"
          options={states}
          onChange={handleChangeState}
          placeholder={states.length > 0 ? state.label : 'State'}
          isSearchable={false}
          value={state}
        />
      </Box>
      <DashboardCalendar stateCode={state.value} />
      <CourtScheduleReport stateCode={state.value} />
    </Stack>
  );
};

export default DashboardSchedulingAssistant;
