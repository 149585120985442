/* eslint-disable no-await-in-loop */
import React, { useMemo, useState } from 'react';
import { Box, Stack, styled, Typography, Container } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import { AttorneyPerformanceFormBoxes, stateCodesOptions } from './AttorneyPerformance.constants';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import CheckboxSelect from '../../../atoms/CheckboxSelect';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import { palette } from '../../../../theme/default/palette';
import { ReactComponent as Cross } from '../../../../assets/icons/Cross.svg';
import {
  createProductivityByAttorneyPerformanceReport,
  getProductivityByAttorneyPerformanceReport,
} from '../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import PieChart from './PieChart';
import Table from '../../../atoms/Table/Table';
import notificationUtils from '../../../../utils/notificationUtils';
import EmptyBlock from '../../../molecules/EmptyBlock';
import ProgressModal from '../ProductivityByAttorney/ProgressModal';
import { useDateRanges } from '../../../../hooks/useDateRanges';

const AddIcon = styled(Add)({
  '& path': {
    stroke: palette.text.secondary,
  },
});

const emptyPeriod = { fromDate: null, toDate: null };

const StyledContainer = styled(Container)({
  '&': {
    marginTop: '32px',
    '.MuiPaper-root': {
      marginBottom: '16px',
      borderRadius: '16px',
      boxShadow: '0px 3px 32px rgb(106 114 142 / 10%)',
    },
  },
});

const AttorneyPerformance = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [chosenStateCodes, setStates] = useState([]);
  const [chosenPeriods, setPeriods] = useState([]);
  const dispatch = useDispatch();

  const [chartItems, setChartItems] = useState([]);
  const [wasRun, setRun] = useState(false);

  const form = useForm({
    defaultValues: {
      periods: [emptyPeriod],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const periods = useFieldArray({
    control: form.control,
    name: 'periods',
  });

  const currentPeriods = form.watch('periods');

  const { dateRanges, getDateRanges } = useDateRanges({ currentPeriods });

  const onAddPeriod = () => periods.append(emptyPeriod);
  const onRemovePeriod = (index) => {
    periods.remove(index);
  };

  const handleSubmit = async (values) => {
    setRun(true);
    setStates(values.stateCodes.map((item) => item.value));
    setPeriods(values.periods);
    const data = {
      periods: values.periods,
      stateCodes: values.stateCodes.map((item) => item.value),
    };

    setIsLoader(true);

    try {
      const { reportId } = await dispatch(
        createProductivityByAttorneyPerformanceReport(data),
      ).unwrap();
      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code },
        } = await dispatch(getProductivityByAttorneyPerformanceReport(reportId)).unwrap();
        if (code === 'Completed') {
          setChartItems(items);
          done = true;
        } else if (code === 'Error') {
          notificationUtils.error('Failed, try again later');
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      notificationUtils.error('Failed, try again later');
    }
    setIsLoader(false);
  };

  const getOptions = (name) => {
    switch (name) {
      case 'stateCodes':
        return stateCodesOptions;
      default:
        return [];
    }
  };

  const checkIfDateIsNotAvailable = (date, index) => {
    const isDateIsOverlapping = currentPeriods.some((item, ind) => {
      const isSameStart = moment(date.format('YYYY-MM-DD')).isSame(
        item?.fromDate?.format('YYYY-MM-DD'),
      );
      const isSameEnd = moment(date.format('YYYY-MM-DD')).isSame(item.toDate?.format('YYYY-MM-DD'));
      const isBetween = moment(date.format('YYYY-MM-DD')).isBetween(
        item.fromDate?.format('YYYY-MM-DD'),
        item.toDate?.format('YYYY-MM-DD'),
      );

      const isPeriodStartWithinCurrentPossibleDates = moment(
        item?.fromDate?.format('YYYY-MM-DD'),
      ).isBetween(currentPeriods[index]?.fromDate?.format('YYYY-MM-DD'), date.format('YYYY-MM-DD'));
      const isPeriodEndWithinCurrentPossibleDates = moment(
        item?.toDate?.format('YYYY-MM-DD'),
      ).isBetween(currentPeriods[index]?.fromDate?.format('YYYY-MM-DD'), date.format('YYYY-MM-DD'));

      return (
        index !== ind &&
        (isSameStart ||
          isSameEnd ||
          isBetween ||
          (isPeriodStartWithinCurrentPossibleDates && isPeriodEndWithinCurrentPossibleDates))
      );
    });

    return isDateIsOverlapping;
  };

  const handleChange = (args, index, cb) => {
    getDateRanges(args.target.name, index);
    cb(args);
  };

  let chosenStates = chosenStateCodes?.length === 2 ? 'Arizona and Nevada' : 'Arizona';
  if (chosenStateCodes?.length === 1 && chosenStateCodes?.[0] === 'NV') {
    chosenStates = 'Nevada';
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Attorney',
        accessor: 'attorney',
        width: 90,
        minWidth: 150,
      },
      {
        Header: 'Billed Hours',
        accessor: 'billedHours',
        width: 10,
        minWidth: 150,
      },
    ];
  }, []);

  const rowsAdapter = useMemo(() => {
    if (!chartItems[0]) return [];
    const rows = chartItems[0]?.attorneys;
    return [
      ...rows,
      {
        attorney: 'Total',
        billedHours: chartItems[0].total,
      },
    ];
  }, [chartItems]);

  const renderContent = () => {
    if (!wasRun || isLoader) return null;

    return (
      <StyledContainer maxWidth={false} disableGutters>
        <ShadowBlock>
          <Typography variant="h5" fontWeight={600} data-testid="report_title">
            Attorney Performance Report for {chosenStates}
          </Typography>
          {rowsAdapter.length > 1 ? (
            <>
              <ShadowBlock>
                <Box>
                  {chosenPeriods.map((item, idx) => {
                    return (
                      <Typography mb={1} sx={{ fontSize: '12px' }} key={idx}>{`${moment(
                        item.fromDate,
                      ).format('L')} - ${moment(item.toDate).format('L')}`}</Typography>
                    );
                  })}
                </Box>
                <PieChart data={chartItems} />
              </ShadowBlock>
              <Stack mb={6}>
                <Table
                  columns={columns}
                  rows={rowsAdapter}
                  hasTotalRow
                  hasFixedColumns
                  showFixedColumnsAtWidth={300}
                />
              </Stack>
            </>
          ) : (
            <Box
              display="flex"
              width="1140px"
              flexDirection="column"
              alignItems="center"
              mt={12}
              mb={25}
              mx="auto"
            >
              <EmptyBlock
                title="No records"
                desc="Please change filters to display report"
                descFontSize={18}
              />
            </Box>
          )}
        </ShadowBlock>
      </StyledContainer>
    );
  };

  return (
    <Stack direction="column" mb={10}>
      <ShadowBlock>
        {isLoader && <ProgressModal />}
        <Form form={form} onSubmit={handleSubmit}>
          {AttorneyPerformanceFormBoxes.map((i) =>
            i.name === 'periods' ? (
              periods.fields?.map((period, index) => {
                return (
                  <Stack
                    direction="row"
                    alignItems="flex-end"
                    columnGap={3}
                    rowGap={2}
                    flexWrap="wrap"
                    py={1}
                    key={period.id}
                  >
                    {Object.keys(i.inputBoxes).map((key) => {
                      const item = i.inputBoxes[key];
                      const name = `periods[${index}].${item.name}`;

                      if (item.isDatePicker) {
                        return (
                          <Field
                            name={name}
                            isMandatory={item.isMandatory}
                            render={({ field, onCustomChange, error }) => (
                              <DateOrTimePicker
                                name={name}
                                isDate
                                error={error}
                                Svg={Calender}
                                label={item.label}
                                selected={field.value}
                                isMandatory={item.isMandatory}
                                width={item.width}
                                height={item.height}
                                placeholder={item.placeholder}
                                setSelectedTimeOrDate={onCustomChange((args) =>
                                  handleChange(args, index, field.onChange),
                                )}
                                minDate={dateRanges?.[name]?.min || null}
                                maxDate={dateRanges?.[name]?.max || moment()}
                                onClose={() => getDateRanges(name, index)}
                                shouldDisableDate={(date) => checkIfDateIsNotAvailable(date, index)}
                              />
                            )}
                          />
                        );
                      }
                      return null;
                    })}
                    {index === 0 ? (
                      <MButton
                        sx={{ marginBottom: '6px' }}
                        variant="secondary"
                        startIcon={<AddIcon />}
                        onClick={onAddPeriod}
                        data-testid="add_time"
                      >
                        Add Time Period
                      </MButton>
                    ) : (
                      <MButton
                        sx={{ marginBottom: '6px' }}
                        variant="borderLess"
                        startIcon={<Cross width="16px" />}
                        color="info"
                        onClick={() => onRemovePeriod(index)}
                        data-testid="remove_time"
                      >
                        Remove
                      </MButton>
                    )}
                  </Stack>
                );
              })
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                columnGap={3}
                rowGap={2}
                flexWrap="wrap"
                py={1}
              >
                {i.inputBoxes.map((item) => {
                  if (item.isCheckboxSelect) {
                    return (
                      <Field
                        key={item.name}
                        isMandatory={item.isMandatory}
                        name={item.name}
                        render={({ field, onCustomChange, error }) => (
                          <CheckboxSelect
                            label={item.label}
                            value={field.value}
                            width={item.width}
                            error={error}
                            isMandatory={item.isMandatory}
                            isDisabled={item.isDisabled}
                            placeholder={item.placeholder}
                            onChange={onCustomChange(field.onChange)}
                            options={getOptions(item.name)}
                          />
                        )}
                      />
                    );
                  }
                  return null;
                })}
              </Stack>
            ),
          )}
          <Stack direction="row" mt={2}>
            <MButton
              data-testid="run_report_btn"
              type="submit"
              size="medium"
              disabled={isLoader || !form.formState.isValid}
            >
              Run Report
            </MButton>
          </Stack>
        </Form>
      </ShadowBlock>
      {renderContent()}
    </Stack>
  );
};

export default AttorneyPerformance;
