import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@mui/material';
import { useEllipsisTooltip } from '../../../hooks/useEllipsisTooltip';
import { LightTooltip } from '../../atoms/MTooltip/MTooltip';

export const DescriptionCell = ({ description, expanded }) => {
  const ref = useRef(null);
  const { showTooltip } = useEllipsisTooltip(ref.current, expanded);

  return (
    <LightTooltip disableHoverListener={!showTooltip} title={description} placement="top">
      <Typography
        variant="primary"
        sx={{
          textOverflow: showTooltip ? 'ellipsis' : '',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        ref={ref}
      >
        {description}
      </Typography>
    </LightTooltip>
  );
};

DescriptionCell.propTypes = {
  description: PropTypes.string.isRequired,
  expanded: PropTypes.shape({}),
};

DescriptionCell.defaultProps = {
  expanded: null,
};
