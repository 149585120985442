import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import useSafeLocationState from '../../../hooks/useSafeLocationState';
import Dropdown from '../../atoms/Dropdown';
import Page from '../../organisms/Page/Page';
import Searcher from '../../molecules/Searcher';
import { ReactComponent as Folder } from '../../../assets/icons/folder.svg';
import { ReactComponent as Document } from '../../../assets/icons/DocumentColored.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/ArrowDown.svg';
import { ReactComponent as Upload } from '../../../assets/icons/document-upload-white.svg';

import { FileLink } from './FileLink';
import { DescriptionCell } from './DescriptionCell';
import MButton from '../../MUI/Button/MButton';
import { apiClient } from '../../../lib/apiClient';
import LoaderCover from '../../atoms/LoaderCover';
import notificationUtils from '../../../utils/notificationUtils';
import { openOrDownloadBinary } from '../../../utils/binaryHelpers';

const Accordion = styled((props) => <MuiAccordion {...props} />)(() => ({
  boxShadow: 'none',
  width: '100%',
  '&.Mui-expanded': {
    margin: 0,
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowDown sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  borderBottom: '1px solid #DCE4FF',
  height: '40px',
  minHeight: '40px',
  alignItems: 'center',
  '&.Mui-expanded': {
    height: '40px',
    minHeight: '40px',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
    marginRight: '8px',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const ClientPortalLegalForms = () => {
  useSafeLocationState('Legal Forms');
  const [docs, setDocs] = useState(null);
  const [initialDocs, setInitialDocs] = useState(null);
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get('cp/api/legalForms');
      setDocs(data.result);
      setInitialDocs(data.result);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const onChange = (value) => {
    if (!value && initialDocs) {
      setDocs(initialDocs);
      setSearch('');
    }
  };

  const handleSearch = (val) => {
    setSearch(val);
    try {
      const check = (folders, acc = {}) => {
        Object.keys(folders).forEach((item) => {
          const isSubfolderMatch = item?.includes(val?.toLowerCase());
          if (isSubfolderMatch) {
            acc[item] = folders[item];
          } else {
            const files = folders[item]?.files?.filter((file) => {
              return file.name?.toLowerCase().includes(val?.toLowerCase());
            });
            if (files?.length) {
              acc[item] = { ...(acc[item] || {}), files };
            }

            if (folders[item].subfolders) {
              const subfolders = check(folders[item].subfolders, (acc[item] || {}).subfolders);
              if (!isEmpty(subfolders)) {
                acc[item] = { ...(acc[item] || {}) };
                acc[item].subfolders = subfolders;
              }
            }
          }
        });
        return acc;
      };
      const newDocs = check(initialDocs.folders);

      setDocs({ folders: newDocs });
    } catch (err) {
      // console.log('search error');
    }
  };

  const checkTextBySearchParameter = (text) => {
    if (!search) {
      return text;
    }
    const regex = new RegExp(search, 'gi');
    const newText = text.replace(regex, '<mark class="highlight">$&</mark>');
    return newText;
  };

  const handleChange = (content) => {
    setExpanded(content);
  };

  const handleUpload = async () => {
    try {
      const { data } = await apiClient.get(`/cp/api/legalForms/zip`);
      openOrDownloadBinary({
        contentType: data.result.contentType || 'application/zip',
        content: data.result.content,
        fileName: data.result.originalFileName,
      });
    } catch {
      notificationUtils.error('Failed, try again later');
    }
  };

  const renderContent = (folder, content, nestingCount = 0) => {
    return (
      <Accordion onChange={() => handleChange(content)}>
        <AccordionSummary
          expandIcon={<ArrowDown />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ paddingLeft: `${nestingCount * 32 + 8}px` }}
        >
          <Grid container>
            <Grid item xs={5} display="flex" alignItems="center">
              <Folder style={{ marginRight: '8px' }} />
              <Typography variant="bodyM">
                <div dangerouslySetInnerHTML={{ __html: checkTextBySearchParameter(folder) }} />
              </Typography>
            </Grid>
            {content.description && (
              <Grid item xs={7} display="flex" alignItems="center">
                <DescriptionCell description={content.description} expanded={expanded} />
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        {(content.files || content.subfolders) && (
          <AccordionDetails sx={{ padding: 0 }}>
            {content.subfolders &&
              Object.entries(content.subfolders || {}).map(([key, value]) => {
                if (!isEmpty(value)) {
                  return renderContent(key, value, nestingCount + 1);
                }
                return null;
              })}
            {Array.isArray(content.files) &&
              content.files.map((item) => {
                return (
                  <Grid
                    container
                    sx={{
                      height: '40px',
                      borderBottom: '1px solid #DCE4FF',
                      paddingLeft: `${(nestingCount + 1) * 32}px`,
                    }}
                  >
                    <Grid item xs={5} display="flex" alignItems="center">
                      <Document style={{ marginRight: '8px', width: '18px' }} color="#AEB1D2" />
                      <FileLink
                        file={item}
                        checkTextBySearchParameter={checkTextBySearchParameter}
                        folder={folder}
                      />
                    </Grid>
                    {item.description && (
                      <Grid
                        item
                        xs={7}
                        display="flex"
                        alignItems="center"
                        sx={{ position: 'relative', left: `-${nestingCount * 18}px` }}
                      >
                        <DescriptionCell description={item.description} expanded={expanded} />
                      </Grid>
                    )}
                  </Grid>
                );
              })}
          </AccordionDetails>
        )}
      </Accordion>
    );
  };

  return (
    <Page navPage="Legal Forms">
      {loading && <LoaderCover />}
      <Stack height="100%" margin="0 32px" direction="column">
        <Stack spacing={4} direction="row" alignItems="center" justifyContent="space-between">
          <Stack spacing={4} direction="row" alignItems="center">
            <Typography variant="h4">Legal Forms</Typography>
            <Dropdown
              label={
                <Typography
                  variant="bodyM"
                  color="text.secondary"
                  mb={0}
                  sx={{ position: 'relative', left: '-16px' }}
                  width="167px"
                >
                  Select state
                </Typography>
              }
              value={{ value: 'AZ', label: 'AZ' }}
              options={[{ value: 'AZ', label: 'AZ' }]}
            />
          </Stack>
          <Box>
            <MButton
              sx={{
                width: '235px',
                fontSize: '16px',
                position: 'relative',
                height: '48px',
              }}
              startIcon={<Upload />}
              onClick={handleUpload}
              data-testid="document_upload_btn"
            >
              Download Legal Forms
            </MButton>
          </Box>
        </Stack>
        <Box mt={4} sx={{ maxWidth: '824px' }}>
          <Searcher inputPlaceholder="Search" onSearch={handleSearch} onChange={onChange} />
        </Box>
        <Box mt={4} mb={4}>
          <Grid container>
            <Grid
              item
              xs={5}
              pl={3}
              display="flex"
              alignItems="center"
              sx={{ background: '#EBEDFA', height: '40px' }}
            >
              <Typography variant="buttonLarge">Name</Typography>
            </Grid>
            <Grid
              item
              xs={7}
              display="flex"
              alignItems="center"
              sx={{ background: '#EBEDFA', height: '40px' }}
            >
              <Typography variant="buttonLarge">Description</Typography>
            </Grid>

            {Object.entries(docs?.folders || {}).map(([folderName, folderContent]) => {
              return renderContent(folderName, folderContent);
            })}
          </Grid>
        </Box>
      </Stack>
    </Page>
  );
};

export default ClientPortalLegalForms;
