/* eslint-disable react/prop-types */
import React from 'react';
import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';
import MRadioButton from '../../../../atoms/MRadioButton';
import { AddressCell } from './CommunityAddressCells';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;
export const MAX_HEIGHT_DIALOG = 624;
export const MAX_HEIGHT_DIALOG_TABLE = 326;

export const CommunityAddressColumns = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 35,
    Cell: AddressCell,
  },
  {
    Header: 'Managment Company',
    accessor: 'managementName',
    width: 35,
  },
  {
    Header: () => <div style={{ textAlign: 'center' }}>On Hold</div>,
    accessor: 'isOnHold',
    width: 20,
    Cell: ({ row }) => (
      <div style={{ textAlign: 'center' }}>
        <MRadioButton isTogglable={false} isChecked={row?.original?.isOnHold} />
      </div>
    ),
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];

export const AddressesAllColumns = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 80,
  },
  {
    Header: () => <div style={{ textAlign: 'center' }}>On Hold</div>,
    accessor: 'isOnHold',
    width: 15,
    Cell: ({ row }) => (
      <div style={{ textAlign: 'center' }}>
        <MRadioButton isTogglable={false} isChecked={row?.original?.isOnHold} />
      </div>
    ),
  },
];
