import TextEllipsis from './TableCells/TextEllipsis';
import TextEllipsisWithTooltip from './TableCells/TextEllipsisWithTooltip';
import { SentPrintedCell } from './TableCells/SentPrintedCell';
import { DateCell } from './TableCells/DateCell';
import { ReferenceNumberCell } from '../CaseSearch/CaseSearchCells';

export const COURTS_PAGE_SIZE = 10;
export const MANAGEMENTS_PAGE_SIZE = 10;
export const COMMUNITIES_PAGE_SIZE = 10;
export const TABLE_PAGE_SIZE = 200;

export const NEVADA_STATE = 'NV';

export const SHOW_FIELD = 'show';

export const showDropdownOptions = [
  {
    id: 0,
    value: 'Unsent/Unprinted',
    label: 'Unsent/Unprinted',
    isPrinted: false,
    isSent: false,
    default: true,
  },
  { id: 1, value: 'Sent/Printed', label: 'Sent/Printed', isPrinted: true, isSent: true },
  { id: 2, value: 'Unsent/Printed', label: 'Unsent/Printed', isPrinted: true, isSent: false },
  { id: 3, value: 'Sent/Unprinted', label: 'Sent/Unprinted', isPrinted: false, isSent: true },
];

export const searchFilters = [
  {
    label: '',
    placeholder: '',
    name: 'approvedRejected',
    width: '290px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Approved', value: 'Approved', isDefault: true, buttonWidth: '141px' },
      { id: 1, label: 'Rejected', value: 'Rejected', buttonWidth: '141px' },
      { id: 2, label: 'Awaiting approval', value: 'AwaitingApproval', buttonWidth: '197px' },
    ],
  },
  {
    isSpace: true,
  },
  {
    label: 'Status',
    name: SHOW_FIELD,
    placeholder: 'Select status',
    width: '367px',
    isDropdown: true,
  },
  {
    label: 'Management',
    name: 'management',
    width: '327px',
    placeholder: 'Select management',
    isDropdown: true,
  },
  {
    label: 'Community',
    name: 'community',
    width: '339px',
    placeholder: 'Select community',
    isDropdown: true,
  },
  {
    label: 'Date Served',
    name: 'dateServed',
    width: '178px',
    placeholder: 'Select community',
    dateFormat: 'MM/DD/YYYY',
    isDate: true,
  },
  {
    isSpace: true,
  },
  {
    label: 'Opposing Party',
    name: 'opposingParty',
    placeholder: 'Enter opposing party name',
    width: '300px',
    isDropdown: true,
  },
  {
    label: 'Apartment #',
    name: 'apartmentNumber',
    placeholder: 'xxxxxxxxx',
    width: '221px',
    isDropdown: false,
  },
];

export const approvedTableColumns = [
  {
    Header: 'Opposing Party Name',
    accessor: 'defendantName',
    width: 25,
  },
  {
    Header: 'Address',
    accessor: 'address',
    width: 20,
  },
  {
    Header: 'Apartment #',
    accessor: 'apartmentNumber',
    width: 10,
  },
  {
    Header: 'Community',
    accessor: 'communityName',
    width: 10,
  },
  {
    Header: 'Management',
    accessor: 'managementName',
    width: 15,
  },
  {
    Header: 'Date Served',
    accessor: 'dateServed',
    width: 10,
    Cell: DateCell,
  },
  {
    Header: 'Created By',
    accessor: 'createdBy',
    width: 10,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 15,
    Cell: SentPrintedCell,
  },
];

export const rejectedTableColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    width: 15,
    Cell: ReferenceNumberCell,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'defendantName',
    width: 15,
    Cell: TextEllipsis,
  },
  {
    Header: 'Address',
    accessor: 'address',
    width: 15,
    Cell: TextEllipsis,
  },
  {
    Header: 'Apartment #',
    accessor: 'apartmentNumber',
    width: 10,
  },
  {
    Header: 'Community',
    accessor: 'communityName',
    width: 10,
  },
  {
    Header: 'Management',
    accessor: 'managementName',
    width: 15,
    Cell: TextEllipsis,
  },
  {
    Header: 'Date Served',
    accessor: 'dateServed',
    width: 10,
    Cell: DateCell,
  },
  {
    Header: 'Reason of Rejection',
    accessor: 'reasonOfRejection',
    width: 15,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 15,
    Cell: TextEllipsisWithTooltip,
  },
];
