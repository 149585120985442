import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const ActiveCell = ({
  cell: {
    row: {
      original: { isActive },
    },
  },
}) => {
  if (isActive) {
    return (
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        Active
      </Typography>
    );
  }

  return '';
};

ActiveCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        isActive: PropTypes.bool,
      }),
    }),
  }).isRequired,
};
