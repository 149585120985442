import { enqueueSnackbar } from 'notistack';

const runNotificationVariant =
  (variant = 'default') =>
  (message = '', props = {}) =>
    enqueueSnackbar(message, { ...props, variant });

export default {
  default: runNotificationVariant('default'),
  success: runNotificationVariant('success'),
  error: runNotificationVariant('error'),
  warning: runNotificationVariant('warning'),
  info: runNotificationVariant('info'),
};
