import React, { useEffect, useState } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import _ from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Stack, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { palette } from '../../../theme/default/palette';
import { ReactComponent as Asterisk } from '../../../assets/icons/asterisk.svg';

const StyledWrapper = styled('div')(({ width }) => ({
  width,
  '& .MuiFormControlLabel-label': {
    fontSize: '16px',
  },
  '& .MuiRadio-root': {
    padding: 0,
  },
  position: 'relative',
}));

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
  '& label': {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    borderRadius: '10px',
    padding: '12px',
    height: '48px',
    margin: '4px 0 0 0',
  },
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme, width, isCheckedLabel }) => ({
  color: palette.buttonPrimary.disabledBorder,
  width,
  border: `1px solid ${
    isCheckedLabel
      ? theme.palette.buttonPrimary.active
      : theme.palette.buttonSecondary.disabledBorder
  }`,
  '&.Mui-checked': {
    color: palette.buttonPrimary.disabledBorder,
  },
  '&.Mui-disabled span.MuiTypography-root': {
    color: palette.text.disabledText,
  },
  '& .MuiFormControlLabel-label': {
    fontSize: '16px',
    fontWeight: 500,
    color: palette.text.primary,
  },
}));

const StyledRadio = styled(Radio)({
  color: palette.buttonPrimary.disabledBorder,
  marginLeft: '16px',
  '&.Mui-checked': {
    color: palette.buttonPrimary.active,
  },
});

const StyledLabelWithIcon = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '8px',
});

const StyledLabelTypographyWithIcon = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
});

const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

const CustomRadioGroup = ({
  data,
  value,
  label,
  width,
  radioButtonWidth,
  onChange,
  defaultValue,
  disabled,
  tooltip,
  isMandatory,
  error,
}) => {
  const [checked, setChecked] = useState(null);

  useEffect(() => {
    if (_.isBoolean(value) || _.isString(value)) {
      const currentValue = data.find((item) => item.value === value);
      setChecked(currentValue);
      return;
    }

    if (defaultValue && !checked) {
      onChange(defaultValue.value);
      setChecked(defaultValue);
    }
  }, [value, defaultValue]);

  const handleRadioChange = (event) => {
    const activeOption = data.find((item) => item.label === event.target.value);
    if (activeOption) {
      setChecked({ ...activeOption });
      onChange(activeOption.value);
    }
  };

  return (
    <StyledWrapper width={width} data-testid="custom_radio_wrapper">
      <Typography
        variant="buttonLarge"
        color="text.secondary"
        component="label"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {label}
        {isMandatory && (
          <StyledSpan style={{ alignSelf: 'flex-start' }}>
            <Asterisk data-testid="mandatory_icon" />
          </StyledSpan>
        )}
        {tooltip}
      </Typography>
      <StyledRadioGroup
        style={{ width }}
        className="classes.customRadioGroupContainer"
        onChange={handleRadioChange}
        value={checked?.label || ''}
      >
        <Stack flex direction="row" columnGap="8px">
          {data.map((item, id) => {
            return (
              <StyledFormControlLabel
                key={item.label}
                value={item.label}
                isCheckedLabel={checked?.id === id}
                control={
                  <StyledRadio
                    data-testid="radio_button"
                    inputProps={{
                      'data-testid': 'radio_input',
                    }}
                  />
                }
                label={
                  item?.icon ? (
                    <StyledLabelWithIcon>
                      {item.icon}
                      <StyledLabelTypographyWithIcon>{item.label}</StyledLabelTypographyWithIcon>
                    </StyledLabelWithIcon>
                  ) : (
                    item.label
                  )
                }
                width={radioButtonWidth || item.buttonWidth}
                disabled={disabled}
              />
            );
          })}
        </Stack>
      </StyledRadioGroup>
      {error?.message && (
        <Typography
          variant="bodyXS"
          color="additional.alert"
          component="span"
          position="absolute"
          left={0}
          top="calc(100% + 4px)"
          data-testid="error_message"
        >
          {error?.message}
        </Typography>
      )}
    </StyledWrapper>
  );
};

CustomRadioGroup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  radioButtonWidth: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  isMandatory: PropTypes.bool,
  tooltip: PropTypes.node,
  error: PropTypes.shape({
    message: PropTypes.string,
    ref: PropTypes.shape({
      name: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
};

CustomRadioGroup.defaultProps = {
  disabled: false,
  onChange: () => null,
  tooltip: null,
  defaultValue: null,
  isMandatory: false,
  error: {},
};

export default CustomRadioGroup;
