import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import MButton from '../../MUI/Button/MButton';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/ArrowLeft.svg';
import { getLoginPage } from '../../../store/slices/adminLoginSlice';
import { convertToBinaryFileUrl } from '../../../utils/binaryHelpers';

const LoginPageInformationBlock = () => {
  const { loginPage } = useSelector((state) => state.adminLogin);

  const url = loginPage?.image?.content
    ? convertToBinaryFileUrl({
        contentType: loginPage.image.contentType,
        content: loginPage.image.content,
        fileName: loginPage.image.fileName,
      })
    : '';

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLoginPage());
  }, []);
  return (
    <Box
      component="section"
      data-testid="info_wrapper"
      sx={{
        marginRight: 12,
        maxWidth: '590px',
        textAlign: 'justify',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        '& p, & b': {
          fontFamily: '"EB Garamond", Times, serif',
          fontSize: '20px',
          lineHeight: 1.3,
        },
      }}
      className="quill ql-editor"
    >
      {!!url && (
        <img
          src={url}
          alt="Celebrating 40 years"
          style={{ height: '250px', width: 'auto', margin: '0 auto' }}
          data-testid="logo"
        />
      )}
      <p>
        {loginPage?.description && (
          <div dangerouslySetInnerHTML={{ __html: loginPage.description }} />
        )}
        {loginPage?.note && <div dangerouslySetInnerHTML={{ __html: loginPage.note }} />}
      </p>
      {loginPage?.linkUrl && loginPage?.linkName && (
        <MButton
          variant="borderLess"
          component="a"
          href={loginPage.linkUrl}
          target="_blank"
          sx={{ color: 'text.secondary' }}
          data-testid="legacy_button"
        >
          <ArrowLeft />
          {loginPage.linkName}
        </MButton>
      )}
    </Box>
  );
};

export default LoginPageInformationBlock;
