/* eslint-disable */
import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import style from './TextInput.module.scss';
import s from 'styled-components';
import { palette } from '../../../theme/default';

// eslint-disable-next-line react/display-name
const TextInput = forwardRef((props, ref) => {
  const {
    type,
    width,
    placeholder,
    name,
    onChange,
    onBlur,
    onFocus,
    value,
    isRequired,
    isDisabled,
    error,
    min,
    readOnly,
  } = props;

  return (
    <StyledInput
      disabled={isDisabled}
      ref={ref}
      required={isRequired}
      width={width}
      className={readOnly ? style.read_text_input : style.text_input}
      type={type}
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      errorMessage={error?.message || ''}
      min={min}
      data-testid="text_input"
      readOnly={readOnly}
      style={{
        color: isDisabled ? palette.text.disabledText : 'inherit',
      }}
    />
  );
});

const StyledInput = s.input`
	width: ${({ width }) => width};
	border-color: ${({ errorMessage }) =>
    errorMessage ? palette.additional.alert : palette.additional.lines};
`;

TextInput.propTypes = {
  type: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  min: PropTypes.number,
  readOnly: PropTypes.bool,
};

export default TextInput;
