import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';

import './Calendar.sass';

import MButton from '../../MUI/Button/MButton';
import { BaseRightButtons } from './CalendarRightPart';

const CalendarHeader = ({ customRightFilters, calendarRef }) => {
  const calendarApi = calendarRef?.getApi();

  const goNext = () => {
    calendarApi.next();
  };
  const goPrev = () => {
    calendarApi.prev();
  };

  return (
    <div className="fc">
      <div className="fc-header-toolbar fc-header fc-toolbar fc-toolbar-ltr">
        <div className="fc-toolbar-chunk">
          <div>
            <h2 className="fc-toolbar-title" id="title_button" data-testid="title_button">
              {calendarApi?.currentData?.viewTitle}
            </h2>
            <MButton variant="borderLess" data-testid="left_button" onClick={goPrev}>
              <ArrowLeft />
            </MButton>
            <MButton variant="borderLess" data-testid="right_button" onClick={goNext}>
              <ArrowRight />
            </MButton>
          </div>
        </div>
        <div className="fc-toolbar-chunk">
          <div className="fc-button-group">
            {customRightFilters || <BaseRightButtons calendarRef={calendarRef} />}
          </div>
        </div>
      </div>
    </div>
  );
};

CalendarHeader.propTypes = {
  calendarRef: PropTypes.shape({
    getApi: PropTypes.shape({}),
  }).isRequired,
  customRightFilters: PropTypes.node,
};

CalendarHeader.defaultProps = {
  customRightFilters: null,
};

export default CalendarHeader;
