import React, { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import MDialog from '../../../../atoms/MDialog';
import MultilineTextField from '../../../../atoms/MultilineTextField/MultilineTextField';
import MButton from '../../../../MUI/Button/MButton';
import CheckboxSelect from '../../../../atoms/CheckboxSelect';

const reasonOfDismissalOptions = [
  { id: 0, value: 'Tenant has vacated the property', label: 'Tenant has vacated the property' },
  {
    id: 1,
    value: 'Landlord has received rental assistance funds',
    label: 'Landlord has received rental assistance funds',
  },
  {
    id: 2,
    value: 'Tenant has resolved the issue that was the subject of the eviction',
    label: 'Tenant has resolved the issue that was the subject of the eviction',
  },
  {
    id: 3,
    value: 'Landlord & Tenant have entered into an agreement',
    label: 'Landlord & Tenant have entered into an agreement',
  },
  { id: 4, value: 'Other', label: 'Other' },
];

const MotionToDismiss = ({ isOpen, handleConfirm, handleClose }) => {
  const [isLoading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setDescription('');
      setSelectedOption([]);
    } else setLoading(false);
  }, [isOpen]);

  const handleDescriptionChange = useCallback((event) => {
    setDescription(event.target.value);
  }, []);

  const handleSelectChange = useCallback((value) => {
    setSelectedOption(value);
  }, []);

  const onConfirm = async () => {
    setLoading(true);

    await handleConfirm({
      ReasonOfDismissal: selectedOption.map((o) => o.value).join(','),
      MotionDescription: description,
    });
  };

  const isOtherSelected = selectedOption.some((item) => item.value === 'Other');

  return (
    <MDialog
      onClose={handleClose}
      isOpen={isOpen}
      maxWidth="582px"
      minHeight="396px"
      fullWidth
      title="Reason of dismissal"
    >
      <Stack rowGap={4}>
        <CheckboxSelect
          value={selectedOption}
          label="Select reason"
          placeholder="Choose reason"
          options={reasonOfDismissalOptions}
          onChange={handleSelectChange}
        />
        <MultilineTextField
          error={false}
          value={description}
          isMandatory={isOtherSelected}
          label="Motion Description"
          rows={10}
          placeholder="Enter Motion Description"
          onChange={handleDescriptionChange}
        />
        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
          <MButton variant="secondary" size="large" onClick={handleClose} sx={{ width: '104px' }}>
            Cancel
          </MButton>
          <MButton
            size="large"
            onClick={onConfirm}
            sx={{ width: '104px' }}
            disabled={!selectedOption.length || (isOtherSelected && !description) || isLoading}
          >
            Create
          </MButton>
        </Stack>
      </Stack>
    </MDialog>
  );
};

MotionToDismiss.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MotionToDismiss;
