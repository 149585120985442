import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const LoginToDashboard = ({ base }) => {
  return <Navigate to={`/${base}/dashboard`} replace />;
};

LoginToDashboard.propTypes = {
  base: PropTypes.string,
};

LoginToDashboard.defaultProps = {
  base: '',
};
export default LoginToDashboard;
