/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getCaseLogs = createAsyncThunk('logs/getCaseLogs', async (params) => {
  const { id } = params;
  const { data } = await apiClient.post(`/api/cases/${id}/logs/filter`, params);
  return data;
});

export const getAddressLogs = createAsyncThunk('logs/getAddressLogs', async (params) => {
  const { id } = params;
  const { data } = await apiClient.post(`/api/addresses/${id}/logs/filter`, params);
  return data;
});

export const getCommunityLogs = createAsyncThunk('logs/getCommunityLogs', async (params) => {
  const { id } = params;
  const { data } = await apiClient.post(`/api/communities/${id}/logs/filter`, params);
  return data;
});

export const getContactLogs = createAsyncThunk('logs/getContactLogs', async (params) => {
  const { id } = params;
  const { data } = await apiClient.post(`/api/contacts/${id}/logs/filter`, params);
  return data;
});

export const getCourtLogs = createAsyncThunk('logs/getCourtLogs', async (params) => {
  const { id } = params;
  const { data } = await apiClient.post(`/api/courts/${id}/logs/filter`, params);
  return data;
});

export const getManagementLogs = createAsyncThunk('logs/getManagementLogs', async (params) => {
  const { id } = params;
  const { data } = await apiClient.post(`/api/managements/${id}/logs/filter`, params);
  return data;
});

export const getOwnerEntityLogs = createAsyncThunk('logs/getOwnerEntityLogs', async (params) => {
  const { id } = params;
  const { data } = await apiClient.post(`/api/ownerEntities/${id}/logs/filter`, params);
  return data;
});

const initialState = {
  addressLogs: {},
  caseLogs: {},
  communityLogs: {},
  contactLogs: {},
  courtLogs: {},
  managementLogs: {},
  ownerEntityLogs: {},
};

export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {},
  extraReducers: {
    [getCaseLogs.fulfilled]: (state, action) => {
      state.caseLogs = action.payload.result;
    },
    [getAddressLogs.fulfilled]: (state, action) => {
      state.addressLogs = action.payload.result;
    },
    [getCommunityLogs.fulfilled]: (state, action) => {
      state.communityLogs = action.payload.result;
    },
    [getContactLogs.fulfilled]: (state, action) => {
      state.contactLogs = action.payload.result;
    },
    [getCourtLogs.fulfilled]: (state, action) => {
      state.courtLogs = action.payload.result;
    },
    [getManagementLogs.fulfilled]: (state, action) => {
      state.managementLogs = action.payload.result;
    },
    [getOwnerEntityLogs.fulfilled]: (state, action) => {
      state.ownerEntityLogs = action.payload.result;
    },
  },
});

export default logsSlice.reducer;
