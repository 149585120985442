import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { palette } from '../../../../../theme/default';
import StateLink from '../../../../atoms/StateLink/StateLink';

export const AddressCell = ({ value, row }) => {
  const location = useLocation();

  const to = '/db/datahandling/editaddress/address';

  const state = {
    navPage: 'Data Handling',
    subNav: 'Addresses',
    id: row.original.addressId,
    name: row.original.address,
    state: location.state?.state || {
      value: row.original.stateCode,
      label: row.original.stateCode,
    },
  };

  return (
    <StateLink to={to} state={state} alwaysOpenInNewTab>
      <Typography
        sx={{ cursor: 'pointer' }}
        textTransform="none"
        variant="link"
        color={palette.text.primary}
      >
        {value}
      </Typography>
    </StateLink>
  );
};

AddressCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      addressId: PropTypes.number,
      address: PropTypes.string,
      stateCode: PropTypes.string,
    }),
  }),
};

AddressCell.defaultProps = {
  value: '',
  row: {
    original: {
      addressId: 0,
      address: '',
      stateCode: '',
    },
  },
};
