/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const getStates = createAsyncThunk('proposedFormOfJudgementsSlice/getStates', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data.result.map((item) => ({
    id: item.id,
    value: item.code,
    label: item.code,
  }));
});

export const getJudgementsList = createAsyncThunk(
  'proposedFormOfJudgementsSlice/getJudgementsList',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/arizonaCases/judgements/filter`, params);
    return data;
  },
);

export const getCourtNamesList = createAsyncThunk(
  'notices/getCompanyManagementsList',
  async (params, { getState }) => {
    const { open, ...rest } = params;

    const { data } = await apiClient.post(`/api/courts/filter`, rest);

    const {
      proposedFormOfJudgements: { courtNamesList },
    } = getState();

    if (!open && !courtNamesList) return null;

    const prevValue = courtNamesList?.prevSearchValue || '';

    if (!open && prevValue !== rest.courtName) {
      return {
        ...data.result,
        prevSearchValue: rest.courtName,
        items: data.result.items.map((item, id) => ({
          id,
          value: item.courtName,
          label: item.courtName,
        })),
      };
    }

    if (!open && courtNamesList) {
      return {
        ...data.result,
        prevSearchValue: rest.courtName,
        items: courtNamesList.items.concat(
          data.result.items.map((item, id) => ({
            id,
            value: item.courtName,
            label: item.courtName,
          })),
        ),
      };
    }

    return {
      ...data.result,
      prevSearchValue: rest.courtName,
      items: data.result.items.map((item, id) => ({
        id,
        value: item.courtName,
        label: item.courtName,
      })),
    };
  },
);

export const resetCourtNamesList = createAction(
  'proposedFormOfJudgementsSlice/resetCourtNamesList',
  () => {
    return {
      type: 'proposedFormOfJudgementsSlice/resetCourtNamesList',
    };
  },
);

export const resetJudgementsList = createAction(
  'proposedFormOfJudgementsSlice/resetJudgementsList',
  () => {
    return {
      type: 'proposedFormOfJudgementsSlice/resetJudgementsList',
    };
  },
);

export const sendJudgements = createAsyncThunk(
  'proposedFormOfJudgementsSlice/sendJudgements',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/arizonaCases/judgements/send`, params);
    return data;
  },
);

export const resetProposedFormOfJudgementsPage = createAction('judgementsSlice/resetPage', () => ({
  type: 'proposedFormOfJudgementsSlice/resetProposedFormOfJudgementsPage',
}));

const initialState = {
  states: [],
  judgementsList: null,
  courtNamesList: null,
  error: null,
};

export const proposedFormOfJudgementsSlice = createSlice({
  name: 'proposedFormOfJudgementsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload;
    },
    [getJudgementsList.fulfilled]: (state, action) => {
      state.judgementsList = action.payload.result;
    },
    [getCourtNamesList.fulfilled]: (state, action) => {
      state.courtNamesList = action.payload;
    },
    [resetCourtNamesList]: (state) => {
      state.courtNamesList = null;
    },
    [resetJudgementsList]: (state) => {
      state.judgementsList = null;
    },
    [resetProposedFormOfJudgementsPage]: (state) => {
      state.states = [];
      state.judgementsList = null;
      state.courtNamesList = null;
      state.error = null;
    },
  },
});

export default proposedFormOfJudgementsSlice.reducer;
