import React, { useCallback, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import Dropdown from '../../../atoms/Dropdown';

const AddressCell = (props) => {
  const { row, updateValue, cell, changeInput, handleMenuScrollToBottom, onOpenAddressesMenu } =
    props;
  const { value } = cell;

  const [isEdit, setIsEdit] = useState(false);
  const { addressesList } = useSelector((state) => state.nonPaymentRequest);

  const options = useMemo(() => {
    return addressesList?.items || [];
  }, [addressesList?.items?.length]);

  const currentValue = value
    ? options.find((item) => item.value === value) || {
        value,
        label: value,
        id: row.original.addressId,
      }
    : null;

  const handleFocus = useCallback(() => {
    onOpenAddressesMenu();
    setIsEdit(true);
  }, [row?.original?.id]);

  const handleChange = useCallback(
    (selected) => {
      if (!row.original?.id) return;
      updateValue(row.original?.id, {
        id: 'addressId',
        value: selected?.id,
        error: undefined,
      });
      updateValue(row.original?.id, {
        id: 'addressName',
        value: selected?.value,
        error: undefined,
      });

      const courtInfo = addressesList?.courts?.find((item) => item.addressId === selected?.id);

      if (courtInfo) {
        updateValue(row.original?.id, {
          id: 'courtName',
          value: courtInfo?.courtName,
          error: undefined,
        });
        updateValue(row.original?.id, {
          id: 'hasGovernmentBackedLoan',
          value: courtInfo?.hasGovernmentBackedLoan,
          error: undefined,
        });
        updateValue(row.original?.id, {
          id: 'abbreviation',
          value: courtInfo?.abbreviation,
          error: undefined,
        });
      }
    },
    [row.original?.id],
  );

  const onBlur = useCallback(() => {
    if (!row.original.errors.addressId) {
      setIsEdit(false);
    }
    if (!row.original?.id) return;
    updateValue(row.original?.id, {
      id: 'addressId',
      touched: true,
      value: currentValue?.id || null,
      error: value ? undefined : 'Mandatory field',
    });
  }, [row.original.errors.addressId, currentValue]);

  const message = useMemo(() => {
    if (row.original.errors.addressId && row.original.touched?.addressId)
      return { message: 'Mandatory field' };
    return null;
  }, [row.original.errors.addressId, row.original.touched.addressId, isEdit]);

  return (
    <Dropdown
      placeholder="Enter address"
      value={currentValue}
      options={options}
      onMenuScrollToBottom={() => handleMenuScrollToBottom(addressesList?.hasNextPage)}
      width="180px"
      menuPosition="fixed"
      height="40px"
      onInputChange={changeInput}
      onChange={handleChange}
      isSearchable
      onMenuOpen={handleFocus}
      error={message}
      readOnly={!isEdit}
      onBlur={onBlur}
      isColumn
      menuPlacement="bottom"
      customMessageStyles={{ top: '100%', lineHeight: '12px', fontWeight: 400 }}
      menuShouldBlockScroll
    />
  );
};

AddressCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    column: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({
      id: PropTypes.number,
      addressId: PropTypes.number,
      errors: PropTypes.shape({ addressId: PropTypes.bool }),
      touched: PropTypes.shape({ addressId: PropTypes.bool }),
    }),
  }).isRequired,
  updateValue: PropTypes.func.isRequired,
  handleMenuScrollToBottom: PropTypes.func.isRequired,
  changeInput: PropTypes.func.isRequired,
  onOpenAddressesMenu: PropTypes.func.isRequired,
};

AddressCell.defaultProps = {
  cell: {
    value: '',
    column: {
      id: '',
    },
  },
};

export default React.memo(AddressCell);
