import moment from 'moment';
import { Box } from '@mui/material';
import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';
import { CaseInformationEditableCell } from './CaseInformationArizonaEditableCell';
import CaseStatus from '../../../molecules/CaseStatus';
import { DropdownCell } from '../../../atoms/Table/TableCells/DropdownCell';
import { DateCell, TextCell } from './TableCells';
import { CaseStatusDeleteCell } from '../CaseInformationNevada/TableCells/CaseStatusDeleteCell';
import { DuplicateCell } from './TableCells/DuplicateCell';
import { ReferenceNumberCell } from '../CaseSearch/CaseSearchCells';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';

const date = 'mm/dd/yyyy';

export const caseInformationForm = {
  boxTitle: 'Case Information',
  name: 'caseInformation',
  withAdd: false,
  inputBoxes: [
    {
      label: 'Case #',
      name: 'caseNumber',
      placeholder: 'xxxxxx',
      width: '199px',
      isMandatory: false,
    },
    {
      label: 'P.O./File #',
      name: 'poNumber',
      placeholder: 'P.O./File #',
      width: '199px',
      isMandatory: false,
    },
    {
      label: 'Assigned Legal Assistant',
      name: 'assignedLegalAssistant',
      placeholder: "Enter legal assistant's name",
      width: '300px',
      isDropdown: true,
      isMandatory: false,
      withUpdate: true,
    },
  ],
};

export const proceduralHistoryForm = {
  boxTitle: 'Procedural History',
  name: 'proceduralHistory',
  withAdd: true,
  inputBoxes: [
    {
      label: 'Assigned Attorney',
      name: 'proceduralHistory.assignedAttorney',
      placeholder: "Enter attorney's name",
      width: '476px',
      isDropdown: true,
      isMandatory: false,
    },
    {
      label: 'Hearing Type',
      name: 'proceduralHistory.hearingType',
      placeholder: 'Default option',
      width: '476px',
      isDropdown: true,
      isMandatory: true,
    },
    {
      isSpace: true,
      label: 'spaceAfterHearingType',
    },
    {
      label: 'Court Date',
      name: 'proceduralHistory.courtDate',
      width: '226px',
      placeholder: date,
      isMandatory: true,
      isDatePicker: true,
      height: '48px',
    },
    {
      label: 'Court Time',
      name: 'proceduralHistory.courtTime',
      placeholder: 'hh:mm',
      width: '226px',
      height: '48px',
      isMandatory: true,
      isTimePicker: true,
    },
    {
      label: 'Date of Writ',
      name: 'proceduralHistory.dateOfWrit',
      width: '226px',
      placeholder: date,
      isMandatory: false,
      isDatePicker: true,
      height: '48px',
    },
    {
      label: 'Judgment Granted',
      name: 'proceduralHistory.judgmentGranted',
      isCheckbox: true,
      isMandatory: false,
    },
    {
      label: 'Dismissed by Judge',
      name: 'proceduralHistory.dismissedByJudge',
      isCheckbox: true,
      isMandatory: false,
    },
  ],
};

export const caseStatusForm = {
  boxTitle: 'Case Status',
  name: 'caseStatus',
  withAdd: true,
  inputBoxes: [
    {
      label: 'Status',
      name: 'caseStatus.caseStatus',
      placeholder: 'Enter status name',
      width: '311px',
      isDropdown: true,
      isMandatory: true,
    },
    {
      label: 'Comment',
      name: 'caseStatus.comment',
      placeholder: 'Enter comment',
      tooltipText: 'Comments are visible to clients.',
      width: '533px',
      isMandatory: false,
    },
  ],
};

export const proceduralHistoryColumns = [
  {
    Header: 'Assigned Attorney',
    accessor: 'assignedAttorney',
    edit: true,
    width: 20,
    Cell: DropdownCell,
    options: 'attorneys',
    saveOnBlur: true,
  },
  {
    Header: 'Hearing Type',
    accessor: 'hearingType',
    width: 15,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDateTime',
    width: 15,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('L') : '',
  },
  {
    Header: 'Court Time',
    accessor: 'courtDateTimee',
    width: 15,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('LT') : '',
  },
  {
    Header: 'Date of Writ',
    accessor: 'dateOfWrit',
    width: 15,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CaseStatus,
    width: 15,
  },
  {
    Header: '',
    accessor: 'control',
    width: 6,
    minWidth: '48px',
    Cell: (props) => {
      // eslint-disable-next-line react/prop-types, react/destructuring-assignment
      const isAttorneyAssigned = !!props.row.original.assignedAttorney;

      const canDelete = useAllowed([rolesDB.Admin]);
      return (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {isAttorneyAssigned && canDelete && <DeleteCell {...props} />}
          {!isAttorneyAssigned && <CaseInformationEditableCell {...props} showDelete={canDelete} />}
        </Box>
      );
    },
  },
];

export const arizonaRelatedCasesColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    width: 15,
    Cell: ReferenceNumberCell,
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    width: 10,
    Cell: TextCell,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'opposingPartyName',
    width: 30,
    Cell: TextCell,
  },
  {
    Header: 'Apartment #',
    accessor: 'apartmentNumber',
    width: 12,
    Cell: TextCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 18,
    Cell: CaseStatus,
  },
  {
    Header: 'Creation Date',
    accessor: 'creationDate',
    width: 13,
    Cell: DateCell,
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    width: 13,
    Cell: DateCell,
  },
  {
    Header: 'Duplicate',
    accessor: 'isPossibleDuplicate',
    width: 10,
    Cell: DuplicateCell,
  },
];

export const caseStatusColumns = [
  {
    Header: 'Status',
    accessor: 'status',
    width: 24,
    Cell: CaseStatus,
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    width: 22,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Updated By',
    accessor: 'updatedBy',
    width: 22,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 24,
  },
  {
    Header: '',
    accessor: 'control',
    width: 8,
    minWidth: 80,
    Cell: (props) => {
      return <CaseStatusDeleteCell {...props} />;
    },
  },
];

export const hearingTypes = [
  { label: 'Initial Hearing', value: 'Initial Hearing', id: 1 },
  { label: 'Bench Trial', value: 'Bench Trial', id: 2 },
  { label: 'Jury Trial', value: 'Jury Trial', id: 3 },
  { label: 'Oral Argument', value: 'Oral Argument', id: 4 },
  { label: 'Status Conference', value: 'Status Conference', id: 5 },
  { label: 'Continuance', value: 'Continuance', id: 6 },
  { label: 'Reset', value: 'Reset', id: 7 },
  { label: 'Appeal', value: 'Appeal', id: 8 },
  { label: 'Evidentiary Hearing', value: 'Evidentiary Hearing', id: 9 },
  { label: 'Case Stayed', value: 'Case Stayed', id: 10 },
  { label: 'Mediation', value: 'Mediation', id: 11 },
];
