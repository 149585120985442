import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MDialog from '../../../atoms/MDialog';
import MButton from '../../../MUI/Button/MButton';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import { isEmailValid } from '../../../../utils/formHelpers';

const NoticeSendDialog = ({ isOpen, onClose, onConfirm }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState();

  const handleChange = (ev) => {
    setEmail(ev.target.value);
    const isValid = isEmailValid(ev.target.value);
    if (isValid && !!error) {
      setError(undefined);
    }
  };

  const handleBlur = () => {
    const isValid = isEmailValid(email);

    if (isValid) {
      setError(undefined);
    } else {
      setError({ message: 'Invalid email' });
    }
  };

  const handleConfirm = () => {
    if (!error) {
      onConfirm(email);
      onClose();
    }
  };
  return (
    <MDialog
      isOpen={isOpen}
      onClose={onClose}
      onClosed={onClose}
      title="Send Notices"
      maxWidth="sm"
      fullWidth
      footerActions={[
        <MButton variant="secondary" size="large" onClick={onClose}>
          Cancel
        </MButton>,
        <MButton type="submit" size="large" disabled={!email || !!error} onClick={handleConfirm}>
          Confirm
        </MButton>,
      ]}
    >
      <EditInputWithLabel
        type="text"
        error={error}
        label="Recipient Email"
        placeholder=""
        width="100%"
        name="email"
        isMandatory
        value={email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </MDialog>
  );
};

NoticeSendDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

NoticeSendDialog.defaultProps = {};

export default NoticeSendDialog;
