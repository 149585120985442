import React from 'react';
import { Container } from '@mui/material';
import ProceduralHistory from './ProceduralHistory';
import CaseStatus from './CaseStatus';
import RelatedCases from './RelatedCases';
import CaseInformation from './CaseInformation';

export default function CivilMatterCaseInformationArizona() {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      <CaseInformation />
      <ProceduralHistory />
      <CaseStatus />
      <RelatedCases />
    </Container>
  );
}
