import React, { useCallback, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import { FormContext } from '../Form';

const Field = (props) => {
  const { name, render, isMandatory } = props;

  const {
    control,
    formState: { errors },
  } = useContext(FormContext);

  const error = get(errors, name, '');

  const onCustomChange = useCallback(
    (callBack) => (value) => callBack({ target: { value, name } }),
    [name],
  );

  const customRenderHandler = useCallback(
    (renderIpi) => render({ ...renderIpi, onCustomChange, error }),
    [onCustomChange, render, error],
  );

  return (
    <Controller
      name={name}
      control={control}
      render={customRenderHandler}
      rules={{ required: isMandatory && 'Mandatory field' }}
    />
  );
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  isMandatory: PropTypes.bool,
};

Field.defaultProps = {
  isMandatory: false,
};

export default Field;
