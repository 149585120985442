import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Typography, styled, LinearProgress, Box, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getDocument, getTypeofEntity } from '../../../../store/slices/documentsSlice';

import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';
import MTooltip from '../../../atoms/MTooltip/MTooltip';
import { isInFuture } from '../../../../utils/dateHelpers';
import { ReactComponent as Edit } from '../../../../assets/icons/Edit.svg';
import TextInput from '../../../MUI/MUITextInput';
import DocumentContext from './DocumentContext';

const StyledTypography = styled(Typography)(({ underlined }) => ({
  textDecoration: underlined && 'underline',
  '&:hover': {
    cursor: underlined && 'pointer',
  },
}));

export const DocumentsLinkCell = ({ value: docName, isDB, row, refreshPage }) => {
  const initialValue = docName || row?.original?.fileName || 'No name';
  const splitFilename = (val = initialValue) => {
    const dotIndex = val.lastIndexOf('.');
    return dotIndex === -1 ? [val, ''] : [val.slice(0, dotIndex), val.slice(dotIndex + 1)];
  };

  const [value, setValue] = useState(splitFilename()[0]);
  const [isEdit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const { handleChangeDocumentName } = useContext(DocumentContext);
  const [isLoader, setIsLoader] = useState(false);
  const {
    pathname,
    state: { id: entityId },
  } = useLocation();
  const typeofEntity = getTypeofEntity(pathname);

  const handleDownload = () => {
    if (!isLoader) {
      setIsLoader(true);
      dispatch(getDocument({ id: entityId, documentId: row.original.documentId, typeofEntity }))
        .unwrap()
        .then(({ result }) => {
          setIsLoader(false);
          openOrDownloadBinary({
            contentType: result.contentType,
            content: result.content,
            fileName: result.originalFileName,
          });
        });
    }
  };

  const handleBlur = async () => {
    setEdit(false);
    setIsLoader(true);

    const ext = splitFilename()[1];
    await handleChangeDocumentName({
      documentId: row.original.documentId,
      documentName: ext ? `${value}.${ext}` : value,
    });
    setIsLoader(false);
    refreshPage();
  };

  const handleClickEdit = () => {
    setEdit(true);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(splitFilename(initialValue)[0]);
  }, [initialValue, isEdit]);

  if (isLoader) {
    return (
      <Box sx={{ width: '100%' }} data-testid="loader">
        <LinearProgress />
      </Box>
    );
  }

  if (isEdit) {
    const onKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleBlur();
      }
    };

    return (
      <TextInput
        sx={{ width: 'fit-content', height: 'fit-content' }}
        value={value}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
        onChange={handleChange}
        height={32}
        data-testid="edit_name_input"
        inputProps={{ 'data-testid': 'edit_name_input_field' }}
      />
    );
  }

  if (row.original.prefileDate && isInFuture(row.original.prefileDate)) {
    return (
      <StyledTypography
        data-testid="future_prefile_cell"
        component="a"
        variant="primary"
        color="GrayText"
        underlined={false}
      >
        {splitFilename(initialValue)[0]}
        <MTooltip text="Prefile date is in the future, all actions are disabled for this document." />
      </StyledTypography>
    );
  }

  return (
    <>
      <StyledTypography
        component="a"
        onClick={handleDownload}
        variant="primary"
        color="primary"
        underlined
        data-testid="initial_link"
      >
        {splitFilename(initialValue)[0]}
      </StyledTypography>
      {isDB && row.original?.source?.toLowerCase() === 'recognized' && (
        <IconButton onClick={handleClickEdit} data-testid="edit_button">
          <Edit />
        </IconButton>
      )}
    </>
  );
};

DocumentsLinkCell.propTypes = {
  refreshPage: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isDB: PropTypes.bool,
  // eslint-disable-next-line
  row: PropTypes.object.isRequired,
};

DocumentsLinkCell.defaultProps = {
  isDB: true,
};
