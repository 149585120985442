import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';
import Table from '../../../atoms/Table/Table';
import { caseInformationBoxes } from './CaseInformationView.constants';
import {
  getCaseInformationNevada,
  getCaseInformationArizona,
  getRelativeCases,
} from '../../../../store/slices/cases/caseCpSlice';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
    '.MuiPaper-root': {
      boxShadow: '0px 3px 20px rgba(69, 80, 121, 0.13)',
      marginBottom: '16px',
      borderRadius: '24px',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiAccordionSummary-root': {
      padding: '0 32px',
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '32px 0',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '16px',
      height: '16px',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 32px 32px',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

const CaseInformationView = () => {
  const {
    state: { id, stateCode = 'NV', caseType },
  } = useLocation();

  const {
    getCaseInformationArizona: caseInfoArizona,
    getCaseInformationNevada: caseInfoNevada,
    relativeCases,
  } = useSelector((state) => state.caseCp);

  const { caseInfo, getCaseInformation } = useMemo(() => {
    if (stateCode === 'NV') {
      return {
        caseInfo: {
          ...caseInfoNevada,
          relativeCases: relativeCases || [],
        },
        getCaseInformation: getCaseInformationNevada,
      };
    }
    if (stateCode === 'AZ') {
      return {
        caseInfo: {
          ...caseInfoArizona,
          relativeCases: relativeCases || [],
        },
        getCaseInformation: getCaseInformationArizona,
      };
    }
    return {
      caseInfo: {},
      getCaseInformation: () => ({}),
    };
  }, [stateCode, caseInfoNevada, caseInfoArizona]);

  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();

  const caseInformation = isLoader ? {} : caseInfo;

  const getCaseInformationData = async () => {
    setIsLoader(true);
    try {
      await dispatch(getCaseInformation(id)).unwrap();
      await dispatch(getRelativeCases(id)).unwrap();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCaseInformationData();
    }
  }, [id]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
      data-testid="case_information_view_wrapper"
    >
      {caseInformationBoxes &&
        caseInformationBoxes.map((data) => {
          return (
            <StyledContainer
              key={data.boxTitle}
              maxWidth={false}
              disableGutters
              sx={{
                mx: 'auto',
                mt: 4,
                px: 4,
                maxWidth: 1542,
                width: 1,
                position: 'relative',
              }}
            >
              <StyledAccordion defaultExpanded>
                <AccordionSummary expandIcon={<ArrowDown />}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">{data.boxTitle}</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {isLoader && (
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      zIndex={10}
                      transform="translate(-50%, -50%)"
                    >
                      <CircularProgress data-testid="circular_progress" />
                    </Box>
                  )}
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={3}
                    rowGap={2}
                    flexWrap="wrap"
                  >
                    {(typeof data.fields === 'function' ? data.fields(stateCode) : data.fields).map(
                      (item) => {
                        if (item.isDatePicker) {
                          return (
                            <DateOrTimePicker
                              name={item.name}
                              label={item.label}
                              isDate
                              Svg={Calender}
                              selected={_.get(caseInformation, item.name, null)}
                              disabled
                              width={item.width}
                              height={item.height}
                              placeholder={item.placeholder}
                            />
                          );
                        }

                        return (
                          <EditInputWithLabel
                            isDisabled
                            key={item.name}
                            type="text"
                            label={item.label}
                            name={item.name}
                            value={_.get(caseInformation, item.name, '')}
                            placeholder={item.placeholder}
                            width={item.width}
                          />
                        );
                      },
                    )}

                    {data.tableColumns && (
                      <Table
                        columns={
                          Array.isArray(data.tableColumns)
                            ? data.tableColumns
                            : data.tableColumns(caseType, stateCode)
                        }
                        loading={false}
                        rows={_.get(caseInformation, data.tableName, [])}
                      />
                    )}
                  </Stack>
                </AccordionDetails>
              </StyledAccordion>
            </StyledContainer>
          );
        })}
    </Container>
  );
};

export default CaseInformationView;
