import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SubPageHeading from '../../../../organisms/SubPageHeading';
import { subPageNavEdit, subPageNavNew } from './ContactContainer.constants';

const ContactContainer = () => {
  const {
    state: { navPage, subNav, contact },
  } = useLocation();

  const { contactId: id, clientUserId, contactName: name } = contact || {};

  const isClientUserContact = !id && clientUserId;

  const subPageNavEditExtended = subPageNavEdit.map((item) => ({
    ...item,
    state: {
      navPage,
      subNav,
      contact,
      ...(!isClientUserContact && { id }),
    },
  }));

  return (
    <>
      <SubPageHeading
        subPageNav={name ? subPageNavEditExtended : subPageNavNew}
        subPageHeading={name || 'New Contact'}
        name={name}
        nav={{
          to: '/db/datahandling/contacts',
          state: {
            navPage,
            subNav,
          },
        }}
      />
      <Outlet />
    </>
  );
};

export default ContactContainer;
