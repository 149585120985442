import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Container,
  Stack,
  Typography,
  Box,
  Accordion,
  styled,
  AccordionSummary,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import {
  hearingTypes,
  damagesForm,
  weekdays,
  rentTypes,
  DAYS_IN_MONTH,
  DAYS_IN_WEEK,
  TABLE_COLUMNS,
  TABLE_ROWS_COUNT,
  totalForm,
} from './CaseInformationArizona.constants';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import Dropdown from '../../../atoms/Dropdown';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import MButton from '../../../MUI/Button/MButton';
import {
  getArizonaAttorneys,
  getArizonaProceduralHistoryEntries,
  updateDamages,
  getArizonaCaseInformation,
  getArizonaViolationTemplatesForCase,
} from '../../../../store/slices/cases/caseInformationArizonaSlice';
import Calculator from '../../../molecules/Calculator/Calculator';
import { parseNumberFromString } from '../../../../utils/parseNumberFromString';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import { convertObjectValuesToNumber } from '../../../../utils/convertObjectValuesToNumbers';
import notificationUtils from '../../../../utils/notificationUtils';
import LoaderCover from '../../../atoms/LoaderCover';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { ReactComponent as Add } from '../../../../assets/icons/AddCurColor.svg';
import Table from '../../../atoms/Table/Table';
import { apiClient } from '../../../../lib/apiClient';
import NotificationDialog from '../../../molecules/NotificationDialog';
import EditExpenseEntry from './EditExpenseEntry';
import EditTimeEntry from './EditTimeEntry';

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
    '.MuiPaper-root': {
      boxShadow: '0px 3px 20px rgba(69, 80, 121, 0.13)',
      marginBottom: '16px',
      borderRadius: '24px',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '16px',
      height: '16px',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 32px 32px',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

const StyledTypography = styled(Typography)(() => ({
  '&:not(:first-of-type)': {
    // marginTop: 32,
  },
}));

const Damages = ({ liftFormData }) => {
  const dispatch = useDispatch();
  const {
    state: { id: caseId, caseType },
  } = useLocation();

  const [isLoader, setIsLoader] = useState(false);
  const [attorneyBilling, setAttorneyBilling] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [legalCategories, setLegalCategories] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const { attorneys, caseCreationDate, arizonaCaseInformation, proceduralHistoryEntries } =
    useSelector((state) => state.caseInformationArizona);

  const form = useForm({
    defaultValues: arizonaCaseInformation?.damages,
  });

  const getNumbersFromForm = (fields) =>
    form.getValues(fields).map((item) => parseNumberFromString(item) || 0);

  const latestCourtDate = useMemo(() => {
    if (!proceduralHistoryEntries.length) {
      return 0;
    }
    return proceduralHistoryEntries[0].courtDateTime;
  }, [proceduralHistoryEntries.length]);

  useEffect(() => {
    dispatch(getArizonaProceduralHistoryEntries(caseId));
    dispatch(getArizonaCaseInformation(caseId));
    dispatch(getArizonaAttorneys(caseId));
  }, []);

  useEffect(() => {
    const fetchLastDates = async () => {
      const {
        data: { result },
      } = await apiClient.get(`/api/cases/${caseId}/lastInvoicing`);
      setLastDate(moment.max(Object.values(result).map((date) => moment(date))));
    };

    fetchLastDates();
  }, [caseId]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await apiClient.get(`/api/cases/attorneyActivities/time/categories`);
      const nextCategories = response?.data?.result?.map(({ id: categoryId, name }) => {
        return {
          id: categoryId,
          value: categoryId,
          label: name,
        };
      });

      setLegalCategories(nextCategories);
    };

    fetchCategories();
  }, [apiClient, caseId, setLegalCategories]);

  useEffect(() => {
    if (!arizonaCaseInformation?.caseId) return;

    const weekDay = arizonaCaseInformation?.damages?.damageDatesAndRent?.weekday;
    const rentType = arizonaCaseInformation?.damages?.damageDatesAndRent?.rentType;

    setInitialValues({
      ...arizonaCaseInformation?.damages,
      damageDatesAndRent: {
        ...arizonaCaseInformation?.damages?.damageDatesAndRent,
        weekday: weekdays.find((item) => item.value === weekDay),
        rentType: rentTypes.find((item) => item.value === rentType),
      },
      total: {
        superiorCourt: arizonaCaseInformation?.damages?.isSuperiorCourt,
      },
    });
    form.reset({
      ...arizonaCaseInformation?.damages,
      damageDatesAndRent: {
        ...arizonaCaseInformation?.damages?.damageDatesAndRent,
        weekday: weekdays.find((item) => item.value === weekDay),
        rentType: rentTypes.find((item) => item.value === rentType),
      },
      total: {
        superiorCourt: arizonaCaseInformation?.damages?.isSuperiorCourt,
      },
    });
  }, [arizonaCaseInformation?.damages]);

  // TotalLateCharges: ((DaysLate * DailyLateCharge) + InitialLateFee)
  useEffect(() => {
    const [dailyLateCharge, initialLateFee, quantityOfDaysLate, totalLateCharges] =
      getNumbersFromForm([
        'lateCharges.dailyLateCharge',
        'lateCharges.initialLateFee',
        'lateCharges.quantityOfDaysLate',
        'chargesAndTotalDue.totalLateCharges',
      ]);

    let newCalculation = quantityOfDaysLate * dailyLateCharge + initialLateFee;

    if (
      totalLateCharges &&
      form.getValues('lateCharges.initialLateFee') === null &&
      form.getValues('lateCharges.quantityOfDaysLate') === null
    ) {
      newCalculation = totalLateCharges;
    }
    if (totalLateCharges !== newCalculation) {
      form.setValue('chargesAndTotalDue.totalLateCharges', newCalculation);
    }
  }, [
    form.watch([
      'lateCharges.dailyLateCharge',
      'lateCharges.initialLateFee',
      'lateCharges.quantityOfDaysLate',
    ]),
  ]);

  const values = form.getValues();

  useEffect(() => {
    if (!isEmpty(values) && initialValues) {
      // if something was changed we recalculate totalLateCharges
      if (
        Number(values.chargesAndTotalDue.concession) !==
          Number(initialValues.chargesAndTotalDue.concession) ||
        Number(values.chargesAndTotalDue.credit) !==
          Number(initialValues.chargesAndTotalDue.credit) ||
        Number(values.chargesAndTotalDue.monthToMonth) !==
          Number(initialValues.chargesAndTotalDue.monthToMonth) ||
        Number(values.chargesAndTotalDue.otherCharges) !==
          Number(initialValues.chargesAndTotalDue.otherCharges) ||
        Number(values.chargesAndTotalDue.otherDesc) !==
          Number(initialValues.chargesAndTotalDue.otherDesc) ||
        Number(values.chargesAndTotalDue.previousBalance) !==
          Number(initialValues.chargesAndTotalDue.previousBalance) ||
        Number(values.chargesAndTotalDue.totalLateCharges) !==
          Number(initialValues.chargesAndTotalDue.totalLateCharges) ||
        Number(values.chargesAndTotalDue.utilities) !==
          Number(initialValues.chargesAndTotalDue.utilities) ||
        !isEqual(values.damageDatesAndRent, initialValues.damageDatesAndRent) ||
        !isEqual(values.justiceFeesAndCosts, initialValues.justiceFeesAndCosts) ||
        !isEqual(values.lateCharges, initialValues.lateCharges) ||
        !isEqual(values.superiorFeesAndCosts, initialValues.superiorFeesAndCosts) ||
        values.isSuperiorCourt !== initialValues.isSuperiorCourt
      ) {
        const [dailyLateCharge, initialLateFee, quantityOfDaysLate, totalLateCharges] =
          getNumbersFromForm([
            'lateCharges.dailyLateCharge',
            'lateCharges.initialLateFee',
            'lateCharges.quantityOfDaysLate',
            'chargesAndTotalDue.totalLateCharges',
          ]);
        const newCalculation = quantityOfDaysLate * dailyLateCharge + initialLateFee;
        if (totalLateCharges !== newCalculation) {
          form.setValue('chargesAndTotalDue.totalLateCharges', newCalculation);
        }
      }
    }
  }, [values, initialValues]);

  // DaysLate: (difference in days of LateFeeStartDate and LateFeeEndDate)
  useEffect(() => {
    const [lateFeesEndOn, lateFeesStartOn, quantityOfDaysLate] = form.getValues([
      'lateCharges.lateFeesEndOn',
      'lateCharges.lateFeesStartOn',
      'lateCharges.quantityOfDaysLate',
    ]);

    if (lateFeesStartOn && !lateFeesEndOn) {
      const courtDate = form.watch('lateCharges.courtDateTime');
      form.setValue('lateCharges.lateFeesEndOn', courtDate ? moment(courtDate) : new Date());
    }

    if (lateFeesEndOn && lateFeesStartOn) {
      const newCalculation = Math.abs(moment(lateFeesStartOn).diff(lateFeesEndOn, 'days'));
      const valueToSet = Math.abs(newCalculation + 1);
      if (quantityOfDaysLate !== valueToSet) {
        form.setValue('lateCharges.quantityOfDaysLate', valueToSet);
      }
    }
  }, [form.watch(['lateCharges.lateFeesEndOn', 'lateCharges.lateFeesStartOn'])]);

  // MonthToMonthCalc: (difference in months among TrialDate (the latest Court Date)
  // and CreatedDate(Case creation date of the Case) times (multiply) MonthToMonth rate)
  useEffect(() => {
    const [monthToMonthRate, monthToMonth] = getNumbersFromForm([
      'damageDatesAndRent.monthToMonthRate',
      'chargesAndTotalDue.monthToMonth',
    ]);

    if (monthToMonthRate < 1 || Number.isNaN(monthToMonthRate)) {
      if (monthToMonth !== '') {
        form.setValue('chargesAndTotalDue.monthToMonth', '');
      }
    }

    if (latestCourtDate && caseCreationDate && monthToMonthRate) {
      const diffInDays = Math.abs(moment(latestCourtDate).diff(moment(caseCreationDate), 'days'));
      const diffInMonths = Math.ceil(diffInDays / DAYS_IN_MONTH);
      const newCalculation = +(monthToMonthRate * diffInMonths).toFixed(2);

      if (monthToMonth !== newCalculation) {
        form.setValue('chargesAndTotalDue.monthToMonth', newCalculation);
      }
    }
  }, [latestCourtDate, caseCreationDate, form.watch('damageDatesAndRent.monthToMonthRate')]);

  useEffect(() => {
    const [rentType, firstUnpaidDueDate, nextRentalDueDate] = form.getValues([
      'damageDatesAndRent.rentType',
      'damageDatesAndRent.firstUnpaidDueDate',
      'damageDatesAndRent.nextRentalDueDate',
    ]);
    const [monthlyWeeklyRent, currentRentDue] = getNumbersFromForm([
      'damageDatesAndRent.monthlyWeeklyRent',
      'damageDatesAndRent.currentRentDue',
    ]);

    const rentTypeValue = rentType?.value;

    const damagesAndRent = arizonaCaseInformation?.damages?.damageDatesAndRent;

    if (
      rentTypeValue === damagesAndRent?.rentType &&
      moment(nextRentalDueDate).format('YYYY-MM-DDTHH:mm:ss') ===
        damagesAndRent?.nextRentalDueDate &&
      moment(firstUnpaidDueDate).format('YYYY-MM-DDTHH:mm:ss') ===
        damagesAndRent?.firstUnpaidDueDate &&
      Number(monthlyWeeklyRent) === Number(damagesAndRent?.monthlyWeeklyRent)
    ) {
      form.setValue('damageDatesAndRent.currentRentDue', damagesAndRent?.currentRentDue);
      return;
    }

    if (!rentTypeValue || !nextRentalDueDate || !firstUnpaidDueDate || !monthlyWeeklyRent) {
      if (currentRentDue !== 0) {
        form.setValue('damageDatesAndRent.currentRentDue', '');
      }
      return;
    }

    let calcDate = nextRentalDueDate;
    const dateToUseInCalculation = caseCreationDate;

    if (dateToUseInCalculation) {
      if (new Date(dateToUseInCalculation) >= new Date(nextRentalDueDate)) {
        calcDate = dateToUseInCalculation;
      }
    }

    const diffInMiliseconds = new Date(firstUnpaidDueDate) - new Date(calcDate);
    const diffInDays = Math.ceil(Math.abs(diffInMiliseconds) / (1000 * 60 * 60 * 24));

    let diff =
      rentTypeValue === 'Monthly'
        ? Math.abs(
            moment(calcDate)
              .startOf('day')
              .subtract(1, 'day')
              .diff(moment(firstUnpaidDueDate).startOf('day'), 'months'),
          ) + 1
        : Math.ceil(diffInDays / DAYS_IN_WEEK);

    if (rentTypeValue === 'Biweekly') {
      diff = Math.ceil(diffInDays / (DAYS_IN_WEEK * 2));
    }

    const newCurrentRentDue = diff * monthlyWeeklyRent;

    if (currentRentDue !== newCurrentRentDue) {
      form.setValue('damageDatesAndRent.currentRentDue', newCurrentRentDue);
    }
  }, [
    latestCourtDate,
    form.watch([
      'damageDatesAndRent.rentType',
      'damageDatesAndRent.nextRentalDueDate',
      'damageDatesAndRent.monthlyWeeklyRent',
      'damageDatesAndRent.firstUnpaidDueDate',
    ]),
  ]);

  useEffect(() => {
    const firstUnpaidDueDateVal = form.getValues('damageDatesAndRent.firstUnpaidDueDate');
    form.setValue(
      'lateCharges.lateFeesStartOn',
      firstUnpaidDueDateVal ? moment(firstUnpaidDueDateVal) : null,
    );
  }, [form.watch('damageDatesAndRent.firstUnpaidDueDate')]);

  // TotalDue:
  //  (
  //    (
  //      (NsfCharges + ServiceProcess + PreviousBalance + Concession + OtherCharges + Utilities  +
  //        (If OverrideRentDue has value then OverrideRentDue else CurrentRentDue)
  //      + TotalLateCharges)
  //    - Credit)
  //  + MonthToMonthCalc) - PaidPostJudgment
  useEffect(() => {
    const [
      nsfCharges,
      credit,
      serviceOfProcess,
      utilities,
      concession,
      otherCharges,
      monthToMonth,
      currentRentDue,
      overrideRentDue,
      previousBalance,
      totalLateCharges,
    ] = getNumbersFromForm([
      'lateCharges.nsfCharges',
      'chargesAndTotalDue.credit',
      'lateCharges.serviceOfProcess',
      'chargesAndTotalDue.utilities',
      'chargesAndTotalDue.concession',
      'chargesAndTotalDue.otherCharges',
      'chargesAndTotalDue.monthToMonth',
      'damageDatesAndRent.currentRentDue',
      'damageDatesAndRent.overrideRentDue',
      'chargesAndTotalDue.previousBalance',
      'chargesAndTotalDue.totalLateCharges',
    ]);

    const rentDue = overrideRentDue || currentRentDue;

    const part1 =
      serviceOfProcess +
      totalLateCharges +
      previousBalance +
      otherCharges +
      nsfCharges +
      concession +
      utilities +
      rentDue;

    const newCalculation = (part1 - credit + monthToMonth).toFixed(2);
    form.setValue('total.totalDue', Number(newCalculation));
  }, [
    form.watch([
      'lateCharges.nsfCharges',
      'chargesAndTotalDue.credit',
      'lateCharges.serviceOfProcess',
      'chargesAndTotalDue.utilities',
      'chargesAndTotalDue.concession',
      'chargesAndTotalDue.otherCharges',
      'chargesAndTotalDue.monthToMonth',
      'damageDatesAndRent.currentRentDue',
      'damageDatesAndRent.overrideRentDue',
      'chargesAndTotalDue.previousBalance',
      'chargesAndTotalDue.totalLateCharges',
    ]),
  ]);

  const getOptions = useCallback(
    (name) => {
      switch (name) {
        case 'proceduralHistory.hearingType':
          return hearingTypes;
        case 'proceduralHistory.assignedAttorney':
          return attorneys;
        case 'damageDatesAndRent.weekday':
          return weekdays;
        case 'damageDatesAndRent.rentType':
          return rentTypes;
        default:
          return [];
      }
    },
    [attorneys],
  );

  const getOnChange = useCallback(
    (onChange, name) => (valueOrEvent) => {
      if (name === 'damageDatesAndRent.rentType') {
        const [firstUnpaidDueDate, nextRentalDueDate] = form.getValues([
          'damageDatesAndRent.firstUnpaidDueDate',
          'damageDatesAndRent.nextRentalDueDate',
        ]);

        const rentTypeValue = valueOrEvent?.value;

        if (rentTypeValue === 'Monthly') {
          if (!firstUnpaidDueDate) {
            form.setValue('damageDatesAndRent.firstUnpaidDueDate', moment().startOf('month'));
          }

          if (!nextRentalDueDate)
            form.setValue(
              'damageDatesAndRent.nextRentalDueDate',
              moment().add(1, 'month').startOf('month'),
            );
        }

        if (rentTypeValue === 'Weekly' || rentTypeValue === 'Biweekly') {
          form.setValue('damageDatesAndRent.firstUnpaidDueDate', null);
          form.setValue('damageDatesAndRent.nextRentalDueDate', null);
        }
      }

      if (name === 'lateCharges.lateFeesStartOn') {
        const courtDate = form.getValues('lateCharges.courtDateTime');
        form.setValue('lateCharges.lateFeesEndOn', courtDate ? moment(courtDate) : new Date());
      }

      onChange(valueOrEvent);
    },
    [],
  );

  const handleSubmit = useCallback(
    (formValues) => {
      setIsLoader(true);

      const isSuperiorCourt = Boolean(formValues?.total?.superiorCourt);

      const formState = {
        caseId,
        isSuperiorCourt,
        totalDue: formValues.total.totalDue,
        lateCharges: {
          ...convertObjectValuesToNumber(formValues?.lateCharges),
          lateFeesEndOn: formValues?.lateCharges.lateFeesEndOn || null,
          lateFeesStartOn: formValues?.lateCharges.lateFeesStartOn || null,
        },
        chargesAndTotalDue: {
          ...convertObjectValuesToNumber(formValues?.chargesAndTotalDue),
          monthToMonth: formValues?.chargesAndTotalDue?.monthToMonth || null,
          otherDesc: formValues?.chargesAndTotalDue?.otherDesc || '',
        },
        damageDatesAndRent: {
          ...formValues?.damageDatesAndRent,
          contractRent: formValues?.damageDatesAndRent?.contractRent || null,
          monthlyWeeklyRent: formValues?.damageDatesAndRent?.monthlyWeeklyRent || null,
          monthToMonthRate: formValues?.damageDatesAndRent?.monthToMonthRate || null,
          currentRentDue: formValues?.damageDatesAndRent?.currentRentDue || null,
          overrideRentDue: formValues?.damageDatesAndRent?.overrideRentDue || null,
          rentType: formValues?.damageDatesAndRent?.rentType?.value || 'Monthly',
          weekday: formValues?.damageDatesAndRent?.weekday?.value || '',
        },
        superiorFeesAndCosts: {
          ...formValues.superiorFeesAndCosts,
          milage: parseNumberFromString(formValues.superiorFeesAndCosts.milage),
        },
        justiceFeesAndCosts: {
          ...formValues.justiceFeesAndCosts,
          milage: parseNumberFromString(formValues.justiceFeesAndCosts.milage),
        },
      };

      return dispatch(updateDamages(formState))
        .unwrap()
        .then(() => {
          notificationUtils.success('Updated successfully');
        })
        .catch(() => notificationUtils.error('Something went wrong'))
        .finally(() => {
          dispatch(getArizonaViolationTemplatesForCase(caseId));
          setIsLoader(false);
        });
    },
    [caseId],
  );

  useEffect(() => {
    liftFormData({
      form,
      onSubmit: handleSubmit,
    });
  }, [form, handleSubmit]);

  const onUpdateData = useCallback(() => {}, []);

  const onDeleteRow = useCallback(
    (rowId) => {
      // TODO: there should be activityId, but it should be fixed on the backend side first
      const { civilMatterId } = attorneyBilling.items[rowId];
      setRowToDelete(civilMatterId);
    },
    [attorneyBilling],
  );

  const handleFetchDataPage = useCallback((currentPage = 1) => {
    const fetcher = async () => {
      const reqBody = {
        caseId,
      };
      setTableLoading(true);
      const filterResponse = await apiClient.post(
        `/api/cases/${caseId}/attorneyActivities/filter`,
        {
          currentPage,
          pageSize: TABLE_ROWS_COUNT,
          ...reqBody,
        },
      );

      const totalResponse = await apiClient.post(
        `/api/cases/${caseId}/attorneyActivities/total`,
        reqBody,
      );

      const {
        data: { result },
      } = filterResponse;
      const {
        quantityTotal,
        nonBillableTotal,
        billableTotal,
        nonBillableQuantityTotal,
        billableQuantityTotal,
      } = totalResponse.data.result;

      if (result?.items?.length) {
        result.items.push({
          isTotalRow: true,
          civilMatterId: null, // TODO: there should be activityId, but it should be fixed on the backend side first
          type: null,
          quantity: quantityTotal,
          description: null,
          rate: null,
          nonBillable: nonBillableTotal,
          billable: billableTotal,
          date: null,
          user: null,
          invoiceStatus: null,
          nonBillableQuantityTotal,
          billableQuantityTotal,
        });
      }

      setAttorneyBilling(result);
      setTableLoading(false);
    };

    fetcher();
  }, []);

  useEffect(() => {
    handleFetchDataPage(1);
  }, []);

  const handleConfirmDeleteTableRow = useCallback(async () => {
    try {
      await apiClient.delete(`/api/cases/${caseId}/attorneyActivities/${rowToDelete || 200}`);
      setRowToDelete(null);
      handleFetchDataPage(1);
      notificationUtils.success('Deleted successfully');
    } catch {
      notificationUtils.error('Failed, try again later');
    }
  }, [rowToDelete]);

  const handleCancelDeleteTableRow = useCallback(() => {
    setRowToDelete(null);
  }, []);

  const handleOpenModalOnTableEditClick = useCallback(
    (_, original) => {
      setModalData({
        id: caseId,
        title: original.type,
        civilMatterId: original.civilMatterId, // TODO: there should be activityId, but it should be fixed on the backend side first
        isEdit: true,
      });
    },
    [attorneyBilling?.items],
  );

  const handleNewTimeEntry = useCallback(() => {
    setModalData({ title: 'Time', isEdit: false });
  }, []);

  const handleNewExpense = useCallback(() => {
    setModalData({ title: 'Expense', isEdit: false });
  }, []);

  const onCloseModal = () => {
    setModalData(null);
  };

  const handleExpenseEntryConfirm = async (valuesExpense) => {
    const { firmUser, date, description, amount, isNonBillable, isEdit, category } = valuesExpense;

    if (isEdit) {
      // TODO: there should be activityId, but it should be fixed on the backend side first
      await apiClient
        .put(`/api/cases/${caseId}/attorneyActivities/expense/${valuesExpense?.civilMatterId}`, {
          caseId,
          userId: firmUser.id,
          date: moment(date, moment.ISO_8601),
          description,
          amount: +amount,
          isNonBillable,
          category: category.value,
        })
        .then(() => {
          handleFetchDataPage();
          notificationUtils.success('Updated successfully');
          setModalData(null);
        });
    } else {
      await apiClient
        .post(`/api/cases/${caseId}/attorneyActivities/expense`, {
          caseId,
          userId: firmUser.id,
          date: moment(date, moment.ISO_8601),
          description,
          amount: +amount,
          isNonBillable,
          category: category.value,
        })
        .then(() => {
          handleFetchDataPage();
          notificationUtils.success('Added successfully');
          setModalData(null);
        });
    }
  };

  const handleTimeEntryConfirm = useCallback(async (valuesTimeEntry) => {
    const { firmUser, date, description, rate, duration, isNonBillable, isEdit, category } =
      valuesTimeEntry;

    if (isEdit) {
      const params = {
        caseId,
        civilMatterId: valuesTimeEntry?.civilMatterId, // TODO: there should be activityId, but it should be fixed on the backend side first
        userId: firmUser.id,
        date,
        description,
        rate: +rate,
        duration: +duration,
        isNonBillable,
        categoryId: category.value,
      };
      // TODO: there should be activityId, but it should be fixed on the backend side first
      await apiClient
        .put(
          `/api/cases/${caseId}/attorneyActivities/time/${valuesTimeEntry?.civilMatterId}`,
          params,
        )
        .then(() => {
          notificationUtils.success('Updated successfully');
          handleFetchDataPage();
          setModalData(null);
        });
    } else {
      const params = {
        caseId,
        userId: firmUser.id,
        date: moment(date, moment.ISO_8601),
        description,
        rate: +rate,
        duration: +duration,
        isNonBillable,
        categoryId: category.value,
      };

      await apiClient.post(`/api/cases/${caseId}/attorneyActivities/time`, params).then(() => {
        handleFetchDataPage();
        notificationUtils.success('Added successfully');
        setModalData(null);
      });
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit} form={form}>
      {isLoader && <LoaderCover isFixed />}
      <StyledContainer
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          px: 4,
          maxWidth: 1542,
          width: 1,
          position: 'relative',
        }}
      >
        <StyledAccordion defaultExpanded sx={{ p: 4 }}>
          <AccordionSummary expandIcon={<ArrowDown />} sx={{ padding: 0 }}>
            <Typography variant="h5">Damages</Typography>
          </AccordionSummary>
          {damagesForm.map((block) => {
            const inputBoxes =
              typeof block.inputBoxes === 'function'
                ? block.inputBoxes({
                    courtType: form.watch('total.superiorCourt') ? 'Superior' : 'Justice',
                  })
                : block.inputBoxes;
            return (
              <Stack
                direction="row"
                alignItems="center"
                columnGap={3}
                rowGap={2}
                flexWrap="wrap"
                mt={2}
                mb={4}
              >
                {inputBoxes.map((item) => {
                  if (item.isDropdown) {
                    return (
                      <Field
                        key={item.name}
                        name={item.name}
                        isMandatory={item.isMandatory}
                        render={({ field, onCustomChange, error }) => (
                          <Dropdown
                            error={error}
                            value={field.value}
                            isAddDisabled={false}
                            isSearchable
                            label={item.label}
                            width={item.width}
                            isColumn
                            placeholder={item.placeholder}
                            options={getOptions(item.name)}
                            onChange={getOnChange(onCustomChange(field.onChange), item.name)}
                            isDisabled={item.isDisabled}
                            isMandatory={item.isMandatory}
                          />
                        )}
                      />
                    );
                  }

                  if (item.isSubHeading) {
                    return (
                      <StyledTypography width={1} variant="bodyL500" key={item.label}>
                        {item.label}
                      </StyledTypography>
                    );
                  }

                  if (item?.isSpace) {
                    return <div style={{ width: '100%' }} key={item.label} />;
                  }

                  if (item.isCheckbox) {
                    return (
                      <Field
                        key={item.name}
                        name={item.name}
                        render={({ field, onCustomChange }) => (
                          <CheckboxWithLabel
                            label={item.label}
                            isChecked={field.value}
                            onChange={onCustomChange(field.onChange)}
                            marginTop="25px"
                          />
                        )}
                      />
                    );
                  }

                  if (item.isDatePicker) {
                    return (
                      <EditInputWithLabel label={item.label} key={item.name}>
                        <Field
                          name={item.name}
                          isMandatory={item.isMandatory}
                          render={({ field, onCustomChange, error }) => (
                            <DateOrTimePicker
                              name={item.name}
                              isDate
                              error={error}
                              Svg={Calender}
                              selected={field.value}
                              isMandatory={item.isMandatory}
                              width={item.width}
                              type={item.type || 'string'}
                              height={item.height}
                              placeholder={item.placeholder}
                              setSelectedTimeOrDate={getOnChange(
                                onCustomChange(field.onChange),
                                item.name,
                              )}
                            />
                          )}
                        />
                      </EditInputWithLabel>
                    );
                  }

                  // if (item.isCalculator) {
                  //   return (
                  //     <Field
                  //       name={item.name}
                  //       key={item.name}
                  //       render={({ field }) => (
                  //         <Calculator
                  //           value={field.value}
                  //           key={item.label}
                  //           label={item.label}
                  //           placeholder={item.placeholder}
                  //         />
                  //       )}
                  //     />
                  //   );
                  // }

                  // if (item?.isUpdateButton) {
                  //   return (
                  //     <MButton
                  //       sx={{
                  //         padding: '12px 43.5px',
                  //       }}
                  //       size="large"
                  //       type="submit"
                  //       disabled={isLoader}
                  //     >
                  //       {item.text}
                  //     </MButton>
                  //   );
                  // }

                  // if (item?.isFlexGrow) {
                  //   return <Stack flexGrow={item.growValue} />;
                  // }

                  if (item.isFieldWithHeading) {
                    return (
                      <Box display="flex" flexDirection="column" key={item.name}>
                        <StyledTypography width={1} mb={1} variant="bodyM">
                          {item.heading}
                        </StyledTypography>
                        <Field
                          name={item.name}
                          isMandatory={item.isMandatory}
                          render={({ field, error }) => (
                            <EditInputWithLabel
                              type="text"
                              error={error}
                              label={item.label}
                              placeholder={item.placeholder}
                              width={item.width}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              value={field.value}
                              onChange={field.onChange}
                              isDisabled={item.isDisabled}
                            />
                          )}
                        />
                      </Box>
                    );
                  }

                  return (
                    <Field
                      name={item.name}
                      key={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, error }) => (
                        <EditInputWithLabel
                          error={error}
                          type={item.type}
                          label={item.label}
                          placeholder={item.placeholder}
                          width={item.width}
                          name={item.name}
                          value={field.value}
                          onChange={field.onChange}
                          isDisabled={item.isDisabled}
                          isMandatory={item.isMandatory}
                          min={item?.min}
                        />
                      )}
                    />
                  );
                })}
              </Stack>
            );
          })}
          {caseType === 'Eviction Matter' && (
            <>
              <Stack
                flex
                direction="row"
                justifyContent="space-between"
                columnGap={4}
                mt={3}
                mb={3}
              >
                <Typography variant="h5">Attorney Billing</Typography>
                <Stack flex direction="row" columnGap={3}>
                  <Box>
                    <MButton
                      onClick={handleNewTimeEntry}
                      startIcon={<Add />}
                      variant="secondary"
                      // disabled={!canCurrentUserAddActivities}
                    >
                      New Time Entry
                    </MButton>
                  </Box>
                  <Box>
                    <MButton
                      onClick={handleNewExpense}
                      startIcon={<Add />}
                      variant="secondary"
                      // disabled={!canCurrentUserAddActivities}
                    >
                      New Expense
                    </MButton>
                  </Box>
                </Stack>
              </Stack>
              {attorneyBilling?.items?.length ? (
                <Table
                  hasTotalRow
                  columns={TABLE_COLUMNS}
                  onUpdateData={onUpdateData}
                  rows={attorneyBilling?.items}
                  onDeleteRow={onDeleteRow}
                  onModalOpenWhenClickEdit={handleOpenModalOnTableEditClick}
                  total={attorneyBilling?.totalRowsCount}
                  isPagination={attorneyBilling?.totalPages > 1}
                  onNextPage={handleFetchDataPage}
                  onPreviousPage={handleFetchDataPage}
                  onGotoPage={handleFetchDataPage}
                  pageSize={TABLE_ROWS_COUNT}
                  loading={tableLoading}
                />
              ) : (
                <Stack alignItems="center" paddingTop="50px">
                  <EmptyBlock title="No records" />
                </Stack>
              )}
              {modalData?.title === 'Expense' && (
                <EditExpenseEntry
                  modalData={modalData}
                  onClose={onCloseModal}
                  firmUsersList={attorneys}
                  onConfirm={handleExpenseEntryConfirm}
                  isOpen={modalData?.title === 'Expense'}
                  minDate={lastDate}
                />
              )}
              {modalData?.title === 'Time' && (
                <EditTimeEntry
                  modalData={modalData}
                  onClose={onCloseModal}
                  firmUsersList={attorneys}
                  categoriesList={legalCategories}
                  onConfirm={handleTimeEntryConfirm}
                  isOpen={modalData?.title === 'Time'}
                  minDate={lastDate}
                />
              )}
              <NotificationDialog
                title="Confirm delete"
                desc="Are you sure you want to delete activity?"
                type="alert"
                buttonSecondaryText="Cancel"
                buttonPrimaryText="Delete"
                onClose={handleCancelDeleteTableRow}
                onConfirm={handleConfirmDeleteTableRow}
                isOpen={rowToDelete}
              />
            </>
          )}
          <Stack
            direction="row"
            alignItems="center"
            columnGap={3}
            rowGap={2}
            flexWrap="wrap"
            mt={2}
            mb={4}
          >
            {totalForm.inputBoxes.map((item) => {
              if (item.isCalculator) {
                return (
                  <Field
                    name={item.name}
                    key={item.name}
                    render={({ field }) => (
                      <Calculator
                        value={field.value}
                        key={item.label}
                        label={item.label}
                        placeholder={item.placeholder}
                      />
                    )}
                  />
                );
              }

              if (item?.isUpdateButton) {
                return (
                  <MButton
                    sx={{
                      padding: '12px 43.5px',
                    }}
                    size="large"
                    type="submit"
                    disabled={isLoader}
                  >
                    {item.text}
                  </MButton>
                );
              }

              if (item.isCheckbox) {
                return (
                  <Field
                    key={item.name}
                    name={item.name}
                    render={({ field, onCustomChange }) => (
                      <CheckboxWithLabel
                        label={item.label}
                        isChecked={field.value}
                        onChange={onCustomChange(field.onChange)}
                        marginTop="25px"
                      />
                    )}
                  />
                );
              }

              if (item?.isFlexGrow) {
                return <Stack flexGrow={item.growValue} />;
              }
              return null;
            })}
          </Stack>
        </StyledAccordion>
      </StyledContainer>
    </Form>
  );
};

Damages.propTypes = {
  liftFormData: PropTypes.func.isRequired,
};

export default Damages;
