import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Table from '../../../../atoms/Table/Table';
import Searcher from '../../../../molecules/Searcher';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import MButton from '../../../../MUI/Button/MButton';
import { getOwnerEntities } from '../../../../../store/slices/ownerEntitiesSlice';
import { deleteOwnerEntity } from '../../../../../store/slices/editOwnerEntitySlice';
import { OwnerEntitiesColumns, MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './OwnerEntities.constants';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';
import useSafeLocationState from '../../../../../hooks/useSafeLocationState';
import StateLink from '../../../../atoms/StateLink/StateLink';
import BottomBar from '../../../../atoms/BottomBar/BottomBar';

const OwnerEntities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const { navPage, subNav } = useSafeLocationState('Data Handling', 'Owner Entities');
  const ownerEntities = useSelector((state) => state.ownerEntities.ownerEntities);

  const currentPageDelete =
    ownerEntities?.items?.length === 1 && ownerEntities?.currentPage
      ? ownerEntities.currentPage - 1
      : ownerEntities?.currentPage;

  useEffect(() => {
    dispatch(getOwnerEntities({ pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() =>
      setIsLoader(false),
    );
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      dispatch(
        getOwnerEntities({
          currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          ownerEntityName: searchValue,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue],
  );

  const handleChangeEmpty = useCallback((searchElement) => {
    if (!searchElement) {
      setIsLoaderTable(true);
      dispatch(getOwnerEntities({ pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() => {
        setIsLoaderTable(false);
        setSearchValue('');
      });
    }
  }, []);

  const handleSearch = useCallback((searchElement) => {
    setSearchValue(searchElement);
    setIsLoaderTable(true);
    dispatch(
      getOwnerEntities({
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        ownerEntityName: searchElement,
      }),
    ).then(() => {
      setIsLoaderTable(false);
    });
  }, []);

  const handleCreateOwnerEntity = useCallback(() => {
    navigate('/db/datahandling/newownerentity/ownerentity', {
      state: {
        navPage,
        subNav,
      },
    });
  }, []);

  const handleDeleteOwnerEntity = useCallback(
    (id, original) => {
      return dispatch(deleteOwnerEntity(original.ownerEntityId));
    },
    [currentPageDelete],
  );

  const canDelete = useAllowed([rolesDB.Admin]);
  const canCreateDocument = useAllowed([rolesDB.Admin, rolesDB.Attorney, rolesDB.Accounting]);

  const rowsAdapter = ownerEntities?.items?.map((item) => ({
    ...item,
    ownerEntityName: (
      <StateLink
        to="/db/datahandling/editownerentity/ownerentity"
        state={{ navPage, subNav, id: item.ownerEntityId, name: item.ownerEntityName }}
      >
        <Typography
          variant="link"
          color="text.primary"
          sx={{
            cursor: 'pointer',
            textDecorationLine: 'underline',
            textTransform: 'none',
          }}
        >
          {item.ownerEntityName}
        </Typography>
      </StateLink>
    ),
    control: {
      hideDelete: !canDelete,
      autoRefresh: true,
    },
  }));

  if (!isLoader && !rowsAdapter?.length && !searchValue) {
    return (
      <Stack height="calc(100vh - 80px - 72px)" direction="column">
        <Typography variant="h3" margin="0 32px">
          Owner Entity Search
        </Typography>

        <Box margin="auto">
          <EmptyBlock
            title="You don’t have any owner entities yet"
            desc={
              canCreateDocument
                ? 'Every owner entity you create will appear here. To get started click on “Create new owner entity” button below'
                : ''
            }
            buttonText={canCreateDocument ? 'New owner entity' : ''}
            onClick={canCreateDocument ? handleCreateOwnerEntity : undefined}
          />
        </Box>
      </Stack>
    );
  }

  return (rowsAdapter?.length && !isLoader) || searchValue ? (
    <>
      <Box width="1066px" marginX="auto" pb={18}>
        <Stack spacing={3}>
          <Typography variant="h5">Owner Entities</Typography>
          <Searcher
            inputPlaceholder="Search by Owner Entity Name"
            onSearch={handleSearch}
            onChange={handleChangeEmpty}
          />
          {!isLoader && !rowsAdapter?.length && searchValue ? (
            <Box paddingTop="100px" display="flex" justifyContent="center">
              <EmptyBlock
                title="Owner entities are not found"
                desc="Сhange the search value"
                margin="auto"
              />
            </Box>
          ) : (
            <Table
              columns={OwnerEntitiesColumns}
              rows={rowsAdapter}
              total={ownerEntities?.totalRowsCount}
              isPagination={ownerEntities?.totalPages > 1}
              onNextPage={handleFetchDataPage}
              onPreviousPage={handleFetchDataPage}
              onGotoPage={handleFetchDataPage}
              pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
              onDeleteRow={handleDeleteOwnerEntity}
              onRefreshPage={handleFetchDataPage}
              loading={isLoaderTable}
            />
          )}
        </Stack>
      </Box>
      {canCreateDocument && (
        <BottomBar>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton sx={{ minWidth: '144px' }} size="large" onClick={handleCreateOwnerEntity}>
              Create New
            </MButton>
          </Stack>
        </BottomBar>
      )}
    </>
  ) : (
    <Box position="absolute" top="50vh" left="50%" zIndex={10} transform="translate(-50%, -50%)">
      <CircularProgress />
    </Box>
  );
};

export default OwnerEntities;
