import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled, Dialog, Typography, Stack, Box, CircularProgress } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import Table from '../../../../atoms/Table/Table';
import Searcher from '../../../../molecules/Searcher';
import { palette } from '../../../../../theme/default';
import {
  ManagementCommunityAllColumns,
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
  MAX_HEIGHT_DIALOG,
  MAX_HEIGHT_DIALOG_TABLE,
} from './ManagementCommunity.constants';
import { getManagementCommunitiesAll } from '../../../../../store/slices/editManagementSlice';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    padding: 32,
    borderRadius: 24,
    boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
    overflowY: 'hidden',
    maxHeight: MAX_HEIGHT_DIALOG,
  },
});

const StyledTable = styled('div')({
  height: MAX_HEIGHT_DIALOG_TABLE,
});

const ManagementCommunitiesDialog = ({
  isOpen,
  title,
  buttonSecondaryText,
  buttonPrimaryText,
  onClose,
  onConfirm,
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const managementCommunitesAll = useSelector(
    (state) => state.editManagement.managementCommunitesAll,
  );
  const {
    state: { id },
  } = useLocation();

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getManagementCommunitiesAll({
          id,
          currentPage: 1,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          excludeManagementId: id,
        }),
      ).then(() => setIsLoader(false));
    }
  }, [isOpen]);

  const handleCheckedRow = useCallback((selectedRows) => {
    setSelectedIds(selectedRows.map((item) => ({ communityId: item.communityId })));
  }, []);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm?.(selectedIds);
  }, [selectedIds, onConfirm]);

  const handleChangeEmpty = useCallback((searchElement) => {
    if (!searchElement) {
      setIsLoaderTable(true);
      dispatch(
        getManagementCommunitiesAll({
          id,
          currentPage: 1,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          excludeManagementId: id,
        }),
      ).then(() => {
        setSearchValue('');
        setIsLoaderTable(false);
      });
    }
  }, []);

  const handleSearch = useCallback((searchElement) => {
    setSearchValue(searchElement);
    setIsLoaderTable(true);
    dispatch(
      getManagementCommunitiesAll({
        communityName: searchElement,
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        excludeManagementId: id,
      }),
    ).then(() => setIsLoaderTable(false));
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      dispatch(
        getManagementCommunitiesAll({
          currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          communityName: searchValue,
          excludeManagementId: id,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue],
  );

  return (
    <StyledDialog onClose={onClose} open={isOpen} maxWidth="lg" fullWidth>
      <Stack spacing={4}>
        {title && <Typography variant="h4">{title}</Typography>}
        <Searcher
          inputPlaceholder="Search by Community Name"
          onSearch={handleSearch}
          onChange={handleChangeEmpty}
        />
        {managementCommunitesAll?.items && !isLoader && (
          <StyledTable>
            <Table
              columns={ManagementCommunityAllColumns}
              rows={managementCommunitesAll?.items}
              total={managementCommunitesAll?.totalRowsCount}
              isPagination={managementCommunitesAll?.totalPages > 1}
              loading={isLoaderTable}
              onCheckedRow={handleCheckedRow}
              isWithCheckbox
              onNextPage={handleFetchDataPage}
              onPreviousPage={handleFetchDataPage}
              onGotoPage={handleFetchDataPage}
              pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            />
          </StyledTable>
        )}
        {isLoader && (
          <Box
            position="absolute"
            top="50vh"
            left="50%"
            zIndex={10}
            transform="translate(-50%, -50%)"
          >
            <CircularProgress />
          </Box>
        )}
        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
          {buttonSecondaryText && (
            <MButton variant="secondary" size="large" onClick={handleClose} sx={{ width: '104px' }}>
              {buttonSecondaryText}
            </MButton>
          )}
          {buttonPrimaryText && (
            <MButton
              size="large"
              onClick={handleConfirm}
              sx={{ width: '104px' }}
              disabled={!selectedIds.length}
            >
              {buttonPrimaryText}
            </MButton>
          )}
        </Stack>
      </Stack>
    </StyledDialog>
  );
};

ManagementCommunitiesDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

ManagementCommunitiesDialog.defaultProps = {
  isOpen: false,
  title: '',
  buttonSecondaryText: '',
  buttonPrimaryText: '',
  onClose: null,
  onConfirm: null,
};

export default ManagementCommunitiesDialog;
