/* eslint-disable react/prop-types */
import { ReactComponent as AddIcon } from '../../../../../assets/icons/Add.svg';
import { ReactComponent as DottedCircleIcon } from '../../../../../assets/icons/DottedCircle.svg';
import { ReactComponent as CircleIcon } from '../../../../../assets/icons/Circle.svg';
import { TextInputCell } from '../../../../atoms/Table/TableCells/TextInputCell';
import { IconCell } from '../../../../atoms/Table/TableCells/IconCell';
import AddressOwnerEntityRefEditCell from '../AddressOwnerEntityRefEditCell/AddressOwnerEntityRefEditCell';
import getFeesAndCosts from './AddressFeesAndCosts.constants';

export const addressHeading = 'Address Properties';
export const INITIAL_CURRENT_PAGE = 1;

const iconCellConfig = [
  { icon: <DottedCircleIcon />, role: 'active' },
  { icon: <CircleIcon />, role: 'disabled' },
];

export const qBsClassItemsByStates = {
  AZ: [
    'Maricopa County',
    'Pima County',
    'Pinal County',
    'Yavapai County',
    'Mohave County',
    'Yuma County',
    'Coconino County',
    'Cochise County',
    'Navajo County',
    'Apache County',
    'Gila County',
    'Santa Cruz County',
    'Graham County',
    'La Paz County',
    'Greenlee County',
  ],
  NV: [
    'Carson City County',
    'Churchill County',
    'Clark County',
    'Douglas County',
    'Elko County',
    'Esmeralda County',
    'Eureka County',
    'Humboldt County',
    'Lander County',
    'Lincoln County',
    'Lyon County',
    'Mineral County',
    'Nye County',
    'Pershing County',
    'Storey County',
    'Washoe County',
    'White Pine County',
  ],
};

export const defaultNonPaymentNoticeTypeOptions = [
  { id: 0, value: '7 Day Non-Payment', label: '7 Day Non-Payment' },
  { id: 1, value: '30 Day Non-Payment', label: '30 Day Non-Payment' },
];

export const defaultAZNonPaymentNoticeTypeOptions = [
  {
    id: 0,
    value: '30-Day Non-Payment',
    label: '30-Day Non-Payment',
  },
  {
    id: 1,
    value: '5-30-Day Non-Payment',
    label: '5-30-Day Non-Payment',
  },
  { id: 1, value: '5-Day Non-Payment', label: '5-Day Non-Payment' },
];
const AZCourt = [
  {
    label: 'Court',
    isSubHeading: true,
  },
  {
    label: 'Justice Court Name',
    name: 'justiceCourtName',
    placeholder: 'Select court ',
    width: '444px',
    isDropdown: true,
    isMandatory: true,
  },
  {
    label: 'Distance From Justice Court',
    name: 'distanceToCourt',
    placeholder: '0',
    width: '208px',
  },
  {
    label: 'Default Non-Payment Notice',
    name: 'defaultNonPaymentNoticeType',
    placeholder: 'Select type',
    width: '325px',
    isDropdown: true,
    isDisabled: false,
    isMandatory: true,
  },
  {
    label: 'Community',
    name: 'communityName',
    placeholder: 'Select community',
    width: '393px',
    isDropdown: true,
  },
  {
    label: 'Management',
    name: 'managementName',
    placeholder: 'Select management',
    width: '393px',
    isDropdown: true,
    isMandatory: true,
  },
  {
    label: 'Corporate Address?',
    name: 'isCorporateAddress',
    width: '240px',
    radioButtonWidth: '100px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
  {
    label: 'Superior Court Name',
    name: 'superiorCourtName',
    placeholder: 'Select court ',
    width: '444px',
    isDropdown: true,
    isMandatory: false,
    isDisabled: true,
  },
];

const NVCourt = [
  {
    label: 'Court',
    isSubHeading: true,
  },
  {
    label: 'Court Name',
    name: 'courtName',
    placeholder: 'Select court ',
    width: '444px',
    isDropdown: true,
    isMandatory: true,
  },
  {
    label: 'Distance To Court',
    name: 'distanceToCourt',
    placeholder: '0',
    width: '208px',
  },
  {
    label: 'Default Non-Payment Notice',
    name: 'defaultNonPaymentNoticeType',
    placeholder: 'Select type',
    width: '325px',
    isDropdown: true,
    isDisabled: false,
    isMandatory: true,
  },
  {
    isSpace: true,
    label: 'spaceAfterDefaultNonPaymentNotice',
  },
  {
    label: 'Community',
    name: 'communityName',
    placeholder: 'Select community',
    width: '393px',
    isDropdown: true,
  },
  {
    label: 'Management',
    name: 'managementName',
    placeholder: 'Select management',
    width: '393px',
    isDropdown: true,
    isMandatory: true,
  },
  {
    label: 'Corporate Address?',
    name: 'isCorporateAddress',
    width: '240px',
    radioButtonWidth: '100px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
];

const getCourt = ({ stateCode }) => {
  if (stateCode === 'AZ') return AZCourt;
  if (stateCode === 'NV') return NVCourt;
  return [];
};

const contactInfoAZ = [
  {
    label: 'Contact Info',
    isSubHeading: true,
  },
  {
    label: 'Address',
    name: 'contactInfo.address',
    placeholder: 'Enter address',
    width: '444px',
    isMandatory: true,
  },
  {
    label: 'City',
    name: 'contactInfo.city',
    placeholder: 'Enter city',
    width: '208px',
    isMandatory: true,
  },
  {
    label: 'State',
    name: 'contactInfo.stateCode',
    width: '94px',
    placeholder: '-',
    isDropdown: true,
    isMandatory: true,
    isDisabled: true,
  },
  {
    label: 'ZIP Code',
    name: 'contactInfo.zipCode',
    width: '94px',
    placeholder: 'xx xx xx',
    isMandatory: true,
  },
  {
    label: 'Property Type',
    name: 'propertyType',
    placeholder: 'Select property type',
    width: '290px',
    isDropdown: true,
    isMandatory: true,
    isDisabled: false,
    isSearchable: false,
  },
  {
    isSpace: true,
    label: 'spaceAfterZipCode',
  },
  {
    label: 'Phone',
    name: 'contactInfo.phone',
    placeholder: '+1 (480) xx xx xx xx',
    width: '326px',
    isMandatory: true,
  },
  {
    label: 'Writ Phone',
    name: 'contactInfo.writPhone',
    placeholder: '+1 (480) xx xx xx xx',
    width: '326px',
    isMandatory: true,
  },
  {
    label: 'Fax',
    name: 'contactInfo.fax',
    placeholder: '+1 (480) xx xx xx xx',
    width: '326px',
  },
];

const contactInfoNV = [
  {
    label: 'Contact Info',
    isSubHeading: true,
  },
  {
    label: 'Address',
    name: 'contactInfo.address',
    placeholder: 'Enter address',
    width: '444px',
    isMandatory: true,
  },
  {
    label: 'City',
    name: 'contactInfo.city',
    placeholder: 'Enter city',
    width: '208px',
    isMandatory: true,
  },
  {
    label: 'State',
    name: 'contactInfo.stateCode',
    width: '94px',
    placeholder: '-',
    isDropdown: true,
    isMandatory: true,
    isDisabled: true,
  },
  {
    label: 'ZIP Code',
    name: 'contactInfo.zipCode',
    width: '94px',
    placeholder: 'xx xx xx',
    isMandatory: true,
  },
  {
    label: 'Property Type',
    name: 'propertyType',
    placeholder: 'Select property type',
    width: '290px',
    isDropdown: true,
    isMandatory: true,
    isDisabled: false,
    isSearchable: false,
  },
  {
    isSpace: true,
    label: 'spaceAfterZipCode',
  },
  {
    label: 'Phone',
    name: 'contactInfo.phone',
    placeholder: '+1 (480) xx xx xx xx',
    width: '326px',
    isMandatory: true,
  },
  {
    label: 'Writ Phone',
    name: 'contactInfo.writPhone',
    placeholder: '+1 (480) xx xx xx xx',
    width: '326px',
    isMandatory: true,
  },
  {
    label: 'Fax',
    name: 'contactInfo.fax',
    placeholder: '+1 (480) xx xx xx xx',
    width: '326px',
  },
  {
    label: 'Gate Code',
    name: 'contactInfo.hasGateCode',
    width: '208px',
    radioButtonWidth: '100px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
  {
    label: 'Gate Code',
    name: 'contactInfo.gateCode',
    placeholder: 'Enter Gate Code',
    width: '280px',
  },
  {
    label: 'Key Provided',
    name: 'contactInfo.isKeyProvided',
    width: '208px',
    radioButtonWidth: '100px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
  {
    label: 'Major Cross Street',
    name: 'contactInfo.majorCrossStreet',
    placeholder: 'Enter Major Cross Street',
    width: '280px',
  },
  {
    label: 'Locksmith Name',
    name: 'contactInfo.locksmithName',
    placeholder: 'Enter Locksmith Name',
    width: '280px',
  },
  {
    label: 'Locksmith Phone',
    name: 'contactInfo.locksmithPhone',
    placeholder: 'Enter Locksmith Phone',
    width: '280px',
  },
  {
    label: 'Do you wish to be present at the time of the eviction?',
    name: 'contactInfo.isPresentAtEviction',
    width: '400px',
    radioButtonWidth: '100px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
  {
    label: 'Will the landlord provide locks?',
    name: 'contactInfo.isProvideLock',
    width: '240px',
    radioButtonWidth: '100px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
];

const getContactInfo = ({ stateCode, hasGateCode }) => {
  if (stateCode === 'AZ') return contactInfoAZ;
  if (stateCode === 'NV') {
    return contactInfoNV.map((item) => {
      if (item.name === 'contactInfo.gateCode') {
        return { ...item, isMandatory: hasGateCode };
      }
      return item;
    });
  }
  return [];
};

export const addressBoxes = [
  {
    name: 'contactInfo',
    inputBoxes: getContactInfo,
  },
  {
    name: 'court',
    inputBoxes: getCourt,
  },
  {
    name: 'feesAndCosts',
    inputBoxes: getFeesAndCosts,
  },
  {
    name: 'ownerEntities',
    inputBoxes: [
      {
        label: 'Owner Entity',
        isSubHeading: true,
      },
      {
        label: 'Owner Entity',
        name: 'ownerEntity.name',
        castErrorFrom: 'ownerEntityRefs',
        placeholder: 'Select owner entity',
        width: '444px',
        isDropdown: true,
        isVisuallyRequired: true,
      },
      {
        label: 'Note',
        name: 'ownerEntity.note',
        placeholder: 'Enter note',
        width: '464px',
      },
      {
        label: '',
        isSubHeadingWithButton: true,
        buttonName: 'Add',
        buttonIcon: <AddIcon />,
      },
      {
        isSpace: true,
        label: 'spaceAfterOwnerEntity',
      },
      {
        label: '',
        name: 'ownerEntityRefs',
        placeholder: '',
        width: '444px',
        isTable: true,
      },
      {
        label: 'QuickBooks Class',
        name: 'quickBooksClass',
        placeholder: 'Select QuickBook Class',
        width: '444px',
        isDropdown: true,
        isMandatory: true,
      },
    ],
  },
  {
    name: 'status',
    inputBoxes: [
      {
        label: 'Address Status',
        isSubHeading: true,
      },
      {
        label: 'Status',
        name: 'status.isDeactivated',
        width: '240px',
        radioButtonWidth: '116px',
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Active', value: false, isDefault: true },
          { id: 1, label: 'Inactive', value: true },
        ],
      },
      {
        label: 'On Hold',
        name: 'status.isOnHold',
        width: '240px',
        radioButtonWidth: '116px',
        isRadioButtons: true,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false, isDefault: true },
        ],
      },
      {
        label: 'On Hold Description',
        name: 'status.onHoldDescription',
        placeholder: 'Enter on hold description',
        width: '464px',
      },
    ],
  },
];

export const ownerEntitiesColumns = [
  {
    Header: 'Name',
    accessor: 'ownerEntityName',
    width: 20,
  },
  {
    Header: 'Note',
    accessor: 'note',
    edit: true,
    Cell: TextInputCell,
    width: 20,
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    width: 20,
  },
  {
    Header: 'End date',
    accessor: 'endDate',
    width: 20,
  },
  {
    Header: 'Active',
    accessor: 'isDisabled',
    width: 14,
    Cell: ({ row }) => {
      return (
        <div style={{ marginLeft: '10px' }}>
          <IconCell config={iconCellConfig} isActive={!row?.original?.isDisabled} />
        </div>
      );
    },
  },
  {
    Header: '',
    accessor: 'control',
    width: 6,
    Cell: (props) => {
      const { row } = props;

      return <AddressOwnerEntityRefEditCell {...props} isActive={!row?.original?.isDisabled} />;
    },
  },
];

export const PROPERTY_TYPE = [
  { id: 0, value: 'Apartment', label: 'Apartment', default: true },
  { id: 1, value: 'Mobile Home Park', label: 'Mobile Home Park' },
  { id: 2, value: 'Commercial', label: 'Commercial' },
];
