/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  styled,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import { CaseStatusComparisonFormBoxes, stateCodesOptions } from './CaseStatusComparison.constants';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import CheckboxSelect from '../../../atoms/CheckboxSelect';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import { palette } from '../../../../theme/default/palette';
import { ReactComponent as Cross } from '../../../../assets/icons/Cross.svg';
import {
  createCaseStatusComparisonReport,
  getCaseStatusComparisonReport,
} from '../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import BarChart from './BarChart';
import Table from '../../../atoms/Table/Table';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import { ALL_STATUSES } from '../../../../global/constants';
import useAuth from '../../../../hooks/useAuth';
import { rolesDB } from '../../../../utils/roleHelpers';

const createRows = (tableData) => {
  const rows = [{ state: 'AZ' }, { state: 'NV' }, { state: 'Total' }];
  const [arizonaRow, nevadaRow, totalRow] = rows;

  tableData.statuses.forEach((item) => {
    const arizona = item.states.find((i) => i.stateCode === 'AZ');
    const nevada = item.states.find((i) => i.stateCode === 'NV');

    if (arizona) {
      arizonaRow[item.status] = arizona?.value;
    }

    if (nevada) {
      nevadaRow[item.status] = nevada?.value;
    }

    totalRow[item.status] = item.total;
  });

  const arizonaKeys = Object.keys(arizonaRow);
  const nevadaKeys = Object.keys(nevadaRow);

  return [
    arizonaKeys.length > 1 && arizonaRow,
    nevadaKeys.length > 1 && nevadaRow,
    totalRow,
  ].filter((row) => row);
};

const createColumns = (tableData) => {
  const statuses = tableData.statuses.map((item) => {
    return {
      Header: item.status,
      accessor: item.status,
      width: 100,
      minWidth: 150,
    };
  });

  return [
    {
      Header: 'State(s)',
      accessor: 'state',
      width: 100,
      minWidth: 150,
    },
    ...statuses,
  ];
};

const AddIcon = styled(Add)({
  '& path': {
    stroke: palette.text.secondary,
  },
});

const emptyPeriod = { fromDate: null, toDate: null };

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
    borderRadius: '24px',
  },
});

const StyledContainer = styled(Container)({
  '&': {
    marginTop: '32px',
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
    },
    '.MuiPaper-root': {
      marginBottom: '16px',
      borderRadius: '16px',
      boxShadow: '0px 3px 32px rgb(106 114 142 / 10%)',
    },
  },
});

const CaseStatusComparison = () => {
  const { userInfo } = useAuth();

  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [chartItems, setChartItems] = useState([]);
  const [accordionOpened, setAccordionOpened] = useState(false);

  const form = useForm({
    defaultValues: {
      periods: [emptyPeriod],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const periods = useFieldArray({
    control: form.control,
    name: 'periods',
  });

  const toggleAccordion = () => () => {
    setAccordionOpened((prev) => !prev);
  };

  const onAddPeriod = () => periods.append(emptyPeriod);
  const onRemovePeriod = (index) => {
    periods.remove(index);
  };

  const handleSubmit = async (values) => {
    const data = {
      periods: values.periods,
      caseStatuses: values.caseStatuses.map((item) => item.value),
      stateCodes: values.stateCodes.map((item) => item.value),
    };

    setIsLoader(true);

    try {
      const { reportId } = await dispatch(createCaseStatusComparisonReport(data)).unwrap();
      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code, message },
        } = await dispatch(getCaseStatusComparisonReport(reportId)).unwrap();
        if (code === 'Completed') {
          setChartItems(items);
          done = true;
        } else if (code === 'Error') {
          // TODO: Add error popup
          alert('--------------', message);
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      // TODO: Add error popup
      alert('--------------', e);
    }
    setIsLoader(false);
  };

  const isAdmin = userInfo.roles.includes(rolesDB.Admin);

  const getOptions = (name) => {
    switch (name) {
      case 'stateCodes':
        return isAdmin
          ? stateCodesOptions
          : stateCodesOptions.filter(({ value }) => userInfo.StateCode === value);
      case 'caseStatuses':
        return ALL_STATUSES;
      default:
        return [];
    }
  };

  return (
    <Stack direction="column" mb={10}>
      <ShadowBlock>
        {isLoader && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100vw"
            height="100vh"
            position="absolute"
            top="0"
            left="0"
            zIndex={10}
          >
            <CircularProgress />
          </Box>
        )}
        <Form form={form} onSubmit={handleSubmit}>
          {CaseStatusComparisonFormBoxes.map((i) =>
            i.name === 'periods' ? (
              periods.fields.map((period, index) => {
                return (
                  <Stack
                    direction="row"
                    alignItems="flex-end"
                    columnGap={3}
                    rowGap={2}
                    flexWrap="wrap"
                    py={1}
                    key={period.id}
                  >
                    {Object.keys(i.inputBoxes).map((key) => {
                      const item = i.inputBoxes[key];
                      const name = `periods[${index}].${item.name}`;
                      if (item.isDatePicker) {
                        return (
                          <Field
                            name={name}
                            isMandatory={item.isMandatory}
                            render={({ field, onCustomChange, error }) => (
                              <DateOrTimePicker
                                name={name}
                                isDate
                                error={error}
                                Svg={Calender}
                                label={item.label}
                                selected={field.value}
                                isMandatory={item.isMandatory}
                                width={item.width}
                                height={item.height}
                                placeholder={item.placeholder}
                                setSelectedTimeOrDate={onCustomChange(field.onChange)}
                              />
                            )}
                          />
                        );
                      }
                      return null;
                    })}
                    {index === 0 ? (
                      <MButton
                        sx={{ marginBottom: '6px' }}
                        variant="secondary"
                        startIcon={<AddIcon />}
                        onClick={onAddPeriod}
                      >
                        Add Time Period
                      </MButton>
                    ) : (
                      <MButton
                        sx={{ marginBottom: '6px' }}
                        variant="borderLess"
                        startIcon={<Cross width="16px" />}
                        color="info"
                        onClick={() => onRemovePeriod(index)}
                      >
                        Remove
                      </MButton>
                    )}
                  </Stack>
                );
              })
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                columnGap={3}
                rowGap={2}
                flexWrap="wrap"
                py={1}
              >
                {i.inputBoxes.map((item) => {
                  if (item.isCheckboxSelect) {
                    return (
                      <Field
                        key={item.name}
                        isMandatory={item.isMandatory}
                        name={item.name}
                        render={({ field, onCustomChange, error }) => (
                          <CheckboxSelect
                            label={item.label}
                            value={field.value}
                            width={item.width}
                            error={error}
                            isMandatory={item.isMandatory}
                            isDisabled={item.isDisabled}
                            placeholder={item.placeholder}
                            onChange={onCustomChange(field.onChange)}
                            options={getOptions(item.name)}
                          />
                        )}
                      />
                    );
                  }
                  return null;
                })}
              </Stack>
            ),
          )}
          <Stack direction="row" mt={2}>
            <MButton type="submit" size="medium" disabled={isLoader || !form.formState.isValid}>
              Run Report
            </MButton>
          </Stack>
        </Form>
      </ShadowBlock>

      <StyledContainer maxWidth={false} disableGutters>
        <StyledAccordion expanded={accordionOpened} onChange={toggleAccordion()}>
          <AccordionSummary
            expandIcon={<ArrowDown />}
            sx={{
              '&': {
                minHeight: 0,
                padding: '32px',
              },
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
              '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: 0,
              },
            }}
          >
            <Typography variant="h5" fontWeight={600}>
              Case Status Comparison
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {chartItems.map((table, idx) => {
              return (
                <Stack key={idx} mb={6}>
                  <Typography variant="body2" fontWeight={400} mb={2}>
                    {`${moment(table.fromDate).format('L')} – ${moment(table.toDate).format('L')}`}
                  </Typography>
                  <Table
                    columns={createColumns(table)}
                    rows={createRows(table)}
                    hasTotalRow
                    hasFixedColumns
                    showFixedColumnsAtWidth={300}
                  />
                </Stack>
              );
            })}
            <BarChart chartData={chartItems} />
          </AccordionDetails>
        </StyledAccordion>
      </StyledContainer>
    </Stack>
  );
};

export default CaseStatusComparison;
