/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Stack, CircularProgress } from '@mui/material';
import Table from '../../../atoms/Table/Table';
import MButton from '../../../MUI/Button/MButton';
import {
  getUserDetails,
  editCommunities,
  getCommunitiesList,
  addCommunityToTheTableInUserCreationMode,
} from '../../../../store/slices/clientPortalAdminUsers';
import { newUserCommunitiesColumns } from './ClientPortalAdmin.constants';
import { MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './ClientPortalAdmin.constants';
import { useLocation } from 'react-router-dom';
import EmptyBlock from '../../../molecules/EmptyBlock';
import Searcher from '../../../molecules/Searcher';
import MDialog from '../../../atoms/MDialog';

const ClientPortalCommunitiesModal = ({
  isOpen,
  title,
  buttonSecondaryText,
  buttonPrimaryText,
  onClose,
  managementId,
  inputPlaceholder,
  alreadyAdded,
  stateCode,
}) => {
  const dispatch = useDispatch();
  const {
    state: { id },
  } = useLocation();

  const { communities } = useSelector((state) => state.clientPortalAdminUsers);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  useEffect(() => {
    if (!managementId) return;

    setIsLoaderTable(true);
    dispatch(
      getCommunitiesList({
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        communityName: '',
        managementId,
        stateCode: stateCode,
      }),
    ).then(() => setIsLoaderTable(false));
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && searchValue === '') {
      setIsLoaderTable(true);
      dispatch(
        getCommunitiesList({
          currentPage: 1,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          communityName: '',
          managementId,
          stateCode: stateCode,
        }),
      ).then(() => setIsLoaderTable(false));
    }
  }, [searchValue]);

  const handleCheckedRow = useCallback((selectedRows) => {
    setCheckedRows(selectedRows.map((item) => item.communityId));
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      dispatch(
        getCommunitiesList({
          currentPage: currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          communityName: searchValue,
          managementId,
          stateCode,
        }),
      );
    },
    [searchValue, title],
  );

  const handleInputChange = (value) => {
    setSearchValue(value);
  };

  const handleSearchByInputValue = () => {
    if (!managementId) return;
    if (isLoaderTable || !searchValue) return;

    dispatch(
      getCommunitiesList({
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        communityName: searchValue,
        managementId,
        stateCode,
      }),
    );
  };

  const addItemsIfEditMode = () => {
    const rowsSet = new Set(checkedRows);

    alreadyAdded &&
      alreadyAdded.forEach((item) => {
        rowsSet.add(item.communityId);
      });

    dispatch(
      editCommunities({
        userId: id,
        communityIds: Array.from(rowsSet.values()),
      }),
    ).then(() => {
      dispatch(getUserDetails(id)).then(onClose);
    });
  };

  const addItemsIfUserCreationMode = () => {
    const alreadyAddedIds = alreadyAdded?.map((item) => item.communityId) || [];

    const rowsToAdd = communities.items.filter(
      (item) =>
        checkedRows.includes(item.communityId) && !alreadyAddedIds.includes(item.communityId),
    );
    dispatch(addCommunityToTheTableInUserCreationMode(rowsToAdd));
    onClose();
  };

  const handleConfirm = useCallback(() => {
    return id ? addItemsIfEditMode() : addItemsIfUserCreationMode();
  }, [checkedRows?.length]);

  return (
    <MDialog onClose={onClose} open={isOpen} maxWidth="md" fullWidth>
      <Stack spacing={4} direction="column" display="flex">
        <Typography variant="h4">{title}</Typography>
        <Stack display="flex" direction="row" alignItems="center" height="48px">
          <Searcher
            inputPlaceholder={inputPlaceholder}
            onSearch={handleSearchByInputValue}
            onChange={handleInputChange}
          />
        </Stack>
        {isLoaderTable ? (
          <Stack flexGrow={1} justifyContent="center" alignItems="center">
            <CircularProgress data-testid="CircularProgress" />
          </Stack>
        ) : (
          <Stack
            flexGrow={1}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ height: '40vh' }}
          >
            {managementId && communities?.items?.length ? (
              <Table
                data-testid="CommunitiesModal"
                columns={newUserCommunitiesColumns}
                rows={communities.items}
                total={communities.totalRowsCount}
                isPagination={communities.totalPages > 1}
                onNextPage={handleFetchDataPage}
                onPreviousPage={handleFetchDataPage}
                onGotoPage={handleFetchDataPage}
                pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
                loading={isLoaderTable}
                isWithCheckbox={true}
                onCheckedRow={handleCheckedRow}
              />
            ) : (
              <EmptyBlock
                title="Communities not found"
                desc="Сhange the search value"
                margin="auto"
              />
            )}
          </Stack>
        )}
        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
          {buttonSecondaryText && (
            <MButton
              data-testid="secondaryBtn"
              variant="secondary"
              size="large"
              onClick={onClose}
              sx={{ width: '104px' }}
            >
              {buttonSecondaryText}
            </MButton>
          )}
          {buttonPrimaryText && (
            <MButton
              data-testid="primaryBtn"
              size="large"
              onClick={handleConfirm}
              sx={{ width: '104px' }}
              disabled={false}
            >
              {buttonPrimaryText}
            </MButton>
          )}
        </Stack>
      </Stack>
    </MDialog>
  );
};

ClientPortalCommunitiesModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  managementId: PropTypes.string,
  onClose: PropTypes.func,
  alreadyAdded: PropTypes.instanceOf(Array),
  inputPlaceholder: PropTypes.string.isRequired,
  stateCode: PropTypes.string,
};

ClientPortalCommunitiesModal.defaultProps = {
  isOpen: false,
  title: '',
  buttonSecondaryText: '',
  buttonPrimaryText: '',
  managementId: null,
  onClose: null,
  stateCode: '',
};

export default ClientPortalCommunitiesModal;
