import React from 'react';
import PropTypes from 'prop-types';

import './Calendar.sass';

export const BaseRightButtons = ({ calendarRef }) => {
  const calendarApi = calendarRef?.getApi();
  const type = calendarApi?.currentData?.currentViewType;

  const changeView = (view) => {
    calendarApi.changeView(view);
  };
  return (
    <>
      <button
        type="button"
        title="Day view"
        aria-pressed="true"
        className={
          type === 'timeGridDay'
            ? 'fc-timeGridDay-button fc-button fc-button-primary fc-button-active'
            : 'fc-timeGridDay-button fc-button fc-button-primary'
        }
        id="day_button"
        onClick={() => changeView('timeGridDay')}
        data-testid="day_button"
      >
        Day
      </button>

      <button
        type="button"
        title="Day view"
        aria-pressed="true"
        className={
          type === 'timeGridWeek'
            ? 'fc-timeGridWeek-button fc-button fc-button-primary fc-button-active'
            : 'fc-timeGridWeek-button fc-button fc-button-primary'
        }
        id="week_button"
        onClick={() => changeView('timeGridWeek')}
        data-testid="week_button"
      >
        Week
      </button>

      <button
        type="button"
        title="Day view"
        aria-pressed="true"
        className={
          type === 'dayGridMonth'
            ? 'fc-dayGridMonth-button fc-button fc-button-primary fc-button-active'
            : 'fc-dayGridMonth-button fc-button fc-button-primary'
        }
        id="month_button"
        onClick={() => changeView('dayGridMonth')}
        data-testid="month_button"
      >
        Month
      </button>
    </>
  );
};

BaseRightButtons.propTypes = {
  calendarRef: PropTypes.shape({
    getApi: PropTypes.shape({}),
  }).isRequired,
};
