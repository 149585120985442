import { useEffect, useState } from 'react';
import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';

import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import notificationUtils from '../../../../utils/notificationUtils';
import Form from '../../../atoms/Form';
import LoaderCover from '../../../atoms/LoaderCover';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import EmptyImage from '../../../../assets/emptyImage.png';
import { ReactComponent as Upload } from '../../../../assets/icons/document-upload-white.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/EditText.svg';
import { ReactComponent as Accept } from '../../../../assets/icons/AcceptCurrColor.svg';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import UploadImageDialog from './UploadImageDialog';
import { getLoginPage, updateLoginPage } from '../../../../store/slices/adminLoginSlice';
import { convertToBinaryFile, convertToBinaryFileUrl } from '../../../../utils/binaryHelpers';
import Field from '../../../atoms/Field';
import NotificationDialog from '../../../molecules/NotificationDialog';

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: '36px 32px',
  border: `1px solid ${theme.palette.shadow.boxContrast}`,
  borderRadius: '16px',
  boxShadow: `0 3px 20px ${theme.palette.shadow.boxContrast}`,
}));

const LoginEdit = () => {
  useSafeLocationState('Admin Panel', 'Log-in Page');
  const [isLoader, setIsLoader] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const { loginPage } = useSelector((state) => state.adminLogin);
  const [edit, setEdit] = useState();

  const theme = useTheme();
  const dispatch = useDispatch();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      imageSrc: null,
      file: null,
      description: '',
      note: '',
      linkName: '',
      linkUrl: '',
      fileExtension: '',
    },
  });

  const values = form.getValues();

  useEffect(() => {
    dispatch(getLoginPage());
  }, []);

  useEffect(() => {
    if (loginPage.image?.content && loginPage.image?.contentType) {
      const url = convertToBinaryFileUrl({
        contentType: loginPage.image.contentType,
        content: loginPage.image.content,
        fileName: loginPage.image.fileName || 'image',
      });
      form.setValue('imageSrc', url);
      form.setValue(
        'file',
        convertToBinaryFile({
          content: loginPage.image.content,
          contentType: loginPage.image.contentType,
          fileName: loginPage.image.fileName || 'image',
        }),
      );
      form.setValue('fileExtension', loginPage.image.contentType);
    }
    if (loginPage.description) {
      form.setValue('description', loginPage.description);
    }
    if (loginPage.note) {
      form.setValue('note', loginPage.note);
    }
    if (loginPage.linkName && loginPage.linkUrl) {
      form.setValue('linkName', loginPage.linkName);
      form.setValue('linkUrl', loginPage.linkUrl);
    }
  }, [loginPage]);

  const handleSubmit = async (valuesToSumbit) => {
    setIsLoader(true);
    const { imageSrc, ...rest } = valuesToSumbit;

    try {
      await dispatch(updateLoginPage(rest)).unwrap();
      notificationUtils.success('Uploaded successfully');
    } catch (e) {
      notificationUtils.error('Failed, try again later');
    }
    setIsLoader(false);
  };

  const handleOpenUpload = () => {
    setUploadOpen(true);
  };

  const handleCloseUpload = (isSuccess, img, file) => {
    setUploadOpen(false);

    if (isSuccess) {
      form.setValue('imageSrc', img.src);
      form.setValue('file', file);
      form.setValue('fileExtension', file.type);
    }
  };

  const handleOpenDeleteConfirmation = () => {
    setConfirmation(true);
  };

  const handleClose = () => {
    setConfirmation(false);
  };

  const handleClickDelete = async () => {
    const { imageSrc, ...rest } = values;
    try {
      await dispatch(updateLoginPage({ ...rest, file: null, fileExtension: null })).unwrap();
      notificationUtils.success('Deleted successfully');
      form.setValue('file', null);
      form.setValue('fileExtension', null);
      form.setValue('imageSrc', null);
      handleClose();
    } catch (e) {
      notificationUtils.error('Failed, try again later');
    }
  };

  const handleEditTextOpen = (field) => {
    setEdit(field);
  };

  const handleEditTextClose = () => {
    setEdit(undefined);
  };

  const handleChange = (field, value) => {
    form.setValue(field, value);
  };

  return (
    <Box px={4}>
      <UploadImageDialog isOpen={uploadOpen} onClose={handleCloseUpload} />
      <NotificationDialog
        title="Confirm Delete"
        desc="Are you sure you want to delete current image?"
        type="alert"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Delete"
        onClose={handleClose}
        onConfirm={handleClickDelete}
        isOpen={openConfirmation}
      />
      <Typography variant="h4">Log-in Page</Typography>
      {isLoader && <LoaderCover isFixed />}
      <Form form={form} onSubmit={handleSubmit}>
        <Box mb={20}>
          <Wrapper mt={2}>
            <Typography variant="h5">Image</Typography>
            <Stack direction="row">
              <Box
                sx={{
                  border: '1px dashed',
                  borderColor: form.watch('imageSrc')
                    ? theme.palette.buttonSecondary.hoverBorder
                    : 'transparent',
                  marginRight: '32px',
                  marginTop: '32px',
                  height: '250px',
                  minWidth: '200px',
                }}
              >
                <img
                  src={form.watch('imageSrc') || EmptyImage}
                  alt="image_login"
                  style={{
                    height: '250px',
                    width: 'auto',
                  }}
                  data-testid="logo"
                />
              </Box>
              <Box alignSelf="flex-end" flex>
                <Stack direction="row">
                  <MButton
                    startIcon={<Upload width="20px" height="20px" />}
                    onClick={handleOpenUpload}
                    data-testid="upload_button"
                    sx={{ marginRight: 1 }}
                  >
                    Upload New Image
                  </MButton>
                  <MButton
                    variant="secondary"
                    onClick={handleOpenDeleteConfirmation}
                    data-testid="delete_button"
                  >
                    Delete Image
                  </MButton>
                </Stack>
              </Box>
            </Stack>
          </Wrapper>
          <Wrapper mt={2} pb={edit === 'description' ? 3 : 0}>
            <Typography variant="h5">Description</Typography>
            <Stack direction="row" mt={2} sx={{ maxWidth: '1018px' }}>
              <>
                <ReactQuill
                  style={{ width: '100%', marginBottom: '30px' }}
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      [
                        { align: '' },
                        { align: 'center' },
                        { align: 'right' },
                        { align: 'justify' },
                      ],
                    ],
                  }}
                  formats={['bold', 'italic', 'underline', 'align']}
                  onChange={(value) => handleChange('description', value)}
                  value={values.description}
                  className={edit === 'description' ? 'visibleToolbar' : 'hiddenToolbar'}
                />
                {edit === 'description' ? (
                  <MButton
                    onClick={handleEditTextClose}
                    data-testid="edit_btn"
                    variant="primary"
                    sx={{
                      marginLeft: 1,
                      width: '48px',
                      height: '48px',
                      minWidth: '48px',
                    }}
                  >
                    <Accept color="white" />
                  </MButton>
                ) : (
                  <MButton
                    onClick={() => handleEditTextOpen('description')}
                    data-testid="edit_btn"
                    variant="primary"
                    sx={{
                      marginLeft: 1,
                      width: '48px',
                      height: '48px',
                      minWidth: '48px',
                    }}
                  >
                    <Edit />
                  </MButton>
                )}
              </>
            </Stack>
          </Wrapper>
          <Wrapper mt={2}>
            <Typography variant="h5">Note</Typography>
            <Stack direction="row" mt={2} sx={{ maxWidth: '1018px' }}>
              <>
                <ReactQuill
                  style={{ width: '100%', marginBottom: '30px', minHeight: '44px' }}
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      [
                        { align: '' },
                        { align: 'center' },
                        { align: 'right' },
                        { align: 'justify' },
                      ],
                    ],
                  }}
                  formats={['bold', 'italic', 'underline', 'align']}
                  onChange={(value) => handleChange('note', value)}
                  value={values.note}
                  className={edit === 'note' ? 'visibleToolbar note' : 'hiddenToolbar note'}
                />
                {edit === 'note' ? (
                  <MButton
                    onClick={handleEditTextClose}
                    data-testid="edit_btn"
                    variant="primary"
                    sx={{
                      marginLeft: 1,
                      width: '48px',
                      height: '48px',
                      minWidth: '48px',
                    }}
                  >
                    <Accept color="white" />
                  </MButton>
                ) : (
                  <MButton
                    onClick={() => handleEditTextOpen('note')}
                    data-testid="edit_btn"
                    variant="primary"
                    sx={{
                      marginLeft: 1,
                      width: '48px',
                      height: '48px',
                      minWidth: '48px',
                    }}
                  >
                    <Edit />
                  </MButton>
                )}
              </>
            </Stack>
          </Wrapper>
          <Wrapper mt={2}>
            <Typography variant="h5">Links</Typography>
            <Stack direction="row" mt={2} sx={{ maxWidth: '962px' }}>
              <Box width="70%" mr={2}>
                <Field
                  name="linkName"
                  isMandatory={!!form.watch('linkUrl')}
                  render={({ field, error }) => (
                    <EditInputWithLabel
                      name="linkName"
                      error={!!form.watch('linkUrl') && error}
                      onChange={field.onChange}
                      label="Link Name"
                      placeholder="Enter link name"
                      width="100%"
                      value={field.value}
                      isMandatory={!!values.linkUrl}
                    />
                  )}
                />
              </Box>
              <Field
                name="linkUrl"
                isMandatory={!!form.watch('linkName')}
                render={({ field, error }) => (
                  <EditInputWithLabel
                    name="linkUrl"
                    onChange={field.onChange}
                    error={!!form.watch('linkName') && error}
                    label="URL"
                    placeholder="Enter URL"
                    width="100%"
                    value={field.value}
                    isMandatory={!!form.watch('linkName')}
                  />
                )}
              />
            </Stack>
          </Wrapper>
        </Box>
        <Box
          position="absolute"
          left="50%"
          bottom={0}
          sx={{ transform: 'translateX(-50%)' }}
          zIndex={10}
          width={1}
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton type="submit" size="large" sx={{ width: '144px' }} disabled={isLoader}>
              Update
            </MButton>
          </Stack>
        </Box>
      </Form>
    </Box>
  );
};

export default LoginEdit;
