import React, { forwardRef, useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import TextInput from '../../atoms/TextInput';
import { ReactComponent as Asterisk } from '../../../assets/icons/asterisk.svg';
import { palette } from '../../../theme/default';
import { amendZeros } from '../../../utils/parseNumberFromString';

const EditInputWithLabel = forwardRef((props, ref) => {
  const {
    type,
    label,
    name,
    value = '',
    placeholder,
    width,
    onChange,
    onBlur,
    children,
    isMandatory,
    isDisabled,
    error,
    min,
    suffix,
    tooltip,
    readOnly,
  } = props;

  const [isFocused, setFocused] = useState(false);

  const handleChange = useCallback(
    (e) => {
      let inputValue = e.target.value || '';
      if (type === 'int') {
        const parsed = /\d+/.exec(inputValue);
        inputValue = parsed ? parsed[0] : '';
      }
      if (type === 'float') {
        const parsed = /\d+\.?\d{0,2}/.exec(inputValue);
        inputValue = parsed ? parsed[0] : '';
      }
      onChange({
        ...e,
        target: {
          ...e.target,
          value: inputValue,
        },
      });
    },
    [onChange, type],
  );

  const getValue = () => {
    if (!isFocused) {
      if (type === 'float') {
        return amendZeros(value);
      }
    }
    return value;
  };

  const handleFocus = () => {
    setFocused(true);
    if (type === 'float') {
      const amended = getValue();
      if (amended !== value) {
        onChange({ target: { value: amendZeros(value) } });
      }
    }
  };

  const handleBlur = () => {
    setFocused(false);
    if (type === 'float') {
      onChange({ target: { value: amendZeros(value) } });
    }
    onBlur?.();
  };

  return (
    <LabelledInput width={width}>
      <Typography
        variant="buttonLarge"
        color="text.secondary"
        marginBottom={0.5}
        component="label"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        data-testid="label_wrapper"
      >
        {label}
        {isMandatory && (
          <StyledSpan style={{ alignSelf: 'flex-start' }}>
            <Asterisk data-testid="mandatory_icon" />
          </StyledSpan>
        )}
        {suffix}
        {tooltip}
      </Typography>
      {!children && (
        <Box width={width} position="relative">
          <TextInput
            isDisabled={isDisabled}
            ref={ref}
            name={name}
            value={getValue()}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            width={width}
            onChange={handleChange}
            isRequired={isMandatory}
            error={error}
            min={min}
            readOnly={readOnly}
          />
          {error?.message && (
            <Typography
              variant="bodyXS"
              color="additional.alert"
              component="span"
              position="absolute"
              left={0}
              top="calc(100% + 4px)"
              data-testid="error_message"
            >
              {error?.message}
            </Typography>
          )}
        </Box>
      )}
      {children}
    </LabelledInput>
  );
});

const LabelledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};

  label {
    width: 100%;
    margin-bottom: 4px;
  }
`;

const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

EditInputWithLabel.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  isMandatory: PropTypes.bool,
  isDisabled: PropTypes.bool,
  min: PropTypes.number,
  error: PropTypes.shape({
    message: PropTypes.string,
    ref: PropTypes.shape({
      name: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
  suffix: PropTypes.node,
  tooltip: PropTypes.node,
  readOnly: PropTypes.bool,
};

EditInputWithLabel.defaultProps = {
  type: 'text',
  label: '',
  name: '',
  value: '',
  placeholder: '',
  width: '',
  min: 0,
  onChange: null,
  onBlur: null,
  children: null,
  isMandatory: false,
  isDisabled: false,
  error: {},
  suffix: null,
  tooltip: null,
  readOnly: false,
};

export default EditInputWithLabel;
