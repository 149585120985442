import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useManualPagination from '../../../../hooks/useManualPagination';
import {
  addUsersToNotificationRule,
  deleteUsersFromNotificationRule,
  getUsersByRuleId,
} from '../../../../store/slices/adminClientRequestNotificationsSlice';
import { MAX_AMOUNT_OF_ITEMS_IN_MODAL } from './ClientRequestNotifications.constants';

const useRuleDialog = ({ currentRule, isOpen, onClose, closeUsersDialog }) => {
  const isEdit = Boolean(currentRule);
  const dispatch = useDispatch();

  const { types, states, usersByRuleId } = useSelector(
    (state) => state.adminClientRequestNotifications,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRequestType, setSelectedRequestType] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);
  const [users, setUsers] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState(null);

  const canSave = selectedRequestType && selectedStates?.length && (isEdit ? true : users?.length);

  const fetchRuleUsers = (currentPage = 1) => {
    setTableLoading(true);

    const params = {
      pageSize: MAX_AMOUNT_OF_ITEMS_IN_MODAL,
      currentPage,
      notificationRuleId: currentRule.notificationRuleId,
    };

    return dispatch(getUsersByRuleId(params)).then(() => {
      setTableLoading(false);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (isEdit && isOpen) {
      const { stateCodes, type } = currentRule;
      setSelectedRequestType({
        label: type,
        value: type,
      });
      setSelectedStates(stateCodes.map((code) => ({ label: code, value: code })));
      setIsLoading(true);
      fetchRuleUsers();
    }
  }, [currentRule, isOpen]);

  const usersByRuleIdItems = (usersByRuleId?.items ?? []).map((user) => ({
    ...user,
    ...(usersByRuleId?.totalRowsCount === 1 && { control: { hideDelete: true } }),
  }));

  const resetFields = () => {
    setSelectedRequestType(null);
    setSelectedStates([]);
    setUsers([]);
  };

  const handleClose = () => {
    onClose(null);
    resetFields();
  };

  const handleSubmit = () => {
    if (isEdit) {
      onClose({
        action: 'update',
        notificationRuleId: currentRule.notificationRuleId,
        type: selectedRequestType?.value,
        stateCodes: selectedStates?.map((x) => x.value),
        hasSkipUniquenessCheck: false,
      });
    } else {
      onClose({
        action: 'create',
        type: selectedRequestType?.value,
        stateCodes: selectedStates?.map((x) => x.value),
        userIds: users.map((u) => u.userId),
        hasSkipUniquenessCheck: false,
      });
    }
    resetFields();
  };

  const usersPagination = useManualPagination({
    data: users,
    pageSize: MAX_AMOUNT_OF_ITEMS_IN_MODAL,
  });

  const handleCloseUsersDialog = async (selectedUsers) => {
    closeUsersDialog();
    if (selectedUsers) {
      if (isEdit) {
        setIsLoading(true);
        await dispatch(
          addUsersToNotificationRule({
            notificationRuleId: currentRule.notificationRuleId,
            userIds: selectedUsers.map((user) => user.userId),
          }),
        ).unwrap();
        fetchRuleUsers();
      } else {
        const usersIds = users.reduce((acc, user) => {
          acc[user.userId] = true;
          return acc;
        }, {});
        setUsers(
          selectedUsers.reduce(
            (acc, user) => {
              if (!usersIds[user.userId]) acc.push(user);
              return acc;
            },
            [...users],
          ),
        );
      }
    }
  };

  const handleDeleteUserRow = (rowId, { userId }) => {
    if (isEdit) {
      setDeleteUserId(userId);
    } else {
      setUsers((prevUsers) => prevUsers.filter((user) => user.userId !== userId));
    }
  };

  const deleteUser = async (userId) => {
    setIsLoading(true);
    setDeleteUserId(null);
    await dispatch(
      deleteUsersFromNotificationRule({
        notificationRuleId: currentRule.notificationRuleId,
        userId,
      }),
    );
    fetchRuleUsers();
  };

  const onConfirmDelete = () => deleteUser(deleteUserId);
  const closeDeleteUserDialog = () => setDeleteUserId(null);

  return {
    isEdit,
    handleClose,
    canSave,
    handleSubmit,
    types,
    selectedRequestType,
    setSelectedRequestType,
    selectedStates,
    setSelectedStates,
    states,
    isLoading,
    usersPagination: isEdit
      ? {
          ...usersByRuleId,
          rows: usersByRuleIdItems,
          isPagination: usersByRuleId?.totalPages > 1,
          setCurrentPage: fetchRuleUsers,
        }
      : usersPagination,
    tableLoading,
    handleDeleteUserRow,
    handleCloseUsersDialog,
    isDeleteDialogOpen: !!deleteUserId,
    onConfirmDelete,
    closeDeleteUserDialog,
  };
};

export default useRuleDialog;
