import { Box, CircularProgress, Stack } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Table from '../../../atoms/Table/Table';

import {
  DEADLINES_DUE_DATES,
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
  listConfig,
} from './DashboardAttorney.constants';
import { apiClient } from '../../../../lib/apiClient';
import useAuth from '../../../../hooks/useAuth';
import { rolesDB } from '../../../../utils/roleHelpers';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import notificationUtils from '../../../../utils/notificationUtils';

export const DeadlinesDueDates = ({
  stateCode,
  handleFetchDataPage,
  sortField,
  sortOrder,
  handleSortTable,
  selectedIds,
  setSelectedIds,
  isLoader,
  period,
}) => {
  const { columns } = listConfig[DEADLINES_DUE_DATES];
  const { lists } = useSelector((state) => state.attorneyDashboard);
  const [isShowAdditionalButton, setIsShowAdditionalButton] = useState(false);
  const { deadlineDueDates } = lists;
  const { userInfo } = useAuth();
  const isCurrentUserAttorney = userInfo.roles.includes(rolesDB.Attorney);

  const isAdmin = userInfo.roles.some((item) => item === rolesDB.Admin);

  const handleCheckedRow = useCallback(
    (selectedRows) => {
      setIsShowAdditionalButton(selectedRows.length > 0);
      setSelectedIds(selectedRows.map((item) => item.caseId));
    },
    [isShowAdditionalButton],
  );

  const rowsAdapter = useMemo(
    () =>
      deadlineDueDates?.items?.map((item) => ({
        ...item,
        isCheckboxDisabled:
          !isAdmin && isCurrentUserAttorney && item.attorney?.id === userInfo.UserGuid,
        //   ||
        //   !item.canBulkComplete,
      })),
    [deadlineDueDates?.items],
  );

  const columnList = useMemo(() => {
    return typeof columns === 'function' ? columns(stateCode) : columns;
  }, [stateCode]);

  const bulkComplete = async () => {
    const url = `/api/dashboards/attorney/cards/timelines/complete`;
    try {
      await apiClient.post(url, { caseIds: selectedIds, stateCode });
      notificationUtils.success('Completed successfully');
      handleFetchDataPage(1, period);
    } catch {
      notificationUtils.error('Failed, try again later');
    }
  };

  return (
    <Stack position="relative">
      {isLoader && (
        <Box position="absolute" top="50%" left="50%" zIndex={10} transform="translate(-50%, -50%)">
          <CircularProgress />
        </Box>
      )}
      <Table
        columns={columnList}
        rows={rowsAdapter}
        total={deadlineDueDates?.totalRowsCount}
        isPagination={deadlineDueDates?.totalPages > 1}
        onNextPage={handleFetchDataPage}
        onPreviousPage={handleFetchDataPage}
        onGotoPage={handleFetchDataPage}
        pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
        isWithCheckbox
        onCheckedRow={handleCheckedRow}
        onSortTable={handleSortTable}
        sortField={sortField}
        sortOrder={sortOrder}
        hasFixedColumns
        showFixedColumnsAtWidth={1600}
        onRefreshPage={handleFetchDataPage}
      />
      {!!selectedIds.length && (
        <Box
          position="fixed"
          left="50%"
          bottom={0}
          sx={{ transform: 'translateX(-50%)', background: 'white' }}
          zIndex={10}
          width={1}
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton
              type="submit"
              size="large"
              name="Submit community"
              sx={{ width: '124px' }}
              onClick={bulkComplete}
              data-testid="bulk_complete_btn"
            >
              Complete
            </MButton>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

DeadlinesDueDates.propTypes = {
  stateCode: PropTypes.string.isRequired,
  attorney: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  handleFetchDataPage: PropTypes.func.isRequired,
  sortField: PropTypes.func.isRequired,
  sortOrder: PropTypes.func.isRequired,
  handleSortTable: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  isLoader: PropTypes.bool,
  period: PropTypes.string.isRequired,
};

DeadlinesDueDates.defaultProps = { isLoader: false };
