/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

const apiByState = {
  AZ: 'arizonaCase',
  NV: 'nevadaCases',
};

export const getListOfAllRequestsForChart = createAsyncThunk(
  'clientPortalDashboard/getListOfAllRequestsForChart',
  async (params) => {
    const { stateCode, timePeriod: period } = params;

    const { data } = await apiClient.get(
      `/cp/api/dashboards/charts/requests?${new URLSearchParams({
        Period: period.toUpperCase(),
        stateCode,
      }).toString()}`,
    );
    return data;
  },
);

export const getDashboardStats = createAsyncThunk(
  'clientPortalDashboard/getDashboardStats',
  async (stateCode) => {
    const cards = ['goingToCourts', 'requireFollowUps', 'pendingApprovals'];
    if (stateCode === 'NV') cards.push('availableLockouts');
    if (stateCode === 'AZ') cards.push('availableWrits');
    const data = await Promise.all(
      cards.map((card) =>
        apiClient
          .post(`/cp/api/dashboards/cards/${card}/filter`, {
            onlyTotal: true,
            stateCode,
            ...(card === 'goingToCourts' && { isForToday: true }),
          })
          .then((res) => res?.data?.result?.totalRowsCount),
      ),
    );
    return cards.reduce((acc, card, index) => {
      acc[card] = data[index] || 0;
      return acc;
    }, {});
  },
);

export const getClientPortalDashboardList = createAsyncThunk(
  'clientPortalDashboard/getListOfGoingToCourt',
  async ({ api, ...params }) => {
    const {
      data: { result },
    } = await apiClient.post(`/cp/api/dashboards/cards/${api}/filter`, params);
    return { api, result };
  },
);

export const requestLockoutForNevadaCase = createAsyncThunk(
  'clientPortalDashboard/requestLockoutForNevadaCase',
  async (id) => {
    await apiClient.put(`/cp/api/nevadaCases/${id}/lockout`);
  },
);

export const approveCaseDocument = createAsyncThunk(
  'clientPortalDashboard/approveCaseDocument',
  async ({ stateCode, caseId, documentId }) => {
    await apiClient.put(
      `/cp/api/${apiByState[stateCode]}/${caseId}/documents/${documentId}/approve`,
    );
  },
);

export const rejectCaseDocument = createAsyncThunk(
  'clientPortalDashboard/rejectCaseDocument',
  async (params) => {
    const { stateCode, caseId, documentId } = params;
    await apiClient.put(
      `/cp/api/${apiByState[stateCode]}/${caseId}/documents/${documentId}/reject`,
      params,
    );
  },
);

export const setRequestWritArizona = createAsyncThunk(
  'clientPortalDashboard/orderWrit',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(`cp/api/arizonaCase/${id}/writ`);
      return data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.errorMessage);
    }
  },
);

const initialState = {
  chart: { forLabels: [], forDatasets: {} },
  stats: {
    goingToCourts: 0,
    requireFollowUps: 0,
    pendingApprovals: 0,
    availableLockouts: 0,
  },
  lists: {
    goingToCourts: {},
    requireFollowUps: {},
    pendingApprovals: {},
    availableLockouts: {},
  },
};

export const clientPortalDashboardSlice = createSlice({
  name: 'clientPortalDashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getListOfAllRequestsForChart.fulfilled]: (state, action) => {
      state.chart = action.payload.result.reduce(
        (acc, period) => {
          acc.forLabels.push({ fromDate: period.fromDate, toDate: period.toDate });
          period.statuses.forEach(({ status, count }) => {
            if (!acc.forDatasets[status]) acc.forDatasets[status] = [];
            acc.forDatasets[status].push(count);
          });

          return acc;
        },
        {
          forLabels: [],
          forDatasets: {},
        },
      );
    },
    [getDashboardStats.fulfilled]: (state, action) => {
      state.stats = {
        ...state.stats,
        ...action.payload,
      };
    },
    [getClientPortalDashboardList.fulfilled]: (state, action) => {
      state.lists[action.payload.api] = action.payload.result;
      state.stats[action.payload.api] = action.payload.result.totalRowsCount;
    },
  },
});

export default clientPortalDashboardSlice.reducer;
