import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography, Box } from '@mui/material';
import AddButton from '../../../atoms/AddButton/AddButton';
import Table, { TableLoader } from '../../../atoms/Table/Table';
import {
  getAvailableStates,
  getAllRequestTypes,
  getClientRequestNotifications,
  getUsersForNotificationRulesDropdown,
  createNotificationRule,
  deleteNotificationRule,
  updateNotificationRule,
} from '../../../../store/slices/adminClientRequestNotificationsSlice';
import MButton from '../../../MUI/Button/MButton';
import EmptyBlock from '../../../molecules/EmptyBlock';
import Dropdown from '../../../atoms/Dropdown';
import CheckboxSelect from '../../../atoms/CheckboxSelect';
import {
  ClientRequestNotificationsTableColumns,
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
} from './ClientRequestNotifications.constants';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import RuleDialog from './RuleDialog';
import NotificationDialog from '../../../molecules/NotificationDialog';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';

const ClientRequestNotifications = () => {
  useSafeLocationState('Admin', 'Client Request Notifications');
  const dispatch = useDispatch();

  const { requestNotifications, usersForDropdown, types, states } = useSelector(
    (state) => state.adminClientRequestNotifications,
  );
  const [selectedRequestType, setSelectedRequestType] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [isRuleDialogOpen, setIsRuleDialogOpen] = useState(false);
  const [isUsersDialogOpen, setIsUsersDialogOpen] = useState(false);
  const [deleteRuleId, setDeleteRuleId] = useState(null);
  const [duplicateRuleData, setDuplicateRuleData] = useState({
    isOpen: false,
    data: null,
    message: '',
  });
  const [currentRule, setCurrentRule] = useState(null);

  const fetchClientRequestNotifications = useCallback(
    (currentPage = 1, extendWithFilters = true) => {
      setTableLoading(true);

      const params = {
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        currentPage,
      };

      if (extendWithFilters) {
        if (selectedRequestType) params.type = selectedRequestType?.value;
        if (selectedStates.length) params.stateCodes = selectedStates.map((state) => state.value);
        if (selectedUsers.length) params.userIds = selectedUsers.map((user) => user.value);
      }

      return dispatch(getClientRequestNotifications(params)).then(() => {
        setTableLoading(false);
        setDataLoading(false);
      });
    },
    [selectedRequestType, selectedStates.length, selectedUsers.length],
  );

  useEffect(() => {
    fetchClientRequestNotifications();
    dispatch(getAllRequestTypes());
    dispatch(getAvailableStates());
  }, []);

  const handleApply = () => {
    fetchClientRequestNotifications();
  };

  const handleReset = () => {
    setSelectedRequestType(null);
    setSelectedStates([]);
    setSelectedUsers([]);
    setDataLoading(true);
    fetchClientRequestNotifications(1, false);
  };

  const openDuplicateRuleDialog = (
    data,
    message = 'A rule for the selected state(s) and request type already exists. Are you sure?',
  ) =>
    setDuplicateRuleData({
      isOpen: true,
      data: { ...data, hasSkipUniquenessCheck: true },
      message,
    });
  const closeDuplicateRuleDialog = () =>
    setDuplicateRuleData({ isOpen: false, data: null, message: '' });

  const openRuleDialog = () => {
    setIsRuleDialogOpen(true);
  };
  const closeRuleDialog = async (ruleData = null) => {
    setIsRuleDialogOpen(false);
    closeDuplicateRuleDialog();
    if (ruleData) {
      setDataLoading(true);
      try {
        const actions = {
          create: createNotificationRule,
          update: updateNotificationRule,
        };
        const { action, ...body } = ruleData;
        await dispatch(actions[action](body)).unwrap();
      } catch (e) {
        const { errorCode, errorMessage } = e.response?.data ?? {};
        if (errorCode === 'notificationRule.not.unique') {
          openDuplicateRuleDialog(ruleData, errorMessage);
        }
      }
      fetchClientRequestNotifications();
      setCurrentRule(null);
    }
  };

  const openUsersDialog = () => {
    setIsRuleDialogOpen(false);
    setIsUsersDialogOpen(true);
  };

  const closeUsersDialog = () => {
    setIsUsersDialogOpen(false);
    setIsRuleDialogOpen(true);
  };

  const openDeleteRuleDialog = (rowId, { notificationRuleId }) =>
    setDeleteRuleId(notificationRuleId);
  const closeDeleteRuleDialog = () => setDeleteRuleId(null);

  useEffect(() => {
    if (!isRuleDialogOpen && !isUsersDialogOpen) {
      setCurrentRule(null);
    }
  }, [isRuleDialogOpen, isUsersDialogOpen]);

  const handleClickTitleRowTable = useCallback(
    (data) => () => {
      openRuleDialog();
      setCurrentRule(data);
    },
    [],
  );

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: usersForDropdown?.hasNextPage,
    getAction: ({ page }) =>
      getUsersForNotificationRulesDropdown({
        currentPage: page,
      }),
  });

  const handleRemoveNotificationRule = async (notificationRuleId) => {
    setDataLoading(true);
    setDeleteRuleId(null);
    await dispatch(deleteNotificationRule(notificationRuleId));
    fetchClientRequestNotifications();
  };

  const rowsAdapter = requestNotifications?.items?.map((item) => ({
    ...item,
    type: (
      <Typography
        component="a"
        variant="bodyM"
        color="text.primary"
        sx={{
          cursor: 'pointer',
          textDecorationLine: 'underline',
        }}
        data-testid="open_rule_dialog"
        onClick={handleClickTitleRowTable(item)}
      >
        {item.type}
      </Typography>
    ),
  }));

  return (
    <>
      <RuleDialog
        isOpen={isRuleDialogOpen}
        onClose={closeRuleDialog}
        currentRule={currentRule}
        openUsersDialog={openUsersDialog}
        closeUsersDialog={closeUsersDialog}
        isUsersDialogOpen={isUsersDialogOpen}
      />
      <NotificationDialog
        desc="Are you sure you want to delete selected rule?"
        type="alert"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Delete"
        onClose={closeDeleteRuleDialog}
        onConfirm={() => handleRemoveNotificationRule(deleteRuleId)}
        isOpen={!!deleteRuleId}
      />
      <NotificationDialog
        desc={duplicateRuleData.message}
        type="alert"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Save"
        onClose={closeDuplicateRuleDialog}
        onConfirm={() => {
          closeRuleDialog(duplicateRuleData.data);
        }}
        isOpen={duplicateRuleData.isOpen}
      />
      <Box marginX="auto" px={4} data-testid="client_request_notifications_wrapper">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">Client Request Notifications</Typography>
          <AddButton label="Create Rule" width="auto" onClick={openRuleDialog} />
        </Stack>
        <Stack direction="row" spacing={2} mb={4} mt={4} alignItems="center" flexWrap="wrap">
          <Dropdown
            isColumn
            label="Request Type"
            placeholder="Select request type"
            width="330px"
            options={types}
            value={selectedRequestType}
            onChange={setSelectedRequestType}
            onEnter={handleApply}
          />
          <CheckboxSelect
            label="State(s)"
            value={selectedStates}
            width="330px"
            placeholder="Select state(s)"
            onChange={setSelectedStates}
            options={states}
            onEnter={handleApply}
          />
          <CheckboxSelect
            label="Users(s)"
            value={selectedUsers}
            width="330px"
            placeholder="Select users(s)"
            onChange={setSelectedUsers}
            options={usersForDropdown?.items}
            onMenuScrollToBottom={handleMenuScrollToBottom}
            onInputChange={changeInput}
            onEnter={handleApply}
          />
          <Stack gap={1} display="flex" flexDirection="row" pt={3.5}>
            <MButton variant="primary" size="large" onClick={handleApply} data-testid="apply_btn">
              Search
            </MButton>
            <MButton
              variant="borderLess"
              size="large"
              onClick={handleReset}
              data-testid="clear_btn"
            >
              Clear filter
            </MButton>
          </Stack>
        </Stack>
        {dataLoading && <TableLoader />}
        {!requestNotifications?.items?.length && !tableLoading && (
          <Stack alignItems="center" mt={18}>
            <EmptyBlock title="No client request notifications found" />
          </Stack>
        )}
        {Boolean(requestNotifications?.items?.length) && !dataLoading && (
          <Table
            columns={ClientRequestNotificationsTableColumns}
            rows={rowsAdapter}
            total={requestNotifications?.totalRowsCount}
            isPagination={requestNotifications?.totalPages > 1}
            onDeleteRow={openDeleteRuleDialog}
            onNextPage={fetchClientRequestNotifications}
            onPreviousPage={fetchClientRequestNotifications}
            onGotoPage={fetchClientRequestNotifications}
            pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            loading={tableLoading}
          />
        )}
      </Box>
    </>
  );
};

export default ClientRequestNotifications;
