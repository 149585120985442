import React, { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material';
import { ReactComponent as Edit } from '../../../../../assets/icons/Edit.svg';
import { ReactComponent as Trash } from '../../../../../assets/icons/Trash.svg';
import { ReactComponent as EditDisabled } from '../../../../../assets/icons/EditDisabled.svg';
import { ReactComponent as DeleteDisabled } from '../../../../../assets/icons/DeleteDisabled.svg';

const StyledStack = styled(Stack)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
});

const EditableCell = ({ row, deleteRow, openModalWhenClickEdit }) => {
  const handleClickEdit = useCallback(() => {
    openModalWhenClickEdit(row.index, row.original);
  }, [row]);

  const handleClickDelete = useCallback(() => {
    deleteRow(row.id);
  }, [row.id, deleteRow]);

  if (row.original?.isTotalRow) {
    return null;
  }

  if (row.original.invoiceStatus === 'Billed') {
    return (
      <StyledStack>
        <IconButton disabled>
          <EditDisabled />
        </IconButton>
        <IconButton disabled>
          <DeleteDisabled />
        </IconButton>
      </StyledStack>
    );
  }

  return (
    <StyledStack>
      <IconButton onClick={handleClickEdit}>
        <Edit />
      </IconButton>
      <IconButton onClick={handleClickDelete}>
        <Trash />
      </IconButton>
    </StyledStack>
  );
};

EditableCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({
      invoiceStatus: PropTypes.string,
      isTotalRow: PropTypes.bool,
    }),
  }).isRequired,
  deleteRow: PropTypes.func.isRequired,
  openModalWhenClickEdit: PropTypes.func.isRequired,
};

export default EditableCell;
