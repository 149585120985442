import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled, Typography, List, ListItem, Stack } from '@mui/material';
import { ReactComponent as ArrowDown } from '../../../assets/icons/ArrowDown.svg';
import NavigationDropdown from '../../molecules/NavigationDropdown/NavigationDropdown';
import useOnClickOutside from '../../../hooks/useOutsideClick';

const StyledList = styled(List)(({ theme }) => ({
  '&.MuiList-root': {
    display: 'flex',
    marginLeft: '24px',
    alignItems: 'center',
    gap: '8px',
    whiteSpace: 'nowrap',

    '& a': {
      textDecoration: 'none',
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.text.secondary,
      width: 'auto',
      transition: 'all .2s ease-in',
      hyphens: 'auto',
      overflowWrap: 'break-word',

      '&:last-child': {
        marginRight: 0,
      },

      '&.active': {
        color: theme.palette.text.primary,
        background: theme.palette.text.disabled,
        borderRadius: '10px',
      },
    },

    '& svg': {
      marginLeft: 8,
    },
  },
}));

const StyledTypography = styled(Typography)(({ active, theme, disabled }) => {
  const getTypographyColor = () => {
    if (active) {
      return theme.palette.text.primary;
      // eslint-disable-next-line no-else-return
    }
    if (disabled) {
      return theme.palette.text.disabled;
    }
    return theme.palette.text.secondary;
  };

  return {
    userSelect: 'none',
    '&.MuiTypography-root': {
      alignItems: 'center',
      display: 'flex',
      padding: '8px 16px',
      fontSize: 16,
      fontWeight: 600,
      justifyContent: 'center',

      '&:hover': {
        cursor: disabled ? 'default' : 'pointer',
      },
    },
    '&.MuiTypography-root span': {
      alignItems: 'center',
      color: getTypographyColor(),
      fontSize: 16,
    },
    '&.MuiTypography-root svg': {
      minWidth: '16px',
    },
  };
});

const StyledStack = styled(Stack)(
  ({ activeWithOpenedNavDropdownList, activeWithoutOpenedNavDropdownList, theme }) => ({
    background:
      activeWithOpenedNavDropdownList || activeWithoutOpenedNavDropdownList
        ? theme.palette.text.disabled
        : 'initial',
    borderRadius: '10px',
    position: 'relative',
    minWidth: '130px',
  }),
);

const Navigation = ({ navData, isInnerPageNavigation }) => {
  const [isNavigationDropdownListVisible, setIsNavigationDropdownListVisible] = useState(false);
  const [activeNavItemWithDropdownListLabel, setActiveNavItemWithDropdownListLabel] = useState('');

  const navDropdownListRef = useRef();

  const { pathname, state } = useLocation();

  const handleNavItemWithDropdownList = (label) => {
    setIsNavigationDropdownListVisible((prev) => !prev);
    setActiveNavItemWithDropdownListLabel(label);
  };

  const resetClickedNavItemWithDropdownListLabel = () => {
    setActiveNavItemWithDropdownListLabel('');
  };

  useOnClickOutside(navDropdownListRef, () => {
    setIsNavigationDropdownListVisible(false);
    setActiveNavItemWithDropdownListLabel('');
  });

  const checkIsDisabledNavItem = (index) => !state?.id && isInnerPageNavigation && index;

  useEffect(() => {
    const activeNavigationItemWithDropdownListLabel =
      navData.find(
        (navDataItem) =>
          pathname.includes(navDataItem.pathname) && navDataItem?.dropdownNavigationConfig?.length,
      )?.label ?? '';

    setActiveNavItemWithDropdownListLabel(activeNavigationItemWithDropdownListLabel);
  }, []);

  return (
    <StyledList data-testid="nav_list_wrapper">
      {navData.map((navDataItem, index) => {
        // TODO: after dropdownNavigationConfig implementation for other pages from navData replcae
        // navDataItem?.dropdownNavigationConfig?.length by navDataItem?.dropdownNavigationConfig
        if (navDataItem?.dropdownNavigationConfig?.length) {
          const isListVisible =
            isNavigationDropdownListVisible &&
            activeNavItemWithDropdownListLabel === navDataItem.label;
          return (
            <StyledStack
              key={navDataItem.label}
              onClick={() => handleNavItemWithDropdownList(navDataItem.label)}
              activeWithOpenedNavDropdownList={
                activeNavItemWithDropdownListLabel === navDataItem.label &&
                isNavigationDropdownListVisible
              }
              activeWithoutOpenedNavDropdownList={pathname.includes(navDataItem.pathname)}
            >
              <StyledTypography
                active={activeNavItemWithDropdownListLabel === navDataItem.label}
                isListVisible={isListVisible}
              >
                <Typography variant="p">{navDataItem.label}</Typography>
                <ArrowDown data-testid="arrow_down_icon" />
              </StyledTypography>
              <NavigationDropdown
                config={navDataItem.dropdownNavigationConfig}
                isListVisible={isListVisible}
                listRef={navDropdownListRef}
                key={navDataItem.label}
              />
            </StyledStack>
          );
        }

        if (checkIsDisabledNavItem(index)) {
          return (
            <StyledTypography disabled={checkIsDisabledNavItem(index)} data-testid="disabled_item">
              <Typography variant="p">{navDataItem.label}</Typography>
            </StyledTypography>
          );
        }
        return (
          <ListItem
            component={NavLink}
            key={navDataItem.label}
            to={navDataItem.to}
            state={navDataItem.state}
            data-testid="link_nav"
            // isDisabledStyles={!state?.id && isInnerPageNavigation && index}
            onClick={resetClickedNavItemWithDropdownListLabel}
          >
            {navDataItem.label}
            {/* TODO: remove line below after dropdownNavigationConfig implementation for other pages from navData */}
            {!!navDataItem?.dropdownNavigationConfig?.length && <ArrowDown />}
          </ListItem>
        );
      })}
    </StyledList>
  );
};

Navigation.propTypes = {
  navData: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      state: PropTypes.shape({
        navPage: PropTypes.string,
        subNav: PropTypes.string,
      }),
    }),
  ).isRequired,
  isInnerPageNavigation: PropTypes.bool,
};

Navigation.defaultProps = {
  isInnerPageNavigation: false,
};

export default Navigation;
