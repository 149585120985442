import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Table from '../../../atoms/Table/Table';
import MButton from '../../../MUI/Button/MButton';
import MDialog from '../../../atoms/MDialog/MDialog';
import {
  CIVIL_MATTERS,
  LegalCategoriesHistoryColumns,
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
  listConfig,
} from './DashboardAttorney.constants';

import { apiClient } from '../../../../lib/apiClient';
import useAuth from '../../../../hooks/useAuth';
import { rolesDB } from '../../../../utils/roleHelpers';
import { palette } from '../../../../theme/default';
import NotificationDialog from '../../../molecules/NotificationDialog';
import notificationUtils from '../../../../utils/notificationUtils';

const DashboardAttorneyCivilMatters = ({
  stateCode,
  handleFetchDataPage,
  sortField,
  sortOrder,
  handleSortTable,
  selectedIds,
  setSelectedIds,
  isLoader,
  setIsLoader,
}) => {
  const { columns } = listConfig[CIVIL_MATTERS];
  const { userInfo } = useAuth();
  const [isCategoryHistoryModalOpen, setIsCategoryHistoryModalOpen] = useState(false);
  const [categoryHistoryTableData, setCategoryHistoryTableData] = useState(null);
  const [isShowAdditionalButton, setIsShowAdditionalButton] = useState(false);
  const [isCloseMatterModalOpen, setIsCloseMatterModalOpen] = useState(false);
  const { lists } = useSelector((state) => state.attorneyDashboard);

  const { civilMatters } = lists;

  const isAdmin = userInfo.roles.includes(rolesDB.Admin);
  const handleOpenModal = ({ referenceNumber, categoryHistory }) => {
    setCategoryHistoryTableData({ referenceNumber, categoryHistory });
    setIsCategoryHistoryModalOpen(true);
  };
  const handleCategoryHistoryModalClose = () => setIsCategoryHistoryModalOpen(false);

  const handleCheckedRow = useCallback(
    (selectedRows) => {
      setIsShowAdditionalButton(selectedRows.length > 0);
      setSelectedIds(selectedRows.map((item) => item.civilMatterCaseId));
    },
    [isShowAdditionalButton],
  );

  const handleCloseMatterModalOpen = () => setIsCloseMatterModalOpen(true);
  const handleCloseMatterModalClose = () => setIsCloseMatterModalOpen(false);
  const handleCloseMatterConfirmBtnClick = async () => {
    setIsLoader(true);
    const apiPath =
      stateCode === 'AZ'
        ? `/api/arizonaCases/civilMatters/closeMatter`
        : `/api/nevadaCases/civilMatters/closeMatter`;

    setIsCloseMatterModalOpen(false);
    await apiClient
      .post(apiPath, { caseIds: selectedIds })
      .then(() => {
        notificationUtils.success('Closed successfully');
        setSelectedIds([]);
        handleFetchDataPage();
      })
      .catch(() => notificationUtils.error('Failed, try again later'))
      .finally(() => setIsLoader(false));
  };

  const rowsAdapter = useMemo(
    () =>
      civilMatters?.items?.map((item) => ({
        ...item,
        // disable checkbox if user is attorney only
        isCheckboxDisabled: !isAdmin ? item.attorney?.id !== userInfo.UserGuid : false,
      })),
    [civilMatters],
  );

  return (
    <Stack position="relative">
      {isLoader && (
        <Box position="absolute" top="50%" left="50%" zIndex={10} transform="translate(-50%, -50%)">
          <CircularProgress />
        </Box>
      )}
      <Table
        columns={typeof columns === 'function' ? columns() : columns}
        rows={rowsAdapter}
        total={civilMatters?.totalRowsCount}
        isPagination={civilMatters?.totalPages > 1}
        onNextPage={handleFetchDataPage}
        onPreviousPage={handleFetchDataPage}
        onGotoPage={handleFetchDataPage}
        pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
        onRowClick={handleOpenModal}
        isWithCheckbox
        onCheckedRow={handleCheckedRow}
        onSortTable={handleSortTable}
        sortField={sortField}
        sortOrder={sortOrder}
        hasFixedColumns
        showFixedColumnsAtWidth={1600}
      />
      {selectedIds.length ? (
        <Box
          position="fixed"
          left={0}
          bottom={0}
          zIndex={10}
          backgroundColor="white"
          width={1}
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton
              size="large"
              onClick={handleCloseMatterModalOpen}
              disabled={isLoader}
              data-testid="close_matter_btn"
            >
              Close Matter
            </MButton>
          </Stack>
        </Box>
      ) : null}

      <MDialog
        isOpen={isCategoryHistoryModalOpen}
        fullWidth
        maxWidth="744px"
        title={
          <>
            <Typography sx={{ mb: 1, fontWeight: 600, fontSize: 24 }}>Category History</Typography>
            <Typography sx={{ mb: 4, fontSize: 16, color: palette.text.secondary }}>
              {categoryHistoryTableData?.referenceNumber}
            </Typography>
          </>
        }
        onClose={handleCategoryHistoryModalClose}
        footerActions={
          <MButton size="large" onClick={handleCategoryHistoryModalClose}>
            OK
          </MButton>
        }
      >
        <Table
          rows={categoryHistoryTableData?.categoryHistory}
          columns={LegalCategoriesHistoryColumns}
          maxHeight={165}
        />
      </MDialog>

      <NotificationDialog
        width="528px"
        title="Confirm Close Matter"
        desc={`Are you sure you want to close ${selectedIds.length} matter(s)?`}
        type="alert"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Close Matter"
        buttonPrimaryWidth={145}
        onClose={handleCloseMatterModalClose}
        onConfirm={handleCloseMatterConfirmBtnClick}
        isOpen={isCloseMatterModalOpen}
      />
    </Stack>
  );
};

DashboardAttorneyCivilMatters.propTypes = {
  stateCode: PropTypes.string.isRequired,
  attorney: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  handleFetchDataPage: PropTypes.func.isRequired,
  sortField: PropTypes.func.isRequired,
  sortOrder: PropTypes.func.isRequired,
  handleSortTable: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  isLoader: PropTypes.bool,
  setIsLoader: PropTypes.func.isRequired,
};

DashboardAttorneyCivilMatters.defaultProps = { isLoader: false };

export default DashboardAttorneyCivilMatters;
