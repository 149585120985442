import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box, Stack } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import MDialog from '../../../../atoms/MDialog/MDialog';
import Form from '../../../../atoms/Form';
import Dropzone from '../../../../atoms/Dropzone';
import { addObjToFormData } from '../../../../../utils/convertObjToFormData';
import notificationUtils from '../../../../../utils/notificationUtils';
import Table from '../../../../atoms/Table/Table';
import {
  updatedCasesColumns,
  uploadDocumentsColumns,
  uploadResultColumns,
} from './UploadDocumentsDialog.constants';
import FullscreenLoaderWithText from '../../../../atoms/FullscreenLoaderWithText/FullscreenLoaderWithText';
import {
  checkUploadDocumentStatus,
  uploadDocument,
} from '../../../../../store/slices/schedule/bulkDocumentPrintingSlice';
import timeoutPromise from '../../../../../utils/timeoutPromise';
import Tabs from '../../../../atoms/Tabs/Tabs';
import EmptyBlock from '../../../../molecules/EmptyBlock';

const INITIAL_RESULT_MESSAGE = 'Uploading...';

const tabs = [
  {
    label: 'General Info',
    id: 'general-info',
    value: 'general-info',
  },
  {
    label: 'Updated Cases',
    id: 'updated-cases',
    value: 'updated-cases',
  },
];

export default function UploadDocumentsDialog({ isOpen, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isResultDialogOpen, setIsResultDialogOpen] = useState(false);
  const [result, setResult] = useState({
    metrics: [],
    updatedCases: [],
  });
  const [tab, setTab] = useState(tabs[0]);
  const [resultMessage, setResultMessage] = useState(INITIAL_RESULT_MESSAGE);
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {
      uploadsArray: [],
    },
    mode: 'onChange',
  });

  const uploadsArray = useFieldArray({
    control: form.control,
    name: 'uploadsArray',
  });

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      uploadsArray.append(
        acceptedFiles.map((file) => ({
          documentName: file.name.includes('.')
            ? file.name.slice(0, file.name.lastIndexOf('.'))
            : file.name,
          type: 'Dismissal',
          file,
        })),
      );
    }
  };

  const onCancelPress = () => {
    onClose();
  };

  const onClosed = () => {
    form.reset({ uploadsArray: [] });
    setResultMessage(INITIAL_RESULT_MESSAGE);
  };

  const onCloseResultDialog = () => {
    setIsResultDialogOpen(false);
  };

  const onResultDialogClosed = () => {
    setResult({
      metrics: [],
      updatedCases: [],
    });
    setTab(tabs[0]);
  };

  const handleSubmit = form.handleSubmit(async (values) => {
    setIsLoading(true);

    const file = values.uploadsArray[0];

    const formData = addObjToFormData({
      documentName: file.documentName,
      type: file.type === 'Other' ? file.newType : file.type,
      file: file.file,
    });

    try {
      const id = await dispatch(uploadDocument(formData)).unwrap();
      while (true) {
        const {
          result: { inProgress, stage, report, error },
          // eslint-disable-next-line
        } = await dispatch(checkUploadDocumentStatus(id)).unwrap();
        if (error) notificationUtils.error(error?.message || error);
        if (stage) setResultMessage(`${stage}...`);

        if (inProgress) {
          // eslint-disable-next-line
          await timeoutPromise(5000);
        } else {
          onCancelPress();
          setResult({
            metrics: [
              {
                metric: 'Recoginized Pages',
                count: report.recognizedPagesCount ?? 0,
              },
              {
                metric: 'Unrecoginized Pages',
                count: report.unrecognizedPagesCount ?? 0,
              },
              {
                metric: 'Total Pages',
                count: report.totalPagesCount ?? 0,
              },
              {
                metric: 'Total Documents',
                count: report.totalDocumentsCount ?? 0,
              },
            ],
            updatedCases: report.updatedCases,
          });
          setIsResultDialogOpen(true);
          break;
        }
      }
    } catch (e) {
      notificationUtils.error('Something went wrong, please try again later.');
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <>
      <MDialog
        isOpen={isOpen}
        onClose={onCancelPress}
        onClosed={onClosed}
        title="Upload Document"
        maxWidth="900px"
        minWidth="900px"
        footerActions={[
          <MButton size="large" variant="bordered" onClick={onCancelPress}>
            Cancel
          </MButton>,
          <MButton
            size="large"
            onClick={handleSubmit}
            disabled={isLoading || !uploadsArray.fields.length || !form.formState.isValid}
          >
            Upload
          </MButton>,
        ]}
      >
        {isLoading && (
          <FullscreenLoaderWithText
            title="File is being processed. Please wait."
            description={resultMessage}
          />
        )}
        <Form form={form} onSubmit={handleSubmit}>
          {uploadsArray.fields.length ? (
            <Stack direction="column">
              <Form form={form}>
                <Table
                  overflowHidden={false}
                  loading={false}
                  columns={uploadDocumentsColumns}
                  rows={uploadsArray.fields}
                  onUpdateData={uploadsArray.update}
                  onDeleteRow={uploadsArray.remove}
                />
              </Form>
            </Stack>
          ) : (
            <Dropzone accept={{ 'application/pdf': [] }} onDrop={handleDrop} multiple={false} />
          )}
        </Form>
      </MDialog>
      <MDialog
        isOpen={isResultDialogOpen}
        onClose={onCloseResultDialog}
        onClosed={onResultDialogClosed}
        title="Results"
        maxWidth="sm"
        fullWidth
        scrollable
        footerActions={[
          <MButton size="large" onClick={onCloseResultDialog}>
            Close
          </MButton>,
        ]}
      >
        <Stack direction="column" gap={2}>
          <Tabs tabs={tabs} onTabChange={setTab} currentTab={tab} />
          <Box height="250px">
            {tab === tabs[0] && (
              <Table maxHeight="250px" columns={uploadResultColumns} rows={result.metrics || []} />
            )}
            {tab === tabs[1] &&
              (result.updatedCases?.length ? (
                <Table
                  maxHeight="250px"
                  columns={updatedCasesColumns}
                  rows={result.updatedCases || []}
                />
              ) : (
                <EmptyBlock width="100%" title="No cases were updated" />
              ))}
          </Box>
        </Stack>
      </MDialog>
    </>
  );
}

UploadDocumentsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
