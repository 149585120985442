import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Typography } from '@mui/material';
import InputCell from './InputCell';
import OpposingPartyDialog from '../OpposingPartyDialog';

const NonPaymentDefendantNameCell = ({ row, updateValue, cell }) => {
  const { value, column } = cell;
  const cellValue = value || '';
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const error = row.original?.errors?.defendantName;
  const touched = row.original?.touched?.defendantName;
  const individuals = row.original?.individuals;

  useEffect(() => {
    setEdit(false);
  }, [row.original?.id, column.id]);

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const onConfirm = useCallback(
    (name, individualsList) => {
      if (!row.original?.id) return;
      updateValue(row.original.id, {
        id: column.id,
        value: name,
        error: undefined,
      });
      updateValue(row.original.id, {
        id: 'individuals',
        value: individualsList,
      });
      handleClose();
    },
    [handleClose, column.id, row.original.id],
  );

  const handleFocus = useCallback(() => {
    setEdit(true);
  }, []);

  const handleBlur = useCallback(() => {
    if (!error) setEdit(false);
    if (!row.original?.id) return;
    updateValue(row.original.id, {
      id: 'defendantName',
      touched: true,
      value,
      error: value ? undefined : 'Mandatory field',
    });
  }, [error, row.original?.id]);

  const message = useMemo(() => {
    if (error && touched) return { message: 'Mandatory field' };
    return null;
  }, [error, touched, isEdit]);

  return (
    <>
      {isDialogOpen && (
        <OpposingPartyDialog
          dataToEdit={individuals?.length ? { individuals, defendantName: cellValue } : null}
          onConfirm={onConfirm}
          isOpen={isDialogOpen}
          onClose={handleClose}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
        <InputCell
          isNoBorder={!isEdit && !message}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onClick={handleOpenDialog}
          data-testid="cell_value_input"
          value={cellValue}
          placeholder="Add opposing party"
          error={!!message && !isDialogOpen}
          multiline
        />
        {message?.message && error && touched && !isDialogOpen && (
          <Typography
            sx={{ position: 'absolute', bottom: '-11px', fontSize: '12px' }}
            variant="caption"
            color="error"
          >
            {message.message}
          </Typography>
        )}
      </Box>
    </>
  );
};

NonPaymentDefendantNameCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    column: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      errors: PropTypes.shape({ defendantName: PropTypes.string }),
      individuals: PropTypes.arrayOf(PropTypes.shape({})),
      touched: PropTypes.shape({ defendantName: PropTypes.bool }),
    }),
  }).isRequired,
  updateValue: PropTypes.func.isRequired,
};

NonPaymentDefendantNameCell.defaultProps = {
  cell: {
    value: '',
    column: {
      id: '',
    },
  },
};

export default React.memo(NonPaymentDefendantNameCell);
