export const subPageNavNew = [
  {
    label: 'Management',
    to: '/db/datahandling/newmanagement/management',
    state: {
      navPage: 'Data Handling',
      subNav: 'Management',
    },
  },
  {
    label: 'Community',
    to: '/db/datahandling/newmanagement/communities',
    state: {
      navPage: 'Data Handling',
      subNav: 'Managements',
    },
  },
  {
    label: 'Address',
    to: '/db/datahandling/newmanagement/addresses',
    state: {
      navPage: 'Data Handling',
      subNav: 'Managements',
    },
  },
  {
    label: 'Documents',
    to: '/db/datahandling/newmanagement/documents',
    state: {
      navPage: 'Data Handling',
      subNav: 'Managements',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/newmanagement/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Managements',
    },
  },
];

export const subPageNavEdit = [
  {
    label: 'Management',
    to: '/db/datahandling/editmanagement/management',
    state: {
      navPage: 'Data Handling',
      subNav: 'Management',
    },
  },
  {
    label: 'Community',
    to: '/db/datahandling/editmanagement/communities',
    state: {
      navPage: 'Data Handling',
      subNav: 'Managements',
    },
  },
  {
    label: 'Address',
    to: '/db/datahandling/editmanagement/addresses',
    state: {
      navPage: 'Data Handling',
      subNav: 'Managements',
    },
  },
  {
    label: 'Documents',
    to: '/db/datahandling/editmanagement/documents',
    state: {
      navPage: 'Data Handling',
      subNav: 'Managements',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/editmanagement/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Managements',
    },
  },
];
