/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getLoginPage = createAsyncThunk('loginSlice/getLoginPage', async () => {
  const { data } = await apiClient.get(`/api/login/settings`);

  return data;
});

export const updateLoginPage = createAsyncThunk(
  'loginSlice/updateLoginPage',
  async (args, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('File', args.file || '');
      formData.append('FileExtension', args.fileExtension || '');
      formData.append('Description', args.description || '');
      formData.append('Note', args.note || '');
      formData.append('LinkUrl', args.linkUrl || '');
      formData.append('LinkName', args.linkName || '');

      const { data } = await apiClient.post(`/api/login/settings`, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errorMessage);
    }
  },
);

const initialState = {
  loginPage: {},
};

export const adminLoginSlice = createSlice({
  name: 'adminLogin',
  initialState,
  reducers: {},
  extraReducers: {
    [getLoginPage.fulfilled]: (state, action) => {
      state.loginPage = action.payload.result;
    },
    [getLoginPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default adminLoginSlice.reducer;
