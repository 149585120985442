import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoaderCover from '../../../atoms/LoaderCover';
import NotificationDialog from '../../../molecules/NotificationDialog';
import { apiClient } from '../../../../lib/apiClient';
import { prepareDefendantName } from '../CaseSearch/CaseSearchCells';
import { useUserInfo } from '../../../../utils/roleHelpers';
import { useBaseUrl } from '../../../../hooks/useBaseUrl';

const mapOpposingPartiesToNames = (opposingParties) => {
  return [...opposingParties]
    .sort((a, b) => {
      if (a.type === 'Individual' && b.type === 'Entity') return 1;
      if (a.type === 'Entity' && b.type === 'Individual') return -1;
      return 0;
    })
    .map((party) => party.fullName)
    .join(', ');
};

const OpenCaseByLink = () => {
  const { isClientPortalUser } = useUserInfo();
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const location = useLocation();
  const [showNotification, setShowNotification] = useState(false);
  const base = useBaseUrl();
  const caseTab = location?.state?.caseTab || 'partiesandprecinct';

  const loadCaseData = async () => {
    try {
      if (!id) throw Error('No case id provided');
      const {
        data: { result },
      } = await apiClient.get(`${isClientPortalUser ? 'cp' : ''}/api/cases/${id}/routeInfo`);

      const navPages = isClientPortalUser
        ? {
            navPage: 'Cases',
          }
        : {
            navPage: 'Processing',
            subNav: 'Cases',
          };

      const caseRouteState = {
        ...navPages,
        id: result.caseId,
        stateCode: result.stateCode,
        defendantName: prepareDefendantName(mapOpposingPartiesToNames(result.opposingParties)),
        refNum: result.referenceNumber,
        caseType: result.type,
      };

      const navigateTo = isClientPortalUser ? `/${base}/cases/viewcase` : `/db/processing/editcase`;

      navigate(`${navigateTo}/${caseTab}`, {
        state: caseRouteState,
        replace: true,
      });
    } catch (e) {
      setShowNotification(true);
    }
  };

  useEffect(() => {
    loadCaseData();
  }, []);

  const onErrorClose = () => {
    navigate(isClientPortalUser ? `/${base}/cases/casesearch` : '/db/processing/casesearch');
  };

  return (
    <>
      <LoaderCover />
      <NotificationDialog
        type="alert"
        title="You can't view this case"
        desc="It may have been deleted or you don't have permission to view it."
        buttonPrimaryText="Go to case search"
        onClose={onErrorClose}
        onConfirm={onErrorClose}
        isOpen={showNotification}
        buttonPrimaryWidth="200px"
        width="528px"
      />
    </>
  );
};

export default OpenCaseByLink;
