import React from 'react';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { ReactComponent as User } from '../../../assets/icons/User.svg';
import MButton from '../../MUI/Button/MButton';
import MBaseInputWithIcon from '../../atoms/MBaseInputWithIcon';
import { useRequestPasswordRecovery } from './ForgotPasswordHooks';
import LoaderCover from '../../atoms/LoaderCover';
import ForgotPasswordLayout from './ForgotPasswordLayout';
import NotificationDialog from '../../molecules/NotificationDialog';

const RequestPasswordRecovery = () => {
  const { control, error, isLoader, onSubmit, onCancel, showSuccessNotification, isValid } =
    useRequestPasswordRecovery();

  return (
    <ForgotPasswordLayout>
      {isLoader && <LoaderCover />}
      <form onSubmit={onSubmit}>
        <Stack pb={3} alignItems="center" width="262px">
          <MBaseInputWithIcon
            svg={<User />}
            placeholder="Username/E-mail"
            control={control}
            regConfig={{ required: true }}
            name="userNameOrEmail"
            error={error}
          />
          {error && (
            <Typography
              variant="bodyXS"
              sx={{ color: 'additional.alert', m: '6px auto 0 0' }}
              data-testid="request_password_recovery_error_msg"
            >
              {error}
            </Typography>
          )}
        </Stack>
        <Stack gap={2} direction="column" alignItems="center">
          <MButton
            type="submit"
            sx={{ width: '262px', height: '48px' }}
            disabled={isLoader || !isValid}
            data-testid="request_password_recovery_btn"
          >
            <Typography variant="buttonLarge">Request recovery link</Typography>
          </MButton>
          <Typography
            variant="buttonSmall"
            color="text.secondary"
            sx={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={onCancel}
          >
            Cancel
          </Typography>
        </Stack>
      </form>
      <NotificationDialog
        desc="A password recovery link has been sent to your email address."
        buttonPrimaryText="Ok"
        onClose={onCancel}
        onConfirm={onCancel}
        isOpen={showSuccessNotification}
      />
    </ForgotPasswordLayout>
  );
};

export default RequestPasswordRecovery;
