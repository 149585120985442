import { useState } from 'react';

export const useDateRanges = ({ currentPeriods }) => {
  const [dateRanges, setDateRanges] = useState();

  const getDateRanges = (name, index) => {
    const startDate = currentPeriods[index]?.fromDate;

    if (name.includes('fromDate')) {
      const toDateName = `periods[${index}].toDate`;
      setDateRanges((prev) => {
        return { ...prev, [toDateName]: { min: startDate } };
      });
    }

    const endDate = currentPeriods[index]?.toDate;

    if (name.includes('toDate')) {
      const fromDateName = `periods[${index}].fromDate`;
      setDateRanges((prev) => {
        return { ...prev, [fromDateName]: { max: endDate } };
      });
    }
  };

  return {
    dateRanges,
    getDateRanges,
  };
};
