import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';

import { Box, Container, styled, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import SearchInputWithLabelAndIcon from '../../atoms/SearchInputWithLabelAndIcon/SearchInputWithLabelAndIcon';
import Dropdown from '../../atoms/Dropdown';
import SearchInputWithLabel from '../../atoms/SearchInputWithLabel/SearchInputWithLabel';
import MButton from '../../MUI/Button/MButton';
import { ReactComponent as Add } from '../../../assets/icons/Add.svg';
import DatePickerWithLabel from '../../atoms/DatePickerWithLabel/DatePickerWithLabel';
import { CIVIL_MATTER_CASE } from '../../../global/Case.constants';
import SearchableCheckboxSelectWithLabel from '../../atoms/SearchableCheckboxSelectWithLabel/SearchableCheckboxSelectWithLabel';
import CheckboxWithLabel from '../CheckboxWithLabel';

const StyledHeading = styled(Typography)({
  textAlign: 'justify',
});

const StyledSearchFieldsContainer = styled('div')({
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  '&>div': {
    marginRight: '8px',
    marginBottom: '16px',
  },
});

const StyledButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '44px',
  letterSpacing: '-0.02em',
});

const StyledStack = styled(Stack)({
  '& > button': {
    marginRight: '24px',
  },
  '& > button:last-child': {
    marginRight: 0,
  },
});

const SearchContainer = ({
  caseSearch,
  searchFields,
  clearFilterString,
  submitClicked,
  submitHandler,
  clearFilterHandler,
  handleChange,
  handleDropdownChange,
  ukey,
  handleInputChange,
  params,
  setIsBulkCaseCreateOpen,
  getDropdownProps,
  states,
  // filter,
  onAdd,
  isLoading,
}) => {
  useEffect(() => {
    localStorage.setItem('stateCode', params.stateCode);
  }, [params]);

  const onNewCivilMatterCaseAdd = () => {
    onAdd(CIVIL_MATTER_CASE);
  };

  const applyFilters = () => submitHandler();

  return (
    <Container
      maxWidth={false}
      sx={{ display: 'flex', flexDirection: 'column', px: 4 }}
      disableGutters
    >
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <StyledHeading variant="h3" data-testid="search_container_label">
            {caseSearch.label}
          </StyledHeading>
          <Dropdown
            label={caseSearch.dropdownLabel}
            options={states}
            onChange={handleDropdownChange('state')}
            placeholder={states.length > 0 ? states[0].label : 'State'}
            isSearchable={false}
            value={states.find((state) => params.stateCode === state.value)}
          />
        </Box>
        <StyledStack direction="row">
          {onAdd && (
            <>
              <MButton
                variant="primary"
                onClick={() => {
                  setIsBulkCaseCreateOpen(true);
                }}
                startIcon={<Add />}
              >
                {caseSearch.bulkCaseCreationLabel}
              </MButton>

              <MButton
                variant="primary"
                onClick={onAdd}
                startIcon={<Add />}
                data-testid="search_container_add_eviction_matter_btn"
              >
                {caseSearch.buttonNewEvictionMatterLabel}
              </MButton>
              <MButton
                variant="primary"
                onClick={onNewCivilMatterCaseAdd}
                startIcon={<Add />}
                data-testid="search_container_add_civil_matter_btn"
              >
                {caseSearch.buttonNewCivilMatterLabel}
              </MButton>
            </>
          )}
        </StyledStack>
      </Container>
      <StyledSearchFieldsContainer>
        {searchFields.length > 0 &&
          searchFields.map((i, index) => {
            if (i.isCheckboxSelect) {
              const dropdownProps = getDropdownProps(i.name);
              return (
                <SearchableCheckboxSelectWithLabel
                  label={i.label}
                  value={dropdownProps.value}
                  width={i.width}
                  name={i.name}
                  height={i.height}
                  isMandatory={i.isMandatory}
                  isDisabled={i.isDisabled}
                  placeholder={i.placeholder}
                  onChange={handleChange(i.name)}
                  options={dropdownProps.options}
                  onEnter={applyFilters}
                  showSelectAll
                  allOption={{
                    id: 'all',
                    label: 'All categories',
                    value: 'all',
                  }}
                />
              );
            }

            if (i.isDropdown) {
              const dropdownProps = getDropdownProps(i.name);
              return (
                <SearchInputWithLabelAndIcon
                  key={ukey ? ukey + index : i.label + index}
                  label={i.label}
                  placeholder={i.placeholder}
                  isDropdown={i.isDropdown}
                  options={dropdownProps.options}
                  name={i.name}
                  width={i.width}
                  handleChange={handleChange(i.name)}
                  handleSearch={dropdownProps.handleSearch}
                  onMenuScrollToBottom={dropdownProps?.handleMenuScrollToBottom}
                  height={48}
                  value={dropdownProps.value}
                  onEnter={applyFilters}
                />
              );
            }
            if (i.isDate) {
              return (
                <DatePickerWithLabel
                  key={ukey ? ukey + index : i.label + index}
                  name={i.name}
                  label={i.label}
                  value={params[i.name]}
                  dateFormat={i.dateFormat}
                  onChangeValue={handleInputChange}
                  onEnter={applyFilters}
                />
              );
            }

            if (i.isCheckbox) {
              return (
                <CheckboxWithLabel
                  defaultValue={params[i.name]}
                  isDisabled={i.isDisabled}
                  key={i.label}
                  label={i.label}
                  isChecked={params[i.name]}
                  onChange={(val) => handleInputChange(val, i.name)}
                />
              );
            }

            return (
              <SearchInputWithLabel
                key={ukey ? ukey + index : i.label + index}
                label={i.label}
                placeholder={i.placeholder}
                isDropdown={i.isDropdown}
                onChangeValue={handleInputChange}
                name={i.name}
                value={params[i.name]}
                width={i.width}
                onEnter={applyFilters}
              />
            );
          })}
        <StyledButtonContainer>
          <Box>
            <MButton sx={{ ml: 2 }} disabled={isLoading} variant="bordered" onClick={applyFilters}>
              Apply
            </MButton>
          </Box>
          {submitClicked && (
            <Box sx={{ ml: 3 }}>
              <MButton
                size="small"
                variant="borderLess"
                onClick={() => clearFilterHandler()}
                disabled={isLoading}
              >
                {clearFilterString}
              </MButton>
            </Box>
          )}
        </StyledButtonContainer>
      </StyledSearchFieldsContainer>
    </Container>
  );
};

SearchContainer.propTypes = {
  params: PropTypes.shape({
    stateCode: PropTypes.string,
  }).isRequired,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ).isRequired,
  // filter: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     stateCode: PropTypes.string,
  //   }),
  // ).isRequired,
  caseSearch: PropTypes.shape({
    label: PropTypes.string,
    bulkCaseCreationLabel: PropTypes.string,
    buttonNewEvictionMatterLabel: PropTypes.string,
    buttonNewCivilMatterLabel: PropTypes.string,
    uploadDocumentsLabel: PropTypes.string,
    dropdownLabel: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    buttonLabel: PropTypes.string,
    bulkCreateButtonLabel: PropTypes.string,
  }).isRequired,
  searchFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      placeholder: PropTypes.string,
    }),
  ).isRequired,
  clearFilterString: PropTypes.string.isRequired,
  submitClicked: PropTypes.bool.isRequired,
  submitHandler: PropTypes.func.isRequired,
  clearFilterHandler: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDropdownChange: PropTypes.func.isRequired,
  ukey: PropTypes.string.isRequired.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  getDropdownProps: PropTypes.func.isRequired,
  setIsBulkCaseCreateOpen: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SearchContainer;
