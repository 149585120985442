/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import MDialog from '../../../../atoms/MDialog';
import MultilineTextField from '../../../../atoms/MultilineTextField/MultilineTextField';
import { Stack } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';

const MotionToPlaceOnCalendar = ({ isOpen, handleConfirm, handleClose }) => {
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (!isOpen) setDescription('');
  }, [isOpen]);

  const handleDescriptionChange = useCallback((event) => {
    setDescription(event.target.value);
  }, []);

  const onConfirm = () => {
    handleConfirm({
      MotionDescription: description,
    });
  };

  return (
    <MDialog
      onClose={handleClose}
      isOpen={isOpen}
      maxWidth="582px"
      minHeight="396px"
      fullWidth
      title="Motion Description"
    >
      <Stack rowGap={4}>
        <MultilineTextField
          error={false}
          value={description}
          isMandatory={true}
          rows={10}
          label="Motion Description"
          placeholder="Enter Motion Description"
          onChange={handleDescriptionChange}
        />
        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
          <MButton variant="secondary" size="large" onClick={handleClose} sx={{ width: '104px' }}>
            Cancel
          </MButton>
          <MButton size="large" onClick={onConfirm} sx={{ width: '104px' }} disabled={!description}>
            Create
          </MButton>
        </Stack>
      </Stack>
    </MDialog>
  );
};

export default MotionToPlaceOnCalendar;
