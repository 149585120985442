import React from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { entityTypes } from './NameCell';

export const DateCell = ({ value, row }) =>
  value ? (
    <Typography
      sx={{
        fontSize: row.original.type === entityTypes.MANAGEMENT ? '16px' : '16px',
        fontWeight: row.original.type === entityTypes.MANAGEMENT ? 600 : 500,
      }}
      data-testid="date_cell"
    >
      {moment(value).format('L')}
    </Typography>
  ) : (
    ''
  );

DateCell.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};
