import React, { useCallback, useState } from 'react';
import { Avatar, Collapse, Stack, Typography, styled } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useSelector } from 'react-redux';

import { ReactComponent as ArrowDown } from '../../../assets/icons/ArrowDown.svg';
import useAuth from '../../../hooks/useAuth';
import { palette } from '../../../theme/default/palette';
import { stringAvatar } from '../../../utils/stringAvatar';
import ProfileDialog from './ProfileDialog';
import { convertToBinaryFileUrl } from '../../../utils/binaryHelpers';
import { rolesDB } from '../../../utils/roleHelpers';

const StyledStack = styled(Stack)(({ isExpanded }) => ({
  cursor: 'pointer',
  padding: 8,
  borderRadius: 10,
  backgroundColor: isExpanded ? palette.buttonSecondary.pressedBg : '',
  whiteSpace: 'nowrap',

  '&:hover': {
    backgroundColor: isExpanded ? palette.additional.tag : '',
  },
}));

const StyledTypography = styled(Typography)(({ isExpanded }) => ({
  cursor: 'pointer',
  padding: 8,
  fontSize: 16,
  marginTop: 8,
  borderRadius: 10,
  backgroundColor: isExpanded ? palette.buttonSecondary.pressedBg : '',

  '&:hover': {
    backgroundColor: isExpanded ? palette.additional.tag : '',
  },
}));

const LoginWidget = () => {
  const { userInfo, onLogout } = useAuth();

  const isCPUserFromDB =
    userInfo.isClientPortalUser &&
    userInfo.roles.some((role) => {
      return Object.values(rolesDB).includes(role);
    });

  const userName = `${userInfo?.FirstName || ''} ${userInfo?.LastName || ''}`;

  const [isExpanded, setExpanded] = useState(false);
  const [isProfileOpened, setProfileOpened] = useState(false);
  const { profile } = useSelector((state) => state.userProfile);
  const imageSrc = profile?.imageContent?.content
    ? convertToBinaryFileUrl({
        contentType: profile.imageContent.contentType,
        content: profile.imageContent.content,
        fileName: 'avatar',
      })
    : null;

  const handleExpandClick = useCallback(() => {
    setExpanded(() => !isExpanded);
  }, [isExpanded]);

  const handleClickAway = useCallback(() => {
    setExpanded(false);
  }, [isExpanded]);

  const handleLogOut = useCallback(() => {
    onLogout();
  }, []);

  const { sx, children } = stringAvatar(userName);

  const handleOpenProfile = () => {
    setProfileOpened(true);
  };

  return (
    <Stack spacing={1} direction="row" height={48} position="relative" data-testid="widget_wrapper">
      {isProfileOpened && (
        <ProfileDialog isOpen={isProfileOpened} onClose={() => setProfileOpened(false)} />
      )}

      <Avatar
        data-testid="avatar"
        sx={{
          width: 48,
          height: 48,
          fontSize: 20,
          cursor: isCPUserFromDB ? 'text' : 'pointer',
          ...sx,
        }}
        onClick={isCPUserFromDB ? undefined : handleOpenProfile}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="image_login"
            style={{
              height: '48px',
              width: '48px',
              borderRadius: '50%',
            }}
            data-testid="logo"
          />
        ) : (
          children
        )}
      </Avatar>

      {!!userName && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Stack>
            <StyledStack
              position="relative"
              top={4}
              spacing={1}
              isExpanded={isExpanded}
              direction="row"
              alignItems="center"
              alignSelf="center"
              onClick={handleExpandClick}
              data-testid="username_button"
            >
              <Typography variant="buttonLarge" color="text.primary" data-testid="username">
                {userName}
              </Typography>
              <ArrowDown />
            </StyledStack>
            <Collapse in={isExpanded} orientation="vertical" timeout={0} sx={{ zIndex: 1 }}>
              <StyledTypography
                isExpanded={isExpanded}
                color="text.primary"
                onClick={handleLogOut}
                data-testid="logout"
              >
                Log out
              </StyledTypography>
            </Collapse>
          </Stack>
        </ClickAwayListener>
      )}
    </Stack>
  );
};

export default LoginWidget;
