export const contactsHeading = 'Contact Person';

export const contactsColumns = [
  {
    Header: 'Full Name',
    accessor: 'contactName',
    width: 30,
  },
  {
    Header: 'Title',
    accessor: 'contactTitle',
    width: 15,
  },
  {
    Header: 'Work #',
    accessor: 'contactWorkPhone',
    width: 15,
  },
  {
    Header: 'Mobile #',
    accessor: 'contactMobilePhone',
    width: 15,
  },
  {
    Header: 'Email',
    accessor: 'contactEmail',
    width: 25,
  },
];
