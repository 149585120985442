import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../../components/pages/Dashboard/Dashboard';
import DashboardAttorney from '../../../components/pages/Dashboard/DashboardAttorney';
import DashboardEmpty from '../../../components/pages/Dashboard/DashboardEmpty';
import DashboardLegalAssistant from '../../../components/pages/Dashboard/DashboardLegalAssistant';
import DashboardSchedulingAssistant from '../../../components/pages/Dashboard/DashboardSchedulingAssistant/DashboardSchedulingAssistant';

const DataBaseDashboardRoutes = () => (
  <Routes>
    <Route path="*" element={<Dashboard />}>
      <Route path="attorney" element={<DashboardAttorney />} />
      <Route path="legalassistant" element={<DashboardLegalAssistant />} />
      <Route path="schedulingassistant" element={<DashboardSchedulingAssistant />} />
      <Route path="*" element={<DashboardEmpty />} />
    </Route>
  </Routes>
);

export default DataBaseDashboardRoutes;
