import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import LoaderCover from '../../atoms/LoaderCover';
import { apiClient, tokenRepository } from '../../../lib/apiClient';
import Tabs from '../../atoms/Tabs/Tabs';
import SelfEnrollmentUserInformation from './SelfEnrollmentUserInformation';
import SelfEnrollmentUserAssignment from './SelfEnrollmentUserAssignment';
import NotificationDialog from '../../molecules/NotificationDialog';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const SelfEnrollmentForms = () => {
  const forms = useMemo(
    () => [
      {
        label: 'User Information',
        id: 'userInformation',
        Component: SelfEnrollmentUserInformation,
      },
      {
        label: 'Assignment',
        id: 'assignment',
        Component: SelfEnrollmentUserAssignment,
      },
    ],
    [],
  );

  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [currentTab, setCurrentTab] = useState(forms[0]);
  const [userInfo, setUserInfo] = useState({});
  const base = useBaseUrl();

  const goToClientPortalLoginPage = () => {
    tokenRepository.removeTokens();
    navigate(`/${base}/login`);
  };

  useEffect(() => {
    if (!tokenRepository.getTemporaryToken()) goToClientPortalLoginPage();
  }, []);

  const onNext = async (data) => {
    if (currentTab.id === 'userInformation') {
      setCurrentTab(forms[1]);
    }
    if (currentTab.id === 'assignment') {
      setIsLoading(true);
      try {
        const token = tokenRepository.getTemporaryToken();
        const body = {
          token,
          firstName: data.firstName,
          lastName: data.lastName,
          userName: data.userName,
          email: data.email,
          title: data.title,
          mobilePhone: data.mobilePhone,
          workPhone: data.workPhone,
          password: data.password,
          confirmPassword: data.confirmPassword,
          defaultRoleId: data?.role?.id || null,
          managementId: data.management.id,
          stateCodes: data.states,
          primaryStateCode: data.primaryStateCode?.value || data.states[0],
          hasEntirePortfolio: data.hasEntirePortfolio,
          communityIds: data.selectedCommunities.map((c) => c.communityId),
          addressIds: data.selectedAddresses.map((a) => a.addressId),
          isActive: true,
        };
        await apiClient.post(`/cp/api/selfEnrollments`, body);
        setShowSuccess(true);
      } catch (e) {
        setError({
          redirectToCP: ['token.has.expired', 'token.was.used'].includes(
            e.response?.data?.errorCode,
          ),
          message: e.response?.data?.errorMessage || 'Unexpected server error',
        });
        setShowError(true);
      }
      setIsLoading(false);
    }
  };

  const onBack = () => {
    setCurrentTab(forms[0]);
  };

  const closeErrorDialog = () => {
    setShowError(false);
    if (error.redirectToCP) goToClientPortalLoginPage();
  };

  return (
    <Stack padding={4} gap={4}>
      {isLoading && <LoaderCover />}
      <Typography variant="h3">Self Enrollment</Typography>
      <Tabs tabs={forms} currentTab={currentTab} />
      <currentTab.Component
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        onNext={onNext}
        onBack={onBack}
      />
      <NotificationDialog
        desc={error?.message}
        buttonPrimaryText="Ok"
        onClose={closeErrorDialog}
        onConfirm={closeErrorDialog}
        isOpen={showError}
        type="alert"
        width="528px"
      />
      <NotificationDialog
        title="Self enrollment successful"
        desc="Your account is available with the username and password you have provided."
        buttonPrimaryText="Ok"
        onClose={goToClientPortalLoginPage}
        onConfirm={goToClientPortalLoginPage}
        isOpen={showSuccess}
        width="528px"
      />
    </Stack>
  );
};

export default SelfEnrollmentForms;
