import { CIVIL_MATTER_CASE } from '../../../../global/Case.constants';
import { EmailCell } from '../../../atoms/Table/TableCells/EmailCell';
import { TextInputCell } from '../../../atoms/Table/TableCells/TextInputCell';
import { ReferenceNumberCell } from '../CaseSearch/CaseSearchCells';
import { DuplicateOrRelatedCell } from './DuplicateOrRelatedCell';
import PartiesAndPrecinctEditableCell from './PartiesAndPrecinctEditableCell';

export const columnsList = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    Cell: (props) => <ReferenceNumberCell {...props} isBlank />,
    width: 50,
  },
  {
    Header: 'Related',
    accessor: 'isRelated',
    width: 25,
    Cell: DuplicateOrRelatedCell,
  },
  {
    Header: 'Duplicate',
    accessor: 'isDuplicate',
    Cell: DuplicateOrRelatedCell,
    width: 25,
  },
];

export const PartiesAndPrecinctBoxes = [
  {
    boxTitle: 'Opposing Party',
    hasTooltip: true,
    tooltipText: 'Please input tenant`s name exactly as it appears on the lease.',
    name: 'oppositeParty',
    inputBoxes: [
      {
        label: 'Individual(s)',
        isSubHeading: true,
      },
      {
        label: 'First Name',
        name: 'individual.firstName',
        placeholder: 'Enter first name',
        width: '311px',
        isMandatory: true,
      },
      {
        label: 'Middle Name',
        name: 'individual.middleName',
        placeholder: 'Enter middle name',
        width: '311px',
        isMandatory: false,
      },
      {
        label: 'Last Name',
        name: 'individual.lastName',
        placeholder: 'Enter last name',
        width: '311px',
        isMandatory: true,
      },
      {
        label: 'Suffix',
        name: 'individual.suffix',
        placeholder: 'Enter suffix',
        width: '199px',
        isMandatory: false,
      },
      {
        isAddButtonWithTable: true,
        table: 'individualTable',
      },
      {
        label: 'Entity',
        isSubHeading: true,
      },
      {
        label: 'Entity Name',
        name: 'entity.entityName',
        placeholder: 'Enter entity name',
        width: '414px',
        isMandatory: true,
      },
      {
        isAddButtonWithTable: true,
        table: 'entityTable',
      },
    ],
  },
  {
    boxTitle: 'Address Information',
    name: 'community',
    inputBoxes: [
      {
        label: 'Address',
        name: 'address',
        placeholder: 'Enter address',
        width: '326px',
        isDropdown: true,
        isMandatory: true,
        isDisabled: false,
        isSearchable: true,
      },
      {
        label: 'City',
        name: 'community.city',
        placeholder: '-',
        width: '208px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'State',
        name: 'community.stateCode',
        placeholder: '-',
        width: '94px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'ZIP Code',
        name: 'community.zipCode',
        placeholder: '-',
        width: '208px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Apartment',
        name: 'community.apartmentNumber',
        placeholder: 'xxxxx',
        width: '209px',
        isMandatory: true,
        isDisabled: (form) => !!form.watch('community.hasNoApartment'),
      },
      {
        label: 'No apartment number',
        name: 'community.hasNoApartment',
        isCheckbox: true,
        isChecked: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Community Name',
        name: 'community.communityName',
        placeholder: '-',
        width: '435px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'MGT',
        name: 'community.mgt',
        placeholder: '-',
        width: '435px',
        isMandatory: false,
        isDisabled: true,
        isDropdown: true,
        isSearchable: true,
      },
      {
        label: 'Billed to corporate',
        name: 'community.isBilledToCorporate',
        isCheckbox: true,
        isChecked: false,
        isMandatory: false,
        isDisabled: false,
        caseTypes: [CIVIL_MATTER_CASE],
      },
      {
        label: 'Owner Entity',
        name: 'community.ownerEntity',
        placeholder: '-',
        width: '435px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Property Type',
        name: 'community.propertyType',
        placeholder: 'Select property type',
        width: '435px',
        isDropdown: true,
        isMandatory: false,
        isDisabled: true,
        isSearchable: false,
      },
      {
        label: 'Email',
        name: 'community.email',
        placeholder: '-',
        width: '435px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Phone',
        name: 'community.phoneNumber',
        placeholder: '-',
        width: '435px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Government Backed Loan',
        name: 'community.hasGovernmentBackedLoan',
        radioButtonWidth: '116px',
        width: 'auto',
        isRadioButtons: true,
        isDisabled: false,
        data: [
          { id: 0, label: 'Yes', value: true },
          { id: 1, label: 'No', value: false },
        ],
        isMandatory: false,
      },
      { table: 'contactPersons' },
    ],
  },
  {
    boxTitle: 'Assigned Precinct*',
    name: 'precinct',
    info: '*This part is filled automatically',
    inputBoxes: [
      {
        label: 'Court',
        name: 'assignedPrecinct.courtName',
        placeholder: '-',
        width: '350px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Phone',
        name: 'assignedPrecinct.courtPhoneNumber',
        placeholder: '-',
        width: '350px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Fax',
        name: 'assignedPrecinct.courtFaxNumber',
        placeholder: '-',
        width: '350px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Court Email',
        name: 'assignedPrecinct.courtEmail',
        placeholder: 'Enter court email',
        width: '350px',
        isDisabled: true,
      },
      {
        label: 'Address',
        name: 'assignedPrecinct.address',
        placeholder: '-',
        width: '350px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'City',
        name: 'assignedPrecinct.city',
        placeholder: '-',
        width: '350px',
        defaultValue: false,
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'State',
        name: 'assignedPrecinct.stateCode',
        placeholder: '-',
        width: '350px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'ZIP Code',
        name: 'assignedPrecinct.zipCode',
        placeholder: '-',
        width: '350px',
        isDisabled: true,
      },
      {
        isSpace: true,
      },
      {
        label: 'Virtual Meeting Link',
        name: 'assignedPrecinct.virtualMeetingLink',
        placeholder: '-',
        width: '350px',
        isMandatory: false,
        isDisabled: true,
        isLink: true,
      },
      {
        label: 'Virtual Court #',
        name: 'assignedPrecinct.virtualCourtNumber',
        placeholder: '-',
        width: '350px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Virtual Meeting #',
        name: 'assignedPrecinct.virtualMeetingNumber',
        placeholder: '-',
        width: '350px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        label: 'Court Room #',
        name: 'assignedPrecinct.courtroom',
        placeholder: '-',
        width: '350px',
        isMandatory: false,
        isDisabled: true,
      },
      {
        isSpace: true,
      },
      {
        label: 'Meeting ID #',
        name: 'assignedPrecinct.meetingIdNumber',
        placeholder: '-',
        width: '727px',
        isMandatory: false,
        isDisabled: true,
        isMultiline: true,
      },
    ],
  },
];

const arizonaCommunityNonEditableBoxes = [
  {
    label: 'Address',
    name: 'community.address',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'City',
    name: 'community.city',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'State',
    name: 'community.stateCode',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'ZIP Code',
    name: 'community.zipCode',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Apartment',
    name: 'community.apartmentNumber',
    placeholder: '-',
    width: '340px',
    isText: true,
  },
  {
    isSpace: true,
    label: 'spaceCommunity.apartmentNumber',
  },
  {
    label: 'Community Name',
    name: 'community.communityName',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Owner Entity',
    name: 'community.ownerEntity',
    placeholder: '-',
    width: '702px',
    isText: true,
  },
  {
    label: 'Management Company',
    name: 'community.mgt',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Billed to corporate',
    name: 'community.isBilledToCorporate',
    width: '339px',
    isBoolean: true,
    caseTypes: [CIVIL_MATTER_CASE],
  },
  {
    isSpace: true,
    label: 'spaceCommunity.mgt',
  },
  {
    label: 'Property Type',
    name: 'community.propertyType',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Email',
    name: 'community.email',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Phone',
    name: 'community.phoneNumber',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Government Backed Loan',
    name: 'community.hasGovernmentBackedLoan',
    width: '339px',
    isBoolean: true,
  },
];

const nevadaCommunityNonEditableBoxes = [
  {
    label: 'Address',
    name: 'community.address',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'City',
    name: 'community.city',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'State',
    name: 'community.stateCode',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'ZIP Code',
    name: 'community.zipCode',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Apartment',
    name: 'community.apartmentNumber',
    placeholder: '-',
    width: '340px',
    isText: true,
  },
  {
    isSpace: true,
    label: 'spaceCommunity.apartmentNumber',
  },
  {
    label: 'Community Name',
    name: 'community.communityName',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Owner Entity',
    name: 'community.ownerEntity',
    placeholder: '-',
    width: '702px',
    isText: true,
  },
  {
    label: 'Management Company',
    name: 'community.mgt',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Billed to corporate',
    name: 'community.isBilledToCorporate',
    width: '339px',
    isBoolean: true,
    caseTypes: [CIVIL_MATTER_CASE],
  },
  {
    isSpace: true,
    label: 'spaceCommunity.mgt',
  },
  {
    label: 'Email',
    name: 'community.email',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Phone',
    name: 'community.phoneNumber',
    placeholder: '-',
    width: '339px',
    isText: true,
  },
  {
    label: 'Government Backed Loan',
    name: 'community.hasGovernmentBackedLoan',
    width: '339px',
    isBoolean: true,
  },
];

export const PartiesAndPrecinctBoxesNonEditable = [
  {
    boxTitle: 'Address Information',
    name: 'community',
    inputBoxes: (stateCode) => {
      if (stateCode === 'NV') {
        return nevadaCommunityNonEditableBoxes;
      }
      if (stateCode === 'AZ') {
        return arizonaCommunityNonEditableBoxes;
      }
      return [];
    },
  },
  {
    boxTitle: 'Assigned Precinct',
    name: 'assignedPrecinct',
    info: '*This part is filled automatically',
    inputBoxes: [
      {
        label: 'Court',
        name: 'assignedPrecinct.courtName',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        label: 'Phone',
        name: 'assignedPrecinct.courtPhoneNumber',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        label: 'Fax',
        name: 'assignedPrecinct.courtFaxNumber',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        label: 'Court Email',
        name: 'assignedPrecinct.courtEmail',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        isSpace: true,
        label: 'spaceAssignedPrecinct.courtEmail',
      },
      {
        label: 'Address',
        name: 'assignedPrecinct.address',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        label: 'City',
        name: 'assignedPrecinct.city',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        label: 'State',
        name: 'assignedPrecinct.stateCode',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        label: 'ZIP Code',
        name: 'assignedPrecinct.zipCode',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        isSpace: true,
        label: 'spaceAssignedPrecinct.zipCode',
      },
      {
        label: 'Virtual Meeting Link',
        name: 'assignedPrecinct.virtualMeetingLink',
        placeholder: '-',
        width: '339px',
        isLink: true,
      },
      {
        label: 'Virtual Court #',
        name: 'assignedPrecinct.virtualCourtNumber',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        label: 'Virtual Meeting #',
        name: 'assignedPrecinct.virtualMeetingNumber',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        label: 'Court Room #',
        name: 'assignedPrecinct.courtroom',
        placeholder: '-',
        width: '339px',
        isText: true,
      },
      {
        isSpace: true,
      },
      {
        label: 'Meeting ID #',
        name: 'assignedPrecinct.meetingIdNumber',
        placeholder: '-',
        width: '702px',
        isMultiline: true,
      },
    ],
  },
];

export const INDIVIDUALS_COLUMNS = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 25,
    edit: true,
    isMandatory: true,
    Cell: TextInputCell,
    alignItems: 'flex-start',
    showHelperText: true,
  },
  {
    Header: 'Middle Name',
    accessor: 'middleName',
    width: 25,
    edit: true,
    Cell: TextInputCell,
    alignItems: 'flex-start',
    showHelperText: true,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 25,
    edit: true,
    isMandatory: true,
    Cell: TextInputCell,
    alignItems: 'flex-start',
    showHelperText: true,
  },
  {
    Header: 'Suffix',
    accessor: 'suffix',
    width: 20,
    edit: true,
    Cell: TextInputCell,
    alignItems: 'flex-start',
    showHelperText: true,
  },
  {
    Header: '',
    width: 5,
    minWidth: 75,
    accessor: 'control',
    Cell: PartiesAndPrecinctEditableCell,
    alignItems: 'flex-start',
  },
];

export const ENTITIES_COLUMNS = [
  {
    Header: 'Entity Name',
    accessor: 'entityName',
    edit: true,
    isMandatory: true,
    Cell: TextInputCell,
  },
  {
    Header: '',
    accessor: 'control',
    width: 5,
    minWidth: 75,
    Cell: PartiesAndPrecinctEditableCell,
  },
];

export const CONTACT_PERSONS_COLUMNS = [
  {
    Header: 'Full Name',
    accessor: 'contactName',
  },
  {
    Header: 'Title',
    accessor: 'contactTitle',
  },
  {
    Header: 'Work #',
    accessor: 'contactWorkPhone',
  },
  {
    Header: 'Mobile #',
    accessor: 'contactMobilePhone',
  },
  {
    Header: 'Email',
    accessor: 'contactEmail',
    Cell: EmailCell,
  },
];

export const DEFENDANTS_COLUMN_NONEDITABLE = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 25,
  },
  {
    Header: 'Middle Name',
    accessor: 'middleName',
    width: 25,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 25,
  },
  {
    Header: 'Suffix',
    accessor: 'suffix',
    width: 25,
  },
];

export const ENTITIES_COLUMNS_NON_EDITABLE = [
  {
    Header: 'Entity Name',
    accessor: 'entityName',
  },
];

export const PROPERTY_TYPE = [
  { id: 0, value: 'Apartment', label: 'Apartment', default: true },
  { id: 1, value: 'Mobile Home Park', label: 'Mobile Home Park' },
  { id: 2, value: 'Commercial', label: 'Commercial' },
];
