import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { palette } from '../../../theme/default';
import { ReactComponent as InfoCircleBlack } from '../../../assets/icons/InfoCircleBlack.svg';

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    data-testid="light_tooltip"
    sx={{
      position: 'relative',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.common.white,
    color: palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

export const LightVioletTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    data-testid="light_violet_tooltip"
    sx={{
      position: 'relative',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }}
  />
))(({ theme, arrow }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.additional.tag,
    color: palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: '16px',
    fontWeight: 500,
    padding: '24px',
    borderRadius: '10px',
    display: 'flex',
  },
  [`& .${tooltipClasses.arrow}`]: {
    ...(arrow && { color: palette.additional.tag, marginLeft: '17px' }),
  },
}));

const MTooltip = ({ text, placement, customStyles, tooltipVariant, arrow }) => {
  const originStyles = {
    position: 'absolute',
    top: '-17px',
    right: '-30px',
  };

  const CurrentTooltipVariant = tooltipVariant === 'white' ? LightTooltip : LightVioletTooltip;

  return (
    <CurrentTooltipVariant title={text} placement={placement} arrow={arrow}>
      <IconButton>
        <InfoCircleBlack
          style={{
            height: '16px',
            ...(customStyles || originStyles),
          }}
        />
      </IconButton>
    </CurrentTooltipVariant>
  );
};

MTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  placement: PropTypes.string,
  tooltipVariant: PropTypes.oneOf(['white', 'lightviolette']),
  customStyles: PropTypes.shape(),
  arrow: PropTypes.bool,
};

MTooltip.defaultProps = {
  placement: 'top',
  tooltipVariant: 'white',
  customStyles: null,
  arrow: false,
};

export default MTooltip;
