import { Box, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import { rolesDB, useAllowed, useUserInfo } from '../../../../utils/roleHelpers';
import Dropdown from '../../../atoms/Dropdown';
import DashboardLegalAssistantChart from './DashboardLegalAssistantChart';
import DashboardLegalAssistantLists from './DashboardLegalAssistantLists';
import { usePrimaryState } from '../../../../hooks/usePrimaryState';

const DashboardLegalAssistant = () => {
  useSafeLocationState('Dashboard', 'Legal Assistant');
  const { stateCodes } = useUserInfo();
  const primaryState = usePrimaryState();

  const states = useMemo(
    () =>
      stateCodes.map((stateCode) => ({
        id: stateCode,
        label: stateCode,
        value: stateCode,
      })),
    [stateCodes],
  );

  const [state, setState] = useState(primaryState || states[0]);
  const canChangeState = useAllowed([rolesDB.Admin]);

  return (
    <Stack padding="0 32px 24px" spacing={3} maxWidth="100vw">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h3">Legal Assistant</Typography>
        {canChangeState && (
          <Box paddingTop={1}>
            <Dropdown label="Select state" options={states} onChange={setState} value={state} />
          </Box>
        )}
      </Stack>
      <DashboardLegalAssistantChart state={state} />
      <DashboardLegalAssistantLists state={state} />
    </Stack>
  );
};

export default DashboardLegalAssistant;
