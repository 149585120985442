import { capitalizeFirstLetter } from './capitalizeFirstLetter';

export const convertToQueryString = (params, capitalized = true) => {
  if (!params) return '';
  return new URLSearchParams(
    capitalized
      ? Object.keys(params).reduce((acc, key) => {
          const value = params[key];
          acc[capitalizeFirstLetter(key)] = value;
          return acc;
        }, {})
      : params,
  ).toString();
};
