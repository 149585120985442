import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack, CircularProgress } from '@mui/material';
import Table from '../../atoms/Table/Table';
import MButton from '../../MUI/Button/MButton';
import EmptyBlock from '../../molecules/EmptyBlock';
import Searcher from '../../molecules/Searcher';
import MDialog from '../../atoms/MDialog';

const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

const newUserAddressColumns = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 50,
  },
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 50,
  },
];

const SelfEnrollmentAddressesModal = ({
  isOpen,
  title,
  buttonSecondaryText,
  buttonPrimaryText,
  onClose,
  inputPlaceholder,
  managementId,
  alreadyAdded,
  setSelected,
  loadData,
  addresses,
  stateCode,
}) => {
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [checkedRows, setCheckedRows] = useState(null);

  useEffect(() => {
    if (!managementId) return;

    setIsLoaderTable(true);
    loadData({
      currentPage: 1,
      pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
      address: '',
      managementId,
      ...(stateCode && { stateCode }),
    }).then(() => setIsLoaderTable(false));
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && searchValue === '') {
      setIsLoaderTable(true);
      loadData({
        currentPage: 1,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        address: '',
        managementId,
        ...(stateCode && { stateCode }),
      }).then(() => setIsLoaderTable(false));
    }
  }, [searchValue]);

  const handleCheckedRow = useCallback((selectedRows) => {
    setCheckedRows(selectedRows.map((item) => item.addressId));
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      loadData({
        currentPage,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        address: searchValue,
        managementId,
        ...(stateCode && { stateCode }),
      });
    },
    [searchValue, title],
  );

  const handleInputChange = (value) => {
    setSearchValue(value);
  };

  const handleSearchByInputValue = () => {
    if (isLoaderTable || !searchValue) return;

    loadData({
      currentPage: 1,
      pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
      address: searchValue,
      managementId,
      ...(stateCode && { stateCode }),
    });
  };

  const handleConfirm = () => {
    const alreadyAddedIds = alreadyAdded.map((item) => item.addressId);

    const rowsToAdd = addresses.items
      .filter(
        (item) => checkedRows.includes(item.addressId) && !alreadyAddedIds.includes(item.addressId),
      )
      .map((item) => ({
        addressId: item.addressId,
        address: item.address,
        communityName: item.communityName,
      }));
    setSelected((selected) => selected.concat(rowsToAdd));
    onClose();
  };

  return (
    <MDialog onClose={onClose} open={isOpen} maxWidth="sm" fullWidth scrollable>
      <Stack data-testid="stack-addressModal" spacing={4} direction="column" display="flex">
        <Typography variant="h4">{title}</Typography>
        <Stack display="flex" direction="row" alignItems="center" height="48px">
          <Searcher
            data-testid="search_input"
            inputPlaceholder={inputPlaceholder}
            onSearch={handleSearchByInputValue}
            onChange={handleInputChange}
          />
        </Stack>
        {isLoaderTable ? (
          <Stack data-testid="loaderTable" flexGrow={1} justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack flexGrow={1} display="flex" justifyContent="flex-start" alignItems="center">
            {managementId && addresses?.items?.length ? (
              <Table
                columns={newUserAddressColumns}
                rows={addresses.items}
                total={addresses.totalRowsCount}
                isPagination={addresses.totalPages > 1}
                onNextPage={handleFetchDataPage}
                onPreviousPage={handleFetchDataPage}
                onGotoPage={handleFetchDataPage}
                pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
                loading={isLoaderTable}
                isWithCheckbox
                maxHeight="40vh"
                onCheckedRow={handleCheckedRow}
              />
            ) : (
              <EmptyBlock
                title="Addresses not found"
                desc="Сhange the search value"
                margin="auto"
              />
            )}
          </Stack>
        )}
        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
          {buttonSecondaryText && (
            <MButton variant="secondary" size="large" onClick={onClose} sx={{ width: '104px' }}>
              {buttonSecondaryText}
            </MButton>
          )}
          {buttonPrimaryText && (
            <MButton
              data-testid={buttonPrimaryText}
              size="large"
              onClick={handleConfirm}
              sx={{ width: '104px' }}
              disabled={false}
            >
              {buttonPrimaryText}
            </MButton>
          )}
        </Stack>
      </Stack>
    </MDialog>
  );
};

SelfEnrollmentAddressesModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  managementId: PropTypes.string,
  onClose: PropTypes.func,
  inputPlaceholder: PropTypes.string.isRequired,
  alreadyAdded: PropTypes.instanceOf(Array),
  addresses: PropTypes.instanceOf(Array).isRequired,
  stateCode: PropTypes.string,
  setSelected: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
};

SelfEnrollmentAddressesModal.defaultProps = {
  isOpen: false,
  title: '',
  buttonSecondaryText: '',
  managementId: null,
  buttonPrimaryText: '',
  onClose: null,
  alreadyAdded: [],
  stateCode: '',
};

export default SelfEnrollmentAddressesModal;
