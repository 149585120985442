/* eslint-disable */
import React, { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';
import { ReactComponent as Download } from '../../../../../../assets/icons/DocumentDownload.svg';

export const DownloadDeleteDoc = ({ row: { id, original }, downloadRow }) => {
  const handleDownloadFile = useCallback(() => {
    downloadRow(id, original);
  }, [id, original]);

  return (
    <IconButton onClick={handleDownloadFile}>
      <Download />
    </IconButton>
  );
};

DownloadDeleteDoc.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({}),
  }).isRequired,
  downloadRow: PropTypes.func.isRequired,
};
