import { useEffect, useState } from 'react';

const withWasPageReloaded = (Component) => (props) => {
  const [wasPageReloaded, setWasPageReloaded] = useState(false);

  useEffect(() => {
    const observer = new PerformanceObserver((list) => {
      const entries = list.getEntries();
      const navigationEntry = entries.find((entry) => entry.entryType === 'navigation');
      if (navigationEntry) {
        setWasPageReloaded(navigationEntry.type === 'reload');
      }
    });

    observer.observe({ entryTypes: ['navigation'] });

    return () => {
      observer.disconnect();
    };
  }, []);

  return <Component key={Number(wasPageReloaded)} {...props} wasPageReloaded={wasPageReloaded} />;
};

export { withWasPageReloaded };
