import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Page from '../../organisms/Page/Page';
import Invoices from './Invoices';

const ClientPortalInvoices = () => {
  const { refNum, defendantName } = useParams();
  const { state } = useLocation();

  const navPageString = state && state.navPage;
  const subNavString = state && state.subNav;
  const subSubNavigation = state && state.subSubNavigation;

  return (
    <Page
      navPage={navPageString}
      subNavigation={subNavString}
      subSubNavigation={subSubNavigation}
      id={refNum}
      caseName={defendantName}
    >
      <Invoices />
    </Page>
  );
};

export default ClientPortalInvoices;
