import { useCallback, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import ShadowBlock from '../../atoms/ShadowBlock';
import Form from '../../atoms/Form';
import { userInformationBoxes } from './SelfEnrollment.constants';
import Field from '../../atoms/Field';
import MultipleSelectChip from '../../atoms/MultipleSelectChip';
import Dropdown from '../../atoms/Dropdown';
import MButton from '../../MUI/Button/MButton';
import CustomRadioGroup from '../../atoms/CustomRadioGroup/CustomRadioGroup';
import Password from '../../atoms/Password/Password';
import EditInputWithLabel from '../../molecules/EditInputWithLabel';
import { palette } from '../../../theme/default';
import { useUserInformationData } from './SelfEnrollmentHooks';
import MTooltip from '../../atoms/MTooltip';
import {
  invalidEmailErrorMessage,
  invalidPasswordErrorMessage,
  isEmailValid,
  isPasswordValid,
} from '../../../utils/formHelpers';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const SelfEnrollmentUserInformation = ({ userInfo, setUserInfo, onNext }) => {
  const navigate = useNavigate();
  const base = useBaseUrl();

  const form = useForm({
    defaultValues: {
      ...userInfo,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { roles, states, companyManagements } = useUserInformationData(form);

  useEffect(() => {
    form.setValue(
      'role',
      roles.find((item) => item.value === 'User'),
    );
  }, [roles]);

  const getOptions = useCallback(
    (dropdownName) => {
      switch (dropdownName) {
        case 'role':
          return roles;
        case 'states':
        case 'primaryStateCode':
          return states;
        case 'management':
          return companyManagements;
        default:
          return [];
      }
    },
    [roles, states, companyManagements],
  );

  const handleCancel = () => {
    navigate(`/${base}/login`);
  };

  const handleSubmit = (formValues) => {
    const { password, confirmPassword, userName, email } = formValues;

    const errors = {};

    if (!/^([a-zA-Z0-9]+)$/.test(userName)) {
      errors.userName = { message: 'Username can only contain letters or digits' };
    }

    if (!isEmailValid(email)) {
      errors.email = { message: invalidEmailErrorMessage };
    }

    if (password || confirmPassword) {
      if (password !== confirmPassword) {
        errors.confirmPassword = { message: 'Passwords do not match' };
      }
      if (!isPasswordValid(password)) {
        errors.password = {
          message: invalidPasswordErrorMessage,
        };
      }
    }

    if (Object.keys(errors).length !== 0) {
      Object.keys(errors).forEach((e) => {
        form.setError(e, errors[e]);
      });
      return;
    }

    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      ...formValues,
    }));
    onNext();
  };

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  const checkDisabledField = useCallback(
    (fieldName, isDisabled) => {
      if (fieldName === 'primaryStateCode') {
        const chosenStates = form.watch('states');
        return chosenStates?.length <= 1;
      }
      return isDisabled;
    },
    [form.watch('states')],
  );

  const selectedState = form.watch('states') ?? [];
  const getMandatory = useCallback(
    (name) => {
      switch (name) {
        case 'primaryStateCode':
          return Boolean(selectedState.length >= 2);
        default:
          return false;
      }
    },
    [selectedState.length],
  );

  const getOnChange = useCallback(
    (onChange, name) => (valueOrEvent) => {
      switch (name) {
        case 'states': {
          form.setValue('states', valueOrEvent);
          if (valueOrEvent.length === 1) {
            const state = states.find((item) => item.value === valueOrEvent[0]);
            form.setValue('primaryStateCode', state);
          }
          return onChange(valueOrEvent);
        }
        case 'primaryStateCode': {
          const state = states.find((item) => item.value === valueOrEvent.value);
          form.setValue('primaryStateCode', state);
          return onChange(valueOrEvent);
        }
        default:
          return onChange(valueOrEvent);
      }
    },
    [],
  );

  return (
    <Box pb={24}>
      <ShadowBlock variant="dropdownCard">
        <Typography variant="h5">User Information</Typography>
        <Form data-testid="form" onSubmit={handleSubmit} form={form}>
          <Stack direction="row" columnGap={3} rowGap={4} flexWrap="wrap">
            {userInformationBoxes.map((item) => {
              if (item.isDropdownMultiChip) {
                return (
                  <Field
                    key={item.label}
                    name={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, error, onCustomChange }) => (
                      <MultipleSelectChip
                        value={field.value || []}
                        isAddDisabled={false}
                        label={item.label}
                        width={item.width}
                        isColumn
                        error={error}
                        isMandatory={item.isMandatory}
                        placeholder={item.placeholder}
                        options={getOptions(item.name)}
                        onChange={getOnChange(onCustomChange(field.onChange), field.name)}
                      />
                    )}
                  />
                );
              }

              if (item.isDropdown) {
                return (
                  <Field
                    key={item.label}
                    name={item.name}
                    isMandatory={getMandatory(item.name) || item.isMandatory}
                    render={({ field, error, onCustomChange }) => {
                      const isPrimaryStateCode = field.name === 'primaryStateCode';
                      const fieldValue = form.watch(field.name);
                      const primaryCode = states?.find((code) => code.value === fieldValue?.value);
                      return (
                        <Dropdown
                          value={isPrimaryStateCode ? primaryCode : field.value}
                          isDisabled={checkDisabledField(item.name, item.isDisabled)}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          tooltip={item.tooltip}
                          isColumn
                          error={error}
                          isMandatory={getMandatory(item.name) || item.isMandatory}
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={getOnChange(onCustomChange(field.onChange), field.name)}
                        />
                      );
                    }}
                  />
                );
              }

              if (item.isRadioButtons) {
                return (
                  <Field
                    key={item.label}
                    name={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, onCustomChange }) => (
                      <CustomRadioGroup
                        data={item.data}
                        value={field.value}
                        label={item.label}
                        width={item.width}
                        radioButtonWidth={item.radioButtonWidth}
                        onChange={onCustomChange(field.onChange)}
                        defaultValue={getRadioGroupDefaultValue(item.data)}
                      />
                    )}
                  />
                );
              }

              if (item?.isSpace) {
                return <div style={{ width: '100%' }} />;
              }

              if (item.isPassword) {
                return (
                  <Field
                    key={item.label}
                    name={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, error, onCustomChange }) => (
                      <Password
                        isMandatory={item.isMandatory}
                        error={error}
                        width={item.width}
                        label={item.label}
                        tooltip={
                          item.tooltip ? (
                            <MTooltip
                              text={item.tooltip}
                              customStyles={{ position: 'absolute', top: '-10px', right: '-30px' }}
                            />
                          ) : null
                        }
                        placeholder={item.placeholder}
                        onChange={onCustomChange(field.onChange)}
                        value={field.value}
                      />
                    )}
                  />
                );
              }

              return (
                <Field
                  key={item.label}
                  name={item.name}
                  isMandatory={item.isMandatory}
                  render={({ field, error }) => (
                    <EditInputWithLabel
                      type="text"
                      error={error}
                      label={item.label}
                      placeholder={item.placeholder}
                      width={item.width}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              );
            })}
            <Box
              position="fixed"
              bottom={0}
              left={0}
              zIndex={10}
              width={1}
              p={4}
              boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
              backgroundColor="#fff"
            >
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <MButton
                  variant="secondary"
                  data-testid="cancel"
                  size="large"
                  onClick={handleCancel}
                  sx={{ width: '144px' }}
                >
                  Cancel
                </MButton>
                <MButton
                  data-testid="nextButton"
                  size="large"
                  disabled={!form.formState.isValid}
                  type="submit"
                  sx={{ width: '144px' }}
                >
                  Next
                </MButton>
              </Stack>
            </Box>
          </Stack>
        </Form>
      </ShadowBlock>
    </Box>
  );
};

SelfEnrollmentUserInformation.propTypes = {
  userInfo: PropTypes.shape({}).isRequired,
  setUserInfo: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default SelfEnrollmentUserInformation;
