/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getCommunities = createAsyncThunk(
  'casesFilter/getCommunities',
  // eslint-disable-next-line
  async (params = {}, thunkAPI) => {
    const {
      communities: { items: prevItems = [] },
    } = thunkAPI.getState().casesFilters;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/communities/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.communityId,
      label: item.communityName,
      value: item.communityId,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getPropertyAddresses = createAsyncThunk(
  'casesFilter/getPropertyAddresses',
  // eslint-disable-next-line
  async (params = {}, thunkAPI) => {
    const {
      propertyAddresses: { items: prevItems = [] },
    } = thunkAPI.getState().casesFilters;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/addresses/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.addressId,
      label: item.address,
      value: item.addressId,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getOpposingParty = createAsyncThunk(
  'casesFilter/getOpposingParty',
  // eslint-disable-next-line
  async (params = {}, thunkAPI) => {
    const {
      opposingParty: { items: prevItems = [] },
    } = thunkAPI.getState().casesFilters;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/cases/defendants/filter`, {
      ...params,
      fullName: params.opposingPartyFullName || '',
    });

    const currentStateItems = items.map((item) => ({
      id: item.caseDefendantId,
      label: item.defendantFullName,
      value: item.defendantFullName,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getStates = createAsyncThunk('casesFilter/states', async (params = {}) => {
  const {
    data: { result },
  } = await apiClient.get(`/api/states`, { params });
  return result;
});

export const getCompanyManagementsList = createAsyncThunk(
  'casesFilter/getCompanyManagementsList',
  // eslint-disable-next-line
  async (params = {}, thunkAPI) => {
    const {
      managementCompanies: { items: prevItems = [] },
    } = thunkAPI.getState().casesFilters;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/managements/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.managementId,
      label: item?.managementName,
      value: item?.managementId,
      data: item,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getJurisdictions = createAsyncThunk(
  'casesFilter/getJurisdictions',
  // eslint-disable-next-line default-param-last
  async (params = {}, thunkAPI) => {
    const {
      jurisdictions: { items: prevStateItems = [] },
    } = thunkAPI.getState().casesFilters;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/courts/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.courtId,
      value: item.courtId,
      label: item.courtName,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevStateItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getCaseStatusesByStateCode = createAsyncThunk(
  'casesFilter/getCaseStatusesByStateCode',
  async (stateCode = 'AZ') => {
    const apiByStateCode = {
      AZ: 'arizonaCases',
      NV: 'nevadaCases',
    };
    const { data } = await apiClient.get(`/api/${apiByStateCode[stateCode]}/statuses`);
    return data;
  },
);

const initialState = {
  communities: {
    items: [],
  },
  propertyAddresses: {
    items: [],
  },
  opposingParty: {
    items: [],
  },
  states: [],
  statuses: [],
  managementCompanies: {
    items: [],
  },
  jurisdictions: {
    items: [],
  },
  statusesByStateCode: [],
};

export const casesFilterSlice = createSlice({
  name: 'casesFilter',
  initialState,
  extraReducers: {
    [getCompanyManagementsList.fulfilled]: (state, action) => {
      state.managementCompanies = action.payload;
    },
    [getJurisdictions.fulfilled]: (state, action) => {
      state.jurisdictions = action.payload;
    },
    [getCommunities.fulfilled]: (state, action) => {
      state.communities = action.payload;
    },
    [getPropertyAddresses.fulfilled]: (state, action) => {
      state.propertyAddresses = action.payload;
    },
    [getOpposingParty.fulfilled]: (state, action) => {
      state.opposingParty = action.payload;
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.map((s) => ({
        id: s.id,
        value: s.code,
        label: s.code,
      }));
    },
    [getCaseStatusesByStateCode.fulfilled]: (state, action) => {
      state.statusesByStateCode = action.payload.result.map(({ value }) => ({
        id: value,
        label: value,
        value,
      }));
    },
  },
});

export default casesFilterSlice.reducer;
