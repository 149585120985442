import React from 'react';
import { Typography, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import MTooltip from '../../../../atoms/MTooltip/MTooltip';

// DO NOT REMOVE IT.
const customStyles = {};

const TextEllipsisWithTooltip = ({ value }) => {
  if (!value) return null;

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MTooltip text={value} customStyles={customStyles} tooltipVariant="lightviolete" arrow />
      <Typography
        sx={{
          textAlign: 'right',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '150px',
          fontSize: '15px',
          fontWeight: 500,
          marginLeft: '10px',
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

TextEllipsisWithTooltip.propTypes = {
  value: PropTypes.string,
};

TextEllipsisWithTooltip.defaultProps = {
  value: '',
};

export default TextEllipsisWithTooltip;
