/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const getCourt = createAsyncThunk('nevadaCourts/getCourt', async (params) => {
  const { data } = await apiClient.get(`/api/nevadaCourts/${params.id}`);

  return data;
});

export const updateCourt = createAsyncThunk(
  'nevadaCourts/updateCourt',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(`/api/nevadaCourts/${params.id}`, params);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const getFeesAndCosts = createAsyncThunk('nevadaCourts/getFeesAndCosts', async (params) => {
  const { data } = await apiClient.get(`/api/nevadaCourts/${params.id}/feesAndCosts`);
  return data;
});

export const createCourt = createAsyncThunk(
  'nevadaCourts/createCourt',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/nevadaCourts`, params);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const getListOfAddressesForCourt = createAsyncThunk(
  'nevadaCourts/getListOfAddressesForCourt',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/nevadaCourts/${params.id}/addresses/filter`,
      params,
    );
    return data;
  },
);

export const addAddressesToCourt = createAsyncThunk(
  'nevadaCourts/addAddressesToCourt',
  async (params) => {
    const { data } = await apiClient.post(`/api/nevadaCourts/${params.id}/addresses`, params);
    return data;
  },
);

export const removeAddressesFromCourt = createAsyncThunk(
  'nevadaCourts/removeAddressesFromCourt',
  async (params) => {
    const { data } = await apiClient.delete(`/api/nevadaCourts/${params.id}/addresses`, {
      data: params,
      headers: { 'Content-Type': 'application/json' },
    });
    return data;
  },
);

export const resetFeesAndCostsNevada = createAction('nevadaCourts/resetFeesAndCosts');

const initialState = {
  getCourt: {},
  getFeesAndCosts: null,
  getListOfAddressesForCourt: [],
};

export const nevadaCourtsSlice = createSlice({
  name: 'nevadaCourts',
  initialState,
  reducers: {},
  extraReducers: {
    [getCourt.fulfilled]: (state, action) => {
      state.getCourt = action.payload.result;
    },
    [getFeesAndCosts.fulfilled]: (state, action) => {
      state.getFeesAndCosts = action.payload.result;
    },
    [getListOfAddressesForCourt.fulfilled]: (state, action) => {
      state.getListOfAddressesForCourt = action.payload.result;
    },
    [resetFeesAndCostsNevada]: (state) => {
      state.getFeesAndCosts = null;
    },
  },
});

export default nevadaCourtsSlice.reducer;
