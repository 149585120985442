/* eslint-disable react/prop-types */
import React from 'react';
import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';
import MRadioButton from '../../../../atoms/MRadioButton';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;
export const MAX_HEIGHT_DIALOG = 624;
export const MAX_HEIGHT_DIALOG_TABLE = 326;

export const ManagementCommunityColumns = [
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 20,
  },
  {
    Header: 'Owner Entity',
    accessor: 'ownerEntity',
    width: 20,
  },
  {
    Header: () => <div style={{ textAlign: 'center' }}>On Hold</div>,
    accessor: 'isOnHold',
    width: 10,
    Cell: ({ row }) => (
      <div style={{ textAlign: 'center' }}>
        <MRadioButton isChecked={row?.original?.isOnHold} isTogglable={false} />
      </div>
    ),
  },
  {
    Header: 'On Hold Description',
    accessor: 'onHoldDescription',
    width: 40,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    Cell: DeleteCell,
  },
];

export const ManagementCommunityAllColumns = [
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 20,
  },
  {
    Header: 'Owner Entity',
    accessor: 'ownerEntity',
    width: 20,
  },
  {
    Header: () => <div style={{ textAlign: 'center' }}>On Hold</div>,
    accessor: 'isOnHold',
    width: 15,
    Cell: ({ row }) => (
      <div style={{ textAlign: 'center' }}>
        <MRadioButton isChecked={row?.original?.isOnHold} isTogglable={false} />
      </div>
    ),
  },
  {
    Header: 'On Hold Description',
    accessor: 'onHoldDescription',
    width: 40,
  },
];
