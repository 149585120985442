import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { palette } from '../../../../theme/default';

const Wrapper = styled(Button)(({ theme, isActive }) => ({
  position: 'relative',
  padding: '0 24px',
  border: `1px solid ${theme.palette.additional.lines}`,
  borderRadius: '16px',
  textTransform: 'none',
  minHeight: '230px',
  justifyContent: 'flex-start',
  width: '326px',
  maxHeight: '265px',
  ...(isActive && { background: theme.palette.text.disabled }),

  '&:hover': {
    background: theme.palette.text.disabled,
  },
}));

const DashboardLegalAssistantCard = ({
  title,
  icon,
  count,
  isActive,
  onClick,
  isDisabled,
  base,
}) => {
  return (
    <Wrapper
      isActive={isActive}
      onClick={onClick}
      disableRipple
      disabled={isDisabled}
      data-testid="card_wrapper_btn"
    >
      <Stack justifyContent="space-evenly" height="100%">
        <Box display="flex">
          {icon}
          <Typography
            variant="h5"
            sx={{ textAlign: 'start' }}
            marginLeft={1}
            color={palette.text.primary}
            data-testid="card_title"
          >
            {title}
          </Typography>
        </Box>
        <Box color={palette.text.secondary} display="inline" textAlign="left">
          <Typography
            fontWeight={700}
            fontSize={32}
            component="span"
            paddingRight={1}
            data-testid="card_count"
          >
            {count}
          </Typography>
          <Typography component="span" variant="bodyL500" data-testid="card_base">
            {`${base}${count === 1 ? '' : 's'}`}
          </Typography>
        </Box>
      </Stack>
    </Wrapper>
  );
};

DashboardLegalAssistantCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  base: PropTypes.string.isRequired,
};

DashboardLegalAssistantCard.defaultProps = { icon: null, isActive: false, isDisabled: false };

export default DashboardLegalAssistantCard;
