import moment from 'moment';
import DeleteCell from './TableCells/DeleteCell';
import { DocumentsLinkCell } from './TableCells/DocumentLinkCell';

export const documentsColumns = [
  {
    Header: 'Document',
    accessor: 'name',
    Cell: DocumentsLinkCell,
    width: 65,
  },
  {
    Header: 'Valid From',
    accessor: 'validFrom',
    width: 30,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: '',
    accessor: 'control',
    Cell: DeleteCell,
    width: 5,
    minWidth: 60,
  },
];
