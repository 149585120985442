/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const getAddresses = createAsyncThunk('addresses/getAddresses', async (params = {}) => {
  const { data } = await apiClient.post('/api/addresses/filter', params);
  return data;
});

export const getStates = createAsyncThunk('addresses/states', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data;
});

const initialState = {
  addresses: {},
  states: [],
  error: null,
};

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {},
  extraReducers: {
    [getAddresses.fulfilled]: (state, action) => {
      state.addresses = action.payload.result;
    },
    [getAddresses.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.result.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.code,
      }));
    },
  },
});

export default addressesSlice.reducer;
