/* eslint-disable no-await-in-loop */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import moment from 'moment';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import { ClientBillingReportFormBoxes, stateCodesOptions } from './ClientBillingReport.constants';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import { palette } from '../../../../theme/default/palette';
import { ReactComponent as Cross } from '../../../../assets/icons/Cross.svg';
import {
  createClientBillingReport,
  getClientBillingReport,
} from '../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import useAuth from '../../../../hooks/useAuth';
import { rolesDB } from '../../../../utils/roleHelpers';
import Dropdown from '../../../atoms/Dropdown';
import notificationUtils from '../../../../utils/notificationUtils';
import ProgressModal from '../ProductivityByAttorney/ProgressModal';
import Table from '../../../atoms/Table/Table';
import ClientBillingNameCell, { entityTypes } from './cells/ClientBillingNameCell';
import './clientBillingName.css';
import RangeCell from './cells/RangeCell';
import { SORT_ORDER_DESC, useSortTable } from '../../../../hooks/useSortTable';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { useDateRanges } from '../../../../hooks/useDateRanges';

const AddIcon = styled(Add)({
  '& path': {
    stroke: palette.text.secondary,
  },
});

const emptyPeriod = { fromDate: null, toDate: null };

const ClientBillingReport = () => {
  const { userInfo } = useAuth();

  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();

  const [reportItems, setReportItems] = useState();
  const [currenState, setCurrentState] = useState(null);
  const [expandedMgs, setExpandedMgs] = useState({});
  const [ranges, setRanges] = useState([]);
  const { sortField, sortOrder, handleSortTable } = useSortTable('', SORT_ORDER_DESC);

  useEffect(() => {
    if (reportItems) {
      const rangesResult = reportItems?.map((range) => {
        const currentRange = `${moment(range.fromDate).format('L')} - ${moment(range.toDate).format(
          'L',
        )}`;
        return currentRange;
      });

      // to be sure that ranges are different
      // to avoid accessor error for columns
      setRanges([...new Set(rangesResult)]);
      handleSortTable(rangesResult[0]);
    }
  }, [reportItems]);

  const form = useForm({
    defaultValues: {
      periods: [emptyPeriod],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const periods = useFieldArray({
    control: form.control,
    name: 'periods',
  });

  const currentPeriods = form.watch('periods');

  const { dateRanges, getDateRanges } = useDateRanges({ currentPeriods });

  const onAddPeriod = () => periods.append(emptyPeriod);
  const onRemovePeriod = (index) => {
    periods.remove(index);
  };

  const handleSubmit = async (values) => {
    const data = {
      periods: values.periods,
      stateCode: values.stateCode.value,
    };
    setCurrentState(values.stateCode.value === 'AZ' ? 'Arizona' : 'Nevada');
    setIsLoader(true);

    try {
      const { reportId } = await dispatch(createClientBillingReport(data)).unwrap();
      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code },
        } = await dispatch(getClientBillingReport(reportId)).unwrap();
        if (code === 'Completed') {
          setReportItems(items);
          done = true;
        } else if (code === 'Error') {
          notificationUtils.error('Failed, try again later');
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      notificationUtils.error('Failed, try again later');
      setCurrentState(null);
    }
    setIsLoader(false);
  };

  const isAdmin = userInfo.roles.includes(rolesDB.Admin);

  const getOptions = (name) => {
    switch (name) {
      case 'stateCode':
        return isAdmin
          ? stateCodesOptions
          : stateCodesOptions.filter(({ value }) => userInfo.StateCode === value);
      default:
        return [];
    }
  };

  const handleChange = (args, index, cb) => {
    getDateRanges(args.target.name, index);
    cb(args);
  };

  const columns = useMemo(() => {
    const columnsBase = [
      {
        Header: (
          <Typography
            sx={{
              position: 'relative',
              top: '-18px',
              left: '24px',
              color: 'rgb(84, 87, 122)',
              fontWeight: 600,
              fontSize: '16px',
            }}
          >
            Name
          </Typography>
        ),
        accessor: 'name',
        // eslint-disable-next-line
        Cell: (props) => (
          <ClientBillingNameCell {...props} stateCode={form.getValues('stateCode')} />
        ),
      },
    ];

    if (ranges.length) {
      ranges.forEach((range) => {
        columnsBase.push({
          Header: <Typography sx={{ color: '#54577A', fontSize: '16px' }}>{range}</Typography>,
          accessor: range,
          Cell: RangeCell,
          isSortable: true,
          sortDescFirst: true,
        });
      });
    }
    return columnsBase;
  }, [ranges, reportItems]);

  const rowsAdapter = useMemo(() => {
    const values = (reportItems || []).reduce((acc, item, idx) => {
      item.managements.forEach((mgt) => {
        const existingMgt = acc.find((i) => i.name === mgt.managementName);
        const isManagementExpanded = expandedMgs[mgt.managementName] || false;
        const mgtValue = {
          name: mgt.managementName,
          expanded: isManagementExpanded,
          visible: true,
          type: entityTypes.MANAGEMENT,
          className: 'management',
          managementName: mgt.managementName,
          id: mgt.id,
          communitiesOrStandaloneAddresses: mgt.communitiesOrStandaloneAddresses,
          [ranges[idx]]: mgt.total || 0,
        };

        if (!existingMgt) {
          acc.push(mgtValue);
          if (!isManagementExpanded) return;
          mgt.communitiesOrStandaloneAddresses.forEach((com) => {
            const existingCom = acc.find((i) => i.name === com.name);
            let comValue = {
              name: com.name,
              collapsed: true,
              visible: false,
              type: !com.isStandaloneAddress
                ? entityTypes.COMMUNITY
                : entityTypes.STANDALONE_ADDRESSES,
              managementName: mgt.managementName,
              id: com.id,
            };
            comValue = { ...comValue, [ranges[idx]]: com.total || 0 };
            if (!existingCom) {
              acc.push(comValue);
            }
          });
        } else {
          existingMgt[ranges[idx]] = mgt.total || 0;
          existingMgt.communitiesOrStandaloneAddresses = mgtValue.communitiesOrStandaloneAddresses;
          existingMgt.communitiesOrStandaloneAddresses?.forEach((com) => {
            const existingCom = acc.find((i) => i.name === com.name);
            if (existingCom) {
              existingCom[ranges[idx]] = com.total || 0;
            }
          });
        }
      });
      return acc;
    }, []);

    let resultValues = [];

    const sortedMgms = values.slice(0).filter((item) => item.type === entityTypes.MANAGEMENT);
    sortedMgms.sort((a, b) => {
      return sortOrder === SORT_ORDER_DESC
        ? b[sortField] - a[sortField]
        : a[sortField] - b[sortField];
    });

    sortedMgms.forEach((mng) => {
      const communitiesByMng = values.filter(
        (item) =>
          item.type !== entityTypes.MANAGEMENT && item.managementName === mng.managementName,
      );
      resultValues.push(mng);
      communitiesByMng.sort((a, b) =>
        sortOrder === SORT_ORDER_DESC ? b[sortField] - a[sortField] : a[sortField] - b[sortField],
      );

      resultValues = resultValues.concat(communitiesByMng);
    });

    (reportItems || [])?.forEach((item, idx) => {
      resultValues.forEach((r) => {
        const val = r;
        val[ranges[idx]] = r[ranges[idx]] || 0;
        val?.communitiesOrStandaloneAddresses?.forEach((c) => {
          const com = c;
          com[ranges[idx]] = c[ranges[idx]] || 0;
        });
      });
    });

    return resultValues;
  }, [reportItems, expandedMgs, sortField, sortOrder, ranges]);

  const handleRowClick = (mg) => {
    setExpandedMgs((prev) => {
      return { ...prev, [mg.value]: !prev[mg.value] };
    });
  };

  return (
    <Stack direction="column" mb={10}>
      <ShadowBlock>
        {isLoader && <ProgressModal />}
        <Form form={form} onSubmit={handleSubmit}>
          {ClientBillingReportFormBoxes.map((i) =>
            i.name === 'periods' ? (
              periods.fields.map((period, index) => {
                return (
                  <Stack
                    direction="row"
                    alignItems="flex-end"
                    columnGap={3}
                    rowGap={2}
                    flexWrap="wrap"
                    py={1}
                    key={period.id}
                  >
                    {Object.keys(i.inputBoxes).map((key) => {
                      const item = i.inputBoxes[key];
                      const name = `periods[${index}].${item.name}`;
                      if (item.isDatePicker) {
                        return (
                          <Field
                            name={name}
                            isMandatory={item.isMandatory}
                            render={({ field, onCustomChange, error }) => (
                              <DateOrTimePicker
                                name={name}
                                isDate
                                error={error}
                                Svg={Calender}
                                label={item.label}
                                selected={field.value}
                                isMandatory={item.isMandatory}
                                width={item.width}
                                height={item.height}
                                placeholder={item.placeholder}
                                setSelectedTimeOrDate={onCustomChange((args) =>
                                  handleChange(args, index, field.onChange),
                                )}
                                minDate={dateRanges?.[name]?.min || null}
                                maxDate={dateRanges?.[name]?.max || moment()}
                                onClose={() => getDateRanges(name, index)}
                              />
                            )}
                          />
                        );
                      }
                      return null;
                    })}
                    {index === 0 ? (
                      <MButton
                        sx={{ marginBottom: '6px' }}
                        variant="secondary"
                        startIcon={<AddIcon />}
                        onClick={onAddPeriod}
                        disabled={form.getValues('periods')?.length >= 4}
                        data-testid="add_time"
                      >
                        Add Time Period
                      </MButton>
                    ) : (
                      <MButton
                        sx={{ marginBottom: '6px' }}
                        variant="borderLess"
                        startIcon={<Cross width="16px" />}
                        color="info"
                        onClick={() => onRemovePeriod(index)}
                        data-testid="remove_time"
                      >
                        Remove
                      </MButton>
                    )}
                  </Stack>
                );
              })
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                columnGap={3}
                rowGap={2}
                flexWrap="wrap"
                py={1}
              >
                {i.inputBoxes.map((item) => {
                  if (item.isDropdown) {
                    return (
                      <Field
                        key={item.name}
                        name={item.name}
                        isMandatory={item.isMandatory}
                        render={({ field, onCustomChange }) => (
                          <Dropdown
                            value={field.value}
                            isAddDisabled={false}
                            isSearchable
                            label={item.label}
                            width={item.width}
                            isColumn
                            placeholder={item.placeholder}
                            options={getOptions(item.name)}
                            onChange={onCustomChange(field.onChange)}
                            isMandatory={item.isMandatory}
                          />
                        )}
                      />
                    );
                  }
                  return null;
                })}
              </Stack>
            ),
          )}
          <Stack direction="row" mt={2}>
            <MButton
              data-testid="run_report_btn"
              type="submit"
              size="medium"
              disabled={isLoader || !form.formState.isValid}
            >
              Run Report
            </MButton>
          </Stack>
        </Form>
      </ShadowBlock>
      {reportItems && (
        <Box mt={3}>
          <ShadowBlock customStyles={{ gap: 0 }}>
            <Typography variant="h5" mt={4} mb={4} data-testid="report_title">
              Client Billing Report for {currenState}
            </Typography>
            {rowsAdapter?.length && !isLoader ? (
              <>
                <Stack
                  direction="row"
                  sx={{ backgroundColor: '#ebedfa', height: '40px' }}
                  flex
                  justifyContent="space-between"
                >
                  <Box flexGrow={1} sx={{ marginLeft: ranges?.length > 1 ? 0 : '105px' }} />
                  <Typography
                    flexGrow={ranges?.length || 1}
                    mt={1}
                    paragraph
                    sx={{ color: 'rgb(84, 87, 122)', fontWeight: 600, fontSize: '16px' }}
                    textAlign={ranges?.length > 1 ? 'center' : 'left'}
                  >
                    Billing Hours
                  </Typography>
                </Stack>
                <Table
                  columns={columns}
                  rows={rowsAdapter}
                  onRowClick={handleRowClick}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSortTable={handleSortTable}
                />
              </>
            ) : (
              <Box
                display="flex"
                width="1140px"
                flexDirection="column"
                alignItems="center"
                mt={12}
                mb={25}
                mx="auto"
              >
                <EmptyBlock
                  title="No Records"
                  desc="Please change filters to display report"
                  descFontSize={18}
                />
              </Box>
            )}
          </ShadowBlock>
        </Box>
      )}
    </Stack>
  );
};

export default ClientBillingReport;
