/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  CASES_TO_BE_ENTERED,
  FOLLOW_UPS,
  NOTICES_TO_BE_DRAFTED,
  PREFILES_TO_BE_PRINTED,
} from '../../components/pages/Dashboard/DashboardLegalAssistant/DashboardLegalAssistantLists/DashboardLegalAssistantLists.constants';
import { apiClient } from '../../lib/apiClient';
import { convertToQueryString } from '../../utils/convertToQueryString';
import {
  convertPeriodFromSettingsToBoolean,
  convertPeriodNameToSettingsName,
} from '../../components/pages/Dashboard/DashboardLegalAssistant/DashboardLegalAssistantLists/DashboardLegalAssistantList';

export const getMetricsForLegalAssistant = createAsyncThunk(
  'legalAssistantDashboard/getMetricsForLegalAssistant',
  // eslint-disable-next-line
  async ({ period, stateCode }) => {
    const { data } = await apiClient.get(
      `/api/dashboards/legalAssistant/charts/metrics?${convertToQueryString({
        period,
        stateCode,
      })}`,
    );
    return data;
  },
);

export const getDashboardStats = createAsyncThunk(
  'legalAssistantDashboard/getDashboardStats',
  async ({ stateCode, cards, userId }, { getState }) => {
    let { settings } = getState().legalAssistantDashboard;

    if (isEmpty(settings)) {
      const { data } = await apiClient.get(`/api/userSettings/${userId}`);
      settings = data.result;
    }

    const settingsForCards =
      stateCode === 'AZ' ? settings?.arizonaLegalAssistant : settings?.nevadaLegalAssistant;

    const data = await Promise.all(
      cards.map((card) => {
        const currentType = convertPeriodNameToSettingsName(card.id);

        const currentPeriod = settingsForCards?.[currentType];

        const convertedSettings = convertPeriodFromSettingsToBoolean(currentPeriod, currentType);

        const onlyMine = currentPeriod
          ? convertedSettings === true
          : [FOLLOW_UPS, PREFILES_TO_BE_PRINTED, CASES_TO_BE_ENTERED].includes(card.id);
        return apiClient
          .get(
            `/api/dashboards/legalAssistant/cards/${card.id}?${convertToQueryString({
              onlyTotal: true,
              stateCode,
              ...(onlyMine && {
                onlyMine: true,
              }),
              ...(convertedSettings === 'unassigned' && { unassigned: true }),
            })}`,
          )
          .then((res) => res?.data?.result?.totalRowsCount);
      }),
    );
    return cards.reduce((acc, card, index) => {
      acc[card.id] = data[index] || 0;
      return acc;
    }, {});
  },
);

export const getDashboardLegalAssistantList = createAsyncThunk(
  'legalAssistantDashboard/getDashboardLegalAssistantList',
  async ({ api, ...params }) => {
    const {
      data: { result },
    } = await apiClient.get(
      `/api/dashboards/legalAssistant/cards/${api}?${convertToQueryString(params)}`,
    );
    return { api, result };
  },
);

export const getSettings = createAsyncThunk(
  'legalAssistantDashboard/getSettings',
  async (userId) => {
    const { data } = await apiClient.get(`/api/userSettings/${userId}`);

    return data;
  },
);

export const updateSettings = createAsyncThunk(
  'legalAssistantDashboard/updateSettings',
  async ({ values, userId }) => {
    const { data } = await apiClient.put(`/api/userSettings/dashboard/${userId}`, {
      ...values,
      userId,
    });
    return data;
  },
);

const initialState = {
  chart: { forLabels: [], forDatasets: {} },
  stats: {
    [NOTICES_TO_BE_DRAFTED]: 0,
    [CASES_TO_BE_ENTERED]: 0,
    [PREFILES_TO_BE_PRINTED]: 0,
    [FOLLOW_UPS]: 0,
  },
  lists: {
    [NOTICES_TO_BE_DRAFTED]: {},
    [CASES_TO_BE_ENTERED]: {},
    [PREFILES_TO_BE_PRINTED]: {},
    [FOLLOW_UPS]: {},
  },
  settings: {},
};

export const legalAssistantDashboardSlice = createSlice({
  name: 'legalAssistantDashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getMetricsForLegalAssistant.fulfilled]: (state, action) => {
      state.chart = action.payload.result.reduce(
        (acc, period) => {
          acc.forLabels.push({ fromDate: period.fromDate, toDate: period.toDate });
          period.statuses.forEach(({ status, count }) => {
            if (!acc.forDatasets[status]) acc.forDatasets[status] = [];
            acc.forDatasets[status].push(count);
          });

          return acc;
        },
        {
          forLabels: [],
          forDatasets: {},
        },
      );
    },
    [getSettings.fulfilled]: (state, action) => {
      state.settings = action.payload.result;
    },
    [getDashboardStats.fulfilled]: (state, action) => {
      state.stats = {
        ...state.stats,
        ...action.payload,
      };
    },
    [getDashboardLegalAssistantList.fulfilled]: (state, action) => {
      state.lists[action.payload.api] = action.payload.result;
      state.stats[action.payload.api] = action.payload.result.totalRowsCount;
    },
  },
});

export default legalAssistantDashboardSlice.reducer;
