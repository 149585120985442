import { Box, Stack, Typography, styled } from '@mui/material';
import { useState } from 'react';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import DashboardAttorneyCalendar from './DashboardAttorneyCalendar';
import Dropdown from '../../../atoms/Dropdown';
import { useAvailableStatesList } from '../../../../hooks/useAvailableStatesList';
import DashboardAttorneyLists from './DashboardAttorneyLists';
import { usePrimaryState } from '../../../../hooks/usePrimaryState';

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: '36px 32px',
  border: `1px solid ${theme.palette.shadow.boxContrast}`,
  borderRadius: '16px',
  boxShadow: `0 3px 20px ${theme.palette.shadow.boxContrast}`,
  marginBottom: '120px',
}));

const StyledHeading = styled(Typography)({
  textAlign: 'justify',
});

const DashboardAttorney = () => {
  useSafeLocationState('Dashboard', 'Attorney');
  const states = useAvailableStatesList();
  const primaryState = usePrimaryState();
  const [state, setState] = useState(primaryState || states[0]);

  const handleChangeState = (val) => {
    setState(val);
  };

  return (
    <Stack padding="0 32px 32px" rowGap={4}>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <StyledHeading variant="h3" data-testid="search_container_label">
          Attorney
        </StyledHeading>
        <Dropdown
          label="Select state"
          options={states}
          onChange={handleChangeState}
          placeholder={states.length > 0 ? state.label : 'State'}
          isSearchable={false}
          value={state}
        />
      </Box>
      <DashboardAttorneyCalendar stateCode={state.value} />
      <Wrapper>
        <DashboardAttorneyLists state={state} />
      </Wrapper>
    </Stack>
  );
};

export default DashboardAttorney;
