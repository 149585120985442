import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
  PartiesAndPrecinctBoxesNonEditable,
  DEFENDANTS_COLUMN_NONEDITABLE,
  ENTITIES_COLUMNS_NON_EDITABLE,
} from './PartiesAndPrecinct.constants';
import {
  getPartiesAndPrecinctArizona,
  getPartiesAndPrecinctNevada,
} from '../../../../store/slices/cases/caseCpSlice';
import Table from '../../../atoms/Table/Table';
import ShadowBlock from '../../../atoms/ShadowBlock';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import MultilineTextField from '../../../atoms/MultilineTextField/MultilineTextField';

const PartiesAndPrecinctNonEditable = () => {
  const dispatch = useDispatch();

  const { state: locationState, pathname } = useLocation();
  const { caseType } = locationState;
  const navigate = useNavigate();

  const { id, stateCode } = locationState;

  const [isLoader, setIsLoader] = useState(false);

  const { partiesAndPrecinctArizona, partiesAndPrecinctNevada } = useSelector(
    (state) => state.caseCp,
  );

  const { partiesAndPrecinct, getPartiesAndPrecinct } = useMemo(() => {
    if (stateCode === 'NV') {
      return {
        partiesAndPrecinct: partiesAndPrecinctNevada,
        getPartiesAndPrecinct: getPartiesAndPrecinctNevada,
      };
    }
    if (stateCode === 'AZ') {
      return {
        partiesAndPrecinct: partiesAndPrecinctArizona,
        getPartiesAndPrecinct: getPartiesAndPrecinctArizona,
      };
    }
    return {
      partiesAndPrecinct: null,
      getPartiesAndPrecinct: null,
    };
  }, [partiesAndPrecinctArizona, partiesAndPrecinctNevada]);

  const { individuals = [], entities = [] } = partiesAndPrecinct ?? {};

  const opposingPartyLength = individuals.length + entities.length;

  const firstInvididual = individuals?.[0];
  const firstEntity = entities?.[0];

  const navigationTitle = useMemo(() => {
    const getFirstOfOppositeParty = () => {
      if (firstInvididual) {
        const { firstName, middleName, lastName, suffix } = firstInvididual;
        return [firstName, middleName, lastName, suffix].filter((x) => x).join(' ');
      }
      if (firstEntity) return firstEntity.entityName;
      return '';
    };
    const firstOfOppositeParty = getFirstOfOppositeParty();
    return opposingPartyLength > 1 ? `${firstOfOppositeParty}, Et Al.` : firstOfOppositeParty;
  }, [firstInvididual, firstEntity, opposingPartyLength]);

  useEffect(() => {
    if (navigationTitle) {
      navigate(pathname, {
        state: {
          ...locationState,
          defendantName: navigationTitle,
        },
        replace: true,
      });
    }
  }, [navigationTitle]);

  useEffect(() => {
    setIsLoader(true);
    dispatch(getPartiesAndPrecinct(id)).then(() => {
      setIsLoader(false);
    });
  }, [id]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1900,
      }}
    >
      {isLoader && (
        <Box position="absolute" top="50%" left="50%" zIndex={10} transform="translate(-50%, -50%)">
          <CircularProgress />
        </Box>
      )}
      <ShadowBlock variant="dropdownCard">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Opposing Party</Typography>
        </Stack>
        {Boolean(partiesAndPrecinct?.individuals?.length) && (
          <Stack rowGap={2}>
            <Typography variant="bodyL500">Individual(s)</Typography>
            <Table columns={DEFENDANTS_COLUMN_NONEDITABLE} rows={partiesAndPrecinct.individuals} />
          </Stack>
        )}
        {Boolean(partiesAndPrecinct?.entities?.length) && (
          <Stack rowGap={2}>
            <Typography variant="bodyL500">Entity</Typography>
            <Table columns={ENTITIES_COLUMNS_NON_EDITABLE} rows={partiesAndPrecinct.entities} />
          </Stack>
        )}
      </ShadowBlock>
      {PartiesAndPrecinctBoxesNonEditable.map((item) => (
        <Container
          key={item.boxTitle}
          maxWidth={false}
          disableGutters
          sx={{
            mx: 'auto',
            mt: 4,
            width: 1,
          }}
        >
          <ShadowBlock variant="dropdownCard">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h5">{item.boxTitle}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" columnGap={2} rowGap={2} flexWrap="wrap">
              {item?.inputBoxes &&
                (typeof item.inputBoxes === 'function'
                  ? item.inputBoxes(stateCode)
                  : item.inputBoxes
                ).map((field) => {
                  if (field?.isSpace) {
                    return <div style={{ width: '100%' }} key={item.label} />;
                  }

                  if (field.isText) {
                    return (
                      <EditInputWithLabel
                        isDisabled
                        key={field.label}
                        type="text"
                        label={field.label}
                        name={field.name}
                        value={_.get(partiesAndPrecinct, field.name)}
                        placeholder={field.placeholder}
                        width={field.width}
                      />
                    );
                  }

                  if (field.isBoolean) {
                    if (field.caseTypes && !field.caseTypes.includes(caseType)) {
                      return null;
                    }
                    return (
                      <EditInputWithLabel
                        isDisabled
                        key={field.label}
                        type="text"
                        height="56px"
                        label={field.label}
                        name={field.name}
                        value={_.get(partiesAndPrecinct, field.name) ? 'Yes' : 'No'}
                        placeholder={field.placeholder}
                        width={field.width}
                      />
                    );
                  }

                  if (field.isMultiline) {
                    return (
                      <MultilineTextField
                        label={field.label}
                        placeholder={field.placeholder}
                        width={field.width}
                        name={field.name}
                        rows={2}
                        value={_.get(partiesAndPrecinct, field.name)}
                        disabled
                      />
                    );
                  }

                  if (field?.isLink) {
                    const linkValue = _.get(partiesAndPrecinct, field.name);

                    return (
                      <EditInputWithLabel
                        isDisabled
                        key={field.label}
                        type="text"
                        height="56px"
                        label={field.label}
                        name={field.name}
                        value={linkValue || '-'}
                        placeholder={field.placeholder}
                        width={field.width}
                      />
                    );
                  }
                  return null;
                })}
            </Stack>
          </ShadowBlock>
        </Container>
      ))}
    </Container>
  );
};

export default PartiesAndPrecinctNonEditable;
