import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ContactContext from '../context/ContactContext';

export const defaultState = {
  name: '',
  title: '',
  email: '',
  workPhone: '',
  mobilePhone: '',
  isEmailUpdatesEnabled: false,
};

const ContactProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const defaultContext = useMemo(
    () => ({
      state,
      actions: {
        update: (form) => setState(() => ({ ...state, ...form })),
        clear: () => setState(defaultState),
      },
    }),
    [state],
  );

  return <ContactContext.Provider value={defaultContext}>{children}</ContactContext.Provider>;
};

ContactProvider.propTypes = {
  children: PropTypes.element,
};

ContactProvider.defaultProps = {
  children: null,
};

export default ContactProvider;
