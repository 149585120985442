import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';
import { ActiveTextInputCell } from '../../../atoms/Table/TableCells/ActiveTextInputCell';
import { DocumentsLinkCell } from './DocumentsLinkCell';
import { DocumentsTypeCell } from '../../../atoms/Table/TableCells/DocumentsTypeCell';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const documentStrings = {
  title: 'Documents',
  boxTitle: 'Upload document',
  upload: {
    title: 'Upload Document',
    text: 'Drop your documents here,',
    action: 'or select',
    additionalInfo: 'Supports PDF, JPEG, PNG, TIFF, DOCS, DOC, Excel files up to 20MB',
    add: 'Add document',
    cancel: 'Cancel',
    submit: 'Upload',
  },
  button: {
    print: 'Print selected',
    delete: 'Delete selected',
    create: 'Create new document',
    upload: 'Upload Document',
    update: 'Update case',
    cancel: 'Cancel',
  },
};

export const uploadDocumentsColumns = [
  {
    Header: 'Document name',
    accessor: 'name',
    width: 47,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 47,
    Cell: ActiveTextInputCell,
    edit: true,
  },
  {
    Header: '',
    width: 6,
    accessor: 'control',
    Cell: DeleteCell,
  },
];

// TODO remove if documentsSlice will be removed
export const documentsColumnsDB = [
  {
    Header: 'Document name',
    accessor: 'documentName',
    width: 20,
    Cell: DocumentsLinkCell,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 15,
    edit: true,
    Cell: DocumentsTypeCell,
  },
  {
    Header: 'Upload Date',
    accessor: 'uploadDate',
    width: 25,
  },
  {
    Header: 'Upload Time',
    accessor: 'uploadTime',
    width: 20,
  },
  {
    Header: 'Uploaded By',
    accessor: 'uploadedBy',
    width: 20,
  },
];
