import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Accounting from '../../../components/pages/Accounting';
import InvoiceGeneration from '../../../components/pages/Accounting/InvoiceGeneration';
import PrintChecks from '../../../components/pages/Accounting/PrintChecks';
import BillingInstances from '../../../components/pages/Accounting/BillingInstances/BillingInstances';

const DataBaseAccountingRoutes = () => (
  <Routes>
    <Route path="/" element={<Accounting />}>
      <Route path="invoicegeneration" element={<InvoiceGeneration />} />
      <Route path="printchecks" element={<PrintChecks />} />
      <Route path="billingInstances" element={<BillingInstances />} />
    </Route>
  </Routes>
);

export default DataBaseAccountingRoutes;
