/* eslint-disable */
import React from 'react';
import Box from '@mui/material/Box';
import MInputBase from '../MInputBase';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const MBaseInputWithIcon = (props) => {
  const {
    placeholder,
    width,
    svg,
    value,
    onChange,
    name,
    type = 'text',
    control,
    regConfig,
    error,
    controlled = false,
  } = props;

  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  return !controlled ? (
    <Controller
      control={control}
      name={name}
      rules={regConfig}
      render={({ field: { onChange } }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderColor: error && error.length > 0 ? 'additional.alert' : 'additional.lines',
            borderRadius: '10px',
            p: 1.5,
            width: { width },
          }}
          data-testid="input_wrapper"
        >
          <Box sx={{ mr: 1.2, display: 'flex', alignItems: 'center' }} data-testid="icon">
            {svg}
          </Box>
          <MInputBase
            inputProps={{ 'data-testid': 'input' }}
            type={type}
            onChange={onChange}
            placeholder={placeholder}
            isNoBorder
          />
        </Box>
      )}
    />
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: error && error.length > 0 ? 'additional.alert' : 'additional.lines',
        borderRadius: '10px',
        p: 1.5,
        width: { width },
      }}
      data-testid="controlled_input_wrapper"
    >
      <Box sx={{ mr: 1.2 }} data-testid="icon">
        {svg}
      </Box>
      <MInputBase
        type={type}
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
        isNoBorder
        inputProps={{ 'data-testid': 'input' }}
      />
    </Box>
  );
};

MBaseInputWithIcon.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.number,
  svg: PropTypes.element,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  control: PropTypes.object,
  regConfig: PropTypes.object,
  error: PropTypes.string,
  controlled: PropTypes.bool,
};

export default MBaseInputWithIcon;
