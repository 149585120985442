import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';

import SubPageHeading from '../../organisms/SubPageHeading';
import Page from '../../organisms/Page/Page';
import EditInputWithLabel from '../../molecules/EditInputWithLabel';
import MultilineTextField from '../../atoms/MultilineTextField/MultilineTextField';
import MButton from '../../MUI/Button/MButton';
import Table from '../../atoms/Table/Table';
import { historyColumns } from './LegalForms.constants';
import { ReactComponent as Upload } from '../../../assets/icons/document-upload-white.svg';
import UploadVersionDialog from './UploadVersionDialog';
import { apiClient } from '../../../lib/apiClient';
import notificationUtils from '../../../utils/notificationUtils';
import { useBaseUrl } from '../../../hooks/useBaseUrl';
import LoaderCover from '../../atoms/LoaderCover';

const ViewLegalFormHistory = () => {
  const {
    state: { subNav, subSubNavigation, templateGroupNumber },
  } = useLocation();
  const base = useBaseUrl();

  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [versions, setVersions] = useState(null);

  const fetcher = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`/cp/api/legalForms/${templateGroupNumber}/history`);
      setVersions(data.result.legalFormDocumentDtos);
      setDescription(data.result.description);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetcher();
  }, []);

  const handleChangeDescription = useCallback((ev) => {
    setDescription(ev.target.value);
  }, []);

  const handleUpdate = async () => {
    try {
      await apiClient.put(`/cp/api/legalForms`, {
        templateGroupNumber,
        description,
      });
      notificationUtils.success('Updated successfully');
      fetcher();
    } catch {
      notificationUtils.error('Failed, try again later');
    }
  };

  const handleUpload = () => {
    setUploadDialogOpen(true);
  };

  const handleClose = useCallback((isSubmit) => {
    setUploadDialogOpen();
    if (isSubmit) {
      fetcher();
    }
  }, []);

  const rows = useMemo(() => {
    return versions || [];
  }, [versions]);

  const columns = useMemo(() => {
    return historyColumns;
  }, []);
  return (
    <Page navPage="Legal Forms" subNavigation={subNav} subSubNavigation={subSubNavigation}>
      {uploadDialogOpen && (
        <UploadVersionDialog
          isOpen={uploadDialogOpen}
          onClose={handleClose}
          templateGroupNumber={templateGroupNumber}
        />
      )}
      <SubPageHeading
        subPageHeading={subSubNavigation}
        nav={{
          to: `/${base}/legalforms`,
          state: {
            navPage: 'Legal Forms',
          },
        }}
      />
      <Stack height="100%" margin="0 32px" direction="column">
        {isLoading && <LoaderCover />}
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3">Legal Form History</Typography>
          <Box>
            <MButton
              sx={{
                width: '186px',
                fontSize: '16px',
                position: 'relative',
                height: '48px',
              }}
              startIcon={<Upload />}
              onClick={handleUpload}
            >
              Upload version
            </MButton>
          </Box>
        </Stack>

        <Stack mt={2} spacing={4} direction="row">
          <EditInputWithLabel label="State" value="AZ" isDisabled />
          <MultilineTextField
            label="Description"
            width="100%"
            onChange={handleChangeDescription}
            value={description}
            maxLength={500}
            rows={1}
          />
          <Box>
            <MButton
              sx={{
                width: '105px',
                fontSize: '16px',
                position: 'relative',
                height: '48px',
                top: '26px',
              }}
              onClick={handleUpdate}
            >
              Update
            </MButton>
          </Box>
        </Stack>
        <Box mt={4}>
          <Table columns={columns} rows={rows} onRefreshPage={fetcher} />
        </Box>
      </Stack>
    </Page>
  );
};

export default React.memo(ViewLegalFormHistory);
