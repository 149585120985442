export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: '#DFDCFF',
      fontWeight: 500,
      textShadow: '0px 0px 1px rgba(0, 0, 0, 0.2)',
    },
    children: name ? `${name.split(' ')[0][0]}${name.split(' ')[1]?.[0] || ''}` : '',
  };
}
