import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { palette } from '../../../../../theme/default';

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      titleFont: {
        size: 0,
      },
      titleMarginBottom: 0,
      bodyFont: {
        size: 16,
        weight: 600,
        family: 'Plus Jakarta Sans',
      },
      footerFont: {
        size: 14,
        family: 'Plus Jakarta Sans',
      },
      callbacks: {
        label(context) {
          const labelMap = {
            'Eviction Prepared': 'case(s) entered',
            'Notice Drafted': 'notice(s) drafted',
          };
          const labelParts = context.dataset.label.toLowerCase().split(' ');
          labelParts[0] += '(s)';
          const label = ` ${context.parsed.y} ${
            labelMap[context.dataset.label] || labelParts.join(' ')
          }`;
          return label;
        },
      },
    },
  },
  layout: {},
  datasets: {
    line: {
      tension: 0.5,
      borderWidth: 3,
    },
  },
  interaction: {
    intersect: false,
  },
  elements: {
    line: {},
    point: {
      pointRadius: 0,
      hoverRadius: 8,
      borderColor: palette.statuses.blue,
      backgroundColor: palette.statuses.blue,
    },
  },
  scales: {
    x: {
      offset: true,
      grid: {
        display: false,
      },
      ticks: {
        padding: 5,
        color: palette.text.secondary,
        font: {
          size: 14,
          family: 'Plus Jakarta Sans',
        },
      },
    },
    y: {
      suggestedMin: 0,
      suggestedMax: 60,
      grid: {
        drawBorder: false,
        lineWidth: 1,
        drawTicks: false,
        color: palette.additional.lines,
      },
      border: {
        display: false,
        dash: [5, 3],
      },
      ticks: {
        padding: 20,
        color: palette.text.secondary,
        font: {
          size: 14,
          family: 'Plus Jakarta Sans',
        },
      },
    },
  },
};

const colors = [palette.buttonPrimary.active, palette.statuses.orange];

export const useLegalAssitantDashboardLineChart = ({ period }) => {
  const {
    chart: { forLabels, forDatasets },
  } = useSelector((state) => state.legalAssistantDashboard);
  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    if (Object.keys(forDatasets).length !== 0 && forLabels.length) {
      const labels = forLabels.map((ld) => {
        if (period === '6M' || period === '1Y') {
          return moment(ld.fromDate).format('MMM');
        }
        if (period === '1M') {
          const getDayOfMonth = (x) => moment(x).format('Do');
          return `${getDayOfMonth(ld.fromDate)} - ${getDayOfMonth(ld.toDate)}`;
        }
        return moment(ld.fromDate).format('dddd');
      });
      const datasets = Object.keys(forDatasets).map((key, index) => ({
        data: forDatasets[key],
        borderColor: colors[index] || colors[0],
        backgroundColor: colors[index] || colors[0],
        label: key,
      }));
      setData({ labels, datasets });
    }
  }, [forLabels, forDatasets]);

  return {
    data,
    options: chartOptions,
    plugins: [
      {
        beforeDraw: (chartInstance) => {
          /* eslint-disable */
          if (chartInstance.tooltip?._active?.length) {
            const { x } = chartInstance.tooltip._active[0].element;
            const yAxis = chartInstance.scales.y;
            const { ctx } = chartInstance;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.setLineDash([5, 3]);
            ctx.lineWidth = 1;
            ctx.strokeStyle = palette.statuses.blue;
            ctx.stroke();
            ctx.restore();
          }
          /* eslint-enable */
        },
      },
    ],
  };
};
