import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import CommunityContext from '../context/CommunityContext';

export const defaultState = {
  communityName: '',
  communityCode: '',
  managementCompanyName: '',
  hasGovernmentBackedLoan: true,
  ownerEntities: '',
  contactInfo: {
    stateCode: '',
    city: '',
    address: '',
    zipCode: '',
    phone: '',
    fax: null,
  },
  invoicing: {
    isDeactivated: false,
    isOnHold: false,
    onHoldDescription: null,
    isAutoInvoicing: false,
    isEmailInvoice: true,
    invoiceFrequency: 'Monthly',
  },
  secondaryKeys: {
    customerRefListId: '',
    customerRefFullName: '',
  },
  contacts: [],
};

const CommunityProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const defaultContext = useMemo(
    () => ({
      state,
      actions: {
        update: (form) => setState(() => ({ ...state, ...form })),
        clear: () => setState(defaultState),
      },
    }),
    [state],
  );

  return <CommunityContext.Provider value={defaultContext}>{children}</CommunityContext.Provider>;
};

CommunityProvider.propTypes = {
  children: PropTypes.element,
};

CommunityProvider.defaultProps = {
  children: null,
};

export default CommunityProvider;
