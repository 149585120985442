import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Tag from '../../../atoms/Tag';
import { ALL_STATUSES } from '../../../../global/constants';
import { palette } from '../../../../theme/default';

export const DateCell = ({ date }) => {
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
        color: palette.text.primary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {moment(date).format('L')}
    </Typography>
  );
};

DateCell.propTypes = {
  date: PropTypes.string.isRequired,
};

export const StatusCell = ({ value: status }) => {
  const backgroundColor = useMemo(() => {
    if (!status) return palette.statuses.transparent;
    const statusOption = ALL_STATUSES.find((o) => o.value === status);
    if (statusOption) return statusOption.color;
    return palette.statuses.transparent;
  }, [status]);

  return <Tag backgroundColor={backgroundColor} text={status} />;
};

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const DefendantNameCell = ({ value }) => {
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
        color: palette.text.primary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {value}
    </Typography>
  );
};

DefendantNameCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const ReferenceNumberCell = ({ value, row }) => {
  const to = `/db/processing/case/${row.original.caseId}`;

  return (
    <Link to={to}>
      <Typography
        sx={{ cursor: 'pointer' }}
        textTransform="none"
        variant="link"
        color={palette.text.primary}
      >
        {value}
      </Typography>
    </Link>
  );
};

ReferenceNumberCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      defendantName: PropTypes.arrayOf(PropTypes.string),
      referenceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      stateCode: PropTypes.string,
      type: PropTypes.string,
      matterType: PropTypes.string,
    }),
  }).isRequired,
};
