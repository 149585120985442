import React, { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../Dropdown';

export const DropdownCell = ({
  value: initialValue,
  changeValueCell,
  editableRowIndex,
  setEditableRowIndex,
  setEditableRow,
  column,
  row,
  updateData,
}) => {
  const [value, setValue] = useState(null);
  const isEditingCell = editableRowIndex === row.index;

  const handleChange = useCallback((nextValue) => {
    setValue(nextValue);
    changeValueCell(column.id, nextValue || null);
  }, []);

  useLayoutEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue, isEditingCell]);

  const dropdownOptions = Array.isArray(column.options)
    ? column.options
    : row.original[column?.options];

  const handleBlur = () => {
    if (column?.saveOnBlur) {
      updateData();
      setEditableRowIndex(null);
      setEditableRow({});
    }
  };

  if (column.edit && isEditingCell) {
    return (
      <Dropdown
        isColumn
        width="100%"
        onChange={handleChange}
        height={32}
        onBlur={handleBlur}
        options={dropdownOptions || []}
        error={column.isMandatory ? !value : false}
      />
    );
  }

  return initialValue;
};

DropdownCell.propTypes = {
  value: PropTypes.string.isRequired,
  changeValueCell: PropTypes.func.isRequired,
  setEditableRowIndex: PropTypes.func.isRequired,
  setEditableRow: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  editableRowIndex: PropTypes.number.isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
    edit: PropTypes.bool,
    isMandatory: PropTypes.bool,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    saveOnBlur: PropTypes.bool,
  }).isRequired,
  row: PropTypes.shape({ index: PropTypes.number, original: PropTypes.shape({}) }).isRequired,
};
