import { DateCell, DefendantCell, ReferenceNumberCell } from './CaseSearchCells';
import CaseStatus from '../../../molecules/CaseStatus';

export const caseSearch = {
  label: 'Case Search',
  dropdownLabel: 'Select state',
  options: [
    { value: 'AZ', label: 'AZ' },
    { value: 'NV', label: 'NV' },
  ],
  buttonNewEvictionMatterLabel: 'New Eviction Matter',
  buttonNewCivilMatterLabel: 'New Civil Matter',
  bulkCaseCreationLabel: 'Bulk Case Creation',
};

export const tableColumns = {
  cp: [
    {
      Header: 'Reference #',
      accessor: 'referenceNumber',
      Cell: ReferenceNumberCell,
    },
    {
      Header: 'Case #',
      accessor: 'caseNumber',
    },
    {
      Header: 'Community Name',
      accessor: 'communityName',
    },
    {
      Header: 'Opposing Party Name',
      accessor: 'opposingPartyName',
      Cell: DefendantCell,
    },
    {
      Header: 'Apartment',
      accessor: 'apartmentNumber',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: CaseStatus,
    },
    {
      Header: 'Category',
      accessor: 'currentActivityCategory',
    },
    {
      Header: 'Creation Date',
      accessor: 'creationDate',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated Date',
      accessor: 'lastUpdatedDate',
      Cell: DateCell,
    },
  ],
  db: [
    {
      Header: 'Reference #',
      accessor: 'referenceNumber',
      Cell: ReferenceNumberCell,
    },
    {
      Header: 'Case #',
      accessor: 'caseNumber',
    },
    {
      Header: 'Community Name',
      accessor: 'communityName',
    },
    {
      Header: 'Opposing Party Name',
      accessor: 'opposingPartyName',
      Cell: DefendantCell,
    },
    {
      Header: 'Apartment #',
      accessor: 'apartmentNumber',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: CaseStatus,
    },
    {
      Header: 'Category',
      accessor: 'currentActivityCategory',
    },
    {
      Header: 'Creation Date',
      accessor: 'creationDate',
      Cell: DateCell,
    },
    {
      Header: 'Last Updated Date',
      accessor: 'lastUpdatedDate',
      Cell: DateCell,
    },
  ],
};

export const searchFields = {
  cp: () => [
    {
      label: 'Reference #',
      name: 'referenceNumber',
      placeholder: 'xxxxxxxxx',
    },
    {
      label: 'Case #',
      name: 'caseNumber',
      placeholder: 'xxxxxxxxx',
    },
    {
      label: 'Apartment #',
      name: 'apartmentNumber',
      placeholder: 'xxxxxxxxx',
    },
    {
      label: 'Opposing Party',
      name: 'opposingPartyFullName',
      placeholder: 'Enter opposing party name',
      width: '258px',
      isDropdown: true,
    },
    {
      label: 'Status',
      name: 'status',
      placeholder: 'Select status',
      width: '154px',
      isDropdown: true,
    },
    {
      label: 'Category',
      name: 'categories',
      placeholder: 'Select category',
      width: '198px',
      height: 44,
      isCheckboxSelect: true,
    },
    {
      label: 'Community',
      name: 'communityId',
      placeholder: 'Enter community name',
      width: '240px',
      isDropdown: true,
    },
    {
      label: 'Created From',
      name: 'createdFrom',
      dateFormat: 'MM/DD/YY',
      isDate: true,
    },
    {
      label: 'Created To',
      name: 'createdTo',
      dateFormat: 'MM/DD/YY',
      isDate: true,
    },
    {
      label: 'Show Archived Cases',
      name: 'showArchived',
      isCheckbox: true,
    },
  ],
  db: (stateCode = 'AZ') => [
    {
      label: 'Reference #',
      name: 'referenceNumber',
      placeholder: 'xxxxxxxxx',
    },
    {
      label: 'Case #',
      name: 'caseNumber',
      placeholder: 'xxxxxxxxx',
    },
    {
      label: 'Opposing Party',
      name: 'opposingPartyFullName',
      placeholder: 'Enter opposing party name',
      width: '258px',
      isDropdown: true,
    },
    {
      label: 'Property Address',
      name: 'addressId',
      placeholder: 'Enter property address',
      width: '248px',
      isDropdown: true,
    },
    {
      label: 'Apartment #',
      name: 'apartmentNumber',
      placeholder: 'xxxxxxxxx',
    },
    {
      label: 'Community',
      name: 'communityId',
      placeholder: 'Enter community name',
      width: '240px',
      isDropdown: true,
    },
    {
      label: 'Management Company',
      name: 'managementId',
      placeholder: 'Select company',
      width: '228px',
      isDropdown: true,
    },
    {
      label: 'Status',
      name: 'status',
      placeholder: 'Select status',
      width: '228px',
      isDropdown: true,
    },
    {
      label: 'Category',
      name: 'categories',
      placeholder: 'Select category',
      width: '198px',
      height: 48,
      isCheckboxSelect: true,
    },
    {
      label: 'Created From',
      name: 'createdFrom',
      placeholder: 'mm/dd/yy',
      width: '228px',
      isDate: true,
      dateFormat: 'MM/DD/YY',
    },
    {
      label: 'Created To',
      name: 'createdTo',
      placeholder: 'mm/dd/yy',
      width: '228px',
      isDate: true,
      dateFormat: 'MM/DD/YY',
    },
    {
      label: 'Jurisdiction',
      name: 'courtId',
      placeholder: 'Enter court name',
      width: '228px',
      isDropdown: true,
    },
    {
      label: 'Last updated from',
      name: 'lastUpdatedFrom',
      placeholder: 'mm/dd/yy',
      width: '228px',
      isDate: true,
      dateFormat: 'MM/DD/YY',
    },
    {
      label: 'Last updated to',
      name: 'lastUpdatedTo',
      placeholder: 'mm/dd/yy',
      width: '228px',
      isDate: true,
      dateFormat: 'MM/DD/YY',
    },
    ...(stateCode === 'NV'
      ? [
          {
            label: 'Envelope #',
            name: 'envelopeNumber',
            placeholder: 'xxxxxxxxx',
          },
        ]
      : []),
    {
      label: 'Show Archived Cases',
      name: 'showArchived',
      isCheckbox: true,
    },
  ],
};

export const clearFilterString = 'Clear filter';
