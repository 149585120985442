/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getOwnerEntities = createAsyncThunk(
  'ownerEntities/getOwnerEntities',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/ownerEntities/filter`, params);
    return data;
  },
);

const initialState = {
  ownerEntities: {},
  error: null,
};

export const communitySlice = createSlice({
  name: 'ownerEntities',
  initialState,
  reducers: {},
  extraReducers: {
    [getOwnerEntities.fulfilled]: (state, action) => {
      state.ownerEntities = action.payload.result;
    },
    [getOwnerEntities.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default communitySlice.reducer;
