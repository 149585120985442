import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import MButton from '../../../MUI/Button/MButton';

const ClientPortalNoticeIntakeUploadModal = ({
  open,
  handleClose,
  handleChange,
  isDragAndDrop,
  dragAndDrop,
  uploadTable,
  uploadHandler,
  documentStrings,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
      PaperProps={{ style: { borderRadius: 24 } }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          p: 4,
          overflowY: 'hidden',
          gap: 3,
        }}
      >
        <Typography variant="h5">{documentStrings.upload.title}</Typography>
        {isDragAndDrop ? dragAndDrop : uploadTable}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            {!isDragAndDrop && (
              <>
                <input
                  onChange={handleChange}
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  multiple
                  type="file"
                />
                <label htmlFor="raised-button-file">
                  <MButton size="large" variant="secondary" component="span">
                    {documentStrings.upload.add}
                  </MButton>
                </label>
              </>
            )}
          </Box>
          <Stack spacing={2} direction="row">
            <MButton size="large" variant="secondary" onClick={handleClose}>
              {documentStrings.upload.cancel}
            </MButton>
            <MButton size="large" onClick={uploadHandler}>
              {documentStrings.upload.submit}
            </MButton>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

const StyledDocumentsPopup = styled(ClientPortalNoticeIntakeUploadModal)({
  height: '450px',
});

ClientPortalNoticeIntakeUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDragAndDrop: PropTypes.bool.isRequired,
  dragAndDrop: PropTypes.element.isRequired,
  uploadTable: PropTypes.element.isRequired,
  uploadHandler: PropTypes.func.isRequired,
  documentStrings: PropTypes.shape({
    upload: PropTypes.shape({
      add: PropTypes.string,
      cancel: PropTypes.string,
      title: PropTypes.string,
      submit: PropTypes.string,
    }),
  }).isRequired,
};

export default StyledDocumentsPopup;
