import React, { useCallback, useEffect, useState } from 'react';
import { Box, styled, Typography, Stack, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  AVAILABLE_LOCKOUTS,
  GOING_TO_COURTS,
  listConfig,
  PENDING_APPROVALS,
  REQUIRE_FOLLOW_UPS,
} from './ClientPortalDashboardCases.constants';
import { getClientPortalDashboardList } from '../../../../store/slices/clientPortalDashboardSlice';
import Table from '../../../atoms/Table/Table';
import EmptyBlock from '../../../molecules/EmptyBlock';
import PeriodButtons from '../../../atoms/PeriodButtons';

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  padding: '32px',
  border: `1px solid ${theme.palette.additional.lines}`,
  borderRadius: '16px',
  height: '100%',
  width: '100%',
  maxWidth: 'calc(100vw - 326px - 64px - 24px)',
}));

const DEFAULT_TIME_PERIOD = 'today';
const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

const ClientPortalDashboardList = ({ type, isLoader, setIsLoader, stateCode }) => {
  const { title, timePeriods, columns } = listConfig[type];
  const dispatch = useDispatch();
  const lists = useSelector((state) => state.clientPortalDashboard.lists);
  const data = lists?.[type];
  const [timePeriod, setTimePeriod] = useState(DEFAULT_TIME_PERIOD);
  const [isTableLoader, setIsTableLoader] = useState(true);

  const handleFetchDataPage = useCallback(
    (currentPage = 1) => {
      setIsTableLoader(true);

      const params = {
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        currentPage,
        api: type,
        stateCode,
      };

      if (type === GOING_TO_COURTS) {
        params.isForToday = timePeriod === 'today';
      }

      return dispatch(getClientPortalDashboardList(params)).then(() => {
        setIsTableLoader(false);
      });
    },
    [type, timePeriod],
  );

  useEffect(() => {
    setIsLoader(true);
    if (timePeriod !== DEFAULT_TIME_PERIOD) setTimePeriod(DEFAULT_TIME_PERIOD);

    handleFetchDataPage().then(() => setIsLoader(false));
  }, [type]);

  useEffect(() => {
    if (type === GOING_TO_COURTS) {
      setIsLoader(true);
      handleFetchDataPage().then(() => setIsLoader(false));
    }
  }, [timePeriod]);

  return (
    <Wrapper>
      {isLoader && (
        <Box position="absolute" top="50%" left="50%" zIndex={10} transform="translate(-50%, -50%)">
          <CircularProgress />
        </Box>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={4}
        height="40px"
        width="100%"
      >
        <Typography variant="h5">{title}</Typography>

        <PeriodButtons
          currentPeriod={timePeriod}
          isDisabled={isLoader}
          onPeriodChange={setTimePeriod}
          periods={timePeriods}
        />
      </Stack>
      {!isLoader && !!data?.items?.length && (
        <Box flexGrow={1}>
          <Table
            columns={columns}
            rows={data?.items ?? []}
            total={data?.totalRowsCount}
            isPagination={data?.totalPages > 1}
            onNextPage={handleFetchDataPage}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            onRefreshPage={handleFetchDataPage}
            pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            loading={isTableLoader}
          />
        </Box>
      )}
      {!isLoader && !data?.items?.length && (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" flexGrow={1}>
          <EmptyBlock title="No records" />
        </Box>
      )}
    </Wrapper>
  );
};

ClientPortalDashboardList.propTypes = {
  type: PropTypes.oneOf([
    GOING_TO_COURTS,
    REQUIRE_FOLLOW_UPS,
    PENDING_APPROVALS,
    AVAILABLE_LOCKOUTS,
  ]).isRequired,
  isLoader: PropTypes.bool.isRequired,
  setIsLoader: PropTypes.func.isRequired,
  stateCode: PropTypes.string.isRequired,
};

export default ClientPortalDashboardList;
