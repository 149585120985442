import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import ShadowBlock from '../../atoms/ShadowBlock/ShadowBlock';
import logo from '../../../assets/logo.svg';
import LoginPageInformationBlock from '../../molecules/LoginPageInformationBlock/LoginPageInformationBlock';

const ForgotPasswordLayout = ({ children, title }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
      data-testid="forgot_password_layout"
    >
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingRight: 4, paddingLeft: 4, margin: '0 auto' }}
        width="100vw"
        maxWidth="1300px"
      >
        <LoginPageInformationBlock />
        <ShadowBlock>
          <Box
            sx={{
              p: '58px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              minHeight: '536px',
            }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              data-testid="forgot_password_layout_container"
            >
              <Box component="img" width="239px" height="97px" src={logo} alt="logotype" />
              <Box
                sx={{ my: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                data-testid="forgot_password_layout_title"
              >
                {typeof title === 'string' ? (
                  <Typography variant="bodySThin">{title}</Typography>
                ) : (
                  title
                )}
              </Box>
              {children}
            </Box>
          </Box>
        </ShadowBlock>
      </Stack>
    </Box>
  );
};

ForgotPasswordLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ForgotPasswordLayout.defaultProps = {
  title: 'Password Recovery',
};

export default ForgotPasswordLayout;
