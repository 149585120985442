/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import MRadioButton from '../../../../atoms/MRadioButton';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;
export const MAX_HEIGHT_DIALOG = 624;
export const MAX_HEIGHT_DIALOG_TABLE = 326;

export const ManagementAddressColumns = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 30,
  },
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 25,
  },
  {
    Header: () => <div style={{ textAlign: 'center' }}>On Hold</div>,
    accessor: 'isOnHold',
    width: 10,
    Cell: ({ row }) => (
      <div style={{ textAlign: 'center' }}>
        <MRadioButton isChecked={row?.original?.isOnHold} isTogglable={false} />
      </div>
    ),
  },
  {
    Header: 'Corporate Address',
    accessor: 'isCorporateAddress',
    width: 25,
    Cell: (props) => {
      const { value, row } = props;
      if (!value) return null;
      return <Typography variant="bodyM">{`${row.original.stateCode} Corporate`}</Typography>;
    },
  },
];

export const AddressesAllColumns = [
  {
    Header: 'Address',
    accessor: 'address',
    width: 45,
  },
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 35,
  },
  {
    Header: () => <div style={{ textAlign: 'center' }}>On Hold</div>,
    accessor: 'isOnHfold',
    width: 15,
    Cell: ({ row }) => (
      <div style={{ textAlign: 'center' }}>
        <MRadioButton isChecked={row?.original?.isOnHold} isTogglable={false} />
      </div>
    ),
  },
];
