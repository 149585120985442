/* eslint-disable no-await-in-loop */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';
import Form from '../../../atoms/Form';
import { CourtScheduleColumns, CourtScheduleFormBoxes } from './CourtSchedule.constants';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import {
  clearCourtScheduleCourts,
  createCourtScheduleReport,
  getCountiesForCourtScheduleReport,
  getCourtScheduleReport,
  getCourtScheduleReportAsPdf,
  getCourtsForCourtScheduleReport,
} from '../../../../store/slices/reportsSlice';
import notificationUtils from '../../../../utils/notificationUtils';
import timeoutPromise from '../../../../utils/timeoutPromise';
import CheckboxSelect from '../../../atoms/CheckboxSelect';
import useAsyncLoadDropdown from '../../../../hooks/useAsyncLoadDropdown';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import Dropdown from '../../../atoms/Dropdown';
import Table from '../../../atoms/Table/Table';
import ProgressModal from '../../Reports/ProductivityByAttorney/ProgressModal';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';
import { ReactComponent as Export } from '../../../../assets/icons/document-download-blue.svg';

const StyledAccordion = styled(Accordion)(({ theme }) => {
  return {
    padding: '32px',
    boxShadow: `0 3px 20px ${theme.palette.shadow.boxContrast}`,
    '&': {
      border: 'none',
      borderRadius: '16px',
    },
    '&:first-of-type': {
      borderRadius: '16px',
    },
    '&:last-of-type': {
      borderRadius: '16px',
    },
    '& .MuiAccordionSummary-root': {
      padding: '0',
    },
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
  };
});

const CourtScheduleReport = ({ stateCode }) => {
  const [accordionOpened, setAccordionOpened] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [lastReportId, setLastReportId] = useState(null);
  const dispatch = useDispatch();
  const { courtScheduleCourts, courtScheduleCounties } = useSelector((state) => state.reports);

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const toggleAccordion = () => () => {
    setAccordionOpened((prev) => !prev);
  };

  const county = form.watch('county');

  useEffect(() => {
    if (!stateCode) {
      return;
    }
    form.setValue('county', null);
    form.setValue('courts', []);
    setTableData(null);
    setDataLoading(true);
    dispatch(getCountiesForCourtScheduleReport(stateCode)).finally(() => {
      setDataLoading(false);
    });
  }, [stateCode]);

  useEffect(() => {
    if (!stateCode) {
      return;
    }
    form.setValue('courts', []);
  }, [county]);

  const countyLabel = form.watch('county')?.label;

  const courtsArguments = useMemo(() => {
    return {
      countyName: countyLabel,
    };
  }, [countyLabel]);

  const handleSubmit = async (values) => {
    const data = {
      fromDate: values.fromDate,
      toDate: values.toDate,
      countyName: values.county?.label,
      courtIds: values.allCourts ? [] : values.courts.map((item) => item.value),
    };

    setIsLoader(true);

    try {
      const { reportId } = await dispatch(createCourtScheduleReport(data)).unwrap();
      setLastReportId(reportId);
      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code, message },
        } = await dispatch(getCourtScheduleReport(reportId)).unwrap();
        if (code === 'Completed') {
          setTableData(items);
          setAccordionOpened(true);
          done = true;
        } else if (code === 'Error') {
          notificationUtils.error(message);
          setTableData(null);
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      notificationUtils.error(e.message || 'Something went wrong');
      setTableData(null);
    }
    setIsLoader(false);
  };

  const { handleMenuScrollToBottom } = useAsyncLoadDropdown({
    hasNextPage: courtScheduleCourts?.hasNextPage,
    actionArguments: courtsArguments,
    getAction: ({ page, ...args }) =>
      getCourtsForCourtScheduleReport({
        currentPage: page,
        pageSize: 100,
        ...args,
      }),
    clearCallback: () => {
      dispatch(clearCourtScheduleCourts());
    },
  });

  const getOptions = (name) => {
    switch (name) {
      case 'county':
        return courtScheduleCounties;
      case 'courts':
        return courtScheduleCourts?.items || [];
      default:
        return [];
    }
  };

  const getDisabled = (name) => {
    switch (name) {
      case 'county':
        return !stateCode;
      case 'courts':
        return !stateCode || !county;
      default:
        return false;
    }
  };

  const getOnChange = useCallback(
    (onChange, name) => (valueOrEvent, isAll) => {
      switch (name) {
        case 'courts': {
          form.setValue('allCourts', isAll);
          return onChange(valueOrEvent);
        }
        default:
          return onChange(valueOrEvent);
      }
    },
    [],
  );

  const exportToPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setDataLoading(true);
      const data = await dispatch(getCourtScheduleReportAsPdf(lastReportId)).unwrap();
      openOrDownloadBinary({
        content: data.content,
        fileName: data.originalFileName,
        contentType: data.contentType,
      });
    } catch (error) {
      notificationUtils.error(error.message || 'Something went wrong');
    } finally {
      setDataLoading(false);
    }
  };

  return (
    <StyledAccordion
      expanded={accordionOpened}
      onChange={toggleAccordion()}
      data-testid="court_schedule_report_section"
    >
      <AccordionSummary
        expandIcon={<ArrowDown />}
        sx={{
          '&': {
            minHeight: 0,
            padding: '32px',
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
          },
        }}
      >
        <Typography variant="h5" fontWeight={600} mr={2}>
          Court Schedule Report
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <ProgressModal isOpen={isLoader} />
        <Box sx={{ position: 'relative' }}>
          {isDataLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              position="absolute"
              top="0"
              left="0"
              zIndex={10}
            >
              <CircularProgress />
            </Box>
          )}
          <Form form={form} onSubmit={handleSubmit}>
            {CourtScheduleFormBoxes.map((i) => (
              <Stack
                direction="row"
                alignItems="center"
                columnGap={3}
                rowGap={2}
                flexWrap="wrap"
                py={1}
              >
                {i.inputBoxes.map((item) => {
                  if (item.isCheckboxSelect) {
                    return (
                      <Field
                        key={item.name}
                        isMandatory={item.isMandatory}
                        name={item.name}
                        render={({ field, onCustomChange, error }) => (
                          <CheckboxSelect
                            label={item.label}
                            value={field.value}
                            width={item.width}
                            error={error}
                            onMenuScrollToBottom={handleMenuScrollToBottom}
                            isMandatory={item.isMandatory}
                            placeholder={item.placeholder}
                            onChange={getOnChange(onCustomChange(field.onChange), field.name)}
                            options={getOptions(item.name)}
                            showSelectAll={item.showSelectAll}
                            isDisabled={getDisabled(item.name)}
                          />
                        )}
                      />
                    );
                  }

                  if (item.isDatePicker) {
                    return (
                      <Field
                        name={item.name}
                        key={item.name}
                        isMandatory={item.isMandatory}
                        render={({ field, onCustomChange, error }) => (
                          <DateOrTimePicker
                            name={item.name}
                            label={item.label}
                            isDate
                            error={error}
                            Svg={Calender}
                            selected={field.value}
                            isMandatory={item.isMandatory}
                            width={item.width}
                            height={item.height}
                            placeholder={item.placeholder}
                            setSelectedTimeOrDate={onCustomChange(field.onChange)}
                          />
                        )}
                      />
                    );
                  }

                  if (item.isDropdown) {
                    return (
                      <Field
                        key={item.name}
                        name={item.name}
                        isMandatory={item.isMandatory}
                        render={({ field, onCustomChange }) => (
                          <Dropdown
                            value={field.value}
                            isAddDisabled={false}
                            isSearchable
                            label={item.label}
                            width={item.width}
                            isColumn
                            placeholder={item.placeholder}
                            options={getOptions(item.name)}
                            onChange={onCustomChange(field.onChange)}
                            isDisabled={getDisabled(item.name)}
                            isMandatory={item.isMandatory}
                          />
                        )}
                      />
                    );
                  }

                  return null;
                })}
              </Stack>
            ))}
            <Stack direction="row" mt={2} gap={2}>
              <MButton type="submit" size="medium" disabled={isLoader || !form.formState.isValid}>
                Run Report
              </MButton>
            </Stack>
          </Form>
        </Box>
        {tableData && (
          <Box mt={3} position="relative">
            {tableData?.length ? (
              <>
                <MButton
                  type="button"
                  onClick={exportToPdf}
                  size="medium"
                  disabled={isDataLoading || !lastReportId}
                  variant="borderLess"
                  sx={{ position: 'absolute', top: 0, right: '30px' }}
                  startIcon={<Export />}
                >
                  Export Report
                </MButton>
                {tableData.map((data) => {
                  return (
                    <Stack direction="column">
                      <Typography mb={5} variant="h4">
                        {data.countyName}
                      </Typography>
                      {data.courtDateTimes.map((cdt) => (
                        <Stack direction="column" mb={2}>
                          <Stack direction="row" gap={1}>
                            <Typography variant="bodyL500" fontWeight={600}>
                              {moment(cdt.courtDateTime).format('MM/DD/YY')}
                            </Typography>
                            <Typography variant="bodyL500" fontWeight={600}>
                              {moment(cdt.courtDateTime).format('hh:mm A')}
                            </Typography>
                          </Stack>
                          {cdt.courts.map((court) => (
                            <Stack direction="column" mb={1}>
                              <Typography mb={1} variant="bodyL500" fontWeight={600}>
                                {court.courtName}
                              </Typography>
                              <Table columns={CourtScheduleColumns} rows={court.cases} />
                            </Stack>
                          ))}
                        </Stack>
                      ))}
                    </Stack>
                  );
                })}
              </>
            ) : (
              <Box
                data-testid="empty_block"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <EmptyBlock title="No Records" />
              </Box>
            )}
          </Box>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

CourtScheduleReport.propTypes = {
  stateCode: PropTypes.string.isRequired,
};

export default CourtScheduleReport;
