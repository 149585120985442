export const subPageHeading = 'View Case';
export const subPageNavAZ = (base) => [
  {
    label: 'Parties & Precinct',
    to: `/${base}/cases/viewcase/partiesandprecinct`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Case Information',
    to: `/${base}/cases/viewcase/caseinformation`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Document',
    to: `/${base}/cases/viewcase/document`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Requests',
    to: `/${base}/cases/viewcase/requestsarizona`,
    state: {
      navPage: 'Cases',
    },
  },
];

export const subPageNavCivilMatterAZ = (base) => [
  {
    label: 'Parties & Precinct',
    to: `/${base}/cases/viewcase/partiesandprecinct`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Case Information',
    to: `/${base}/cases/viewcase/caseinformation`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Civil Matter',
    to: `/${base}/cases/viewcase/civilmatter`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Document',
    to: `/${base}/cases/viewcase/document`,
    state: {
      navPage: 'Cases',
    },
  },
];

export const subPageNavNV = (base) => [
  {
    label: 'Parties & Precinct',
    to: `/${base}/cases/viewcase/partiesandprecinct`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Case Information',
    to: `/${base}/cases/viewcase/caseinformation`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Document',
    to: `/${base}/cases/viewcase/document`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Requests',
    to: `/${base}/cases/viewcase/requests`,
    state: {
      navPage: 'Cases',
    },
  },
];

export const subPageNavCivilMatterNV = (base) => [
  {
    label: 'Parties & Precinct',
    to: `/${base}/cases/viewcase/partiesandprecinct`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Case Information',
    to: `/${base}/cases/viewcase/caseinformation`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Civil Matter',
    to: `/${base}/cases/viewcase/civilmatter`,
    state: {
      navPage: 'Cases',
    },
  },
  {
    label: 'Document',
    to: `/${base}/cases/viewcase/document`,
    state: {
      navPage: 'Cases',
    },
  },
];
