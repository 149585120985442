import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled, Dialog, Typography, Stack } from '@mui/material';
import MButton from '../../MUI/Button/MButton';
import { icons } from './NotificationDialog.constants';
import { palette } from '../../../theme/default/palette';

const StyledDialog = styled(Dialog)(({ width }) => ({
  '& .MuiDialog-paper': {
    width,
    padding: 32,
    borderRadius: 24,
    boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
  },
}));

const getIcon = (type) => icons.find((item) => item.type === type)?.icon;

const NotificationDialog = ({
  isOpen,
  type,
  title,
  desc,
  buttonSecondaryText,
  buttonPrimaryText,
  buttonSecondaryWidth,
  buttonPrimaryWidth,
  buttonSecondaryVariant,
  onClose,
  onClosed,
  onCancel,
  onConfirm,
  width,
  maxWidth,
  content,
}) => {
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handlePrimaryButton = useCallback(() => {
    onConfirm?.();
  }, [onConfirm]);

  const handleSecondaryButton = useCallback(() => {
    (onCancel || onClose)?.();
  }, [onCancel, onClose]);

  return (
    <StyledDialog
      onClose={handleClose}
      open={isOpen}
      width={width}
      sx={{ zIndex: 10000 }}
      maxWidth={maxWidth}
      TransitionProps={{
        onExited: () => {
          onClosed?.();
        },
      }}
      data-testid="notification_dialog"
    >
      <Stack spacing={4}>
        {title && (
          <Typography variant="h4" data-testid="notification_dialog_title">
            {title}
          </Typography>
        )}
        {desc && (
          <Stack direction="row" spacing={1} alignItems="center">
            {getIcon(type)}
            <Typography variant="bodyL500" data-testid="notification_dialog_description">
              {desc}
            </Typography>
          </Stack>
        )}
        {content || null}
        <Stack direction="row">
          <Stack display="flex" flexDirection="row" gap={2} flexGrow={1} justifyContent="end">
            {buttonSecondaryText && (
              <MButton
                variant={buttonSecondaryVariant}
                size="large"
                onClick={handleSecondaryButton}
                sx={{ width: buttonSecondaryWidth }}
                data-testid="notification_dialog_secondary_btn"
              >
                {buttonSecondaryText}
              </MButton>
            )}
            {buttonPrimaryText && (
              <MButton
                size="large"
                onClick={handlePrimaryButton}
                sx={{ width: buttonPrimaryWidth }}
                data-testid="notification_dialog_primary_btn"
              >
                {buttonPrimaryText}
              </MButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </StyledDialog>
  );
};

NotificationDialog.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.oneOf(['alert', 'confirm']),
  title: PropTypes.string,
  desc: PropTypes.string,
  width: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonSecondaryVariant: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  buttonSecondaryWidth: PropTypes.string,
  buttonPrimaryWidth: PropTypes.string,
  onClose: PropTypes.func,
  onClosed: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  content: PropTypes.node,
  maxWidth: PropTypes.string,
};

NotificationDialog.defaultProps = {
  isOpen: false,
  type: 'confirm',
  title: '',
  desc: '',
  buttonSecondaryText: '',
  buttonPrimaryText: '',
  buttonSecondaryVariant: 'secondary',
  buttonSecondaryWidth: '104px',
  buttonPrimaryWidth: '104px',
  onClose: null,
  onClosed: null,
  onConfirm: null,
  onCancel: null,
  width: 'auto',
  content: null,
  maxWidth: undefined,
};

export default NotificationDialog;
