import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Box, styled } from '@mui/material';
import { always } from 'ramda';
import SearchLabel from '../SearchLabel/SearchLabel';
import { palette } from '../../../theme/default/palette';
import { useRandomId } from '../../../hooks/useRandomId';

const StyledContainer = styled('div')(({ containerMarginBottom }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  fontWeight: 500,
  fontSize: 16,
  marginBottom: containerMarginBottom || '16px',
}));

const customStyles = (width, height) => ({
  control: (provided) => ({
    ...provided,
    borderRadius: '0 10px 10px 0',
    border: `1px solid  ${palette.additional.lines}`,
    width: width || 'auto',
    minHeight: `${height}px` || '48px',
  }),
  container: (prev) => ({
    ...prev,
  }),
  Input: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginLeft: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: palette.buttonSecondary.hoverBorder,
    fontWeight: 500,
    fontSize: '16px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: palette.text.primary,
    },
  }),
  IndicatorsContainer: (prev) => ({
    ...prev,
    margin: 0,
  }),
});

const SearchInputWithLabelAndIcon = ({
  label,
  placeholder,
  name,
  options,
  handleChange,
  handleSearch,
  width,
  height,
  value,
  onMenuScrollToBottom,
  containerMarginBottom,
  onMenuClose,
  onMenuOpen,
  isSearchable,
  isDisabled,
  onEnter,
  testid,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && onEnter && !isMenuOpen) {
      e.preventDefault();
      onEnter(e);
    }
  };

  const handleMenuOpen = (...args) => {
    setIsMenuOpen(true);
    onMenuOpen?.(...args);
  };
  const handleMenuClose = (...args) => {
    setIsMenuOpen(false);
    onMenuClose?.(...args);
  };

  const id = useRandomId();

  return (
    <StyledContainer
      containerMarginBottom={containerMarginBottom}
      data-testid={testid || 'search_input_with_label_icon_wrapper'}
    >
      <SearchLabel value={label} height={height && `${height}px`} htmlFor={id} />
      <Box sx={{ alignItems: 'center' }}>
        <Select
          data-testid="searchInputSelect"
          inputId={id}
          isDisabled={isDisabled}
          styles={customStyles(width, height)}
          options={options}
          name={name}
          value={value}
          placeholder={placeholder}
          onMenuOpen={handleMenuOpen}
          onChange={handleChange}
          onInputChange={handleSearch}
          noOptionsMessage={always(null)}
          onMenuScrollToBottom={onMenuScrollToBottom}
          onMenuClose={handleMenuClose}
          isSearchable={isSearchable}
          onKeyDown={onKeyDown}
          {...(handleSearch && { filterOption: always(true) })}
        />
      </Box>
    </StyledContainer>
  );
};

SearchInputWithLabelAndIcon.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.string]),
  containerMarginBottom: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      lable: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func,
  onMenuScrollToBottom: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onEnter: PropTypes.func,
  testid: PropTypes.string,
};

SearchInputWithLabelAndIcon.defaultProps = {
  onMenuScrollToBottom: () => {},
  handleSearch: () => {},
  onMenuClose: () => {},
  onMenuOpen: () => {},
  height: 44,
  value: '',
  containerMarginBottom: '',
  isSearchable: true,
  isDisabled: false,
  onEnter: null,
  name: '',
  testid: '',
};

export default SearchInputWithLabelAndIcon;
