import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useForm } from 'react-hook-form';
import { Typography, Box } from '@mui/material';
import MButton from '../../MUI/Button/MButton';
import MDialog from '../../atoms/MDialog/MDialog';
import Form from '../../atoms/Form';
import Dropzone from '../../atoms/Dropzone';
import { palette } from '../../../theme/default';
import { uploadDocumentsColumns } from './LegalForms.constants';
import Table from '../../atoms/Table/Table';
import { apiClient } from '../../../lib/apiClient';
import notificationUtils from '../../../utils/notificationUtils';
import { addObjToFormData } from '../../../utils/convertObjToFormData';
import LoaderCover from '../../atoms/LoaderCover';

const UploadVersionDialog = ({ isOpen, onClose, templateGroupNumber }) => {
  const [loading, setIsLoader] = useState(false);
  const form = useForm({
    defaultValues: {
      uploadDocumentsArray: [],
    },
    mode: 'onChange',
  });

  const uploadDocumentsArray = useFieldArray({
    control: form.control,
    name: 'uploadDocumentsArray',
  });

  const handleDrop = (acceptedFiles) => {
    // only 1 file for now
    form.setValue('File', acceptedFiles[0]);
    uploadDocumentsArray.replace(
      acceptedFiles.map((file) => ({
        name: file.name,
        file,
      })),
    );
  };

  const handleDelete = () => {
    form.setValue('File', null);
    uploadDocumentsArray.remove();
  };

  const onCancelPress = (isSubmitted) => {
    onClose(isSubmitted);
    form.reset();
    uploadDocumentsArray.remove();
  };

  const handleSubmit = form.handleSubmit(async (formValues) => {
    // upload query
    setIsLoader(true);
    try {
      await apiClient.post(
        `/cp/api/legalForms/${templateGroupNumber}/upload`,
        addObjToFormData({
          request: formValues.File,
        }),
      );
      onCancelPress(true);
    } catch {
      notificationUtils.error('Failed, try again later');
    } finally {
      setIsLoader(false);
    }
  });

  const file = form.watch('File');

  return (
    <MDialog
      isOpen={isOpen}
      onClose={() => onCancelPress(false)}
      title="Upload Legal Form Version"
      maxWidth="796px"
      minWidth="796px"
      footerActions={[
        <MButton size="large" variant="bordered" onClick={() => onCancelPress(false)}>
          Cancel
        </MButton>,
        <MButton size="large" onClick={handleSubmit} disabled={!file}>
          Upload
        </MButton>,
      ]}
    >
      {loading && <LoaderCover />}
      <Form form={form} onSubmit={handleSubmit}>
        {file ? (
          <Box
            sx={{
              overflowY: 'scroll',
              maxHeight: '240px',
              width: '732px',
              margin: '32px 0',
              '&::-webkit-scrollbar': {
                width: '4px',
                marginLeft: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: palette.additional.lines,
              },
              '&::-webkit-scrollbar-thumb': {
                background: palette.additional.thumb,
              },
            }}
          >
            <Table
              loading={false}
              columns={uploadDocumentsColumns}
              rows={uploadDocumentsArray.fields}
              onDeleteRow={handleDelete}
            />
          </Box>
        ) : (
          <Dropzone
            accept={{
              'application/pdf': ['.pdf'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
              'application/vnd.ms-excel': ['.xls'],
            }}
            maxSize={1024 * 1024 * 10}
            onDrop={handleDrop}
            customContent={
              <>
                <Typography variant="bodyL500" color="text.secondary" sx={{ my: 1 }}>
                  Drop your documents here,
                  <Typography
                    variant="bodyL"
                    sx={{
                      color: 'buttonPrimary.active',
                      textDecoration: 'underline',
                      marginLeft: 0.5,
                    }}
                  >
                    or select
                  </Typography>
                </Typography>
                <Typography variant="bodyXS" color="text.secondary" sx={{ my: 1 }}>
                  Supports PDF, DOCX, DOC, Excel files up to 10MB
                </Typography>
              </>
            }
          />
        )}
      </Form>
    </MDialog>
  );
};

UploadVersionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  templateGroupNumber: PropTypes.number.isRequired,
};

export default UploadVersionDialog;
