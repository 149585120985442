import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MDialog from '../../../atoms/MDialog';
import MButton from '../../../MUI/Button/MButton';
import LoaderCover from '../../../atoms/LoaderCover';
import AdditionalInformationFormDialog from './AdditionalInformationFormDialog';
import Dropdown from '../../../atoms/Dropdown';
import { getNevadaNoticeTemplateData } from '../../../../store/slices/cases/casesNevadaSlice';

const AdditionalInformationDialog = ({ isOpen, onClose, templates, addViolation, caseId }) => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [documentTemplate, setDocumentTemplate] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const { fields } = useSelector((state) => state.casesNevada.noticeTemplateData) || {
    fields: [],
  };

  const handleClosed = () => {
    setIsLoader(false);
  };

  const handleCloseForm = () => {
    setAdditionalInfo(false);
    onClose();
  };

  const fetchTemplateData = async () => {
    const templateId = documentTemplate.value;
    setIsLoader(true);
    const templateData = await dispatch(getNevadaNoticeTemplateData({ templateId, id: caseId }));
    const templateFields = templateData?.payload?.fields;
    if (templateFields?.length) {
      setAdditionalInfo(true);
      setIsLoader(false);
    } else {
      addViolation(documentTemplate?.id)({}, handleCloseForm());
    }
  };

  return (
    <>
      <MDialog
        title="Additional Information"
        isOpen={isOpen && !additionalInfo}
        onClose={onClose}
        onClosed={handleClosed}
        fullWidth
        maxWidth="sm"
        footerActions={
          <>
            <MButton variant="secondary" size="large" onClick={onClose} disabled={isLoader}>
              Cancel
            </MButton>
            <MButton
              type="submit"
              size="large"
              onClick={fetchTemplateData}
              disabled={!documentTemplate || isLoader}
            >
              Submit
            </MButton>
          </>
        }
      >
        {isLoader && <LoaderCover />}
        <Dropdown
          isColumn
          label="Document for generation"
          placeholder="Choose document template for generation"
          options={templates ?? []}
          value={documentTemplate}
          onChange={setDocumentTemplate}
        />
      </MDialog>
      <AdditionalInformationFormDialog
        isOpen={additionalInfo}
        onClose={handleCloseForm}
        addViolation={addViolation(documentTemplate?.id)}
        fields={fields}
      />
    </>
  );
};

AdditionalInformationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  caseId: PropTypes.number.isRequired,
  court: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    propertyType: PropTypes.string,
    cause: PropTypes.string,
  }).isRequired,
  addViolation: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
};

export default AdditionalInformationDialog;
