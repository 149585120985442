import React from 'react';
import { PropTypes } from 'prop-types';
import MCheckbox from '../../../atoms/MCheckbox';

const CheckboxCell = ({ row, updateValue, cell }) => {
  const { value, column } = cell;

  const handleChange = (ev) => {
    if (!row.original?.id) return;
    updateValue(row.original.id, { id: column.id, value: ev.target.checked, touched: true });
    if (ev.target.checked) {
      updateValue(row.original.id, { id: 'apartmentNumber', value: '', error: undefined });
    }
  };

  return <MCheckbox isChecked={value} onChange={handleChange} />;
};

CheckboxCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    column: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({ id: PropTypes.number }),
  }).isRequired,
  updateValue: PropTypes.func.isRequired,
};

CheckboxCell.defaultProps = {
  cell: {
    value: '',
    column: {
      id: '',
    },
  },
};

export default React.memo(CheckboxCell);
