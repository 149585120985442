/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const getJusticeCourt = createAsyncThunk('arizonaCourts/getJusticeCourt', async (params) => {
  const { data } = await apiClient.get(`/api/arizonaCourts/justices/${params.id}`);
  return data;
});

export const updateJusticeCourt = createAsyncThunk(
  'arizonaCourts/updateJusticeCourt',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(`/api/arizonaCourts/justices/${params.id}`, params);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const getJusticeFeesAndCosts = createAsyncThunk(
  'arizonaCourts/getJusticeFeesAndCosts',
  async (params) => {
    const { data } = await apiClient.get(`/api/arizonaCourts/justices/${params.id}/feesAndCosts`);
    return data;
  },
);

export const getSuperiorCourt = createAsyncThunk(
  'arizonaCourts/getSuperiorCourt',
  async (params) => {
    const { data } = await apiClient.get(`/api/arizonaCourts/superiors/${params.id}`);
    return data;
  },
);

export const updateSuperiorCourt = createAsyncThunk(
  'arizonaCourts/updateSuperiorCourt',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(`/api/arizonaCourts/superiors/${params.id}`, params);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const getSuperiorFeesAndCosts = createAsyncThunk(
  'arizonaCourts/getSuperiorFeesAndCosts',
  async (params) => {
    const { data } = await apiClient.get(`/api/arizonaCourts/superiors/${params.id}/feesAndCosts`);
    return data;
  },
);

export const createJusticeCourt = createAsyncThunk(
  'arizonaCourts/createJusticeCourt',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/arizonaCourts/justices`, params);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const createSuperiorCourt = createAsyncThunk(
  'arizonaCourts/createSuperiorCourt',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/arizonaCourts/superiors`, params);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const getListOfAddressesForJusticeCourt = createAsyncThunk(
  'arizonaCourts/getListOfAddressesForJusticeCourt',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/arizonaCourts/justices/${params.id}/addresses/filter`,
      params,
    );
    return data;
  },
);

export const getListOfAddressesForSuperiorCourt = createAsyncThunk(
  'arizonaCourts/getListOfAddressesForSuperiorCourt',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/arizonaCourts/superiors/${params.id}/addresses/filter`,
      params,
    );
    return data;
  },
);

export const addAddressesToJusticeCourt = createAsyncThunk(
  'arizonaCourts/addAddressesToJusticeCourt',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/arizonaCourts/justices/${params.id}/addresses`,
      params,
    );
    return data;
  },
);

export const removeAddressesFromJusticeCourt = createAsyncThunk(
  'arizonaCourts/removeAddressesFromJusticeCourt',
  async (params) => {
    const { data } = await apiClient.delete(`/api/arizonaCourts/justices/${params.id}/addresses`, {
      data: params,
      headers: { 'Content-Type': 'application/json' },
    });
    return data;
  },
);

export const addAddressesToSuperiorCourt = createAsyncThunk(
  'arizonaCourts/addAddressesToSuperiorCourt',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/arizonaCourts/superiors/${params.id}/addresses`,
      params,
    );
    return data;
  },
);

export const removeAddressesFromSuperiorCourt = createAsyncThunk(
  'arizonaCourts/removeAddressesFromSuperiorCourt',
  async (params) => {
    const { data } = await apiClient.delete(`/api/arizonaCourts/superiors/${params.id}/addresses`, {
      data: params,
      headers: { 'Content-Type': 'application/json' },
    });
    return data;
  },
);

export const resetFeesAndCostsArizona = createAction('arizonaCourts/resetFeesAndCosts');

const initialState = {
  getJusticeCourt: {},
  getJusticeFeesAndCosts: null,
  getSuperiorCourt: {},
  getSuperiorFeesAndCosts: null,
  getListOfAddressesForJusticeCourt: [],
  getListOfAddressesForSuperiorCourt: [],
};

export const arizonaCourtsSlice = createSlice({
  name: 'arizonaCourts',
  initialState,
  reducers: {},
  extraReducers: {
    [getJusticeCourt.fulfilled]: (state, action) => {
      state.getJusticeCourt = action.payload.result;
    },
    [getJusticeFeesAndCosts.fulfilled]: (state, action) => {
      state.getJusticeFeesAndCosts = action.payload.result;
    },
    [getSuperiorCourt.fulfilled]: (state, action) => {
      state.getSuperiorCourt = action.payload.result;
    },
    [getSuperiorFeesAndCosts.fulfilled]: (state, action) => {
      state.getSuperiorFeesAndCosts = action.payload.result;
    },
    [getListOfAddressesForJusticeCourt.fulfilled]: (state, action) => {
      state.getListOfAddressesForJusticeCourt = action.payload.result;
    },
    [getListOfAddressesForSuperiorCourt.fulfilled]: (state, action) => {
      state.getListOfAddressesForSuperiorCourt = action.payload.result;
    },
    [resetFeesAndCostsArizona]: (state) => {
      state.getJusticeFeesAndCosts = null;
      state.getSuperiorFeesAndCosts = null;
    },
  },
});

export default arizonaCourtsSlice.reducer;
