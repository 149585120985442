import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Typography, LinearProgress, Box } from '@mui/material';
import { getDocument } from '../../../../../store/slices/cases/caseCpSlice';
import { openOrDownloadBinary } from '../../../../../utils/binaryHelpers';

export const DocumentDownloadCell = ({
  value,
  row: {
    original: { caseId, documentId },
  },
}) => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);

  const handleClick = () => {
    if (!isLoader) {
      setIsLoader(true);
      dispatch(getDocument({ id: caseId, documentId }))
        .unwrap()
        .then(({ result }) => {
          setIsLoader(false);
          openOrDownloadBinary({
            contentType: result.contentType,
            content: result.content,
            fileName: result.originalFileName,
          });
        });
    }
  };

  if (isLoader) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  const dotIndex = value.lastIndexOf('.');
  const label = dotIndex === -1 ? value : value.slice(0, dotIndex);

  return (
    <Typography
      component="a"
      onClick={handleClick}
      variant="primary"
      color="primary"
      title={label}
      sx={{
        textDecoration: 'underline',
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {label}
    </Typography>
  );
};

DocumentDownloadCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseId: PropTypes.number.isRequired,
      documentId: PropTypes.number.isRequired,
    }),
  }).isRequired,
};
