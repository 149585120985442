import { DateCell } from './cells/DateCell';
import NameCell from './cells/NameCell';

export const stateCodesOptions = [
  { value: 'AZ', label: 'AZ' },
  { value: 'NV', label: 'NV' },
];

export const periodOptions = [
  { value: '30', label: '30 days' },
  { value: '60', label: '60 days' },
  { value: '90', label: '90 days' },
  { value: 'more', label: 'more than 90 days' },
];

export const InactiveClientFormBoxes = [
  {
    name: 'dataFields',
    inputBoxes: [
      {
        label: 'State',
        name: 'stateCode',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'Inactive Period',
        name: 'period',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
    ],
  },
];

export const InactiveClientFormBoxesColumns = ({ stateCode }) => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props) => <NameCell {...props} stateCode={stateCode} />,
      width: 70,
    },
    {
      Header: 'Last Evictions Requested',
      accessor: 'lastEvictionRequestDate',
      Cell: DateCell,
      width: 30,
    },
  ];
};
