import { CIVIL_MATTER_CASE, EVICTION_MATTER_CASE } from '../../../../global/Case.constants';

export const subPageHeading = 'Edit Case';

export const subPageNavWithEvictionMatterArizona = [
  {
    label: 'Parties & Precinct',
    to: '/db/processing/editcase/partiesandprecinct',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: EVICTION_MATTER_CASE,
    },
  },
  {
    label: 'Case Information',
    to: '/db/processing/editcase/caseinformationarizona',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: EVICTION_MATTER_CASE,
    },
  },
  {
    label: 'Document',
    to: '/db/processing/editcase/document',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: EVICTION_MATTER_CASE,
    },
  },
  {
    label: 'Notes',
    to: '/db/processing/editcase/notes',
    tooltip: 'Notes are for internal use only.',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: EVICTION_MATTER_CASE,
    },
  },
];

export const subPageNavWithEvictionMatterNevada = [
  {
    label: 'Parties & Precinct',
    to: '/db/processing/editcase/partiesandprecinct',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: EVICTION_MATTER_CASE,
    },
  },
  {
    label: 'Case Information',
    to: '/db/processing/editcase/caseinformationnevada',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: EVICTION_MATTER_CASE,
    },
  },
  {
    label: 'Document',
    to: '/db/processing/editcase/document',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: EVICTION_MATTER_CASE,
    },
  },
  {
    label: 'Notes',
    to: '/db/processing/editcase/notes',
    tooltip: 'Notes are for internal use only.',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: EVICTION_MATTER_CASE,
    },
  },
];

export const subPageNavWithCivilMatterArizona = [
  {
    label: 'Parties & Precinct',
    to: '/db/processing/editcase/partiesandprecinct',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: CIVIL_MATTER_CASE,
    },
  },
  {
    label: 'Case Information',
    to: '/db/processing/editcase/civilmatterscaseinformationarizona',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: CIVIL_MATTER_CASE,
    },
  },
  {
    label: 'Civil Matter',
    to: '/db/processing/editcase/civilmattersarizona',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: CIVIL_MATTER_CASE,
    },
  },
  {
    label: 'Document',
    to: '/db/processing/editcase/document',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: CIVIL_MATTER_CASE,
    },
  },
  {
    label: 'Notes',
    to: '/db/processing/editcase/notes',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: CIVIL_MATTER_CASE,
    },
  },
];

export const subPageNavWithCivilMatterNevada = [
  {
    label: 'Parties & Precinct',
    to: '/db/processing/editcase/partiesandprecinct',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: CIVIL_MATTER_CASE,
    },
  },
  {
    label: 'Case Information',
    to: '/db/processing/editcase/civilmatterscaseinformationnevada',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: CIVIL_MATTER_CASE,
    },
  },
  {
    label: 'Civil Matter',
    to: '/db/processing/editcase/civilmattersnevada',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: CIVIL_MATTER_CASE,
    },
  },
  {
    label: 'Document',
    to: '/db/processing/editcase/document',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: CIVIL_MATTER_CASE,
    },
  },
  {
    label: 'Notes',
    to: '/db/processing/editcase/notes',
    state: {
      navPage: 'Processing',
      subNav: 'Cases',
      caseType: CIVIL_MATTER_CASE,
    },
  },
];
