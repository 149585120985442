/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getDocuments = createAsyncThunk('feeScheduleSlice/getDocuments', async (params) => {
  const query = new URLSearchParams(params);

  const { data } = await apiClient.get(
    `/cp/api/feeSchedules/documents?${query.toString()}`,
    params,
  );

  return data;
});

export const downloadDocument = createAsyncThunk(
  'feeScheduleSlice/downloadDocument',
  async (id) => {
    const { data } = await apiClient.get(`/cp/api/feeSchedules/documents/${id}`);
    return data;
  },
);

export const uploadDocuments = createAsyncThunk(
  'feeScheduleSlice/uploadDocuments',
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('ValidFrom', data.validFrom);
      formData.append('StateCode', data.stateCode);
      formData.append('File', data.File || new Blob(), data.File?.name || '');
      const { data: resData } = await apiClient.post(`/cp/api/feeSchedules/documents`, formData);

      return resData;
    } catch (error) {
      return rejectWithValue(error.response.data.errorMessage);
    }
  },
);

export const deleteDocument = createAsyncThunk(
  'feeScheduleSlice/getDocuments',
  async ({ documentId }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.delete(`/cp/api/feeSchedules/documents/${documentId}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errorMessage);
    }
  },
);

const initialState = {
  documents: [],
};

export const feeScheduleSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: {
    [getDocuments.fulfilled]: (state, action) => {
      state.documents = action.payload.result;
    },
    [getDocuments.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default feeScheduleSlice.reducer;
