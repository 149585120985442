/* eslint-disable no-param-reassign */

export function convertObjectValuesToNumber(obj) {
  if (!obj) return {};
  return Object.entries(obj).reduce((result, [key, value]) => {
    const isBooleanValue = typeof value === 'boolean';
    const isEmptyStringValue = typeof value === 'string' && value.trim() === '';
    const isUndefinedValue = typeof value === 'undefined';

    if (isBooleanValue) {
      result[key] = value;
    } else if (isEmptyStringValue) {
      result[key] = null;
    } else if (isUndefinedValue) {
      result[key] = null;
    } else {
      const parsedValue = parseFloat(value);
      if (Number.isNaN(parsedValue)) {
        result[key] = value;
      } else {
        result[key] = parsedValue;
      }
    }
    return result;
  }, {});
}
