import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import Form from '../../../../atoms/Form';
import { palette } from '../../../../../theme/default';
import useAddress from '../../../../../hooks/useAddress';
import { ReactComponent as Edit } from '../../../../../assets/icons/Edit.svg';
import {
  addressBoxes,
  ownerEntitiesColumns,
  addressHeading,
  INITIAL_CURRENT_PAGE,
  qBsClassItemsByStates,
  defaultNonPaymentNoticeTypeOptions,
  PROPERTY_TYPE,
  defaultAZNonPaymentNoticeTypeOptions,
} from './Address.constants';
import Dropdown from '../../../../atoms/Dropdown';
import Field from '../../../../atoms/Field';
import EditInputWithLabel from '../../../../molecules/EditInputWithLabel';
import CustomRadioGroup from '../../../../atoms/CustomRadioGroup/CustomRadioGroup';
import {
  addOwnerEntityRef,
  disableOwnerEntityRef,
  editOwnerEntityRef,
  getCommunities,
  getCourts,
  getManagements,
  getOwnerEntities,
  getOwnerEntityRefs,
  getStates,
  resetCourts,
} from '../../../../../store/slices/addresses/editAddressSlice';
import Table from '../../../../atoms/Table/Table';
import { useNavigationBlockPopup } from '../../../../../hooks/useNavigationBlockPopup';
import { useAddressStateSpecificStore, useStateSpecificStore } from './hooks';
import { resetFeesAndCostsNevada } from '../../../../../store/slices/courts/nevadaCourtsSlice';
import { resetFeesAndCostsArizona } from '../../../../../store/slices/courts/arizonaCourtsSlice';
import { parseNumberFromString } from '../../../../../utils/parseNumberFromString';
import notificationUtils from '../../../../../utils/notificationUtils';
import LoaderCover from '../../../../atoms/LoaderCover';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';
import NotificationDialog from '../../../../molecules/NotificationDialog';
import NewCorporateAddressDialog from './NewCorporateAddressDialog';

const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

const Address = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    state: { navPage, subNav, id, state: defaultStateCode },
  } = useLocation();
  const { popup, handleChangeBlockerByDefault } = useNavigationBlockPopup({
    blockerByDefault: !id,
  });

  const { ownerEntities, ownerEntityRefs, courts, communities, managements, states } = useSelector(
    (state) => state.editAddress,
  );

  const canUpdate = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);

  const { state, actions } = useAddress();
  const form = useForm({ defaultValues: state });

  form.register('ownerEntityRefs', {
    validate: (value) => !!value?.length || 'Please add at least one Owner Entity',
  });

  const stateAndActions = useAddressStateSpecificStore({ stateCode: defaultStateCode.value });
  const courtStateAndActions = useStateSpecificStore({ stateCode: defaultStateCode.value });
  const { address: editableAddress } = stateAndActions;

  const [isPageLoader, setIsPageLoader] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [ownerEntitiesCurrentPage, setOwnerEntitiesCurrentPage] = useState(INITIAL_CURRENT_PAGE);
  const [managementsCurrentPage, setManagementsCurrentPage] = useState(INITIAL_CURRENT_PAGE);
  const [communitiesCurrentPage, setCommunitiesCurrentPage] = useState(INITIAL_CURRENT_PAGE);
  const [courtsCurrentPage, setCourtsCurrentPage] = useState(INITIAL_CURRENT_PAGE);
  const [confirmationChangeCourtDialog, setConfirmationChangeCourtDialog] = useState(false);
  const [confirmationSetToCorporate, setConfirmationSetToCorporate] = useState(false);
  const [confirmationUnsetToCorporate, setConfirmationUnsetToCorporate] = useState(false);
  const [existingCorporateAddress, setExistingCorporateAddress] = useState('');

  const isFormFieldTouched = !!Object.keys(form.formState.dirtyFields).length;

  const quickBooksClassOptions = useMemo(
    () =>
      qBsClassItemsByStates[defaultStateCode?.value]?.map((item) => ({
        id: item,
        label: item,
        value: item,
      })),
    [defaultStateCode?.value],
  );

  useEffect(() => {
    if (id) {
      Promise.all([
        dispatch(getOwnerEntityRefs(id)),
        dispatch(stateAndActions.getAddress(id)),
      ]).then((data) => {
        const addressData = data[1].payload.result;
        const propertyType =
          PROPERTY_TYPE.find((item) => item.value === addressData.propertyType) || PROPERTY_TYPE[0];

        const nonPaymentNoticeType = {
          value: addressData.defaultNonPaymentNoticeType,
          label: addressData.defaultNonPaymentNoticeType,
        };

        form.setValue('defaultNonPaymentNoticeType', nonPaymentNoticeType);
        form.setValue('propertyType', propertyType);

        setIsPageLoader(false);
      });
    } else {
      setIsPageLoader(false);
    }
  }, [id]);

  useEffect(() => {
    dispatch(getCommunities());
    dispatch(getOwnerEntities());
    dispatch(getManagements());
    dispatch(getStates());

    return () => {
      dispatch(resetFeesAndCostsNevada());
      dispatch(resetFeesAndCostsArizona());
    };
  }, []);

  useEffect(() => {
    if (defaultStateCode) {
      form.setValue('contactInfo.stateCode', defaultStateCode);
    }
  }, [defaultStateCode]);

  const stateCodeValue = form.watch('contactInfo.stateCode');

  useEffect(() => {
    if (id && !isFormFieldTouched) {
      form.reset({
        ...editableAddress,
        propertyType: editableAddress?.propertyType
          ? PROPERTY_TYPE.find((item) => item.value === editableAddress.propertyType) ||
            PROPERTY_TYPE[0]
          : null,
        managementName: editableAddress?.managementId
          ? {
              id: editableAddress.managementId,
              value: editableAddress.managementName,
              label: editableAddress.managementName,
            }
          : null,
        communityName: editableAddress?.communityId
          ? {
              id: editableAddress.communityId,
              value: editableAddress.communityName,
              label: editableAddress.communityName,
            }
          : null,
        courtName: {
          label: editableAddress.courtName,
          id: editableAddress.courtId,
          value: editableAddress.courtId,
        },
        superiorCourtName: {
          label: editableAddress.superiorCourtName,
          id: editableAddress.superiorCourtId,
          value: editableAddress.superiorCourtId,
        },
        justiceCourtName: {
          label: editableAddress.justiceCourtName,
          id: editableAddress.justiceCourtId,
          value: editableAddress.justiceCourtId,
        },
        contactInfo: {
          ...editableAddress.contactInfo,
          stateCode: states.find((item) => item.label === editableAddress?.contactInfo?.stateCode),
        },
        quickBooksClass: quickBooksClassOptions.find(
          (item) => item.label === editableAddress.quickBooksClass,
        ),
        defaultNonPaymentNoticeType: defaultNonPaymentNoticeTypeOptions.find(
          (item) => item.value === editableAddress.defaultNonPaymentNoticeType,
        ),
      });
    }
  }, [id, editableAddress, states]);

  useEffect(() => {
    if (defaultStateCode.value === 'AZ') {
      if (editableAddress.justiceCourtId) {
        dispatch(
          courtStateAndActions.getJusticeFeesAndCosts({ id: editableAddress.justiceCourtId }),
        );
      }
      if (editableAddress.superiorCourtId) {
        dispatch(
          courtStateAndActions.getSuperiorFeesAndCosts({ id: editableAddress.superiorCourtId }),
        );
      }
    }
    if (defaultStateCode.value === 'NV' && editableAddress.courtId) {
      dispatch(courtStateAndActions.getFeesAndCosts({ id: editableAddress.courtId }));
    }
  }, [editableAddress.courtId, editableAddress.superiorCourtId, editableAddress.justiceCourtId]);

  useEffect(() => {
    if (id && isFormFieldTouched) {
      form.setValue('ownerEntityRefs', ownerEntityRefs);
    }
  }, [id, ownerEntityRefs]);

  const handleSubmit = useCallback(
    (formFields, ev, skipConfirmation = null) => {
      const {
        propertyType,
        ownerEntityRefs: ownerEntityRefsData,
        communityName,
        courtName,
        justiceCourtName,
        justiceCourtId,
        superiorCourtId,
        superiorCourtName,
        defaultNonPaymentNoticeType,
        ownerEntities: formFieldOwnerEntities,
        managementName,
        quickBooksClass: formFieldQBsClass,
        ...restFormFields
      } = formFields;

      const courtId =
        formFields.contactInfo.stateCode.value === 'AZ' ? justiceCourtName.id : courtName.id;
      const currentCourtId =
        formFields.contactInfo.stateCode.value === 'AZ'
          ? editableAddress?.justiceCourtId
          : editableAddress?.courtId;
      if (
        !skipConfirmation?.skipCourtConfirmation &&
        editableAddress &&
        courtId !== currentCourtId &&
        editableAddress?.caseCount > 0
      ) {
        setConfirmationChangeCourtDialog(true);
      } else if (
        // changes from Yes To No corporateAddress field
        id &&
        !skipConfirmation?.skipConfirmationUnsetToCorporate &&
        editableAddress &&
        !restFormFields.isCorporateAddress &&
        editableAddress.isCorporateAddress !== restFormFields.isCorporateAddress
      ) {
        setConfirmationUnsetToCorporate(true);
      } else {
        setConfirmationChangeCourtDialog(false);
        setConfirmationSetToCorporate(false);
        setConfirmationUnsetToCorporate(false);
        const {
          stateCode: { value: stateCode },
          ...restContactInfo
        } = formFields.contactInfo;

        let formDataForSubmit = {
          ...restFormFields,
          propertyType: propertyType.value,
          managementId: managementName.id,
          ...(communityName?.id && { communityId: communityName.id }),
          contactInfo: {
            ...restContactInfo,
            stateCode,
          },
          quickBooksClass: formFieldQBsClass.label,
          distanceToCourt: parseNumberFromString(formFields.distanceToCourt),
        };

        if (stateCode === 'AZ') {
          const defaultNonPaymentNoticeTypeValue = defaultNonPaymentNoticeType?.value || null;
          formDataForSubmit = {
            ...formDataForSubmit,
            defaultNonPaymentNoticeType: defaultNonPaymentNoticeTypeValue,
            justiceCourtId: justiceCourtName.id,
          };
        }

        if (stateCode === 'NV') {
          const defaultNonPaymentNoticeTypeValue = defaultNonPaymentNoticeType?.value || null;

          formDataForSubmit = {
            ...formDataForSubmit,
            defaultNonPaymentNoticeType: defaultNonPaymentNoticeTypeValue,
            courtId: courtName.id,
          };
        }

        setIsLoader(true);
        if (id) {
          dispatch(
            stateAndActions.updateAddress({
              ...formDataForSubmit,
              id,
              skipChecking: Boolean(skipConfirmation?.skipConfirmationSetToCorporate),
            }),
          )
            .unwrap()
            .then(() => {
              dispatch(stateAndActions.getAddress(id));
              notificationUtils.success('Updated successfully');
            })
            .catch((rejectedValueError) => {
              if (rejectedValueError.errorCode === 'management.already.has.corporate.address') {
                setConfirmationSetToCorporate(true);
                setExistingCorporateAddress(rejectedValueError?.errorMessage);
              } else {
                notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
              }
            })
            .finally(() => {
              setIsLoader(false);
            });
        } else {
          const formOwnerEntityRefs = form.getValues('ownerEntityRefs') || [];
          handleChangeBlockerByDefault(false);
          const ownerEntityRefsForSubmit = formOwnerEntityRefs.map(({ ownerEntityId, note }) => ({
            ownerEntityId,
            note,
          }));
          dispatch(
            stateAndActions.createAddress({
              ...formDataForSubmit,
              ownerEntities: ownerEntityRefsForSubmit,
              skipChecking: Boolean(skipConfirmation?.skipConfirmationSetToCorporate),
            }),
          )
            .unwrap()
            .then((originalPromiseResult) => {
              const { result } = originalPromiseResult;

              notificationUtils.success('Created successfully');

              navigate('/db/datahandling/editaddress/address', {
                state: {
                  navPage,
                  subNav,
                  id: result,
                  name: formDataForSubmit?.contactInfo?.address,
                  state: states.find(
                    (item) => item.label === formDataForSubmit?.contactInfo?.stateCode,
                  ),
                },
              });
              actions?.clear();
              form.reset();
            })
            .catch((rejectedValueError) => {
              if (rejectedValueError.errorCode === 'management.already.has.corporate.address') {
                setConfirmationSetToCorporate(true);
                setExistingCorporateAddress(rejectedValueError?.errorMessage);
              } else {
                notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
              }
            })
            .finally(() => {
              setIsLoader(false);
            });
        }
      }
    },
    [states, editableAddress],
  );

  const handleCancel = useCallback(() => {
    navigate('/db/datahandling/addresses', {
      state: {
        navPage,
        subNav,
        state: defaultStateCode,
      },
    });
  }, []);

  const getOptions = (inputBoxesItemName) => {
    switch (inputBoxesItemName) {
      case 'propertyType':
        return PROPERTY_TYPE;
      case 'defaultNonPaymentNoticeType':
        return defaultStateCode.value === 'AZ'
          ? defaultAZNonPaymentNoticeTypeOptions
          : defaultNonPaymentNoticeTypeOptions;
      case 'contactInfo.stateCode':
        return states;
      case 'courtName':
      case 'superiorCourtName':
      case 'justiceCourtName':
        return courts.items;
      // TODO: implement after will be implemented from be-side
      // case 'contactInfo.defaultNonPaymentNotice':
      case 'communityName':
        return communities.items;
      case 'managementName':
        return managements.items;
      case 'quickBooksClass':
        return quickBooksClassOptions;
      case 'ownerEntity.name':
        return ownerEntities.items;
      default:
        return [];
    }
  };

  const hasGateCode = form.watch('contactInfo.hasGateCode');

  const getIsMandatory = (name, defaultValue = false) => {
    switch (name) {
      case 'contactInfo.gateCode':
        return hasGateCode;
      default:
        return defaultValue;
    }
  };

  const getIsDisabled = (name, defaultValue = false) => {
    switch (name) {
      case 'contactInfo.gateCode':
        return !hasGateCode;
      default:
        return defaultValue;
    }
  };

  useEffect(() => {
    if (stateCodeValue?.value === 'NV') {
      if (!hasGateCode) {
        form.setValue('contactInfo.gateCode', '');
        form.clearErrors('contactInfo.gateCode');
      }
    }
  }, [hasGateCode, stateCodeValue?.value]);

  const handleMenuScrollToBottomCourts = () => {
    if (courts.hasNextPage) {
      setCourtsCurrentPage(courtsCurrentPage + 1);
    }
  };

  const handleMenuScrollToBottomCommunities = () => {
    if (communities.hasNextPage) {
      setCommunitiesCurrentPage(communitiesCurrentPage + 1);
    }
  };

  const handleMenuScrollToBottomManagements = () => {
    if (managements.hasNextPage) {
      setManagementsCurrentPage(managementsCurrentPage + 1);
    }
  };

  const handleMenuScrollToBottomCourtOwnerEntities = () => {
    if (ownerEntities.hasNextPage) {
      setOwnerEntitiesCurrentPage(ownerEntitiesCurrentPage + 1);
    }
  };

  const handleMenuScrollToBottom = (inputBoxesItemName) => {
    switch (inputBoxesItemName) {
      case 'courtName':
      case 'superiorCourtName':
      case 'justiceCourtName':
        return handleMenuScrollToBottomCourts;
      case 'communityName':
        return handleMenuScrollToBottomCommunities;
      case 'managementName':
        return handleMenuScrollToBottomManagements;
      case 'ownerEntity.name':
        return handleMenuScrollToBottomCourtOwnerEntities;
      default:
        return [];
    }
  };

  const handleAddOwnerEntityRef = () => {
    const { id: ownerEntityId, label: ownerEntityName } = form.getValues('ownerEntity.name') || {};
    const ownerEntityNote = form.getValues('ownerEntity.note');

    if (id && ownerEntityId) {
      setIsUpdating(true);
      dispatch(addOwnerEntityRef({ id, ownerEntityId, note: ownerEntityNote })).then(() => {
        dispatch(getOwnerEntityRefs(id)).then(() => {
          form.setValue('ownerEntity.name', '');
          form.setValue('ownerEntity.note', '');
          setIsUpdating(false);
        });
      });
    } else if (!id && ownerEntityId) {
      const currentOwnerEntityRefs = form.getValues('ownerEntityRefs') || [];
      const updatedOwnerEntityRefs = [
        ...currentOwnerEntityRefs,
        {
          ownerEntityId,
          ownerEntityName,
          note: ownerEntityNote,
          temporaryOwnerEntityRefId: uuidv4(),
        },
      ];

      form.setValue('ownerEntityRefs', updatedOwnerEntityRefs);
    }
  };

  const handleDisableOwnerEntityRef = (ownerEntityRef) => {
    if (id) {
      dispatch(disableOwnerEntityRef({ id, ...ownerEntityRef })).then(() => {
        dispatch(getOwnerEntityRefs(id));
      });
    }
  };

  const handleEditOwnerEntityRefNote = (__, ownerEntityRef) => {
    const formOwnerEntityRefs = form.getValues('ownerEntityRefs') || [];

    if (id) {
      const { note } = formOwnerEntityRefs.find(
        (item) => item.ownerEntityId === ownerEntityRef.ownerEntityId,
      );

      if (note !== ownerEntityRef.note) {
        dispatch(editOwnerEntityRef({ id, ...ownerEntityRef })).then(() =>
          dispatch(getOwnerEntityRefs(id)),
        );
      }
    } else {
      form.setValue('ownerEntityRefs', [
        ...formOwnerEntityRefs.map((item) => {
          if (
            item.temporaryOwnerEntityRefId === ownerEntityRef.temporaryOwnerEntityRefId &&
            item.note !== ownerEntityRef.note
          ) {
            return { ...item, note: ownerEntityRef.note };
          }
          return item;
        }),
      ]);
    }
  };

  const handleDropdownInputChange = (fieldName, dropdownInputValue) => {
    switch (fieldName) {
      case 'courtName':
        if (dropdownInputValue) {
          dispatch(getCourts({ courtName: dropdownInputValue, stateCode: defaultStateCode.value }));
        } else {
          dispatch(resetCourts());
          setCourtsCurrentPage(INITIAL_CURRENT_PAGE);
        }
        break;
      case 'justiceCourtName':
        if (dropdownInputValue) {
          dispatch(
            getCourts({
              courtName: dropdownInputValue,
              stateCode: defaultStateCode.value,
              courtType: 'Justice',
            }),
          );
        } else {
          dispatch(resetCourts());
          setCourtsCurrentPage(INITIAL_CURRENT_PAGE);
        }
        break;
      case 'superiorCourtName':
        if (dropdownInputValue) {
          dispatch(
            getCourts({
              courtName: dropdownInputValue,
              stateCode: defaultStateCode.value,
              courtType: 'Superior',
            }),
          );
        } else {
          dispatch(resetCourts());
          setCourtsCurrentPage(INITIAL_CURRENT_PAGE);
        }
        break;
      case 'communityName':
        if (dropdownInputValue) {
          dispatch(getCommunities({ communityName: dropdownInputValue }));
        } else {
          dispatch(getCommunities());
          setCommunitiesCurrentPage(INITIAL_CURRENT_PAGE);
        }
        break;
      case 'managementName':
        if (dropdownInputValue) {
          dispatch(getManagements({ managementName: dropdownInputValue }));
        } else {
          dispatch(getManagements());
          setManagementsCurrentPage(INITIAL_CURRENT_PAGE);
        }
        break;
      case 'ownerEntity.name':
        if (dropdownInputValue) {
          dispatch(getOwnerEntities({ ownerEntityName: dropdownInputValue }));
        } else {
          dispatch(getOwnerEntities());
          setOwnerEntitiesCurrentPage(INITIAL_CURRENT_PAGE);
        }
        break;
      default:
        break;
    }
  };

  const handleMenuOpen = (fieldName) => () => {
    switch (fieldName) {
      case 'courtName':
        dispatch(getCourts({ stateCode: defaultStateCode.value }));
        break;
      case 'justiceCourtName':
        dispatch(
          getCourts({
            stateCode: defaultStateCode.value,
            courtType: 'Justice',
          }),
        );
        break;
      case 'superiorCourtName':
        dispatch(
          getCourts({
            stateCode: defaultStateCode.value,
            courtType: 'Superior',
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleDropdownChange = (onCustomChange, onChange, name) => async (value) => {
    onCustomChange(onChange)(value);
    switch (name) {
      case 'courtName':
        dispatch(courtStateAndActions.getFeesAndCosts({ id: value.id }));
        break;
      case 'justiceCourtName':
        form.setValue('superiorCourtName', {
          id: value.superiorCourtId,
          value: value.superiorCourtId,
          label: value.superiorCourtName,
        });

        dispatch(courtStateAndActions.getJusticeFeesAndCosts({ id: value.id }));
        dispatch(courtStateAndActions.getSuperiorFeesAndCosts({ id: value.superiorCourtId }));
        break;
      case 'superiorCourtName':
        dispatch(courtStateAndActions.getSuperiorFeesAndCosts({ id: value.id }));
        break;
      default:
    }
  };

  const revertToCourtDefaults = () => {
    if (defaultStateCode.value === 'AZ') {
      form.reset({
        ...form.getValues(),
        justiceFeesAndCosts: courtStateAndActions.justiceFeesAndCosts?.externalFeesAndCosts || {},
        superiorFeesAndCosts: courtStateAndActions.superiorFeesAndCosts?.externalFeesAndCosts || {},
      });
    }
    if (defaultStateCode.value === 'NV') {
      form.reset({
        ...form.getValues(),
        feesAndCosts: courtStateAndActions.feesAndCosts?.feesAndCosts || {},
      });
    }
  };

  useEffect(() => {
    if (courtStateAndActions.feesAndCosts) {
      if (!id || (id && !editableAddress.feesAndCosts)) {
        form.reset({
          ...form.getValues(),
          feesAndCosts: courtStateAndActions.feesAndCosts.feesAndCosts,
        });
      }
    }
  }, [courtStateAndActions.feesAndCosts, editableAddress.feesAndCosts]);

  useEffect(() => {
    if (courtStateAndActions.justiceFeesAndCosts) {
      if (!id || (id && !editableAddress.justiceFeesAndCosts)) {
        form.reset({
          ...form.getValues(),
          justiceFeesAndCosts: courtStateAndActions.justiceFeesAndCosts.externalFeesAndCosts,
        });
      }
    }
  }, [courtStateAndActions.justiceFeesAndCosts, editableAddress.justiceFeesAndCosts]);

  useEffect(() => {
    if (courtStateAndActions.superiorFeesAndCosts) {
      if (!id || (id && !editableAddress.superiorFeesAndCosts)) {
        form.reset({
          ...form.getValues(),
          superiorFeesAndCosts: courtStateAndActions.superiorFeesAndCosts.externalFeesAndCosts,
        });
      }
    }
  }, [courtStateAndActions.superiorFeesAndCosts, editableAddress.superiorFeesAndCosts]);

  useEffect(() => {
    if (ownerEntitiesCurrentPage > INITIAL_CURRENT_PAGE) {
      dispatch(getOwnerEntities({ currentPage: ownerEntitiesCurrentPage }));
    }
  }, [ownerEntitiesCurrentPage]);

  useEffect(() => {
    if (communitiesCurrentPage > INITIAL_CURRENT_PAGE) {
      dispatch(getCommunities({ currentPage: communitiesCurrentPage }));
    }
  }, [communitiesCurrentPage]);

  useEffect(() => {
    if (courtsCurrentPage > INITIAL_CURRENT_PAGE) {
      dispatch(getCourts({ currentPage: courtsCurrentPage, stateCode: stateCodeValue.value }));
    }
  }, [courtsCurrentPage]);

  useEffect(() => {
    if (managementsCurrentPage > INITIAL_CURRENT_PAGE) {
      dispatch(getManagements({ currentPage: managementsCurrentPage }));
    }
  }, [managementsCurrentPage]);

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  const handleChangeManagementCorporateAddress = (newCorporateAddress) => {
    const values = form.getValues();

    handleSubmit(
      { ...values, ...{ newAddressId: newCorporateAddress.managementCorporateAddressId } },
      null,
      {
        skipConfirmationUnsetToCorporate: true,
      },
    );
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      {popup}
      {!(isPageLoader || !states.length) ? (
        <>
          {isLoader && <LoaderCover isFixed />}
          {confirmationChangeCourtDialog && (
            <NotificationDialog
              title="Notification message"
              desc={
                <>
                  Address update includes updating the associated court. This action will update the
                  associated court in {editableAddress?.caseCount || 0} existing cases with the same
                  address.
                  <br />
                  <br />
                  Are you sure you want to proceed with the address update?
                </>
              }
              buttonPrimaryText="Update Address"
              buttonSecondaryText="Back to Address"
              buttonPrimaryWidth="170px"
              buttonSecondaryWidth="175px"
              onConfirm={(ev) =>
                handleSubmit(form.getValues(), ev, { skipCourtConfirmation: true })
              }
              onCancel={() => setConfirmationChangeCourtDialog(false)}
              isOpen={confirmationChangeCourtDialog}
              type="warning"
              width="800px"
            />
          )}
          {confirmationSetToCorporate && (
            <NotificationDialog
              title={
                stateCodeValue.value === 'AZ'
                  ? 'Change the Arizona Corporate Address'
                  : 'Change the Nevada Corporate Address'
              }
              desc={
                <>
                  <Typography variant="bodyL" sx={{ fontWeight: 700 }}>
                    {form.getValues('managementName').value}
                  </Typography>{' '}
                  management company has {stateCodeValue.value === 'AZ' ? 'Arizona' : 'Nevada'}{' '}
                  corporate address (
                  <Typography variant="bodyL" sx={{ fontWeight: 700 }}>
                    {existingCorporateAddress}
                  </Typography>
                  ) set up already.
                  <br />
                  <br />
                  Are you sure you want to change it?
                </>
              }
              buttonPrimaryText="Change Address"
              buttonSecondaryText="Cancel"
              buttonPrimaryWidth="175px"
              buttonSecondaryWidth="105px"
              onConfirm={(ev) =>
                handleSubmit(form.getValues(), ev, { skipConfirmationSetToCorporate: true })
              }
              onCancel={() => setConfirmationSetToCorporate(false)}
              isOpen={confirmationSetToCorporate}
              type="warning"
              width="800px"
            />
          )}
          {confirmationUnsetToCorporate && (
            <NewCorporateAddressDialog
              isOpen={confirmationUnsetToCorporate}
              onClose={() => setConfirmationUnsetToCorporate(false)}
              stateCode={stateCodeValue.value}
              managementName={form.getValues('managementName').value}
              onSubmit={handleChangeManagementCorporateAddress}
              managementId={form.getValues('managementName').id}
            />
          )}
          <Typography variant="h4">{addressHeading}</Typography>
          <Form onSubmit={handleSubmit} form={form}>
            {addressBoxes?.map((addressBox) => {
              const inputStateCode = stateCodeValue?.value;
              const inputBoxes =
                typeof addressBox.inputBoxes === 'function'
                  ? addressBox.inputBoxes({
                      stateCode: inputStateCode,
                      hasGateCode,
                    })
                  : addressBox.inputBoxes;
              return (
                <Container
                  key={addressBox.name}
                  maxWidth={false}
                  disableGutters
                  sx={{
                    mx: 'auto',
                    mt: 4,
                    width: 1,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={3}
                    rowGap={2}
                    flexWrap="wrap"
                  >
                    {inputBoxes.map((item) => {
                      const key =
                        typeof addressBox.inputBoxes === 'function'
                          ? `${inputStateCode}${item.name || item.label}`
                          : item.name || item.label;
                      if (item.isDropdown) {
                        return (
                          <Field
                            key={key}
                            name={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, onCustomChange, error }) => {
                              if (id && !canUpdate)
                                return (
                                  <EditInputWithLabel
                                    type="text"
                                    label={item.label}
                                    width={item.width}
                                    name={item.name}
                                    value={field?.value?.label || '-'}
                                    readOnly
                                  />
                                );
                              return (
                                <Dropdown
                                  value={field.value}
                                  isAddDisabled={false}
                                  isSearchable
                                  label={item.label}
                                  isDisabled={item.isDisabled}
                                  width={item.width}
                                  onMenuScrollToBottom={handleMenuScrollToBottom(item.name)}
                                  isColumn
                                  error={
                                    item.castErrorFrom
                                      ? form.getFieldState(item.castErrorFrom)?.error || error
                                      : error
                                  }
                                  isMandatory={item.isMandatory || item.isVisuallyRequired}
                                  placeholder={item.placeholder}
                                  options={getOptions(item.name)}
                                  onMenuOpen={handleMenuOpen(item.name)}
                                  onChange={handleDropdownChange(
                                    onCustomChange,
                                    field.onChange,
                                    item.name,
                                  )}
                                  onInputChange={(inputValue) =>
                                    handleDropdownInputChange(item.name, inputValue)
                                  }
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item.isRadioButtons) {
                        return (
                          <Field
                            key={key}
                            name={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, onCustomChange }) => {
                              if (id && !canUpdate) {
                                return (
                                  <EditInputWithLabel
                                    type="text"
                                    label={item.label}
                                    width={item.width}
                                    name={item.name}
                                    value={
                                      item.data.find((i) => i.value === field.value)?.label || '-'
                                    }
                                    readOnly
                                  />
                                );
                              }
                              return (
                                <CustomRadioGroup
                                  data={item.data}
                                  value={field.value}
                                  label={item.label}
                                  width={item.width}
                                  isMandatory={item.isMandatory}
                                  radioButtonWidth={item.radioButtonWidth}
                                  onChange={onCustomChange(field.onChange)}
                                  defaultValue={getRadioGroupDefaultValue(item.data)}
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item?.isSpace) {
                        return <div style={{ width: '100%' }} key={key} />;
                      }

                      if (item.isSubHeading) {
                        return (
                          <StyledTypography width={1} mb={1} variant="bodyL500" key={key}>
                            {item.label}
                          </StyledTypography>
                        );
                      }

                      if (item.isCourtLabel) {
                        return (
                          <StyledTypography width={1} mb={1} variant="bodyL500" key={key}>
                            {item.label}
                          </StyledTypography>
                        );
                      }

                      if (item.isSubHeadingWithRevert) {
                        return (
                          <React.Fragment key={key}>
                            <Stack
                              direction="row"
                              alignContent="center"
                              columnGap={2}
                              mt={3}
                              width="100%"
                            >
                              <StyledTypography variant="bodyL500">{item.label}</StyledTypography>
                              {canUpdate && (
                                <MButton
                                  size="small"
                                  sx={{ width: '200px' }}
                                  variant="secondary"
                                  onClick={revertToCourtDefaults}
                                >
                                  Revert to Court Defaults
                                </MButton>
                              )}
                            </Stack>
                            <Stack
                              direction="row"
                              alignContent="center"
                              columnGap={2}
                              mt={1}
                              mb={1}
                              width="100%"
                            >
                              <StyledTypography variant="bodyL500" color="text.tertiary">
                                External Billing Fees
                              </StyledTypography>
                            </Stack>
                          </React.Fragment>
                        );
                      }
                      if (item.isSubHeadingWithButton) {
                        if (!canUpdate) return null;

                        return (
                          <Stack
                            direction="row"
                            alignContent="center"
                            columnGap={2}
                            mt={3}
                            key={key}
                          >
                            <StyledTypography width={1} variant="bodyL500">
                              {item.label}
                            </StyledTypography>
                            <MButton
                              onClick={handleAddOwnerEntityRef}
                              disabled={isUpdating}
                              startIcon={item.buttonIcon}
                            >
                              {item.buttonName}
                            </MButton>
                          </Stack>
                        );
                      }

                      if (item.isTable) {
                        return (
                          <Field
                            key={key}
                            name={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field }) => {
                              return (
                                !!field?.value?.length && (
                                  <Table
                                    columns={ownerEntitiesColumns}
                                    rows={field.value}
                                    total={field?.value?.length}
                                    onChange={handleDisableOwnerEntityRef}
                                    loading={false}
                                    onUpdateData={handleEditOwnerEntityRefNote}
                                  />
                                )
                              );
                            }}
                          />
                        );
                      }

                      const getSuffix = (name, value) => {
                        if (
                          name.startsWith('feesAndCosts') ||
                          name.startsWith('justiceFeesAndCosts') ||
                          name.startsWith('superiorFeesAndCosts')
                        ) {
                          const map =
                            defaultStateCode.value === 'AZ'
                              ? {
                                  justiceFeesAndCosts:
                                    courtStateAndActions.justiceFeesAndCosts?.externalFeesAndCosts,
                                  superiorFeesAndCosts:
                                    courtStateAndActions.superiorFeesAndCosts?.externalFeesAndCosts,
                                }
                              : {
                                  feesAndCosts: courtStateAndActions.feesAndCosts?.feesAndCosts,
                                };
                          const cortValue = _.get(map, name);
                          if (
                            cortValue !== undefined &&
                            cortValue?.toString() !== value?.toString()
                          ) {
                            return `Special rate, normally: ${cortValue}`;
                          }
                          return null;
                        }
                        return null;
                      };
                      return (
                        <Field
                          key={key}
                          name={item.name}
                          isMandatory={item.isMandatory}
                          render={({ field, error }) => {
                            const suffix = getSuffix(item.name, field.value);
                            const isMandatory = getIsMandatory(item.name, item.isMandatory);
                            const isDisabled = getIsDisabled(item.name, item.isDisabled);
                            return (
                              <EditInputWithLabel
                                type="text"
                                error={error}
                                label={item.label}
                                placeholder={item.placeholder}
                                width={item.width}
                                name={item.name}
                                isMandatory={((id && canUpdate) || !id) && isMandatory}
                                value={id && !canUpdate ? field.value || '-' : field.value}
                                onChange={field.onChange}
                                isDisabled={isDisabled}
                                readOnly={id && !canUpdate}
                                suffix={
                                  suffix ? (
                                    <Box display="inline-flex" ml={2} title={suffix}>
                                      <Tooltip>
                                        <Edit />
                                      </Tooltip>
                                    </Box>
                                  ) : null
                                }
                              />
                            );
                          }}
                        />
                      );
                    })}
                  </Stack>
                </Container>
              );
            })}
            {((id && canUpdate) || !id) && (
              <Box
                position="absolute"
                left="50%"
                bottom={0}
                sx={{ transform: 'translateX(-50%)' }}
                zIndex={10}
                width={1}
                p={4}
                boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
              >
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <MButton
                    variant="secondary"
                    size="large"
                    onClick={handleCancel}
                    sx={{ width: '144px' }}
                    disabled={isLoader}
                  >
                    Cancel
                  </MButton>
                  <MButton type="submit" size="large" sx={{ width: '144px' }} disabled={isLoader}>
                    {id ? 'Update' : 'Create'}
                  </MButton>
                </Stack>
              </Box>
            )}
          </Form>
        </>
      ) : (
        <Box
          position="absolute"
          top="50vh"
          left="50%"
          zIndex={10}
          transform="translate(-50%, -50%)"
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default Address;
