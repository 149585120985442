/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const createNoticeIntake = createAsyncThunk(
  'NoticeIntake/createNoticeIntake',
  async (body) => {
    const { data } = await apiClient.post(`/cp/api/requests/noticeIntake`, body);
    return data;
  },
);

export const getAddresses = createAsyncThunk(
  'NoticeIntake/getAddresses',
  async (params = {}, thunkAPI) => {
    const {
      getAddresses: { items: prevItems = [] },
    } = thunkAPI.getState().noticeIntake;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/cp/api/addresses/filter`, params);

    const currentStateItems = items.map((address) => ({
      id: address.addressId,
      label: address.address,
      value: address.addressId,
      data: address,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

const initialState = {
  createNoticeIntake: null,
  getAddresses: {
    items: [],
  },
  error: null,
};

export const noticeIntakeSlice = createSlice({
  name: 'noticeIntake',
  initialState,
  reducers: {},
  extraReducers: {
    [createNoticeIntake.fulfilled]: (state, action) => {
      state.createNoticeIntake = action.payload;
    },
    [createNoticeIntake.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.getAddresses = action.payload;
    },
    [getAddresses.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default noticeIntakeSlice.reducer;
