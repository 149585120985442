import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  Container,
  Stack,
  Typography,
  Box,
  Accordion,
  styled,
  AccordionSummary,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { caseInformationForm } from './CivilMatterCaseInformationArizona.constants';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import Dropdown from '../../../atoms/Dropdown';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import MButton from '../../../MUI/Button/MButton';
import {
  updateArizonaCaseNumber,
  getArizonaCaseInformation,
  resetArizonaCaseInformation,
  getArizonaLegalAssistants,
  getArizonaStatusesForCase,
} from '../../../../store/slices/cases/caseInformationArizonaSlice';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import notificationUtils from '../../../../utils/notificationUtils';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import { fullNameFromObj } from '../../../../utils/fullNameFromObj';
import LoaderCover from '../../../atoms/LoaderCover';

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
    '.MuiPaper-root': {
      boxShadow: '0px 3px 20px rgba(69, 80, 121, 0.13)',
      marginBottom: '16px',
      borderRadius: '24px',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '16px',
      height: '16px',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 32px 32px',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

const CaseInformation = () => {
  const form = useForm();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = location.state;

  const [isLoader, setIsLoader] = useState(false);

  const { boxTitle, inputBoxes } = caseInformationForm;

  const { arizonaCaseInformation, legalAssistants } = useSelector(
    (state) => state.caseInformationArizona,
  );

  const fetchData = () => {
    dispatch(getArizonaCaseInformation(id));
    dispatch(getArizonaLegalAssistants(id));
  };

  useEffect(() => {
    fetchData();
    return () => dispatch(resetArizonaCaseInformation());
  }, []);

  useEffect(() => {
    const { assignedLegalAssistant } = arizonaCaseInformation;
    form.setValue('caseNumber', arizonaCaseInformation.caseNumber);
    form.setValue('poNumber', arizonaCaseInformation.poNumber);
    form.setValue(
      'assignedLegalAssistant',
      assignedLegalAssistant
        ? {
            id: assignedLegalAssistant.userId,
            label: fullNameFromObj(assignedLegalAssistant),
            value: assignedLegalAssistant.userId,
          }
        : null,
    );
  }, [arizonaCaseInformation]);

  const canAssignLegalAssistant = useAllowed([rolesDB.Admin, rolesDB.LegalAssistant]);

  const getDisabled = (name) => {
    switch (name) {
      case 'assignedLegalAssistant':
        return !canAssignLegalAssistant;
      default:
        return false;
    }
  };

  const getOptions = useCallback(
    (name) => {
      switch (name) {
        case 'assignedLegalAssistant':
          return legalAssistants;
        default:
          return [];
      }
    },
    [legalAssistants],
  );

  const handleSubmit = async (values) => {
    setIsLoader(true);
    const formValues = {
      id,
      caseNumber: values.caseNumber,
      poNumber: values.poNumber,
      assignedLegalAssistantUserId: values.assignedLegalAssistant?.id ?? null,
    };

    await dispatch(updateArizonaCaseNumber(formValues));
    dispatch(getArizonaStatusesForCase(id));
    fetchData();
    notificationUtils.success('Updated successfully');
    setIsLoader(false);
  };

  return (
    <Form onSubmit={handleSubmit} form={form}>
      {isLoader && <LoaderCover isFixed />}
      <StyledContainer
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          width: 1,
          position: 'relative',
        }}
      >
        <StyledAccordion defaultExpanded sx={{ p: 4 }}>
          <AccordionSummary expandIcon={<ArrowDown />} sx={{ padding: 0 }}>
            <Typography variant="h5">{boxTitle}</Typography>
          </AccordionSummary>
          <Stack direction="row" alignItems="center" columnGap={3} rowGap={2} flexWrap="wrap">
            {inputBoxes.map((item) => {
              const getWrapper = (children) =>
                item.withUpdate ? (
                  <Box alignItems="center" display="flex" key={`${item.label}Box`}>
                    {children}
                    <MButton
                      sx={{
                        ml: '24px',
                        mt: '28px',
                      }}
                      type="submit"
                      disabled={isLoader}
                    >
                      Update
                    </MButton>
                  </Box>
                ) : (
                  children
                );

              const getField = () => {
                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <Dropdown
                          value={field.value}
                          isAddDisabled={false}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          isColumn
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={onCustomChange(field.onChange)}
                          isDisabled={getDisabled(item.name) || item.isDisabled}
                          isMandatory={item.isMandatory}
                        />
                      )}
                    />
                  );
                }

                if (item.isSpace) {
                  return <div style={{ width: '100%' }} key={item.label} />;
                }

                return (
                  <Field
                    name={item.name}
                    key={item.name}
                    render={({ field }) => (
                      <EditInputWithLabel
                        type="text"
                        label={item.label}
                        placeholder={item.placeholder}
                        width={item.width}
                        name={item.name}
                        value={field.value}
                        onChange={field.onChange}
                        isDisabled={item.isDisabled}
                      />
                    )}
                  />
                );
              };
              return getWrapper(getField());
            })}
          </Stack>
        </StyledAccordion>
      </StyledContainer>
    </Form>
  );
};

export default CaseInformation;
