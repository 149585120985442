import { LinearProgress, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  billBillingInstance,
  unbillBillingInstance,
  deleteBillingInstance,
} from '../../../../store/slices/generateInvoiceSlice';
import Dropdown from '../../../atoms/Dropdown';
import NotificationDialog from '../../../molecules/NotificationDialog';
import notificationUtils from '../../../../utils/notificationUtils';
import { LightTooltip } from '../../../atoms/MTooltip/MTooltip';

const statuses = {
  UNBILLED: 'Unbilled',
  BILLED: 'Billed',
  REMOVED: 'Removed',
};
const options = [
  { value: statuses.UNBILLED, label: 'Unbilled', id: 1 },
  { value: statuses.BILLED, label: 'Billed', id: 2 },
  { value: statuses.REMOVED, label: 'Removed', id: 3 },
];

const removeOptions = [
  { value: statuses.UNBILLED, label: 'Unbilled', id: 1 },
  { value: statuses.REMOVED, label: 'Removed', id: 3 },
];

const unbilledOptions = [
  { value: statuses.BILLED, label: 'Billed', id: 2 },
  { value: statuses.REMOVED, label: 'Removed', id: 3 },
];

const removeOption = { value: statuses.REMOVED, label: 'Removed', id: 3 };

const BillingStatusCell = ({
  row,
  value: billingStatus,
  refreshPage,
  state: { pageIndex },
  goPreviousPage,
  rows,
  canPreviousPage,
}) => {
  const { billingInstanceId, isCaseDeleted } = row.original;
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [isRemoveDialogOpen, setOpenRemoveDialog] = useState(false);
  const [isRestoreDialog, setOpenRestoreDialog] = useState(false);
  const shouldGoBack = rows?.length === 1 && canPreviousPage;
  const refresh = () => refreshPage(pageIndex + 1);

  const currentOptions = billingStatus === statuses.UNBILLED ? [...options, removeOption] : options;
  const value = currentOptions.find((option) => option.value === billingStatus) ?? null;

  const availableOptionsByStatus = useMemo(() => {
    if (billingStatus === statuses.REMOVED) {
      return removeOptions;
    }
    if (billingStatus === statuses.UNBILLED) {
      return unbilledOptions;
    }
    return options;
  }, [billingStatus]);

  const handleSetBillingStatus = async ({ value: nextValue }) => {
    if (value?.value === nextValue) return;
    if (nextValue === statuses.BILLED) {
      setIsLoader(true);
      await dispatch(billBillingInstance(billingInstanceId));
      refresh();
    } else if (nextValue === statuses.REMOVED) {
      setOpenRemoveDialog(true);
    } else if (nextValue === statuses.UNBILLED && value?.value === statuses.REMOVED) {
      setOpenRestoreDialog(true);
    } else {
      setIsLoader(true);
      await dispatch(unbillBillingInstance(billingInstanceId));
      refresh();
    }
  };

  const closeRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const closeRestoreDialog = () => {
    setOpenRestoreDialog(false);
  };

  const confirmRemove = async () => {
    setIsLoader(true);
    try {
      await dispatch(deleteBillingInstance(billingInstanceId)).unwrap();

      if (shouldGoBack) goPreviousPage();
      else refreshPage();
    } catch {
      notificationUtils.error('Failed, try again later');
    } finally {
      closeRemoveDialog();
      setIsLoader(false);
    }
  };

  const confirmRestore = async () => {
    setIsLoader(true);
    try {
      // set removed to unbill to restore it
      await dispatch(unbillBillingInstance(billingInstanceId)).unwrap();
      refreshPage();
    } catch {
      notificationUtils.error('Failed, try again later');
    } finally {
      closeRestoreDialog();
      setIsLoader(false);
    }
  };

  if (isLoader) {
    return (
      <Box data-testid="LinearProgress" flexGrow={1}>
        <LinearProgress />
      </Box>
    );
  }

  const renderDropdown = () => {
    const dropdown = (
      <Dropdown
        value={value}
        width="100%"
        isColumn
        options={availableOptionsByStatus}
        onChange={handleSetBillingStatus}
        isDisabled={isCaseDeleted && value?.value === statuses.REMOVED}
      />
    );
    if (isCaseDeleted && value?.value === statuses.REMOVED) {
      return (
        <LightTooltip placement="top" title="The parent case of this instance was deleted">
          <div style={{ width: '100%' }}>{dropdown}</div>
        </LightTooltip>
      );
    }
    return dropdown;
  };

  return (
    <>
      <NotificationDialog
        title="Instance Removal"
        desc="Are you sure that you want to remove this billing instance?"
        type="none"
        buttonSecondaryText="Remove Instance"
        buttonPrimaryText="Cancel"
        onClose={closeRemoveDialog} // secondary button is remove action
        onConfirm={closeRemoveDialog} // primary button is cancel
        onCancel={confirmRemove} // secondary button is remove action
        isOpen={!!isRemoveDialogOpen}
        buttonSecondaryWidth="180px"
      />
      <NotificationDialog
        title="Instance Restoration"
        desc={
          <>
            Are you sure you want to restore this billing instance?
            <br />
            {`It will be added to this case's current billing period`}
          </>
        }
        type="none"
        buttonSecondaryText="Restore Instance"
        buttonPrimaryText="Cancel"
        onClose={closeRestoreDialog} // secondary button is remove action
        onConfirm={closeRestoreDialog} // primary button is cancel
        onCancel={confirmRestore} // secondary button is remove action
        isOpen={!!isRestoreDialog}
        buttonSecondaryWidth="180px"
      />
      {renderDropdown()}
    </>
  );
};

BillingStatusCell.propTypes = {
  value: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      billingInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isCaseDeleted: PropTypes.bool,
    }),
  }).isRequired,
  refreshPage: PropTypes.func.isRequired,
  state: PropTypes.shape({
    pageIndex: PropTypes.number.isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  goPreviousPage: PropTypes.func.isRequired,
};

export default BillingStatusCell;
