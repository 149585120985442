import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import useAuth from '../../../hooks/useAuth';
import { getSettings } from '../../../store/slices/legalAssistantDashboardSlice';
import LoaderCover from '../../atoms/LoaderCover';
import { rolesDB } from '../../../utils/roleHelpers';
import LADashboardSettings from './LADashboardSettings';
import AttorneyDashboardSettings from './AttorneyDashboardSettings';

const DashboardSettings = ({ form }) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { userInfo } = useAuth();
  const isAttorney = userInfo.roles.includes(rolesDB.Attorney);
  const isLA = userInfo.roles.includes(rolesDB.LegalAssistant);
  const isAdmin = userInfo.roles.includes(rolesDB.Admin);

  const fetchSettings = async () => {
    setLoading(true);
    try {
      await dispatch(getSettings(userInfo.UserGuid)).unwrap();
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Box>
      {isLoading && <LoaderCover />}
      {(isAdmin || isAttorney) && <AttorneyDashboardSettings form={form} />}
      {(isAdmin || isLA) && <LADashboardSettings form={form} />}
    </Box>
  );
};

DashboardSettings.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    watch: PropTypes.func,
    setValue: PropTypes.func,
  }).isRequired,
};

export default DashboardSettings;
