/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MDialog from '../../../atoms/MDialog';
import MultilineTextField from '../../../atoms/MultilineTextField/MultilineTextField';
import CheckboxSelect from '../../../atoms/CheckboxSelect';
import { Stack } from '@mui/material';
import MButton from '../../../MUI/Button/MButton';

const FAKE_OPTIONS = [
  { id: 0, value: 'Tenant has vacated the property', label: 'Tenant has vacated the property' },
  {
    id: 1,
    value: 'Landlord has received rental assistance funds',
    label: 'Landlord has received rental assistance funds',
  },
  {
    id: 2,
    value: 'Tenant has resolved the issue that was the subject of the eviction',
    label: 'Tenant has resolved the issue that was the subject of the eviction',
  },
  {
    id: 3,
    value: 'Landlord & Tenant have entered into an agreement',
    label: 'Landlord & Tenant have entered into an agreement',
  },
  { id: 4, value: 'Other', label: 'Other' },
];

const ReasonOfDismissal = ({ isOpen, handleConfirm, onClose }) => {
  const [isLoading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setDescription('');
      setSelectedOption([]);
    } else setLoading(false);
  }, [isOpen]);

  const handleDescriptionChange = useCallback((event) => {
    setDescription(event.target.value);
  }, []);

  const handleSelectChange = useCallback((value) => {
    setSelectedOption(value);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, []);

  const isOtherChecked = !!selectedOption.find((o) => o.value === 'Other');

  const isTextInputEditable = useMemo(() => {
    return {
      disabled: !isOtherChecked,
      mandatory: isOtherChecked,
    };
  }, [selectedOption, isOtherChecked]);

  const isCreateButtonDisabled = useMemo(() => {
    if (!selectedOption.length) return false;

    if (isOtherChecked) {
      return description.length === 0;
    }
    return false;
  }, [selectedOption, description, isOtherChecked]);

  const onConfirm = async () => {
    setLoading(true);

    await handleConfirm({
      ReasonOfDismissal: selectedOption.map((o) => o.value).join(','),
      MotionDescription: description,
    });
  };

  return (
    <MDialog
      onClose={onClose}
      isOpen={isOpen}
      maxWidth="582px"
      minHeight="396px"
      fullWidth
      title="Reason of dismissal"
      titleVariant="h4"
    >
      <Stack rowGap={4}>
        <CheckboxSelect
          value={selectedOption}
          label="Select reason"
          placeholder="Select Option"
          options={FAKE_OPTIONS}
          onChange={handleSelectChange}
        />
        <MultilineTextField
          rows={10}
          error={false}
          value={description}
          isMandatory={isTextInputEditable.mandatory}
          label="Motion Description"
          placeholder="Enter Motion Description"
          onChange={handleDescriptionChange}
          disabled={isTextInputEditable.disabled}
        />
        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
          <MButton variant="secondary" size="large" onClick={handleClose} sx={{ width: '104px' }}>
            Cancel
          </MButton>
          <MButton
            size="large"
            onClick={onConfirm}
            sx={{ width: '104px' }}
            disabled={isCreateButtonDisabled || isLoading}
          >
            Create
          </MButton>
        </Stack>
      </Stack>
    </MDialog>
  );
};

export default ReasonOfDismissal;
