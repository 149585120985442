import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { ReactComponent as InfoCircleRed } from '../../../../assets/icons/InfoCircleRed.svg';
import { ReactComponent as CheckCircleGreen } from '../../../../assets/icons/CheckCircleGreen.svg';

export const InvalidCell = ({ value }) => {
  return (
    <Box width="100%" display="flex" justifyContent="center" data-testid="invalid_cell">
      {value ? <InfoCircleRed /> : <CheckCircleGreen />}
    </Box>
  );
};

InvalidCell.propTypes = {
  value: PropTypes.bool.isRequired,
};
