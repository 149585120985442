import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import moment from 'moment';
import MButton from '../../MUI/Button/MButton';
import DatePickerWithLabel from '../../atoms/DatePickerWithLabel/DatePickerWithLabel';
import SearchInputWithLabel from '../../atoms/SearchInputWithLabel/SearchInputWithLabel';
import SearchInputWithLabelAndIcon from '../../atoms/SearchInputWithLabelAndIcon/SearchInputWithLabelAndIcon';
import Table from '../../atoms/Table/Table';
import {
  COMMUNITY_FIELD_NAME,
  DROPDOWN_SIZE,
  INVOICES_FILTER,
  INVOICES_LABEL,
  INVOICES_TABLE_COLUMNS,
  INVOICENUM_FIELD_NAME,
  STATUS_FIELD_NAME,
  FROMDATE_FIELD_NAME,
  TODATE_FIELD_NAME,
  PAGE_SIZE,
  INVOICE_STATUSES,
} from './Invoices.constants';
import {
  getAddressesList,
  getCommunitiesList,
  getInvoices,
  resetCommunities,
  resetInvoices,
} from '../../../store/slices/invoicesSlice';
import useSearchDropdown from '../../../hooks/useSearchDropdown';
import EmptyBlock from '../../molecules/EmptyBlock';
import useSafeLocationState from '../../../hooks/useSafeLocationState';

const Invoices = () => {
  useSafeLocationState('Invoices');
  const dispatch = useDispatch();

  const [isInvoicesFetched, setIsInvoicesFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [communityId, setCommunityId] = useState(null);

  const { addresses, communities, invoices } = useSelector((state) => state.invoices);

  const handleFetchDataPage = useCallback(
    (currentPage = 1, addFilters = true) => {
      setIsLoading(true);

      const params = {
        currentPage,
        pageSize: PAGE_SIZE,
      };

      if (addFilters) {
        if (invoiceNumber) params.invoiceNumber = invoiceNumber;
        if (communityId) params.communityId = communityId.id;
        if (status) params.status = status.value;
        if (fromDate) params.fromDate = moment(fromDate, moment.ISO_8601);
        if (toDate) params.toDate = moment(toDate, moment.ISO_8601);
      }

      dispatch(getInvoices(params)).finally(() => {
        if (!isInvoicesFetched) setIsInvoicesFetched(true);
        setIsLoading(false);
      });
    },
    [invoiceNumber, communityId, status, fromDate, toDate],
  );

  const handleClearFilters = useCallback(() => {
    setInvoiceNumber('');
    setFromDate(null);
    setToDate(null);
    setStatus(null);
    setCommunityId(null);
    handleFetchDataPage(1, false);
  }, []);

  useEffect(() => {
    return () => dispatch(resetInvoices());
  }, []);

  const { handleMenuScrollToBottom: handleAddressScrollToBottom, changeInput: changeAddressInput } =
    useSearchDropdown({
      hasNextPage: addresses?.hasNextPage,
      getAction: ({ input, page }) =>
        getAddressesList({
          currentPage: page,
          pageSize: DROPDOWN_SIZE,
          address: input,
          open: false,
        }),
    });

  const {
    handleMenuScrollToBottom: handleCommunitiesScrollToBottom,
    changeInput: changeCommunitiesInput,
  } = useSearchDropdown({
    hasNextPage: communities?.hasNextPage,
    getAction: ({ input, page }) =>
      getCommunitiesList({
        currentPage: page,
        pageSize: DROPDOWN_SIZE,
        communityName: input,
        open: false,
      }),
  });

  const onCloseCommunitiesMenu = () => {
    dispatch(resetCommunities());
  };

  const onOpenCommunitiesMenu = () => {
    setIsLoading(true);
    dispatch(
      getCommunitiesList({
        currentPage: 1,
        pageSize: DROPDOWN_SIZE,
        communityName: '',
        open: true,
      }),
    ).then(() => {
      setIsLoading(false);
    });
  };

  const resolveCallbacks = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case COMMUNITY_FIELD_NAME:
          return {
            scrollToBottom: handleCommunitiesScrollToBottom,
            inputSearch: changeCommunitiesInput,
            onMenuClose: onCloseCommunitiesMenu,
            onMenuOpen: onOpenCommunitiesMenu,
          };
        default:
          return {
            scrollToBottom: () => {},
            inputSearch: () => {},
            onMenuClose: () => {},
            onMenuOpen: () => {},
          };
      }
    },
    [
      handleCommunitiesScrollToBottom,
      changeCommunitiesInput,
      handleAddressScrollToBottom,
      changeAddressInput,
    ],
  );

  const resolveOptions = useCallback(
    (fieldName) => {
      const optionsMap = {
        [COMMUNITY_FIELD_NAME]: communities?.items || [],
        [STATUS_FIELD_NAME]: INVOICE_STATUSES,
      };

      return optionsMap[fieldName] || [];
    },
    [addresses, communities],
  );

  const resolveValue = useCallback(
    (fieldName) => {
      const values = {
        [INVOICENUM_FIELD_NAME]: invoiceNumber,
        [COMMUNITY_FIELD_NAME]: communityId,
        [STATUS_FIELD_NAME]: status,
        [FROMDATE_FIELD_NAME]: fromDate,
        [TODATE_FIELD_NAME]: toDate,
      };

      return values[fieldName];
    },
    [fromDate, toDate, status, communityId, invoiceNumber],
  );

  const resolveOnChangeCallback = useCallback((fieldName) => {
    const cb = {
      [INVOICENUM_FIELD_NAME]: setInvoiceNumber,
      [COMMUNITY_FIELD_NAME]: setCommunityId,
      [STATUS_FIELD_NAME]: setStatus,
      [FROMDATE_FIELD_NAME]: setFromDate,
      [TODATE_FIELD_NAME]: setToDate,
    };

    return (value) => {
      cb[fieldName](value);
    };
  }, []);

  const showClearFilterButton = useMemo(() => {
    return invoiceNumber || communityId || status || fromDate || toDate;
  }, [invoiceNumber, communityId, status, fromDate, toDate]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        px: 4,
        position: 'relative',
      }}
    >
      {isLoading && (
        <Box
          position="absolute"
          width="100%"
          height="calc(100% - 80px)"
          top="-80px"
          left="0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={10}
          transform="translate(-50%, -50%)"
        >
          <CircularProgress />
        </Box>
      )}

      <Stack flex justifyContent="flex-start" alignItems="center" flexDirection="initial" mb={4}>
        <Typography variant="h3" pb={1}>
          {INVOICES_LABEL}
        </Typography>
      </Stack>
      <Stack
        columnGap={3}
        rowGap={2}
        sx={{
          display: 'flex',
          flexDirection: 'revert',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        {INVOICES_FILTER.map((item) => {
          const { scrollToBottom, inputSearch, onMenuClose, onMenuOpen } = resolveCallbacks(
            item.name,
          );

          const options = resolveOptions(item.name);

          const value = resolveValue(item.name);

          const changeCallback = resolveOnChangeCallback(item.name);

          if (item.isDropdown) {
            return (
              <SearchInputWithLabelAndIcon
                key={item.label}
                label={item.label}
                placeholder={item.placeholder}
                options={options}
                name={item.name}
                width={item.width}
                onMenuClose={onMenuClose}
                onMenuOpen={onMenuOpen}
                handleChange={changeCallback}
                handleSearch={inputSearch}
                onMenuScrollToBottom={scrollToBottom}
                containerMarginBottom="0px"
                height={44}
                value={value}
                onEnter={() => handleFetchDataPage()}
              />
            );
          }

          if (item.isDatePicker) {
            return (
              <DatePickerWithLabel
                key={item.label}
                name={item.name}
                label={item.label}
                value={value}
                dateFormat={item.dateFormat}
                height={item.height}
                width={item.width}
                onChangeValue={changeCallback}
                onEnter={() => handleFetchDataPage()}
              />
            );
          }

          if (item?.isSpace) {
            return <div style={{ width: '100%' }} />;
          }

          if (item.isFilterActions) {
            return (
              <Stack
                flex
                gap={1}
                flexDirection="row"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <MButton onClick={() => handleFetchDataPage()}>Apply</MButton>
                {showClearFilterButton && (
                  <MButton variant="borderLess" onClick={handleClearFilters}>
                    Clear Filter
                  </MButton>
                )}
              </Stack>
            );
          }

          return (
            <SearchInputWithLabel
              key={item.label}
              label={item.label}
              placeholder={item.placeholder}
              isDropdown={item.isDropdown}
              onChangeValue={changeCallback}
              name={item.name}
              value={value}
              width={item.width}
              onEnter={() => handleFetchDataPage()}
            />
          );
        })}
      </Stack>
      <Stack mt={4}>
        {invoices?.items?.length ? (
          <Table
            columns={INVOICES_TABLE_COLUMNS}
            rows={invoices?.items}
            total={invoices?.totalRowsCount}
            isPagination={invoices?.totalPages > 1}
            onNextPage={handleFetchDataPage}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            pageSize={PAGE_SIZE}
          />
        ) : (
          <Stack alignItems="center" pt={12}>
            <EmptyBlock
              title="No records"
              desc={
                isInvoicesFetched
                  ? "The search criteria didn't return any data"
                  : 'Please use filters to display available invoices'
              }
              descFontSize="18px"
            />
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default Invoices;
