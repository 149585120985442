import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import AddressContext from '../context/AddressContext';

export const defaultState = {
  addressId: 0,
  communityId: 0,
  courtName: '',
  communityName: '',
  managementName: '',
  managementId: 0,
  ownerEntity: { name: '', note: '' },
  contactInfo: {
    stateCode: '',
    city: '',
    address: '',
    zipCode: '',
    phone: '',
    writPhone: '',
    fax: '',
  },
  ownerEntityRefs: [],
  status: {
    isDeactivated: false,
    isOnHold: false,
    onHoldDescription: '',
  },
  qBsClass: '',
  distanceToCourt: 0,
};

const AddressProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const defaultContext = useMemo(
    () => ({
      state,
      actions: {
        update: (form) => setState(() => ({ ...state, ...form })),
        clear: () => setState(defaultState),
      },
    }),
    [state],
  );

  return <AddressContext.Provider value={defaultContext}>{children}</AddressContext.Provider>;
};

AddressProvider.propTypes = {
  children: PropTypes.element,
};

AddressProvider.defaultProps = {
  children: null,
};

export default AddressProvider;
