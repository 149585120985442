import { styled } from '@mui/material';
import { NumberCell } from './cells/NumberCell';
import NameCell from './cells/NameCell';

export const stateCodesOptions = [
  { value: 'AZ', label: 'AZ' },
  { value: 'NV', label: 'NV' },
];

export const clientActivityFormBoxes = [
  {
    name: 'dataFields',
    inputBoxes: [
      {
        label: 'State',
        name: 'stateCode',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'From Date',
        name: 'fromDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'To Date',
        name: 'toDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
    ],
  },
];

const StyledHeader = styled('div')({
  width: '100%',
  fontSize: '16px',
  fontWeight: '600',
  textAlign: 'end',
});

export const clientActivityFormBoxesColumnsByState = ({ stateCode }) => {
  const columns = {
    AZ: [
      {
        Header: 'Name',
        accessor: 'name',
        sticky: 'left',
        Cell: (props) => <NameCell {...props} />,
        minWidth: 400,
      },
      {
        Header: <StyledHeader>Evictions Requested</StyledHeader>,
        accessor: 'EvictionRequested',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Evictions Cancelled</StyledHeader>,
        accessor: 'Cancelled',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Evictions Filed</StyledHeader>,
        accessor: 'EvictionFiled',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Evictions Dismissed</StyledHeader>,
        accessor: 'Dismissed',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Judgements Granted</StyledHeader>,
        accessor: 'JudgmentGranted',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Writs Ordered</StyledHeader>,
        accessor: 'WritOrdered',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Satisfactions Ordered</StyledHeader>,
        accessor: 'Satisfied',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Motions To Vacate</StyledHeader>,
        accessor: 'Vacated',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Notices Requested</StyledHeader>,
        accessor: 'NoticeRequested',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Notices Served</StyledHeader>,
        accessor: 'NoticeServed',
        Cell: NumberCell,
        minWidth: 230,
      },
    ],
    NV: [
      {
        Header: 'Name',
        accessor: 'name',
        sticky: 'left',
        Cell: (props) => <NameCell {...props} />,
        minWidth: 400,
      },
      {
        Header: <StyledHeader>Evictions Requested</StyledHeader>,
        accessor: 'EvictionRequested',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Evictions Cancelled</StyledHeader>,
        accessor: 'Cancelled',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Evictions Filed</StyledHeader>,
        accessor: 'EvictionFiled',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Evictions Dismissed</StyledHeader>,
        accessor: 'Dismissed',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Eviction Granted</StyledHeader>,
        accessor: 'EvictionGranted',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Lockouts Filed</StyledHeader>,
        accessor: 'LockoutFiled',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Cases Rescinded</StyledHeader>,
        accessor: 'Rescinded',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Notices Requested</StyledHeader>,
        accessor: 'NoticeRequested',
        Cell: NumberCell,
        minWidth: 230,
      },
      {
        Header: <StyledHeader>Notices Served</StyledHeader>,
        accessor: 'NoticeServed',
        Cell: NumberCell,
        minWidth: 230,
      },
    ],
  };

  return columns[stateCode || stateCodesOptions[0].value];
};
