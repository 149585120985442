import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../../MUI/MUITextInput';
import Field from '../../../../atoms/Field';

export const ActiveTextFormInputCell = ({ column, row: { index } }) => {
  const name = column?.name.replace('[i]', `[${index}]`) || '';
  const isNewType = name.includes('newType');

  return (
    <Field
      name={name}
      isMandatory={column.isMandatory}
      render={({ field, error }) => (
        <TextInput
          type="text"
          error={error}
          name={name}
          placeholder={isNewType && 'Enter New Document Type'}
          isMandatory={column.isMandatory}
          value={field.value}
          onChange={field.onChange}
          height={38}
          width={298}
        />
      )}
    />
  );
};

ActiveTextFormInputCell.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    edit: PropTypes.bool,
    isMandatory: PropTypes.bool,
  }).isRequired,
  row: PropTypes.shape({ index: PropTypes.number }).isRequired,
};
