import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Typography, styled } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import { openOrDownloadBinary } from '../../../utils/binaryHelpers';
import { rolesDB } from '../../../utils/roleHelpers';
import notificationUtils from '../../../utils/notificationUtils';
import { apiClient } from '../../../lib/apiClient';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const StyledTypography = styled(Typography)(({ underlined }) => ({
  textDecoration: underlined && 'underline',
  '&:hover': {
    cursor: underlined && 'pointer',
  },
}));

export const FileLink = ({ file, checkTextBySearchParameter, folder }) => {
  const { userInfo } = useAuth();
  const base = useBaseUrl();
  const navigate = useNavigate();
  const isDBAdmin = userInfo.roles?.includes(rolesDB.Admin);

  const [isLoader, setIsLoader] = useState(false);

  const handleDownload = async () => {
    if (!isLoader) {
      setIsLoader(true);
      try {
        const { data } = await apiClient.get(`/cp/api/legalForms/${file.id}`);
        openOrDownloadBinary({
          contentType: data.result.contentType,
          content: data.result.content,
          fileName: data.result.originalFileName,
        });
      } catch {
        notificationUtils.error('Failed, try again later');
      } finally {
        setIsLoader(false);
      }
    }
  };

  const handleClickLink = () => {
    if (isDBAdmin) {
      navigate(`/${base}/legalforms/history`, {
        state: {
          navPage: 'Legal Forms',
          subNav: folder,
          subSubNavigation: file.name,
          id: file.id,
          templateGroupNumber: file.templateGroupNumber,
        },
      });
    } else {
      handleDownload();
    }
  };

  return (
    <StyledTypography
      component="a"
      onClick={handleClickLink}
      variant="primary"
      color="primary"
      underlined
    >
      <div dangerouslySetInnerHTML={{ __html: checkTextBySearchParameter(file.name) }} />
    </StyledTypography>
  );
};

FileLink.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    templateGroupNumber: PropTypes.number,
  }).isRequired,
  checkTextBySearchParameter: PropTypes.func.isRequired,
  folder: PropTypes.string.isRequired,
};
