import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { palette } from '../../../theme/default';

const BottomBar = ({ children }) => {
  return (
    <Box
      width={1}
      p={4}
      boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
      position="fixed"
      bottom="0"
      left="0"
      backgroundColor={palette.common.white}
      data-testid="bottom_bar_wrapper"
    >
      {children}
    </Box>
  );
};

BottomBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BottomBar;
