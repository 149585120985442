import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MDialog from '../../../atoms/MDialog';
import MButton from '../../../MUI/Button/MButton';
import Dropdown from '../../../atoms/Dropdown';
import { apiClient } from '../../../../lib/apiClient';
import LoaderCover from '../../../atoms/LoaderCover';

export const BulkAssignToOtherDialog = ({ handleSubmit, onClose, title, stateCode }) => {
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetcher = async () => {
      setIsLoading(true);
      const { data: attorneysList } = await apiClient.get(
        `/api/cases/attorneys?stateCode=${stateCode}`,
      );
      const list = attorneysList.result.map((item) => ({
        id: item.userId,
        value: item.userId,
        label: `${item.firstName} ${item.lastName}`,
      }));
      setAssigneeOptions(list);
      setIsLoading(false);
    };
    fetcher();
  }, [stateCode]);

  return (
    <MDialog
      minWidth="lg"
      title={title}
      onClose={onClose}
      footerActions={[
        <MButton
          data-testid="cancel_bulk_assign_btn"
          sx={{ width: '104px' }}
          variant="bordered"
          size="large"
          onClick={onClose}
        >
          Cancel
        </MButton>,
        <MButton
          sx={{ width: '104px' }}
          disabled={!selectedAssignee}
          variant="primary"
          size="large"
          onClick={() => handleSubmit(selectedAssignee?.value)}
          data-testid="confirm_bulk_assign_btn"
        >
          Assign
        </MButton>,
      ]}
    >
      {isLoading && <LoaderCover />}
      <Dropdown
        isMandatory
        isColumn
        label="Assignee"
        placeholder="Select assignee"
        width="519px"
        options={assigneeOptions}
        value={selectedAssignee}
        onChange={setSelectedAssignee}
        testid="assignee_select"
      />
    </MDialog>
  );
};

BulkAssignToOtherDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  stateCode: PropTypes.string.isRequired,
};
