/* eslint-disable no-await-in-loop */
import React, { useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import {
  clientActivityFormBoxes,
  clientActivityFormBoxesColumnsByState,
  stateCodesOptions,
} from './ClientActivity.constants';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';

import {
  createClientActivityReport,
  getClientActivityReport,
  getClientActivityReportAsPdf,
} from '../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import Dropdown from '../../../atoms/Dropdown';

import Table from '../../../atoms/Table/Table';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import notificationUtils from '../../../../utils/notificationUtils';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { entityTypes } from './cells/NameCell';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import './ClientActivity.css';
import useAuth from '../../../../hooks/useAuth';
import { ReactComponent as ExportIcon } from '../../../../assets/icons/document-download-blue.svg';
import ProgressModal from '../ProductivityByAttorney/ProgressModal';
import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';

const ClientActivity = () => {
  const isAdmin = useAllowed([rolesDB.Admin]);
  const { userInfo } = useAuth();
  const [isLoader, setIsLoader] = useState(false);
  // eslint-disable-next-line
  const [tableData, setTableData] = useState(null);
  const [expandedMgs, setExpandedMgs] = useState({});
  const [dateRange, setDateRange] = useState('');
  const [currentStateName, setCurrentStateName] = useState('');
  const [lastReportId, setLastReportId] = useState(null);
  const [ranges, setRanges] = useState(null);

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const fromDate = form.watch('fromDate');
  const toDate = form.watch('toDate');

  const currentRange = useMemo(() => {
    return `${moment(fromDate).format('L')} - ${moment(toDate).format('L')}`;
  }, [fromDate, toDate]);

  const getOptions = (name) => {
    switch (name) {
      case 'stateCode':
        return isAdmin
          ? stateCodesOptions
          : stateCodesOptions.filter(({ value }) => userInfo.StateCode === value);
      default:
        return [];
    }
  };

  const handleSubmit = async (values) => {
    const data = {
      fromDate: values.fromDate,
      toDate: values.toDate,
      stateCode: values.stateCode?.value,
    };
    setCurrentStateName(values.stateCode?.value === 'AZ' ? 'Arizona' : 'Nevada');
    setIsLoader(true);

    try {
      const { reportId } = await dispatch(createClientActivityReport(data)).unwrap();
      setLastReportId(reportId);

      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code, message },
        } = await dispatch(getClientActivityReport(reportId)).unwrap();
        if (code === 'Completed') {
          setTableData(items);
          done = true;
          setDateRange(currentRange);
        } else if (code === 'Error') {
          notificationUtils.error(message);
          setDateRange('');
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      notificationUtils.error(e.message || 'Something went wrong');
      setDateRange('');
    }
    setIsLoader(false);
  };

  const columns = useMemo(() => {
    return clientActivityFormBoxesColumnsByState({
      stateCode: currentStateName === 'Arizona' ? 'AZ' : 'NV',
    });
  }, [currentStateName]);

  const adjustColValues = (obj) => {
    const res = obj;
    columns.forEach((col) => {
      if (!res[col.accessor]) {
        res[col.accessor] = '0';
      }
    });
    return res;
  };

  const rowsAdapter = useMemo(() => {
    const values = [];
    tableData?.forEach((mgt) => {
      const isManagementExpanded = expandedMgs[mgt.managementId] || false;
      values.push({
        expanded: isManagementExpanded,
        visible: true,
        className: 'management-client-activity',
        managementName: mgt.managementName,
        id: mgt.managementId,
        type: entityTypes.MANAGEMENT,
        ...adjustColValues(mgt.statuses),
        name: mgt.managementName,
      });
      if (isManagementExpanded) {
        mgt.communities.forEach((com) => {
          values.push({
            expanded: true,
            visible: true,
            managementName: mgt.managementName,
            id: com.id,
            type: com.isStandaloneAddress
              ? entityTypes.STANDALONE_ADDRESSES
              : entityTypes.COMMUNITY,
            ...adjustColValues(com.statuses),
            name: com.name,
          });
        });
      }
    });
    return values;
  }, [tableData, expandedMgs, currentStateName]);

  const handleRowClick = (val) => {
    if (val.type === entityTypes.MANAGEMENT) {
      setExpandedMgs((prev) => {
        return { ...prev, [val.value]: !prev[val.value] };
      });
    }
  };

  const getRanges = (name) => {
    const currentFromDate = form.getValues('fromDate');
    const currentToDate = form.getValues('toDate');
    if (name.includes('fromDate')) {
      setRanges({ toDate: { min: currentFromDate } });
    }

    if (name.includes('toDate')) {
      setRanges({ fromDate: { max: currentToDate } });
    }
  };

  const exportToPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoader(true);
    try {
      const data = await dispatch(getClientActivityReportAsPdf(lastReportId)).unwrap();
      openOrDownloadBinary({
        content: data.content,
        fileName: data.originalFileName,
        contentType: data.contentType,
      });
    } catch (error) {
      notificationUtils.error(error.message || 'Something went wrong');
    } finally {
      setIsLoader(false);
    }
  };

  return (
    <Stack direction="column" mb={6}>
      <ShadowBlock>
        <ProgressModal isOpen={isLoader} />
        <Form form={form} onSubmit={handleSubmit}>
          {clientActivityFormBoxes.map((i) => (
            <Stack
              direction="row"
              alignItems="center"
              columnGap={3}
              rowGap={2}
              flexWrap="wrap"
              py={1}
            >
              {i.inputBoxes.map((item) => {
                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <Dropdown
                          value={field.value}
                          isAddDisabled={false}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          isColumn
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={onCustomChange(field.onChange)}
                          isMandatory={item.isMandatory}
                        />
                      )}
                    />
                  );
                }
                if (item.isDatePicker) {
                  return (
                    <Field
                      name={item.name}
                      key={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange, error }) => (
                        <DateOrTimePicker
                          name={item.name}
                          label={item.label}
                          isDate
                          error={error}
                          Svg={Calender}
                          selected={field.value}
                          isMandatory={item.isMandatory}
                          width={item.width}
                          height={item.height}
                          placeholder={item.placeholder}
                          minDate={ranges?.[item.name]?.min || null}
                          maxDate={ranges?.[item.name]?.max || moment()}
                          onClose={() => getRanges(item.name)}
                          setSelectedTimeOrDate={onCustomChange(field.onChange)}
                        />
                      )}
                    />
                  );
                }
                return null;
              })}
            </Stack>
          ))}
          <Stack direction="row" mt={2} gap={2}>
            <MButton
              data-testid="run_report_btn"
              type="submit"
              size="medium"
              disabled={isLoader || !form.formState.isValid}
            >
              Run Report
            </MButton>
          </Stack>
        </Form>
      </ShadowBlock>

      {!!tableData && (
        <Box mt={3}>
          <ShadowBlock customStyles={{ gap: 0 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
              <Typography variant="h5" data-testid="report_title">
                Client Activity Report for {currentStateName} {dateRange}
              </Typography>
              <MButton variant="bordered" startIcon={<ExportIcon />} onClick={exportToPdf}>
                Export PDF
              </MButton>
            </Box>
            {!!tableData.length && !isLoader ? (
              <Table
                hasFixedColumns
                showFixedColumnsAtWidth={2930}
                columns={columns}
                rows={rowsAdapter}
                onRowClick={handleRowClick}
              />
            ) : (
              <Box
                display="flex"
                width="1140px"
                flexDirection="column"
                alignItems="center"
                mt={12}
                mb={25}
                mx="auto"
              >
                <EmptyBlock
                  title="No Records"
                  desc="Please change filters to display report"
                  descFontSize={18}
                />
              </Box>
            )}
          </ShadowBlock>
        </Box>
      )}
    </Stack>
  );
};

export default ClientActivity;
