import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;
export const USER_STATUSES = [
  { id: 0, label: 'Active', value: 'active' },
  { id: 1, label: 'Inactive', value: 'inactive' },
];
export const USER_ASSIGNMENTS = [
  { id: 0, label: 'Entire Portfolio', value: 'yes' },
  { id: 1, label: 'Limited Access', value: 'no' },
];

export const UsersTableColumns = ({ isAdmin }) => {
  if (isAdmin) {
    return [
      {
        Header: 'First Name',
        accessor: 'firstName',
        width: 30,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        width: 30,
      },
      {
        Header: 'Role',
        accessor: 'roleName',
        width: 30,
      },
      {
        Header: 'State',
        accessor: 'stateCode',
        width: 30,
      },
      {
        Header: 'Status',
        accessor: 'isActive',
        width: 30,
        Cell: ({ value }) => (value ? 'Active' : 'Inactive'),
      },
      {
        Header: 'Entire Portfolio',
        accessor: 'entirePortfolio',
        width: 30,
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
      {
        Header: '',
        accessor: 'control',
        width: 6,
        Cell: DeleteCell,
      },
    ];
  }
  return [
    {
      Header: 'First Name',
      accessor: 'firstName',
      width: 30,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      width: 30,
    },
    {
      Header: 'Management Company',
      accessor: 'managementCompany',
      width: 30,
    },
    {
      Header: 'Role',
      accessor: 'roleName',
      width: 30,
    },
    {
      Header: 'State',
      accessor: 'stateCode',
      width: 30,
    },
    {
      Header: 'Status',
      accessor: 'isActive',
      width: 30,
      Cell: ({ value }) => (value ? 'Active' : 'Inactive'),
    },
    {
      Header: 'Entire Portfolio',
      accessor: 'entirePortfolio',
      width: 30,
      Cell: ({ value }) => (value ? 'Yes' : 'No'),
    },
    {
      Header: '',
      accessor: 'control',
      width: 6,
      Cell: DeleteCell,
    },
  ];
};

export const userEditFormFields = [
  {
    name: '',
    inputBoxes: [
      {
        label: 'First Name',
        name: 'firstName',
        placeholder: 'Enter first name',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'Last Name',
        name: 'lastName',
        placeholder: 'Enter last name',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
    ],
  },
  {
    name: '',
    inputBoxes: [
      {
        label: 'User Name',
        name: 'userName',
        placeholder: 'Enter user name',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'User ID',
        name: 'userId',
        placeholder: '349800-89',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: false,
        isDisabled: true,
      },
    ],
  },
  {
    name: '',
    inputBoxes: [
      {
        label: 'Default Role',
        name: 'defaultRoleId',
        placeholder: 'Select default role',
        width: '326px',
        isDropdown: true,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'State',
        name: 'state',
        placeholder: '-',
        width: '158px',
        isDropdown: true,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
    ],
  },
  {
    name: '',
    inputBoxes: [
      {
        label: 'Email',
        name: 'email',
        placeholder: 'Enter email',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
      },
      {
        label: 'Status',
        name: 'status',
        placeholder: 'Enter status',
        width: '326px',
        data: [
          { id: 0, label: 'Active', value: true, isDefault: true },
          { id: 1, label: 'Inactive', value: false },
        ],
        radioButtonWidth: '158px',
        isDropdown: false,
        isRadioButtons: true,
        isMandatory: true,
        isDisabled: false,
      },
    ],
  },
  {
    name: '',
    inputBoxes: [
      {
        label: 'Password',
        name: 'password',
        placeholder: 'Enter password',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
        isPassword: true,
      },
      {
        label: 'Confirm Password',
        name: 'confirmPassword',
        placeholder: 'Confirm Password',
        width: '326px',
        isDropdown: false,
        isRadioButtons: false,
        isMandatory: true,
        isDisabled: false,
        isPassword: true,
      },
    ],
  },
];
