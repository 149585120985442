import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/Add.svg';

export const ownerEntityHeading = 'Owner Entity Properties';

export const ownerEntityBoxes = [
  {
    name: '',
    inputBoxes: [
      {
        label: 'Owner Entity Name',
        name: 'name',
        placeholder: 'Enter owner entity name',
        width: '444px',
        isDropdown: false,
        isMandatory: true,
        isDisabled: false,
      },
    ],
  },
  {
    name: 'contactInfo',
    inputBoxes: [
      {
        label: 'Contact Info',
        isSubHeading: true,
      },
      {
        label: 'Address',
        name: 'contactInfo.address',
        placeholder: 'Enter address',
        width: '444px',
        isDropdown: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'City',
        name: 'contactInfo.city',
        placeholder: 'Enter city',
        width: '208px',
        isDropdown: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'State',
        name: 'contactInfo.stateCode',
        placeholder: '-',
        width: '94px',
        isDropdown: true,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'ZIP Code',
        name: 'contactInfo.zipCode',
        placeholder: 'xx xx xx',
        width: '208px',
        isDropdown: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Phone',
        name: 'contactInfo.phone',
        placeholder: '+1(480)xx xx xx xx',
        width: '336px',
        isDropdown: false,
        isMandatory: false,
        isDisabled: false,
      },
      {
        label: 'Email',
        name: 'contactInfo.email',
        placeholder: 'Enter email',
        width: '444px',
        isDropdown: false,
        isMandatory: false,
        isDisabled: false,
      },
    ],
  },
  {
    name: 'contacts',
    inputBoxes: [
      {
        label: 'Contact Persons',
        isSubHeading: false,
        isSubHeadingWithButton: true,
        buttonName: 'Add',
        buttonIcon: <AddIcon />,
      },
      {
        label: '',
        name: 'contacts',
        placeholder: '',
        width: '444px',
        isDropdown: false,
        isMandatory: false,
        isDisabled: false,
        isTable: true,
      },
    ],
  },
];

export const contactColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    width: 20,
  },
  {
    Header: 'Title',
    accessor: 'title',
    width: 16,
  },
  {
    Header: 'Work #',
    accessor: 'workPhone',
    width: 20,
  },
  {
    Header: 'Mobile',
    accessor: 'mobilePhone',
    width: 20,
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 20,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];
