import React, { useEffect, useState } from 'react';
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import { palette } from '../../../../theme/default';
import Table from '../../../atoms/Table/Table';
import { accordionTableColumns } from './AttorneyAssignments.constants';
import Dropdown from '../../../atoms/Dropdown';
import MPagination from '../../../atoms/MPagination';
import {
  setNewCourtAttorney,
  callNewPage,
} from '../../../../store/slices/schedule/attorneyAssignmentsSlice';

const AttorneyAssignmentsAccordions = () => {
  const dispatch = useDispatch();

  const { assignedAttorneysList, accordionAttorneysList, newCourtAttorney } = useSelector(
    (state) => state.attorneyAssignments,
  );

  const [expanded, setExpanded] = useState([]);
  const [selectedAttorney, setSelectedAttorney] = useState(null);

  useEffect(() => {
    if (assignedAttorneysList) {
      setSelectedAttorney(null);
      setExpanded([]);
    }
  }, [assignedAttorneysList]);

  const handleChange = (panel) => () => {
    setExpanded((prev) => {
      return prev.includes(panel) ? prev.filter((item) => item !== panel) : [...prev, panel];
    });
  };

  // eslint-disable-next-line
  const handleChangePage = (event, page) => {
    dispatch(callNewPage(page));
  };

  const handleSelect = (value, cases, index) => {
    dispatch(
      setNewCourtAttorney({
        ...newCourtAttorney,
        [index]: {
          caseIds: cases.map((item) => item.caseId),
          attorneyId: value.id,
        },
      }),
    );
    setSelectedAttorney((prev) => ({ ...prev, [index]: value }));
  };

  const defineAttorney = (attorneyName) => {
    return accordionAttorneysList?.find((item) => item.value === attorneyName);
  };

  if (!assignedAttorneysList?.items) return null;

  return (
    <Stack gap={2} pb={20}>
      {assignedAttorneysList &&
        assignedAttorneysList?.items.map((courtItem, index) => {
          const courtNameWithDate = `${courtItem.courtName} (${moment(courtItem.courtDate).format(
            'LLL',
          )})`;

          return (
            <Accordion
              key={courtNameWithDate}
              expanded={expanded.includes(index)}
              onChange={handleChange(index)}
              sx={{
                '&': {
                  border: `2px solid ${palette.additional.lines}`,
                  borderRadius: '16px !important',
                  boxShadow: 'none',
                },
                '&:before': {
                  display: 'none',
                },
                '& div.Mui-focusVisible': {
                  backgroundColor: 'unset',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowDown />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  display: 'flex',
                }}
              >
                <Typography
                  className="MuiAccordionSummary_Custom_Header_Text"
                  variant="bodyL500"
                  m="auto 0"
                >
                  {courtNameWithDate}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexGrow: 1,
                    marginRight: '124px',
                  }}
                >
                  <Dropdown
                    width="326px"
                    onChange={(val) => handleSelect(val, courtItem?.cases, index)}
                    options={accordionAttorneysList}
                    value={selectedAttorney?.[index] || defineAttorney(courtItem.groupAttorney)}
                    placeholder="Assigned Attorney"
                    backgroundColor={palette.buttonSecondary.pressedBg}
                    placeholderStyles={{
                      color: palette.text.secondary,
                      paddingLeft: '12px',
                      fontSize: '16px',
                      fontWeight: 500,
                    }}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Table columns={accordionTableColumns} rows={courtItem?.cases} />
              </AccordionDetails>
            </Accordion>
          );
        })}
      {assignedAttorneysList?.totalPages > 1 && (
        <MPagination
          currentPage={assignedAttorneysList?.currentPage}
          count={assignedAttorneysList?.totalPages}
          handleChange={handleChangePage}
        />
      )}
    </Stack>
  );
};

export default AttorneyAssignmentsAccordions;
