import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import MCheckbox from '../../MCheckbox';
import { hideDocumentforCP } from '../../../../store/slices/cases/casesGeneralSlice';
import notificationUtils from '../../../../utils/notificationUtils';

export const HideDocumentCell = ({ row: { original }, refreshPage }) => {
  const dispatch = useDispatch();

  const handleClickHide = () => {
    const { isHidden } = original;
    const { documentId } = original;

    const body = {
      documentId,
      isHidden: !isHidden,
    };

    dispatch(hideDocumentforCP({ documentId, body }))
      .then(() => {
        notificationUtils.success('Done successfully');
        refreshPage();
      })
      .catch(() => {
        notificationUtils.error('Failed, try again later');
      });
  };

  return (
    <MCheckbox
      isChecked={original.isHidden}
      onChange={() => handleClickHide(original)}
      data-testid="hide_checkbox"
    />
  );
};

HideDocumentCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      isHidden: PropTypes.bool.isRequired,
      documentId: PropTypes.number.isRequired,
    }),
  }).isRequired,
  refreshPage: PropTypes.func.isRequired,
};
