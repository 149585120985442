import React from 'react';
import PropTypes from 'prop-types';
import { styled, Dialog, Typography, Stack } from '@mui/material';
import { palette } from '../../../theme/default/palette';

const StyledDialog = styled(Dialog)(({ minWidth, maxWidth, scrollable }) => ({
  overflowY: 'visible',

  '& .MuiDialog-paper': {
    minWidth,
    maxWidth,
    padding: 32,
    borderRadius: 24,
    boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
    overflowY: scrollable ? 'auto' : 'visible',
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--table_color)',
    },
  },
}));

const MDialog = ({
  isOpen,
  title,
  onClose,
  children,
  footerActions,
  maxWidth,
  minWidth,
  fullWidth,
  titleVariant,
  scrollable,
  onClosed,
}) => (
  <StyledDialog
    onClose={onClose}
    open={isOpen}
    maxWidth={maxWidth}
    minWidth={minWidth}
    fullWidth={fullWidth}
    scrollable={scrollable}
    sx={{ zIndex: 10000 }}
    data-testid="dialog_wrapper"
    TransitionProps={{
      onExited: () => {
        onClosed?.();
      },
    }}
  >
    {typeof title === 'string' && title !== '' ? (
      <Typography sx={{ mb: 4 }} variant={titleVariant} data-testid="dialog_title">
        {title}
      </Typography>
    ) : (
      title
    )}
    {children}
    {footerActions && (
      <Stack
        direction="row"
        spacing={2}
        sx={{ mt: 4 }}
        justifyContent="flex-end"
        data-testid="dialog_actions"
      >
        {footerActions}
      </Stack>
    )}
  </StyledDialog>
);

MDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.node,
  footerActions: PropTypes.node,
  onClose: PropTypes.func,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  titleVariant: PropTypes.string,
  scrollable: PropTypes.bool,
  onClosed: PropTypes.func,
};

MDialog.defaultProps = {
  isOpen: true,
  title: '',
  onClose: () => {},
  children: null,
  footerActions: null,
  maxWidth: 'sm',
  minWidth: 'sm',
  fullWidth: false,
  titleVariant: 'h5',
  scrollable: false,
  onClosed: null,
};

export default MDialog;
