import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SubPageHeading from '../../../../organisms/SubPageHeading';

const DocumentsContainer = () => {
  const {
    state: { filters, subPageHeading: subHeading },
  } = useLocation();

  return (
    <>
      <SubPageHeading
        subPageNav={[]}
        subPageHeading={subHeading}
        nav={{
          to: '/db/admin/documents',
          state: {
            filters,
            subPageHeading: subHeading,
            isSteppedBackFromDocumentsHistory: true,
          },
        }}
      />
      <Outlet />
    </>
  );
};

export default DocumentsContainer;
