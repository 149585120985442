/* eslint-disable */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { styled, Typography, Dialog, Stack, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { palette } from '../../../../theme/default';
import MButton from '../../../MUI/Button/MButton';
import { ReactComponent as CopySvg } from '../../../../assets/icons/Copy.svg';
import Form from '../../../atoms/Form';
import { useForm } from 'react-hook-form';
import {
  EDIT_TIME_ENTRY_LEFT_COLUMN,
  EDIT_TIME_ENTRY_RIGHT_COLUMN,
} from './CivilMatters.constants';
import Field from '../../../atoms/Field';
import Dropdown from '../../../atoms/Dropdown';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import MultilineTextField from '../../../atoms/MultilineTextField/MultilineTextField';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import AuthContext from '../../../../context/AuthContext';
import { apiClient } from '../../../../lib/apiClient';
import { roundNumToThousands } from '../../../../utils/roundNumToThousands';
import { rolesDB } from '../../../../utils/roleHelpers';
import { LightTooltip } from '../../../atoms/MTooltip/MTooltip';
import moment from 'moment';
import LoaderCover from '../../../atoms/LoaderCover';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '741px',
    padding: 32,
    borderRadius: 24,
    boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
  },
});

const EditTimeEntry = ({
  modalData,
  isOpen,
  onClose,
  onConfirm,
  firmUsersList,
  minDate,
  categoriesList,
}) => {
  const { userInfo } = useContext(AuthContext);
  const form = useForm();
  const { handleSubmit } = form;

  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(null);
  const wasChaned = useRef(false);

  useEffect(() => {
    if (!firmUsersList || !userInfo) return;
    const currentUserIndex = firmUsersList.findIndex(({ id }) => id === userInfo.UserGuid);
    if (
      currentUserIndex !== -1 &&
      userInfo.roles.includes(rolesDB.Attorney) &&
      !modalData?.isEdit
    ) {
      form.setValue('firmUser', firmUsersList[currentUserIndex]);
    }
  }, [firmUsersList, userInfo]);

  useEffect(() => {
    const firmUserValue = form.getValues('firmUser');

    // if user changes attorney then default rate should be applied
    // even for the same attorney in edit modal
    if (firmUserValue && (!modalData?.isEdit || currentTime?.firmUser?.id !== firmUserValue?.id)) {
      form.setValue('rate', firmUserValue.attorneyRate || 0);
      wasChaned.current = true;
    } else if (currentTime?.firmUser?.id === firmUserValue?.id) {
      const valueForCurrentAttorney = wasChaned.current
        ? firmUserValue.attorneyRate
        : currentTime?.rate;
      form.setValue('rate', valueForCurrentAttorney || 0);
    }
  }, [form.watch('firmUser'), currentTime, modalData?.isEdit]);

  useEffect(() => {
    if (!modalData?.isEdit) {
      form.setValue('date', moment());
    }
  }, []);

  useEffect(() => {
    if (!modalData?.isEdit) return;
    if (!firmUsersList) return;

    const fetcher = async () => {
      setLoading(true);
      const { id, civilMatterId } = modalData;
      const { data } = await apiClient.get(`/api/cases/${id}/civilMatters/time/${civilMatterId}`);

      const firmUserValue = firmUsersList.find((item) => item.id === data.result.userId);
      const categoryValue = categoriesList.find((item) => item.value === data.result.categoryId);

      const values = {
        firmUser: firmUserValue,
        category: categoryValue,
        description: data.result.description,
        date: data.result.date,
        duration: data.result.duration,
        rate: data.result.rate,
        isNonBillable: data.result.isNonBillable,
      };
      setCurrentTime(values);

      form.reset(values);
      setLoading(false);
    };
    fetcher();
  }, [modalData?.isEdit, firmUsersList]);

  const getOptions = useCallback(
    (fieldName) => {
      const options = {
        firmUser: firmUsersList,
        category: categoriesList,
      };
      return options[fieldName] || [];
    },
    [firmUsersList],
  );

  const handleDefaultRate = useCallback(() => {
    const firmUserValue = form.getValues('firmUser');
    form.setValue('rate', firmUserValue.attorneyRate || 0);
  }, [firmUsersList]);

  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => form.reset(), 500);
  }, []);

  const onRateChange = useCallback(({ target: { value } }) => {
    form.setValue('rate', value);
  }, []);

  const onDurationChange = useCallback(({ target: { value } }) => {
    form.setValue('duration', value);
  }, []);

  const getOnChangeCallback = useCallback((fieldName, cb) => {
    const callbacks = {
      rate: onRateChange,
      duration: onDurationChange,
    };
    return callbacks[fieldName] || cb;
  }, []);

  const onRateBlur = useCallback(() => {
    const rate = form.getValues('rate') || '';
    let value = roundNumToThousands(String(rate));

    if (String(value).endsWith('.')) value = parseFloat(value);
    form.setValue('rate', value);
  }, []);

  const onDurationBlur = useCallback(() => {
    const duration = form.getValues('duration') || '';
    let value = roundNumToThousands(String(duration));

    if (String(value).endsWith('.')) value = parseFloat(value);
    form.setValue('duration', value);
  }, []);

  const getOnBlurCallback = useCallback((fieldName) => {
    const callbacks = {
      rate: onRateBlur,
      duration: onDurationBlur,
    };
    callbacks[fieldName]?.();
  }, []);

  const getDisabledState = useCallback(
    (fieldName) => {
      if (fieldName === 'firmUser') return !userInfo.roles.includes(rolesDB.Admin);
      return false;
    },
    [userInfo],
  );

  const onSubmit = useCallback(
    async (values) => {
      await onConfirm({
        ...values,
        isEdit: modalData.isEdit,
        civilMatterId: modalData?.civilMatterId,
      });
      form.reset();
      setSubmitDisabled(false);
    },
    [modalData],
  );

  const firmUser = form.watch('firmUser');

  const canSelectedAttorneyAddActivities = useMemo(() => {
    if (firmUser && !firmUser?.attorneyQuickBookId) {
      return false;
    }
    return true;
  }, [firmUser?.attorneyQuickBookId]);

  const tooltipText = canSelectedAttorneyAddActivities
    ? ''
    : 'Firm user is not connected to QB yet. Please reach out to your admin to resolve the issue.';

  return (
    <StyledDialog onClose={onClose} open={isOpen} maxWidth="741px">
      {isLoading && <LoaderCover />}
      <Typography variant="h5" mb="40px">
        {modalData?.isEdit ? 'Edit Time Entry' : 'New Time Entry'}
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)} form={form}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            columnGap: 3,
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              columnGap: 3,
              rowGap: 3,
            }}
          >
            {EDIT_TIME_ENTRY_LEFT_COLUMN.map((item) => {
              if (item.isDropdown) {
                return (
                  <Field
                    key={item.label}
                    name={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, error, onCustomChange }) => (
                      <Dropdown
                        isMandatory={item.isMandatory}
                        type="text"
                        label={item.label}
                        placeholder={item.placeholder}
                        width={item.width}
                        name={item.name}
                        value={field.value}
                        onChange={onCustomChange(field.onChange)}
                        isColumn
                        options={getOptions(item.name)}
                        error={error}
                        height={item.height}
                        isDisabled={getDisabledState(field.name)}
                      />
                    )}
                  />
                );
              }

              if (item.isMultiLineTextField) {
                return (
                  <Field
                    key={item.label}
                    name={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, error }) => (
                      <MultilineTextField
                        error={error}
                        value={field.value}
                        isMandatory={item.isMandatory}
                        width={item.width}
                        label={item.label}
                        rows={item.rows}
                        placeholder={item.placeholder}
                        onChange={field.onChange}
                        maxLength={item.maxLength}
                      />
                    )}
                  />
                );
              }

              return null;
            })}
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              columnGap: 3,
              rowGap: 3,
            }}
          >
            {EDIT_TIME_ENTRY_RIGHT_COLUMN.map((item) => {
              if (item.isDropdown) {
                return (
                  <Field
                    key={item.label}
                    name={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, error, onCustomChange }) => (
                      <Dropdown
                        isMandatory={item.isMandatory}
                        type="text"
                        label={item.label}
                        placeholder={item.placeholder}
                        width={item.width}
                        name={item.name}
                        value={field.value}
                        onChange={onCustomChange(field.onChange)}
                        isColumn
                        options={getOptions(item.name)}
                        error={error}
                        height="auto"
                        maxMenuHeight={200}
                      />
                    )}
                  />
                );
              }

              if (item.isDatePicker) {
                return (
                  <Field
                    name={item.name}
                    isMandatory={item.isMandatory}
                    key={item.name}
                    render={({ field, onCustomChange, error }) => (
                      <DateOrTimePicker
                        error={error}
                        label={item.label}
                        name={item.name}
                        isDate
                        minDate={minDate}
                        Svg={Calender}
                        selected={field.value}
                        width={item.width}
                        height={item.height}
                        placeholder={item.placeholder}
                        isMandatory={item.isMandatory}
                        setSelectedTimeOrDate={onCustomChange(field.onChange)}
                      />
                    )}
                  />
                );
              }

              if (item.isButton) {
                return (
                  <Stack alignSelf="flex-end">
                    <MButton
                      variant="borderLess"
                      size="large"
                      onClick={handleDefaultRate}
                      sx={{
                        height: '48px',
                        padding: '0 10px',
                        margin: '0 auto',
                      }}
                    >
                      {item.text}
                    </MButton>
                  </Stack>
                );
              }

              if (item.isCheckbox) {
                return (
                  <Field
                    key={item.label}
                    name={item.name}
                    render={({ field, onCustomChange }) => (
                      <CheckboxWithLabel
                        isDisabled={item.isDisabled}
                        key={item.label}
                        label={item.label}
                        isChecked={field.value}
                        onChange={onCustomChange(field.onChange)}
                      />
                    )}
                  />
                );
              }

              return (
                <Field
                  name={item.name}
                  key={item.name}
                  isMandatory={item.isMandatory}
                  render={({ field, error }) => (
                    <EditInputWithLabel
                      error={error}
                      isMandatory={item.isMandatory}
                      type="text"
                      label={item.label}
                      placeholder={item.placeholder}
                      width={item.width}
                      name={item.name}
                      value={field.value}
                      onChange={getOnChangeCallback(item.name, field.onChange)}
                      onBlur={() => getOnBlurCallback(item.name)}
                      isDisabled={item.isDisabled}
                    />
                  )}
                />
              );
            })}
          </Stack>
        </Stack>
        <Stack direction="row" spacing={3} mt="40px" justifyContent="flex-end" height="auto">
          <MButton variant="secondary" size="large" onClick={handleClose} sx={{ width: '150px' }}>
            Cancel
          </MButton>

          <LightTooltip title={tooltipText}>
            <Box>
              <MButton
                type="submit"
                size="large"
                sx={{ width: '150px' }}
                disabled={isSubmitDisabled || !canSelectedAttorneyAddActivities}
                startIcon={<CopySvg />}
              >
                Save
              </MButton>
            </Box>
          </LightTooltip>
        </Stack>
      </Form>
    </StyledDialog>
  );
};

EditTimeEntry.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmError: PropTypes.shape({}),
  firmUsersList: PropTypes.shape(),
  categoriesList: PropTypes.shape(),
};

EditTimeEntry.defaultProps = {
  isOpen: false,
  title: '',
  buttonSecondaryText: '',
  buttonPrimaryText: '',
  onClose: null,
  onConfirm: null,
  confirmError: {},
  firmUsersList: null,
  categoriesList: null,
};

export default EditTimeEntry;
