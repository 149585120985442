import React, { useEffect } from 'react';
import { Typography, Accordion, styled, AccordionSummary, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { arizonaRelatedCasesColumns } from './CaseInformationArizona.constants';
import { getAraizonaRelatedCases } from '../../../../store/slices/cases/caseInformationArizonaSlice';
import Table from '../../../atoms/Table/Table';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
    '.MuiPaper-root': {
      boxShadow: '0px 3px 20px rgba(69, 80, 121, 0.13)',
      marginBottom: '16px',
      borderRadius: '24px',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '16px',
      height: '16px',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 32px 32px',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

const RelatedCases = () => {
  const dispatch = useDispatch();

  const {
    state: { id: caseId },
  } = useLocation();
  // eslint-disable-next-line
  const { relatedArizonaCases } = useSelector((state) => state.caseInformationArizona);

  useEffect(() => {
    if (caseId) {
      dispatch(getAraizonaRelatedCases({ caseId }));
    }
  }, [caseId]);

  return (
    <StyledContainer
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        px: 4,
        maxWidth: 1542,
        width: 1,
        position: 'relative',
      }}
    >
      <StyledAccordion defaultExpanded sx={{ p: 4 }}>
        <AccordionSummary expandIcon={<ArrowDown />} sx={{ padding: 0 }}>
          <Typography variant="h5">Related cases</Typography>
        </AccordionSummary>
        {relatedArizonaCases ? (
          <Table columns={arizonaRelatedCasesColumns} rows={relatedArizonaCases} />
        ) : (
          <Typography variant="bodyL500" textAlign="center">
            Currently there is no Related Cases.
          </Typography>
        )}
      </StyledAccordion>
    </StyledContainer>
  );
};

export default RelatedCases;
