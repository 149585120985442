import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Table from '../../../../atoms/Table/Table';
import Searcher from '../../../../molecules/Searcher';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import { getManagements, deleteManagement } from '../../../../../store/slices/managementSlice';
import { ManagementsColumns, MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './Managements.constants';
import MButton from '../../../../MUI/Button/MButton';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';
import useSafeLocationState from '../../../../../hooks/useSafeLocationState';
import StateLink from '../../../../atoms/StateLink/StateLink';
import BottomBar from '../../../../atoms/BottomBar/BottomBar';

const Managements = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);

  const { navPage, subNav } = useSafeLocationState('Data Handling', 'Managements');
  const canDelete = useAllowed([rolesDB.Admin]);
  const canCreate = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);

  const managements = useSelector((state) => state.management.managements);
  useEffect(() => {
    dispatch(getManagements({ pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() =>
      setIsLoader(false),
    );
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      dispatch(
        getManagements({
          currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          managementName: searchValue,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue],
  );

  const handleChangeEmpty = useCallback((searchElement) => {
    if (!searchElement) {
      setIsLoaderTable(true);
      dispatch(getManagements({ pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() => {
        setIsLoaderTable(false);
        setSearchValue('');
      });
    }
  }, []);

  const handleSearch = useCallback((searchElement) => {
    setSearchValue(searchElement);
    setIsLoaderTable(true);
    dispatch(
      getManagements({ managementName: searchElement, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE }),
    ).then(() => {
      setIsLoaderTable(false);
    });
  }, []);

  const handleCreateManagement = useCallback(() => {
    navigate('/db/datahandling/newmanagement/management', {
      state: {
        navPage,
        subNav,
      },
    });
  }, []);

  const handleDeleteManagement = useCallback((id, original) => {
    return dispatch(deleteManagement(original.managementId));
  }, []);

  const rowsAdapter = managements?.items?.map((item) => ({
    ...item,
    managementName: (
      <StateLink
        to="/db/datahandling/editmanagement/management"
        state={{ navPage, subNav, id: item.managementId, name: item.managementName }}
      >
        <Typography
          variant="link"
          color="text.primary"
          sx={{
            cursor: 'pointer',
            textDecorationLine: 'underline',
            textTransform: 'none',
          }}
        >
          {item.managementName}
        </Typography>
      </StateLink>
    ),
    control: {
      hideDelete: !canDelete,
      autoRefresh: true,
    },
  }));

  if (!isLoader && !rowsAdapter?.length && !searchValue) {
    return (
      <Stack height="calc(100vh - 80px - 72px)" direction="column">
        <Typography variant="h3" margin="0 32px">
          Management Search
        </Typography>
        <Box margin="auto">
          <EmptyBlock
            width="544px"
            title="You don't have any managements yet"
            desc={
              canCreate
                ? 'Every management you  create will appear here. To  get started click on “Create new management” button below'
                : ''
            }
            buttonText={canCreate ? 'Create new management' : ''}
            onClick={canCreate ? handleCreateManagement : undefined}
          />
        </Box>
      </Stack>
    );
  }

  return (rowsAdapter?.length && !isLoader) || searchValue ? (
    <>
      <Box width="1066px" marginX="auto" pb={18}>
        <Stack spacing={3}>
          <Typography variant="h5">Managements</Typography>
          <Searcher
            inputPlaceholder="Search by Management Name"
            onSearch={handleSearch}
            onChange={handleChangeEmpty}
          />
          {!isLoader && !rowsAdapter?.length && searchValue ? (
            <Box paddingTop="100px" display="flex" justifyContent="center">
              <EmptyBlock
                title="Managements are not found"
                desc="Сhange the search value"
                margin="auto"
              />
            </Box>
          ) : (
            <Table
              columns={ManagementsColumns}
              rows={rowsAdapter}
              total={managements?.totalRowsCount}
              isPagination={managements?.totalPages > 1}
              onDeleteRow={handleDeleteManagement}
              loading={isLoaderTable}
              onNextPage={handleFetchDataPage}
              onPreviousPage={handleFetchDataPage}
              onGotoPage={handleFetchDataPage}
              onRefreshPage={handleFetchDataPage}
              pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            />
          )}
        </Stack>
      </Box>
      {canCreate && (
        <BottomBar>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton sx={{ width: '144px' }} size="large" onClick={handleCreateManagement}>
              Create New
            </MButton>
          </Stack>
        </BottomBar>
      )}
    </>
  ) : (
    <Box position="absolute" top="50vh" left="50%" zIndex={10} transform="translate(-50%, -50%)">
      <CircularProgress />
    </Box>
  );
};

export default Managements;
