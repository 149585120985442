import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import 'rc-time-picker/assets/index.css';
import { Typography, TextField, Stack } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { palette } from '../../../theme/default';
import { ReactComponent as Asterisk } from '../../../assets/icons/asterisk.svg';
import LoaderCover from '../LoaderCover';
import { getRandomId } from '../../../utils/randomId';

const StyledDatePicker = styled(DesktopDatePicker)((props) => ({
  '& .MuiInputBase-root': {
    height: props.height,
    width: props.width,
  },
  '& fieldset': {
    border: `1px solid ${palette.buttonSecondary.disabledBorder}`,
    borderRadius: props.borderRadius,
  },
  '& input': {
    fontWeight: 500,
    fontSize: '16px',
    paddingBottom: 0,
    paddingTop: 0,
  },
  '& .Mui-disabled': {
    backgroundColor: palette.fieldDisabled.backgroundColor,
  },
  '& .Mui-disabled input': {
    '-webkit-text-fill-color': palette.text.disabledText,
  },
  ...props.customStyles,
}));

const StyledTimePicker = styled(TimePicker)((props) => ({
  '& .MuiInputBase-root': {
    height: props.height,
    width: props.width,
  },
  '& fieldset': {
    border: `1px solid ${palette.buttonSecondary.disabledBorder}`,
    borderRadius: props.borderRadius,
  },
  '& input': {
    fontWeight: 500,
    fontSize: '16px',
    paddingBottom: 0,
    paddingTop: 0,
  },
  '& .Mui-disabled': {
    backgroundColor: palette.fieldDisabled.backgroundColor,
  },
  '& .Mui-disabled input': {
    '-webkit-text-fill-color': palette.text.disabledText,
  },
}));

const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

const StyledTextField = styled(TextField)((props) => ({
  ...(props.forbidManualInput && {
    '& .Mui-disabled': {
      backgroundColor: 'transparent',
      '& > input': {
        color: palette.text.primary,
        '-webkit-text-fill-color': palette.text.primary,
      },
    },
    '& fieldset': {
      border: `1px solid ${palette.buttonSecondary.disabledBorder} !important`,
      borderRadius: props.borderRadius,
    },
    ...props.inputProps.sx,
  }),
}));

const DateOrTimePicker = ({
  id,
  Svg,
  width,
  height,
  setSelectedTimeOrDate,
  onChange,
  isTime,
  isDate,
  selected,
  placeholder,
  label,
  dateFormat,
  timeFormat,
  isMandatory,
  disabled,
  borderRadius,
  minDate,
  maxDate,
  shouldDisableDate,
  isLoading,
  onMonthChange,
  error,
  tooltip,
  forbidManualInput,
  onEnter,
  onClose,
  inputProps,
  customStyles,
}) => {
  const handleChange = (data) => {
    if (setSelectedTimeOrDate) {
      setSelectedTimeOrDate(data);
    }
    onChange(data);
  };
  const inputDisabled = disabled || forbidManualInput;

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && onEnter) {
      e.preventDefault();
      onEnter(e);
    }
  };

  const inputId = useMemo(() => {
    if (id) return id;
    return getRandomId();
  }, [id]);

  const renderInput = (params) => (
    <StyledTextField
      {...params}
      inputProps={{
        ...params.inputProps,
        ...inputProps,
        disabled: inputDisabled,
        id: inputId,
        'data-testid': 'input',
      }}
      helperText={inputProps?.helperText}
      disabled={inputDisabled}
      forbidManualInput={forbidManualInput}
      error={error?.message}
      onKeyDown={onKeyDown}
    />
  );

  return (
    <Stack position="relative" height="auto">
      {label && (
        <Typography
          htmlFor={inputId}
          variant="buttonLarge"
          color="text.secondary"
          marginBottom={0.5}
          component="label"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          data-testid="label"
        >
          {label}
          {tooltip}
          {isMandatory && (
            <StyledSpan style={{ alignSelf: 'flex-start' }}>
              <Asterisk data-testid="mandatory_icon" />
            </StyledSpan>
          )}
        </Typography>
      )}
      {isDate && (
        <StyledDatePicker
          customStyles={customStyles}
          width={width}
          height={height}
          borderRadius={borderRadius}
          value={selected || null}
          onChange={handleChange}
          renderInput={renderInput}
          inputFormat={dateFormat}
          minDate={minDate && minDate}
          maxDate={maxDate && maxDate}
          PopperProps={{ style: { zIndex: 10000 } }}
          onMonthChange={onMonthChange}
          data-testid="date_datepicker"
          renderLoading={() => <LoaderCover />}
          loading={isLoading}
          components={{
            OpenPickerIcon: Svg,
          }}
          shouldDisableDate={shouldDisableDate}
          disabled={disabled}
          onClose={onClose}
        />
      )}

      {isTime && (
        <StyledTimePicker
          width={width}
          height={height}
          borderRadius={borderRadius}
          value={selected || null}
          onChange={handleChange}
          renderInput={renderInput}
          placeholder={placeholder}
          inputFormat={timeFormat}
          data-testid="time_datepicker"
          components={{
            OpenPickerIcon: Svg,
          }}
          disabled={disabled}
          onClose={onClose}
        />
      )}

      {error?.message && (
        <Typography
          variant="bodyXS"
          color="additional.alert"
          component="span"
          position="absolute"
          left={0}
          bottom={0}
          top="calc(100% + 4px)"
          data-testid="error_message"
          sx={{ ...customStyles.error }}
        >
          {error?.message}
        </Typography>
      )}
    </Stack>
  );
};

DateOrTimePicker.propTypes = {
  id: PropTypes.string,
  Svg: PropTypes.element.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  setSelectedTimeOrDate: PropTypes.func,
  onChange: PropTypes.func,
  isTime: PropTypes.bool,
  isDate: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMandatory: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.string]),
  label: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.node,
  borderRadius: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.instanceOf(Date)]),
  forbidManualInput: PropTypes.bool,
  onEnter: PropTypes.func,
  onMonthChange: PropTypes.func,
  shouldDisableDate: PropTypes.func,
  onClose: PropTypes.func,
  inputProps: PropTypes.shape({ helperText: PropTypes.string }),
  customStyles: PropTypes.shape({ error: PropTypes.shape({}) }),
};

DateOrTimePicker.defaultProps = {
  id: '',
  isLoading: false,
  setSelectedTimeOrDate: () => {},
  onChange: () => {},
  isTime: false,
  isDate: false,
  selected: '',
  placeholder: '',
  error: { message: '' },
  label: '',
  dateFormat: 'MM/DD/YYYY',
  timeFormat: 'hh:mm A',
  isMandatory: false,
  disabled: false,
  borderRadius: '10px',
  minDate: null,
  maxDate: null,
  tooltip: null,
  forbidManualInput: false,
  shouldDisableDate: null,
  onEnter: null,
  onMonthChange: null,
  onClose: undefined,
  inputProps: {},
  customStyles: {},
};

export default React.memo(DateOrTimePicker);
