import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/system';
import React from 'react';

const InputCell = styled(InputBase)(
  ({ theme, width, isNoBorder, textAlign, error, disabled, readOnly }) => ({
    '& .MuiInputBase-input': {
      width: `${width}px`,
      border: `${isNoBorder || disabled || readOnly ? null : '1px solid'}`,
      borderColor: error ? theme.palette.error.main : theme.palette.additional.lines,
      borderRadius: '10px',
      padding: '12px',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.common.white,
      fontWeight: '500',
      fontSize: 16,
      color: theme.palette.text.secondary,
      height: '40px',
      textAlign: textAlign || 'left',
      '&:focus': {
        borderColor: error ? theme.palette.error.main : theme.palette.additional.lines,
        backgroundColor: theme.palette.common.white,
        borderRadius: '10px',
        border: disabled || readOnly ? 'none' : '1px solid',
      },
      '&:active': {
        borderColor: error ? theme.palette.error.main : theme.palette.additional.lines,
        backgroundColor: theme.palette.common.white,
        borderRadius: '10px',
        border: disabled || readOnly ? 'none' : '1px solid',
      },
      '&::placeholder': {
        color: theme.palette.text.secondary,
        opacity: 1 /* Firefox */,
      },
    },
  }),
);

export default React.memo(InputCell);
