import React, { useEffect } from 'react';
import { styled, Dialog, Typography, Stack } from '@mui/material';
import { palette } from '../../../theme/default';
import MButton from '../../MUI/Button/MButton';
import { useActivityWatcher } from '../../../hooks/useActivityWatcher';
import useAuth from '../../../hooks/useAuth';

const StyledLogoutDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    padding: 32,
    borderRadius: 24,
    boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
  },
  '& .MuiTypography-bodyL500': {
    margin: '32px 0',
  },
});

const LogoutTimer = () => {
  const { onLogout } = useAuth();

  const { isModal, secondsToLogout, okCallback } = useActivityWatcher();

  useEffect(() => {
    if (secondsToLogout === 0) {
      onLogout();
    }
  }, [secondsToLogout]);

  useEffect(() => {
    const setLastActivity = () => localStorage.setItem('lastActivity', (+new Date()).toString());

    const lastActivity = localStorage.getItem('lastActivity');
    if (lastActivity) {
      const now = +new Date();
      const minutes = Math.floor((now - +lastActivity) / 1000 / 60);
      if (minutes > 15) {
        onLogout();
      }
    } else {
      setLastActivity();
    }

    const interval = setInterval(() => {
      setLastActivity();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <StyledLogoutDialog open={isModal} data-testid="logout_dialog">
      <Stack spacing={4} direction="column" width="528px">
        <Typography variant="h4">{secondsToLogout} seconds</Typography>
        <Typography variant="bodyL500">User session is running out.</Typography>

        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto" m={0}>
          <MButton
            size="large"
            onClick={okCallback}
            sx={{ width: '104px' }}
            m={0}
            data-testid="ok_btn"
          >
            Ok
          </MButton>
        </Stack>
      </Stack>
    </StyledLogoutDialog>
  );
};

export default LogoutTimer;
