import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, styled, Typography, Stack, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  listConfig,
  ATTORNEY_REVIEW,
  CIVIL_MATTERS,
  DEADLINES_DUE_DATES,
  UNASSIGNED_EXTRA_OPTION,
} from './DashboardAttorney.constants';
import { getDashboardAttorneyList } from '../../../../store/slices/attorneyDashboardSlice';
import EmptyBlock from '../../../molecules/EmptyBlock';
import Dropdown from '../../../atoms/Dropdown';
import { ATTORNEYS_EXTRA_OPTION } from '../../Reports/ProductivityByAttorney/ProductivityByAttorney.constants';
import { apiClient } from '../../../../lib/apiClient';
import DashboardAttorneyCivilMatters from './DashboardAttorneyCivilMatters';
import { SORT_ORDER_DESC, useSortTable } from '../../../../hooks/useSortTable';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { AttorneyReview } from './AttorneyReview';
import { DeadlinesDueDates } from './DeadLinesDueDates';
import PeriodButtons from '../../../atoms/PeriodButtons';

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  padding: '32px',
  border: `1px solid ${theme.palette.additional.lines}`,
  borderRadius: '16px',
  maxWidth: 'calc(100vw - 328px - 64px - 114px)',
  flexGrow: 1,
}));

const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

const DashboardAttorneyList = ({
  type,
  isLoader,
  setIsLoader,
  setCoverLoader,
  coverLoader,
  stateCode,
  defaultAttorneyId,
}) => {
  const { title, periods, defaultPeriod: defaultPeriodFromConst } = listConfig[type];
  const [selectedIds, setSelectedIds] = useState([]);
  const [attorneys, setAttorneys] = useState([]);
  const [attorney, setAttorney] = useState(null);
  const dispatch = useDispatch();
  const lists = useSelector((state) => state.attorneyDashboard.lists);
  const { settings } = useSelector((state) => state.attorneyDashboard);

  const settingsForCards =
    stateCode === 'AZ' ? settings?.arizonaAttorney : settings?.nevadaAttorney;

  const getSettingsAttorneyByType = () => {
    switch (type) {
      case 'attorneyReview': {
        return settingsForCards?.overviewDto?.attorneyReview;
      }
      case 'deadlineDueDates': {
        return settingsForCards?.overviewDto?.deadlinesDueDatesAttorney;
      }
      case 'civilMatters': {
        return settingsForCards?.overviewDto?.civilMattersAttorney;
      }
      default:
        return '';
    }
  };

  const defaultSettingByCard = settingsForCards?.overviewDto?.deadlinesDueDatesOption;

  const defaultPeriod = defaultSettingByCard || defaultPeriodFromConst;
  const wasLoaded = useRef({
    [CIVIL_MATTERS]: false,
    [ATTORNEY_REVIEW]: false,
    [DEADLINES_DUE_DATES]: false,
  });

  const [period, setPeriod] = useState(defaultPeriod);

  const data = lists?.[type];
  const { sortField, sortOrder, handleSortTable } = useSortTable(
    'referenceNumber',
    SORT_ORDER_DESC,
  );

  useEffect(() => {
    // if we change state or type of card, we shouldn't see previously selected attorney
    if (attorneys?.length) {
      const defaultAttorneyByCardFromSettings = getSettingsAttorneyByType(type);
      const defaultId = defaultAttorneyByCardFromSettings || defaultAttorneyId;
      const currentInTheList = attorneys.find((item) => item.id === defaultId);
      if (currentInTheList) {
        setAttorney(currentInTheList);
      } else {
        setAttorney(ATTORNEYS_EXTRA_OPTION);
      }
    }
  }, [attorneys?.length, defaultAttorneyId, type, settingsForCards?.overviewDto]);

  const handleFetchDataPage = useCallback(
    (currentPage = 1, nextPeriod = period) => {
      if (!attorney) return null;
      if (!wasLoaded.current?.[type]) {
        setCoverLoader(true);
      }
      setIsLoader(true);
      const params = {
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        currentPage,
        onlyTotal: false,
        stateCode,
        api: type,
        attorney: attorney?.id,
      };

      if (sortField) params.sortField = capitalizeFirstLetter(sortField);
      if (sortOrder) params.sortOrder = sortOrder;

      if (type === DEADLINES_DUE_DATES) {
        if (nextPeriod) {
          if (nextPeriod === 'day') {
            params.from = moment().format('yyyy-MM-DD');
            params.to = moment().format('yyyy-MM-DD');
          } else if (nextPeriod === 'week') {
            params.from = moment().startOf('week').format('yyyy-MM-DD');
            params.to = moment().endOf('week').format('yyyy-MM-DD');
          } else if (nextPeriod === 'month') {
            params.from = moment().startOf('month').format('yyyy-MM-DD');
            params.to = moment().endOf('month').format('yyyy-MM-DD');
          }
        }
      }

      return dispatch(getDashboardAttorneyList(params))
        .then(() => {
          setIsLoader(false);
          setCoverLoader(false);
          setSelectedIds([]);
        })
        .finally(() => {
          wasLoaded.current = { ...wasLoaded.current, [type]: true };
        });
    },
    [stateCode, attorney, sortField, sortOrder, type, period],
  );

  useEffect(() => {
    const fetcher = async () => {
      const { data: attorneysList } = await apiClient.get(
        `/api/cases/attorneys?stateCode=${stateCode}`,
      );
      const list = attorneysList.result.map((item) => ({
        id: item.userId,
        value: item.userId,
        label: `${item.firstName} ${item.lastName}`,
      }));
      let attorneyList = [ATTORNEYS_EXTRA_OPTION, UNASSIGNED_EXTRA_OPTION, ...list];
      if (type === DEADLINES_DUE_DATES) {
        attorneyList = [ATTORNEYS_EXTRA_OPTION, ...list];
      }

      setAttorneys(attorneyList);
    };
    fetcher();
  }, [stateCode, type]);

  useEffect(() => {
    handleFetchDataPage(1)
      ?.then(() => setIsLoader(false))
      .finally(() => setIsLoader(false));
  }, [stateCode, attorney, sortField, sortOrder, type, period]);

  useEffect(() => {
    if (type === DEADLINES_DUE_DATES) {
      setPeriod(defaultPeriod);
    }
  }, [type, defaultPeriod]);

  const handleChangeAttorney = (val) => setAttorney(val);

  const renderTable = () => {
    switch (type) {
      case CIVIL_MATTERS:
        return (
          <DashboardAttorneyCivilMatters
            stateCode={stateCode}
            sortField={sortField}
            sortOrder={sortOrder}
            handleSortTable={handleSortTable}
            handleFetchDataPage={handleFetchDataPage}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            isLoader={isLoader}
            setIsLoader={setIsLoader}
          />
        );
      case ATTORNEY_REVIEW:
        return (
          <AttorneyReview
            stateCode={stateCode}
            sortField={sortField}
            sortOrder={sortOrder}
            handleSortTable={handleSortTable}
            handleFetchDataPage={handleFetchDataPage}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            isLoader={isLoader}
          />
        );
      case DEADLINES_DUE_DATES:
        return (
          <DeadlinesDueDates
            stateCode={stateCode}
            sortField={sortField}
            sortOrder={sortOrder}
            handleSortTable={handleSortTable}
            handleFetchDataPage={handleFetchDataPage}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            isLoader={isLoader}
            period={period}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      {coverLoader && (
        <Box position="absolute" top="50%" left="50%" zIndex={10} transform="translate(-50%, -50%)">
          <CircularProgress />
        </Box>
      )}
      {!coverLoader && (
        <>
          <Stack flex direction="row" justifyContent="space-between" alignItems="center" mb={4}>
            <Typography variant="h5">{title}</Typography>
            <Stack flex direction="row" alignItems="center">
              {periods && (
                <PeriodButtons
                  currentPeriod={period}
                  isDisabled={isLoader}
                  onPeriodChange={(p) => {
                    setPeriod(p);
                  }}
                  periods={periods}
                />
              )}
              <Box ml={2}>
                <Dropdown
                  width="248px"
                  isColumn
                  isSearchable
                  placeholder="Select attorney"
                  value={attorney}
                  options={attorneys}
                  onChange={handleChangeAttorney}
                />
              </Box>
            </Stack>
          </Stack>
          {!!data?.items?.length && <Box flexGrow={1}>{renderTable()}</Box>}
          {!data?.items?.length && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              flexGrow={1}
            >
              <EmptyBlock title="No records" />
            </Box>
          )}
        </>
      )}
    </Wrapper>
  );
};

DashboardAttorneyList.propTypes = {
  type: PropTypes.oneOf([ATTORNEY_REVIEW, DEADLINES_DUE_DATES, CIVIL_MATTERS]).isRequired,
  isLoader: PropTypes.bool.isRequired,
  setIsLoader: PropTypes.func.isRequired,
  stateCode: PropTypes.string.isRequired,
  defaultAttorneyId: PropTypes.string.isRequired,
  coverLoader: PropTypes.bool.isRequired,
  setCoverLoader: PropTypes.func.isRequired,
};

export default DashboardAttorneyList;
