import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TextCell = ({ value }) => {
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
      }}
    >
      {value}
    </Typography>
  );
};

TextCell.propTypes = {
  value: PropTypes.string,
};

TextCell.defaultProps = {
  value: '',
};

export default TextCell;
