export const subPageNavNew = [
  {
    label: 'Owner Entity',
    to: '/db/datahandling/newownerentity/ownerentity',
    state: {
      navPage: 'Data Handling',
      subNav: 'Owner Entities',
    },
  },
  {
    label: 'Address',
    to: '/db/datahandling/newownerentity/address',
    state: {
      navPage: 'Data Handling',
      subNav: 'Owner Entities',
    },
  },
  {
    label: 'Documents',
    to: '/db/datahandling/newownerentity/documents',
    state: {
      navPage: 'Data Handling',
      subNav: 'Owner Entities',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/newownerentity/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Owner Entities',
    },
  },
];

export const subPageNavEdit = [
  {
    label: 'Owner Entity',
    to: '/db/datahandling/editownerentity/ownerentity',
    state: {
      navPage: 'Data Handling',
      subNav: 'Owner Entities',
    },
  },
  {
    label: 'Address',
    to: '/db/datahandling/editownerentity/address',
    state: {
      navPage: 'Data Handling',
      subNav: 'Owner Entities',
    },
  },
  {
    label: 'Documents',
    to: '/db/datahandling/editownerentity/documents',
    state: {
      navPage: 'Data Handling',
      subNav: 'Owner Entities',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/editownerentity/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Owner Entities',
    },
  },
];
