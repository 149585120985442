import {
  invalidEmailErrorMessage,
  invalidPasswordErrorMessage,
  isEmailValid,
  isPasswordValid,
} from '../../../../utils/formHelpers';

export const validateDialogForm = ({ email, userName, password, confirmPassword }) => {
  const errors = {
    hasErros: false,
  };

  if (!/^([a-zA-Z0-9]+)$/.test(userName)) {
    errors.userName = 'Username can only contain letters or digits.';
    errors.hasErros = true;
  }

  if (!isEmailValid(email)) {
    errors.email = invalidEmailErrorMessage;
    errors.hasErros = true;
  }

  if (password?.length || confirmPassword?.length) {
    if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
      errors.hasErros = true;
    }

    if (!isPasswordValid(password)) {
      errors.password = invalidPasswordErrorMessage;
      errors.hasErros = true;
    }
  }

  return errors;
};
