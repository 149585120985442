import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  addAddressesToJusticeCourt,
  addAddressesToSuperiorCourt,
  createJusticeCourt,
  createSuperiorCourt,
  getJusticeCourt,
  getJusticeFeesAndCosts,
  getListOfAddressesForJusticeCourt,
  getListOfAddressesForSuperiorCourt,
  getSuperiorCourt,
  getSuperiorFeesAndCosts,
  removeAddressesFromJusticeCourt,
  removeAddressesFromSuperiorCourt,
  updateJusticeCourt,
  updateSuperiorCourt,
} from '../../../../store/slices/courts/arizonaCourtsSlice';
import {
  addAddressesToCourt,
  createCourt,
  getCourt,
  getFeesAndCosts,
  getListOfAddressesForCourt,
  removeAddressesFromCourt,
  updateCourt,
} from '../../../../store/slices/courts/nevadaCourtsSlice';

export const useStateSpecificStore = ({ stateCode, type }) => {
  const arizonaCourts = useSelector((state) => state.arizonaCourts);
  const nevadaCourts = useSelector((state) => state.nevadaCourts);

  const data = useMemo(() => {
    if (stateCode === 'NV') {
      return {
        create: createCourt,
        get: getCourt,
        update: updateCourt,
        getFeesAndCosts,
        getAddresses: getListOfAddressesForCourt,
        removeAddresses: removeAddressesFromCourt,
        addAddresses: addAddressesToCourt,

        court: nevadaCourts.getCourt,
        feesAndCosts: nevadaCourts.getFeesAndCosts,
        addresses: nevadaCourts.getListOfAddressesForCourt,
      };
    }
    if (stateCode === 'AZ' && type === 'Justice') {
      return {
        create: createJusticeCourt,
        get: getJusticeCourt,
        update: updateJusticeCourt,
        getFeesAndCosts: getJusticeFeesAndCosts,
        getAddresses: getListOfAddressesForJusticeCourt,
        addAddresses: addAddressesToJusticeCourt,
        removeAddresses: removeAddressesFromJusticeCourt,

        court: arizonaCourts.getJusticeCourt,
        feesAndCosts: arizonaCourts.getJusticeFeesAndCosts,
        addresses: arizonaCourts.getListOfAddressesForJusticeCourt,
      };
    }
    if (stateCode === 'AZ' && type === 'Superior') {
      return {
        create: createSuperiorCourt,
        get: getSuperiorCourt,
        update: updateSuperiorCourt,
        getFeesAndCosts: getSuperiorFeesAndCosts,
        getAddresses: getListOfAddressesForSuperiorCourt,
        addAddresses: addAddressesToSuperiorCourt,
        removeAddresses: removeAddressesFromSuperiorCourt,

        court: arizonaCourts.getSuperiorCourt,
        feesAndCosts: arizonaCourts.getSuperiorFeesAndCosts,
        addresses: arizonaCourts.getListOfAddressesForSuperiorCourt,
      };
    }
    return {};
  }, [stateCode, type, arizonaCourts, nevadaCourts]);

  return data;
};
