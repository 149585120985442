import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Stack, Typography } from '@mui/material';
import ShadowBlock from '../../atoms/ShadowBlock';
import { ReactComponent as DocumentText } from '../../../assets/icons/DocumentText.svg';
import { ReactComponent as DocumentCopy } from '../../../assets/icons/DocumentCopy.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/ArrowLeft.svg';
import { palette } from '../../../theme/default';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const StyledWrapper = ({ title, icon, to, navPage, subNav, subSubNavigation }) => {
  return (
    <Stack
      component={Link}
      to={to}
      state={{
        subSubNavigation,
        navPage,
        subNav,
      }}
      sx={{ textDecoration: 'none' }}
      width="calc((100% - 24px)/2)"
    >
      <ShadowBlock>
        <Stack alignItems="center" spacing={3}>
          <Typography variant="h5" color="text.secondary" align="center" whiteSpace="pre-line">
            {title}
          </Typography>
          {icon}
        </Stack>
      </ShadowBlock>
    </Stack>
  );
};

const ClientPortalEvictionRequests = () => {
  const base = useBaseUrl();
  return (
    <Stack height="calc(100vh - 80px - 72px)" margin="24px 32px" direction="column">
      <Stack spacing={1} direction="row" alignItems="center" width={1}>
        <Link
          to={`/${base}/requests`}
          state={{
            navPage: 'Requests',
          }}
        >
          <ArrowLeft width={24} height={24} />
        </Link>
        <Typography variant="h4">Eviction Requests</Typography>
      </Stack>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          m: 'auto',
          px: 4,
          width: 1,
          maxWidth: 825,
        }}
      >
        <Stack spacing={3} direction="row" justifyContent="center">
          <StyledWrapper
            title={`Request Eviction For\nNew Case`}
            icon={<DocumentText color={palette.buttonPrimary.active} />}
            to="evictionintakeform"
            navPage="Requests"
            subNav="Eviction Requests"
            subSubNavigation="Request Eviction For New Case"
          />
          <StyledWrapper
            title={`Request Eviction For\nExisting Case`}
            icon={<DocumentCopy color={palette.buttonPrimary.active} />}
            to={`/${base}/cases/casesearch`}
            state={{ caseTab: 'requests', stateCode: 'NV' }}
            navPage="Cases"
          />
        </Stack>
      </Container>
    </Stack>
  );
};

StyledWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  navPage: PropTypes.string.isRequired,
  subNav: PropTypes.string.isRequired,
  subSubNavigation: PropTypes.string,
  icon: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

StyledWrapper.defaultProps = {
  subSubNavigation: '',
};

export default ClientPortalEvictionRequests;
