import React, { useCallback, useEffect, useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Container, Stack, Typography } from '@mui/material';
import Table from '../../../atoms/Table/Table';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import { addObjToFormData } from '../../../../utils/convertObjToFormData';
import {
  DOCUMENT_COLUMNS_NOTICE,
  DOCUMENT_COLUMNS_LEASE,
  DOCUMENT_COLUMNS_LEDGER,
} from './RequestEvictionNevada.constants';
import { setEvictionWithDocs, getEviction } from '../../../../store/slices/cases/caseCpSlice';
import MTooltip from '../../../atoms/MTooltip';
import notificationUtils from '../../../../utils/notificationUtils';

const StyledRequestDocument = ({
  title,
  buttonText,
  checkboxText,
  onChangeFile,
  onChange,
  onDeleteRow,
  docColumns,
  docRows,
  setNameType,
  setCheckedName,
  isDisabled,
  isChecked,
  checkboxName,
  documentName,
  refElement,
  tooltip,
}) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h5" color="text.primary">
        {title}
      </Typography>
      <Stack
        alignItems="center"
        justifyContent={checkboxText ? 'space-between' : 'flex-end'}
        direction="row"
      >
        {checkboxText && (
          <CheckboxWithLabel
            label={
              <>
                {checkboxText}
                {tooltip && <MTooltip text={tooltip} />}
              </>
            }
            onChange={onChange(setCheckedName)}
            isDisabled={isDisabled}
            isChecked={isChecked}
            name={checkboxName}
          />
        )}
        <MButton size="large" component="label" disabled={isDisabled || isChecked}>
          {buttonText}
          <input
            type="file"
            ref={refElement}
            name={documentName}
            onChange={onChangeFile(setNameType)}
            hidden
          />
        </MButton>
      </Stack>
      <Table columns={docColumns} rows={docRows} onDeleteRow={onDeleteRow} />
    </Stack>
  );
};

const RequestEvictionNevada = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: stateLocation } = useLocation();

  const inputNotice = useRef(null);
  const inputLease = useRef(null);
  const inputLedger = useRef(null);
  const [noticeFile, setNoticeFile] = useState(null);
  const [leaseFile, setLeaseFile] = useState(null);
  const [ledgerFile, setLedgerFile] = useState(null);
  const [isCheckedLease, setIsCheckedLease] = useState(false);
  const [isCheckedLedger, setIsCheckedLedger] = useState(false);

  const { evictionDocumentsInfo } = useSelector((state) => state.caseCp);

  const noticeDocuments = evictionDocumentsInfo?.noticeDocuments?.length
    ? evictionDocumentsInfo?.noticeDocuments?.map((item) => ({
        noticeName: item.documentName,
        noticeType: item.documentType,
        isDeleteRow: false,
      }))
    : [];
  const leaseDocuments = evictionDocumentsInfo?.leaseDocuments?.length
    ? evictionDocumentsInfo?.leaseDocuments?.map((item) => ({
        leaseName: item.documentName,
        leaseType: item.documentType,
        isDeleteRow: false,
      }))
    : [];
  const ledgerDocuments = evictionDocumentsInfo?.ledgerDocuments?.length
    ? evictionDocumentsInfo?.ledgerDocuments?.map((item) => ({
        ledgerName: item.documentName,
        ledgerType: item.documentType,
        isDeleteRow: false,
      }))
    : [];

  const notiesRowsTable = noticeFile?.name
    ? [{ noticeName: noticeFile.name, noticeType: 'Notice', isDeleteRow: true }, ...noticeDocuments]
    : noticeDocuments;
  const leaseRowsTable = leaseFile?.name
    ? [{ leaseName: leaseFile.name, leaseType: 'Lease', isDeleteRow: true }, ...leaseDocuments]
    : leaseDocuments;
  const ledgerRowsTable = ledgerFile?.name
    ? [{ ledgerName: ledgerFile.name, ledgerType: 'Ledger', isDeleteRow: true }, ...ledgerDocuments]
    : ledgerDocuments;

  useEffect(() => {
    dispatch(getEviction(stateLocation.id));
  }, []);

  useEffect(() => {
    setIsCheckedLease(evictionDocumentsInfo.isLeaseUnavailable);
    setIsCheckedLedger(evictionDocumentsInfo.isLedgerUnavailable);
  }, [evictionDocumentsInfo]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {};

    if (inputNotice?.current?.files[0]) {
      data.NoticeDocument = inputNotice?.current?.files[0];
      data.NoticeDocumentName = inputNotice?.current?.files[0]?.name;
    }

    if (isCheckedLease) {
      data.IsLeaseUnavailable = isCheckedLease;
    } else if (inputLease?.current?.files[0]) {
      data.LeaseDocument = inputLease?.current?.files[0];
      data.LeaseDocumentName = inputLease?.current?.files[0]?.name;
    }

    if (isCheckedLedger) {
      data.IsLedgerUnavailable = isCheckedLedger;
    } else if (inputLedger?.current?.files[0]) {
      data.LedgerDocument = inputLedger?.current?.files[0];
      data.LedgerDocumentName = inputLedger?.current?.files[0]?.name;
    }

    dispatch(
      setEvictionWithDocs({
        id: stateLocation.id,
        formData: addObjToFormData(data),
      }),
    )
      .unwrap()
      .then(() => {
        notificationUtils.success('Requested successfully');
        navigate('../requests', {
          state: stateLocation,
        });
      })
      .catch((rejectedValueError) => {
        // todo: Add Alert Component
        alert('---------------', rejectedValueError);
      });
  };

  const handleCancel = useCallback(() => {
    navigate('../requests', {
      state: stateLocation,
    });
  }, []);

  const handleChooseDoc = (setNameType) =>
    useCallback(
      (e) => {
        const file = e.target.files[0];
        setNameType({
          ...file,
          name: file.name,
        });
      },
      [noticeFile, leaseFile, ledgerFile],
    );

  const handleChange = (setCheckedName) =>
    useCallback(
      (e) => {
        setCheckedName(e);
      },
      [isCheckedLease, isCheckedLedger],
    );

  const handleDeleteDoc = (setNameType) =>
    useCallback(() => {
      setNameType(null);
    }, [noticeFile, leaseFile, ledgerFile]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mt: 3,
        px: 4,
        width: 1,
        maxWidth: 1376,
        pb: 20,
      }}
    >
      <form onSubmit={handleSubmit} id="formId">
        <Stack spacing={4}>
          <StyledRequestDocument
            title="Notice"
            documentName="NoticeDocument"
            buttonText="Upload Notice Document"
            onChangeFile={handleChooseDoc}
            docColumns={DOCUMENT_COLUMNS_NOTICE}
            docRows={notiesRowsTable.length ? notiesRowsTable : [{}]}
            onDeleteRow={handleDeleteDoc(setNoticeFile)}
            setNameType={setNoticeFile}
            setCheckedName={() => null}
            isDisabled={!!noticeFile?.name || !!noticeDocuments.length}
            refElement={inputNotice}
          />
          <StyledRequestDocument
            tooltip="Please select if lease is unavailable."
            title="Lease"
            documentName="LeaseDocument"
            buttonText="Upload Lease Document"
            checkboxName="IsLeaseUnavailable"
            checkboxText="Lease Unavailable"
            onChangeFile={handleChooseDoc}
            onChange={handleChange}
            docColumns={DOCUMENT_COLUMNS_LEASE}
            docRows={leaseRowsTable.length ? leaseRowsTable : [{}]}
            onDeleteRow={handleDeleteDoc(setLeaseFile)}
            setNameType={setLeaseFile}
            isDisabled={!!leaseFile?.name || !!leaseDocuments.length}
            isChecked={isCheckedLease}
            setCheckedName={setIsCheckedLease}
            refElement={inputLease}
          />
          <StyledRequestDocument
            tooltip="Please select if ledger is unavailable."
            title="Ledger"
            documentName="LedgerDocument"
            buttonText="Upload Ledger Document"
            checkboxName="IsLedgerUnavailable"
            checkboxText="Ledger Unavailable"
            onChangeFile={handleChooseDoc}
            onChange={handleChange}
            docColumns={DOCUMENT_COLUMNS_LEDGER}
            docRows={ledgerRowsTable.length ? ledgerRowsTable : [{}]}
            onDeleteRow={handleDeleteDoc(setLedgerFile)}
            setNameType={setLedgerFile}
            isDisabled={!!ledgerFile?.name || !!ledgerDocuments.length}
            isChecked={isCheckedLedger}
            setCheckedName={setIsCheckedLedger}
            refElement={inputLedger}
          />
        </Stack>
        <Box
          position="absolute"
          left="50%"
          bottom={0}
          sx={{ transform: 'translateX(-50%)' }}
          zIndex={10}
          width={1}
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton variant="secondary" size="large" onClick={handleCancel}>
              Cancel
            </MButton>
            <MButton
              type="submit"
              size="large"
              name="Submit"
              disabled={
                !(noticeFile?.name || noticeDocuments.length) ||
                !(leaseFile?.name || leaseDocuments.length || isCheckedLease) ||
                !(ledgerFile?.name || ledgerDocuments.length || isCheckedLedger)
              }
            >
              Request eviction
            </MButton>
          </Stack>
        </Box>
      </form>
    </Container>
  );
};

StyledRequestDocument.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  documentName: PropTypes.string.isRequired,
  onChangeFile: PropTypes.func.isRequired,
  setNameType: PropTypes.func.isRequired,
  setCheckedName: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  refElement: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  docColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
      width: PropTypes.number,
      minWidth: PropTypes.string,
      Cell: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]),
    }),
  ),
  docRows: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      isDeleteRow: PropTypes.bool,
    }),
  ),
  isChecked: PropTypes.bool,
  checkboxText: PropTypes.string,
  tooltip: PropTypes.string,
  onChange: PropTypes.func,
  onDeleteRow: PropTypes.func,
  checkboxName: PropTypes.string,
};

StyledRequestDocument.defaultProps = {
  checkboxText: '',
  checkboxName: '',
  tooltip: null,
  onChange: () => null,
  onDeleteRow: () => null,
  isChecked: false,
  docColumns: [],
  docRows: [],
};

export default RequestEvictionNevada;
