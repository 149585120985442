import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import MButton from '../../../MUI/Button/MButton';
import MDialog from '../../../atoms/MDialog/MDialog';
import Table from '../../../atoms/Table/Table';
import { violationColumns } from './Document.constants';
import { LightTooltip } from '../../../atoms/MTooltip/MTooltip';
import EmptyBlock from '../../../molecules/EmptyBlock';

const SelectViolationsPopup = ({ onClose, onSubmit }) => {
  const [selected, setSelected] = useState([]);
  const violations = useSelector(
    (state) => state.casesArizona.listOfAllViolationsForDocumentGeneration,
  );

  const handleCheckedRow = (items) => {
    setSelected(items);
  };

  const { isSubmitDisabled, tooltipText = null } = useMemo(() => {
    if (!selected.length) return { isSubmitDisabled: true };

    const nonPaymentCount = selected.reduce((acc, violation) => {
      if (violation.noticeTypeGroup.toLowerCase().startsWith('non-payment')) return acc + 1;
      return acc;
    }, 0);

    if (nonPaymentCount > 1)
      return {
        isSubmitDisabled: true,
        tooltipText:
          'A complex complaint can only contain one Non-Payment violation and multiple Other-Than violations.',
      };

    return { isSubmitDisabled: false };
  }, [selected]);

  const onSubmitClick = () => onSubmit(selected.map((v) => v.caseViolationId));

  return (
    <MDialog
      title="Select Violation(s)"
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      footerActions={
        <>
          <MButton variant="secondary" size="large" onClick={onClose}>
            Cancel
          </MButton>
          <LightTooltip sx={{ zIndex: 1500 }} title={tooltipText}>
            <Box>
              <MButton
                sx={{ height: '50px' }}
                type="submit"
                size="large"
                onClick={onSubmitClick}
                disabled={isSubmitDisabled}
              >
                Create
              </MButton>
            </Box>
          </LightTooltip>
        </>
      }
    >
      {violations?.length ? (
        <Stack width="100%" pl="32px" pr="32px" sx={{ height: '40vh' }}>
          <Table
            columns={violationColumns}
            rows={violations}
            onCheckedRow={handleCheckedRow}
            isWithCheckbox
            hasFixedColumns
            showFixedColumnsAtWidth={1920}
          />
        </Stack>
      ) : (
        <Box display="flex" justifyContent="center">
          <EmptyBlock title="No records" desc="No violations available for complaint creation" />
        </Box>
      )}
    </MDialog>
  );
};

SelectViolationsPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

SelectViolationsPopup.defaultProps = {};

export default SelectViolationsPopup;
