/* eslint-disable no-await-in-loop */
import React, { useMemo, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import {
  ClientAcquisitionFormBoxes,
  ClientAcquisitionFormBoxesColumns,
  stateCodesOptions,
} from './ClientAcquisition.constants';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';

import {
  createClientAcquisitionReport,
  getClientAcquisitionReport,
} from '../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import Dropdown from '../../../atoms/Dropdown';

import Table from '../../../atoms/Table/Table';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import notificationUtils from '../../../../utils/notificationUtils';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { entityTypes } from './cells/NameCell';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import { palette } from '../../../../theme/default';
import './ClientAcquisition.css';
import useAuth from '../../../../hooks/useAuth';
import ProgressModal from '../ProductivityByAttorney/ProgressModal';

const ClientAcquisition = () => {
  const isAdmin = useAllowed([rolesDB.Admin]);
  const { userInfo } = useAuth();
  const [isLoader, setIsLoader] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [expandedMgs, setExpandedMgs] = useState({});
  const [expandedAddress, setExpandedAddress] = useState({});
  const [dateRange, setDateRange] = useState('');
  const [currentStateName, setCurrentStateName] = useState('');
  const [ranges, setRanges] = useState(null);

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const fromDate = form.watch('fromDate');
  const toDate = form.watch('toDate');

  const currentRange = useMemo(() => {
    return `${moment(fromDate).format('L')} - ${moment(toDate).format('L')}`;
  }, [fromDate, toDate]);

  const formStateCode = form.watch('stateCode');

  const getOptions = (name) => {
    switch (name) {
      case 'stateCode':
        return isAdmin
          ? stateCodesOptions
          : stateCodesOptions.filter(({ value }) => userInfo.StateCode === value);
      default:
        return [];
    }
  };

  const handleSubmit = async (values) => {
    const data = {
      fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
      toDate: moment(values.toDate).format('YYYY-MM-DD'),
      stateCode: values.stateCode?.value,
    };
    setCurrentStateName(values.stateCode?.value === 'AZ' ? 'Arizona' : 'Nevada');
    setIsLoader(true);

    try {
      const { reportId } = await dispatch(createClientAcquisitionReport(data)).unwrap();
      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code, message },
        } = await dispatch(getClientAcquisitionReport(reportId)).unwrap();
        if (code === 'Completed') {
          setTableData(items);
          done = true;
          setDateRange(currentRange);
        } else if (code === 'Error') {
          notificationUtils.error(message);
          setDateRange('');
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      notificationUtils.error(e.message || 'Something went wrong');
      setDateRange('');
    }
    setIsLoader(false);
  };

  const rowsAdapter = useMemo(() => {
    const values = [];
    tableData?.forEach((item) => {
      item.managements.forEach((mgt) => {
        const isManagementExpanded = expandedMgs[mgt.id] || false;
        values.push({
          name: mgt.managementName,
          expanded: isManagementExpanded,
          visible: true,
          className: 'management',
          managementName: mgt.managementName,
          id: mgt.id,
          creationDate: mgt.creationDate,
          isNew: mgt.isNew,
          type: entityTypes.MANAGEMENT,
        });
        if (isManagementExpanded) {
          mgt.communities.forEach((com) => {
            values.push({
              name: com.name,
              expanded: false,
              visible: true,
              managementName: mgt.managementName,
              id: com.id,
              creationDate: com.creationDate,
              type: entityTypes.COMMUNITY,
            });
          });
          const isAddressExpanded = expandedAddress[mgt.id] || false;
          if (mgt.standaloneAddresses?.length) {
            values.push({
              name: 'Standalone Addresses',
              expanded: isAddressExpanded,
              visible: true,
              managementName: mgt.managementName,
              id: mgt.id,
              type: entityTypes.STANDALONE_ADDRESSES,
            });
            if (isAddressExpanded) {
              mgt.standaloneAddresses.forEach((address) => {
                values.push({
                  name: address.name,
                  expanded: false,
                  visible: true,
                  managementName: mgt.managementName,
                  id: address.id,
                  creationDate: address.creationDate,
                  type: entityTypes.ADDRESS,
                });
              });
            }
          }
        }
      });
    });
    return values;
  }, [tableData, expandedMgs, expandedAddress]);

  const handleRowClick = (val) => {
    if (val.type === entityTypes.MANAGEMENT) {
      setExpandedMgs((prev) => {
        return { ...prev, [val.value]: !prev[val.value] };
      });
    } else {
      setExpandedAddress((prev) => {
        return { ...prev, [val.value]: !prev[val.value] };
      });
    }
  };
  const columns = useMemo(() => {
    return ClientAcquisitionFormBoxesColumns({ stateCode: formStateCode });
  }, [formStateCode]);

  const getRanges = (name) => {
    const currentFromDate = form.getValues('fromDate');
    const currentToDate = form.getValues('toDate');

    if (name.includes('fromDate')) {
      setRanges({ toDate: { min: currentFromDate } });
    }
    if (name.includes('toDate')) {
      setRanges({ fromDate: { max: currentToDate } });
    }
  };

  return (
    <Stack direction="column" mb={6}>
      <ShadowBlock>
        {isLoader && <ProgressModal isOpen={isLoader} />}
        <Form form={form} onSubmit={handleSubmit}>
          {ClientAcquisitionFormBoxes.map((i) => (
            <Stack
              direction="row"
              alignItems="center"
              columnGap={3}
              rowGap={2}
              flexWrap="wrap"
              py={1}
            >
              {i.inputBoxes.map((item) => {
                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <Dropdown
                          value={field.value}
                          isAddDisabled={false}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          isColumn
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={onCustomChange(field.onChange)}
                          isMandatory={item.isMandatory}
                        />
                      )}
                    />
                  );
                }
                if (item.isDatePicker) {
                  return (
                    <Field
                      name={item.name}
                      key={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange, error }) => (
                        <DateOrTimePicker
                          name={item.name}
                          label={item.label}
                          isDate
                          error={error}
                          Svg={Calender}
                          selected={field.value}
                          isMandatory={item.isMandatory}
                          width={item.width}
                          height={item.height}
                          placeholder={item.placeholder}
                          minDate={ranges?.[item.name]?.min || null}
                          maxDate={ranges?.[item.name]?.max || moment()}
                          onClose={() => getRanges(item.name)}
                          setSelectedTimeOrDate={onCustomChange(field.onChange)}
                        />
                      )}
                    />
                  );
                }
                return null;
              })}
            </Stack>
          ))}
          <Stack direction="row" mt={2} gap={2}>
            <MButton
              data-testid="run_report_btn"
              type="submit"
              size="medium"
              disabled={isLoader || !form.formState.isValid}
            >
              Run Report
            </MButton>
          </Stack>
        </Form>
      </ShadowBlock>

      {tableData && (
        <Box mt={3}>
          <ShadowBlock customStyles={{ gap: 0 }}>
            <Typography variant="h5" mt={4} mb={2} data-testid="report_title">
              Client Acquisition Report for {currentStateName} {dateRange}
            </Typography>
            {tableData?.length && !isLoader ? (
              <Box>
                <Box mb={4}>
                  <Grid container>
                    <Grid item>
                      <Typography
                        paragraph
                        mr={2}
                        sx={{ fontSize: '12px', fontWeight: 600 }}
                        color={palette.text.secondary}
                      >
                        {tableData?.[0].totalNewManagements || 0}
                      </Typography>
                      <Typography
                        paragraph
                        mr={2}
                        sx={{ fontSize: '12px', fontWeight: 600 }}
                        color={palette.text.secondary}
                      >
                        {tableData?.[0].totalNewCommunities || 0}
                      </Typography>
                      <Typography
                        paragraph
                        mr={2}
                        sx={{ fontSize: '12px', fontWeight: 600 }}
                        color={palette.text.secondary}
                      >
                        {tableData?.[0].totalNewStandaloneAddresses || 0}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        paragraph
                        mr={2}
                        sx={{ fontSize: '12px' }}
                        color={palette.text.secondary}
                      >
                        New Management companies
                      </Typography>
                      <Typography
                        paragraph
                        mr={2}
                        sx={{ fontSize: '12px' }}
                        color={palette.text.secondary}
                      >
                        New Communities
                      </Typography>
                      <Typography
                        paragraph
                        mr={2}
                        sx={{ fontSize: '12px' }}
                        color={palette.text.secondary}
                      >
                        New Standalone Addresses
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Table columns={columns} rows={rowsAdapter} onRowClick={handleRowClick} />
              </Box>
            ) : (
              <Box
                display="flex"
                width="1140px"
                flexDirection="column"
                alignItems="center"
                mt={12}
                mb={25}
                mx="auto"
              >
                <EmptyBlock
                  title="No Records"
                  desc="Please change filters to display report"
                  descFontSize={18}
                />
              </Box>
            )}
          </ShadowBlock>
        </Box>
      )}
    </Stack>
  );
};

export default ClientAcquisition;
