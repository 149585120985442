import jwtDecode from 'jwt-decode';
import { tokenRepository } from '../lib/apiClient';

const jwtKeysMap = {
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': 'roles',
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role/name': 'roleName',
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress': 'emailAddress',
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': 'username',
  IsClientPortalUser: 'isClientPortalUser',
  StateCode: 'stateCodes',
};

const jwtValuesMappers = {
  isClientPortalUser: (value) => {
    if (value === 'True') return true;
    if (value === 'False') return false;
    return false;
  },
  roles: (value) => {
    if (typeof value === 'string') return [value];
    if (Array.isArray(value)) return value;
    return [];
  },
  stateCodes: (value) => {
    if (typeof value === 'string') return [value];
    if (Array.isArray(value)) return value;
    return [];
  },
};

const getDecodedJwtToken = () => {
  const token = tokenRepository.getToken();
  const data = token ? jwtDecode(token) : {};

  return Object.keys(data).reduce(
    (acc, key) => {
      const value = data[key];

      if (Object.hasOwn(jwtKeysMap, key)) {
        const nextKey = jwtKeysMap[key];
        acc[nextKey] = jwtValuesMappers[nextKey] ? jwtValuesMappers[nextKey](value) : value;
      } else {
        acc[key] = jwtValuesMappers[key] ? jwtValuesMappers[key](value) : value;
      }

      return acc;
    },
    // to leave old properties untouched while new can be used
    { ...data },
  );
};

export default getDecodedJwtToken;
