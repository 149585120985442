import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { apiClient } from '../../../lib/apiClient';

export const useRequestPasswordRecovery = () => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const { portal = 'cp' } = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);

  const onSubmit = handleSubmit(async ({ userNameOrEmail }) => {
    setIsLoader(true);
    try {
      const apiByPortal = {
        db: '/api/passwordRecoveries/tokens',
        cp: '/cp/api/passwordRecoveries/tokens',
        client: '/cp/api/passwordRecoveries/tokens',
      };
      const baseUrl = [window.location.origin, portal, 'password-recovery'].join('/');
      await apiClient.post(apiByPortal[portal], {
        userNameOrEmail,
        baseUrl,
      });
      setShowSuccessNotification(true);
    } catch (e) {
      setError(e.response?.data?.errorMessage || 'Unexpected error');
    }
    setIsLoader(false);
  });

  const onCancel = () => navigate(`/${portal}/login`);

  return {
    control,
    error,
    isLoader,
    onSubmit,
    onCancel,
    showSuccessNotification,
    isValid,
  };
};
