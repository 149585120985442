import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import Table from '../../../atoms/Table/Table';
import Searcher from '../../../molecules/Searcher';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { CourtAddressColumns, MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './CourtAddress.constants';
import { useStateSpecificStore } from '../Court/hooks';
import StateLink from '../../../atoms/StateLink/StateLink';

const CourtAddress = () => {
  const dispatch = useDispatch();
  const {
    state: { id, state: defaultStateCode, type },
  } = useLocation();

  const { addresses, getAddresses: getCourtAddresses } = useStateSpecificStore({
    stateCode: defaultStateCode.value,
    type,
  });
  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);

  const paginationRef = useRef({ autoResetPage: true });
  useEffect(() => {
    dispatch(getCourtAddresses({ id, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() =>
      setIsLoader(false),
    );
  }, []);

  const handleChangeEmpty = useCallback((searchElement) => {
    if (!searchElement) {
      setIsLoaderTable(true);
      dispatch(getCourtAddresses({ id, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() => {
        setSearchValue('');
        setIsLoaderTable(false);
      });
    }
  }, []);

  const handleSearch = useCallback((searchElement) => {
    setSearchValue(searchElement);
    setIsLoaderTable(true);
    dispatch(
      getCourtAddresses({ id, address: searchElement, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE }),
    ).then(() => setIsLoaderTable(false));
  }, []);

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      dispatch(
        getCourtAddresses({
          id,
          currentPage,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          address: searchValue,
        }),
      ).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue],
  );

  const onPagination = (paginationControls) => {
    paginationRef.current = { ...paginationRef.current, ...paginationControls };
  };

  const rowsAdapter = addresses?.items?.map((item) => ({
    ...item,
    address: (
      <StateLink
        to="/db/datahandling/editaddress/address"
        state={{
          navPage: 'Data Handling',
          subNav: 'Addresses',
          id: item.addressId,
          name: item.address,
          state: { value: item.stateCode || 'AZ' }, // TODO add state here
        }}
        alwaysOpenInNewTab
      >
        <Typography
          variant="link"
          color="text.primary"
          sx={{
            cursor: 'pointer',
            textDecorationLine: 'underline',
            textTransform: 'none',
          }}
        >
          {item.address}
        </Typography>
      </StateLink>
    ),
    control: {
      hideDelete: true,
      autoRefresh: true,
    },
  }));

  return !isLoader ? (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        px: 4,
        maxWidth: 1066,
        width: 1,
        pb: '150px',
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h5">Court Addresses</Typography>
        <Searcher
          inputPlaceholder="Search by Address"
          onSearch={handleSearch}
          onChange={handleChangeEmpty}
        />
        {!addresses?.items?.length && !searchValue && (
          <Stack direction="column" paddingTop="100px">
            <Box margin="auto">
              <EmptyBlock title="You don’t have any court addresses yet" />
            </Box>
          </Stack>
        )}
        {!addresses?.items?.length && searchValue && (
          <Stack direction="column" paddingTop="50px">
            <Box margin="auto">
              <EmptyBlock title="Court addresses not found" desc="Сhange the search value" />
            </Box>
          </Stack>
        )}
        {!!addresses?.items?.length && (
          <Table
            columns={CourtAddressColumns}
            rows={rowsAdapter}
            total={addresses?.totalRowsCount}
            isPagination={addresses?.totalPages > 1}
            onNextPage={handleFetchDataPage}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            onPagination={onPagination}
            loading={isLoaderTable}
            autoResetPage={paginationRef.current.autoResetPage}
          />
        )}
      </Stack>
    </Container>
  ) : (
    <Box position="absolute" top="50vh" left="50%" zIndex={10} transform="translate(-50%, -50%)">
      <CircularProgress />
    </Box>
  );
};

export default CourtAddress;
