import React from 'react';
import { Stack, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import MDialog from '../../../atoms/MDialog';
import { ReactComponent as Loader } from '../../../../assets/icons/loader.svg';

const ProgressModal = ({ isOpen }) => {
  return (
    <MDialog isOpen={isOpen} title={null}>
      <Stack flex flexDirection="row" justifyContent="center" alignItems="center">
        <Loader />
        <Typography fontSize="16px" fontWeight={500} m={0} ml={2} data-testid="creating_report">
          Creating Report
        </Typography>
      </Stack>
    </MDialog>
  );
};

ProgressModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default ProgressModal;
