import { LinearProgress, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRequestLockout } from '../../../../../store/slices/cases/caseCpSlice';
import MButton from '../../../../MUI/Button/MButton';
import ConstableInstructions from '../../../Processing/Document/Modals/ConstableInstructions';

const RequestLockoutCell = ({
  row: {
    original: { caseId },
  },
  value: canRequestLockout,
  refreshPage,
  state: { pageIndex },
}) => {
  const [isLoader, setIsLoader] = useState(false);
  const [isConstableInstructionsOpen, setIsConstableInstructionsOpen] = useState(false);

  const dispatch = useDispatch();

  const onClick = () => {
    setIsConstableInstructionsOpen(true);
  };

  const handleCloseConstableInstructions = () => setIsConstableInstructionsOpen(false);

  const onRequestLockout = (data) => {
    handleCloseConstableInstructions();
    setIsLoader(true);

    const popupFields = Object.entries(data)
      .map(([key, value]) => {
        const convertValue = () => {
          if (typeof value === 'boolean') {
            return value ? 'True' : 'False';
          }
          return value;
        };
        return { key, value: convertValue() };
      })
      .filter((field) => field.value !== null);

    dispatch(
      setRequestLockout({
        caseId,
        popupFields,
      }),
    ).then(() => {
      setIsLoader(false);
      refreshPage(pageIndex + 1);
    });
  };

  return (
    <>
      {isLoader ? (
        <Box sx={{ width: '130px' }}>
          <LinearProgress />
        </Box>
      ) : (
        <MButton variant="secondary" size="small" disabled={!canRequestLockout} onClick={onClick}>
          Request Lockout
        </MButton>
      )}
      {isConstableInstructionsOpen && (
        <ConstableInstructions
          isOpen
          handleClose={handleCloseConstableInstructions}
          handleConfirm={onRequestLockout}
        />
      )}
    </>
  );
};

RequestLockoutCell.propTypes = {
  value: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  refreshPage: PropTypes.func.isRequired,
  state: PropTypes.shape({
    pageIndex: PropTypes.number.isRequired,
  }).isRequired,
};

export default RequestLockoutCell;
