import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Schedule from '../../../components/pages/Schedule';
import ProposedFormOfJudgments from '../../../components/pages/Schedule/ProposedFormOfJudgments';
import AttorneyAssignments from '../../../components/pages/Schedule/AttorneyAssignments';
import AddCourtDates from '../../../components/pages/Schedule/AddCourtDates/AddCourtDates';

const DataBaseScheduleRoutes = () => (
  <Routes>
    <Route element={<Schedule />}>
      <Route path="addcourtdates" element={<AddCourtDates />} />
      <Route path="judgements" element={<ProposedFormOfJudgments />} />
      <Route path="attorneyassignments" element={<AttorneyAssignments />} />
    </Route>
  </Routes>
);

export default DataBaseScheduleRoutes;
