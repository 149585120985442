/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const getCases = createAsyncThunk(
  'casesGeneralCp/getCases',
  async ({ filter, currentPage }) => {
    const {
      data: { result },
    } = await apiClient.post(`/cp/api/cases/filter`, {
      currentPage,
      pageSize: 20,
      ...Object.keys(filter).reduce((acc, key) => {
        if (filter[key]) {
          acc[key] =
            key === 'createdTo'
              ? filter[key]
                  .add({ second: 86399, millisecond: 999 })
                  .format('YYYY-MM-DDTHH:mm:ss.SSS')
              : filter[key];
        }
        return acc;
      }, {}),
    });
    return result;
  },
);

export const getCommunities = createAsyncThunk(
  'casesGeneralCp/getCommunities',
  // eslint-disable-next-line
  async (params = {}, thunkAPI) => {
    const {
      communities: { items: prevItems = [] },
    } = thunkAPI.getState().casesGeneralCp;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/cp/api/communities/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.communityId,
      label: item.communityName,
      value: item.communityId,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getOpposingParty = createAsyncThunk(
  'casesGeneralCp/getOpposingParty',
  // eslint-disable-next-line
  async (params = {}, thunkAPI) => {
    const {
      opposingParty: { items: prevItems = [] },
    } = thunkAPI.getState().casesGeneralCp;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/cp/api/cases/defendants/filter`, {
      ...params,
      fullName: params.opposingPartyFullName || '',
    });

    const currentStateItems = items.map((item) => ({
      id: item.caseDefendantId,
      label: item.defendantFullName,
      value: item.defendantFullName,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getStates = createAsyncThunk('casesGeneralCp/getStates', async (params = {}) => {
  const {
    data: { result },
  } = await apiClient.get(`/cp/api/states`, { params });
  return result;
});

export const getCaseStatusesByStateCode = createAsyncThunk(
  'casesGeneralCp/getCaseStatusesByStateCode',
  async (stateCode = 'AZ') => {
    const apiByStateCode = {
      AZ: 'arizonaCase',
      NV: 'nevadaCases',
    };
    const { data } = await apiClient.get(`/cp/api/${apiByStateCode[stateCode]}/statuses`);
    return data;
  },
);

const initialState = {
  getCases: [],
  getCasesError: null,
  communities: {
    items: [],
  },
  propertyAddresses: {
    items: [],
  },
  opposingParty: {
    items: [],
  },
  states: [],
  statusesByStateCode: [],
};

export const casesGeneralCpSlice = createSlice({
  name: 'casesGeneralCp',
  initialState,
  extraReducers: {
    [getCases.fulfilled]: (state, action) => {
      state.getCases = action.payload;
    },
    [getCases.rejected]: (state, action) => {
      state.getCasesError = action.payload;
    },
    [getCommunities.fulfilled]: (state, action) => {
      state.communities = action.payload;
    },
    [getOpposingParty.fulfilled]: (state, action) => {
      state.opposingParty = action.payload;
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.map((s) => ({
        id: s.id,
        value: s.code,
        label: s.code,
      }));
    },
    [getCaseStatusesByStateCode.fulfilled]: (state, action) => {
      state.statusesByStateCode = action.payload.result.map(({ value }) => ({
        id: value,
        label: value,
        value,
      }));
    },
  },
});

export default casesGeneralCpSlice.reducer;
