import React, { useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import QuickBookConnect from '../../molecules/QuickBookConnect';
import Page from '../../organisms/Page/Page';

const Accounting = () => {
  const { refNum, defendantName } = useParams();
  const { state, pathname } = useLocation();

  const isInvoiceGeneration = pathname.includes('db/accounting/invoicegeneration');

  const { navPageString, subNavString, subSubNavigation } = useMemo(() => {
    if (isInvoiceGeneration) {
      return {
        navPageString: 'Accounting',
        subNavString: 'Invoice Generation',
      };
    }
    return {
      navPageString: state ? state.navPage : '',
      subNavString: state ? state.subNav : '',
      subSubNavigation: state ? state.subSubNavigation : '',
    };
  }, [state, pathname]);

  const actions = useMemo(() => {
    if (isInvoiceGeneration) {
      return <QuickBookConnect />;
    }
    return null;
  }, [pathname]);

  return (
    <Page
      navPage={navPageString}
      subNavigation={subNavString}
      subSubNavigation={subSubNavigation}
      id={refNum}
      caseName={defendantName}
      actions={actions}
    >
      <Outlet />
    </Page>
  );
};

export default Accounting;
