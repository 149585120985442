import moment from 'moment';
import { Box } from '@mui/material';
import { TextInputCell } from '../../../atoms/Table/TableCells/TextInputCell';
import { ReferenceNumberCell } from '../CaseSearch/CaseSearchCells';
import { CaseInformationEditableCell } from './TableCells/CaseInformationArizonaEditableCell';
import CaseStatus from '../../../molecules/CaseStatus';
import { DropdownCell } from '../../../atoms/Table/TableCells/DropdownCell';
import { DateCell, TextCell } from './TableCells';
import { CaseStatusDeleteCell } from '../CaseInformationNevada/TableCells/CaseStatusDeleteCell';
import { DuplicateCell } from './TableCells/DuplicateCell';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';
import TypeCell from './TableCells/TypeCell';
import QuantityCell from './TableCells/QuantityCell';
import TextEllipsis from './TableCells/TextEllipsis';
import NonBillableCell from './TableCells/NonBillableCell';
import EditableCell from './TableCells/EditableCell';
import BillableCell from '../CivilMattersArizona/TableCells/BillableCell';

const date = 'mm/dd/yyyy';

export const DAYS_IN_YEAR = 365;
export const DAYS_IN_MONTH = 30;
export const MONTHS_IN_YEAR = 12;
export const DAYS_IN_WEEK = 7;

export const TABLE_ROWS_COUNT = 20;

export const TABLE_COLUMNS = [
  {
    Header: 'Type',
    accessor: 'type',
    width: 4,
    Cell: TypeCell,
  },
  {
    Header: 'Qty',
    width: 10,
    accessor: 'quantity',
    Cell: QuantityCell,
  },
  {
    Header: 'Description',
    width: 20,
    accessor: 'description',
    Cell: TextEllipsis,
  },
  {
    width: 10,
    Header: 'Rate ($)',
    accessor: 'rate',
    Cell: TextCell,
  },
  {
    Header: 'Non-billable ($)',
    accessor: 'nonBillable',
    width: 10,
    Cell: NonBillableCell,
  },
  {
    Header: 'Billable ($)',
    accessor: 'billable',
    width: 10,
    Cell: BillableCell,
  },
  {
    Header: 'Date ',
    accessor: 'date',
    width: 10,
    Cell: DateCell,
  },
  {
    Header: 'User',
    accessor: 'user',
    width: 15,
    Cell: TextCell,
  },
  {
    Header: 'Category',
    accessor: 'category',
    width: 15,
    Cell: TextEllipsis,
  },
  {
    Header: '',
    show: true,
    accessor: 'control',
    width: 8,
    Cell: EditableCell,
  },
];

export const caseInformationForm = {
  boxTitle: 'Case Information',
  name: 'caseInformation',
  withAdd: false,
  inputBoxes: [
    {
      label: 'Case #',
      name: 'caseNumber',
      placeholder: 'xxxxxx',
      width: '199px',
      isMandatory: false,
    },
    {
      label: 'P.O./File #',
      name: 'poNumber',
      placeholder: 'P.O./File #',
      width: '199px',
      isMandatory: false,
    },
    {
      label: 'Assigned Legal Assistant',
      name: 'assignedLegalAssistant',
      placeholder: "Enter legal assistant's name",
      width: '300px',
      isDropdown: true,
      isMandatory: false,
      withUpdate: true,
    },
  ],
};

export const proceduralHistoryForm = {
  boxTitle: 'Procedural History',
  name: 'proceduralHistory',
  withAdd: true,
  inputBoxes: [
    {
      label: 'Assigned Attorney',
      name: 'proceduralHistory.assignedAttorney',
      placeholder: "Enter attorney's name",
      width: '476px',
      isDropdown: true,
      isMandatory: false,
    },
    {
      label: 'Hearing Type',
      name: 'proceduralHistory.hearingType',
      placeholder: 'Default option',
      width: '476px',
      isDropdown: true,
      isMandatory: true,
    },
    {
      isSpace: true,
      label: 'spaceAfterHearingType',
    },
    {
      label: 'Court Date',
      name: 'proceduralHistory.courtDate',
      width: '226px',
      placeholder: date,
      isMandatory: true,
      isDatePicker: true,
      height: '48px',
    },
    {
      label: 'Court Time',
      name: 'proceduralHistory.courtTime',
      placeholder: 'hh:mm',
      width: '226px',
      height: '48px',
      isMandatory: true,
      isTimePicker: true,
    },
    {
      label: 'Date of Writ',
      name: 'proceduralHistory.dateOfWrit',
      width: '226px',
      placeholder: date,
      isMandatory: false,
      isDatePicker: true,
      height: '48px',
    },
    {
      label: 'Judgment Granted',
      name: 'proceduralHistory.judgmentGranted',
      isCheckbox: true,
      isMandatory: false,
    },
    {
      label: 'Dismissed by Judge',
      name: 'proceduralHistory.dismissedByJudge',
      isCheckbox: true,
      isMandatory: false,
    },
  ],
};

export const proceduralHistoryFormEvictionMatter = {
  boxTitle: 'Procedural History',
  name: 'proceduralHistory',
  withAdd: true,
  inputBoxes: [
    {
      label: 'Assigned Attorney',
      name: 'proceduralHistory.assignedAttorney',
      placeholder: "Enter attorney's name",
      width: '476px',
      isDropdown: true,
      isMandatory: false,
    },
    {
      label: 'Hearing Type',
      name: 'proceduralHistory.hearingType',
      placeholder: 'Default option',
      width: '476px',
      isDropdown: true,
      isMandatory: true,
    },
    {
      label: 'Comment',
      name: 'proceduralHistory.comment',
      placeholder: 'Enter comment',
      width: '476px',
      isMandatory: false,
    },
    {
      isSpace: true,
      label: 'spaceAfterHearingType',
    },
    {
      label: 'Court Date',
      name: 'proceduralHistory.courtDate',
      width: '226px',
      placeholder: date,
      isMandatory: true,
      isDatePicker: true,
      height: '48px',
    },
    {
      label: 'Court Time',
      name: 'proceduralHistory.courtTime',
      placeholder: 'hh:mm',
      width: '226px',
      height: '48px',
      isMandatory: true,
      isTimePicker: true,
    },
    {
      label: 'Date of Writ',
      name: 'proceduralHistory.dateOfWrit',
      width: '226px',
      placeholder: date,
      isMandatory: false,
      isDatePicker: true,
      height: '48px',
    },
    {
      label: 'Judgment Granted',
      name: 'proceduralHistory.judgmentGranted',
      isCheckbox: true,
      isMandatory: false,
    },
    {
      label: 'Dismissed by Judge',
      name: 'proceduralHistory.dismissedByJudge',
      isCheckbox: true,
      isMandatory: false,
    },
    {
      label: 'Tentative',
      name: 'proceduralHistory.isTentative',
      isCheckbox: true,
      isMandatory: false,
    },
    {
      label: 'Amended Time',
      name: 'proceduralHistory.isAmmendedTime',
      isCheckbox: true,
      isMandatory: false,
    },
  ],
};

export const caseStatusForm = {
  boxTitle: 'Case Status',
  name: 'caseStatus',
  withAdd: true,
  inputBoxes: [
    {
      label: 'Status',
      name: 'caseStatus.caseStatus',
      placeholder: 'Enter status name',
      width: '311px',
      isDropdown: true,
      isMandatory: true,
    },
    {
      label: 'Comment',
      name: 'caseStatus.comment',
      placeholder: 'Enter comment',
      tooltipText: 'Comments are visible to clients.',
      width: '533px',
      isMandatory: false,
    },
  ],
};

export const deliveredByOptions = [
  { id: 1, label: '-', value: null },
  { id: 2, label: 'Certified Mail', value: 'Certified Mail' },
  { id: 3, label: 'Personally Served', value: 'Personally Served' },
];

export const violationsForm = {
  boxTitle: 'Violation(s)',
  name: 'violations',
  withAdd: true,
  inputBoxes: [
    {
      label: 'Notice Type',
      name: 'violations.noticeType',
      placeholder: 'Select notice type',
      width: '422px',
      isDropdown: true,
      isMandatory: true,
    },
    {
      label: 'Date Served',
      name: 'violations.dateServed',
      placeholder: date,
      width: '200px',
      height: '48px',
      isMandatory: true,
      isDatePicker: true,
    },
    {
      label: 'Delivered By',
      name: 'violations.deliveredBy',
      placeholder: 'Select delivery type',
      isDropdown: true,
      width: '584px',
      isMandatory: false,
    },
    {
      label: 'Complex Complaint',
      name: 'violations.isComplexComplaint',
      placeholder: '',
      width: 'auto',
      radioButtonWidth: '116px',
      isRadioButtons: true,
      data: [
        { id: 0, label: 'Yes', value: true },
        { id: 1, label: 'No', value: false, isDefault: true },
      ],
      isMandatory: false,
      tooltipText: 'Select "Yes" only if this complaint will have multiple violations.',
    },
    {
      label: 'File Date',
      name: 'violations.fileDate',
      width: '200px',
      placeholder: date,
      isMandatory: false,
      isDatePicker: true,
      height: '48px',
      tooltipText: 'Suspend document until the file date.',
      minDate: moment().add(1, 'day').endOf('day'),
    },
    {
      label: 'Description',
      name: 'violations.description',
      width: '422px',
      placeholder: 'Add comments',
      height: '48px',
    },
    {
      label: 'Amended',
      name: 'violations.amended',
      isCheckbox: true,
      isMandatory: false,
    },
    {
      label: 'Cares Act Notice',
      name: 'violations.caresActNotice',
      isCheckbox: true,
      isMandatory: false,
    },
  ],
};

export const JusticeCourtFees = [
  {
    label: 'Internal Court Fees',
    isSubHeading: true,
  },
  {
    label: 'Attorney Fee',
    name: 'justiceFeesAndCosts.attorneyFee',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Attoney Fee Immediate',
    name: 'justiceFeesAndCosts.attorneyFeeImmediate',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Court Cost',
    name: 'justiceFeesAndCosts.courtCost',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Max Total Late Charges',
    name: 'justiceFeesAndCosts.maxTotalLateCharges',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  { isSpace: true },
  {
    label: 'Writ Cost (JC)',
    name: 'justiceFeesAndCosts.writCostJC',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Writ Cost Constable',
    name: 'justiceFeesAndCosts.writCostConstable',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Mileage',
    name: 'justiceFeesAndCosts.milage',
    placeholder: '-',
    width: '290px',
    type: 'float',
  },
  {
    label: 'Forcible Cost',
    name: 'justiceFeesAndCosts.forcibleCost',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  { isSpace: true },
  {
    label: 'Dismissal Fee (Court Attorney Fees - Notice of Voluntary Dismissal by Judge)',
    name: 'justiceFeesAndCosts.dismissalFeeVoluntaryByJudge',
    placeholder: '-',
    width: '605px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Dismissal Fee (Ex Parte Motion to Vacate Judgment)',
    name: 'justiceFeesAndCosts.dismissalFeeVacateJudgement',
    placeholder: '-',
    width: '605px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Notice Fee',
    name: 'justiceFeesAndCosts.noticeFee',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
];

export const SuperiorCourtFees = [
  {
    label: 'Internal Court Fees',
    name: 'internalCourtFees',
    isSubHeading: true,
    type: 'float',
  },
  {
    label: 'Superior Court Filing Fee',
    name: 'superiorFeesAndCosts.filingFee',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Superior Court Judgement Fee',
    name: 'superiorFeesAndCosts.judgementFee',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Superior Court Writ (Pima)',
    name: 'superiorFeesAndCosts.writPima',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Superior Court Writ of Restitution',
    name: 'superiorFeesAndCosts.writOfRestitution',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  {
    label: 'Notice Fee',
    name: 'superiorFeesAndCosts.noticeFee',
    placeholder: '-',
    width: '290px',
    isMandatory: true,
    type: 'float',
  },
  { isSpace: true },
  {
    heading: 'Superior Court Attorney Fees - Notice of Voluntary Dismissal by Judge',
    label: 'Dismissal Fee',
    isFieldWithHeading: true,
    name: 'superiorFeesAndCosts.dismissalFeeVoluntaryByJudge',
    placeholder: '-',
    width: '605px',
    isMandatory: true,
    type: 'float',
  },
  {
    heading: 'Superior Court Attorney Fees - Notice of Voluntary Dismissal',
    label: 'Dismissal Fee',
    isFieldWithHeading: true,
    name: 'superiorFeesAndCosts.dismissalFeeVoluntary',
    placeholder: '-',
    width: '605px',
    isMandatory: true,
    type: 'float',
  },
];

export const damagesForm = [
  {
    boxTitle: 'Damages',
    name: 'attorneyFees',
    inputBoxes: ({ courtType }) => {
      if (courtType === 'Justice') return JusticeCourtFees;
      if (courtType === 'Superior') return SuperiorCourtFees;
      return [];
    },
  },
  {
    name: 'damageDatesAndRent',
    inputBoxes: [
      {
        label: 'Dates & Rent',
        isSubHeading: true,
      },
      {
        label: 'Rent Type',
        name: 'damageDatesAndRent.rentType',
        placeholder: 'Monthly',
        width: '225px',
        isDropdown: true,
        isMandatory: false,
      },
      {
        label: 'Weekday',
        name: 'damageDatesAndRent.weekday',
        placeholder: '-',
        width: '225px',
        isDropdown: true,
        isMandatory: false,
      },
      {
        label: 'First Unpaid Due Date',
        name: 'damageDatesAndRent.firstUnpaidDueDate',
        placeholder: date,
        width: '225px',
        height: '48px',
        isDatePicker: true,
        isMandatory: false,
      },
      {
        label: 'Next Rental Due Date',
        name: 'damageDatesAndRent.nextRentalDueDate',
        placeholder: date,
        width: '225px',
        height: '48px',
        isDatePicker: true,
        isMandatory: false,
      },
      {
        label: 'Monthly/Weekly Rent',
        name: 'damageDatesAndRent.monthlyWeeklyRent',
        placeholder: '-',
        width: '225px',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Contract Rent',
        name: 'damageDatesAndRent.contractRent',
        placeholder: '-',
        width: '225px',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Current Rent Due',
        name: 'damageDatesAndRent.currentRentDue',
        placeholder: '-',
        width: '225px',
        isMandatory: false,
        type: 'float',
        min: 0,
        isDisabled: true,
      },
      {
        label: 'Override Rent Due',
        name: 'damageDatesAndRent.overrideRentDue',
        placeholder: '-',
        width: '225px',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Month to Month Rate',
        name: 'damageDatesAndRent.monthToMonthRate',
        placeholder: '-',
        width: '225px',
        isMandatory: false,
        isDisabled: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Subsidized Housing',
        name: 'damageDatesAndRent.isSubsidizedHousing',
        isCheckbox: true,
        isMandatory: false,
      },
    ],
  },
  {
    name: 'lateCharges',
    inputBoxes: [
      {
        label: 'Late Charges',
        isSubHeading: true,
      },
      {
        label: 'Late Fees Start On',
        name: 'lateCharges.lateFeesStartOn',
        width: '225px',
        height: '48px',
        placeholder: date,
        isMandatory: false,
        isDatePicker: true,
      },
      {
        label: 'Late Fees End On',
        name: 'lateCharges.lateFeesEndOn',
        width: '225px',
        height: '48px',
        placeholder: date,
        isMandatory: false,
        isDatePicker: true,
      },
      {
        label: 'Quantity of Days Late',
        name: 'lateCharges.quantityOfDaysLate',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'int',
      },
      {
        label: 'Initial Late Fee',
        name: 'lateCharges.initialLateFee',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Daily Late Charge',
        name: 'lateCharges.dailyLateCharge',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Service of Process',
        name: 'lateCharges.serviceOfProcess',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'NSF Charges',
        name: 'lateCharges.nsfCharges',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
    ],
  },
  {
    name: 'chargesAndTotalDue',
    inputBoxes: [
      {
        label: 'Charges and Total Due',
        isSubHeading: true,
      },
      {
        label: 'Previous Balance',
        name: 'chargesAndTotalDue.previousBalance',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Utilities',
        name: 'chargesAndTotalDue.utilities',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Credit',
        name: 'chargesAndTotalDue.credit',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Other Charges',
        name: 'chargesAndTotalDue.otherCharges',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Other Desc',
        name: 'chargesAndTotalDue.otherDesc',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
      },
      {
        label: 'Month to Month',
        name: 'chargesAndTotalDue.monthToMonth',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
        isDisabled: true,
      },
      {
        label: 'Concession',
        name: 'chargesAndTotalDue.concession',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
      },
      {
        label: 'Total Late Charges',
        name: 'chargesAndTotalDue.totalLateCharges',
        width: '225px',
        placeholder: '-',
        isMandatory: false,
        type: 'float',
        min: 0,
        isDisabled: true,
      },
    ],
  },
];

export const totalForm = {
  name: 'total',
  inputBoxes: [
    {
      label: 'Total Due',
      name: 'total.totalDue',
      isCalculator: true,
      placeholder: '0.00',
    },
    {
      label: 'Superior Court',
      name: 'total.superiorCourt',
      isCheckbox: true,
    },
    {
      isFlexGrow: true,
      growValue: 1,
    },
    {
      name: '',
      isUpdateButton: true,
      text: 'Update',
    },
  ],
};

export const proceduralHistoryColumns = [
  {
    Header: 'Assigned Attorney',
    accessor: 'assignedAttorney',
    edit: true,
    width: 20,
    Cell: DropdownCell,
    options: 'attorneys',
  },
  {
    Header: 'Hearing Type',
    accessor: 'hearingType',
    width: 15,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDateTime',
    width: 15,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('L') : '',
  },
  {
    Header: 'Court Time',
    accessor: 'courtDateTimee',
    width: 15,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('LT') : '',
  },
  {
    Header: 'Date of Writ',
    accessor: 'dateOfWrit',
    width: 15,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CaseStatus,
    width: 15,
  },
  {
    Header: '',
    accessor: 'control',
    width: 6,
    minWidth: '48px',
    Cell: (props) => {
      // eslint-disable-next-line react/prop-types, react/destructuring-assignment
      const isAttorneyAssigned = !!props.row.original.assignedAttorney;

      const canDelete = useAllowed([rolesDB.Admin]);
      return (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {isAttorneyAssigned && canDelete && <DeleteCell {...props} />}
          {!isAttorneyAssigned && <CaseInformationEditableCell {...props} showDelete={canDelete} />}
        </Box>
      );
    },
  },
];

export const proceduralHistoryColumnsEvictionMatter = [
  {
    Header: 'Assigned Attorney',
    accessor: 'assignedAttorney',
    edit: true,
    width: 20,
    Cell: DropdownCell,
    options: 'attorneys',
  },
  {
    Header: 'Hearing Type',
    accessor: 'hearingType',
    width: 10,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDateTime',
    width: 15,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('L') : '',
  },
  {
    Header: 'Court Time',
    accessor: 'courtDateTimee',
    width: 15,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('LT') : '',
  },
  {
    Header: 'Date of Writ',
    accessor: 'dateOfWrit',
    width: 10,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CaseStatus,
    width: 10,
  },
  {
    Header: 'Condition',
    accessor: 'condition',
    width: 10,
    Cell: ({ row: { original } }) => {
      if (original.isTentative && original.isAmmendedTime) return 'Tentative, Amended';
      if (original.isTentative) return 'Tentative';
      if (original.isAmmendedTime) return 'Amended';
      return '';
    },
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 10,
  },
  {
    Header: '',
    accessor: 'control',
    width: 6,
    minWidth: '48px',
    Cell: (props) => {
      // eslint-disable-next-line react/prop-types, react/destructuring-assignment
      const isAttorneyAssigned = !!props.row.original.assignedAttorney;

      const canDelete = useAllowed([rolesDB.Admin]);
      return (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {isAttorneyAssigned && canDelete && <DeleteCell {...props} />}
          {!isAttorneyAssigned && <CaseInformationEditableCell {...props} showDelete={canDelete} />}
        </Box>
      );
    },
  },
];

export const arizonaViolationsColumns = [
  {
    Header: 'Selected Violations',
    accessor: 'noticeType',
    width: 30,
  },
  {
    Header: 'Date Served',
    accessor: 'dateServed',
    width: 10,
    Cell: ({ row: { original } }) =>
      original.dateServed ? moment(original.dateServed).format('L') : '',
  },
  {
    Header: 'File Date',
    accessor: 'fileDate',
    width: 10,
    Cell: ({ row: { original } }) =>
      original.fileDate ? moment(original.fileDate).format('L') : '',
  },
  {
    Header: 'Delivered By',
    accessor: 'deliveredBy', // courtDateTime - но это дубликат предыдущего аксессора, выдает ошибку
    width: 20,
    Cell: ({ value }) => (value === 'Hand Delivered' ? 'Personally Served' : value),
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: 45,
    edit: true,
    Cell: TextInputCell,
  },
  {
    width: 6,
    minWidth: 115,
    Header: '',
    accessor: 'control',
    // eslint-disable-next-line react/prop-types, react/destructuring-assignment
    Cell: (props) => <CaseInformationEditableCell {...props} showDelete showInfo />,
  },
];

export const arizonaRelatedCasesColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    width: 15,
    Cell: ReferenceNumberCell,
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    width: 10,
    Cell: TextCell,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'opposingPartyName',
    width: 30,
    Cell: TextCell,
  },
  {
    Header: 'Apartment #',
    accessor: 'apartmentNumber',
    width: 12,
    Cell: TextCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 18,
    Cell: CaseStatus,
  },
  {
    Header: 'Creation Date',
    accessor: 'creationDate',
    width: 13,
    Cell: DateCell,
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    width: 13,
    Cell: DateCell,
  },
  {
    Header: 'Duplicate',
    accessor: 'isPossibleDuplicate',
    width: 10,
    Cell: DuplicateCell,
  },
];

export const caseStatusColumns = [
  {
    Header: 'Status',
    accessor: 'status',
    width: 24,
    Cell: CaseStatus,
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    width: 22,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Updated By',
    accessor: 'updatedBy',
    width: 22,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 24,
  },
  {
    Header: '',
    accessor: 'control',
    width: 8,
    minWidth: 80,
    Cell: (props) => {
      return <CaseStatusDeleteCell {...props} />;
    },
  },
];

export const violationColumns = [
  {
    Header: 'Selected Violations',
    accessor: 'noticeType',
    width: 240,
  },
  {
    Header: 'Date Served',
    accessor: 'dateServed',
    width: 142,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Delivered By',
    accessor: 'deliveredBy',
    width: 188,
  },
  {
    Header: 'Process Server',
    accessor: 'processServer',
    width: 188,
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: 504,
  },
];

export const violationTypes = [
  { label: 'Nuisance Business', value: 'Nuisance Business', id: 1 },
  { label: 'Waste Business', value: 'Waste Business', id: 2 },
  { label: 'Assigning Business', value: 'Assigning Business', id: 3 },
  { label: 'Subletting Business', value: 'Subletting Business', id: 4 },
  { label: 'Unlawful Business', value: 'Unlawful Business', id: 5 },
  { label: 'Controlled Substance Violation', value: 'Controlled Substance Violation', id: 6 },
  { label: 'Perform Lease Condition', value: 'Perform Lease Condition', id: 7 },
  { label: 'Tenancy-At-Will', value: 'Tenancy-At-Will', id: 8 },
  { label: 'No Cause', value: 'No Cause', id: 9 },
  { label: 'Unlawful Detainer', value: 'Unlawful Detainer', id: 10 },
  { label: '5 Day to Quit', value: '5 Day to Quit', id: 11 },
];

export const tenancyTypes = [
  { label: 'Periodic week-to-week', value: 'Periodic week-to-week', id: 1 },
  { label: 'Fixed term', value: 'Fixed term', id: 2 },
  { label: 'Tenancy-at-will', value: 'Tenancy-at-will', id: 3 },
  { label: 'Other', value: 'Other', id: 4 },
];

export const hearingTypes = [
  { label: 'Initial Hearing', value: 'Initial Hearing', id: 1 },
  { label: 'Bench Trial', value: 'Bench Trial', id: 2 },
  { label: 'Jury Trial', value: 'Jury Trial', id: 3 },
  { label: 'Oral Argument', value: 'Oral Argument', id: 4 },
  { label: 'Status Conference', value: 'Status Conference', id: 5 },
  { label: 'Continuance', value: 'Continuance', id: 6 },
  { label: 'Reset', value: 'Reset', id: 7 },
  { label: 'Appeal', value: 'Appeal', id: 8 },
  { label: 'Evidentiary Hearing', value: 'Evidentiary Hearing', id: 9 },
  { label: 'Case Stayed', value: 'Case Stayed', id: 10 },
  { label: 'Mediation', value: 'Mediation', id: 11 },
];

export const weekdays = [
  { id: 0, value: 'Monday', label: 'Monday' },
  { id: 0, value: 'Tuesday', label: 'Tuesday' },
  { id: 0, value: 'Wednesday', label: 'Wednesday' },
  { id: 0, value: 'Thursday', label: 'Thursday' },
  { id: 0, value: 'Friday', label: 'Friday' },
  { id: 0, value: 'Saturday', label: 'Saturday' },
  { id: 0, value: 'Sunday', label: 'Sunday' },
];

export const rentTypes = [
  { id: 0, value: 'Monthly', label: 'Monthly' },
  { id: 1, value: 'Biweekly', label: 'Biweekly' },
  { id: 2, value: 'Weekly', label: 'Weekly' },
];
