import React from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';

const PieChart = ({ data }) => {
  const formattedData =
    data?.[0]?.attorneys?.map((item) => ({
      name: item?.attorney,
      value: item?.billedHours,
    })) || [];

  const options = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}',
      textStyle: {
        fontSize: '16px',
        fontWeight: 500,
      },
    },
    legend: {
      data: formattedData.map((item) => item.name),
      orient: 'vertical',
      left: '30%',
      top: 'center',
      maxHeight: '100px',
      overflow: 'auto',
      icon: 'circle',
      textStyle: {
        fontSize: '16px',
        fontWeight: 500,
      },
    },
    series: [
      {
        type: 'pie',
        data: formattedData,
        center: ['15%', '50%'],
        right: 'auto',
        label: {
          show: true,
          formatter: '{c}',
          // Увеличиваем значение left для отталкивания серии диаграммы от легенды
          left: formattedData.length > 10 ? '220px' : '120px',
        },
      },
    ],
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  };

  return (
    <ReactEcharts option={options} style={{ height: '350px', width: '100%', margin: '0 auto' }} />
  );
};

PieChart.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default PieChart;
