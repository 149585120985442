/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getClientRequestNotifications = createAsyncThunk(
  'adminClientRequestNotifications/getClientRequestNotifications',
  async (params) => {
    const { data } = await apiClient.post(`/api/notificationRules/filter`, params);
    return data;
  },
);

export const getUsersForNotificationRules = createAsyncThunk(
  'adminClientRequestNotifications/getUsersForNotificationRules',
  async (params) => {
    const { data } = await apiClient.post(`/api/notificationRules/users/filter`, params);
    return data;
  },
);

export const getUsersForNotificationRulesDropdown = createAsyncThunk(
  'adminClientRequestNotifications/getUsersForNotificationRulesDropdown',
  async (params, thunkAPI) => {
    const {
      usersForDropdown: { items: prevItems = [] },
    } = thunkAPI.getState().adminClientRequestNotifications;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/notificationRules/users/filter`, params);

    const nextItems = items.map((item) => ({
      id: item.userId,
      value: item.userId,
      label: [item.firstName, item.lastName].filter((x) => x).join(' '),
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1 ? [...prevItems, ...nextItems] : nextItems,
      hasNextPage,
    };
  },
);

export const getUsersByRuleId = createAsyncThunk(
  'adminClientRequestNotifications/getUsersByRuleId',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/notificationRules/${params.notificationRuleId}/users/filter`,
      params,
    );
    return data;
  },
);

export const getAllRequestTypes = createAsyncThunk(
  'adminClientRequestNotifications/getAllRequestTypes',
  async (params) => {
    const { data } = await apiClient.get(`/api/notificationRules/types`, params);
    return data;
  },
);

export const createNotificationRule = createAsyncThunk(
  'adminClientRequestNotifications/createNotificationRule',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/api/notificationRules`, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const updateNotificationRule = createAsyncThunk(
  'adminClientRequestNotifications/updateNotificationRule',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(
        `/api/notificationRules/${params.notificationRuleId}`,
        params,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const deleteNotificationRule = createAsyncThunk(
  'adminClientRequestNotifications/deleteNotificationRule',
  async (notificationRuleId) => {
    const { data } = await apiClient.delete(`/api/notificationRules/${notificationRuleId}`);
    return data;
  },
);

export const addUsersToNotificationRule = createAsyncThunk(
  'adminClientRequestNotifications/addUsersToNotificationRule',
  async (params) => {
    const { data } = await apiClient.post(
      `/api/notificationRules/${params.notificationRuleId}/users`,
      params,
    );
    return data;
  },
);

export const deleteUsersFromNotificationRule = createAsyncThunk(
  'adminClientRequestNotifications/deleteUsersFromNotificationRule',
  async (params) => {
    const { data } = await apiClient.delete(
      `/api/notificationRules/${params.notificationRuleId}/users/${params.userId}`,
    );
    return data;
  },
);

export const getAvailableStates = createAsyncThunk(
  'adminClientRequestNotifications/getAvailableStates',
  async () => {
    const { data } = await apiClient.get(`/api/states`);
    return data;
  },
);

const initialState = {
  requestNotifications: {
    items: [],
  },
  users: {
    items: [],
  },
  usersForDropdown: {
    items: [],
  },
  usersByRuleId: {
    items: [],
  },
  types: [],
  states: [],
};

export const adminClientRequestNotificationsSlice = createSlice({
  name: 'adminClientRequestNotifications',
  initialState,
  reducers: null,
  extraReducers: {
    [getAvailableStates.fulfilled]: (state, action) => {
      state.states = action.payload.result.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.code,
      }));
    },
    [getClientRequestNotifications.fulfilled]: (state, action) => {
      state.requestNotifications = action.payload.result;
    },
    [getUsersForNotificationRules.fulfilled]: (state, action) => {
      state.users = action.payload.result;
    },
    [getUsersForNotificationRulesDropdown.fulfilled]: (state, action) => {
      state.usersForDropdown = action.payload;
    },
    [getUsersByRuleId.fulfilled]: (state, action) => {
      state.usersByRuleId = action.payload.result;
    },
    [getAllRequestTypes.fulfilled]: (state, action) => {
      state.types = action.payload.result.map(({ type }) => ({
        id: type,
        label: type,
        value: type,
      }));
    },
  },
});

export default adminClientRequestNotificationsSlice.reducer;
