import React, { useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Page from '../../organisms/Page/Page';

const DataHandling = () => {
  const { refNum, defendantName } = useParams();
  const { state, pathname } = useLocation();

  const { navPageString, subNavString, subSubNavigation } = useMemo(() => {
    return {
      navPageString: state ? state.navPage : '',
      subNavString: state ? state.subNav : '',
      subSubNavigation: state ? state.subSubNavigation : '',
    };
  }, [state, pathname]);

  return (
    <Page
      navPage={navPageString}
      subNavigation={subNavString}
      subSubNavigation={subSubNavigation}
      id={refNum}
      caseName={defendantName}
    >
      <Outlet />
    </Page>
  );
};

export default DataHandling;
