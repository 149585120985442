import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const NonBillableCell = ({ value, row }) => {
  const nonBillable = Number(row?.original?.nonBillable) || 0;
  const nonBillableQuantityTotal = Number(row?.original?.nonBillableQuantityTotal) || 0;

  if (row?.original?.isTotalRow) {
    return (
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        {nonBillable > 0 ? `$${nonBillable}` : 0}
        <br />
        {nonBillableQuantityTotal > 0 ? `${nonBillableQuantityTotal}h` : 0}
      </Typography>
    );
  }

  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
      }}
    >
      {value ? `$${value}` : '-'}
    </Typography>
  );
};

NonBillableCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      nonBillable: PropTypes.string,
      nonBillableQuantityTotal: PropTypes.string,
      isTotalRow: PropTypes.bool,
    }),
  }).isRequired,
};

NonBillableCell.defaultProps = {
  value: '',
};

export default NonBillableCell;
