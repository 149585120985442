import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { palette } from '../../../theme/default';

const StyledTag = styled('span')(({ backgroundColor, width }) => ({
  backgroundColor,
  padding: '4px 8px',
  borderRadius: '8px',
  display: 'inline-block',
  minWidth: width,
}));

const Tag = ({ text, backgroundColor, width, color }) => (
  <StyledTag backgroundColor={backgroundColor} width={width} data-testid="tag_wrapper">
    <Typography variant="bodyM" align="center" data-testid="tag_text" sx={{ color }}>
      {text}
    </Typography>
  </StyledTag>
);

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

Tag.defaultProps = {
  backgroundColor: 'transparent',
  width: 'auto',
  color: palette.text.primary,
};

export default Tag;
