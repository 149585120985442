import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const STATUS = 'status';

export const UsersTableColumns = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 30,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 30,
  },
  {
    Header: 'Role',
    accessor: 'role',
    width: 20,
  },
  {
    Header: 'State',
    accessor: 'stateCodes',
    width: 15,
    Cell: ({ value }) => value.join(', '),
  },
  {
    Header: '',
    accessor: 'control',
    width: 5,
    Cell: DeleteCell,
  },
];

export const userEditFormFields = [
  {
    label: 'First Name',
    name: 'firstName',
    placeholder: 'Enter first name',
    width: '326px',
    isDropdown: false,
    isRadioButtons: false,
    isMandatory: true,
    isDisabled: false,
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Enter last name',
    width: '326px',
    isDropdown: false,
    isRadioButtons: false,
    isMandatory: true,
    isDisabled: false,
  },
  {
    label: 'Middle Name',
    name: 'middleName',
    placeholder: 'Enter middle name',
    width: '326px',
    isDropdown: false,
    isRadioButtons: false,
    isMandatory: false,
    isDisabled: false,
  },
  {
    label: 'Suffix',
    name: 'suffix',
    placeholder: 'Enter suffix',
    width: '326px',
    isDropdown: false,
    isRadioButtons: false,
    isMandatory: false,
    isDisabled: false,
  },
  {
    label: 'User Name',
    name: 'userName',
    placeholder: 'Enter user name',
    width: '326px',
    isDropdown: false,
    isRadioButtons: false,
    isMandatory: true,
    isDisabled: false,
  },
  {
    label: 'User ID',
    name: 'userId',
    placeholder: '349800-89',
    width: '326px',
    isDropdown: false,
    isRadioButtons: false,
    isMandatory: false,
    isDisabled: true,
  },
  {
    label: 'Role(s)',
    name: 'roleIds',
    placeholder: 'Select role(s)',
    width: '326px',
    isCheckboxSelect: true,
    isMandatory: true,
    isDisabled: false,
  },
  { isEmptySpace: true },
  {
    label: 'State',
    name: 'stateCodes',
    placeholder: '-',
    width: '326px',
    isCheckboxSelect: true,
    isMandatory: true,
    isDisabled: false,
  },
  {
    label: 'Primary State',
    name: 'primaryStateCode',
    placeholder: '-',
    width: '326px',
    isDropdown: true,
  },
  {
    label: 'Attorney Rate',
    name: 'attorneyRate',
    placeholder: '$0.00',
    width: '326px',
  },
  {
    label: 'QB Attorney ID',
    name: 'attorneyQuickBookId',
    placeholder: '-',
    width: '326px',
  },
  {
    label: 'Automatic Assignment',
    name: 'hasAutoAssignment',
    width: '326px',
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
    radioButtonWidth: '158px',
    isRadioButtons: true,
    isMandatory: false,
  },
  { isEmptySpace: true },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Enter email',
    width: '326px',
    isDropdown: false,
    isRadioButtons: false,
    isMandatory: true,
    isDisabled: false,
  },
  {
    label: 'Status',
    name: STATUS,
    placeholder: 'Enter status',
    width: '326px',
    data: [
      { id: 0, label: 'Active', value: true, isDefault: true },
      { id: 1, label: 'Inactive', value: false },
    ],
    radioButtonWidth: '158px',
    isDropdown: false,
    isRadioButtons: true,
    isMandatory: true,
    isDisabled: false,
  },
  {
    label: 'Password',
    name: 'password',
    placeholder: 'Enter password',
    width: '326px',
    isDropdown: false,
    isRadioButtons: false,
    isMandatory: true,
    isDisabled: false,
    isPassword: true,
  },
  {
    label: 'Confirm Password',
    name: 'confirmPassword',
    placeholder: 'Confirm Password',
    width: '326px',
    isDropdown: false,
    isRadioButtons: false,
    isMandatory: true,
    isDisabled: false,
    isPassword: true,
  },
];
