import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack, styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import MCheckbox from '../../atoms/MCheckbox';

const StyledCheckboxWithLabel = styled('div')(({ marginTop, marginRight }) => ({
  marginTop,
  marginRight,
  '& label': {
    margin: 0,
  },
}));

const CheckboxWithLabel = ({
  isChecked,
  isDisabled,
  label,
  onChange,
  marginTop,
  defaultValue,
  marginRight,
  errorMessage,
  icon,
}) => {
  useEffect(() => {
    if (onChange) onChange(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback(
    (event) => {
      if (onChange) {
        onChange(event.target.checked);
      }
    },
    [isChecked, onChange],
  );

  return (
    <StyledCheckboxWithLabel marginTop={marginTop} marginRight={marginRight}>
      <FormControlLabel
        control={
          <MCheckbox isChecked={isChecked} isDisabled={isDisabled} onChange={handleChange} />
        }
        label={
          <Stack direction="row" gap={1}>
            <Typography variant="bodyM" data-testid="checkbox_label">
              {label}
            </Typography>
            {icon || null}
          </Stack>
        }
      />
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </StyledCheckboxWithLabel>
  );
};

CheckboxWithLabel.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  marginTop: PropTypes.string,
  marginRight: PropTypes.string,
  defaultValue: PropTypes.bool,
  errorMessage: PropTypes.string,
  icon: PropTypes.elementType,
};

CheckboxWithLabel.defaultProps = {
  isChecked: false,
  isDisabled: false,
  label: '',
  onChange: () => null,
  marginTop: '',
  marginRight: '',
  defaultValue: false,
  errorMessage: '',
  icon: null,
};

export default CheckboxWithLabel;
