import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Typography, Stack, styled, Box, IconButton } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import MDialog from '../../../../atoms/MDialog/MDialog';
import Form from '../../../../atoms/Form';
import Field from '../../../../atoms/Field';
import Dropzone from '../../../../atoms/Dropzone';
import DateOrTimePicker from '../../../../atoms/DateOrTimePicker';
import { ReactComponent as Calendar } from '../../../../../assets/icons/Calender.svg';
import { ReactComponent as Document } from '../../../../../assets/icons/Document.svg';
import { ReactComponent as Close } from '../../../../../assets/icons/Close.svg';
import { BulkCaseCreateStrings } from './BulkCaseCreateDialog.constants';
import { getCases } from '../../../../../store/slices/cases/casesGeneralSlice';
import { formatBytes } from '../../../../../utils/formatBytes';
import { palette } from '../../../../../theme/default/palette';
import { createCasesFromBulk } from '../../../../../store/slices/cases/casesNevadaSlice';
import LoaderCover from '../../../../atoms/LoaderCover';
import notificationUtils from '../../../../../utils/notificationUtils';
import { createCasesFromBulkArizona } from '../../../../../store/slices/cases/casesArizonaSlice';

const FileStack = styled(Stack)({
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
  border: '1px solid #DCE4FF',
  borderRadius: '10px',
});

const BulkCaseCreateDialog = ({ isOpen, onClose, stateCode }) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
  });

  const handleDrop = ([file]) => {
    form.setValue('File', file);
  };

  const handleDelete = () => {
    form.setValue('File', null);
    setError('');
  };

  const onCancelPress = () => {
    onClose();
    setError('');
    form.reset();
  };

  const handleSubmit = form.handleSubmit((formValues) => {
    const values = {
      ...formValues,
      DateServed: formValues.DateServed ? formValues.DateServed.format('YYYY-MM-DD') : null,
    };
    setIsLoading(true);
    const method = stateCode === 'NV' ? createCasesFromBulk : createCasesFromBulkArizona;
    dispatch(method(values))
      .unwrap()
      .then(() => {
        onCancelPress();
        setError('');
        notificationUtils.success('Uploaded successfully');
        return dispatch(getCases({ currentPage: 1, filter: { stateCode } }));
      })
      .catch((e) => {
        const errorMessage = typeof e === 'string' ? e : 'Failed, try again later';
        setError(errorMessage);
      })
      .finally(() => setIsLoading(false));
  });

  const file = form.watch('File');

  const isValid = file && form.formState.isValid;

  return (
    <MDialog
      isOpen={isOpen}
      onClose={onCancelPress}
      title={BulkCaseCreateStrings.title}
      maxWidth="796px"
      minWidth="796px"
      footerActions={[
        <MButton size="large" variant="bordered" onClick={onCancelPress}>
          {BulkCaseCreateStrings.cancelBtn}
        </MButton>,
        <MButton size="large" onClick={handleSubmit} disabled={!isValid || isLoading}>
          {BulkCaseCreateStrings.submitBtn}
        </MButton>,
      ]}
    >
      {isLoading && <LoaderCover />}
      <Form form={form} onSubmit={handleSubmit}>
        {file ? (
          <FileStack direction="row">
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Document style={{ margin: '0 20px 0 0' }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '90%',
                }}
              >
                <Typography
                  variant="bodyM"
                  sx={{
                    width: '650px',
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {file.name}
                </Typography>
                <IconButton onClick={handleDelete}>
                  <Close width="18px" height="18px" />
                </IconButton>
              </Box>
            </Stack>
          </FileStack>
        ) : (
          <Dropzone
            accept={{
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
              'application/vnd.ms-excel': ['.xls'],
            }}
            onDrop={handleDrop}
            customContent={
              <>
                <Typography variant="bodyL500" color="text.secondary" sx={{ my: 1 }}>
                  {BulkCaseCreateStrings.text}
                  <Typography
                    variant="bodyL"
                    sx={{
                      color: 'buttonPrimary.active',
                      textDecoration: 'underline',
                      marginLeft: 0.5,
                    }}
                  >
                    {BulkCaseCreateStrings.action}
                  </Typography>
                </Typography>
                <Typography variant="bodyXS" color="text.secondary" sx={{ my: 1 }}>
                  {BulkCaseCreateStrings.additionalInfo}
                </Typography>
              </>
            }
          />
        )}
        <Stack direction="row" mt={3} alignItems="center" columnGap={3} flexWrap="nowrap">
          <Field
            name="DateServed"
            isMandatory
            render={({ field, onCustomChange }) => (
              <DateOrTimePicker
                isDate
                Svg={Calendar}
                name="DateServed"
                setSelectedTimeOrDate={onCustomChange(field.onChange)}
                selected={field.value}
                label="Date Served"
                width="278px"
                height="48px"
              />
            )}
          />
          {error && (
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              minHeight="48px"
              mt={3}
              width="100%"
            >
              <Typography
                width="100%"
                variant="bodyM"
                color={error ? palette.additional.alert : palette.text.tertiary}
              >
                {error || formatBytes(file.size)}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Form>
    </MDialog>
  );
};

BulkCaseCreateDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  stateCode: PropTypes.string,
};

BulkCaseCreateDialog.defaultProps = {
  stateCode: 'NV',
};

export default BulkCaseCreateDialog;
