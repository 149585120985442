import moment from 'moment';
import CaseStatus from '../../../molecules/CaseStatus';
import { ReferenceNumberCell } from '../CaseSearch/CaseSearchCells';
import { TextCell, DateCell, DuplicateCell } from './TableCell';

export const arizonaProceduralHistoryColumns = [
  {
    Header: 'Assigned Attorney',
    accessor: 'assignedAttorney',
    width: 216,
  },
  {
    Header: 'Procedural Posture',
    accessor: 'hearingType',
    width: 216,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDate',
    width: 216,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('L') : '',
  },
  {
    Header: 'Court Time',
    accessor: 'courtTime',
    width: 216,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('LT') : '',
  },
  {
    Header: 'Date of Writ',
    accessor: 'dateOfWrit',
    width: 216,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CaseStatus,
  },
];

export const arizonaProceduralHistoryEvictionMatterColumns = [
  {
    Header: 'Assigned Attorney',
    accessor: 'assignedAttorney',
    width: 216,
  },
  {
    Header: 'Procedural Posture',
    accessor: 'hearingType',
    width: 216,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDate',
    width: 216,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('L') : '',
  },
  {
    Header: 'Court Time',
    accessor: 'courtTime',
    width: 216,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('LT') : '',
  },
  {
    Header: 'Date of Writ',
    accessor: 'dateOfWrit',
    width: 216,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CaseStatus,
  },
  {
    Header: 'Condition',
    accessor: 'condition',
    width: 200,
    Cell: ({ row: { original } }) => {
      if (original.isTentative && original.isAmmendedTime) return 'Tentative, Amended';
      if (original.isTentative) return 'Tentative';
      if (original.isAmmendedTime) return 'Amended';
      return '';
    },
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 562,
  },
];

export const nevadaProceduralHistoryColumns = [
  {
    Header: 'Assigned Attorney',
    accessor: 'assignedAttorney',
    width: 216,
  },
  {
    Header: 'Procedural Posture',
    accessor: 'hearingType',
    width: 216,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDate',
    width: 216,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('L') : '',
  },
  {
    Header: 'Court Time',
    accessor: 'courtTime',
    width: 216,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('LT') : '',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CaseStatus,
  },
];

export const nevadaProceduralHistoryEvictionMatterColumns = [
  {
    Header: 'Assigned Attorney',
    accessor: 'assignedAttorney',
    width: 216,
  },
  {
    Header: 'Procedural Posture',
    accessor: 'hearingType',
    width: 216,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDate',
    width: 216,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('L') : '',
  },
  {
    Header: 'Court Time',
    accessor: 'courtTime',
    width: 216,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('LT') : '',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CaseStatus,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 562,
  },
];

export const caseStatusColumns = [
  {
    Header: 'Status',
    accessor: 'status',
    width: 20,
    Cell: CaseStatus,
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    width: 20,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Updated By',
    accessor: 'updatedBy',
    width: 20,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 40,
  },
];

export const relatedCasesColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    width: 15,
    Cell: ReferenceNumberCell,
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    width: 10,
    Cell: TextCell,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'defendantName',
    width: 20,
    Cell: TextCell,
  },
  {
    Header: 'Apartment #',
    accessor: 'apartmentNumber',
    width: 10,
    Cell: TextCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 15,
    Cell: CaseStatus,
  },
  {
    Header: 'Creation Date',
    accessor: 'creationDate',
    width: 15,
    Cell: DateCell,
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    width: 15,
    Cell: DateCell,
  },
  {
    Header: 'Duplicate',
    accessor: 'isPossibleDuplicate',
    width: 10,
    Cell: DuplicateCell,
  },
];

export const caseInformationBoxes = [
  {
    boxTitle: 'Procedural History',
    name: 'proceduralHistory',
    fields: (stateCode = 'AZ') =>
      stateCode === 'AZ'
        ? [
            {
              label: 'Case #',
              name: 'caseNumber',
              placeholder: 'xxxxxx',
              width: '199px',
            },
            {
              label: 'P.O./File #',
              name: 'poNumber',
              placeholder: 'P.O./File #',
              width: '199px',
            },
          ]
        : [
            {
              label: 'Case #',
              name: 'caseNumber',
              placeholder: 'xxxxxx',
              width: '199px',
            },
            {
              label: 'P.O./File #',
              name: 'poNumber',
              placeholder: 'P.O./File #',
              width: '199px',
            },
            {
              label: 'Eviction Granted On',
              name: 'evictionGrantedOn',
              width: '199px',
              placeholder: 'mm/dd/yyyy',
              isDatePicker: true,
              height: '48px',
            },
          ],
    tableName: 'proceduralHistoryEntries',
    tableColumns: (caseType, stateCode = 'AZ') => {
      if (caseType === 'Eviction Matter') {
        return stateCode === 'AZ'
          ? arizonaProceduralHistoryEvictionMatterColumns
          : nevadaProceduralHistoryEvictionMatterColumns;
      }
      return stateCode === 'AZ' ? arizonaProceduralHistoryColumns : nevadaProceduralHistoryColumns;
    },
  },
  {
    boxTitle: 'Case Status',
    name: 'caseStatus',
    fields: [],
    tableName: 'caseStatuses',
    tableColumns: caseStatusColumns,
  },
  {
    boxTitle: 'Related Cases',
    name: 'relatedCases',
    fields: [],
    tableName: 'relativeCases',
    tableColumns: () => relatedCasesColumns,
  },
];
