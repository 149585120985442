import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { Box, Container, Typography, Stack } from '@mui/material';

import {
  columnsList,
  CONTACT_PERSONS_COLUMNS,
  ENTITIES_COLUMNS,
  INDIVIDUALS_COLUMNS,
  PartiesAndPrecinctBoxes,
  PROPERTY_TYPE,
} from './PartiesAndPrecinct.constants';
import Field from '../../../atoms/Field';
import Form from '../../../atoms/Form';
import Dropdown from '../../../atoms/Dropdown';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import useCases from '../../../../hooks/useCases';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import { ReactComponent as InfoCirleRed } from '../../../../assets/icons/infoCircleFilledRed.svg';
import {
  getPartiesAndPrecinct,
  createCase,
  getAddresses,
  updateCase,
  removeDefendantFromCase,
  addDefendantToCase,
  updateDefendantInCase,
  addEntityToCase,
  removeEntityFromCase,
  updateEntityInCase,
  getContactPersons,
  getContactPersonsByManagement,
  getContactPersonsByAddressId,
} from '../../../../store/slices/cases/casesGeneralSlice';
import { getAddresses as getAddressesList } from '../../../../store/slices/addresses/addressesSlice';
import Table from '../../../atoms/Table/Table';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import { useNavigationBlockPopup } from '../../../../hooks/useNavigationBlockPopup';
import NotificationDialog from '../../../molecules/NotificationDialog';
import MTooltip from '../../../atoms/MTooltip';
import ShadowBlock from '../../../atoms/ShadowBlock';
import { EditableContext } from './PartiesAndPrecinctEditableCell';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup/CustomRadioGroup';
import notificationUtils from '../../../../utils/notificationUtils';
import LoaderCover from '../../../atoms/LoaderCover';
import MultilineTextField from '../../../atoms/MultilineTextField/MultilineTextField';
import { getCompanyManagementsList } from '../../../../store/slices/casesFilterSlice';

const mandatoryError = { type: 'required', message: 'Mandatory field' };

const MAX_PERSONS_PER_PAGE = 10;

const PartiesAndPrecinct = () => {
  const {
    getAddresses: addresses,
    contactPersons,
    // partiesAndPrecinct,
  } = useSelector((state) => state.casesGeneral);
  const { managementCompanies } = useSelector((store) => store.casesFilters);
  const [contacts, setContacts] = useState(contactPersons);

  const [isLoader, setIsLoader] = useState(false);
  const [isPersonsLoader, setPersonsLoader] = useState(true);
  const { actions } = useCases();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const wasChangedIsBilledToCorporate = useRef(false);

  const {
    state: { id: caseId, navPage, subNav, stateCode, caseType },
  } = useLocation();

  const { popup, navigateWithoutBlock } = useNavigationBlockPopup({
    blockerByDefault: !caseId,
  });
  const [showDuplicationPopup, setShowDuplicationPopup] = useState(false);
  const [duplicationError, setDuplicationError] = useState(null);
  const [errorCorpAddress, setErrorCorpAddress] = useState('');

  useEffect(() => {
    setContacts(contactPersons);
  }, [contactPersons?.items]);

  const form = useForm({
    defaultValues: {},
    resolver: async (data) => {
      const errors = {};

      if (typeof data.address?.id !== 'number') {
        errors.address = mandatoryError;
      }

      if (
        !data.community.hasNoApartment &&
        !data.community.apartmentNumber &&
        !data.community.isBilledToCorporate
      )
        errors.community = {
          apartmentNumber: mandatoryError,
        };

      return { values: data, errors };
    },
  });

  const individualFields = useFieldArray({
    control: form.control,
    name: 'individuals',
  });

  const entityFields = useFieldArray({
    control: form.control,
    name: 'entities',
  });

  const opposingPartyLength = individualFields.fields.length + entityFields.fields.length;

  const firstInvididual = individualFields.fields?.[0];
  const firstEntity = entityFields.fields?.[0];

  const navigationTitle = useMemo(() => {
    const getFirstOfOppositeParty = () => {
      if (firstInvididual) {
        const { firstName, middleName, lastName, suffix } = firstInvididual;
        return [firstName, middleName, lastName, suffix].filter((x) => x).join(' ');
      }
      if (firstEntity) return firstEntity.entityName;
      return '';
    };
    const firstOfOppositeParty = getFirstOfOppositeParty();
    return opposingPartyLength > 1 ? `${firstOfOppositeParty}, Et Al.` : firstOfOppositeParty;
  }, [firstInvididual, firstEntity, opposingPartyLength]);

  useEffect(() => {
    if (caseId && navigationTitle) {
      navigate(location.pathname, {
        state: {
          ...location.state,
          defendantName: navigationTitle,
        },
        replace: true,
      });
    }
  }, [navigationTitle]);

  const management = form.watch('community.mgt');

  const clearAddressRelatedFields = () => {
    form.setValue('address', null);
    form.setValue('community.city', '');
    form.setValue('community.communityName', '');
    form.setValue('community.apartmentNumber', '');
    form.setValue('community.hasNoApartment', false);
    form.setValue('community.zipCode', '');
    form.setValue('community.ownerEntity', '');
    form.setValue('community.propertyType', null);
    form.setValue('community.email', '');
    form.setValue('community.phoneNumber', '');
    form.setValue('community.hasGovernmentBackedLoan', null);

    form.setValue('assignedPrecinct.courtName', '');
    form.setValue('assignedPrecinct.courtPhoneNumber', '');
    form.setValue('assignedPrecinct.courtFaxNumber', '');
    form.setValue('assignedPrecinct.courtEmail', '');
    form.setValue('assignedPrecinct.address', '');
    form.setValue('assignedPrecinct.city', '');
    form.setValue('assignedPrecinct.zipCode', '');
    form.setValue('assignedPrecinct.virtualMeetingLink', '');
    form.setValue('assignedPrecinct.virtualMeetingNumber', '');
    form.setValue('assignedPrecinct.stateCode', '');
    form.setValue('assignedPrecinct.virtualCourtNumber', '');
    form.setValue('assignedPrecinct.courtroom', '');
    form.setValue('assignedPrecinct.meetingIdNumber', '');
  };
  const isBilledToCorporate = form.watch('community.isBilledToCorporate');

  const handleFetchPersonsByManagement = async (mgtId) => {
    setPersonsLoader(true);
    await dispatch(getContactPersonsByManagement(mgtId || management?.id));
    setPersonsLoader(false);
  };

  const getCorporateAddressByManagementId = async (chosenManagementId) => {
    try {
      const res = await dispatch(
        getAddressesList({
          managementId: chosenManagementId,
          currentPage: 1,
          stateCode,
          isCorporateAddress: true,
        }),
      );
      // TODO: it should be refactored on the backend side to get address from one query
      const corpAddress = res.payload.result.items?.[0];

      if (!corpAddress) {
        const errorMessageByState =
          stateCode === 'AZ' ? 'No AZ corporate address found' : 'No NV corporate address found';
        setErrorCorpAddress(errorMessageByState);
      } else {
        const result = await dispatch(getAddresses({ addressId: corpAddress.addressId }));

        const chosenAddressInfo = result.payload.items?.[0]?.data;

        form.setValue('address', {
          id: corpAddress.addressId,
          label: chosenAddressInfo.community.address,
          value: corpAddress.addressId,
        });

        form.setValue('community.communityName', chosenAddressInfo.community.communityName);
        form.setValue('community.city', chosenAddressInfo.community.city);
        form.setValue('community.apartmentNumber', '');
        form.setValue('community.hasNoApartment', false);
        form.setValue('community.zipCode', chosenAddressInfo.community.zipCode || '');
        form.setValue('community.ownerEntity', chosenAddressInfo.community.ownerEntity || '');
        const propertyType =
          PROPERTY_TYPE.find((item) => item.value === chosenAddressInfo.propertyType) || null;

        form.setValue('community.propertyType', propertyType);
        form.setValue('community.communityName', chosenAddressInfo.community.communityName);
        form.setValue('community.email', chosenAddressInfo.community.email || '');
        form.setValue('community.phoneNumber', chosenAddressInfo.community.phoneNumber);
        form.setValue(
          'hasGovernmentBackedLoan',
          chosenAddressInfo.community.hasGovernmentBackedLoan,
        );
        form.setValue('assignedPrecinct.courtName', chosenAddressInfo.assignedPrecinct.courtName);
        form.setValue(
          'assignedPrecinct.courtPhoneNumber',
          chosenAddressInfo.assignedPrecinct.courtPhoneNumber,
        );
        form.setValue(
          'assignedPrecinct.courtFaxNumber',
          chosenAddressInfo.assignedPrecinct.courtFaxNumber,
        );
        form.setValue('assignedPrecinct.courtEmail', chosenAddressInfo.assignedPrecinct.courtEmail);
        form.setValue('assignedPrecinct.address', chosenAddressInfo.assignedPrecinct.address);
        form.setValue('assignedPrecinct.city', chosenAddressInfo.assignedPrecinct.city);
        form.setValue('assignedPrecinct.zipCode', chosenAddressInfo.assignedPrecinct.zipCode);
        form.setValue(
          'assignedPrecinct.virtualMeetingLink',
          chosenAddressInfo.assignedPrecinct.virtualMeetingLink,
        );
        form.setValue(
          'assignedPrecinct.virtualMeetingNumber',
          chosenAddressInfo.assignedPrecinct.virtualMeetingNumber,
        );
        form.setValue('assignedPrecinct.stateCode', chosenAddressInfo.assignedPrecinct.stateCode);
        form.setValue(
          'assignedPrecinct.virtualCourtNumber',
          chosenAddressInfo.assignedPrecinct.virtualCourtNumber,
        );
        form.setValue('assignedPrecinct.courtroom', chosenAddressInfo.assignedPrecinct.courtroom);
        form.setValue(
          'assignedPrecinct.meetingIdNumber',
          chosenAddressInfo.assignedPrecinct.meetingIdNumber,
        );

        setErrorCorpAddress('');
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getPartiesAndPrecinctAction = async () => {
    const { result: partiesAndPrecinct } = await dispatch(
      getPartiesAndPrecinct({ caseId, stateCode }),
    ).unwrap();
    const formValues = form.getValues();

    const propertyType =
      PROPERTY_TYPE.find((item) => item.value === partiesAndPrecinct.propertyType) || null;

    form.reset({
      ...formValues,
      address: {
        id: partiesAndPrecinct.addressId,
        label: partiesAndPrecinct.community.address,
        value: partiesAndPrecinct.addressId,
      },
      community: {
        ...partiesAndPrecinct.community,
        propertyType,
        mgt: {
          id: partiesAndPrecinct.managementId,
          label: partiesAndPrecinct.community.mgt,
          value: partiesAndPrecinct.managementId,
        },
      },
      assignedPrecinct: partiesAndPrecinct.assignedPrecinct,
    });
    individualFields.replace(partiesAndPrecinct.individuals);
    entityFields.replace(partiesAndPrecinct.entities);

    return partiesAndPrecinct;
  };

  const handleFetchPersons = async (currentPage = 1) => {
    setPersonsLoader(true);
    await dispatch(
      getContactPersons({
        caseId,
        currentPage,
        pageSize: MAX_PERSONS_PER_PAGE,
        stateCode,
      }),
    );
    setPersonsLoader(false);
  };

  useEffect(() => {
    const fetcher = async () => {
      if (caseId) {
        const res = await getPartiesAndPrecinctAction();
        if (res.community.isBilledToCorporate) {
          handleFetchPersonsByManagement(res.managementId);
        } else {
          handleFetchPersons();
        }
      } else {
        const propertyType = PROPERTY_TYPE.find((item) => item.default);

        form.setValue('community.propertyType', propertyType);
      }
    };
    fetcher();
  }, [caseId]);

  const {
    handleMenuScrollToBottom: managementCompaniesScrollTobottom,
    changeInput: managementCompaniesChangeInput,
  } = useSearchDropdown({
    hasNextPage: managementCompanies?.hasNextPage,
    getAction: ({ input, page }) =>
      getCompanyManagementsList({
        currentPage: page,
        managementName: input,
      }),
  });

  const {
    handleMenuScrollToBottom: addressHandleMenuScrollToBottom,
    changeInput: addressChangeInput,
  } = useSearchDropdown({
    hasNextPage: addresses.hasNextPage,
    getAction: ({ input, page }) =>
      getAddresses({
        currentPage: page,
        stateCode,
        address: input,
      }),
  });

  const getHandlers = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case 'community.mgt':
          return {
            handleSearch: managementCompaniesChangeInput,
            onMenuScrollToBottom: managementCompaniesScrollTobottom,
          };
        case 'address':
          return {
            handleSearch: addressChangeInput,
            onMenuScrollToBottom: addressHandleMenuScrollToBottom,
          };
        default:
          return {
            handleSearch: null,
            onMenuScrollToBottom: null,
          };
      }
    },
    [
      managementCompaniesScrollTobottom,
      managementCompaniesChangeInput,
      addressChangeInput,
      addressHandleMenuScrollToBottom,
    ],
  );

  const handleSubmit = useCallback((values) => {
    const caseValues = {
      caseType,
      stateCode,
      addressId: values.address.id,
      apartmentNumber: values.community.apartmentNumber || '',
      hasNoApartmentNumber: !!values.community.hasNoApartment,
      individuals: values.individuals.map((individual) => ({
        firstName: individual.firstName || '',
        lastName: individual.lastName || '',
        middleName: individual.middleName || '',
        suffix: individual.suffix || '',
      })),
      entities: values.entities,
      hasSkipDuplicationCheck: values.hasSkipDuplicationCheck || false,
      hasGovernmentBackedLoan: values.community.hasGovernmentBackedLoan,
      isBilledToCorporate: values.community.isBilledToCorporate,
      propertyType: values.community.propertyType.value,
      ...((values.community.mgt && { mgtId: values.community.mgt.id }) || {}),
    };

    const handleError = (e) => {
      if (e?.errorCode === 'case.has.possibleDuplicates') {
        const caseDuplicatesOrRelatives = e?.errorData?.caseDuplicatesOrRelatives?.map((item) => {
          return {
            ...item,
            caseId: item.id,
          };
        });
        setDuplicationError({
          message: e.errorMessage,
          caseDuplicatesOrRelatives: caseDuplicatesOrRelatives ?? [],
        });
        setShowDuplicationPopup(true);
      } else {
        notificationUtils.error(e?.errorMessage || 'Unexpected error');
      }
    };

    setIsLoader(true);
    dispatch(getAddresses({ stateCode, currentPage: 1 }));
    if (caseId) {
      dispatch(updateCase({ id: caseId, body: caseValues }))
        .unwrap()
        .then(() => {
          getPartiesAndPrecinctAction();
          notificationUtils.success('Updated successfully');
        })
        .catch(handleError)
        .finally(() => {
          setIsLoader(false);
          if (caseValues.hasSkipDuplicationCheck) setShowDuplicationPopup(false);
        });
    } else {
      dispatch(createCase(caseValues))
        .unwrap()
        .then(({ result }) => {
          notificationUtils.success('Created successfully');
          dispatch(getPartiesAndPrecinct({ caseId: result, stateCode })).then(({ payload }) => {
            const {
              result: { referenceNumber },
            } = payload;
            navigateWithoutBlock('/db/processing/editcase/partiesandprecinct', {
              state: {
                navPage: 'Processing',
                subNav: 'Cases',
                id: result,
                stateCode,
                caseType,
                defendantName: navigationTitle,
                refNum: referenceNumber,
              },
            });
          });
        })
        .catch(handleError)
        .finally(() => {
          setIsLoader(false);
          if (caseValues.hasSkipDuplicationCheck) setShowDuplicationPopup(false);
        });
    }
  }, []);

  const handleCancel = useCallback(() => {
    actions?.clear();
    form.reset();
    navigate('/db/processing/casesearch', {
      state: {
        navPage,
        subNav,
        stateCode,
      },
    });
  }, []);

  const handleAddDefendant = (i) => async () => {
    const values = form.getValues();
    const boxes = i.inputBoxes.filter((b) => !!b?.name?.startsWith('individual.'));
    const errors = boxes.map(({ name, isMandatory }) => ({
      name,
      error: isMandatory && !form.getValues(name) ? mandatoryError : null,
    }));
    errors.forEach(({ name, error }) => form.setError(name, error));
    if (errors.find((e) => e.error)) {
      return;
    }
    if (!caseId) {
      individualFields.prepend(values.individual);
    } else {
      setIsLoader(true);
      await dispatch(addDefendantToCase({ id: caseId, body: values.individual })).unwrap();
      await getPartiesAndPrecinctAction();
      setIsLoader(false);
    }
    boxes.forEach((box) => form.setValue(box.name, ''));
  };

  const handleRemoveDefendant = (index) => {
    const defendant = individualFields.fields[index];
    dispatch(removeDefendantFromCase({ defendantId: defendant.caseDefendantId, id: caseId })).then(
      () => {
        setIsLoader(true);
        getPartiesAndPrecinctAction().then(() => {
          setIsLoader(false);
        });
      },
    );
  };

  const handleUpdateDefendant = async (index, values) => {
    const areFieldsInvalid = INDIVIDUALS_COLUMNS.some((c) => c.isMandatory && !values[c.accessor]);
    if (areFieldsInvalid) return false;
    if (!caseId) {
      individualFields.update(index, values);
    } else {
      const defendant = individualFields.fields[index];

      dispatch(
        updateDefendantInCase({ defendantId: defendant.caseDefendantId, id: caseId, body: values }),
      ).then(() => {
        setIsLoader(true);
        getPartiesAndPrecinctAction().then(() => {
          setIsLoader(false);
        });
      });
    }
    return true;
  };

  const handleAddEntity = (i) => async () => {
    const values = form.getValues();
    const boxes = i.inputBoxes.filter((b) => !!b?.name?.startsWith('entity.'));
    const errors = boxes.map(({ name, isMandatory }) => ({
      name,
      error: isMandatory && !form.getValues(name) ? mandatoryError : null,
    }));
    errors.forEach(({ name, error }) => form.setError(name, error));
    if (errors.find((e) => e.error)) {
      return;
    }
    if (!caseId) {
      entityFields.prepend(values.entity);
    } else {
      setIsLoader(true);
      await dispatch(addEntityToCase({ id: caseId, body: values.entity })).unwrap();
      await getPartiesAndPrecinctAction();
      setIsLoader(false);
    }
    boxes.forEach((box) => form.setValue(box.name, ''));
  };

  const handleRemoveEntity = (index) => {
    const entity = entityFields.fields[index];
    dispatch(removeEntityFromCase({ caseEntityId: entity.caseEntityId, id: caseId })).then(() => {
      setIsLoader(true);
      getPartiesAndPrecinctAction().then(() => {
        setIsLoader(false);
      });
    });
  };

  const handleUpdateEntity = async (index, values) => {
    const areFieldsInvalid = ENTITIES_COLUMNS.some((c) => c.isMandatory && !values[c.accessor]);
    if (areFieldsInvalid) return;
    if (!caseId) {
      entityFields.update(index, values);
    } else {
      const entity = entityFields.fields[index];

      dispatch(
        updateEntityInCase({ caseEntityId: entity.caseEntityId, id: caseId, body: values }),
      ).then(() => {
        setIsLoader(true);
        getPartiesAndPrecinctAction().then(() => {
          setIsLoader(false);
        });
      });
    }
  };

  const hasNoApartmentNumber = form.watch('community.hasNoApartment');
  useEffect(() => {
    if (hasNoApartmentNumber) form.setValue('community.apartmentNumber', '');
  }, [hasNoApartmentNumber]);

  const hideDuplicationPopup = () => setShowDuplicationPopup(false);
  const goToCases = () => {
    const caseIds = duplicationError?.caseDuplicatesOrRelatives?.map((item) => item.id);
    navigateWithoutBlock('/db/processing/casesearch', {
      state: {
        navPage,
        subNav,
        caseIds,
        stateCode,
      },
    });
  };

  const createCaseWithPossibleDuplicate = () => {
    handleSubmit({
      ...form.watch(),
      hasSkipDuplicationCheck: true,
    });
  };

  const defineOptions = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case 'community.propertyType':
          return PROPERTY_TYPE;
        case 'address':
          return addresses.items;
        case 'community.mgt':
          return managementCompanies?.items;
        default:
          return [];
      }
    },
    [addresses.items, managementCompanies?.items],
  );

  const canChangeGovernmentBackedLoan = useAllowed([rolesDB.Attorney, rolesDB.Admin]);

  const getShowMandatory = useCallback(
    (fieldName, initial = false) => {
      switch (fieldName) {
        case 'individual.firstName':
        case 'individual.lastName':
          return !entityFields.fields.length;
        case 'entity.entityName':
          return !individualFields.fields.length;
        case 'community.apartmentNumber':
          return !form.getValues('community.isBilledToCorporate');
        default:
          return initial;
      }
    },
    [opposingPartyLength, form.getValues('community.isBilledToCorporate')],
  );

  const editableContextValue = useMemo(
    () => ({
      isDeletable: !caseId || opposingPartyLength > 1,
    }),
    [opposingPartyLength, caseId],
  );

  const assignAddressData = useCallback(
    ({ id: addressId }) => {
      if (addressId && addresses?.items?.length && !isLoader) {
        const address = addresses.items.find((addr) => addr.id === addressId);
        if (address) {
          const { community = {}, assignedPrecinct = {} } = address.data;

          const values = form.getValues();

          // old logic
          // const {
          //   community: { propertyType },
          // } = values;
          // const propertyTypeValue =
          // PROPERTY_TYPE.find(
          //   (item) => item.value === propertyType?.value,
          // ) || null;
          const propertyTypeValue =
            PROPERTY_TYPE.find((item) => item.value === address.data.propertyType) || null;

          form.reset({
            ...values,
            community: {
              ...community,
              isBilledToCorporate,
              propertyType: propertyTypeValue,
              apartmentNumber: values?.community?.apartmentNumber ?? '',
              hasNoApartment: values?.community?.hasNoApartment ?? false,
              mgt: {
                value: address.data.managementId,
                label: address.data.community.mgt,
                id: address.data.managementId,
              },
            },
            assignedPrecinct,
          });
        }
      }
    },
    [addresses, form, isBilledToCorporate],
  );

  const defineDropdownCallback = useCallback(
    (value, fieldName, callbackFn) => {
      if (fieldName === 'address') {
        form.setValue('address', value);
        if (!isBilledToCorporate) {
          dispatch(
            getContactPersonsByAddressId({
              id: value.value,
              currentPage: 1,
              pageSize: MAX_PERSONS_PER_PAGE,
            }),
          );
        }
        assignAddressData(value);
      } else if (fieldName === 'community.mgt') {
        if (isBilledToCorporate) {
          getCorporateAddressByManagementId(value.value);
          handleFetchPersonsByManagement(value.value);
        }
        form.setValue('community.mgt', value);
      } else {
        callbackFn(value);
      }
    },
    [assignAddressData, isBilledToCorporate],
  );

  const renderDuplicateContent = () => {
    return (
      <Box>
        <Table
          columns={columnsList}
          rows={duplicationError?.caseDuplicatesOrRelatives ?? []}
          total={duplicationError?.caseDuplicatesOrRelatives?.length}
        />
      </Box>
    );
  };

  const checkIsDisabledField = useCallback(
    (name, defaultDisabled) => {
      switch (name) {
        case 'address':
        case 'community.hasNoApartment':
        case 'community.apartmentNumber': {
          if (isBilledToCorporate) return true;
          return defaultDisabled;
        }
        case 'community.mgt': {
          if (isBilledToCorporate) return false;
          return defaultDisabled;
        }
        case 'community.hasGovernmentBackedLoan': {
          if (isBilledToCorporate) return true;
          return !canChangeGovernmentBackedLoan;
        }
        default:
          return defaultDisabled;
      }
    },
    [canChangeGovernmentBackedLoan, isBilledToCorporate],
  );

  const getPlaceholder = (name, defaultPlaceholder) => {
    switch (name) {
      case 'community.mgt': {
        if (isBilledToCorporate) return 'Select Management company';
        return defaultPlaceholder;
      }
      case 'individual.firstName':
      case 'individual.middleName':
      case 'individual.lastName':
      case 'individual.suffix':
      case 'entity.entityName':
        return defaultPlaceholder;
      default: {
        if (isBilledToCorporate) return '-';
        return defaultPlaceholder;
      }
    }
  };

  const handleChangeCheckbox = (name, val, cb) => {
    if (name === 'community.isBilledToCorporate') {
      if (val) {
        setContacts({ items: [] });
        clearAddressRelatedFields();
        form.setValue('community.mgt', null);
      } else {
        setErrorCorpAddress('');
        clearAddressRelatedFields();
        form.setValue('community.mgt', null);
        dispatch(getAddresses({ stateCode, currentPage: 1 }));
        setContacts({ items: [] });
        setPersonsLoader(false);
      }
    }
    cb();
  };

  return (
    <EditableContext.Provider value={editableContextValue}>
      {popup}
      <NotificationDialog
        title="Possible duplicate cases"
        width="800px"
        maxWidth="800px"
        desc={duplicationError?.message}
        type="alert"
        buttonSecondaryText="Go to cases"
        buttonSecondaryWidth="140px"
        buttonPrimaryText={caseId ? 'Update' : 'Create'}
        onClose={hideDuplicationPopup}
        onCancel={goToCases}
        onConfirm={createCaseWithPossibleDuplicate}
        isOpen={showDuplicationPopup}
        content={renderDuplicateContent()}
      />
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          mb: 9 + 4 + 4 + 6,
          px: 4,
          width: 1,
          maxWidth: 1600,
        }}
      >
        {isLoader && <LoaderCover isFixed />}
        <Form form={form} onSubmit={handleSubmit}>
          {PartiesAndPrecinctBoxes &&
            PartiesAndPrecinctBoxes.map((i) => (
              <Container
                key={i.boxTitle}
                maxWidth={false}
                disableGutters
                sx={{
                  mx: 'auto',
                  mt: 4,
                  width: 1,
                }}
              >
                <ShadowBlock variant="dropdownCard">
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">
                      {i.boxTitle}
                      {i.hasTooltip && stateCode === 'AZ' && <MTooltip text={i.tooltipText} />}
                    </Typography>
                    {!!i.info && (
                      <Typography variant="bodyM" color="text.secondary">
                        {i.info}
                      </Typography>
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={3}
                    rowGap={2}
                    flexWrap="wrap"
                  >
                    {i.inputBoxes.map((item) => {
                      if (item.isSubHeading) {
                        return (
                          <Typography width={1} mb={1.5} variant="bodyL500" key={item.label}>
                            {item.label}
                          </Typography>
                        );
                      }

                      if (item.isDropdown) {
                        const { handleSearch, onMenuScrollToBottom } = getHandlers(item.name);
                        return (
                          <Field
                            key={item.label}
                            isMandatory={item.isMandatory}
                            name={item.name}
                            render={({ field, onCustomChange, error }) => (
                              <Dropdown
                                key={item.label}
                                label={item.label}
                                value={field.value}
                                width={item.width}
                                error={error}
                                isColumn
                                isMandatory={item.isMandatory}
                                isDisabled={checkIsDisabledField(item.name, item.isDisabled)}
                                placeholder={getPlaceholder(item.name, item.placeholder)}
                                onChange={(value) =>
                                  defineDropdownCallback(
                                    value,
                                    field.name,
                                    onCustomChange(field.onChange),
                                  )
                                }
                                options={defineOptions(item.name)}
                                onMenuScrollToBottom={onMenuScrollToBottom}
                                onInputChange={handleSearch}
                                isSearchable={item?.isSearchable}
                                filterOption={null}
                              />
                            )}
                          />
                        );
                      }

                      if (item.isCheckbox) {
                        if (item.caseTypes && !item.caseTypes.includes(caseType)) {
                          return null;
                        }
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            render={({ field, onCustomChange }) => (
                              <CheckboxWithLabel
                                isDisabled={checkIsDisabledField(item.name, item.isDisabled)}
                                key={item.label}
                                label={item.label}
                                isChecked={field.value}
                                onChange={onCustomChange((val) =>
                                  handleChangeCheckbox(item.name, val.target.value, () =>
                                    field.onChange(val),
                                  ),
                                )}
                                marginTop="25px"
                                errorMessage={
                                  item.name === 'community.isBilledToCorporate' && errorCorpAddress
                                    ? errorCorpAddress
                                    : ''
                                }
                                icon={
                                  item.name === 'community.isBilledToCorporate' &&
                                  errorCorpAddress ? (
                                    <InfoCirleRed width="18" />
                                  ) : null
                                }
                              />
                            )}
                          />
                        );
                      }

                      if (item.isRadioButtons) {
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            render={({ field, onCustomChange }) => (
                              <CustomRadioGroup
                                data={item.data}
                                disabled={checkIsDisabledField(item.name)}
                                value={field.value}
                                label={item.label}
                                width={item.width}
                                radioButtonWidth={item.radioButtonWidth}
                                onChange={onCustomChange(field.onChange)}
                              />
                            )}
                          />
                        );
                      }

                      if (item?.isSpace) {
                        return <div style={{ width: '100%' }} />;
                      }

                      if (item?.isLink) {
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            render={({ field, error }) => {
                              return field.value ? (
                                <Stack spacing={0.5} width={item.width}>
                                  <Typography
                                    variant="buttonLarge"
                                    component="label"
                                    color="text.secondary"
                                  >
                                    {item.label}
                                  </Typography>
                                  <Typography
                                    variant="bodyM"
                                    component="a"
                                    color="text.primary"
                                    target="_blank"
                                    href={field.value}
                                    height={48}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    {field.value}
                                  </Typography>
                                </Stack>
                              ) : (
                                <EditInputWithLabel
                                  error={error}
                                  isMandatory={item.isMandatory}
                                  isDisabled={checkIsDisabledField(
                                    item.name,
                                    typeof item.isDisabled === 'function'
                                      ? item.isDisabled(form)
                                      : item.isDisabled,
                                  )}
                                  key={item.label}
                                  type="text"
                                  label={item.label}
                                  name={item.name}
                                  value={field.value}
                                  placeholder={getPlaceholder(item.name, item.placeholder)}
                                  width={item.width}
                                  onChange={field.onChange}
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item.isAddButtonWithTable) {
                        if (item.table === 'individualTable') {
                          return (
                            <React.Fragment key={item.table}>
                              <MButton
                                sx={{ marginTop: '32px', marginLeft: 'auto' }}
                                startIcon={<Add />}
                                onClick={handleAddDefendant(i)}
                              >
                                Add
                              </MButton>
                              <Box mt={2} width="100%">
                                <Table
                                  columns={INDIVIDUALS_COLUMNS}
                                  isLoading={false}
                                  onUpdateData={handleUpdateDefendant}
                                  onDeleteRow={
                                    caseId ? handleRemoveDefendant : individualFields.remove
                                  }
                                  rows={individualFields.fields}
                                />
                              </Box>
                            </React.Fragment>
                          );
                        }
                        if (item.table === 'entityTable') {
                          return (
                            <React.Fragment key={item.table}>
                              <MButton
                                sx={{ marginTop: '32px', marginRight: '100px' }}
                                startIcon={<Add />}
                                onClick={handleAddEntity(i)}
                              >
                                Add
                              </MButton>
                              <Box flexGrow={1}>
                                <Table
                                  columns={ENTITIES_COLUMNS}
                                  isLoading={false}
                                  onUpdateData={handleUpdateEntity}
                                  onDeleteRow={caseId ? handleRemoveEntity : entityFields.remove}
                                  rows={entityFields.fields}
                                />
                              </Box>
                            </React.Fragment>
                          );
                        }
                        return null;
                      }

                      if (item.table === 'contactPersons') {
                        if (!caseId) return null;

                        return (
                          <Stack direction="column" key={item.table} spacing={3} width="100%">
                            <Typography variant="bodyL500" color="text.primary">
                              Contact Persons
                            </Typography>
                            <Table
                              columns={CONTACT_PERSONS_COLUMNS}
                              rows={contacts?.items ?? []}
                              loading={isPersonsLoader}
                              isPagination={contacts?.totalPages > 1}
                              pageSize={MAX_PERSONS_PER_PAGE}
                              onNextPage={handleFetchPersons}
                              total={contacts?.totalRowsCount ?? 0}
                              onPreviousPage={handleFetchPersons}
                              onGotoPage={handleFetchPersons}
                            />
                          </Stack>
                        );
                      }

                      if (item.isMultiline) {
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, error }) => (
                              <MultilineTextField
                                label={item.label}
                                placeholder={item.placeholder}
                                width={item.width}
                                name={item.name}
                                rows={2}
                                value={field.value}
                                disabled={
                                  typeof item.isDisabled === 'function'
                                    ? item.isDisabled(form)
                                    : item.isDisabled
                                }
                                error={error}
                                onChange={field.onChange}
                                isMandatory={getShowMandatory(item.name, item.isMandatory)}
                              />
                            )}
                          />
                        );
                      }

                      return (
                        <Field
                          key={item.label}
                          name={item.name}
                          isMandatory={item.isMandatory}
                          render={({ field, error }) => (
                            <EditInputWithLabel
                              error={error}
                              isMandatory={getShowMandatory(item.name, item.isMandatory)}
                              isDisabled={checkIsDisabledField(
                                item.name,
                                typeof item.isDisabled === 'function'
                                  ? item.isDisabled(form)
                                  : item.isDisabled,
                              )}
                              key={item.label}
                              type="text"
                              label={item.label}
                              name={item.name}
                              value={field.value}
                              placeholder={getPlaceholder(item.name, item.placeholder)}
                              width={item.width}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      );
                    })}
                  </Stack>
                </ShadowBlock>
              </Container>
            ))}
          <Box
            position="absolute"
            left="50%"
            bottom={0}
            sx={{ transform: 'translateX(-50%)' }}
            zIndex={10}
            width={1}
            p={4}
            boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
          >
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <MButton
                variant="secondary"
                size="large"
                onClick={handleCancel}
                sx={{ width: '144px' }}
                disabled={isLoader}
              >
                Cancel
              </MButton>
              <MButton
                type="submit"
                size="large"
                name="Submit parties and precinct"
                sx={{ width: '144px' }}
                disabled={!opposingPartyLength || isLoader || errorCorpAddress}
              >
                {caseId ? 'Update' : 'Create'}
              </MButton>
            </Stack>
          </Box>
        </Form>
      </Container>
    </EditableContext.Provider>
  );
};

export default PartiesAndPrecinct;
