// Example: "2012-01-26T13:51:50.417-07:00" => "26/01/2012, 02:51 AM"
export const beautifulDate = (param) => {
  const date = new Date(Date.parse(param));
  const newDate = date.toLocaleString('en-us', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  return newDate;
};
