import { useEffect, useMemo, useState } from 'react';
import useSafeLocationState from '../../../hooks/useSafeLocationState';
import { apiClient, tokenRepository } from '../../../lib/apiClient';

export const useRolesAndStates = () => {
  const { roles: availableRoles = [], stateCodes: availableStates = [] } = useSafeLocationState();
  const roles = useMemo(
    () =>
      availableRoles.map((item) => ({
        id: item.roleId,
        value: item.roleName,
        label: item.roleName,
      })),
    [availableRoles],
  );
  const states = useMemo(
    () =>
      availableStates.map((item) => ({
        id: item,
        value: item,
        label: item,
      })),
    [availableStates],
  );

  return { roles, states };
};

export const useUserInformationData = (form) => {
  const { management } = useSafeLocationState();

  const { roles, states } = useRolesAndStates();

  const companyManagements = useMemo(
    () =>
      management
        ? [
            {
              id: management.managementId,
              label: management.managementName,
              value: management.managementId,
            },
          ]
        : [],
    [management],
  );

  useEffect(() => {
    if (companyManagements?.length === 1) {
      form.setValue('management', companyManagements[0]);
    }
  }, [companyManagements]);

  return {
    companyManagements,
    form,
    roles,
    states,
  };
};

export const useUserAssignmentData = (userInfo) => {
  const {
    states: stateCodes,
    management,
    selectedAddresses: lastAddresses = [],
    selectedCommunities: lastCommunities = [],
  } = userInfo;

  const [hasEntirePortfolio, setHasEntirePortfolio] = useState(
    userInfo.hasEntirePortfolio || false,
  );

  const [communities, setCommunities] = useState({
    items: [],
  });
  const [selectedCommunities, setSelectedCommunities] = useState(lastCommunities);
  const [isCommunitiesModalOpen, setCommunitiesModalOpen] = useState(false);

  const [addresses, setAddresses] = useState({
    items: [],
  });
  const [selectedAddresses, setSelectedAddresses] = useState(lastAddresses);
  const [isAddressesModalOpen, setAddressesModalOpen] = useState(false);

  const loadCommunities = async (params) => {
    const token = tokenRepository.getTemporaryToken();
    const {
      data: { result },
    } = await apiClient.post(`/cp/api/selfEnrollments/tokens/${token}/communities/filter`, params);
    setCommunities(result);
  };

  const loadAddresses = async (params) => {
    const token = tokenRepository.getTemporaryToken();
    const {
      data: { result },
    } = await apiClient.post(`/cp/api/selfEnrollments/tokens/${token}/addresses/filter`, params);
    setAddresses(result);
  };

  useEffect(() => {
    if (hasEntirePortfolio) {
      setSelectedAddresses([]);
      setSelectedCommunities([]);
    }
  }, [hasEntirePortfolio]);

  return {
    hasEntirePortfolio,
    setHasEntirePortfolio,

    statesToUseInSearch: stateCodes?.length === 1 ? stateCodes[0] : '',
    management,

    communities,
    loadCommunities,
    setCommunities,
    selectedCommunities,
    setSelectedCommunities,
    isCommunitiesModalOpen,
    setCommunitiesModalOpen,

    addresses,
    loadAddresses,
    setAddresses,
    selectedAddresses,
    setSelectedAddresses,
    isAddressesModalOpen,
    setAddressesModalOpen,
  };
};
