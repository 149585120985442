import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack } from '@mui/material';
import moment from 'moment';
import Tag from '../../../atoms/Tag';
import { ALL_STATUSES } from '../../../../global/constants';
import { palette } from '../../../../theme/default';
import { ReactComponent as CheckGreenIcon } from '../../../../assets/icons/CheckmarkGreenRounded.svg';

export const TextCell = ({ cell }) => {
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
        color: palette.text.primary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      data-testid="text_cell"
    >
      {cell?.value || '-'}
    </Typography>
  );
};

TextCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export const StatusCell = ({ value: status }) => {
  const backgroundColor = useMemo(() => {
    if (!status) return palette.statuses.transparent;
    const statusOption = ALL_STATUSES.find((o) => o.value === status);
    if (statusOption) return statusOption.color;
    return palette.statuses.transparent;
  }, [status]);

  return <Tag backgroundColor={backgroundColor} text={status} />;
};

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const DateCell = (props) => {
  const {
    cell: { value },
  } = props;
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
        color: palette.text.primary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      data-testid="date_cell"
    >
      {moment(value).format('L')}
    </Typography>
  );
};

DateCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
};

export const DuplicateCell = ({ cell }) => {
  if (!cell.value) {
    return null;
  }

  return (
    <Stack display="flex" width="100%" data-testid="check_icon">
      <CheckGreenIcon />
    </Stack>
  );
};

DuplicateCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.bool.isRequired,
  }).isRequired,
};
