import * as React from 'react';
import { Typography, Checkbox, Box } from '@mui/material';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { PropTypes } from 'prop-types';
import { ReactComponent as Asterisk } from '../../../assets/icons/asterisk.svg';
import { palette } from '../../../theme/default';

const StyledDropdown = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  label {
    margin-bottom: 4px;
  }
`;

const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

const selectAllOption = {
  label: 'Select/Unselect All',
  value: null,
};

const Option = (props) => {
  const { isSelected, label, data } = props;

  const isSelectAll = data === selectAllOption;

  return (
    <components.Option {...props}>
      <Checkbox
        sx={{
          '& .MuiSvgIcon-root': {
            fill: palette.buttonPrimary.active,
          },
          visibility: isSelectAll ? 'hidden' : 'visible',
        }}
        checked={isSelected}
      />
      <Typography data-testid="option">{label}</Typography>
    </components.Option>
  );
};

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

const ValueContainer = ({ children, ...props }) => {
  const getLabel = () => {
    if (!props.hasValue) return '';
    const values = props.getValue();
    return values.map((i) => i.label).join(', ');
  };
  return (
    <components.ValueContainer {...props}>
      <span style={{ maxWidth: '100%' }} data-testid="value_label">
        {getLabel()}
      </span>
      {children}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  hasValue: PropTypes.bool.isRequired,
  getValue: PropTypes.func.isRequired,
};

const styles = {
  control: (provided, state) => ({
    ...provided,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: state.selectProps.isError ? 'var(--alert)' : 'var(--border_line)',
    borderRadius: '10px',
    height: '48px',
    width: 'auto',
    backgroundColor: palette.common.white,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 12px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'flex',
    flexWrap: 'nowrap',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: palette.text.secondary,
    fontWeight: 500,
    fontSize: 16,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided) => ({
    ...provided,
    zIndex: 15,
  }),
  multiValue: () => ({
    display: 'none',
  }),
  option: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    minHeight: '39px',
    padding: '0 4px',
    backgroundColor: 'transparent',
    color: palette.text.primary,
    wordBreak: 'break-word',
    '&:hover': {
      backgroundColor: palette.additional.tag,
    },
  }),
};

const CheckboxSelect = React.forwardRef(
  (
    {
      isDisabled,
      isMandatory,
      label,
      options,
      onChange,
      placeholder,
      width,
      value,
      error,
      onInputChange,
      onEnter,
      onMenuScrollToBottom,
      isSearchable,
      showSelectAll,
      onCloseMenu,
    },
    ref,
  ) => {
    const handleClickStopPropagation = (e) => {
      e.stopPropagation();
    };

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const onKeyDown = (e) => {
      if (e.key === 'Enter' && onEnter && !isMenuOpen) {
        e.preventDefault();
        onEnter(e);
      }
    };

    const handleMenuOpen = () => {
      setIsMenuOpen(true);
    };
    const handleMenuClose = () => {
      onCloseMenu?.();
      setIsMenuOpen(false);
    };

    const selectOptions = showSelectAll ? [selectAllOption, ...options] : options;

    const handleChange = (nextValue) => {
      if (showSelectAll) {
        if (nextValue.includes(selectAllOption)) {
          // the second arg shows if allSelected was chosen
          // it is made for lazy loading in dropdown
          onChange(
            nextValue.length === selectOptions.length ? [] : options,
            !(nextValue.length === selectOptions.length),
          );
        } else {
          onChange(nextValue);
        }
      } else {
        onChange(nextValue);
      }
    };

    return (
      <StyledDropdown width={width} ref={ref} data-testid="dropdown_wrapper">
        {label && (
          <Typography
            variant="buttonLarge"
            color="text.secondary"
            component="label"
            data-testid="dropdown_label"
          >
            {label}
            {isMandatory && (
              <StyledSpan>
                <Asterisk data-testid="mandatory_icon" />
              </StyledSpan>
            )}
          </Typography>
        )}
        <Box position="relative" onClick={handleClickStopPropagation} data-testid="dropdown_select">
          <Select
            isMulti
            isClearable={false}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, ValueContainer }}
            styles={styles}
            placeholder={placeholder}
            options={selectOptions}
            onChange={handleChange}
            onInputChange={onInputChange}
            onMenuScrollToBottom={onMenuScrollToBottom}
            value={value}
            isError={!!error?.message}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            onKeyDown={onKeyDown}
          />
          {error?.message && (
            <Typography
              variant="bodyXS"
              color="additional.alert"
              component="span"
              position="absolute"
              left={0}
              top="calc(100% + 4px)"
              data-testid="error_message"
            >
              {error?.message}
            </Typography>
          )}
        </Box>
      </StyledDropdown>
    );
  },
);

CheckboxSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  width: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  onMenuScrollToBottom: PropTypes.func,
  onInputChange: PropTypes.func,
  onEnter: PropTypes.func,
  isSearchable: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  onCloseMenu: PropTypes.func,
};

CheckboxSelect.defaultProps = {
  value: [],
  isMandatory: false,
  isDisabled: false,
  placeholder: '',
  error: '',
  onMenuScrollToBottom: () => {},
  onInputChange: () => {},
  onEnter: null,
  isSearchable: false,
  showSelectAll: false,
  onCloseMenu: () => {},
};

export default CheckboxSelect;
