import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Table from '../../../../atoms/Table/Table';
import Searcher from '../../../../molecules/Searcher';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import MButton from '../../../../MUI/Button/MButton';
import { getCommunities, getStates } from '../../../../../store/slices/communitiesSlice';
import { deleteCommunity } from '../../../../../store/slices/editCommunitySlice';
import { CommunitiesColumns, MAX_AMOUNT_OF_ITEMS_ON_PAGE } from './Communities.constants';
import Dropdown from '../../../../atoms/Dropdown';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';
import useSafeLocationState from '../../../../../hooks/useSafeLocationState';
import StateLink from '../../../../atoms/StateLink/StateLink';
import BottomBar from '../../../../atoms/BottomBar/BottomBar';
import { usePrimaryState } from '../../../../../hooks/usePrimaryState';

const Communities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const primaryState = usePrimaryState();
  const [searchValue, setSearchValue] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const {
    navPage,
    subNav,
    state: defaultState,
  } = useSafeLocationState('Data Handling', 'Communities');
  const { communities, states } = useSelector((state) => state.communities);
  const [state, setState] = useState(primaryState || defaultState || null);
  const canCreate = useAllowed(rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney);
  const canDelete = useAllowed(rolesDB.Admin);

  const getCommunitiesData = useCallback(
    ({ currentPage = null, communityName = null, stateCode = state?.value } = {}) => {
      if (stateCode !== state?.value) setIsLoader(true);
      const params = {
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
        stateCode,
      };
      if (currentPage !== null) params.currentPage = currentPage;
      if (communityName !== null) params.communityName = communityName;
      return dispatch(getCommunities(params)).then(() => setIsLoader(false));
    },
    [dispatch, setIsLoader, state],
  );

  const currentPageDelete =
    communities?.items?.length === 1 && communities?.currentPage
      ? communities.currentPage - 1
      : communities?.currentPage;

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      setIsLoaderTable(true);
      getCommunitiesData({
        currentPage,
        communityName: searchValue,
      }).then(() => {
        setIsLoaderTable(false);
      });
    },
    [searchValue, getCommunitiesData],
  );

  const handleChangeEmpty = useCallback(
    (searchElement) => {
      if (!searchElement) {
        setIsLoaderTable(true);
        getCommunitiesData({
          currentPage: 1,
        }).then(() => {
          setIsLoaderTable(false);
          setSearchValue('');
        });
      }
    },
    [getCommunitiesData],
  );

  const handleSearch = useCallback(
    (searchElement) => {
      setSearchValue(searchElement);
      setIsLoaderTable(true);
      getCommunitiesData({
        currentPage: 1,
        communityName: searchElement,
      }).then(() => {
        setIsLoaderTable(false);
      });
    },
    [getCommunitiesData],
  );

  const handleStateChange = useCallback(
    (value) => {
      setState(value);
      setIsLoaderTable(true);
      getCommunitiesData({
        courtName: searchValue,
        stateCode: value?.value,
      }).then(() => setIsLoaderTable(false));
    },
    [getCommunitiesData],
  );

  const handleCreateCommunity = useCallback(() => {
    navigate('/db/datahandling/newcommunity/community', {
      state: {
        navPage,
        subNav,
        state,
      },
    });
  }, [state]);

  const handleDeleteCommunity = useCallback(
    (id, original) => {
      return dispatch(deleteCommunity(original.communityId));
    },
    [currentPageDelete, getCommunitiesData],
  );

  useEffect(() => {
    dispatch(getStates());
  }, []);

  useEffect(() => {
    if (states.length) {
      handleStateChange(primaryState || defaultState || states[0]);
    }
  }, [states]);

  const rowsAdapter = communities?.items?.map((item) => ({
    ...item,
    communityName: (
      <StateLink
        to="/db/datahandling/editcommunity/community"
        state={{ navPage, subNav, id: item.communityId, name: item.communityName, state }}
      >
        <Typography
          variant="link"
          color="text.primary"
          sx={{
            cursor: 'pointer',
            textDecorationLine: 'underline',
            textTransform: 'none',
          }}
        >
          {item.communityName}
        </Typography>
      </StateLink>
    ),
    control: {
      hideDelete: !canDelete,
      autoRefresh: true,
    },
  }));

  if (!isLoader && !rowsAdapter?.length && !searchValue) {
    return (
      <Stack height="calc(100vh - 80px - 72px)" direction="column">
        <Stack display="flex" flexDirection="row">
          <Typography variant="h3" margin="0 32px">
            Communities Search
          </Typography>
          <Dropdown
            label="Select state"
            options={states}
            onChange={handleStateChange}
            value={state}
          />
        </Stack>
        <Box margin="auto">
          <EmptyBlock
            title="You don’t have any communities yet"
            desc={
              canCreate
                ? 'Every community  you  create will appear here. To get started click on “Create new community” button below'
                : ''
            }
            buttonText={canCreate ? 'Create new community' : ''}
            onClick={canCreate ? handleCreateCommunity : undefined}
          />
        </Box>
      </Stack>
    );
  }

  return (rowsAdapter?.length && !isLoader) || searchValue ? (
    <Stack justifyContent="space-between" height="100%" pb={18}>
      <Box width="1066px" marginX="auto">
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5">Communities</Typography>
            <Box paddingTop={1}>
              <Dropdown
                label="Select state"
                options={states}
                onChange={handleStateChange}
                value={state}
              />
            </Box>
          </Stack>
          <Searcher
            inputPlaceholder="Search by Community Name"
            onSearch={handleSearch}
            onChange={handleChangeEmpty}
          />
          {!isLoader && !rowsAdapter?.length && searchValue ? (
            <Box paddingTop="100px" display="flex" justifyContent="center">
              <EmptyBlock
                title="Commnunities are not found"
                desc="Сhange the search value"
                margin="auto"
              />
            </Box>
          ) : (
            <Table
              columns={CommunitiesColumns}
              rows={rowsAdapter}
              total={communities?.totalRowsCount}
              isPagination={communities?.totalPages > 1}
              pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
              onDeleteRow={handleDeleteCommunity}
              onRefreshPage={handleFetchDataPage}
              loading={isLoaderTable}
              onNextPage={handleFetchDataPage}
              onPreviousPage={handleFetchDataPage}
              onGotoPage={handleFetchDataPage}
            />
          )}
        </Stack>
      </Box>
      {canCreate && (
        <BottomBar>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton sx={{ width: '144px' }} size="large" onClick={handleCreateCommunity}>
              Create New
            </MButton>
          </Stack>
        </BottomBar>
      )}
    </Stack>
  ) : (
    <Box position="absolute" top="50vh" left="50%" zIndex={10} transform="translate(-50%, -50%)">
      <CircularProgress />
    </Box>
  );
};

export default Communities;
