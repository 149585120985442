import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SubPageHeading from '../../../organisms/SubPageHeading';
import { subPageNavEdit, subPageNavNew } from './CourtContainer.constants';

const CourtContainer = () => {
  const { state } = useLocation();

  const { name, navPage, subNav, state: defaultState = null } = state;

  const subPageNavEditExtended = subPageNavEdit.map((item) => ({
    ...item,
    state,
  }));

  return (
    <>
      <SubPageHeading
        subPageNav={name ? subPageNavEditExtended : subPageNavNew}
        subPageHeading={name || 'New Court'}
        name={name}
        nav={{
          to: '/db/datahandling/courts',
          state: {
            state: defaultState,
            navPage,
            subNav,
          },
        }}
      />
      <Outlet />
    </>
  );
};

export default CourtContainer;
