import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Stack,
  Typography,
  Container,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import print from 'print-js';
import MCheckbox from '../../../atoms/MCheckbox';
import { ReactComponent as CheckboxIcon } from '../../../../assets/icons/Checkbox.svg';
import {
  ARIZONA_KEY,
  BULK_DOCUMENT_PRINTING_LABEL,
  BULK_DOCUMENT_PRINTING_STATE_DROPDOWN,
  DOCUMENTS_PAGE_SIZE,
  DOCUMENT_TYPE_DROPDOWN,
  SENT_PRINTED,
  STATUS_TYPE_DROPDOWN,
  tableColumns,
  UNSENT_UNPRINTED,
  UPLOAD_DOCUMENTS_LABEL,
} from './BulkDocumentPrinting.constants';
import Dropdown from '../../../atoms/Dropdown';
import SearchInputWithLabelAndIcon from '../../../atoms/SearchInputWithLabelAndIcon/SearchInputWithLabelAndIcon';
import MButton from '../../../MUI/Button/MButton';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import {
  getCourtNames,
  getDocumentsByFilter,
  resetGetDocumentsByFilter,
} from '../../../../store/slices/schedule/bulkDocumentPrintingSlice';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import { ReactComponent as PrinterActive } from '../../../../assets/icons/PrinterWhiteIcon.svg';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import { palette } from '../../../../theme/default';
import MPagination from '../../../atoms/MPagination';
import Table from '../../../atoms/Table/Table';
import { apiClient } from '../../../../lib/apiClient';
import { convertToBinaryFileUrl } from '../../../../utils/binaryHelpers';
import { useUserInfo } from '../../../../utils/roleHelpers';
import LoaderCover from '../../../atoms/LoaderCover';
import DatePickerWithLabel from '../../../atoms/DatePickerWithLabel/DatePickerWithLabel';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import UploadDocumentsDialog from './UploadDocumentsDialog/UploadDocumentsDialog';
import MTooltip from '../../../atoms/MTooltip';
import FullscreenLoaderWithText from '../../../atoms/FullscreenLoaderWithText/FullscreenLoaderWithText';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { usePrimaryState } from '../../../../hooks/usePrimaryState';

const findDefaultOption = (options) => {
  return options.find((item) => item.default);
};

const BulkDocumentPrinting = () => {
  useSafeLocationState('Processing', 'Bulk Document Handling');
  const { stateCodes } = useUserInfo();
  const primaryState = usePrimaryState();
  const bulkDocumentStateOptions = useMemo(
    () => BULK_DOCUMENT_PRINTING_STATE_DROPDOWN.filter(({ value }) => stateCodes.includes(value)),
    [stateCodes],
  );
  const [selectedState, setSelectedState] = useState(primaryState || bulkDocumentStateOptions[0]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedCourtName, setSelectedCourtName] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [selectedDocumentOptions, setSelectedDocumentOptions] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const refAll = useRef({});
  const [wasAllOperated, setWasAllOperated] = useState(false);
  const abortController = useRef(undefined);

  const dispatch = useDispatch();

  const { courtNames, bulkDocuments } = useSelector((state) => state.bulkDocumentPrinting);

  const resetDocumentsList = useCallback(() => {
    dispatch(resetGetDocumentsByFilter());
  }, [dispatch]);

  useEffect(() => {
    setSelectedStatus(findDefaultOption(STATUS_TYPE_DROPDOWN));
    setSelectedDocumentOptions(
      DOCUMENT_TYPE_DROPDOWN[bulkDocumentStateOptions[0]?.value || ARIZONA_KEY],
    );
    setSelectedDocumentType(
      findDefaultOption(DOCUMENT_TYPE_DROPDOWN[bulkDocumentStateOptions[0]?.value || ARIZONA_KEY]),
    );
    resetDocumentsList();
  }, []);

  const areDateFieldsMandatory = useMemo(() => {
    return selectedStatus?.value === SENT_PRINTED;
  }, [selectedStatus]);

  const isApplyButtonDisabled = useMemo(() => {
    if (!areDateFieldsMandatory) return false;

    return !selectedFromDate || !selectedToDate;
  }, [areDateFieldsMandatory, selectedFromDate, selectedToDate]);

  const handleChangeDocumentType = (value) => {
    setSelectedDocumentType(value);
  };

  const handleChangeStatus = (value) => {
    setSelectedStatus(value);
  };

  const handleClearFilters = useCallback(
    async (stateCode = selectedState?.value) => {
      const defaultStatus = findDefaultOption(STATUS_TYPE_DROPDOWN);
      const defaultDocTypeDropdown = DOCUMENT_TYPE_DROPDOWN[stateCode];
      const defaultDocumentType = findDefaultOption(defaultDocTypeDropdown);

      setSelectedDocumentOptions(defaultDocTypeDropdown);
      setSelectedDocumentType(defaultDocumentType);
      setSelectedStatus(defaultStatus);
      setSelectedCourtName(null);
      setSelectedFromDate(null);
      setSelectedToDate(null);

      resetDocumentsList();
    },
    [selectedState?.value, resetDocumentsList],
  );

  const handleDropdownChangeState = (selected) => {
    setSelectedState(selected);

    handleClearFilters(selected.value);
  };

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: courtNames?.hasNextPage,
    getAction: ({ input, page, value }) => {
      return getCourtNames({
        currentPage: page,
        pageSize: DOCUMENTS_PAGE_SIZE,
        courtName: input,
        stateCode: value,
      });
    },
    actionArguments: selectedState,
  });

  const handleChangeCourtname = (value) => {
    setSelectedCourtName(value);
  };

  const onChangeDateFrom = (value) => {
    setSelectedFromDate(value);
  };

  const onChangeDateTo = (value) => {
    setSelectedToDate(value);
  };

  const handleApply = async () => {
    setIsLoader(true);
    await dispatch(
      getDocumentsByFilter({
        currentPage: 1,
        pageSize: DOCUMENTS_PAGE_SIZE,
        documentType: selectedDocumentType.value,
        stateCode: selectedState.value,
        showUnPrintedAndUnSentOnly: selectedStatus.value === UNSENT_UNPRINTED,
        showPrintedOrSentOnly: selectedStatus.value === SENT_PRINTED,
        courtName: selectedCourtName?.value || null,
        fromDate: selectedFromDate ? moment(selectedFromDate, moment.ISO_8601) : null,
        toDate: selectedToDate ? moment(selectedToDate, moment.ISO_8601) : null,
      }),
    );
    setIsLoader(false);
  };

  const [accordionsToggled, setAccordionsToggled] = useState([]);

  const toggleAccodion = (index) => {
    setAccordionsToggled((prev) =>
      prev.includes(index) ? prev.filter((item) => item !== index) : [index, ...prev],
    );
  };

  const [checkedRows, setCheckedRows] = useState([]);
  const [docs, setDocs] = useState({});

  useEffect(() => {
    setCheckedRows([]);
    bulkDocuments?.items.forEach((item, ind) => {
      refAll.current = { ...(refAll.current || {}), [ind]: { checked: false } };
    });
  }, [bulkDocuments]);

  const handleCheck = (rowIndex) => {
    setWasAllOperated(true);
    const isRowChecked = _.includes(checkedRows, rowIndex);
    refAll.current[rowIndex].checked = !isRowChecked;

    const docsByCourt = { ...docs };
    const docsArray = [];

    if (!isRowChecked) {
      bulkDocuments?.items[rowIndex].cases.forEach((case1) => {
        case1.documentIds?.forEach((doc) => {
          docsArray.push({ caseId: bulkDocuments?.items[rowIndex]?.caseId, documentId: doc });
          docsByCourt[rowIndex] = docsArray;
        });
      });
    } else {
      delete docsByCourt[rowIndex];
    }

    setDocs(docsByCourt);

    setCheckedRows(
      isRowChecked ? checkedRows.filter((i) => i !== rowIndex) : checkedRows.concat([rowIndex]),
    );
  };

  const [filesToPrint, setFilesToPrint] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const isPrintingEnabled = useRef(true);

  useEffect(() => {
    if (filesToPrint.length === 0) {
      setIsPrinting(false);
      return;
    }
    if (!isPrintingEnabled.current) return;
    if (abortController?.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    const documentIds = filesToPrint.map((item) => item.documentId);

    setIsPrinting(true);
    apiClient
      .post(
        `/api/cases/documents/concat`,
        { documentIds },
        { signal: abortController?.current?.signal },
      )
      .then((response) => {
        if (!isPrintingEnabled.current) return;
        const {
          data: { result },
        } = response;
        const { contentType, content } = result;

        const fileURL = convertToBinaryFileUrl({ contentType, content });

        print({
          printable: fileURL,
          onPrintDialogClose: () => {
            Promise.all(
              documentIds.map((documentId) =>
                apiClient.put(`/api/cases/documents/${documentId}/print`),
              ),
            ).finally(() => {
              setIsPrinting(false);
              abortController.current = undefined;
            });
          },
        });
      });
  }, [filesToPrint.length]);

  const cancelPrinting = () => {
    if (abortController?.current) {
      abortController.current.abort();
    }
    isPrintingEnabled.current = false;

    setFilesToPrint([]);
    setDocs({});
    handleApply();
    setCheckedRows([]);
    setIsPrinting(false);
  };

  const handlePrint = async () => {
    isPrintingEnabled.current = true;
    const collection = [];
    Object.values(docs).forEach((item) => {
      collection.push(item);
    });

    setFilesToPrint(collection.flatMap((i) => i));
  };

  const printAsOne = (e, item) => {
    e.stopPropagation();
    isPrintingEnabled.current = true;
    const documentIds = item.cases.flatMap((i) => i.documentIds);

    if (abortController?.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    setIsPrinting(true);
    apiClient
      .post(
        `/api/cases/documents/concat`,
        { documentIds },
        { signal: abortController?.current?.signal },
      )
      .then((response) => {
        if (!isPrintingEnabled.current) return;
        const {
          data: { result },
        } = response;
        const { contentType, content } = result;

        const fileURL = convertToBinaryFileUrl({ contentType, content });

        print({
          printable: fileURL,
          onPrintDialogClose: () => {
            Promise.all(
              documentIds.map((documentId) =>
                apiClient.put(`/api/cases/documents/${documentId}/print`),
              ),
            ).finally(() => {
              setIsPrinting(false);
              abortController.current = undefined;
            });
          },
        });
      });
  };

  const handleChangePage = async (event, page) => {
    setIsLoader(true);
    await dispatch(
      getDocumentsByFilter({
        currentPage: page,
        pageSize: DOCUMENTS_PAGE_SIZE,
        documentType: selectedDocumentType.value,
        stateCode: selectedState.value,
        showUnPrintedAndUnSentOnly: selectedStatus.value === UNSENT_UNPRINTED,
        showPrintedOrSentOnly: selectedStatus.value === SENT_PRINTED,
        fromDate: moment(selectedFromDate).toISOString(false),
        courtName: selectedCourtName?.value || null,
        toDate: moment(selectedToDate).toISOString(false),
      }),
    );
    setIsLoader(false);
  };

  const [isUploadDocumentsOpen, setIsUploadDocumentsOpen] = useState(false);
  const handleUploadDocumentsClose = () => setIsUploadDocumentsOpen(false);

  const checkRow = (documents, ids, length, courtIndex) => {
    setWasAllOperated(false);
    const docsByCourt = { ...docs };
    const docsArray = [];

    documents.forEach((item) => {
      item?.documentIds?.forEach((doc) => {
        docsArray.push({ caseId: item.caseId, documentId: doc });
        docsByCourt[courtIndex] = docsArray;
      });
    });
    if (!documents.length) delete docsByCourt[courtIndex];

    setDocs(docsByCourt);

    // we check all row by row
    if (ids?.length === length && !checkedRows.includes(courtIndex)) {
      setCheckedRows(checkedRows.concat([courtIndex]));
      refAll.current[courtIndex].checked = true;
    } else if (checkedRows.includes(courtIndex) && ids?.length !== length) {
      setCheckedRows(checkedRows.filter((i) => i !== courtIndex));
      refAll.current[courtIndex].checked = false;
    }
  };
  const checkIsDisabled = useMemo(() => {
    const isSingleSelected = Object.entries(docs).some(([index, item]) => {
      return !checkedRows.includes(index) && !!item;
    });
    return isSingleSelected;
  }, [checkedRows, docs]);

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          px: 4,
        }}
      >
        {isLoader && <LoaderCover />}
        {isPrinting && (
          <FullscreenLoaderWithText
            title="Document is loading. Please wait."
            description={filesToPrint.length ? `(${filesToPrint.length} document(s) left)` : ''}
            actions={
              <MButton size="large" onClick={cancelPrinting}>
                Cancel
              </MButton>
            }
          />
        )}
        <Stack flex justifyContent="flex-start" alignItems="center" flexDirection="row">
          <Typography variant="h3" pb={1}>
            {BULK_DOCUMENT_PRINTING_LABEL}
          </Typography>
          <Dropdown
            label="Select State"
            options={bulkDocumentStateOptions}
            onChange={handleDropdownChangeState}
            placeholder=""
            isSearchable={false}
            value={selectedState}
          />
          <MButton
            variant="primary"
            sx={{ ml: 'auto' }}
            onClick={() => {
              setIsUploadDocumentsOpen(true);
            }}
            startIcon={<Add />}
          >
            {UPLOAD_DOCUMENTS_LABEL}
          </MButton>
          <MTooltip tooltipVariant="lightviolette" text="Document should be in PDF format" />
        </Stack>
        <Stack flex flexWrap="wrap" direction="initial" gap={2} mt={4}>
          <SearchInputWithLabelAndIcon
            label="Court Name"
            options={courtNames?.items}
            placeholder="Enter court name"
            onMenuScrollToBottom={handleMenuScrollToBottom}
            handleSearch={changeInput}
            handleChange={handleChangeCourtname}
            value={selectedCourtName}
            isDropdown
            width="288px"
            height={44}
            containerMarginBottom="0px"
            onEnter={handleApply}
          />
          <SearchInputWithLabelAndIcon
            handleChange={handleChangeDocumentType}
            options={selectedDocumentOptions}
            placeholder="Select Document Type"
            label="Document Type"
            value={selectedDocumentType}
            width="288px"
            isDropdown
            height={44}
            containerMarginBottom="0px"
            onEnter={handleApply}
          />
          <SearchInputWithLabelAndIcon
            label="Status"
            options={STATUS_TYPE_DROPDOWN}
            isDropdown
            value={selectedStatus}
            placeholder="Select status"
            handleChange={handleChangeStatus}
            width="288px"
            height={44}
            containerMarginBottom="0px"
            onEnter={handleApply}
          />
          <div style={{ width: '100%' }} />

          <DatePickerWithLabel
            name="selectedFromDate"
            label="Select From Date"
            value={selectedFromDate}
            dateFormat="MM/DD/YYYY"
            onChangeValue={onChangeDateFrom}
            width="160px"
            onEnter={handleApply}
          />
          <DatePickerWithLabel
            name="selectedToDate"
            label="Select To Date"
            value={selectedToDate}
            dateFormat="MM/DD/YYYY"
            onChangeValue={onChangeDateTo}
            width="160px"
            onEnter={handleApply}
          />
          <Stack
            flex
            gap={1}
            mb="5px"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="center"
          >
            <MButton
              type="submit"
              onClick={handleApply}
              disabled={isApplyButtonDisabled || isLoader}
            >
              Apply
            </MButton>
            <MButton variant="bordered" onClick={() => handleClearFilters()} disabled={isLoader}>
              Clear Filter
            </MButton>
          </Stack>
        </Stack>
        <Stack gap={2} pb={25} mt={4}>
          {!isLoader && bulkDocuments?.items?.length === 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 6 }}>
              <EmptyBlock
                title="No records"
                desc="Please use search and filters to display available notices"
              />
            </Box>
          )}
          {bulkDocuments?.items.map((item, index) => {
            return (
              <Accordion
                key={`${item.documentType}_${item.stateCode}_${index}`} // there is no id
                expanded={accordionsToggled.includes(index)}
                onChange={() => toggleAccodion(index)}
                sx={{
                  '&': {
                    border: `2px solid ${palette.additional.lines}`,
                    borderRadius: '16px !important',
                    boxShadow: 'none',
                  },
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDown />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Stack direction="row" alignItems="center" width="100%">
                    <MCheckbox
                      isChecked={checkedRows.includes(index)}
                      onChange={() => handleCheck(index)}
                      isDisabled={false}
                      indeterminate={!checkedRows.includes(index) && !!docs[index]?.length}
                      indeterminateIcon={<CheckboxIcon />}
                    />
                    <Typography variant="bodyL500">{item.courtName}</Typography>
                    <MButton
                      sx={{ ml: 'auto', mr: 3 }}
                      size="medium"
                      startIcon={<PrinterActive />}
                      onClick={(e) => printAsOne(e, item)}
                      disabled={checkIsDisabled}
                    >
                      Print
                    </MButton>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Table
                    isWithCheckbox
                    columns={tableColumns}
                    rows={item?.cases || []}
                    refAll={refAll.current[index]}
                    onCheckedRow={(cases, ids) => checkRow(cases, ids, item?.cases?.length, index)}
                    hideAllCheckbox
                    wasAllOperated={wasAllOperated}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })}
          {bulkDocuments?.totalPages > 1 && (
            <MPagination
              currentPage={bulkDocuments?.currentPage}
              count={bulkDocuments?.totalPages}
              handleChange={handleChangePage}
            />
          )}
        </Stack>
        <Box
          position="absolute"
          left="50%"
          bottom={0}
          sx={{ transform: 'translateX(-50%)' }}
          zIndex={10}
          width={1}
          p={4}
          bgcolor="#FFF"
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton
              size="large"
              sx={{ height: '48px', width: '144px' }}
              startIcon={<PrinterActive />}
              onClick={handlePrint}
              disabled={_.isEmpty(docs)}
            >
              Print
            </MButton>
          </Stack>
        </Box>
      </Container>
      <UploadDocumentsDialog isOpen={isUploadDocumentsOpen} onClose={handleUploadDocumentsClose} />
    </>
  );
};

export default BulkDocumentPrinting;
