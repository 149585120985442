import React, { useCallback, useMemo, createContext } from 'react';
import { PropTypes } from 'prop-types';

const defaultValue = {};

export const FormContext = createContext(defaultValue);

const Form = ({ form, onSubmit, children, onError }) => {
  const contextValue = useMemo(() => form ?? defaultValue, [form]);

  const handleSubmit = useCallback(
    (e) => {
      if (form) {
        if (onSubmit) form.handleSubmit(onSubmit, onError)(e);
      }
    },
    [form, onSubmit],
  );

  return (
    <form data-testid="form" noValidate onSubmit={handleSubmit}>
      <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
    </form>
  );
};

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Form.defaultProps = {
  onError: () => {},
};

export default Form;
