import { Box } from '@mui/material';
import moment from 'moment';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import { DeleteCell } from '../../../atoms/Table/TableCells/DeleteCell';
import { DropdownCell } from '../../../atoms/Table/TableCells/DropdownCell';
import { EditCell } from '../../../atoms/Table/TableCells/EditCell';
import { TextInputCell } from '../../../atoms/Table/TableCells/TextInputCell';
import CaseStatus from '../../../molecules/CaseStatus';
import { ReferenceNumberCell } from '../CaseSearch/CaseSearchCells';
import { TextCell, DateCell } from './TableCells';
import { CaseStatusDeleteCell } from './TableCells/CaseStatusDeleteCell';
import { DuplicateCell } from './TableCells/DuplicateCell';
import TypeCell from './TableCells/TypeCell';
import QuantityCell from './TableCells/QuantityCell';
import TextEllipsis from './TableCells/TextEllipsis';
import NonBillableCell from './TableCells/NonBillableCell';
import BillableCell from './TableCells/BillableCell';
import TextAttorneyCell from './TableCells/TextCell';
import EditableCell from './TableCells/EditableCell';

const date = 'mm/dd/yyyy';
export const TABLE_ROWS_COUNT = 20;

export const TABLE_COLUMNS = [
  {
    Header: 'Type',
    accessor: 'type',
    width: 4,
    Cell: TypeCell,
  },
  {
    Header: 'Qty',
    width: 10,
    accessor: 'quantity',
    Cell: QuantityCell,
  },
  {
    Header: 'Description',
    width: 20,
    accessor: 'description',
    Cell: TextEllipsis,
  },
  {
    width: 10,
    Header: 'Rate ($)',
    accessor: 'rate',
    Cell: TextAttorneyCell,
  },
  {
    Header: 'Non-billable ($)',
    accessor: 'nonBillable',
    width: 10,
    Cell: NonBillableCell,
  },
  {
    Header: 'Billable ($)',
    accessor: 'billable',
    width: 10,
    Cell: BillableCell,
  },
  {
    Header: 'Date ',
    accessor: 'date',
    width: 10,
    Cell: DateCell,
  },
  {
    Header: 'User',
    accessor: 'user',
    width: 15,
    Cell: TextAttorneyCell,
  },
  {
    Header: 'Category',
    accessor: 'category',
    width: 15,
    Cell: TextEllipsis,
  },
  {
    Header: '',
    show: true,
    accessor: 'control',
    width: 8,
    Cell: EditableCell,
  },
];

export const caseInformationForm = {
  boxTitle: 'Case Information',
  name: 'caseInformation',
  withAdd: false,
  inputBoxes: [
    {
      label: 'Case #',
      name: 'caseNumber',
      placeholder: 'xxxxxx',
      width: '199px',
      isMandatory: false,
    },
    {
      label: 'P.O./File #',
      name: 'poNumber',
      placeholder: 'P.O./File #',
      width: '199px',
      isMandatory: false,
    },
    {
      label: 'Envelope #',
      name: 'envelopeNumber',
      placeholder: 'xxxxxx',
      width: '199px',
    },
    {
      label: 'Notice Expires On',
      name: 'noticeExpiresOn',
      width: '226px',
      placeholder: date,
      isDatePicker: true,
      isMandatory: false,
      height: '48px',
    },
    {
      label: 'Eviction Granted On',
      name: 'evictionGrantedOn',
      width: '226px',
      placeholder: date,
      isDatePicker: true,
      isMandatory: false,
      height: '48px',
    },
    {
      label: 'Assigned Legal Assistant',
      name: 'assignedLegalAssistant',
      placeholder: "Enter legal assistant's name",
      width: '300px',
      isDropdown: true,
      isMandatory: false,
      withUpdate: true,
    },
  ],
};

export const nevadaRelatedCasesColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    width: 13,
    Cell: ReferenceNumberCell,
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    width: 10,
    Cell: TextCell,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'opposingPartyName',
    width: 30,
    Cell: TextCell,
  },
  {
    Header: 'Apartment #',
    accessor: 'apartmentNumber',
    width: 12,
    Cell: TextCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 18,
    Cell: CaseStatus,
  },
  {
    Header: 'Creation Date',
    accessor: 'creationDate',
    width: 13,
    Cell: DateCell,
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    width: 13,
    Cell: DateCell,
  },
  {
    Header: 'Duplicate',
    accessor: 'isPossibleDuplicate',
    width: 10,
    Cell: DuplicateCell,
  },
];

export const OTHER_THAN = 'Other-Than';
export const NON_PAYMENT = 'Non-Payment';

export const noticeRequestDropdownBlockToggler = [
  { id: 0, value: OTHER_THAN, label: OTHER_THAN },
  { id: 1, value: NON_PAYMENT, label: NON_PAYMENT },
];

const OTHER_THAN_NOTICE_BLOCK_DAMAGES = [
  {
    label: 'Other-Than Notice',
    isSubHeading: true,
  },
  {
    label: 'Other-Than Eviction',
    isLabelGroupHeading: true,
  },
  {
    label: 'Violation Type',
    name: 'damages.otherThanEviction.violationType',
    placeholder: 'Select violation type',
    width: '352px',
    isDropdown: true,
  },
  {
    label: 'Tenancy Type',
    name: 'damages.otherThanEviction.tenancyType',
    placeholder: 'Select tenancy type',
    width: '352px',
    isDropdown: true,
  },
  {
    label: 'Lease End Date',
    name: 'damages.otherThanEviction.leaseEndDate',
    placeholder: 'mm/dd/yyyy',
    width: '226px',
    height: '48px',
    isDatePicker: true,
    getDisabled: (form) =>
      form.watch('damages.otherThanEviction.tenancyType')?.value !== 'Fixed term',
  },
  {
    label: 'Tenancy Commenced On',
    name: 'damages.tenancyCommencedOn',
    placeholder: 'mm/dd/yyyy',
    width: '226px',
    height: '48px',
    isDatePicker: true,
  },
  {
    label: 'Awareness Date',
    name: 'damages.otherThanEviction.awarenessDate',
    placeholder: 'mm/dd/yyyy',
    width: '226px',
    height: '48px',
    isDatePicker: true,
  },
  {
    label: 'Entrance Date',
    name: 'damages.otherThanEviction.entranceDate',
    placeholder: 'mm/dd/yyyy',
    width: '226px',
    height: '48px',
    isDatePicker: true,
    getDisabled: (form) => form.watch('damages.otherThanEviction.hasUnknownEntranceDate') === true,
  },
  {
    label: 'Unknown',
    name: 'damages.otherThanEviction.hasUnknownEntranceDate',
    isCheckbox: true,
  },
  {
    isSpace: true,
  },
  {
    label: 'Defendant Notice Type',
    name: 'damages.otherThanEviction.defendantNoticeType',
    width: '727px',
    placeholder: 'Enter defendant notice type',
  },
  {
    label: 'Defendant Notice Date',
    name: 'damages.otherThanEviction.defendantNoticeDate',
    placeholder: 'mm/dd/yyyy',
    width: '226px',
    height: '48px',
    isDatePicker: true,
  },
  {
    label: 'UD Date Served',
    name: 'damages.otherThanEviction.udDateServed',
    placeholder: 'mm/dd/yyyy',
    width: '226px',
    height: '48px',
    isDatePicker: true,
  },
  {
    isSpace: true,
  },
  {
    label: 'In Default',
    name: 'damages.otherThanEviction.isInDefault',
    width: 'auto',
    radioButtonWidth: '96px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
  {
    label: 'Exemption From Stay',
    name: 'damages.otherThanEviction.isExemptionFromStay',
    width: 'auto',
    radioButtonWidth: '96px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
    getDisabled: (form) => !form.watch('damages.otherThanEviction.isInDefault'),
  },
  {
    label: 'Reason',
    name: 'damages.otherThanEviction.exemptionFromStayReason',
    width: '479px',
    placeholder: 'Enter a reason',
    getDisabled: (form) => !form.watch('damages.otherThanEviction.isInDefault'),
  },
  {
    isSpace: true,
  },
  {
    isStub: true,
    width: '200px',
  },
  {
    label: 'Moving To Rebut',
    name: 'damages.otherThanEviction.isMovingToRebut',
    width: 'auto',
    radioButtonWidth: '96px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
    getDisabled: (form) => !form.watch('damages.otherThanEviction.isInDefault'),
  },
  {
    label: 'Reason',
    name: 'damages.otherThanEviction.movingToRebutReason',
    width: '479px',
    placeholder: 'Enter a reason',
    getDisabled: (form) => !form.watch('damages.otherThanEviction.isInDefault'),
  },
];

const NON_PAYMENT_NOTICE_DAMAGES = [
  {
    label: 'Non-Payment',
    isSubHeading: true,
  },
  {
    label: 'Non-Payment Notice',
    isLabelGroupHeading: true,
  },
  {
    label: 'Current Amount Due',
    name: 'noticeRequest.nonPaymentNotice.currentAmountDue',
    placeholder: '-',
    width: '200px',
  },
  {
    label: 'Past Amount Due',
    name: 'noticeRequest.nonPaymentNotice.pastAmountDue',
    placeholder: '-',
    width: '200px',
  },
  {
    label: 'Late Fees',
    name: 'noticeRequest.nonPaymentNotice.lateFees',
    placeholder: '-',
    width: '200px',
  },
  {
    label: 'Last Payment Date',
    name: 'noticeRequest.nonPaymentNotice.lastPaymentDate',
    placeholder: 'mm/dd/yyyy',
    width: '200px',
    height: '48px',
    isDatePicker: true,
  },
  {
    label: 'Last Payment Amount',
    name: 'noticeRequest.nonPaymentNotice.lastPaymentAmount',
    placeholder: '-',
    width: '200px',
  },
  {
    label: 'Total Owed:',
    value: 'totalOwed',
    name: 'noticeRequest.nonPaymentNotice.totalOwed',
    placeholder: '00.00',
    isCalculator: true,
  },
  {
    label: 'Rental Details',
    isSubHeading: true,
  },
  {
    label: 'Tenancy Commenced On',
    name: 'damages.tenancyCommencedOn',
    placeholder: 'mm/dd/yyyy',
    width: '226px',
    height: '48px',
    isDatePicker: true,
  },
  {
    label: 'Rent Became Delinquent On',
    name: 'damages.nonPaymentEviction.rentBecameDelinquentOn',
    placeholder: 'mm/dd/yyyy',
    height: '48px',
    width: '226px',
    isDatePicker: true,
  },
  {
    label: 'Have Written Rental Agreement',
    name: 'damages.nonPaymentEviction.hasWrittenRentalAgreement',
    placeholder: '',
    width: 'auto',
    radioButtonWidth: '96px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true, isDefault: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Rent Subsidized',
    name: 'damages.nonPaymentEviction.hasRentSubsidized',
    width: 'auto',
    radioButtonWidth: '96px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true, isDefault: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Eviction From Stay',
    name: 'damages.nonPaymentEviction.hasEvictionFromStay',
    width: 'auto',
    radioButtonWidth: '96px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
  {
    label: 'Perform Lease Condition',
    name: 'damages.nonPaymentEviction.hasPerformLeaseCondition',
    width: 'auto',
    radioButtonWidth: '96px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
  {
    label: 'Rental Fees',
    isSubHeading: true,
  },
  {
    label: 'Periodic Rent',
    name: 'damages.nonPaymentEviction.periodicRent',
    placeholder: '-',
    width: '200px',
  },
  {
    label: 'Rent Deposit',
    name: 'damages.nonPaymentEviction.rentDeposit',
    width: '226px',
    placeholder: '-',
  },
  {
    label: 'Security Deposit',
    name: 'damages.nonPaymentEviction.securityDeposit',
    width: '226px',
    placeholder: '-',
  },
  {
    label: 'Cleaning Deposit',
    name: 'damages.nonPaymentEviction.cleaningDeposit',
    width: '226px',
    placeholder: '-',
  },
  {
    label: 'Owed Fees',
    isSubHeading: true,
  },
  {
    label: 'Owed Periodic Rent',
    name: 'damages.nonPaymentEviction.owedPeriodicRent',
    width: '226px',
    placeholder: '-',
  },
  {
    label: 'Owed Late Fee',
    name: 'damages.nonPaymentEviction.owedLateFee',
    width: '226px',
    placeholder: '-',
  },
  {
    label: 'Late Fee Assessed',
    name: 'damages.nonPaymentEviction.lateFeeAssessed',
    placeholder: 'mm/dd/yyyy',
    width: '226px',
    height: '48px',
    isDatePicker: true,
  },
  {
    label: 'Total Owed: ',
    name: 'damages.nonPaymentEviction.totalOwed',
    placeholder: '00.00',
    isCalculator: true,
    tooltip: 'Owed Total Due and Delinquent.',
  },
];

const getCommonFormDamages = (blockSelected) => {
  const noticeFee =
    blockSelected === NON_PAYMENT
      ? {
          label: 'Non-Payment Notice Fee',
          name: 'noticeRequest.associatedFees.nonPaymentNoticeFee',
          placeholder: '0',
          width: '290px',
          isMandatory: true,
        }
      : {
          label: 'Other-Than Notice Fee',
          name: 'noticeRequest.associatedFees.otherThanNoticeFee',
          placeholder: '0',
          width: '290px',
          isMandatory: true,
        };
  return [
    {
      label: 'Court Fees',
      isSubHeading: true,
    },
    {
      label: 'Attorney Fee - Summary Eviction',
      name: 'damages.courtFees.attorneyFeeSummaryEviction',
      placeholder: '0',
      width: '290px',
      isMandatory: true,
    },
    {
      label: 'Court Filing Fee',
      name: 'damages.courtFees.courtFilingFee',
      placeholder: '0',
      width: '290px',
      isMandatory: true,
    },
    {
      label: 'Constable Fee',
      name: 'damages.courtFees.constableFee',
      placeholder: '0',
      width: '290px',
      isMandatory: true,
    },
    {
      label: 'Rescission Fee',
      name: 'damages.courtFees.rescissionFee',
      placeholder: '0',
      width: '290px',
      isMandatory: true,
    },
    {
      label: 'Preparation Fee',
      name: 'damages.courtFees.preparationFee',
      placeholder: '0',
      width: '290px',
      isMandatory: true,
    },
    {
      label: 'Dismissal Fee',
      name: 'damages.courtFees.dismissalFee',
      placeholder: '0',
      width: '290px',
      isMandatory: true,
    },
    noticeFee,
    {
      label: 'Mileage',
      name: 'noticeRequest.associatedFees.milage',
      placeholder: '-',
      width: '290px',
    },
    {
      isSpace: true,
    },
    {
      label: 'Mediation',
      isSubHeading: true,
    },
    {
      label: 'Mediation Requested',
      name: 'damages.mediation.hasMediationRequested',
      width: 'auto',
      radioButtonWidth: '96px',
      isRadioButtons: true,
      isDisabled: true,
      data: [
        { id: 0, label: 'Yes', value: true },
        { id: 1, label: 'No', value: false, isDefault: true },
      ],
    },
    {
      label: 'Mediation Mode',
      name: 'damages.mediation.mediationMode',
      width: '352px',
      placeholder: 'Telephonic and video conference',
      getDisabled: (form) => !form.watch('damages.mediation.hasMediationRequested'),
    },
    {
      label: 'Authorized Contact',
      isGrouppedCheckboxes: true,
      fields: [
        {
          label: 'Attorney',
          name: 'damages.mediation.hasAuthorizedContactAttorney',
          isCheckbox: true,
          defaultValue: true,
        },
        {
          label: 'Plaintiff',
          name: 'damages.mediation.hasAuthorizedContactPlaintiff',
          isCheckbox: true,
          defaultValue: true,
        },
      ],
    },
    {
      isSpace: true,
      label: 'spaceAfterZipCode',
    },
    {
      label: 'Client Representative',
      name: 'damages.mediation.clientRepresentative',
      placeholder: 'Enter client representative',
      width: '304px',
    },
    {
      isSpace: true,
      label: 'spaceAfterClientRepresentative',
    },
    {
      label: 'Client Phone Number',
      name: 'damages.mediation.clientPhoneNumber',
      placeholder: '+1 (480) xx xx xx xx',
      width: '304px',
    },
    {
      label: 'Client Email',
      name: 'damages.mediation.clientEmail',
      placeholder: 'Enter email',
      width: '414px',
    },
    {
      label: 'Client Mailing Address',
      name: 'damages.mediation.clientMailingAddress',
      placeholder: 'Enter mailing address',
      width: '546px',
    },
  ];
};

export const caseInformationBoxes = [
  {
    boxTitle: 'Procedural History',
    name: 'proceduralHistory',
    withAdd: true,
    inputBoxes: (_, caseType) => {
      return caseType === 'Eviction Matter'
        ? [
            {
              label: 'Assigned Attorney',
              name: 'proceduralHistoryFields.assignedAttorney',
              placeholder: "Enter attorney's name",
              width: '477px',
              isDropdown: true,
              isMandatory: false,
            },
            {
              label: 'Hearing Type',
              name: 'proceduralHistoryFields.hearingType',
              placeholder: 'Default option',
              width: '477px',
              isDropdown: true,
              isMandatory: true,
            },
            {
              label: 'Comment',
              name: 'proceduralHistoryFields.comment',
              placeholder: 'Enter comment',
              width: '476px',
              isMandatory: false,
            },
            {
              isSpace: true,
            },
            {
              label: 'Court Date',
              name: 'proceduralHistoryFields.courtDate',
              width: '226px',
              placeholder: date,
              isDatePicker: true,
              isMandatory: true,
              height: '48px',
            },
            {
              label: 'Court Time',
              name: 'proceduralHistoryFields.courtTime',
              placeholder: 'hh:mm',
              width: '226px',
              height: '48px',
              isTimePicker: true,
              isMandatory: true,
            },
            {
              label: 'Dismissed by Judge',
              name: 'proceduralHistoryFields.dismissedByJudge',
              isCheckbox: true,
            },
          ]
        : [
            {
              label: 'Assigned Attorney',
              name: 'proceduralHistoryFields.assignedAttorney',
              placeholder: "Enter attorney's name",
              width: '477px',
              isDropdown: true,
              isMandatory: false,
            },
            {
              label: 'Hearing Type',
              name: 'proceduralHistoryFields.hearingType',
              placeholder: 'Default option',
              width: '477px',
              isDropdown: true,
              isMandatory: true,
            },
            {
              isSpace: true,
            },
            {
              label: 'Court Date',
              name: 'proceduralHistoryFields.courtDate',
              width: '226px',
              placeholder: date,
              isDatePicker: true,
              isMandatory: true,
              height: '48px',
            },
            {
              label: 'Court Time',
              name: 'proceduralHistoryFields.courtTime',
              placeholder: 'hh:mm',
              width: '226px',
              height: '48px',
              isTimePicker: true,
              isMandatory: true,
            },
            {
              label: 'Dismissed by Judge',
              name: 'proceduralHistoryFields.dismissedByJudge',
              isCheckbox: true,
            },
          ];
    },
  },
  {
    boxTitle: 'Case Status',
    name: 'caseStatus',
    withAdd: true,
    inputBoxes: [
      {
        label: 'Status',
        name: 'caseStatusFields.caseStatus',
        placeholder: 'Enter status name',
        width: '311px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'Comment',
        name: 'caseStatusFields.comment',
        tooltipText: 'Comments are visible to clients.',
        placeholder: 'Enter comment',
        width: '533px',
      },
    ],
  },
  {
    boxTitle: 'Related Cases',
    name: 'relatedCases',
    inputBoxes: [
      {
        isRelatedCasesTable: true,
        columns: nevadaRelatedCasesColumns,
      },
    ],
  },
  {
    boxTitle: 'Damages',
    name: 'noticeRequest&damages',
    withUpdate: true,
    inputBoxes(blockSelected = noticeRequestDropdownBlockToggler[0].value) {
      const cells =
        blockSelected === NON_PAYMENT
          ? NON_PAYMENT_NOTICE_DAMAGES
          : OTHER_THAN_NOTICE_BLOCK_DAMAGES;
      const commonFormDamages = getCommonFormDamages(blockSelected);
      const items = [...commonFormDamages, ...cells];
      return [
        {
          label: '',
          name: 'noticeRequest.noticeRequestType',
          placeholder: '',
          width: '290px',
          isDropdown: true,
        },
        ...items,
      ];
    },
  },
  {
    boxTitle: 'Violation(s)',
    name: 'violations',
    withAdd: true,
    inputBoxes: [
      {
        label: 'Notice Type',
        name: 'violationsFields.noticeType',
        placeholder: 'Select notice type',
        width: '477px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'Date Served',
        name: 'violationsFields.dateServed',
        placeholder: date,
        width: '226px',
        height: '48px',
        isMandatory: true,
        isDatePicker: true,
      },
      {
        label: 'Signed Date',
        name: 'violationsFields.signedDate',
        placeholder: date,
        width: '226px',
        height: '48px',
        isMandatory: false,
        isDatePicker: true,
      },
      {
        label: 'Delivered By',
        name: 'violationsFields.deliveredBy',
        placeholder: 'Personally Served',
        width: '352px',
      },
      {
        label: 'Description',
        name: 'violationsFields.description',
        width: '727px',
        placeholder: 'Add comments',
        height: '48px',
      },
      {
        label: 'Process Server',
        name: 'violationsFields.processServer',
        width: '352px',
        placeholder: 'Add process server',
        height: '48px',
      },
    ],
  },
];

export const proceduralHistoryColumns = [
  {
    Header: 'Assigned Attorney',
    accessor: 'assignedAttorney',
    width: 20,
    edit: true,
    Cell: DropdownCell,
    options: 'attorneys',
    saveOnBlur: true,
  },
  {
    Header: 'Hearing Type',
    accessor: 'hearingType',
    width: 20,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDate',
    width: 20,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('L') : '',
  },
  {
    Header: 'Court Time',
    accessor: 'courtTime',
    width: 20,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('LT') : '',
  },

  {
    Header: 'Status',
    width: 15,
    accessor: 'status',
    Cell: CaseStatus,
  },
  {
    Header: '',
    accessor: 'control',
    width: 6,
    minWidth: '48px',
    Cell: (props) => {
      const canDelete = useAllowed([rolesDB.Admin]);
      return (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {canDelete && <DeleteCell {...props} />}
        </Box>
      );
    },
  },
];

export const proceduralHistoryColumnsEvictionMatter = [
  {
    Header: 'Assigned Attorney',
    accessor: 'assignedAttorney',
    width: 20,
    edit: true,
    Cell: DropdownCell,
    options: 'attorneys',
    saveOnBlur: true,
  },
  {
    Header: 'Hearing Type',
    accessor: 'hearingType',
    width: 20,
  },
  {
    Header: 'Court Date',
    accessor: 'courtDate',
    width: 20,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('L') : '',
  },
  {
    Header: 'Court Time',
    accessor: 'courtTime',
    width: 15,
    Cell: ({ row: { original } }) =>
      original.courtDateTime ? moment(original.courtDateTime).format('LT') : '',
  },
  {
    Header: 'Status',
    width: 10,
    accessor: 'status',
    Cell: CaseStatus,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 10,
  },
  {
    Header: '',
    accessor: 'control',
    width: 6,
    minWidth: '48px',
    Cell: (props) => {
      const canDelete = useAllowed([rolesDB.Admin]);
      return (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {canDelete && <DeleteCell {...props} />}
        </Box>
      );
    },
  },
];

export const caseStatusColumns = [
  {
    Header: 'Status',
    accessor: 'status',
    width: 24,
    Cell: CaseStatus,
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    width: 22,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Updated By',
    accessor: 'updatedBy',
    width: 22,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    width: 24,
  },
  {
    Header: '',
    accessor: 'control',
    width: 8,
    minWidth: 80,
    Cell: (props) => {
      return <CaseStatusDeleteCell {...props} />;
    },
  },
];

export const violationColumns = [
  {
    Header: 'Selected Violations',
    accessor: 'noticeType',
    width: 240,
  },
  {
    Header: 'Date Served',
    accessor: 'dateServed',
    width: 142,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  /*
   *{
   *  Header: 'Signed Date',
   *  accessor: 'signedDate',
   *  width: 142,
   *  Cell: ({ value }) => (value ? moment(value).format('L') : ''),
   *},
   */
  {
    Header: 'Delivered By',
    accessor: 'deliveredBy',
    width: 188,
  },
  {
    Header: 'Process Server',
    accessor: 'processServer',
    width: 188,
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: 400,
    edit: true,
    Cell: TextInputCell,
  },
  {
    width: 60,
    Header: '',
    accessor: 'control',
    Cell: EditCell,
  },
];

export const violationTypes = [
  { label: '-', value: null, id: 0 },
  { label: 'Nuisance Business', value: 'Nuisance Business', id: 1 },
  { label: 'Waste Business', value: 'Waste Business', id: 2 },
  { label: 'Assigning Business', value: 'Assigning Business', id: 3 },
  { label: 'Subletting Business', value: 'Subletting Business', id: 4 },
  { label: 'Unlawful Business', value: 'Unlawful Business', id: 5 },
  { label: 'Controlled Substance Violation', value: 'Controlled Substance Violation', id: 6 },
  { label: 'Perform Lease Condition', value: 'Perform Lease Condition', id: 7 },
  { label: 'Tenancy-At-Will', value: 'Tenancy-At-Will', id: 8 },
  { label: 'No Cause', value: 'No Cause', id: 9 },
  { label: 'Unlawful Detainer', value: 'Unlawful Detainer', id: 10 },
  { label: '5 Day to Quit', value: '5 Day to Quit', id: 11 },
];

export const tenancyTypes = [
  { label: '-', value: null, id: 0 },
  { label: 'Periodic month-to-month', value: 'Periodic month-to-month', id: 1 },
  { label: 'Periodic week-to-week', value: 'Periodic week-to-week', id: 2 },
  { label: 'Fixed term', value: 'Fixed term', id: 3 },
  { label: 'Tenancy-at-will', value: 'Tenancy-at-will', id: 4 },
  { label: 'Other', value: 'Other', id: 5 },
];

export const hearingTypes = [
  { label: 'Initial Hearing', value: 'Initial Hearing', id: 1 },
  { label: 'Bench Trial', value: 'Bench Trial', id: 2 },
  { label: 'Jury Trial', value: 'Jury Trial', id: 3 },
  { label: 'Oral Argument', value: 'Oral Argument', id: 4 },
  { label: 'Status Conference', value: 'Status Conference', id: 5 },
  { label: 'Continuance', value: 'Continuance', id: 6 },
  { label: 'Reset', value: 'Reset', id: 7 },
  { label: 'Appeal', value: 'Appeal', id: 8 },
  { label: 'Evidentiary Hearing', value: 'Evidentiary Hearing', id: 9 },
  { label: 'Case Stayed', value: 'Case Stayed', id: 10 },
  { label: 'Mediation', value: 'Mediation', id: 11 },
];
