import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SubPageHeading from '../../../organisms/SubPageHeading';
import {
  subPageHeading,
  subPageNavWithEvictionMatterArizona,
  subPageNavWithEvictionMatterNevada,
  subPageNavWithCivilMatterArizona,
  subPageNavWithCivilMatterNevada,
} from './EditCase.constants';
import { CIVIL_MATTER_CASE } from '../../../../global/Case.constants';
import { apiClient } from '../../../../lib/apiClient';
import EstimatedFeesBlock from '../../../molecules/EstimatedFeesBlock/EstimatedFeesBlock';

const EditCase = () => {
  const {
    state: { id, navPage, subNav, defendantName = '', refNum = '', stateCode, caseType },
  } = useLocation();

  const [estimatedFeesAmount, setEstimatedFeesAmount] = useState(null);

  const subPageNavExtended = useMemo(() => {
    const isArizona = stateCode === 'AZ';
    const isNevada = stateCode === 'NV';
    const isCivilMatter = caseType === CIVIL_MATTER_CASE;

    let subNavigation = null;

    if (isArizona) {
      subNavigation = isCivilMatter
        ? subPageNavWithCivilMatterArizona
        : subPageNavWithEvictionMatterArizona;
    }

    if (isNevada) {
      subNavigation = isCivilMatter
        ? subPageNavWithCivilMatterNevada
        : subPageNavWithEvictionMatterNevada;
    }

    return subNavigation.map((item) => ({
      ...item,
      state: {
        stateCode,
        navPage,
        subNav,
        id,
        defendantName,
        refNum,
        caseType,
      },
    }));
  }, [stateCode, id, caseType, defendantName]);

  useEffect(() => {
    const fetcher = async () => {
      const apiPath =
        stateCode === 'AZ' ? `/api/arizonaCases/${id}/fees` : `/api/nevadaCases/${id}/fees`;

      const {
        data: { result },
      } = await apiClient.get(apiPath);

      setEstimatedFeesAmount(result?.estimatedAmount ?? null);
    };
    fetcher();
  }, [id, stateCode]);

  return (
    <>
      <SubPageHeading
        subPageHeading={
          defendantName && refNum ? `Ref. # ${refNum} | ${defendantName}` : subPageHeading
        }
        subPageHeadingRight={<EstimatedFeesBlock estimatedAmount={estimatedFeesAmount} />}
        subPageNav={subPageNavExtended}
        nav={{
          to: '/db/processing/casesearch',
          state: {
            navPage: 'Processing',
            subNav: 'Cases',
            stateCode,
          },
        }}
      />
      <Outlet />
    </>
  );
};

export default EditCase;
