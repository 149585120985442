import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack } from '@mui/material';
import moment from 'moment';
import Tag from '../../../../atoms/Tag';
import { ALL_STATUSES } from '../../../../../global/constants';
import { palette } from '../../../../../theme/default';

const formatStatusTime = (time) => {
  return time ? `${moment(time).format('L')} ${moment(time).format('LT')}` : '';
};

/* eslint-disable no-unused-vars */
export const SentPrintedCell = ({
  value,
  row: {
    original: { wasPrinted, wasSent, printDateTime, sendDateTime },
  },
}) => {
  let receivedStatus = [];

  if (wasSent && wasPrinted) {
    if (printDateTime === sendDateTime) {
      receivedStatus = [{ badge: 'Printed & Sent', dateAndTime: formatStatusTime(printDateTime) }];
    } else {
      receivedStatus = [
        { badge: 'Printed', dateAndTime: formatStatusTime(printDateTime) },
        { badge: 'Sent', dateAndTime: formatStatusTime(sendDateTime) },
      ];
    }
  }

  if (!wasSent && !wasPrinted) {
    receivedStatus = [{ badge: 'Unprinted & Unsent', dateAndTime: null }];
  }

  if (!wasSent && wasPrinted) {
    receivedStatus = [
      { badge: 'Unsent', dateAndTime: '' },
      { badge: 'Printed', dateAndTime: formatStatusTime(printDateTime) },
    ];
  }

  if (wasSent && !wasPrinted) {
    receivedStatus = [
      { badge: 'Sent', dateAndTime: formatStatusTime(sendDateTime) },
      { badge: 'Unprinted', dateAndTime: null },
    ];
  }

  if (wasSent === undefined && wasPrinted) {
    receivedStatus = [{ badge: 'Printed', dateAndTime: formatStatusTime(printDateTime) }];
  }

  if (wasSent === undefined && !wasPrinted) {
    receivedStatus = [{ badge: 'Unprinted', dateAndTime: formatStatusTime(printDateTime) }];
  }

  const backgroundColor = useMemo(() => {
    const noColors = [palette.statuses.transparent, palette.statuses.transparent];

    if (!receivedStatus.length) {
      return noColors;
    }
    const [firstBadge, secondBadge] = receivedStatus;

    const statusOption = ALL_STATUSES.filter(
      (o) => o.value === firstBadge?.badge || o.value === secondBadge?.badge,
    );

    if (statusOption) {
      const [firstStatus, secondStatus] = statusOption;
      if (secondStatus)
        return [
          {
            ...firstStatus,
            dateAndTime: receivedStatus.find((item) => item.badge === firstStatus.label)
              .dateAndTime,
          },
          {
            ...secondStatus,
            dateAndTime: receivedStatus.find((item) => item.badge === secondStatus.label)
              .dateAndTime,
          },
        ];
      return [{ ...firstStatus, dateAndTime: firstBadge.dateAndTime }];
    }

    return [palette.statuses.transparent, palette.statuses.transparent];
  }, [receivedStatus]);

  return (
    <Stack gap={1} display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
      {backgroundColor.map((item) => (
        <Stack
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Tag backgroundColor={item?.color} text={item.label} />
          <Typography variant="body2" fontSize="10px" pl={1}>
            {item.dateAndTime}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

SentPrintedCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      wasPrinted: PropTypes.bool,
      wasSent: PropTypes.bool,
      printDateTime: PropTypes.string,
      sendDateTime: PropTypes.string,
    }),
  }).isRequired,
};
