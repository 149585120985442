export const stateCodesOptions = [
  { value: 'AZ', label: 'AZ' },
  { value: 'NV', label: 'NV' },
];

export const CourtScheduleFormBoxes = [
  {
    name: 'periods',
    inputBoxes: [
      {
        label: 'From Date',
        name: 'fromDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'To Date',
        name: 'toDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
    ],
  },
  {
    name: 'dataFields',
    inputBoxes: [
      {
        label: 'State',
        name: 'stateCode',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'County',
        name: 'county',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'Courts',
        name: 'courts',
        placeholder: '',
        width: '226px',
        isCheckboxSelect: true,
        showSelectAll: true,
        isMandatory: true,
      },
    ],
  },
];

export const CourtScheduleColumns = [
  {
    Header: 'Community Name',
    accessor: 'communityName',
  },
  {
    Header: 'Opposing Party',
    accessor: 'opposingParty',
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];
