const AZ = [
  {
    label: 'Fees And Costs',
    isSubHeadingWithRevert: true,
  },
  {
    label: 'Justice Court',
    isSubHeading: true,
  },
  {
    label: 'Court Filing Fee',
    name: 'justiceFeesAndCosts.filingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Judgment Fee',
    name: 'justiceFeesAndCosts.judgementFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Judgment Fee Immediate',
    name: 'justiceFeesAndCosts.judgementFeeImmediate',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Trial Fee',
    name: 'justiceFeesAndCosts.trialFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Dismissal Fee',
    name: 'justiceFeesAndCosts.dismissalFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Satisfaction Fee',
    name: 'justiceFeesAndCosts.satisfactionFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Writ Fee',
    name: 'justiceFeesAndCosts.writFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Dismissal Fee (Court Attorney Fees - Notice of Voluntary Dismissal by Judge)',
    name: 'justiceFeesAndCosts.dismissalFeeVoluntaryByJudge',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  {
    label: 'Dismissal Fee (Ex Parte Motion to Vacate Judgment)',
    name: 'justiceFeesAndCosts.dismissalFeeVacateJudgement',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  {
    label: 'Process Server Fee',
    name: 'justiceFeesAndCosts.processServerFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },

  {
    label: 'Notice Fee',
    name: 'justiceFeesAndCosts.noticeFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Preparation Fee',
    name: 'justiceFeesAndCosts.preparationFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Eviction Processing Fee',
    name: 'justiceFeesAndCosts.evictionProcessingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Trial Fee - Immediate',
    name: 'justiceFeesAndCosts.trialFeeImmediate',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Process Server Fee Billable',
    name: 'justiceFeesAndCosts.processServerFeeBillable',
    placeholder: '',
    width: 'auto',
    radioButtonWidth: '100px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true, isDefault: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Government Backed Loan',
    name: 'justiceFeesAndCosts.governmentBackedLoan',
    width: '240px',
    radioButtonWidth: '116px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
  {
    label: 'Superior Court',
    isSubHeading: true,
  },
  {
    label: 'Superior Court Filing Fee',
    name: 'superiorFeesAndCosts.filingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Superior Court Judgement Fee',
    name: 'superiorFeesAndCosts.judgementFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Superior Court Writ (Pima)',
    name: 'superiorFeesAndCosts.writPima',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Superior Court Writ of Restitution',
    name: 'superiorFeesAndCosts.writOfRestitution',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },

  {
    label: 'Superior Court Attorney Fees - Notice of Voluntary Dismissal by Judge',
    name: 'superiorFeesAndCosts.dismissalFeeVoluntaryByJudge',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  {
    label: 'Superior Court Attorney Fees - Notice of Voluntary Dismissal',
    name: 'superiorFeesAndCosts.dismissalFeeVoluntary',
    placeholder: '0',
    width: '605px',
    isMandatory: true,
  },
  {
    label: 'Process Server Fee',
    name: 'superiorFeesAndCosts.processServerFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },

  {
    label: 'Notice Fee',
    name: 'superiorFeesAndCosts.noticeFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Preparation Fee',
    name: 'superiorFeesAndCosts.preparationFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Eviction Processing Fee',
    name: 'superiorFeesAndCosts.evictionProcessingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Process Server Fee Billable',
    name: 'superiorFeesAndCosts.processServerFeeBillable',
    placeholder: '',
    width: 'auto',
    radioButtonWidth: '100px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true, isDefault: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  {
    label: 'Government Backed Loan',
    name: 'superiorFeesAndCosts.governmentBackedLoan',
    width: '240px',
    radioButtonWidth: '116px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
];

const NV = [
  {
    label: 'Fees And Costs',
    isSubHeadingWithRevert: true,
  },
  {
    label: 'Non-Payment Notice Fee',
    name: 'feesAndCosts.nonPaymentNoticeFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Other-Than Notice Fee',
    name: 'feesAndCosts.otherThanNoticeFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Mileage',
    name: 'feesAndCosts.milage',
    placeholder: '-',
    width: '290px',
  },
  {
    label: 'Attorney Fee-Summary Eviction',
    name: 'feesAndCosts.attorneyFeeSummaryEviction',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  { isSpace: true },
  {
    label: 'Court Filing Fee',
    name: 'feesAndCosts.courtFilingFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Constable Fee',
    name: 'feesAndCosts.constableFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Rescission Fee',
    name: 'feesAndCosts.rescissionFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Preparation Fee',
    name: 'feesAndCosts.preparationFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Dismissal Fee',
    name: 'feesAndCosts.dismissalFee',
    placeholder: '0',
    width: '290px',
    isMandatory: true,
  },
  {
    label: 'Government Backed Loan',
    name: 'governmentBackedLoan',
    width: '240px',
    radioButtonWidth: '116px',
    isRadioButtons: true,
    data: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false, isDefault: true },
    ],
  },
];

const getFeesAndCosts = ({ stateCode }) => {
  if (stateCode === 'AZ') return AZ;
  if (stateCode === 'NV') return NV;
  return [];
};

export default getFeesAndCosts;
