import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { ReactComponent as CheckCircleGreen } from '../../../../assets/icons/CheckCircleGreen.svg';

export const DuplicateOrRelatedCell = ({ value }) => {
  if (typeof value !== 'boolean' || !value) return null;

  return (
    <Box width="100%" display="flex" data-testid="duplicate_related_cell">
      <CheckCircleGreen />
    </Box>
  );
};

DuplicateOrRelatedCell.propTypes = {
  value: PropTypes.string.isRequired,
};
