import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const LinkCell = ({ value, row: { original } }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    return navigate('/db/admin/documents/document/history', {
      state: {
        navPage: 'Admin',
        subNav: 'Documents',
        subSubNavigation: 'Templates',
        subPageHeading: value,
        noticeType: original.noticeType,
        templateType: original.name,
        templateGroupId: original.templateGroupId,
        templateId: original.templateId,
        filters: original.filters,
        stateCode: original.stateCode,
        templateName: original.name,
      },
    });
  }, [original, navigate]);

  return (
    <Typography sx={{ cursor: 'pointer' }} variant="link" onClick={handleClick}>
      {value}
    </Typography>
  );
};

LinkCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      templateGroupId: PropTypes.string,
      stateCode: PropTypes.string,
      name: PropTypes.string,
      templateType: PropTypes.string,
      templateId: PropTypes.string,
      noticeType: PropTypes.string,
      filters: PropTypes.shape({}),
    }),
  }).isRequired,
};
