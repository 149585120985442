import { createTheme } from '@mui/material';
import { palette, typography } from './default';

export const theme = createTheme({
  palette,
  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          fontFamily: ["Plus Jakarta Sans", "sans-serif"].join(","),
          fontStyle: "normal",
          fontSize: 14,        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        // Disable ripple for jest tests
        disableRipple: !!process.env.JEST_WORKER_ID,
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 10000,
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: 10000,
        },
      },
    },
  },
});
