import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import ShadowBlock from '../../../atoms/ShadowBlock';
import { ReactComponent as DocumentText } from '../../../../assets/icons/DocumentText.svg';
import { ReactComponent as DocumentForward } from '../../../../assets/icons/DocumentForward.svg';
import { ReactComponent as DocumentCopy } from '../../../../assets/icons/DocumentCopy.svg';
import { palette } from '../../../../theme/default';
import {
  getAvailableRequestsNevada,
  setRequestLockout,
  setRequestCancellation,
  setRequestEviction,
  setRequestDismissal,
  setRequestLockoutCompleted,
  setRequestRescind,
} from '../../../../store/slices/cases/caseCpSlice';
import { LightTooltip } from '../../../atoms/MTooltip/MTooltip';
import ReasonOfDismissal from './ReasonOfDismissal';
import ConstableInstructions from '../Document/Modals/ConstableInstructions';
import MotionToRescind from '../Document/Modals/MotionToRescind';
import NotificationDialog from '../../../molecules/NotificationDialog';
import { formatUSD } from '../../../../utils/formatCurrency';

export const EVICTION_REQUEST_TYPE = 'eviction';
export const DISMISSAL_REQUEST_TYPE = 'dismissal';
export const RESCIND_REQUEST_TYPE = 'rescind';

const StyledWrapper = ({ title, icon, to, isActive, onClick, state, tooltip }) => {
  return (
    <LightTooltip title={!isActive && tooltip ? tooltip : null} placement="top">
      <Stack
        component={to && isActive ? Link : Box}
        to={to}
        sx={{
          textDecoration: 'none',
          cursor: isActive ? 'pointer' : 'not-allowed',
        }}
        width="calc((100% - 24px)/2)"
        maxWidth="400px"
        onClick={onClick}
        state={state || null}
      >
        <ShadowBlock>
          <Stack alignItems="center" spacing={3}>
            <Typography
              variant="h5"
              color={isActive ? 'text.secondary' : 'text.tertiary'}
              align="center"
              whiteSpace="pre-line"
            >
              {title}
            </Typography>
            {icon}
          </Stack>
        </ShadowBlock>
      </Stack>
    </LightTooltip>
  );
};

const RequestsNevada = () => {
  const {
    requestsNevada: {
      canRequestCancellation,
      canRequestDismissal,
      canRequestEviction,
      canRequestLockout,
      canRequestLockoutCompleted,
      canRequestRescind,
      hasMissedDocsForEviction,
      feeForRequestEviction,
      feeForRequestDismissal,
      feeForRequestRescind,
    },
  } = useSelector((state) => state.caseCp);

  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoader, setIsLoader] = useState(true);
  const [fees, setFees] = useState({});
  const [isFeesNotificationOpen, setIsFeesNotificationOpen] = useState(false);
  const [isReasonOfDismissalOpen, setIsReasonOfDismissalOpen] = useState(false);
  const [isConstableInstructionsOpen, setIsConstableInstructionsOpen] = useState(false);
  const [isMotionToRescindOpen, setIsMotionToRescindOpen] = useState(false);

  const loadRequests = () =>
    dispatch(getAvailableRequestsNevada(state.id))
      .unwrap()
      .then(() => {
        setIsLoader(false);
      });

  useEffect(() => {
    loadRequests();
  }, []);

  const handleOpenModal = (isActive, setModalOpenState) => () => {
    if (isActive) setModalOpenState(true);
  };

  const handleSetStatus = (isActive, request, isLink) => () => {
    if (isActive && !isLink && !isLoader) {
      setIsLoader(true);
      dispatch(request(state.id)).then(() => {
        loadRequests();
      });
    }
  };

  const handleSetStatusWithBody = (request, setModalOpenState) => async (data) => {
    const popupFields = Object.entries(data)
      .map(([key, value]) => {
        const convertValue = () => {
          if (typeof value === 'boolean') {
            return value ? 'True' : 'False';
          }
          return value;
        };
        return { key, value: convertValue() };
      })
      .filter((field) => field.value !== null);

    setIsLoader(true);
    await dispatch(
      request({
        caseId: state.id,
        popupFields,
      }),
    ).then(() => {
      loadRequests();
      setModalOpenState(false);
    });
  };

  const handleCloseReasonOfDismissModal = () => setIsReasonOfDismissalOpen(false);
  const handleCloseConstableInstructions = () => setIsConstableInstructionsOpen(false);
  const handleCloseMotionToRescind = () => setIsMotionToRescindOpen(false);
  const handleCloseFeesNotification = () => setIsFeesNotificationOpen(false);

  const getRequestFunc = (requestType) => {
    switch (requestType) {
      case EVICTION_REQUEST_TYPE: {
        if (hasMissedDocsForEviction) {
          return navigate('../requests/requesteviction', { state });
        }
        return handleSetStatus(canRequestEviction, setRequestEviction, hasMissedDocsForEviction);
      }
      case DISMISSAL_REQUEST_TYPE:
        return handleOpenModal(canRequestDismissal, setIsReasonOfDismissalOpen);
      case RESCIND_REQUEST_TYPE:
        return handleOpenModal(canRequestRescind, setIsMotionToRescindOpen);
      default:
        return null;
    }
  };

  const handleRequestClick = (requestType, requestFees) => {
    const getCanRequest = () => {
      switch (requestType) {
        case EVICTION_REQUEST_TYPE:
          return canRequestEviction;
        case RESCIND_REQUEST_TYPE:
          return canRequestRescind;
        case DISMISSAL_REQUEST_TYPE:
          return canRequestDismissal;
        default:
          return false;
      }
    };
    const canRequest = getCanRequest();
    if (requestFees && canRequest) {
      setFees({ requestFees, requestType });
      setIsFeesNotificationOpen(true);
      return;
    }

    getRequestFunc(requestType)();
  };

  const handleConfirmFeesNotification = () => {
    setIsFeesNotificationOpen(false);

    getRequestFunc(fees.requestType)();
  };

  const renderFeesMessage = () => {
    return (
      <Typography variant="bodyL500">
        {`This action will trigger ${formatUSD(fees.requestFees)} fee.`}
        <br />
        Would you like to proceed?
      </Typography>
    );
  };

  return (
    <Stack height="calc(100vh - 80px - 72px - 130px)" mx={4} direction="column">
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          m: 'auto',
          px: 4,
          width: 1,
          maxWidth: 1320,
        }}
      >
        {isLoader && (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            zIndex={10}
            transform="translate(-50%, -50%)"
          >
            <CircularProgress />
          </Box>
        )}
        <Stack
          rowGap={4}
          columnGap={3}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          flexWrap="wrap"
        >
          {!canRequestLockoutCompleted && (
            <StyledWrapper
              tooltip={
                !canRequestLockout
                  ? 'You can only request a lockout for a case where the eviction has been granted.'
                  : ''
              }
              title="Request Lockout"
              icon={
                <DocumentText
                  color={canRequestLockout ? palette.buttonPrimary.active : palette.text.tertiary}
                />
              }
              to=""
              onClick={handleOpenModal(canRequestLockout, setIsConstableInstructionsOpen)}
              isActive={!isLoader && canRequestLockout}
            />
          )}

          {canRequestLockoutCompleted && (
            <StyledWrapper
              title="Request Lockout Completed"
              icon={
                <DocumentText
                  color={
                    canRequestLockoutCompleted
                      ? palette.buttonPrimary.active
                      : palette.text.tertiary
                  }
                />
              }
              to=""
              onClick={handleSetStatus(canRequestLockoutCompleted, setRequestLockoutCompleted)}
              isActive={!isLoader && canRequestLockoutCompleted}
            />
          )}

          <StyledWrapper
            title="Request Cancellation"
            icon={
              <DocumentForward
                color={
                  canRequestCancellation ? palette.buttonPrimary.active : palette.text.tertiary
                }
              />
            }
            to=""
            onClick={handleSetStatus(canRequestCancellation, setRequestCancellation)}
            isActive={!isLoader && canRequestCancellation}
          />

          <StyledWrapper
            title="Request Dismissal"
            icon={
              <DocumentForward
                color={canRequestDismissal ? palette.buttonPrimary.active : palette.text.tertiary}
              />
            }
            to=""
            // onClick={handleOpenModal(canRequestDismissal, setIsReasonOfDismissalOpen)}
            onClick={() => handleRequestClick(DISMISSAL_REQUEST_TYPE, feeForRequestDismissal)}
            isActive={!isLoader && canRequestDismissal}
          />

          <StyledWrapper
            tooltip={
              !canRequestEviction ? 'An eviction request is not available at this time.' : ''
            }
            title="Request Eviction"
            icon={
              <DocumentCopy
                color={canRequestEviction ? palette.buttonPrimary.active : palette.text.tertiary}
              />
            }
            to=""
            // to={hasMissedDocsForEviction ? 'requesteviction' : ''}
            // onClick={handleSetStatus(
            //   canRequestEviction,
            //   setRequestEviction,
            //   hasMissedDocsForEviction,
            // )}
            onClick={() => handleRequestClick(EVICTION_REQUEST_TYPE, feeForRequestEviction)}
            state={state}
            isActive={!isLoader && canRequestEviction}
          />

          <StyledWrapper
            tooltip=""
            title="Request Rescind"
            icon={
              <DocumentText
                color={canRequestRescind ? palette.buttonPrimary.active : palette.text.tertiary}
              />
            }
            to=""
            // onClick={handleOpenModal(canRequestRescind, setIsMotionToRescindOpen)}
            onClick={() => handleRequestClick(RESCIND_REQUEST_TYPE, feeForRequestRescind)}
            isActive={canRequestRescind}
          />
        </Stack>
      </Container>
      <ReasonOfDismissal
        isOpen={isReasonOfDismissalOpen}
        onClose={handleCloseReasonOfDismissModal}
        handleConfirm={handleSetStatusWithBody(
          setRequestDismissal,
          handleCloseReasonOfDismissModal,
        )}
      />
      <ConstableInstructions
        isOpen={isConstableInstructionsOpen}
        handleClose={handleCloseConstableInstructions}
        handleConfirm={handleSetStatusWithBody(setRequestLockout, handleCloseConstableInstructions)}
      />
      <MotionToRescind
        isOpen={isMotionToRescindOpen}
        handleClose={handleCloseMotionToRescind}
        handleConfirm={handleSetStatusWithBody(setRequestRescind, handleCloseMotionToRescind)}
      />
      <NotificationDialog
        title="Fee will be applied"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Proceed"
        onClose={handleCloseFeesNotification}
        onConfirm={handleConfirmFeesNotification}
        isOpen={isFeesNotificationOpen}
        content={renderFeesMessage()}
        width={528}
      />
    </Stack>
  );
};

StyledWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  state: PropTypes.shape({
    defendantName: PropTypes.string,
    id: PropTypes.number,
    navPage: PropTypes.string,
    refNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stateCode: PropTypes.string,
    subNav: PropTypes.string,
  }),
};

StyledWrapper.defaultProps = {
  isActive: false,
  onClick: () => null,
  state: null,
  tooltip: '',
};

export default RequestsNevada;
