import React from 'react';
import Dropzone from 'react-dropzone';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ReactComponent as Upload } from '../../../assets/icons/document-upload.svg';
import notificationUtils from '../../../utils/notificationUtils';
import { SUPPORTED_FILE_TYPES } from './Dropzone.constants';

const StyledDropbox = styled(Box)(({ theme }) => ({
  border: '1px dashed',
  backgroundColor: theme.palette.buttonSecondary.pressedBg,
  borderColor: theme.palette.buttonSecondary.hoverBorder,
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}));

const DropZone = ({
  onDrop,
  dropText,
  actionText,
  accept,
  maxSize,
  multiple,
  showErrors,
  customContent,
}) => {
  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (showErrors && rejectedFiles.length) {
      rejectedFiles.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          if (error.code === 'file-invalid-type') {
            notificationUtils.error(`File type is not supported - ${file.name}`, {
              autoHideDuration: 5000,
            });
          }
          if (error.code === 'file-too-large') {
            notificationUtils.error(`File is too large - ${file.name}`, { autoHideDuration: 5000 });
          }
        });
      });
    }
    onDrop(acceptedFiles, rejectedFiles);
  };

  return (
    <Dropzone onDrop={handleDrop} accept={accept} maxSize={maxSize} multiple={multiple}>
      {({ getRootProps, getInputProps }) => (
        <StyledDropbox {...getRootProps()} data-testid="dropzone_dropbox">
          <input {...getInputProps()} data-testid="dropzone_input" />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              my: 6,
            }}
          >
            <Upload />
            {customContent ? (
              <>{customContent} </>
            ) : (
              <>
                <Typography
                  variant="bodyL500"
                  color="text.secondary"
                  sx={{ my: 1 }}
                  data-testid="dropzone_drop_text"
                >
                  {dropText}
                </Typography>
                <Typography
                  variant="bodyL"
                  sx={{
                    color: 'buttonPrimary.active',
                    textDecoration: 'underline',
                  }}
                  data-testid="dropzone_action_text"
                >
                  {actionText}
                </Typography>
              </>
            )}
          </Box>
        </StyledDropbox>
      )}
    </Dropzone>
  );
};

DropZone.propTypes = {
  accept: PropTypes.shape({}),
  customContent: PropTypes.shape({}),
  onDrop: PropTypes.func.isRequired,
  dropText: PropTypes.string,
  actionText: PropTypes.string,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  showErrors: PropTypes.bool,
};

DropZone.defaultProps = {
  accept: SUPPORTED_FILE_TYPES,
  customContent: undefined,
  dropText: 'Drop your document here, or select',
  actionText: 'Click to browse',
  multiple: true,
  maxSize: 1024 * 1024 * 20,
  showErrors: true,
};

export default DropZone;
