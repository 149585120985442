import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { palette } from '../../../../../../theme/default';
import { ReactComponent as WarningIcon } from '../../../../../../assets/icons/InfoCircleRed.svg';
import { LightTooltip } from '../../../../../atoms/MTooltip/MTooltip';

export const PrefileDateCell = (props) => {
  const {
    cell: { value },
    isDateInThePast,
  } = props;
  return (
    <Stack direction="row" sx={{ position: 'relative', width: '150px' }}>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          color: palette.text.primary,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {moment(value).format('L')}
      </Typography>
      {isDateInThePast && (
        <LightTooltip
          title={
            <>
              That prefile was not printed/
              <br />
              completed by the deadline date.
            </>
          }
          placement="top"
        >
          <WarningIcon style={{ position: 'absolute', top: 0, right: 0 }} />
        </LightTooltip>
      )}
    </Stack>
  );
};

PrefileDateCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  isDateInThePast: PropTypes.bool,
};

PrefileDateCell.defaultProps = {
  isDateInThePast: false,
};
