import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { palette } from '../../../theme/default';

const Calculator = ({ label, value, tooltip }) => {
  return (
    <Box display="flex" mt={3}>
      <Typography variant="h5" mr={3} color={palette.text.secondary} data-testid="calculator_label">
        {label}
      </Typography>
      <Typography display="flex" alignItems="center" variant="h5" data-testid="calculator_value">
        {value || '00.00'} {tooltip}
      </Typography>
    </Box>
  );
};

Calculator.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  tooltip: PropTypes.node,
};

Calculator.defaultProps = {
  tooltip: PropTypes.node,
};

export default Calculator;
