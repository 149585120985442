/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';
import { dateTransformer } from '../../lib/libUtils';
import { convertToQueryString } from '../../utils/convertToQueryString';
import {
  ATTORNEY_REVIEW,
  CIVIL_MATTERS,
  DEADLINES_DUE_DATES,
} from '../../components/pages/Dashboard/DashboardAttorney/DashboardAttorney.constants';

export const getSettings = createAsyncThunk('attorneyDashboard/getSettings', async (userId) => {
  const { data } = await apiClient.get(`/api/userSettings/${userId}`);

  return data;
});

export const saveRefreshSettings = createAsyncThunk(
  'attorneyDashboard/saveRefreshSettings',
  async ({ userId, settings }) => {
    const { data } = await apiClient.put(`/api/userSettings/refreshTime/${userId}`, settings);

    return data;
  },
);

export const getCasesAssignedToAttorney = createAsyncThunk(
  'attorneyDashboard/getCasesAssignedToAttorney',
  async ({ period = 'daily', ...params }) => {
    const { data } = await apiClient.get(
      `/api/dashboards/charts/assignedAttorneys/${period}?${new URLSearchParams(
        dateTransformer(params),
      ).toString()}`,
    );

    return data;
  },
);

export const getListOfCivilMattersAlerts = createAsyncThunk(
  'attorneyDashboard/getListOfCivilMattersAlerts',
  async (params = {}) => {
    const paramsString = new URLSearchParams(params).toString();
    const { data } = await apiClient.get(
      `/api/dashboards/charts/assignedAttorneys/alerts?${paramsString}`,
    );
    return data;
  },
);

const getUrlByCard = (card) => {
  switch (card) {
    case DEADLINES_DUE_DATES:
      return '/api/dashboards/attorney/cards/timelines';
    case CIVIL_MATTERS:
      return '/api/dashboards/attorney/cards/civilMatters';
    default:
      return '/api/dashboards/attorney/cards/evictionMatters/byAttorneyReview';
  }
};

export const getDashboardStats = createAsyncThunk(
  'attorneyDashboard/getDashboardStats',
  async ({ stateCode, cards }) => {
    const data = await Promise.all(
      cards.map((card) =>
        apiClient
          .get(
            `${getUrlByCard(card.id)}?${convertToQueryString({
              onlyTotal: true,
              stateCode,
              attorney: 'All Attorneys',
            })}`,
          )
          .then((res) => res?.data?.result?.totalRowsCount),
      ),
    );
    return cards.reduce((acc, card, index) => {
      acc[card.id] = data[index] || 0;
      return acc;
    }, {});
    // return {
    //   [ATTORNEY_REVIEW]: 11,
    //   [DEADLINES_DUE_DATES]: 10,
    //   [CIVIL_MATTERS]: 12,
    // };
  },
);

export const getDashboardAttorneyList = createAsyncThunk(
  'attorneyDashboard/getDashboardAttorneyList',
  async ({ api, ...params }) => {
    let url = ``;
    if (api === ATTORNEY_REVIEW) {
      url = `/api/dashboards/attorney/cards/evictionMatters/byAttorneyReview?${convertToQueryString(
        params,
      )}`;
    }
    if (api === CIVIL_MATTERS) {
      url = `/api/dashboards/attorney/cards/civilMatters?${convertToQueryString(params)}`;
    }
    if (api === DEADLINES_DUE_DATES) {
      url = `/api/dashboards/attorney/cards/timelines?${convertToQueryString(params)}`;
    }
    const {
      data: { result },
    } = await apiClient.get(url);
    // const result =  {
    //       "items": [
    //           {
    //               "caseId": "738",
    //               "referenceNumber": "240404-000002",
    //               "management": "12",
    //               "community": null,
    //               "apartment": "1234",
    //               "opposingParty": "Mike Sal",
    //               "lastUpdatedDate": "2024-04-04T00:00:00",
    //               "stateCode": "AZ",
    //               "matterType": "Civil Matter",
    //               "created": "2024-04-04T00:00:00",
    //               "attorney": null,
    //               canComplete: true,
    //               "timeCategoryHistory": [
    //                   {
    //                       "id": 18,
    //                       "name": "Bankruptcy",
    //                       "description": "404 testing",
    //                       "createdDate": "2024-04-04T00:00:00"
    //                   }
    //               ]
    //           }
    //         ]
    //   };

    return { api, result };
  },
);

const initialState = {
  civilMatters: {
    items: [],
    settings: null,
  },
  stats: {
    [ATTORNEY_REVIEW]: 0,
    [DEADLINES_DUE_DATES]: 0,
    [CIVIL_MATTERS]: 0,
  },
  lists: {
    [ATTORNEY_REVIEW]: {},
    [DEADLINES_DUE_DATES]: {},
    [CIVIL_MATTERS]: {},
  },
};

export const attorneyDashboardSlice = createSlice({
  name: 'attorneyDashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getListOfCivilMattersAlerts.fulfilled]: (state, action) => {
      state.civilMatters = action.payload.result;
    },
    [getSettings.fulfilled]: (state, action) => {
      state.settings = action.payload.result;
    },
    [getDashboardStats.fulfilled]: (state, action) => {
      state.stats = {
        ...state.stats,
        ...action.payload,
      };
    },
    [getDashboardAttorneyList.fulfilled]: (state, action) => {
      state.lists[action.payload.api] = action.payload.result;
      state.stats[action.payload.api] = action.payload.result.totalRowsCount;
    },
  },
});

export default attorneyDashboardSlice.reducer;
