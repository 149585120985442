import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { isEmpty } from 'ramda';
import { saveAs } from 'file-saver';
import Table from '../../../atoms/Table/Table';
import EmptyBlock from '../../../molecules/EmptyBlock';
import {
  getCourtDates,
  getCounties,
  updateCourtDates,
  resetCourtDates,
} from '../../../../store/slices/schedule/addCourtDatesSlice';
import { AddCourtDatesColumns } from './AddCourtDates.constants';
import Dropdown from '../../../atoms/Dropdown';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Clock } from '../../../../assets/icons/Clock.svg';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import formatDateAndTimeToISO from '../../../../utils/formatDateAndTimeToISO';
import UploadDoc from './UploadDoc';
import NotificationDialog from '../../../molecules/NotificationDialog';
import { apiClient } from '../../../../lib/apiClient';
import notificationUtils from '../../../../utils/notificationUtils';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';

const AddCourtDates = () => {
  useSafeLocationState('Schedule', 'Add Court Dates');
  const dispatch = useDispatch();

  const [county, setCounty] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const { courtDates, counties } = useSelector((state) => state.addCourtDates);
  const [dateTimes, setDateTimes] = useState({});

  const resetDateTimes = () => {
    setDateTimes({});
  };

  const setDate = (courtId, date) => {
    setDateTimes((prev) => ({
      ...prev,
      [courtId]: {
        ...(prev[courtId] || {}),
        courtDate: date,
      },
    }));
  };

  const setTime = (courtId, time) => {
    setDateTimes((prev) => ({
      ...prev,
      [courtId]: {
        ...(prev[courtId] || {}),
        courtTime: time,
      },
    }));
  };

  useEffect(() => {
    dispatch(getCounties());

    return () => {
      dispatch(resetCourtDates());
    };
  }, []);

  useEffect(() => {
    if (county) {
      setIsLoader(true);
      dispatch(getCourtDates(county.id)).then(() => {
        setIsLoader(false);
      });
    }
    resetDateTimes();
  }, [county]);

  const updateDateTimes = async () => {
    setIsLoader(true);
    const values = Object.keys(dateTimes).reduce((acc, id) => {
      const dateTime = dateTimes[id];
      const initialDate = courtDates.find((c) => c.courtId === +id);
      const date = dateTime.courtDate || initialDate?.courtDateTime;
      const time = dateTime.courtTime || initialDate?.courtDateTime;

      if (date && time) {
        acc.push({
          courtId: +id,
          courtDateTime: formatDateAndTimeToISO(date, time),
        });
      }

      return acc;
    }, []);

    try {
      await dispatch(updateCourtDates({ courtDates: values })).unwrap();
      await dispatch(getCourtDates(county.id)).unwrap();
    } catch (e) {
      // TODO: Add error popup
      alert('--------------', e);
    } finally {
      setDateTimes({});
    }
    setIsLoader(false);
  };

  const rowsAdapter = courtDates?.map((item) => ({
    ...item,
    courtDate: (
      <DateOrTimePicker
        height={32}
        width={200}
        isDate
        Svg={Calender}
        selected={dateTimes[item.courtId]?.courtDate || item.courtDateTime}
        setSelectedTimeOrDate={(value) => setDate(item.courtId, value)}
      />
    ),
    courtTime: (
      <DateOrTimePicker
        height={32}
        width={200}
        isTime
        Svg={Clock}
        selected={dateTimes[item.courtId]?.courtTime || item.courtDateTime}
        setSelectedTimeOrDate={(value) => setTime(item.courtId, value)}
      />
    ),
  }));

  const groupedCounties = counties.reduce(
    (acc, option) => {
      const group = acc.find((g) => g.stateCode === option.stateCode);
      if (group) group.options.push(option);

      return acc;
    },
    [
      { label: 'AZ', stateCode: 'AZ', options: [] },
      { label: 'NV', stateCode: 'NV', options: [] },
    ],
  );

  const [isDocPopupOpened, setIsDocPopupOpened] = useState(false);

  const handleOpenDocPopModal = () => {
    setIsDocPopupOpened(true);
  };

  const handleCloseDocPopModal = () => {
    setIsDocPopupOpened(false);
  };

  const [fileToDownload, setFileToDownload] = useState({
    uri: null,
    fileName: null,
    description: null,
  });

  const handleConfirmUploadDocModal = async (file) => {
    const response = await apiClient.post('/api/arizonaCases/courtDateSpreadSheet', file);

    notificationUtils.success('Uploaded successfully');

    if (response?.data?.result?.skipped === 0) {
      return;
    }

    const logId = response?.data?.result?.logId;

    if (logId) {
      const { data } = await apiClient.get(`/api/operationResults/${logId}`);
      const uri = `data:${data.result.contentType};base64,${data.result.content}`;
      setFileToDownload({
        uri,
        fileName: data.result.originalFileName,
        description: `Case # or Court Date is missing for ${response?.data?.result?.skipped} number of cases.`,
      });
    }
  };

  const handleCloseErrorMessage = () => {
    setFileToDownload({
      uri: null,
      fileName: null,
      description: null,
    });
  };

  const handleDownloadError = () => {
    saveAs(fileToDownload.uri, fileToDownload.fileName);
  };

  return (
    <Stack direction="column">
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pr={4}
      >
        <Typography variant="h3" margin="0 32px">
          Court Dates
        </Typography>
        <MButton
          size="small"
          onClick={handleOpenDocPopModal}
          sx={{
            height: '36px',
          }}
        >
          Case Update
        </MButton>
      </Stack>
      {isDocPopupOpened ? (
        <UploadDoc
          title="Upload Document"
          isOpen={isDocPopupOpened}
          handleClose={handleCloseDocPopModal}
          onConfirm={handleConfirmUploadDocModal}
        />
      ) : null}
      <NotificationDialog
        type=""
        width="528px"
        isOpen={fileToDownload.description}
        title={fileToDownload.uri ? fileToDownload.description : 'Error'}
        onClose={handleCloseErrorMessage}
        onConfirm={handleCloseErrorMessage}
        buttonPrimaryText="Ok"
        desc={
          fileToDownload.uri ? (
            <Typography
              onClick={handleDownloadError}
              sx={{
                fontSize: '18px',
                color: palette.text.browseFile,
                fontWeight: 400,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Click here for details.
            </Typography>
          ) : (
            fileToDownload.description
          )
        }
      />
      <Box width="1140px" marginX="auto" marginBottom={25}>
        <Box width={326} margin="32px 0 24px">
          <Dropdown
            options={groupedCounties}
            isSearchable
            placeholder="Select county"
            label="County"
            value={county}
            isColumn
            onChange={setCounty}
          />
        </Box>
        {isLoader && (
          <Box
            position="absolute"
            top="50vh"
            left="50%"
            zIndex={10}
            transform="translate(-50%, -50%)"
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoader && !!rowsAdapter?.length && (
          <Stack spacing={3}>
            <Table columns={AddCourtDatesColumns} rows={rowsAdapter} loading={false} />
          </Stack>
        )}
        {!isLoader && !rowsAdapter?.length && (
          <Box display="flex" justifyContent="center">
            <EmptyBlock
              title="No records"
              desc="Please select county to display available Court Dates"
            />
          </Box>
        )}
      </Box>
      <Box
        position="fixed"
        bottom={0}
        zIndex={10}
        width="100vw"
        p={4}
        boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        bgcolor="#FFF"
      >
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <MButton
            variant="secondary"
            size="large"
            disabled={isEmpty(dateTimes)}
            onClick={resetDateTimes}
            sx={{ width: '144px' }}
          >
            Cancel
          </MButton>
          <MButton
            type="submit"
            size="large"
            disabled={isEmpty(dateTimes)}
            onClick={updateDateTimes}
            sx={{ width: '144px' }}
          >
            Update
          </MButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default AddCourtDates;
