import React, { useCallback, useLayoutEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { PropTypes } from 'prop-types';
import { isObject, isNull } from 'lodash';
import TextInput from '../../../MUI/MUITextInput';
import { ReactComponent as Edit } from '../../../../assets/icons/Edit.svg';
import { isInFuture } from '../../../../utils/dateHelpers';

export const DocumentsTypeCell = ({
  value: initialValue,
  changeValueCell,
  editableRowIndex,
  setEditableRowIndex,
  updateData,
  column,
  isDB,
  row: { index, original },
}) => {
  const [value, setValue] = useState('');
  const isEditingCell = editableRowIndex === index;
  const isObjectValue = !isNull(initialValue) && isObject(initialValue);

  const handleClickEdit = useCallback(() => {
    setEditableRowIndex(index);
  }, [index, setEditableRowIndex]);

  // todo: check if value needed here
  // eslint-disable-next-line no-shadow
  const handleChange = useCallback(({ target: { value } }) => {
    setValue(value);

    changeValueCell(column.id, isObjectValue ? { ...initialValue, value } : value);
  }, []);

  useLayoutEffect(() => {
    setValue(isObjectValue ? initialValue.value : initialValue);
  }, [initialValue, isEditingCell]);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateData();
    }
  };

  if (column.edit && isEditingCell) {
    return (
      <TextInput
        sx={{ width: 'fit-content', height: 'fit-content' }}
        value={value}
        onKeyDown={onKeyDown}
        onBlur={updateData}
        onChange={handleChange}
        height={32}
        data-testid="input_wrapper"
        inputProps={{ 'data-testid': 'input' }}
      />
    );
  }

  const isPrefileDateInFuture = original.prefileDate && isInFuture(original.prefileDate);

  return (
    <div
      style={{
        backgroundColor: '#EBEDFA',
        borderRadius: '8px',
        width: 'fit-content',
        minHeight: '34px',
        padding: `0 ${isDB && !isPrefileDateInFuture ? 0 : 8}px 0 8px`,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
      }}
      data-testid="cell_wrapper"
    >
      {initialValue}
      {isDB && !isPrefileDateInFuture && (
        <IconButton onClick={handleClickEdit} data-testid="edit_btn">
          <Edit />
        </IconButton>
      )}
    </div>
  );
};

DocumentsTypeCell.propTypes = {
  value: PropTypes.string.isRequired,
  changeValueCell: PropTypes.func.isRequired,
  editableRowIndex: PropTypes.number.isRequired,
  setEditableRowIndex: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  column: PropTypes.shape({
    edit: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  row: PropTypes.shape({
    index: PropTypes.number,
    original: PropTypes.shape({
      prefileDate: PropTypes.string,
    }),
  }).isRequired,
  isDB: PropTypes.bool,
};

DocumentsTypeCell.defaultProps = {
  isDB: true,
};
