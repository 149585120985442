import moment from 'moment';
import BillingStatusCell from './BillingStatusCell';
import { ReferenceNumberCell } from '../../Processing/CaseSearch/CaseSearchCells';

export const billingInstancesTableColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    Cell: (props) => <ReferenceNumberCell {...props} isBlank />,
    width: 15,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'opposingParty',
    width: 15,
  },
  {
    Header: 'Billing Instance',
    accessor: 'billingInstance',
    width: 10,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    width: 10,
  },
  {
    Header: 'Date',
    accessor: 'serviceDate',
    width: 10,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Removed On',
    accessor: 'removedOn',
    width: 10,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Removed By',
    accessor: 'removedBy',
    width: 15,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 15,
    Cell: BillingStatusCell,
  },
];
