import React from 'react';
import { ReactComponent as InfoCircleRed } from '../../../assets/icons/InfoCircleRed.svg';
import { ReactComponent as CheckCircleGreen } from '../../../assets/icons/CheckCircleGreen.svg';
import { ReactComponent as InfoTriangleRed } from '../../../assets/icons/InfoTriangleIcon.svg';

export const icons = [
  {
    type: 'alert',
    icon: (
      <InfoCircleRed
        style={{ flexShrink: 0 }}
        width={24}
        height={24}
        data-testid="red_circle_icon"
      />
    ),
  },
  {
    type: 'warning',
    icon: (
      <InfoTriangleRed
        style={{ flexShrink: 0 }}
        width={24}
        height={24}
        data-testid="red_triangle_icon"
      />
    ),
  },
  {
    type: 'confirm',
    icon: (
      <CheckCircleGreen
        style={{ flexShrink: 0 }}
        width={24}
        height={24}
        data-testid="check_circle_icon"
      />
    ),
  },
];
