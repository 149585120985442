import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { palette } from '../../../../../theme/default';
import { useBaseUrl } from '../../../../../hooks/useBaseUrl';

const CaseNumberCell = ({ value, row }) => {
  const base = useBaseUrl();
  const path = `/${base}/cases/case/${row.original.caseId}`;

  return (
    <Link to={path}>
      <Typography sx={{ cursor: 'pointer' }} variant="link" color={palette.text.primary}>
        {value || 'No Case #'}
      </Typography>
    </Link>
  );
};

CaseNumberCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
};

export default CaseNumberCell;
