import { Button, Stack, styled, Typography } from '@mui/material';
import { useCallback } from 'react';
import PropTypes from 'prop-types';

const PeriodButton = styled(Button)(({ theme, isActive = false }) => ({
  height: '40px',
  minWidth: '49px',
  padding: '8px 8px',
  borderRadius: '10px',
  textTransform: 'none',
  ...(isActive && { background: theme.palette.text.disabled }),

  '&:hover': {
    background: theme.palette.text.disabled,
  },
}));

const PeriodButtons = ({ periods, currentPeriod, isDisabled, onPeriodChange }) => {
  const onClick = useCallback(
    (value) => () => {
      onPeriodChange(value);
    },
    [onPeriodChange],
  );

  return (
    <Stack direction="row" columnGap={0.5} data-testid="period_button_block">
      {periods?.map(({ label, value, disabled }) => (
        <PeriodButton
          key={value}
          isActive={value === currentPeriod}
          disabled={isDisabled || disabled}
          onClick={onClick(value)}
          data-testid={`period_button_${label}`}
        >
          <Typography variant="buttonLarge" color="text.secondary">
            {label}
          </Typography>
        </PeriodButton>
      ))}
    </Stack>
  );
};

PeriodButtons.propTypes = {
  periods: PropTypes.arrayOf([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
    }),
  ]),
  currentPeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDisabled: PropTypes.bool,
  onPeriodChange: PropTypes.func.isRequired,
};

PeriodButtons.defaultProps = {
  isDisabled: false,
  periods: [],
};

export default PeriodButtons;
