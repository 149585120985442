import { LinkCell } from './TableCells/LinkCell';
import { DownloadDeleteDoc } from './TableCells/DownloadDeleteDoc';
import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';

export const documentTemplatesColumns = [
  {
    Header: 'Template Name',
    accessor: 'name',
    width: 50,
    Cell: LinkCell,
  },
  {
    Header: 'Notice Type',
    accessor: 'noticeType',
    width: 30,
  },
  {
    Header: 'State',
    accessor: 'stateCode',
    width: 20,
  },
  {
    Header: '',
    accessor: 'control',
    width: 3,
    Cell: DownloadDeleteDoc,
  },
];

export const acceptedFilesExtensions = {
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

export const documentsLabelAndDropDown = {
  label: 'Document Templates',
  dropdownLabel: 'Select state',
  options: [
    { value: 'AZ', label: 'AZ' },
    { value: 'NV', label: 'NV' },
  ],
};

export const documentUploadsTableColumns = [
  {
    Header: 'Document Name',
    accessor: 'documentName',
    width: 94,
  },
  {
    Header: '',
    accessor: 'control',
    width: 6,
    Cell: DeleteCell,
  },
];

export const COURTS_PAGE_SIZE = 10;
