import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from './AddButton.module.scss';
import { ReactComponent as Add } from '../../../assets/icons/Add.svg';
import { palette } from '../../../theme/default';

const AddButton = memo((props) => {
  const { label, onClick, height, width, disabled } = props;

  const handleClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <StyledButton
      className={styles.add_button}
      onClick={handleClick}
      height={height}
      width={width}
      disabled={disabled}
      data-testid="add_button"
    >
      <Add data-testid="add_icon" />
      <span data-testid="add_label">{label}</span>
    </StyledButton>
  );
});

const StyledButton = styled.button`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-color: ${({ disabled }) => disabled && '#6F6F6F'};
  &:hover {
    background-color: ${palette.buttonPrimary.hover};
  }
`;

AddButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  height: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};

AddButton.defaultProps = {
  label: '',
  onClick: '',
  height: '',
  width: '',
  disabled: false,
};

export default AddButton;
