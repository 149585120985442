import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@mui/material';

export const DeliveredByCell = ({ row }) => {
  const { deliveredBy } = row.original;

  return (
    <Typography fontSize="15px" fontWeight={500}>
      {deliveredBy === 'null' ? '' : deliveredBy}
    </Typography>
  );
};

DeliveredByCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      deliveredBy: PropTypes.string,
    }),
  }).isRequired,
};
