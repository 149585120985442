import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';

export const MAX_AMOUNT_OF_ITEMS_ON_PAGE = 10;

export const ManagementsColumns = [
  {
    Header: 'Management Name',
    accessor: 'managementName',
    width: 96,
  },
  {
    Header: '',
    accessor: 'control',
    width: 4,
    minWidth: '48px',
    Cell: DeleteCell,
  },
];
