import React from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const PieChart = ({ data: formattedData, stateCode }) => {
  const options = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}',
      textStyle: {
        fontSize: '16px',
        fontWeight: 500,
      },
    },
    legend: {
      data: formattedData.map((item) => item.name),
      orient: 'vertical',
      type: 'scroll',
      left: '60%',
      top: 'center',
      icon: 'circle',
      textStyle: {
        fontSize: '16px',
        fontWeight: 500,
      },
    },
    series: [
      {
        type: 'pie',
        data: formattedData,
        center: ['30%', '50%'],
        right: 'auto',
        label: {
          show: true,
          formatter: '{c}',
          // Увеличиваем значение left для отталкивания серии диаграммы от легенды
          left: formattedData.length > 10 ? '220px' : '120px',
        },
      },
    ],
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  };

  return (
    <>
      <Typography variant="h5" pl={2} pt={2}>
        {stateCode}
      </Typography>
      <ReactEcharts
        option={options}
        style={{
          height: '250px',
          width: '100%',
          margin: '0 auto',
          overflowX: 'auto',
          display: 'block',
        }}
      />
    </>
  );
};

PieChart.propTypes = {
  data: PropTypes.shape().isRequired,
  stateCode: PropTypes.string.isRequired,
};

export default PieChart;
