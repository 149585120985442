import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled, Dialog, Typography, Stack, Container, Box } from '@mui/material';
import { useForm, useFieldArray } from 'react-hook-form';

import MButton from '../../MUI/Button/MButton';
import { palette } from '../../../theme/default';
import {
  INDIVIDUALS_COLUMNS,
  OpposingPartyBoxes,
} from './ClientPortalNonPaymentNoticeDialog.constants';
import Form from '../../atoms/Form';
import Field from '../../atoms/Field';
import EditInputWithLabel from '../../molecules/EditInputWithLabel/EditInputWithLabel';
import Table from '../../atoms/Table/Table';
import { ReactComponent as Add } from '../../../assets/icons/Add.svg';

const StyledDialog = styled(Dialog)(({ width, minWidth }) => ({
  '& .MuiDialog-paper': {
    width: width || 'auto',
    minWidth: minWidth || 'auto',
    padding: 32,
    borderRadius: 24,
    boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
  },
  '.MuiPaper-root': {
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '12px',
      backgroundColor: palette.scrollbar.thumb,
    },
  },
}));

const mandatoryError = { type: 'required', message: 'Mandatory field' };

export const validateForm = (data) => {
  const errors = {};

  if (data.individuals.some((individual) => !individual.firstName || !individual.lastName)) {
    errors.individuals = mandatoryError;
  }

  return { values: data, errors };
};

const OpposingPartyDialog = ({ isOpen, onClose, onConfirm, dataToEdit }) => {
  const form = useForm({
    mode: dataToEdit ? 'onChange' : 'onSubmit',
    reValidateMode: dataToEdit ? 'onChange' : 'onSubmit',
    resolver: validateForm,
  });

  const individualFields = useFieldArray({
    control: form.control,
    name: 'individuals',
  });

  const opposingPartyLength = individualFields.fields.length;

  useEffect(() => {
    if (dataToEdit) {
      form.reset({
        individuals: dataToEdit.individuals,
      });
      form.trigger();
    }
  }, [dataToEdit]);

  const handleAddDefendant = (i) => () => {
    const values = form.getValues();
    const boxes = i.inputBoxes.filter((b) => !!b?.name?.startsWith('individual.'));
    const errors = boxes.map(({ name, isMandatory }) => ({
      name,
      error: isMandatory && !form.getValues(name) ? mandatoryError : null,
    }));
    errors.forEach(({ name, error }) => form.setError(name, error));
    if (errors.find((e) => e.error)) {
      return;
    }
    individualFields.prepend(values.individual);
    boxes.forEach((box) => form.setValue(box.name, ''));
  };

  const handleUpdateDefendant = (index, values) => {
    const areFieldsInvalid = INDIVIDUALS_COLUMNS.some((c) => c.isMandatory && !values[c.accessor]);
    if (areFieldsInvalid) return false;
    individualFields.update(index, values);
    return true;
  };

  const handleCancel = () => {
    onClose();
  };

  const isDisabled = useMemo(() => {
    return !opposingPartyLength;
  }, [!opposingPartyLength]);

  const getShowMandatory = useCallback(
    (fieldName, initial = false) => {
      switch (fieldName) {
        default:
          return initial;
      }
    },
    [opposingPartyLength],
  );

  const handleSubmit = (formValues) => {
    const { individuals } = formValues;

    const defendantName = [...individuals.map((item) => `${item.firstName} ${item.lastName}`)].join(
      ', ',
    );

    const formData = {
      defendantName,
      individuals: individuals.map((individual) => ({
        firstName: individual.firstName || '',
        lastName: individual.lastName || '',
        middleName: individual.middleName || '',
        suffix: individual.suffix || '',
      })),
    };

    form.reset({});
    return onConfirm(formData.defendantName, formData.individuals);
  };

  return (
    <StyledDialog onClose={onClose} open={isOpen} minWidth="1129px">
      <Typography variant="h4">Opposing Party</Typography>
      <Stack>
        <Form form={form} onSubmit={handleSubmit} id="a-form">
          {OpposingPartyBoxes.map((box) => (
            <Container
              key={box.boxTitle}
              maxWidth={false}
              disableGutters
              sx={{
                mx: 'auto',
                width: 1,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={4}
                mt={4}
              >
                <Typography variant="h5">{box.boxTitle}</Typography>
              </Stack>

              <Stack direction="row" alignItems="center" columnGap={3} rowGap={2} flexWrap="wrap">
                {box.inputBoxes.map((item) => {
                  if (item.isSubHeading) {
                    return (
                      <Typography width={1} mb={1.5} variant="bodyL500" key={item.label}>
                        {item.label}
                      </Typography>
                    );
                  }

                  if (item.isAddButtonWithTable) {
                    if (item.table === 'individualTable') {
                      return (
                        <React.Fragment key={item.table}>
                          <MButton
                            sx={{ marginTop: '32px', marginLeft: 'auto' }}
                            startIcon={<Add />}
                            onClick={handleAddDefendant(box)}
                          >
                            Add
                          </MButton>
                          <Box mt={2} width="100%">
                            <Table
                              columns={INDIVIDUALS_COLUMNS}
                              isLoading={false}
                              onUpdateData={handleUpdateDefendant}
                              onDeleteRow={individualFields.remove}
                              rows={individualFields.fields}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    }
                    return null;
                  }

                  return (
                    <Field
                      key={item.label}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, error }) => (
                        <EditInputWithLabel
                          disabled={item.isDisabled}
                          error={error}
                          isMandatory={getShowMandatory(item.name, item.isMandatory)}
                          isDisabled={false}
                          key={item.label}
                          type={item.type}
                          label={item.label}
                          name={item.name}
                          value={field.value}
                          placeholder={item.placeholder}
                          width={item.width}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  );
                })}
              </Stack>
            </Container>
          ))}
        </Form>
      </Stack>

      <Stack spacing={2} direction="row" justifyContent="flex-end" mt={6}>
        <MButton variant="secondary" size="large" onClick={handleCancel} sx={{ width: '144px' }}>
          Cancel
        </MButton>
        <MButton
          onClick={form.handleSubmit(handleSubmit)}
          size="large"
          sx={{ width: '200px' }}
          disabled={isDisabled}
        >
          Add Opposing Party
        </MButton>
      </Stack>
    </StyledDialog>
  );
};

OpposingPartyDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  dataToEdit: PropTypes.func,
};

OpposingPartyDialog.defaultProps = {
  isOpen: false,
  onClose: null,
  onConfirm: null,
  dataToEdit: null,
};

export default OpposingPartyDialog;
