import React, { useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useLocation } from 'react-router-dom';

import MButton from '../../../MUI/Button/MButton';
import { apiClient } from '../../../../lib/apiClient';
import notificationUtils from '../../../../utils/notificationUtils';

const MakeActiveCell = ({ row: { original }, refreshPage }) => {
  const [isLoader, setIsLoader] = useState(false);
  const {
    state: { templateGroupNumber },
  } = useLocation();

  const handleClickMakeActive = useCallback(async () => {
    if (!isLoader) {
      setIsLoader(true);
      try {
        await apiClient.put(`/cp/api/legalForms/activate`, {
          documentId: original.id,
          templateNumber: templateGroupNumber,
        });
        refreshPage();
      } catch {
        notificationUtils.error('Failed, try again later');
      } finally {
        setIsLoader(false);
      }
    }
  }, [original.id, isLoader]);

  if (original.isActive) return null;

  return (
    <MButton
      variant="borderLess"
      sx={{ whiteSpace: 'nowrap' }}
      onClick={handleClickMakeActive}
      data-testid="make_active_button"
    >
      Make active
    </MButton>
  );
};

MakeActiveCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    original: PropTypes.shape({
      isActive: PropTypes.bool,
      id: PropTypes.string,
      templateGroupNumber: PropTypes.number,
    }),
  }).isRequired,
  refreshPage: PropTypes.func.isRequired,
};

export default MakeActiveCell;
