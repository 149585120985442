import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowDown } from '../../../../../assets/icons/ArrowDown.svg';
import { navigateToNewTab } from '../../../../../utils/toNewTab';

export const entityTypes = {
  MANAGEMENT: 'management',
  ADDRESS: 'address',
  STANDALONE_ADDRESSES: 'standaloneAddresses',
  COMMUNITY: 'sommunity',
};

const NameCell = ({ value, row, rowClick, stateCode }) => {
  const handleRowClick = () => {
    if (row.original.type === entityTypes.COMMUNITY || row.original.type === entityTypes.ADDRESS)
      return;
    rowClick({
      value: row.original.id,
      type: row.original.type,
    });
  };

  const goToPage = () => {
    if (!row.original.id) return;
    if (row.original.type === entityTypes.MANAGEMENT) {
      navigateToNewTab('/db/datahandling/editmanagement/management', {
        id: row.original.id,
        name: value,
        navPage: 'Data Handling',
        subNav: 'Managements',
      });
    } else if (row.original.type === entityTypes.COMMUNITY) {
      navigateToNewTab('/db/datahandling/editcommunity/community', {
        id: row.original.id,
        name: value,
        navPage: 'Data Handling',
        subNav: 'Communities',
      });
    } else if (row.original.type === entityTypes.ADDRESS) {
      navigateToNewTab('/db/datahandling/editaddress/address', {
        id: row.original.id,
        name: value,
        navPage: 'Data Handling',
        subNav: 'Addresses',
        state: stateCode,
      });
    }
  };

  return (
    <Stack
      data-testid="cell_wrapper"
      direction="row"
      sx={{ cursor: 'pointer' }}
      alignItems="center"
      onClick={handleRowClick}
    >
      {row.original.type === entityTypes.MANAGEMENT ||
      row.original.type === entityTypes.STANDALONE_ADDRESSES ? (
        <ArrowDown
          style={{
            marginRight: '8px',
            transform: !row.original.expanded ? 'rotate(-90deg)' : 'none',
            transition: 'transform 0.2s',
            marginLeft: row.original.type === entityTypes.STANDALONE_ADDRESSES ? '40px' : '0',
          }}
          data-testid="arrow_icon"
        />
      ) : (
        <Box
          sx={{
            width: row.original.type === entityTypes.ADDRESS ? '80px' : '40px',
            height: '24px',
          }}
        />
      )}
      {row.original.type === entityTypes.MANAGEMENT && (
        <Box
          sx={{
            backgroundColor: row.original.isNew ? '#57CEBA' : '#EBEDFA',
            padding: '4px 8px',
            borderRadius: '8px',
            marginRight: '8px',
          }}
        >
          {row.original.isNew ? 'New' : 'Old'}
        </Box>
      )}
      <Typography
        sx={{
          textDecoration:
            row.original.type !== entityTypes.STANDALONE_ADDRESSES ? 'underline' : 'none',
          fontWeight: row.original.type === entityTypes.MANAGEMENT ? 600 : 500,
          fontSize: row.original.type === entityTypes.MANAGEMENT ? '18px' : '16px',
        }}
        onClick={goToPage}
        data-testid="name_cell"
      >
        {value}
      </Typography>
    </Stack>
  );
};

NameCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      expanded: PropTypes.bool,
      visible: PropTypes.bool,
      id: PropTypes.number,
      isNew: PropTypes.bool,
      type: PropTypes.string,
    }),
  }).isRequired,
  rowClick: PropTypes.func,
  stateCode: PropTypes.shape({}).isRequired,
};

NameCell.defaultProps = {
  value: '',
  rowClick: null,
};

export default NameCell;
