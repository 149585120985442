import React, { useCallback, useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';

// Icons
import { CircularProgress } from '@mui/material';
import { ReactComponent as Accept } from '../../../../../assets/icons/accept.svg';
import { ReactComponent as Closed } from '../../../../../assets/icons/closed.svg';
import { ReactComponent as Edit } from '../../../../../assets/icons/Edit.svg';
import { ReactComponent as Trash } from '../../../../../assets/icons/Trash.svg';
import { ReactComponent as InfoCircleBlack } from '../../../../../assets/icons/InfoCircleBlack.svg';

export const EditableArizonaCellContext = React.createContext({});

export const CaseInformationEditableCell = ({
  row: { index, id, original },
  setEditableRowIndex,
  editableRowIndex,
  cancelEditing,
  updateData,
  deleteRow,
  showDelete,
  showInfo,
}) => {
  const { onShowInfo } = useContext(EditableArizonaCellContext);
  const [isLoader, setIsLoader] = useState(false);

  const handleClickEdit = useCallback(() => {
    setEditableRowIndex(index);
  }, [index, setEditableRowIndex]);

  const handleClickDelete = useCallback(() => {
    deleteRow(id, original);
  }, [id, original, deleteRow]);

  const handleClickShowInfo = useCallback(async () => {
    if (onShowInfo) {
      setIsLoader(true);
      await onShowInfo(id, original);
      setIsLoader(false);
    }
  }, [id, original, onShowInfo]);

  return editableRowIndex === index ? (
    <>
      <IconButton onClick={updateData}>
        <Accept />
      </IconButton>
      <IconButton onClick={cancelEditing}>
        <Closed />
      </IconButton>
    </>
  ) : (
    <>
      {showInfo &&
        (isLoader ? (
          <IconButton>
            <CircularProgress disableShrink size={18} />
          </IconButton>
        ) : (
          <IconButton onClick={handleClickShowInfo} sx={{ height: '34px', width: '34px' }}>
            <InfoCircleBlack />
          </IconButton>
        ))}
      <IconButton onClick={handleClickEdit}>
        <Edit />
      </IconButton>
      {showDelete && (
        <IconButton onClick={handleClickDelete}>
          <Trash />
        </IconButton>
      )}
    </>
  );
};

CaseInformationEditableCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({}),
  }).isRequired,
  setEditableRowIndex: PropTypes.func.isRequired,
  editableRowIndex: PropTypes.number.isRequired,
  cancelEditing: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  showDelete: PropTypes.bool,
  showInfo: PropTypes.bool,
};

CaseInformationEditableCell.defaultProps = {
  showDelete: false,
  showInfo: false,
};
