/* eslint-disable */
import React, { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';
import { ReactComponent as Download } from '../../../../../../assets/icons/DocumentDownload.svg';
import { Stack, Typography } from '@mui/material';
import { palette } from '../../../../../../theme/default';

export const MakeActiveOrDownloadCell = ({ row: { original }, downloadRow, makeActive }) => {
  const { isActive, templateId, templateGroupId } = original;

  const handleDownload = useCallback(() => {
    downloadRow(templateId);
  }, [templateId, downloadRow]);

  const handleMakeActive = useCallback(() => {
    makeActive({ templateId, templateGroupId });
  }, [makeActive, templateId, templateGroupId]);

  return (
    <Stack
      width="100%"
      display="flex"
      alignItems="center"
      direction="row"
      justifyContent="flex-end"
    >
      {!isActive && (
        <Typography
          onClick={handleMakeActive}
          sx={{
            color: palette.text.browseFile,
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '16px',
            mr: '26px',
          }}
        >
          Make active
        </Typography>
      )}
      <IconButton onClick={handleDownload}>
        <Download />
      </IconButton>
    </Stack>
  );
};

MakeActiveOrDownloadCell.propTypes = {
  row: PropTypes.shape({ id: PropTypes.number, index: PropTypes.number }).isRequired,
  setEditableRowIndex: PropTypes.func.isRequired,
  editableRowIndex: PropTypes.number.isRequired,
  cancelEditing: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
};
