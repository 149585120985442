import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Stack, Typography } from '@mui/material';
import Dropdown from '../../atoms/Dropdown';
import ShadowBlock from '../../atoms/ShadowBlock';
import { ReactComponent as DocumentText } from '../../../assets/icons/DocumentText.svg';
import { ReactComponent as DocumentForward } from '../../../assets/icons/DocumentForward.svg';
import useAuth from '../../../hooks/useAuth';
import { palette } from '../../../theme/default';
import { evictionLinks } from './ClientPortalRequests.constants';
import { ALL_STATES } from '../../../global/constants';
import useSafeLocationState from '../../../hooks/useSafeLocationState';
import ClientPortalNotiesRequestsNevada from './ClientPortalNotiesRequestsNevada';
import { usePrimaryState } from '../../../hooks/usePrimaryState';

const StyledWrapper = ({ title, icon, to, navPage, subNav, state }) => {
  return (
    <Stack
      component={Link}
      to={to}
      state={{
        ...state,
        navPage,
        subNav,
      }}
      sx={{ textDecoration: 'none' }}
      width="calc((100% - 24px*2)/2)"
    >
      <ShadowBlock>
        <Stack alignItems="center" spacing={3}>
          <Typography variant="h5" color="text.secondary" align="center" whiteSpace="pre-line">
            {title}
          </Typography>
          {icon}
        </Stack>
      </ShadowBlock>
    </Stack>
  );
};

const getLink = (obj, state) => obj[state.value];

const ClientPortalRequests = () => {
  const stateLocation = useSafeLocationState('New Requests');
  const primaryState = usePrimaryState();

  const {
    userInfo: { StateCode },
  } = useAuth();

  const mapStateCode = Array.isArray(StateCode)
    ? StateCode.map((state) => {
        return ALL_STATES.find((item) => item.label === state);
      })
    : [ALL_STATES.find((item) => item.label === StateCode)];

  const initialState = stateLocation.stateCode
    ? mapStateCode.find((item) => item.label === stateLocation.stateCode)
    : mapStateCode[0];

  const [stateValue, setStateValue] = useState(primaryState || initialState);

  const handleChangeState = useCallback((value) => {
    setStateValue(value);
  }, []);

  const renderContentForChosenState = () => {
    if (stateValue.value === 'NV') {
      return <ClientPortalNotiesRequestsNevada />;
    }
    return (
      <Stack spacing={3} direction="row" justifyContent="stretch">
        <StyledWrapper
          title="Notice requests"
          icon={<DocumentText color={palette.buttonPrimary.active} />}
          to="notices"
          navPage="New Requests"
          subNav="Notice requests"
        />
        <StyledWrapper
          title="Eviction requests"
          icon={<DocumentForward color={palette.buttonPrimary.active} />}
          to={getLink(evictionLinks, stateValue)}
          navPage="New Requests"
          subNav="Eviction requests"
        />
      </Stack>
    );
  };

  return (
    <Stack height="100%" margin="0 32px" direction="column">
      <Stack spacing={4} direction="row" alignItems="center">
        <Typography variant="h4">New Requests</Typography>
        <Dropdown
          label="Select state"
          options={mapStateCode}
          onChange={handleChangeState}
          value={stateValue}
        />
      </Stack>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          m: 'auto',
          px: 4,
          width: 1,
          maxWidth: 1250,
        }}
      >
        {renderContentForChosenState()}
      </Container>
    </Stack>
  );
};

StyledWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  navPage: PropTypes.string.isRequired,
  subNav: PropTypes.string.isRequired,
  state: PropTypes.shape({}),
};

StyledWrapper.defaultProps = {
  state: {},
};

export default ClientPortalRequests;
