/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getAllUsers = createAsyncThunk('adminusers/getAllUsers', async (params) => {
  const { data } = await apiClient.post(`/api/users/filter`, params);
  return data;
});

export const deleteUser = createAsyncThunk('adminusers/deleteUser', async ({ userId }) => {
  const { data } = await apiClient.delete(`/api/users/${userId}`);
  return data;
});

export const getAvailableRoles = createAsyncThunk('adminusers/getAvailableRoles', async () => {
  const { data } = await apiClient.get(`/api/roles`);
  return data.result.map((item) => ({
    id: item.roleId,
    value: item.roleId,
    label: item.name,
  }));
});

export const getAvailableStates = createAsyncThunk('adminusers/getAvailableStates', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data;
});

export const getUser = createAsyncThunk('adminusers/getUser', async (userId) => {
  const { data } = await apiClient.get(`/api/users/${userId}`);
  return data;
});

export const resetEditableUser = createAction('adminusers/resetEditableUser', () => {
  return {
    type: 'adminusers/resetEditableUser',
  };
});

const initialState = {
  roles: [],
  editableUser: null,
  states: [],
  usersTable: [],
  primaryStateCode: {},
  error: null,
};

export const adminUsersSlice = createSlice({
  name: 'adminusers',
  initialState,
  reducers: null,
  extraReducers: {
    [resetEditableUser]: (state) => {
      state.editableUser = null;
    },
    [getUser.fulfilled]: (state, action) => {
      state.editableUser = action.payload.result;
      state.primaryStateCode = action.payload.result.primaryStateCode;
    },
    [getAvailableStates.fulfilled]: (state, action) => {
      state.states = action.payload.result.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.code,
      }));
    },
    [getAvailableStates.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.usersTable = action.payload.result;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAvailableRoles.fulfilled]: (state, action) => {
      state.roles = action.payload;
    },
    [getAvailableRoles.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteUser.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default adminUsersSlice.reducer;
