import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import ClientPortalDashboardCard from './ClientPortalDashboardCard';
import { cardsListByState, PENDING_APPROVALS } from './ClientPortalDashboardCases.constants';
import { getDashboardStats } from '../../../../store/slices/clientPortalDashboardSlice';
import ClientPortalDashboardList from './ClientPortalDashboardList';
import EmptyBlock from '../../../molecules/EmptyBlock';

const ClientPortalDashboardCases = ({ stateCode }) => {
  const [activeCard, setActiveCard] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [listLoader, setListLoader] = useState(false);
  const { stats } = useSelector((state) => state.clientPortalDashboard);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { section = '' } = Object.fromEntries(searchParams);

  const loadStats = () => {
    setIsLoader(true);
    dispatch(getDashboardStats(stateCode)).finally(() => setIsLoader(false));
  };

  useEffect(() => {
    loadStats();
    if (activeCard) {
      setActiveCard(null);
      searchParams.delete('section');
      setSearchParams(searchParams);
    }
  }, [stateCode]);

  useEffect(() => {
    if (section === 'pending_approvals') {
      setActiveCard(PENDING_APPROVALS);
    }
  }, [section]);

  const onCardClick = (id) => () => {
    if (activeCard === id) return;
    setListLoader(true);
    setActiveCard(id);
    searchParams.delete('section');
    setSearchParams(searchParams);
  };

  return (
    <Stack direction="row" columnGap={3} alignItems="stretch" minHeight="736px">
      <Stack rowGap={2} position="relative" direction="row" flexWrap="wrap" width="326px">
        {isLoader && (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            zIndex={10}
            transform="translate(-50%, -50%)"
          >
            <CircularProgress />
          </Box>
        )}
        {cardsListByState[stateCode].map(({ title, icon, id, isDisabled, base }) => (
          <ClientPortalDashboardCard
            key={id}
            isActive={activeCard === id}
            onClick={onCardClick(id)}
            title={title}
            icon={icon}
            count={stats?.[id] ?? 0}
            base={base}
            isDisabled={isLoader || isDisabled}
          />
        ))}
      </Stack>
      {activeCard ? (
        <ClientPortalDashboardList
          isLoader={listLoader}
          setIsLoader={setListLoader}
          type={activeCard}
          stateCode={stateCode}
        />
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <EmptyBlock title="Please select a card" />
        </Box>
      )}
    </Stack>
  );
};

ClientPortalDashboardCases.propTypes = {
  stateCode: PropTypes.string.isRequired,
};

export default ClientPortalDashboardCases;
