import moment from 'moment';
import BillingStatusCell from './BillingStatusCell';

const DATE_FORMAT = 'mm/dd/yyyy';

export const invoiceDetailOptions = [
  { label: 'Management', value: 'Management' },
  { label: 'Community', value: 'Community' },
  { label: 'Address', value: 'Address' },
];

export const invoiceGenerationBoxes = [
  {
    label: 'Management',
    name: 'management',
    placeholder: 'Select management',
    width: '326px',
    isDynamicSearchDropdown: true,
    isMandatory: true,
  },
  {
    label: 'Invoicing Detail',
    name: 'invoicingDetail',
    placeholder: 'Select management or community',
    width: '326px',
    isDropdown: true,
    isMandatory: true,
  },
  {
    label: 'Community',
    name: 'community',
    placeholder: 'Select community',
    width: '676px',
    isDynamicSearchDropdown: true,
    isMandatory: false,
  },
  {
    label: 'Start Date',
    name: 'startDate',
    width: '326px',
    height: '48px',
    placeholder: DATE_FORMAT,
    isMandatory: true,
    isDatePicker: true,
  },
  {
    label: 'End Date',
    name: 'endDate',
    width: '326px',
    height: '48px',
    placeholder: DATE_FORMAT,
    isMandatory: true,
    isDatePicker: true,
  },
];

export const invoceGenerationTableColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    width: 20,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'opposingParty',
    width: 20,
  },
  {
    Header: 'Billing Instance',
    accessor: 'billingInstance',
    width: 15,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    width: 10,
  },
  {
    Header: 'Date',
    accessor: 'serviceDate',
    width: 15,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 20,
    Cell: BillingStatusCell,
  },
];
