/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';
import { convertToQueryString } from '../../../utils/convertToQueryString';

export const getAddressesList = createAsyncThunk(
  'nonPaymentRequest/getAddressesList',
  async (params, { getState }) => {
    const { open, ...rest } = params;
    const { data } = await apiClient.post(`/cp/api/addresses/filter`, rest);

    const {
      nonPaymentRequest: { addressesList },
    } = getState();

    // if (!open && !addressesList) return null;

    const prevValue = addressesList?.prevSearchValue || '';

    if (!open && prevValue !== rest.address) {
      return {
        ...data.result,
        prevSearchValue: rest.address,
        items: data.result.items.map((item) => ({
          id: item.addressId,
          value: item.address,
          label: item.address,
        })),
      };
    }

    if (!open && addressesList) {
      return {
        ...data.result,
        prevSearchValue: rest.communityName,
        items: addressesList.items.concat(
          data.result.items.map((item) => ({
            id: item.addressId,
            value: item.address,
            label: item.address,
          })),
        ),
      };
    }

    return {
      ...data.result,
      prevSearchValue: rest.address,
      items: data.result.items.map((item) => ({
        id: item.addressId,
        value: item.address,
        label: item.address,
      })),
    };
  },
);

export const resetAddresses = createAction('nonPaymentRequest/resetAddresses', () => {
  return {
    type: 'nonPaymentRequest/resetAddresses',
  };
});

export const getAbbriviation = (courtName) => {
  switch (courtName) {
    case 'Las Vegas Justice Court':
      return 'LVT';
    case 'Henderson Justice Court':
      return 'HEN';
    case 'North Las Vegas Justice Court':
    case 'Fallon Justice Court':
    case 'Laughlin Justice Court':
    case 'Moapa Justice Court':
    case 'Tahoe Justice Court':
      return 'NLV';
    case 'Reno Justice Court':
      return 'RENO';
    case 'Sparks Justice Court':
      return 'SPARKS'; // the same as SPK
    default:
      return 'test';
  }
};

export const getAddressesListNV = createAsyncThunk(
  'nonPaymentRequest/getAddressesListNV',
  async (params, { getState }) => {
    const { open, ...rest } = params;
    const { data } = await apiClient.post(`/cp/api/nevada/requests/requestNotices/addresses`, {
      ...rest,
      headers: { 'Content-Type': 'application/json' },
    });
    const {
      nonPaymentRequest: { addressesList },
    } = getState();
    // if (!open && !addressesList) return null;
    const prevValue = addressesList?.prevSearchValue || '';
    if (!open && prevValue !== rest.address) {
      return {
        ...data.result,
        prevSearchValue: rest.address,
        items: data.result.items.map((item) => ({
          id: item.addressId,
          value: item.address,
          label: item.address,
        })),
        courts: (addressesList?.courts || []).concat(
          data.result.items.map((item) => ({
            addressId: item.addressId,
            courtName: item.courtName,
            hasGovernmentBackedLoan: item.hasGovernmentBackedLoan,
            abbreviation: getAbbriviation(item.courtName),
          })),
        ),
      };
    }
    if (!open && addressesList) {
      return {
        ...data.result,
        prevSearchValue: rest.communityName,
        items: addressesList.items.concat(
          data.result.items.map((item) => ({
            id: item.addressId,
            value: item.address,
            label: item.address,
          })),
        ),
        courts: (addressesList?.courts || []).concat(
          data.result.items.map((item) => ({
            addressId: item.addressId,
            courtName: item.courtName,
            hasGovernmentBackedLoan: item.hasGovernmentBackedLoan,
            abbreviation: getAbbriviation(item.courtName),
          })),
        ),
      };
    }

    return {
      ...data.result,
      prevSearchValue: rest.address,
      items: data.result.items.map((item) => ({
        id: item.addressId,
        value: item.address,
        label: item.address,
      })),
      courts: (addressesList?.courts || []).concat(
        data.result.items.map((item) => ({
          addressId: item.addressId,
          courtName: item.courtName,
          hasGovernmentBackedLoan: item.hasGovernmentBackedLoan,
          abbreviation: getAbbriviation(item.courtName),
        })),
      ),
    };
  },
);

export const getNonWorkingDates = createAsyncThunk(
  'nonPaymentRequest/getNonWorkingDates',
  async ({ year, month, stateCode }) => {
    const {
      data: { result },
    } = await apiClient.get(
      `/cp/api/states/${stateCode}/nonWorkingDays?${convertToQueryString({ year, month })}`,
    );
    return {
      dates: result,
    };
  },
);

export const createRequestNotice = createAsyncThunk(
  'nonPaymentRequest/createRequestNotice',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/cp/api/nevada/requests/requestNotices`, body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.errorMessage);
    }
  },
);

export const createArizonaRequestNotice = createAsyncThunk(
  'nonPaymentRequest/createArizonaRequestNotice',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(`/cp/api/arizona/requests/requestNotices`, body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.errorMessage);
    }
  },
);

export const bulkNoticeRequest = createAsyncThunk(
  'nonPaymentRequest/bulkNoticeRequest',
  async (body, { rejectWithValue }) => {
    try {
      const {
        data: { result },
      } = await apiClient.post(`/cp/api/nevada/requests/requestNotices/process`, body);

      return result.map((item) => ({
        addressId: item.addressId,
        courtName: item.courtName,
        hasGovernmentBackedLoan: item.hasGovernmentBackedLoan,
        abbreviation: getAbbriviation(item.courtName),
        addressName: item.address,
        defendantName: item.individuals.map((i) => `${i.firstName} ${i.lastName}`).join(', '),
        apartmentNumber: item.apartmentNumber,
        hasNoAppartmentNumber: item.hasNoApartmentNumber,
        individuals: item.individuals,
        entities: item.entities,
        lateFeeAssessed: item.lateFeeAssessed,
        rentBecameDelinquent: item.rentBecameDelinquent,
        lastPaymentDate: item.lastPaymentDate,
        periodicRent: item.periodicRent,
        currentAmountDue: item.currentAmountDue,
        pastAmountDue: item.pastAmountDue,
        lateFees: item.lateFees,
        lastPaymentAmount: item.lastPaymentAmount,
        totalOwed: item.totalOwed,
        errors: item.errors,
        __error: item.errors?.length > 0,
      }));
    } catch (error) {
      return rejectWithValue(error.response?.data?.errorMessage);
    }
  },
);

export const bulkAZNoticeRequest = createAsyncThunk(
  'nonPaymentRequest/bulkAZNoticeRequest',
  async (body, { rejectWithValue }) => {
    try {
      const {
        data: { result },
      } = await apiClient.post(`/cp/api/arizona/requests/requestNotices/process`, body);

      return result.map((item) => ({
        addressId: item.addressId,
        addressName: item.address,
        defendantName: item.individuals.map((i) => `${i.firstName} ${i.lastName}`).join(', '),
        apartmentNumber: item.apartmentNumber,
        hasNoAppartmentNumber: item.hasNoApartmentNumber,
        individuals: item.individuals,
        entities: item.entities,
        rentPerLeaseAgreement: item.rentPerLeaseAgreement,
        totalLateFee: item.totalLateFee,
        dailyLateFee: item.dailyLateFee,
        previousBalance: item.previousBalance,
        utilities: item.utilities,
        nsfFee: item.nsfFee,
        noticeFee: item.noticeFee,
        otherCharges: item.otherCharges,
        otherChargesDescription: item.otherChargesDescription,
        totalDue: item.totalDue,
        errors: item.errors,
        __error: item.errors?.length > 0,
      }));
    } catch (error) {
      return rejectWithValue(error.response?.data?.errorMessage);
    }
  },
);

const initialState = {
  addressesList: null,
  nonWorkingDates: [],
  error: null,
};

export const nonPaymentRequest = createSlice({
  name: 'nonPaymentRequest',
  initialState,
  reducers: {},
  extraReducers: {
    [getAddressesList.fulfilled]: (state, action) => {
      state.addressesList = action.payload;
    },
    [getAddressesListNV.fulfilled]: (state, action) => {
      state.addressesList = action.payload;
    },
    [getNonWorkingDates.fulfilled]: (state, action) => {
      state.nonWorkingDates = [...new Set(state.nonWorkingDates.concat(action.payload.dates))];
    },
    [getAddressesList.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [resetAddresses]: (state) => {
      state.addressesList = {};
    },
  },
});

export default nonPaymentRequest.reducer;
