import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Printer } from '../../../../assets/icons/Printer.svg';
import { ReactComponent as Trash } from '../../../../assets/icons/Trash.svg';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import { isInFuture } from '../../../../utils/dateHelpers';

export const PrintCell = ({ row: { id, original }, deleteRow, printRow }) => {
  const handleClickPrint = useCallback(() => {
    printRow(id);
  }, [id, printRow]);

  const handleClickDelete = useCallback(() => {
    deleteRow(id, original.fileName);
  }, [id, deleteRow]);

  const canDeleteDocument = useAllowed([rolesDB.Admin]);

  const isPrefileDateInFuture = original.prefileDate && isInFuture(original.prefileDate);

  return (
    <>
      <IconButton
        onClick={handleClickPrint}
        sx={{ visibility: isPrefileDateInFuture ? 'hidden' : 'visible' }}
        data-testid="print_button"
      >
        <Printer />
      </IconButton>
      {canDeleteDocument && (
        <IconButton onClick={handleClickDelete} data-testid="delete_button">
          <Trash />
        </IconButton>
      )}
    </>
  );
};

PrintCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    original: PropTypes.shape({
      fileName: PropTypes.string,
      prefileDate: PropTypes.string,
    }),
  }).isRequired,
  deleteRow: PropTypes.func.isRequired,
  printRow: PropTypes.func.isRequired,
};
