import React from 'react';
import { Box, styled } from '@mui/material';
import PropTypes from 'prop-types';
import SearchLabel from '../SearchLabel/SearchLabel';
import TextInput from '../../MUI/MUITextInput/TextInput';
import { useRandomId } from '../../../hooks/useRandomId';

const StyledSearchContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const SearchInputWithLabel = ({
  label,
  placeholder,
  name,
  onChangeValue,
  value,
  width,
  height,
  onEnter,
}) => {
  const handleChange = (e) => onChangeValue(e.target.value, name);

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && onEnter) {
      e.preventDefault();
      onEnter(e);
    }
  };

  const id = useRandomId();

  return (
    <StyledSearchContainer
      display="flex"
      alignItems="flex-start"
      height={height}
      data-testid="search_input_container"
    >
      <SearchLabel value={label} height={height} htmlFor={id} />
      <TextInput
        id={id}
        width={width}
        search
        height={height}
        name={name}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        disableUnderline
        shared
        inputProps={{ 'data-testid': 'search_input' }}
      />
    </StyledSearchContainer>
  );
};

SearchInputWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func,
  value: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.number,
  onEnter: PropTypes.func,
};

SearchInputWithLabel.defaultProps = {
  placeholder: '',
  onChangeValue: () => {},
  value: '',
  width: 130,
  height: 44,
  onEnter: null,
};

export default SearchInputWithLabel;
