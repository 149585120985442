import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { ReactComponent as CheckGreenIcon } from '../../../../../assets/icons/CheckmarkGreenRounded.svg';

export const DuplicateCell = ({ cell }) => {
  if (!cell.value) {
    return null;
  }

  return (
    <Stack display="flex" width="100%">
      <CheckGreenIcon />
    </Stack>
  );
};

DuplicateCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.bool.isRequired,
  }).isRequired,
};
