import React, { useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SearchInputWithLabelAndIcon from '../../../atoms/SearchInputWithLabelAndIcon/SearchInputWithLabelAndIcon';
import DatePickerWithLabel from '../../../atoms/DatePickerWithLabel/DatePickerWithLabel';
import MButton from '../../../MUI/Button/MButton';
import {
  getCourtNames,
  resetPageState,
  resetCourtNames,
  getAttorneysList,
  getAssignedAttorneysList,
  getCourtNamesWhenOpenDropdown,
} from '../../../../store/slices/schedule/attorneyAssignmentsSlice';
import {
  COURTS_PAGE_SIZE,
  INCOMPLETE_ASSIGNMENT,
  MULTIPLE_ATTORNEYS,
  UNASSIGNED,
  ATORNEY,
} from './AttorneyAssignments.constants';
import useSearchDropdown from '../../../../hooks/useSearchDropdown';
import LoaderCover from '../../../atoms/LoaderCover';

const AttorneyAssignmentsFilters = () => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const {
    courtNames,
    currentState,
    attorneysList,
    assignedAttorneysList,
    refreshPage,
    currentPage,
  } = useSelector((state) => state.attorneyAssignments);

  const [selectedAttorney, setSelectedAttorney] = useState(null);
  const [courtName, setCourtName] = useState(null);
  const [date, setDate] = useState(null);

  const isApplyDisabled = useMemo(() => {
    return !selectedAttorney && !courtName && !date;
  }, [selectedAttorney, courtName, date]);

  useEffect(() => {
    return () => dispatch(resetPageState());
  }, []);

  const { handleMenuScrollToBottom, changeInput } = useSearchDropdown({
    hasNextPage: courtNames?.hasNextPage,
    getAction: ({ input, page }) =>
      getCourtNames({
        currentPage: page,
        pageSize: COURTS_PAGE_SIZE,
        courtName: input,
      }),
  });

  const resetCourtNameDropdown = () => {
    dispatch(resetCourtNames());
  };

  const handleOpenCourtnameDropdown = () => {
    dispatch(
      getCourtNamesWhenOpenDropdown({
        currentPage: 1,
        pageSize: COURTS_PAGE_SIZE,
        courtName: '',
      }),
    );
  };

  const prepareRequestListParams = () => {
    let attorneyKey = '';

    switch (selectedAttorney?.id) {
      case ATORNEY[UNASSIGNED]:
      case ATORNEY[MULTIPLE_ATTORNEYS]:
      case ATORNEY[INCOMPLETE_ASSIGNMENT]: {
        attorneyKey = 'attorneyGroup';
        break;
      }
      default:
        attorneyKey = 'attorneyId';
        break;
    }

    const params = {};

    if (courtName?.id) {
      params.courtId = courtName?.id;
    }

    if (date) {
      params.courtDate = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }

    if (selectedAttorney?.id) {
      params[attorneyKey] = selectedAttorney?.id;
    }

    return params;
  };

  useEffect(() => {
    if (refreshPage) {
      setIsLoader(true);
      dispatch(
        getAssignedAttorneysList({
          ...prepareRequestListParams(),
          pageSize: 10,
          currentPage: 1,
          stateCode: currentState?.value,
        }),
      ).then(() => setIsLoader(false));
    }
  }, [refreshPage]);

  useEffect(() => {
    if (!assignedAttorneysList) return;

    if (currentPage !== assignedAttorneysList?.currentPage) {
      setIsLoader(true);
      dispatch(
        getAssignedAttorneysList({
          ...prepareRequestListParams(),
          pageSize: 10,
          currentPage,
          stateCode: currentState?.value,
        }),
      ).then(() => setIsLoader(false));
    }
  }, [currentPage]);

  useEffect(() => {
    setDate(null);
    setCourtName(null);
    setSelectedAttorney(null);

    dispatch(resetCourtNames());
    dispatch(getAttorneysList());
  }, [currentState]);

  const handleChangeCourtname = (value) => {
    setCourtName(value);
  };

  const handleChangeAttorney = (value) => {
    setSelectedAttorney(value);
  };

  const handleChangeDate = (value) => {
    setDate(value);
  };

  const handleClearFilters = () => {
    dispatch(resetPageState());
    setSelectedAttorney(null);
    setCourtName(null);
    setDate(null);
  };

  const handleApply = async () => {
    if (isApplyDisabled) return;
    setIsLoader(true);
    await dispatch(
      getAssignedAttorneysList({
        ...prepareRequestListParams(),
        pageSize: 10,
        currentPage: 1,
        stateCode: currentState?.value,
      }),
    );
    setIsLoader(false);
  };

  return (
    <Stack flex direction="initial" alignItems="center" mt={4} mb={4}>
      {isLoader && <LoaderCover />}
      <Stack flex direction="initial" gap={3}>
        <SearchInputWithLabelAndIcon
          label="Court Name"
          options={courtNames?.items}
          placeholder="Enter court name"
          onMenuScrollToBottom={handleMenuScrollToBottom}
          handleChange={handleChangeCourtname}
          handleSearch={changeInput}
          onMenuClose={resetCourtNameDropdown}
          onMenuOpen={handleOpenCourtnameDropdown}
          isDropdown
          value={courtName}
          width="288px"
          height={44}
          onEnter={handleApply}
        />
        <SearchInputWithLabelAndIcon
          label="Attorney"
          options={attorneysList}
          isDropdown
          value={selectedAttorney}
          placeholder="Enter attorney name"
          handleChange={handleChangeAttorney}
          width="288px"
          height={44}
          onEnter={handleApply}
        />
        <DatePickerWithLabel
          height={44}
          width="169px"
          value={date}
          label="Court Date"
          dateFormat="MM/DD/YY"
          forbidManualInput
          minDate={moment()}
          onChangeValue={handleChangeDate}
          onEnter={handleApply}
        />

        <Stack
          flex
          gap={1}
          mb="12px"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <MButton type="submit" onClick={handleApply} disabled={isApplyDisabled || isLoader}>
            Apply
          </MButton>
          <MButton variant="bordered" onClick={handleClearFilters} disabled={isLoader}>
            Clear Filter
          </MButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AttorneyAssignmentsFilters;
