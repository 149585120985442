import { LinearProgress, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { apiClient } from '../../../../../../lib/apiClient';
import NotificationDialog from '../../../../../molecules/NotificationDialog';
import MButton from '../../../../../MUI/Button/MButton';
import {
  CASES_TO_BE_ENTERED,
  NOTICES_TO_BE_DRAFTED,
} from '../DashboardLegalAssistantLists.constants';
import { rolesDB, useUserInfo } from '../../../../../../utils/roleHelpers';
import Dropdown from '../../../../../atoms/Dropdown';
import notificationUtils from '../../../../../../utils/notificationUtils';
import { BulkAssignToOtherDialog } from '../BulkAssignToOtherDialog';

const AssignToMeCell = ({
  row: { original },
  value,
  refreshPage,
  state: { pageIndex },
  card,
  stateCode,
}) => {
  const { caseId } = original;

  const [otherModalOpen, setOtherModalOpen] = useState(false);
  const { roles } = useUserInfo();
  const isRoleLA = roles.includes(rolesDB.LegalAssistant);
  const isRoleAdmin = roles.includes(rolesDB.Admin);
  const bothRoles = isRoleLA && isRoleAdmin;

  // temporary enable button for NOTICES_TO_BE_DRAFTED
  // disabling of this button will be in the next story
  const canBeAssignedToMe = card === NOTICES_TO_BE_DRAFTED || value;

  const [isLoader, setIsLoader] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [error, setError] = useState('');

  const refresh = () => refreshPage(pageIndex + 1);

  const handleClose = () => {
    setShowErrorDialog(false);
    refresh();
  };

  const handleClosed = () => setError('');

  const assignToMe = async () => {
    setIsLoader(true);
    const url = `/api/dashboards/legalAssistant/cards/${card}/${caseId}/assignToMe`;
    try {
      await apiClient.put(url, { id: caseId });
      notificationUtils.success('Assigned successfully');
      refresh();
    } catch (e) {
      console.log(e);
      setError(
        e.response.data?.errorMessage ||
          'Case has been recently reassigned. Please try again later.',
      );
      setShowErrorDialog(true);
    }
    setIsLoader(false);
  };

  const closeOtherModal = () => {
    setOtherModalOpen(false);
  };

  const assignToOther = async (chosenAssigneeId) => {
    setIsLoader(true);
    const url = `/api/dashboards/legalAssistant/cards/${card}/assignAssistant`;
    const ids = [caseId];

    try {
      await apiClient.put(url, { caseIds: ids, userId: chosenAssigneeId, stateCode });
      notificationUtils.success('Assigned successfully');
      refresh();
    } catch {
      notificationUtils.error('Failed, try again later');
    }
    closeOtherModal();
    setIsLoader(false);
  };

  const handleChange = (option) => {
    if (option.value === 'assignToMe') {
      assignToMe();
    } else if (option.value === 'assignToOther') {
      setOtherModalOpen(true);
    }
  };

  if (isLoader) {
    return (
      <Box sx={{ width: '130px' }}>
        <LinearProgress />
      </Box>
    );
  }

  const renderButton = () => {
    if (bothRoles) {
      const options = [
        { value: 'assignToMe', label: 'Assign To Me', id: 'assignToMe' },
        { value: 'assignToOther', label: 'Assign To Other', id: 'assignToOther' },
      ];
      return (
        <Dropdown
          isMandatory
          isColumn
          placeholder="Assign"
          width="100px"
          height="38px"
          options={options}
          value={null}
          onChange={handleChange}
          testid="assign_select"
          menuStyles={{ width: '150px', right: '0' }}
        />
      );
    }

    if (isRoleLA) {
      return (
        <MButton
          variant="secondary"
          size="small"
          disabled={!canBeAssignedToMe}
          onClick={assignToMe}
        >
          Assign to me
        </MButton>
      );
    }

    return (
      <MButton
        variant="secondary"
        size="small"
        disabled={!canBeAssignedToMe}
        onClick={() => setOtherModalOpen(true)}
        sx={{ width: '140px', whiteSpace: 'nowrap' }}
      >
        Assign to other
      </MButton>
    );
  };
  return (
    <>
      {otherModalOpen && (
        <BulkAssignToOtherDialog
          title={card === CASES_TO_BE_ENTERED ? 'Assign Cases' : 'Assign Notices'}
          onClose={closeOtherModal}
          handleSubmit={assignToOther}
          stateCode={stateCode}
        />
      )}
      {renderButton()}
      {showErrorDialog && (
        <NotificationDialog
          type="alert"
          desc={error}
          buttonPrimaryText="Ok"
          onClose={handleClose}
          onClosed={handleClosed}
          onConfirm={handleClose}
          isOpen
          width="400px"
        />
      )}
    </>
  );
};

AssignToMeCell.propTypes = {
  value: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  refreshPage: PropTypes.func.isRequired,
  state: PropTypes.shape({
    pageIndex: PropTypes.number.isRequired,
  }).isRequired,
  card: PropTypes.string.isRequired,
  stateCode: PropTypes.string.isRequired,
};

export default AssignToMeCell;
