import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, CircularProgress, Container, Stack, styled, Typography } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import Form from '../../../../atoms/Form';
import { palette } from '../../../../../theme/default';
import { editContact, getContact } from '../../../../../store/slices/editContactSlice';
import useContact from '../../../../../hooks/useContact';
import { contactBoxes, contactHeading } from './Contact.constants';
import { createContact } from '../../../../../store/slices/createContactSlice';
import Field from '../../../../atoms/Field';
import EditInputWithLabel from '../../../../molecules/EditInputWithLabel';
import CustomRadioGroup from '../../../../atoms/CustomRadioGroup/CustomRadioGroup';
import { useNavigationBlockPopup } from '../../../../../hooks/useNavigationBlockPopup';
import notificationUtils from '../../../../../utils/notificationUtils';
import LoaderCover from '../../../../atoms/LoaderCover';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';

const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

const Contact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    state: { navPage, subNav, contact },
  } = useLocation();

  const { contactId: id, clientUserId } = contact || {};

  const isClientUserContact = !id && clientUserId;

  const { popup, handleChangeBlockerByDefault } = useNavigationBlockPopup({
    blockerByDefault: !id && !isClientUserContact,
  });

  const { editableContact } = useSelector((state) => state.editContact);

  const { state, actions } = useContact();
  const [isPageLoader, setIsPageLoader] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const form = useForm({ defaultValues: state });
  const canUpdate = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);
  const canCreate = useAllowed([rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney]);

  useEffect(() => {
    if (id) {
      dispatch(getContact(id)).then(() => setIsPageLoader(false));
    } else {
      if (isClientUserContact) {
        form.reset({
          name: contact?.contactName,
          title: contact?.contactTitle,
          workPhone: contact?.contactWorkPhone,
          mobilePhone: contact?.contactMobilePhone,
          email: contact?.contactEmail,
        });
      }
      setIsPageLoader(false);
    }
  }, [id, isClientUserContact]);

  useEffect(() => {
    if (id) {
      form.reset({
        ...editableContact,
      });
    }
  }, [id, editableContact]);

  const handleSubmit = useCallback((formFields) => {
    setIsLoader(true);
    if (id) {
      dispatch(
        editContact({
          ...formFields,
          id,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(getContact(id));
          notificationUtils.success('Updated successfully');
        })
        .catch((rejectedValueError) => {
          notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
        })
        .finally(() => {
          setIsLoader(false);
        });
    } else {
      handleChangeBlockerByDefault(false);
      dispatch(
        createContact({
          ...formFields,
        }),
      )
        .unwrap()
        .then((originalPromiseResult) => {
          const { result } = originalPromiseResult;
          notificationUtils.success('Created successfully');
          navigate('/db/datahandling/editcontact/contact', {
            state: {
              navPage,
              subNav,
              id: result,
              name: formFields.name,
            },
          });
          actions?.clear();
          form.reset();
        })
        .catch((rejectedValueError) => {
          notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
        })
        .finally(() => {
          setIsLoader(false);
        });
    }
  }, []);

  const handleCancel = useCallback(() => {
    navigate('/db/datahandling/contacts', {
      state: {
        navPage,
        subNav,
      },
    });
  }, []);

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  const getIsDisabled = useCallback(
    (isDefaultDisabled = false) => {
      if (isClientUserContact) {
        return true;
      }
      return isDefaultDisabled;
    },
    [isClientUserContact],
  );

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      {popup}
      {!isPageLoader ? (
        <>
          {isLoader && <LoaderCover isFixed />}
          <Typography variant="h4">{contactHeading}</Typography>
          <Form onSubmit={handleSubmit} form={form}>
            {contactBoxes?.map((contactBox, index) => {
              return (
                <Container
                  key={`${index + 1}`}
                  maxWidth={false}
                  disableGutters
                  sx={{
                    mx: 'auto',
                    mt: 4,
                    width: 1,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={3}
                    rowGap={2}
                    flexWrap="wrap"
                  >
                    {contactBox.inputBoxes.map((item, contactBoxIndex) => {
                      if (item.isRadioButtons) {
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            render={({ field, onCustomChange }) => {
                              if (id && !canUpdate) {
                                return (
                                  <EditInputWithLabel
                                    type="text"
                                    label={item.label}
                                    width={item.width}
                                    name={item.name}
                                    value={
                                      item.data.find((i) => i.value === field.value)?.label || '-'
                                    }
                                    readOnly
                                  />
                                );
                              }
                              return (
                                <CustomRadioGroup
                                  data={item.data}
                                  disabled={getIsDisabled(item.isDisabled)}
                                  value={field.value}
                                  label={item.label}
                                  width={item.width}
                                  radioButtonWidth={item.radioButtonWidth}
                                  onChange={onCustomChange(field.onChange)}
                                  defaultValue={getRadioGroupDefaultValue(item.data)}
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item?.isSpace) {
                        return <div style={{ width: '100%' }} key={`${contactBoxIndex + 1}`} />;
                      }

                      if (item.isSubHeading) {
                        return (
                          <StyledTypography width={1} mb={1} variant="bodyL500" key={item.label}>
                            {item.label}
                          </StyledTypography>
                        );
                      }

                      return (
                        <Field
                          key={item.label}
                          name={item.name}
                          isMandatory={item.isMandatory}
                          render={({ field, error }) => (
                            <EditInputWithLabel
                              type="text"
                              label={item.label}
                              placeholder={item.placeholder}
                              width={item.width}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              error={error}
                              value={field.value}
                              onChange={field.onChange}
                              isDisabled={getIsDisabled(item.isDisabled)}
                              readOnly={id && !canUpdate}
                            />
                          )}
                        />
                      );
                    })}
                  </Stack>
                </Container>
              );
            })}
            {((id && canUpdate) || (!id && canCreate)) && (
              <Box
                position="absolute"
                left="50%"
                bottom={0}
                sx={{ transform: 'translateX(-50%)' }}
                zIndex={10}
                width={1}
                p={4}
                boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
              >
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <MButton
                    variant="secondary"
                    size="large"
                    onClick={handleCancel}
                    sx={{ width: '144px' }}
                    disabled={isLoader}
                  >
                    Cancel
                  </MButton>
                  <MButton
                    type="submit"
                    size="large"
                    name="Submit contact"
                    sx={{ width: '144px' }}
                    disabled={isLoader || isClientUserContact}
                  >
                    {id ? 'Update' : 'Create'}
                  </MButton>
                </Stack>
              </Box>
            )}
          </Form>
        </>
      ) : (
        <Box
          position="absolute"
          top="50vh"
          left="50%"
          zIndex={10}
          transform="translate(-50%, -50%)"
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default Contact;
