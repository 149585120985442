import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TextEllipsis = ({ value }) => {
  return (
    <Typography
      sx={{
        textAlign: 'right',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        fontSize: '16px',
        fontWeight: 500,
      }}
    >
      {value}
    </Typography>
  );
};

TextEllipsis.propTypes = {
  value: PropTypes.string,
};

TextEllipsis.defaultProps = {
  value: '',
};

export default TextEllipsis;
