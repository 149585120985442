import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jwt-decode';
import { Avatar, Stack, Typography } from '@mui/material';
import MButton from '../../MUI/Button/MButton';
import TextField from '../../MUI/MUITextInput';
import { tokenRepository } from '../../../lib/apiClient';
import MTooltip from '../../atoms/MTooltip';
import { stringAvatar } from '../../../utils/stringAvatar';

const AddComment = ({ title, inputPlaceholder, buttonText, onAddComment, tooltip }) => {
  const [comment, setComment] = useState('');

  const token = tokenRepository.getToken();
  const { FirstName, LastName } = jwt(token);

  const userName = `${FirstName} ${LastName}`;

  const handleChange = useCallback((event) => {
    setComment(event.target.value);
  }, []);

  const handleClickAddComment = useCallback(() => {
    onAddComment?.(comment);
    setComment('');
  }, [comment, onAddComment]);

  const { sx, children } = stringAvatar(userName);

  return (
    <Stack spacing={3}>
      <Typography variant="h5" data-testid="add_comment_title">
        {title}
        {tooltip && <MTooltip text={tooltip} />}
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={4}
        placeholder={inputPlaceholder}
        id="add-comment-input"
        value={comment}
        onChange={handleChange}
        inputProps={{ 'data-testid': 'add_comment_input' }}
      />
      <Stack direction="row" justifyContent="space-between">
        <Stack spacing={2} direction="row" alignItems="center">
          <Avatar sx={{ width: 32, height: 32, fontSize: 14, ...sx }}>{children}</Avatar>
          {!!userName && (
            <Typography variant="bodyL500" color="text.primary" data-testid="add_comment_username">
              {userName}
            </Typography>
          )}
        </Stack>
        <MButton
          onClick={handleClickAddComment}
          disabled={!comment}
          data-testid="add_comment_button"
        >
          {buttonText}
        </MButton>
      </Stack>
    </Stack>
  );
};

AddComment.propTypes = {
  title: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string,
  tooltip: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onAddComment: PropTypes.func.isRequired,
};

AddComment.defaultProps = {
  tooltip: '',
  inputPlaceholder: '',
};

export default AddComment;
