/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';
import { ATORNEY } from '../../../components/pages/Schedule/AttorneyAssignments/AttorneyAssignments.constants';

export const getStates = createAsyncThunk('attorneyAssignmentsSlice/getStates', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data.result.map((item) => ({
    id: item.id,
    value: item.code,
    label: item.code,
  }));
});

export const setCurrentState = createAction(
  'attorneyAssignmentsSlice/setCurrentState',
  (value) => ({
    type: 'attorneyAssignmentsSlice/setCurrentState',
    payload: value,
  }),
);

export const refreshPage = createAction('attorneyAssignmentsSlice/refreshPage', (value) => ({
  type: 'attorneyAssignmentsSlice/refreshPage',
  payload: value,
}));

export const resetSliceState = createAction('attorneyAssignmentsSlice/resetSliceState', () => ({
  type: 'attorneyAssignmentsSlice/resetSliceState',
}));

export const callNewPage = createAction('attorneyAssignmentsSlice/callNewPage', (page) => ({
  type: 'attorneyAssignmentsSlice/callNewPage',
  payload: page,
}));

export const setNewCourtAttorney = createAction(
  'attorneyAssignmentsSlice/setNewCourtAttorney',
  (value) => ({
    type: 'attorneyAssignmentsSlice/setNewCourtAttorney',
    payload: value,
  }),
);

export const resetPageState = createAction('attorneyAssignmentsSlice/resetPageState', () => ({
  type: 'attorneyAssignmentsSlice/resetPageState',
}));

export const getCourtNamesWhenOpenDropdown = createAsyncThunk(
  'attorneyAssignmentsSlice/getCourtNamesWhenOpenDropdown',
  async (params, { getState }) => {
    const { attorneyAssignments } = getState();
    const { currentState } = attorneyAssignments;

    const reqParams = {
      ...params,
      stateCode: currentState?.value,
    };

    const { data } = await apiClient.post(`/api/courts/filter`, reqParams);

    const mappedItems = data.result.items.map((item) => ({
      id: item.courtId,
      value: item.courtName,
      label: item.courtName,
    }));

    return {
      courtNames: {
        ...data.result,
        // eslint-disable-next-line
        items: mappedItems,
      },
      prevCourtSearchValue: '',
    };
  },
);

export const getCourtNames = createAsyncThunk(
  'attorneyAssignmentsSlice/getCourtNames',
  async (params, { getState }) => {
    const { attorneyAssignments } = getState();
    const { currentState, prevCourtSearchValue, courtNames } = attorneyAssignments;

    const reqParams = {
      ...params,
      stateCode: currentState?.value,
    };

    const { data } = await apiClient.post(`/api/courts/filter`, reqParams);

    const mappedItems = data.result.items.map((item) => ({
      id: item.courtId,
      value: item.courtName,
      label: item.courtName,
    }));

    // NEED FOR SEARCH WHEN USER IS TYPING IN INPUT FIELD.
    if (prevCourtSearchValue !== params.courtName) {
      return {
        courtNames: {
          ...data.result,
          items: mappedItems,
        },
        prevCourtSearchValue: params.courtName || '',
      };
    }

    // NEED WHEN USER IS SCROLLING DOWN THE MENU.
    return {
      courtNames: {
        ...data.result,
        // eslint-disable-next-line
        items: [...(courtNames?.items || []), ...mappedItems],
      },
      prevCourtSearchValue: params.courtName || '',
    };
  },
);

export const resetCourtNames = createAction('attorneyAssignmentsSlice/resetCourtNames', () => ({
  type: 'attorneyAssignmentsSlice/resetCourtNames',
}));

export const getAttorneysList = createAsyncThunk(
  'attorneyAssignmentsSlice/getAttorneyAssignmentsCourts',
  async () => {
    const { data } = await apiClient.get('/api/cases/attorneys');

    const list = data.result.map((item) => ({
      id: item.userId,
      value: `${item.firstName} ${item.lastName}`,
      label: `${item.firstName} ${item.lastName}`,
    }));

    const attorneysKeys = Object.keys(ATORNEY);

    const hardcodedAttorneys = attorneysKeys.map((key) => ({
      id: ATORNEY[key],
      value: ATORNEY[key],
      label: ATORNEY[key],
    }));

    const hardcodedAttorneysDisabled = attorneysKeys.map((key) => ({
      id: ATORNEY[key],
      value: ATORNEY[key],
      label: ATORNEY[key],
      isDisabled: true,
    }));

    return {
      filterList: hardcodedAttorneys.concat(list),
      accordionList: hardcodedAttorneysDisabled.concat(list),
    };
  },
);

export const getAssignedAttorneysList = createAsyncThunk(
  'attorneyAssignmentsSlice/getAssignedAttorneysList',
  async (params) => {
    const { stateCode } = params;

    const api =
      stateCode === 'AZ'
        ? '/api/arizonaCases/assignedAttorneys/filter'
        : '/api/nevadaCases/assignedAttorneys/filter';

    const response = await apiClient.post(api, params);
    return response.data.result;
  },
);

export const editAssignedAttorneysList = createAsyncThunk(
  'attorneyAssignmentsSlice/editAssignedAttorneysList',
  async (params, { rejectWithValue }) => {
    try {
      const {
        stateCode: { value },
        attorneyAssignments,
      } = params;

      const api =
        value === 'AZ'
          ? '/api/arizonaCases/assignedAttorneys'
          : '/api/nevadaCases/assignedAttorneys';

      const response = await apiClient.put(api, { attorneyAssignments });
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

const initialState = {
  currentPage: 1,
  states: [],
  courtNames: null,
  currentState: null,
  refreshPage: false,
  attorneysList: null,
  judgementsList: null,
  newCourtAttorney: {},
  assignedAttorneysList: null,
  accordionAttorneysList: null,
  showNotFound: false,
  showUseFiltersMessage: true,
  prevCourtSearchValue: '',
  error: null,
};

export const attorneyAssignmentsSlice = createSlice({
  name: 'attorneyAssignmentsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [resetSliceState]: (state) => {
      state.currentPage = 1;
      state.states = [];
      state.courtNames = null;
      state.currentState = null;
      state.refreshPage = false;
      state.attorneysList = null;
      state.judgementsList = null;
      state.newCourtAttorney = {};
      state.assignedAttorneysList = null;
      state.accordionAttorneysList = null;
      state.showNotFound = false;
      state.showUseFiltersMessage = true;
      state.prevCourtSearchValue = '';
      state.error = null;
    },
    [resetPageState]: (state) => {
      state.assignedAttorneysList = null;
      state.showUseFiltersMessage = true;
    },
    [callNewPage]: (state, action) => {
      state.currentPage = action.payload;
    },
    [setCurrentState]: (state, action) => {
      state.currentState = action.payload;
    },
    [refreshPage]: (state, action) => {
      state.refreshPage = action.payload;
    },
    [setNewCourtAttorney]: (state, action) => {
      state.newCourtAttorney = action.payload;
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload;
    },
    [editAssignedAttorneysList.fulfilled]: (state) => {
      state.newCourtAttorney = {};
    },
    [editAssignedAttorneysList.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getAssignedAttorneysList.fulfilled]: (state, action) => {
      state.showNotFound = action.payload.items.length === 0;
      state.assignedAttorneysList = action.payload;
      state.showUseFiltersMessage = false;
      state.refreshPage = false;
    },
    [getStates.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCourtNames.fulfilled]: (state, action) => {
      state.prevCourtSearchValue = action.payload.prevCourtSearchValue;
      state.courtNames = action.payload.courtNames;
    },
    [getCourtNamesWhenOpenDropdown.fulfilled]: (state, action) => {
      state.prevCourtSearchValue = action.payload.prevCourtSearchValue;
      state.courtNames = action.payload.courtNames;
    },
    [resetCourtNames]: (state) => {
      state.courtNames = null;
    },
    [getAttorneysList.fulfilled]: (state, action) => {
      state.attorneysList = action.payload.filterList;
      state.accordionAttorneysList = action.payload.accordionList;
    },
  },
});

export default attorneyAssignmentsSlice.reducer;
