import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as arizonaAddressesSlice from '../../../../../store/slices/addresses/arizonaAddressesSlice';
import * as nevadaAddressesSlice from '../../../../../store/slices/addresses/nevadaAddressesSlice';
import {
  getJusticeFeesAndCosts,
  getSuperiorFeesAndCosts,
} from '../../../../../store/slices/courts/arizonaCourtsSlice';
import { getFeesAndCosts } from '../../../../../store/slices/courts/nevadaCourtsSlice';

export const useStateSpecificStore = ({ stateCode }) => {
  const arizonaCourts = useSelector((state) => state.arizonaCourts);
  const nevadaCourts = useSelector((state) => state.nevadaCourts);

  const data = useMemo(() => {
    if (stateCode === 'NV') {
      return {
        getFeesAndCosts,
        feesAndCosts: nevadaCourts.getFeesAndCosts,
      };
    }
    if (stateCode === 'AZ') {
      return {
        getJusticeFeesAndCosts,
        justiceFeesAndCosts: arizonaCourts.getJusticeFeesAndCosts,
        getSuperiorFeesAndCosts,
        superiorFeesAndCosts: arizonaCourts.getSuperiorFeesAndCosts,
      };
    }
    return {};
  }, [stateCode, arizonaCourts, nevadaCourts]);

  return data;
};

export const useAddressStateSpecificStore = ({ stateCode }) => {
  const arizonaAddresses = useSelector((state) => state.arizonaAddresses);
  const nevadaAddresses = useSelector((state) => state.nevadaAddresses);

  const data = useMemo(() => {
    if (stateCode === 'NV') {
      return {
        address: nevadaAddresses.getAddress,
        addressId: nevadaAddresses.addressId,
        editAddressError: nevadaAddresses.editAddressError,

        getAddress: nevadaAddressesSlice.getAddress,
        updateAddress: nevadaAddressesSlice.updateAddress,
        createAddress: nevadaAddressesSlice.createAddress,
        resetEditAddressError: nevadaAddressesSlice.resetEditAddressError,
      };
    }
    if (stateCode === 'AZ') {
      return {
        address: arizonaAddresses.getAddress,
        addressId: arizonaAddresses.addressId,
        editAddressError: arizonaAddresses.editAddressError,

        getAddress: arizonaAddressesSlice.getAddress,
        updateAddress: arizonaAddressesSlice.updateAddress,
        createAddress: arizonaAddressesSlice.createAddress,
        resetEditAddressError: arizonaAddressesSlice.resetEditAddressError,
      };
    }
    return {};
  }, [stateCode, nevadaAddresses, arizonaAddresses]);

  return data;
};
