import React from 'react';

import TextField from '@mui/material/TextField';
import { styled, Stack, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import { palette } from '../../../theme/default/palette';
import { ReactComponent as Asterisk } from '../../../assets/icons/asterisk.svg';

const StyledInputLabel = styled(InputLabel)({
  '&': {
    height: '24px',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '4px',
    color: palette.text.secondary,
  },
});

const StyledSpan = styled('span')({
  position: 'relative',
  '& svg': {
    position: 'absolute',
    fill: palette.additional.alert,
    height: '6px',
    right: '-8px',
    top: 0,
  },
});

const StyledTextField = styled(TextField)(({ width }) => ({
  width,
  '& textarea': {
    fontSize: '16px',
    fontWeight: 500,
  },
  '& .MuiInputBase-root': {
    minHeight: '48px',
    borderRadius: '10px',
    padding: '12px',
  },
  '& .MuiInputBase-root:disabled': {
    color: 'red',
  },
  '& .Mui-disabled': {
    borderRadius: '10px',
  },
}));

const MultilineTextField = ({
  label,
  width,
  placeholder,
  value,
  disabled,
  isMandatory,
  rows,
  error,
  onChange,
  maxLength,
}) => {
  const handleChange = (e) => {
    if (onChange) onChange(e);
  };

  return (
    <Stack direction="column" position="relative" width={width || '100%'}>
      <StyledInputLabel data-testid="multiline_text_field_label">
        {label}
        {isMandatory && (
          <StyledSpan data-testid="multiline_text_field_asterisk">
            <Asterisk />
          </StyledSpan>
        )}
      </StyledInputLabel>
      <StyledTextField
        error={error}
        disabled={disabled}
        width={width}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        multiline
        rows={rows}
        sx={{
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: palette.text.disabledText,
          },
        }}
        inputProps={{
          'data-testid': 'multiline_text_field_input',
          maxLength: maxLength || Infinity,
        }}
      />
      {error?.message && (
        <Typography
          variant="bodyXS"
          color="additional.alert"
          component="span"
          position="absolute"
          left={0}
          top="calc(100% + 4px)"
          data-testid="multiline_text_field_error_msg"
        >
          {error?.message}
        </Typography>
      )}
    </Stack>
  );
};

MultilineTextField.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  rows: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
};

MultilineTextField.defaultProps = {
  error: null,
  maxLength: Infinity,
};

export default MultilineTextField;
