/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { prop, sortBy } from 'ramda';
import { apiClient } from '../../../lib/apiClient';

export const getCourtDates = createAsyncThunk('addCourtDates/getCourtDates', async (countyId) => {
  const {
    data: { result },
  } = await apiClient.get(`/api/courts/dates?CountyId=${countyId}`);
  return result;
});

export const updateCourtDates = createAsyncThunk('addCourtDates/updateCourtDates', async (body) => {
  const { data } = await apiClient.put(`/api/courts/dates`, body);
  return data;
});

export const getCounties = createAsyncThunk('addCourtDates/counties', async () => {
  const { data } = await apiClient.get(`/api/counties`);
  return data;
});

export const resetCourtDates = createAction('addCourtDates/resetCourtDates', () => ({
  type: 'addCourtDates/resetCourtDates',
}));

const initialState = {
  courtDates: [],
  counties: [],
  error: null,
};

export const addCourtDatesSlice = createSlice({
  name: 'addCourtDates',
  initialState,
  reducers: {},
  extraReducers: {
    [getCourtDates.fulfilled]: (state, action) => {
      state.courtDates = sortBy(prop('courtName'), action.payload);
    },
    [resetCourtDates]: (state) => {
      state.courtDates = [];
    },
    [getCourtDates.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCounties.fulfilled]: (state, action) => {
      state.counties = sortBy(
        prop('label'),
        action.payload.result.map((item) => ({
          id: item.id,
          value: item.id,
          label: item.name,
          stateCode: item.stateCode,
        })),
      );
    },
    [getCounties.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateCourtDates.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default addCourtDatesSlice.reducer;
