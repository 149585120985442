import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Typography, useTheme } from '@mui/material';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';

const DateCell = ({ cell, row, updateValue }) => {
  const { value, column } = cell;
  const dateInitial = moment(value);
  const isValid = dateInitial.isValid();
  const [input, setInput] = useState(value);
  const theme = useTheme();
  const [isEdit, setIsEdit] = useState(false);
  const ref = useRef();
  const inputRef = useRef();
  const { addressesList } = useSelector((state) => state.nonPaymentRequest);
  const chosenAddressCourtInfo = addressesList?.courts?.find(
    (item) => item.addressId === row.original.addressId,
  ) || {
    abbreviation: row.original.abbreviation,
    addressId: row.original.addressId,
    courtName: row.original.courtName,
    hasGovernmentBackedLoan: row.original.hasGovernmentBackedLoan,
  };
  const courtArray = ['HEN', 'NLV', 'RENO', 'SPARKS'];
  const isDisabled =
    ((chosenAddressCourtInfo?.abbreviation === 'LVT' &&
      !chosenAddressCourtInfo?.hasGovernmentBackedLoan) ||
      courtArray.includes(chosenAddressCourtInfo?.abbreviation)) &&
    column.id === 'lastPaymentDate';
  const error = row.original?.errors?.[column.id];
  const touched = row.original?.touched?.[column.id];

  useEffect(() => {
    if (value !== input) {
      setInput(value);
    }
    setIsEdit(false);
  }, [column.id, row.original.id]);

  useEffect(() => {
    if (!row.original?.id) return;
    if (isDisabled) {
      setIsEdit(false);
      setInput(null);
      updateValue(row.original.id, { id: column.id, value: null, error: undefined });
    } else if (!isValid && touched) {
      setInput(null);
      updateValue(row.original.id, {
        id: column.id,
        value: null,
        error: 'Invalid date',
        touched: true,
      });
    }
  }, [isDisabled, row.original.id, column.id]);

  const checkIsValid = useCallback(
    (currentValue) => {
      const date = moment(currentValue);
      const isValidDate = date.isValid();
      const valueString = `${moment(currentValue).format('yyyy-MM-DD')}T00:00:00`;
      if (!row.original?.id) return false;
      setInput(`${moment(currentValue).format('yyyy-MM-DD')}`);
      if (isValidDate) {
        if (inputRef.current) {
          inputRef.current.blur();
        }
        setIsEdit(false);

        updateValue(row.original.id, {
          id: column.id,
          value: valueString,
          error: undefined,
          touched: true,
        });
      } else {
        setIsEdit(true);
        updateValue(row.original.id, {
          id: column.id,
          value: valueString,
          error: 'Invalid date',
          touched: true,
        });
      }
      return isValidDate;
    },
    [column.id, row.original.id],
  );

  const handleChange = useCallback(
    (selected) => {
      ref.current = selected;
    },
    [row.original.id],
  );

  const handleFocus = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleClose = useCallback(() => {
    checkIsValid(ref.current);
  }, [checkIsValid, ref.current, row.original.id, column.id]);

  const handleBlur = useCallback(
    (ev) => {
      if (ev.relatedTarget?.childNodes[0]?.id !== 'calendar') {
        checkIsValid(ev.target.value);
        ref.current = ev.target.value;
      }
    },
    [error, row.original.id, column.id],
  );

  const onKeyDown = (ev) => {
    if (ev.key === 'Tab') {
      handleBlur(ev);
    }
  };

  return (
    <DateOrTimePicker
      name="date_picker"
      width="160px"
      selected={input}
      // eslint-disable-next-line
      Svg={isEdit ? () => <Calender id="calendar" /> : () => <div />}
      isDate
      error={error && touched ? { message: error } : null}
      setSelectedTimeOrDate={handleChange}
      height="40px"
      disabled={isDisabled}
      placeholder="mm/dd/yyyy"
      onClose={handleClose}
      customStyles={
        isEdit
          ? {
              error: { display: 'none' },
              '& input::placeholder': { color: theme.palette.text.primary, opacity: 1 },
            }
          : {
              error: { display: 'none' },
              '& fieldset': { border: 'none' },
              '.Mui-disabled': { backgroundColor: 'transparent' },
              '& input::placeholder': { color: theme.palette.text.primary, opacity: 1 },
            }
      }
      inputProps={{
        onBlur: handleBlur,
        onFocus: handleFocus,
        ref: inputRef,
        onKeyDown,
        helperText:
          error && touched ? (
            <Typography
              sx={{ position: 'absolute', left: '2px', bottom: '-15px', fontSize: '12px' }}
              variant="caption"
              color="error"
            >
              {error}
            </Typography>
          ) : (
            ''
          ),
      }}
    />
  );
};

DateCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    column: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({
      id: PropTypes.number,
      errors: PropTypes.shape({}),
      addressId: PropTypes.number,
      touched: PropTypes.shape({}),
      hasGovernmentBackedLoan: PropTypes.bool,
      abbreviation: PropTypes.string,
      courtName: PropTypes.string,
    }),
  }).isRequired,
  updateValue: PropTypes.func.isRequired,
};

DateCell.defaultProps = {
  cell: {
    value: '',
    column: {
      id: '',
    },
  },
};

const customComparator = (prevProps, nextProps) => {
  return (
    nextProps.cell.value === prevProps.cell.value &&
    isEqual(nextProps.row.original.errors, prevProps.row.original.errors) &&
    isEqual(nextProps.row.original.touched, prevProps.row.original.touched) &&
    nextProps.row.original.addressId === prevProps.row.original.addressId &&
    nextProps.row.original.id === prevProps.row.original.id &&
    nextProps.cell.column.id === prevProps.cell.column.id
  );
};

export default React.memo(DateCell, customComparator);
