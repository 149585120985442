import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../atoms/Tag';
import { ALL_STATUSES } from '../../../../global/constants';
import { palette } from '../../../../theme/default';

export const StatusCell = ({ value: status }) => {
  const backgroundColor = useMemo(() => {
    if (!status) return palette.statuses.transparent;
    const statusOption = ALL_STATUSES.find((o) => o.value === status);
    if (statusOption) return statusOption.color;
    return palette.statuses.transparent;
  }, [status]);

  return <Tag backgroundColor={backgroundColor} text={status} />;
};

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};
