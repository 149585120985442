import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { palette } from '../../../../theme/default';

const Wrapper = styled(Button)(({ theme, isActive }) => ({
  position: 'relative',
  padding: '0 24px',
  border: `1px solid ${theme.palette.additional.lines}`,
  borderRadius: '16px',
  textTransform: 'none',
  justifyContent: 'flex-start',
  width: '326px',
  maxHeight: '164px',
  ...(isActive && { background: theme.palette.text.disabled }),

  '&:hover': {
    background: theme.palette.text.disabled,
  },
}));

const ClientPortalDashboardCard = ({ title, icon, count, isActive, onClick, isDisabled, base }) => {
  return (
    <Wrapper isActive={isActive} onClick={onClick} disableRipple disabled={isDisabled}>
      <Stack justifyContent="space-evenly" height="100%">
        <Box display="flex" alignItems="center">
          {icon}
          <Typography variant="h5" marginLeft={1} color={palette.text.primary}>
            {title}
          </Typography>
        </Box>
        <Box color={palette.text.secondary} display="inline" textAlign="left">
          <Typography fontWeight={700} fontSize={32} component="span" paddingRight={1}>
            {count}
          </Typography>
          <Typography component="span" variant="bodyL500">
            {`${base}${count === 1 ? '' : 's'}`}
          </Typography>
        </Box>
      </Stack>
    </Wrapper>
  );
};

ClientPortalDashboardCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  base: PropTypes.string.isRequired,
};

ClientPortalDashboardCard.defaultProps = { icon: null, isActive: false, isDisabled: false };

export default ClientPortalDashboardCard;
