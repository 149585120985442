import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack, Box } from '@mui/material';
import MButton from '../../MUI/Button/MButton';
import Table from '../../atoms/Table/Table';
import Searcher from '../../molecules/Searcher';
import { getContacts } from '../../../store/slices/contactsSlice';
import { contactsColumns } from './DialogContacts.constants';
import MDialog from '../../atoms/MDialog';
import LoaderCover from '../../atoms/LoaderCover';
import EmptyBlock from '../../molecules/EmptyBlock';

const DialogContacts = ({
  isOpen,
  title,
  buttonSecondaryText,
  buttonPrimaryText,
  onClose,
  onConfirm,
}) => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const contacts = useSelector((state) => state.contacts.contacts);

  useEffect(() => {
    if (isOpen) dispatch(getContacts()).then(() => setIsLoader(false));
  }, [isOpen]);

  const handleCheckedRow = useCallback((selectedRows) => {
    setSelectedIds(selectedRows.map((item) => ({ ...item })));
  }, []);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm?.(
      selectedIds.map((item) => ({
        contactId: item.contactId,
        name: item.contactName,
        title: item.contactTitle,
        workPhone: item.contactWorkPhone,
        mobilePhone: item.contactMobilePhone,
        email: item.contactEmail,
      })),
    );
  }, [selectedIds, onConfirm]);

  const handleChangeEmpty = useCallback((searchElement) => {
    if (!searchElement) {
      setIsLoaderTable(true);
      dispatch(getContacts({})).then(() => setIsLoaderTable(false));
    }
  }, []);

  const handleSearch = useCallback((searchElement) => {
    setIsLoaderTable(true);
    dispatch(getContacts({ contactName: searchElement })).then(() => {
      setIsLoaderTable(false);
      setIsLoader(false);
    });
  }, []);

  return (
    <MDialog
      onClose={onClose}
      title={title}
      titleVariant="h4"
      isOpen={isOpen}
      maxWidth="xl"
      fullWidth
      scrollable
      footerActions={
        <>
          {buttonSecondaryText && (
            <MButton
              data-testid="secondary_btn"
              variant="secondary"
              size="large"
              onClick={handleClose}
              sx={{ width: '104px' }}
            >
              {buttonSecondaryText}
            </MButton>
          )}
          {buttonPrimaryText && (
            <MButton
              size="large"
              onClick={handleConfirm}
              sx={{ width: '104px' }}
              disabled={!selectedIds.length}
              data-testid="primary_btn"
            >
              {buttonPrimaryText}
            </MButton>
          )}
        </>
      }
    >
      <Stack spacing={4}>
        <Searcher
          inputPlaceholder="Search by Contact Person"
          onSearch={handleSearch}
          onChange={handleChangeEmpty}
        />
        <Box height="calc(35vh + 72px + 24px)" width="100%" position="relative">
          {isLoader && <LoaderCover />}
          {!isLoader &&
            (contacts?.items?.length ? (
              <Table
                columns={contactsColumns}
                rows={contacts?.items}
                total={contacts?.items?.length}
                loading={isLoaderTable}
                onCheckedRow={handleCheckedRow}
                isWithCheckbox
                maxHeight="35vh"
              />
            ) : (
              <Box
                data-testid="empty_block_wrapper_dialog"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <EmptyBlock title="Contact persons weren't found" desc="Сhange the search value" />
              </Box>
            ))}
        </Box>
      </Stack>
    </MDialog>
  );
};

DialogContacts.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

DialogContacts.defaultProps = {
  isOpen: false,
  title: '',
  buttonSecondaryText: '',
  buttonPrimaryText: '',
  onClose: null,
  onConfirm: null,
};

export default DialogContacts;
