import React, { useCallback, useEffect, useState } from 'react';
import { styled, Typography, Dialog, Stack, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { palette } from '../../../../theme/default';
import MButton from '../../../MUI/Button/MButton';
import { userEditFormFields } from './users.constants';
import Dropdown from '../../../atoms/Dropdown';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup/CustomRadioGroup';
import { ReactComponent as CopySvg } from '../../../../assets/icons/Copy.svg';
import { getAvailableStates, getAvailableRoles } from '../../../../store/slices/adminUsersSlice';
import Password from '../../../atoms/Password/Password';
import Field from '../../../atoms/Field';
import Form from '../../../atoms/Form';
import { validateDialogForm } from './validateDialogForm';
import { amendZeros } from '../../../../utils/parseNumberFromString';
import CheckboxSelect from '../../../atoms/CheckboxSelect';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    padding: 32,
    borderRadius: 24,
    boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
    maxWidth: '741px',
  },
});

const AddNewUserDialog = ({
  isOpen,
  title,
  buttonSecondaryText,
  buttonPrimaryText,
  onClose,
  onConfirm,
  confirmError,
}) => {
  const { roles, states, editableUser } = useSelector((state) => state.adminUsers);
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: editableUser,
  });

  const { handleSubmit, reset, setError, watch } = form;

  const passwordVal = watch('password');
  const confirmPasswordVal = watch('confirmPassword');

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (!editableUser) return;
    reset({
      ...editableUser,
      stateCodes:
        editableUser?.stateCodes?.map((item) => ({
          value: item,
          label: item,
          id: item,
        })) ?? [],
      roleIds:
        editableUser?.roles?.map((item) => ({
          id: item.roleId,
          value: item.roleId,
          label: item.roleName,
        })) ?? [],
      attorneyRate: editableUser.attorneyRate ? `$${amendZeros(editableUser.attorneyRate)}` : null,
    });

    setIsEditMode(editableUser?.userId);
  }, [roles, states, editableUser]);

  useEffect(() => {
    if (!roles.length) {
      dispatch(getAvailableRoles());
    }
    if (!states.length) {
      dispatch(getAvailableStates());
    }
  }, []);

  useEffect(() => {
    if (confirmPasswordVal && !passwordVal) {
      return setError('confirmPassword', { message: 'Passwords do not match' });
    }
    if (confirmPasswordVal && passwordVal && confirmPasswordVal !== passwordVal) {
      return setError('confirmPassword', { message: 'Passwords do not match' });
    }
    return setError('confirmPassword', { message: '' });
  }, [confirmPasswordVal, passwordVal]);

  useEffect(() => {
    if (!confirmError) return;

    const keys = Object.keys(confirmError);
    keys.forEach((fieldName) => setError(fieldName, { message: confirmError[fieldName] }));
  }, [confirmError]);

  const getOptions = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case 'roleIds':
          return roles;
        case 'stateCodes':
        case 'primaryStateCode':
          return states;
        default:
          return [];
      }
    },
    [roles, states],
  );

  const getOnChange = useCallback((name, onChange) => {
    switch (name) {
      case 'attorneyRate':
        return ({ target: { value } }) => {
          const amount = /\d+\.?\d{0,2}/.exec(value);
          if (!amount || !amount?.length) {
            form.setValue('attorneyRate', '');
          } else {
            form.setValue('attorneyRate', `$${amount[0]}`);
          }
        };
      case 'stateCodes':
        return (value) => {
          form.setValue('stateCodes', value);
          if (value.length === 1) {
            form.setValue('primaryStateCode', value[0].value);
          }
        };
      case 'primaryStateCode':
        return ({ value }) => {
          const state = states.find((item) => item.value === value);
          form.setValue('primaryStateCode', state);
        };
      default:
        return onChange;
    }
  }, []);

  const selectedRoles = watch('roleIds') ?? [];
  const selectedState = watch('stateCodes') ?? [];

  const getMandatory = useCallback(
    (name) => {
      switch (name) {
        case 'attorneyRate':
          // case 'attorneyQuickBookId':
          return Boolean(selectedRoles.find((role) => role?.label === 'Attorney'));
        case 'primaryStateCode':
          return Boolean(selectedState.length >= 2);
        default:
          return false;
      }
    },
    [selectedRoles.length, selectedState.length],
  );

  const getOnBlur = useCallback((name) => {
    switch (name) {
      case 'attorneyRate':
        return ({ target: { value, name: inputName } }) => {
          form.setValue(inputName, amendZeros(value));
        };
      default:
        return null;
    }
  }, []);

  const getDisabled = useCallback(
    (name) => {
      switch (name) {
        case 'attorneyRate':
        case 'attorneyQuickBookId':
          return !selectedRoles.find((role) => role?.label === 'Attorney');
        case 'hasAutoAssignment':
          return !selectedRoles.find((role) => role?.label === 'Legal Assistant');
        case 'primaryStateCode':
          return selectedState.length <= 1;
        default:
          return false;
      }
    },
    [selectedRoles.length, selectedState.length],
  );

  useEffect(() => {
    if (!selectedRoles.find((role) => role?.label === 'Attorney')) {
      form.setValue('attorneyRate', '');
      form.setValue('attorneyQuickBookId', '');
    }
    if (!selectedRoles.find((role) => role?.label === 'Legal Assistant')) {
      form.setValue('hasAutoAssignment', false);
    }
  }, [selectedRoles.length]);

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  const onSubmit = async (data) => {
    const {
      firstName,
      lastName,
      middleName,
      suffix,
      userName,
      stateCodes,
      primaryStateCode,
      attorneyRate,
      attorneyQuickBookId,
      hasAutoAssignment,
      email,
      status,
      password,
      confirmPassword,
      roleIds,
      userId,
    } = data;

    const { hasErros, ...restErrors } = validateDialogForm({
      email,
      userName,
      password,
      confirmPassword,
    });

    if (hasErros) {
      Object.keys(restErrors).forEach((errorKey) => {
        setError(errorKey, { message: restErrors[errorKey] });
      });
      return;
    }

    setIsLoader(true);

    await onConfirm({
      userId,
      firstName,
      lastName,
      middleName,
      suffix,
      userName,
      stateCodes: stateCodes.map((item) => item.value),
      primaryStateCode: primaryStateCode?.value || selectedState[0].value,
      attorneyRate: attorneyRate ? attorneyRate.slice(1) : null,
      attorneyQuickBookId,
      hasAutoAssignment,
      email,
      status: status?.value || status,
      password,
      confirmPassword,
      roleIds: roleIds.map((role) => role.value),
    });

    setIsLoader(false);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <StyledDialog onClose={onClose} open={isOpen} data-testid="add_new_user_dialog">
      <Typography variant="h5" mb="40px" data-testid="add_new_user_dialog_title">
        {title}
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)} form={form}>
        <Stack rowGap={3} justifyContent="space-between" direction="row" mb={3} flexWrap="wrap">
          {userEditFormFields.map((item) => {
            if (item.isDropdown) {
              const isMandatory = getMandatory(item.name) || item.isMandatory;
              const isDisabled = getDisabled(item.name) || item.isDisabled;

              return (
                <Field
                  key={item.label}
                  name={item.name}
                  isMandatory={isMandatory}
                  render={({ field, error }) => {
                    const isPrimaryStateCode = field.name === 'primaryStateCode';
                    const fieldValue = watch(field.name);
                    const primaryCode = states.find((code) => code.value === fieldValue);
                    return (
                      <Dropdown
                        type="text"
                        label={item.label}
                        placeholder={item.placeholder}
                        width={item.width}
                        name={item.name}
                        value={isPrimaryStateCode ? primaryCode : field.value}
                        onChange={getOnChange(field.name, field.onChange)}
                        isColumn
                        isMandatory={isMandatory}
                        isDisabled={isDisabled}
                        options={getOptions(item.name)}
                        error={error}
                      />
                    );
                  }}
                />
              );
            }

            if (item.isEmptySpace) {
              return <Box width="326px" />;
            }

            if (item.isRadioButtons) {
              return (
                <Field
                  key={item.label}
                  name={item.name}
                  render={({ field, error, onCustomChange }) => (
                    <CustomRadioGroup
                      error={error}
                      data={item.data}
                      value={field.value}
                      label={item.label}
                      width={item.width}
                      defaultValue={getRadioGroupDefaultValue(item.data)}
                      radioButtonWidth={item.radioButtonWidth}
                      disabled={getDisabled(item.name)}
                      onChange={onCustomChange(field.onChange)}
                    />
                  )}
                />
              );
            }

            if (item.isPassword) {
              return (
                <Field
                  key={item.label}
                  name={item.name}
                  isMandatory={isEditMode ? false : item.isMandatory}
                  render={({ field, error, onCustomChange }) => (
                    <Password
                      isMandatory={isEditMode ? false : item.isMandatory}
                      error={error}
                      width={item.width}
                      label={item.label}
                      filled={isEditMode}
                      value={field.value}
                      placeholder={item.placeholder}
                      onChange={onCustomChange(field.onChange)}
                    />
                  )}
                />
              );
            }

            if (item.isCheckboxSelect) {
              return (
                <Field
                  key={item.name}
                  isMandatory={item.isMandatory}
                  name={item.name}
                  render={({ field, error }) => (
                    <CheckboxSelect
                      label={item.label}
                      value={field.value}
                      width={item.width}
                      error={error}
                      isMandatory={item.isMandatory}
                      isDisabled={item.isDisabled}
                      placeholder={item.placeholder}
                      onChange={getOnChange(field.name, field.onChange)}
                      options={getOptions(item.name)}
                    />
                  )}
                />
              );
            }

            const isMandatory = getMandatory(item.name) || item.isMandatory;

            return (
              <Field
                key={item.label}
                name={item.name}
                isMandatory={isMandatory}
                render={({ field, error }) => {
                  return (
                    <EditInputWithLabel
                      error={error}
                      type="text"
                      label={item.label}
                      placeholder={item.placeholder}
                      width={item.width}
                      name={item.name}
                      isMandatory={isMandatory}
                      value={field.value}
                      onChange={getOnChange(item.name, field.onChange)}
                      isDisabled={getDisabled(item.name) || item.isDisabled}
                      onBlur={getOnBlur(item.name)}
                    />
                  );
                }}
              />
            );
          })}
        </Stack>

        <Stack direction="row" spacing={2} mt={5} justifyContent="flex-end" height="auto">
          {buttonSecondaryText && (
            <MButton
              variant="secondary"
              size="large"
              onClick={handleClose}
              sx={{ width: '150px' }}
              disabled={isLoader}
              data-testid="add_new_user_dialog_secondary_btn"
            >
              {buttonSecondaryText}
            </MButton>
          )}
          {buttonPrimaryText && (
            <MButton
              type="submit"
              size="large"
              sx={{ width: '150px' }}
              disabled={isLoader}
              startIcon={<CopySvg />}
              data-testid="add_new_user_dialog_primary_btn"
            >
              {buttonPrimaryText}
            </MButton>
          )}
        </Stack>
      </Form>
    </StyledDialog>
  );
};

AddNewUserDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmError: PropTypes.shape({}),
};

AddNewUserDialog.defaultProps = {
  isOpen: false,
  title: '',
  buttonSecondaryText: '',
  buttonPrimaryText: '',
  onClose: null,
  onConfirm: null,
  confirmError: {},
};

export default AddNewUserDialog;
