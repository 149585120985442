import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { styled, Dialog, Typography, Stack, Box, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import MButton from '../../../MUI/Button/MButton';
import Table from '../../../atoms/Table/Table';
import { palette } from '../../../../theme/default';
import { TemplatesAllColumns } from './Court.constants';
import {
  getCourtsTemplatesAll,
  getCourtTemplatesTypes,
  getCourtTemplatesNoticeTypes,
} from '../../../../store/slices/editCourtSlice';
import Dropdown from '../../../atoms/Dropdown';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    padding: 32,
    borderRadius: 24,
    boxShadow: `0px 3px 32px ${palette.shadow.boxBlur}`,
  },
});

const CourtTemplatesDialog = ({
  title,
  isOpen,
  onClose,
  onConfirm,
  buttonPrimaryText,
  buttonSecondaryText,
}) => {
  const dispatch = useDispatch();
  const {
    state: { state: stateCode },
  } = useLocation();

  const [isLoader, setIsLoader] = useState(true);
  const [isLoaderTable, setIsLoaderTable] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const templatesAll = useSelector((state) => state.editCourt.templatesAll);
  const { templateNoticeTypes, templateTypes } = useSelector((state) => state.editCourt);
  const [tableData, setTableData] = useState(templatesAll);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [noticeType, setNoticeType] = useState('');

  useEffect(() => {
    if (!name && !type && !noticeType) {
      setTableData(templatesAll);
      return;
    }
    if (!name && !type && !noticeType && !tableData.length) {
      setTableData(templatesAll);
      return;
    }

    setTableData(
      templatesAll.filter((item) => {
        const searchByAllInputs =
          item.name === name && item.templateType === type && item.noticeType?.includes(noticeType);
        const searchByTemplateTypeAndNoticeType =
          item.templateType === type && item.noticeType?.includes(noticeType);
        const searchByNameAndNoticeType =
          item.name === name && item.noticeType?.includes(noticeType);
        const searcByNameAndTemplateType = item.name === name && item.templateType === type;

        if (name) {
          if (type && noticeType) return searchByAllInputs;
          if (!type && noticeType) return searchByNameAndNoticeType;
          if (type && !noticeType) return searcByNameAndTemplateType;
          if (!type && !noticeType) return item.name === name;
        }

        if (type) {
          if (name && noticeType) return searchByAllInputs;
          if (!name && noticeType) return searchByTemplateTypeAndNoticeType;
          if (name && !noticeType) return searcByNameAndTemplateType;
          if (!name && !noticeType) return item.templateType === type;
        }

        if (noticeType) {
          if (name && type) return searchByAllInputs;
          if (!name && type) return searchByTemplateTypeAndNoticeType;
          if (name && !type) return searchByNameAndNoticeType;
          if (!name && !type) return item.noticeType?.includes(noticeType);
        }

        return false;
      }),
    );
  }, [name, type, noticeType]);

  useEffect(() => {
    setTableData(templatesAll);
  }, [templatesAll]);

  useEffect(() => {
    if (isOpen) {
      Promise.allSettled([
        dispatch(getCourtsTemplatesAll({ stateCode })),
        dispatch(getCourtTemplatesTypes()),
        dispatch(getCourtTemplatesNoticeTypes({ stateCode: stateCode?.value })),
      ]).then(() => {
        setIsLoader(false);
        setIsLoaderTable(false);
      });
    }
  }, [isOpen]);

  const handleCheckedRow = useCallback((selectedRows) => {
    setSelectedIds(
      selectedRows.map((item) => ({
        noticeType: item.noticeType,
        templateId: item.templateId,
        templateName: item.name,
        templateType: item.templateType,
      })),
    );
  }, []);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm?.(selectedIds);
  }, [selectedIds, onConfirm]);

  const handleSearchByTemplateName = (selected) => {
    setName(selected.name);
  };

  const handleSearchByType = ({ value }) => {
    setType(value);
  };
  const handleSearchByNoticeTypeType = ({ value }) => {
    setNoticeType(value);
  };

  return (
    <StyledDialog onClose={onClose} open={isOpen} maxWidth="md" fullWidth>
      <Stack spacing={4} height="60vh" direction="column">
        {title && <Typography variant="h4">{title}</Typography>}
        <Stack direction="row" justifyContent="space-between" spacing={2} height="auto">
          <Dropdown
            isSearchable
            width="50%"
            isColumn
            placeholder="Template Name"
            options={templatesAll}
            onChange={handleSearchByTemplateName}
          />
          <Dropdown
            isSearchable={false}
            width="20%"
            isColumn
            placeholder="Types"
            options={templateTypes}
            onChange={handleSearchByType}
          />
          <Dropdown
            isSearchable={false}
            width="30%"
            isColumn
            placeholder="Notice Types"
            options={templateNoticeTypes}
            onChange={handleSearchByNoticeTypeType}
          />
        </Stack>
        {templatesAll?.length && !isLoader ? (
          <Stack height="100px" overflow="scroll" flexGrow={1}>
            <Table
              columns={TemplatesAllColumns}
              rows={tableData}
              total={templatesAll?.length}
              pagination={false}
              onCheckedRow={handleCheckedRow}
              isWithCheckbox
              loading={isLoaderTable}
            />
          </Stack>
        ) : (
          <Box
            position="absolute"
            top="50vh"
            left="50%"
            zIndex={10}
            transform="translate(-50%, -50%)"
          >
            <CircularProgress />
          </Box>
        )}
        <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
          {buttonSecondaryText && (
            <MButton variant="secondary" size="large" onClick={handleClose} sx={{ width: '104px' }}>
              {buttonSecondaryText}
            </MButton>
          )}
          {buttonPrimaryText && (
            <MButton
              size="large"
              onClick={handleConfirm}
              sx={{ width: '104px' }}
              disabled={!selectedIds.length}
            >
              {buttonPrimaryText}
            </MButton>
          )}
        </Stack>
      </Stack>
    </StyledDialog>
  );
};

CourtTemplatesDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

CourtTemplatesDialog.defaultProps = {
  isOpen: false,
  title: '',
  buttonSecondaryText: '',
  buttonPrimaryText: '',
  onClose: null,
  onConfirm: null,
};

export default CourtTemplatesDialog;
