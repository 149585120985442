import { ReactComponent as GoingToCourtIcon } from '../../../../assets/icons/GoingToCourt.svg';
import { ReactComponent as RequiringFollowUpIcon } from '../../../../assets/icons/RequiringFollowUp.svg';
import { ReactComponent as PendingApprovalIcon } from '../../../../assets/icons/PendingApproval.svg';
import { ReactComponent as AvailableLockoutIcon } from '../../../../assets/icons/AvailableLockout.svg';
import { ReactComponent as AvailableWritsIcon } from '../../../../assets/icons/AvailableWrits.svg';

import CaseStatus from '../../../molecules/CaseStatus';
import { DefendantCell, ReferenceNumberCell } from '../../Processing/CaseSearch/CaseSearchCells';
import CaseNumberCell from './cells/CaseNumberCell';
import CommentCell from './cells/CommentCell';
import RequestLockoutCell from './cells/RequestLockoutCell';
import { DocumentDownloadCell } from './cells/DocumentDownloadCell';
import PendingApprovalCell from './cells/PendingApprovalCell';
import AvailableWritsCell from './cells/AvailableWritsCell';

export const GOING_TO_COURTS = 'goingToCourts';
export const REQUIRE_FOLLOW_UPS = 'requireFollowUps';
export const PENDING_APPROVALS = 'pendingApprovals';
export const AVAILABLE_LOCKOUTS = 'availableLockouts';
export const AVAILABLE_WRITS = 'availableWrits';

export const cardsListByState = {
  AZ: [
    {
      id: GOING_TO_COURTS,
      title: 'Going to Court',
      base: 'case',
      icon: <GoingToCourtIcon />,
    },
    {
      id: REQUIRE_FOLLOW_UPS,
      title: 'Requiring Follow-up',
      base: 'case',
      icon: <RequiringFollowUpIcon />,
    },
    {
      id: PENDING_APPROVALS,
      title: 'Pending Approval(s)',
      base: 'notice',
      icon: <PendingApprovalIcon />,
    },
    {
      id: AVAILABLE_WRITS,
      title: 'Available Writs',
      base: 'writ',
      icon: <AvailableWritsIcon />,
    },
  ],
  NV: [
    {
      id: GOING_TO_COURTS,
      title: 'Going to Court',
      base: 'case',
      icon: <GoingToCourtIcon />,
    },
    {
      id: REQUIRE_FOLLOW_UPS,
      title: 'Requiring Follow-up',
      base: 'case',
      icon: <RequiringFollowUpIcon />,
    },
    {
      id: PENDING_APPROVALS,
      title: 'Pending Approval(s)',
      base: 'notice',
      icon: <PendingApprovalIcon />,
    },
    {
      id: AVAILABLE_LOCKOUTS,
      title: 'Available Lockout(s)',
      base: 'case',
      icon: <AvailableLockoutIcon />,
    },
  ],
};

export const listConfig = {
  [GOING_TO_COURTS]: {
    title: 'Going to Court',
    timePeriods: [
      { label: 'Today', value: 'today' },
      { label: 'Tomorrow', value: 'tomorrow' },
    ],
    columns: [
      {
        Header: 'Case #',
        accessor: 'caseNumber',
        Cell: CaseNumberCell,
        width: 20,
      },
      {
        Header: 'Community Name',
        accessor: 'communityName',
        width: 20,
      },
      {
        Header: 'Opposing Party Name',
        accessor: 'defendantName',
        Cell: DefendantCell,
        width: 20,
      },
      {
        Header: 'Apartment #',
        accessor: 'apartmentNumber',
        width: 20,
      },
      {
        Header: 'Status',
        accessor: 'caseStatus',
        Cell: CaseStatus,
        width: 20,
      },
    ],
  },
  [REQUIRE_FOLLOW_UPS]: {
    title: 'Requiring Follow-up',
    columns: [
      {
        Header: 'Reference #',
        accessor: 'referenceNumber',
        Cell: ReferenceNumberCell,
        width: 20,
      },
      {
        Header: 'Community Name',
        accessor: 'communityName',
        width: 20,
      },
      {
        Header: 'Opposing Party Name',
        accessor: 'defendantName',
        Cell: DefendantCell,
        width: 20,
      },
      {
        Header: 'Apartment #',
        accessor: 'apartmentNumber',
        width: 20,
      },
      {
        Header: 'Comment',
        accessor: 'caseStatusComment',
        Cell: CommentCell,
        width: 20,
      },
    ],
  },
  [PENDING_APPROVALS]: {
    title: 'Pending Approval(s)',
    columns: [
      {
        Header: 'Document Name',
        accessor: 'documentName',
        Cell: DocumentDownloadCell,
        width: 20,
      },
      {
        Header: 'Community Name',
        accessor: 'communityName',
        width: 20,
      },
      {
        Header: 'Opposing Party Name',
        accessor: 'defendantName',
        Cell: DefendantCell,
        width: 20,
      },
      {
        Header: 'Apartment',
        accessor: 'apartmentNumber',
        width: 20,
      },
      {
        Header: '',
        accessor: 'documentId',
        Cell: PendingApprovalCell,
        width: 20,
        minWidth: 200,
      },
    ],
  },
  [AVAILABLE_LOCKOUTS]: {
    title: 'Available Lockout(s)',
    columns: [
      {
        Header: 'Case #',
        accessor: 'caseNumber',
        Cell: CaseNumberCell,
        width: 20,
      },
      {
        Header: 'Community Name',
        accessor: 'communityName',
        width: 20,
      },
      {
        Header: 'Opposing Party Name',
        accessor: 'defendantName',
        Cell: DefendantCell,
        width: 20,
      },
      {
        Header: 'Apartment #',
        accessor: 'apartmentNumber',
        width: 20,
      },
      {
        Header: '',
        accessor: 'canRequestLockout',
        Cell: RequestLockoutCell,
        width: 20,
      },
    ],
  },
  [AVAILABLE_WRITS]: {
    title: 'Available Writs',
    columns: [
      {
        Header: 'Case #',
        accessor: 'caseNumber',
        Cell: CaseNumberCell,
        width: 20,
      },
      {
        Header: 'Community Name',
        accessor: 'communityName',
        width: 25,
      },
      {
        Header: 'Opposing Party Name',
        accessor: 'defendantName',
        Cell: DefendantCell,
        width: 20,
      },
      {
        Header: 'Apartment',
        accessor: 'apartmentNumber',
        width: 20,
      },
      {
        Header: '',
        accessor: 'caseId',
        Cell: AvailableWritsCell,
        width: 15,
      },
    ],
  },
};
