import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, LinearProgress, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../atoms/Dropdown';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import MButton from '../../../MUI/Button/MButton';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup/CustomRadioGroup';
import { TypeRadio, TABLE_COLUMNS, TABLE_ROWS_COUNT } from './CivilMatters.constants';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import { ReactComponent as DocumentDownload } from '../../../../assets/icons/document-download-blue.svg';
import DatePickerWithLabel from '../../../atoms/DatePickerWithLabel/DatePickerWithLabel';
import Table from '../../../atoms/Table/Table';
import EditTimeEntry from './EditTimeEntry';
import EditExpenseEntry from './EditExpenseEntry';
import { apiClient } from '../../../../lib/apiClient';
import AuthContext from '../../../../context/AuthContext';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import NotificationDialog from '../../../molecules/NotificationDialog';
import EmptyBlock from '../../../molecules/EmptyBlock';
import ShadowBlock from '../../../atoms/ShadowBlock';
import { rolesDB } from '../../../../utils/roleHelpers';
import MDialog from '../../../atoms/MDialog/MDialog';
import {
  getCivilMattersCsv,
  getCivilMattersPdf,
} from '../../../../store/slices/cases/casesGeneralSlice';
import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import { LightTooltip } from '../../../atoms/MTooltip/MTooltip';
import LoaderCover from '../../../atoms/LoaderCover';
import notificationUtils from '../../../../utils/notificationUtils';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';

const ExportActivitiesContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  marginLeft: 'auto',
  cursor: 'pointer',
  '& > svg': {
    marginRight: '10px',
  },
});

const Text = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '20px',
  textAlign: 'center',
  letterSpacing: '-0.02em',
  color: '#546FFF',
});

export default function CivilMattersNevada() {
  const {
    state: { id },
  } = useLocation();

  const dispatch = useDispatch();
  const attorneysForm = useForm();

  const { userInfo } = useContext(AuthContext);
  const [isLoader, setIsLoader] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [activities, setActivities] = useState(null);
  const [attorneys, setAttorneys] = useState(null);
  const [legalCategories, setLegalCategories] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [type, setType] = useState(TypeRadio[0].value);
  const [isExportActivitiesDialogOpen, setIsExportActivitiesDialogOpen] = useState(false);
  const [isExportActivitiesDialogLoader, setIsExportActivitiesDialogLoader] = useState(false);
  const [initialMatterProperties, setInitialMatterProperties] = useState(null);

  useEffect(() => {
    const fetchAttorneys = async () => {
      const response = await apiClient.get(`/api/cases/${id}/attorneys?isActive=true`);
      const nextAttorneys = response.data.result.map(
        ({ userId, firstName, lastName, attorneyRate, attorneyQuickBookId }) => {
          return {
            id: userId,
            value: userId,
            attorneyRate,
            label: `${firstName} ${lastName}`,
            attorneyQuickBookId,
          };
        },
      );
      setAttorneys(nextAttorneys);
    };

    fetchAttorneys();
  }, [apiClient, id, setAttorneys]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await apiClient.get(`/api/cases/civilMatters/time/categories`);
      const nextCategories = response?.data?.result?.map(({ id: categoryId, name }) => {
        return {
          id: categoryId,
          value: categoryId,
          label: name,
        };
      });

      // const fakeActivityCategories = [
      //   { id: 0, value: 'fake activity category 1', label: 'fake activity category 1' },
      //   { id: 2, value: 'fake activity category 2', label: 'fake activity category 2' },
      //   { id: 3, value: 'fake activity category 3', label: 'fake activity category 3' },
      // ];
      setLegalCategories(nextCategories);
      // setLegalCategories(fakeActivityCategories);
    };

    fetchCategories();
  }, [apiClient, id, setLegalCategories]);

  useEffect(() => {
    const fetchLastDates = async () => {
      const {
        data: { result },
      } = await apiClient.get(`/api/cases/${id}/lastInvoicing`);
      setLastDate(moment.max(Object.values(result).map((date) => moment(date))));
    };

    fetchLastDates();
  }, [id]);

  const isCurrentUserAttorney = userInfo.roles.includes(rolesDB.Attorney);

  const canCurrentUserAddActivities = useMemo(() => {
    if (isCurrentUserAttorney) {
      return attorneys?.some((att) => att.id === userInfo.UserGuid && att.attorneyQuickBookId);
    }
    return true;
  }, [isCurrentUserAttorney, userInfo.UserGuid, attorneys]);

  useEffect(() => {
    if (attorneys) {
      apiClient.get(`/api/cases/${id}/civilMatters/attorneys`).then(({ data: { result } }) => {
        const { responsibleAttorneyId, requestedBy, description, dueDate, deadline } = result;
        let updatedAttorneysForm = {};

        const responsibleAttorney = attorneys.find((a) => a.id === responsibleAttorneyId) || null;
        if (userInfo.roles.includes(rolesDB.Attorney)) {
          updatedAttorneysForm = {
            responsibleAttorney:
              responsibleAttorney || attorneys.find((a) => a.id === userInfo.UserGuid),
            requestedBy,
            description,
            dueDate,
            deadline,
          };
        } else {
          updatedAttorneysForm = {
            responsibleAttorney,
            requestedBy,
            description,
            dueDate,
            deadline,
          };
        }
        setInitialMatterProperties(updatedAttorneysForm);
        attorneysForm.reset(updatedAttorneysForm);
      });
    }
  }, [attorneys, id, userInfo.roles]);

  const handleNewTimeEntry = useCallback(() => {
    setModalData({ title: 'Time', isEdit: false });
  }, []);

  const handleNewExpense = useCallback(() => {
    setModalData({ title: 'Expense', isEdit: false });
  }, []);

  const handleTypeChange = useCallback((value) => {
    setType(value);
  }, []);

  const handleFromDateChange = useCallback((value) => {
    setFromDate(value);
  }, []);

  const handleToDateChange = useCallback((value) => {
    setToDate(value);
  }, []);

  const handleApply = useCallback(
    async (populateWithParams = true) => {
      setIsLoader(true);
      let requestBody = {
        caseId: id,
        type: TypeRadio[0].value,
      };

      if (populateWithParams) {
        requestBody = {
          ...requestBody,
          type: type?.value || type,
          ...(fromDate && { fromDate: moment(fromDate, moment.ISO_8601) }),
          ...(toDate && { toDate: moment(toDate, moment.ISO_8601) }),
        };
      }
      try {
        const civilMatterResponse = await apiClient.post(`/api/cases/${id}/civilMatters/filter`, {
          currentPage: 1,
          pageSize: TABLE_ROWS_COUNT,
          ...requestBody,
        });

        const totalResponse = await apiClient.post(
          `/api/cases/${id}/civilMatters/total`,
          requestBody,
        );

        const {
          data: { result },
        } = civilMatterResponse;
        const {
          quantityTotal,
          nonBillableTotal,
          billableTotal,
          nonBillableQuantityTotal,
          billableQuantityTotal,
        } = totalResponse.data.result;

        if (result?.items?.length) {
          result.items.push({
            isTotalRow: true,
            civilMatterId: null,
            type: null,
            quantity: quantityTotal,
            description: null,
            nonBillableQuantityTotal,
            billableQuantityTotal,
            rate: null,
            nonBillable: nonBillableTotal,
            billable: billableTotal,
            date: null,
            user: null,
            invoiceStatus: null,
          });
        }
        setActivities(result);
      } finally {
        setIsLoader(false);
      }
    },
    [apiClient, id, type, fromDate, toDate],
  );

  useEffect(() => {
    handleApply();
  }, []);

  const handleClearFilter = useCallback(() => {
    setType(TypeRadio[0].value);
    setFromDate(null);
    setToDate(null);
    handleApply(false);
  }, [handleApply]);

  const onAttorneysSubmit = useCallback(async (values) => {
    setIsLoader(true);
    try {
      await apiClient.put(`/api/cases/${id}/civilMatters/attorneys`, {
        caseId: id,
        responsibleAttorneyId: values.responsibleAttorney?.id,
        requestedBy: values.requestedBy,
        deadline: values.deadline ? moment(values.deadline).format('yyyy-MM-DD') : null,
        dueDate: values.dueDate ? moment(values.dueDate).format('yyyy-MM-DD') : null,
        description: values.description,
      });
      setInitialMatterProperties({
        responsibleAttorney: values.responsibleAttorney,
        requestedBy: values.requestedBy,
        deadline: values.deadline ? moment(values.deadline).format('yyyy-MM-DD') : null,
        dueDate: values.dueDate ? moment(values.dueDate).format('yyyy-MM-DD') : null,
        description: values.description,
      });
      notificationUtils.success('Updated successfully');
    } catch {
      notificationUtils.error('Failed, try again later');
    } finally {
      setIsLoader(false);
    }
  }, []);

  const onUpdateData = useCallback(() => {}, []);

  const onCloseModal = () => {
    setModalData(null);
  };

  const onExportActivities =
    (docType = 'pdf') =>
    async () => {
      const actionsByDocType = {
        csv: getCivilMattersCsv,
        pdf: getCivilMattersPdf,
      };
      setIsExportActivitiesDialogLoader(true);

      try {
        const request = actionsByDocType[docType];
        const { result } = await dispatch(request(id)).unwrap();
        openOrDownloadBinary({
          contentType: result.contentType,
          content: result.content,
          fileName: result.originalFileName,
        });
      } catch (e) {
        // todo: Add Alert Component
        alert('---------------', e);
      }

      setIsExportActivitiesDialogLoader(false);
      setIsExportActivitiesDialogOpen(false);
    };

  const onExportActivitiesDialogOpen = useCallback(() => {
    setIsExportActivitiesDialogOpen(true);
  }, []);

  const onExportActivitiesDialogClose = useCallback(() => {
    setIsExportActivitiesDialogOpen(false);
  }, []);

  const handleOpenModalOnTableEditClick = useCallback(
    (_, original) => {
      setModalData({
        id,
        title: original.type,
        civilMatterId: original.civilMatterId,
        isEdit: true,
      });
    },
    [activities?.items],
  );

  const handleExpenseEntryConfirm = async (values) => {
    const { firmUser, date, description, amount, isNonBillable, isEdit, category } = values;

    if (isEdit) {
      await apiClient
        .put(`/api/cases/${id}/civilMatters/expense/${values?.civilMatterId}`, {
          caseId: id,
          userId: firmUser.id,
          date: moment(date, moment.ISO_8601),
          description,
          amount: +amount,
          isNonBillable,
          category: category.value,
        })
        .then(() => {
          notificationUtils.success('Updated successfully');
          handleApply();
          setModalData(null);
        });
    } else {
      await apiClient
        .post(`/api/cases/${id}/civilMatters/expense`, {
          caseId: id,
          userId: firmUser.id,
          date: moment(date, moment.ISO_8601),
          description,
          amount: +amount,
          isNonBillable,
          category: category.value,
        })
        .then(() => {
          notificationUtils.success('Added successfully');
          handleApply();
          setModalData(null);
        });
    }
  };

  const handleConfirmDeleteTableRow = useCallback(() => {
    apiClient.delete(`/api/cases/${id}/civilMatters/${rowToDelete}`).then(() => {
      setRowToDelete(null);
      handleApply();
    });
  }, [rowToDelete]);

  const handleCancelDeleteTableRow = useCallback(() => {
    setRowToDelete(null);
  }, []);

  const onDeleteRow = useCallback(
    (rowId) => {
      const { civilMatterId } = activities.items[rowId];
      setRowToDelete(civilMatterId);
    },
    [activities],
  );

  const handleFetchDataPage = useCallback(
    (currentPage) => {
      const fetcher = async () => {
        const requestBody = {
          caseId: id,
          type: type?.value || type,
          ...(fromDate && { fromDate: moment(fromDate, moment.ISO_8601) }),
          ...(toDate && { toDate: moment(toDate, moment.ISO_8601) }),
        };

        const filterResponse = await apiClient.post(`/api/cases/${id}/civilMatters/filter`, {
          currentPage,
          pageSize: TABLE_ROWS_COUNT,
          ...requestBody,
        });

        const totalResponse = await apiClient.post(
          `/api/cases/${id}/civilMatters/total`,
          requestBody,
        );

        const {
          data: { result },
        } = filterResponse;
        const {
          quantityTotal,
          nonBillableTotal,
          billableTotal,
          nonBillableQuantityTotal,
          billableQuantityTotal,
        } = totalResponse.data.result;

        if (result?.items?.length) {
          result.items.push({
            isTotalRow: true,
            civilMatterId: null,
            type: null,
            quantity: quantityTotal,
            description: null,
            rate: null,
            nonBillable: nonBillableTotal,
            billable: billableTotal,
            date: null,
            user: null,
            invoiceStatus: null,
            nonBillableQuantityTotal,
            billableQuantityTotal,
          });
        }

        setActivities(result);
      };

      fetcher();
    },
    [type, fromDate, toDate],
  );

  const handleTimeEntryConfirm = useCallback(
    async (values) => {
      const { firmUser, date, description, rate, duration, isNonBillable, isEdit, category } =
        values;

      if (isEdit) {
        const params = {
          caseId: id,
          civilMatterActivityId: values?.civilMatterId,
          userId: firmUser.id,
          date,
          description,
          rate: +rate,
          duration: +duration,
          isNonBillable,
          categoryId: category.value,
        };
        await apiClient
          .put(`/api/cases/${id}/civilMatters/time/${values?.civilMatterId}`, params)
          .then(() => {
            handleApply();
            notificationUtils.success('Updated successfully');
            setModalData(null);
          });
      } else {
        const params = {
          caseId: id,
          userId: firmUser.id,
          date: moment(date, moment.ISO_8601),
          description,
          rate: +rate,
          duration: +duration,
          isNonBillable,
          categoryId: category.value,
        };
        await apiClient.post(`/api/cases/${id}/civilMatters/time`, params).then(() => {
          handleApply();
          notificationUtils.success('Added successfully');
          setModalData(null);
        });
      }
    },
    [type, fromDate, toDate],
  );

  const tooltipText = canCurrentUserAddActivities
    ? ''
    : 'Your user is not connected to QB yet. Please reach out to your admin to resolve the issue.';

  const values = attorneysForm.watch();

  const checkValuesChanged = useMemo(() => {
    return (
      moment(values.deadline).format('yyyy-MM-DD') ===
        moment(initialMatterProperties?.deadline).format('yyyy-MM-DD') &&
      moment(values.dueDate).format('yyyy-MM-DD') ===
        moment(initialMatterProperties?.dueDate).format('yyyy-MM-DD') &&
      values.description === initialMatterProperties?.description &&
      values.requestedBy === initialMatterProperties?.requestedBy &&
      values.responsibleAttorney?.id === initialMatterProperties?.responsibleAttorney?.id
    );
  }, [values, initialMatterProperties]);

  return (
    <>
      {isLoader && <LoaderCover isFixed />}
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          mb: 4,
          px: 4,
          width: 1,
          maxWidth: 1856,
        }}
      >
        <ShadowBlock variant="dropdownCard">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">Matter Properties</Typography>
          </Stack>
          <Form onSubmit={attorneysForm.handleSubmit(onAttorneysSubmit)} form={attorneysForm}>
            <Stack flex direction="row" flexWrap="wrap" columnGap={3} rowGap={1}>
              <Field
                name="responsibleAttorney"
                isMandatory
                render={({ field, error, onCustomChange }) => (
                  <Dropdown
                    error={error}
                    placeholder="Select Handling Attorney"
                    label="Handling Attorney"
                    value={field.value}
                    isSearchable
                    width="279px"
                    isColumn
                    options={attorneys}
                    onChange={onCustomChange(field.onChange)}
                    isMandatory
                  />
                )}
              />

              <Field
                name="requestedBy"
                isMandatory={false}
                render={({ field, error }) => (
                  <EditInputWithLabel
                    error={error}
                    placeholder="Enter requested by"
                    label="Requested By"
                    value={field.value}
                    width="278px"
                    onChange={field.onChange}
                    isMandatory={false}
                  />
                )}
              />
              <Field
                name="deadline"
                render={({ field, onCustomChange, error }) => {
                  return (
                    <DateOrTimePicker
                      isDate
                      error={error}
                      height="48px"
                      width="247px"
                      name="deadline"
                      label="Deadline"
                      placeholder="mm/dd/yyyy"
                      Svg={Calender}
                      minDate={moment()}
                      onChange={onCustomChange(field.onChange)}
                      setSelectedTimeOrDate={onCustomChange(field.onChange)}
                      selected={field.value}
                    />
                  );
                }}
              />
              <Field
                name="dueDate"
                render={({ field, onCustomChange, error }) => {
                  return (
                    <DateOrTimePicker
                      isDate
                      error={error}
                      height="48px"
                      width="247px"
                      name="dueDate"
                      label="Due Date"
                      placeholder="mm/dd/yyyy"
                      Svg={Calender}
                      minDate={moment()}
                      onChange={onCustomChange(field.onChange)}
                      setSelectedTimeOrDate={onCustomChange(field.onChange)}
                      selected={field.value}
                    />
                  );
                }}
              />

              <Field
                name="description"
                render={({ field, error }) => (
                  <EditInputWithLabel
                    type="text"
                    error={error}
                    label="Description"
                    placeholder="Enter description"
                    width="415px"
                    name="description"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <MButton
                disabled={isLoader || checkValuesChanged}
                type="submit"
                size="medium"
                sx={{ alignSelf: 'flex-end', mb: '7px' }}
              >
                Update
              </MButton>
            </Stack>
          </Form>
        </ShadowBlock>
      </Container>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          mx: 'auto',
          mt: 4,
          mb: 9 + 4 + 4,
          px: 4,
          width: 1,
          maxWidth: 1856,
        }}
      >
        <ShadowBlock variant="dropdownCard">
          <Stack flex direction="row" justifyContent="space-between" columnGap={4}>
            <Typography variant="h5">Activities</Typography>
            <Stack flex direction="row" columnGap={3}>
              <LightTooltip title={tooltipText}>
                <Box>
                  <MButton
                    onClick={handleNewTimeEntry}
                    startIcon={<Add />}
                    disabled={!canCurrentUserAddActivities}
                  >
                    New Time Entry
                  </MButton>
                </Box>
              </LightTooltip>
              <LightTooltip title={tooltipText}>
                <Box>
                  <MButton
                    onClick={handleNewExpense}
                    startIcon={<Add />}
                    disabled={!canCurrentUserAddActivities}
                  >
                    New Expense
                  </MButton>
                </Box>
              </LightTooltip>
            </Stack>
          </Stack>
          <Stack
            flex
            flexWrap="wrap"
            direction="row"
            alignItems="flex-end"
            columnGap={3}
            rowGap={3}
            mt="36px"
            mb={4}
          >
            <CustomRadioGroup value={type} data={TypeRadio} onChange={handleTypeChange} />
            <DatePickerWithLabel
              label="From Date"
              value={fromDate}
              dateFormat="MM/DD/YY"
              onChangeValue={handleFromDateChange}
              width="200px"
            />
            <DatePickerWithLabel
              label="To Date"
              value={toDate}
              dateFormat="MM/DD/YY"
              onChangeValue={handleToDateChange}
              width="200px"
            />
            <Stack
              flex
              mb="6px"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              flexGrow={1}
              columnGap={1}
            >
              <MButton variant="bordered" onClick={handleApply} disabled={isLoader}>
                Apply
              </MButton>
              <MButton variant="borderLess" onClick={handleClearFilter} disabled={isLoader}>
                Clear Filter
              </MButton>
            </Stack>
            <ExportActivitiesContainer onClick={onExportActivitiesDialogOpen}>
              <DocumentDownload />
              <Text variant="body1">Export Activities</Text>
            </ExportActivitiesContainer>
          </Stack>
          {activities?.items?.length ? (
            <Table
              hasTotalRow
              columns={TABLE_COLUMNS}
              onUpdateData={onUpdateData}
              rows={activities?.items}
              onDeleteRow={onDeleteRow}
              onModalOpenWhenClickEdit={handleOpenModalOnTableEditClick}
              total={activities?.totalRowsCount}
              isPagination={activities?.totalPages > 1}
              onNextPage={handleFetchDataPage}
              onPreviousPage={handleFetchDataPage}
              onGotoPage={handleFetchDataPage}
              pageSize={TABLE_ROWS_COUNT}
            />
          ) : (
            <Stack alignItems="center" paddingTop="50px">
              <EmptyBlock title="No records" />
            </Stack>
          )}
        </ShadowBlock>
        {modalData?.title === 'Expense' && (
          <EditExpenseEntry
            modalData={modalData}
            onClose={onCloseModal}
            firmUsersList={attorneys}
            onConfirm={handleExpenseEntryConfirm}
            isOpen={modalData?.title === 'Expense'}
            minDate={lastDate}
          />
        )}
        {modalData?.title === 'Time' && (
          <EditTimeEntry
            modalData={modalData}
            onClose={onCloseModal}
            firmUsersList={attorneys}
            categoriesList={legalCategories}
            onConfirm={handleTimeEntryConfirm}
            isOpen={modalData?.title === 'Time'}
            minDate={lastDate}
          />
        )}
        <NotificationDialog
          title="Confirm delete"
          desc="Are you sure you want to delete activity?"
          type="alert"
          buttonSecondaryText="Cancel"
          buttonPrimaryText="Delete"
          onClose={handleCancelDeleteTableRow}
          onConfirm={handleConfirmDeleteTableRow}
          isOpen={rowToDelete}
        />
      </Container>
      <MDialog
        isOpen={isExportActivitiesDialogOpen}
        title="Export Activites As"
        onClose={onExportActivitiesDialogClose}
      >
        {isExportActivitiesDialogLoader ? (
          <Box
            sx={{
              width: '208px',
              height: '48px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <LinearProgress />
          </Box>
        ) : (
          <Stack direction="row" justifyContent="space-around" columnGap={2}>
            <MButton size="large" onClick={onExportActivities('pdf')}>
              PDF
            </MButton>
            <MButton size="large" onClick={onExportActivities('csv')}>
              CSV
            </MButton>
          </Stack>
        )}
      </MDialog>
    </>
  );
}
