import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';
import { palette } from '../../../theme/default/palette';

const TextInput = styled(TextField)(
  ({ theme, width, height, shared, multiline, table, search }) => {
    const getHeight = () => {
      if (height) return `${height}px`;
      if (multiline) return 'auto';
      if (table) return '32px';
      return '48px';
    };
    return {
      '.MuiInputBase-root': {
        height: getHeight(),
        borderRadius: `${shared ? '0 10px 10px 0' : '10px'}`,
        padding: '12px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.common.white,
        fontWeight: '500',
        fontSize: 16,
        width: width ? `${width}px` : 'auto',
        '& ::placeholder': {
          color: palette.buttonSecondary.hoverBorder,
          opacity: 1,
        },
        ...(search
          ? {
              '& fieldset': {
                borderColor: theme.palette.additional.lines,
              },
              '&:active fieldset': {
                borderColor: theme.palette.additional.lines,
              },

              '&:focus': {
                borderColor: theme.palette.additional.lines,
              },
              '&:not(.Mui-disabled):hover fieldset': {
                borderColor: theme.palette.additional.lines,
              },
              '&.Mui-disabled': {
                borderColor: theme.palette.additional.lines,
                color: theme.palette.text.disabled,
                pointerEvents: 'none',
              },
            }
          : {
              '&:active fieldset': {
                borderColor: theme.palette.buttonPrimary.active,
              },

              '&:focus': {
                borderColor: theme.palette.buttonPrimary.active,
              },
              '&:not(.Mui-disabled):hover fieldset': {
                borderColor: theme.palette.buttonPrimary.active,
              },
              '&.Mui-disabled': {
                borderColor: theme.palette.additional.lines,
                color: theme.palette.text.disabled,
                pointerEvents: 'none',
              },
            }),
        '.MuiFormHelperText-root': {
          marginLeft: 0,
        },
      },
    };
  },
);

export default TextInput;
