import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Container,
  Stack,
  Typography,
  Box,
  Accordion,
  styled,
  AccordionSummary,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  violationsForm,
  arizonaViolationsColumns,
  deliveredByOptions,
} from './CaseInformationArizona.constants';
import Form from '../../../atoms/Form';
import Field from '../../../atoms/Field';
import Dropdown from '../../../atoms/Dropdown';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel/EditInputWithLabel';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import { ReactComponent as Clock } from '../../../../assets/icons/Clock.svg';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import MButton from '../../../MUI/Button/MButton';
import Table from '../../../atoms/Table/Table';
import {
  getArizonaNoticeTypes,
  getArizonaCasesViolations,
  addArizonaCasesViolations,
  deleteArizonaCasesViolations,
  editArizonaCasesViolations,
  getArizonaProceduralHistoryEntries,
  getArizonaViolationData,
  getArizonaViolationTemplatesForCase,
  getArizonaPrefileDate,
} from '../../../../store/slices/cases/caseInformationArizonaSlice';
import MTooltip from '../../../atoms/MTooltip';
import CustomRadioGroup from '../../../atoms/CustomRadioGroup/CustomRadioGroup';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import AdditionalInformationDialog from './AdditionalInformationDialog';
import { LightTooltip } from '../../../atoms/MTooltip/MTooltip';
import AdditionalInformationFormDialog from './AdditionalInformationFormDialog';
import { EditableArizonaCellContext } from './TableCells/CaseInformationArizonaEditableCell';
import LoaderCover from '../../../atoms/LoaderCover';
import notificationUtils from '../../../../utils/notificationUtils';

const StyledContainer = styled(Container)({
  '&': {
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
    '.MuiPaper-root': {
      boxShadow: '0px 3px 20px rgba(69, 80, 121, 0.13)',
      marginBottom: '16px',
      borderRadius: '24px',
    },
    '.MuiPaper-root::before': {
      height: 0,
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.MuiAccordionSummary-expandIconWrapper svg': {
      width: '16px',
      height: '16px',
    },
    '.MuiButtonBase-root': {
      pointerEvent: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: '0 32px 32px',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
  },
});

const Violations = ({ damagesFormData }) => {
  const form = useForm({
    defaultValues: {
      violations: {
        isComplexComplaint: false,
      },
    },
  });
  const dispatch = useDispatch();
  const {
    state: { id },
  } = useLocation();

  const { noticeTypes, arizonaViolations, violationData, violationTemplates } = useSelector(
    (state) => state.caseInformationArizona,
  );
  const { boxTitle, inputBoxes, withAdd } = violationsForm;
  const [isLoader, setIsLoader] = useState(false);
  const [isAdditionalInformationDialogOpen, setIsAdditionalInformationDialogOpen] = useState(false);
  const [isViolationInfoOpen, setIsViolationInfoOpen] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getArizonaNoticeTypes());
      dispatch(getArizonaCasesViolations(id));
      dispatch(getArizonaViolationTemplatesForCase(id));
    }
  }, [id]);

  const getOptions = useCallback(
    (name) => {
      switch (name) {
        case 'violations.noticeType':
          return noticeTypes;
        case 'violations.deliveredBy':
          return deliveredByOptions;
        default:
          return [];
      }
    },
    [noticeTypes],
  );

  const handleAdditionalInformationDialogOpen = () => setIsAdditionalInformationDialogOpen(true);
  const handleAdditionalInformationDialogClose = () => setIsAdditionalInformationDialogOpen(false);

  const deleteRow = (value, original) => {
    setIsLoader(true);
    dispatch(deleteArizonaCasesViolations({ id, violationId: original.caseViolationId })).then(
      () => {
        dispatch(getArizonaCasesViolations(id));
        setIsLoader(false);
      },
    );
  };

  const loadViolationData = useCallback(
    async (value, original) => {
      await dispatch(getArizonaViolationData({ id, violationId: original.caseViolationId }));
      setIsViolationInfoOpen(true);
    },
    [id],
  );

  const updateViolation = (value, { caseViolationId, description }) => {
    setIsLoader(true);
    const editObj = {
      caseId: id,
      caseViolationId,
      description,
    };
    dispatch(editArizonaCasesViolations(editObj)).then(() => {
      dispatch(getArizonaCasesViolations(id));
      setIsLoader(false);
    });
  };

  const noticeType = form.watch('violations.noticeType')?.label;
  const dateServed = form.watch('violations.dateServed');
  const deliveredBy = form.watch('violations.deliveredBy')?.value;

  useEffect(() => {
    if (noticeType && dateServed && moment(dateServed).isValid() && deliveredBy) {
      setIsLoader(true);
      const req = dispatch(getArizonaPrefileDate({ noticeType, dateServed, deliveredBy }))
        .unwrap()
        .then((res) => {
          if (res) {
            form.setValue('violations.fileDate', moment(res));
          }
        })
        .catch(() => {})
        .finally(() => setIsLoader(false));
      return () => req.abort?.();
    }
    return () => {};
  }, [noticeType, dateServed, deliveredBy]);

  const showNoTemplatesError = violationTemplates.length === 0 && !deliveredBy;

  const handleSubmit = ({ violations }) => {
    if (violationTemplates.length !== 0 && !deliveredBy) {
      handleAdditionalInformationDialogOpen();
    } else {
      setIsLoader(true);

      const { form: damagesForm, onSubmit } = damagesFormData;

      onSubmit(damagesForm.getValues())
        .then(() =>
          dispatch(
            addArizonaCasesViolations({
              id,
              caseId: id,
              amended: violations.amended || false,
              caresActNotice: violations.caresActNotice || false,
              dateServed: violations.dateServed,
              description: violations.description,
              deliveredBy: violations.deliveredBy?.value,
              isComplexComplaint: violations.isComplexComplaint,
              fileDate: violations.fileDate,
              noticeType,
            }),
          ),
        )
        .then(() => {
          dispatch(getArizonaCasesViolations(id));
          dispatch(getArizonaProceduralHistoryEntries(id));
          form.reset({
            violations: {
              noticeType: null,
              isComplexComplaint: false,
              deliveredBy: null,
            },
          });
          notificationUtils.success('Added successfully');
          setIsLoader(false);
        });
    }
  };

  const handleSubmitWithGeneration = (templateId) => (fields, onFinish) => {
    const violations = form.watch('violations');
    setIsLoader(true);
    const { form: damagesForm, onSubmit } = damagesFormData;

    onSubmit(damagesForm.getValues())
      .then(() =>
        dispatch(
          addArizonaCasesViolations({
            id,
            caseId: id,
            amended: violations.amended || false,
            caresActNotice: violations.caresActNotice || false,
            dateServed: violations.dateServed,
            description: violations.description,
            deliveredBy: violations.deliveredBy?.value,
            fileDate: violations.fileDate,
            noticeType,
            templateId,
            templateFields: fields,
          }),
        ),
      )
      .then(() => {
        dispatch(getArizonaCasesViolations(id));
        dispatch(getArizonaProceduralHistoryEntries(id));
        dispatch(getArizonaViolationTemplatesForCase(id));
        form.reset({
          violations: {
            noticeType: null,
            isComplexComplaint: false,
            deliveredBy: null,
          },
        });
        notificationUtils.success('Added successfully');
        onFinish();
      })
      .finally(() => setIsLoader(false));
  };

  const mandatoryFields = useMemo(
    () => inputBoxes.filter((i) => i.isMandatory).map((i) => i.name),
    [],
  );

  const areMandatoryFieldsFilled = useMemo(() => {
    const checkIfFieldsNotEmpty = (fields) => {
      for (let i = 0; i < fields.length; i += 1) {
        if (!fields[i]) return false;
      }
      return true;
    };
    return mandatoryFields.length ? checkIfFieldsNotEmpty(form.watch(mandatoryFields)) : true;
  }, [...form.watch(mandatoryFields)]);

  const isDeliveredByNotEmpty = Boolean(form.watch('violations.deliveredBy')?.value);
  useEffect(() => {
    if (!isDeliveredByNotEmpty) form.setValue('violations.isComplexComplaint', false);
  }, [isDeliveredByNotEmpty]);

  const getDisabled = useCallback(
    (name) => {
      switch (name) {
        case 'violations.isComplexComplaint':
          return !isDeliveredByNotEmpty;
        default:
          return false;
      }
    },
    [isDeliveredByNotEmpty],
  );

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  const editableArizonaCellContextData = useMemo(
    () => ({
      onShowInfo: loadViolationData,
      isLoader,
    }),
    [loadViolationData, isLoader, id],
  );

  return (
    <>
      <Form onSubmit={handleSubmit} form={form}>
        <StyledContainer
          maxWidth={false}
          disableGutters
          sx={{
            mx: 'auto',
            mt: 4,
            px: 4,
            maxWidth: 1542,
            width: 1,
            position: 'relative',
          }}
        >
          {isLoader && <LoaderCover isFixed />}
          <StyledAccordion defaultExpanded sx={{ p: 4 }}>
            <AccordionSummary expandIcon={<ArrowDown />} sx={{ padding: 0 }}>
              <Typography variant="h5">{boxTitle}</Typography>
            </AccordionSummary>
            <Stack direction="row" alignItems="center" columnGap={3} rowGap={2} flexWrap="wrap">
              {inputBoxes.map((item) => {
                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <Dropdown
                          value={field.value}
                          isAddDisabled={false}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          isColumn
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={onCustomChange(field.onChange)}
                          isDisabled={item.isDisabled}
                          isMandatory={item.isMandatory}
                        />
                      )}
                    />
                  );
                }

                if (item?.isSpace) {
                  return <div style={{ width: '100%' }} key={item.label} />;
                }

                if (item.isCheckbox) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      render={({ field, onCustomChange }) => (
                        <CheckboxWithLabel
                          label={item.label}
                          isChecked={field.value}
                          onChange={onCustomChange(field.onChange)}
                          marginTop="25px"
                        />
                      )}
                    />
                  );
                }

                if (item.isRadioButtons) {
                  return (
                    <Field
                      key={item.label}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <CustomRadioGroup
                          data={item.data}
                          value={field.value}
                          label={item.label}
                          width={item.width}
                          radioButtonWidth={item.radioButtonWidth}
                          onChange={onCustomChange(field.onChange)}
                          disabled={getDisabled(item.name)}
                          tooltip={item?.tooltipText && <MTooltip text={item.tooltipText} />}
                          defaultValue={getRadioGroupDefaultValue(item.data)}
                        />
                      )}
                    />
                  );
                }

                if (item.isDatePicker) {
                  return (
                    <Field
                      name={item.name}
                      isMandatory={item.isMandatory}
                      key={item.name}
                      render={({ field, onCustomChange }) => (
                        <DateOrTimePicker
                          tooltip={item?.tooltipText && <MTooltip text={item.tooltipText} />}
                          label={item.label}
                          name={item.name}
                          isDate
                          Svg={Calender}
                          selected={field.value}
                          width={item.width}
                          minDate={item.minDate}
                          height={item.height}
                          placeholder={item.placeholder}
                          isMandatory={item.isMandatory}
                          setSelectedTimeOrDate={onCustomChange(field.onChange)}
                        />
                      )}
                    />
                  );
                }

                if (item.isTimePicker) {
                  return (
                    <EditInputWithLabel label={item.label} key={item.name}>
                      <Field
                        name={item.name}
                        render={({ field, onCustomChange }) => (
                          <DateOrTimePicker
                            name={item.name}
                            isTime
                            Svg={Clock}
                            selected={field.value}
                            setSelectedTimeOrDate={onCustomChange(field.onChange)}
                            width={item.width}
                            height={item.height}
                            placeholder={item.placeholder}
                          />
                        )}
                      />
                    </EditInputWithLabel>
                  );
                }

                const editInputField = (
                  <Field
                    name={item.name}
                    key={item.name}
                    render={({ field }) => (
                      <EditInputWithLabel
                        type="text"
                        label={item.label}
                        placeholder={item.placeholder}
                        width={item.width}
                        name={item.name}
                        value={field.value}
                        onChange={field.onChange}
                        isDisabled={item.isDisabled}
                      />
                    )}
                  />
                );

                if (item.withAdd) {
                  return (
                    <Box width="100%" alignItems="center" display="flex" key={`${item.label} Box`}>
                      {editInputField}
                      <MButton
                        sx={{
                          ml: '24px',
                          mt: '28px',
                        }}
                        onClick={null}
                      >
                        Update
                      </MButton>
                    </Box>
                  );
                }
                return editInputField;
              })}

              {withAdd && (
                <>
                  <LightTooltip
                    title={
                      showNoTemplatesError
                        ? 'There is no available notice to create for selected violation.'
                        : null
                    }
                  >
                    <Box
                      sx={{
                        ml: 'auto',
                        mt: '28px',
                      }}
                    >
                      <MButton
                        disabled={!areMandatoryFieldsFilled || showNoTemplatesError || isLoader}
                        startIcon={<Add />}
                        type="submit"
                      >
                        Add
                      </MButton>
                    </Box>
                  </LightTooltip>

                  {arizonaViolations && (
                    <EditableArizonaCellContext.Provider value={editableArizonaCellContextData}>
                      <Table
                        columns={arizonaViolationsColumns}
                        loading={false}
                        rows={arizonaViolations}
                        onUpdateData={updateViolation}
                        onDeleteRow={deleteRow}
                      />
                    </EditableArizonaCellContext.Provider>
                  )}
                </>
              )}
            </Stack>
          </StyledAccordion>
        </StyledContainer>
      </Form>
      <AdditionalInformationDialog
        isOpen={isAdditionalInformationDialogOpen}
        onClose={handleAdditionalInformationDialogClose}
        templates={violationTemplates}
        caseId={id}
        addViolation={handleSubmitWithGeneration}
      />
      <AdditionalInformationFormDialog
        isOpen={isViolationInfoOpen}
        onClose={() => setIsViolationInfoOpen(false)}
        fields={violationData?.fields ?? []}
        isLoading={isLoader}
        readonly
      />
    </>
  );
};

Violations.propTypes = {
  damagesFormData: PropTypes.shape({
    form: PropTypes.shape({
      getValues: PropTypes.func,
    }),
    onSubmit: PropTypes.func,
  }).isRequired,
};

export default Violations;
