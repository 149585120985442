/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const getCourtNames = createAsyncThunk(
  'bulkDocumentPrintingSlice/getCourtNames',
  async (params, thunkAPI) => {
    const {
      courtNames: { items: prevItems = [] },
    } = thunkAPI.getState().bulkDocumentPrinting;

    const {
      data: {
        result: { items, hasNextPage, ...rest },
      },
    } = await apiClient.post(`/api/courts/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.courtId,
      value: item.courtName,
      label: item.courtName,
    }));

    return {
      ...rest,
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const onOpenCourtNamesDropDown = createAsyncThunk(
  'bulkDocumentPrintingSlice/onOpenCourtNamesDropDown',
  async (params) => {
    const { courtName } = params;
    const { data } = await apiClient.post(`/api/courts/filter`, params);

    const mappedCourtNames = data.result.items.map((item) => ({
      id: item.courtId,
      value: item.courtName,
      label: item.courtName,
    }));

    return {
      ...data.result,
      prevSearchValue: courtName,
      items: mappedCourtNames,
    };
  },
);

export const getDocumentsByFilter = createAsyncThunk(
  'bulkDocumentPrintingSlice/getDocumentsByFilter',
  async (params) => {
    const { stateCode } = params;

    const api =
      stateCode === 'AZ'
        ? '/api/arizonaCases/documents/filter'
        : '/api/nevadaCases/documents/filter';

    const {
      data: { result },
    } = await apiClient.post(api, params);

    result.items = result.items.map((doc) => ({
      ...doc,
      cases: doc.cases.map((c) => ({
        ...c,
        stateCode,
      })),
    }));

    return result;
  },
);

export const resetGetDocumentsByFilter = createAction(
  'bulkDocumentPrinting/resetGetDocumentsByFilter',
);

export const uploadDocument = createAsyncThunk(
  'bulkDocumentPrinting/uploadDocument',
  async (data, { rejectWithValue }) => {
    try {
      const {
        data: { result: id },
      } = await apiClient.post(`/api/cases/documents/recognition`, data);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data.errorMessage);
    }
  },
);

export const checkUploadDocumentStatus = createAsyncThunk(
  'bulkDocumentPrinting/checkUploadDocumentStatus',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.get(`/api/cases/documents/recognition/results/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.errorMessage);
    }
  },
);

const initialState = {
  bulkDocuments: null,
  courtNames: {
    items: [],
  },
  error: null,
};

export const bulkDocumentPrintingSlice = createSlice({
  name: 'bulkDocumentPrintingSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getDocumentsByFilter.fulfilled]: (state, action) => {
      state.bulkDocuments = action.payload;
    },
    [getCourtNames.fulfilled]: (state, action) => {
      state.courtNames = action.payload;
    },
    [resetGetDocumentsByFilter]: (state) => {
      state.bulkDocuments = null;
    },
    [onOpenCourtNamesDropDown.fulfilled]: (state, action) => {
      state.courtNames = action.payload;
    },
  },
});

export default bulkDocumentPrintingSlice.reducer;
