import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Typography, Box } from '@mui/material';
import InputCell from './InputCell';
import { useEllipsisTooltip } from '../../../../hooks/useEllipsisTooltip';
import { LightTooltip } from '../../../atoms/MTooltip/MTooltip';

const InputWithTooltip = ({ row, updateValue, cell }) => {
  const { value = '', column } = cell;

  const [isEdit, setEdit] = useState(false);
  const [val, setVal] = useState(value);
  const ref = useRef(null);
  const { showTooltip } = useEllipsisTooltip(ref.current);
  const error = row.original?.errors?.[column.id];
  const touched = row.original?.touched?.[column.id];

  useEffect(() => {
    if (val !== value) {
      setVal(value);
    }
    setEdit(false);
  }, [row.original?.id, column.id]);

  const onChange = (ev) => {
    setVal(ev.target.value);
  };

  const handleFocus = useCallback(() => {
    setEdit(true);
  }, [row.original.id]);

  const onBlur = useCallback(() => {
    if (!row.original?.id) return;

    setEdit(false);
    updateValue(row.original.id, {
      id: column.id,
      value: val,
      error: undefined,
      touched: true,
    });
  }, [val, column.id, row.original.id]);

  const message = useMemo(() => {
    if (error && touched) return 'Mandatory field';
    return null;
  }, [error, touched, row.original.id, column.id]);

  return (
    <LightTooltip
      disableHoverListener={!showTooltip || isEdit || !!message}
      title={value}
      placement="top"
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
        <InputCell
          isNoBorder={!isEdit && !message}
          onBlur={onBlur}
          onFocus={handleFocus}
          data-testid="cell_value_input"
          value={val}
          onChange={onChange}
          placeholder="Enter Description"
          error={!!message}
          inputProps={{
            style: {
              textOverflow: showTooltip && !isEdit && !message ? 'ellipsis' : '',
            },
            ref,
          }}
        />
        {message && !value && (
          <Typography
            sx={{ position: 'absolute', bottom: '-15px', fontSize: '12px' }}
            color="error"
          >
            {message}
          </Typography>
        )}
      </Box>
    </LightTooltip>
  );
};

InputWithTooltip.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    column: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  row: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    original: PropTypes.shape({
      id: PropTypes.number,
      errors: PropTypes.shape({ apartmentNumber: PropTypes.string }),
      hasNoAppartmentNumber: PropTypes.bool,
      touched: PropTypes.shape({ apartmentNumber: PropTypes.bool }),
    }),
  }).isRequired,
  updateValue: PropTypes.func.isRequired,
};

InputWithTooltip.defaultProps = {
  cell: {
    value: '',
    column: {
      id: '',
    },
  },
};

export default React.memo(InputWithTooltip);
