/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import MDialog from '../../../../atoms/MDialog';
import MultilineTextField from '../../../../atoms/MultilineTextField/MultilineTextField';
import { Stack } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import { ReactComponent as Calender } from '../../../../../assets/icons/Calender.svg';
import DateOrTimePicker from '../../../../atoms/DateOrTimePicker';

const MotionToReissueLockout = ({ isOpen, handleConfirm, handleClose }) => {
  const [filingDate, setFilingDate] = useState(null);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setDescription('');
      setFilingDate(null);
    }
  }, [isOpen]);

  const handleDescriptionChange = useCallback((event) => {
    setDescription(event.target.value);
  }, []);

  const onConfirm = () => {
    handleConfirm({
      MotionDescription: description,
      FillingDate: filingDate,
    });
  };

  return (
    <MDialog
      onClose={handleClose}
      isOpen={isOpen}
      maxWidth="582px"
      minHeight="396px"
      fullWidth
      title="Motion Description"
    >
      <Stack rowGap={3} mb={4}>
        <DateOrTimePicker
          isDate
          Svg={Calender}
          label="Filing Date"
          selected={filingDate}
          width="226px"
          height="48px"
          placeholder="mm/dd/yyyy"
          setSelectedTimeOrDate={setFilingDate}
        />
        <MultilineTextField
          error={false}
          value={description}
          isMandatory={true}
          rows={10}
          label="Motion Description"
          placeholder="Enter Motion Description"
          onChange={handleDescriptionChange}
        />
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="flex-end" height="auto">
        <MButton variant="secondary" size="large" onClick={handleClose} sx={{ width: '104px' }}>
          Cancel
        </MButton>
        <MButton size="large" onClick={onConfirm} sx={{ width: '104px' }} disabled={!description}>
          Create
        </MButton>
      </Stack>
    </MDialog>
  );
};

export default MotionToReissueLockout;
