import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import uniqBy from 'lodash/uniqBy';
import { Box, CircularProgress, Container, Stack, Typography, styled } from '@mui/material';
import MButton from '../../../../MUI/Button/MButton';
import Dropdown from '../../../../atoms/Dropdown';
import Form from '../../../../atoms/Form';
import Field from '../../../../atoms/Field';
import Table from '../../../../atoms/Table/Table';
import EditInputWithLabel from '../../../../molecules/EditInputWithLabel';
import DialogContacts from '../../../../organisms/DialogContacts';
import { palette } from '../../../../../theme/default';
import { createCommunity } from '../../../../../store/slices/createCommunitySlice';
import {
  editCommunity,
  getCommunity,
  getManagements,
  getStates,
  editCommunityContacts,
  deleteCommunityContacts,
  getCommunityContacts,
} from '../../../../../store/slices/editCommunitySlice';
import useCommunity from '../../../../../hooks/useCommunity';
import {
  communityHeading,
  communityBoxes,
  contactColumns,
  invoiceDropdown,
  invoiceDetailDropdown,
} from './Community.constants';
import CustomRadioGroup from '../../../../atoms/CustomRadioGroup/CustomRadioGroup';
import { useNavigationBlockPopup } from '../../../../../hooks/useNavigationBlockPopup';
import useSearchDropdown from '../../../../../hooks/useSearchDropdown';
import notificationUtils from '../../../../../utils/notificationUtils';
import LoaderCover from '../../../../atoms/LoaderCover';
import { rolesDB, useAllowed } from '../../../../../utils/roleHelpers';

const StyledTypography = styled(Typography)({
  '&:not(:first-of-type)': {
    marginTop: 32,
  },
});

const Community = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    state: { navPage, subNav, id, state: stateCode },
  } = useLocation();

  const { popup, handleChangeBlockerByDefault } = useNavigationBlockPopup({
    blockerByDefault: !id,
  });

  const { editableCommunity, managements, states, editableCommunityContacts } = useSelector(
    (state) => state.editCommunity,
  );

  const { state, actions } = useCommunity();
  const [isLoader, setIsLoader] = useState(true);
  const [isOpenDialogContacts, setIsOpenDialogContacts] = useState(false);
  const form = useForm({ defaultValues: state });
  const canUpdate = useAllowed(rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney);

  const canCreate = useAllowed(rolesDB.Admin, rolesDB.Accounting, rolesDB.Attorney);

  useEffect(() => {
    form.setValue('contactInfo.stateCode', stateCode);
  }, [stateCode]);

  useEffect(() => {
    if (id) {
      dispatch(getCommunity(id)).then(() => setIsLoader(false));
    } else {
      setIsLoader(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      form.reset({
        ...editableCommunity,
        managementCompanyName: editableCommunity?.managementId
          ? {
              id: editableCommunity?.managementId,
              value: editableCommunity?.managementCompanyName,
              label: editableCommunity?.managementCompanyName,
            }
          : null,
        invoicing: {
          ...editableCommunity.invoicing,
          invoiceFrequency: invoiceDropdown.find(
            (item) => item.value === editableCommunity?.invoicing?.invoiceFrequency,
          ),
          invoicingDetail: invoiceDetailDropdown.find(
            (item) => item.value === editableCommunity?.invoicing?.invoicingDetail,
          ),
        },
        contactInfo: {
          ...editableCommunity.contactInfo,
          stateCode: states?.find(
            (item) => item.label === editableCommunity?.contactInfo?.stateCode,
          ),
        },
      });
    } else {
      form.setValue(
        'invoicing.invoiceFrequency',
        invoiceDropdown.find((item) => item.isDefault),
      );
      form.setValue(
        'invoicing.invoicingDetail',
        invoiceDetailDropdown.find((item) => item.isDefault),
      );
      form.setValue('invoicing.isOnHold', false);
    }
  }, [id, editableCommunity]);

  useEffect(() => {
    if (id) {
      form.setValue('contacts', editableCommunityContacts);
    }
  }, [id, editableCommunityContacts]);

  useEffect(() => {
    dispatch(getStates());
  }, []);

  const {
    handleMenuScrollToBottom: handleManagementsScrollToBottom,
    changeInput: changeManagementInput,
  } = useSearchDropdown({
    hasNextPage: managements.hasNextPage,
    getAction: ({ input, page }) =>
      getManagements({
        currentPage: page,
        managementName: input,
      }),
  });

  const getOptions = useCallback(
    (inputBoxesItemName) => {
      switch (inputBoxesItemName) {
        case 'managementCompanyName':
          return [
            { id: 0, value: null, label: '-' },
            ...(managements?.items?.length ? managements.items : []),
          ];
        case 'invoicing.invoiceFrequency':
          return invoiceDropdown;
        case 'invoicing.invoicingDetail':
          return invoiceDetailDropdown || [];
        case 'contactInfo.stateCode':
          return states || [];
        default:
          return [];
      }
    },
    [managements.items, invoiceDropdown],
  );

  const getDropdownHandleInput = useCallback(
    (name) => {
      switch (name) {
        case 'managementCompanyName':
          return changeManagementInput;
        default:
          return () => {};
      }
    },
    [changeManagementInput],
  );

  const getDropdownScrollToBottom = useCallback(
    (name) => {
      switch (name) {
        case 'managementCompanyName':
          return handleManagementsScrollToBottom;
        default:
          return () => {};
      }
    },
    [changeManagementInput],
  );

  const handleClickAddContact = useCallback(() => {
    setIsOpenDialogContacts(true);
  }, []);

  // eslint-disable-line no-unused-vars
  const handleDeleteContact = (idRow, { contactId }) => {
    if (id) {
      dispatch(deleteCommunityContacts({ id, contactId })).then(() => {
        dispatch(getCommunityContacts(id));
      });
    } else {
      const addedContacts = form.getValues('contacts') || [];
      const filteredContacts = addedContacts.filter((item) => item.contactId !== contactId);
      form.setValue('contacts', filteredContacts);
    }
  };

  const handleSubmit = useCallback(
    async (formFields) => {
      const { managementCompanyName, ...rest } = formFields;

      if (id) {
        await dispatch(
          editCommunity({
            ...rest,
            id,
            managementId: managementCompanyName?.id || null,
            invoicing: {
              ...rest?.invoicing,
              invoiceFrequency: rest?.invoicing?.invoiceFrequency?.label,
              invoicingDetail: rest.invoicing?.invoicingDetail?.value,
            },
            contactInfo: {
              ...rest?.contactInfo,
              stateCode: rest?.contactInfo?.stateCode?.label,
            },
          }),
        )
          .unwrap()
          .then(() => {
            dispatch(getCommunity(id));
            notificationUtils.success('Updated successfully');
          })
          .catch((rejectedValueError) => {
            notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
          });
      } else {
        handleChangeBlockerByDefault(false);
        await dispatch(
          createCommunity({
            ...formFields,
            managementId: formFields?.managementCompanyName?.id,
            invoicing: {
              ...formFields?.invoicing,
              invoiceFrequency: formFields?.invoicing?.invoiceFrequency?.label,
              invoicingDetail: formFields.invoicing?.invoicingDetail?.value,
            },
            contactInfo: {
              ...formFields?.contactInfo,
              stateCode: formFields?.contactInfo?.stateCode?.label,
            },
            secondaryKeys: {
              customerRefListId: formFields?.secondaryKeys?.customerRefListId,
              customerRefFullName: formFields?.secondaryKeys?.customerRefFullName,
            },
          }),
        )
          .unwrap()
          .then((originalPromiseResult) => {
            const { result } = originalPromiseResult;
            notificationUtils.success('Created successfully');
            navigate('/db/datahandling/editcommunity/community', {
              state: {
                navPage,
                subNav,
                id: result,
                name: formFields?.communityName,
                state: stateCode,
              },
            });
            actions?.clear();
            form.reset();
          })
          .catch((rejectedValueError) => {
            notificationUtils.error(rejectedValueError?.errorMessage || 'Unexpected error');
          });
      }
    },
    [id],
  );

  const handleCancel = useCallback(() => {
    navigate('/db/datahandling/communities', {
      state: {
        navPage,
        subNav,
        state: stateCode,
      },
    });
  }, [stateCode]);

  const handleConfirmDialogContacts = useCallback(
    (selectedIdsContacts) => {
      if (id) {
        dispatch(
          editCommunityContacts({
            id,
            communityId: id,
            selectedContacts: selectedIdsContacts,
          }),
        ).then(() => {
          dispatch(getCommunityContacts(id));
        });
      } else {
        const currentContacts = form.getValues('contacts') || [];
        const contacts = currentContacts.concat(selectedIdsContacts);
        const uniqValues = uniqBy(contacts, (e) => e.contactId);
        form.setValue('contacts', uniqValues);
      }
      setIsOpenDialogContacts(false);
    },
    [id],
  );

  const handleCloseDialogContacts = useCallback(() => {
    setIsOpenDialogContacts(false);
  }, []);

  const defineIsDisabled = (fieldName, isFieldDisabled = false) => {
    if (fieldName === 'contactInfo.stateCode') return true;
    return isFieldDisabled;
  };

  const getRadioGroupDefaultValue = useCallback((options) => {
    return options.find((item) => item.isDefault) || null;
  }, []);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        mx: 'auto',
        mt: 4,
        mb: 9 + 4 + 4 + 6,
        px: 4,
        width: 1,
        maxWidth: 1542,
      }}
    >
      {popup}
      {!isLoader ? (
        <>
          {form.formState.isSubmitting && <LoaderCover isFixed />}
          <Typography variant="h4">{communityHeading}</Typography>
          <Form onSubmit={handleSubmit} form={form}>
            {communityBoxes?.map((data) => {
              return (
                <Container
                  key={data.boxTitle}
                  maxWidth={false}
                  disableGutters
                  sx={{
                    mx: 'auto',
                    mt: 4,
                    width: 1,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={3}
                    rowGap={3}
                    flexWrap="wrap"
                  >
                    {data.inputBoxes.map((item) => {
                      if (item.isDropdown) {
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, onCustomChange, error }) => {
                              if (id && !canUpdate) {
                                return (
                                  <EditInputWithLabel
                                    type="text"
                                    label={item.label}
                                    width={item.width}
                                    name={item.name}
                                    value={field?.value?.label || '-'}
                                    readOnly
                                  />
                                );
                              }
                              return (
                                <Dropdown
                                  value={field.value}
                                  isSearchable
                                  key={item.label}
                                  label={item.label}
                                  width={item.width}
                                  isColumn
                                  placeholder={item.placeholder}
                                  options={getOptions(item.name)}
                                  onChange={onCustomChange(field.onChange)}
                                  isDisabled={defineIsDisabled(item.name, item.isDisabled)}
                                  isMandatory={item.isMandatory}
                                  error={error}
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item.isDynamicSearchDropdown) {
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            isMandatory={item.isMandatory}
                            render={({ field, onCustomChange, error }) => {
                              if (id && !canUpdate)
                                return (
                                  <EditInputWithLabel
                                    type="text"
                                    label={item.label}
                                    width={item.width}
                                    name={item.name}
                                    value={field?.value?.label || '-'}
                                    readOnly
                                  />
                                );
                              return (
                                <Dropdown
                                  value={field.value}
                                  isSearchable
                                  key={item.label}
                                  label={item.label}
                                  width={item.width}
                                  isColumn
                                  placeholder={item.placeholder}
                                  options={getOptions(item.name)}
                                  onChange={onCustomChange(field.onChange)}
                                  isMandatory={item.isMandatory}
                                  onMenuScrollToBottom={getDropdownScrollToBottom(item.name)}
                                  onInputChange={getDropdownHandleInput(item.name)}
                                  filterOption={null}
                                  error={error}
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item.isRadioButtons) {
                        return (
                          <Field
                            key={item.label}
                            name={item.name}
                            render={({ field, onCustomChange }) => {
                              if (id && !canUpdate) {
                                return (
                                  <EditInputWithLabel
                                    type="text"
                                    label={item.label}
                                    width={item.width}
                                    name={item.name}
                                    value={
                                      item.data.find((i) => i.value === field.value)?.label || '-'
                                    }
                                    readOnly
                                  />
                                );
                              }
                              return (
                                <CustomRadioGroup
                                  data={item.data}
                                  value={field.value}
                                  label={item.label}
                                  width={item.width}
                                  radioButtonWidth={item.radioButtonWidth}
                                  onChange={onCustomChange(field.onChange)}
                                  disabled={item.isDisabled}
                                  defaultValue={getRadioGroupDefaultValue(item.data)}
                                />
                              );
                            }}
                          />
                        );
                      }

                      if (item.isSubHeading) {
                        return (
                          <StyledTypography width={1} mb={1} variant="bodyL500" key={item.label}>
                            {item.label}
                          </StyledTypography>
                        );
                      }

                      if (item.isSubHeadingWithButton) {
                        return (
                          <Stack
                            direction="row"
                            alignContent="center"
                            columnGap={2}
                            mt={3}
                            key={item.label}
                            width={1}
                          >
                            <StyledTypography variant="bodyL500">{item.label}</StyledTypography>
                            <MButton startIcon={item.buttonIcon} onClick={handleClickAddContact}>
                              {item.buttonName}
                            </MButton>
                          </Stack>
                        );
                      }

                      if (item.isTable) {
                        return (
                          <>
                            <Field
                              key={item.label}
                              name={item.name}
                              isMandatory={item.isMandatory}
                              render={({ field }) => {
                                return (
                                  !!field?.value?.length && (
                                    <Table
                                      columns={contactColumns}
                                      rows={field.value.map((contact) => ({
                                        ...contact,
                                        control: {
                                          hideDelete: !contact?.contactId,
                                        },
                                      }))}
                                      total={field?.value?.length}
                                      onDeleteRow={handleDeleteContact}
                                      loading={false}
                                    />
                                  )
                                );
                              }}
                            />
                            <DialogContacts
                              title="Contact Person"
                              buttonSecondaryText="Cancel"
                              buttonPrimaryText="Add"
                              onClose={handleCloseDialogContacts}
                              onConfirm={handleConfirmDialogContacts}
                              isOpen={isOpenDialogContacts}
                            />
                          </>
                        );
                      }

                      return (
                        <Field
                          key={item.label}
                          name={item.name}
                          isMandatory={item.isMandatory}
                          render={({ field, error }) => (
                            <EditInputWithLabel
                              type="text"
                              label={item.label}
                              placeholder={item.placeholder}
                              width={item.width}
                              name={item.name}
                              value={id && !canUpdate ? field.value || '-' : field.value}
                              onChange={field.onChange}
                              isDisabled={item.isDisabled}
                              isMandatory={((id && canUpdate) || !id) && item.isMandatory}
                              error={error}
                              readOnly={id && !canUpdate}
                            />
                          )}
                        />
                      );
                    })}
                  </Stack>
                </Container>
              );
            })}
            {((id && canUpdate) || (!id && canCreate)) && (
              <Box
                position="absolute"
                left="50%"
                bottom={0}
                sx={{ transform: 'translateX(-50%)' }}
                zIndex={10}
                width={1}
                p={4}
                boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
              >
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <MButton
                    variant="secondary"
                    size="large"
                    onClick={handleCancel}
                    sx={{ width: '144px' }}
                  >
                    Cancel
                  </MButton>
                  <MButton
                    type="submit"
                    size="large"
                    disabled={form.formState.isSubmitting}
                    name="Submit community"
                    sx={{ width: '144px' }}
                  >
                    {id ? 'Update' : 'Create'}
                  </MButton>
                </Stack>
              </Box>
            )}
          </Form>
        </>
      ) : (
        <Box
          position="absolute"
          top="50vh"
          left="50%"
          zIndex={10}
          transform="translate(-50%, -50%)"
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default Community;
