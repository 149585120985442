import React from 'react';
import { Outlet } from 'react-router-dom';
import useSafeLocationState from '../../../hooks/useSafeLocationState';
import Page from '../../organisms/Page/Page';

const Admin = () => {
  const {
    navPage: navPageString = '',
    subNav: subNavString = '',
    subSubNavigation = '',
  } = useSafeLocationState();

  return (
    <Page navPage={navPageString} subNavigation={subNavString} subSubNavigation={subSubNavigation}>
      <Outlet />
    </Page>
  );
};

export default Admin;
