import { ReactComponent as GoingToCourtIcon } from '../../../../../assets/icons/GoingToCourt.svg';
import { ReactComponent as RequiringFollowUpIcon } from '../../../../../assets/icons/RequiringFollowUp.svg';
import { ReactComponent as PendingApprovalIcon } from '../../../../../assets/icons/PendingApproval.svg';
import { ReactComponent as AvailableLockoutIcon } from '../../../../../assets/icons/AvailableLockout.svg';

import { DefendantCell, ReferenceNumberCell } from '../../../Processing/CaseSearch/CaseSearchCells';
import { DateCell } from './cells/DateCell';
import AssignToMeCell from './cells/AssignToMeCell';
import { PrefileDateCell } from './cells/PrefileDateCell';

export const NOTICES_TO_BE_DRAFTED = 'toBeDraftedNotices';
export const CASES_TO_BE_ENTERED = 'toBeEnteredCases';
export const PREFILES_TO_BE_PRINTED = 'toBePrintedPrefiles';
export const FOLLOW_UPS = 'followUpCases';

export const cardsListByState = {
  AZ: [
    {
      id: NOTICES_TO_BE_DRAFTED,
      title: 'Notices To Be Drafted',
      base: 'case',
      icon: <GoingToCourtIcon />,
    },
    {
      id: CASES_TO_BE_ENTERED,
      title: 'Cases To Be Entered',
      base: 'case',
      icon: <RequiringFollowUpIcon />,
    },
    {
      id: PREFILES_TO_BE_PRINTED,
      title: 'Prefiles To Be Printed',
      base: 'prefile',
      icon: <AvailableLockoutIcon />,
    },
    {
      id: FOLLOW_UPS,
      title: 'Follow-Ups',
      base: 'case',
      icon: <PendingApprovalIcon />,
    },
  ],
  NV: [
    {
      id: NOTICES_TO_BE_DRAFTED,
      title: 'Notices To Be Drafted',
      base: 'case',
      icon: <GoingToCourtIcon />,
    },
    {
      id: CASES_TO_BE_ENTERED,
      title: 'Cases To Be Entered',
      base: 'case',
      icon: <RequiringFollowUpIcon />,
    },
    {
      id: FOLLOW_UPS,
      title: 'Follow-Ups',
      base: 'case',
      icon: <PendingApprovalIcon />,
    },
  ],
};

export const listConfig = {
  [NOTICES_TO_BE_DRAFTED]: {
    title: 'Notices To Be Drafted',
    periods: [
      { label: 'All notices', value: false },
      { label: 'Unassigned', value: 'unassigned' },
      { label: 'My notices', value: true },
    ],
    defaultPeriod: false,
    columns: ({ period, stateCode }) => {
      const allColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 15,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 15,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 15,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 10,
        },
        {
          Header: 'Creation Date',
          accessor: 'creationDate',
          Cell: DateCell,
          width: 15,
        },
        {
          Header: 'Legal Assistant',
          accessor: 'legalAssistantFullName',
          width: 20,
        },
        {
          Header: '',
          accessor: 'canBeReassignedToMe',
          Cell: (props) => AssignToMeCell({ ...props, card: NOTICES_TO_BE_DRAFTED, stateCode }),
          width: 10,
          minWidth: 130,
        },
      ];
      const unassignColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 15,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 15,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 20,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 20,
        },
        {
          Header: 'Creation Date',
          accessor: 'creationDate',
          Cell: DateCell,
          width: 20,
        },
        {
          Header: '',
          accessor: 'canBeReassignedToMe',
          Cell: (props) => AssignToMeCell({ ...props, card: NOTICES_TO_BE_DRAFTED, stateCode }),
          width: 10,
          minWidth: 130,
        },
      ];
      const myColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 20,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 20,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 20,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 20,
        },
        {
          Header: 'Creation Date',
          accessor: 'creationDate',
          Cell: DateCell,
          width: 20,
        },
      ];
      if (!period) {
        return allColumns;
      }
      if (period === 'unassigned') {
        return unassignColumns;
      }
      return myColumns;
    },
  },
  [CASES_TO_BE_ENTERED]: {
    title: 'Cases To Be Entered',
    periods: [
      { label: 'All cases', value: false },
      { label: 'Unassigned', value: 'unassigned' },
      { label: 'My cases', value: true },
    ],
    defaultPeriod: true,
    columns: ({ period, stateCode }) => {
      const allColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 15,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 15,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 15,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 10,
        },
        {
          Header: 'Creation Date',
          accessor: 'creationDate',
          Cell: DateCell,
          width: 15,
        },
        {
          Header: 'Legal Assistant',
          accessor: 'legalAssistantFullName',
          width: 20,
        },
        {
          Header: '',
          accessor: 'canBeReassignedToMe',
          Cell: (props) => AssignToMeCell({ ...props, card: CASES_TO_BE_ENTERED, stateCode }),
          width: 10,
          minWidth: 130,
        },
      ];
      const unassignColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 15,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 15,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 20,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 20,
        },
        {
          Header: 'Creation Date',
          accessor: 'creationDate',
          Cell: DateCell,
          width: 20,
        },
        {
          Header: '',
          accessor: 'canBeReassignedToMe',
          Cell: (props) => AssignToMeCell({ ...props, card: CASES_TO_BE_ENTERED, stateCode }),
          width: 10,
          minWidth: 130,
        },
      ];
      const myColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 20,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 20,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 20,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 20,
        },
        {
          Header: 'Creation Date',
          accessor: 'creationDate',
          Cell: DateCell,
          width: 20,
        },
      ];
      if (!period) {
        return allColumns;
      }
      if (period === 'unassigned') {
        return unassignColumns;
      }
      return myColumns;
    },
  },
  [FOLLOW_UPS]: {
    title: 'Follow-Ups',
    periods: [
      { label: 'All cases', value: false },
      { label: 'My cases', value: true },
    ],
    defaultPeriod: true,
    columns: ({ period }) => {
      const allColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 15,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 15,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 15,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 15,
        },
        {
          Header: 'Last Updated Date',
          accessor: 'lastUpdatedDate',
          Cell: DateCell,
          width: 10,
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: 10,
        },
        {
          Header: 'Legal Assistant',
          accessor: 'legalAssistantFullName',
          width: 20,
        },
      ];
      const unassignColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 15,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 15,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 15,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 20,
        },
        {
          Header: 'Last Updated Date',
          accessor: 'lastUpdatedDate',
          Cell: DateCell,
          width: 20,
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: 15,
        },
      ];
      const myColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 20,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 20,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 20,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 15,
        },
        {
          Header: 'Last Updated Date',
          accessor: 'lastUpdatedDate',
          Cell: DateCell,
          width: 15,
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: 10,
        },
      ];
      if (!period) {
        return allColumns;
      }
      if (period === 'unassigned') {
        return unassignColumns;
      }
      return myColumns;
    },
  },
  [PREFILES_TO_BE_PRINTED]: {
    title: 'Prefiles To Be Printed',
    periods: [
      { label: 'All prefiles', value: false },
      { label: 'My prefiles', value: true },
    ],
    defaultPeriod: true,
    columns: ({ period, isDateInThePast }) => {
      const allColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 20,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 20,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 15,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 15,
        },
        {
          Header: 'Prefile Date',
          accessor: 'prefileDate',
          Cell: (props) => <PrefileDateCell {...props} isDateInThePast={isDateInThePast} />,
          width: 10,
          minWidth: 150,
        },
        {
          Header: 'Legal Assistant',
          accessor: 'legalAssistantFullName',
          width: 20,
        },
      ];
      const unassignColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 20,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 20,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 20,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 20,
        },
        {
          Header: 'Prefile Date',
          accessor: 'prefileDate',
          Cell: (props) => <PrefileDateCell {...props} isDateInThePast={isDateInThePast} />,
          width: 20,
          minWidth: 150,
        },
      ];
      const myColumns = [
        {
          Header: 'Reference #',
          accessor: 'referenceNumber',
          Cell: ReferenceNumberCell,
          width: 20,
        },
        {
          Header: 'Community Name',
          accessor: 'communityName',
          width: 20,
        },
        {
          Header: 'Opposing Party Name',
          accessor: 'opposingParty',
          Cell: DefendantCell,
          width: 20,
        },
        {
          Header: 'Apartment #',
          accessor: 'apartmentNumber',
          width: 20,
        },
        {
          Header: 'Prefile Date',
          accessor: 'prefileDate',
          Cell: (props) => <PrefileDateCell {...props} isDateInThePast={isDateInThePast} />,
          width: 20,
        },
      ];

      if (!period) {
        return allColumns;
      }
      if (period === 'unassigned') {
        return unassignColumns;
      }
      return myColumns;
    },
  },
};
