import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled, Radio } from '@mui/material';
import { palette } from '../../../theme/default/palette';

const StyledRadio = styled(Radio)(({ isTogglable }) => ({
  color: palette.buttonPrimary.active,
  width: 16,
  height: 16,

  '&:hover': {
    color: isTogglable ? palette.buttonPrimary.active : palette.buttonPrimary.hover,
    cursor: isTogglable ? 'default' : 'pointer',
    backgroundColor: isTogglable ? palette.common.white : palette.buttonPrimary.hover,
  },

  '&.Mui-checked': {
    color: palette.buttonPrimary.active,

    '&:hover': {
      color: isTogglable ? palette.buttonPrimary.active : palette.buttonPrimary.hover,
      cursor: isTogglable ? 'default' : 'pointer',
      backgroundColor: isTogglable ? palette.common.white : palette.buttonPrimary.hover,
    },
  },

  '&.Mui-disabled': {
    borderRadius: '50%',
    backgroundColor: palette.text.disabled,

    '&:hover': {
      color: palette.buttonPrimary.disabled,
    },

    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },
}));

const MRadioButton = ({ isChecked: isCheckedProps, isDisabled, onChange, isTogglable }) => {
  const [isChecked, setChecked] = useState(isCheckedProps);

  useEffect(() => {
    setChecked(isCheckedProps);
  }, [isCheckedProps]);

  const handleChange = useCallback(
    (event) => {
      if (isTogglable) {
        const { checked } = event.target;
        setChecked(checked);
        onChange?.(checked);
      }
    },
    [isChecked],
  );

  return (
    <StyledRadio
      disableRipple
      isTogglable
      checked={isChecked}
      disabled={isDisabled}
      onChange={handleChange}
      data-testid="radio_wrapper"
      inputProps={{ 'data-testid': 'radio_input' }}
    />
  );
};

MRadioButton.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  isTogglable: PropTypes.bool,
};

MRadioButton.defaultProps = {
  isChecked: false,
  isDisabled: false,
  onChange: null,
  isTogglable: true,
};

export default MRadioButton;
