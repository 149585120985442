import React from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@mui/material';

const ActiveCell = ({ value }) => {
  return <Typography variant="bodyM">{value ? 'Active' : ''}</Typography>;
};

ActiveCell.propTypes = {
  value: PropTypes.bool,
};

ActiveCell.defaultProps = {
  value: false,
};

export default ActiveCell;
