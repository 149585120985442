import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const LegalCategoryCell = ({ value, row, rowClick }) => {
  const referenceNumber = row?.original?.referenceNumber;
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
        textDecoration: 'underline',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      data-testid="legal_category_cell"
      onClick={() => rowClick({ referenceNumber, categoryHistory: value })}
    >
      {Array.isArray([value]) ? value[0]?.name : ''}
    </Typography>
  );
};

LegalCategoryCell.propTypes = {
  value: {
    id: PropTypes.number,
    name: PropTypes.string,
    date: PropTypes.string,
    description: PropTypes.string,
  },
  row: PropTypes.shape({
    original: PropTypes.shape({
      referenceNumber: PropTypes.string,
    }),
  }).isRequired,
  rowClick: PropTypes.func,
};

LegalCategoryCell.defaultProps = {
  value: {},
  rowClick: null,
};

export default LegalCategoryCell;
