import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Typography, styled, LinearProgress, Box } from '@mui/material';
import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';
import { downloadDocument } from '../../../../store/slices/feeScheduleSlice';

const StyledTypography = styled(Typography)(({ underlined }) => ({
  textDecoration: underlined && 'underline',
  '&:hover': {
    cursor: underlined && 'pointer',
  },
}));

export const DocumentsLinkCell = ({ value: docName, row }) => {
  const initialValue = docName || 'No name';
  const splitFilename = (val = initialValue) => {
    const dotIndex = val.lastIndexOf('.');
    return dotIndex === -1 ? [val, ''] : [val.slice(0, dotIndex), val.slice(dotIndex + 1)];
  };

  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);

  const handleDownload = () => {
    if (!isLoader) {
      setIsLoader(true);
      dispatch(downloadDocument(row.original.id))
        .unwrap()
        .then(({ result }) => {
          setIsLoader(false);
          openOrDownloadBinary({
            contentType: result.contentType,
            content: result.content,
            fileName: result.originalFileName,
          });
        });
    }
  };

  if (isLoader) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <StyledTypography
      component="a"
      onClick={handleDownload}
      variant="primary"
      color="primary"
      underlined
    >
      {splitFilename(initialValue)[0]}
    </StyledTypography>
  );
};

DocumentsLinkCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
};
