import { LinearProgress, Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRequestWritArizona } from '../../../../../store/slices/clientPortalDashboardSlice';

import MButton from '../../../../MUI/Button/MButton';
import notificationUtils from '../../../../../utils/notificationUtils';

const AvailableWritsCell = ({
  row: {
    original: { caseId },
  },
  refreshPage,
  rows,
  canPreviousPage,
  goPreviousPage,
}) => {
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const shouldGoBack = rows.length === 1 && canPreviousPage;

  const onOrderWrit = async () => {
    setIsLoader(true);
    const res = await dispatch(setRequestWritArizona(caseId));

    if (res.error) {
      notificationUtils.error('Failed, try again later');
    } else {
      notificationUtils.success('Ordered successfully');
      if (shouldGoBack) goPreviousPage();
      refreshPage();
    }
    setIsLoader(false);
  };

  if (isLoader) {
    return (
      <Box sx={{ width: '200px' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Stack direction="row">
      <MButton variant="secondary" size="small" onClick={onOrderWrit} sx={{ textWrap: 'nowrap' }}>
        Order Writ
      </MButton>
    </Stack>
  );
};

AvailableWritsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      documentId: PropTypes.number.isRequired,
      stateCode: PropTypes.string.isRequired,
    }),
  }).isRequired,
  refreshPage: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  goPreviousPage: PropTypes.func.isRequired,
};

export default AvailableWritsCell;
