/* eslint-disable */
import { Stack } from '@mui/material';

import { Bar } from 'react-chartjs-2';
import { palette } from '../../../../theme/default';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { ALL_STATUSES } from '../../../../global/constants';
import { alterHexColor } from '../../../../utils/alterHexColor';

const findColorByStatus = (status) => {
  return ALL_STATUSES.find((item) => item.label === status)?.color;
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        drawBorder: false,
        lineWidth: 0,
      },
      ticks: {
        font: {
          size: 14,
        },
      },
    },
    y: {
      stacked: true,
      grid: {
        drawBorder: false,
        lineWidth: function (context) {
          return context?.index === 0 ? 0 : 1;
        },
        color: palette.additional.lines,
      },
      border: {
        display: false,
      },
      ticks: {
        min: 0,
        stepSize: 25,
        font: {
          size: 14,
        },
      },
    },
  },
};

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const BarChart = ({ chartData }) => {
  const setChartData = (values) => {
    const initialDataset = {
      labels: [],
      datasets: [
        {
          label: 'AZ',
          data: {},
          backgroundColor: [],
        },
        {
          label: 'NV',
          data: {},
          backgroundColor: [],
        },
      ],
    };

    const allStatuses = values.map((item) => item.statuses).flatMap((item) => item);

    allStatuses.forEach((element) => {
      if (!initialDataset.labels.includes(element.status)) {
        initialDataset.labels.push(element.status);
      }

      const { states } = element;
      const arizonaState = states.find((item) => item.stateCode === 'AZ');
      const nevadaState = states.find((item) => item.stateCode === 'NV');

      const [arizonaSet, nevadaSet] = initialDataset.datasets;

      if (arizonaState) {
        const curSum = arizonaSet.data[element.status] || 0;
        arizonaSet.data[element.status] = curSum + arizonaState.value;

        const color = findColorByStatus(element.status);
        arizonaSet.backgroundColor.push(color);
      }

      if (nevadaState) {
        const curSum = nevadaSet.data[element.status] || 0;
        nevadaSet.data[element.status] = curSum + nevadaState.value;

        const color = findColorByStatus(element.status);
        nevadaSet.backgroundColor.push(alterHexColor(color, 20));
      }
    });

    return initialDataset;
  };

  const getChartMonths = (values) => {
    const allStatuses = values
      .map((item) => item.statuses)
      .flatMap((item) => item)
      .map((item) => item.total);
    const maxTotalValue = Math.max.apply(null, allStatuses);

    let step = 10;

    if (maxTotalValue > 0 && maxTotalValue < 250) step = 25;
    if (maxTotalValue > 250 && maxTotalValue < 500) step = 50;
    if (maxTotalValue > 500 && maxTotalValue < 1000) step = 100;
    if (maxTotalValue > 1000) step = 200;

    const months = [];
    values.forEach((item) => {
      if (item.fromDate) {
        months.push(moment(item.fromDate).format('MMMM'));
      }
      if (item.toDate) {
        months.push(moment(item.toDate).format('MMMM'));
      }
    });

    const allSelectedMonths = Array.from(new Set(months).values());

    const orderedMonths = [];

    MONTHS.forEach((month) => {
      if (allSelectedMonths.includes(month)) {
        orderedMonths.push(month);
      }
    });

    return {
      ...options,
      plugins: {
        ...options.plugins,
        title: {
          display: true,
          text: orderedMonths.join(', '),
          font: {
            size: '16px',
          },
        },
      },
      scales: {
        ...options.scales,
        y: {
          ...options.scales.y,
          ticks: {
            ...options.scales.y.ticks,
            stepSize: step,
          },
        },
      },
    };
  };

  if (!chartData.length) {
    return null;
  }

  const chartOptions = getChartMonths(chartData);
  const chartValues = setChartData(chartData);

  return (
    <Stack width="100%" display="flex" direction="row" alignItems="flex-start">
      <Stack width="60%" height="100%">
        <Bar options={chartOptions} data={chartValues} />
      </Stack>
      <ul
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          flexGrow: 1,
          listStyle: 'none',
          fontSize: '16px',
          fontWeight: 600,
          margin: 0,
          padding: '0 0 0 90px',
          width: '35%',
          alignSelf: 'center',
        }}
      >
        {chartValues.labels.map((label, id) => {
          return (
            <li
              key={id}
              style={{
                width: '50%',
                marginBottom: '14px',
                display: 'flex',
              }}
            >
              <div
                style={{
                  minWidth: '20px',
                  maxHeight: '20px',
                  borderRadius: '50%',
                  backgroundColor: findColorByStatus(label),
                  marginRight: '14px',
                }}
              />
              {label}
            </li>
          );
        })}
      </ul>
    </Stack>
  );
};

BarChart.propTypes = {
  chartData: PropTypes.obj,
};

export default BarChart;
