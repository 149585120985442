import moment from 'moment';
import styled from 'styled-components';
import BillableCell from './TableCells/BillableCell';
import NonBillableCell from './TableCells/NonBillableCell';
import QuantityCell from './TableCells/QuantityCell';
import TextEllipsis from './TableCells/TextEllipsis';
import RateCell from './TableCells/RateCell';

export const ALL_ATTORNEYS = 'All Attorneys';

export const ATTORNEYS_EXTRA_OPTION = {
  id: ALL_ATTORNEYS,
  value: ALL_ATTORNEYS,
  label: ALL_ATTORNEYS,
};

export const TYPE_OPTIONS = [
  { id: 1, label: 'Time', value: 'Time' },
  { id: 2, label: 'Expense', value: 'Expense' },
];

export const ProductivityByAttorneyFormBoxes = [
  {
    label: 'User(s)',
    name: 'user',
    placeholder: 'Select User',
    width: '320px',
    isDropdown: true,
    isMandatory: true,
  },
  {
    label: 'From Date',
    name: 'fromDate',
    placeholder: 'mm/dd/yyyy',
    width: '240px',
    height: '48px',
    isDatePicker: true,
    isMandatory: true,
    isDate: true,
  },
  {
    label: 'To Date',
    name: 'toDate',
    placeholder: 'mm/dd/yyyy',
    width: '240px',
    height: '48px',
    isDatePicker: true,
    isMandatory: true,
    isDate: true,
  },
  {
    label: 'Type',
    name: 'type',
    placeholder: 'Select type',
    width: '240px',
    height: '48px',
    isDropdownMultiChip: true,
    isMandatory: true,
  },
  {
    label: 'Show description',
    name: 'showDescription',
    height: '48px',
    isCheckbox: true,
    isChecked: false,
    isMandatory: false,
    isDisabled: false,
  },
];

const StyledHeader = styled.div`
  flex-grow: 1;
  text-align: right;
  font-size: 16px;
`;

export const productivityByAttorneyColumns = [
  {
    Header: '#',
    accessor: 'lineNo',
    width: 5,
  },
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    minWidth: 160,
    width: 15,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 10,
  },
  {
    Header: 'Category',
    accessor: 'category',
    width: 10,
  },
  {
    Header: 'Handling Attorney',
    accessor: 'handlingAttorney',
    width: 15,
  },
  {
    Header: () => <StyledHeader>Rate</StyledHeader>,
    accessor: 'rate',
    width: 10,
    Cell: RateCell,
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    width: 10,
    Cell: QuantityCell,
  },
  {
    Header: () => <StyledHeader>Non-Billable</StyledHeader>,
    accessor: 'nonBillable',
    width: 10,
    Cell: NonBillableCell,
  },
  {
    Header: () => <StyledHeader>Billable</StyledHeader>,
    accessor: 'billable',
    width: 10,
    Cell: BillableCell,
  },
  {
    Header: 'Date',
    accessor: 'date',
    width: 10,
    Cell: ({ value }) => (value ? moment(value).format('L') : ''),
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: 20,
    Cell: TextEllipsis,
  },
];
