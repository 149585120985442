import CaseStatus from '../../../molecules/CaseStatus';
import { ReferenceNumberCell, DefendantNameCell } from './TableCells';

export const UNASSIGNED = 'Unassigned';
export const MULTIPLE_ATTORNEYS = 'Multiple Attorneys';
export const INCOMPLETE_ASSIGNMENT = 'Incomplete Assignment';

export const ATORNEY = {
  [UNASSIGNED]: UNASSIGNED,
  [MULTIPLE_ATTORNEYS]: MULTIPLE_ATTORNEYS,
  [INCOMPLETE_ASSIGNMENT]: INCOMPLETE_ASSIGNMENT,
};

export const attorneyLabelAndDropDown = {
  label: 'Attorney Assignments',
  dropdownLabel: 'Select state',
  options: [
    { value: 'AZ', label: 'AZ' },
    { value: 'NV', label: 'NV' },
  ],
};

export const COURTS_PAGE_SIZE = 10;

export const accordionTableColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    width: 15,
    Cell: ReferenceNumberCell,
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    width: 15,
  },
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 20,
    Cell: DefendantNameCell,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'defendantName',
    width: 20,
    Cell: DefendantNameCell,
  },
  {
    Header: 'Apartment',
    accessor: 'apartmentNumber',
    width: 10,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 15,
    Cell: CaseStatus,
  },
  {
    Header: 'Attorney',
    accessor: 'attorney',
    width: 15,
  },
];
