import PropTypes from 'prop-types';
import { DeleteCell } from '../../../../atoms/Table/TableCells/DeleteCell';
import { ReferenceNumberCell } from '../../CaseSearch/CaseSearchCells';
import { ActiveTextFormInputCell } from '../TableCells/ActiveTextFormInputCell';
import { DropdownFormInputCell } from '../TableCells/DropdownFormInputCell';

const HiddenTextInputCell = (props) => {
  const { row } = props;
  return row.original.type === 'Other' ? <ActiveTextFormInputCell {...props} /> : null;
};

export const uploadDocumentsColumns = [
  {
    Header: 'Document name',
    accessor: 'documentName',
    name: 'uploadsArray[i].documentName',
    isMandatory: true,
    width: 40,
    Cell: ActiveTextFormInputCell,
    edit: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    name: 'uploadsArray[i].type',
    isMandatory: true,
    width: 20,
    Cell: DropdownFormInputCell,
    edit: true,
  },
  {
    Header: '',
    accessor: 'newType',
    name: 'uploadsArray[i].newType',
    width: 35,
    isMandatory: true,
    Cell: HiddenTextInputCell,
    edit: true,
  },
  {
    Header: '',
    width: 5,
    minWidth: 44,
    accessor: 'control',
    Cell: DeleteCell,
  },
];

export const uploadResultColumns = [
  {
    Header: 'Metric',
    accessor: 'metric',
    width: 80,
  },
  {
    Header: 'Count',
    accessor: 'count',
    width: 20,
  },
];

export const updatedCasesColumns = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    Cell: ReferenceNumberCell,
    width: 60,
  },
  {
    Header: 'Documents Added',
    accessor: 'addedDocumentsCount',
    width: 40,
  },
];

HiddenTextInputCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
