import { List, ListItem, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const StyledList = styled(List)(({ theme }) => ({
  position: 'absolute',
  top: 40,
  zIndex: 5,
  paddingTop: 0,
  paddingBottom: 0,
  minWidth: '100%',
  marginTop: '4px',

  '&.MuiList-root a': {
    background: theme.palette.text.disabled,
    minWidth: '100%',
    fontWeight: 400,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',

    '&:hover': {
      background: theme.palette.additional.tag,
    },

    '&.active': {
      borderRadius: '0px',
    },
    '&:first-child': {
      borderRadius: '10px 10px 0 0',
      '&:last-child': {
        borderRadius: '10px',
      },
    },
    '&:last-child': {
      borderRadius: '0 0 10px 10px',
    },
  },
}));

const NavigationDropdown = ({ config, isListVisible, listRef }) =>
  isListVisible && (
    <StyledList ref={listRef}>
      {config.map((item) => (
        <ListItem
          component={NavLink}
          key={item.label}
          to={item.to}
          state={item.state}
          data-testid="list_item"
        >
          {item.label}
        </ListItem>
      ))}
    </StyledList>
  );

NavigationDropdown.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      state: PropTypes.shape({
        navPage: PropTypes.string,
        subNav: PropTypes.string,
      }),
    }),
  ).isRequired,
  isListVisible: PropTypes.bool,
  listRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

NavigationDropdown.defaultProps = {
  isListVisible: false,
};

export default NavigationDropdown;
