/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Box, Stack, Typography } from '@mui/material';
import moment from 'moment';
import print from 'print-js';
import DocumentsPopup from './MDocumentsPopup';
import MButton from '../../../MUI/Button/MButton';
import MTooltip from '../../../atoms/MTooltip';
import {
  MAX_AMOUNT_OF_ITEMS_ON_PAGE,
  documentStrings,
  documentsColumnsCP,
  documentsColumnsDB,
  uploadDocumentsColumns,
} from './Document.constants';

import Table from '../../../atoms/Table/Table';
import Form from '../../../atoms/Form';
import { ReactComponent as Upload } from '../../../../assets/icons/document-upload.svg';
import { ReactComponent as Printer } from '../../../../assets/icons/Printer.svg';
import { ReactComponent as Add } from '../../../../assets/icons/Add.svg';
import { ReactComponent as Trash } from '../../../../assets/icons/Trash.svg';
import { palette } from '../../../../theme/default';
import CreateUploadDocument from './CreateUploadDocument';
import {
  getDocumentTemplates,
  getDocuments as getDocumentsDB,
  deleteDocument,
  uploadDocument as uploadDocumentDB,
  updateDocument,
  hideDocumentforCP,
} from '../../../../store/slices/cases/casesGeneralSlice';
import {
  getDocumentInfo,
  getDocuments as getDocumentsCP,
  updateDocumentInfo,
  uploadDocument as uploadDocumentCP,
} from '../../../../store/slices/cases/caseCpSlice';
import {
  createNevadaDocument,
  printNevadaCaseDocument,
} from '../../../../store/slices/cases/casesNevadaSlice';
import {
  createArizonaDocument,
  generateComplexComplaintDocumentForArizonaCase,
  getArizonaNoticeDocumentData,
  getListOfAllViolationsForArizonaCaseDocumentGeneration,
  printArizonaCaseDocument,
} from '../../../../store/slices/cases/casesArizonaSlice';
import NotificationDialog from '../../../molecules/NotificationDialog';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import Field from '../../../atoms/Field';
import { apiClient } from '../../../../lib/apiClient';
import SelectViolationsPopup from './SelectViolationsPopup';
import { convertToBinaryFileUrl } from '../../../../utils/binaryHelpers';
import MotionToDismiss from './Modals/MotionToDismiss';
import MotionToPlaceOnCalendar from './Modals/MotionToPlaceOnCalendar';
import MotionToReissueLockout from './Modals/MotionToReissueLockout';
import ConstableInstructions from './Modals/ConstableInstructions';
import MotionToRescind from './Modals/MotionToRescind';
import Dropdown from '../../../atoms/Dropdown';
import { isInFuture } from '../../../../utils/dateHelpers';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import AdditionalInformationFormDialog from '../CaseInformationArizona/AdditionalInformationFormDialog';
import notificationUtils from '../../../../utils/notificationUtils';
import LoaderCover from '../../../atoms/LoaderCover';
import DocumentContext from './DocumentContext';
import Dropzone from '../../../atoms/Dropzone';
import { PrintingDialog } from '../../../molecules/PrintingDialog/PrintingDialog';

const documentsMapper = (item) => ({
  ...item,
  createdDate: moment(item.createdDate).format('L'),
  createdTime: moment(item.createdDate).format('LT'),
});

const nevadaTemplatesWithModals = [
  'Motion to Dismiss',
  'Motion to Place on Calendar',
  'Motion to Re-Issue Lockout',
  'HEN - Constable Instructions',
  'LVT - Constable Instructions',
  'Motion to Rescind',
];

const Document = ({ isDB }) => {
  const [isShowAdditionalButtons, setIsShowAdditionalButtons] = useState({
    print: false,
    remove: false,
  });
  const [isPrinting, setIsPrinting] = useState(false);
  const isPrintingEnabled = useRef(true);
  const [isDragAndDrop, setIsDrag] = useState(true);
  const [isConfirmDeleteDocumentsModalOpen, setIsConfirmDeleteDocumentsModalOpen] = useState(false);
  const [isComplexComplaintModalOpen, setIsComplexComplaintModalOpen] = useState(false);
  const [uploadFilesData, setUploadFilesData] = useState([]);
  const [selectedRowIndexes, setSelectedRowIndexes] = useState([]);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [currentDocumentTemplate, setCurrentDocumentTemplate] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [motionToDismissOpen, setMotionToDismissOpen] = useState(false);
  const [isMotionToPlaceOnCalendarOpen, setMotionToPlaceOnCalendarOpen] = useState(false);
  const [isMotionToReissueLockoutOpen, setMotionToReissueLockoutOpen] = useState(false);
  const [isConstableInstructionsOpen, setIsConstableInstructionsOpen] = useState(false);
  const [isMotionToRescindOpen, setIsMotionToRescindOpen] = useState(false);
  const [isAdditionalInformationDialogOpen, setIsAdditionalInformationDialogOpen] = useState(false);

  const { documentTemplates, documents: documentsDB } = useSelector((state) => state.casesGeneral);
  const { documents: documentsCP, documentInfo } = useSelector((state) => state.caseCp);
  const { noticeDocumentData } = useSelector((state) => state.casesArizona);
  const abortController = useRef(undefined);
  const {
    state: { id: caseId, stateCode },
  } = useLocation();

  const canDeleteDocument = useAllowed([rolesDB.Admin]);

  const { documents, getDocuments, uploadDocument, documentsColumns, createDocument } =
    useMemo(() => {
      if (isDB) {
        const createDocumentByState = {
          AZ: createArizonaDocument,
          NV: createNevadaDocument,
        };

        return {
          documents: documentsDB,
          getDocuments: getDocumentsDB,
          uploadDocument: uploadDocumentDB,
          documentsColumns: documentsColumnsDB?.[stateCode] || [],
          createDocument: createDocumentByState?.[stateCode] || null,
        };
      }
      return {
        documents: documentsCP,
        getDocuments: getDocumentsCP,
        uploadDocument: uploadDocumentCP,
        documentsColumns: documentsColumnsCP,
        createDocument: null,
      };
    }, [isDB, documentsCP, documentsDB, stateCode]);

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: { isLeaseUnavailable: false, isLedgerUnavailable: false },
  });

  const formUploads = useForm({
    defaultValues: {
      uploadDocumentsArray: [],
    },
  });

  const uploadDocumentsArray = useFieldArray({
    control: formUploads.control,
    name: 'uploadDocumentsArray',
  });

  const documentsArray = useFieldArray({
    control: form.control,
    name: 'documents',
    keyName: 'formId',
  });

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    uploadDocumentsArray.remove();
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      uploadDocumentsArray.replace(
        acceptedFiles.map((file) => ({
          name: file.name,
          type: 'Notice',
          file,
        })),
      );
    },
    [uploadDocumentsArray],
  );

  useEffect(() => {
    if (documents?.items) {
      dispatch(getDocumentInfo(caseId));
    }
  }, [documents]);

  useEffect(() => {
    form.reset({
      documents: documents?.items?.map((item) => documentsMapper(item)),
      isLedgerUnavailable: documentInfo?.isLedgerUnavailable,
      isLeaseUnavailable: documentInfo?.isLeaseUnavailable,
    });
  }, [documents, documentInfo]);

  useEffect(() => {
    setIsDrag(!uploadDocumentsArray.fields.length);
  }, [uploadDocumentsArray.fields.length]);

  useEffect(() => {
    if (uploadFilesData.length) {
      setIsDocumentsLoading(true);

      dispatch(uploadDocument({ id: caseId, data: uploadFilesData })).then(() => {
        setIsDocumentsLoading(false);
        dispatch(getDocuments({ id: caseId, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() => {
          setIsDocumentsLoading(false);
        });
      });
    }
  }, [uploadFilesData]);

  useEffect(() => {
    if (isDB) {
      dispatch(getDocumentTemplates({ caseId, stateCode }));
    }
    dispatch(getDocuments({ id: caseId, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() => {
      setIsLoader(false);
    });
    if (stateCode === 'AZ') {
      dispatch(getListOfAllViolationsForArizonaCaseDocumentGeneration(caseId));
    }
  }, []);

  const handleUploadDocuments = () => {
    const values = formUploads.watch();
    const allowedExtensions = ['.pdf', '.jpeg', '.png', '.tiff', '.docs', '.doc', '.xlsx', '.xls'];

    const invalidFile = uploadDocumentsArray.fields.find(
      ({ name }) => !allowedExtensions.some((ext) => name.toLowerCase().endsWith(ext)),
    );

    if (invalidFile) {
      notificationUtils.error(<>File type is not supported - {invalidFile.name}</>);
    } else {
      setUploadFilesData(values.uploadDocumentsArray);
      handleClose();
    }
  };

  const dragAndDrop = (
    <Dropzone
      onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
      multiple
      maxSize={31457280} // 30 MB
      customContent={
        <>
          <Typography variant="bodyL500" color="text.secondary" sx={{ my: 1 }}>
            {documentStrings.uploadNew.text}
            <Typography
              variant="bodyL"
              sx={{
                color: 'buttonPrimary.active',
                textDecoration: 'underline',
                marginLeft: 0.5,
              }}
            >
              {documentStrings.uploadNew.action}
            </Typography>
          </Typography>
          <Typography variant="bodyXS" color="text.secondary" sx={{ my: 1 }}>
            {documentStrings.uploadNew.additionalInfo}
          </Typography>
        </>
      }
    />
  );

  const uploadTable = (
    <Form form={formUploads}>
      <Box
        sx={{
          overflowY: 'scroll',
          maxHeight: '240px',
          width: '732px',
          margin: '32px 0',
          '&::-webkit-scrollbar': {
            width: '4px',
            marginLeft: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: palette.additional.lines,
          },
          '&::-webkit-scrollbar-thumb': {
            background: palette.additional.thumb,
          },
        }}
      >
        <Table
          loading={false}
          columns={uploadDocumentsColumns}
          rows={uploadDocumentsArray.fields}
          onUpdateData={uploadDocumentsArray.update}
          onDeleteRow={uploadDocumentsArray.remove}
        />
      </Box>
    </Form>
  );

  const handleChangeDocumentTypeInPopup = (event) => {
    if (event.target?.files?.length) {
      const file = event.target.files[0];
      uploadDocumentsArray.prepend({
        name: file.name,
        type: 'Notice',
        file,
      });
    }
  };

  const handleCheckedRow = (documentRows, ids) => {
    const selectedDocIds = documentRows.map((item) => +item.documentId);
    const areAnyDocumentsChecked = !!documentRows.length;
    setIsShowAdditionalButtons({
      remove: areAnyDocumentsChecked && canDeleteDocument,
      print:
        areAnyDocumentsChecked &&
        !documentRows.some((doc) => doc.prefileDate && isInFuture(doc.prefileDate)),
    });

    setSelectedRowIndexes(ids);
    setSelectedDocumentIds(selectedDocIds);
  };

  const handleFetchDataPage = (currentPage) => {
    setIsDocumentsLoading(true);
    dispatch(
      getDocuments({
        id: caseId,
        currentPage,
        pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
      }),
    ).then(() => {
      setIsDocumentsLoading(false);
    });
  };

  function openModalForNevadaTemplate(templateName) {
    if (stateCode === 'NV') {
      switch (templateName) {
        case 'Motion to Dismiss':
          setMotionToDismissOpen(true);
          break;
        case 'Motion to Place on Calendar':
          setMotionToPlaceOnCalendarOpen(true);
          break;
        case 'Motion to Re-Issue Lockout':
          setMotionToReissueLockoutOpen(true);
          break;
        case 'HEN - Constable Instructions':
        case 'LVT - Constable Instructions':
          setIsConstableInstructionsOpen(true);
          break;
        case 'Motion to Rescind':
          setIsMotionToRescindOpen(true);
          break;
        default:
          break;
      }
    }
  }

  const generateDocumentsWithAdditionalInformation = async (fields, onFinish) => {
    setIsDocumentsLoading(true);
    const templateId = currentDocumentTemplate.id;
    try {
      await apiClient.post(`/api/arizonaCases/${caseId}/documents/${templateId}`, {
        templateFields: fields,
        caseId,
        templateId,
      });
      await dispatch(getDocuments({ id: caseId, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE }));
      setIsDocumentsLoading(false);
      setCurrentDocumentTemplate(null);
    } catch (e) {
      // TODO: Add error popup
      alert('--------------', e);
    }
    onFinish?.();
  };

  const handleCreateDocumentByTemplate = async () => {
    if (!currentDocumentTemplate) return;

    const { templateId, templateType, templateName } = documentTemplates.find(
      (documentTemplate) => documentTemplate.templateName === currentDocumentTemplate.label,
    );

    if (typeof templateType === 'string' && templateType.toLowerCase() === 'complaint') {
      setIsComplexComplaintModalOpen(true);
    } else if (stateCode === 'NV' && nevadaTemplatesWithModals.includes(templateName)) {
      openModalForNevadaTemplate(templateName);
    } else if (stateCode === 'AZ' && templateType.toLowerCase() === 'notice') {
      setIsDocumentsLoading(true);
      const templateData = await dispatch(getArizonaNoticeDocumentData({ id: caseId, templateId }));
      const templateFields = templateData?.payload?.fields;
      if (templateFields?.length) {
        setIsAdditionalInformationDialogOpen(true);
        setIsDocumentsLoading(false);
      } else {
        generateDocumentsWithAdditionalInformation({});
      }
    } else {
      dispatch(createDocument({ templateId, id: caseId }))
        .unwrap()
        .then(() => {
          setIsDocumentsLoading(true);
          notificationUtils.success('Created successfully');
          dispatch(getDocuments({ id: caseId, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() => {
            setIsDocumentsLoading(false);
            setCurrentDocumentTemplate(null);
          });
        })
        .catch(() => {
          notificationUtils.error('Something went wrong');
          setIsDocumentsLoading(false);
        });
    }
  };

  const handleCreateComplexComplaint = (selectedViolationIds) => {
    if (!currentDocumentTemplate) return;
    setIsDocumentsLoading(true);
    setIsComplexComplaintModalOpen(false);

    const { templateId } = documentTemplates.find(
      (documentTemplate) => documentTemplate.templateName === currentDocumentTemplate.label,
    );

    dispatch(
      generateComplexComplaintDocumentForArizonaCase({
        templateId,
        caseId,
        selectedViolationIds,
      }),
    ).then(() => {
      notificationUtils.success('Created successfully');
      dispatch(getListOfAllViolationsForArizonaCaseDocumentGeneration(caseId));
      dispatch(getDocuments({ id: caseId, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE })).then(() => {
        setCurrentDocumentTemplate(null);
        setIsDocumentsLoading(false);
      });
    });
  };

  const handleCloseConfirmDeleteDocumentsModal = () => {
    setIsConfirmDeleteDocumentsModalOpen(false);
  };

  const handleCloseComplexComplaintModal = () => {
    setIsComplexComplaintModalOpen(false);
  };

  const handleOpenConfirmDeleteDocumentsModal = () => {
    setIsConfirmDeleteDocumentsModalOpen(true);
  };

  // TODO: refactor/check Table component
  const handleDeleteDocument = (id, _, row) => {
    dispatch(
      deleteDocument({
        id: caseId,
        caseId,
        selectedDocuments: [
          {
            documentId: row.documentId,
          },
        ],
        printAllDocuments: false,
      }),
    ).then(() => {
      const { items, currentPage } = documents;
      const isLastRowOnPage = items.length === 1;
      let pageToRequest = 0;

      if (isLastRowOnPage) {
        pageToRequest = currentPage > 1 ? currentPage - 1 : currentPage;
      } else {
        pageToRequest = currentPage;
      }

      notificationUtils.success('Deleted successfully');
      dispatch(
        getDocuments({
          id: caseId,
          pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE,
          currentPage: pageToRequest,
        }),
      ).then(() => {
        setIsDocumentsLoading(false);
      });
    });
  };

  const handleDeleteDocuments = async () => {
    documentsArray.remove(selectedRowIndexes);
    handleCloseConfirmDeleteDocumentsModal();
    setIsLoader(true);
    await dispatch(
      deleteDocument({
        id: caseId,
        caseId,
        selectedDocuments: [
          ...selectedDocumentIds.map((selectedDocumentId) => ({ documentId: selectedDocumentId })),
        ],
        printAllDocuments: documents?.totalRowsCount === selectedRowIndexes.length,
      }),
    );
    notificationUtils.success('Deleted successfully');
    setIsLoader(false);
  };

  const getPrintDocumentsAction = () => {
    const localStorageStateCode = localStorage.getItem('stateCode');
    return localStorageStateCode === 'AZ' ? printArizonaCaseDocument : printNevadaCaseDocument;
  };

  // TODO: refactor/check Table component
  const handlePrintDocument = async (_, __, row) => {
    setIsDocumentsLoading(true);
    const response = await apiClient.get(`/api/cases/${caseId}/documents/${row.documentId}`);
    const {
      data: { result },
    } = response;

    const { contentType, content } = result;

    const fileURL = convertToBinaryFileUrl({ contentType, content });

    print({
      printable: fileURL,
      onPrintDialogClose: () => {
        dispatch(
          getPrintDocumentsAction()({
            id: caseId,
            caseId,
            selectedDocuments: [
              {
                documentId: row.documentId,
              },
            ],
            printAllDocuments: false,
          }),
        ).then(() => {
          setIsDocumentsLoading(false);
        });
      },
    });
  };

  const [filesToPrint, setFilesToPrint] = useState([]);

  const prepareDocuments = ({
    selectedDocumentIds: docIds,
    caseId: currentCaseId,
    documents: selectedDocs,
    selectedRowIndexes: selectedRowIdxs,
  }) => {
    return docIds.map((selectedDocumentId) => ({
      printAllDocuments: selectedDocs?.items?.length === selectedRowIdxs.length,
      documentId: selectedDocumentId,
      id: currentCaseId,
      caseId: currentCaseId,
    }));
  };

  const handlePrintDocuments = () => {
    const selectedDocuments = selectedRowIndexes.map((index) => documents.items[index]);
    const invalidFile = selectedDocuments.some(
      ({ documentName }) => !documentName.toLowerCase().endsWith('.pdf'),
    );

    if (invalidFile) {
      notificationUtils.error('File type is not supported for printing');
    } else {
      setFilesToPrint(
        prepareDocuments({
          selectedDocumentIds,
          caseId,
          documents,
          selectedRowIndexes,
        }),
      );
    }
  };

  const printCPDocuments = (filesPrint) => {
    // only for CP
    if (!filesPrint?.length || isDB) return;

    if (!isPrintingEnabled.current) return;

    if (abortController?.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();
    if (filesPrint?.length > 10) {
      notificationUtils.error(
        <>
          Number of files exceeds 10. Kindly <br />
          select a smaller number of documents
        </>,
      );
      return;
    }

    setIsPrinting(true);

    const filesIds = filesPrint.map((item) => item.documentId);
    apiClient
      .post(
        `/cp/api/cases/documents/concat`,
        {
          documentIds: filesIds,
        },
        { signal: abortController?.current?.signal },
      )
      .then((response) => {
        if (!isPrintingEnabled.current) return;
        const {
          data: { result },
        } = response;
        const { contentType, content } = result;

        const fileURL = convertToBinaryFileUrl({ contentType, content });

        print({
          printable: fileURL,
          onPrintDialogClose: async () => {
            const printUrl = `/cp/api/cases/documents/print`;
            await apiClient.put(printUrl, {
              documentIds: filesIds,
            });
            const { currentPage } = documents;
            setFilesToPrint([]);
            handleFetchDataPage(currentPage);
          },
        });
      })
      .catch(() => {
        if (isPrintingEnabled.current) {
          notificationUtils.error('Failed, try again later');
        }
      })
      .finally(() => {
        setIsPrinting(false);
        abortController.current = undefined;
      });
  };

  const printAsOne = () => {
    isPrintingEnabled.current = true;
    const documentsToPrint = prepareDocuments({
      selectedDocumentIds,
      caseId,
      documents,
      selectedRowIndexes,
    });

    setFilesToPrint(documentsToPrint);

    printCPDocuments(documentsToPrint);
  };

  const bulkDocumentPrinting = (documentIds) => {
    if (abortController?.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();
    setIsPrinting(true);

    apiClient
      .post(
        `/api/cases/documents/concat`,
        { documentIds },
        { signal: abortController?.current?.signal },
      )
      .then((response) => {
        const {
          data: { result },
        } = response;
        const { contentType, content } = result;
        const fileURL = convertToBinaryFileUrl({ contentType, content });

        print({
          printable: fileURL,
          onPrintDialogClose: async () => {
            await apiClient
              .put(`/api/cases/documents/print`, {
                documentIds,
              })
              .then(() => {
                const { currentPage } = documents;
                handleFetchDataPage(currentPage);
              });
          },
        });
      })
      .catch(() => {
        if (isPrintingEnabled.current) {
          notificationUtils.error('Failed, try again later');
        }
      })
      .finally(() => {
        setIsPrinting(false);
        abortController.current = undefined;
      });
  };
  useEffect(() => {
    // only for DB
    if (!filesToPrint.length || !isDB) return;
    if (filesToPrint.length > 10) {
      notificationUtils.error(
        <>
          Number of files exceeds 10. Kindly <br />
          select a smaller number of documents
        </>,
      );
      return;
    }

    if (filesToPrint.length > 1) {
      isPrintingEnabled.current = true;
      const documentIds = filesToPrint.map((file) => file.documentId);
      bulkDocumentPrinting(documentIds);
      return;
    }

    const [firstDocument, ...rest] = filesToPrint;
    const { documentId, caseId: currentCaseId } = firstDocument;

    apiClient
      .get(`/api/cases/${currentCaseId}/documents/${documentId}`)
      .then((response) => {
        const {
          data: { result },
        } = response;
        const { contentType, content } = result;
        const fileURL = convertToBinaryFileUrl({ contentType, content });

        print({
          printable: fileURL,
          onPrintDialogClose: () => {
            dispatch(
              getPrintDocumentsAction()({
                id: currentCaseId,
                caseId: currentCaseId,
                selectedDocuments: [
                  {
                    documentId,
                  },
                ],
                printAllDocuments: false,
              }),
            ).then(() => {
              const { currentPage } = documents;
              handleFetchDataPage(currentPage);
              setIsDocumentsLoading(false);
            });

            if (!rest.length) {
              dispatch(
                getDocuments({ id: currentCaseId, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE }),
              ).then(() => {
                setIsDocumentsLoading(false);
              });
            }
            setFilesToPrint(rest);
          },
        });
      })
      .finally(() => {
        setIsDocumentsLoading(false);
      });
  }, [filesToPrint.length, isDB]);

  const handleUpdateDocumentInfo = () => {
    const { isLeaseUnavailable, isLedgerUnavailable } = form.watch();
    dispatch(updateDocumentInfo({ id: caseId, caseId, isLeaseUnavailable, isLedgerUnavailable }))
      .then(() => {
        notificationUtils.success('Done successfully');
      })
      .catch(() => {
        notificationUtils.error('Failed, try again later');
      });
  };

  const handleChangeDocumentType = (editableRowIndex, editableRow) => {
    dispatch(
      updateDocument({ id: caseId, documentId: editableRow.documentId, type: editableRow.type }),
    ).then(() => {
      notificationUtils.success('Updated successfully');
      documentsArray.update(editableRowIndex, editableRow);
    });
  };

  const handleChangeDocumentName = useCallback(
    async ({ documentId, documentName }) => {
      await dispatch(
        updateDocument({
          id: caseId,
          documentId,
          name: documentName,
        }),
      ).then(() => {
        notificationUtils.success('Updated successfully');
      });
    },
    [caseId, documentsArray],
  );

  const handleCloseMotionToDismissModal = useCallback(() => {
    setMotionToDismissOpen(false);
  }, []);

  const handleCloseMotionToPlaceOnCalendar = useCallback(() => {
    setMotionToPlaceOnCalendarOpen(false);
  }, []);

  const handleCloseMotionToReissueLockout = useCallback(() => {
    setMotionToReissueLockoutOpen(false);
  }, []);

  const handleCloseConstableInstrictions = useCallback(() => {
    setIsConstableInstructionsOpen(false);
  }, []);

  const handleCloseMotionToRescind = useCallback(() => {
    setIsMotionToRescindOpen(false);
  }, []);

  const documentTemplatesOptions = useMemo(() => {
    return documentTemplates.map((item) => ({
      id: item.templateId,
      label: item.templateName,
      value: item.templateName,
    }));
  }, [documentTemplates]);

  const contextValue = useMemo(
    () => ({
      handleChangeDocumentName,
    }),
    [handleChangeDocumentName],
  );

  if (isLoader) return <LoaderCover />;

  const createDocumentPanel = (
    <CreateUploadDocument
      handleOpen={handleOpen}
      value={currentDocumentTemplate}
      onChange={setCurrentDocumentTemplate}
      onCreateDocument={handleCreateDocumentByTemplate}
      isDB={isDB}
    />
  );

  const generateDocumentsWithPopupFields = async (body) => {
    const popupFields = Object.entries(body)
      .map(([key, value]) => {
        const convertValue = () => {
          if (typeof value === 'boolean') {
            return value ? 'True' : 'False';
          }
          return value;
        };
        return { key, value: convertValue() };
      })
      .filter((field) => field.value !== null);
    const templateId = currentDocumentTemplate.id;
    try {
      await apiClient.post(`/api/cases/${caseId}/documents/${templateId}/popupfields`, {
        popupFields,
        caseId,
        templateId,
      });
      notificationUtils.success('Created successfully');
      await dispatch(getDocuments({ id: caseId, pageSize: MAX_AMOUNT_OF_ITEMS_ON_PAGE }));
      setIsDocumentsLoading(false);
      setCurrentDocumentTemplate(null);
    } catch (e) {
      // TODO: Add error popup
      alert('--------------', e);
    }
  };

  const onCreateMotionToDismiss = async (values) => {
    await generateDocumentsWithPopupFields(values);
    setMotionToDismissOpen(false);
  };

  const onCreateMotionToPlaceOnCalendar = async (values) => {
    await generateDocumentsWithPopupFields(values);
    setMotionToPlaceOnCalendarOpen(false);
  };

  const onCreateMotionToReissueLockout = async (values) => {
    await generateDocumentsWithPopupFields(values);
    setMotionToReissueLockoutOpen(false);
  };

  const onCreateConstableInstructions = async (values) => {
    await generateDocumentsWithPopupFields(values);
    setIsConstableInstructionsOpen(false);
  };

  const onCreateMotionToRescind = async (values) => {
    await generateDocumentsWithPopupFields(values);
    setIsMotionToRescindOpen(false);
  };

  const cancelPrinting = () => {
    if (abortController?.current) {
      abortController.current.abort();
    }
    isPrintingEnabled.current = false;
    setFilesToPrint([]);
    setIsPrinting(false);
  };

  const documentsSection = (
    <Form form={form} height="100%">
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          mt: 4,
          px: 4,
          pb: 13,
          width: 1,
        }}
      >
        <Stack spacing={2} mb={4}>
          <Typography variant="h5" marginRight="auto">
            {documentStrings.title}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2} flexGrow={1}>
              <Field
                name="isLeaseUnavailable"
                render={({ field, onCustomChange }) => {
                  return (
                    <CheckboxWithLabel
                      label="Lease Unavailable"
                      isChecked={field?.value}
                      defaultValue={field?.value}
                      onChange={onCustomChange(field.onChange)}
                      marginRight="24px"
                    />
                  );
                }}
              />
              <Field
                name="isLedgerUnavailable"
                render={({ field, onCustomChange }) => {
                  return (
                    <CheckboxWithLabel
                      label="Ledger Unavailable"
                      isChecked={field?.value}
                      defaultValue={field?.value}
                      onChange={onCustomChange(field.onChange)}
                      marginRight="24px"
                    />
                  );
                }}
              />
              <MButton disabled={isLoader} onClick={handleUpdateDocumentInfo}>
                Update
              </MButton>
              <MTooltip
                text="In case the lease and/or ledger is unavailable, check the appropiate checkbox and update please."
                customStyles={{ position: 'absolute', top: '-15px', right: '-10px' }}
              />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              {isDB && (
                <>
                  <Dropdown
                    width="320px"
                    options={documentTemplatesOptions}
                    placeholder={documentStrings.boxes[0].selectLabel}
                    value={currentDocumentTemplate}
                    isColumn
                    onChange={setCurrentDocumentTemplate}
                  />
                  <MButton
                    variant="bordered"
                    disabled={isLoader}
                    onClick={handleCreateDocumentByTemplate}
                  >
                    {documentStrings.button.create}
                  </MButton>
                </>
              )}
              <MButton
                variant={isDB ? 'bordered' : ''}
                disabled={isLoader}
                startIcon={
                  isDB ? <Upload width="20px" height="20px" /> : <Add width="18px" height="18px" />
                }
                onClick={handleOpen}
              >
                {documentStrings.button.upload}
              </MButton>
            </Stack>
          </Stack>
        </Stack>
        <DocumentContext.Provider value={contextValue}>
          <Table
            columns={documentsColumns}
            rows={documentsArray.fields}
            loading={isDocumentsLoading}
            onDeleteRow={handleDeleteDocument}
            onUpdateData={handleChangeDocumentType}
            onCheckedRow={handleCheckedRow}
            isWithCheckbox
            onPrintRow={handlePrintDocument}
            isPagination={documents?.totalPages > 1}
            pageSize={MAX_AMOUNT_OF_ITEMS_ON_PAGE}
            onNextPage={handleFetchDataPage}
            total={documents?.totalRowsCount}
            onPreviousPage={handleFetchDataPage}
            onGotoPage={handleFetchDataPage}
            onRefreshPage={handleFetchDataPage}
          />
        </DocumentContext.Provider>
      </Container>
      {Object.values(isShowAdditionalButtons).some((x) => x) && (
        <Box
          width={1}
          p={4}
          position="absolute"
          bottom={0}
          zIndex={5}
          height="100px"
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
        >
          <Stack direction="row" alignItems="center">
            <Stack spacing={2} direction="row">
              {isShowAdditionalButtons.print && (
                <MButton
                  variant="minimal"
                  onClick={isDB ? handlePrintDocuments : printAsOne}
                  startIcon={<Printer width="20px" height="20px" />}
                >
                  {documentStrings.button.print}
                </MButton>
              )}
              {isShowAdditionalButtons.remove && isDB && (
                <MButton
                  variant="minimal"
                  startIcon={<Trash width="20px" height="20px" />}
                  onClick={handleOpenConfirmDeleteDocumentsModal}
                >
                  {documentStrings.button.delete}
                </MButton>
              )}
            </Stack>
          </Stack>
        </Box>
      )}
      <NotificationDialog
        title="Confirm delete"
        desc="Are you sure you want to delete the selected items?"
        type="alert"
        buttonSecondaryText="Cancel"
        buttonPrimaryText="Delete"
        onClose={handleCloseConfirmDeleteDocumentsModal}
        onConfirm={handleDeleteDocuments}
        isOpen={isConfirmDeleteDocumentsModalOpen}
      />
    </Form>
  );

  return (
    <>
      {isPrinting && (
        <PrintingDialog amount={filesToPrint?.length || 0} cancelPrinting={cancelPrinting} />
      )}
      {!isDocumentsLoading && !documentsArray.fields.length
        ? createDocumentPanel
        : documentsSection}
      <MotionToDismiss
        isOpen={motionToDismissOpen}
        handleClose={handleCloseMotionToDismissModal}
        handleConfirm={onCreateMotionToDismiss}
      />
      <MotionToPlaceOnCalendar
        isOpen={isMotionToPlaceOnCalendarOpen}
        handleClose={handleCloseMotionToPlaceOnCalendar}
        handleConfirm={onCreateMotionToPlaceOnCalendar}
      />
      <MotionToReissueLockout
        isOpen={isMotionToReissueLockoutOpen}
        handleClose={handleCloseMotionToReissueLockout}
        handleConfirm={onCreateMotionToReissueLockout}
      />
      <ConstableInstructions
        isOpen={isConstableInstructionsOpen}
        handleClose={handleCloseConstableInstrictions}
        handleConfirm={onCreateConstableInstructions}
      />
      <MotionToRescind
        isOpen={isMotionToRescindOpen}
        handleClose={handleCloseMotionToRescind}
        handleConfirm={onCreateMotionToRescind}
      />
      <DocumentsPopup
        open={open}
        handleChange={handleChangeDocumentTypeInPopup}
        handleClose={handleClose}
        isDragAndDrop={isDragAndDrop}
        dragAndDrop={dragAndDrop}
        uploadTable={uploadTable}
        uploadHandler={handleUploadDocuments}
        documentStrings={documentStrings}
      />
      {isComplexComplaintModalOpen && (
        <SelectViolationsPopup
          onClose={handleCloseComplexComplaintModal}
          onSubmit={handleCreateComplexComplaint}
        />
      )}
      <AdditionalInformationFormDialog
        isOpen={isAdditionalInformationDialogOpen}
        onClose={() => setIsAdditionalInformationDialogOpen(false)}
        addViolation={generateDocumentsWithAdditionalInformation}
        fields={noticeDocumentData?.fields || []}
      />
    </>
  );
};

Document.propTypes = {
  isDB: PropTypes.bool,
};

Document.defaultProps = {
  isDB: true,
};

export default Document;
