import { saveAs } from 'file-saver';

const browserSupportedMimeTypes = [
  'application/pdf',
  'image/png',
  'text/html',
  'image/gif',
  'image/jpeg',
  'audio/mpeg',
  'video/mp4',
  'video/mpeg',
  'image/png',
  'audio/wav',
  'video/webm',
  'image/webp',
];

export const convertToBinaryFileUrl = ({ contentType, content, fileName }) => {
  const binary = atob(content.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i);
  }
  const file = new File([view], fileName, { type: contentType });
  const fileURL = URL.createObjectURL(file);

  return fileURL;
};

export const convertToBinaryFile = ({ contentType, content, fileName }) => {
  const binary = atob(content.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i);
  }
  const file = new File([view], fileName, { type: contentType });

  return file;
};

export const openBinaryInNewWindow = ({ contentType, content, fileName }) => {
  const fileURL = convertToBinaryFileUrl({ contentType, content, fileName });
  window.open(fileURL, fileName || 'untitled');
};

export const downloadBinaryFile = ({ contentType, content, fileName }) => {
  const uri = `data:${contentType};base64,${content}`;
  saveAs(uri, fileName);
};

export const openOrDownloadBinary = ({ contentType, content, fileName }) =>
  browserSupportedMimeTypes.includes(contentType)
    ? openBinaryInNewWindow({ contentType, content, fileName })
    : downloadBinaryFile({ contentType, content, fileName });
