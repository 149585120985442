import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MDialog from '../../../../atoms/MDialog';
import MButton from '../../../../MUI/Button/MButton';
import Dropdown from '../../../../atoms/Dropdown';
import { apiClient } from '../../../../../lib/apiClient';
import notificationUtils from '../../../../../utils/notificationUtils';
import LoaderCover from '../../../../atoms/LoaderCover';

export const BulkAssignToOtherDialog = ({ handleSubmit, onClose, title, stateCode }) => {
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetcher = async () => {
      setIsLoading(true);
      try {
        const rolesRes = await apiClient.get(`/api/roles`);
        const roleLegalAssistant = rolesRes?.data?.result?.find(
          (role) => role.name === 'Legal Assistant',
        );

        const res = await apiClient.post(`/api/users/filter`, {
          roleId: roleLegalAssistant.roleId,
          isActive: true,
          stateCode,
        });

        const options = res?.data?.result?.items?.map((item) => {
          return {
            value: item.userGuid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        setAssigneeOptions(options);
      } catch {
        notificationUtils.error('Failed, try again later');
      }
      setIsLoading(false);
    };
    fetcher();
  }, []);
  return (
    <MDialog
      minWidth="lg"
      title={title}
      onClose={onClose}
      footerActions={[
        <MButton
          data-testid="cancel_bulk_assign_btn"
          sx={{ width: '104px' }}
          variant="bordered"
          size="large"
          onClick={onClose}
        >
          Cancel
        </MButton>,
        <MButton
          sx={{ width: '104px' }}
          disabled={!selectedAssignee}
          variant="primary"
          size="large"
          onClick={() => handleSubmit(selectedAssignee?.value)}
          data-testid="confirm_bulk_assign_btn"
        >
          Assign
        </MButton>,
      ]}
    >
      {isLoading && <LoaderCover />}
      <Dropdown
        isMandatory
        isColumn
        label="Assignee"
        placeholder="Select assignee"
        width="519px"
        options={assigneeOptions}
        value={selectedAssignee}
        onChange={setSelectedAssignee}
        testid="assignee_select"
      />
    </MDialog>
  );
};

BulkAssignToOtherDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  stateCode: PropTypes.string.isRequired,
};
