/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const setCurrentState = createAction('documentsSlice/setCurrentState', (value) => ({
  type: 'documentsSlice/setCurrentState',
  payload: value,
}));

export const resetTemplatesGroupes = createAction('documentsSlice/resetTemplatesGroupes', () => ({
  type: 'documentsSlice/resetTemplatesGroupes',
}));

export const getStates = createAsyncThunk('documentsSlice/getStates', async () => {
  const { data } = await apiClient.get(`/api/states`);
  return data.result.map((item) => ({
    id: item.id,
    value: item.code,
    label: item.code,
  }));
});

export const getAllExisitingTemplates = createAsyncThunk(
  'documentsSlice/getAllExisitingTemplates',
  async (params) => {
    const { stateCode = '', templateName = '', noticeType = '' } = params;

    const query = new URLSearchParams({
      StateCode: stateCode,
      TemplateName: templateName,
      NoticeType: noticeType,
    });

    const { data } = await apiClient.get(`/api/templates/groups?${query.toString()}`);

    return data?.result.map((item) => ({
      id: item.templateId,
      label: item.name,
      value: item.name,
    }));
  },
);

export const getTemplatesNoticeTypes = createAsyncThunk(
  'documentsSlice/getTemplatesNoticeTypes',
  async (stateCode) => {
    const { data } = await apiClient.get(`/api/templates/noticeTypes?stateCode=${stateCode}`);
    return data.result.map((item, id) => ({
      id,
      label: item.noticeType,
      value: item.noticeType,
    }));
  },
);

export const getTemplatesGroups = createAsyncThunk(
  'documentsSlice/getTemplatesGroups',
  async (params) => {
    const { stateCode = '', noticeType = '', templateName = '' } = params;

    const query = new URLSearchParams({
      TemplateName: templateName,
      NoticeType: noticeType,
      StateCode: stateCode,
    });

    const { data } = await apiClient.get(`api/templates/groups?${query.toString()}`);
    return data;
  },
);

export const downloadTemplateById = createAsyncThunk(
  'documentsSlice/downloadTemplateById',
  async (id) => {
    const data = await apiClient.get(`/api/templates/${id}/download`);
    return data;
  },
);

export const downloadActiveTemplateByGroupId = createAsyncThunk(
  'documentsSlice/downloadActiveTemplateByGroupId',
  async (id) => {
    const data = await apiClient.get(`/api/templates/groups/${id}/download`);
    return data;
  },
);

export const makeTemplateActive = createAsyncThunk(
  'documentsSlice/makeTemplateActive',
  async ({ templateId, groupId }) => {
    const data = await apiClient.put(`/api/templates/groups/${groupId}/activate/${templateId}`);
    return data;
  },
);

export const getTemplatesHistoryByGroupId = createAsyncThunk(
  'documentsSlice/getTemplatesHistoryByGroupId',
  async ({ templateGroupId }) => {
    const { data } = await apiClient.get(`/api/templates/groups/${templateGroupId}`);
    return data;
  },
);

const initialState = {
  states: [],
  courts: null,
  currentState: null,
  templateNameList: [],
  templatesNoticeTypes: [],
  allExisitingTemplates: [],
  templatesGroups: [],
  templateHistory: [],
  error: null,
};

export const documentsSlice = createSlice({
  name: 'documentsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [setCurrentState]: (state, action) => {
      state.currentState = action.payload;
    },
    [resetTemplatesGroupes]: (state) => {
      state.templatesGroups = [];
      state.allExisitingTemplates = [];
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload;
    },
    [getTemplatesHistoryByGroupId.fulfilled]: (state, action) => {
      state.templateHistory = action.payload.result;
    },
    [getTemplatesGroups.fulfilled]: (state, action) => {
      state.templatesGroups = action.payload.result;
    },
    [getAllExisitingTemplates.fulfilled]: (state, action) => {
      state.allExisitingTemplates = action.payload;
    },
    [getTemplatesNoticeTypes.fulfilled]: (state, action) => {
      state.templatesNoticeTypes = action.payload;
    },
  },
});

export default documentsSlice.reducer;
