/* eslint-disable no-await-in-loop */
import React, { useMemo, useState } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import {
  InactiveClientFormBoxes,
  InactiveClientFormBoxesColumns,
  periodOptions,
  stateCodesOptions,
} from './InactiveClient.constants';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';

import {
  createInactiveClientReport,
  getInactiveClientReport,
} from '../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import Dropdown from '../../../atoms/Dropdown';

import Table from '../../../atoms/Table/Table';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import notificationUtils from '../../../../utils/notificationUtils';
import { palette } from '../../../../theme/default';
import EmptyBlock from '../../../molecules/EmptyBlock';
import { entityTypes } from './cells/NameCell';
import useAuth from '../../../../hooks/useAuth';

const InactiveClient = () => {
  const isAdmin = useAllowed([rolesDB.Admin]);
  const { userInfo } = useAuth();

  const [isLoader, setIsLoader] = useState(false);
  // eslint-disable-next-line
  const [tableData, setTableData] = useState(null);
  const [expandedMgs, setExpandedMgs] = useState({});
  const [expandedAddress, setExpandedAddress] = useState({});
  const [dateRange, setDateRange] = useState('');

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const period = form.watch('period');

  const currentRange = useMemo(() => {
    switch (period?.value) {
      case 'more': {
        const today = moment();
        const start = today.subtract(90, 'days');
        return `Earlier than ${start.format('L')}`;
      }
      default: {
        if (Number.isNaN(period?.value)) return '';
        const today = moment();
        const start = moment(today).startOf('day').subtract(Number(period?.value), 'days');
        return `${start.format('L')} - ${today.format('L')}`;
      }
    }
  }, [period]);

  const formStateCode = form.watch('stateCode');

  const getOptions = (name) => {
    switch (name) {
      case 'stateCode':
        return isAdmin
          ? stateCodesOptions
          : stateCodesOptions.filter(({ value }) => userInfo.StateCode === value);
      case 'period':
        return periodOptions;
      default:
        return [];
    }
  };

  const handleSubmit = async (values) => {
    const data = {
      inactiveTimePeriod: values.period?.label,
      stateCode: values.stateCode?.value,
    };

    setIsLoader(true);

    try {
      const { reportId } = await dispatch(createInactiveClientReport(data)).unwrap();
      //   setLastReportId(reportId);
      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code, message },
        } = await dispatch(getInactiveClientReport(reportId)).unwrap();
        if (code === 'Completed') {
          setTableData(items || []);
          done = true;
          setDateRange(currentRange);
        } else if (code === 'Error') {
          notificationUtils.error(message);
          setDateRange('');
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      notificationUtils.error(e.message || 'Something went wrong');
      setDateRange('');
      setTableData(null);
    }
    setIsLoader(false);
  };

  const rowsAdapter = useMemo(() => {
    const values = [];
    tableData?.forEach((mgt) => {
      const isManagementExpanded = expandedMgs[mgt.managementId] || false;
      values.push({
        name: mgt.managementName,
        expanded: isManagementExpanded,
        visible: true,
        className: 'management',
        managementName: mgt.managementName,
        id: mgt.managementId,
        lastEvictionRequestDate: mgt.lastEvictionRequestDate,
        isActive: mgt.isActive,
        type: entityTypes.MANAGEMENT,
      });
      if (isManagementExpanded) {
        mgt.communityItems.forEach((com) => {
          values.push({
            name: com.communityName,
            expanded: false,
            visible: true,
            className: 'management',
            managementName: mgt.managementName,
            id: com.communityId,
            lastEvictionRequestDate: com.lastEvictionRequestDate,
            isActive: false,
            type: entityTypes.COMMUNITY,
          });
        });
        if (mgt.addressItems.length) {
          const isExpanded = expandedAddress[mgt.managementId] || false;
          values.push({
            name: 'Standalone Addresses',
            expanded: isExpanded,
            visible: true,
            managementName: mgt.managementName,
            type: entityTypes.STANDALONE_ADDRESSES,
            id: mgt.managementId,
          });
          if (isExpanded) {
            mgt.addressItems.forEach((address) => {
              values.push({
                name: address.addressName,
                expanded: false,
                visible: true,
                managementName: mgt.managementName,
                id: address.addressId,
                lastEvictionRequestDate: address.lastEvictionRequestDate,
                type: entityTypes.ADDRESS,
                isActive: false,
              });
            });
          }
        }
      }
    });
    return values;
  }, [tableData, expandedMgs, expandedAddress]);

  const handleRowClick = (val) => {
    if (val.type === entityTypes.MANAGEMENT) {
      setExpandedMgs((prev) => {
        return { ...prev, [val.value]: !prev[val.value] };
      });
    } else if (val.type === entityTypes.STANDALONE_ADDRESSES) {
      setExpandedAddress((prev) => {
        return { ...prev, [val.value]: !prev[val.value] };
      });
    }
  };
  const columns = useMemo(() => {
    return InactiveClientFormBoxesColumns({ stateCode: formStateCode });
  }, [formStateCode]);

  return (
    <Stack direction="column" mb={6}>
      <ShadowBlock>
        {isLoader && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100vw"
            height="100vh"
            position="absolute"
            top="0"
            left="0"
            zIndex={10}
          >
            <CircularProgress />
          </Box>
        )}
        <Form form={form} onSubmit={handleSubmit}>
          {InactiveClientFormBoxes.map((i) => (
            <Stack
              direction="row"
              alignItems="center"
              columnGap={3}
              rowGap={2}
              flexWrap="wrap"
              py={1}
            >
              {i.inputBoxes.map((item) => {
                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <Dropdown
                          value={field.value}
                          isAddDisabled={false}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          isColumn
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={onCustomChange(field.onChange)}
                          isMandatory={item.isMandatory}
                        />
                      )}
                    />
                  );
                }
                return null;
              })}
              {period && currentRange && (
                <Box mt={4} data-testid="date_range_text">
                  <Typography color={palette.text.secondary}>Date range:</Typography>
                  <Typography color={palette.text.secondary}>{currentRange}</Typography>
                </Box>
              )}
            </Stack>
          ))}
          <Stack direction="row" mt={2} gap={2}>
            <MButton
              data-testid="run_report_btn"
              type="submit"
              size="medium"
              disabled={isLoader || !form.formState.isValid}
            >
              Run Report
            </MButton>
          </Stack>
        </Form>
      </ShadowBlock>

      {tableData && (
        <Box mt={3}>
          <ShadowBlock customStyles={{ gap: 0 }}>
            <Typography variant="h5" mt={4} mb={2} data-testid="report_title">
              Inactive Client Report for Arizona {dateRange}
            </Typography>
            {tableData?.length && !isLoader ? (
              <Table columns={columns} rows={rowsAdapter} onRowClick={handleRowClick} />
            ) : (
              <Box
                display="flex"
                width="1140px"
                flexDirection="column"
                alignItems="center"
                mt={12}
                mb={25}
                mx="auto"
              >
                <EmptyBlock
                  title="No Records"
                  desc="Please change filters to display report"
                  descFontSize={18}
                />
              </Box>
            )}
          </ShadowBlock>
        </Box>
      )}
    </Stack>
  );
};

export default InactiveClient;
