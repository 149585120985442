import React from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const DateCell = ({ value }) =>
  value ? (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
      }}
    >
      {moment(value).format('L')}
    </Typography>
  ) : (
    ''
  );

DateCell.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
};
