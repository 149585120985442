import { ReferenceNumberCell } from '../../Processing/CaseSearch/CaseSearchCells';
import { StatusCell, SentPrintedCell } from './TableCells';

export const JUDGEMENTS_PAGE_SIZE = 10;
export const COURTNAMES_PAGE_SIZE = 10;

export const UNSENT_UNPRINTED = 'Unsent/Unprinted';
export const SENT_PRINTED = 'Sent/Printed';

export const ACCORDION_TABLE_COLUMNS = [
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    width: 15,
    Cell: ReferenceNumberCell,
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    width: 15,
  },
  {
    Header: 'Community Name',
    accessor: 'communityName',
    width: 20,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'defendantName',
    width: 20,
  },
  {
    Header: 'Violation Type',
    accessor: 'noticeType',
    width: 15,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 15,
    Cell: StatusCell,
  },
  {
    Header: 'Printed/Sent',
    accessor: 'printed',
    width: 21,
    Cell: SentPrintedCell,
  },
];

export const SHOW_DROPDOWN_OPTIONS = [
  { id: 0, value: UNSENT_UNPRINTED, label: UNSENT_UNPRINTED, default: true },
  { id: 1, value: SENT_PRINTED, label: SENT_PRINTED },
];

export const PROPOSED_FORM_OF_JUDGEMENTS_FITER = [
  {
    label: 'County',
    name: 'county',
    placeholder: 'Enter county name',
    width: '322px',
    height: 44,
    isDropdown: false,
    isCheckboxSelect: true,
  },
  {
    label: 'Court Name',
    name: 'courtName',
    placeholder: 'Enter court name',
    width: '221px',
    height: 44,
    isDropdown: true,
    isCheckboxSelect: false,
  },
  {
    label: 'Show',
    name: 'show',
    width: '327px',
    height: 44,
    placeholder: 'Sent/Printed only',
    isDropdown: true,
    isCheckboxSelect: false,
  },
  {
    label: 'Court Date',
    name: 'courtDate',
    width: '178px',
    height: 44,
    placeholder: 'Select community',
    dateFormat: 'MM/DD/YYYY',
    isDate: true,
    isCheckboxSelect: false,
  },
];
