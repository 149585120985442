import { useState } from 'react';

export const SORT_ORDER_ASC = 'asc';
export const SORT_ORDER_DESC = 'desc';

export const useSortTable = (defaultSortField, defaultSortOrder) => {
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);

  const handleSortTable = (sortFieldName, sortDescFirst) => {
    if (sortFieldName) {
      setSortField(sortFieldName);
    }
    if (sortDescFirst) {
      setSortOrder(sortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC);
    }
  };

  return {
    sortField,
    sortOrder,
    handleSortTable,
  };
};
