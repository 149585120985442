export const subPageNavNew = [
  {
    label: 'Court',
    to: '/db/datahandling/newcourt/court',
    state: {
      navPage: 'Data Handling',
      subNav: 'Court',
    },
  },
  {
    label: 'Address',
    to: '/db/datahandling/newcourt/address',
    state: {
      navPage: 'Data Handling',
      subNav: 'Courts',
    },
  },
  {
    label: 'Documents',
    to: '/db/datahandling/editcourt/documents',
    state: {
      navPage: 'Data Handling',
      subNav: 'Courts',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/newcourt/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Courts',
    },
  },
];

export const subPageNavEdit = [
  {
    label: 'Court',
    to: '/db/datahandling/editcourt/court',
    state: {
      navPage: 'Data Handling',
      subNav: 'Court',
    },
  },
  {
    label: 'Address',
    to: '/db/datahandling/editcourt/address',
    state: {
      navPage: 'Data Handling',
      subNav: 'Courts',
    },
  },
  {
    label: 'Documents',
    to: '/db/datahandling/editcourt/documents',
    state: {
      navPage: 'Data Handling',
      subNav: 'Courts',
    },
  },
  {
    label: 'Notes',
    to: '/db/datahandling/editcourt/notes',
    state: {
      navPage: 'Data Handling',
      subNav: 'Courts',
    },
  },
];
