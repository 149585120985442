export const tokenKey = 'token';
export const refreshTokenKey = 'refreshtoken';
export const temporaryTokenKey = 'temporaryToken';
export const caseSearchParamsKey = 'caseSearchParams';

export function createRepositoryWithLocalstorage() {
  return {
    getToken() {
      return localStorage.getItem(tokenKey);
    },

    setToken(token) {
      localStorage.setItem(tokenKey, token);
    },

    getRefreshToken() {
      return localStorage.getItem(refreshTokenKey);
    },

    setRefreshToken(refreshToken) {
      localStorage.setItem(refreshTokenKey, refreshToken);
    },

    getTemporaryToken() {
      return sessionStorage.getItem(temporaryTokenKey);
    },

    setTemporaryToken(temporaryToken) {
      sessionStorage.setItem(temporaryTokenKey, temporaryToken);
    },

    getCaseSearchParams() {
      const searchParams = sessionStorage.getItem(caseSearchParamsKey);
      try {
        return JSON.parse(searchParams);
      } catch {
        return { params: null, selectedValues: null };
      }
    },

    setCaseSearchParams(caseSearchParams) {
      sessionStorage.setItem(caseSearchParamsKey, JSON.stringify(caseSearchParams));
    },

    clearCaseSearchParams() {
      sessionStorage.removeItem(caseSearchParamsKey);
    },

    removeTokens() {
      localStorage.clear();
      sessionStorage.clear();
    },
  };
}
