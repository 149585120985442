import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Typography, Stack, styled, Box, IconButton } from '@mui/material';
import MButton from '../../MUI/Button/MButton';
import MDialog from '../../atoms/MDialog/MDialog';
import Form from '../../atoms/Form';
import Field from '../../atoms/Field';
import Dropzone from '../../atoms/Dropzone';
import DateOrTimePicker from '../../atoms/DateOrTimePicker';
import { ReactComponent as Calendar } from '../../../assets/icons/Calender.svg';
import { ReactComponent as Document } from '../../../assets/icons/Document.svg';
import { ReactComponent as Close } from '../../../assets/icons/Close.svg';
import LoaderCover from '../../atoms/LoaderCover';
import notificationUtils from '../../../utils/notificationUtils';
import Dropdown from '../../atoms/Dropdown';
import { uploadDocuments } from '../../../store/slices/feeScheduleSlice';

const FileStack = styled(Stack)({
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
  border: '1px solid #DCE4FF',
  borderRadius: '10px',
});

const UploadFeeScheduleDocument = ({ isOpen, onClose, availableStates }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
  });

  useEffect(() => {
    if (availableStates.length > 0) {
      form.setValue('state', availableStates[0]);
    }
  }, [availableStates]);

  const handleDrop = ([file]) => {
    form.setValue('File', file);
  };

  const handleDelete = () => {
    form.setValue('File', null);
  };

  const onCancelPress = (isSubmitted) => {
    onClose(isSubmitted);
    form.reset();
  };

  const handleSubmit = form.handleSubmit((formValues) => {
    const values = {
      ...formValues,
      validFrom: formValues.validFrom ? formValues.validFrom.format('YYYY-MM-DD') : null,
      stateCode: formValues.state.value,
    };
    setIsLoading(true);

    dispatch(uploadDocuments(values))
      .unwrap()
      .then(() => {
        onCancelPress(true);
        notificationUtils.success('Uploaded successfully');
      })
      .catch(() => {
        notificationUtils.error('Failed, try again later');
      })
      .finally(() => setIsLoading(false));
  });

  const file = form.watch('File');

  return (
    <MDialog
      isOpen={isOpen}
      onClose={() => onCancelPress(false)}
      title="Upload Fee Schedule"
      maxWidth="796px"
      minWidth="796px"
      footerActions={[
        <MButton size="large" variant="bordered" onClick={() => onCancelPress(false)}>
          Cancel
        </MButton>,
        <MButton size="large" onClick={handleSubmit} disabled={!file || isLoading}>
          Upload
        </MButton>,
      ]}
    >
      {isLoading && <LoaderCover />}
      <Form form={form} onSubmit={handleSubmit}>
        <Stack direction="row" mt={3} mb={3} alignItems="center" columnGap={3} flexWrap="nowrap">
          <Field
            name="state"
            isMandatory
            render={({ field, onCustomChange }) => (
              <Dropdown
                label="Select state"
                name="state"
                options={availableStates}
                onChange={onCustomChange(field.onChange)}
                isMandatory
                placeholder="Select state"
                isSearchable={false}
                isColumn
                width="354px"
                height="48px"
                value={field.value}
              />
            )}
          />

          <Field
            name="validFrom"
            isMandatory
            render={({ field, onCustomChange }) => (
              <DateOrTimePicker
                isDate
                isMandatory
                Svg={Calendar}
                name="validFrom"
                setSelectedTimeOrDate={onCustomChange(field.onChange)}
                selected={field.value}
                label="Valid From"
                width="354px"
                height="48px"
                error={
                  form.formState?.errors?.validFrom ? { message: 'Mandatory Field' } : undefined
                }
              />
            )}
          />
        </Stack>
        {file ? (
          <FileStack direction="row">
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Document style={{ margin: '0 20px 0 0' }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '90%',
                }}
              >
                <Typography
                  variant="bodyM"
                  sx={{
                    width: '650px',
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {file.name}
                </Typography>
                <IconButton onClick={handleDelete}>
                  <Close width="18px" height="18px" />
                </IconButton>
              </Box>
            </Stack>
          </FileStack>
        ) : (
          <Dropzone
            accept={{
              'application/pdf': ['.pdf'],
            }}
            maxSize={1024 * 1024 * 10}
            onDrop={handleDrop}
            customContent={
              <>
                <Typography variant="bodyL500" color="text.secondary" sx={{ my: 1 }}>
                  Drop your document here,
                  <Typography
                    variant="bodyL"
                    sx={{
                      color: 'buttonPrimary.active',
                      textDecoration: 'underline',
                      marginLeft: 0.5,
                    }}
                  >
                    or select
                  </Typography>
                </Typography>
                <Typography variant="bodyXS" color="text.secondary" sx={{ my: 1 }}>
                  Supports PDF files up to 10MB
                </Typography>
              </>
            }
          />
        )}
      </Form>
    </MDialog>
  );
};

UploadFeeScheduleDocument.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  availableStates: PropTypes.arrayOf(PropTypes.string),
};

UploadFeeScheduleDocument.defaultProps = {
  availableStates: [],
};

export default UploadFeeScheduleDocument;
